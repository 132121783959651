import React, { memo } from "react"
import { OnboardingTableHeadCell, OnboardingTableHeaderRow } from "../Onboarding.style";

export const TableHeader = memo(() => (
    <OnboardingTableHeaderRow >
      <OnboardingTableHeadCell>Дата</OnboardingTableHeadCell>
      <OnboardingTableHeadCell>Тема(заголовок)</OnboardingTableHeadCell>
      <OnboardingTableHeadCell>Текст(описание)</OnboardingTableHeadCell>
      <OnboardingTableHeadCell>Статус</OnboardingTableHeadCell>
    </OnboardingTableHeaderRow>
  )
)