import { useReactiveVar } from '@apollo/client';
import { Switch, withStyles } from '@material-ui/core';
import {
  CurrentBundleContainer as Container,
} from "../../../../../MobileAppModal/MobileAppModal.style";
import { primary, secondary } from '../../../../../shared/Style/Style';
import { BundleBlockTitle as Title} from './style';
import { robotsState } from '../../../state';

const StyledSwitch = withStyles({
  switchBase: {
    color: secondary,
    '&$checked': {
      color: primary,
    },
    '&$checked + $track': {
      backgroundColor: primary,
    },
  },
  checked: {},
  track: {},
})(Switch);

export const ArtifactDefaultClothSwitcher = () => {
  const artifact = useReactiveVar(robotsState.artifact);

  const handleChange = () => {
    robotsState.setArtifact({...artifact, isDefault: !artifact?.isDefault});
  }
  
  if (!artifact) return null;
    
  return (
    <Container key={artifact.id}>
      <Title>Назначить по дефолту</Title>
      <StyledSwitch
        checked={artifact.isDefault}
        onChange={handleChange}
      />
    </Container>
  );
};
