import React, { useEffect, useState } from "react";

import { useLazyQuery, useQuery } from "@apollo/client";
import { GetDashboardUserWorkloadProjectsDocument, GetDashboardUserWorkloadProjectsQuery, GetDashboardUserWorkloadReportDocument, GetDashboardUserWorkloadReportQuery } from "../../../../generated/graphql";
import { IDashboardReportProps } from "../../Dashboard";

import { TableBody, TableRow, CircularProgress, Table } from "@material-ui/core";

import { CustomTable } from "../../../shared/CustomTable";
import { Dropdown } from "../../Dropdown";
import { DropdownArrowButton } from "../../../shared/Buttons/DropdownArrowButton";

import { LoadingBox, primary } from "../../../shared/Style/Style";
import { CustomTableCell, CustomTableHead, CustomTableHeaderCell, CustomTableRow } from "../../../shared/CustomTable/CustomTable.style";

import { choseOrToggleIdHandler } from "../../../shared/Utils/StateOperations/choseOrToggleId";

export const EmployeesWorkload = ({ dateRange, projectId }: IDashboardReportProps) => {
  const [assigneeId, setAssigneeId] = useState('');

  const [getProjects, {
    data: projectsData,
    loading: projectsLoading,
    error: projectsError,
  }] = useLazyQuery<GetDashboardUserWorkloadProjectsQuery>(
    GetDashboardUserWorkloadProjectsDocument, {
      fetchPolicy: 'cache-and-network'
    }
  );

  useEffect(() => {
    if (assigneeId) {
      getProjects({
        variables: {
          assigneeId,
          ...dateRange,
        }
      });
    }
  }, [assigneeId, dateRange.startDate, dateRange.endDate]);

  let projectsList: JSX.Element;

  if (projectsLoading) {
    projectsList = (
      <TableRow>
        <CustomTableCell colSpan={2}>
          <LoadingBox>
            <CircularProgress color='inherit'/>
          </LoadingBox>
        </CustomTableCell>
      </TableRow>
    )
  }

  if (projectsError) {
    projectsList = (
      <TableRow>
        <CustomTableCell colSpan={2}>
          Не удалось загрузить данные
        </CustomTableCell>
      </TableRow>
    )
  }

  if (projectsData) {
    const projects = projectsData.getDashboardUserWorkloadProjects;

    projectsList = <Dropdown type='workload' items={projects} />
  }

  const { data, loading, error } = useQuery<GetDashboardUserWorkloadReportQuery>(
    GetDashboardUserWorkloadReportDocument, {
      variables: {
        projectId,
        ...dateRange
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  let content;

  if (loading) {
    content = (
      <TableBody>
        <TableRow>
          <CustomTableCell>
            <LoadingBox>
              <CircularProgress color='inherit'/>
            </LoadingBox>
          </CustomTableCell>
        </TableRow>
      </TableBody>
    )
  }

  if (error) {
    content = (
      <TableBody>
        <TableRow>
          <CustomTableCell>
            Не удалось загрузить данные
          </CustomTableCell>
        </TableRow>
      </TableBody>
    )
  }

  if (data) {
    const users = data.getDashboardUserWorkloadReport;

    content = (
      <TableBody>
        {users.length
          ? users.map(user => (
            <React.Fragment key={user.userId}>

            <CustomTableRow
              clickable={true}
              onClick={() => choseOrToggleIdHandler(user.userId, assigneeId, setAssigneeId)}
            >
              <CustomTableCell width={100}>
                {user.assigneeName}

                <DropdownArrowButton expanded={user.userId === assigneeId}/>
              </CustomTableCell>
              <CustomTableCell width={100} style={{ color: primary }}>
                ({user.pct}%)
              </CustomTableCell>
            </CustomTableRow>

            {(user.userId === assigneeId) && projectsList}
            </React.Fragment>
          ))
          : (
            <TableRow>
              <CustomTableCell>
                Не удалось загрузить данные
              </CustomTableCell>
            </TableRow>
          )
        }
      </TableBody>
    )
  }

  const table = (
    <Table>
      <CustomTableHead>
        <TableRow>
          <CustomTableHeaderCell colSpan={2} width={100}>
            Нагрузка по сотрудникам
          </CustomTableHeaderCell>
        </TableRow>
      </CustomTableHead>

      {content}
    </Table>
  )

  return (
    <>
      <CustomTable data={data?.getDashboardUserWorkloadReport} tableContent={table} height={247}/>
    </>
  )
}