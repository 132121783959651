import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { Box, TextField, Typography } from "@material-ui/core";
import { PersonalData } from "../UserEdit/UserEdit.style";
import { useStyles } from "../../shared/Style/Style";
import { ButtonCustom } from "../../shared/Style/Style";
import { ProjectAddLinkModalContainer } from "./ProjectModal.style";
import {Project} from "../../../generated/graphql";

export interface LinkData {
  title: string;
  link: string;
  login?: string;
  password?: string;
  values?: Project;
}

export const ProjectAddLinkModal = ({
  open,
  showModal,
  modalHandleSubmit,
  projectLink,
  values,
}: {
  open: boolean;
  showModal(param: boolean): void;
  modalHandleSubmit(data: any): void;
  projectLink?: boolean;
  values: Project;
}) => {
  const classes = useStyles();
  const [linkData, setLinkData] = useState<LinkData>({ title: "", link: "", values });
  const [showErrors, setShowErrors] = useState(false);

  const handleClose = (): void => {
    showModal(false);
  };

  const handleChange = (
    e: React.ChangeEvent<{ value: any; name: string }>
  ): void => {
    e.target.name === "name" &&
      setLinkData({ ...linkData, title: e.target.value });
    e.target.name === "link" &&
      setLinkData({ ...linkData, link: e.target.value });
    e.target.name === "login" &&
      setLinkData({ ...linkData, login: e.target.value });
    e.target.name === "password" &&
      setLinkData({ ...linkData, password: e.target.value });
  };

  const handleSubmit = () => {
    setShowErrors(true);
    linkData.title &&
      linkData.link &&
      modalHandleSubmit(linkData)
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="simple-dialog-title"
      onClose={handleClose}
    >
      <ProjectAddLinkModalContainer>
        <Box mb={4}>
          <Typography component={PersonalData} variant="subtitle1" gutterBottom>
            Создать ссылку
          </Typography>
        </Box>

        <Box component="div" width="100%">
          <form onSubmit={handleSubmit}>
            <TextField
              name="name"
              id="name"
              className={classes.root}
              margin="normal"
              fullWidth
              label="Название"
              variant="filled"
              type="text"
              value={linkData.title}
              onChange={handleChange}
              error={showErrors && !linkData.title}
              helperText={showErrors && !linkData.title && "Обязательное поле"}
            />

            <TextField
              name="link"
              id="link"
              className={classes.root}
              margin="normal"
              fullWidth
              label="Ссылка"
              variant="filled"
              type="text"
              value={linkData.link}
              onChange={handleChange}
              error={showErrors && !linkData.link}
              helperText={showErrors && !linkData.link && "Обязательное поле"}
            />
            {projectLink && (
              <>
                <TextField
                  name="login"
                  id="login"
                  className={classes.root}
                  margin="normal"
                  fullWidth
                  label="Логин"
                  variant="filled"
                  type="text"
                  value={linkData.login}
                  onChange={handleChange}
                />
                <TextField
                  name="password"
                  id="password"
                  className={classes.root}
                  margin="normal"
                  fullWidth
                  label="Пароль"
                  variant="filled"
                  type="text"
                  value={linkData.password}
                  onChange={handleChange}
                />
              </>
            )}

            <Box display="flex" justifyContent="center" mb={3} mt={4}>
              <ButtonCustom type="submit">Создать</ButtonCustom>
            </Box>
          </form>
        </Box>
      </ProjectAddLinkModalContainer>
    </Dialog>
  );
};
