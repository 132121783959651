import styled from "styled-components";
import { Box, List, TextField, Select } from "@material-ui/core";
import { ButtonCustomWithDarkText, lightBlack, primary, SmallUserAvatarStyle } from "../shared/Style/Style";

export const CorrectionsList = styled(List)`
  max-height: 52vh;
  overflow-y: auto;
`;

export const CorrectionsListItem = styled.li`
  display: flex;
  gap: 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 50px 20px 35px 40px;
  color: ${lightBlack};
  margin-bottom: 10px;
`;

export const EmptyCorrectionsListItem = styled(CorrectionsListItem)`
  justify-content: center;
  padding: 30px;
`;

export const CorrectionContentBox = styled(Box)`
  display: block;
  width: 100%;
`;

export const CorrectionTextBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 20px;
`;

export const DateBox = styled(CorrectionTextBox)`
  gap: 20px;
`;

export const CommentsControl = styled.p`
  margin: 0 0 20px;
  color: ${primary};
  cursor: pointer;
`;

export const AddCommentBar = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 70px;
`;

export const AddCommentBtn = styled(ButtonCustomWithDarkText)`
  text-transform: unset;
  line-height: 45px;
  max-height: 45px;
  width: 145px;
  margin-left: 70px;
`;

export const AddCommentTextField = styled(TextField)`
  margin-top: 20px;

  & .MuiInput-underline:before {
    border-bottom-color: #eee;
  }

  & .MuiInput-underline:after {
    border-bottom-color:${primary};
  }

  & .MuiInputBase-multiline {
    padding: 13px 14px 13px 20px;
  }
`;

interface ICustomListProps {
  visible: boolean;
}

export const CommentsList = styled(List)<ICustomListProps>`
  padding: 0;
  transition: all 0.5s;
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  max-height: ${props => props.visible ? '300px' : 0};
  overflow-y: ${props => props.visible ? 'auto' : 'hidden'};
`;

export const Comment = styled.li`
  width: 75%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  color: ${lightBlack};
`;

export const CommentContent = styled(Box)`
  flex-grow: 1;
`;

export const CommentAvatar = styled(SmallUserAvatarStyle)`
  flex-shrink: 0;
`;

export const CommentTitle = styled.h3`
  font-size: 12px;
  line-height: 14px;
  margin: 0;
`;


export const CommentText = styled.p`
  margin: 10px 0;

`;

export const CommentDate = styled.div`
  text-align: right;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
`;

export const CorrectionStatusSelect = styled(Select)`
  padding: 5px 10px 5px 20px;
  background-color: #e9e9e9;
  width: 240px;
  font-weight: 500;
  color: ${lightBlack};

  & .MuiSelect-select:focus {
    background-color: unset;
  }

  &::after {
    content: none;
  }

  &::before {
    content: none;
  }

  &:hover {
    border: none;
  }

  & .MuiSelect-icon {
    right: 10px;
  }
`;
