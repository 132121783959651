import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useMutation } from "@apollo/client";

import { Backdrop, Box, CircularProgress } from "@material-ui/core";

import { HeaderCell } from "../../Salary/Salary.style";

import {
  CreatePayrollKpiDocument,
  UpdatePayrollKpiDocument,
  DeletePayrollKpiDocument,
  GetUserPayrollDocument,
  CreatePayrollKpiMutation,
  DeletePayrollKpiMutation,
  PayrollKpi,
  UpdatePayrollKpiMutation,
} from "../../../generated/graphql";
import {
  ButtonCustomWithIcon,
  ButtonPlusSmall,
  HeaderTxt,
  Cell,
} from "../../shared/Style/Style";
import { ErrorMassage } from "../../Payments/Payments.style";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";
import { ISalaryModalInnerProps } from "../SalaryModal";
import {
  BodyRowGrid,
  ContainerKpiButtons,
  FooterCell,
  FooterGrid,
  HeadersGrid,
  KpiTable,
  ModalBody,
  ModalHeaderLeftMargin,
  ModalSubtitle,
  ModalSubtitleColorPrimary,
  ContainerKpiSaveButton
} from "../SalaryModal.style";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { CustomTableInput } from "../../shared/CustomTable/CustomTable.style";

export const round2 = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

const kpiTableHeader = [
  { title: "Показатели" },
  { title: "Един. измерения" },
  { title: "Вес" },
  { title: "План" },
  { title: "Факт" },
  { title: "% выполнения" },
  { title: "МВО" },
];

interface KpiData {
  name: string;
  unit: string;
  weigh: number;
  plan: number;
  fact: number;
}

export const KpiModal = ({
  close,
  payroll,
}: ISalaryModalInnerProps) => {
  const [kpiData, setKpiData] = useState<KpiData | PayrollKpi>({
    name: "",
    unit: "",
    weigh: 0,
    plan: 0,
    fact: 0,
  });
  const [createNewRow, setCreateNewRow] = useState<boolean>(false);
  const [updateRow, setUpdateRow] = useState<string | null>(null);
  const [createKpiErrors, setCreateKpiErrors] = useState({
    nameError: false,
    unitError: false,
    weighError: false,
    planError: false,
    factError: false,
  });

  const [createKPI, { loading: createKPILoading, error: createKPIError }] =
    useMutation<CreatePayrollKpiMutation>(CreatePayrollKpiDocument);

  const [updateKPI, { loading: updateKPILoading, error: updateKPIError }] =
    useMutation<UpdatePayrollKpiMutation>(UpdatePayrollKpiDocument);

  const [deleteKPI, { loading: deleteKPILoading, error: deleteKPIError }] =
    useMutation<DeletePayrollKpiMutation>(DeletePayrollKpiDocument);

  const kpiSum = payroll.kpi.reduce(
    (acc, k) =>
      acc +
      round2(((k.weigh as number) * (k.fact as number)) / (k.plan as number)),
    0
  );
  const kpiValue = kpiSum * payroll.bonusFund;

  const mutateKPI = (type: string): void => {
    if (!kpiData.name) {
      setCreateKpiErrors((state) => ({
        ...state,
        nameError: true,
      }));
    }

    if (!kpiData.unit) {
      setCreateKpiErrors((state) => ({
        ...state,
        unitError: true,
      }));
    }

    if (!kpiData.weigh) {
      setCreateKpiErrors((state) => ({
        ...state,
        weighError: true,
      }));
    }

    if (!kpiData.plan) {
      setCreateKpiErrors((state) => ({
        ...state,
        planError: true,
      }));
    }

    if (!kpiData.fact) {
      setCreateKpiErrors((state) => ({
        ...state,
        factError: true,
      }));
    }

    if (
      !kpiData.name ||
      !kpiData.unit ||
      !kpiData.weigh ||
      !kpiData.plan ||
      !kpiData.fact
    ) {
      return;
    }

    type === "create" &&
      createKPI({
        variables: { kpiData: { payrollId: payroll.id, ...kpiData } },
        refetchQueries: [
          {
            query: GetUserPayrollDocument,
            variables: { payrollId: payroll.id },
          },
        ],
      });

    type === "update" &&
      updateKPI({
        variables: {
          kpiData: {
            id: updateRow,
            name: kpiData.name,
            unit: kpiData.unit,
            weigh: kpiData.weigh,
            plan: kpiData.plan,
            fact: kpiData.fact,
          },
          refetchQueries: [
            {
              query: GetUserPayrollDocument,
              variables: { payrollId: payroll.id },
            },
          ],
        },
      });

    setCreateNewRow(false);
    setUpdateRow(null);
    setKpiData({
      name: "",
      unit: "",
      weigh: 0,
      plan: 0,
      fact: 0,
    });
  };

  if (createKPILoading || updateKPILoading || deleteKPILoading) {
    return (
      <Backdrop
        open={createKPILoading || updateKPILoading || deleteKPILoading}
        style={{ color: "#FFB61D" }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (createKPIError || updateKPIError || deleteKPIError) {
    return (
      <ErrorMassage>
        <ErrorSnackbar
          error={createKPIError || updateKPIError || deleteKPIError}
        />
      </ErrorMassage>
    );
  }

  return (
    <ModalBody>
      <Box component="div" display="flex" mb={2} alignItems="center">
        <ModalHeaderLeftMargin>KPI</ModalHeaderLeftMargin>
        <ModalSubtitle>Премиальный фонд</ModalSubtitle>
        <ModalSubtitleColorPrimary>{payroll.bonusFund || 0}</ModalSubtitleColorPrimary>
        <ButtonPlusSmall onClick={() => setCreateNewRow(true)} />
        <CloseIconButton close={close}/>
      </Box>

      <KpiTable>
        <HeadersGrid>
          {kpiTableHeader.map((el, i) => (
            <HeaderCell key={i}>
              <HeaderTxt>{el.title}</HeaderTxt>
            </HeaderCell>
          ))}
        </HeadersGrid>

        {createNewRow && (
          <Box component="div" display="flex" position="relative">
            <BodyRowGrid>
              <Cell>
                <CustomTableInput
                  type="text"
                  value={kpiData!.name || ""}
                  onChange={({ target: { value } }) => {
                    setKpiData({ ...kpiData, name: value });
                    setCreateKpiErrors({
                      ...createKpiErrors,
                      nameError: false,
                    });
                  }}
                  style={{
                    border: `1px solid ${
                      createKpiErrors.nameError ? "#E85050" : ""
                    }`,
                  }}
                />
              </Cell>

              <Cell>
                <CustomTableInput
                  type="text"
                  value={kpiData!.unit || ""}
                  onChange={({ target: { value } }) => {
                    setKpiData({ ...kpiData, unit: value });
                    setCreateKpiErrors({
                      ...createKpiErrors,
                      unitError: false,
                    });
                  }}
                  style={{
                    border: `1px solid ${
                      createKpiErrors.unitError ? "#E85050" : ""
                    }`,
                  }}
                />
              </Cell>
              <Cell>
                <CustomTableInput
                  type="number"
                  step="0.01"
                  value={kpiData!.weigh || 0}
                  onChange={({ target: { value } }) => {
                    setKpiData({ ...kpiData, weigh: +value });
                    setCreateKpiErrors({
                      ...createKpiErrors,
                      weighError: false,
                    });
                  }}
                  style={{
                    border: `1px solid ${
                      createKpiErrors.weighError ? "#E85050" : ""
                    }`,
                  }}
                />
              </Cell>
              <Cell>
                <CustomTableInput
                  type="number"
                  value={kpiData!.plan || 0}
                  onChange={({ target: { value } }) => {
                    setKpiData({ ...kpiData, plan: +value });
                    setCreateKpiErrors({
                      ...createKpiErrors,
                      planError: false,
                    });
                  }}
                  style={{
                    border: `1px solid ${
                      createKpiErrors.planError ? "#E85050" : ""
                    }`,
                  }}
                />
              </Cell>
              <Cell>
                <CustomTableInput
                  type="number"
                  value={kpiData!.fact || 0}
                  onChange={({ target: { value } }) => {
                    setKpiData({ ...kpiData, fact: +value });
                    setCreateKpiErrors({
                      ...createKpiErrors,
                      factError: false,
                    });
                  }}
                  style={{
                    border: `1px solid ${
                      createKpiErrors.factError ? "#E85050" : ""
                    }`,
                  }}
                />
              </Cell>

              <Cell>
                <CustomTableInput
                  type="number"
                  value={
                    (
                      ((kpiData?.fact as number) / (kpiData?.plan as number)) *
                      100
                    ).toFixed() || 0
                  }
                />
              </Cell>
              <Cell>
                <CustomTableInput
                  type="number"
                  value={
                    (
                      ((kpiData?.fact as number) / (kpiData?.plan as number)) *
                      100 *
                      (kpiData?.weigh as number)
                    ).toFixed() || 0
                  }
                />
              </Cell>
            </BodyRowGrid>

            <ContainerKpiSaveButton>
              <ButtonCustomWithIcon
                style={{ color: "#FFB61D" }}
                onClick={() => mutateKPI(updateRow ? "update" : "create")}
              >
                Save
              </ButtonCustomWithIcon>
            </ContainerKpiSaveButton>
          </Box>
        )}

        {payroll.kpi?.map((k) => (
          <Box component="div" display="flex" position="relative">
            <BodyRowGrid>
              <Cell>
                <CustomTableInput type="text" value={k!.name || ""} disabled />
              </Cell>
              <Cell>
                <CustomTableInput type="text" value={k!.unit || ""} disabled />
              </Cell>
              <Cell>
                <CustomTableInput type="text" value={k!.weigh || 0} disabled />
              </Cell>
              <Cell>
                <CustomTableInput type="number" value={k!.plan || 0} disabled />
              </Cell>
              <Cell>
                <CustomTableInput type="number" value={k!.fact || 0} disabled />
              </Cell>

              <Cell>
                <CustomTableInput
                  type="number"
                  disabled
                  value={
                    (
                      ((k?.fact as number) / (k?.plan as number)) *
                      100
                    ).toFixed() || 0
                  }
                />
              </Cell>
              <Cell>
                <CustomTableInput
                  type="number"
                  disabled
                  value={
                    (
                      ((k?.fact as number) / (k?.plan as number)) *
                      100 *
                      (k?.weigh as number)
                    ).toFixed() || 0
                  }
                />
              </Cell>
            </BodyRowGrid>
            {updateRow !== k.id && (
              <ContainerKpiButtons>
                <EditIcon
                  onClick={() => {
                    setUpdateRow(k.id as string);
                    setKpiData(k);
                    setCreateNewRow(true);
                  }}
                  style={{ color: "#C4C4C4" }}
                />

                <DeleteIcon
                  style={{ color: "#E85050" }}
                  onClick={() => {
                    deleteKPI({
                      variables: { kpiId: k!.id },
                      refetchQueries: [
                        {
                          query: GetUserPayrollDocument,
                          variables: { payrollId: payroll.id },
                        },
                      ],
                    });
                  }}
                />
              </ContainerKpiButtons>
            )}
          </Box>
        ))}

        <div>
          <FooterGrid>
            <FooterCell>Итого</FooterCell>
            <FooterCell style={{ gridColumnEnd: '7' }}/>
            <FooterCell>{kpiValue}</FooterCell>
          </FooterGrid>
        </div>
      </KpiTable>
    </ModalBody>
  );
};
