import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import {
  ApplicationLink,
  CreateApplicationLinkDocument,
  CreateApplicationLinkMutation,
  DeleteApplicationLinkDocument,
  DeleteApplicationLinkMutation,
  GetApplicationDocument,
  UpdateApplicationLinkDocument,
  UpdateApplicationLinkMutation
} from "../../generated/graphql";

import { List, Box, Collapse } from "@material-ui/core";

import { MobileAppInput, MobileAppInputTitle } from "./MobileAppEdit.style";

import { ButtonPlusSmall } from "../shared/Style/Style";
import { SaveIconButton } from "../shared/Buttons/SaveIconButton";
import { DeleteIconButton } from "../shared/Buttons/DeleteIconButton";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";

interface IMobileAppEditLinksListProps {
  links: ApplicationLink[];
  applicationId: string;
}

const initialLinkData: {[key: string]: string} = {
  name: '',
  link: '',
}

const initialErrors: {[key: string]: boolean} = {
  name: false,
  link: false,
}

export const MobileAppEditLinksList = ({ links, applicationId }: IMobileAppEditLinksListProps) => {
  const [linkData, setLinkData] = useState(initialLinkData);
  const [errors, setErrors] = useState(initialErrors);

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isCreating, setCreating] = useState(false);

  const [editedLink, setEditedLink] = useState<ApplicationLink>(null);

  const choseAppLinkHandler = (link: ApplicationLink): void => {
    if (editedLink?.id !== link.id) {
      setCreating(false);
      setEditedLink(link);
      setErrors(initialErrors);
      setLinkData({
        name: link.name,
        link: link.link,
      });
    }
  }

  const setInitialState = (): void => {
    setErrors(initialErrors);
    setLinkData(initialLinkData);
    setRequestError(false);
    setErrorMessage('');
  }

  const showCreateFieldsHandler = (): void => {
    setCreating(true);
    setEditedLink(null);
    setInitialState();
  }

  const hideCreateFieldsHandler = (): void => {
    setCreating(false);
    setInitialState();
  }

  //#region delete link

  const [deleteAppLink, {
    data: deleteAppLinkData,
    loading: deleteAppLinkLoading,
    error: deleteAppLinkError,
  }] = useMutation<DeleteApplicationLinkMutation>(
    DeleteApplicationLinkDocument, {
      variables: {
        id: editedLink?.id,
      },
      refetchQueries: [{ query: GetApplicationDocument, variables: { id: applicationId } }]
    }
  );

  //#endregion

  //#region create link

  const [createAppLink, {
    data: createAppLinkData,
    loading: createAppLinkLoading,
    error: createAppLinkError,
  }] = useMutation<CreateApplicationLinkMutation>(
    CreateApplicationLinkDocument, {
      variables: {
        applicationId,
        ...linkData,
      },
      refetchQueries: [{ query: GetApplicationDocument, variables: { id: applicationId } }]
    }
  );

  //#endregion

  //#region update link

  const [updateAppLink, {
    data: updateAppLinkData,
    loading: updateAppLinkLoading,
    error: updateAppLinkError,
  }] = useMutation<UpdateApplicationLinkMutation>(
    UpdateApplicationLinkDocument, {
      variables: {
        applicationLinkId: editedLink?.id,
        ...linkData,
      },
      refetchQueries: [{ query: GetApplicationDocument, variables: { id: applicationId } }]
    }
  )

  //#endregion


  const saveAppLinkHandler = (): void => {
    Object.entries(linkData).forEach((field) => {
      if (!field[1]) {
        setErrors(state => ({
          ...state,
          [field[0]]: true,
        }))
      }
    });

    if (Object.values(linkData).some(value => !value)) {
      return;
    }

    isCreating ? createAppLink() : updateAppLink();
  }

  //#region effects

  useEffect(() => {
    if (deleteAppLinkError) {
      setRequestError(true);
      setErrorMessage('При удалении ссылки произошла ошибка');
    }
  }, [deleteAppLinkError])

  useEffect(() => {
    if (deleteAppLinkData) {
      setEditedLink(null);
      setInitialState();
    }
  }, [deleteAppLinkData])

  useEffect(() => {
    if (updateAppLinkError) {
      setRequestError(true);
      setErrorMessage('При редактировании ссылки произошла ошибка');
    }
  }, [updateAppLinkError])

  useEffect(() => {
    if (updateAppLinkData) {
      setEditedLink(null);
      setInitialState();
    }
  }, [updateAppLinkData])

  useEffect(() => {
    if (createAppLinkError) {
      setRequestError(true);
      setErrorMessage('При создании ссылки произошла ошибка');
    }
  }, [createAppLinkError])

  useEffect(() => {
    if (createAppLinkData) {
      hideCreateFieldsHandler();
    }
  }, [createAppLinkData])

  //#endregion

  const disableOnLoading = createAppLinkLoading || updateAppLinkLoading || deleteAppLinkLoading;

  return (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        mr={'13px'}
      >
        <MobileAppInputTitle>
          Ссылки на социальные сети
        </MobileAppInputTitle>

        <ButtonPlusSmall
          disabled={disableOnLoading}
          onClick={showCreateFieldsHandler}
        />
      </Box>

      <List
        disablePadding
      >
        {links.length
          ? (
            <>
              <Collapse
                in={isCreating}
                collapsedSize={'0px'}
                style={{
                  transitionDuration: '500ms'
                }}
              >
                <Box
                  display='flex'
                  justifyContent='space-between'
                  mb={2}
                >
                  <Box
                    mr={2}
                    flexBasis={'40%'}
                  >
                    <MobileAppInput
                      fullWidth
                      disabled={disableOnLoading}
                      value={linkData.name}
                      onChange={({ target: { value } }) => setLinkData({...linkData, name: value})}
                      onFocus={() => setErrors({...errors, name: false})}
                      error={errors.name}
                    />
                  </Box>

                  <Box
                    flexBasis={'60%'}
                  >
                    <MobileAppInput
                      fullWidth
                      disabled={disableOnLoading}
                      value={linkData.link}
                      onChange={({ target: { value } }) => setLinkData({...linkData, link: value})}
                      onFocus={() => setErrors({...errors, link: false})}
                      error={errors.link}
                    />
                  </Box>

                  <SaveIconButton
                    disabled={false}
                    save={saveAppLinkHandler}
                  />

                  <DeleteIconButton
                    disabled={disableOnLoading}
                    deleteHandler={hideCreateFieldsHandler}
                    item='ссылку'
                  />
                </Box>
              </Collapse>

              {links.map(appLink => (
                <Box
                  key={appLink.id}
                  display='flex'
                  justifyContent='space-between'
                  mb={2}
                  onClick={() => choseAppLinkHandler(appLink)}
                >
                  <Box
                    mr={2}
                    flexBasis={'40%'}
                  >
                    <MobileAppInput
                      fullWidth
                      disabled={disableOnLoading}
                      value={appLink.id === editedLink?.id ? linkData.name : appLink.name}
                      onChange={({ target: { value } }) => setLinkData({...linkData, name: value})}
                      onFocus={() => setErrors({...errors, name: false})}
                      error={appLink.id === editedLink?.id && errors.name}
                    />
                  </Box>

                  <Box
                    flexBasis={'60%'}
                  >
                    <MobileAppInput
                      fullWidth
                      disabled={disableOnLoading}
                      value={appLink.id === editedLink?.id ? linkData.link : appLink.link}
                      onChange={({ target: { value } }) => setLinkData({...linkData, link: value})}
                      onFocus={() => setErrors({...errors, link: false})}
                      error={appLink.id === editedLink?.id && errors.link}
                    />
                  </Box>

                  <SaveIconButton
                    disabled={disableOnLoading}
                    save={saveAppLinkHandler}
                  />

                  <DeleteIconButton
                    disabled={disableOnLoading}
                    deleteHandler={deleteAppLink}
                    item='ссылку'
                  />
                </Box>
              ))}
            </>
          ) : (
            <Collapse
              in={isCreating}
              collapsedSize={'0px'}
              style={{
                transitionDuration: '500ms'
              }}
            >
              <Box
                display='flex'
                justifyContent='space-between'
                mb={2}
              >
                <Box
                  mr={2}
                  flexBasis={'40%'}
                >
                  <MobileAppInput
                    fullWidth
                    disabled={disableOnLoading}
                    value={linkData.name}
                    onChange={({ target: { value } }) => setLinkData({...linkData, name: value})}
                    onFocus={() => setErrors({...errors, name: false})}
                    error={errors.name}
                  />
                </Box>

                <Box
                  flexBasis={'60%'}
                >
                  <MobileAppInput
                    fullWidth
                    disabled={disableOnLoading}
                    value={linkData.link}
                    onChange={({ target: { value } }) => setLinkData({...linkData, link: value})}
                    onFocus={() => setErrors({...errors, link: false})}
                    error={errors.link}
                  />
                </Box>

                <SaveIconButton
                  disabled={disableOnLoading}
                  save={saveAppLinkHandler}
                />

                <DeleteIconButton
                  disabled={disableOnLoading}
                  deleteHandler={hideCreateFieldsHandler}
                  item='ссылку'
                />
              </Box>
            </Collapse>
          )
        }

        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={errorMessage}
        />
      </List>
    </>
  )
}