import { useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { ModalInput } from "../../../../../shared/Style/Style";
import { robotsState } from "../../../state";

export const ArtifactPrice = () => {
    const artifact = useReactiveVar(robotsState.artifact);

    const handleValueChange = (value: number) => {
        robotsState.setArtifactPrice(value);
    };

    useEffect(() => {
        if (artifact?.isDefault) robotsState.setArtifactPrice(0);
    }, [artifact?.isDefault]);

    return (
        <ModalInput
            key={artifact?.id ?? "price"}
            disabled={artifact?.isDefault}
            type="number"
            variant="filled"
            margin="normal"
            label="Цена (в энергии)"
            value={artifact?.energyPrice ?? null}
            onChange={({ target: { value } }) => handleValueChange(Number(value))}
        />
    );    
};
