import React, { useState } from "react";
import { BlockInputLesson } from "../LessonBlocks/Style";
import { Box, Collapse, InputAdornment } from "@material-ui/core";
import { RadioButtonUnchecked } from "@material-ui/icons";
import { SaveIconButton } from "../../shared/Buttons/SaveIconButton";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { EditIconButton } from "../../shared/Buttons/EditIconButton";
import { DeleteIconButton } from "../../shared/Buttons/DeleteIconButton";
import { LanguageSwitch } from "../../shared/LanguageSwitch/LanguageSwitch";
import { languages } from "../../../constants/language";

export const CreatedListItem = ({
  listItem,
  disabled,
  updateListItem,
  content,
  setContent,
  chosenItemId,
  setChosenItemId,
  deleteListItem,
  index,
  isCreating,
  type,
}) => {
  const [language, setLanguage] = useState("UKR");

  return (
    <Box display="flex" alignItems="center">
      <BlockInputLesson
        fullWidth
        disabled={disabled || !chosenItemId || chosenItemId !== listItem.id}
        type={type}
        value={
          chosenItemId === listItem.id
            ? content[language]
            : listItem[`content${language}`]
        }
        onChange={({ target: { value } }) =>
          setContent({ ...content, [language]: value })
        }
        InputProps={{
          startAdornment: listItem.isNumbered ? (
            <InputAdornment position="end">{`${index + 1}. `}</InputAdornment>
          ) : (
            <RadioButtonUnchecked style={{ fontSize: "8px" }} />
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Collapse in={!disabled}>
                {chosenItemId === listItem.id ? (
                  <>
                    <SaveIconButton
                      save={() =>
                        updateListItem(
                          listItem.id,
                          content.UKR,
                          content.EN,
                          listItem.isNumbered
                        )
                      }
                      disabled={disabled}
                    />

                    <CloseIconButton
                      close={() => {
                        setContent({ EN: "", UKR: "" });
                        setChosenItemId("");
                      }}
                      disabled={disabled}
                      position="static"
                    />
                  </>
                ) : (
                  <>
                    <EditIconButton
                      edit={() => {
                        setChosenItemId(listItem.id);
                        setContent({
                          UA: listItem.contentUKR,
                          EN: listItem.contentEN,
                        });
                      }}
                      disabled={
                        disabled ||
                        isCreating ||
                        (!!chosenItemId && chosenItemId !== listItem.id)
                      }
                    />

                    <DeleteIconButton
                      disabled={
                        disabled ||
                        isCreating ||
                        (!!chosenItemId && chosenItemId !== listItem.id)
                      }
                      deleteHandler={() => deleteListItem(listItem.id)}
                      item="пункт списка"
                    />
                  </>
                )}
              </Collapse>
            </InputAdornment>
          ),
        }}
      />

      <LanguageSwitch
        switchLanguage={setLanguage}
        languages={languages}
        disabled={disabled || chosenItemId !== listItem.id}
      />
    </Box>
  );
};
