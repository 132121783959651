import React from "react";
import { MobileAppModalSMartNotification } from "./MobileAppModalSMartNotification";
import {
  GetSmartNotificationDocument,
  GetSmartNotificationQuery,
  SmartNotificationTypeEnum,
} from "../../../generated/graphql";
import { useMutation, useQuery } from "@apollo/client";

interface MobileAppModalSMartNotificationProps {
  applicationId: string;
  notificationId?: string;
  typeNotification: SmartNotificationTypeEnum;
  isOpen: boolean;
  modalType: string;
  close: () => void;
  setRequestError: (value: boolean) => void;
  setErrorMessage: (message: string) => void;
  handleFetch: ({
    variables,
  }: {
    variables: {
      smartNotificationData: {
        id: string;
        applicationId: string;
        description: string;
      };
      photo: File;
    };
  }) => void;
}

export const UpdateSmartNotification = ({
  applicationId,
  notificationId,
  typeNotification,
  isOpen,
  close,
  setRequestError,
  setErrorMessage,
  handleFetch,
  modalType,
}: MobileAppModalSMartNotificationProps) => {
  const {
    data: smartNotificationData,
    loading: smartNotificationLoading,
    error: smartNotificationError,
  } = useQuery<GetSmartNotificationQuery>(GetSmartNotificationDocument, {
    variables: {
      id: notificationId,
    },
  });

  const handleUpdateSmartNotification = async (
    description: string,
    photo: File
  ) => {
    try {
      await handleFetch({
        variables: {
          smartNotificationData: {
            id: notificationId,
            applicationId,
            description,
          },
          photo,
        },
      });
      close();
    } catch (e) {
      setRequestError(true);
      setErrorMessage("При обновлении обновления произошла ошибка");
    }
  };

  if (smartNotificationLoading) {
    return <div>...loading</div>;
  }

  const smartNotification = smartNotificationData?.getSmartNotification;

  return (
    <MobileAppModalSMartNotification
      modalType={modalType}
      isOpen={isOpen}
      close={close}
      notificationId={notificationId}
      smartNotification={smartNotification}
      handleFetch={handleUpdateSmartNotification}
      type={typeNotification}
    />
  );
};
