import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
} from "../../shared/Style/Style";

export const LessonModalTextField = ({
  open,
  closeModal,
  entity,
  disableOnLoading,
  createLesson,
}) => {
  const [nameLesson, setNameLesson] = useState("");

  return (
    <Dialog open={open}>
      <DialogContent style={{ minWidth: "400px", padding: "30px" }}>
        <CloseIconButton close={closeModal} />

        <Box textAlign="center" mb={2}>
          <DialogTitle>Название {entity}</DialogTitle>
        </Box>

        <TextField
          fullWidth
          variant="filled"
          label={`Введите название ${entity}`}
          value={nameLesson}
          onChange={({ target: { value } }) => setNameLesson(value)}
        />

        <ButtonsWrapper>
          <CancelButton onClick={closeModal} disabled={disableOnLoading}>
            Отмена
          </CancelButton>

          <ButtonCustom
            onClick={async () => {
              await createLesson(nameLesson);
              setNameLesson('')
              closeModal();
            }}
            disabled={disableOnLoading}
          >
            Создать
          </ButtonCustom>
        </ButtonsWrapper>
      </DialogContent>
    </Dialog>
  );
};
