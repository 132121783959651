import React, {
  forwardRef,
  memo,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Close,
  ClosePromo,
  DialogActionsStyle,
  Title,
  TitlePromo,
} from "../Login.style";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Slide,
  TextField,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { Field, Form } from "react-final-form";
import { validateForgotEmailField } from "../../../core/validators/validators";
import { ButtonCustom, useStyles } from "../../shared/Style/Style";
import { useMutation } from "@apollo/client";
import {
  ForgotPasswordDocument,
  ForgotPasswordMutation,
  IsFromEnum,
} from "../../../generated/graphql";

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface ForgotInterface {
  initialValue?: any;
  handleCloseForgot?: any;
  openForgot?: any;
  setSuccessForgot?: any;
}

export const Forgot = memo((props: ForgotInterface) => {
  const [error, setError] = useState("");
  const [email, { data, loading }] = useMutation<ForgotPasswordMutation>(
    ForgotPasswordDocument
  );
  const { initialValue, handleCloseForgot, openForgot, setSuccessForgot } =
    props;

  const handleClose = useCallback(() => handleCloseForgot, [openForgot]);

  useEffect(() => {
    if (data?.forgotPassword) {
      setSuccessForgot(true);
      handleCloseForgot(false);
    }
  }, [data?.forgotPassword]);

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={openForgot}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth={"lg"}
      >
        <TitlePromo>
          <Title>Восстановление пароля</Title>
          <ClosePromo>
            <IconButton>
              <Close onClick={handleClose()} />
            </IconButton>
          </ClosePromo>
        </TitlePromo>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Form onSubmit={() => {}} initialValues={initialValue}>
              {({ invalid, values }) => {
                return (
                  <Grid item xs={12}>
                    <Field name="email" validate={validateForgotEmailField}>
                      {({ input, meta }) => (
                        <TextField
                          className={classes.root}
                          error={(meta.error && meta.touched) || !!error}
                          margin="normal"
                          fullWidth
                          helperText={(meta.touched && meta.error) || error}
                          label="Email"
                          variant="filled"
                          type="email"
                          value={input.value}
                          onChange={(event) => {
                            setError("");
                            input.onChange(event.target?.value);
                            input.onBlur();
                          }}
                          onKeyDown={async (event) => {
                            if (
                              event.code === "Enter" ||
                              event.code === "NumpadEnter"
                            ) {
                              await email({
                                variables: {
                                  email: values.email,
                                  isFrom: IsFromEnum.AdminPanel,
                                },
                              });
                            }
                          }}
                        />
                      )}
                    </Field>
                    <DialogActionsStyle>
                      <ButtonCustom
                        disabled={loading || invalid}
                        onClick={async () => {
                          !values.email && setError("Обязательное поле");
                          !invalid &&
                            !error &&
                            values.email &&
                            (await email({
                              variables: {
                                email: values.email,
                                isFrom: IsFromEnum.AdminPanel,
                              },
                            }));
                        }}
                      >
                        Отправить
                      </ButtonCustom>
                    </DialogActionsStyle>
                  </Grid>
                );
              }}
            </Form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
});
