import React from "react";

import { Dialog, DialogContent, Box } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { CloseIconButton } from "../shared/Buttons/CloseIconButton";

import { ThemeProvider } from "@material-ui/styles";
import { ModalDialogTitle } from "../Payments/Payments.style";
import {
  ButtonCustomWithDarkText,
  ButtonsWrapper,
  CancelButton,
  defaultMaterialTheme,
  ModalInput
} from "../shared/Style/Style";

interface ICreateEpicModalProps {
  epicData: {
    name: string,
    startDateEstimate: Date | null,
    startDateFact: Date | null,
    finishDateEstimate: Date | null,
    finishDateFact: Date | null,
    statusId: string,
  };
  setEpicData(epicData: {
    name: string,
    startDateEstimate: Date | null,
    startDateFact: Date | null,
    finishDateEstimate: Date | null,
    finishDateFact: Date | null,
    statusId: string,
  }): void;
  disabled: boolean;
  open: boolean;
  nameError: boolean;
  closeHandler(): void;
  createHandler(): void;
  setNameError(nameError: boolean): void
}

export const CreateEpicModal = ({ setEpicData, epicData, disabled, open, closeHandler, createHandler, nameError, setNameError }: ICreateEpicModalProps) => {
  return (
    <Dialog
      open={open}
      transitionDuration={500}
    >
      <DialogContent>
        <CloseIconButton
          disabled={disabled}
          close={closeHandler}
        />
        <ModalDialogTitle>Создание этапа</ModalDialogTitle>

        <ModalInput
          fullWidth
          label='Название этапа'
          value={epicData.name}
          onChange={({ target: { value } }) => {
            setEpicData({...epicData, name: value});
            setNameError(false);
          }}
          error={nameError}
        />

        <ThemeProvider theme={defaultMaterialTheme}>
          <Box
            mt={2}
            minWidth={500}
            display='flex'
            justifyContent='space-between'
            alignContent='center'
          >
            <DatePicker
              label='Плановая дата начала'
              style={{ width: '200px' }}
              disabled={disabled}
              variant="inline"
              format="dd.MM.yyyy"
              maxDate={epicData.finishDateEstimate}
              value={epicData.startDateEstimate}
              onChange={(date) => setEpicData({...epicData, startDateEstimate: date})}
              autoOk={true}
            />

            <DatePicker
              label='Плановая дата конца'
              disabled={disabled}
              style={{ width: '200px' }}
              variant="inline"
              format="dd.MM.yyyy"
              minDate={epicData.startDateEstimate}
              value={epicData.finishDateEstimate}
              onChange={(date) => setEpicData({...epicData, finishDateEstimate: date})}
              autoOk={true}
            />
          </Box>

          <Box
            mt={2}
            minWidth={500}
            display='flex'
            justifyContent='space-between'
            alignContent='center'
          >
            <DatePicker
              label='Фактическая дата начала'
              style={{ width: '200px' }}
              disabled={disabled}
              variant="inline"
              format="dd.MM.yyyy"
              maxDate={epicData.finishDateFact}
              value={epicData.startDateFact}
              onChange={(date) => setEpicData({...epicData, startDateFact: date})}
              autoOk={true}
            />

            <DatePicker
              label='Фактическая дата конца'
              disabled={disabled}
              style={{ width: '200px' }}
              variant="inline"
              format="dd.MM.yyyy"
              minDate={epicData.startDateFact}
              value={epicData.finishDateFact}
              onChange={(date) => setEpicData({...epicData, finishDateFact: date})}
              autoOk={true}
            />
          </Box>
        </ThemeProvider>

        <ButtonsWrapper>
          <CancelButton
            onClick={closeHandler}
            disabled={disabled}
          >
            Отмена
          </CancelButton>

          <ButtonCustomWithDarkText
            active={true}
            disabled={disabled}
            onClick={createHandler}
          >
            Создать
          </ButtonCustomWithDarkText>
        </ButtonsWrapper>
      </DialogContent>
    </Dialog>
  )
}