import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  ClientSite,
  ClientSiteModel,
  DeleteClientSiteDocument,
  DeleteClientSiteModelDocument,
  DeleteClientSiteModelMutation,
  DeleteClientSiteMutation,
  GetClientSitesDocument,
  GetModelsByClientSiteIdDocument,
} from "../../../generated/graphql";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  DialogContentText,
} from "@material-ui/core";

import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";

import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
} from "../../shared/Style/Style";

interface IDeleteModalProps {
  open: boolean;
  close(): void;
  refetchVariables: {
    limit: number;
    skip: number;
    clientSiteId?: string;
  };
  item: ClientSite | ClientSiteModel;
}

export const DeleteModal = ({
  open,
  close,
  refetchVariables,
  item,
}: IDeleteModalProps) => {
  const [requestError, setRequestError] = useState(false);

  const [
    deleteClientSite,
    {
      data: deleteClientSiteData,
      loading: deleteClientSiteLoading,
      error: deleteClientSiteError,
    },
  ] = useMutation<DeleteClientSiteMutation>(DeleteClientSiteDocument, {
    variables: {
      id: item?.id,
    },
    refetchQueries: [
      { query: GetClientSitesDocument, variables: refetchVariables },
    ],
  });

  const [
    deleteClientSiteModel,
    {
      data: deleteClientSiteModelData,
      loading: deleteClientSiteModelLoading,
      error: deleteClientSiteModelError,
    },
  ] = useMutation<DeleteClientSiteModelMutation>(
    DeleteClientSiteModelDocument,
    {
      variables: {
        id: item?.id,
      },
      refetchQueries: [
        { query: GetModelsByClientSiteIdDocument, variables: refetchVariables },
      ],
    }
  );

  useEffect(() => {
    if (deleteClientSiteError || deleteClientSiteModelError) {
      setRequestError(true);
    }
  }, [deleteClientSiteError, deleteClientSiteModelError]);

  useEffect(() => {
    if (deleteClientSiteData || deleteClientSiteModelData) {
      close();
    }
  }, [deleteClientSiteData, deleteClientSiteModelData]);

  const disableOnLoading = deleteClientSiteLoading || deleteClientSiteModelLoading;

  return (
    <Dialog open={open}>
      <DialogContent style={{ minWidth: "400px" }}>
        <CloseIconButton close={close} />

        <Box textAlign="start" mb={2}>
          <DialogTitle style={{ padding: "0" }}>Удаление</DialogTitle>
        </Box>

        <DialogContentText style={{ color: "#000" }} align="center">
          При удалении этой страницы с сайта {item?.name} пропадут все
          3D-модели. Продолжить?
        </DialogContentText>

        <ButtonsWrapper>
          <CancelButton
            width={"150"}
            onClick={close}
            disabled={disableOnLoading}
          >
            отмена
          </CancelButton>

          <ButtonCustom
            width={"178"}
            onClick={() => {
              item.__typename === "ClientSiteModel"
                ? deleteClientSiteModel()
                : deleteClientSite();
            }}
            disabled={disableOnLoading}
          >
            удалить
          </ButtonCustom>
        </ButtonsWrapper>
      </DialogContent>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={`При удалении ${item?.name} произошла ошибка`}
      />
    </Dialog>
  );
};
