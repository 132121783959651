import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { primary, secondary } from "../../../shared/Style/Style";

export const Input = styled(TextField)`
  display: block;
  background-color: rgba(249, 249, 249, 1);
  width: 100%;

  & .MuiInputBase-input {
    height: 2em;
    padding-left: 0.5em;
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused {
      & .MuiOutlinedInput-notchedOutline {
        border-color: ${primary};
      }
    }
  }

  & .MuiInput-underline.Mui-error:after {
    border-bottom-color: red;
  }

  & .MuiInput-underline {
    width: 100%;

    &:before {
      border-bottom-color: #eee;
    }

    &:after {
      border-bottom-color: ${primary};
    }

    &:hover:not(.Mui-disabled):before {
      border-bottom-color: ${secondary};
    }
  }

  & .MuiFormLabel-root {
    color: ${secondary};
    &.Mui-Focused {
      color: ${secondary};
    }
  }
`;
