import React from "react";
import {Box} from "@material-ui/core";
import {
  Container,
  List,
  Subtitle,
  Text,
  Title,
  ListItem,
  WeightText,
  MarkerListItem,
} from "./PrivatePolicy.style";

export const PrivatePolicyUkr = () => (
  <Container>
    <Title>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</Title>

    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Ця Політика конфіденційності (далі — <strong>«Політика»</strong> ) є юридично обов’язковим документом і є
        частиною нашої угоди користувача, представляє собою правила збирання, використання Ваших
        персональних даних інтерактивною системою ArBook яка включає в себе такі продукти як ArBook,
        VR_Lab, AR_Teacher та AR_Book Market, всі згадані продукти є частиною інтерактивної системи та
        використовують цю Політику.
      </Text>
    </Box>

    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
       <strong> Товариство з обмеженою відповідальністю «АР БУК ЮА»</strong>, ЄДРПОУ: 44973818, в особі
        директора Головахінoї Ольги Миколаївни, що діє на підставі Статуту розробило цю Політику про
        нижчевикладене адже відповідально ставиться до питання конфіденційності інформації своїх користувачів
        і відвідувачів Платформи ArBook (далі —<strong>«Веб-сайт», «Платформа», «Сайт»</strong>).
      </Text>
      <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Визначення в цій політиці конфіденційності мають ті ж значення, що надані їм в Угоді користувача.
      </Text>
      </Box>
      <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Ця політика конфіденційності описує, чого можна очікувати від інтерактивної системи ArBook щодо своїх персональних даних, коли ми виступаємо у якості контролера даних. В цьому документів ми пояснимо наступні питання:
      </Text>
      </Box>

      <Box mt={3} >
      <List>
        <MarkerListItem>
          Що таке особиста інформація і її обробка?
        </MarkerListItem>
        <MarkerListItem>
          Яку інформацію ми збираємо?
        </MarkerListItem>
        <MarkerListItem>
          Підстави для використання даних.
        </MarkerListItem>
        <MarkerListItem>
          Як довго ми зберігаємо дані?
        </MarkerListItem>
        <MarkerListItem>
          З ким ми ділимось даними?
        </MarkerListItem>
        <MarkerListItem>
          Як ми захищаємо персональні дані?
        </MarkerListItem>
        <MarkerListItem>
        Безпека онлайн платежів.
        </MarkerListItem>
        <MarkerListItem>
          Cookies.
        </MarkerListItem>
        <MarkerListItem>
          Права Користувача відповідно до GDPR.
        </MarkerListItem>
        <MarkerListItem>
          Запити і термін для відповідей.
        </MarkerListItem>
        <MarkerListItem>
          Обмеження відповідальності.
        </MarkerListItem>
        <MarkerListItem>
          Сповіщення про порушення захисту даних.
        </MarkerListItem>
        <MarkerListItem>
          Зміни в цій Політиці конфіденційності.
        </MarkerListItem>
        <MarkerListItem>
          Контакти для зв’язку з нами.
        </MarkerListItem>
      </List>
      </Box>

      <Title>Що таке особиста інформація і її обробка?</Title>

      <Box mt={3} style={{textIndent:"20px"}}>
        <Text >
         <strong>Особиста інформація</strong> (або <strong>персональні дані</strong>) — це будь-яка інформація про Користувача, яка
            окремо або в поєднанні з іншими частинами інформації дозволяє ідентифікувати Користувача як
            особистість. Це можуть бути ім’я та прізвище, логін, назва компанії, адреса електронної пошти та
            ідентифікатор учасника, дані про місцезнаходження, IP-адреса пристрою Користувача тощо, це поняття
            не обмежується цим переліком прикладів.
          </Text>
      </Box>

      <Box mt={3} style={{textIndent:"20px"}}>
        <Text >
         <strong>Обробка</strong> інформації означає будь-які дії (операції) з особистими даними Користувача, наприклад,
            збір, запис, систематизація, накопичення, зберігання, уточнення (оновлення, зміна), вилучення,
            використання, передача (поширення, надання, доступ), знеособлення, блокування, видалення,
            знищення персональних даних, що здійснюються з використанням засобів автоматизації або без такого
            використання.
          </Text>
      </Box>
    </Box>

    <Title>Яку інформацію ми збираємо?</Title>

    <Box mt={3} style={{textIndent:"20px"}}>
      <Subtitle>Інформація з акаунтів Користувача.</Subtitle>
      <Text> Ми збираємо, обробляємо і зберігаємо дані, які Користувач добровільно нам надає при закінченні
        реєстрації для користування будь якого Продукту інтерактивної системи ArBook.
      </Text>
    </Box>
      <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        До таких даних, які збираються та обробляються Платформою належать:
      </Text>
      </Box>
      <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Повне ім’я та по батькові користувача, дата його народження, паролі, номер телефону, фотографії
        або інші зображення, завантажені в твоєму особистому профілі, дані про твоє місцезнаходження (місто,
        регіон, країна), місце роботи та посада, IP адреса пристрою користувача фіксується для забезпечення
        безпеки і для того, щоб приблизно дізнатися, де ви знаходитесь у світі, щоб персоналізувати свій досвід.
        Електрона адреса батьків користувача продукту Ar_book, якому не виповнилось 14 років.
      </Text>
      </Box>
      <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Платформою також опрацьовується інформація щодо статистики твого прогресу, кількість
        оплаченого контенту та історії платежів, у продуктах, що мають платний контент, а також твої дані, які ми
        можемо отримати з соціальних мереж, в яких ти зареєстрований, за умови реєстрації на нашій платформі за
        допомогою визначених соціальних мереж, тощо.
      </Text>
      </Box>
      <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Платформа, а зокрема продукт AR_Book використовує профільну камеру користувача,
        використовує систему розпізнавання обличчя Face tracking, яка, за згодою користувача, отримує доступ до
        фронтальної камери на пристрої та фіксує положення, розташування та рухи обличчя користувача. Додатку
        заборонено використовувати фронтальну камеру на пристрої без згоди користувача. Користувач, за
        бажанням, може вимкнути таку функцію в налаштуваннях. Система розпізнавання обличчя Face tracking не
        записує отриманні дані та не зберігає їх, функціонує виключно в режимі реального часу з метою допомоги
        користувачу під час використання додатку AR Book. Додаток при цьому отримує дані необхідні для свого
        належного функціонування, а також отримує супутню додаткову інформацію яка надходить внаслідок
        використання фронтальної камери на пристрої. Функція розпізнавання обличчя використовує спеціальні
        алгоритми на пристрої користувача для оброблення інформації від датчиків, для визначення положення
        обличчя користувача у фізичному просторі.
      </Text>
      </Box>

      <Box mt={2} style={{textIndent:"20px"}}>
        <Text>
          Велика частина інформації, яку ми збираємо, ми збираємо безпосередньо від тебе. Іноді ми можемо
          збирати особисті дані про тебе з інших джерел, таких як загальнодоступні матеріали чи довірені треті
          сторони, такі як наші партнери з маркетингу і досліджень. Ми використовуємо цю інформацію для
          доповнення вже наявних у вас персональних даних, щоб краще інформувати, персоналізувати та
          покращувати наш Додаток, а також для перевірки наданих вами персональних даних..
        </Text>
    </Box>
    <Box mt={2} style={{textIndent:"20px"}}>
      <Text>
        Частина цієї інформації збирається з використанням файлів cookie і аналогічних технологій
        відстеження, які детально описані в Політиці використання cookies-файлів AR_BOOK.
      </Text>
    </Box>
      <Box mt={2} style={{textIndent:"20px"}}>
        <Text>
          Тип інформації, яку ми збираємо, буде залежати від вашої взаємодії з Платформою.
        </Text>
      </Box>
      <Box mt={2} style={{textIndent:"20px"}}>
        <Text>
          За дозволом Користувача, платформа також отримує доступ до мікрофону пристрою з якого
          користувач безпосередньо користується продуктом платформи, та аудіофайлів на цьому пристрої.
        </Text>

        <Text>
          Платформа, у відповідності з політикою GOOGLE, може запитувати доступ до Google Диску Користувача для підвищення продуктивності та навчання, що використовує
          тільки обмежені області для опрацювання файлів Диска (чи їх методів або розширень) через інтерфейс користувача. Після надання Користувачем дозволу на 
          використання даних з Диска, Платформа отримує доступ до його файлів на Google Диску. Ми використовуємо отриману таким чином додаткову інформацію, щоб керувати,
          підтримувати, покращувати та надавати вам функції Платформи у повній мірі. Дана функція дозволить Платформі покращити взаємодію з Користувачем.
        </Text>
        
        <Text >
          Для здійснення доступу до Google Диску Платформа здійснює запит на отримання дозволу від Користувача отримати доступ до файлів на Диску. Платформа отримує 
          можливість інтегрувати в роботу Google Диск Користувача на весь час дії такого дозволу. В свою чергу Користувач може обмежити Платформу у інтеграції його 
          Диску в будь який момент.
        </Text>
      </Box>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Subtitle>Інформація про корпоративних-клієнтів.</Subtitle>
        <Text>
          У той же час, ми можемо збирати і обробляти платіжні реквізити наших корпоративних партнерів.
          Ці дані включають назву юридичної особи або приватного підприємця, його реєстраційні дані,
          включаючи реєстраційний номер, дату реєстрації, юридичні та поштові адреси, інвойсні дані про
          кількість та перелік оплачених послуг, розрахункові рахунки та інші банківські дані партнера, контактні
          телефони, а також дані представника або іншої уповноваженої особи такого клієнта.
        </Text>
      </Box>
    <Title>Підстави для використання даних.</Title>

    <Box mt={3} style={{textIndent:"20px"}}>
    <Text>
      Ми використовуємо дані з акаунта Користувача, так як він добровільно ними ділиться з нами, в
      зв’язку з чим ми вважаємо, що ти не заперечуєш проти збору і використання такої інформації. Своєю
      явною позитивною дією ти надаєш нам згоду на обробку цих даних.
    </Text>
  </Box>
    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Інтерактивна система ArBook збирає інформацію для наступних цілей:
      </Text>
    </Box>

    <Box mt={3} >
      <List style={{paddingLeft:"10px"}}>
        <MarkerListItem>
          ідентифікація тебе як Користувача Сайту;
        </MarkerListItem>
        <MarkerListItem>
          для підтвердження Користувачу доступу до акаунту;
        </MarkerListItem>
        <MarkerListItem>
          для визначення уподобань, інтересів та цілей, що в подальшому допомагатиме покращити твій
          досвід користувача при використані продуктів Платформі;
        </MarkerListItem>
        <MarkerListItem>
          для поширення маркетингової інформації, що стосується супровідних послуг, у тому числі розсилка
          електронних листів;
        </MarkerListItem>
        <MarkerListItem>
          для збору і аналізу статистики користування продуктами платформи.
        </MarkerListItem>
      </List>
    </Box>
    <Box mt={5} style={{textIndent:"40px"}}>
      <Text>
        Підставою для обробки даних наших співробітників/підрядників може слугувати:
      </Text>
    </Box>

    <Box mt={3} >
      <List style={{paddingLeft:"10px"}}>
        <MarkerListItem>
          необхідність виконання норм закону, наприклад, трудового законодавства або AML при здійсненні
          платежів;
        </MarkerListItem>
        <MarkerListItem>
          особиста згода;
        </MarkerListItem>
        <MarkerListItem>
          наявність законного інтересу у нашої компанії в такій обробці;
        </MarkerListItem>
        <MarkerListItem>
          необхідність виконання вже укладеного договору або необхідність укладення договору за запитом
          самого власника персональних даних.
        </MarkerListItem>
      </List>
    </Box>

    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Також ми використовуємо дані наших корпоративних партнерів відповідно до правил надання
        конкретних Послуг для виконання відповідних договірних зобов’язань. Така інформація необхідна для
        підготовки та виставлення рахунків.
      </Text>
    </Box>

    <Title>Як довго ми зберігаємо дані?</Title>

    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Ми не будемо зберігати дані Користувача довше, ніж це необхідно для виконання мети, для якої
        вони обробляються, або для дотримання встановлених законом вимог.
      </Text>
    </Box>
    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Оскільки ми зобов’язані адмініструвати твій акаунт, інформація зберігається як мінімум протягом
        терміну активності твого облікового запису на будь якому з продуктів ArBook, VR_Lab, AR_Teacher та
        AR_Book Market, як того вимагає мета обробки.
      </Text>
    </Box>
    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Також ми зберігаємо інформацію про наших співробітників/підрядників протягом всього періоду їх
        роботи з нами, після чого така інформація знищується, крім випадків неможливості її видалення
        (наприклад, дані з податкових документів, трудові відомості тощо)
      </Text>
    </Box>
    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Що стосується наших корпоративних клієнтів (партнерів), ми зберігаємо їх дані до моменту
        припинення наших правовідносин з ними, якщо інше не передбачено законодавством конкретної
        країни, або до тих пір, доки вони не забажають їх видалення, за умови, що це буде можливо.
      </Text>
    </Box>

    <Title>З ким ми ділимось даними?</Title>

    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Ми сподіваємось, що ти розумієш, що наша Платформа не функціонує самостійно і існує коло осіб,
        допущених до обробки персональних даних зі сторони інтерактивної системи ArBook.
      </Text>
    </Box>
    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Для досягнення цілей цього документа до обробки особистих даних допущені тільки ті наші
        підрядники/консультанти і співробітники, на яких покладено такий обов’язок відповідно до їх
        службових, трудових або договірних обов’язків.
      </Text>
    </Box>
    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Доступ до даних корпоративних партнерів є лише у наших співробітників, які безпосередньо
        взаємодіють з ними
      </Text>
    </Box>
    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        У той же час, доступ до даних про співробітників/підрядників має директор компанії, а також
        співробітники HR і фінансового відділів за їх за функціональними зобов’язаннями.
      </Text>
    </Box>

    <Title>Як ми захищаємо персональні дані?</Title>

    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Ми забезпечуємо як організаційні, так і технічні заходи захисту даних від незаконної або
        несанкціонованої обробки і від будь-яких випадкових втрат, або пошкоджень. Ці заходи безпеки
        постійно змінюються, щоб відповідати останнім технологічним досягненням.
      </Text>
    </Box>

    <Title>Організаційні заходи захисту персональних даних.</Title>

    <Box mt={3} >
      <List style={{paddingLeft:"10px"}}>
        <MarkerListItem>
          Ініціювали проведення систематичних інструктажів персоналу з роботи з особистими даними
          користувачів.
        </MarkerListItem>
        <MarkerListItem>
          Запровадили внутрішню документацію з правилами роботи з конфіденційною інформацією.
        </MarkerListItem>
        <MarkerListItem>
          Підписуємо з підрядниками Data Processing Agreement, в яких детально прописуємо права і
          обов’язки підрядників щодо захисту персональних даних.
        </MarkerListItem>
        <MarkerListItem>
          З співробітниками і підрядниками/консультантами додатково укладаємо договори про
          нерозголошення конфіденційної інформації.
        </MarkerListItem>
        <MarkerListItem>
          За функціональними обов’язками співробітників розмежували зони відповідальності, щоб
          виключити випадки перебування важливих даних у багатьох співробітників одночасно.
        </MarkerListItem>
      </List>
    </Box>

    <Title>Технічні заходи захисту персональних даних.</Title>

    <Box mt={3} >
      <List style={{paddingLeft:"10px"}}>
        <MarkerListItem>
          Використовуємо сертифікати для забезпечення безпеки даних Користувача та шифрування зв’язку.
        </MarkerListItem>
        <MarkerListItem>
          Запровадили хешування паролів для додаткової безпеки, щоб допомогти тобі уникнути
          несанкціонованого використання твого облікового запису.
        </MarkerListItem>
        <MarkerListItem>
          Всі доступи до баз даних здійснюються лише за довіреними IP
        </MarkerListItem>
      </List>
    </Box>

    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Ми не розкриваємо персоніфікованих даних одних відвідувачів Сайту іншим відвідувачам Сайту. Ми
        ніколи не публікуємо персоніфіковану інформацію у відкритому доступі і не передаємо її третім особам.
        Винятком є лише ситуації, коли надання такої інформації уповноваженим державним органам передбачено
        чинним законодавством. Ми публікуємо і поширюємо лише звіти, складені на основі зібраних анонімних
        даних. При цьому звіти не містять інформацію, за якою було б можливим ідентифікувати персоніфіковані
        дані користувачів послуг. Ми також використовуємо анонімні дані для внутрішнього аналізу, метою якого є
        розвиток продуктів і наших послуг.
      </Text>
    </Box>

    <Title>Безпека онлайн платежів.</Title>

    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Для повного доступу до всіх функцій та контенту на таких продуктах інтерактивної системи як
        AR_Teacher та AR_Book Market існує система онлайн платежів, які регламентуються нашою платіжною
        політикою.
      </Text>
    </Box>
    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Надана Користувачем персональна інформація (ім’я, адреса, телефон, e-mail, номер кредитної картки) є
        конфіденційною і не підлягає розголошенню. Твої дані банківської картки передаються лише в
        зашифрованому вигляді і не зберігаються на нашому Web-сервері.
      </Text>
    </Box>
    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Ми рекомендуємо Користувачу згаданих продуктів перевірити, чи твій браузер досить безпечний для
        проведення платежів онлайн, на спеціальній сторінці.
      </Text>
    </Box>
    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Всі операції з платіжними картками відбуваються відповідно до вимог VISA International, MasterCard та
        інших платіжних систем. При передачі інформації використовуються спеціальні технології безпеки
        карткових онлайн-платежів, обробка даних ведеться на безпечному високотехнологічному сервері
        процесингової компанії.
      </Text>
    </Box>

    <Title>Cookies.</Title>

    <Box mt={3} style={{textIndent:"20px"}}>
      <Text>
        Як і багато інших веб-сайтів, всі наші продукти на базі Платформи ArBook використовують файли
        cookies. Це невеликі файли даних, які виступають ідентифікаторами технічного відстеження з метою обліку
        статистики, маркетингу, аналітики тощо. У будь-який час ти можеш змінити налаштування файлів cookie,
        використовуючи налаштування свого браузера. Більш детальна інформація знаходиться в нашій <strong>Політиці
        використання файлів cookies.</strong>
      </Text>
    </Box>

    <Title>права Користувача відповідно до GDPR.</Title>

    <Box mt={3} >
      <List style={{paddingLeft:"10px"}}>
        <MarkerListItem>
          Право на доступ до власних даних.
        </MarkerListItem>
        <MarkerListItem>
          Право на виправлення.
        </MarkerListItem>
        <MarkerListItem>
          Право на видалення («право бути забутим»).
        </MarkerListItem>
        <MarkerListItem>
          Право на обмеження обробки.
        </MarkerListItem>
        <MarkerListItem>
          Право на відкликання згоди.
        </MarkerListItem>
        <MarkerListItem>
          Право на заперечення проти обробки.
        </MarkerListItem>
        <MarkerListItem>
          Право на переносимість даних.
        </MarkerListItem>
      </List>
    </Box>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Subtitle>Право на доступ до власних даних.</Subtitle>
      <Text>
        Користувач має право знати, як його особисту інформацію обробляє інтерактивна система ArBook, і
        отримати деталі такої обробки.
      </Text>
    </Box>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Text>
        Це означає, що у тебе є право попросити нас надати тобі підтвердження того, чи обробляються
        компанією твої персональні дані, а також отримати повну інформацію про умови такої обробки, тобто про
        цілі збору і обробки, період обробки, третіх осіб, які мають доступ до інформації.
      </Text>
    </Box>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Subtitle>Право на виправлення.</Subtitle>
      <Text>
        На нашому сайті ти також самостійно можеш заповнити і змінити цю інформацію в твоєму особистому
        профілі користувача
      </Text>
    </Box>
    <Box mt={2} style={{textIndent:"20px"}}>
      <Text>
        Це означає, що у тебе є право попросити нас надати тобі підтвердження того, чи обробляються
        компанією твої персональні дані, а також отримати повну інформацію про умови такої обробки, тобто про
        цілі збору і обробки, період обробки, третіх осіб, які мають доступ до інформації.
      </Text>
    </Box>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Subtitle>Право на видалення твоїх даних («право бути забутим»).</Subtitle>
      <Text>
        Користувач може звернутися до Платформи з проханням видалити його персональні дані, за умови, що
        їх обробка неправомірна, або якщо обробка даних більше не потрібна для досягнення цілей, для яких вони
        були зібрані або наявні інші підстави, передбачені правилами GDPR.
      </Text>
    </Box>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Subtitle>Право на обмеження обробки.</Subtitle>
      <Text>
        Користувач має право вимагати обмеження обробки своїх персональних даних у випадках,
        передбачених чинним законодавством, а саме положень, передбачених статтею 18 GDPR.
      </Text>
    </Box>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Subtitle>Право на відкликання твоєї згоди.</Subtitle>
      <Text>
        Якщо ми обробляємо твої персональні дані на підставі твоєї згоди, ти маєш право звернутися до нас для
        відкликання такої згоди на відповідну обробку. Для цього достатньо написати нам за адресою:
        support@arbook.info
      </Text>
    </Box>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Subtitle>Право на заперечення проти обробки.</Subtitle>
      <Text>
        Це означає, що Користувач має право заперечувати проти обробки своїх даних для використання в
        маркетингу, соціально-демографічному профілюванні та інших активностях, передбачених статтею 21
        GDPR.
      </Text>
    </Box>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Subtitle>Право на переносимість даних.</Subtitle>
      <Text>
        Ти маєш право отримувати свої персональні дані, які ти надав нам як контролеру в структурованому,
        універсальному і машиночитабельному форматі, ти маєш право передати зазначені дані іншому контролеру
        за умови, що це технічно можливо і відсутні обмеження, зазначені в статті 20 GDPR.
      </Text>
    </Box>

    <Title>Запити і термін для відповідей.</Title>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Text>
        Наша Платформа докладе всіх розумних зусиль для своєчасного розгляду твоїх запитів, що стосуються
        прав, описаних вище, однак ти повинен очікувати нашої відповіді не пізніше, ніж через місяць з дати
        подання такого запиту. Наш час для відповіді може бути продовжений ще на два місяці, якщо у системі
        ArBook буде багато запитів від користувачів. Платформа проінформує тебе про таке продовження протягом
        одного місяця з моменту отримання запиту разом з причинами затримки.
      </Text>
    </Box>

    <Title>Обмеження відповідальності.</Title>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Text>
        Ми робимо все можливе для виконання положень цієї політики конфіденційності, однак, ми не можемо
        гарантувати збереження інформації у разі дії факторів, що знаходяться поза нашим впливом, результатом
        дії яких стане розкриття інформації. Сайт і вся розміщена на ньому інформація представлені за принципом
        «як є» без будь-яких гарантій. Ми не несемо відповідальність за несприятливі наслідки, а також за будь-які
        збитки, завдані внаслідок обмеження доступу до сайту або внаслідок його відвідування і використання
        розміщеної на ньому інформації.
      </Text>
    </Box>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Text>
        Разом з тим, наш Сайт може містити посилання на інші онлайн-ресурси, що не мають відношення до
        нашої компанії і належать третім особам. Ми не несемо відповідальність за точність, повноту і
        достовірність відомостей, розміщених на сайтах третіх осіб, і не беремо на себе ніяких зобов’язань щодо
        збереження конфіденційності інформації, залишеної тобою на таких сайтах. У цьому випадку, будь ласка,
        ознайомся з правилами користування та політикою конфіденційності зазначених онлайн-ресурсів.
      </Text>
    </Box>

    <Title>Сповіщення про порушення захисту твоїх даних.</Title>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Text>
        У разі порушення захисту твоїх персональних даних або наявності високого ризику порушення твоїх
        прав в якості суб’єкта даних, ми негайно повідомляємо про це тебе і компетентну наглядову установу із
        захисту даних. Ми також докладаємо зусиль, щоб зменшити будь-які такі ризики.
      </Text>
    </Box>

    <Title>Зміни в цій Політиці конфіденційності.</Title>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Text>
        Будь-які зміни в Політиці конфіденційності будуть опубліковані на Сайті із зазначенням дати.
      </Text>
    </Box>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Text>
        Якщо ти продовжуєш використовувати продукт нашої Платформи після внесення змін до цього
        документа, ми будемо розглядати це як прийняття таких змін.
      </Text>
    </Box>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Text>
        Якщо Користувач не згоден зі змінами, йому слід припинити використання нашої Платформи. Якщо
        зміни будуть настільки значними, що нам буде потрібно знову запитати твоєї згоди, ми зробимо це.
      </Text>
    </Box>

    <Title>Контакти для зв’язку з нами.</Title>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Text>
        Ми сподіваємося, що ця Політика конфіденційності допомогла тобі зрозуміти, як обробляється твоя
        особиста інформація продуктами ArBook, VR_Lab, AR_Teacher та AR_Book Market, кожен з яких є
        частиною інтерактивної системи ArBook.
      </Text>
    </Box>

    <Box mt={2} style={{textIndent:"20px"}}>
      <Text>Якщо у тебе залишилися питання, будь ласка, напиши нам за адресою
        <a href="support@arbook.info"> support@arbook.info.</a>.
      </Text>
    </Box>
  </Container>
);
