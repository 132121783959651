import { NavBar } from "../../shared/NavBar";
import { Container } from "@material-ui/core";
import {
  Header,
} from "../../shared/Style/Style";
import { NAV_ITEMS, REPORTS_AR_BOOK_TITLE } from "../constants";
import { ReactNode } from "react";

interface ReportsContainerProps {
  children?: ReactNode | ReactNode[] | null;
}

export const ReportsContainer = ({ children }: ReportsContainerProps) => {
  return (
    <Container maxWidth="lg">
      <Header>{REPORTS_AR_BOOK_TITLE}</Header>
      <NavBar items={NAV_ITEMS} />
      {children}
    </Container>
  );
};
