import React from 'react';
import {Box, Dialog, DialogContent} from "@material-ui/core";
import {CloseIconButton} from "../Buttons/CloseIconButton";
import {PreviewImage} from '../Style/Style';

interface ModalPreviewPhoto {
  isOpen: boolean,
  close: (value: boolean) => void
  imageUrl: string | ArrayBuffer
}

export const ModalPreviewPhoto = ({isOpen, close, imageUrl}) => (
  <Dialog open={isOpen}>
    <DialogContent style={{ minWidth: "400px", padding: "60px" }}>
      <CloseIconButton close={() => close(false)} />
      <Box>
        <PreviewImage src={imageUrl} />
      </Box>
    </DialogContent>
  </Dialog>
)