import { useQuery } from "@apollo/client";
import {
  GetArBookUsersCountLightDocument,
  GetArBookUsersCountLightQuery,
} from "../../generated/graphql";

export const useGetArBookUsersCountLight = () => {
  const { data, loading, error } = useQuery<GetArBookUsersCountLightQuery>(
    GetArBookUsersCountLightDocument,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  return {
    getARBookUsersCountLight: data?.getARBookUsersCountLight,
    getArBookUsersCountLightLoading: loading,
    getArBookUsersCountLightError: error,
  };
};
