import { Dialog } from "@material-ui/core";
import { LoadingProgress } from "./LoadingProgress";

export const LoaderProps = {
  style: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
    Position: 'relative',
    top: "40%",
    left: 0,
    width: '100%',
    height: '100%'
  },
};

export const LoadingProgressOverlay = () => {
  return (
    <Dialog
      open
      PaperProps={LoaderProps}
    >
      <LoadingProgress />
    </Dialog>
  )
};
