import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import {
  GetDateReportByEpicQuery,
  GetDateReportByEpicDocument,
} from "../../../../generated/graphql";
import { IProjectReportModalContentProps } from "../ProjectReportModal";

import {
  Table,
  TableRow,
  TableBody,
  CircularProgress
} from "@material-ui/core";
import {
  CustomTableHead,
  CustomTableHeaderCell,
  CustomTableCell,
  ColorizedTableCell,
  CustomTableRow
} from "../../../shared/CustomTable/CustomTable.style";

import { LoadingBox } from "../../../shared/Style/Style";
import { CustomTable } from "../../../shared/CustomTable";
import { DropdownArrowButton } from "../../../shared/Buttons/DropdownArrowButton";
import { ErrorMessageSnackbar } from "../../../shared/ErrorMessageSnackbar";

import {
  convertDateStringToLocaleHandler
} from "../../../shared/Utils/ConvertOperations/convertDateStringToLocaleHandler";
import { choseOrToggleIdHandler } from "../../../shared/Utils/StateOperations/choseOrToggleId";

export const DifferenceInDateContent = ({ epics, dateRange }: IProjectReportModalContentProps) => {
  const headers = [
    'Этап',
    'Задача',
    'Ответственный',
    'Статус',
    'Дата начала',
    'Разница',
    'Дата конца',
    'Разница'
  ];

  const headersSecondRow = [
    '',
    'План',
    'Факт',
    '',
    'План',
    'Факт',
    '',
  ];

  const [chosenEpic, setChosenEpic] = useState('');
  const [requestError, setRequestError] = useState(false);

  const [getTasks, { data, loading, error }] = useLazyQuery<GetDateReportByEpicQuery>(
    GetDateReportByEpicDocument, {
      variables: {
        epicKey: chosenEpic,
        ...dateRange
      }
    }
  );

  useEffect(() => {
    if (chosenEpic) {
      getTasks();
    }
  }, [chosenEpic]);

  useEffect(() => {
    if (error) {
      setRequestError(true);
    }
  }, [error]);

  let tasksList: JSX.Element | JSX.Element[];
  let epicStartDifference: number;
  let epicFinishDifference: number;

  if (loading) {
    tasksList = (
      <CustomTableCell colSpan={7}>
        <LoadingBox>
          <CircularProgress color='inherit'/>
        </LoadingBox>
      </CustomTableCell>
    )
  }

  if (data) {
    const tasks = data.getDateReportByEpic.tasks
    epicStartDifference = data.getDateReportByEpic.totalStartDifference;
    epicFinishDifference = data.getDateReportByEpic.totalFinishDifference;

    tasksList =
      tasks.length
        ? tasks.map(task => (
          <TableRow key={task.id}>
            <CustomTableCell />
            {[
              task.name,
              task.assigneeName,
              task.status,
              convertDateStringToLocaleHandler(task.startDateEstimate),
              convertDateStringToLocaleHandler(task.startDateFact),
            ].map((field, i) => (
              <CustomTableCell
                key={`${i} ${field}}`}
              >
                {field}
              </CustomTableCell>
            ))}

            <ColorizedTableCell
              number={task.startDifference}
            >
              {task.startDifference}
            </ColorizedTableCell>

            {[
              convertDateStringToLocaleHandler(task.finishDateEstimate),
              convertDateStringToLocaleHandler(task.finishDateFact),
            ].map((field, i) => (
              <CustomTableCell
                key={`${i} ${field}}`}
              >
                {field}
              </CustomTableCell>
            ))}

            <ColorizedTableCell
              number={task.finishDifference}
            >
              {task.finishDifference}
            </ColorizedTableCell>
          </TableRow>
        ))
        : (
          <TableRow>
            <CustomTableCell colSpan={10}>У данного этапа отсутствуют задачи</CustomTableCell>
          </TableRow>
        )
  }

  const content = (
    <>
      <Table>
        <CustomTableHead>
          <TableRow>
            {headers.map((header, i) => (
              <CustomTableHeaderCell
                colSpan={header.includes('Дата') ? 2 : 1}
                width={150}
                key={`${i} ${header}`}
              >
                {header}
              </CustomTableHeaderCell>
            ))}
          </TableRow>

          <TableRow>
            {headersSecondRow.map((header, i) => (
              !header
                ? <CustomTableCell colSpan={i === 0 ? 4 : 1}/>
                : (
                    <CustomTableHeaderCell
                      key={`${i} ${header}`}
                    >
                      {header}
                    </CustomTableHeaderCell>
                  )
            ))}
          </TableRow>
        </CustomTableHead>

        <TableBody>
          {epics.map(epic => (
            <>
              <CustomTableRow
                clickable={true}
                key={epic.epicKey}
                onClick={() => choseOrToggleIdHandler(epic.epicKey, chosenEpic, setChosenEpic)}
              >
                <CustomTableCell>
                  {epic.epicName}

                  <DropdownArrowButton
                    expanded={chosenEpic === epic.epicKey}
                  />
                </CustomTableCell>

                <CustomTableCell colSpan={5} />

                <ColorizedTableCell
                  number={chosenEpic === epic.epicKey ? epicStartDifference : 0}
                >
                  {chosenEpic === epic.epicKey && epicStartDifference}
                </ColorizedTableCell>

                <CustomTableCell colSpan={2} />

                <ColorizedTableCell
                  number={chosenEpic === epic.epicKey ? epicFinishDifference : 0}
                >
                  {chosenEpic === epic.epicKey && epicFinishDifference}
                </ColorizedTableCell>
              </CustomTableRow>
              {chosenEpic === epic.epicKey && tasksList && (
                tasksList
              )}
            </>
          ))}
        </TableBody>
      </Table>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message='При загрузке задач произошла ошибка'
      />
    </>
  )

  return <CustomTable data={data?.getDateReportByEpic?.tasks || epics} tableContent={content} />
}
