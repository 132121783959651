import { gql } from '@apollo/client'

export const CREATE_SMART_NOTIFICATION = gql`
    mutation createSmartNotification($smartNotificationData: CreateSmartNotificationInput!, $photo: Upload,$video: Upload) {
        createSmartNotification(smartNotificationData: $smartNotificationData, photo: $photo,video:$video) {
            id
        }
    }
`

export const UPDATE_SMART_NOTIFICATION = gql`
    mutation updateSmartNotification($smartNotificationData: UpdateSmartNotificationInput!, $photo: Upload) {
        updateSmartNotification(smartNotificationData: $smartNotificationData photo: $photo) {
            id
        }
    }
`

export const DELETE_SMART_NOTIFICATION = gql`
    mutation deleteSmartNotification($id: String!) {
        deleteSmartNotification(id: $id)
    }
`

export const PUBLISH_SMART_NOTIFICATION = gql`
    mutation publishSmartNotification($id: String!) {
        publishSmartNotification(id: $id)
    }
`

export const UNPUBLISH_SMART_NOTIFICATION = gql`
    mutation unpublishSmartNotification($id: String!) {
        unpublishSmartNotification(id: $id)
    }
`

export const DELETE_SMART_NOTIFICATION_PHOTO = gql`
    mutation deleteSmartNotificationPhoto($id: String!) {
        deleteSmartNotificationPhoto(id: $id)
    }
`
export const SET_SMART_NOTIFICATION_PARTICIPANTS = gql`
mutation setSmartNotificationParticipants($notificationData:SetSmartNotificationParticipantsInput!){
setSmartNotificationParticipants(notificationData:$notificationData)
}
`;