import { ErrorsState, StateType } from "./Modal/types";

export const INITIAL_STATE: StateType = {
  title: "",
  description: "",
  video: "",
  isFinished: false,
  routeId: "",
}

export const INITIAL_ERRORS: ErrorsState = {
  title: "",
  description: "",
  video: "",
}

export const PUBLISHED = {
  name: "Опубликованно",
  className: "published"
}

export const UNPUBLISHED = {
  name: "Не опубликованно",
  className: "unpublished"
}

export const MENU_ITEM_STATUS = {
  publish: "Опубликовать",
  unPublish: "Снять с публикации",
}

export const tooltipTitle = 'Алгоритм вставки посилання YouTube правильного формату: відкрити Youtube відео → Поділитися → Вставити відео. Копіювати посилання після символів src=""'

