import styled from "styled-components";

export const HoverNameContainer = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 4px;
  padding: 5px;
  background-color: #fff;
  opacity: 0.7;
`;
