import {Item} from "./types";
import {INITIAL_DATA} from "./constants";

export const getItemData = (item: Item): { [key: string]: string } => {
  switch (item?.__typename) {
    case "Application":
      return {
        id: item.id,
        name: item.name,
        entityName: "приложения",
      };

    case "ApplicationMain":
      return {
        id: item.id,
        name: item.name,
        entityName: "страницы приложения",
      };

    case "ApplicationMainModel":
      return {
        id: item.id,
        name: item.name,
        entityName: "модели",
      };

    case "MobileNotification":
      return {
        id: item.id,
        name: item.name,
        entityName: "уведомления",
      };
    
    case "Award":
  	  return {
  	    id: item.id,
  	    name: item.name,
  	    entityName: "награды",
      };
    
    case "GameModel":
      return {
        id: item.id,
        name: item.name,
        entityName: "робота",
      };

    case "GameModelState":
      return {
        id: item.id,
        name: item.name,
        entityName: "стадии",
      };
    
    case "GameModelArtifact":
      return {
        id: item.id,
        name: item.name,
        entityName: "артефакта",
      };
    
    default:
      return INITIAL_DATA;
  }
};

export const getErrorArtifactMessage = (error: string) => {
  if (error?.includes('is still referenced from table "user_artifact_purchase"'))
    return "Нельзя удалить купленный артефакт";
};
