import styled from 'styled-components';
import { IconButton, Input, Button, withStyles, InputProps, List, DialogTitle } from '@material-ui/core';
import { lightBlack, primary, secondary } from '../shared/Style/Style';
import { DatePicker } from '@material-ui/pickers';

export const ErrorMassage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  box-sizing: border-box;
  text-align: center;
`;

interface IPrimaryButtonProps {
  creating?: boolean;
  editing?: boolean;
  published?: boolean | null;
}

export const PrimaryButton = styled(Button)<IPrimaryButtonProps>`
  width: 140px;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  background-color: ${props => {
    if (props.editing) {
      return '#2b8eff';
    }

    if (props.creating) {
      return '#008106';
    }

      return props.published ? '#000' : '#FFB61D';
    }
  };
  margin: 3px 6px;
  box-sizing: border-box;
  transition: opacity 0.3s, background-color 0.3s;

  &:hover {
    background-color: ${props => {
    if (props.editing) {
      return '#2b8eff';
    }

    if (props.creating) {
      return '#008106';
    }

      return props.published ? '#000' : '#FFB61D';
    }};
    opacity: ${props => props.published && !props.editing ? 1 : 0.6};
  }

  &.Mui-disabled {
    color: ${secondary};
  }
`;

export const PaymentDatePickerInput = withStyles({
  root: {
    color: '${lightBlack}',
    height: '100%',
    padding: '10px',
    border: (props: InputProps ) => props.disabled ? '1px solid transparent' : '1px solid #FFB61D',

    '&::before': {
      content: 'none',
    },

    '&::after': {
      content: 'none',
    },

    "& .MuiInputBase-input": {
      textAlign: 'center',
    },

    "& .MuiInputBase-input.Mui-disabled": {
      color: "${lightBlack}",
    },
  }
})(Input);

export const ControlWrapper = styled.div`
  margin-bottom: 20px;
  text-align: right;
`;

export const InvoicesList = styled(List)`
  padding: 0;
`;

export const Invoice = styled.li`
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-of-type {
    padding: 5px 0 0;
  }
`;

export const InvoiceLink = styled.a`
  color: ${lightBlack};
  text-decoration: none;
`;

export const DeleteInvoiceBtn = styled(IconButton)`
  color: red;
`;

export const AddInvoicesBtn = styled(IconButton)`
  color: ${primary};
  display: block;
  margin: 0 auto;
  padding: 6px;
`;

export const ModalDialogTitle = styled(DialogTitle)`
  text-align: center;
`;

export const CustomDatePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    min-height: 100%;
    padding: 15px 10px;
    text-align: center;
    border: 1px solid ${primary};
    cursor: pointer;
  }

  & .MuiInput-underline {
    &::before,
    &::after {
      content: none;
    }
  }

  & .MuiPickersCalendarHeader-switchHeader {
    background-color: ${primary};
  }
`;

