import { useReactiveVar } from "@apollo/client";
import { robotsState } from "../state";
import { ArtifactNamesMap } from "../maps";
import { ArtifactsNameEnum } from "../types";

export const useGetArtifactsTypes = () => {
    const artifactClothTypes = useReactiveVar(robotsState.artifactClothTypes);

    const getArtifactTypes = (value: ArtifactsNameEnum) => {
        const type = ArtifactNamesMap.get(value);
        const clothTypeId = artifactClothTypes?.find(item => item?.displayName === value)?.id;

        return { type, clothTypeId };
    };

    return getArtifactTypes;
};
