import { useEffect, useRef, useState } from "react";
import { useGetValidatedUserInvitationsList, useInviteUsersByList } from "./hooks";
import { setErrorMessage } from "../utils";
import { initialFileData, initialUploadStatus } from "../constants";
import { ImportModalContainer } from "../Style";
import {Dialog, Snackbar} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
  ModalInput,
  ImportFileUpload,
} from "../../shared/Style/Style";
import {CloseIconButton} from "../../shared/Buttons/CloseIconButton";
import { LoadingProgressOverlay } from "../../shared/LoadingProgress";

interface MobileAppModalProps {
  open: boolean;
  close(): void;
  successNotification(): void;
  schoolId: string;
}

export const ImportNotificationModal = ({ open, close, successNotification, schoolId }: MobileAppModalProps) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const [currentFile, setCurrentFile] = useState(initialFileData);
  const [uploadStatus, setUploadStatus] = useState(initialUploadStatus);

  const {
    validateUserInvitationsList,
    validateUserListData,
    validateUserListLoading,
    validateUserListError
  } = useGetValidatedUserInvitationsList();

  const {
    inviteUsersByList,
    inviteUsersByListData,
    inviteUsersByListLoading,
    inviteUsersByListError
  } = useInviteUsersByList(currentFile.file, schoolId);

  const validationResult = validateUserListData?.validateUserInvitationsList || null;
  const inviteUsersResult = inviteUsersByListData?.inviteUsersByList || null;
  const isLoading = validateUserListLoading || inviteUsersByListLoading;
  const isCurrentFileEmpty = currentFile === initialFileData;
  const isSubmitDisabled = isCurrentFileEmpty || validationResult?.success === false;

  useEffect(() => {
    if (currentFile !== initialFileData) {
      validateUserInvitationsList({ variables: { list: currentFile.file } });
    }
  }, [currentFile]);
  
  useEffect(() => {
    if (validationResult && !validateUserListLoading) {
      const { success, errors } = validationResult;
      if (success) {
        setUploadStatus({ status: true, message: "Файл готовий до відправлення", variant: 'success' })
      };
      if (!success && errors) {
        setUploadStatus({ status: true, message: "Виправте та повторно завантажте файл: " + setErrorMessage(errors), variant: 'error' })
      };
    }
    
    if (validateUserListError) {
      setUploadStatus({ status: true, message: "При відправленні файлу виникла помилка: " + validateUserListError.message, variant: 'error' })
    }
  }, [validateUserListError, validateUserListLoading, validationResult]);
  
  useEffect(() => {
    if (inviteUsersResult?.length === 0) {
      close();
      successNotification();
    }

    if (inviteUsersResult?.length > 0) {
      setUploadStatus({ status: true, message: setErrorMessage(inviteUsersResult), variant: 'info' })
    }
    
    if (inviteUsersByListError) {
      setUploadStatus({ status: true, message: "При відправленні файлу виникла помилка: " + inviteUsersByListError.message, variant: 'error' })
    }
  }, [inviteUsersResult, inviteUsersByListError]);
  
  const handleDrop = (file: File) => {
    if (file.type === "text/csv") {
      setCurrentFile({ file, name: file.name });
    } else {
      setUploadStatus({ status: true, message: "Неправильний формат файла, для завантаження використовуйте .csv", variant: 'error' })
    };
 
    if (fileInput.current) { fileInput.current.value = ''; }
  };
  
  const handleCancel = () => {
    if (isCurrentFileEmpty) close();
    setCurrentFile(initialFileData);
  };

  const handleSubmit = async () => {
    try {
      await inviteUsersByList();
    } catch (e) {
      console.error(e);
    }
  };

  const notification = uploadStatus.message.split('\n').map((item, i) =>
    <div key={i}>{item}</div>) || 'Невідома помилка';
  
  return (
    <>
      <Dialog open={open}>
        <ImportModalContainer>
          {isLoading && <LoadingProgressOverlay />}

          <CloseIconButton close={close} />

          <ButtonsWrapper>
            <ModalInput variant="filled" type="text" placeholder={currentFile.name} disabled />
            <ButtonCustom onClick={() => fileInput.current.click()}>
              <span>Выбрать</span>
              <ImportFileUpload
                type="file"
                accept="text/csv"
                ref={fileInput}
                onChange={({ target: { files } }) => {
                  files[0] && handleDrop(files[0]);
                }}
              />
            </ButtonCustom>
          </ButtonsWrapper>

          <ButtonsWrapper>
            <CancelButton onClick={handleCancel}>
              Отменить
            </CancelButton>

            <ButtonCustom
              disabled={isSubmitDisabled}
              onClick={handleSubmit}
            >
              Отправить
            </ButtonCustom>
          </ButtonsWrapper>
        </ImportModalContainer>
      </Dialog>

      <Snackbar
        open={uploadStatus.status}
        onClose={() => setUploadStatus(initialUploadStatus)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity={uploadStatus.variant}
          onClose={() => setUploadStatus(initialUploadStatus)}
        >
          {notification}
        </Alert>
      </Snackbar>
    </>
  );
};
