import {KnowledgeBaseBlockTypeEnum, LessonBlockTypeEnum} from "../../../../generated/graphql";

export const getFontSizeOfBlockInput = (type?: KnowledgeBaseBlockTypeEnum): string => {
  switch (type) {
    case KnowledgeBaseBlockTypeEnum.Header:
      return '36px';

    case KnowledgeBaseBlockTypeEnum.Subtitle:
      return '24px';

    default:
      return 'inherit';
  }
}

export const getFontSizeOfBlockInputLesson = (type?: LessonBlockTypeEnum): string => {
  switch (type) {
    case LessonBlockTypeEnum.Header:
      return '36px';

    case LessonBlockTypeEnum.Subtitle:
      return '24px';

    default:
      return 'inherit';
  }
}