export const getWeekDatesRanges = (maxDate: Date = new Date()): string[] => {
  const rangesArray: string[] = [];
  const millisecondsInDay = 24 * 60 * 60 * 1000;
  const dayOrder = maxDate.getDay();

  let sunday;

  if (dayOrder > 0) {
    sunday = new Date(maxDate.getTime() + (7 - dayOrder) * millisecondsInDay);
  } else {
    sunday = maxDate;
  }

  let range = 6;

  while (range > 0) {
    const year = sunday.getFullYear();
    const month = sunday.getMonth() + 1  >= 10 ? sunday.getMonth() + 1 : `0${sunday.getMonth() + 1}`;
    const day = sunday.getDate() >= 10 ? sunday.getDate() : `0${sunday.getDate()}`;

    rangesArray.unshift(`${year}-${month}-${day}T00:00:00.000Z`);

    sunday = new Date(sunday.getTime() - 7 * millisecondsInDay);

    range--;
  }

  return rangesArray;
}
