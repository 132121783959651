import React, { useState, useEffect } from "react";

import { useMutation, useQuery } from "@apollo/client";
import {
  CreateUserAgreementDocument,
  CreateUserAgreementMutation,
  DeleteUserAgreementDocument,
  DeleteUserAgreementMutation,
  GetUserAgreementsDocument,
  GetUserAgreementsQuery,
  UpdateUserAgreementDocument,
  UpdateUserAgreementMutation
} from "../../../../generated/graphql";

import {  CircularProgress, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { LoadingBox } from "../../../shared/Style/Style";
import { SettingsList } from "../SettingsList";
import { SettingsCreateItemForm } from "../SettingsCreateItemForm";

import { Content } from "../Settings.style";


export const Agreement = () => {
  const [inputError, setInputError] = useState(false);
  const [requestError, setRequestError] = useState(false);

  const [isCreating, setCreating] = useState(false);
  const [isEditing, setEditing] = useState({
    itemId: '',
    status: false,
  })

  const {
    data: AgreementsData,
    loading: AgreementsLoading,
    error: AgreementsError,
  } = useQuery<GetUserAgreementsQuery>(GetUserAgreementsDocument);

  const [
    createAgreementType,
    {
      data: createAgreementData,
      loading: createAgreementLoading,
      error: createAgreementError,
    }
  ] = useMutation<CreateUserAgreementMutation>(
    CreateUserAgreementDocument,
    { refetchQueries: [{query: GetUserAgreementsDocument}] }
  );

  const createHandler = (name: string): void => {
    if (!name) {
      setInputError(true);
      return;
    }

    createAgreementType({ variables: {name} })
  }

  const [
    updateAgreementType,
    {
      data: updateAgreementData,
      loading: updateAgreementLoading,
      error: updateAgreementError,
    }
  ] = useMutation<UpdateUserAgreementMutation>(
    UpdateUserAgreementDocument,
    { refetchQueries: [{query: GetUserAgreementsDocument}] }
  );

  const updateHandler = (id: string, name: string): void => {
    if(!id || !name) {
      setInputError(true);
      return;
    }

    updateAgreementType({ variables: {userAgreementId: id, name} })
  }

  const [
    deleteAgreementType,
    {
      data: deleteAgreementData,
      loading: deleteAgreementLoading,
      error: deleteAgreementError,
    }
  ] = useMutation<DeleteUserAgreementMutation>(
    DeleteUserAgreementDocument,
    { refetchQueries: [{query: GetUserAgreementsDocument}] }
  );

  const deleteHandler = (id: string): void => {
    if(!id) {
      setInputError(true);
      return;
    }
    deleteAgreementType({ variables: {userAgreementId: id} })
  }

  useEffect(() => {
    if(createAgreementError || updateAgreementError || deleteAgreementError) {
      setRequestError(true);
    }
  }, [createAgreementError, updateAgreementError, deleteAgreementError]);

  useEffect(() => {
    if(deleteAgreementData || updateAgreementData || createAgreementData) {
      setRequestError(false);
    }
  }, [deleteAgreementData, updateAgreementData, createAgreementData]);

  useEffect(() => {
    if(updateAgreementData) {
      setEditing({
        itemId: '',
        status: false,
      });
    }

    if(createAgreementData) {
      setCreating(false);
    }
  }, [updateAgreementData, createAgreementData]);

  const areEditingButtonsDisabled = createAgreementLoading || updateAgreementLoading || deleteAgreementLoading || isCreating;
  const areCreatingButtonsDisabled = createAgreementLoading || updateAgreementLoading || deleteAgreementLoading || isEditing.status;

  let content;

  if (AgreementsLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    )
  }

  if (AgreementsError) {
    content = <ErrorSnackbar error={AgreementsError}/>
  }

  if (AgreementsData) {
    const userAgreementTypes = AgreementsData.getUserAgreements;

    content = (
      <>
        <SettingsCreateItemForm
          header={'Оформление'}
          createItem={createHandler}
          setCreating={setCreating}
          inputError={inputError}
          setInputError={setInputError}
          areButtonsDisabled={areCreatingButtonsDisabled}
          isRequestSuccessful={Boolean(createAgreementData)}
        />

        <SettingsList
          list={userAgreementTypes}
          updateItem={updateHandler}
          deleteItem={deleteHandler}
          buttonsDisabled={areEditingButtonsDisabled}
          isEditing={isEditing}
          setEditing={setEditing}
        />
      </>
    )
  }

  return (
    <Content>
      {content}

      <Snackbar
        open={inputError}
        onClose={() => setInputError(false)}
        autoHideDuration={6000}
      >
        <Alert severity="error">
          Вы ввели некорректную информацию
        </Alert>
      </Snackbar>

      <Snackbar
        open={requestError}
        onClose={() => setRequestError(false)}
        autoHideDuration={6000}
      >
        <Alert severity="error">
          {createAgreementError?.message || updateAgreementError?.message || deleteAgreementError?.message}
        </Alert>
      </Snackbar>
    </Content>
  )
}
