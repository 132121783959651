import React, { useEffect, useState } from "react";
import {
  CreateKnowledgeBaseThemeDocument,
  CreateKnowledgeBaseThemeMutation,
  GetKnowledgeBaseThemesByCategoryIdDocument,
  KnowledgeBaseCategory,
  KnowledgeBaseTheme,
  UpdateKnowledgeBaseThemeDocument,
  UpdateKnowledgeBaseThemeMutation
} from "../../../generated/graphql";
import { useMutation } from "@apollo/client";
import { Box, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { ButtonCustom, ButtonsWrapper, CancelButton, ModalInput, primary } from "../../shared/Style/Style";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";

interface IThemesBlockModal{
  open: boolean;
  close(): void;
  isTheme: boolean;
  category?: KnowledgeBaseCategory;
  theme?: KnowledgeBaseTheme;
}

export const ThemeBlockModal = ({ open, close, isTheme, theme,category }:IThemesBlockModal) => {
  const isEditing=theme && isTheme

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const errorHandler = (message: string) => {
    setRequestError(true);
    setErrorMessage(message);
  };

  const closeHandler = () => {
    setName('');
    setRequestError(false);
    setErrorMessage('');
    close();
  };

  const [createTheme, {
    data: createThemeData,
    loading: createThemeLoading
  }] = useMutation<CreateKnowledgeBaseThemeMutation>(CreateKnowledgeBaseThemeDocument, {
    variables: {
      name,
      categoryId: category?.id
    },
    refetchQueries: [{
      query: GetKnowledgeBaseThemesByCategoryIdDocument,
      variables: { categoryId: category?.id }
    }]
  });

  const [updateTheme, {
    data: updateThemeData,
    loading: updateThemeLoading
  }] = useMutation<UpdateKnowledgeBaseThemeMutation>(UpdateKnowledgeBaseThemeDocument, {
    variables: {
      id: theme?.id,
      name,
      categoryId: category?.id,
    },
    refetchQueries: [{
      query: GetKnowledgeBaseThemesByCategoryIdDocument,
      variables: { categoryId: category?.id }
    }]
  });

  const submitHandler = async () => {
    if (!name) {
      setNameError(true);
      return;
    }

    try {
      if (isEditing && isTheme) {
        await updateTheme()
      } else if (!isEditing && isTheme) {
        await createTheme()
      }
    } catch (e) {
      errorHandler(
        `При ${isEditing ? 'редактировании' : 'создании'} ${isTheme ? 'темы' : 'темы'} произошла ошибка`
      )
    }
  };

  useEffect(() => {
    if (createThemeData || updateThemeData ) {
      closeHandler();
    }
  }, [createThemeData, updateThemeData])


  useEffect(() => {
    isEditing
      ? setName(isTheme ? theme?.name :'')
      : setName('');
  }, [isEditing])

  const disableOnLoading = updateThemeLoading || createThemeLoading

  return (
    <Dialog
      open={open}
    >
      <CloseIconButton
        close={closeHandler}
        disabled={disableOnLoading}
      />

      <DialogContent
        style={{
          width: '570px'
        }}
      >
        <DialogTitle>{isEditing ? 'Редактировать' : 'Создать'} {isTheme ? 'тему' : 'категорию'}</DialogTitle>

        <Box
          padding='0 24px'
        >
          <ModalInput
            label='Название'
            disabled={disableOnLoading}
            error={nameError}
            onFocus={() => setNameError(false)}
            value={name}
            onChange={({ target: { value } }) => setName(value)}
          />
          <ButtonsWrapper>
            <CancelButton
              onClick={closeHandler}
              disabled={disableOnLoading}
            >
              Отменить
            </CancelButton>

            <ButtonCustom
              main_color={primary}
              onClick={submitHandler}
              disabled={disableOnLoading}
            >
              Сохранить
            </ButtonCustom>
          </ButtonsWrapper>
        </Box>
      </DialogContent>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Dialog>


  )
};