import React from "react";
import { IDateRange } from "../shared/DateRangePicker/DateRangePicker";
import { ReportNavBarButton, ReportNavBarBox } from "./ReportsNavBar.style";

interface IReportsNavBarProps {
  activeReport: string;
  setActiveReport(reportName: string): void;
  setDateRange(state: IDateRange): void;
}

export const ReportsNavBar = ({
  activeReport,
  setActiveReport,
  setDateRange,
}: IReportsNavBarProps) => {
  const reportsList = [
    {
      name: "diffInHours",
      displayName: "Разница в часах",
    },
    {
      name: "diffInMoney",
      displayName: "Разница в деньгах",
    },
    {
      name: "diffInDays",
      displayName: "Разница в датах",
    },
    {
      name: "completionPercent",
      displayName: "Процент выполнения",
    },
    {
      name: "addedTasks",
      displayName: "Добавленные задачи",
    },
    {
      name: "employeesWorkload",
      displayName: "Нагрузка по сотруднику всего и на проекте",
    },
    {
      name: "employees",
      displayName: "Сотрудники (штат, аутсорс, оформление)",
    },
    {
      name: "projectSpending",
      displayName: "Освоение денег по проектам",
    },
    {
      name: "vacancyClosingRate",
      displayName: "Процент закрытия вакансий",
    },
    {
      name: "employeesDistribution",
      displayName: "Распределение сотрудников",
    },
    { name: "ratioReport", displayName: "% соотношение" },
    // { name: "bugs", displayName: "Баги" },
  ];

  return (
    <ReportNavBarBox>
      {reportsList.map((item) => (
        <ReportNavBarButton
          key={item.name}
          onClick={() => {
            setActiveReport(item.name);
            setDateRange({
              startDate: undefined,
              endDate: undefined,
            });
          }}
          active={item.name === activeReport}
        >
          {item.displayName}
        </ReportNavBarButton>
      ))}
    </ReportNavBarBox>
  );
};
