import React, {useEffect, useState} from 'react';
import {LessonBlockTypeEnum} from "../../generated/graphql";
import {
  HeaderIcon,
  ListIcon,
  PhotoIcon, QuestionIcon,
  SubtitleIcon,
  TextIcon, VideoIcon
} from "../KnowledgeBaseModals/AddBlockModal/AddBlockModalIcons";
import {AddBlockModalHeader} from "../KnowledgeBaseModals/AddBlockModal/AddBlockModal.style";
import {Box, Dialog, List, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import {CloseIconButton} from "../shared/Buttons/CloseIconButton";
import {ErrorMessageSnackbar} from "../shared/ErrorMessageSnackbar";
import {useHistory} from "react-router-dom";

interface IBlockFields {
  name: string;
  type: LessonBlockTypeEnum;
  icon: JSX.Element;
}

const blocks: IBlockFields[] = [
  {
    name: 'Заголовок',
    type: LessonBlockTypeEnum.Header,
    icon: <HeaderIcon />
  },
  {
    name: 'Подзаголовок',
    type: LessonBlockTypeEnum.Subtitle,
    icon: <SubtitleIcon />
  },
  {
    name: 'Основной текст',
    type: LessonBlockTypeEnum.Text,
    icon: <TextIcon />
  },
  {
    name: 'Список',
    type: LessonBlockTypeEnum.List,
    icon: <ListIcon />
  },
  {
    name: 'Изображение',
    type: LessonBlockTypeEnum.Photo,
    icon: <PhotoIcon />
  },
  {
    name: 'Видео',
    type: LessonBlockTypeEnum.Video,
    icon: <VideoIcon />
  }
]

export const LessonAddBlockModal = ({ open, closeModal, createLessonBlock }) => {
  const [requestError, setRequestError] = useState(false);

  const closeHandler = () => {
    setRequestError(false);
    closeModal(false);
  }

  const createLessonBlockHandler = async (type: LessonBlockTypeEnum) => {
    await createLessonBlock({
      variables: {
        type: type
      }
    })
    closeModal(false);
  }

  return (
    <Dialog
      open={open}
    >
      <AddBlockModalHeader>
        Выберите тип блока

        <Box
          position='relative'
          padding='22px'
        >
          <CloseIconButton
            close={closeHandler}
            main_color='black'
          />
        </Box>
      </AddBlockModalHeader>

      <List
        disablePadding
        style={{
          width: '370px'
        }}
      >
        {blocks.map(({ name, type, icon }) => (
          <ListItem
            button
            key={type}
            onClick={() => createLessonBlockHandler(type)}
          >
            <ListItemAvatar>
              <Box
                textAlign='center'
              >
                {icon}
              </Box>
            </ListItemAvatar>

            <ListItemText>
              {name}
            </ListItemText>
          </ListItem>
        ))}
      </List>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message="При создании блока произошла ошибка"
      />
    </Dialog>
  )
}