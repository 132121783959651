import React, {useEffect, useRef, useState} from "react";
import { Box, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { CloseIconButton } from "../../../shared/Buttons/CloseIconButton";
import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
  HiddenInput, TextPhotoName,
} from "../../../shared/Style/Style";
import {BlockInput} from "../../KnowledgeBaseBlockItem.style";
import {
  CreateKnowledgeBaseBlockListItemMutation,
  KnowledgeBaseBlockList,
  UpdateKnowledgeBaseBlockListItemMutation
} from "../../../../generated/graphql";
import {ModalImage} from "./ModalImage";
import {result} from "validate.js";

interface ModalAddStep {
  open: boolean;
  setOpen: (value: boolean) => void;
  blockId: string;
  blockListItem: KnowledgeBaseBlockList | null;
  createListItem?(
    blockId: string,
    content: string,
    isNumbered: boolean,
    photo?: File,
    video?: string,
    photoTitle?: string
  ): void;
  updateListItem?(
    listItemId: string,
    content: string,
    photo?: File,
    video?: string,
    photoTitle?: string
  ): void;
  setChosenItem: (value: KnowledgeBaseBlockList | null) => void,
  successRequest?: CreateKnowledgeBaseBlockListItemMutation | UpdateKnowledgeBaseBlockListItemMutation
}

const initialState: KnowledgeBaseBlockList = {
  content: "",
  photoTitle: "",
  video: "",
}

export const ModalAddStep = ({
  open,
  setOpen,
  blockId,
  blockListItem,
  createListItem,
  updateListItem,
  setChosenItem,
  successRequest
}: ModalAddStep) => {
  const [listItem, setListItem] = useState<KnowledgeBaseBlockList>(initialState)
  const [isPreview, serIsPreview] = useState<boolean>(false)
  const [file, setFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | null>();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClose = (): void => {
    setOpen(false);
    setListItem(initialState)
    setPreviewImage('')
    setFile(null);
    setChosenItem(null)
  };

  useEffect(() => {
    if(blockListItem) {
      setListItem({...blockListItem});
      setPreviewImage(blockListItem.photo)
    }
  }, [blockListItem])

  useEffect(() => {
    if(successRequest) {
      handleClose()
    }
  },[successRequest])

  const handleDrop = (drop: File): void => {
    setListItem(prev => ({...prev, photoTitle: drop.name}))
    setFile(drop)

    const reader = new FileReader();

    reader.onload = () => {
      setPreviewImage(reader.result);
    };

    reader.readAsDataURL(drop);
  };

  const handleFetch = async () => {
      if(blockListItem) {
        await updateListItem(listItem?.id, listItem.content, file, listItem.video, listItem.photoTitle)
        return
      }

      await createListItem(blockId, listItem.content,false, file, listItem.video, listItem.photoTitle )
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Создание шага</DialogTitle>

      <CloseIconButton close={handleClose} />

      <DialogContent style={{ minWidth: "400px", paddingBottom: '20px' }}>
        <Box>

          <Box mb={3}>
            <BlockInput
              fullWidth
              rows={5}
              label="Название"
              value={listItem.content}
              onChange={({ target: { value } }) => setListItem(prev => ({...prev, content: value}))}
            />
          </Box>

          <Box mb={3}>
            <BlockInput
              fullWidth
              rows={5}
              label="Ссылка"
              value={listItem.video}
              onChange={({ target: { value } }) => setListItem(prev => ({...prev, video: value}))}
            />
          </Box>

          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='flex-end'
          >
            <ButtonCustom onClick={() => inputRef.current.click()}>
              Прикрепить
              <HiddenInput
                type="file"
                ref={inputRef}
                onChange={({ target: { files } }) => handleDrop(files[0])}
              />
            </ButtonCustom>
            <TextPhotoName
              onClick={() => serIsPreview(true)}
            >
              {listItem.photoTitle}
            </TextPhotoName>
          </Box>

        </Box>

        <ButtonsWrapper>
          <CancelButton
            onClick={handleClose}
            disabled={false}
          >
            Отмена
          </CancelButton>

          <ButtonCustom
            onClick={handleFetch}
            disabled={false}
          >
            Сохранить
          </ButtonCustom>
        </ButtonsWrapper>
      </DialogContent>

      <ModalImage
        open={isPreview}
        close={() => serIsPreview(false)}
        img={previewImage}
      />
    </Dialog>
  );
};
