export const renderSwitch = (param: number): string => {
  switch (param) {
    case 1:
      return "Новый";
    case 2:
      return "В работе";
    default:
      return "Завершен";
  }
};

export const noData = "Данные отсутствуют";