import { Chart } from "chart.js";
import {
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      labels: {
        font: {
          weight: (ctx) => {
            return ctx.chart.width > 400 ? "bold" : "normal";
          },
        },
      },
    },
    datalabels: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: true,
      usePointStyle: true,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: true,
      },
      ticks: {
        beginAtZero: true,
        stepSize: 10,
        callback: function (value) {
          return value + "шт.";
        },
      },
    },
  },
};

export const getChartData = (chart) => {
  return {
    labels: chart.label,
    datasets: [
      {
        data: chart.value,
        backgroundColor: "rgba(255, 182, 29, 1)",
        hoverBackgroundColor: "rgba(255, 182, 29, 0.8)",
        categoryPercentage: 0.8,
        barPercentage: 1,
      },
    ],
  };
};
