import React, {useState, useEffect, ChangeEvent} from "react";

import { useMutation } from "@apollo/client";
import { ApolloError } from '@apollo/client';
import {
  MobileNotification,
  UpdateMobileNotificationMutation,
  UpdateMobileNotificationDocument,
  GetMobileNotificationsByAppDocument,
  SetMobileNotificationParticipantsMutation,
  SetMobileNotificationParticipantsDocument,
  GetMobileNotificationDocument,
  AvailableForMobileNotificationEnum,
  RemoveMobileNotificationPhotoMutation,
  RemoveMobileNotificationPhotoDocument,
} from "../../../generated/graphql";

import {Box, Dialog, DialogTitle} from "@material-ui/core";

import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
  ModalWithPhotoContent,
  ModalWithPhotoForm,
  ModalWithPhotoInput,
  ModalWithPhotoInputs,
} from "../../shared/Style/Style";

import {CloseIconButton} from "../../shared/Buttons/CloseIconButton";
import {AddClientsToMobileNotificationModal} from "../../ParticipantsModal/AddClientsToMobileNotificationModal";
import {ErrorMessageSnackbar} from "../../shared/ErrorMessageSnackbar";

import {
  MobileAppPhotoDropzone,
} from "../MobileAppModal.style";
import { useRequestError } from "../../../hooks";


interface IMobileAppModalProps {
  open: boolean;

  close(): void;

  notification: MobileNotification;

  refetchVariables: {
    limit: number;
    skip: number;
    appId: string;
  }
}

const initialData: { [key: string]: string } = {
  name: "",
  description: "",
};

const initialErrors: { [key: string]: boolean } = {
  name: false,
  description: false,
}

const validateField = {
  name: 28,
  description: 15000
}


export const MobileAppNotificationModal = ({open, close, notification, refetchVariables}: IMobileAppModalProps) => {

  const [data, setData] = useState(initialData);
  const [newPhoto, setNewPhoto] = useState<File | null>(null);
  const [deletePhoto, setDeletePhoto] = useState<string | null>(null);

  const [inputErrors, setInputErrors] = useState(initialErrors);

  const [addUsersModalOpen, setAddUsersModalOpen] = useState(false);
  const [notificationParticipants, setNotificationParticipants] = useState<string>(
    AvailableForMobileNotificationEnum.Everyone
  );

  const closeHandler = () => {
    setData(initialData);
    setNewPhoto(null);
    setInputErrors(initialErrors);
    close();
  }

  const [
    updateNotification,
    {
      data: updateNotificationData,
      loading: updateNotificationLoading,
      error: updateNotificationError
    }
  ] = useMutation<UpdateMobileNotificationMutation>(
    UpdateMobileNotificationDocument,
    {
      variables: {
        notificationData: {
          id: notification.id,
          ...data,
        },
        photo: newPhoto ? newPhoto : undefined,
      },
      refetchQueries: [{
        query: GetMobileNotificationsByAppDocument,
        variables: refetchVariables
      }],
    },
    );
  
  const [
    changeNotificationParticipants,
    {
      loading: changeNotificationParticipantsLoading,
      error: changeNotificationParticipantsError,
    },
  ] = useMutation<SetMobileNotificationParticipantsMutation>(
    SetMobileNotificationParticipantsDocument,
    {
      variables: {
        notificationData: {
          notificationId: notification.id,
          availableFor: notificationParticipants,
        },
      },
      refetchQueries: [
        {
          query: GetMobileNotificationDocument,
          variables: { id: notification.id},
        },
      ],
    }
    );

  const [ 
    removeMobileNotificationPhoto,
    { 
      error: removeMobileNotificationPhotoError,
    },
  ] = useMutation<RemoveMobileNotificationPhotoMutation>(
    RemoveMobileNotificationPhotoDocument,
    {
      variables: {
        removeMobileNotificationPhotoId: deletePhoto ? deletePhoto : null,
      },
      refetchQueries: [
        {
          query: GetMobileNotificationDocument,
          variables: { id: notification.id},
        },
      ],
    }
  );

  const updateNotificationHandler = async () => {
    const dataArray = Object.entries(data);

    dataArray.forEach((field: string[]) => {
      if (!field[1]) {
        setInputErrors(state => ({
          ...state,
          [field[0]]: true,
        }))
      }
    });

    if (dataArray.some((field: string[]) => !field[1])) {
      return;
    }

    if (deletePhoto) {
      await removeMobileNotificationPhoto();
    }

    await updateNotification();

    await changeNotificationParticipants();

  }

  const errors = updateNotificationError || changeNotificationParticipantsError || removeMobileNotificationPhotoError;
  const MessageErrorsTypeMap = new Map< ApolloError, string>([
    [updateNotificationError, "Ошибка при сохранении уведомления"],
    [changeNotificationParticipantsError, "Ошибка при сохранении участников"],
    [removeMobileNotificationPhotoError, "Ошибка при удалении фото уведомления"],
  ]);
  const { requestError, setRequestError, errorMessage } = useRequestError(errors, MessageErrorsTypeMap.get(errors));
  
  useEffect(() => {
    if (notification) {
      const {name, description} = notification

      setData({name, description,});
    }

  }, [notification.id]);

  useEffect(() => {
    if (updateNotificationData) {
      closeHandler();
    }
  }, [updateNotificationData]);
  

  const onChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target

    if (value.length > validateField[name]) return
    setData({...data, [name]: value})
  }

  return (
    <Dialog
      open={open}
      TransitionProps={{timeout: 300}}
    >
      <ModalWithPhotoContent>
        <CloseIconButton
          close={closeHandler}
        />

        <Box
          display='flex'
          alignItems='center'
          mb={1}
        >
          <DialogTitle>{notification.name ? 'Редактирование' : 'Создание'}</DialogTitle>
        </Box>

        <ModalWithPhotoInputs>
          <MobileAppPhotoDropzone
            photo={notification?.photo}
            id={notification?.id}
            setNewPhoto={setNewPhoto}
            setDeletePhoto={setDeletePhoto}
          />

          <ModalWithPhotoForm>
            <ModalWithPhotoInput
              fullWidth
              label='Заголовок'
              disabled={updateNotificationLoading}
              error={inputErrors.name}
              value={data.name}
              name="name"
              onFocus={() => setInputErrors({
                ...inputErrors,
                name: false,
              })}
              onChange={onChangeDescription}
            />

            <ModalWithPhotoInput
              multiline
              disabled={updateNotificationLoading}
              error={inputErrors.description}
              maxRows={7}
              label='Текст уведомления'
              value={data.description}
              name="description"
              onFocus={() => setInputErrors({
                ...inputErrors,
                description: false,
              })}
              onChange={onChangeDescription}
            />
          </ModalWithPhotoForm>
        </ModalWithPhotoInputs>

        <Box
          textAlign='center'
          margin={3}
        >
          <ButtonCustom
            disabled={changeNotificationParticipantsLoading}
            onClick={() => setAddUsersModalOpen(true)}
          >
            Добавить участников
          </ButtonCustom>
        </Box>

        <ButtonsWrapper>
          <CancelButton
            disabled={updateNotificationLoading}
            onClick={closeHandler}
          >
            Отменить
          </CancelButton>

          <ButtonCustom
            disabled={updateNotificationLoading}
            onClick={updateNotificationHandler}
          >
            Сохранить
          </ButtonCustom>
        </ButtonsWrapper>
      </ModalWithPhotoContent>

      <AddClientsToMobileNotificationModal
        open={addUsersModalOpen}
        close={() => setAddUsersModalOpen(false)}
        notificationId={notification.id}
        onChangeNotification={setNotificationParticipants}
      />
      
      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />

    </Dialog>
  )
}