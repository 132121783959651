import styled from "styled-components";
import { Filter, MainTableCell, MainTableRow, primary } from "../../shared/Style/Style";

export const ParticipantsContainer = styled.div`
  max-width: 1200px;
  margin-top: 40px;
`;

export const SearchSortWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
`;

export const ParticipantsSearchContainer = styled.div`
  display: flex;
`;

export const ParticipantsSearchInput = styled(Filter)`
  min-width: 340px;

  & .MuiInputLabel-root.Mui-focused {
    color: ${primary};
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${primary};
  }
`;

export const ParticipantsTableRow = styled(MainTableRow)`
  grid-template-columns: 1fr 1fr 1.2fr 0.5fr 0.6fr 0.7fr;
  grid-gap: 14px;
`;

export const ParticipantsTableCell = styled(MainTableCell)`
  font-size: 14px;
  line-height: 1.2;
  padding-top: 28px;
  padding-bottom: 26px;

  p {
    margin: 0;
  }
`;

export const ParticipantsTableCellName = styled(ParticipantsTableCell)`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: ${primary};
    text-decoration-thickness: 2px;
  }
`;

export const ParticipantsTableHeadCell = styled(ParticipantsTableCell)`
  font-weight: bold;
  padding-top: 0;
  padding-bottom: 0;
`;
