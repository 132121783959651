import styled from "styled-components";
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import {Typography} from '@material-ui/core'
import { lightBlack, secondary } from "../shared/Style/Style";

export const Container = styled.div`
  width: 70%;
  margin: 50px auto;
`

export const Title = styled.h2`
  text-align: center;
  font-size: 30px;
  font-weight: 500;
`

export const Subtitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
  padding-left: 20px;
`
export const SubtitleCenter = styled(Subtitle)`
  padding-left: 0;
  text-align: center;
`

// export const Text = styled.p`
//   text-indent: 50px;
//   text-align: justify;
//   margin: 0;
// `

export const Text = styled.p`
  text-align: justify;
  margin: 0;
  .indented-paragraph {
    text-indent: 20px;
  }
`

export const WeightText = styled.span`
  font-weight: 500;
`

export const WeightTextTitle = styled.p`
  font-weight: 500;
`

export const List = styled.ul`
  margin: 0;
`

export const ListItem = styled.li`
  list-style-type: none;
  padding: 0;
`

// export const MarkerList = styled.ul``

export const MarkerListItem = styled.li`
margin-bottom: 24px;
`
export const TableHeaderCell = styled(TableCell)`
  && {
    background-color: ${secondary};
    text-align: center;
    padding: 20px 0;
    border: 1px solid ${lightBlack};
  }
`;

export const TableBodyCell = styled(TableHeaderCell)`
  && {
    background-color: inherit;
    padding: 16px;
  }
`;





