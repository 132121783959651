import { Modal } from "@material-ui/core";
import { ButtonCustom, ButtonCustomCancel } from "../Style/Style";
import { Container, Content, Buttons, Text } from "./ConfirmationModal.style";

interface ConfirmationModalProps {
  open: boolean;
  content: string;
  confirmButtonText?: string;
  disabled?: boolean;
  onConfirm(): void;
  onClose(): void;
}

export const ConfirmationModal = ({
  open,
  content,
  confirmButtonText,
  disabled = false,
  onConfirm,
  onClose,
}: ConfirmationModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Container>
        <Content>
          <Text>{content}</Text>

          <Buttons>
            <ButtonCustom onClick={onConfirm} disabled={disabled}>
              {confirmButtonText || "Удалить"}
            </ButtonCustom>
            <ButtonCustomCancel onClick={onClose}>Отмена</ButtonCustomCancel>
          </Buttons>
        </Content>
      </Container>
    </Modal>
  );
};
