import React from 'react';
import { TasksTable } from './Tables/TasksTable';
import { WorkloadTable } from './Tables/WorkloadTable';

interface IDropdownProps {
  type?: string;
  items: any[];
}

export const Dropdown = ({ type, items }: IDropdownProps) => {
  let dropdown;

  switch (type) {
    case 'task':
      dropdown = <TasksTable tasks={items} />
      break;

    case 'workload':
      dropdown = <WorkloadTable projects={items} />
      break;

    default:
      dropdown = <WorkloadTable projects={items} />
      break;
  }

  return (
    <>
      {dropdown}
    </>
  );

}