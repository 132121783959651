export const blockNameText = "Курсы";
export const allowedQuantityToAccess = 1;

export const errorMessages = {
  responseError: 'User already got a certificate for this course type',
  forbidden: {
    add: "У пользователя уже есть сертификат для этого типа курса",
    remove: "Курс пройден пользователем. Закрытие доступа невозможно",
  },
  defaultMessage: {
    add: "При активации курса произошла ошибка",
    remove: "При деактивации курса произошла ошибка",
  },
};
