import React, { FC, useState } from "react";
import { PlusIcon } from "../../../../shared/Icons/PlusIcon";
import { AddMediaButton, CardContainer } from "./Style";
import { UploadMenu } from "./UploadMenu";
import { UploadVideoUrl } from "./UploadVideoUrl";

interface MediaCardEmptyProps {
  isDisable: boolean;
}

export const MediaCardEmpty: FC<MediaCardEmptyProps> = ({
  isDisable,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <CardContainer>
      <AddMediaButton
        disabled={isDisable}
        onClick={(e: React.SyntheticEvent) => setAnchorEl(e.currentTarget)}
      >
        <PlusIcon />
      </AddMediaButton>

      {anchorEl && (
        <UploadMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          setOpenModal={setOpenModal}
        />
      )}

      {openModal && (
        <UploadVideoUrl
          open={openModal}
          close={() => setOpenModal(false)}
        />
      )}
    </CardContainer>
  );
};
