import { useReactiveVar } from "@apollo/client";
import { ModalInput } from "../../../../../shared/Style/Style";
import { robotsState } from "../../../state";

export const ArtifactName = () => {
    const artifact = useReactiveVar(robotsState.artifact);

    const handleValueChange = (value: string) => {
        robotsState.setArtifactName(value);
    };

    return (
        <ModalInput
            key={artifact?.id ?? "name"}
            type="text"
            variant="filled"
            margin="normal"
            label="Название"
            value={artifact?.name ?? null}
            onChange={({ target: { value } }) => handleValueChange(value)}
        />
    );    
};
