import { createContext, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import clsx from "clsx";
import {
  GetUserInfoDocument,
  GetUserInfoQuery,
  User,
} from "../../generated/graphql";
import { CurrentUser } from "../CurrentUser";
import { Sidebar } from "./Sidebar";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CssBaseline, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "./Main.style";
import { AppBarStyle } from "../Login/Login.style";
import { useStyles as loginStyles } from "../Login";

import { MAIN_ROUTES } from "./routes";

export const UserInfoContext = createContext<User>({} as User);

export function Main() {
  const [open, setOpen] = useState(false);

  const { data, loading, error } =
    useQuery<GetUserInfoQuery>(GetUserInfoDocument);

  const user = data?.getUserInfo as User;

  const drawerStyles = useStyles();
  const classes = loginStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (error) {
    return <ErrorSnackbar error={error} />;
  }

  if (!user) {
    return <Redirect to="/login" />;
  }

  return (
    <UserInfoContext.Provider value={user}>
      <div className={drawerStyles.root}>
        <CssBaseline />
        <AppBarStyle
          position="fixed"
          className={clsx(drawerStyles.appBar, {
            [drawerStyles.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="default"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(drawerStyles.menuButton, {
                [drawerStyles.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <CurrentUser />
          </Toolbar>
        </AppBarStyle>
        <Sidebar open={open} handleDrawerClose={handleDrawerClose} />
        <main className={drawerStyles.content}>
          <div className={drawerStyles.toolbar} />
          <Switch>
            {MAIN_ROUTES.map((route, i) => (
              <Route
                key={i}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            ))}
          </Switch>
        </main>
      </div>
    </UserInfoContext.Provider>
  );
}
