import React from "react";
import { useLocation, useParams } from "react-router-dom";

import { Box } from "@material-ui/core";
import {
  ButtonCustomWithDarkText,
  CustomNavBar,
  CustomNavLink,
} from "../../shared/Style/Style";

export const TimelineNavbar = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const {  search } = useLocation();

  return (
    <CustomNavBar>
      <Box display="flex">
        <CustomNavLink to={`/project/${projectId}/timeline?month`}>
          <Box mr={1}>
            <ButtonCustomWithDarkText
              type="button"
              variant="contained"
              size="small"
              active={search.includes("month")}
            >
              Месяцы
            </ButtonCustomWithDarkText>
          </Box>
        </CustomNavLink>

        <CustomNavLink to={`/project/${projectId}/timeline?weeks`}>
          <Box mr={1}>
            <ButtonCustomWithDarkText
              type="button"
              variant="contained"
              size="small"
              active={search.includes("weeks")}
            >
              Недели
            </ButtonCustomWithDarkText>
          </Box>
        </CustomNavLink>

        <CustomNavLink to={`/project/${projectId}/timeline?days`}>
          <Box mr={1}>
            <ButtonCustomWithDarkText
              type="button"
              variant="contained"
              size="small"
              active={search.includes("days")}
            >
              Дни
            </ButtonCustomWithDarkText>
          </Box>
        </CustomNavLink>
      </Box>
    </CustomNavBar>
  );
};
