import React from "react";
import { Grid } from "@material-ui/core";
import { Field } from "react-final-form";
import { ThemeProvider } from "@material-ui/styles";
import { defaultMaterialTheme } from "../../shared/Style/Style";
import { DatePicker } from "@material-ui/pickers";
import { Project } from "../../../generated/graphql";

interface ProjectDatePickersProps {
  values: Project;
}

export const ProjectDatePickers = ({ values }: ProjectDatePickersProps) => {
  return (
    <>
      <Grid xs={12} sm={6} item>
        <Field name="startDate">
          {({ input, meta }) => (
            <ThemeProvider theme={defaultMaterialTheme}>
              <DatePicker
                error={meta.error && meta.touched}
                helperText={meta.touched && meta.error}
                fullWidth
                variant="inline"
                format="dd.MM.yyyy"
                id="date-picker-startDate"
                label="Дата начала"
                inputVariant="filled"
                value={input.value || null}
                onChange={input.onChange}
                maxDate={
                  values.finishDate &&
                  new Date(new Date(values.finishDate).getTime() - 86400000)
                }
              />
            </ThemeProvider>
          )}
        </Field>
      </Grid>

      <Grid xs={12} sm={6} item>
        <Field name="finishDate">
          {({ input, meta }) => (
            <ThemeProvider theme={defaultMaterialTheme}>
              <DatePicker
                error={meta.error && meta.touched}
                helperText={meta.touched && meta.error}
                fullWidth
                variant="inline"
                format="dd.MM.yyyy"
                id="date-picker-finishDate"
                label="Дата конца"
                inputVariant="filled"
                value={input.value || null}
                onChange={input.onChange}
                minDate={
                  values.startDate
                    ? new Date(new Date(values.startDate).getTime() + 86400000)
                    : new Date(new Date().getTime() + 86400000)
                }
              />
            </ThemeProvider>
          )}
        </Field>
      </Grid>

      <Grid xs={12} sm={6} item>
        <Field name="startDateEstimate">
          {({ input, meta }) => (
            <ThemeProvider theme={defaultMaterialTheme}>
              <DatePicker
                error={meta.error && meta.touched}
                helperText={meta.touched && meta.error}
                fullWidth
                variant="inline"
                format="dd.MM.yyyy"
                id="date-picker-startDate"
                label="Планируемая дата начала"
                inputVariant="filled"
                value={input.value || null}
                onChange={input.onChange}
                maxDate={
                  values.finishDateEstimate &&
                  new Date(
                    new Date(values.finishDateEstimate).getTime() - 86400000
                  )
                }
              />
            </ThemeProvider>
          )}
        </Field>
      </Grid>

      <Grid xs={12} sm={6} item>
        <Field name="finishDateEstimate">
          {({ input, meta }) => (
            <ThemeProvider theme={defaultMaterialTheme}>
              <DatePicker
                error={meta.error && meta.touched}
                helperText={meta.touched && meta.error}
                fullWidth
                variant="inline"
                format="dd.MM.yyyy"
                id="date-picker-finishDate"
                label="Палнируемая дата конца"
                inputVariant="filled"
                value={input.value || null}
                onChange={input.onChange}
                minDate={
                  values.startDateEstimate
                    ? new Date(
                        new Date(values.startDateEstimate).getTime() + 86400000
                      )
                    : new Date(new Date().getTime() + 86400000)
                }
              />
            </ThemeProvider>
          )}
        </Field>
      </Grid>
    </>
  );
};
