import React, { useState } from "react";
import { OrderTypeEnum, SortTypeEnum } from "../../generated/graphql";
import { ThemeProvider } from "styled-components";
import { defaultMaterialTheme } from "../shared/Style/Style";
import { Box, IconButton, MenuItem } from "@material-ui/core";

import { SearchOutlined } from "@material-ui/icons";
import {
  SearchBar,
  SearchInput,
  SortArrow,
  SortOfType,
} from "./SearchAndSortBar.style";
import { useResetPageOnSearch } from "../../hooks";

export interface ActiveSearchAndSortState {
  searchKey?: string;
  sortKey: SortTypeEnum | string;
  orderKey: OrderTypeEnum;
}

interface SearchAndSortBarProps {
  label: string;
  activeFilter: ActiveSearchAndSortState;

  setPage(number): void;

  setActiveFilter(activeFilter: ActiveSearchAndSortState): void;
}

const sortItem = [
  { value: SortTypeEnum.Date, name: "По дате" },
  { value: SortTypeEnum.Name, name: "По имени" },
];

export const SearchAndSortBar = ({
  label,
  activeFilter,
  setActiveFilter,
  setPage,
}: SearchAndSortBarProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchClick = () => {
    setActiveFilter({
      ...activeFilter,
      searchKey: searchTerm,
    });
  };

  const handleKeyboardEvent = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      setActiveFilter({
        ...activeFilter,
        searchKey: searchTerm,
      });
    }
  };
  const searchHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  const orderKeyCheck = activeFilter.orderKey.includes(OrderTypeEnum.Asc);

  const orderHandler = () => {
    if (orderKeyCheck) {
      setActiveFilter({ ...activeFilter, orderKey: OrderTypeEnum.Desc });
    }
    if (!orderKeyCheck) {
      setActiveFilter({ ...activeFilter, orderKey: OrderTypeEnum.Asc });
    }
  };
  const sortHandler = (value: unknown) => {
    if (typeof value === "string") {
      setActiveFilter({ ...activeFilter, sortKey: value });
    }
  };
  const sortedValue =
    activeFilter.sortKey === SortTypeEnum.Date
      ? sortItem[0].value
      : activeFilter.sortKey;

  useResetPageOnSearch({ searchKey: searchTerm, setPage });

  return (
    <SearchBar>
      <ThemeProvider theme={defaultMaterialTheme}>
        <SearchInput
          label={label}
          value={searchTerm}
          onKeyPress={handleKeyboardEvent}
          onChange={searchHandle}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleSearchClick}>
                <SearchOutlined />
              </IconButton>
            ),
          }}
        />
      </ThemeProvider>
      <Box display="flex">
        <SortArrow
          active={!!activeFilter.orderKey}
          direction={orderKeyCheck ? "asc" : "desc"}
          onClick={orderHandler}
        />
        <SortOfType
          value={sortedValue}
          onChange={({ target: { value } }) => sortHandler(value)}
        >
          {sortItem.map((select) => (
            <MenuItem key={select.value} button value={select.value}>
              {select.name}
            </MenuItem>
          ))}
        </SortOfType>
      </Box>
    </SearchBar>
  );
};
