import React from "react";

import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  OperationVariables,
  useQuery
} from "@apollo/client";
import {
  User,
  CreateTrackerContractMutation,
  GetProjectsLightDocument,
  GetProjectsLightQuery
} from "../../../generated/graphql";

import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";
import { LoadingBox } from "../../shared/Style/Style";

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";

import { ProjectModalNoProjects } from "./ProjectModal.style";


interface IProjectContractModalProps {
  disabled: boolean;
  open: boolean;
  close(): void;
  createContract: (options?: MutationFunctionOptions<CreateTrackerContractMutation, OperationVariables, DefaultContext, ApolloCache<any>>) => Promise<any>;
  user?: User;
}


export const ProjectContractModal = ({ disabled, open, close, createContract, user }: IProjectContractModalProps) => {
  const { data, loading, error } = useQuery<GetProjectsLightQuery>(GetProjectsLightDocument);

  const createHandler = async (userId: string, projectId: string) => {
    await createContract({
      variables: {
        userId,
        projectId
      }
    })

    close();
  }

  let content: JSX.Element;

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit' />
      </LoadingBox>
    )
  }

  if (error) {
    content = (
      <ErrorSnackbar error={error} />
    )
  }

  if (data) {
    const projects = data.getProjectsLight;

    const projectsToShow = user?.trackerContracts?.length
      ? projects.filter(project => user.trackerContracts.some(contract => contract.project?.id !== project?.id))
      : projects;

    content = (
      <List>
        {projectsToShow.length
          ? projectsToShow.map(({ id, name }) => (
            <ListItem
              button
              key={id}
              style={{ borderBottom: "1px solid #eee", marginBottom: "0.5rem", width: "300px"}}
              onClick={() => createHandler(user?.id, id)}
              disabled={disabled}
            >
              <ListItemText primary={name} />
            </ListItem>
            ))
          : (
            <ProjectModalNoProjects>Нет доступных проектов</ProjectModalNoProjects>
          )
        }
      </List>
    )
  }

  return (
    <Dialog
      onClose={close}
      open={open}
    >
      <DialogTitle>Выберите проект</DialogTitle>

      <DialogContent>
        {content}
      </DialogContent>
    </Dialog>
  )
}