import React, { useEffect, useReducer, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CreateVacanciesPlanDocument,
  CreateVacanciesPlanMutation,
  DeleteVacanciesPlanDocument,
  DeleteVacanciesPlanMutation,
  GetVacanciesPlanLightDocument,
  GetVacanciesPlanLightQuery,
  GetVacanciesPlanOptionsFieldsDocument,
  GetVacanciesPlanOptionsFieldsQuery,
  GetVacanciesPlansByNameDocument,
  GetVacanciesPlansByNameQuery,
  UpdateVacanciesPlanDocument,
  UpdateVacanciesPlanMutation,
} from "../../../../generated/graphql";
import { Box, CircularProgress, Container } from "@material-ui/core";
import { CreateVacancyPlanModal } from "./CreateVacancyPlanModal";
import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { MonthNavigationBar } from "../../../shared/MonthNavigationBar";
import { initialState, reducer } from "./VacancyReportReducer";
import { TableContent } from "./TableContent";
import { ButtonPlus, LoadingBox } from "../../../shared/Style/Style";
import { SpeedometerReport } from "../../../shared/SpeedometerReport/SpeedometerReport";
import { ChartContainer } from "./VacancyReport.style";

const checkExistMonth = (data: any[], activeTab: string) => {
  return data.find((month) => month.name.slice(0, 7) === activeTab.slice(0, 7));
};

export const VacancyReport = ({ reports }: { reports: boolean }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [months, setMonths] = useState<{ id: string; name: string }[]>([]);
  const [activeTab, setActiveTab] = useState<string>("");

  const {
    data: dataOptionFields,
    loading: loadingOptionFields,
    error: errorOptionFields,
  } = useQuery<GetVacanciesPlanOptionsFieldsQuery>(
    GetVacanciesPlanOptionsFieldsDocument
  );

  const {
    data: dataPlans,
    loading: loadingPlans,
    error: errorPlans,
  } = useQuery<GetVacanciesPlanLightQuery>(GetVacanciesPlanLightDocument, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const [
    getPlans,
    {
      data: dataVacanciesPlans,
      loading: loadingVacanciesPlans,
      error: errorVacanciesPlans,
    },
  ] = useLazyQuery<GetVacanciesPlansByNameQuery>(
    GetVacanciesPlansByNameDocument,
    { fetchPolicy: "network-only" }
  );

  const [mutationCreatePlan, { data: createPlanData, error: createPlanError }] =
    useMutation<CreateVacanciesPlanMutation>(CreateVacanciesPlanDocument);

  const [mutationUpdatePlan, { data: updatePlanData, error: updatePlanError }] =
    useMutation<UpdateVacanciesPlanMutation>(UpdateVacanciesPlanDocument);

  const [
    mutationDeletePlan,
    { data: deleteVacationPlan, error: deleteVacationPlanError },
  ] = useMutation<DeleteVacanciesPlanMutation>(DeleteVacanciesPlanDocument);

  useEffect(() => {
    months.length && setActiveTab(months[months.length - 1].name);
  }, [months]);

  useEffect(() => {
    const data = dataPlans?.getVacanciesPlanLight;
    if (data) {
      setMonths([...data.map((data) => ({ id: data.name, name: data.name }))]);
    }
  }, [dataPlans?.getVacanciesPlanLight]);

  useEffect(() => {
    const data = dataPlans?.getVacanciesPlanLight;
    let existMonth;
    if (data) {
      existMonth = activeTab && checkExistMonth(data, activeTab);
      !existMonth && dispatch({ type: "setNewMonth", payload: true });
    }

    activeTab &&
      existMonth &&
      getPlans({
        variables: {
          name: activeTab,
        },
      });
  }, [activeTab]);

  useEffect(() => {
    if (
      createPlanData?.createVacanciesPlan ||
      updatePlanData?.updateVacanciesPlan ||
      deleteVacationPlan?.deleteVacanciesPlan
    ) {
      getPlans({ variables: { name: activeTab } });
      dispatch({ type: "setNewMonth", payload: false });
    }
  }, [
    createPlanData?.createVacanciesPlan,
    updatePlanData?.updateVacanciesPlan,
    deleteVacationPlan?.deleteVacanciesPlan,
  ]);

  let content: any;

  if (
    loadingOptionFields ||
    loadingPlans ||
    loadingVacanciesPlans ||
    errorVacanciesPlans
  ) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  const optionFields = dataOptionFields?.getVacanciesPlanOptionsFields;
  const plans = dataVacanciesPlans?.getVacanciesPlansByName.plans;
  const progress = dataVacanciesPlans?.getVacanciesPlansByName.progress;

  if (
    errorOptionFields ||
    errorPlans ||
    createPlanError ||
    updatePlanError ||
    deleteVacationPlanError
  ) {
    content = (
      <ErrorSnackbar
        error={
          errorOptionFields ||
          errorPlans ||
          createPlanError ||
          updatePlanError ||
          deleteVacationPlanError
        }
      />
    );
  }

  const mutateVacancy = async (): Promise<void> => {
    if (
      !state.vacancyData.positionId ||
      !state.vacancyData.experience ||
      !state.vacancyData.jobId
    ) {
      return;
    }

    if (state.mutationType === "create") {
      await mutationCreatePlan({
        variables: {
          planData: {
            name: activeTab,
            jobId: state.vacancyData.jobId,
            positionId: state.vacancyData.positionId,
            experience: state.vacancyData.experience,
          },
        },
      });

      dispatch({ type: "createNewRow", payload: false });
      dispatch({ type: "setMutationType", payload: "" });
      dispatch({ type: "clearVacancyData" });
    }

    if (state.mutationType === "update") {
      await mutationUpdatePlan({
        variables: {
          planData: {
            ...state.vacancyData,
            statusId: +state.vacancyData.statusId,
          },
        },
      });

      dispatch({ type: "createNewRow", payload: false });
      dispatch({ type: "setMutationType", payload: "" });
      dispatch({ type: "clearVacancyData" });
    }
  };

  if (optionFields || activeTab) {
    content = (
      <TableContent
        reports={reports}
        state={state}
        dispatch={dispatch}
        optionFields={optionFields}
        plans={!state.newMonth ? plans : []}
        mutateVacancy={mutateVacancy}
        mutationDeletePlan={mutationDeletePlan}
      />
    );
  }

  const speed = (() => (progress ? progress * 2.58 : 0))();

  return (
    <Container maxWidth="lg" style={{ position: "relative" }}>
      {!reports && (
        <Box mb={5} mt={4} textAlign="right">
          <ButtonPlus
            width={180}
            variant="contained"
            onClick={() =>
              dispatch({ type: "setCreateVacancyPlan", payload: true })
            }
          >
            Добавить
          </ButtonPlus>
        </Box>
      )}

      {content}

      {state.createVacancyPlan && (
        <CreateVacancyPlanModal
          open={state.createVacancyPlan}
          setOpen={() =>
            dispatch({ type: "setCreateVacancyPlan", payload: false })
          }
          setMonths={setMonths}
          months={months}
        />
      )}

      <MonthNavigationBar
        field="id"
        months={months}
        chosenMonth={activeTab}
        setChosenMonth={setActiveTab}
      />
      {reports && (
        <ChartContainer>
          <SpeedometerReport value={speed} />
        </ChartContainer>
      )}
    </Container>
  );
};
