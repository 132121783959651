import React, { useMemo, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { StyledTextField } from "../Style";
import { LoadingProgress } from "../../shared/LoadingProgress";
import { useDebounce } from "../../shared/Utils/OtherOperations/useDebounce";
import {
  GetTerritorialCommunitiesDocument,
  GetTerritorialCommunitiesQuery,
  TerritorialCommunity,
} from "../../../generated/graphql";
import { useInfiniteScrollForQuery } from "../hooks";
import { QUERY_LIMIT } from "../constants";

interface SelectCommunitiesProps {
  communityId: string;
  regionId: string;
  setCommunityId: (communityId: string) => void;
  community: TerritorialCommunity;
}

export const SelectCommunities = ({
  communityId,
  regionId,
  setCommunityId,
  community = null,
}: SelectCommunitiesProps) => {
  const [searchKey, setSearchKey] = useState("");
  const debouncedValue = useDebounce(searchKey, 500);
  const variables = useMemo(
    () => ({
      data: {
        name: debouncedValue,
        regionId,
      },
      skip: 0,
    }),
    [debouncedValue, regionId]
  );

  const {
    items: communities,
    loading,
    handleScroll,
  } = useInfiniteScrollForQuery<GetTerritorialCommunitiesQuery>({
    query: GetTerritorialCommunitiesDocument,
    variables,
    getItems: (data) => data.getTerritorialCommunities?.communities || [],
    limitKey: "take",
    limit: QUERY_LIMIT,
  });

  const getCommunitiesFullName = (communityId: string) => {
    if (!communityId) return "";
    const territorialCommunity = communities.find(
      (community) => community.id === communityId
    );
    if (!territorialCommunity) {
      return community?.fullName || "";
    }
    return territorialCommunity?.fullName;
  };

  return (
    <>
      <Autocomplete
        options={communities?.map((community) => community.id) || []}
        getOptionLabel={(option) => getCommunitiesFullName(option)}
        inputValue={
          searchKey.length > 0 ? searchKey : getCommunitiesFullName(communityId)
        }
        onChange={(_, value) => {
          setCommunityId(value);
          setSearchKey("");
        }}
        value={communityId || community?.id || null}
        ListboxProps={{
          onScroll: handleScroll,
        }}
        renderInput={(params) => (
          <>
            <StyledTextField
              label="ОТГ"
              variant="filled"
              onChange={({ target: { value: inputValue } }) => {
                if (communityId) {
                  return setSearchKey("");
                }
                setCommunityId("");
                setSearchKey(inputValue);
              }}
              {...params}
            />
            {loading && <LoadingProgress />}
          </>
        )}
      />
    </>
  );
};
