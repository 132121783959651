import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useRequestError } from "../../../hooks";
import { GetRegisteredUsersAdminReportsQuery, GetRegisteredUsersAdminReportsDocument, PaymentSpaceType, } from "../../../generated/graphql";
import { ReportsContainer } from "../ReportsContainer";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { VerticalBarChart } from "../VerticalBarChart/VerticalBarChart";
import { getChartLabelsData, getChartQuantityData, getMessage } from "../utils";
import { ERROR_MESSAGE_USERS, FILTER_INTERNAL_USERS, PERIOD_FILTER_TYPES, REPORT_VARIANTS, SPACE_FILTER_TYPES, TOP_FILTER, USERS_FILTER_TYPES, USERS_PRODUCT_FILTER_TYPES } from "../constants";
import { ReportTopFilter } from "../ReportTopFilter/ReportTopFilter";

export const UsersRegistrationReport = () => {
  
  const [topFilterVariables, setTopFilterVariables] = useState(TOP_FILTER);
  const [filterVariablesTypes, setFilterVariablesTypes] = useState(FILTER_INTERNAL_USERS);

  const [isAllPeriodDateRangeData, setAllPeriodDateRangeData] = useState(false);
  const [isDateRangePickerOpen, setDateRangePickerOpen] = useState(false);

  const {from:topFilterFrom, to: topFilterTo} = topFilterVariables; 
  const isDatePickerActive = (!!topFilterFrom || !!topFilterTo) && !isAllPeriodDateRangeData;
  const isSchoolSpaceType = topFilterVariables.spaceType === PaymentSpaceType.School;

  const [loadRegisteredUsers, { data: registeredUsersData, loading: registeredUsersLoading, error: registeredUsersError }] = useLazyQuery<GetRegisteredUsersAdminReportsQuery>(
    GetRegisteredUsersAdminReportsDocument, {
    fetchPolicy: 'cache-and-network',
  });
  
  useEffect(() => {
    if (topFilterFrom && topFilterTo) {
      loadRegisteredUsers({
        variables: {
          topFilter: topFilterVariables,
          filter: filterVariablesTypes,
        }
      });
    }
   }, [topFilterVariables, filterVariablesTypes]);

  const registeredUsersReport = registeredUsersData?.getRegisteredUsersAdminReports.dataChart || null;
  const totalRegisteredUsers = registeredUsersData?.getRegisteredUsersAdminReports.dataTotal;

  const { requestError, setRequestError, errorMessage } = useRequestError(registeredUsersError, ERROR_MESSAGE_USERS);
  
  const variant = REPORT_VARIANTS.registeredUsers;
  const chartLabels = getChartLabelsData(registeredUsersReport, filterVariablesTypes.periodType);
  const chartQuantity = getChartQuantityData( totalRegisteredUsers, variant.metric);

  const handleSetTopFilterVariables = (newValue) => {
    setTopFilterVariables((prevState) => ({ ...prevState, ...newValue }));
  }

  const dataReportTopFilter = {
    handleDateRangePickerOpen: setDateRangePickerOpen,
    handleAllPeriodDateRangeData: setAllPeriodDateRangeData,
    isDatePickerActive: isDatePickerActive,
    titleDatePickerButton: "Период",
    titleDateButton: "За всё время",
    spaceFilterFieldsData: SPACE_FILTER_TYPES,
    productFilterFieldsData: USERS_PRODUCT_FILTER_TYPES,
    isDateRangePickerOpen: isDateRangePickerOpen,
    isAllPeriodDateRangeData,
    setTopFilterVariables: handleSetTopFilterVariables,
    variant,
  };

  const dataVerticalChartRegisteredUsers = {
    chartHeader: {
      chartTitle: "Новые зарегистрированные пользователи",
      chartSubtitle: "Общее количество новых зарегистрированных пользователей",
      totalValue: chartQuantity,
    },
    chart: chartLabels,
    chartFilter: {
      period: PERIOD_FILTER_TYPES,
      activePeriod: filterVariablesTypes.periodType,
      usersType: USERS_FILTER_TYPES,
      setInternalChartFilter: setFilterVariablesTypes,
      variant,
      isSchoolSpaceType,
    },
    isLoadingProgress: registeredUsersLoading,
    message: getMessage(totalRegisteredUsers),
  };
  

  return (
    <ReportsContainer>

      <ReportTopFilter {...dataReportTopFilter} />

      <VerticalBarChart {...dataVerticalChartRegisteredUsers} />
      
      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
      
    </ReportsContainer>
  );
};
