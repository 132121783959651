import React, { useState, useEffect } from "react";

import { Snackbar, Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useMutation } from "@apollo/client";
import { GetUserPayrollDocument, UpdateUserPayrollDocument, UpdateUserPayrollMutation } from "../../generated/graphql";
import { SalaryInputField } from "./SalaryInput.style";


interface ISalaryInputProps {
  editedFieldName: string;
  value?: string | number;
  chosenPayroll?: string;
}


export const SalaryInput = ({ editedFieldName, value, chosenPayroll }: ISalaryInputProps) => {
  const [state, setState] = useState(value);
  const [isError, setError] = useState(false);

  useEffect(() => {
    setState(value);
  }, [value])

  const valueType = typeof value;

  const [updatePayroll, { data, loading, error }] = useMutation<UpdateUserPayrollMutation>(
    UpdateUserPayrollDocument,
    {
      variables: {
        payrollData: {
          id: chosenPayroll,
          [editedFieldName]: valueType === 'number' ? +state : state,
        }
      },
      refetchQueries: [{
        query: GetUserPayrollDocument,
        variables: { payrollId: chosenPayroll },
      }]
    }
  );

  const updatePayrollHandler = ():void => {
    if (!value && state) {
      updatePayroll();
      return;
    }

    if (value.toString() !== state) {
      updatePayroll()
    }
  }

  useEffect(() => {
    if (error) {
      setError(true);
    }

    if (data) {
      setError(false);
    }
  }, [error, data])

  return (
    <Box display='flex' alignItems='center' height={'100%'} justifyContent='center'>
      <SalaryInputField
        type={valueType === 'number' ? 'number' : 'string'}
        disabled={loading}
        value={state}
        onChange={({ target: { value } }) => setState(value)}
        onBlur={updatePayrollHandler}
      />

      <Snackbar
        open={isError}
        autoHideDuration={6000}
        onClose={() => setError(false)}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      >
        <Alert severity='error'>{error?.message}</Alert>
      </Snackbar>
    </Box>
  )
}