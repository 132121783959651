import styled from "styled-components";


export const FeedBackNavBarBlock = styled.div<{ primary?: boolean }>`
  display: flex;
  margin: 40px 0 40px 0;
  padding: 14px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  justify-items: center;
`;