import React, { useState, useEffect } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetUsersDocument,
  GetUsersQuery,
  User,
  UserJob,
  GetBaseKnowledgeBaseCategoriesDocument,
  KnowledgeBaseCategory,
  SetKnowledgeBaseCategoryParticipantsMutation,
  SetKnowledgeBaseCategoryParticipantsDocument,
} from "../../../generated/graphql";

import { Dialog, FormControlLabel, Radio, CircularProgress, ListItem, Checkbox, Snackbar } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert } from "@material-ui/lab";

import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";
import { LoadingBox } from "../../shared/Style/Style";
import { DEFAULT_AVATAR_URL } from "../../shared/constants";

import {
  AddUsersToNotificationModalContent,
  BtnsWrapper,
  UsersFilterForm,
  UsersFilterFormLabel,
  UsersFilters,
  UsersFilter,
  MainList,
  UserLabel,
  UserAvatarXS,
  UserLabelText,
  UsersGroupButton,
  UsersGroupByJob,
  UsersGroupList,
  DropdownIconWrapper,
  UserListItem,
  ButtonCancelFixedWidth,
  ButtonFixedWidth,
} from "../ParticipantsModal.style";

import { AvailableForToggler } from "../AvailableForToggler";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";


interface IKnowledgeBaseParticipantsModalProps {
  category: KnowledgeBaseCategory;
  participants: [] | string[];
  setParticipants(ids: string[]): void;
  open: boolean;
  close(): void;
}

export const KnowledgeBaseParticipantsModal = ({
  open,
  close,
  category,
  participants,
  setParticipants
}: IKnowledgeBaseParticipantsModalProps) => {
  const [activeFilter, setActiveFilter] = useState<string | undefined>(undefined);
  const [togglerActive, setTogglerActive] = useState(true);

  const [chosenJob, setChosenJob] = useState<UserJob | null>(null);

  const choseJobHandler = (job: UserJob) => {
    if (job.id === chosenJob?.id) {
      setChosenJob(null);
      return;
    }

    setChosenJob(job);
  }

  // #region set participants
  const [availableFor, setAvailableFor] = useState<string | null>('ALL');

  const changeParticipantStatusHandler = (id: string) => {
    if (!id) {
      return;
    }

    if (
      participants?.some(participant => participant === id)
    ) {
      setParticipants(participants.filter(participant => participant !== id));
      return;
    }

    if (participants && participants?.length) {
      setParticipants([...participants, id]);
      return;
    }

    setParticipants([id]);
  }

  const [
    SetCategoryParticipants,
    {
      data: setParticipantsData,
      loading: setParticipantsLoading,
    }
  ] = useMutation<SetKnowledgeBaseCategoryParticipantsMutation>(
    SetKnowledgeBaseCategoryParticipantsDocument, {
      refetchQueries: [{ query: GetBaseKnowledgeBaseCategoriesDocument }]
    }
  );

  const setParticipantsHandler = async() => {
    try {
      if (!availableFor && !participants) {
        return;
      }

      let knowledgeBaseCategoryData;

      availableFor
        ? knowledgeBaseCategoryData = {
            id: category?.id,
            availableFor,
          }
        : knowledgeBaseCategoryData = {
            id: category?.id,
            participants: participants,
            availableFor: null,
          }

      await SetCategoryParticipants({
        variables: {
          knowledgeBaseCategoryData: {
            ...knowledgeBaseCategoryData,
          }
        }
      })
    } catch (e) {
      setParticipantsErrorSnackbarOpen(true);
    }
  };

  const [isParticipantsErrorSnackbarOpen, setParticipantsErrorSnackbarOpen] = useState(false);

  useEffect(() => {
    if (setParticipantsData) {
      close();
      setParticipantsErrorSnackbarOpen(false);
      setChosenJob(null);
      setActiveFilter(undefined);
      setTogglerActive(true);
      setAvailableFor('ALL');
    }
  }, [setParticipantsData])


  // #endregion

  const [getUsers, { loading, error, data }] = useLazyQuery<GetUsersQuery>(GetUsersDocument);

  let content;

  if (loading) {
    content =
      <LoadingBox>
        <CircularProgress color='inherit' />
      </LoadingBox>
  }

  if (error) {
    content = <ErrorSnackbar error={error}/>
  }

  if (data && !availableFor) {
    const users = data?.getUsers?.users as User[];

    interface usersByJob {
      job: UserJob;
      users: User[];
    }

    let usersByJobs = [] as usersByJob[];

    if (activeFilter === 'STAFF') {
      users.forEach((user: User) => {
        if (user.job && !usersByJobs.find(({ job }: usersByJob) => job.id === user.job?.id)) {
          usersByJobs.push({job: user.job, users: [user]});
          return;
        }

        if (user.job && usersByJobs.find(({ job }: usersByJob) => job.id === user.job?.id)) {
          usersByJobs?.find(({ job }: usersByJob) => job.id === user.job?.id)?.users.push(user)
        }
      });
    }


    content = (
      <>
        {activeFilter === 'STAFF'
          ? (usersByJobs?.length
            ? (<MainList>
              {usersByJobs.map(({ job, users }: usersByJob) => (
                <UsersGroupByJob
                  key={job.id}
                  expanded={job.id === chosenJob?.id}
                  usersCount={users.length}
                >
                  <UsersGroupButton
                    onClick={() => choseJobHandler(job)}
                  >
                    <span>
                      {job.name}
                    </span>

                    <DropdownIconWrapper
                      expanded={job.id === chosenJob?.id}
                    >
                      <ExpandMoreIcon />
                    </DropdownIconWrapper>
                  </UsersGroupButton>

                  <UsersGroupList>
                    {users.map(({ id, photo, firstName, lastName }: User) => (
                      <ListItem
                        key={`${job.id} ${firstName} ${lastName}`}
                      >
                        <FormControlLabel
                          value="end"
                          control={
                            <Checkbox
                              disabled={setParticipantsLoading}
                              onChange={() => changeParticipantStatusHandler(id)}
                              checked={participants?.some(participant => participant === id)}
                              color="default"
                            />
                          }
                          label={
                            <UserLabel>
                              <UserAvatarXS src={photo || DEFAULT_AVATAR_URL}/>
                              <UserLabelText primary={`${lastName} ${firstName}`} />
                            </UserLabel>
                          }
                        />
                      </ListItem>
                      ))}
                    </UsersGroupList>
                  </UsersGroupByJob>
                ))}
              </MainList>
            ) : <p>Нет доступных пользователей</p>
          )
          : ( users?.length
            ? (
              <MainList>
                {users.map(({ id, photo, firstName, lastName }: User, i: number) => (
                  <ListItem
                    button
                    key={`${i} ${firstName} ${lastName}`}
                  >
                    <UserListItem
                      value="end"
                      control={
                        <Checkbox
                          disabled={setParticipantsLoading}
                          onChange={() => changeParticipantStatusHandler(id)}
                          checked={participants?.some(participant => participant === id)}
                          color="default"
                        />
                      }
                      label={
                        <UserLabel>
                          <UserAvatarXS src={photo || DEFAULT_AVATAR_URL} />
                          <UserLabelText primary={`${lastName} ${firstName}`} />
                        </UserLabel>
                      }
                    />
                  </ListItem>
                ))}
              </MainList>
            ) : <p>Нет доступных пользователей</p>
          )
        }

      </>
    );
  }

  return (
    <Dialog
      open={open}
      TransitionProps={{timeout: 300}}
    >
      <AddUsersToNotificationModalContent>
        <UsersFilterForm>
          <UsersFilterFormLabel>Отметьте пользователей</UsersFilterFormLabel>
          <UsersFilters
            aria-label="users filter"
            value={activeFilter || ''}
            onChange={({ target: { value } }) => {
              setTogglerActive(true);

              if (!value) {
                setActiveFilter(undefined);
                return;
              }

              setActiveFilter(value);
            }}
          >
            <UsersFilter value={''} control={<Radio />} label="Все" />
            <UsersFilter value="STAFF" control={<Radio />} label="Сотрудники" />
            <UsersFilter value="CLIENT" control={<Radio />} label="Клиенты" />
          </UsersFilters>
        </UsersFilterForm>

        <AvailableForToggler
          activeFilter={activeFilter}
          setAvailableFor={setAvailableFor}
          getUsers={getUsers}
          togglerActive={togglerActive}
          setTogglerActive={setTogglerActive}
        />

        {content}

        <BtnsWrapper>
          <ButtonCancelFixedWidth
            disabled={setParticipantsLoading}
            onClick={close}
          >
            отмена
          </ButtonCancelFixedWidth>

          <ButtonFixedWidth
            disabled={setParticipantsLoading}
            onClick={setParticipantsHandler}
          >
            ок
          </ButtonFixedWidth>
        </BtnsWrapper>
      </AddUsersToNotificationModalContent>

      <ErrorMessageSnackbar
        open={isParticipantsErrorSnackbarOpen}
        closeHandler={() => setParticipantsErrorSnackbarOpen(false)}
        message='При изменении доступа произошла ошибка'
      />
    </Dialog>
  )
}
