import { Box, Typography } from "@material-ui/core";
import {
  VerticalChartContainer,
  VerticalChartQuantity,
  VerticalChartSubTitle,
  VerticalChartTitle,
} from "../../shared/Style/Style";
import InfoIcon from "@material-ui/icons/Info";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from "../../Gamification/Economics/ChapterBlock/shared";

type TooltipPlacementType =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";

interface VerticalBarChartContainerProps {
  chartTitle: string;
  chartSubtitle: string;
  totalValue: string;
  children?: React.ReactNode | React.ReactNode[] | null;
  isTooltipVisible?: boolean;
  tooltipText?: string;
  tooltipPlacement?: TooltipPlacementType;
}

export const VerticalBarChartContainer: React.FC<VerticalBarChartContainerProps> =
  ({
    chartTitle,
    chartSubtitle,
    totalValue,
    children,
    isTooltipVisible = false,
    tooltipText,
    tooltipPlacement = "bottom",
  }) => {
    return (
      <VerticalChartContainer>
        <VerticalChartTitle>
          <Typography variant="subtitle1" align="center">
            {chartTitle}
          </Typography>

          {isTooltipVisible && (
            <Box position="absolute" right={10} top={15}>
              <Tooltip title={tooltipText} placement={tooltipPlacement} arrow>
                <IconButton $iconSize={16}>
                  <InfoIcon style={{ color: "#FFB61D" }} />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </VerticalChartTitle>
        <VerticalChartSubTitle>
          <Typography variant="h6">{chartSubtitle}</Typography>
        </VerticalChartSubTitle>
        <VerticalChartQuantity>
          <Typography variant="h3">{totalValue}</Typography>
        </VerticalChartQuantity>
        {children}
      </VerticalChartContainer>
    );
  };
