import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
  ModalInput,
} from "../../shared/Style/Style";
import {
  CreateEventDateDocument,
  CreateEventDateMutation,
  EventDate,
  GetEventDocument,
} from "../../../generated/graphql";
import { useMutation } from "@apollo/client";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";

interface ICreateEventDate {
  open: boolean;
  eventId: string;
  requestError: boolean;
  errorMessage: string;
  close(): void;
  setErrorMessage(errorMessage: string): void;
  setRequestError(requestError: boolean): void;
}

export const CreateEventDateModal = ({
  open,
  close,
  eventId,
  requestError,
  setRequestError,
  errorMessage,
  setErrorMessage,
}: ICreateEventDate) => {
  const [eventDate, setEventDate] = useState<EventDate>(null);

  const [
    createEventDate,
    {
      data: createEventDateData,
      loading: createEventDateLoading,
      error: createEventDateError,
    },
  ] = useMutation<CreateEventDateMutation>(CreateEventDateDocument, {
    variables: {
      date: eventDate?.date,
      link: eventDate?.link,
      userLimit: +eventDate?.userLimit,
      eventId,
    },
    refetchQueries: [
      {
        query: GetEventDocument,
        variables: { id: eventId },
      },
    ],
  });
  const closeHandler = () => {
    setEventDate(null);
    close();
  };

  const createEventDateHandler = async () => {
    try {
      await createEventDate();
    } catch (e) {
      setRequestError(true);
      setErrorMessage("При создании произошла ошибка");
    }
  };

  useEffect(() => {
    if (createEventDateData) {
      setEventDate(null);
      close();
    }
  }, [createEventDateData]);

  return (
    <Dialog open={open}>
      <DialogContent style={{ minWidth: "400px" }}>
        <CloseIconButton close={closeHandler} />
        <Box textAlign="center" mb={2}>
          <DialogTitle>Дата и ссылка на мероприятие</DialogTitle>
        </Box>

        <ModalInput
          style={{ marginBottom: "10px" }}
          fullWidth
          id="datetime-local"
          label="Дата и Время"
          type="datetime-local"
          value={eventDate?.date}
          onChange={({ target: { value } }) =>
            setEventDate((prev) => ({ ...prev, date: value }))
          }
          InputLabelProps={{
            shrink: true,
          }}
        />

        <ModalInput
          style={{ marginBottom: "10px" }}
          fullWidth
          label="Ссылка"
          value={eventDate?.link}
          onChange={({ target: { value } }) =>
            setEventDate((prev) => ({ ...prev, link: value }))
          }
        />
        <ModalInput
          style={{ marginBottom: "10px" }}
          fullWidth
          label="Лимит по количеству людей"
          value={eventDate?.userLimit}
          onChange={({ target: { value } }) =>
            setEventDate((prev) => ({ ...prev, userLimit: +value }))
          }
        />
        <ButtonsWrapper>
          <CancelButton onClick={closeHandler}>Отмена</CancelButton>

          <ButtonCustom onClick={createEventDateHandler}>Создать</ButtonCustom>
        </ButtonsWrapper>
      </DialogContent>
      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Dialog>
  );
};
