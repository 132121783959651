import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container } from "@material-ui/core";
import { MobileNotificationStatisticRow } from "../SmartNotification.style";
import { Header, LoadingBox, MainTableCell } from "../../shared/Style/Style";
import { StatisticColumn } from "./StatisticColumn";
import { useQuery } from "@apollo/client";
import {
  GetAnswersWithPercentsBySmartNotificationIdDocument,
  GetAnswersWithPercentsBySmartNotificationIdQuery,
  GetSmartNotificationByIdDocument,
  GetSmartNotificationByIdQuery,
} from "../../../generated/graphql";
import { useParams } from "react-router-dom";
import { convertDateStringToLocaleHandler } from "../../shared/Utils/ConvertOperations/convertDateStringToLocaleHandler";
import { convertDateAndTimeToLocaleHandler } from "../../shared/Utils/ConvertOperations/convertTimeToLocalHandler";

import { SmartNotificationRatting } from "./SmartNotificationRatting";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";
import { PieChartAdapter } from "../../Charts/PieBarChartAdapter";
import { options } from "../../Charts/chartConfig";

export const SmartNotificationStatisticPollList = () => {
  const { pollId } = useParams<{ pollId: string }>();
  const [requestError, setRequestError] = useState(false);
  const barColors = ["#FFC97E", "#94E492", "#0046fc", "#fac804"];

  const { data, loading, error } = useQuery<GetSmartNotificationByIdQuery>(
    GetSmartNotificationByIdDocument,
    {
      variables: {
        id: pollId,
      },
    }
  );
  const {
    data: answerData,
    loading: answerLoading,
    error: answerError,
  } = useQuery<GetAnswersWithPercentsBySmartNotificationIdQuery>(
    GetAnswersWithPercentsBySmartNotificationIdDocument,
    {
      variables: {
        smartNotificationId: pollId,
      },
    }
  );

  useEffect(() => {
    if (data || answerData) {
      setRequestError(false);
    }
  }, [data, answerData]);

  const disableOnLoading = loading || answerLoading;

  let content;

  if (disableOnLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }
  if (error || answerError) {
    content = <ErrorSnackbar error={error || answerError} />;
  }

  if (data) {
    const { createdDate, description, isPublished, type, pollLists } =
      data.getSmartNotification;

    const answers = answerData?.getAnswersWithPercentsBySmartNotificationId;
    const totals = answers?.answers?.map((getTotal) => getTotal.total);

    content = (
      <>
        <Box>
          <MobileNotificationStatisticRow>
            <MainTableCell>
              {convertDateStringToLocaleHandler(createdDate)}
            </MainTableCell>
            <MainTableCell>
              {convertDateAndTimeToLocaleHandler(createdDate)}
            </MainTableCell>
            <MainTableCell>{description}</MainTableCell>
            <MainTableCell>
              {(isPublished && "Опубликованно") || "Не опубликованно"}
            </MainTableCell>
          </MobileNotificationStatisticRow>
        </Box>
        <Box display="flex">
          {type.length && type.includes("POLL") ? (
            <>
              <Box flex="0 0 50%">
                {pollLists.map(({ id }) => (
                  <StatisticColumn key={id} id={id} />
                ))}
              </Box>
              <Box flex="0 0 50%">
                {totals?.length > 0 ? (
                  <PieChartAdapter
                    options={options}
                    data={{
                      datasets: [
                        {
                          backgroundColor: barColors,
                          data: totals,
                        },
                      ],
                    }}
                  />
                ) : (
                  <PieChartAdapter
                    options={options}
                    data={{
                      datasets: [
                        {
                          backgroundColor: barColors,
                          data: [1, 1],
                        },
                      ],
                    }}
                  />
                )}
              </Box>
            </>
          ) : (
            type.includes("RATING") && (
              <Box width="100%">
                <SmartNotificationRatting answers={answers} pollId={pollId} />
              </Box>
            )
          )}
        </Box>
      </>
    );
  }

  return (
    <Container>
      <Header>Статистика</Header>
      {content}
      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={"При загрузке данных произошла ошибка"}
      />
    </Container>
  );
};
