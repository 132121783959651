import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useGrayBackgroundDictionaryStyles } from "../Style/Style";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { ProjectDataForRequest } from "../../OverdueTasks/OverdueTasks";

interface DictionaryProjectSelectProps {
  values: any[];
  error?: boolean;
  customClasses?: ClassNameMap<"root">;
  projectData?: ProjectDataForRequest;
  setProjectId?(status: ProjectDataForRequest): void;
}

export const DictionaryProjectSelect = ({
  values,
  customClasses,
  projectData,
  setProjectId,
}: DictionaryProjectSelectProps) => {
  const [id, setId] = useState<number>();

  const classes = useGrayBackgroundDictionaryStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setId(event.target.value as number);
    setProjectId({ ...projectData, projectId: event.target.value as string });
  };
  return (
    <FormControl
      className={customClasses ? customClasses.root : classes.root}
      variant="filled"
    >
      {!id && <InputLabel>Выберите проект...</InputLabel>}
      <Select
        className={classes.root}
        value={id}
        onChange={handleChange}
        fullWidth
        style={{
          backgroundColor: "inherit",
        }}
      >
        {values.map((value) => {
          return (
            <MenuItem key={value.id} value={value.id}>
              {value.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
