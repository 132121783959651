import styled from "styled-components";
import { IconButton } from "@material-ui/core";

interface IArrowIconButtonProps {
  disabled?: boolean;
  customColor?: string;
}

export const ArrowIconButton = styled(IconButton)<IArrowIconButtonProps>`
  padding: 0;
  color: ${props => props.customColor ? props.customColor : 'white'};
  opacity: ${props => props.disabled ? 0.7 : 1};
  transition: opacity 0.5s;
`;