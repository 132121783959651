import React from "react";
import { Tabs } from "@material-ui/core";
import { CustomTab, TabIndicatorProps } from "./Styles";

interface TabList {
  tabName: string;
  TabPanel: JSX.Element;
}

interface BasicTabsProps {
  tabList?: TabList[];
  activeTab: number;
  onTabChange: (newValue: number) => void;
}

export const BasicTabs = ({
  tabList,
  activeTab,
  onTabChange,
}: BasicTabsProps) => {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    onTabChange(newValue);
  };

  if (!tabList) return null;

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        TabIndicatorProps={TabIndicatorProps}
      >
        {tabList.map(({ tabName }, index) => (
          <CustomTab
            key={tabName}
            label={tabName}
            $active={activeTab === index}
          />
        ))}
      </Tabs>
      {tabList.map(({ tabName, TabPanel }, index) => (
        <div key={tabName} hidden={activeTab !== index}>
          {activeTab === index && TabPanel}
        </div>
      ))}
    </>
  );
};
