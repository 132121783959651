import { useRef, ChangeEvent } from "react";
import { validate as validateUUID } from "uuid";
import { useReactiveVar } from "@apollo/client";
import { mobileAppModel } from "../../state";
import { ContentTypeEnum } from "../../../../../generated/graphql";
import { Delete } from "@material-ui/icons";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  CurrentBundleContainer,
  MobileAppModalBundleName,
} from "../../../MobileAppModal.style";
import {
  ButtonCustom,
  ImportFileUpload,
  ModalWithPhotoInput as EqualIdInput,
  PrimaryLink,
} from "../../../../shared/Style/Style";
import {
  acceptedColoringPageFileExtension,
  BundleNames,
} from "../../constants";
import { getTextHelperEqualId, validateColoringPageFile } from "../../utils";

export const AppModelColoringFile = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const name = BundleNames.ColoringBookAR;
  const isLoading = useReactiveVar(mobileAppModel.updateLoading);
  const inputErrors = useReactiveVar(mobileAppModel.inputErrors);
  const model = useReactiveVar(mobileAppModel.model);

  if (!model) return null;
  const { coloringPageFile, contentType, libraryContent } = model;
  const { equalId } = libraryContent;
  const isColoringPageExperiment =
    contentType?.name === ContentTypeEnum.ColoringPageAr;

  const handleFileInputChange = async ({
    target: { files },
  }: ChangeEvent<HTMLInputElement>) => {
    const file = files[0];
    const [hasError, errorMessage] = validateColoringPageFile(file);

    if (hasError) return mobileAppModel.setError(hasError, errorMessage);

    await mobileAppModel.updateColoringPageFile(file);
  };

  return (
    <>
      <EqualIdInput
        disabled={isLoading}
        fullWidth
        required
        label="equalID"
        value={equalId}
        helperText={getTextHelperEqualId(equalId)}
        error={inputErrors.libraryContent.error}
        onChange={({ target: { value } }) => {
          mobileAppModel.setEqualId(value);
        }}
        onFocus={() => mobileAppModel.setInputErrors("libraryContent", false)}
      />
      <CurrentBundleContainer>
        <MobileAppModalBundleName>
          <PrimaryLink>{name}</PrimaryLink>
        </MobileAppModalBundleName>
        {!coloringPageFile ? (
          <Tooltip title={acceptedColoringPageFileExtension}>
            <ButtonCustom
              disabled={!isColoringPageExperiment || isLoading}
              onClick={() => inputRef.current.click()}
            >
              <span>Прикрепить</span>
              <ImportFileUpload
                type="file"
                accept={acceptedColoringPageFileExtension}
                ref={inputRef}
                onChange={handleFileInputChange}
              />
            </ButtonCustom>
          </Tooltip>
        ) : (
          <span>
            <PrimaryLink href={coloringPageFile}>{name}</PrimaryLink>

            <IconButton
              disabled={isLoading}
              onClick={() => mobileAppModel.deleteColoringPageFile()}
            >
              <Delete color="secondary" />
            </IconButton>
          </span>
        )}
      </CurrentBundleContainer>
    </>
  );
};
