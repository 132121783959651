import React from "react";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  Select,
} from "@material-ui/core";
import { FieldInputProps, FieldMetaState } from "react-final-form";
import {
  City,
  UserAgreementType,
  UserDepartment,
  UserJob,
  UserPosition,
  UserRole,
  UserStatus,
  UserAgreement,
} from "../../../generated/graphql";
import { useStyles } from "../../shared/Style/Style";

interface DictionarySelectProps {
  label: string;
  values:
    | UserPosition[]
    | UserJob[]
    | UserStatus[]
    | UserRole[]
    | UserAgreementType[]
    | City[]
    | UserDepartment[]
    | UserAgreement[];
  input: FieldInputProps<any, HTMLElement>;
  meta: FieldMetaState<any>;
  setUserRoleId?(id: number): void;
}

export function DictionarySelect({
  label,
  values,
  input,
  meta,
  setUserRoleId,
}: DictionarySelectProps) {
  const classes = useStyles();
  const id = input?.value?.id?.toString();

  return (
    <FormControl variant="filled" error={meta.error} fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        className={classes.root}
        value={id}
        onBlur={input.onBlur}
        onChange={(e) => {
          input.onChange({
            id: e.target.value,
          });

          if (setUserRoleId) {
            setUserRoleId(e.target.value as number)
          }
        }}
        fullWidth
      >
        {values.map((value: any) => {
          return (
            <MenuItem key={value.id} value={value.id}>
              {value.displayName || value.name}
            </MenuItem>
          );
        })}
      </Select>
      {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
}
