import React, { useState, useEffect } from "react";

import { useMutation } from "@apollo/client";
import {
  GetNotificationsByManagerDocument,
  Maybe,
  Notification,
  UpdateNotificationDocument,
  UpdateNotificationMutation,
} from "../../generated/graphql";

import { Dialog, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';

import { ButtonCustom, ButtonsWrapper, CancelButton } from "../shared/Style/Style";

import { NotificationModalContent, NotificationModalInput, CloseBtn } from "./NotificationModal.style";

import { AddUsersToNotificationModal } from "../ParticipantsModal/AddUsersToNotificationModal";


interface INotificationModalProps {
  notification: Notification | null | undefined;
  open: boolean;
  close(): void;
}

export const NotificationModal = ({ notification, open, close }: INotificationModalProps) => {
  const [ name, setName ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ participants, setParticipants ] = useState<(Maybe<string> | undefined)[] | null | undefined | []>([]);

  const [ addUsersModalOpen, setAddUsersModalOpen ] = useState(false);

  const openAddUsersModalHandler = () => {
    setAddUsersModalOpen(true);
  }

  const closeAddUsersModalHandler = () => {
    setAddUsersModalOpen(false);
  }

  // #region update notification

  const [
    updateNotification,
    {
      data: updateNotificationData,
      loading: updateNotificationLoading,
      error: updateNotificationError
    }
  ] = useMutation<UpdateNotificationMutation>(
    UpdateNotificationDocument,
    {
      variables: {
        notificationData: {
          id: notification?.id,
          name,
          description,
        },
      },
      refetchQueries: [{
        query: GetNotificationsByManagerDocument,
        variables: { limit: 1000, skip: 0, isPublished: null },
      }],
    },
  );

  const [openUpdateErrorSnackbar, setOpenUpdateErrorSnackbar] = useState(false);

  useEffect(() => {
    if (updateNotificationError) {
      setOpenUpdateErrorSnackbar(true);
    }
  }, [updateNotificationError]);

  const [updateInputErrorsSnackbar, setUpdateInputErrorsSnackbar] = useState(false);

  const [updateNotificationInputErrors, setUpdateNotificationInputErrors] = useState({
    name: false,
    description: false,
  });

  const updateNotificationHandler = () => {
    if (!name) {
      setUpdateNotificationInputErrors(state => ({
        ...state,
        name: true,
      }))
    }

    if (!description) {
      setUpdateNotificationInputErrors(state => ({
        ...state,
        description: true,
      }))
    }

    if (!name || !description) {
      setUpdateInputErrorsSnackbar(true);
      return;
    }

    updateNotification();

    setUpdateNotificationInputErrors({
      name: false,
      description: false,
    });
  }

  // #endregion

  useEffect(() => {
    setName(notification?.name || '')
    setDescription(notification?.description || '')

    notification?.participants
      ? setParticipants(notification?.participants.map(participant => participant?.user?.id))
      : setParticipants([]);

    if (updateNotificationData) {
      close();
    }
  }, [notification?.id, notification?.name, notification?.description, notification?.participants, updateNotificationData]);

  return (
    <Dialog
      open={open}
      TransitionProps={{timeout: 300}}
    >
      <NotificationModalContent>
        <CloseBtn
          disabled={updateNotificationLoading}
          onClick={close}
        >
          <CloseIcon />
        </CloseBtn>

        <NotificationModalInput
          label='Введите название уведомления'
          value={name}
          onFocus={() => setUpdateNotificationInputErrors({
            ...updateNotificationInputErrors,
            name: false,
          })}
          onChange={({ target: { value }} ) => setName(value)}
        />

        <NotificationModalInput
          label='Введите текст уведомления'
          value={description}
          multiline={true}
          onFocus={() => setUpdateNotificationInputErrors({
            ...updateNotificationInputErrors,
            description: false,
          })}
          onChange={({ target: { value }} ) => setDescription(value)}
        />

        <ButtonCustom
          disabled={updateNotificationLoading}
          onClick={openAddUsersModalHandler}
        >
          Добавить участников
        </ButtonCustom>

        <ButtonsWrapper>
          <CancelButton
            disabled={updateNotificationLoading}
            onClick={close}
          >
            Отменить
          </CancelButton>

          <ButtonCustom
            disabled={updateNotificationLoading}
            onClick={updateNotificationHandler}
          >
            Сохранить
          </ButtonCustom>
        </ButtonsWrapper>
      </NotificationModalContent>

      <AddUsersToNotificationModal
        open={addUsersModalOpen}
        close={closeAddUsersModalHandler}
        notification={notification}
        participants={participants}
        setParticipants={setParticipants}
      />

      {(updateNotificationInputErrors.name || updateNotificationInputErrors.description)
        && (
          <Snackbar
            open={updateInputErrorsSnackbar}
            autoHideDuration={6000}
            onClose={() => setUpdateInputErrorsSnackbar(false)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert severity='error'>
              <p>{updateNotificationInputErrors.name && 'Отсутствует название уведомления'}</p>
              <p>{updateNotificationInputErrors.description && 'Отсутствует текст уведомления'}</p>
            </Alert>
          </Snackbar>
        )
      }

      <Snackbar
        open={openUpdateErrorSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenUpdateErrorSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity='error'>
          При сохранении уведомления произошла ошибка!
        </Alert>
      </Snackbar>
    </Dialog>
  )
}
