import {
  ArtifactTypeEnum,
  GameModelArtifactFileTypeEnum,
  GameModelStateFileTypeEnum
} from "../../../generated/graphql";
import { BundleNames } from "../../MobileAppModal/MobileAppModelModal/constants";
import { ArtifactsNameEnum } from "./types";

export const BundleTypesMap = new Map<BundleNames, GameModelStateFileTypeEnum>([
  [BundleNames.Ios, GameModelStateFileTypeEnum.IosBundle],
  [BundleNames.Android, GameModelStateFileTypeEnum.AndroidBundle],
  [BundleNames.IosManifest, GameModelStateFileTypeEnum.IosManifest],
  [BundleNames.AndroidManifest, GameModelStateFileTypeEnum.AndroidManifest],
]);

export const ArtifactNamesMap = new Map<ArtifactsNameEnum, ArtifactTypeEnum>([
  [ArtifactsNameEnum.Head, ArtifactTypeEnum.Cloth],
  [ArtifactsNameEnum.Body, ArtifactTypeEnum.Cloth],
  [ArtifactsNameEnum.Legs, ArtifactTypeEnum.Cloth],
  [ArtifactsNameEnum.Things, ArtifactTypeEnum.Good],
  [ArtifactsNameEnum.Animations, ArtifactTypeEnum.Animation],
]);

export const ArtifactModelTitles = new Map<ArtifactTypeEnum, string>([
  [ArtifactTypeEnum.Cloth, "одежды"],
  [ArtifactTypeEnum.Good, "предмета"],
  [ArtifactTypeEnum.Animation, "анимации"],
]);

export const ArtifactBundleTypesMap = new Map<BundleNames, GameModelArtifactFileTypeEnum>([
  [BundleNames.Ios, GameModelArtifactFileTypeEnum.IosBundle],
  [BundleNames.Android, GameModelArtifactFileTypeEnum.AndroidBundle],
  [BundleNames.IosManifest, GameModelArtifactFileTypeEnum.IosManifest],
  [BundleNames.AndroidManifest, GameModelArtifactFileTypeEnum.AndroidManifest],
]);
