import { Box } from "@material-ui/core";
import { AddVideoInput } from "./AddVideoInput";
import { initialData, inputsArray } from "./constants";
import { useGetRobotsHistoryQuery } from "../../../../generated/graphql";
import { HistoryLink } from "./HistoryLink";
import { findItemByOrder } from "./utils";
import { SuccessSnackbar } from "../../../shared/SuccessSnackbar";
import { useState } from "react";

export const VideoInputs = () => {
  const { data, loading, refetch } = useGetRobotsHistoryQuery();
  const robotsHistory = data?.getRobotsHistory ?? initialData;

  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(undefined);

  const handleRefetch = async (message) => {
    await refetch();
    setIsSnackBarOpen(true);
    setSnackBarMessage(message);
  };

  const handleCloseSnackbar = () => {
    setIsSnackBarOpen(false);
  };

  if (loading) return null;

  return (
    <>
      <Box width="100%" display="flex" flexDirection="column" gridGap={10}>
        {inputsArray.map((_, order) => {
          const existItem = findItemByOrder(order, robotsHistory);
          const component = existItem ? (
            <HistoryLink item={existItem} handleRefetch={handleRefetch} />
          ) : (
            <AddVideoInput order={order} handleRefetch={handleRefetch} />
          );

          return <div key={order}>{component}</div>;
        })}
      </Box>
      <SuccessSnackbar
        open={isSnackBarOpen}
        handleClose={handleCloseSnackbar}
        message={snackBarMessage}
        snackbarProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        }}
      />
    </>
  );
};
