import React, { useContext, useEffect, useState } from "react";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CreateProjectTaskInKanbanMutation,
  GetProjectStepsByProjectLightDocument,
  GetProjectStepsByProjectLightQuery,
  GetUsersForDropdownDocument,
  GetUsersForDropdownQuery,
  GetProjectTasksByWorkflowStepDocument,
  CreateProjectTaskInKanbanDocument,
  GetProjectTaskQuery,
  GetProjectTaskDocument,
  UpdateProjectTaskMutation,
  UpdateProjectTaskDocument,
  ProjectTaskCheckListItem,
  ProjectStep,
  ProjectTaskComment,
  User,
} from "../../generated/graphql";

import { UserInfoContext } from "../Main";

import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { CloseIconButton } from "../shared/Buttons/CloseIconButton";
import { DEFAULT_AVATAR_URL } from "../shared/constants";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";

import { Checklist } from "./Parts/Checklist";
import { Comments } from "./Parts/Comments";
import { Labels } from "./Parts/Labels";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";

import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
  defaultMaterialTheme,
  LoadingBox,
  ModalInput,
} from "../shared/Style/Style";

import {
  KanbanModalSubtitle,
  KanbanModalText,
  KanbanModalUserAvatar,
} from "./KanbanTaskModal.style";

import { inputNumberCheckHandler } from "../shared/Utils/CheckOperations/inputNumberCheckHandler";
import { nullifyFalseValuesHandler } from "../shared/Utils/OtherOperations/nullifyFalseValuesHandler";
import { convertISOStringToDateWithOffset } from "../shared/Utils/ConvertOperations/convertISOStringToDateWithOffset";

interface IKanbanModalProps {
  open: boolean;
  close(): void;
  projectId: string;
  taskId?: string;
  workflowStepId: string;
  workflowStepOrder: number;
}

interface ITaskData {
  name: string;
  epicId: string;
  description: string;
  assigneeId: string;
  responsibleId: string;
  startDateEstimate: Date;
  startDateFact: Date;
  finishDateEstimate: Date;
  finishDateFact: Date;
  timeEstimate: string;
  timeSpent: string;
}

const initialTaskData: ITaskData = {
  name: "",
  epicId: "",
  description: "",
  assigneeId: "",
  responsibleId: "",
  startDateEstimate: null,
  startDateFact: null,
  finishDateEstimate: null,
  finishDateFact: null,
  timeEstimate: '0',
  timeSpent: '0',
};

const initialErrors: { [key: string]: boolean } = {
  name: false,
  epicId: false,
};

export const KanbanTaskModal = ({
  open,
  close,
  projectId,
  taskId,
  workflowStepId,
  workflowStepOrder,
}: IKanbanModalProps) => {
  const author = useContext<User>(UserInfoContext);

  const [taskData, setTaskData] = useState(initialTaskData);
  const [createdTaskId, setCreatedTaskId] = useState("");
  const [taskItems, setTaskItems] = useState<ProjectTaskCheckListItem[]>([]);
  const [taskComments, setTaskComments] = useState<ProjectTaskComment[]>([]);
  const [errors, setErrors] = useState(initialErrors);

  const [errorMessage, setErrorMessage] = useState("");
  const [requestError, setRequestError] = useState(false);

  const closeHandler = (): void => {
    setTaskData(initialTaskData);
    setErrors(initialErrors);
    setTaskItems([]);
    setTaskComments([]);
    setErrorMessage("");
    setRequestError(false);
    setCreatedTaskId("");
    close();
  };

  const getEpicValue = (epics: ProjectStep[]): string => {
    if (taskId) {
      return epics.find((epic) => epic.key === taskData.epicId)?.key || "";
    }

    return epics.find((epic) => epic.id === taskData.epicId)?.key || "";
  };

  const [
    createTask,
    {
      data: createTaskData,
      loading: createTaskLoading,
      error: createTaskError,
    },
  ] = useMutation<CreateProjectTaskInKanbanMutation>(
    CreateProjectTaskInKanbanDocument,
    {
      refetchQueries: [
        {
          query: GetProjectTasksByWorkflowStepDocument,
          variables: {
            projectId,
            workflowStepId,
            limit: 1000,
            skip: 0,
          },
        },
      ],
    }
  );

  const [
    updateTask,
    {
      data: updateTaskData,
      loading: updateTaskLoading,
      error: updateTaskError,
    },
  ] = useMutation<UpdateProjectTaskMutation>(UpdateProjectTaskDocument, {
    refetchQueries: [
      {
        query: GetProjectTasksByWorkflowStepDocument,
        variables: {
          projectId,
          workflowStepId,
          limit: 1000,
          skip: 0,
        },
      },
    ],
  });

  const saveTaskHandler = () => {
    const data = Object.entries({
      name: taskData.name,
      epicId: taskData.epicId,
    });

    data.forEach(([key, value]) => {
      if (!value) {
        setErrors((state) => ({
          ...state,
          [key]: true,
        }));
      }
    });

    if (data.some(([_, value]) => !value)) {
      return;
    }

    taskId || createdTaskId
      ? updateTask({
          variables: {
            taskData: {
              id: taskId || createdTaskId,
              ...nullifyFalseValuesHandler(taskData),
              timeEstimate: taskData.timeEstimate ? Math.round(+taskData.timeEstimate * 60) : undefined,
              timeSpent: taskData.timeSpent ? Math.round(+taskData.timeSpent * 60) : undefined,
              epicId: undefined,
            },
          },
        })
      : createTask({
        variables: {
          taskData: {
            ...nullifyFalseValuesHandler(taskData),
            timeEstimate: taskData.timeEstimate ? Math.round(+taskData.timeEstimate * 60) : undefined,
            timeSpent: taskData.timeSpent ? Math.round(+taskData.timeSpent * 60) : undefined,
          },
          workflowStepId,
          workflowStepOrder,
        },
      });
  };

  const [
    getTask,
    {
      data: editedTaskData,
      loading: editedTaskLoading,
      error: editedTaskError,
    },
  ] = useLazyQuery<GetProjectTaskQuery>(GetProjectTaskDocument, {
    fetchPolicy: "cache-and-network",
  });

  const {
    data: projectStepsData,
    loading: projectStepsLoading,
    error: projectStepsError,
  } = useQuery<GetProjectStepsByProjectLightQuery>(
    GetProjectStepsByProjectLightDocument,
    {
      variables: { projectId },
    }
  );

  const [
    getUsers,
    { data: usersData, loading: usersLoading, error: usersError },
  ] = useLazyQuery<GetUsersForDropdownQuery>(GetUsersForDropdownDocument, {
    variables: {
      getUsersData: {
        limit: 100,
        skip: 0,
        statuses: ["VERIFIED"],
      },
    },
  });

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (taskId) {
      getTask({
        variables: {
          id: taskId,
        },
      });
    }
  }, [taskId]);

  useEffect(() => {
    if (createTaskData || updateTaskData) {
      let id;
      id = createTaskData
        ? createTaskData?.createProjectTaskInKanban.id
        : updateTaskData?.updateProjectTask.id;
      setCreatedTaskId(id);
      getTask({ variables: { id: id } });
    }
  }, [createTaskData, updateTaskData]);

  useEffect(() => {
    if (editedTaskData) {
      const task = editedTaskData.getProjectTask;

      task?.items && setTaskItems(task.items);

      task?.comments && setTaskComments(task.comments);

      setTaskData({
        name: task?.name || "",
        description: task?.description || "",
        epicId: task?.epicKey || "",
        assigneeId: task?.assigneeId || "",
        responsibleId: task?.responsibleId || "",
        startDateEstimate: task?.startDateEstimate
          ? convertISOStringToDateWithOffset(task.startDateEstimate)
          : null,
        startDateFact: task?.startDateFact
          ? convertISOStringToDateWithOffset(task?.startDateFact)
          : null,
        finishDateEstimate: task?.finishDateEstimate
          ? convertISOStringToDateWithOffset(task?.finishDateEstimate)
          : null,
        finishDateFact: task?.finishDateFact
          ? convertISOStringToDateWithOffset(task?.finishDateFact)
          : null,
        timeEstimate: (task?.timeEstimate / 60).toFixed(2) || '0',
        timeSpent: (task?.timeSpent / 60).toFixed(2) || '0',
      });
    }
  }, [editedTaskData, taskId]);

  useEffect(() => {
    if (createTaskError || updateTaskError) {
      setRequestError(true);
      setErrorMessage("При сохранении задачи произошла ошибка");
    }
  }, [createTaskError, updateTaskError]);

  let content: JSX.Element;

  const disabledOnLoading = createTaskLoading || updateTaskLoading;

  if (projectStepsLoading || usersLoading || editedTaskLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (projectStepsError || usersError || editedTaskError) {
    content = (
      <ErrorSnackbar
        error={projectStepsError || usersError || editedTaskError}
      />
    );
  }

  if (projectStepsData && usersData) {
    const epics = projectStepsData.getProjectStepsByProject;
    const users = usersData.getUsers.users;
    const creator = editedTaskData?.getProjectTask?.createdBy;
    const labels = editedTaskData?.getProjectTask?.labels;

    content = (
      <DialogContent>
        <ThemeProvider theme={defaultMaterialTheme}>
          <CloseIconButton close={closeHandler} disabled={disabledOnLoading} />

          <DialogTitle>Создать задачу</DialogTitle>

          <Box display="flex" justifyContent="space-between">
            <Box flexBasis={"565px"} mr={7.5}>
              <Box display="flex" alignItems="center">
                <Box mr={1.5} mt={"14px"}>
                  <KanbanModalSubtitle>Этап</KanbanModalSubtitle>
                </Box>

                <Autocomplete
                  fullWidth
                  disabled={disabledOnLoading || !!taskId}
                  options={epics.map((epic) => epic.key)}
                  getOptionLabel={(option) =>
                    epics.find((epic) => epic.key === option)?.name || ""
                  }
                  value={getEpicValue(epics)}
                  onChange={(_, value) => {
                    if (taskId) {
                      return;
                    }

                    if (typeof value === "string") {
                      setErrors({ ...errors, epicId: false });
                      setTaskData({
                        ...taskData,
                        epicId: epics.find((epic) => epic.key === value)?.id,
                      });
                    }

                    if (!value) {
                      setErrors({ ...errors, epicId: false });
                      setTaskData({ ...taskData, epicId: "" });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.epicId}
                      label="Название этапа"
                    />
                  )}
                />
              </Box>

              <Box mt={"10px"}>
                <ModalInput
                  label="Заголовок"
                  disabled={disabledOnLoading}
                  fullWidth
                  value={taskData.name}
                  onChange={({ target: { value } }) => {
                    setTaskData({ ...taskData, name: value });
                    setErrors({ ...errors, name: false });
                  }}
                  error={errors.name}
                />
              </Box>

              <Box mt={"10px"}>
                <ModalInput
                  label="Описание"
                  disabled={disabledOnLoading}
                  value={taskData.description}
                  onChange={({ target: { value } }) =>
                    setTaskData({ ...taskData, description: value })
                  }
                  fullWidth
                  multiline
                />
              </Box>

              {(taskId || createdTaskId) && (
                <>
                  <Checklist
                    disabledOnLoading={disabledOnLoading}
                    items={taskItems}
                    taskId={taskId || createdTaskId}
                    description={editedTaskData?.getProjectTask?.description}
                    saveTaskHandler={saveTaskHandler}
                  />
                  <Comments
                    disabledOnLoading={disabledOnLoading}
                    author={author}
                    comments={taskComments}
                    taskId={taskId || createdTaskId}
                    description={editedTaskData?.getProjectTask?.description}
                    saveTaskHandler={saveTaskHandler}
                  />
                </>
              )}
            </Box>

            <Box
              flexBasis={"443px"}
              display="grid"
              gridTemplateColumns="1fr 3fr"
              gridTemplateRows="repeat(10, min-content)"
              gridGap="20px 25px"
            >
              <Box mt="24px">
                <KanbanModalSubtitle>Исполнитель</KanbanModalSubtitle>
              </Box>

              <Autocomplete
                fullWidth
                disabled={disabledOnLoading}
                options={users.map((user) => user.id)}
                getOptionLabel={(option) => {
                  const user = users.find((user) => user.id === option);
                  return user ? `${user?.lastName} ${user?.firstName}` : "";
                }}
                value={taskData.assigneeId}
                onChange={(_, value) => {
                  if (typeof value === "string") {
                    setTaskData({ ...taskData, assigneeId: value });
                  }

                  if (!value) {
                    setTaskData({ ...taskData, assigneeId: "" });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Исполнитель" />
                )}
              />

              <Box mt="24px">
                <KanbanModalSubtitle>Ответственный</KanbanModalSubtitle>
              </Box>

              <Autocomplete
                fullWidth
                disabled={disabledOnLoading}
                options={users.map((user) => user.id)}
                getOptionLabel={(option) => {
                  const user = users.find((user) => user.id === option);
                  return user ? `${user?.lastName} ${user?.firstName}` : "";
                }}
                value={taskData.responsibleId}
                onChange={(_, value) => {
                  if (typeof value === "string") {
                    setTaskData({ ...taskData, responsibleId: value });
                  }

                  if (!value) {
                    setTaskData({ ...taskData, responsibleId: "" });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Ответственный" />
                )}
              />

              {!!taskId && (
                <>
                  <KanbanModalSubtitle>Автор</KanbanModalSubtitle>

                  <Box margin={"auto 0"} display="flex">
                    {taskId && creator && (
                      <>
                        <KanbanModalUserAvatar
                          prim={creator?.photo || DEFAULT_AVATAR_URL}
                        />

                        <KanbanModalText>
                          {creator?.lastName} {creator?.firstName}
                        </KanbanModalText>
                      </>
                    )}
                  </Box>
                </>
              )}

              {(taskId || createdTaskId) && (
                <Labels
                  projectId={projectId}
                  projectTaskId={taskId || createdTaskId}
                  disabledOnLoading={disabledOnLoading}
                  labels={labels}
                />
              )}

              <KanbanModalSubtitle>Плановая дата начала</KanbanModalSubtitle>

              <KeyboardDatePicker
                autoOk
                fullWidth
                disabled={disabledOnLoading}
                format="dd.MM.yyyy"
                value={taskData.startDateEstimate}
                onChange={(date) =>
                  setTaskData({ ...taskData, startDateEstimate: date })
                }
              />

              <KanbanModalSubtitle>Плановая дата конца</KanbanModalSubtitle>

              <KeyboardDatePicker
                fullWidth
                autoOk
                disabled={disabledOnLoading}
                minDate={taskData.startDateEstimate}
                format="dd.MM.yyyy"
                value={taskData.finishDateEstimate}
                onChange={(date) =>
                  setTaskData({ ...taskData, finishDateEstimate: date })
                }
              />

              <KanbanModalSubtitle>Фактическая дата начала</KanbanModalSubtitle>

              <KeyboardDatePicker
                fullWidth
                autoOk
                disabled={disabledOnLoading}
                format="dd.MM.yyyy"
                value={taskData.startDateFact}
                onChange={(date) =>
                  setTaskData({ ...taskData, startDateFact: date })
                }
              />

              <KanbanModalSubtitle>Фактическая дата конца</KanbanModalSubtitle>

              <KeyboardDatePicker
                fullWidth
                autoOk
                disabled={disabledOnLoading}
                format="dd.MM.yyyy"
                minDate={taskData.startDateFact}
                value={taskData.finishDateFact}
                onChange={(date) =>
                  setTaskData({ ...taskData, finishDateFact: date })
                }
              />

              <KanbanModalSubtitle>Длительность план</KanbanModalSubtitle>

              <ModalInput
                disabled={disabledOnLoading}
                fullWidth
                value={taskData.timeEstimate}
                onChange={({ target: { value } }) => {
                  inputNumberCheckHandler(value ? value : '0') && setTaskData({
                    ...taskData,
                    timeEstimate: value,
                  })
                }}
                InputProps={{
                  endAdornment: "ч.",
                }}
              />

              <KanbanModalSubtitle>Затраченное время</KanbanModalSubtitle>

              <ModalInput
                disabled={disabledOnLoading}
                fullWidth
                value={taskData.timeSpent}
                onChange={({ target: { value } }) => {
                  inputNumberCheckHandler(value ? value : '0') && setTaskData({
                    ...taskData,
                    timeSpent: value,
                  })
                }}
                InputProps={{
                  endAdornment: "ч.",
                }}
              />
            </Box>
          </Box>

          <ButtonsWrapper>
            <CancelButton onClick={closeHandler} disabled={disabledOnLoading}>
              Отмена
            </CancelButton>

            <ButtonCustom
              disabled={disabledOnLoading}
              onClick={saveTaskHandler}
            >
              сохранить
            </ButtonCustom>
          </ButtonsWrapper>
        </ThemeProvider>
      </DialogContent>
    );
  }

  return (
    <Dialog open={open} transitionDuration={500} maxWidth={false}>
      <Box maxWidth={1140}>{content}</Box>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Dialog>
  );
};
