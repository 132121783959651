import React, { useEffect, useState } from "react";

import { useQuery, useLazyQuery } from "@apollo/client";
import { GetEmployeeWorkloadReportByJobsDocument, GetEmployeeWorkloadReportByJobsQuery, GetEmployeeWorkloadReportByUserDocument, GetEmployeeWorkloadReportByUserQuery, EmployeeWorkloadReportByUser } from "../../../../generated/graphql";
import { IDateRange } from "../../../shared/DateRangePicker/DateRangePicker";

import {  Table, TableBody, TableRow, CircularProgress } from "@material-ui/core";
import { LoadingBox } from "../../../shared/Style/Style";

import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { ReportModal } from "../../../ReportModal";
import { CustomTable } from "../../../shared/CustomTable";

import {
  CustomTableCell,
  CustomTableCellNoBottom,
  CustomTableHead,
  CustomTableHeaderCell,
  CustomTableRow,
} from "../../../shared/CustomTable/CustomTable.style";

const headers = [
  "Должность",
  "Сотрудники",
  "Количество проектов",
  "По всем проектам",
];

const headersSecondRow = [
  "Часов",
  "Дней",
  "Недель",
  "Месяцев",
];

export const EmployeesWorkloadReport = ({ dateRange }: {dateRange: IDateRange}) => {
  const [jobName, setJobName] = useState('');
  const [user, setUser] = useState<EmployeeWorkloadReportByUser | null>(null);
  const [openModal, setOpenModal] = useState(false);

  // #region users list

  const [
    getUsersList,
    {
      data: workloadUsersData,
      loading: workloadUsersLoading,
      error: workloadUsersError,
    }
  ] = useLazyQuery<GetEmployeeWorkloadReportByUserQuery>(
    GetEmployeeWorkloadReportByUserDocument, {
      variables: { jobName }
    }
  );

  useEffect(() => {
    if (!!jobName) {
      getUsersList();
    }
  }, [jobName]);

  let usersList: JSX.Element;

  if (workloadUsersLoading) {
    usersList = (
      <TableRow>
        <CustomTableCell colSpan={7}>
          <LoadingBox>
            <CircularProgress color='inherit' />
          </LoadingBox>
        </CustomTableCell>
      </TableRow>
    )
  }

  if (workloadUsersError) {
    usersList = (
      <TableRow>
        <CustomTableCell colSpan={7}>
          При загрузке данных произошла ошибка
        </CustomTableCell>
      </TableRow>
    )
  }

  if (workloadUsersData) {
    const users = workloadUsersData.getEmployeeWorkloadReportByUser;

    usersList = (
      <>
        {users.map((user, i) => (
          <CustomTableRow
            clickable={true}
            key={user.userId}
            onClick={() => {
              setUser(user);
              setOpenModal(true);
            }}
          >
            {users.length - 1 !== i ? <CustomTableCellNoBottom /> : <CustomTableCell/>}
            {[
              user.userName,
              user.projectCount,
              user.totalHours,
              user.totalDays,
              user.totalWeeks,
              user.totalMonth
            ].map((field, i) => (
              <CustomTableCell
                key={i}
              >
                {field}
              </CustomTableCell>
            ))}
          </CustomTableRow>
        ))}
      </>
    )
  }

  // #endregion

  const {
    data: workloadData,
    loading: workloadLoading,
    error: workloadError,
  } = useQuery<GetEmployeeWorkloadReportByJobsQuery>(
    GetEmployeeWorkloadReportByJobsDocument
  );

  let content;

  if (workloadLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit' />
      </LoadingBox>
    )
  }

  if (workloadError) {
    content = <ErrorSnackbar error={workloadError} />
  }

  if (workloadData) {
    const jobs = workloadData.getEmployeeWorkloadReportByJobs;

    content = (
      <Table>
        <CustomTableHead>
          <TableRow>
            {headers.map((header, i) => (
              <CustomTableHeaderCell
                key={header}
                colSpan={headers.length - 1 === i ? 4 : 1}
                width={headers.length - 1 === i ? 100 : 300}
              >
                {header}
              </CustomTableHeaderCell>
            ))}
          </TableRow>

          <TableRow>
            <CustomTableCell colSpan={3}/>
            {headersSecondRow.map((header) => (
              <CustomTableHeaderCell
                key={header}
                width={100}
              >
                {header}
              </CustomTableHeaderCell>
            ))}
          </TableRow>
        </CustomTableHead>

        <TableBody>
          {jobs.length
            ? jobs.map((job) => (
              <>
                <CustomTableRow
                  clickable={true}
                  onClick={() => {
                    setJobName(job.jobName);
                  }}
                >
                  <CustomTableCell>
                    {job.jobName}
                  </CustomTableCell>

                  <CustomTableCell colSpan={2} />
                  <CustomTableCell>{job.totalHours}</CustomTableCell>
                  <CustomTableCell>{job.totalDays}</CustomTableCell>
                  <CustomTableCell>{job.totalWeeks}</CustomTableCell>
                  <CustomTableCell>{job.totalMonth}</CustomTableCell>
                </CustomTableRow>

                {jobName === job.jobName && usersList}
              </>
            ))
            : (
              <TableRow>
                <CustomTableCell colSpan={7}>Профессии отсутствуют</CustomTableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    )
  }

  return (
    <>
      <CustomTable data={workloadData?.getEmployeeWorkloadReportByJobs} tableContent={content} />
      {openModal && user && (
        <ReportModal
          title={user.userName}
          reportType='employee'
          open={openModal}
          closeHandler={() => {
            setOpenModal(false);
            setUser(null);
          }}
          user={user}
          dateRange={dateRange}
        />
      )}
    </>
  );
};
