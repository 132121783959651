import styled from "styled-components";
import { lightBlack } from "../Style/Style";

export const SpeedometerContainer = styled.div`
  position: relative;
  width: 454px;
  height: 454px;
`;

export const SpeedometerArrowContainer = styled.div`
  position: absolute;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  left: 127px;
  top: 127px;
  transition: transform 1s;
`;

export const PercentItem1 = styled.span`
  position: absolute;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: ${lightBlack};
  top: 325px;
  left: 50px;
`;

export const PercentItem2 = styled.span`
  position: absolute;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: ${lightBlack};
  top: 135px;
  left: 23px;
`;

export const PercentItem3 = styled.span`
  position: absolute;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: ${lightBlack};
  top: 25px;
  left: 210px;
`;

export const PercentItem4 = styled.span`
  position: absolute;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: ${lightBlack};
  top: 135px;
  right: 23px;
`;

export const PercentItem5 = styled.span`
  position: absolute;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: ${lightBlack};
  top: 325px;
  right: 35px;
`;

export const CostField = styled.div`
  max-width: unset;
  position: absolute;
  bottom: 40px;
  right: 50%;
  transform: translateX(50%);
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;

  &::after {
    content: '';
    display: block;
    height: 2px;
    width: 100%;
    background-color: #101010;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
`;