import React, {ChangeEvent, FocusEvent} from "react";
import {ContainerTextArea, DEFAULT_INLINE_STYLE_CONFIG, Label, StyledTextAreaAutosize} from "./Style";

interface TextareaResizeProps {
  value: string;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  minRows?: number;
  style?: {[key: string]: string};
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus: (e: FocusEvent<HTMLTextAreaElement>) => void;
}

export const TextareaResize = ({
 value,
 disabled,
 error,
 label,
 style,
 minRows,
 onChange,
 onFocus,
}: TextareaResizeProps) => {
  return (
    <ContainerTextArea>
      <Label>{label}</Label>
      <StyledTextAreaAutosize
        style={style || DEFAULT_INLINE_STYLE_CONFIG}
        value={value}
        disabled={disabled}
        err={error}
        minRows={minRows}
        onChange={onChange}
        onFocus={onFocus}
      />
    </ContainerTextArea>
  )
}