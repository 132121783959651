import React, { SyntheticEvent } from "react";
import { CustomIconButtonBody } from "./CustomIconButton.style";

interface ICustomIconButtonProps {
  disabled?: boolean;
  disabledColor?: string;
  mainColor?: string;
  bgColor?: string;
  fz?: number;
  callback(e: SyntheticEvent): void;
  children: React.ReactNode
}

export const CustomIconButton = ({
  disabled,
  callback,
  mainColor,
  bgColor,
  disabledColor,
  fz,
  children
}: ICustomIconButtonProps) => {
  return (
    <CustomIconButtonBody
      disabled={disabled}
      disabled_color={disabledColor}
      main_color={mainColor}
      bg_color={bgColor}
      fz={fz}
      onClick={(e) => callback(e)}
    >
      {children}
    </CustomIconButtonBody>
  )
}