import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import {
  CreateKnowledgeBaseCategoryDocument,
  CreateKnowledgeBaseCategoryMutation,
  GetBaseKnowledgeBaseCategoriesDocument,
  KnowledgeBaseCategory,
  KnowledgeBaseCategoryIsForEnum,
  KnowledgeBaseTheme,
  UpdateKnowledgeBaseCategoryDocument,
  UpdateKnowledgeBaseCategoryMutation,
} from "../../../generated/graphql";

import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";

import { Box, Dialog, DialogContent, DialogTitle, FormControlLabel } from "@material-ui/core";

import { ButtonCustom, ButtonsWrapper, CancelButton, ModalInput, primary } from "../../shared/Style/Style";
import { CheckBoxCheck } from "../../NewsModal/NewsModal.style";
import { CustomRadioControl } from "../../shared/CustomRadioGroup/CustomRadioGroup.style";
import { AvatarPicker } from "../../shared/AvatarPicker";


const backgrounds = [
  {
    name: 'Голубой',
    color: '#69B4EB'
  },
  {
    name: 'Фиолетовый',
    color: '#C5BAFD'
  },
  {
    name: 'Желтый',
    color: '#FBDEAF'
  }
]


interface IKnowledgeBaseMainModalProps {
  open: boolean;
  close(): void;
  isTheme: boolean;
  category?: KnowledgeBaseCategory;
  theme?: KnowledgeBaseTheme;
}


export const KnowledgeBaseMainModal = ({
  open,
  close,
  isTheme,
  category,
  theme
}: IKnowledgeBaseMainModalProps) => {
  const isEditing = category && !isTheme;

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isFor, setIsFor] = useState(KnowledgeBaseCategoryIsForEnum.AdminPanel);
  const [backgroundColor, setBackgroundColor] = useState(backgrounds[0].color);
  const [photo, setPhoto] = useState<File>(null);

  console.log(category, backgroundColor);

  const errorHandler = (message: string) => {
    setRequestError(true);
    setErrorMessage(message);
  };

  const closeHandler = () => {
    setName('');
    setRequestError(false);
    setErrorMessage('');
    close();
  };

  const [createCategory, {
    data: createCategoryData,
    loading: createCategoryLoading
  }] = useMutation<CreateKnowledgeBaseCategoryMutation>(CreateKnowledgeBaseCategoryDocument, {
    variables: {
      name,
      isFor,
      photo,
      backgroundColor
    },
    refetchQueries: [{ query: GetBaseKnowledgeBaseCategoriesDocument }]
  });

  const [updateCategory, {
    data: updateCategoryData,
    loading: updateCategoryLoading
  }] = useMutation<UpdateKnowledgeBaseCategoryMutation>(UpdateKnowledgeBaseCategoryDocument, {
    variables: {
      id: category?.id,
      name,
      isFor,
      photo,
      backgroundColor
    },
    refetchQueries: [{ query: GetBaseKnowledgeBaseCategoriesDocument }]
  });


  const submitHandler = async () => {
    if (!name) {
      setNameError(true);
      return;
    }

    try {
      if (isEditing && !isTheme) {
        await updateCategory()
      } else {
        await createCategory()
      }
    } catch (e) {
      errorHandler(
        `При ${isEditing ? 'редактировании' : 'создании'} ${isTheme ? 'категории' : 'категории'} произошла ошибка`
      )
    }

  };

  //#region effects

  useEffect(() => {
    if (isEditing) {
      setName(isTheme ? theme?.name : category?.name);
      setBackgroundColor(category?.backgroundColor);
      setIsFor(isTheme ? KnowledgeBaseCategoryIsForEnum.AdminPanel : category?.isFor);
      return
    }

    setName('');
    setBackgroundColor(backgrounds[0].color);
    setIsFor(KnowledgeBaseCategoryIsForEnum.AdminPanel);
  }, [isEditing, category])

  useEffect(() => {
    if (createCategoryData || updateCategoryData ) {
      closeHandler();
    }
  }, [createCategoryData, updateCategoryData])

  //#endregion

  const disableOnLoading = createCategoryLoading || updateCategoryLoading


  return (
    <Dialog
      open={open}
    >
      <CloseIconButton
        close={closeHandler}
        disabled={disableOnLoading}
      />

      <DialogContent
        style={{
          width: '570px'
        }}
      >
        <DialogTitle>{isEditing ? 'Редактировать' : 'Создать'} {isTheme ? 'тему' : 'категорию'}</DialogTitle>

        <Box
          padding='0 24px'
        >
          {!isTheme && (
            <Box
              display='flex'
              justifyContent='center'
            >
              <AvatarPicker
                uploadHandler={photo => setPhoto(photo)}
                photo={category?.photo}
                disabled={disableOnLoading}
              />
            </Box>
          )}

          <ModalInput
            label='Название'
            disabled={disableOnLoading}
            error={nameError}
            onFocus={() => setNameError(false)}
            value={name}
            onChange={({ target: { value } }) => setName(value)}
          />

          <Box mt={2} >Опубликовать в ...</Box>

          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box display="flex" alignItems="center">
              <CheckBoxCheck
                checked={KnowledgeBaseCategoryIsForEnum.LkTeacherGuide ===isFor}

                onChange={() =>
                  setIsFor(KnowledgeBaseCategoryIsForEnum.LkTeacherGuide)
                }
                value={KnowledgeBaseCategoryIsForEnum.LkTeacherGuide}
                name="radio-buttons"
              />

              <Box >Личный кабинет учителя</Box>

              <CheckBoxCheck
                checked={KnowledgeBaseCategoryIsForEnum.AdminPanel ===isFor}
                onChange={() =>
                  setIsFor(KnowledgeBaseCategoryIsForEnum.AdminPanel)
                }
                value={KnowledgeBaseCategoryIsForEnum.AdminPanel}
                name="radio-buttons"
              />

              <Box>Личный кабинет сотрудника</Box>
            </Box>
          </Box>

          {!isTheme && (
            <>
              <Box m='16px 0 0'>Выберите цвет карточки:</Box>

              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                p='0 12px'
              >
                {backgrounds.map(({ name, color }) => (
                  <FormControlLabel
                    key={name}
                    style={{ color }}
                    control={
                      <CustomRadioControl
                        value={color}
                        checked={backgroundColor === color}
                        disabled={disableOnLoading}
                        onChange={() => setBackgroundColor(color)}
                        style={{ color }}
                      />
                    }
                    label={name}
                  />
                ))}
              </Box>
            </>
          )}

          <ButtonsWrapper>
            <CancelButton
              onClick={closeHandler}
              disabled={disableOnLoading}
            >
              Отменить
            </CancelButton>

            <ButtonCustom
              main_color={primary}
              onClick={submitHandler}
              disabled={disableOnLoading}
            >
              Сохранить
            </ButtonCustom>
          </ButtonsWrapper>
        </Box>
      </DialogContent>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Dialog>
  )
}
