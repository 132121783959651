import styled from "styled-components";
import {Cell} from "../../../shared/Style/Style";

export const CreateVacancyPlanModalContainer = styled.div`
  width: 300px;
  height: 300px;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  position: relative;
  border-radius: 10px;
`;

export const ModalHeader = styled.h3`
  color: #000;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
`;

export const DataPickerContainer = styled.h3`
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const HeaderCell = styled(Cell)`
  border-left: none;
  border-bottom: none;
  border-top: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgba(196, 196, 196, 0.3);
  &:last-of-type {
    border-right: none;
  }
`;

export const ModalErrorTxt = styled.span`
  position: absolute;
  display: block;
  color: #E85050;
  font-size: 14px;
  bottom: 15px;
`;
