import React from "react";

import { useQuery } from "@apollo/client";
import { GetUsersForEmployeesReportDocument, GetUsersForEmployeesReportQuery } from "../../../../generated/graphql";

import { Table, TableBody, TableRow, CircularProgress } from "@material-ui/core";

import { CustomTable } from "../../../shared/CustomTable";
import { LoadingBox } from "../../../shared/Style/Style";
import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";

import { CustomTableCell, CustomTableHead, CustomTableHeaderCell } from "../../../shared/CustomTable/CustomTable.style";

export const EmployeesReport = () => {
  const headers = [
    'Сотрудники',
    'Город',
    'Должность',
    'Отдел',
    'Принцип организации работы',
    'Оформление',
  ];

  const { data, loading, error } = useQuery<GetUsersForEmployeesReportQuery>(
    GetUsersForEmployeesReportDocument,
    { variables: { getUsersData: { limit: 100, skip: 0, role: 'STAFF', statuses: ['VERIFIED'] } } }
  );

  let content;

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit'/>
      </LoadingBox>
    )
  }

  if (error) {
    content = <ErrorSnackbar error={error}/>
  }

  if (data) {
    const employees = data.getUsers.users;

    content = (
      <Table>
        <CustomTableHead>
          <TableRow>
            {headers.map(header => <CustomTableHeaderCell key={header}>{header}</CustomTableHeaderCell>)}
          </TableRow>
        </CustomTableHead>

        <TableBody>
          {!!employees.length
            ? employees.map(employee => (
                <TableRow key={employee.id}>
                  {[
                    `${employee.lastName} ${employee.firstName}`,
                    employee.city?.name,
                    employee.job?.name,
                    employee.department?.name,
                    `${employee.isRemote ? 'Удаленная' : 'Офис'}`,
                    employee.agreement?.name,
                  ].map(field => (
                    <CustomTableCell>{field}</CustomTableCell>
                  ))}
                </TableRow>
              ))
            : (
              <TableRow>
                <CustomTableCell colSpan={6}>Сотрудники отсутствуют</CustomTableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    )
  }

  return (
    <CustomTable data={data?.getUsers?.users} tableContent={content}/>
  )
}
