interface vacancyData {
  id?: string;
  jobId: string;
  positionId: number | null;
  experience: number;
  statusId: string;
  comment: string;
}

export type State = {
  vacancyData?: vacancyData;
  mutationType?: string;
  createVacancyPlan?: boolean;
  newRow?: boolean;
  newMonth?: boolean;
};

export type Action =
  | { type: "setVacancyData"; payload: vacancyData }
  | { type: "clearVacancyData" }
  | { type: "setMutationType"; payload: string }
  | { type: "setCreateVacancyPlan"; payload: boolean }
  | { type: "createNewRow"; payload: boolean }
  | { type: "setNewMonth"; payload: boolean };

export const initialState: State = {
  vacancyData: {
    id: "",
    jobId: "",
    positionId: null,
    experience: 0,
    statusId: "",
    comment: "",
  },
  mutationType: "",
  createVacancyPlan: false,
  newRow: false,
  newMonth: false,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "setVacancyData":
      return { ...state, vacancyData: action.payload };
    case "clearVacancyData":
      return {
        ...state,
        vacancyData: {
          jobId: "",
          positionId: null,
          experience: 0,
          statusId: "",
          comment: "",
        },
      };

    case "setMutationType":
      return {
        ...state,
        mutationType: action.payload,
      };

    case "setCreateVacancyPlan":
      return {
        ...state,
        createVacancyPlan: action.payload,
      };

    case "createNewRow":
      return {
        ...state,
        newRow: action.payload,
      };

    case "setNewMonth":
      return {
        ...state,
        newMonth: action.payload,
      };

    default:
      throw new Error();
  }
};
