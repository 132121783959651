import {
  DialogContent,
  FormControl,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import styled from "styled-components";

export const ProjectModalContainer = styled.div`
  width: 516px;
  height: 297px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
`;

export const ProjectAddLinkModalContainer = styled.div`
  width: 516px;
  //height: 540px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
`;

export const AddContactContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
  top: 26px;
  width: 298px;
  padding: 20px;
  z-index: 1;
`;

export const AddContactList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ContactListContainer = styled.div`
  height: 235px;
  overflow: auto;
`;

export const ProjectModalDialogContent = styled(DialogContent)`
  min-width: 100%;
`;

export const ProjectModalFormControl = styled(FormControl)`
  min-width: 100%;
  overflow: hidden;
  max-height: 51%;
`;

export const ProjectModalFormGroup = styled(FormGroup)`
  overflow-y: auto;
  flex-wrap: nowrap;
`;

export const ProjectModalFormControlLabel = styled(FormControlLabel)`
  min-width: 100%;
  display: block;
  padding: 0;
`;

export const ProjectModalNoProjects = styled.div`
  margin-top: 15px;
`;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
