import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControlLabel,
  ListItem,
  Radio,
} from "@material-ui/core";
import {
  ButtonCancelFixedWidth,
  ButtonFixedWidth,
  CustomSwitch,
  MainList,
  UserAvatarXS,
  UserLabel,
  UserLabelText,
  UserListItem,
  UsersFilter,
  UsersFilterForm,
  UsersFilters,
} from "../../ParticipantsModal/ParticipantsModal.style";
import {
  AvailableForMobileNotificationEnum,
  GetSmartNotificationDocument,
  GetSmartNotificationQuery,
  GetUsersParticipantsDocument,
  GetUsersParticipantsQuery,
  SetSmartNotificationParticipantsDocument,
  SetSmartNotificationParticipantsMutation,
  UserRoleEnum,
} from "../../../generated/graphql";
import { ButtonsWrapper, LoadingBox } from "../../shared/Style/Style";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { DEFAULT_AVATAR_URL } from "../../shared/constants";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";

interface IAddParcipiantClientModal {
  open: boolean;

  close(): void;

  notificationId: string;
}

const allParticipants = `${UserRoleEnum.Pupil},${UserRoleEnum.Teacher},${UserRoleEnum.Parent},${UserRoleEnum.Guest}`;

export const AddParcipiantClientModal = ({
  open,
  close,
  notificationId,
}: IAddParcipiantClientModal) => {
  const { appId } = useParams<{ appId: string }>();
  const [activeFilter, setActiveFilter] = useState<string | undefined | null>(
    AvailableForMobileNotificationEnum.Everyone
  );
  const [toggleActive, setToggleActive] = useState(true);
  const [participantError, setParticipantError] = useState(false);
  const [participants, setParticipants] = useState<string[]>();

  const {
    data: notificationData,
    loading: notificationDataLoading,
    error: notificationDataError,
  } = useQuery<GetSmartNotificationQuery>(GetSmartNotificationDocument, {
    variables: { id: notificationId },
  });

  const {
    data: participantData,
    loading: participantDataLoading,
    error: participantDataError,
  } = useQuery<GetUsersParticipantsQuery>(GetUsersParticipantsDocument, {
    variables: {
      getUsersData: {
        limit: 1000,
        applicationId: appId,
        statuses: ["VERIFIED"],
        roles:
          activeFilter === AvailableForMobileNotificationEnum.Everyone
            ? allParticipants.split(",")
            : activeFilter,
      },
    },
    fetchPolicy: "network-only",
  });

  const [
    changeNotificationParticipants,
    {
      data: changeNotificationParticipantsData,
      loading: changeNotificationParticipantsLoading,
      error: changeNotificationParticipantsError,
    },
  ] = useMutation<SetSmartNotificationParticipantsMutation>(
    SetSmartNotificationParticipantsDocument,
    {
      refetchQueries: [
        {
          query: GetSmartNotificationDocument,
          variables: { id: notificationId },
        },
      ],
    }
  );
  useEffect(() => {
    if (changeNotificationParticipantsData) {
      closeHandler();
    }
  }, [changeNotificationParticipantsData, changeNotificationParticipantsError]);

  useEffect(() => {
    if (notificationData) {
      setParticipants(
        notificationData.getSmartNotification?.participants.map(
          (participant) => participant.user.id
        )
      );
    }
  }, [notificationData]);

  const changeParticipantStatusHandler = (id: string) => {
    if (participants?.some((participant) => participant === id)) {
      setParticipants(participants.filter((participant) => participant !== id));
      return;
    }

    if (participants && participants?.length) {
      setParticipants([...participants, id]);
      return;
    }
    setParticipants([id]);
  };
  useEffect(() => {
    if (changeNotificationParticipantsData) {
      closeHandler();
    }
  }, [changeNotificationParticipantsData]);

  const closeHandler = () => {
    setToggleActive(true);
    close();
  };

  const assignParticipantsHandler = () => {
    if (!participants?.length && !toggleActive) {
      setParticipantError(true);
      return;
    }

    changeNotificationParticipants({
      variables: {
        notificationData: {
          notificationId,
          availableFor: toggleActive ? activeFilter : null,
          participants: participants,
        },
      },
    });
  };

  const disabledOnLoading =
    participantDataLoading ||
    notificationDataLoading ||
    changeNotificationParticipantsLoading;

  let content;

  if (disabledOnLoading) {
    content = (
      <Box>
        <LoadingBox>
          <CircularProgress color="inherit" />
        </LoadingBox>
      </Box>
    );
  }

  if (participantDataError) {
    content = <ErrorSnackbar error={participantDataError} />;
  }

  if (participantData) {
    const allUsers = participantData.getUsers.users;

    content =
      !toggleActive &&
      (!!allUsers.length ? (
        <MainList>
          {allUsers.map(({ id, photo, firstName, lastName }) => (
            <ListItem button key={id}>
              <UserListItem
                value="end"
                control={
                  <Checkbox
                    disabled={disabledOnLoading}
                    onChange={() => {
                      setParticipantError(false);
                      changeParticipantStatusHandler(id);
                    }}
                    checked={participants?.some(
                      (participant) => participant === id
                    )}
                    color="default"
                  />
                }
                label={
                  <UserLabel>
                    <UserAvatarXS src={photo || DEFAULT_AVATAR_URL} />
                    <UserLabelText primary={`${lastName} ${firstName}`} />
                  </UserLabel>
                }
              />
            </ListItem>
          ))}
        </MainList>
      ) : (
        <p>Нет доступных пользователей</p>
      ));
  }

  return (
    <Dialog open={open}>
      <Box minHeight={"500px"} p="20px">
        <CloseIconButton close={closeHandler} />
        <DialogTitle style={{ padding: 0, marginBottom: "20px" }}>
          Отметьте участников
        </DialogTitle>
        <UsersFilterForm width={"auto"}>
          <UsersFilters
            aria-label="users filter"
            value={activeFilter}
            onChange={({ target: { value } }) => setActiveFilter(value)}
          >
            <UsersFilter
              value={AvailableForMobileNotificationEnum.Everyone}
              control={<Radio />}
              label="Все"
            />
            <UsersFilter
              value={AvailableForMobileNotificationEnum.Pupil}
              control={<Radio />}
              label="Ученики"
            />
            <UsersFilter
              value={AvailableForMobileNotificationEnum.Teacher}
              control={<Radio />}
              label="Учителя"
            />
            <UsersFilter
              value={AvailableForMobileNotificationEnum.Parent}
              control={<Radio />}
              label="Родители"
            />
            <UsersFilter
              value={AvailableForMobileNotificationEnum.Guest}
              control={<Radio />}
              label="Гость"
            />
          </UsersFilters>
        </UsersFilterForm>
        <FormControlLabel
          style={{ display: "block" }}
          control={
            <CustomSwitch
              disabled={disabledOnLoading}
              checked={toggleActive}
              onChange={() => {
                setParticipantError(false);
                setToggleActive(!toggleActive);
              }}
            />
          }
          label="Выбрать всех"
        />

        <Box minHeight={"240px"}>{content}</Box>

        <ButtonsWrapper>
          <ButtonCancelFixedWidth
            disabled={disabledOnLoading}
            onClick={closeHandler}
          >
            отмена
          </ButtonCancelFixedWidth>

          <ButtonFixedWidth
            disabled={disabledOnLoading}
            onClick={() => assignParticipantsHandler()}
          >
            ок
          </ButtonFixedWidth>
        </ButtonsWrapper>
      </Box>
      <ErrorMessageSnackbar
        open={participantError}
        closeHandler={() => setParticipantError(false)}
        message='Выберите хотя бы одного пользователя или переключитесь на "Выбрать всех"'
      />
    </Dialog>
  );
};
