import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container, Table } from "@material-ui/core";
import {
  ButtonPlus,
  Header,
  LoadingBox,
  MainTableCell,
  MainTableHeaderTitle,
  MainTableStatusCell,
} from "../shared/Style/Style";
import { useHistory } from "react-router-dom";
import {
  CreateTraningDocument,
  CreateTraningMutation,
  DeleteTraningDocument,
  DeleteTraningMutation,
  GetTraningsDocument,
  GetTraningsQuery,
  PublishTraningDocument,
  PublishTraningMutation,
  Traning,
  UnpublishTraningDocument,
  UnpublishTraningMutation,
} from "../../generated/graphql";
import { useMutation, useQuery } from "@apollo/client";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { CustomTablePagination } from "../shared/CustomTablePagination";
import { TrainingTableRows } from "./Training.style";
import { EmptyMobileAppTableRow } from "../MobileApps/MobileApps.style";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { Menu } from "../shared/Menu";
import { TrainingModalName } from "./TrainingModalName/TrainingModalName";

const headers = ["Название", "Статус"];

export const Training = () => {

  const history = useHistory();
  const [chosenTraining, setChosenTraining] = useState<Traning>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpenModalName, setIsOpenModalName] = useState(false);
  const [name, setName] = useState("");

  const [
    crateTraining,
    {
      data: createTrainingData,
      loading: createTrainingLoading,
      error: createTrainingError,
    },
  ] = useMutation<CreateTraningMutation>(CreateTraningDocument, {
    variables: {
      traningData: {
        nameUKR: name,
      },
    },
    refetchQueries: [
      {
        query: GetTraningsDocument,
        variables: {
          limit: rowsPerPage,
          skip: page * rowsPerPage,
        },
      },
    ],
  });

  const {
    data: trainingsData,
    loading: trainingsLoading,
    error: trainingsError,
  } = useQuery<GetTraningsQuery>(GetTraningsDocument, {
    variables: {
      limit: rowsPerPage,
      skip: page * rowsPerPage,
    },
  });

  const [
    deleteTraining,
    {
      data: deleteTrainingData,
      loading: deleteTrainingLoading,
      error: deleteTrainingError,
    },
  ] = useMutation<DeleteTraningMutation>(DeleteTraningDocument, {
    variables: {
      id: chosenTraining?.id,
    },
    refetchQueries: [
      {
        query: GetTraningsDocument,
        variables: {
          limit: rowsPerPage,
          skip: page * rowsPerPage,
        },
      },
    ],
  });

  const [
    publishTraining,
    { data: publishTrainingData, loading: publishTrainingLoading },
  ] = useMutation<PublishTraningMutation>(PublishTraningDocument, {
    variables: {
      id: chosenTraining?.id,
    },
    refetchQueries: [
      {
        query: GetTraningsDocument,
        variables: {
          limit: rowsPerPage,
          skip: page * rowsPerPage,
        },
      },
    ],
  });

  const [
    unpublishTraining,
    { data: unpublishTrainingData, loading: unpublishTrainingLoading },
  ] = useMutation<UnpublishTraningMutation>(UnpublishTraningDocument, {
    variables: {
      id: chosenTraining?.id,
    },
    refetchQueries: [
      {
        query: GetTraningsDocument,
        variables: {
          limit: rowsPerPage,
          skip: page * rowsPerPage,
        },
      },
    ],
  });

  useEffect(() => {
    if (createTrainingData) {
      history.push(`/training/list/${createTrainingData.createTraning.id}`);
    }
  }, [createTrainingData]);


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const createHandleTraining = async () => {
    try {
      await crateTraining();
    } catch (e) {
      setRequestError(true);
    }
  };

  const deleteHandleTraining = async (e) => {
    try {
      e.stopPropagation();
      await deleteTraining();
    } catch (e) {
      setRequestError(true);
      setErrorMessage("При удалении произошла ошибка");
    }
  };

  const publishHandleTraining = async (e) => {
    try {
      e.stopPropagation();
      await publishTraining();
    } catch (e) {
      setRequestError(true);
      setErrorMessage("При публикации произошла ошибка");
    }
  };
  const unpublishHandleTraining = async (e) => {
    try {
      e.stopPropagation();
      await unpublishTraining();
    } catch (e) {
      setRequestError(true);
      setErrorMessage("При отмене публикации произошла ошибка");
    }
  };

  useEffect(() => {
    if (deleteTrainingData) {
      setChosenTraining(null);
    }
  }, [deleteTrainingData]);

  const disableOnLoading =
    trainingsLoading ||
    deleteTrainingLoading ||
    createTrainingLoading ||
    unpublishTrainingLoading ||
    publishTrainingLoading;
  let content;

  if (trainingsLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }
  if (trainingsError) {
    content = <ErrorSnackbar error={trainingsError} />;
  }
  if (trainingsData) {
    const trainingList = trainingsData.getTranings.tranings;

    const menuItems = [
      {
        name: "Опубликовать",
        action: (e) => publishHandleTraining(e),
      },
      {
        name: "Не публиковать",
        action: (e) => unpublishHandleTraining(e),
      },
      {
        name: "Удалить",
        action: (e) => deleteHandleTraining(e),
        color: "red",
      },
    ];

    const pagination = (
      <CustomTablePagination
        rowsPerPageOptions={[10, 20]}
        count={trainingsData.getTranings?.total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    );

    content = (
      <>
        <Box textAlign="right" mt={3} mb={3}>
          <ButtonPlus
            width={179}
            variant="contained"
            disabled={false}
            onClick={() => setIsOpenModalName(!isOpenModalName)}
          >
            Создать
          </ButtonPlus>
        </Box>
        <Box mb={2}>{pagination}</Box>

        <Table>
          <TrainingTableRows>
            {headers.map((header) => (
              <MainTableHeaderTitle key={header}>{header}</MainTableHeaderTitle>
            ))}
          </TrainingTableRows>
        </Table>
        {trainingList.length ? (
          trainingList?.map((training) => (
            <TrainingTableRows
              key={training.id}
              onClick={() => history.push(`/training/list/${training.id}`)}
            >
              <MainTableCell>
                {training.nameUKR ? training.nameUKR : "Название"}
              </MainTableCell>

              <MainTableStatusCell status={training.isPublished}>
                {(training.isPublished && "Опубликованно") ||
                  "Не опубликованое"}
              </MainTableStatusCell>
              <MainTableCell onMouseOver={() => setChosenTraining(training)}>
                <Menu
                  vertical={36}
                  horizontal={12}
                  width={170}
                  disabled={disableOnLoading}
                  items={menuItems}
                />
              </MainTableCell>
            </TrainingTableRows>
          ))
        ) : (
          <EmptyMobileAppTableRow>
            Тренировки отсутствуют
          </EmptyMobileAppTableRow>
        )}
        <Box mb={2}>{pagination}</Box>

        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={errorMessage}
        />
      </>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header>Физкультура</Header>

      {content}

      <TrainingModalName
        setName={setName}
        createHandleTraining={createHandleTraining}
        open={isOpenModalName}
        close={setIsOpenModalName}
      />
    </Container>
  );
};
