import React, { useState } from "react";
import { Box, CircularProgress, Collapse, SvgIcon } from "@material-ui/core";
import { CustomIconButton } from "../../../shared/Buttons/CustomIconButton";
import { CustomTextField } from "../Style";
import { DeleteIconButton } from "../../../shared/Buttons/DeleteIconButton";
import { SaveIconButton } from "../../../shared/Buttons/SaveIconButton";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateSmartNotificationPollListDocument,
  CreateSmartNotificationPollListMutation,
  DeleteSmartNotificationPollListDocument,
  DeleteSmartNotificationPollListMutation,
  GetPollListsBySmartNotificationIdDocument,
  GetPollListsBySmartNotificationIdQuery,
  UpdateSmartNotificationMutation,
  UpdateSmartNotificationPollListDocument,
} from "../../../../generated/graphql";
import { VariantItem } from "./VariantItem";
import { LoadingBox } from "../../../shared/Style/Style";

const PlusIcon = () => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18">
    <path
      d="M14 10H10V14H8V10H4V8H8V4H10V8H14V10ZM16 0H2C0.89 0 0 0.89 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 0.89 17.1 0 16 0Z"
      fill="#FFB61D"
    />
  </SvgIcon>
);

interface ChooseVariantAnswersProps {
  notificationId: string;
  setRequestError?: (value: boolean) => void;
  setErrorMessage?: (value: string) => void;
}

export const ChooseVariantAnswers = ({
  notificationId,
  setRequestError,
  setErrorMessage,
}: ChooseVariantAnswersProps) => {
  const [isCreatingAnswerOption, setIsCreatingAnswerOption] = useState(false);
  const [pollName, setPollName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [nameVariantError, setNameVariantError] = useState(false);

  const {
    data: getPollListData,
    loading: getPollListLoading,
    error: getPollListError,
  } = useQuery<GetPollListsBySmartNotificationIdQuery>(
    GetPollListsBySmartNotificationIdDocument,
    {
      variables: { smartNotificationId: notificationId },
    }
  );

  const [
    createPollItem,
    {
      data: createPollData,
      loading: createPollLoading,
      error: createPollError,
    },
  ] = useMutation<CreateSmartNotificationPollListMutation>(
    CreateSmartNotificationPollListDocument,
    {
      refetchQueries: [
        {
          query: GetPollListsBySmartNotificationIdDocument,
          variables: { smartNotificationId: notificationId },
        },
      ],
    }
  );

  const [
    deletePollList,
    {
      data: deletePollListData,
      loading: deletePollListLoading,
      error: deletePollListError,
    },
  ] = useMutation<DeleteSmartNotificationPollListMutation>(
    DeleteSmartNotificationPollListDocument,
    {
      refetchQueries: [
        {
          query: GetPollListsBySmartNotificationIdDocument,
          variables: { smartNotificationId: notificationId },
        },
      ],
    }
  );

  const [
    updatePollItem,
    {
      data: updatePollData,
      loading: updatePollLoading,
      error: updatePollError,
    },
  ] = useMutation<UpdateSmartNotificationMutation>(
    UpdateSmartNotificationPollListDocument,
    {
      refetchQueries: [
        {
          query: GetPollListsBySmartNotificationIdDocument,
          variables: { smartNotificationId: notificationId },
        },
      ],
    }
  );

  const handlerCreatePoll = async (name: string) => {
    try {
      if (!name) {
        setNameError(true);
        return;
      }
      await createPollItem({
        variables: {
          notificationId,
          name,
        },
      });
      setPollName("");
      setIsCreatingAnswerOption(false);
    } catch (e) {
      setRequestError(true);
      setErrorMessage("Во время создания опроса произошла ошибка");
    }
  };

  const handlerDeletePollList = async (id: string) => {
    try {
      await deletePollList({
        variables: { id },
      });
    } catch (e) {
      setRequestError(true);
      setErrorMessage("");
    }
  };

  const handleUpdatePoll = async (id: string, name: string) => {
    try {
      if (!name) {
      }
      await updatePollItem({
        variables: {
          id,
          name,
        },
      });
    } catch (e) {
      setRequestError(true);
      setErrorMessage("Во время создания опроса произошла ошибка");
    }
  };

  const handleDeleteCollapse = () => {
    setPollName("");
    setIsCreatingAnswerOption(false);
  };

  const disableOnLoading =
    createPollLoading ||
    deletePollListLoading ||
    updatePollLoading ||
    getPollListLoading;

  let content;

  if (disableOnLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (getPollListData) {
    const pollList = getPollListData?.getPollListsBySmartNotificationId;

    content = (
      <>
        {pollList.map(({ id, name }) => (
          <VariantItem
            key={id}
            id={id}
            name={name}
            handleUpdateVariant={handleUpdatePoll}
            handleDeleteVariant={handlerDeletePollList}
          />
        ))}
      </>
    );
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>Варианты ответов</Box>
        <CustomIconButton callback={(_) => setIsCreatingAnswerOption(true)}>
          <PlusIcon />
        </CustomIconButton>
      </Box>

      <Box mb={3}>
        <Collapse in={isCreatingAnswerOption}>
          <CustomTextField
            fullWidth
            variant="filled"
            placeholder="Введите вариант ответа..."
            value={pollName}
            error={nameError}
            onFocus={() => setNameError(false)}
            onChange={({ target: { value } }) => setPollName(value)}
            InputProps={{
              endAdornment: (
                <Box display="flex">
                  <DeleteIconButton
                    fz={20}
                    item="вариант ответа"
                    deleteHandler={handleDeleteCollapse}
                  />
                  <SaveIconButton
                    fz={20}
                    save={() => handlerCreatePoll(pollName)}
                  />
                </Box>
              ),
            }}
          />
        </Collapse>
      </Box>

      {content}
    </Box>
  );
};
