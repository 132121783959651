import styled from "styled-components";
import { Accordion, AccordionDetails, AccordionSummary, DialogTitle, Paper } from "@material-ui/core";
import { MainTableRow } from "../../shared/Style/Style";

export const RobotsTableHeader = styled(MainTableRow)`
  grid-template-columns: 0.5fr 1fr 1.5fr 2fr 1fr 0.5fr;
  grid-gap: 10px;
  align-items: center;
`;

export const RobotsListItem = styled(RobotsTableHeader)`
  min-height: 116px;
  cursor: pointer;
`;

export const RobotMenu = styled.div`
  margin-left: auto;
`;

export const ModalTitle = styled(DialogTitle)`
   display: flex;
   align-items: center;
   margin-bottom: 20px;
   font-size: 24px;
   font-weight: 700;
   line-height: 28px;

   &.MuiDialogTitle-root {
      padding-left: 0;
    }
 `;
 
export const EditRobotContainer = styled(Paper)`
  margin-top: 40px;
  padding: 20px 40px;
`;

export const EditRobotTitle = styled(DialogTitle)`
  margin: 0;
  padding: 0;
`;

export const EditRobotForm = styled.div`
  width: 370px;
  margin-bottom: 20px;

  && .MuiInputBase-inputMultiline {
    padding: 16px 4px;
  }
`;

export const EditRobotButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const AddRobotStageButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const StageTableHeader = styled(MainTableRow)`
  grid-template-columns: 1fr 1fr 3fr 1.5fr 0.5fr;
  grid-gap: 10px;
  align-items: center;
`;

export const StageListItem = styled(StageTableHeader)`
  cursor: pointer;
`;

export const EditStageButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const EditStageContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-bottom: 40px;

  & > div:first-child {
    margin-top: 20px;
  }
`;

export const EditStageBundle = styled.div`
  width: 540px;
  height: max-content;
  padding-left: 20px;
  margin-top: 20px;
  border-left: 2px solid #C4C4C4;
`;

export const StageArtifactsContainer = styled.div`
  display: grid;
  gap: 20px;
  margin: 20px auto 60px;
`;

export const AccordionStyled = styled(Accordion)`
  background-color: #EBEBEB;
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  padding: 0 20px;

  & .MuiAccordionSummary-content {
    margin: 10px 0;
    & .Mui-expanded {
      margin: 0;
    }
  }

  & > div {
    justify-content: space-between;
    align-items: center;
    
    & > button > span > svg {
      fill: #FFB61D;
    }
  }
`;

export const AccordionDetailsStyled = styled(AccordionDetails)`
  background-color: #FFFFFF;
  border: 1px solid #C4C4C4;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
`;

export const ArtifactItemContainer = styled.div`
  background-color: #EBEBEB;
  width: 48%;
  padding: 4px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
`;

export const ArtifactInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  && .MuiSvgIcon-colorPrimary{
    color: #0EAA00;
  }
  && .MuiSvgIcon-colorDisabled {
    color: #969696;
  }
`;

export const ArtifactPhoto = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
