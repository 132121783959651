import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import { GetProjectCorrectionsLightDocument, GetProjectCorrectionsLightQuery } from "../../generated/graphql";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";

import { Filter, FilterButton, FilterButtonEndIcon } from "../shared/Style/Style";

interface ICorrectionsNavBarProps {
  activeFilter: string[];
  setActiveFilter(e: string[]): void;
}

export const CorrectionsNavBar = ({ activeFilter, setActiveFilter }: ICorrectionsNavBarProps) => {
  const { projectId } = useParams<{ projectId: string }>();

  const {
    data,
    loading,
    error
  } = useQuery<GetProjectCorrectionsLightQuery>(
    GetProjectCorrectionsLightDocument,
    { variables: { projectId } }
  );

  let content;

  if (error) {
    content = <ErrorSnackbar error={error} />;
  }

  if (loading) {
    content = <CircularProgress color="inherit" />
  }

  if (data) {
    const statusesCount = data.getProjectCorrectionsLight;

    content = (
      <>
        <FilterButton
          active={activeFilter.includes('NEW')}
          onClick={() => setActiveFilter(['NEW'])}
          endIcon={
            <FilterButtonEndIcon>
              {
                statusesCount?.find(correction => correction?.status === 'NEW')?.count
                  ? `(${statusesCount?.find(correction => correction?.status === 'NEW')?.count})`
                  : ''
              }
            </FilterButtonEndIcon>
          }
        >
          Новые
        </FilterButton>

        <FilterButton
          active={activeFilter.includes('REVIEW')}
          onClick={() => setActiveFilter(['REVIEW'])}
          endIcon={
            <FilterButtonEndIcon>
              {
                statusesCount?.find(correction => correction?.status === 'REVIEW')?.count
                  ? `(${statusesCount?.find(correction => correction?.status === 'REVIEW')?.count})`
                  : ''
              }
            </FilterButtonEndIcon>
          }
        >
          На рассмотрении
        </FilterButton>

        <FilterButton
          active={activeFilter.includes('DISCUSSION')}
          onClick={() => setActiveFilter(['DISCUSSION'])}
          endIcon={
            <FilterButtonEndIcon>
              {
                statusesCount?.find(correction => correction?.status === 'DISCUSSION')?.count
                  ? `(${statusesCount?.find(correction => correction?.status === 'DISCUSSION')?.count})`
                  : ''
              }
            </FilterButtonEndIcon>
          }
        >
          На обсуждении
        </FilterButton>

        <FilterButton
          active={activeFilter.includes('ACCEPTED')}
          onClick={() => setActiveFilter(['ACCEPTED'])}
          endIcon={
            <FilterButtonEndIcon>
              {
                statusesCount?.find(correction => correction?.status === 'ACCEPTED')?.count
                  ? `(${statusesCount?.find(correction => correction?.status === 'ACCEPTED')?.count})`
                  : ''
              }
            </FilterButtonEndIcon>
          }
        >
          Принято
        </FilterButton>

        <FilterButton
          active={activeFilter.includes('DONE')}
          onClick={() => setActiveFilter(['DONE'])}
          endIcon={
            <FilterButtonEndIcon>
              {
                statusesCount?.find(correction => correction?.status === 'DONE')?.count
                  ? `(${statusesCount?.find(correction => correction?.status === 'DONE')?.count})`
                  : ''
              }
            </FilterButtonEndIcon>
          }
        >
          Сделано
        </FilterButton>

        <FilterButton
          active={activeFilter.includes('REJECTED')}
          onClick={() => setActiveFilter(['REJECTED'])}
          endIcon={
            <FilterButtonEndIcon>
              {
                statusesCount?.find(correction => correction?.status === 'REJECTED')?.count
                  ? `(${statusesCount?.find(correction => correction?.status === 'REJECTED')?.count})`
                  : ''
              }
            </FilterButtonEndIcon>
          }
        >
          Отклонено
        </FilterButton>
      </>
    )
  }

  return (
    <Filter>
      {content}
    </Filter>
  )
}