import { FC } from "react";
import { Dialog } from "@material-ui/core";
import { useReactiveVar } from "@apollo/client";
import { robotsState } from "../../../state";
import { Container, Title, Context, FormContainer } from "./style";
import { CloseIconButton } from "../../../../../shared/Buttons/CloseIconButton";
import { ModalButtons } from "../../../../shared/ModalButtons";
import { ArtifactPhotoDropzone } from "./ArtifactPhotoDropzone";
import { ArtifactName } from "./ArtifactName";
import { SelectAnimationType } from "./SelectAnimationType";
import { SelectArtifactRank } from "./SelectArtifactRank";
import { ArtifactPrice } from "./ArtifactPrice";
import { ArtifactAnimation } from "./ArtifactAnimation";
import { ArtifactBundles } from "./ArtifactBundles";
import { ArtifactClothType } from "./ArtifactClothType";
import { ArtifactDefaultClothSwitcher } from "./ArtifactDefaultClothSwitcher";
import { LoadingProgressOverlay } from "../../../../../shared/LoadingProgress";
import { ArtifactRankEnum, ArtifactTypeEnum } from "../../../../../../generated/graphql";
import { defaultArtifactName, defaultEnergyPrice, initialAddArtifactItem } from "../../../constants";
import { ArtifactModelTitles } from "../../../maps";
import { useValidateArtifactFields } from "../../../hooks/useValidateArtifactFields";

interface ArtifactModalProps {
    refetch: (type: ArtifactTypeEnum, clothTypeId: string, skip: number) => void;
}

export const ArtifactModal: FC<ArtifactModalProps> = ({refetch}) => { 
    const isAddArtifactModalOpen = useReactiveVar(robotsState.isArtifactModalOpen);
    const stageGeneralInfo = useReactiveVar(robotsState.stageGeneralInfo);
    const artifact = useReactiveVar(robotsState.artifact);
    const currentArtifactMode = useReactiveVar(robotsState.currentArtifactMode);
    const artifactsSkipPage = useReactiveVar(robotsState.artifactsSkipPage);
    const isLoading = useReactiveVar(robotsState.isLoading);
    const validateFields = useValidateArtifactFields();

    const type = isAddArtifactModalOpen.type;
    const clothTypeId = isAddArtifactModalOpen?.clothTypeId ?? artifact?.clothTypeId ?? undefined;
    const titleVariant = ArtifactModelTitles.get(type);
    const title = artifact?.id ? `Редактирование ${titleVariant}` : `Создание ${titleVariant}`;
    const isAnimation = type === ArtifactTypeEnum.Animation;
    const isCloth = type === ArtifactTypeEnum.Cloth;
    
    const artifactParams = {
        type,
        clothTypeId,
        gameModelStateId: stageGeneralInfo?.id,
        name: artifact?.name ?? defaultArtifactName,
        animationTypeId: artifact?.animationTypeId ?? undefined,
        rank: artifact?.isDefault ? undefined : artifact?.rank ?? ArtifactRankEnum.Bronze,
        energyPrice: artifact?.isDefault ? 0 : artifact?.energyPrice ?? defaultEnergyPrice,
        isDefault: isCloth ? artifact?.isDefault : undefined,
    };

    const closeHandler = () => {
        if (currentArtifactMode) {
            refetch(
                currentArtifactMode.type,
                currentArtifactMode.clothTypeId,
                artifactsSkipPage
            );
        };
        robotsState.clearArtifact();
        robotsState.setIsArtifactModalOpen(initialAddArtifactItem);
    };

    const submitHandler = async () => { 
        if (artifact?.id) { 
            await robotsState.updateArtifact({
                updateGameModelArtifactId: artifact?.id,
                input: artifactParams,
            });
        } else { 
            await robotsState.createArtifact({ input: artifactParams });
        };

        closeHandler();
    };

    const publishHandler = async () => {
        const isValidFields = validateFields();
        
        if (!isValidFields) return;

        await robotsState.updateArtifact({
            updateGameModelArtifactId: artifact?.id,
            input: {
                ...artifactParams,
                isPublished: true},
        });

        closeHandler();
    };
    
    return (
        <Dialog open maxWidth="md">
            <Container>
                {isLoading && <LoadingProgressOverlay />}
                
                <CloseIconButton close={closeHandler} />

                <Title>{title}</Title>

                <Context>
                    {!isAnimation && artifact?.id && <ArtifactPhotoDropzone />}

                    <FormContainer>
                        <ArtifactName />

                        {isCloth && clothTypeId &&
                            <ArtifactClothType id={clothTypeId} />
                        }

                        {isAnimation && <SelectAnimationType />}

                        <SelectArtifactRank />

                        <ArtifactPrice />

                        {isAnimation && artifact?.id && <ArtifactAnimation />}

                        {!isAnimation && artifact?.id && <ArtifactBundles />}

                        {isCloth && <ArtifactDefaultClothSwitcher />}

                    </FormContainer>
                </Context>

                <ModalButtons
                    isLoading={isLoading}
                    cancelHandler={closeHandler} 
                    submitHandler={submitHandler}
                    publishHandler={artifact?.id && publishHandler}
                    isPublished={Boolean(artifact?.isPublished)}
                />
            </Container>
        </Dialog>
    );    
};
