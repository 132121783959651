import { useRef } from "react";
import { useReactiveVar } from "@apollo/client";
import { robotsState } from "../../../state";
import { IconButton } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { AnimationInput, AnimationTitle } from "../../../../Awards/components/Modal/style";
import { ButtonCustom, ImportFileUpload as AnimationFileUpload, BundleLink } from "../../../../../shared/Style/Style";
import { GameModelArtifactFileTypeEnum } from "../../../../../../generated/graphql";

export const ArtifactAnimation = () => {
    const animationInput = useRef<HTMLInputElement | null>(null);
    
    const artifact = useReactiveVar(robotsState.artifact);
    const isLoading = useReactiveVar(robotsState.isLoading);

    const uploadAnimationHandler = (file: File) => {
        if (!file) return;

        const reader = new FileReader();
        reader.onload = () => {
            robotsState.updateArtifactFile({
                updateGameModelArtifactFileId: artifact?.id,
                file,
                fileType: GameModelArtifactFileTypeEnum.Animation,
            }, () => {
                robotsState.setArtifactFile(
                    GameModelArtifactFileTypeEnum.Animation,
                    "#"
                );
            });
        };
        reader.readAsDataURL(file);
    };

    const deleteAnimationHandler = () => {
        robotsState.deleteArtifactFile({
            deleteGameModelArtifactFileId: artifact?.id,
            fileType: GameModelArtifactFileTypeEnum.Animation,
        }, () => { 
            robotsState.setArtifactFile(GameModelArtifactFileTypeEnum.Animation, "");
        });
    };

    return (
        <AnimationInput>
            <AnimationTitle>Атлас с превью</AnimationTitle>

            {artifact?.animation ? (
                <span>
                    <BundleLink
                        href={artifact.animation}
                        download
                    >
                        Animation
                    </BundleLink>

                    <IconButton
                        disabled={isLoading}
                        onClick={deleteAnimationHandler}
                    >
                        <ClearIcon color="error" fontSize="small"/>
                    </IconButton>
                </span>
                ) : (
                <ButtonCustom
                    onClick={() => animationInput?.current.click()}
                >
                    <span>Прикрепить</span>
                        
                    <AnimationFileUpload
                        type="file"
                        accept=".png"
                        ref={animationInput}
                        onChange={({ target: { files } }) =>
                          files[0] && uploadAnimationHandler(files[0])
                        }
                    />
                </ButtonCustom>                         
            )}
        </AnimationInput>        
    );    
};
