import { Toolbar, Typography } from "@material-ui/core";
import React from "react";
import Button from "@material-ui/core/Button";
import { AppBarStyle, LoginForm, LoginLink } from "../Login/Login.style";
import { Wrapper } from "../shared/Style/Style";

interface RegistrationSuccessInterface {
  password?: string;
}

export const RegistrationSuccess = ({
  password,
}: RegistrationSuccessInterface) => {
  return (
    <LoginForm>
      <AppBarStyle position="fixed">
        <Toolbar />
      </AppBarStyle>
      <Wrapper>
        <Typography variant="h5" component="h5" gutterBottom>
          Спасибо за регистрацию
        </Typography>
        <Typography variant="subtitle2" component="h5">
          На ваш e-mail была отправлена инструкция для авторизации
        </Typography>
        <Button
          className={"button__backLogin"}
          color="primary"
          component={LoginLink}
          to={"/login"}
        >
          Вернуться на главную
        </Button>
      </Wrapper>
    </LoginForm>
  );
};
