import React, { useEffect, useState } from "react";
import { useStyles } from "../../shared/Style/Style";
import { useParams } from "react-router-dom";
import {
  DeleteKanbanWorkflowStepDocument,
  DeleteKanbanWorkflowStepMutation,
  GetKanbanWorkflowStepsByProjectDocument,
  GetProjectTaskStatusesByProjectDocument,
  KanbanWorkflowStep,
  UpdateKanbanWorkflowStepDocument,
  UpdateKanbanWorkflowStepMutation,
} from "../../../generated/graphql";
import { useMutation } from "@apollo/client";
import { Grid, TextField } from "@material-ui/core";
import { ButtonsContainer, ItemContainer } from "../KanbanSettings.style";
import { SaveIconButton } from "../../shared/Buttons/SaveIconButton";
import { EditIconButton } from "../../shared/Buttons/EditIconButton";
import { DeleteIconButton } from "../../shared/Buttons/DeleteIconButton";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";

interface WorkflowStepItemProps {
  step: KanbanWorkflowStep;
  label: string;
  projectId: string;
}

export const WorkflowStepItem = ({
  step,
  label,
  projectId,
}: WorkflowStepItemProps) => {
  const classes = useStyles();
  const [requestError, setRequestError] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);

  const [value, setValue] = useState<string>(step.name);
  const [order, setOrder] = useState<number>(step.order);

  const [
    updateKanbanWorkflowStep,
    {
      data: updateKanbanWorkflowStepData,
      loading: updateKanbanWorkflowStepLoading,
      error: updateKanbanWorkflowStepError,
    },
  ] = useMutation<UpdateKanbanWorkflowStepMutation>(
    UpdateKanbanWorkflowStepDocument,
    {
      refetchQueries: [
        {
          query: GetKanbanWorkflowStepsByProjectDocument,
          variables: {
            projectId,
          },
        },
      ],
    }
  );

  const [
    deleteKanbanWorkflowStep,
    {
      loading: deleteKanbanWorkflowStepLoading,
      error: deleteKanbanWorkflowStepsError,
    },
  ] = useMutation<DeleteKanbanWorkflowStepMutation>(
    DeleteKanbanWorkflowStepDocument,
    {
      refetchQueries: [
        {
          query: GetKanbanWorkflowStepsByProjectDocument,
          variables: {
            projectId,
          },
        },
      ],
    }
  );

  useEffect(() => {
    if (updateKanbanWorkflowStepLoading || deleteKanbanWorkflowStepLoading) {
      setEdit(false);
    }
  }, [updateKanbanWorkflowStepLoading, deleteKanbanWorkflowStepLoading]);

  useEffect(() => {
    if (updateKanbanWorkflowStepData) {
      setEdit(false);
    }
  }, [updateKanbanWorkflowStepData]);

  useEffect(() => {
    if (updateKanbanWorkflowStepError || deleteKanbanWorkflowStepsError) {
      setRequestError(true);
    }
  }, [updateKanbanWorkflowStepError, deleteKanbanWorkflowStepsError]);

  const onSave = (): void => {
    updateKanbanWorkflowStep({
      variables: {
        name: value,
        id: step.id,
        order,
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={4} item>
        <TextField
          disabled={step.isBase || !edit}
          className={classes.root}
          fullWidth
          label="порядковый номер"
          variant="filled"
          type="text"
          value={order}
          onChange={(e) => {
            setOrder(+e.target.value);
          }}
        />
      </Grid>

      <Grid xs={8} item>
        <ItemContainer>
          <TextField
            disabled={!edit}
            className={classes.root}
            fullWidth
            label={label}
            variant="filled"
            type="text"
            value={
              (value === "new" && "новые") ||
              (value === "inProgress" && "в работе") ||
              (value === "done" && "выполнены") ||
              value
            }
            onChange={(e) => setValue(e.target.value)}
          />

          {/*{!(step.name === "new" ||*/}
          {/*  step.name === "inProgress" ||*/}
          {/*  step.name === "done") && (*/}
          {console.log("step", step)}
          <ButtonsContainer>
            {edit ? (
              <SaveIconButton
                disabled={edit && value === step.name && order === step.order}
                save={onSave}
              />
            ) : (
              <EditIconButton
                disabled={edit}
                active={!edit}
                edit={() => {
                  setEdit(true);
                }}
              />
            )}
            <DeleteIconButton
              disabled={false}
              deleteHandler={() => {
                deleteKanbanWorkflowStep({
                  variables: {
                    id: step.id,
                  },
                });
              }}
              item="этап"
              fz={20}
            />
          </ButtonsContainer>
          {/*)}*/}
        </ItemContainer>
      </Grid>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={
          (updateKanbanWorkflowStepError && "Ошибка обновления колонки") ||
          (deleteKanbanWorkflowStepsError?.message ===
          "Can not delete workflow step, there are tasks with this status"
            ? "Удаление базовых колонок не доступно."
            : "Ошибка при удалении колонки")
        }
      />
    </Grid>
  );
};
