import React, { Dispatch } from "react";
import {
  ButtonAddGrid,
  ButtonCustomWithIcon,
  HeadersGrid,
  RowGrid,
  SaveButtonContainer,
} from "./VacancyReport.style";
import { HeaderCell } from "./CreateVacancyPlanModal.style";
import { VacancyDictionarySelect } from "../VacancyClosingRateReport/VacancyDictionarySelect";
import {
  // ButtonCustomWithIcon,
  AddBtnBgTransparent,
  ValueTxt,
  TableWrapper,
  HeaderTxt,
  Cell,
  MainSalaryGrid,
} from "../../../shared/Style/Style";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import { Action, State } from "./VacancyReportReducer";
import {
  VacanciesPlan,
  VacanciesPlanOptionFields,
} from "../../../../generated/graphql";
import { CustomTableSalaryInput } from "../../../SalaryModal/SalaryModal.style";

interface TableContentProps {
  reports: boolean;
  state: State;
  dispatch: Dispatch<Action>;
  optionFields: VacanciesPlanOptionFields;
  plans: VacanciesPlan[];
  mutateVacancy(): void;
  mutationDeletePlan: any;
}

export const TableContent = ({
  reports,
  state,
  dispatch,
  optionFields,
  plans,
  mutateVacancy,
  mutationDeletePlan,
}: TableContentProps) => {

  return (
    <TableWrapper>
      <MainSalaryGrid>
        <HeadersGrid>
          {["Вакансия", "Уровень", "Опыт работы", "Статус", "Комментарий"].map(
            (text) => (
              <HeaderCell>
                <HeaderTxt>{text}</HeaderTxt>
              </HeaderCell>
            )
          )}
        </HeadersGrid>
        {!reports && (
          <ButtonAddGrid>
            <AddBtnBgTransparent
              onClick={() => {
                dispatch({ type: "createNewRow", payload: true });
                dispatch({ type: "setMutationType", payload: "create" });
              }}
            >
              Добавить
            </AddBtnBgTransparent>
          </ButtonAddGrid>
        )}

        {state.newRow && (
          <RowGrid>
            <Cell
              style={{
                borderBottom: !state.vacancyData.jobId
                  ? "1px solid #E85050"
                  : "none",
              }}
            >
              <VacancyDictionarySelect
                stateField="jobId"
                values={optionFields.jobs}
                setVacancyData={dispatch}
                vacancyData={state.vacancyData}
              />
            </Cell>
            <Cell
              style={{
                borderBottom: !state.vacancyData.positionId
                  ? "1px solid #E85050"
                  : "none",
              }}
            >
              <VacancyDictionarySelect
                stateField="positionId"
                values={optionFields.positions}
                setVacancyData={dispatch}
                vacancyData={state.vacancyData}
              />
            </Cell>
            <Cell
              style={{
                borderBottom: !state.vacancyData.experience
                  ? "1px solid #E85050"
                  : "none",
              }}
            >
              <CustomTableSalaryInput
                type="number"
                step="0.01"
                value={state.vacancyData!.experience || ""}
                onChange={({ target: { value } }) => {
                  dispatch({
                    type: "setVacancyData",
                    payload: { ...state.vacancyData, experience: +value },
                  });
                }}
              />
            </Cell>

            <Cell>
              <VacancyDictionarySelect
                stateField="statusId"
                values={optionFields.statuses}
                error={!state.vacancyData.statusId}
                setVacancyData={dispatch}
                vacancyData={state.vacancyData}
                disabled={state.mutationType === "create"}
              />
            </Cell>
            <Cell>
              <CustomTableSalaryInput
                type="text"
                value={state.vacancyData!.comment || ""}
                onChange={({ target: { value } }) => {
                  dispatch({
                    type: "setVacancyData",
                    payload: { ...state.vacancyData, comment: value },
                  });
                }}
              />
            </Cell>

            <SaveButtonContainer>
              <ButtonCustomWithIcon
                style={{ color: "#FFB61D" }}
                onClick={() => {
                  mutateVacancy();
                }}
              >
                <SaveIcon />
              </ButtonCustomWithIcon>
              <ButtonCustomWithIcon
                style={{ color: "#000" }}
                onClick={() => {
                  dispatch({ type: "clearVacancyData" });
                  dispatch({ type: "createNewRow", payload: false });
                }}
              >
                <CloseIcon />
              </ButtonCustomWithIcon>
            </SaveButtonContainer>
          </RowGrid>
        )}
        <>
        {plans?.length
          ? plans.map((plan) => (
            <RowGrid key={plan.id}>
              <Cell style={{ minWidth: "180px" }}>
                <ValueTxt>{plan.job.name}</ValueTxt>
              </Cell>
              <Cell>
                <ValueTxt>{plan.position.displayName}</ValueTxt>
              </Cell>
              <Cell>
                <ValueTxt>{plan.experience}</ValueTxt>
              </Cell>
              <Cell>
                <ValueTxt>{plan.status.displayName}</ValueTxt>
              </Cell>
              <Cell>
                <ValueTxt>{plan?.comment}</ValueTxt>
              </Cell>
              {!reports && (
                <SaveButtonContainer>
                  <EditIcon
                    onClick={() => {
                      dispatch({ type: "createNewRow", payload: true });
                      dispatch({ type: "setMutationType", payload: "update" });
                      dispatch({
                        type: "setVacancyData",
                        payload: {
                          id: plan.id,
                          experience: plan.experience,
                          jobId: plan.job.id,
                          positionId: plan.position.id,
                          statusId: plan.status.id.toString(),
                          comment: plan.comment,
                        },
                      });
                    }}
                    style={{ color: "#C4C4C4" }}
                  />

                  <DeleteIcon
                    style={{ color: "#E85050" }}
                    onClick={() => {
                      mutationDeletePlan({ variables: { planId: plan.id } });
                    }}
                  />
                </SaveButtonContainer>
              )}
            </RowGrid>
          ))
          : (
            <RowGrid>
              <Cell style={{ gridColumn: 'span 5' }}>
                <ValueTxt>Вакансии отсутствуют</ValueTxt>
              </Cell>
            </RowGrid>
          )
        }
        </>
      </MainSalaryGrid>
    </TableWrapper>
  );
};
