import React, { useEffect, useState } from "react";
import { IDateRange } from "../../../shared/DateRangePicker/DateRangePicker";
import {
  ANALYTICS_USERS_TYPES,
  ERROR_MESSAGE_ACTIVITY_OF_TEACHERS,
  REPORT_VARIANTS,
  TOP_FILTER_PARTICIPANTS,
} from "../../../ReportsARBook/constants";
import { AnalyticsTopFilters } from "../AnalyticsTopFilters/AnalyticsTopFilters";
import {
  getChartLabelsData,
  getChartQuantityData,
  getMessage,
} from "../../../ReportsARBook/utils";
import { VerticalBarChart } from "../../../ReportsARBook";
import { useParams } from "react-router-dom";
import {
  PeriodAdminReportEnum,
  TeacherPupilRoleEnum,
  useGetSchoolConnectionStatisticLazyQuery,
} from "../../../../generated/graphql";
import { ErrorMessageSnackbar } from "../../../shared/ErrorMessageSnackbar";
import { useRequestError } from "../../../../hooks";
import { UsersType } from "../../../ReportsARBook/types";

export const ConnectionParticipants = () => {
  const { schoolId } = useParams<{ schoolId: string }>();
  const [isDateRangePickerOpen, setDateRangePickerOpen] = useState(false);
  const [isAllPeriodDateRangeData, setAllPeriodDateRangeData] = useState(false);
  const [topFilterVariables, setTopFilterVariables] = useState(
    TOP_FILTER_PARTICIPANTS
  );

  const { from: topFilterFrom, to: topFilterTo, roles } = topFilterVariables;
  const isDatePickerActive =
    (!!topFilterFrom || !!topFilterTo) && !isAllPeriodDateRangeData;

  const variant = REPORT_VARIANTS.connectionParticipants;
  const { allPeriodDate } = variant;

  const [
    getSchoolConnectionStatistic,
    {
      data: connectionStatisticData,
      loading: connectionStatisticLoading,
      error: connectionStatisticError,
    },
  ] = useGetSchoolConnectionStatisticLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const { requestError, setRequestError, errorMessage } = useRequestError(
    connectionStatisticError,
    ERROR_MESSAGE_ACTIVITY_OF_TEACHERS
  );
  const connectionStatistic =
    connectionStatisticData?.getSchoolConnectionStatistic?.dataChart ?? [];

  const chartLabels = getChartLabelsData(
    connectionStatistic,
    PeriodAdminReportEnum.Day
  );
  const totalConnectionStatistic =
    connectionStatisticData?.getSchoolConnectionStatistic?.dataTotal ?? null;

  const handleSetTopFilterVariables = (newValue) => {
    setTopFilterVariables((prevState) => ({ ...prevState, ...newValue }));
  };

  const handleSetFilterPeriod = (newDateRange: IDateRange) => {
    const { startDate, endDate } = newDateRange;
    handleSetTopFilterVariables({ from: startDate, to: endDate });
  };

  const handleSetDatePickerButton = () => {
    setDateRangePickerOpen(true);
    setAllPeriodDateRangeData(false);
  };

  const handleSetAllDatePeriod = () => {
    handleSetFilterPeriod(allPeriodDate);
    setAllPeriodDateRangeData(true);
  };
  const handleSetFilterUsers = (value: UsersType["value"]) => {
    setTopFilterVariables((prev) => ({ ...prev, roles: value as string }));

    const fromDate = topFilterFrom || allPeriodDate.startDate;
    const toDate = topFilterTo || allPeriodDate.endDate;

    handleSetFilterPeriod({
      startDate: fromDate,
      endDate: toDate,
    });

    if (!topFilterFrom && !topFilterTo) {
      setAllPeriodDateRangeData(true);
    }
  };

  const chartQuantity = getChartQuantityData(
    totalConnectionStatistic,
    variant.metric
  );

  const dataConnectionParticipantsTopFilters = {
    handleDateRangePickerOpen: handleSetFilterPeriod,
    handleAllPeriodDateRangeData: handleSetAllDatePeriod,
    handleSetDatePickerButton,
    isDatePickerActive,
    titleDatePickerButton: "Период",
    titleDateButton: "За все время",
    isDateRangePickerOpen,
    isAllPeriodDateRangeData,
    setTopFilterVariables,
    handleDateRangePickerClose: setDateRangePickerOpen,
    isUsersFiltering: true,
    usersFilterFieldsData: ANALYTICS_USERS_TYPES,
    handleSetFilterUsers,
  };

  useEffect(() => {
    if (topFilterFrom && topFilterTo && roles) {
      const fromDate =
        new Date(topFilterFrom) || new Date(allPeriodDate.startDate);
      const toDate = new Date(topFilterTo) || new Date(allPeriodDate.endDate);

      if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
        return;
      }

      getSchoolConnectionStatistic({
        variables: {
          schoolId,
          from: fromDate,
          to: toDate,
          roles: roles as TeacherPupilRoleEnum,
        },
      });
    }
  }, [topFilterFrom, topFilterTo, roles, schoolId]);

  const dataVerticalChartTypeConnectionParticipants = {
    chartHeader: {
      chartTitle: "% подключение участников",
      chartSubtitle: "% подключенных участников",
      totalValue: chartQuantity,
      isTooltipVisible: true,
      tooltipText:
        "Як рахується % підключених учасників? % підключених учасників розраховується по підключених до простору учасниках. Порівнюється кількість учасників, що приєдналися до простору з максимально можливою кількістю - тобто тією, що доступна за поточним тарифом.Планова кількість користувачів Для персонального простору: вчитель - 1, учні - кількість відповідна активному тарифу Для шкільного простору: вчитель - планова кількість вчителів додана при створенні підписки, учні - планова кількість вчителів додана при створенні підписки Приклад: школа заплатила за 230 учнів, з них підключилося 115 115/230*100=50%",
      tooltipPlacement: "right",
    },
    noFilters: true,
    chart: chartLabels,
    isLoadingProgress: connectionStatisticLoading,
    message: getMessage(totalConnectionStatistic),
    unit: "%",
  };

  return (
    <>
      <AnalyticsTopFilters {...dataConnectionParticipantsTopFilters} />
      <VerticalBarChart {...dataVerticalChartTypeConnectionParticipants} />
      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
    </>
  );
};
