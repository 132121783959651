import React from 'react';
import {AvatarPicker} from "../../shared/AvatarPicker";
import {LessonBaseBlockInnerProps} from "../LessonBlockItem/LessonBlockItem";

export const PhotoBlock = ({ disabled, block, uploadPhoto }: LessonBaseBlockInnerProps) => {

  return (
    <>
      <AvatarPicker
        variant="pic"
        border_radius="0%"
        disabled={disabled}
        disableResize={true}
        photo={block.photo}
        uploadHandler={newPhoto => uploadPhoto({
          variables: {
            lessonBlockData: {
              lessonBlockId: block.id,
              photo: newPhoto
            }
          }
        })}
      />
    </>
  )
}