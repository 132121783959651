import { useMutation } from "@apollo/client";
import {
    DeleteApplicationDocument,
    DeleteApplicationMainDocument,
    DeleteApplicationMainModelDocument,
    DeleteApplicationMainModelMutation,
    DeleteApplicationMainMutation,
    DeleteApplicationMutation,
    DeleteAwardDocument,
    DeleteAwardMutation,
    DeleteGameModelArtifactDocument,
    DeleteGameModelArtifactMutation,
    DeleteGameModelDocument,
    DeleteGameModelMutation,
    DeleteGameModelStateDocument,
    DeleteGameModelStateMutation,
    DeleteMobileNotificationDocument,
    DeleteMobileNotificationMutation
} from "../../../generated/graphql";

export const useDeleteApp = (id: string) => {
  const [
    deleteApp,
    { data: deleteAppData, loading: deleteAppLoading, error: deleteAppError },
  ] = useMutation<DeleteApplicationMutation>(DeleteApplicationDocument, {
    variables: { id },
  });

  return {
    deleteApp,
    deleteAppData,
    deleteAppLoading,
    deleteAppError,
  };
};

export const useDeletePage = (id: string) => {
  const [
    deletePage,
    {
      data: deletePageData,
      loading: deletePageLoading,
      error: deletePageError,
    },
  ] = useMutation<DeleteApplicationMainMutation>(
    DeleteApplicationMainDocument,
    {
      variables: { id },
    }
  );
  return {
    deletePage,
    deletePageData,
    deletePageLoading,
    deletePageError,
  };
};

export const useDeleteModel = (id: string) => {
  const [
    deleteModel,
    {
      data: deleteModelData,
      loading: deleteModelLoading,
      error: deleteModelError,
    },
  ] = useMutation<DeleteApplicationMainModelMutation>(
    DeleteApplicationMainModelDocument,
    { variables: { id } }
  );

  return {
    deleteModel,
    deleteModelData,
    deleteModelLoading,
    deleteModelError,
  };
};

export const useDeleteNotification = (id: string) => {
  const [
    deleteNotification,
    {
      data: deleteNotificationData,
      loading: deleteNotificationLoading,
      error: deleteNotificationError,
    },
  ] = useMutation<DeleteMobileNotificationMutation>(
    DeleteMobileNotificationDocument,
    {
      variables: { id },
    }
  );

  return {
    deleteNotification,
    deleteNotificationData,
    deleteNotificationLoading,
    deleteNotificationError,
  };
};

export const useDeleteAward = (deleteAwardId: string) => {
  const [
    deleteAward,
    {
      data: deleteAwardData,
      loading: deleteAwardLoading,
      error: deleteAwardError,
    },
  ] = useMutation<DeleteAwardMutation>(DeleteAwardDocument, {
    variables: { deleteAwardId },
  });

  return { deleteAward, deleteAwardData, deleteAwardLoading, deleteAwardError };
};

export const useDeleteRobot = (deleteGameModelId: string) => {
  const [
    deleteRobot,
    {
      data: deleteRobotData,
      loading: deleteRobotLoading,
      error: deleteRobotError,
    },
  ] = useMutation<DeleteGameModelMutation>(DeleteGameModelDocument, {
    variables: { deleteGameModelId },
  });

  return { deleteRobot, deleteRobotData, deleteRobotLoading, deleteRobotError };
};

export const useDeleteStage = (deleteGameModelStateId: string) => {
  const [
    deleteStage,
    {
      data: deleteStageData,
      loading: deleteStageLoading,
      error: deleteStageError,
    },
  ] = useMutation<DeleteGameModelStateMutation>(DeleteGameModelStateDocument, {
    variables: { deleteGameModelStateId },
  });

  return {
    deleteStage,
    deleteStageData,
    deleteStageLoading,
    deleteStageError,
  };
};

export const useDeleteArtefact = (deleteGameModelArtifactId: string) => {
    const [
        deleteArtifact,
        {data: deleteArtifactData, loading: deleteArtifactLoading, error: deleteArtifactError},
    ] = useMutation<DeleteGameModelArtifactMutation>(DeleteGameModelArtifactDocument, {
        variables: { deleteGameModelArtifactId },
    });

    return {
        deleteArtifact,
        deleteArtifactData,
        deleteArtifactLoading,
        deleteArtifactError
    };
};
