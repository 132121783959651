import { useReactiveVar } from "@apollo/client";
import { robotsState } from "../../../state";
import { Autocomplete } from "@material-ui/lab";
import { ModalInput } from "../../../../../shared/Style/Style";

export const SelectAnimationType = () => { 
    const artifact = useReactiveVar(robotsState.artifact);
    const artifactAnimationTypes = useReactiveVar(robotsState.artifactAnimationTypes);
    const isLoading = useReactiveVar(robotsState.isLoading);
    
    const animationType = artifactAnimationTypes?.find(item => item.id === artifact?.animationTypeId);
    const animationTypeValue = animationType ? animationType.displayName : null;
    const selectOptions = artifactAnimationTypes?.map(item => item.displayName);

    const handleTypeChange = (_, value: string) => { 
        const animationTypeId = artifactAnimationTypes?.find(item => item.displayName === value)?.id;
        return robotsState.setArtifactAnimationType(animationTypeId);
    };

    const getOptionLabel = (option: string) => {
        return selectOptions?.find(item => item === option) || null;
    }

    return (
        <Autocomplete
          disabled={isLoading}
          options={selectOptions}
          value={animationTypeValue}
          onChange={handleTypeChange}
          getOptionLabel={getOptionLabel}
            renderInput={(params) => {
                return (
                    <ModalInput
                        {...params}
                        label="Тип анимации"
                        fullWidth
                        variant="filled"
                        margin="normal"
                    />
                );
            }}
        />
    );    
};
