import React, { useEffect, useState } from "react";
import { useDebounce } from "../shared/Utils/OtherOperations/useDebounce";

import { IActiveFilterState } from "../People/People";

import { ThemeProvider } from "@material-ui/styles";
import { defaultMaterialTheme } from "../shared/Style/Style";
import { SearchBar, SearchBarInput } from "./PeopleSearchBar.style";

interface IPeopleSearchBarProps {
  label: string
  activeFilter: IActiveFilterState;
  setActiveFilter(state: IActiveFilterState): void;
}

export const PeopleSearchBar = ({ activeFilter, setActiveFilter, label }: IPeopleSearchBarProps) => {
  const [searchTerm, setSearchTerm] = useState<string>( '');

  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 500);

  useEffect(() => {
    setActiveFilter({
      ...activeFilter,
      searchKey: debouncedSearchTerm,
    })
  }, [debouncedSearchTerm]);

  return (
    <SearchBar>
      <ThemeProvider theme={defaultMaterialTheme}>
        <SearchBarInput
          label={label}
          value={searchTerm}
          onChange={({ target: { value } }) => setSearchTerm(value)}
        />
      </ThemeProvider>
    </SearchBar>
  )
}