import styled from "styled-components";
import { SvgIcon } from "@material-ui/core";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { secondary, UserAvatarStyle } from "../Style/Style";
import { StyledDropzone } from "../Common/Dropzone";
import DeleteIcon from '@material-ui/icons/Delete';

export const PhotoTemplate = styled.div<{dashed: boolean}>`
  width: 106px;
  height: 106px;
  text-align: center;
  cursor: pointer;
  border: ${ props => props.dashed ? `1px dashed transparent` : `1px dashed ${secondary}`};
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.85;
  }
`;

export const PhotoPickerImage = styled.img`
  height: 400px;
  width: 100%;
`;

export const PhotoUploaded = styled(UserAvatarStyle)`
  width: 106px;
  height: 106px;
`;

export const PhotoPickerLabel = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${secondary};
`;

export const PhotoPickerDropzone = styled(StyledDropzone)``;

export const PhotoPickerIcon = styled(SvgIcon)`
  &.MuiSvgIcon-root {
    width: 35px;
    height: 35px;
    margin-top: 25px;
    margin-bottom: 5px;
    justify-self: center;
    align-self: center;
  }
`;

export const IconDeletePhoto = styled(DeleteIcon)`
  color: ${secondary};
  cursor: pointer;
  width: 15px;
  height: auto;
  position: absolute;
`; 