import { useEffect } from "react";
import { useApolloClient, useReactiveVar } from "@apollo/client";
import { awardsState } from "./state";
import { Button, Table } from "@material-ui/core";
import { ContainerWithNavBar } from "../shared/ContainerWithNavBar";
import { MainTableHeaderTitle } from "../../shared/Style/Style";
import { AwardsTableHeader, EmptyPageTitle } from "./style";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { SuccessSnackbar } from "../../shared/SuccessSnackbar";
import { AwardsList } from "./components/AwardsList";
import { AwardModal } from "./components/Modal";
import { MobileAppDeleteModal } from "../../MobileAppModal/MobileAppDeleteModal";
import { awardsPageTitle, awardsQuantity, initialDeleteAward, initialNotifications, tableHeader } from "./constants";
import { getPublishedAwardsIds, getUnpublishedAwardsIds } from "./utils";

export const AwardsPage = () => {
    const apolloClient = useApolloClient();
    awardsState.initMobileAppAwards(apolloClient);

    const awardsList = useReactiveVar(awardsState.awardsList);
    const isAwardModalOpen = useReactiveVar(awardsState.isAwardModalOpen);
    const deleteAward = useReactiveVar(awardsState.deleteAward);
    const notifications = useReactiveVar(awardsState.notifications);
    const isLoading = useReactiveVar(awardsState.isLoading);

    const isAddAwardButtonDisabled = awardsList?.length >= awardsQuantity;
    const publishedAwardsIds = getPublishedAwardsIds(awardsList);
    const unPublishedAwardsIds = getUnpublishedAwardsIds(awardsList);
    const isUnPublishedAwards = awardsList && publishedAwardsIds?.length > 0;
    const isPublicationDisable = awardsList?.length !== awardsQuantity && !isUnPublishedAwards;

    const fetchAwardsList = async () => await awardsState.getAwards();

    useEffect(() => { 
        fetchAwardsList();
    }, []);

    const addAwardHandler = () => awardsState.setIsAwardModalOpen(true);
    
    const publishAwardsHandler = async () => {
        if (publishedAwardsIds?.length > 0) { 
            await awardsState.unPublishAwards({ ids: publishedAwardsIds });
        };

        if (unPublishedAwardsIds?.length > 0) { 
            await awardsState.publishAwards({ ids: unPublishedAwardsIds });
        };

        await fetchAwardsList();
     };

    const renderEmptyPage = !isLoading && (
        <EmptyPageTitle>
            У Вас пока не созданы награды, нажмите “Добавить”, чтобы создать
        </EmptyPageTitle>
    );

    return (
        <ContainerWithNavBar
            header={awardsPageTitle}
            addButton={{
                handler: addAwardHandler,
                isDisabled: isAddAwardButtonDisabled
            }}
        >

            <Table>
                <AwardsTableHeader>
                    {tableHeader.map((item) => (<MainTableHeaderTitle key={item}>{item}</MainTableHeaderTitle>))}
                    <Button
                        disabled={isPublicationDisable}
                        onClick={publishAwardsHandler}
                    >
                        {isUnPublishedAwards ? "Снять все с публикации" : "Опубликовать все"}
                    </Button>
                </AwardsTableHeader>
            </Table>

            {awardsList?.length > 0 ? <AwardsList /> : renderEmptyPage}

            {isAwardModalOpen && <AwardModal refetchAwards={() => fetchAwardsList()}/>}
            
            {notifications?.error && (
                <ErrorMessageSnackbar
                    open={notifications?.error}
                    closeHandler={() => awardsState.setNotifications(initialNotifications)}
                    message={notifications?.message}
                />
            )}
            
            {notifications?.success && (
                <SuccessSnackbar
                    open={notifications?.success}
                    handleClose={() => awardsState.setNotifications(initialNotifications)}
                    message={notifications?.message}
                    snackbarProps={{
                        anchorOrigin: { vertical: "bottom", horizontal: "center" },
                    }}
                />
            )}

            {deleteAward.isOpen &&
  		        <MobileAppDeleteModal
  		            open={deleteAward.isOpen}
  		            close={() => awardsState.setDeleteAward(initialDeleteAward)}
  		            item={deleteAward.award}
  		            refetch={() => fetchAwardsList()}
  		        />
  		    }
        </ContainerWithNavBar>
    )
};
