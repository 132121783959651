import React, { useState } from "react";
import {
  CircularProgress,
  Container,
} from "@material-ui/core";
import {
  ButtonPlus,
  Header,
  LoadingBox,
  PlaceholderBlock,
} from "../shared/Style/Style";
import { useQuery } from "@apollo/client";
import { GetTraningDocument, GetTraningQuery } from "../../generated/graphql";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { AddBlockTraining } from "../AddBlockTraining/AddBlockTraining";
import { useParams } from "react-router-dom";
import { TrainingListItem } from "../TrainingBlock/TrainingListItem";
import { TrainingForm } from "../TrainingForm/TrainingForm";

export const TrainingList = () => {

  const { trainingsId } = useParams<{ trainingsId: string }>();
  const [addBlockTrainingModal, setAddBlockTrainingModal] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { data, loading, error } = useQuery<GetTraningQuery>(
    GetTraningDocument,
    {
      variables: {
        id: trainingsId,
      },
    }
  );

  const disabledOnLoading = loading;

  let contentBlock;

  if (loading) {
    contentBlock = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (error) {
    contentBlock = (
      <PlaceholderBlock>
        При загрузке данных физкультуры произошла ошибка
      </PlaceholderBlock>
    );
  }

  if (data) {
    const blocks = data.getTraning.exercises;
    const trainingData = data.getTraning;
    const { contentUKR, startTitleUKR, endTitleUKR, startVideo, endVideo, photo } =
      trainingData;

    contentBlock = (
      <>
        <ButtonPlus
          width={179}
          variant="contained"
          disabled={
            !contentUKR ||
            !startTitleUKR ||
            !endTitleUKR ||
            !startVideo ||
            !endVideo ||
            !photo
          }
          onClick={() => setAddBlockTrainingModal(true)}
        >
          Добавить блок
        </ButtonPlus>
        {blocks &&
          blocks.map((block) => (
            <TrainingListItem
              errorMessage={errorMessage}
              key={block.id}
              block={block}
              requestError={requestError}
              setRequestError={setRequestError}
              setErrorMessage={setErrorMessage}
            />
          ))}
        <TrainingForm
          setErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
          requestError={requestError}
          disabled={disabledOnLoading}
          setRequestError={setRequestError}
          trainingData={trainingData}
        />
      </>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header>Физкультура</Header>

      {contentBlock}

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />

      <AddBlockTraining
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        open={addBlockTrainingModal}
        close={() => setAddBlockTrainingModal(false)}
        trainingsId={trainingsId}
      />
    </Container>
  );
};
