import styled from "styled-components";
import { ModalInput, secondary, primary } from "../shared/Style/Style";
import { Box, TableRow, TextField } from "@material-ui/core";

export const StyledTextField = styled(ModalInput)`
  margin-bottom: 20px;
`;

export const SubscriptionContainer = styled(Box)`
  padding: 0 30px;

  & > h3 {
    font-size: 16px;
    padding: 20px 0;
  }
`;

export const PaymentsContainer = styled(Box)`
  padding: 20px 10px 20px 20px;
  margin: 20px 0;

  & > h3 {
    font-size: 16px;
  }
`;

export const Count = styled.span`
  font-size: 25px;
  font-weight: 700;
`;

export const TableContainer = styled(Box)`
  margin: 0 14px;
  padding: 10px 0;
  border-bottom: 1px solid ${secondary};
`;

export const TableRows = styled(TableRow)`
  & td {
    border-bottom: none;
    line-height: normal;
    & div > input {
      padding: 4px;
      text-align: center;
      max-width: 80px;
      font-size: 14px;
    }
  }
`;

export const TableInputField = styled(TextField)`
  .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: ${primary};
    }
    &.Mui-focused fieldset {
      border-color: ${primary};
    }
  }
  `;

export const ImportExportContainer = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  padding: 0 30px;

  & .MuiButton-root.MuiButton-text {
    padding: 4px;
    text-transform: none;
    line-height: normal;
    font-weight: normal;
    border-radius: 10px;
    border: 2px solid ${secondary};
    cursor: pointer;
  }
`;

export const ImportModalContainer = styled.div`
  position: relative;
  padding: 4px 28px 48px;
  min-width: 446px;
  min-height: 260px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;

  & div > .MuiFormControl-root {
    width: 90%;

    & .MuiInputBase-root {
      height: 42px;
      border-radius: 2px;
    }
    
    & .MuiFilledInput-underline.Mui-disabled:before {
      border-bottom-style: none;
    }
  }
`;
