import React from "react";
import { useQuery } from "@apollo/client";
import {
  GetClassroomsByPupilDocument,
  GetClassroomsByPupilQuery,
} from "../../../generated/graphql";
import { Autocomplete } from "@material-ui/lab";
import { UserFields } from "../Style";
import { LoadingBox } from "../../shared/Style/Style";
import { CircularProgress } from "@material-ui/core";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";

interface IUserElectivesByPupil {
  pupilId: string;
  getAllItemsParams: {
    limit: number;
    skip: number;
  };
}

export const UserElectivesByPupil = ({
  pupilId,
  getAllItemsParams,
}: IUserElectivesByPupil) => {
  const {
    data,
    loading,
    error,
  } = useQuery<GetClassroomsByPupilQuery>(GetClassroomsByPupilDocument, {
    variables: {
      pupilId,
      ...getAllItemsParams,
    },
  });
  if (loading) {
    return (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }
  if (error) {
    return <ErrorSnackbar error={error} />;
  }


  const pupils =data?.getClassroomsByPupil?.classrooms||[];

  return (
    <Autocomplete
      multiple
      fullWidth
      filterSelectedOptions
      options={pupils?.map(({ id }) => id)}
      getOptionLabel={(option) =>
        pupils?.find((room) => room?.id === option)?.name || ""
      }
      value={pupils?.map(({ id }) => id)}
      disabled={true}
      renderInput={(params) => (
        <UserFields {...params} label="Факультатив" variant="standard" />
      )}
    />
  );
};
