import React, { useEffect, useState } from "react";
import {
  ButtonPlus,
  Header,
  LoadingBox,
  MainTableCell,
} from "../shared/Style/Style";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { Box, CircularProgress, Container, Radio } from "@material-ui/core";
import { CustomTablePagination } from "../shared/CustomTablePagination";
import { NavBar } from "../shared/NavBar";
import { navItems } from "../../constants/navItemMobileApp";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import {
  CreateSmartNotificationDocument,
  DeleteSmartNotificationDocument,
  DeleteSmartNotificationMutation,
  GetSmartNotificationsDocument,
  GetSmartNotificationsQuery,
  MobileNotification,
  PublishNotificationMutation,
  PublishSmartNotificationDocument,
  SmartNotificationTypeEnum,
  UnpublishSmartNotificationDocument,
  UnpublishSmartNotificationMutation,
  UpdateNotificationMutation,
  UpdateSmartNotificationDocument,
} from "../../generated/graphql";
import { useMutation, useQuery } from "@apollo/client";
import {
  CheckRudioButton,
  MobileAppNotificationTableRow,
  WithoutNotification,
} from "./SmartNotification.style";
import { Menu } from "../shared/Menu";
import { UpdateSmartNotification } from "../MobileAppModal/MobileAppModalCreateSMartNotification/UpdateSmartNotification";
import { convertDateStringToLocaleHandler } from "../shared/Utils/ConvertOperations/convertDateStringToLocaleHandler";
import { convertDateAndTimeToLocaleHandler } from "../shared/Utils/ConvertOperations/convertTimeToLocalHandler";
import { useHistory, useParams } from "react-router-dom";

const headers = ["Дата", "Время", "Текс уведомления", "Статистика", "Статус"];

export const SmartNotification = () => {
  const { appId } = useParams<{ appId: string }>();
  const { location, push } = useHistory();

  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenUpdateModal, setOpenUpdateModal] = useState(false);

  const [typeNotification, setTypeNotification] = useState(
    SmartNotificationTypeEnum.Poll
  );
  const [notificationId, setNotificationId] = useState<string>();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalType, setModalType] = useState("");

  const {
    data: smartNotificationData,
    loading: smartNotificationLoading,
    error: smartNotificationError,
  } = useQuery<GetSmartNotificationsQuery>(GetSmartNotificationsDocument, {
    variables: {
      limit: rowsPerPage,
      skip: page * rowsPerPage,
      type: typeNotification,
    },
  });

  const [
    createSmartNotification,
    {
      data: createSmartNotificationData,
      loading: createSmartNotificationLoading,
      error: createSmartNotificationError,
    },
  ] = useMutation(CreateSmartNotificationDocument, {
    refetchQueries: [
      {
        query: GetSmartNotificationsDocument,
        variables: {
          limit: rowsPerPage,
          skip: page * rowsPerPage,
          type: typeNotification,
        },
      },
    ],
  });

  const [
    updateNotification,
    {
      data: updateNotificationData,
      loading: updateNotificationLoading,
      error: updateNotificationError,
    },
  ] = useMutation<UpdateNotificationMutation>(UpdateSmartNotificationDocument, {
    refetchQueries: [
      {
        query: GetSmartNotificationsDocument,
        variables: {
          limit: rowsPerPage,
          skip: page * rowsPerPage,
          type: typeNotification,
        },
      },
    ],
  });

  const [
    deleteSmartNotification,
    {
      data: deleteSmartNotificationData,
      loading: deleteSmartNotificationLoading,
      error: deleteSmartNotificationError,
    },
  ] = useMutation<DeleteSmartNotificationMutation>(
    DeleteSmartNotificationDocument,
    {
      refetchQueries: [
        {
          query: GetSmartNotificationsDocument,
          variables: {
            limit: rowsPerPage,
            skip: page * rowsPerPage,
            type: typeNotification,
          },
        },
      ],
    }
  );

  const [
    publishNotification,
    {
      data: publishNotificationData,
      loading: publishNotificationLoading,
      error: publishNotificationError,
    },
  ] = useMutation<PublishNotificationMutation>(
    PublishSmartNotificationDocument,
    {
      refetchQueries: [
        {
          query: GetSmartNotificationsDocument,
          variables: {
            limit: rowsPerPage,
            skip: page * rowsPerPage,
            type: typeNotification,
          },
        },
      ],
    }
  );

  const [
    unpublishNotification,
    {
      data: unpublishNotificationData,
      loading: unpublishNotificationLoading,
      error: unpublishNotificationError,
    },
  ] = useMutation<UnpublishSmartNotificationMutation>(
    UnpublishSmartNotificationDocument,
    {
      refetchQueries: [
        {
          query: GetSmartNotificationsDocument,
          variables: {
            limit: rowsPerPage,
            skip: page * rowsPerPage,
            type: typeNotification,
          },
        },
      ],
    }
  );

  useEffect(() => {
    setPage(0);
  }, [typeNotification]);

  useEffect(() => {
    if (createSmartNotificationData) {
      setNotificationId(createSmartNotificationData.createSmartNotification.id);
      setModalType("create");
      setOpenUpdateModal(true);
    }
  }, [createSmartNotificationData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const closeHandler = () => {
    setModalType("");
    setOpenUpdateModal(false);
  };

  const menuItems = (id: string, isPublished: boolean) => {
    return [
      {
        name: isPublished ? "Убрать из публикации" : "Опубликовать",
        action: isPublished
          ? async (e) => {
              e.stopPropagation();
              await unpublishNotification({ variables: { id } });
            }
          : async (e) => {
              e.stopPropagation();
              await publishNotification({ variables: { id } });
            },
      },
      {
        name: "Редактировать",
        action: (e) => {
          e.stopPropagation();
          setNotificationId(id);
          setModalType("update");
          setOpenUpdateModal(true);
        },
      },
      {
        name: " Удалить",
        action: async (e) => {
          e.stopPropagation();
          await deleteSmartNotification({ variables: { id } });
        },
        color: "red",
      },
    ];
  };

  const disableOnLoading =
    smartNotificationLoading ||
    createSmartNotificationLoading ||
    publishNotificationLoading ||
    updateNotificationLoading ||
    deleteSmartNotificationLoading ||
    unpublishNotificationLoading;

  let content;

  if (disableOnLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (smartNotificationError) {
    content = <ErrorSnackbar error={"error"} />;
  }

  if (smartNotificationData) {
    const smartNotifications =
      smartNotificationData.getSmartNotifications.notifications;
    const total = smartNotificationData.getSmartNotifications.total;

    content = (
      <>
        <Box display="flex" mt={2}>
          <Box display="flex" alignItems="center">
            <CheckRudioButton
              checked={SmartNotificationTypeEnum.Poll === typeNotification}
              onChange={() =>
                setTypeNotification(SmartNotificationTypeEnum.Poll)
              }
              value={SmartNotificationTypeEnum.Poll}
              name="radio-buttons"
            />
            <Box>Опросы</Box>
          </Box>

          <Box display="flex" alignItems="center">
            <CheckRudioButton
              checked={SmartNotificationTypeEnum.Rating === typeNotification}
              onChange={() =>
                setTypeNotification(SmartNotificationTypeEnum.Rating)
              }
              value={SmartNotificationTypeEnum.Rating}
              name="radio-buttons"
            />
            <Box>Оценка</Box>
          </Box>

          <Box display="flex" alignItems="center">
            <CheckRudioButton
              checked={SmartNotificationTypeEnum.Link === typeNotification}
              onChange={() =>
                setTypeNotification(SmartNotificationTypeEnum.Link)
              }
              value={SmartNotificationTypeEnum.Link}
              name="radio-buttons"
            />
            <Box>Ссылка</Box>
          </Box>
        </Box>

        <Box mt={5}>
          <CustomTablePagination
            rowsPerPageOptions={[10, 20]}
            count={total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>

        <MobileAppNotificationTableRow>
          {headers.map((header) => (
            <MainTableCell>{header}</MainTableCell>
          ))}
        </MobileAppNotificationTableRow>

        {(smartNotifications.length &&
          smartNotifications.map(
            ({ id, createdDate, description, isPublished }) => (
              <MobileAppNotificationTableRow
                key={id}
                onClick={(e) => {
                  push(`${location.pathname}/${id}`);
                }}
              >
                <MainTableCell>
                  {convertDateStringToLocaleHandler(createdDate)}
                </MainTableCell>

                <MainTableCell>
                  {convertDateAndTimeToLocaleHandler(createdDate)}
                </MainTableCell>

                <MainTableCell>{description}</MainTableCell>

                <MainTableCell>Statistic</MainTableCell>

                <MainTableCell>
                  {(isPublished && "Опубликованно") || "Не опубликованно"}
                </MainTableCell>

                <Menu
                  vertical={36}
                  horizontal={12}
                  width={170}
                  disabled={disableOnLoading}
                  items={menuItems(id, isPublished)}
                />
              </MobileAppNotificationTableRow>
            )
          )) || (
          <WithoutNotification>
            <MainTableCell>Уведомления отсутсвуют</MainTableCell>
          </WithoutNotification>
        )}
      </>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header>Опросы</Header>

      <Box display="flex" justifyContent="space-between" alignItems="baseline" mt={5}>
        <NavBar items={navItems} margin={"100px 0 0 0"} />

        <ButtonPlus
          width={179}
          variant="contained"
          disabled={disableOnLoading}
          onClick={() => {
            createSmartNotification({
              variables: {
                smartNotificationData: {
                  applicationId: appId,
                  type: typeNotification,
                },
              },
            });
          }}
        >
          Добавить
        </ButtonPlus>
      </Box>

      {content}

      {isOpenUpdateModal && (
        <UpdateSmartNotification
          applicationId={appId}
          modalType={modalType}
          isOpen={isOpenUpdateModal}
          close={closeHandler}
          notificationId={notificationId}
          typeNotification={typeNotification}
          setRequestError={setRequestError}
          setErrorMessage={setErrorMessage}
          handleFetch={updateNotification}
        />
      )}

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Container>
  );
};
