import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  ClientSite,
  GetClientSitesDocument,
  GetClientSitesQuery,
} from "../../generated/graphql";
import {
  Box,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableHead,
} from "@material-ui/core";
import { Menu } from "../shared/Menu";
import { CustomTablePagination } from "../shared/CustomTablePagination";
import { SiteModal } from "./SiteModal";
import { DeleteModal } from "./DeleteModal";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import {
  EmptyTableRow,
  SiteLink,
  SitesHeaderTableRow,
  SitesTableRow,
} from "./Sites.style";
import {
  ButtonPlus,
  Header,
  LoadingBox,
  MainTableCell,
  MainTableHeaderTitle,
} from "../shared/Style/Style";

const headers = ["Название", "Ключ", "Ссылка на айфрейм", "Ссылка"];

export const Sites = () => {
  const history = useHistory();

  const [currentSite, setCurrentSite] = useState<ClientSite>(null);

  const [createSite, setCreateSite] = useState(false);
  const [editSite, setEditSite] = useState(false);
  const [deleteSite, setDeleteSite] = useState(false);

  const [requestError, setRequestError] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    data: clientSitesData,
    loading: clientSitesLoading,
    error: clientSitesError,
  } = useQuery<GetClientSitesQuery>(
    GetClientSitesDocument, {
      variables: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  useEffect(() => {
    if (clientSitesError) {
      setRequestError(true);
    }
  }, [clientSitesError]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const menuItems = [
    {
      name: "К списку моделей",
      action: () => history.push(`/sites/${currentSite.id}/models`),
    },
    {
      name: "Редактировать",
      action: () => {
        setEditSite(true);
      },
    },
    {
      name: " Удалить",
      action: () => setDeleteSite(true),
      color: "red",
    },
  ];

  let content;

  if (clientSitesLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (clientSitesData) {
    const clientSites = clientSitesData.getClientSites.clientSites;

    const pagination = (
      <CustomTablePagination
        rowsPerPageOptions={[5, 10]}
        count={clientSitesData.getClientSites.total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    );
    content = (
      <>
        <Table>
          <TableHead>
            <SitesHeaderTableRow>
              {headers.map((header) => (
                <MainTableHeaderTitle key={header}>
                  {header}
                </MainTableHeaderTitle>
              ))}
            </SitesHeaderTableRow>
          </TableHead>

          <TableBody>
            {clientSites?.length ? (
              clientSites?.map((site) => (
                <SitesTableRow
                  key={site.id}
                >
                  <MainTableCell>{site.name}</MainTableCell>

                  <MainTableCell>
                    {site.key ? (
                      <SiteLink>
                        {site.key}
                      </SiteLink>
                    ) : (
                      "-"
                    )}
                  </MainTableCell>

                  <MainTableCell>
                    {site.iframe ? (
                      <SiteLink href={site.iframe} target="_blank">
                        {site.iframe}
                      </SiteLink>
                    ) : (
                      "-"
                    )}
                  </MainTableCell>

                  <MainTableCell>
                    {site.link ? (
                      <SiteLink href={site.link} target="_blank">
                        {site.link}
                      </SiteLink>
                    ) : (
                      "-"
                    )}
                  </MainTableCell>

                  <MainTableCell
                    style={{ position: "absolute", right: "0", top: 'calc(50% - 24px)' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentSite(site);
                    }}
                  >
                    <Menu
                      vertical={36}
                      horizontal={12}
                      width={170}
                      items={menuItems}
                    />
                  </MainTableCell>
                </SitesTableRow>
              ))
            ) : (
              <EmptyTableRow>Сайты отсутствуют</EmptyTableRow>
            )}
          </TableBody>
        </Table>

        <Box mt={2}>
          {pagination}
        </Box>
      </>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header>Сайты</Header>
      <Box textAlign="right" mt={3} mb={3}>
        <ButtonPlus
          width={179}
          variant="contained"
          onClick={() => setCreateSite(true)}
        >
          Добавить сайт
        </ButtonPlus>
      </Box>
      {content}

      <SiteModal
        open={createSite || editSite}
        close={() => (editSite ? setEditSite(false) : setCreateSite(false))}
        refetchVariables={{
          limit: rowsPerPage,
          skip: page * rowsPerPage,
        }}
        edit={editSite}
        item={currentSite}
      />

      <DeleteModal
        open={deleteSite}
        close={() => setDeleteSite(false)}
        item={currentSite}
        refetchVariables={{
          limit: rowsPerPage,
          skip: page * rowsPerPage,
        }}
      />

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={"Ошибка при получении сайтов клиентов"}
      />
    </Container>
  );
};
