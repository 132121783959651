import React, {memo} from 'react';
import {Draggable} from "react-beautiful-dnd";
import {ThemeItem, ThemeItemProps} from "./ThemeItem";

interface ThemeItemDnd extends ThemeItemProps {
  position: number,
}

export const ThemeItemDnd = memo(({
  position,
  themeId,
  title,
  description,
  isPublished,
  publishedAt,
  setOpenModal,
  setCurrentThemeId
}: ThemeItemDnd) => {
  return (
    <Draggable draggableId={themeId} index={position}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ThemeItem
            themeId={themeId}
            title={title}
            description={description}
            isPublished={isPublished}
            publishedAt={publishedAt}
            setOpenModal={setOpenModal}
            setCurrentThemeId={setCurrentThemeId}
          />
        </div>
      )
      }
    </Draggable>
  )
});
