import { FC } from "react";
import { useReactiveVar } from "@apollo/client";
import { ModalInput } from "../../../../../shared/Style/Style";
import { robotsState } from "../../../state";

interface ArtifactClothTypeProps {
    id: string;
}

export const ArtifactClothType: FC<ArtifactClothTypeProps> = ({id}) => {
    const artifactClothTypes = useReactiveVar(robotsState.artifactClothTypes);
    const clothPart = artifactClothTypes?.find(item => item.id === id)?.displayName;
    
    return (
        <ModalInput
            key={id}
            type="text"
            variant="filled"
            margin="normal"
            value={clothPart}
            disabled
        />
    );    
};
