import React, { useEffect, useState } from "react";
import { InputLabel, Select, FormControl, MenuItem } from "@material-ui/core";
import { useWhiteDictionaryStyles } from "../Style/Style";
import { ProjectTaskStatus } from "../../../generated/graphql";
import { ProjectDataForRequest } from "../../OverdueTasks/OverdueTasks";

interface DictionaryProjectSelectProps {
  label: string;
  values: ProjectTaskStatus[];
  currentStatus: ProjectTaskStatus;
  projectData: ProjectDataForRequest;
  setStatus(status: ProjectDataForRequest): void;
}

export const DictionaryProjectTaskStatusSelect = ({
  label,
  values,
  currentStatus,
  projectData,
  setStatus,
}: DictionaryProjectSelectProps) => {
  const [value, setValue] = useState(currentStatus.id);
  const classes = useWhiteDictionaryStyles();

  useEffect(() => {
    setStatus({ ...projectData, statusId: currentStatus.id });
  }, [currentStatus]);

  return (
    <FormControl className={classes.root} variant="filled">
      {!value && <InputLabel>{label}</InputLabel>}
      <Select
        value={value}
        onChange={(e) => {
          setValue(e.target.value as string);
          setStatus({ ...projectData, statusId: e.target.value as string });
        }}
        fullWidth
      >
        {values?.map((value) => {

          return (
            <MenuItem key={value.id} value={value.id}>
              {(value.name === "new" && "новая") ||
                (value.name === "inProgress" && "в работе") ||
                (value.name === "done" && "выполнена")}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
