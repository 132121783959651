import React, { useState } from "react";

import { Maybe } from "../../../generated/graphql";

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
  Box,
} from "@material-ui/core";

import {
  ButtonCustom,
} from "../Style/Style";

import {
  PhotoTemplate,
  PhotoPickerLabel,
  PhotoPickerDropzone,
  PhotoUploaded,
  PhotoPickerImage,
  IconDeletePhoto
} from "./PhotoPicker.style";

import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { imageValidation } from "../../MobileAppModal/MobileAppModelModal/utils";


interface IPhotoPickerProps {
  photo: Maybe<string> | undefined;
  id?: string | undefined
  setNewPhoto(file: File | undefined): void;
  setDeletePhoto?(id: string | null): void;
  showError?(error: boolean, message: string): void;
}

export const PhotoPicker = ({ photo, id, setNewPhoto, setDeletePhoto, showError }: IPhotoPickerProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [latestPhoto, setLatestPhoto] = useState<string | ArrayBuffer | null>(photo);
  
  const handleCancel = (): void => {
    setImage(null);
    setImageSrc(null);
    setOpen(false);
  };

  const handleSave = (): void => {
    setNewPhoto(image);
    setLatestPhoto(imageSrc as string);
    setOpen(false);
  };
  
  const handleDeletePhoto = () => {
    setImage(null);
    setImageSrc(null);
    setLatestPhoto(null);
    setDeletePhoto && setDeletePhoto(id);
  };

  const [image, setImage] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string | ArrayBuffer | null>();
   
  const handleDrop = (dropped: File): void => {
    const { isValidImage, isInvalidExtension, errorMessage } = imageValidation(dropped);
    
    if (isValidImage && !isInvalidExtension) {
      setImage(dropped);
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(dropped);
    } else {
      handleCancel();
      showError(true, errorMessage);
    }
  };

  const PhotoIcon = latestPhoto ?
    <PhotoUploaded src={latestPhoto as string} /> :
    <Box mt={3}>
      <AddPhotoAlternateIcon fontSize="large" color="disabled" />
      <PhotoPickerLabel>Добавьте фото</PhotoPickerLabel>
    </Box>;

  const DeletePhotoButton = id && latestPhoto &&
    <Tooltip title="Удалить фото">
      <IconDeletePhoto onClick={handleDeletePhoto} />
    </Tooltip>;
  
  const PhotoDialogContent = !imageSrc ?
    <PhotoPickerDropzone
      onDrop={handleDrop}
      dropzoneContent={<PhotoPickerLabel>Нажмите чтобы добавить фото</PhotoPickerLabel>}
    /> :
    <PhotoPickerImage src={imageSrc as string} />;
  
  const DialogActionsButtons = <DialogActions>
    <Button onClick={handleCancel}>Отмена</Button>
    <ButtonCustom disabled={!image} onClick={handleSave} color="primary" autoFocus>Сохранить</ButtonCustom>
  </DialogActions>;
  
  return (
    <>
      <PhotoTemplate dashed={!!latestPhoto} onClick={() => setOpen(true)}>{PhotoIcon}</PhotoTemplate>
      
      {DeletePhotoButton}

      <Dialog open={open}>

        <DialogContent>
          {PhotoDialogContent}
          {DialogActionsButtons}
        </DialogContent>

      </Dialog>
    </>
  )
};