import { ApolloClient, makeVar } from "@apollo/client";
import { GET_GAME_MODEL, GET_GAME_MODEL_ARTIFACT, GET_GAME_MODEL_ARTIFACT_ANIMATION_TYPES, GET_GAME_MODEL_ARTIFACT_CLOTH_TYPES, GET_GAME_MODEL_ARTIFACTS, GET_GAME_MODEL_STATE, GET_GAME_MODEL_STATE_STAGES, GET_GAME_MODEL_STATES, GET_GAME_MODEL_STATES_FOR_SELECT, GET_GAME_MODELS } from "../../../graphql/queries/gamification";
import { CREATE_GAME_MODEL, CREATE_GAME_MODEL_ARTIFACT, CREATE_GAME_MODEL_STATE, DELETE_GAME_MODEL_ARTIFACT, DELETE_GAME_MODEL_ARTIFACT_FILE, DELETE_GAME_MODEL_STATE_FILE, UPDATE_GAME_MODEL, UPDATE_GAME_MODEL_ARTIFACT, UPDATE_GAME_MODEL_ARTIFACT_FILE, UPDATE_GAME_MODEL_STATE, UPDATE_GAME_MODEL_STATE_FILE } from "../../../graphql/mutations/gamification";
import {
    ArtifactRankEnum,
    CreateGameModelArtifactMutation,
    CreateGameModelArtifactMutationVariables,
    CreateGameModelMutation,
    CreateGameModelMutationVariables,
    CreateGameModelStateMutation,
    CreateGameModelStateMutationVariables,
    DeleteGameModelArtifactFileMutation,
    DeleteGameModelArtifactFileMutationVariables,
    DeleteGameModelArtifactMutation,
    DeleteGameModelArtifactMutationVariables,
    DeleteGameModelStateFileMutation,
    DeleteGameModelStateFileMutationVariables,
    GameModel,
    GameModelArtifact,
    GameModelArtifactAnimationType,
    GameModelArtifactClothType,
    GameModelArtifactFileTypeEnum,
    GameModelState,
    GameModelStateStage,
    GetGameModelArtifactAnimationTypesQuery,
    GetGameModelArtifactClothTypesQuery,
    GetGameModelArtifactQuery,
    GetGameModelArtifactQueryVariables,
    GetGameModelArtifacts,
    GetGameModelArtifactsQuery,
    GetGameModelArtifactsQueryVariables,
    GetGameModelQuery,
    GetGameModelQueryVariables,
    GetGameModelsQuery,
    GetGameModelStateQuery,
    GetGameModelStateQueryVariables,
    GetGameModelStatesForSelectQuery,
    GetGameModelStatesForSelectQueryVariables,
    GetGameModelStatesQuery,
    GetGameModelStatesQueryVariables,
    GetGameModelStateStagesQuery,
    UpdateGameModelArtifactFileMutation,
    UpdateGameModelArtifactFileMutationVariables,
    UpdateGameModelArtifactMutation,
    UpdateGameModelArtifactMutationVariables,
    UpdateGameModelMutation,
    UpdateGameModelMutationVariables,
    UpdateGameModelStateFileMutation,
    UpdateGameModelStateFileMutationVariables,
    UpdateGameModelStateMutation,
    UpdateGameModelStateMutationVariables
} from "../../../generated/graphql";
import { isValidHexColor } from "../Awards/utils";
import { AddArtifactItem, CurrentArtifactMode, DeleteArtifact, DeleteRobot, DeleteStage, InputErrors, NotificationsType } from "./types";
import {
    descriptionMaxLength,
    initialDeleteRobot,
    initialNotifications,
    initialRobotGeneralInfo,
    initialStageGeneralInfo,
    nameMaxLength,
    RobotGeneralFields,
    initialInputErrors,
    initialDeleteStage,
    initialAddArtifactItem,
    initialDeleteArtifact
} from "./constants";

export class MobileAppRobotsPageState {
    apolloClient: ApolloClient<any> | null = null;

    initMobileAppRobots(client: ApolloClient<any>) {
        this.apolloClient = client;
    }

    robotGeneralInfo = makeVar<GameModel>(initialRobotGeneralInfo);

    stageGeneralInfo = makeVar<GameModelState>(initialStageGeneralInfo);

    stageBundles = makeVar<GameModelState>(null);

    robotsList = makeVar<GameModel[]>(null);

    robotStagesList = makeVar<GameModelState[]>(null);

    robotStageTypes = makeVar<GameModelStateStage[]>([]);

    artifact = makeVar<GameModelArtifact>(null);
    
    currentArtifactMode = makeVar<CurrentArtifactMode>(null);

    artifacts = makeVar<GetGameModelArtifacts>(null);
    
    artifactClothTypes = makeVar<GameModelArtifactClothType[]>([]);

    artifactAnimationTypes = makeVar<GameModelArtifactAnimationType[]>([]);
    
    artifactsSkipPage = makeVar(0);

    isCreateRobotModalOpen = makeVar(false);

    isCreateRobotStageModalOpen = makeVar(false);

    isArtifactModalOpen = makeVar<AddArtifactItem>(initialAddArtifactItem);

    deleteRobot = makeVar<DeleteRobot>(initialDeleteRobot);

    deleteStage = makeVar<DeleteStage>(initialDeleteStage);

    deleteArtifact = makeVar<DeleteArtifact>(initialDeleteArtifact);

    inputErrors = makeVar<InputErrors>(initialInputErrors);

    notifications = makeVar<NotificationsType>(initialNotifications);

    isLoading = makeVar(false);

    clearNotifications() {
        this.inputErrors(initialInputErrors);
        this.notifications(initialNotifications);
    }

    clearRobot() {
        this.robotGeneralInfo(initialRobotGeneralInfo);
        this.robotStagesList(null);
        this.clearNotifications();
    }

    clearStage() {
        this.stageGeneralInfo(initialStageGeneralInfo);
        this.stageBundles(null);
        this.clearNotifications();
    }

    clearArtifact() {
        this.artifact(null);
        this.inputErrors(initialInputErrors);
    }

    clearArtifacts() {
        this.artifacts(null);
        this.currentArtifactMode(null);
        this.artifactsSkipPage(0);
        this.clearNotifications();
    }

    setCurrentRobot(robot: GameModel) {
        this.robotGeneralInfo(
            {
                name: robot.name,
                description: robot.description,
                background: robot.background,
                order: robot.order,
            }
        
        );
    }

    setCurrentStage(stage: GameModelState) {
        const { id, image, name, description, energyPrice, experiencePrice, gameModelId,
            iosBundle, iosManifest, androidBundle, androidManifest } = stage ?? {};

        this.stageGeneralInfo({ id, image, name, description, energyPrice, experiencePrice, gameModelId });
        this.stageBundles({ iosBundle, iosManifest, androidBundle, androidManifest });
    }

    setRobotStagesList(stages: GameModelState[]) {
        this.robotStagesList(stages);
    }

    setRobotOrder(order: number) {
        this.robotGeneralInfo({ ...this.robotGeneralInfo(), order });
    }

    setRobotName(name: string) {
        if (name.length > nameMaxLength) {
            robotsState.setInputErrors(
                RobotGeneralFields.Name,
                true,
                "Название не должно превышать 20 символов"
            );
        } else {
            robotsState.setInputErrors(RobotGeneralFields.Name, false);
        }
        
        this.robotGeneralInfo({ ...this.robotGeneralInfo(), name });
    }

    setRobotDescription(description: string) {
        if (description.length > descriptionMaxLength) {
            robotsState.setInputErrors(
                RobotGeneralFields.Description,
                true,
                "Описание не должно превышать 200 символов"
            );
        } else {
            robotsState.setInputErrors(RobotGeneralFields.Description, false);
        }

        this.robotGeneralInfo({ ...this.robotGeneralInfo(), description });
    }

    setRobotBackground(background: string) {
        if (!isValidHexColor(background)) {
            robotsState.setInputErrors(
                RobotGeneralFields.Background,
                true,
                "Неверный формат цвета фона"
            );
        } else {
            robotsState.setInputErrors(RobotGeneralFields.Background, false);
        }
        
        this.robotGeneralInfo({ ...this.robotGeneralInfo(), background });
    }

    setStageGeneralInfo(field: keyof GameModelState, value: string | number) {
        this.stageGeneralInfo({ ...this.stageGeneralInfo(), [field]: value });
    }

    setRobotStagesTypes(types: GameModelStateStage[]) {
        this.robotStageTypes(types);
    }

    setArtifact(artifact: GameModelArtifact) {
        this.artifact(artifact);
    }

    setArtifactName(name: string) {
        this.artifact({ ...this.artifact(), name });
    }

    setArtifactPrice(energyPrice: number) {
        this.artifact({ ...this.artifact(), energyPrice });
    }

    setArtifactRank(rank: ArtifactRankEnum) { 
        this.artifact({ ...this.artifact(), rank });
    }

    setArtifactAnimationType(animationTypeId: string) {
        this.artifact({ ...this.artifact(), animationTypeId });
    }

    setArtifactFile(field: GameModelArtifactFileTypeEnum, link: string) {
        this.artifact({ ...this.artifact(), [field]: link});
    }

    setCurrentArtifactMode(mode: CurrentArtifactMode) {
        this.currentArtifactMode(mode);
    }

    setArtifacts(artifacts: GetGameModelArtifacts) {
        this.artifacts(artifacts);
    }
    
    setArtifactClothTypes(types: GameModelArtifactClothType[]) {
        this.artifactClothTypes(types);
    }

    setArtifactAnimationTypes(types: GameModelArtifactAnimationType[]) {
        this.artifactAnimationTypes(types);
    }

    setArtifactsSkipPage(page: number) {
        this.artifactsSkipPage(page);
    }

    setIsCreateRobotModalOpen(isOpen: boolean) {
        this.isCreateRobotModalOpen(isOpen);
    }

    setIsCreateRobotStageModalOpen(isOpen: boolean) {
        this.isCreateRobotStageModalOpen(isOpen);
    }

    setIsArtifactModalOpen(value: AddArtifactItem) {
        this.isArtifactModalOpen(value);
    }

    setNotifications(notifications: NotificationsType) {
        this.notifications(notifications);
    }

    setDeleteRobot(deleteRobot: DeleteRobot) {
        this.deleteRobot(deleteRobot);
    }

    setDeleteStage(deleteStage: DeleteStage) {
        this.deleteStage(deleteStage);
    }

    setDeleteArtifact(deleteArtifact: DeleteArtifact) { 
        this.deleteArtifact(deleteArtifact);
    }

    setInputErrors(field: keyof InputErrors, error: boolean, message: string = "Заполните все необходимые поля") {
        const errorObj = error ? { error, message } : initialNotifications;

        this.inputErrors({ ...this.inputErrors(), [field]: { error, message } });

        this.setNotifications(errorObj)
    }

    setLoading(isLoading: boolean) {
        this.isLoading(isLoading);
    }

    async getRobots() { 
        try { 
            this.setLoading(true);
            const result = await this.apolloClient.query<GetGameModelsQuery>({
                query: GET_GAME_MODELS,
                fetchPolicy: "network-only",
            });

            if (result?.data?.getGameModels) { 
                this.robotsList(result.data.getGameModels);
                return;
            }

            this.notifications({ error: true, message: "Произошла ошибка при загрузке роботов" });
        } catch (error) {
            this.notifications({ error: true, message: "Произошла ошибка при загрузке роботов" });
        } finally { 
            this.setLoading(false);
        }
    }

    async getRobot(variables: GetGameModelQueryVariables) { 
        try {
            this.setLoading(true);
            const result = await this.apolloClient.query<GetGameModelQuery>({
                query: GET_GAME_MODEL,
                variables,
                fetchPolicy: "network-only",
            });

            if (result?.data?.getGameModel?.id) { 
                return this.setCurrentRobot(result.data.getGameModel);
            };

            this.notifications({ error: true, message: "Произошла ошибка при загрузке робота" });
        } catch { 
            this.notifications({ error: true, message: "Произошла ошибка при загрузке робота" });
        } finally {
            this.setLoading(false);
        }
    }

    async getRobotStages(variables: GetGameModelStatesQueryVariables) { 
        try {
            this.setLoading(true);
            const result = await this.apolloClient.query<GetGameModelStatesQuery>({
                query: GET_GAME_MODEL_STATES,
                variables,
                fetchPolicy: "network-only",
            });

            if (result?.data?.getGameModelStates) {
                return this.setRobotStagesList(result.data.getGameModelStates);
            }

            this.notifications({ error: true, message: "Произошла ошибка при загрузке стадий" });
        } catch {
            this.notifications({ error: true, message: "Произошла ошибка при загрузке стадий" });
        } finally {
            this.setLoading(false);
        }
    }

    async getRobotStagesForSelect(variables: GetGameModelStatesForSelectQueryVariables) { 
        try {
            this.setLoading(true);
            const result = await this.apolloClient.query<GetGameModelStatesForSelectQuery>({
                query: GET_GAME_MODEL_STATES_FOR_SELECT,
                variables,
            });

            if (result?.data?.getGameModelStates) {
                return this.setRobotStagesList(result.data.getGameModelStates);
            }

            this.notifications({ error: true, message: "Произошла ошибка при загрузке списка стадий" });
        } catch {
            this.notifications({ error: true, message: "Произошла ошибка при загрузке списка стадий" });
        } finally {
            this.setLoading(false);
        }
    }

    async getRobotStage(variables: GetGameModelStateQueryVariables) {
        try {
            this.setLoading(true);
            const result = await this.apolloClient.query<GetGameModelStateQuery>({
                query: GET_GAME_MODEL_STATE,
                variables,
                fetchPolicy: "network-only",
            });

            if (result?.data?.getGameModelState) {
                return this.setCurrentStage(result.data.getGameModelState);
            };

            this.notifications({ error: true, message: "Произошла ошибка при загрузке стадии" });
        } catch {
            this.notifications({ error: true, message: "Произошла ошибка при загрузке стадии" });
        } finally {
            this.setLoading(false);
        }
    }

    async getRobotArtifact(variables: GetGameModelArtifactQueryVariables) {
        try {
            this.setLoading(true);
            const result = await this.apolloClient.query<GetGameModelArtifactQuery>({
                query: GET_GAME_MODEL_ARTIFACT,
                variables,
                fetchPolicy: "network-only",
            });

            if (result?.data?.getGameModelArtifact) {
                return this.setArtifact(result?.data?.getGameModelArtifact);
            };

            this.notifications({ error: true, message: "Произошла ошибка при загрузке артефакта" });
        } catch {
            this.notifications({ error: true, message: "Произошла ошибка при загрузке артефакта" });
        } finally {
            this.setLoading(false);
        }
    }

    async getRobotArtifacts(
        variables: GetGameModelArtifactsQueryVariables,
    ) { 
        try {
            this.setLoading(true);
            const result = await this.apolloClient.query<GetGameModelArtifactsQuery>({
                query: GET_GAME_MODEL_ARTIFACTS,
                variables,
                fetchPolicy: "network-only",
            });

            if (result?.data?.getGameModelArtifacts) {
                return this.setArtifacts(result.data.getGameModelArtifacts);
            };

            this.notifications({ error: true, message: "Произошла ошибка при загрузке артефактов робота" });
        } catch { 
            this.notifications({ error: true, message: "Произошла ошибка при загрузке артефактов робота" });
        } finally { 
            this.setLoading(false);
        }
    }

    async getArtifactClothTypes() { 
        try { 
            this.setLoading(true);
            const result = await this.apolloClient.query<GetGameModelArtifactClothTypesQuery>({
                query: GET_GAME_MODEL_ARTIFACT_CLOTH_TYPES,
                fetchPolicy: "network-only",
            });

            if (result?.data?.getGameModelArtifactClothTypes) {
                return this.setArtifactClothTypes(result.data.getGameModelArtifactClothTypes);
            };

            this.notifications({ error: true, message: "Произошла ошибка при загрузке типов одежды"});
        } catch {
            this.notifications({ error: true, message: "Произошла ошибка при загрузке типов одежды"});
        } finally {
            this.setLoading(false);
        }
    }

    async getArtifactAnimationTypes() { 
        try {
            this.setLoading(true);
            const result = await this.apolloClient.query<GetGameModelArtifactAnimationTypesQuery>({
                query: GET_GAME_MODEL_ARTIFACT_ANIMATION_TYPES,
                fetchPolicy: "network-only",
            });

            if (result?.data?.getGameModelArtifactAnimationTypes) {
                return this.artifactAnimationTypes(result.data.getGameModelArtifactAnimationTypes);
            };

            this.notifications({ error: true, message: "Произошла ошибка при загрузке списка видов анимации"});
        } catch { 
            this.notifications({ error: true, message: "Произошла ошибка при загрузке списка видов анимации" });
        } finally {
            this.setLoading(false);
        }
    }

    async getRobotStageTypes() { 
        try {
            this.setLoading(true);
            const result = await this.apolloClient.query<GetGameModelStateStagesQuery>({
                query: GET_GAME_MODEL_STATE_STAGES,
                fetchPolicy: "network-only",
            });

            if (result?.data?.getGameModelStateStages) {
                return this.setRobotStagesTypes(result.data.getGameModelStateStages);
            };

            this.notifications({ error: true, message: "Произошла ошибка при загрузке типов стадий" });
        } catch { 
            this.notifications({ error: true, message: "Произошла ошибка при загрузке типов стадий" });
        } finally { 
            this.setLoading(false);
        }
    };

    async createRobot(variables: CreateGameModelMutationVariables) { 
        try {
            this.setLoading(true);
            const result = await this.apolloClient.mutate<CreateGameModelMutation>({
                mutation: CREATE_GAME_MODEL,
                variables,
            });

            if (result?.data?.createGameModel?.id) {
                return this.notifications({ success: true, message: "Робот успешно создан" });
            }

            this.notifications({ error: true, message: "Произошла ошибка при создании робота" });
        } catch {
            this.notifications({ error: true, message: "Произошла ошибка при создании робота" });
        } finally {
            this.setLoading(false);
        }
    }

    async updateRobot(variables: UpdateGameModelMutationVariables) { 
        try {
            this.setLoading(true);
            const result = await this.apolloClient.mutate<UpdateGameModelMutation>({
                mutation: UPDATE_GAME_MODEL,
                variables,
            });

            if (result?.data?.updateGameModel?.id) {
                return this.notifications({ success: true, message: "Робот успешно обновлен" });
            }

            this.notifications({ error: true, message: "Произошла ошибка при обновлении робота" });
        } catch { 
            this.notifications({ error: true, message: "Произошла ошибка при обновлении робота" });
        } finally {
            this.setLoading(false);
         }
    }

    async createRobotStage(variables: CreateGameModelStateMutationVariables) { 
        try { 
            this.setLoading(true);
            const result = await this.apolloClient.mutate<CreateGameModelStateMutation>({
                mutation: CREATE_GAME_MODEL_STATE,
                variables,
            });

            if (result?.data?.createGameModelState?.id) {
                return this.notifications({ success: true, message: "Стадия успешно создана" });
            }

            this.notifications({ error: true, message: "Произошла ошибка при создании стадии" });
        } catch {
            this.notifications({ error: true, message: "Произошла ошибка при создании стадии" });
        } finally { 
            this.setLoading(false);
        }
    }

    async updateRobotStage(variables: UpdateGameModelStateMutationVariables) { 
        try { 
            this.setLoading(true);
            const result = await this.apolloClient.mutate<UpdateGameModelStateMutation>({
                mutation: UPDATE_GAME_MODEL_STATE,
                variables,
            });

            if (result?.data?.updateGameModelState?.id) {
                return this.notifications({ success: true, message: "Стадия успешно обновлена" });
            }

            this.notifications({ error: true, message: "Произошла ошибка при обновлении стадии" });
        } catch { 
            this.notifications({ error: true, message: "Произошла ошибка при обновлении стадии" });
        } finally { 
            this.setLoading(false);
        }
    }
    
    async updateRobotStageFile(variables: UpdateGameModelStateFileMutationVariables) { 
        try {
            this.setLoading(true);
            const result = await this.apolloClient.mutate<UpdateGameModelStateFileMutation>({
                mutation: UPDATE_GAME_MODEL_STATE_FILE,
                variables,
            });
            
            if (result?.data?.updateGameModelStateFile) {
                return this.notifications({ success: true, message: "Файл успешно загружен" });
            };

            this.notifications({ error: true, message: "Произошла ошибка при загрузке файла" });
        } catch {
            this.notifications({ error: true, message: "Произошла ошибка при загрузке файла" });
        } finally {
            this.setLoading(false);
        }
    }

    async deleteRobotStageFile(variables: DeleteGameModelStateFileMutationVariables) {
        try {
            this.setLoading(true);
            const result = await this.apolloClient.mutate<DeleteGameModelStateFileMutation>({
                mutation: DELETE_GAME_MODEL_STATE_FILE,
                variables,
            });

            if (result?.data?.deleteGameModelStateFile) {
                return this.notifications({ success: true, message: "Файл успешно удален" });
            };

            this.notifications({ error: true, message: "Произошла ошибка при удалении файла" });
        } catch {
            this.notifications({ error: true, message: "Произошла ошибка при удалении файла" });
        } finally {
            this.setLoading(false);
        }
    }

    async createArtifact(variables: CreateGameModelArtifactMutationVariables) {
        try { 
            this.setLoading(true);
            const result = await this.apolloClient.mutate<CreateGameModelArtifactMutation>({
                mutation: CREATE_GAME_MODEL_ARTIFACT,
                variables,
            });

            if (result?.data?.createGameModelArtifact?.id) {
                return this.notifications({ success: true, message: "Артефакт успешно создан" });
            }

            this.notifications({ error: true, message: "Произошла ошибка при создании артефакта" });
        } catch {
            this.notifications({ error: true, message: "Произошла ошибка при создании артефакта" });
        } finally {
            this.setLoading(false);
        }
    }

    async updateArtifact(variables: UpdateGameModelArtifactMutationVariables) {
        try {
            this.setLoading(true);
            const result = await this.apolloClient.mutate<UpdateGameModelArtifactMutation>({
                mutation: UPDATE_GAME_MODEL_ARTIFACT,
                variables,
            });

            if (result?.data?.updateGameModelArtifact?.id) {
                return this.notifications({ success: true, message: "Артефакт успешно обновлен" });
            }

            this.notifications({ error: true, message: "Произошла ошибка при обновлении артефакта" });
        } catch {
            this.notifications({ error: true, message: "Произошла ошибка при обновлении артефакта" });
        } finally {
            this.setLoading(false);
        }
    }

    async deleteRobotArtifact(
        variables: DeleteGameModelArtifactMutationVariables,
        callback?: () => void,
    ) { 
        try {
            this.setLoading(true);
            const result = await this.apolloClient.mutate<DeleteGameModelArtifactMutation>({
                mutation: DELETE_GAME_MODEL_ARTIFACT,
                variables,
            });

            if (result?.data?.deleteGameModelArtifact) {
                callback && callback();
                return this.notifications({ success: true, message: "Артефакт успешно удален" });
            };

            this.notifications({ error: true, message: "Произошла ошибка при удалении артефакта"});
        } catch {
            this.notifications({ error: true, message: "Произошла ошибка при удалении артефакта"});
        } finally {
            this.setLoading(false);
        }
    }

    async updateArtifactFile(
        variables: UpdateGameModelArtifactFileMutationVariables,
        callback?: () => void,
    ) {
        try {
            this.setLoading(true);
            const result = await this.apolloClient.mutate<UpdateGameModelArtifactFileMutation>({
                mutation: UPDATE_GAME_MODEL_ARTIFACT_FILE,
                variables,
            });

            if (result?.data?.updateGameModelArtifactFile) {
                callback && callback();
                return this.notifications({ success: true, message: "Файл успешно загружен" });
            };

            this.notifications({ error: true, message: "Произошла ошибка при загрузке файла" });
        } catch {  
            this.notifications({ error: true, message: "Произошла ошибка при загрузке файла" });
        } finally { 
            this.setLoading(false);
        }
    }

    async deleteArtifactFile(
        variables: DeleteGameModelArtifactFileMutationVariables,
        callback?: () => void,
    ) { 
        try {
            this.setLoading(true);
            const result = await this.apolloClient.mutate<DeleteGameModelArtifactFileMutation>({
                mutation: DELETE_GAME_MODEL_ARTIFACT_FILE,
                variables,
            });

            if (result?.data?.deleteGameModelArtifactFile) {
                callback && callback();
                return this.notifications({ success: true, message: "Файл успешно удален" });
            };

            this.notifications({ error: true, message: "Произошла ошибка при удалении файла" });
        } catch {
            this.notifications({ error: true, message: "Произошла ошибка при удалении файла" });
        } finally {
            this.setLoading(false);
        }
    }
}

export const robotsState = new MobileAppRobotsPageState();
