import React from "react";
import { SvgIcon } from "@material-ui/core";

export const OnePointRating = () => (
  <SvgIcon viewBox="0 0 20 20">
    <path
      d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20Z"
      fill="url(#paint0_radial_8338_27976)"/>
    <path
      d="M6.73623 12.5347C7.26794 12.5347 7.69902 11.8829 7.69902 11.0789C7.69902 10.2748 7.26794 9.62305 6.73623 9.62305C6.20448 9.62305 5.77344 10.2748 5.77344 11.0789C5.77344 11.8829 6.20448 12.5347 6.73623 12.5347Z"
      fill="url(#paint1_radial_8338_27976)"/>
    <path
      d="M6.35501 10.5579C6.91779 10.5579 7.38756 11.1347 7.52245 11.9161C7.63409 11.6789 7.69919 11.3905 7.69919 11.0789C7.69919 10.2742 7.26664 9.62305 6.7364 9.62305C6.27592 9.62305 5.8899 10.1114 5.79688 10.7672C5.96432 10.6323 6.15501 10.5579 6.35501 10.5579Z"
      fill="url(#paint2_linear_8338_27976)"/>
    <path
      d="M5.70833 7.53467C4.84785 7.53467 4.10833 8.52535 5.07112 8.67885C6.03391 8.83232 7.78741 9.66025 8.52693 10.2323C8.58275 10.2975 8.80135 10.2789 8.78741 10.1067C8.78275 9.45093 6.75486 7.53467 5.70833 7.53467Z"
      fill="url(#paint3_linear_8338_27976)"/>
    <path
      d="M13.2675 12.5347C13.7992 12.5347 14.2303 11.8829 14.2303 11.0789C14.2303 10.2748 13.7992 9.62305 13.2675 9.62305C12.7358 9.62305 12.3047 10.2748 12.3047 11.0789C12.3047 11.8829 12.7358 12.5347 13.2675 12.5347Z"
      fill="url(#paint4_radial_8338_27976)"/>
    <path
      d="M13.6489 10.5579C13.0861 10.5579 12.6163 11.1347 12.4815 11.9161C12.3698 11.6789 12.3047 11.3905 12.3047 11.0789C12.3047 10.2742 12.7373 9.62305 13.2675 9.62305C13.728 9.62305 14.114 10.1114 14.207 10.7672C14.0396 10.6323 13.8489 10.5579 13.6489 10.5579Z"
      fill="url(#paint5_linear_8338_27976)"/>
    <path
      d="M14.2907 7.53467C15.1511 7.53467 15.8907 8.52535 14.9279 8.67885C13.9651 8.83232 12.2116 9.66025 11.4721 10.2323C11.4162 10.2975 11.1976 10.2789 11.2116 10.1067C11.2162 9.45093 13.2442 7.53467 14.2907 7.53467Z"
      fill="url(#paint6_linear_8338_27976)"/>
    <path
      d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20Z"
      fill="url(#paint7_radial_8338_27976)"/>
    <path
      d="M17.2647 17.9952H2.78096C2.53909 17.9952 2.34375 17.7999 2.34375 17.558V13.8371C2.34375 13.5952 2.53909 13.3999 2.78096 13.3999H17.2647C17.5065 13.3999 17.7019 13.5952 17.7019 13.8371V17.558C17.7019 17.7999 17.5065 17.9952 17.2647 17.9952Z"
      fill="url(#paint8_linear_8338_27976)"/>
    <path
      d="M5.55489 17.0556L5.34557 16.8276C5.12697 17.009 4.81071 17.0928 4.51302 17.0928C3.95023 17.0928 3.57812 16.809 3.57812 16.3579C3.57812 15.9346 3.90374 15.73 4.02 15.637L4.15489 15.5393L4.04326 15.423C3.96884 15.3439 3.78278 15.1486 3.78278 14.8509C3.78278 14.4695 4.10374 14.1904 4.5735 14.1904C5.02931 14.1904 5.34557 14.4462 5.34557 14.8323C5.34557 15.1625 5.10836 15.3625 4.9735 15.4649L4.82466 15.5765L5.31768 16.1114C5.38744 15.93 5.39673 15.7393 5.3921 15.5207H6.14095V15.9067H5.81534C5.80139 16.1207 5.74557 16.3253 5.65255 16.4742L6.1921 17.0556H5.55489ZM5.02931 16.4881L4.43394 15.8462L4.31768 15.9346C4.21071 16.0184 4.11302 16.116 4.11302 16.2881C4.11302 16.5207 4.29442 16.6462 4.57812 16.6462C4.75023 16.6416 4.90836 16.5904 5.02931 16.4881ZM4.27581 14.8509C4.27581 15.0184 4.40139 15.1207 4.46187 15.1858L4.54095 15.2695L4.64326 15.1858C4.70374 15.1393 4.84792 15.0276 4.84792 14.8509C4.84792 14.7021 4.75023 14.5765 4.55955 14.5765C4.38278 14.5765 4.27581 14.6974 4.27581 14.8509Z"
      fill="url(#paint9_radial_8338_27976)"/>
    <path
      d="M8.04045 15.0422L8.01719 15.191H8.76603V15.5631H7.95671L7.83579 16.3631C7.75209 16.8747 7.44511 17.0515 6.95671 17.0515H6.62185L6.61719 16.6236H6.91021C7.19392 16.6236 7.3195 16.5026 7.37069 16.1817L7.473 15.5631H6.93348V15.191H7.52882L7.56603 14.9352C7.6544 14.3957 7.97069 14.2422 8.4544 14.2422H8.76603V14.6747H8.48229C8.1893 14.6794 8.07766 14.805 8.04045 15.0422Z"
      fill="url(#paint10_radial_8338_27976)"/>
    <path
      d="M11.281 15.442L11.1973 15.8559H11.6392L11.5508 16.3303H11.0997L10.9555 17.0512H10.4671L10.6113 16.3303H10.1183L9.97407 17.0512H9.49036L9.63455 16.3303H9.25781L9.35081 15.8559H9.73686L9.81594 15.442H9.39268L9.4857 14.9675H9.9136L10.0578 14.2466H10.5462L10.402 14.9675H10.895L11.0392 14.2466H11.5229L11.3787 14.9675H11.7834L11.6904 15.442H11.281ZM10.7973 15.442H10.3043L10.2159 15.8559H10.709L10.7973 15.442Z"
      fill="url(#paint11_radial_8338_27976)"/>
    <path
      d="M12.7854 14.1445C13.1901 14.1445 13.4506 14.4468 13.4506 14.7864C13.4506 15.1213 13.1994 15.4236 12.7994 15.4236C12.3948 15.4236 12.125 15.1213 12.125 14.7864C12.125 14.4515 12.3948 14.1445 12.7854 14.1445ZM12.3762 17.0515L14.5668 14.1678H15.0692L12.8738 17.0515H12.3762ZM12.7948 15.098C12.9901 15.098 13.1064 14.9445 13.1064 14.7864C13.1064 14.6282 12.9901 14.4748 12.7948 14.4748C12.604 14.4748 12.4738 14.6143 12.4785 14.791C12.4785 14.9492 12.604 15.098 12.7948 15.098ZM14.5948 15.8376C14.9994 15.8376 15.2552 16.1399 15.2552 16.4794C15.2552 16.8143 14.9994 17.112 14.5994 17.112C14.1994 17.112 13.9343 16.819 13.9343 16.4794C13.9343 16.1399 14.1948 15.8376 14.5948 15.8376ZM14.5948 16.7817C14.7854 16.7817 14.9017 16.6329 14.9017 16.4748C14.9017 16.3166 14.7901 16.1631 14.5901 16.1631C14.3948 16.1631 14.2785 16.3166 14.2785 16.4748C14.2785 16.6422 14.404 16.7817 14.5948 16.7817Z"
      fill="url(#paint12_radial_8338_27976)"/>
    <path
      d="M15.7266 16.7533C15.7266 16.5301 15.8987 16.3952 16.1033 16.3952C16.2987 16.3952 16.4707 16.5301 16.4707 16.7533C16.4707 16.9673 16.3033 17.1115 16.1033 17.1115C15.8894 17.1115 15.7266 16.9673 15.7266 16.7533ZM15.9359 16.0417L15.8289 14.251H16.3731L16.2615 16.0417H15.9359Z"
      fill="url(#paint13_radial_8338_27976)"/>
    <defs>
      <radialGradient id="paint0_radial_8338_27976" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(7.74622 6.0191) rotate(-29.698) scale(10.0916 8.2442)">
        <stop stop-color="#FF9114"/>
        <stop offset="1" stop-color="#FF4E0D"/>
      </radialGradient>
      <radialGradient id="paint1_radial_8338_27976" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(6.44425 11.1141) rotate(73.8539) scale(1.41293 0.914139)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint2_linear_8338_27976" x1="6.74798" y1="10.0662" x2="6.74798" y2="11.3696"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <linearGradient id="paint3_linear_8338_27976" x1="6.69953" y1="10.9947" x2="6.69953" y2="7.97355"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#643800"/>
      </linearGradient>
      <radialGradient id="paint4_radial_8338_27976" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(13.5801 11.0965) rotate(106.146) scale(1.41293 0.91414)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint5_linear_8338_27976" x1="13.2559" y1="10.0662" x2="13.2559" y2="11.3696"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <linearGradient id="paint6_linear_8338_27976" x1="13.2995" y1="10.9947" x2="13.2995" y2="7.97355"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#643800"/>
      </linearGradient>
      <radialGradient id="paint7_radial_8338_27976" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(10.0314 18.1059) rotate(0.862144) scale(7.94851 3.95557)">
        <stop stop-color="#7A4400" stop-opacity="0.5"/>
        <stop offset="0.6008" stop-color="#894D00" stop-opacity="0.1981"/>
        <stop offset="0.995" stop-color="#965500" stop-opacity="0"/>
      </radialGradient>
      <linearGradient id="paint8_linear_8338_27976" x1="10.0226" y1="13.4031" x2="10.0226" y2="18.6543"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1"/>
      </linearGradient>
      <radialGradient id="paint9_radial_8338_27976" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(9.94108 15.6915) scale(12.0454)">
        <stop offset="0.00132565" stop-color="white"/>
        <stop offset="1" stop-color="#A8BBBD"/>
      </radialGradient>
      <radialGradient id="paint10_radial_8338_27976" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(9.94293 15.6921) scale(12.0454 12.0454)">
        <stop offset="0.00132565" stop-color="white"/>
        <stop offset="1" stop-color="#A8BBBD"/>
      </radialGradient>
      <radialGradient id="paint11_radial_8338_27976" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(9.94168 15.6918) scale(12.0454 12.0454)">
        <stop offset="0.00132565" stop-color="white"/>
        <stop offset="1" stop-color="#A8BBBD"/>
      </radialGradient>
      <radialGradient id="paint12_radial_8338_27976" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(9.93911 15.6921) scale(12.0454 12.0454)">
        <stop offset="0.00132565" stop-color="white"/>
        <stop offset="1" stop-color="#A8BBBD"/>
      </radialGradient>
      <radialGradient id="paint13_radial_8338_27976" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(9.94067 15.6916) scale(12.0454 12.0454)">
        <stop offset="0.00132565" stop-color="white"/>
        <stop offset="1" stop-color="#A8BBBD"/>
      </radialGradient>
    </defs>

  </SvgIcon>
);

export const TwoPointRating = () => (
  <SvgIcon viewBox="0 0 20 20">
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47716 15.5228 0 10 0C4.47717 0 0 4.47716 0 10C0 15.5228 4.47717 20 10 20Z"
      fill="url(#paint0_radial_8338_27991)"/>
    <path
      d="M9.80771 14.0625C9.80771 14.0625 11.8827 14.0562 12.5952 15.7062C12.639 15.8125 12.6702 15.925 12.6765 16.0375C12.6952 16.2687 12.5327 16.5062 12.139 16.35C11.5077 16.1 10.4202 15.975 9.80771 15.975C9.1952 15.975 8.11396 16.1 7.47645 16.35C7.0827 16.5062 6.92021 16.2687 6.93895 16.0375C6.94521 15.925 6.97645 15.8125 7.02022 15.7062C7.73895 14.0562 9.80771 14.0625 9.80771 14.0625Z"
      fill="url(#paint1_radial_8338_27991)"/>
    <path
      d="M6.73593 12.5375C7.26752 12.5375 7.69845 11.8855 7.69845 11.0813C7.69845 10.277 7.26752 9.625 6.73593 9.625C6.20436 9.625 5.77344 10.277 5.77344 11.0813C5.77344 11.8855 6.20436 12.5375 6.73593 12.5375Z"
      fill="url(#paint2_radial_8338_27991)"/>
    <path
      d="M6.35547 10.5561C6.91798 10.5561 7.38674 11.1311 7.52422 11.9124C7.63674 11.6749 7.69924 11.3874 7.69924 11.0749C7.69924 10.2686 7.26799 9.61865 6.73672 9.61865C6.27423 9.61865 5.88671 10.1061 5.79297 10.7624C5.96172 10.6311 6.14921 10.5561 6.35547 10.5561Z"
      fill="url(#paint3_linear_8338_27991)"/>
    <path
      d="M5.70497 7.53125C4.84246 7.53125 4.10496 8.52501 5.06745 8.67499C6.02997 8.82501 7.77996 9.65625 8.52372 10.2313C8.57996 10.3 8.79872 10.275 8.77996 10.1062C8.77996 9.45626 6.75497 7.53125 5.70497 7.53125Z"
      fill="url(#paint4_linear_8338_27991)"/>
    <path
      d="M9.80548 14.6062C9.80548 14.6062 11.493 14.6 12.6492 15.9C12.6367 15.8312 12.618 15.7687 12.5867 15.7062C11.8742 14.0562 9.79921 14.0625 9.79921 14.0625C9.79921 14.0625 7.72422 14.0562 7.01173 15.7062C6.98672 15.7687 6.96799 15.8312 6.94922 15.9C8.11797 14.6 9.80548 14.6062 9.80548 14.6062Z"
      fill="url(#paint5_linear_8338_27991)"/>
    <path
      d="M13.2633 12.5375C13.7949 12.5375 14.2258 11.8855 14.2258 11.0813C14.2258 10.277 13.7949 9.625 13.2633 9.625C12.7317 9.625 12.3008 10.277 12.3008 11.0813C12.3008 11.8855 12.7317 12.5375 13.2633 12.5375Z"
      fill="url(#paint6_radial_8338_27991)"/>
    <path
      d="M13.6446 10.5561C13.082 10.5561 12.6133 11.1311 12.4758 11.9124C12.3633 11.6749 12.3008 11.3874 12.3008 11.0749C12.3008 10.2686 12.732 9.61865 13.2633 9.61865C13.7258 9.61865 14.1133 10.1061 14.2071 10.7624C14.0383 10.6311 13.8508 10.5561 13.6446 10.5561Z"
      fill="url(#paint7_linear_8338_27991)"/>
    <path
      d="M14.2949 7.53125C15.1574 7.53125 15.8948 8.52501 14.9324 8.67499C13.9699 8.82501 12.2199 9.65625 11.4761 10.2313C11.4199 10.3 11.2011 10.275 11.2199 10.1062C11.2199 9.45626 13.2449 7.53125 14.2949 7.53125Z"
      fill="url(#paint8_linear_8338_27991)"/>
    <defs>
      <radialGradient id="paint0_radial_8338_27991" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(7.74621 6.01909) rotate(-29.698) scale(10.0916 8.2442)">
        <stop stop-color="#FF9214"/>
        <stop offset="1" stop-color="#FF4E0D"/>
      </radialGradient>
      <radialGradient id="paint1_radial_8338_27991" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(9.80883 15.2323) scale(2.18957 1.16639)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <radialGradient id="paint2_radial_8338_27991" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(6.44137 11.1143) rotate(73.8539) scale(1.41293 0.914143)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint3_linear_8338_27991" x1="6.74571" y1="10.0663" x2="6.74571" y2="11.3697"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <linearGradient id="paint4_linear_8338_27991" x1="6.69689" y1="10.995" x2="6.69689" y2="7.97376"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#643800"/>
      </linearGradient>
      <linearGradient id="paint5_linear_8338_27991" x1="9.8066" y1="13.3521" x2="9.8066" y2="14.9264"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <radialGradient id="paint6_radial_8338_27991" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(13.5855 11.0908) rotate(106.146) scale(1.41293 0.91414)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint7_linear_8338_27991" x1="13.2543" y1="10.0663" x2="13.2543" y2="11.3697"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <linearGradient id="paint8_linear_8338_27991" x1="13.303" y1="10.995" x2="13.303" y2="7.97376"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#643800"/>
      </linearGradient>
    </defs>
  </SvgIcon>
)

export const ThreePointRating = () => (
  <SvgIcon viewBox="0 0 20 20">
    <path
      d="M4.96093 19.8391C5.37516 19.8391 5.71096 19.5118 5.71096 19.108C5.71096 18.7042 5.37516 18.377 4.96093 18.377C4.54674 18.377 4.21094 18.7042 4.21094 19.108C4.21094 19.5118 4.54674 19.8391 4.96093 19.8391Z"
      fill="white"/>
    <path
      d="M4.96093 19.8391C5.37516 19.8391 5.71096 19.5118 5.71096 19.108C5.71096 18.7042 5.37516 18.377 4.96093 18.377C4.54674 18.377 4.21094 18.7042 4.21094 19.108C4.21094 19.5118 4.54674 19.8391 4.96093 19.8391Z"
      fill="url(#paint0_radial_8338_28001)"/>
    <path
      d="M10 19.9951C15.5228 19.9951 20 15.519 20 9.99753C20 4.47606 15.5228 0 10 0C4.47717 0 0 4.47606 0 9.99753C0 15.519 4.47717 19.9951 10 19.9951Z"
      fill="url(#paint1_radial_8338_28001)"/>
    <path
      d="M13.6141 18.4766C13.4266 18.5453 13.2141 17.558 13.4704 17.3643C13.1079 17.0957 11.6641 16.2334 11.8141 16.1771C11.9454 15.8772 13.1641 15.6085 13.5016 15.6023L11.3766 10.9722C11.3766 10.9722 12.0516 14.9025 12.1016 15.2586C12.1454 15.5648 11.7516 15.5773 11.5954 16.0084C11.5391 16.1709 11.4329 16.3084 11.3079 16.4208C11.1516 16.5583 10.9766 16.7895 10.9891 17.1269C10.9954 17.2831 10.9641 17.4393 10.8954 17.583C10.7579 17.8455 10.5891 18.3204 10.7829 18.8202C10.8829 19.0764 10.9079 19.3514 10.8453 19.62C10.8204 19.7262 10.8141 19.8387 10.8329 19.9637C11.9078 19.8762 12.9329 19.62 13.8829 19.2201C13.7766 18.9014 13.6641 18.5266 13.6141 18.4766Z"
      fill="url(#paint2_radial_8338_28001)"/>
    <path
      d="M8.97718 16.5333C8.97718 16.5333 8.91467 15.8647 8.38967 15.5336C8.21468 15.4211 8.12094 15.2086 8.14594 15.0024L8.6272 10.9722L6.57094 15.9959C7.0022 16.0084 7.0147 18.0767 6.45843 18.1329C6.23343 18.1516 6.22093 18.5641 6.03968 18.4578C6.02717 18.5141 6.20843 18.9327 6.35845 19.3139C7.17095 19.6325 8.03969 19.845 8.93968 19.9387C9.29592 19.6138 9.48968 19.1452 9.30842 18.6328C9.31469 18.6328 9.47718 16.9769 8.97718 16.5333Z"
      fill="url(#paint3_radial_8338_28001)"/>
    <path
      d="M4.79155 14.99C4.88532 15.0087 4.97907 14.9775 5.04781 14.915C6.2728 13.809 8.16657 13.2154 9.59155 13.2154H9.83532H10.079C11.5041 13.2154 13.3978 13.809 14.6228 14.915C14.6916 14.9775 14.7853 15.0087 14.8791 14.99C14.9791 14.9712 15.029 14.865 14.9791 14.7838C13.9291 13.0405 11.9978 11.9595 9.89156 11.9595H9.82905H9.76654C7.66031 11.9595 5.72906 13.0405 4.67906 14.7838C4.63533 14.8713 4.69157 14.9775 4.79155 14.99Z"
      fill="#643800"/>
    <path
      d="M4.79278 14.9902C4.88653 15.0089 4.98027 14.9777 5.04905 14.9152C6.27403 13.8092 8.16778 13.2156 9.59279 13.2156H9.83652H10.0803C11.5053 13.2156 13.399 13.8092 14.624 14.9152C14.6928 14.9777 14.7865 15.0089 14.8803 14.9902C14.9803 14.9714 15.0303 14.8652 14.9803 14.784C14.9678 14.7652 14.9553 14.7527 14.949 14.734C14.849 14.7902 14.7428 14.8464 14.6365 14.7215C13.474 13.4593 11.749 12.7095 9.89903 12.7095H9.83652H9.77404C7.91154 12.7095 6.17402 13.4718 5.01154 14.7465C4.90529 14.8589 4.80528 14.809 4.71154 14.7465C4.70527 14.759 4.69277 14.7715 4.68653 14.784C4.63653 14.8714 4.69277 14.9777 4.79278 14.9902Z"
      fill="url(#paint4_linear_8338_28001)"/>
    <path
      d="M11.6197 6.62944C11.251 6.38577 11.2822 5.82963 11.7385 5.14232C12.126 4.55495 13.301 3.48648 15.026 3.349C15.3448 3.324 15.2947 3.6552 15.051 3.72392C14.226 3.96136 12.6635 5.02983 12.0322 6.24828C11.7822 6.74192 11.6197 6.62944 11.6197 6.62944Z"
      fill="url(#paint5_linear_8338_28001)"/>
    <path
      d="M6.2867 9.44747C6.2867 9.44747 4.7117 9.29126 4.27421 8.0978C4.2492 8.02285 4.23047 7.94162 4.23047 7.86663C4.23047 7.70415 4.38045 7.5417 4.66172 7.69168C6.39294 8.64144 7.39297 8.19779 8.13046 7.88536C8.4242 7.76041 8.6742 8.06034 8.58672 8.31651C8.56172 8.3915 8.54295 8.46649 8.50545 8.53522C7.8617 9.61619 6.2867 9.44747 6.2867 9.44747Z"
      fill="url(#paint6_radial_8338_28001)"/>
    <path
      d="M8.37979 6.62944C8.74854 6.38577 8.7173 5.82963 8.26104 5.14232C7.87356 4.55495 6.69854 3.48648 4.97356 3.349C4.65478 3.324 4.70479 3.6552 4.94855 3.72392C5.77356 3.96136 7.33606 5.02983 7.9673 6.24828C8.2173 6.74192 8.37979 6.62944 8.37979 6.62944Z"
      fill="url(#paint7_linear_8338_28001)"/>
    <path
      d="M13.7122 9.44747C13.7122 9.44747 15.2873 9.29126 15.7248 8.0978C15.7498 8.02285 15.7685 7.94162 15.7685 7.86663C15.7685 7.70415 15.6185 7.5417 15.3372 7.69168C13.606 8.64144 12.606 8.19779 11.8685 7.88536C11.5748 7.76041 11.3248 8.06034 11.4122 8.31651C11.4372 8.3915 11.456 8.46649 11.4935 8.53522C12.1372 9.61619 13.7122 9.44747 13.7122 9.44747Z"
      fill="url(#paint8_radial_8338_28001)"/>
    <path
      d="M8.45623 10.9033L5.71875 16.1208L7.24374 16.227L8.63749 10.9658C8.65626 10.8658 8.50624 10.8096 8.45623 10.9033Z"
      fill="url(#paint9_linear_8338_28001)"/>
    <path
      d="M8.45623 10.9033L5.71875 16.1208L7.24374 16.227L8.63749 10.9658C8.65626 10.8658 8.50624 10.8096 8.45623 10.9033Z"
      fill="url(#paint10_linear_8338_28001)"/>
    <path
      d="M5.97543 16.346C6.02544 16.8896 5.61919 17.3707 5.05669 17.4207C4.49421 17.4707 4.00669 17.0708 3.95044 16.5272C3.8942 15.9836 4.30669 15.5025 4.86919 15.4525C5.4317 15.4025 5.92546 15.8024 5.97543 16.346Z"
      fill="url(#paint11_radial_8338_28001)"/>
    <path
      d="M4.78301 16.7521C4.46427 16.7208 4.09553 16.1585 4.39553 15.6211C4.10176 15.8148 3.92054 16.1585 3.95801 16.5271C3.98928 16.827 4.15177 17.0833 4.38303 17.2394L4.78301 16.7521Z"
      fill="url(#paint12_radial_8338_28001)"/>
    <path
      d="M8.26151 16.2896C8.30524 16.7521 7.95527 17.1582 7.48024 17.2019C7.00525 17.2457 6.58649 16.9083 6.54275 16.4459C6.49902 15.9835 6.84899 15.5773 7.32402 15.5336C7.79901 15.4898 8.21777 15.8273 8.26151 16.2896Z"
      fill="url(#paint13_radial_8338_28001)"/>
    <path
      d="M7.24009 16.2337C7.29009 16.7773 6.88384 17.2584 6.32134 17.3084C5.75883 17.3584 5.27134 16.9585 5.2151 16.4149C5.16509 15.8713 5.57134 15.3901 6.13385 15.3402C6.69635 15.2902 7.19008 15.6901 7.24009 16.2337Z"
      fill="url(#paint14_radial_8338_28001)"/>
    <path
      d="M6.55078 18.989C7.2722 18.9877 7.85597 18.4187 7.85469 17.7182C7.85341 17.0177 7.26751 16.4508 6.54609 16.4522C5.82468 16.4535 5.24091 17.0225 5.24219 17.723C5.24347 18.4236 5.82937 18.9904 6.55078 18.989Z"
      fill="white"/>
    <path
      d="M9.03841 17.0207C8.9634 16.6583 8.63216 16.3896 8.25715 16.3771C8.10089 16.3709 7.95717 16.4084 7.83216 16.4709C7.57589 16.6021 7.28216 16.6271 7.01342 16.5271C6.81966 16.4584 6.60717 16.4271 6.38841 16.4584C5.72592 16.5396 5.22589 17.1145 5.2509 17.7643C5.2759 18.5016 5.93215 19.0515 6.67591 18.9828C7.28842 18.9265 7.76342 18.4704 7.84466 17.9018C7.98214 17.9705 8.1384 18.0017 8.30092 17.9892C8.78841 17.9455 9.14466 17.5019 9.03841 17.0207Z"
      fill="white"/>
    <path
      d="M6.1632 17.7892C6.22571 18.4265 5.73821 18.9889 5.08819 19.0514C4.43195 19.1076 3.8507 18.6389 3.79446 18.0016C3.73196 17.3643 4.21945 16.8019 4.86947 16.7394C5.51945 16.6832 6.10069 17.1518 6.1632 17.7892Z"
      fill="url(#paint15_radial_8338_28001)"/>
    <path
      d="M8.79575 18.1268C8.84576 18.6704 8.43951 19.1515 7.877 19.2015C7.32077 19.2515 6.827 18.8516 6.77076 18.308C6.71449 17.7644 7.12701 17.2832 7.68951 17.2332C8.24575 17.1832 8.74575 17.5831 8.79575 18.1268Z"
      fill="url(#paint16_radial_8338_28001)"/>
    <path
      d="M8.79575 18.1268C8.84576 18.6704 8.43951 19.1515 7.877 19.2015C7.32077 19.2515 6.827 18.8516 6.77076 18.308C6.71449 17.7644 7.12701 17.2832 7.68951 17.2332C8.24575 17.1832 8.74575 17.5831 8.79575 18.1268Z"
      fill="url(#paint17_radial_8338_28001)"/>
    <path
      d="M9.05069 17.108C9.09443 17.5517 8.75692 17.9453 8.30069 17.9891C7.84443 18.0328 7.43818 17.7016 7.39445 17.258C7.35067 16.8144 7.68818 16.4207 8.14444 16.377C8.60693 16.3395 9.01318 16.6644 9.05069 17.108Z"
      fill="url(#paint18_radial_8338_28001)"/>
    <path
      d="M7.27491 18.7579C7.33115 19.3828 6.86239 19.9327 6.21865 19.9889C5.5749 20.0451 5.00616 19.589 4.94989 18.9642C4.89365 18.3393 5.3624 17.7894 6.00615 17.7332C6.6499 17.677 7.21241 18.1331 7.27491 18.7579Z"
      fill="url(#paint19_radial_8338_28001)"/>
    <path
      d="M8.45623 10.9033L5.71875 16.1208L7.24374 16.227L8.63749 10.9658C8.65626 10.8658 8.50624 10.8096 8.45623 10.9033Z"
      fill="url(#paint20_linear_8338_28001)"/>
    <path opacity="0.5"
          d="M6.16251 19.6453C5.70626 19.689 5.28127 19.5078 5 19.2078C5.16252 19.7077 5.66252 20.0451 6.21875 19.9951C6.80626 19.9452 7.25002 19.4765 7.27502 18.9204C7.06249 19.3141 6.65628 19.5953 6.16251 19.6453Z"
          fill="url(#paint21_linear_8338_28001)"/>
    <path
      d="M8.62188 18.764C8.7469 18.5828 8.81564 18.3578 8.79064 18.1204C8.75313 17.7517 8.51563 17.4456 8.18439 17.3081L7.75314 17.3643L7.4594 17.2769C7.4344 17.2831 7.41563 17.2894 7.39062 17.3019C7.40313 17.4706 7.47814 18.0642 8.00937 18.1704C8.62815 18.2891 8.62188 18.764 8.62188 18.764Z"
      fill="url(#paint22_radial_8338_28001)"/>
    <path
      d="M9.05069 17.1085C9.09443 17.5521 8.75692 17.9458 8.30069 17.9895C7.84443 18.0333 7.43818 17.7021 7.39445 17.2585C7.35067 16.8148 7.68818 16.4212 8.14444 16.3775C8.60693 16.34 9.01318 16.6649 9.05069 17.1085Z"
      fill="url(#paint23_radial_8338_28001)"/>
    <path
      d="M15.0391 19.8391C15.4533 19.8391 15.7891 19.5118 15.7891 19.108C15.7891 18.7042 15.4533 18.377 15.0391 18.377C14.6248 18.377 14.2891 18.7042 14.2891 19.108C14.2891 19.5118 14.6248 19.8391 15.0391 19.8391Z"
      fill="white"/>
    <path
      d="M15.0391 19.8391C15.4533 19.8391 15.7891 19.5118 15.7891 19.108C15.7891 18.7042 15.4533 18.377 15.0391 18.377C14.6248 18.377 14.2891 18.7042 14.2891 19.108C14.2891 19.5118 14.6248 19.8391 15.0391 19.8391Z"
      fill="url(#paint24_radial_8338_28001)"/>
    <path
      d="M12.1929 18.214C12.1304 18.8513 12.6179 19.4137 13.2679 19.4762C13.9179 19.5387 14.5054 19.0638 14.5617 18.4265C14.6179 17.7891 14.1366 17.2267 13.4867 17.1642C12.8366 17.108 12.2554 17.5767 12.1929 18.214Z"
      fill="url(#paint25_radial_8338_28001)"/>
    <path
      d="M11.5422 10.9033L14.2797 16.1208L12.7547 16.227L11.361 10.9658C11.3422 10.8658 11.4922 10.8096 11.5422 10.9033Z"
      fill="url(#paint26_linear_8338_28001)"/>
    <path
      d="M11.5422 10.9033L14.2797 16.1208L12.7547 16.227L11.361 10.9658C11.3422 10.8658 11.4922 10.8096 11.5422 10.9033Z"
      fill="url(#paint27_linear_8338_28001)"/>
    <path
      d="M14.0276 16.346C13.9776 16.8896 14.3838 17.3707 14.9463 17.4207C15.5089 17.4707 15.9963 17.0708 16.0526 16.5272C16.1026 15.9836 15.6963 15.5025 15.1338 15.4525C14.5776 15.4025 14.0776 15.8024 14.0276 16.346Z"
      fill="url(#paint28_radial_8338_28001)"/>
    <path
      d="M15.2188 16.7521C15.5375 16.7208 15.9062 16.1585 15.6062 15.6211C15.9 15.8148 16.0812 16.1585 16.0437 16.5271C16.0125 16.827 15.85 17.0833 15.6187 17.2394L15.2188 16.7521Z"
      fill="url(#paint29_radial_8338_28001)"/>
    <path
      d="M11.7381 16.2896C11.6943 16.7521 12.0443 17.1582 12.5193 17.2019C12.9943 17.2457 13.413 16.9083 13.4568 16.4459C13.5006 15.9835 13.1505 15.5773 12.6756 15.5336C12.2006 15.4898 11.7818 15.8273 11.7381 16.2896Z"
      fill="url(#paint30_radial_8338_28001)"/>
    <path
      d="M12.6292 16.3832C12.6792 16.9269 13.1729 17.3268 13.7292 17.2768C14.2854 17.2268 14.6979 16.7457 14.6479 16.2083C14.5979 15.6647 14.1042 15.2648 13.5479 15.3148C12.9854 15.3648 12.5792 15.8396 12.6292 16.3832Z"
      fill="url(#paint31_radial_8338_28001)"/>
    <path
      d="M12.1494 17.6016C12.0806 18.3015 12.7556 18.4764 13.4744 18.5389C14.1931 18.6014 14.6869 18.5327 14.7556 17.8328C14.8244 17.133 14.2931 16.5144 13.5744 16.4519C12.8494 16.3894 12.2119 16.9018 12.1494 17.6016Z"
      fill="white"/>
    <path
      d="M11.4313 16.3021C11.6562 16.0085 12.075 15.9023 12.4187 16.046C12.5625 16.1084 12.6812 16.1959 12.7625 16.3084C12.9312 16.5333 13.1937 16.6771 13.4812 16.6958C13.6875 16.7146 13.8937 16.777 14.0813 16.8895C14.6438 17.2394 14.8438 17.9642 14.5437 18.5391C14.2 19.1952 13.7375 18.8828 13.0937 18.5141C12.5625 18.2142 11.9625 18.1455 12.1312 17.5956C11.975 17.6018 11.8187 17.5643 11.675 17.4831C11.25 17.2457 11.125 16.6958 11.4313 16.3021Z"
      fill="white"/>
    <path
      d="M13.8374 17.7892C13.7749 18.4265 14.2624 18.9889 14.9124 19.0514C15.5687 19.1076 16.1499 18.6389 16.2062 18.0016C16.2687 17.3643 15.7812 16.8019 15.1312 16.7394C14.4812 16.6832 13.8999 17.1518 13.8374 17.7892Z"
      fill="url(#paint32_radial_8338_28001)"/>
    <path
      d="M11.2073 18.1268C11.1573 18.6704 11.5635 19.1515 12.126 19.2015C12.6823 19.2515 13.176 18.8516 13.2323 18.308C13.2823 17.7644 12.8761 17.2832 12.3136 17.2332C11.7573 17.1832 11.2573 17.5831 11.2073 18.1268Z"
      fill="url(#paint33_radial_8338_28001)"/>
    <path
      d="M11.2073 18.1268C11.1573 18.6704 11.5635 19.1515 12.126 19.2015C12.6823 19.2515 13.176 18.8516 13.2323 18.308C13.2823 17.7644 12.8761 17.2832 12.3136 17.2332C11.7573 17.1832 11.2573 17.5831 11.2073 18.1268Z"
      fill="url(#paint34_radial_8338_28001)"/>
    <path
      d="M13.0627 18.7513C13.0502 19.3762 13.569 19.8948 14.2127 19.901C14.8565 19.9135 15.3877 19.4137 15.4002 18.7826C15.4127 18.1577 14.894 17.6391 14.2502 17.6329C13.6002 17.6266 13.069 18.1265 13.0627 18.7513Z"
      fill="url(#paint35_radial_8338_28001)"/>
    <path
      d="M11.5422 10.9033L14.2797 16.1208L12.7547 16.227L11.361 10.9658C11.3422 10.8658 11.4922 10.8096 11.5422 10.9033Z"
      fill="url(#paint36_linear_8338_28001)"/>
    <path opacity="0.5"
          d="M14.7141 19.395C15.0828 19.1388 15.2891 18.7327 15.2953 18.3203C15.5078 18.8014 15.3578 19.3763 14.9078 19.6887C14.4266 20.0199 13.7766 19.9511 13.3828 19.5512C13.8141 19.7137 14.3141 19.67 14.7141 19.395Z"
          fill="url(#paint37_linear_8338_28001)"/>
    <path
      d="M12.093 17.6017C12.5521 17.6017 12.9242 17.238 12.9242 16.7894C12.9242 16.3407 12.5521 15.9771 12.093 15.9771C11.6339 15.9771 11.2617 16.3407 11.2617 16.7894C11.2617 17.238 11.6339 17.6017 12.093 17.6017Z"
      fill="url(#paint38_radial_8338_28001)"/>
    <path
      d="M15.0391 19.8391C15.4533 19.8391 15.7891 19.5118 15.7891 19.108C15.7891 18.7042 15.4533 18.377 15.0391 18.377C14.6248 18.377 14.2891 18.7042 14.2891 19.108C14.2891 19.5118 14.6248 19.8391 15.0391 19.8391Z"
      fill="white"/>
    <path
      d="M15.0391 19.8391C15.4533 19.8391 15.7891 19.5118 15.7891 19.108C15.7891 18.7042 15.4533 18.377 15.0391 18.377C14.6248 18.377 14.2891 18.7042 14.2891 19.108C14.2891 19.5118 14.6248 19.8391 15.0391 19.8391Z"
      fill="url(#paint39_radial_8338_28001)"/>
    <path
      d="M12.1929 18.214C12.1304 18.8513 12.6179 19.4137 13.2679 19.4762C13.9179 19.5387 14.5054 19.0638 14.5617 18.4265C14.6179 17.7891 14.1366 17.2267 13.4867 17.1642C12.8366 17.108 12.2554 17.5767 12.1929 18.214Z"
      fill="url(#paint40_radial_8338_28001)"/>
    <path
      d="M11.5422 10.9033L14.2797 16.1208L12.7547 16.227L11.361 10.9658C11.3422 10.8658 11.4922 10.8096 11.5422 10.9033Z"
      fill="url(#paint41_linear_8338_28001)"/>
    <path
      d="M11.5422 10.9033L14.2797 16.1208L12.7547 16.227L11.361 10.9658C11.3422 10.8658 11.4922 10.8096 11.5422 10.9033Z"
      fill="url(#paint42_linear_8338_28001)"/>
    <path
      d="M14.0276 16.346C13.9776 16.8896 14.3838 17.3707 14.9463 17.4207C15.5089 17.4707 15.9963 17.0708 16.0526 16.5272C16.1026 15.9836 15.6963 15.5025 15.1338 15.4525C14.5776 15.4025 14.0776 15.8024 14.0276 16.346Z"
      fill="url(#paint43_radial_8338_28001)"/>
    <path
      d="M15.2188 16.7521C15.5375 16.7208 15.9062 16.1585 15.6062 15.6211C15.9 15.8148 16.0812 16.1585 16.0437 16.5271C16.0125 16.827 15.85 17.0833 15.6187 17.2394L15.2188 16.7521Z"
      fill="url(#paint44_radial_8338_28001)"/>
    <path
      d="M11.7381 16.2896C11.6943 16.7521 12.0443 17.1582 12.5193 17.2019C12.9943 17.2457 13.413 16.9083 13.4568 16.4459C13.5006 15.9835 13.1505 15.5773 12.6756 15.5336C12.2006 15.4898 11.7818 15.8273 11.7381 16.2896Z"
      fill="url(#paint45_radial_8338_28001)"/>
    <path
      d="M12.6292 16.3832C12.6792 16.9269 13.1729 17.3268 13.7292 17.2768C14.2854 17.2268 14.6979 16.7457 14.6479 16.2083C14.5979 15.6647 14.1042 15.2648 13.5479 15.3148C12.9854 15.3648 12.5792 15.8396 12.6292 16.3832Z"
      fill="url(#paint46_radial_8338_28001)"/>
    <path
      d="M12.1494 17.6016C12.0806 18.3015 12.7556 18.4764 13.4744 18.5389C14.1931 18.6014 14.6869 18.5327 14.7556 17.8328C14.8244 17.133 14.2931 16.5144 13.5744 16.4519C12.8494 16.3894 12.2119 16.9018 12.1494 17.6016Z"
      fill="white"/>
    <path
      d="M11.4313 16.3021C11.6562 16.0085 12.075 15.9023 12.4187 16.046C12.5625 16.1084 12.6812 16.1959 12.7625 16.3084C12.9312 16.5333 13.1937 16.6771 13.4812 16.6958C13.6875 16.7146 13.8937 16.777 14.0813 16.8895C14.6438 17.2394 14.8438 17.9642 14.5437 18.5391C14.2 19.1952 13.7375 18.8828 13.0937 18.5141C12.5625 18.2142 11.9625 18.1455 12.1312 17.5956C11.975 17.6018 11.8187 17.5643 11.675 17.4831C11.25 17.2457 11.125 16.6958 11.4313 16.3021Z"
      fill="white"/>
    <path
      d="M13.8374 17.7892C13.7749 18.4265 14.2624 18.9889 14.9124 19.0514C15.5687 19.1076 16.1499 18.6389 16.2062 18.0016C16.2687 17.3643 15.7812 16.8019 15.1312 16.7394C14.4812 16.6832 13.8999 17.1518 13.8374 17.7892Z"
      fill="url(#paint47_radial_8338_28001)"/>
    <path
      d="M11.2073 18.1268C11.1573 18.6704 11.5635 19.1515 12.126 19.2015C12.6823 19.2515 13.176 18.8516 13.2323 18.308C13.2823 17.7644 12.8761 17.2832 12.3136 17.2332C11.7573 17.1832 11.2573 17.5831 11.2073 18.1268Z"
      fill="url(#paint48_radial_8338_28001)"/>
    <path
      d="M11.2073 18.1268C11.1573 18.6704 11.5635 19.1515 12.126 19.2015C12.6823 19.2515 13.176 18.8516 13.2323 18.308C13.2823 17.7644 12.8761 17.2832 12.3136 17.2332C11.7573 17.1832 11.2573 17.5831 11.2073 18.1268Z"
      fill="url(#paint49_radial_8338_28001)"/>
    <path
      d="M13.0627 18.7513C13.0502 19.3762 13.569 19.8948 14.2127 19.901C14.8565 19.9135 15.3877 19.4137 15.4002 18.7826C15.4127 18.1577 14.894 17.6391 14.2502 17.6329C13.6002 17.6266 13.069 18.1265 13.0627 18.7513Z"
      fill="url(#paint50_radial_8338_28001)"/>
    <path
      d="M11.5422 10.9033L14.2797 16.1208L12.7547 16.227L11.361 10.9658C11.3422 10.8658 11.4922 10.8096 11.5422 10.9033Z"
      fill="url(#paint51_linear_8338_28001)"/>
    <path opacity="0.5"
          d="M14.7141 19.395C15.0828 19.1388 15.2891 18.7327 15.2953 18.3203C15.5078 18.8014 15.3578 19.3763 14.9078 19.6887C14.4266 20.0199 13.7766 19.9511 13.3828 19.5512C13.8141 19.7137 14.3141 19.67 14.7141 19.395Z"
          fill="url(#paint52_linear_8338_28001)"/>
    <path
      d="M12.093 17.6017C12.5521 17.6017 12.9242 17.238 12.9242 16.7894C12.9242 16.3407 12.5521 15.9771 12.093 15.9771C11.6339 15.9771 11.2617 16.3407 11.2617 16.7894C11.2617 17.238 11.6339 17.6017 12.093 17.6017Z"
      fill="url(#paint53_radial_8338_28001)"/>
    <g opacity="0.5">
      <path opacity="0.5"
            d="M8 13.4277C8.11875 13.3965 8.23123 13.3715 8.34374 13.3527L8.49373 12.103C8.43749 12.1155 8.38748 12.128 8.33124 12.1405L8 13.4277Z"
            fill="#3C2200"/>
      <path opacity="0.5"
            d="M11.582 12.1968C11.6508 12.5904 11.7258 13.0278 11.7945 13.4527C11.8695 13.4715 11.9508 13.4965 12.0258 13.5152L11.6945 12.228C11.657 12.2155 11.6195 12.2093 11.582 12.1968Z"
            fill="#3C2200"/>
    </g>
    <path
      d="M15.3412 7.69189C13.6099 8.64164 12.6099 8.19799 11.8724 7.88556C11.5786 7.76061 11.3286 8.06054 11.4162 8.31671C11.4162 8.31671 11.5286 8.01055 12.3724 8.36671C14.0724 9.08529 15.7474 7.72935 15.6912 7.67939C15.6162 7.6169 15.4974 7.6044 15.3412 7.69189Z"
      fill="url(#paint54_linear_8338_28001)"/>
    <path
      d="M4.65608 7.69189C6.38733 8.64164 7.38732 8.19799 8.12482 7.88556C8.41859 7.76061 8.66858 8.06054 8.58107 8.31671C8.58107 8.31671 8.46859 8.01055 7.62482 8.36671C5.92484 9.08529 4.24982 7.72935 4.30607 7.67939C4.38107 7.6169 4.49982 7.6044 4.65608 7.69189Z"
      fill="url(#paint55_linear_8338_28001)"/>
    <defs>
      <radialGradient id="paint0_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(5.64209 18.1995) scale(2.35943 2.29326)">
        <stop stop-color="#80DAFE"/>
        <stop offset="0.3454" stop-color="#83DBFE" stop-opacity="0.6546"/>
        <stop offset="0.5956" stop-color="#8CDEFE" stop-opacity="0.4044"/>
        <stop offset="0.815" stop-color="#9CE3FF" stop-opacity="0.185"/>
        <stop offset="1" stop-color="#B0E9FF" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint1_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.03246 5.94586) scale(12.0637 12.0607)">
        <stop stop-color="#FCCE38"/>
        <stop offset="1" stop-color="#FF8000"/>
      </radialGradient>
      <radialGradient id="paint2_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(11.8489 25.1187) scale(11.4759 11.473)">
        <stop stop-color="#F05C00"/>
        <stop offset="1" stop-color="#FF8000"/>
      </radialGradient>
      <radialGradient id="paint3_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(11.8494 25.1187) scale(11.4758 11.473)">
        <stop stop-color="#F05C00"/>
        <stop offset="1" stop-color="#FF8000"/>
      </radialGradient>
      <linearGradient id="paint4_linear_8338_28001" x1="9.8378" y1="12.0771" x2="9.8378" y2="13.7949"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <linearGradient id="paint5_linear_8338_28001" x1="13.5191" y1="5.11427" x2="12.5447" y2="4.46849"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#7A4400"/>
      </linearGradient>
      <radialGradient id="paint6_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(6.37276 8.60552) rotate(-174.133) scale(1.70292 0.821237)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint7_linear_8338_28001" x1="6.49422" y1="5.12778" x2="7.46867" y2="4.48197"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#7A4400"/>
      </linearGradient>
      <radialGradient id="paint8_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(13.6265 8.60594) rotate(-5.86623) scale(1.70292 0.821237)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint9_linear_8338_28001" x1="7.22032" y1="13.5122" x2="7.87081" y2="13.6709"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="white"/>
        <stop offset="0.2441" stop-color="#FBFEFF"/>
        <stop offset="0.4613" stop-color="#F1FBFF"/>
        <stop offset="0.6683" stop-color="#DEF6FF"/>
        <stop offset="0.8677" stop-color="#C5EFFF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </linearGradient>
      <linearGradient id="paint10_linear_8338_28001" x1="7.70827" y1="13.7612" x2="7.11514" y2="13.4802"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#B0E9FF" stop-opacity="0"/>
        <stop offset="0.8857" stop-color="#B0E9FF" stop-opacity="0.8869"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </linearGradient>
      <radialGradient id="paint11_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(4.71534 16.1961) rotate(-5.1254) scale(1.01464 0.986646)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint12_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(3.19699 17.486) rotate(-7.90602) scale(1.54586 1.99576)">
        <stop stop-color="#80DAFE"/>
        <stop offset="0.3454" stop-color="#83DBFE" stop-opacity="0.6546"/>
        <stop offset="0.5956" stop-color="#8CDEFE" stop-opacity="0.4044"/>
        <stop offset="0.815" stop-color="#9CE3FF" stop-opacity="0.185"/>
        <stop offset="1" stop-color="#B0E9FF" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint13_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(7.1741 15.7309) rotate(-5.12543) scale(1.24326 1.20897)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint14_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(6.44117 16.515) scale(1.65935 1.61282)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint15_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(5.37078 17.0557) scale(2.02103 1.96418)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint16_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(7.76839 17.3709) rotate(153.281) scale(2.07908 1.52577)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint17_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(7.11145 18.2746) rotate(153.281) scale(2.08011 1.52653)">
        <stop stop-color="#B0E9FF" stop-opacity="0"/>
        <stop offset="0.8857" stop-color="#B0E9FF" stop-opacity="0.8869"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint18_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(5.98613 16.5805) scale(3.51684 3.41825)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint19_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(6.12549 18.4048) rotate(89.993) scale(1.13445 1.70466)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <linearGradient id="paint20_linear_8338_28001" x1="7.38293" y1="14.3211" x2="8.78324" y2="11.4518"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0"/>
        <stop offset="0.8857" stop-color="white" stop-opacity="0.8869"/>
        <stop offset="0.9987" stop-color="white"/>
      </linearGradient>
      <linearGradient id="paint21_linear_8338_28001" x1="5.08869" y1="20.4711" x2="6.82181" y2="18.428"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#80DAFE"/>
        <stop offset="0.3454" stop-color="#83DBFE" stop-opacity="0.6546"/>
        <stop offset="0.5956" stop-color="#8CDEFE" stop-opacity="0.4044"/>
        <stop offset="0.815" stop-color="#9CE3FF" stop-opacity="0.185"/>
        <stop offset="1" stop-color="#B0E9FF" stop-opacity="0"/>
      </linearGradient>
      <radialGradient id="paint22_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.53617 17.5309) scale(1.20336 1.16961)">
        <stop stop-color="#80DAFE"/>
        <stop offset="0.3454" stop-color="#83DBFE" stop-opacity="0.6546"/>
        <stop offset="0.5956" stop-color="#8CDEFE" stop-opacity="0.4044"/>
        <stop offset="0.815" stop-color="#9CE3FF" stop-opacity="0.185"/>
        <stop offset="1" stop-color="#B0E9FF" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint23_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(5.98613 16.5809) scale(3.51684 3.41822)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint24_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(14.3579 18.1995) rotate(180) scale(2.35942 2.29326)">
        <stop stop-color="#80DAFE"/>
        <stop offset="0.3454" stop-color="#83DBFE" stop-opacity="0.6546"/>
        <stop offset="0.5956" stop-color="#8CDEFE" stop-opacity="0.4044"/>
        <stop offset="0.815" stop-color="#9CE3FF" stop-opacity="0.185"/>
        <stop offset="1" stop-color="#B0E9FF" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint25_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(12.3937 20.1897) scale(1.2608 1.22545)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <linearGradient id="paint26_linear_8338_28001" x1="12.7781" y1="13.5122" x2="12.1276" y2="13.6709"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="white"/>
        <stop offset="0.2441" stop-color="#FBFEFF"/>
        <stop offset="0.4613" stop-color="#F1FBFF"/>
        <stop offset="0.6683" stop-color="#DEF6FF"/>
        <stop offset="0.8677" stop-color="#C5EFFF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </linearGradient>
      <linearGradient id="paint27_linear_8338_28001" x1="12.2902" y1="13.7612" x2="12.8834" y2="13.4802"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#B0E9FF" stop-opacity="0"/>
        <stop offset="0.8857" stop-color="#B0E9FF" stop-opacity="0.8869"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </linearGradient>
      <radialGradient id="paint28_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(15.2738 16.214) rotate(-174.874) scale(1.01464 0.986646)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint29_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(16.832 17.4831) rotate(-172.094) scale(1.54586 1.99576)">
        <stop stop-color="#80DAFE"/>
        <stop offset="0.3454" stop-color="#83DBFE" stop-opacity="0.6546"/>
        <stop offset="0.5956" stop-color="#8CDEFE" stop-opacity="0.4044"/>
        <stop offset="0.815" stop-color="#9CE3FF" stop-opacity="0.185"/>
        <stop offset="1" stop-color="#B0E9FF" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint30_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(12.8115 15.7488) rotate(-174.874) scale(1.24325 1.20897)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint31_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(13.4398 16.5442) rotate(169.845) scale(1.65795 1.61429)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint32_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(14.6489 17.0557) rotate(179.997) scale(2.02103 1.96418)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint33_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(12.2478 17.3595) rotate(26.7191) scale(2.07908 1.52577)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint34_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(12.9048 18.2632) rotate(26.7191) scale(2.08012 1.52653)">
        <stop stop-color="#B0E9FF" stop-opacity="0"/>
        <stop offset="0.8857" stop-color="#B0E9FF" stop-opacity="0.8869"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint35_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(14.1752 18.3281) rotate(85.5089) scale(1.13466 1.70356)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <linearGradient id="paint36_linear_8338_28001" x1="12.6155" y1="14.3211" x2="11.2152" y2="11.4518"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0"/>
        <stop offset="0.8857" stop-color="white" stop-opacity="0.8869"/>
        <stop offset="0.9987" stop-color="white"/>
      </linearGradient>
      <linearGradient id="paint37_linear_8338_28001" x1="13.9432" y1="20.5916" x2="14.8604" y2="17.8394"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#80DAFE"/>
        <stop offset="0.3454" stop-color="#83DBFE" stop-opacity="0.6546"/>
        <stop offset="0.5956" stop-color="#8CDEFE" stop-opacity="0.4044"/>
        <stop offset="0.815" stop-color="#9CE3FF" stop-opacity="0.185"/>
        <stop offset="1" stop-color="#B0E9FF" stop-opacity="0"/>
      </linearGradient>
      <radialGradient id="paint38_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(14.3984 17.1941) rotate(-155.513) scale(3.49924 3.43555)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint39_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(14.3579 18.1995) rotate(180) scale(2.35942 2.29326)">
        <stop stop-color="#80DAFE"/>
        <stop offset="0.3454" stop-color="#83DBFE" stop-opacity="0.6546"/>
        <stop offset="0.5956" stop-color="#8CDEFE" stop-opacity="0.4044"/>
        <stop offset="0.815" stop-color="#9CE3FF" stop-opacity="0.185"/>
        <stop offset="1" stop-color="#B0E9FF" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint40_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(12.2844 20.5489) scale(1.2608 1.22545)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <linearGradient id="paint41_linear_8338_28001" x1="12.7781" y1="13.5122" x2="12.1276" y2="13.6709"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="white"/>
        <stop offset="0.2441" stop-color="#FBFEFF"/>
        <stop offset="0.4613" stop-color="#F1FBFF"/>
        <stop offset="0.6683" stop-color="#DEF6FF"/>
        <stop offset="0.8677" stop-color="#C5EFFF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </linearGradient>
      <linearGradient id="paint42_linear_8338_28001" x1="12.2902" y1="13.7612" x2="12.8834" y2="13.4802"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#B0E9FF" stop-opacity="0"/>
        <stop offset="0.8857" stop-color="#B0E9FF" stop-opacity="0.8869"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </linearGradient>
      <radialGradient id="paint43_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(15.2738 16.214) rotate(-174.874) scale(1.01464 0.986646)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint44_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(16.832 17.4831) rotate(-172.094) scale(1.54586 1.99576)">
        <stop stop-color="#80DAFE"/>
        <stop offset="0.3454" stop-color="#83DBFE" stop-opacity="0.6546"/>
        <stop offset="0.5956" stop-color="#8CDEFE" stop-opacity="0.4044"/>
        <stop offset="0.815" stop-color="#9CE3FF" stop-opacity="0.185"/>
        <stop offset="1" stop-color="#B0E9FF" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint45_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(12.8115 15.7488) rotate(-174.874) scale(1.24325 1.20897)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint46_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(13.4398 16.5442) rotate(169.845) scale(1.65795 1.61429)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint47_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(14.6489 17.0557) rotate(179.997) scale(2.02103 1.96418)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint48_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(12.2478 17.3595) rotate(26.7191) scale(2.07908 1.52577)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint49_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(12.9048 18.2632) rotate(26.7191) scale(2.08012 1.52653)">
        <stop stop-color="#B0E9FF" stop-opacity="0"/>
        <stop offset="0.8857" stop-color="#B0E9FF" stop-opacity="0.8869"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <radialGradient id="paint50_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(14.1752 18.3281) rotate(85.5089) scale(1.13466 1.70356)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <linearGradient id="paint51_linear_8338_28001" x1="12.6155" y1="14.3211" x2="11.2152" y2="11.4518"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0"/>
        <stop offset="0.8857" stop-color="white" stop-opacity="0.8869"/>
        <stop offset="0.9987" stop-color="white"/>
      </linearGradient>
      <linearGradient id="paint52_linear_8338_28001" x1="13.9432" y1="20.5916" x2="14.8604" y2="17.8394"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#80DAFE"/>
        <stop offset="0.3454" stop-color="#83DBFE" stop-opacity="0.6546"/>
        <stop offset="0.5956" stop-color="#8CDEFE" stop-opacity="0.4044"/>
        <stop offset="0.815" stop-color="#9CE3FF" stop-opacity="0.185"/>
        <stop offset="1" stop-color="#B0E9FF" stop-opacity="0"/>
      </linearGradient>
      <radialGradient id="paint53_radial_8338_28001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(14.3984 17.1941) rotate(-155.513) scale(3.49924 3.43555)">
        <stop stop-color="white"/>
        <stop offset="0.2855" stop-color="#FCFEFF"/>
        <stop offset="0.4924" stop-color="#F3FCFF"/>
        <stop offset="0.6743" stop-color="#E3F7FF"/>
        <stop offset="0.841" stop-color="#CDF1FF"/>
        <stop offset="0.9975" stop-color="#B0E9FF"/>
        <stop offset="0.9987" stop-color="#B0E9FF"/>
      </radialGradient>
      <linearGradient id="paint54_linear_8338_28001" x1="13.5388" y1="7.01441" x2="13.5388" y2="8.69937"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <linearGradient id="paint55_linear_8338_28001" x1="6.45843" y1="7.01441" x2="6.45843" y2="8.69937"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
    </defs>

  </SvgIcon>
)

export const FourPointRating = () => (
  <SvgIcon viewBox="0 0 20 20">
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20Z"
      fill="url(#paint0_radial_8338_28071)"/>
    <path opacity="0.5"
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20Z"
          fill="url(#paint1_radial_8338_28071)"/>
    <path
      d="M8.29409 6.48126C8.40034 6.09375 8.0441 5.7375 7.32534 5.54378C6.71283 5.38126 5.28158 5.35626 4.03782 6.30001C3.80659 6.47503 4.03782 6.66252 4.24408 6.56878C4.94407 6.2375 6.63158 6.02501 7.78783 6.47503C8.25032 6.65625 8.29409 6.48126 8.29409 6.48126Z"
      fill="url(#paint2_linear_8338_28071)"/>
    <path
      d="M6.72811 10.5375C7.25968 10.5375 7.6906 9.88552 7.6906 9.08125C7.6906 8.27698 7.25968 7.625 6.72811 7.625C6.19655 7.625 5.76562 8.27698 5.76562 9.08125C5.76562 9.88552 6.19655 10.5375 6.72811 10.5375Z"
      fill="url(#paint3_radial_8338_28071)"/>
    <path
      d="M6.72811 8.1124C7.1406 8.1124 7.50311 8.47491 7.6906 9.00615C7.6781 8.21865 7.25311 7.5874 6.72811 7.5874C6.20311 7.5874 5.77813 8.21865 5.76562 9.00615C5.95312 8.47491 6.31563 8.1124 6.72811 8.1124Z"
      fill="url(#paint4_linear_8338_28071)"/>
    <path
      d="M5.80645 14.9949C5.88772 15.015 5.9627 14.9747 6.01897 14.8941C7.0377 13.442 8.6127 12.6554 9.79395 12.6554H10.0002H10.2065C11.3877 12.6554 12.9627 13.442 13.9814 14.8941C14.0377 14.9747 14.119 15.015 14.194 14.9949C14.2752 14.9747 14.319 14.8336 14.2752 14.7226C14.269 14.7025 14.2565 14.6823 14.2502 14.652C14.169 14.7226 14.0815 14.7932 13.9877 14.642C13.019 12.9882 11.5877 12 10.0502 12H9.99394H9.9377C8.39397 12 6.95022 12.9983 5.98147 14.6823C5.89396 14.8336 5.81271 14.7629 5.73147 14.6823C5.7252 14.7025 5.71897 14.7125 5.7127 14.7327C5.6752 14.8336 5.71897 14.9747 5.80645 14.9949Z"
      fill="url(#paint5_linear_8338_28071)"/>
    <path
      d="M11.706 6.48126C11.5997 6.09375 11.956 5.7375 12.6747 5.54378C13.2872 5.38126 14.7184 5.35626 15.9622 6.30001C16.1935 6.47503 15.9622 6.66252 15.7559 6.56878C15.056 6.2375 13.3684 6.02501 12.2122 6.47503C11.7497 6.65625 11.706 6.48126 11.706 6.48126Z"
      fill="url(#paint6_linear_8338_28071)"/>
    <path
      d="M13.2633 10.5375C13.7949 10.5375 14.2258 9.88552 14.2258 9.08125C14.2258 8.27698 13.7949 7.625 13.2633 7.625C12.7317 7.625 12.3008 8.27698 12.3008 9.08125C12.3008 9.88552 12.7317 10.5375 13.2633 10.5375Z"
      fill="url(#paint7_radial_8338_28071)"/>
    <path
      d="M13.2633 8.1124C13.6758 8.1124 14.0383 8.47491 14.2258 9.00615C14.2133 8.21865 13.7883 7.5874 13.2633 7.5874C12.7383 7.5874 12.3133 8.21865 12.3008 9.00615C12.4883 8.47491 12.8508 8.1124 13.2633 8.1124Z"
      fill="url(#paint8_linear_8338_28071)"/>
    <defs>
      <radialGradient id="paint0_radial_8338_28071" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.03 5.94733) scale(12.0637)">
        <stop stop-color="#FFE030"/>
        <stop offset="1" stop-color="#FFB92E"/>
      </radialGradient>
      <radialGradient id="paint1_radial_8338_28071" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.03 5.94733) scale(9.49105)">
        <stop stop-color="#FFEA5F"/>
        <stop offset="1" stop-color="#FFBC47" stop-opacity="0"/>
      </radialGradient>
      <linearGradient id="paint2_linear_8338_28071" x1="5.91473" y1="7.20901" x2="6.34677" y2="5.59654"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#7A4400"/>
      </linearGradient>
      <radialGradient id="paint3_radial_8338_28071" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(6.44179 9.11816) rotate(73.8539) scale(1.41293 0.914143)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint4_linear_8338_28071" x1="6.72952" y1="7.58711" x2="6.72952" y2="9.00713"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <linearGradient id="paint5_linear_8338_28071" x1="9.99778" y1="11.1626" x2="9.99778" y2="13.4216"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <linearGradient id="paint6_linear_8338_28071" x1="14.0674" y1="7.24776" x2="13.6354" y2="5.63529"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#7A4400"/>
      </linearGradient>
      <radialGradient id="paint7_radial_8338_28071" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(12.9756 9.1183) rotate(73.8539) scale(1.41293 0.914143)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint8_linear_8338_28071" x1="13.2632" y1="7.58711" x2="13.2632" y2="9.00713"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
    </defs>
  </SvgIcon>
)
export const FivePointRating = () => (
  <SvgIcon viewBox="0 0 20 20">
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20Z"
      fill="url(#paint0_radial_8338_28082)"/>
    <path opacity="0.5"
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 10 0C4.47717 0 0 4.47717 0 10C0 15.5228 4.47717 20 10 20Z"
          fill="url(#paint1_radial_8338_28082)"/>
    <path
      d="M7.42353 4.53109C7.36726 4.13107 6.90477 3.94358 6.16727 4.04983C5.54226 4.13734 4.21103 4.67484 3.42976 6.02485C3.28601 6.27484 3.57351 6.36232 3.72353 6.1936C4.23603 5.6186 5.71102 4.76235 6.94851 4.72485C7.45477 4.70608 7.42353 4.53109 7.42353 4.53109Z"
      fill="url(#paint2_linear_8338_28082)"/>
    <path
      d="M12.5707 4.53109C12.6269 4.13107 13.0894 3.94358 13.8269 4.04983C14.4519 4.13734 15.7832 4.67484 16.5644 6.02485C16.7082 6.27484 16.4207 6.36232 16.2707 6.1936C15.7582 5.6186 14.2832 4.76235 13.0457 4.72485C12.5394 4.70608 12.5707 4.53109 12.5707 4.53109Z"
      fill="url(#paint3_linear_8338_28082)"/>
    <path
      d="M15.2521 11.6248C16.4721 10.848 16.8313 9.22933 16.0545 8.00939C15.2777 6.78943 13.659 6.43019 12.4391 7.207C11.2191 7.9838 10.8599 9.60251 11.6367 10.8224C12.4135 12.0424 14.0322 12.4016 15.2521 11.6248Z"
      fill="white"/>
    <path
      d="M13.8492 12.0373C15.2955 12.0373 16.468 10.8648 16.468 9.41854C16.468 7.97226 15.2955 6.7998 13.8492 6.7998C12.4029 6.7998 11.2305 7.97226 11.2305 9.41854C11.2305 10.8648 12.4029 12.0373 13.8492 12.0373Z"
      fill="url(#paint4_radial_8338_28082)"/>
    <path
      d="M13.8492 12.0373C15.2955 12.0373 16.468 10.8648 16.468 9.41854C16.468 7.97226 15.2955 6.7998 13.8492 6.7998C12.4029 6.7998 11.2305 7.97226 11.2305 9.41854C11.2305 10.8648 12.4029 12.0373 13.8492 12.0373Z"
      fill="url(#paint5_radial_8338_28082)"/>
    <path
      d="M13.8508 12.2499C15.4144 12.2499 16.682 10.9823 16.682 9.41863C16.682 7.85498 15.4144 6.5874 13.8508 6.5874C12.2871 6.5874 11.0195 7.85498 11.0195 9.41863C11.0195 10.9823 12.2871 12.2499 13.8508 12.2499Z"
      fill="url(#paint6_radial_8338_28082)"/>
    <path
      d="M13.8508 12.2499C15.4144 12.2499 16.682 10.9823 16.682 9.41863C16.682 7.85498 15.4144 6.5874 13.8508 6.5874C12.2871 6.5874 11.0195 7.85498 11.0195 9.41863C11.0195 10.9823 12.2871 12.2499 13.8508 12.2499Z"
      fill="url(#paint7_radial_8338_28082)"/>
    <path
      d="M9.99999 15C11.1046 15 12 14.7762 12 14.5C12 14.2239 11.1046 14 9.99999 14C8.89542 14 8 14.2239 8 14.5C8 14.7762 8.89542 15 9.99999 15Z"
      fill="url(#paint8_radial_8338_28082)"/>
    <path
      d="M7.549 11.6268C8.76897 10.85 9.1282 9.23128 8.35139 8.01135C7.57459 6.79138 5.95589 6.43215 4.73595 7.20895C3.51601 7.98575 3.15675 9.60443 3.93355 10.8244C4.71036 12.0443 6.32906 12.4036 7.549 11.6268Z"
      fill="white"/>
    <path
      d="M6.14217 12.0373C7.58848 12.0373 8.76093 10.8648 8.76093 9.41857C8.76093 7.97226 7.58848 6.7998 6.14217 6.7998C4.69586 6.7998 3.52344 7.97226 3.52344 9.41857C3.52344 10.8648 4.69586 12.0373 6.14217 12.0373Z"
      fill="url(#paint9_radial_8338_28082)"/>
    <path
      d="M6.14217 12.0373C7.58848 12.0373 8.76093 10.8648 8.76093 9.41857C8.76093 7.97226 7.58848 6.7998 6.14217 6.7998C4.69586 6.7998 3.52344 7.97226 3.52344 9.41857C3.52344 10.8648 4.69586 12.0373 6.14217 12.0373Z"
      fill="url(#paint10_radial_8338_28082)"/>
    <path
      d="M6.14376 12.2499C7.70741 12.2499 8.97502 10.9823 8.97502 9.41866C8.97502 7.85498 7.70741 6.5874 6.14376 6.5874C4.58011 6.5874 3.3125 7.85498 3.3125 9.41866C3.3125 10.9823 4.58011 12.2499 6.14376 12.2499Z"
      fill="url(#paint11_radial_8338_28082)"/>
    <path
      d="M6 8.99985C6.55228 8.99985 7 8.55217 7 7.99993C7 7.44768 6.55228 7 6 7C5.44772 7 5 7.44768 5 7.99993C5 8.55217 5.44772 8.99985 6 8.99985Z"
      fill="url(#paint12_radial_8338_28082)"/>
    <path
      d="M5.99982 8.69247C5.60532 8.69247 5.25671 8.509 5.02734 8.22461C5.12827 8.66952 5.52734 8.99979 5.99982 8.99979C6.47229 8.99979 6.87138 8.66952 6.97229 8.22461C6.74753 8.509 6.39431 8.69247 5.99982 8.69247Z"
      fill="url(#paint13_linear_8338_28082)"/>
    <path
      d="M6 8.99985C6.55228 8.99985 7 8.55217 7 7.99993C7 7.44768 6.55228 7 6 7C5.44772 7 5 7.44768 5 7.99993C5 8.55217 5.44772 8.99985 6 8.99985Z"
      fill="url(#paint14_linear_8338_28082)"/>
    <path
      d="M14 8.99985C14.5523 8.99985 15 8.55217 15 7.99993C15 7.44768 14.5523 7 14 7C13.4477 7 13 7.44768 13 7.99993C13 8.55217 13.4477 8.99985 14 8.99985Z"
      fill="url(#paint15_radial_8338_28082)"/>
    <path
      d="M13.9998 8.69247C13.6053 8.69247 13.2567 8.509 13.0273 8.22461C13.1283 8.66952 13.5273 8.99979 13.9998 8.99979C14.4723 8.99979 14.8714 8.66952 14.9723 8.22461C14.7475 8.509 14.3943 8.69247 13.9998 8.69247Z"
      fill="url(#paint16_linear_8338_28082)"/>
    <path
      d="M14 8.99985C14.5523 8.99985 15 8.55217 15 7.99993C15 7.44768 14.5523 7 14 7C13.4477 7 13 7.44768 13 7.99993C13 8.55217 13.4477 8.99985 14 8.99985Z"
      fill="url(#paint17_linear_8338_28082)"/>
    <path
      d="M6.14376 12.2499C7.70741 12.2499 8.97502 10.9823 8.97502 9.41866C8.97502 7.85498 7.70741 6.5874 6.14376 6.5874C4.58011 6.5874 3.3125 7.85498 3.3125 9.41866C3.3125 10.9823 4.58011 12.2499 6.14376 12.2499Z"
      fill="url(#paint18_radial_8338_28082)"/>
    <defs>
      <radialGradient id="paint0_radial_8338_28082" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.02951 5.94612) scale(12.0637)">
        <stop stop-color="#FFE030"/>
        <stop offset="1" stop-color="#FFB92E"/>
      </radialGradient>
      <radialGradient id="paint1_radial_8338_28082" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.02951 5.94611) scale(9.49105)">
        <stop stop-color="#FFEA5F"/>
        <stop offset="1" stop-color="#FFBC47" stop-opacity="0"/>
      </radialGradient>
      <linearGradient id="paint2_linear_8338_28082" x1="5.42561" y1="5.48689" x2="5.28075" y2="4.44315"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#7A4400"/>
      </linearGradient>
      <linearGradient id="paint3_linear_8338_28082" x1="14.5685" y1="5.47625" x2="14.7134" y2="4.43255"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#7A4400"/>
      </linearGradient>
      <radialGradient id="paint4_radial_8338_28082" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(13.8456 9.73974) rotate(90) scale(3.28912 3.32916)">
        <stop stop-color="#CFDCDE" stop-opacity="0"/>
        <stop offset="0.8869" stop-color="#CFDCDE" stop-opacity="0.8869"/>
        <stop offset="1" stop-color="#CFDCDE"/>
      </radialGradient>
      <radialGradient id="paint5_radial_8338_28082" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(13.8344 9.18284) rotate(180) scale(2.82631)">
        <stop stop-color="white" stop-opacity="0"/>
        <stop offset="0.4376" stop-color="#FDFEFE" stop-opacity="0.3499"/>
        <stop offset="0.5952" stop-color="#F6F8F9" stop-opacity="0.4759"/>
        <stop offset="0.7075" stop-color="#EBEFF0" stop-opacity="0.5657"/>
        <stop offset="0.7983" stop-color="#DAE2E3" stop-opacity="0.6384"/>
        <stop offset="0.8752" stop-color="#C4D1D3" stop-opacity="0.6999"/>
        <stop offset="0.9004" stop-color="#BBCACC" stop-opacity="0.72"/>
        <stop offset="1" stop-color="#99AFB3"/>
      </radialGradient>
      <radialGradient id="paint6_radial_8338_28082" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(13.8297 9.41873) rotate(180) scale(2.87286)">
        <stop offset="0.8945" stop-color="#E89043" stop-opacity="0"/>
        <stop offset="0.9103" stop-color="#EE9640" stop-opacity="0.0748107"/>
        <stop offset="0.9488" stop-color="#F89F3C" stop-opacity="0.2573"/>
        <stop offset="1" stop-color="#FBA23B" stop-opacity="0.5"/>
      </radialGradient>
      <radialGradient id="paint7_radial_8338_28082" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(13.8297 9.41873) rotate(180) scale(2.87286)">
        <stop offset="0.8827" stop-color="#FBA23B" stop-opacity="0"/>
        <stop offset="0.9161" stop-color="#FBA23B" stop-opacity="0.5"/>
        <stop offset="0.9866" stop-color="#E89043" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint8_radial_8338_28082" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(9.99933 14.4995) rotate(-3.45267) scale(1.4534 0.808376)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <radialGradient id="paint9_radial_8338_28082" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(6.1422 9.73967) rotate(90) scale(3.28912 3.32916)">
        <stop stop-color="#CFDCDE" stop-opacity="0"/>
        <stop offset="0.8869" stop-color="#CFDCDE" stop-opacity="0.8869"/>
        <stop offset="1" stop-color="#CFDCDE"/>
      </radialGradient>
      <radialGradient id="paint10_radial_8338_28082" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(6.13108 9.18288) rotate(180) scale(2.82631)">
        <stop stop-color="white" stop-opacity="0"/>
        <stop offset="0.4376" stop-color="#FDFEFE" stop-opacity="0.3499"/>
        <stop offset="0.5952" stop-color="#F6F8F9" stop-opacity="0.4759"/>
        <stop offset="0.7075" stop-color="#EBEFF0" stop-opacity="0.5657"/>
        <stop offset="0.7983" stop-color="#DAE2E3" stop-opacity="0.6384"/>
        <stop offset="0.8752" stop-color="#C4D1D3" stop-opacity="0.6999"/>
        <stop offset="0.9004" stop-color="#BBCACC" stop-opacity="0.72"/>
        <stop offset="1" stop-color="#99AFB3"/>
      </radialGradient>
      <radialGradient id="paint11_radial_8338_28082" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(6.1264 9.41873) rotate(180) scale(2.87286)">
        <stop offset="0.8945" stop-color="#E89043" stop-opacity="0"/>
        <stop offset="0.9103" stop-color="#EE9640" stop-opacity="0.0748107"/>
        <stop offset="0.9488" stop-color="#F89F3C" stop-opacity="0.2573"/>
        <stop offset="1" stop-color="#FBA23B" stop-opacity="0.5"/>
      </radialGradient>
      <radialGradient id="paint12_radial_8338_28082" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(5.82658 8.30209) scale(1.04945 1.04937)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint13_linear_8338_28082" x1="5.02809" y1="8.61243" x2="6.97352" y2="8.61252"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <linearGradient id="paint14_linear_8338_28082" x1="6.0014" y1="7.06938" x2="6.0014" y2="8.12479"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#7A4400" stop-opacity="0"/>
      </linearGradient>
      <radialGradient id="paint15_radial_8338_28082" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(13.8266 8.30209) scale(1.04945 1.04937)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint16_linear_8338_28082" x1="13.0281" y1="8.61243" x2="14.9735" y2="8.61252"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <linearGradient id="paint17_linear_8338_28082" x1="14.0014" y1="7.06938" x2="14.0014" y2="8.12479"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#7A4400" stop-opacity="0"/>
      </linearGradient>
      <radialGradient id="paint18_radial_8338_28082" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(6.1264 9.41873) rotate(180) scale(2.87286)">
        <stop offset="0.8827" stop-color="#FBA23B" stop-opacity="0"/>
        <stop offset="0.9161" stop-color="#FBA23B" stop-opacity="0.5"/>
        <stop offset="0.9866" stop-color="#E89043" stop-opacity="0"/>
      </radialGradient>
    </defs>

  </SvgIcon>
)

export const SixPointRating = () => (
  <SvgIcon viewBox="0 0 20 20">
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47716 15.5228 0 10 0C4.47717 0 0 4.47716 0 10C0 15.5228 4.47717 20 10 20Z"
      fill="url(#paint0_radial_8338_28106)"/>
    <path opacity="0.5"
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47716 15.5228 0 10 0C4.47717 0 0 4.47716 0 10C0 15.5228 4.47717 20 10 20Z"
          fill="url(#paint1_radial_8338_28106)"/>
    <path
      d="M6.73596 9.4252C7.26752 9.4252 7.69845 8.77321 7.69845 7.96895C7.69845 7.16468 7.26752 6.5127 6.73596 6.5127C6.20436 6.5127 5.77344 7.16468 5.77344 7.96895C5.77344 8.77321 6.20436 9.4252 6.73596 9.4252Z"
      fill="url(#paint2_radial_8338_28106)"/>
    <path
      d="M6.73596 7.00644C7.14844 7.00644 7.51096 7.36896 7.69845 7.90022C7.68595 7.11272 7.26096 6.48145 6.73596 6.48145C6.21096 6.48145 5.78594 7.11272 5.77344 7.90022C5.96096 7.36896 6.31721 7.00644 6.73596 7.00644Z"
      fill="url(#paint3_linear_8338_28106)"/>
    <path
      d="M13.2594 9.4252C13.791 9.4252 14.2219 8.77321 14.2219 7.96895C14.2219 7.16468 13.791 6.5127 13.2594 6.5127C12.7278 6.5127 12.2969 7.16468 12.2969 7.96895C12.2969 8.77321 12.7278 9.4252 13.2594 9.4252Z"
      fill="url(#paint4_radial_8338_28106)"/>
    <path
      d="M13.2594 7.04404C12.8469 7.04404 12.4844 7.40652 12.2969 7.93779C12.3094 7.15029 12.7344 6.51904 13.2594 6.51904C13.7844 6.51904 14.2094 7.15029 14.2219 7.93779C14.0344 7.40652 13.6781 7.04404 13.2594 7.04404Z"
      fill="url(#paint5_linear_8338_28106)"/>
    <path
      d="M14.2141 14.3628H5.78907C5.53284 14.3628 5.32031 14.1503 5.32031 13.894C5.32031 13.6378 5.53284 13.4253 5.78907 13.4253H14.2141C14.4703 13.4253 14.6828 13.6378 14.6828 13.894C14.6828 14.1503 14.4703 14.3628 14.2141 14.3628Z"
      fill="url(#paint6_radial_8338_28106)"/>
    <path
      d="M5.74533 13.6753H14.2641C14.4453 13.6753 14.6078 13.7815 14.6828 13.9315C14.6828 13.919 14.6828 13.9065 14.6828 13.894C14.6828 13.6378 14.4703 13.4253 14.2141 13.4253H5.78907C5.53284 13.4253 5.32031 13.6378 5.32031 13.894C5.32031 13.9065 5.32031 13.919 5.32031 13.9315C5.40159 13.7753 5.55784 13.6753 5.74533 13.6753Z"
      fill="url(#paint7_linear_8338_28106)"/>
    <defs>
      <radialGradient id="paint0_radial_8338_28106" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.03246 5.94733) scale(12.0637 12.0637)">
        <stop stop-color="#FFE030"/>
        <stop offset="1" stop-color="#FFB92E"/>
      </radialGradient>
      <radialGradient id="paint1_radial_8338_28106" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.03246 5.94733) scale(9.49105 9.49105)">
        <stop stop-color="#FFEA5F"/>
        <stop offset="1" stop-color="#FFBC47" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint2_radial_8338_28106" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(6.44147 8.00734) rotate(73.8539) scale(1.41293 0.914143)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint3_linear_8338_28106" x1="6.7336" y1="6.50707" x2="6.7336" y2="7.86423"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <radialGradient id="paint4_radial_8338_28106" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(12.97 8.00757) rotate(73.8539) scale(1.41293 0.914143)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint5_linear_8338_28106" x1="13.2618" y1="6.54286" x2="13.2618" y2="7.89999"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <radialGradient id="paint6_radial_8338_28106" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(10.0016 13.8914) scale(3.32669 0.598473)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint7_linear_8338_28106" x1="5.32031" y1="13.675" x2="14.6828" y2="13.675"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
    </defs>
  </SvgIcon>
)

export const SevenPointRating = () => (
  <SvgIcon viewBox="0 0 20 20">
    <path
      d="M9.99999 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 9.99999 0C4.47716 0 0 4.47717 0 10C0 15.5228 4.47716 20 9.99999 20Z"
      fill="url(#paint0_radial_8338_28116)"/>
    <path opacity="0.5"
          d="M9.99999 20C15.5228 20 20 15.5228 20 10C20 4.47717 15.5228 0 9.99999 0C4.47716 0 0 4.47717 0 10C0 15.5228 4.47716 20 9.99999 20Z"
          fill="url(#paint1_radial_8338_28116)"/>
    <path
      d="M6.73202 8.6752C7.26362 8.6752 7.69454 8.02318 7.69454 7.21895C7.69454 6.41468 7.26362 5.7627 6.73202 5.7627C6.20046 5.7627 5.76953 6.41468 5.76953 7.21895C5.76953 8.02318 6.20046 8.6752 6.73202 8.6752Z"
      fill="url(#paint2_radial_8338_28116)"/>
    <path
      d="M6.73202 6.25645C7.14454 6.25645 7.50702 6.61893 7.69454 7.15019C7.68204 6.36269 7.25702 5.73145 6.73202 5.73145C6.20702 5.73145 5.78203 6.36269 5.76953 7.15019C5.95702 6.61893 6.31953 6.25645 6.73202 6.25645Z"
      fill="url(#paint3_linear_8338_28116)"/>
    <path
      d="M13.2633 8.6752C13.7949 8.6752 14.2258 8.02318 14.2258 7.21895C14.2258 6.41468 13.7949 5.7627 13.2633 5.7627C12.7317 5.7627 12.3008 6.41468 12.3008 7.21895C12.3008 8.02318 12.7317 8.6752 13.2633 8.6752Z"
      fill="url(#paint4_radial_8338_28116)"/>
    <path
      d="M13.2633 6.25645C12.8508 6.25645 12.4883 6.61896 12.3008 7.15022C12.3133 6.36272 12.7383 5.73145 13.2633 5.73145C13.7883 5.73145 14.2133 6.36272 14.2258 7.15022C14.0383 6.61896 13.6758 6.25645 13.2633 6.25645Z"
      fill="url(#paint5_linear_8338_28116)"/>
    <path
      d="M14.4187 11.925C14.3375 11.9125 14.25 11.9375 14.1937 11.9938C13.1187 13 11.4625 13.5438 10.2125 13.5438H9.99998H9.78748C8.53749 13.5438 6.88121 13 5.80621 11.9938C5.74373 11.9375 5.66249 11.9125 5.58121 11.925C5.49374 11.9438 5.44996 12.0375 5.49374 12.1125C6.41249 13.7 8.10623 14.6813 9.94997 14.6813H10.0062H10.0625C11.9062 14.6813 13.6 13.7 14.5187 12.1125C14.55 12.0375 14.5062 11.9438 14.4187 11.925Z"
      fill="#643800"/>
    <path
      d="M14.4187 11.925C14.3375 11.9125 14.25 11.9375 14.1937 11.9938C13.1187 13 11.4625 13.5438 10.2125 13.5438H9.99998H9.78748C8.53749 13.5438 6.88121 13 5.80621 11.9938C5.74373 11.9375 5.66249 11.9125 5.58121 11.925C5.49374 11.9438 5.44996 12.0375 5.49374 12.1125C5.49997 12.1313 5.51247 12.1438 5.52497 12.1563C5.61248 12.1063 5.70623 12.0563 5.79997 12.1625C6.81874 13.3063 8.33123 13.9938 9.94997 13.9938H10.0062H10.0625C11.6937 13.9938 13.2125 13.3 14.2312 12.1375C14.325 12.0313 14.4125 12.0813 14.4937 12.1375C14.5 12.125 14.5062 12.1188 14.5125 12.1063C14.55 12.0375 14.5062 11.9438 14.4187 11.925Z"
      fill="url(#paint6_linear_8338_28116)"/>
    <defs>
      <radialGradient id="paint0_radial_8338_28116" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.03 5.94733) scale(12.0637 12.0637)">
        <stop stop-color="#FFE030"/>
        <stop offset="1" stop-color="#FFB92E"/>
      </radialGradient>
      <radialGradient id="paint1_radial_8338_28116" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.03 5.94733) scale(9.49105 9.49105)">
        <stop stop-color="#FFEA5F"/>
        <stop offset="1" stop-color="#FFBC47" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint2_radial_8338_28116" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(6.44462 7.26026) rotate(73.8539) scale(1.41293 0.914143)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint3_linear_8338_28116" x1="6.73343" y1="5.75704" x2="6.73343" y2="7.1142"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <radialGradient id="paint4_radial_8338_28116" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(12.9747 7.26049) rotate(73.8539) scale(1.41293 0.914144)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint5_linear_8338_28116" x1="13.2632" y1="5.75707" x2="13.2632" y2="7.11423"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <linearGradient id="paint6_linear_8338_28116" x1="9.99751" y1="12.6319" x2="9.99751" y2="14.1247"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
    </defs>
  </SvgIcon>
)
export const EightPointRating = () => (
  <SvgIcon viewBox="0 0 20 20">
    <path
      d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20Z"
      fill="url(#paint0_radial_8338_28138)"/>
    <path opacity="0.5"
          d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20Z"
          fill="url(#paint1_radial_8338_28138)"/>
    <path opacity="0.5"
          d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20Z"
          fill="url(#paint2_radial_8338_28138)"/>
    <path opacity="0.5"
          d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20Z"
          fill="url(#paint3_radial_8338_28138)"/>
    <path
      d="M13.4384 5.00949C13.5314 4.61878 14.0058 4.47459 14.7361 4.64204C15.3547 4.78623 16.6291 5.43738 17.2849 6.85599C17.4058 7.11646 17.1128 7.17691 16.9779 6.99551C16.5174 6.37691 15.1267 5.39089 13.8942 5.24204C13.3965 5.18157 13.4384 5.00949 13.4384 5.00949Z"
      fill="url(#paint4_linear_8338_28138)"/>
    <path
      d="M14.3954 7.29309C14.3954 7.29309 16.0048 7.73959 16.2466 9.06054C16.2606 9.14425 16.2652 9.22796 16.2466 9.3117C16.2187 9.47915 16.0326 9.62796 15.7722 9.41401C14.1443 8.1117 13.0234 8.40006 12.1955 8.59078C11.8699 8.66517 11.6606 8.3117 11.7955 8.06054C11.8373 7.98612 11.8652 7.90704 11.921 7.84656C12.7861 6.83728 14.3954 7.29309 14.3954 7.29309Z"
      fill="url(#paint5_radial_8338_28138)"/>
    <path
      d="M14.2918 7.67915C14.2918 7.67915 15.6034 8.04194 16.2545 9.20935C16.2545 9.15819 16.2545 9.1117 16.2452 9.06054C16.0034 7.73959 14.3941 7.29309 14.3941 7.29309C14.3941 7.29309 12.7848 6.83728 11.9196 7.84656C11.8871 7.88377 11.8592 7.92564 11.8359 7.96751C12.9801 7.30704 14.2918 7.67915 14.2918 7.67915Z"
      fill="url(#paint6_linear_8338_28138)"/>
    <path
      d="M6.56261 5.00949C6.46962 4.61878 5.9952 4.47459 5.26496 4.64204C4.64636 4.78623 3.37193 5.43738 2.71612 6.85599C2.5952 7.11646 2.88819 7.17691 3.02309 6.99551C3.48357 6.37691 4.87425 5.39089 6.1068 5.24204C6.60448 5.18157 6.56261 5.00949 6.56261 5.00949Z"
      fill="url(#paint7_linear_8338_28138)"/>
    <path
      d="M5.60562 7.29309C5.60562 7.29309 3.99631 7.73959 3.75447 9.06054C3.74049 9.14425 3.73586 9.22796 3.75447 9.3117C3.78236 9.47915 3.96841 9.62796 4.22889 9.41401C5.85678 8.1117 6.9777 8.40006 7.80562 8.59078C8.1312 8.66517 8.34049 8.3117 8.20562 8.06054C8.16375 7.98612 8.13586 7.90704 8.08004 7.84656C7.21491 6.83728 5.60562 7.29309 5.60562 7.29309Z"
      fill="url(#paint8_radial_8338_28138)"/>
    <path
      d="M5.70498 7.67915C5.70498 7.67915 4.39334 8.04194 3.74219 9.20935C3.74219 9.15819 3.74219 9.1117 3.75147 9.06054C3.99334 7.73959 5.60266 7.29309 5.60266 7.29309C5.60266 7.29309 7.21195 6.83728 8.07708 7.84656C8.10964 7.88377 8.13753 7.92564 8.16079 7.96751C7.01661 7.30704 5.70498 7.67915 5.70498 7.67915Z"
      fill="url(#paint9_linear_8338_28138)"/>
    <path
      d="M14.4178 12.9258C14.3341 12.9118 14.2503 12.9351 14.1899 12.9956C13.1154 14.0049 11.4596 14.5444 10.2131 14.5444H9.99916H9.78521C8.53871 14.5444 6.87824 14.0049 5.80848 12.9956C5.748 12.9397 5.66429 12.9118 5.58055 12.9258C5.49684 12.9397 5.45035 13.0374 5.49218 13.1165C6.40848 14.7025 8.10613 15.6886 9.948 15.6886H10.0038H10.0596C11.9015 15.6886 13.5992 14.7072 14.5154 13.1165C14.5527 13.0374 14.5062 12.9444 14.4178 12.9258Z"
      fill="#643800"/>
    <path
      d="M14.4178 12.9258C14.3341 12.9118 14.2503 12.9351 14.1899 12.9956C13.1154 14.0049 11.4596 14.5444 10.2131 14.5444H9.99916H9.78521C8.53871 14.5444 6.87824 14.0049 5.80848 12.9956C5.748 12.9397 5.66429 12.9118 5.58055 12.9258C5.49684 12.9397 5.45035 13.0374 5.49218 13.1165C5.5015 13.1305 5.51079 13.1491 5.52011 13.163C5.60848 13.1118 5.69684 13.0653 5.79453 13.1723C6.81314 14.3165 8.32474 15.0002 9.94334 15.0002H9.99916H10.055C11.6829 15.0002 13.2038 14.3072 14.2224 13.1491C14.3154 13.0421 14.4038 13.0932 14.4876 13.1491C14.4922 13.1397 14.5015 13.1258 14.5062 13.1165C14.5527 13.0374 14.5062 12.9444 14.4178 12.9258Z"
      fill="url(#paint10_linear_8338_28138)"/>
    <defs>
      <radialGradient id="paint0_radial_8338_28138" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.03249 5.94733) scale(12.0637)">
        <stop stop-color="#FFDF30"/>
        <stop offset="1" stop-color="#FFB82E"/>
      </radialGradient>
      <radialGradient id="paint1_radial_8338_28138" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.03249 5.94733) scale(9.49106)">
        <stop stop-color="#FFE95F"/>
        <stop offset="1" stop-color="#FFBB47" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint2_radial_8338_28138" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(4.55193 12.4877) rotate(-2.7703) scale(4.20623 3.3282)">
        <stop stop-color="#FF4C00"/>
        <stop offset="0.1542" stop-color="#FF4C00"/>
        <stop offset="0.1795" stop-color="#FF4C00"/>
        <stop offset="0.3996" stop-color="#FA4C0B" stop-opacity="0.7318"/>
        <stop offset="0.7799" stop-color="#EE4B27" stop-opacity="0.2683"/>
        <stop offset="1" stop-color="#E64A3A" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint3_radial_8338_28138" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(15.4429 12.4877) rotate(-177.23) scale(4.20623 3.3282)">
        <stop stop-color="#FF4C00"/>
        <stop offset="0.1542" stop-color="#FF4C00"/>
        <stop offset="0.1795" stop-color="#FF4C00"/>
        <stop offset="0.3996" stop-color="#FA4C0B" stop-opacity="0.7318"/>
        <stop offset="0.7799" stop-color="#EE4B27" stop-opacity="0.2683"/>
        <stop offset="1" stop-color="#E64A3A" stop-opacity="0"/>
      </radialGradient>
      <linearGradient id="paint4_linear_8338_28138" x1="15.339" y1="6.1395" x2="15.5774" y2="5.11312"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#7A4400"/>
      </linearGradient>
      <radialGradient id="paint5_radial_8338_28138" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(14.1452 8.20587) rotate(15.68) scale(1.7972 0.867002)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint6_linear_8338_28138" x1="14.5392" y1="6.7892" x2="14.2318" y2="7.90891"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <linearGradient id="paint7_linear_8338_28138" x1="4.66175" y1="6.13477" x2="4.42329" y2="5.10839"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#7A4400"/>
      </linearGradient>
      <radialGradient id="paint8_radial_8338_28138" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(5.8494 8.20984) rotate(164.32) scale(1.7972 0.867002)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint9_linear_8338_28138" x1="5.45185" y1="6.78418" x2="5.75932" y2="7.90389"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <linearGradient id="paint10_linear_8338_28138" x1="9.99916" y1="13.6321" x2="9.99916" y2="15.1249"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
    </defs>
  </SvgIcon>
)
export const NinePointRating = () => (
  <SvgIcon viewBox="0 0 20 20">
    <path
      d="M10.1824 19.597C15.4737 19.597 19.7632 15.3533 19.7632 10.1183C19.7632 4.88339 15.4737 0.639648 10.1824 0.639648C4.89106 0.639648 0.601562 4.88339 0.601562 10.1183C0.601562 15.3533 4.89106 19.597 10.1824 19.597Z"
      fill="url(#paint0_radial_8338_28151)"/>
    <path opacity="0.5"
          d="M10.1824 19.597C15.4737 19.597 19.7632 15.3533 19.7632 10.1183C19.7632 4.88339 15.4737 0.639648 10.1824 0.639648C4.89106 0.639648 0.601562 4.88339 0.601562 10.1183C0.601562 15.3533 4.89106 19.597 10.1824 19.597Z"
          fill="url(#paint1_radial_8338_28151)"/>
    <path opacity="0.5"
          d="M10.1824 19.597C15.4737 19.597 19.7632 15.3533 19.7632 10.1183C19.7632 4.88339 15.4737 0.639648 10.1824 0.639648C4.89106 0.639648 0.601562 4.88339 0.601562 10.1183C0.601562 15.3533 4.89106 19.597 10.1824 19.597Z"
          fill="url(#paint2_radial_8338_28151)"/>
    <path opacity="0.5"
          d="M10.1824 19.597C15.4737 19.597 19.7632 15.3533 19.7632 10.1183C19.7632 4.88339 15.4737 0.639648 10.1824 0.639648C4.89106 0.639648 0.601562 4.88339 0.601562 10.1183C0.601562 15.3533 4.89106 19.597 10.1824 19.597Z"
          fill="url(#paint3_radial_8338_28151)"/>
    <path
      d="M10.1771 16.422C7.41662 16.422 6.53638 13.4955 7.37472 13.9516C8.50045 14.5677 9.21904 14.627 10.1771 14.627C11.1352 14.627 11.8478 14.5677 12.9795 13.9516C13.8178 13.4955 12.9376 16.422 10.1771 16.422Z"
      fill="#643800"/>
    <path
      d="M12.9811 13.9515C11.8554 14.5675 11.1369 14.6268 10.1788 14.6268C9.22067 14.6268 8.50812 14.5675 7.37638 13.9515C7.08295 13.7915 6.99914 14.0403 7.13086 14.4372C7.13086 14.4313 7.17276 14.0048 7.56799 14.301C7.56799 14.301 9.02309 15.1659 10.1728 15.1659C11.3225 15.1659 12.7776 14.301 12.7776 14.301C13.1728 14.0107 13.2147 14.4313 13.2147 14.4372C13.3584 14.0462 13.2746 13.7915 12.9811 13.9515Z"
      fill="url(#paint4_linear_8338_28151)"/>
    <path
      d="M8.09039 6.66474C8.14428 6.28558 7.76106 5.99531 7.05445 5.90645C6.44966 5.82944 5.09038 5.97755 4.01854 7.01427C3.82092 7.20383 4.06645 7.35788 4.24608 7.23938C4.86885 6.84246 6.44966 6.43963 7.59938 6.71805C8.06644 6.83061 8.09039 6.66474 8.09039 6.66474Z"
      fill="url(#paint5_linear_8338_28151)"/>
    <path
      d="M12.2706 6.66474C12.2167 6.28558 12.6 5.99531 13.3066 5.90645C13.9113 5.82944 15.2706 5.97755 16.3425 7.01427C16.5401 7.20383 16.2946 7.35788 16.1149 7.23938C15.4922 6.84246 13.9113 6.43963 12.7617 6.71805C12.2946 6.83061 12.2706 6.66474 12.2706 6.66474Z"
      fill="url(#paint6_linear_8338_28151)"/>
    <path
      d="M14.1501 8.96906C14.1501 8.96906 15.731 9.24157 16.0842 10.4619C16.1082 10.539 16.1142 10.6219 16.1082 10.6989C16.0962 10.8589 15.9346 11.0129 15.6651 10.8411C13.9885 9.76881 12.9525 10.142 12.1801 10.4027C11.8807 10.5034 11.6411 10.1894 11.7489 9.94062C11.7789 9.86952 11.8028 9.79251 11.8507 9.72735C12.5693 8.69063 14.1501 8.96906 14.1501 8.96906Z"
      fill="url(#paint7_radial_8338_28151)"/>
    <path
      d="M14.0848 9.34253C14.0848 9.34253 15.3722 9.56174 16.1028 10.6044C16.0968 10.557 16.0908 10.5096 16.0788 10.4622C15.7255 9.24183 14.1447 8.96931 14.1447 8.96931C14.1447 8.96931 12.5698 8.69089 11.8393 9.72167C11.8094 9.76316 11.7914 9.80462 11.7734 9.84607C12.8034 9.11742 14.0848 9.34253 14.0848 9.34253Z"
      fill="url(#paint8_linear_8338_28151)"/>
    <path
      d="M6.21026 8.96906C6.21026 8.96906 4.62941 9.24157 4.27612 10.4619C4.25217 10.539 4.24616 10.6219 4.25217 10.6989C4.26414 10.8589 4.42583 11.0129 4.69527 10.8411C6.37191 9.76881 7.40785 10.142 8.18029 10.4027C8.4797 10.5034 8.71922 10.1894 8.61145 9.94062C8.58149 9.86952 8.55754 9.79251 8.50963 9.72735C7.79107 8.69063 6.21026 8.96906 6.21026 8.96906Z"
      fill="url(#paint9_radial_8338_28151)"/>
    <path
      d="M6.27576 9.34253C6.27576 9.34253 4.98835 9.56174 4.25781 10.6044C4.26379 10.557 4.26979 10.5096 4.28177 10.4622C4.63506 9.24183 6.2159 8.96931 6.2159 8.96931C6.2159 8.96931 7.79074 8.69089 8.52128 9.72167C8.55121 9.76316 8.56919 9.80462 8.58714 9.84607C7.5572 9.11742 6.27576 9.34253 6.27576 9.34253Z"
      fill="url(#paint10_linear_8338_28151)"/>
    <path opacity="0.5"
          d="M10.1824 19.597C15.4737 19.597 19.7632 15.3533 19.7632 10.1183C19.7632 4.88339 15.4737 0.639648 10.1824 0.639648C4.89106 0.639648 0.601562 4.88339 0.601562 10.1183C0.601562 15.3533 4.89106 19.597 10.1824 19.597Z"
          fill="url(#paint11_radial_8338_28151)"/>
    <path opacity="0.5"
          d="M10.1824 19.597C15.4737 19.597 19.7632 15.3533 19.7632 10.1183C19.7632 4.88339 15.4737 0.639648 10.1824 0.639648C4.89106 0.639648 0.601562 4.88339 0.601562 10.1183C0.601562 15.3533 4.89106 19.597 10.1824 19.597Z"
          fill="url(#paint12_radial_8338_28151)"/>
    <path opacity="0.5"
          d="M10.1824 19.597C15.4737 19.597 19.7632 15.3533 19.7632 10.1183C19.7632 4.88339 15.4737 0.639648 10.1824 0.639648C4.89106 0.639648 0.601562 4.88339 0.601562 10.1183C0.601562 15.3533 4.89106 19.597 10.1824 19.597Z"
          fill="url(#paint13_radial_8338_28151)"/>
    <path
      d="M16.6163 0.00585938C16.0354 0.00585938 15.5205 0.296143 15.2151 0.734534C14.9097 0.296143 14.3947 0.00585938 13.8139 0.00585938C12.8737 0.00585938 12.1133 0.758234 12.1133 1.68834C12.1133 3.73808 15.2151 5.58053 15.2151 5.58053C15.2151 5.58053 18.3169 3.73808 18.3169 1.68834C18.3169 0.758234 17.5564 0.00585938 16.6163 0.00585938Z"
      fill="url(#paint14_radial_8338_28151)"/>
    <path opacity="0.5"
          d="M17.315 0.153809C18.2431 1.62299 17.3868 3.54835 16.3569 4.09339C15.2192 4.69766 14.4467 4.4133 12.8359 3.5069C13.7521 4.70951 15.2072 5.57443 15.2072 5.57443C15.2072 5.57443 18.309 3.73201 18.309 1.68224C18.315 1.00689 17.9078 0.420396 17.315 0.153809Z"
          fill="url(#paint15_radial_8338_28151)"/>
    <path opacity="0.5"
          d="M16.6163 0.00585938C16.0354 0.00585938 15.5205 0.296143 15.2151 0.734534C14.9097 0.296143 14.3947 0.00585938 13.8139 0.00585938C12.8737 0.00585938 12.1133 0.758234 12.1133 1.68834C12.1133 3.73808 15.2151 5.58053 15.2151 5.58053C15.2151 5.58053 18.3169 3.73808 18.3169 1.68834C18.3169 0.758234 17.5564 0.00585938 16.6163 0.00585938Z"
          fill="url(#paint16_radial_8338_28151)"/>
    <path opacity="0.5"
          d="M16.6163 0.00585938C16.0354 0.00585938 15.5205 0.296143 15.2151 0.734534C14.9097 0.296143 14.3947 0.00585938 13.8139 0.00585938C12.8737 0.00585938 12.1133 0.758234 12.1133 1.68834C12.1133 3.73808 15.2151 5.58053 15.2151 5.58053C15.2151 5.58053 18.3169 3.73808 18.3169 1.68834C18.3169 0.758234 17.5564 0.00585938 16.6163 0.00585938Z"
          fill="url(#paint17_radial_8338_28151)"/>
    <path opacity="0.24"
          d="M14.7905 0.686881C14.9582 1.08379 14.5809 1.62291 13.9462 1.88948C13.3115 2.15609 12.6528 2.05535 12.4851 1.65846C12.3175 1.26154 12.6947 0.722415 13.3295 0.455837C13.9642 0.18925 14.6228 0.289961 14.7905 0.686881Z"
          fill="url(#paint18_radial_8338_28151)"/>
    <path opacity="0.24"
          d="M16.8712 0.36743C17.1107 0.63402 16.9671 1.15534 16.5359 1.53451C16.1108 1.91364 15.5718 2.00844 15.3263 1.74183C15.0868 1.47526 15.2305 0.953925 15.6617 0.574777C16.0868 0.195629 16.6317 0.100843 16.8712 0.36743Z"
          fill="url(#paint19_radial_8338_28151)"/>
    <path opacity="0.32"
          d="M16.6452 0.450238C17.4954 0.740519 18.3098 1.9846 16.9386 3.53674C16.1122 4.46684 15.214 4.93483 14.0703 4.75121C14.6871 5.26066 15.214 5.57466 15.214 5.57466C15.214 5.57466 18.3158 3.73224 18.3158 1.68247C18.3158 0.752368 17.5553 0 16.6152 0C16.0344 0 15.5194 0.290284 15.214 0.728669C15.214 0.734609 16.0224 0.242891 16.6452 0.450238Z"
          fill="url(#paint20_linear_8338_28151)"/>
    <path
      d="M4.50298 11.9966C3.92215 11.9966 3.40717 12.2869 3.10179 12.7252C2.79641 12.2809 2.28144 11.9966 1.70061 11.9966C0.760486 11.9966 0 12.7489 0 13.6731C0 15.7229 3.10179 17.5653 3.10179 17.5653C3.10179 17.5653 6.2036 15.7229 6.2036 13.6731C6.2036 12.7489 5.4431 11.9966 4.50298 11.9966Z"
      fill="url(#paint21_radial_8338_28151)"/>
    <path opacity="0.5"
          d="M5.20559 12.1445C6.13374 13.6137 5.27746 15.5391 4.24752 16.0841C3.10981 16.6884 2.33734 16.404 0.726562 15.4976C1.64273 16.7002 3.09783 17.5652 3.09783 17.5652C3.09783 17.5652 6.1996 15.7227 6.1996 13.673C6.20561 12.9917 5.7984 12.4052 5.20559 12.1445Z"
          fill="url(#paint22_radial_8338_28151)"/>
    <path opacity="0.5"
          d="M4.50298 11.9966C3.92215 11.9966 3.40717 12.2869 3.10179 12.7252C2.79641 12.2809 2.28144 11.9966 1.70061 11.9966C0.760486 11.9966 0 12.7489 0 13.6731C0 15.7229 3.10179 17.5653 3.10179 17.5653C3.10179 17.5653 6.2036 15.7229 6.2036 13.6731C6.2036 12.7489 5.4431 11.9966 4.50298 11.9966Z"
          fill="url(#paint23_radial_8338_28151)"/>
    <path opacity="0.5"
          d="M4.50298 11.9966C3.92215 11.9966 3.40717 12.2869 3.10179 12.7252C2.79641 12.2809 2.28144 11.9966 1.70061 11.9966C0.760486 11.9966 0 12.7489 0 13.6731C0 15.7229 3.10179 17.5653 3.10179 17.5653C3.10179 17.5653 6.2036 15.7229 6.2036 13.6731C6.2036 12.7489 5.4431 11.9966 4.50298 11.9966Z"
          fill="url(#paint24_radial_8338_28151)"/>
    <path opacity="0.24"
          d="M2.67331 12.6777C2.84099 13.0747 2.46375 13.6138 1.829 13.8804C1.19429 14.147 0.535595 14.0462 0.367932 13.6493C0.200268 13.2524 0.577523 12.7133 1.21224 12.4467C1.84698 12.1742 2.50565 12.2809 2.67331 12.6777Z"
          fill="url(#paint25_radial_8338_28151)"/>
    <path opacity="0.24"
          d="M4.75669 12.3577C4.99621 12.6243 4.85251 13.1456 4.42138 13.5247C3.99622 13.9039 3.4573 13.9987 3.21181 13.7321C2.96628 13.4655 3.11599 12.9442 3.54711 12.565C3.97227 12.1859 4.51717 12.0911 4.75669 12.3577Z"
          fill="url(#paint26_radial_8338_28151)"/>
    <path opacity="0.32"
          d="M4.53189 12.4405C5.3822 12.7308 6.19658 13.9748 4.82532 15.527C3.99897 16.4571 3.10074 16.9251 1.95703 16.7414C2.57383 17.2509 3.10074 17.5649 3.10074 17.5649C3.10074 17.5649 6.20255 15.7225 6.20255 13.6727C6.20255 12.7426 5.44209 11.9902 4.50196 11.9902C3.92113 11.9902 3.40616 12.2805 3.10074 12.7189C3.10074 12.7248 3.90915 12.2272 4.53189 12.4405Z"
          fill="url(#paint27_linear_8338_28151)"/>
    <path
      d="M18.2999 14.4253C17.719 14.4253 17.2041 14.7156 16.8986 15.154C16.5933 14.7097 16.0783 14.4253 15.4975 14.4253C14.5573 14.4253 13.7969 15.1777 13.7969 16.1078C13.7969 18.1575 16.8986 19.9999 16.8986 19.9999C16.8986 19.9999 20.0005 18.1575 20.0005 16.1078C20.0005 15.1777 19.24 14.4253 18.2999 14.4253Z"
      fill="url(#paint28_radial_8338_28151)"/>
    <path opacity="0.5"
          d="M19.0064 14.5732C19.9345 16.0424 19.0782 17.9678 18.0483 18.5128C16.9106 19.1171 16.1381 18.8327 14.5273 17.9263C15.4435 19.1289 16.8986 19.9939 16.8986 19.9939C16.8986 19.9939 20.0004 18.1515 20.0004 16.1017C20.0004 15.4204 19.5932 14.8339 19.0064 14.5732Z"
          fill="url(#paint29_radial_8338_28151)"/>
    <path opacity="0.5"
          d="M18.2999 14.4253C17.719 14.4253 17.2041 14.7156 16.8986 15.154C16.5933 14.7097 16.0783 14.4253 15.4975 14.4253C14.5573 14.4253 13.7969 15.1777 13.7969 16.1078C13.7969 18.1575 16.8986 19.9999 16.8986 19.9999C16.8986 19.9999 20.0005 18.1575 20.0005 16.1078C20.0005 15.1777 19.24 14.4253 18.2999 14.4253Z"
          fill="url(#paint30_radial_8338_28151)"/>
    <path opacity="0.5"
          d="M18.2999 14.4253C17.719 14.4253 17.2041 14.7156 16.8986 15.154C16.5933 14.7097 16.0783 14.4253 15.4975 14.4253C14.5573 14.4253 13.7969 15.1777 13.7969 16.1078C13.7969 18.1575 16.8986 19.9999 16.8986 19.9999C16.8986 19.9999 20.0005 18.1575 20.0005 16.1078C20.0005 15.1777 19.24 14.4253 18.2999 14.4253Z"
          fill="url(#paint31_radial_8338_28151)"/>
    <path opacity="0.24"
          d="M16.4702 15.107C16.6379 15.5039 16.2606 16.043 15.6259 16.3096C14.9912 16.5762 14.3325 16.4755 14.1648 16.0785C13.9971 15.6816 14.3744 15.1425 15.0091 14.8759C15.6439 14.6034 16.3026 14.7101 16.4702 15.107Z"
          fill="url(#paint32_radial_8338_28151)"/>
    <path opacity="0.24"
          d="M18.5509 14.7869C18.7904 15.0534 18.6467 15.5748 18.2156 15.9539C17.7904 16.3331 17.2515 16.4279 17.006 16.1613C16.7665 15.8947 16.9102 15.3734 17.3413 14.9942C17.7665 14.6151 18.3114 14.5203 18.5509 14.7869Z"
          fill="url(#paint33_radial_8338_28151)"/>
    <path opacity="0.32"
          d="M18.3288 14.8697C19.179 15.1599 19.9934 16.404 18.6222 17.9562C17.7958 18.8863 16.8976 19.3543 15.7539 19.1706C16.3707 19.6801 16.8976 19.9941 16.8976 19.9941C16.8976 19.9941 19.9994 18.1517 19.9994 16.1019C19.9994 15.1718 19.2389 14.4194 18.2988 14.4194C17.718 14.4194 17.203 14.7097 16.8976 15.1481C16.8976 15.154 17.706 14.6564 18.3288 14.8697Z"
          fill="url(#paint34_linear_8338_28151)"/>
    <defs>
      <radialGradient id="paint0_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.29733 6.27897) scale(11.558 11.4348)">
        <stop stop-color="#FFE030"/>
        <stop offset="1" stop-color="#FFB92E"/>
      </radialGradient>
      <radialGradient id="paint1_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.29733 6.27897) scale(9.09321 8.99627)">
        <stop stop-color="#FFEA5F"/>
        <stop offset="1" stop-color="#FFBC47" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint2_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(4.96267 12.4784) rotate(-2.74081) scale(4.02982 3.15475)">
        <stop stop-color="#FF4C00"/>
        <stop offset="0.1542" stop-color="#FF4C00"/>
        <stop offset="0.1795" stop-color="#FF4C00"/>
        <stop offset="0.3996" stop-color="#FB4C0B" stop-opacity="0.7318"/>
        <stop offset="0.7799" stop-color="#EF4B27" stop-opacity="0.2683"/>
        <stop offset="1" stop-color="#E74A3A" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint3_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(15.3989 12.4784) rotate(-177.259) scale(4.02982 3.15475)">
        <stop stop-color="#FF4C00"/>
        <stop offset="0.1542" stop-color="#FF4C00"/>
        <stop offset="0.1795" stop-color="#FF4C00"/>
        <stop offset="0.3996" stop-color="#FB4C0B" stop-opacity="0.7318"/>
        <stop offset="0.7799" stop-color="#EF4B27" stop-opacity="0.2683"/>
        <stop offset="1" stop-color="#E74A3A" stop-opacity="0"/>
      </radialGradient>
      <linearGradient id="paint4_linear_8338_28151" x1="10.1786" y1="12.8726" x2="10.1786" y2="15.102"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <linearGradient id="paint5_linear_8338_28151" x1="6.00149" y1="7.02612" x2="6.13296" y2="6.03587"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#7A4400"/>
      </linearGradient>
      <linearGradient id="paint6_linear_8338_28151" x1="14.3589" y1="7.02624" x2="14.2274" y2="6.03599"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#7A4400"/>
      </linearGradient>
      <radialGradient id="paint7_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(13.9944 9.84992) rotate(9.87649) scale(1.7213 0.822177)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint8_linear_8338_28151" x1="14.2455" y1="8.4789" x2="14.0628" y2="9.56453"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <radialGradient id="paint9_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(6.38983 9.82383) rotate(170.123) scale(1.7213 0.822177)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <linearGradient id="paint10_linear_8338_28151" x1="6.09959" y1="8.46994" x2="6.28229" y2="9.55558"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="1" stop-color="#512D00"/>
      </linearGradient>
      <radialGradient id="paint11_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(16.2372 3.41411) rotate(-177.259) scale(4.02982 3.15475)">
        <stop stop-color="#FF4C00"/>
        <stop offset="0.1542" stop-color="#FF4C00"/>
        <stop offset="0.1795" stop-color="#FF4C00"/>
        <stop offset="0.3996" stop-color="#FB4C0B" stop-opacity="0.7318"/>
        <stop offset="0.7799" stop-color="#EF4B27" stop-opacity="0.2683"/>
        <stop offset="1" stop-color="#E74A3A" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint12_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(17.4947 18.2448) rotate(-177.259) scale(4.02982 3.15475)">
        <stop stop-color="#FF4C00"/>
        <stop offset="0.1542" stop-color="#FF4C00"/>
        <stop offset="0.1795" stop-color="#FF4C00"/>
        <stop offset="0.3996" stop-color="#FB4C0B" stop-opacity="0.7318"/>
        <stop offset="0.7799" stop-color="#EF4B27" stop-opacity="0.2683"/>
        <stop offset="1" stop-color="#E74A3A" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint13_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(2.43535 16.0924) rotate(-177.259) scale(4.02982 3.15475)">
        <stop stop-color="#FF4C00"/>
        <stop offset="0.1542" stop-color="#FF4C00"/>
        <stop offset="0.1795" stop-color="#FF4C00"/>
        <stop offset="0.3996" stop-color="#FB4C0B" stop-opacity="0.7318"/>
        <stop offset="0.7799" stop-color="#EF4B27" stop-opacity="0.2683"/>
        <stop offset="1" stop-color="#E74A3A" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint14_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(14.3271 1.5615) rotate(-29.146) scale(4.8409 3.81867)">
        <stop offset="0.2479" stop-color="#FF0000"/>
        <stop offset="0.8639" stop-color="#C20000"/>
      </radialGradient>
      <radialGradient id="paint15_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(14.4452 1.26681) rotate(-29.146) scale(4.16786 3.28774)">
        <stop offset="0.2479" stop-color="#FF0000"/>
        <stop offset="1" stop-color="#C20000"/>
      </radialGradient>
      <radialGradient id="paint16_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(14.3271 1.5615) rotate(-29.146) scale(4.8409 3.81867)">
        <stop stop-color="white" stop-opacity="0.25"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint17_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(16.0718 3.19075) rotate(-26.0529) scale(3.53536 1.73557)">
        <stop stop-color="#BD2719" stop-opacity="0.25"/>
        <stop offset="1" stop-color="#BD2719" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint18_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(13.6372 1.17398) rotate(-21.4807) scale(1.25762 0.77513)">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint19_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(16.0974 1.06017) rotate(-40.46) scale(1.03802 0.643794)">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </radialGradient>
      <linearGradient id="paint20_linear_8338_28151" x1="15.8537" y1="7.77077" x2="16.442" y2="-0.246819"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#860805"/>
        <stop offset="1" stop-color="#BD2719" stop-opacity="0"/>
      </linearGradient>
      <radialGradient id="paint21_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(2.21439 13.5489) rotate(-29.146) scale(4.8409 3.81867)">
        <stop offset="0.2479" stop-color="#FF0000"/>
        <stop offset="0.8639" stop-color="#C20000"/>
      </radialGradient>
      <radialGradient id="paint22_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(2.33642 13.2542) rotate(-29.146) scale(4.16786 3.28774)">
        <stop offset="0.2479" stop-color="#FF0000"/>
        <stop offset="1" stop-color="#C20000"/>
      </radialGradient>
      <radialGradient id="paint23_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(2.21439 13.5489) rotate(-29.146) scale(4.8409 3.81867)">
        <stop stop-color="white" stop-opacity="0.25"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint24_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(3.9593 15.179) rotate(-26.0529) scale(3.53536 1.73557)">
        <stop stop-color="#BD2719" stop-opacity="0.25"/>
        <stop offset="1" stop-color="#BD2719" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint25_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(1.51972 13.1614) rotate(-21.4807) scale(1.25762 0.77513)">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint26_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(3.98302 13.0481) rotate(-40.4598) scale(1.03802 0.643794)">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </radialGradient>
      <linearGradient id="paint27_linear_8338_28151" x1="3.74068" y1="19.7582" x2="4.32892" y2="11.7406"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#860805"/>
        <stop offset="1" stop-color="#BD2719" stop-opacity="0"/>
      </linearGradient>
      <radialGradient id="paint28_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(16.0111 15.9783) rotate(-29.146) scale(4.8409 3.81867)">
        <stop offset="0.2479" stop-color="#FF0000"/>
        <stop offset="0.8639" stop-color="#C20000"/>
      </radialGradient>
      <radialGradient id="paint29_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(16.131 15.6836) rotate(-29.146) scale(4.16786 3.28774)">
        <stop offset="0.2479" stop-color="#FF0000"/>
        <stop offset="1" stop-color="#C20000"/>
      </radialGradient>
      <radialGradient id="paint30_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(16.0111 15.9783) rotate(-29.146) scale(4.8409 3.81867)">
        <stop stop-color="white" stop-opacity="0.25"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint31_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(17.7561 17.6075) rotate(-26.0529) scale(3.53536 1.73557)">
        <stop stop-color="#BD2719" stop-opacity="0.25"/>
        <stop offset="1" stop-color="#BD2719" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint32_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(15.3171 15.591) rotate(-21.4806) scale(1.25762 0.77513)">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint33_radial_8338_28151" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(17.7771 15.4775) rotate(-40.46) scale(1.03802 0.643795)">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </radialGradient>
      <linearGradient id="paint34_linear_8338_28151" x1="17.5378" y1="22.1874" x2="18.1261" y2="14.1698"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#860805"/>
        <stop offset="1" stop-color="#BD2719" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </SvgIcon>
)
export const TenPointRating = () => (
  <SvgIcon width="20" height="18" viewBox="0 0 20 18">
    <path
      d="M9.99709 18C14.8302 18 18.7481 13.9706 18.7481 9C18.7481 4.0294 14.8302 0 9.99709 0C5.16409 0 1.24609 4.0294 1.24609 9C1.24609 13.9706 5.16409 18 9.99709 18Z"
      fill="url(#paint0_radial_8361_28318)"/>
    <path opacity="0.5"
          d="M9.99709 18C14.8302 18 18.7481 13.9706 18.7481 9C18.7481 4.0294 14.8302 0 9.99709 0C5.16409 0 1.24609 4.0294 1.24609 9C1.24609 13.9706 5.16409 18 9.99709 18Z"
          fill="url(#paint1_radial_8361_28318)"/>
    <path
      d="M16.0272 11.9927C16.0327 11.942 16.0381 11.8914 16.0436 11.8464C16.0327 11.6833 15.9342 11.5314 15.7756 11.4752C15.6935 11.4414 15.606 11.4414 15.5185 11.4639C13.73 11.9758 11.8704 12.2402 9.99451 12.2402C8.11841 12.2402 6.25891 11.9814 4.47041 11.4639C4.38831 11.4414 4.29541 11.4414 4.21331 11.4752C4.06021 11.5371 3.96171 11.6777 3.94531 11.8408C3.95081 11.897 3.95631 11.9533 3.96721 12.0152L4.06571 12.437C4.08751 12.5158 4.11491 12.6002 4.14771 12.6902C4.76031 14.1639 6.35731 16.757 9.99991 16.757C13.6371 16.757 15.2341 14.1583 15.8522 12.6845C15.8795 12.6058 15.9069 12.5327 15.9233 12.4595L16.0272 11.9927Z"
      fill="url(#paint2_radial_8361_28318)"/>
    <path
      d="M9.99717 16.3458C11.4083 16.3458 12.4913 15.9295 13.3172 15.3389C12.4092 14.9058 11.3154 14.6245 9.99717 14.6245C8.67907 14.6245 7.57977 14.9058 6.67188 15.3445C7.50318 15.9351 8.58607 16.3458 9.99717 16.3458Z"
      fill="url(#paint3_radial_8361_28318)"/>
    <path
      d="M15.9287 12.4427L16.0272 12.0096C16.0381 11.9477 16.0436 11.8914 16.0436 11.8408C16.0326 11.6777 15.9287 11.5371 15.7756 11.4752C15.6935 11.4414 15.606 11.4414 15.5185 11.4639C13.73 11.9758 11.8704 12.2402 9.99441 12.2402C8.11841 12.2402 6.25881 11.9814 4.47041 11.4639C4.38831 11.4414 4.29531 11.4414 4.21331 11.4752C4.06021 11.5371 3.96171 11.6777 3.94531 11.8408C3.95081 11.8914 3.95631 11.9533 3.96171 12.0152L4.06021 12.4427C4.08211 12.5214 4.10941 12.6058 4.14221 12.6902C4.38831 13.2864 4.79851 14.0627 5.43301 14.777C4.83681 14.0458 5.56971 13.6464 6.88781 13.8095C8.20051 13.9727 9.99441 13.9783 9.99441 13.9783C9.99441 13.9783 11.7884 13.9727 13.101 13.8095C14.4137 13.6464 15.1521 14.0514 14.5559 14.777C15.1958 14.0627 15.6005 13.2864 15.8467 12.6902C15.8795 12.6002 15.9068 12.5214 15.9287 12.4427Z"
      fill="url(#paint4_linear_8361_28318)"/>
    <path
      d="M9.99517 12.6507C8.10277 12.6507 6.23228 12.392 4.42188 11.8745L4.44378 12.1276C4.48748 12.6114 4.83207 13.0108 5.29147 13.1176C6.82837 13.4664 8.40357 13.6407 9.98967 13.6407C11.7016 13.6407 13.3971 13.4383 15.0489 13.0333C15.295 12.9714 15.4755 12.7576 15.5028 12.4989L15.563 11.8801C13.7581 12.392 11.8876 12.6507 9.99517 12.6507Z"
      fill="url(#paint5_radial_8361_28318)"/>
    <path
      d="M9.99709 18C14.8302 18 18.7481 13.9706 18.7481 9C18.7481 4.0294 14.8302 0 9.99709 0C5.16409 0 1.24609 4.0294 1.24609 9C1.24609 13.9706 5.16409 18 9.99709 18Z"
      fill="url(#paint6_radial_8361_28318)"/>
    <path
      d="M9.99709 18C14.8302 18 18.7481 13.9706 18.7481 9C18.7481 4.0294 14.8302 0 9.99709 0C5.16409 0 1.24609 4.0294 1.24609 9C1.24609 13.9706 5.16409 18 9.99709 18Z"
      fill="url(#paint7_radial_8361_28318)"/>
    <path opacity="0.24"
          d="M17.0754 7.94823C17.1574 7.93693 17.234 7.93133 17.3106 7.92573C17.3106 7.93133 17.3051 7.94263 17.3051 7.95383L17.2832 10.9913C17.2832 11.2388 17.0098 11.3738 16.8183 11.2219L14.4884 9.36013C14.4556 9.33193 14.4118 9.32633 14.3735 9.33763L11.5568 10.2544C11.3271 10.3276 11.1192 10.1026 11.2013 9.87193L12.1748 7.09883C12.2076 7.00883 12.1912 6.90763 12.1365 6.82883L10.4684 4.43263C10.4137 4.35383 10.4027 4.25823 10.4246 4.17383L10.7036 4.20193C13.307 4.23573 13.3781 8.41513 17.0754 7.94823Z"
          fill="url(#paint8_radial_8361_28318)"/>
    <path
      d="M15.5104 1.55278L13.7547 4.03898C13.7328 4.07278 13.6891 4.09528 13.6508 4.08958L10.6645 4.01088C10.4238 4.00528 10.2817 4.28648 10.4238 4.48338L12.1303 6.89648C12.185 6.97528 12.2014 7.07648 12.1686 7.17208L11.2005 9.98458C11.1185 10.2209 11.3372 10.4459 11.567 10.3672L14.4056 9.41648C14.4438 9.40528 14.4876 9.41088 14.5204 9.43898L16.8887 11.3065C17.0801 11.4584 17.3536 11.3178 17.3536 11.0703V7.99898C17.3536 7.95398 17.3754 7.91459 17.4082 7.89209L19.8695 6.15398C20.0664 6.01338 20.0226 5.69838 19.7929 5.62528L16.9543 4.67468C16.916 4.66338 16.8832 4.62958 16.8723 4.59028L16.0245 1.64278C15.9589 1.40648 15.6526 1.35028 15.5104 1.55278Z"
      fill="url(#paint9_radial_8361_28318)"/>
    <path
      d="M18.1907 6.80593C18.8306 6.33903 19.7112 5.83843 19.5909 5.55713L19.7987 5.62463C20.0284 5.70343 20.0722 6.01283 19.8753 6.15343L17.4141 7.89153C17.3813 7.91403 17.3594 7.95343 17.3594 7.99843C17.3703 7.45843 17.5508 7.26713 18.1907 6.80593Z"
      fill="url(#paint10_radial_8361_28318)"/>
    <path
      d="M16.0245 1.64278L16.8723 4.58458C16.8832 4.62398 16.916 4.65778 16.9543 4.66898L19.7984 5.61968C20.0281 5.69838 20.0718 6.00778 19.8749 6.14838L17.4848 7.84148C17.4082 7.89778 17.359 7.98778 17.359 8.08898V11.0703C17.359 11.3178 17.0855 11.4584 16.8941 11.3065L14.5259 9.43898C14.4931 9.41088 14.4493 9.40528 14.411 9.41648L11.5724 10.3672C11.3427 10.4459 11.1294 10.2153 11.206 9.98458L12.1959 7.08778C12.2123 7.04838 12.2014 7.00338 12.1795 6.96958L10.4238 4.48338C10.2817 4.28088 10.4238 4.00528 10.6645 4.01088L13.6508 4.08958C13.6945 4.08958 13.7328 4.07278 13.7547 4.03898L15.5104 1.55278C15.6526 1.35028 15.9589 1.40648 16.0245 1.64278Z"
      fill="url(#paint11_radial_8361_28318)"/>
    <path
      d="M16.0245 1.64278L16.8723 4.58458C16.8832 4.62398 16.916 4.65778 16.9543 4.66898L19.7984 5.61968C20.0281 5.69838 20.0718 6.00778 19.8749 6.14838L17.4848 7.84148C17.4082 7.89778 17.359 7.98778 17.359 8.08898V11.0703C17.359 11.3178 17.0855 11.4584 16.8941 11.3065L14.5259 9.43898C14.4931 9.41088 14.4493 9.40528 14.411 9.41648L11.5724 10.3672C11.3427 10.4459 11.1294 10.2153 11.206 9.98458L12.1959 7.08778C12.2123 7.04838 12.2014 7.00338 12.1795 6.96958L10.4238 4.48338C10.2817 4.28088 10.4238 4.00528 10.6645 4.01088L13.6508 4.08958C13.6945 4.08958 13.7328 4.07278 13.7547 4.03898L15.5104 1.55278C15.6526 1.35028 15.9589 1.40648 16.0245 1.64278Z"
      fill="url(#paint12_radial_8361_28318)"/>
    <path opacity="0.24"
          d="M16.0245 1.64278L16.8723 4.58458C16.8832 4.62398 16.916 4.65778 16.9543 4.66898L19.7984 5.61968C20.0281 5.69838 20.0718 6.00778 19.8749 6.14838L17.4848 7.84148C17.4082 7.89778 17.359 7.98778 17.359 8.08898V11.0703C17.359 11.3178 17.0855 11.4584 16.8941 11.3065L14.5259 9.43898C14.4931 9.41088 14.4493 9.40528 14.411 9.41648L11.5724 10.3672C11.3427 10.4459 11.1294 10.2153 11.206 9.98458L12.1959 7.08778C12.2123 7.04838 12.2014 7.00338 12.1795 6.96958L10.4238 4.48338C10.2817 4.28088 10.4238 4.00528 10.6645 4.01088L13.6508 4.08958C13.6945 4.08958 13.7328 4.07278 13.7547 4.03898L15.5104 1.55278C15.6526 1.35028 15.9589 1.40648 16.0245 1.64278Z"
          fill="url(#paint13_radial_8361_28318)"/>
    <path opacity="0.24"
          d="M16.0245 1.64278L16.8723 4.58458C16.8832 4.62398 16.916 4.65778 16.9543 4.66898L19.7984 5.61968C20.0281 5.69838 20.0718 6.00778 19.8749 6.14838L17.4848 7.84148C17.4082 7.89778 17.359 7.98778 17.359 8.08898V11.0703C17.359 11.3178 17.0855 11.4584 16.8941 11.3065L14.5259 9.43898C14.4931 9.41088 14.4493 9.40528 14.411 9.41648L11.5724 10.3672C11.3427 10.4459 11.1294 10.2153 11.206 9.98458L12.1959 7.08778C12.2123 7.04838 12.2014 7.00338 12.1795 6.96958L10.4238 4.48338C10.2817 4.28088 10.4238 4.00528 10.6645 4.01088L13.6508 4.08958C13.6945 4.08958 13.7328 4.07278 13.7547 4.03898L15.5104 1.55278C15.6526 1.35028 15.9589 1.40648 16.0245 1.64278Z"
          fill="url(#paint14_radial_8361_28318)"/>
    <path
      d="M19.7984 5.61938L16.9544 4.67438C16.9161 4.66318 16.8833 4.62938 16.8723 4.59008L16.0246 1.64258C16.0246 1.64258 15.8824 1.77198 16.0519 2.39628C16.1668 2.80128 16.4512 3.92068 16.6153 4.50008C16.7082 4.82628 16.9216 4.87128 17.2388 4.97258L19.339 5.63628C19.339 5.63628 19.5742 5.69818 19.7984 5.61938Z"
      fill="url(#paint15_radial_8361_28318)"/>
    <path
      d="M11.2005 9.98458C11.1185 10.2209 11.3372 10.4459 11.567 10.3672L14.4056 9.41648C14.4438 9.40528 14.4876 9.41088 14.5204 9.43898L16.8887 11.3065C17.0801 11.4584 17.3536 11.3178 17.3536 11.0703V7.99898C17.3536 7.95398 17.3754 7.91459 17.4082 7.89209L19.8695 6.15398C20.0664 6.01338 20.0226 5.69838 19.7929 5.62528L16.9543 4.67468C16.916 4.66338 16.8832 4.62958 16.8723 4.59028L16.0245 1.64278C15.9534 1.40648 15.6526 1.35028 15.5104 1.55278L13.7547 4.03898C13.7328 4.07278 13.6891 4.09528 13.6508 4.08958L10.6645 4.01088C10.4238 4.00528 10.2817 4.28648 10.4238 4.48338L12.1303 6.89648C12.185 6.97528 12.2014 7.07648 12.1686 7.17208L11.2005 9.98458ZM11.3536 10.0409L12.3217 7.22838C12.371 7.08218 12.3491 6.92458 12.2616 6.79528L10.5551 4.38778C10.5114 4.33148 10.5332 4.26968 10.5442 4.25278C10.5551 4.23028 10.5879 4.18528 10.6536 4.18528C10.6536 4.18528 10.6536 4.18528 10.659 4.18528L13.6453 4.26398C13.7383 4.26968 13.8313 4.21898 13.886 4.14028L15.6416 1.65398C15.6744 1.60898 15.7127 1.59778 15.7401 1.59778C15.751 1.59778 15.7565 1.59778 15.762 1.59778C15.7838 1.60338 15.844 1.62028 15.8604 1.68778L16.7082 4.62958C16.7355 4.72528 16.8066 4.79838 16.8941 4.82648L19.7327 5.77708C19.7984 5.79958 19.8148 5.85588 19.8202 5.88398C19.8257 5.90648 19.8257 5.96838 19.7656 6.00778L17.3043 7.74588C17.2278 7.80218 17.1785 7.89208 17.1785 7.99338V11.0647C17.1785 11.1378 17.1293 11.1715 17.1074 11.1828C17.0855 11.194 17.0308 11.2165 16.9762 11.1715L14.6079 9.30398C14.5314 9.24218 14.4329 9.22528 14.3399 9.25898L11.5013 10.2096C11.4357 10.2321 11.3865 10.1928 11.3701 10.1759C11.3701 10.159 11.3318 10.1084 11.3536 10.0409Z"
      fill="#333333"/>
    <path opacity="0.24"
          d="M2.91879 7.94797C2.83679 7.93667 2.76019 7.93107 2.68359 7.92547C2.68359 7.93107 2.68909 7.94237 2.68909 7.95357L2.71099 10.9911C2.71099 11.2386 2.98439 11.3736 3.17579 11.2217L5.50579 9.35987C5.53859 9.33167 5.58239 9.32607 5.62069 9.33737L8.43739 10.2542C8.66709 10.3274 8.87489 10.1023 8.79289 9.87167L7.81929 7.09297C7.78649 7.00297 7.80289 6.90167 7.85759 6.82297L9.52579 4.42667C9.58049 4.34797 9.59139 4.25227 9.56959 4.16797L9.29059 4.19607C6.68719 4.23547 6.61609 8.41487 2.91879 7.94797Z"
          fill="url(#paint16_radial_8361_28318)"/>
    <path
      d="M4.48388 1.55283L6.23958 4.03913C6.26148 4.07283 6.30528 4.09533 6.34348 4.08973L9.32978 4.01103C9.57048 4.00543 9.71268 4.28663 9.57048 4.48353L7.86398 6.89663C7.80928 6.97533 7.79288 7.07663 7.82568 7.17223L8.79378 9.98473C8.87588 10.221 8.65708 10.446 8.42738 10.3672L5.58878 9.41663C5.55048 9.40543 5.50668 9.41103 5.47388 9.43913L3.10568 11.3066C2.91428 11.4585 2.64078 11.3178 2.64078 11.0704V7.99913C2.64078 7.95413 2.61888 7.91473 2.58608 7.89223L0.124881 6.15413C-0.0720187 6.01353 -0.0283187 5.69853 0.201381 5.62543L3.03998 4.67473C3.07828 4.66353 3.11108 4.62973 3.12208 4.59033L3.96978 1.64853C4.04088 1.40663 4.34178 1.35033 4.48388 1.55283Z"
      fill="url(#paint17_radial_8361_28318)"/>
    <path
      d="M1.80938 6.80593C1.16948 6.33903 0.28888 5.83843 0.40928 5.55713L0.201381 5.62463C-0.0283187 5.70343 -0.0720187 6.01283 0.124881 6.15343L2.58608 7.89153C2.61888 7.91403 2.64078 7.95343 2.64078 7.99843C2.62978 7.45843 2.44388 7.26713 1.80938 6.80593Z"
      fill="url(#paint18_radial_8361_28318)"/>
    <path
      d="M8.19587 5.67534C8.64987 5.01724 9.31157 4.24104 9.10927 4.01604L9.32807 4.01034C9.56867 4.00474 9.71087 4.28604 9.56867 4.48284L7.81847 6.96914C7.79657 7.00284 7.78567 7.04784 7.80207 7.08724C7.63257 6.57534 7.74197 6.33354 8.19587 5.67534Z"
      fill="url(#paint19_radial_8361_28318)"/>
    <path
      d="M9.3291 4.01045L6.3428 4.08915C6.299 4.08915 6.2607 4.07225 6.2389 4.03855L4.4832 1.55225C4.4832 1.55225 4.3902 1.72665 4.7567 2.24975C4.9919 2.59295 5.6263 3.54915 5.9709 4.03855C6.1623 4.31975 6.3756 4.28605 6.7092 4.26915L8.9079 4.17355C8.9025 4.17915 9.1486 4.15665 9.3291 4.01045Z"
      fill="url(#paint20_radial_8361_28318)"/>
    <path
      d="M3.96978 1.64278L3.12208 4.58458C3.11108 4.62398 3.07828 4.65778 3.03998 4.66898L0.201381 5.61968C-0.0283187 5.69838 -0.0720187 6.00778 0.124881 6.14838L2.51498 7.84148C2.59148 7.89778 2.64078 7.98778 2.64078 8.08898V11.0703C2.64078 11.3178 2.91428 11.4584 3.10568 11.3065L5.47388 9.43898C5.50668 9.41088 5.55048 9.40528 5.58878 9.41648L8.42738 10.3672C8.65708 10.4459 8.87038 10.2153 8.79378 9.98458L7.79838 7.08778C7.78198 7.04838 7.79288 7.00338 7.81478 6.96958L9.57048 4.48338C9.71268 4.28088 9.57048 4.00528 9.32978 4.01088L6.34348 4.08958C6.29978 4.08958 6.26148 4.07278 6.23958 4.03898L4.48388 1.55278C4.34178 1.35028 4.04088 1.40648 3.96978 1.64278Z"
      fill="url(#paint21_radial_8361_28318)"/>
    <path
      d="M3.96978 1.64278L3.12208 4.58458C3.11108 4.62398 3.07828 4.65778 3.03998 4.66898L0.201381 5.61968C-0.0283187 5.69838 -0.0720187 6.00778 0.124881 6.14838L2.51498 7.84148C2.59148 7.89778 2.64078 7.98778 2.64078 8.08898V11.0703C2.64078 11.3178 2.91428 11.4584 3.10568 11.3065L5.47388 9.43898C5.50668 9.41088 5.55048 9.40528 5.58878 9.41648L8.42738 10.3672C8.65708 10.4459 8.87038 10.2153 8.79378 9.98458L7.79838 7.08778C7.78198 7.04838 7.79288 7.00338 7.81478 6.96958L9.57048 4.48338C9.71268 4.28088 9.57048 4.00528 9.32978 4.01088L6.34348 4.08958C6.29978 4.08958 6.26148 4.07278 6.23958 4.03898L4.48388 1.55278C4.34178 1.35028 4.04088 1.40648 3.96978 1.64278Z"
      fill="url(#paint22_radial_8361_28318)"/>
    <path opacity="0.24"
          d="M3.96978 1.64278L3.12208 4.58458C3.11108 4.62398 3.07828 4.65778 3.03998 4.66898L0.201381 5.61968C-0.0283187 5.69838 -0.0720187 6.00778 0.124881 6.14838L2.51498 7.84148C2.59148 7.89778 2.64078 7.98778 2.64078 8.08898V11.0703C2.64078 11.3178 2.91428 11.4584 3.10568 11.3065L5.47388 9.43898C5.50668 9.41088 5.55048 9.40528 5.58878 9.41648L8.42738 10.3672C8.65708 10.4459 8.87038 10.2153 8.79378 9.98458L7.79838 7.08778C7.78198 7.04838 7.79288 7.00338 7.81478 6.96958L9.57048 4.48338C9.71268 4.28088 9.57048 4.00528 9.32978 4.01088L6.34348 4.08958C6.29978 4.08958 6.26148 4.07278 6.23958 4.03898L4.48388 1.55278C4.34178 1.35028 4.04088 1.40648 3.96978 1.64278Z"
          fill="url(#paint23_radial_8361_28318)"/>
    <path
      d="M0.203125 5.61938L3.04172 4.66878C3.08002 4.65758 3.11282 4.62378 3.12382 4.58448L3.97153 1.64258C3.97153 1.64258 4.11372 1.77198 3.94422 2.39628C3.82932 2.80128 3.54492 3.92068 3.38082 4.50008C3.28792 4.82628 3.07453 4.87128 2.75733 4.97258L0.657125 5.63628C0.657125 5.63628 0.421925 5.69818 0.203125 5.61938Z"
      fill="url(#paint24_radial_8361_28318)"/>
    <path
      d="M11.8045 5.67534C11.3505 5.01724 10.6887 4.24104 10.8911 4.01604L10.6723 4.01034C10.4317 4.00474 10.2895 4.28604 10.4317 4.48284L12.1873 6.96914C12.2092 7.00284 12.2202 7.04784 12.2037 7.08724C12.3623 6.57534 12.253 6.33354 11.8045 5.67534Z"
      fill="url(#paint25_radial_8361_28318)"/>
    <path
      d="M6.07527 4.6123C6.43077 5.2311 5.84008 6.2604 4.76258 6.9073C3.68508 7.5542 2.52557 7.5823 2.17557 6.9636C1.82557 6.3448 2.41078 5.3154 3.48828 4.6685C4.56568 4.0217 5.71977 3.9992 6.07527 4.6123Z"
      fill="url(#paint26_radial_8361_28318)"/>
    <path
      d="M16.3956 4.30879C16.7511 4.92759 16.1604 5.957 15.0829 6.6038C14.0054 7.2507 12.8459 7.27879 12.4959 6.66009C12.1459 6.04129 12.7311 5.01199 13.8086 4.36509C14.886 3.71819 16.0401 3.69009 16.3956 4.30879Z"
      fill="url(#paint27_radial_8361_28318)"/>
    <path
      d="M7.83118 7.17208C7.79838 7.08218 7.81478 6.97528 7.86948 6.89648L9.57048 4.48338C9.71268 4.28088 9.57048 4.00528 9.32978 4.01088L6.34348 4.08958C6.29978 4.08958 6.26148 4.07278 6.23958 4.03898L4.48388 1.55278C4.34178 1.35028 4.04088 1.40648 3.96978 1.64278L3.12208 4.58458C3.11108 4.62398 3.07828 4.65778 3.03998 4.66898L0.201381 5.61968C-0.0283187 5.69838 -0.0720187 6.00778 0.124881 6.14838L2.58608 7.88649C2.61888 7.90899 2.64078 7.94838 2.64078 7.99338V11.0647C2.64078 11.3122 2.91428 11.4528 3.10568 11.3009L5.47388 9.43338C5.50668 9.40528 5.55048 9.39968 5.58878 9.41088L8.42738 10.3615C8.65708 10.4403 8.87038 10.2096 8.79378 9.97898L7.83118 7.17208ZM8.61328 10.1759C8.59688 10.1928 8.55318 10.2321 8.48208 10.2096L5.64348 9.25898C5.55048 9.23088 5.45198 9.24778 5.37548 9.30398L3.00718 11.1771C2.95248 11.2221 2.89778 11.1996 2.87588 11.1884C2.85408 11.1771 2.80488 11.1434 2.80488 11.0703V7.99898C2.80488 7.90338 2.75558 7.80778 2.67908 7.75148L0.217781 6.01338C0.157681 5.97398 0.16308 5.91218 0.16308 5.88968C0.16858 5.86718 0.17958 5.80528 0.25068 5.78278L3.08928 4.83208C3.18218 4.80398 3.24788 4.72528 3.27518 4.63528L4.12298 1.69338C4.14488 1.62588 4.19948 1.60898 4.22138 1.60338C4.22688 1.60338 4.23238 1.60338 4.24328 1.60338C4.27068 1.60338 4.31438 1.61468 4.34178 1.65968L6.09738 4.14588C6.15208 4.22468 6.24508 4.26968 6.33808 4.26968L9.32438 4.19088C9.32438 4.19088 9.32438 4.19088 9.32978 4.19088C9.39548 4.19088 9.42828 4.24148 9.43918 4.25838C9.45018 4.28088 9.47198 4.33708 9.42828 4.39338L7.72178 6.80648C7.63428 6.93028 7.61238 7.09338 7.66168 7.23958L8.62968 10.0521C8.66258 10.1084 8.62968 10.159 8.61328 10.1759Z"
      fill="#333333"/>
    <defs>
      <radialGradient id="paint0_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.27639 5.3526) scale(10.557 10.8573)">
        <stop stop-color="#FFE030"/>
        <stop offset="1" stop-color="#FFB92E"/>
      </radialGradient>
      <radialGradient id="paint1_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.27639 5.3526) scale(8.30562 8.54194)">
        <stop stop-color="#FFEA5F"/>
        <stop offset="1" stop-color="#FFBC47" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint2_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(9.99471 14.1038) scale(4.64978 4.78208)">
        <stop offset="0.00132565" stop-color="#7A4400"/>
        <stop offset="1" stop-color="#643800"/>
      </radialGradient>
      <radialGradient id="paint3_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(10.065 15.693) scale(3.69951 1.15041)">
        <stop offset="0.2479" stop-color="#FF0000"/>
        <stop offset="1" stop-color="#C20000"/>
      </radialGradient>
      <linearGradient id="paint4_linear_8361_28318" x1="15.9815" y1="13.1145" x2="4.09311" y2="13.1145"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stop-color="#3C2200"/>
        <stop offset="0.5" stop-color="#512D00"/>
        <stop offset="1" stop-color="#3C2200"/>
      </linearGradient>
      <radialGradient id="paint5_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(9.99577 12.7572) scale(10.1984 10.4885)">
        <stop offset="0.00132565" stop-color="white"/>
        <stop offset="1" stop-color="#A9BCBE"/>
      </radialGradient>
      <radialGradient id="paint6_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(18.0457 8.6875) rotate(34.9802) scale(5.01124 5.09242)">
        <stop stop-color="#FF8000" stop-opacity="0.5"/>
        <stop offset="0.995" stop-color="#FF8000" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint7_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(1.95139 8.6892) rotate(145.02) scale(5.01124 5.09241)">
        <stop stop-color="#FF8000" stop-opacity="0.5"/>
        <stop offset="0.995" stop-color="#FF8000" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint8_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(14.1616 7.43043) rotate(180) scale(7.36141 7.57087)">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint9_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(15.4101 5.20648) rotate(-169.995) scale(4.77397 4.90172)">
        <stop stop-color="#FFEA5F"/>
        <stop offset="0.4904" stop-color="#FFE95D"/>
        <stop offset="0.667" stop-color="#FFE756"/>
        <stop offset="0.793" stop-color="#FFE34B"/>
        <stop offset="0.8947" stop-color="#FEDD3A"/>
        <stop offset="0.9809" stop-color="#FED524"/>
        <stop offset="1" stop-color="#FED31E"/>
      </radialGradient>
      <radialGradient id="paint10_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(18.5626 7.72793) rotate(-129.989) scale(0.896677 2.40355)">
        <stop stop-color="#D86D00"/>
        <stop offset="0.3292" stop-color="#DC6C0A" stop-opacity="0.6708"/>
        <stop offset="0.8792" stop-color="#E86823" stop-opacity="0.1208"/>
        <stop offset="1" stop-color="#EB672A" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint11_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(13.0595 10.7421) rotate(-17.0259) scale(2.62885 1.2752)">
        <stop stop-color="#D86D00"/>
        <stop offset="0.3292" stop-color="#DC6C0A" stop-opacity="0.6708"/>
        <stop offset="0.8792" stop-color="#E86823" stop-opacity="0.1208"/>
        <stop offset="1" stop-color="#EB672A" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint12_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(13.9494 12.3701) rotate(12.8529) scale(3.47208 3.65094)">
        <stop stop-color="#D86D00"/>
        <stop offset="0.3292" stop-color="#DC6C0A" stop-opacity="0.6708"/>
        <stop offset="0.8792" stop-color="#E86823" stop-opacity="0.1208"/>
        <stop offset="1" stop-color="#EB672A" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint13_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(13.4778 8.25848) rotate(32.0036) scale(3.69468 2.82806)">
        <stop stop-color="#FFA754"/>
        <stop offset="0.3697" stop-color="#FFB152" stop-opacity="0.6303"/>
        <stop offset="0.9874" stop-color="#FFCA4B" stop-opacity="0.0126062"/>
        <stop offset="1" stop-color="#FFCB4B" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint14_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(15.5766 5.11068) rotate(32.0036) scale(2.22608 1.70393)">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint15_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(18.6129 2.94908) rotate(57.2962) scale(3.52373 2.39767)">
        <stop stop-color="#ED9900"/>
        <stop offset="1" stop-color="#ED9900" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint16_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(5.83469 7.43017) scale(7.36141 7.57087)">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint17_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(4.58688 5.20673) rotate(-10.0058) scale(4.77397 4.90172)">
        <stop stop-color="#FFEA5F"/>
        <stop offset="0.4904" stop-color="#FFE95D"/>
        <stop offset="0.667" stop-color="#FFE756"/>
        <stop offset="0.793" stop-color="#FFE34B"/>
        <stop offset="0.8947" stop-color="#FEDD3A"/>
        <stop offset="0.9809" stop-color="#FED524"/>
        <stop offset="1" stop-color="#FED31E"/>
      </radialGradient>
      <radialGradient id="paint18_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(1.43478 7.72753) rotate(-50.0109) scale(0.89668 2.40355)">
        <stop stop-color="#D86D00"/>
        <stop offset="0.3292" stop-color="#DC6C0A" stop-opacity="0.6708"/>
        <stop offset="0.8792" stop-color="#E86823" stop-opacity="0.1208"/>
        <stop offset="1" stop-color="#EB672A" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint19_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(8.77437 6.57734) rotate(-149.554) scale(0.888491 2.4258)">
        <stop stop-color="#D86D00"/>
        <stop offset="0.3292" stop-color="#DC6C0A" stop-opacity="0.6708"/>
        <stop offset="0.8792" stop-color="#E86823" stop-opacity="0.1208"/>
        <stop offset="1" stop-color="#EB672A" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint20_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(7.3472 1.90035) rotate(37.8709) scale(3.49054 2.42007)">
        <stop stop-color="#ED9900"/>
        <stop offset="1" stop-color="#ED9900" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint21_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(6.93708 10.7406) rotate(-162.974) scale(2.62885 1.2752)">
        <stop stop-color="#D86D00"/>
        <stop offset="0.3292" stop-color="#DC6C0A" stop-opacity="0.6708"/>
        <stop offset="0.8792" stop-color="#E86823" stop-opacity="0.1208"/>
        <stop offset="1" stop-color="#EB672A" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint22_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(6.04788 12.3701) rotate(167.147) scale(3.47208 3.65094)">
        <stop stop-color="#D86D00"/>
        <stop offset="0.3292" stop-color="#DC6C0A" stop-opacity="0.6708"/>
        <stop offset="0.8792" stop-color="#E86823" stop-opacity="0.1208"/>
        <stop offset="1" stop-color="#EB672A" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint23_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(6.51968 8.25988) rotate(147.996) scale(3.69469 2.82806)">
        <stop stop-color="#FFA754"/>
        <stop offset="0.3697" stop-color="#FFB152" stop-opacity="0.6303"/>
        <stop offset="0.9874" stop-color="#FFCA4B" stop-opacity="0.0126062"/>
        <stop offset="1" stop-color="#FFCB4B" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint24_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(1.38652 2.94778) rotate(122.704) scale(3.52373 2.39767)">
        <stop stop-color="#ED9900"/>
        <stop offset="1" stop-color="#ED9900" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint25_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(11.2238 6.57674) rotate(-30.4463) scale(0.888492 2.4258)">
        <stop stop-color="#D86D00"/>
        <stop offset="0.3292" stop-color="#DC6C0A" stop-opacity="0.6708"/>
        <stop offset="0.8792" stop-color="#E86823" stop-opacity="0.1208"/>
        <stop offset="1" stop-color="#EB672A" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint26_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(4.12537 5.776) rotate(-32.6357) scale(2.29484 1.25012)">
        <stop stop-color="white" stop-opacity="0.5"/>
        <stop offset="0.995" stop-color="white" stop-opacity="0"/>
      </radialGradient>
      <radialGradient id="paint27_radial_8361_28318" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(14.4439 5.46719) rotate(-32.6357) scale(2.29484 1.25012)">
        <stop stop-color="white" stop-opacity="0.5"/>
        <stop offset="0.995" stop-color="white" stop-opacity="0"/>
      </radialGradient>
    </defs>
  </SvgIcon>
)
