import {
  GetCoursesForAdminPanelDocument,
  useAddCourseToSchoolV3Mutation,
} from "../../../generated/graphql";

export const useAddCourse = (schoolId) => {
  const [addCourseToSchoolV3Mutation] = useAddCourseToSchoolV3Mutation({
    refetchQueries: [
      {
        query: GetCoursesForAdminPanelDocument,
        variables: { schoolId },
      },
    ],
    errorPolicy: "all",
  });
  return (schoolId, courseId) =>
    addCourseToSchoolV3Mutation({
      variables: {
        schoolId,
        courseId,
      },
    });
};
