import styled from "styled-components";
import { secondary } from "../../Style/Style";

export const DropdownIconWrapper = styled.span<{expanded: boolean}>`
  position: absolute;
  text-align: center;
  display: block;
  top: 50%;
  right: 0;
  height: 24px;
  color: ${secondary};
  transform: translateY(-50%) ${props => props.expanded ? 'rotate(180deg)': 'rotate(0)'};
  transition: transform 0.5s;
`;