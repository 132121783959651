import styled from "styled-components";
import { MainTableRow } from "../../shared/Style/Style";

export const AwardsTableHeader = styled(MainTableRow)`
  grid-template-columns: 1fr 1fr 1fr 2fr 1fr 2fr;
  grid-gap: 10px;
  align-items: center;
  
  .MuiButton-root{
    color: #FFB61D;
  }

  .MuiButton-root.Mui-disabled {
    color: #BDBDBD;
  }
`;

export const AwardsListItem = styled(MainTableRow)`
  grid-template-columns: 1fr 1fr 1fr 2fr 1fr 2fr;
  grid-gap: 10px;
  align-items: center;
  cursor: pointer;
`;

export const EmptyPageTitle = styled.p`
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: 600px;
`;

export const AwardMenu = styled.div`
  margin-left: auto;
`;
