import styled from "styled-components";
import {Box, List, TextField} from "@material-ui/core";
import {lightBlack, primary} from "../shared/Style/Style";



export const AddVersions = styled(TextField)`
  margin:0;
  height: 45px;
  width: 270px;

  & .MuiInput-underline:before {
    border-bottom-color: #eee;
  }
  & .MuiInput-formControl {
    margin-top: 0px;
  }
  & .MuiInputLabel-formControl{
    top:-10px;
  }

  & .MuiInput-underline:after {
    border-bottom-color:${primary};
  }

  & .MuiInputBase-multiline {
    padding: 13px 14px 13px 20px;
  }
`;

export const MobileVersionList = styled(List)`
  width: 100%;
  padding: 0;
  transition: all 0.5s;
  max-height:300px;
`;

export const MobileVersionInfo = styled.h3`
  color: ${lightBlack};
  margin-top: 0;
  font-size: 13px;
`;

export const EmptyRowVersion=styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;