import { KnowledgeBaseBlockTypeEnum, LessonBlockTypeEnum} from "../../../../generated/graphql";

export const getMarginOfBlockInputLabel = (type?: KnowledgeBaseBlockTypeEnum): string => {
  switch (type) {
    case KnowledgeBaseBlockTypeEnum.Header:
      return '28px';

    case KnowledgeBaseBlockTypeEnum.Subtitle:
      return '22px';

    default:
      return '16px';
  }
}

export const getMarginOfBlockInputLabelLesson = (type?: LessonBlockTypeEnum): string => {
  switch (type) {
    case LessonBlockTypeEnum.Header:
      return '28px';

    case LessonBlockTypeEnum.Subtitle:
      return '22px';

    default:
      return '16px';
  }
}