import React from "react";
import { Box } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import { CustomNavBarNoMargin, CustomNavLink, ButtonCustomWithDarkText } from "../Style/Style";

interface INavBarProps {
  items: {
    name: string;
    link: string;
  }[];
  margin?: string;
}

export const NavBar = ({ items, margin }: INavBarProps) => {
  const { path } = useRouteMatch<{ path: string }>();

  return (
    <Box
      display='flex'
      alignItems='center'
      margin={margin ? `${margin}px 0` : '20px 0'}
    >
      <CustomNavBarNoMargin>
        {items.map(({ name, link }) => (
          <Box
            mr={1}
            key={name}
          >
            <CustomNavLink
              to={link}
            >
              <ButtonCustomWithDarkText
                type="button"
                variant="contained"
                size="small"
                active={path.includes(link)}
              >
                {name}
              </ButtonCustomWithDarkText>
            </CustomNavLink>
          </Box>
        ))}
      </CustomNavBarNoMargin>
    </Box>
  )
}