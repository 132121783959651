import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  GetProjectDocument,
  ProjectFile,
  UpdateProjectFilesDocument,
  UpdateProjectFilesMutation,
} from "../../../../generated/graphql";
import { DeleteIconButton } from "../../../shared/Buttons/DeleteIconButton";
import { ErrorMessageSnackbar } from "../../../shared/ErrorMessageSnackbar";
import { Box, Grid, TextField } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Link, LinkContainer, LinkLabel } from "./ProjectFileItem.style";

interface ProjectFileProps {
  file: ProjectFile;
  classes: ClassNameMap<"root">;
  projectId: string;
}

export const ProjectFileItem = ({
  file,
  classes,
  projectId,
}: ProjectFileProps) => {
  const [requestError, setRequestError] = useState(false);

  const [deleteFile, { loading: deleteLoading, error: deleteError }] =
    useMutation<UpdateProjectFilesMutation>(UpdateProjectFilesDocument, {
      refetchQueries: [{ query: GetProjectDocument, variables: { projectId } }],
    });

  useEffect(() => {
    if (deleteError) {
      setRequestError(true);
    }
  }, [deleteError]);

  return (
    <Box mb={1}>
      <Grid
        container
        justifyContent="center"
        spacing={2}
        style={{ position: "relative" }}
      >
        <Grid xs={6} item>
          <TextField
            className={classes.root}
            style={{ marginBottom: 0 }}
            disabled
            fullWidth
            label="Название"
            variant="filled"
            type="text"
            value={file.name}
          />
        </Grid>

        <Grid xs={6} item>
          <LinkContainer>
            <LinkLabel>Ссылка на документ</LinkLabel>
            <Link href={file.file}>{file.file.slice(0, 20)}...</Link>
          </LinkContainer>

          <Box style={{ position: "absolute", right: "8px", top: "8px" }}>
            <DeleteIconButton
              disabled={deleteLoading}
              deleteHandler={() =>
                deleteFile({
                  variables: { projectId, deleteFileId: file.id },
                })
              }
              item="ссылку"
              fz={14}
            />
          </Box>
        </Grid>
      </Grid>

      {/*<AddProjectFileModal*/}
      {/*  open={edit}*/}
      {/*  showModal={setEdit}*/}
      {/*  file={file}*/}
      {/*  projectId={projectId}*/}
      {/*/>*/}

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={deleteError && "Ошибка при удалении файла"}
      />
    </Box>
  );
};
