import { FC, useCallback, useState } from "react";
import { mobileAppModel } from "../../state";
import { getYoutubeVideoId } from "../../utils";
import { regexYouTubeUrl } from "../../constants";
import { Dialog, DialogContent, DialogTitle, Box } from "@material-ui/core";
import { CloseIconButton } from "../../../../shared/Buttons/CloseIconButton";
import { ButtonCustom, ButtonsWrapper, CancelButton, ModalWithPhotoInput } from "../../../../shared/Style/Style";

interface UploadVideoUrlProps {
  open: boolean;
  close(): void;
}

export const UploadVideoUrl: FC<UploadVideoUrlProps> = ({
  open,
  close,
}) => {
  const [videoUrl, setVideoUrl] = useState("");

  const handleCancel = () => {
    if (videoUrl) {
      setVideoUrl("");
    } else {
      close();
    }
  };

  const onVideoUrlAdd = useCallback(() => {
    if (videoUrl) {
      const videoUrlFormatted = `https://www.youtube.com/embed/${getYoutubeVideoId(videoUrl)}`;
      mobileAppModel.createContentPreview(videoUrlFormatted);
    }

    setVideoUrl("");
    close();
  }, [videoUrl]);

  const isVideoFormat = videoUrl && !!videoUrl.match(regexYouTubeUrl);

  return (
    <Dialog open={open}>
      <DialogContent style={{ padding: "12px" }}>
        <Box>
          <DialogTitle style={{  width: "440px", padding: 0 }}>
            Ссылка на YouTube видео 
          </DialogTitle>
          <CloseIconButton close={close} />
        </Box>
        <ModalWithPhotoInput
          value={videoUrl}
          placeholder="https://..."
          variant="outlined"
          onChange={({ target: { value } }) => setVideoUrl(value)}
          style={{marginTop: "20px"}}
        />
        <ButtonsWrapper>
          <CancelButton onClick={handleCancel}>
            Отменить
          </CancelButton>
          
          <ButtonCustom disabled={!isVideoFormat} onClick={onVideoUrlAdd}>
            Добавить
          </ButtonCustom>        
        </ButtonsWrapper>
      </DialogContent>
    </Dialog>
  );
};
