import { useCallback, useRef } from "react";
import CropperComponent, { ReactCropperElement } from "react-cropper";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { ButtonCustom, CancelButton } from "../../../../shared/Style/Style";
import { aspectRatio } from "../../constants";
import { StyledDialogContent } from "./Style";

interface ImageCropperDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (image: HTMLCanvasElement) => void;
  image: string;
}

export function ImageCropperDialog({
  open,
  onClose,
  onSubmit,
  image,
}: ImageCropperDialogProps) {
  const cropperRef = useRef<ReactCropperElement | null>(null);
  const onSubmitCrop = useCallback(async () => {
    const canvasScaled = cropperRef?.current?.cropper?.getCroppedCanvas();
    onSubmit(canvasScaled);
  }, [onSubmit]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Редактирование изображения</DialogTitle>

      <StyledDialogContent>
        <CropperComponent
          ref={cropperRef}
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
          src={image as string}
          dragMode="move"
          aspectRatio={aspectRatio}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          autoCropArea={1}
          responsive
          zoomable
          guides
        />
      </StyledDialogContent>

      <DialogActions>
        <CancelButton
          onClick={onClose}
        >
          Отменить
        </CancelButton>

        <ButtonCustom
          onClick={onSubmitCrop}
        >
          Сохранить
        </ButtonCustom>
      </DialogActions>
    </Dialog>
  );
};
