import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useDropzone } from 'react-dropzone';

import { useMutation } from '@apollo/client';
import {
  GetUserPaymentsByProjectDocument,
  SetUserPaymentDocumentsDocument,
  SetUserPaymentDocumentsMutation
} from '../../../generated/graphql';

import { Dialog, Snackbar, List } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import {
  CloseDropzoneButton,
  DeleteAddedFileBtn,
  DropzoneContainer,
  DropzoneFilesListItem,
  DropzoneContent,
  AddButton
} from './DropzonePdf.style';


export const DropzonePdf = (props: any) => {
  const { projectId } = useParams<{projectId: string}>()
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  const [files, setFiles] = useState<File[]>([]);

  const [
    setInvoices,
    {data, loading, error}
  ] = useMutation<SetUserPaymentDocumentsMutation>(
    SetUserPaymentDocumentsDocument,
    { refetchQueries: [
      {
        query: GetUserPaymentsByProjectDocument,
        variables: { projectId, limit: props.limit, skip: props.skip },
      }
    ]}
  );

  const setInvoicesHandler = () => {
    if (!files.length) {
      return;
    }

    setInvoices({variables: { paymentId: props.paymentId, newDocuments: files }})
  }

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'application/pdf',
    multiple: true,
    onDropAccepted: (acceptedFiles) => {
      setFiles(files.concat(acceptedFiles))
      setSnackbarOpen(true);
    },
    onDropRejected: () => {
      setSnackbarOpen(true);
    },
  });

  const [invoicesError, setInvoicesError] = useState(false);

  useEffect(() => {
    if (error) {
      setInvoicesError(true);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setInvoicesError(false);
      props.close();
    }
  }, [data]);

  return (
    <Dialog
      open={props.open}
    >
      <DropzoneContent>
        <CloseDropzoneButton
          onClick={() => {
            setFiles([]);
            props.close();
          }}
        >
          <CloseIcon fontSize='small'/>
        </CloseDropzoneButton>

        {!!files.length &&
          <List>
            {files.map((file: File) => (
              <DropzoneFilesListItem
                button={true}
              >
                <DescriptionIcon fontSize='small'/>
                <div>{file.name}</div>

                <DeleteAddedFileBtn
                  onClick={() => setFiles(files.filter(addedFile => addedFile.name !== file.name))}
                >
                  <DeleteIcon fontSize='small'/>
                </DeleteAddedFileBtn>

              </DropzoneFilesListItem>
            ))}
          </List>
        }

        <DropzoneContainer className="dropzone-instance" {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
          <div>Нажмите сюда или перетяните файл для добавления</div>
          <input {...getInputProps()} />
          {props.dropzoneContent}
        </DropzoneContainer>

        <AddButton
          disabled={loading || !files.length}
          onClick={setInvoicesHandler}
        >
          Прикрепить
        </AddButton>

        {isDragAccept && (
          <Snackbar
            open={isDragAccept && isSnackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnackbarOpen(false)}
          >
            {isDragReject
              ? <Alert severity="error">Вы пытаетесь загрузить неподдерживаемый тип файла</Alert>
              : <Alert severity="success">Файл успешно добавлен</Alert>
            }
          </Snackbar>
        )}

          <Snackbar
            open={invoicesError}
            autoHideDuration={6000}
            onClose={() => setInvoicesError(false)}
          >
              <Alert severity="error">При сохранении файлов произошла ошибка</Alert>
          </Snackbar>
      </DropzoneContent>
    </Dialog>
  );
}
