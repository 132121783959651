import React, { useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { useMutation, useQuery } from "@apollo/client";
import {
  PublishApplicationMainMutation,
  PublishApplicationMainDocument,
  ApplicationMain,
  GetApplicationsMainQuery,
  GetApplicationsMainDocument,
  ApplicationMainAvailableForEnum,
} from "../../generated/graphql";

import { useRequestError } from "../../hooks";

import {
  Box,
  CircularProgress,
  Container,
  Table,
  TablePagination,
} from "@material-ui/core";

import {
  MobileAppPagesTableRow,
  EmptyMobileAppPagesTableRow,
} from "./MobileAppPages.style";
import {
  ButtonPlus,
  Header,
  LoadingBox,
  MainTableCell,
  MainTableFooter,
  MainTableHeaderTitle,
  MainTableImage,
  MainTableStatusCell,
} from "../shared/Style/Style";

import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { Menu } from "../shared/Menu";
import { NavBar } from "../shared/NavBar";
import { MobileAppCreateModal } from "../MobileAppModal/MobileAppCreateModal";
import { MobileAppDeleteModal } from "../MobileAppModal/MobileAppDeleteModal";

import { DEFAULT_AVATAR_URL } from "../shared/constants";
import { cutStringHandler } from "../shared/Utils/StringOperations/cutStringHandler";

import { navItems } from "../../constants/navItemMobileApp";

const getPermissionLabel = (
  permission: ApplicationMainAvailableForEnum
): string => {
  switch (permission) {
    case "ALL":
      return "Не ограничен";

    case "QR":
      return "По QR";

    case "CLIENTS":
      return "Некоторым участникам";

    default:
      return "Не ограничен";
  }
};

const headers = ["Фото", "Название", "Описание", "Доступ", "Статус", " "];

export const MobileAppPages = () => {
  const { appId } = useParams<{ appId: string }>();
  const { push } = useHistory();

  //#region state

  const [chosenMain, setChosenMain] = useState<ApplicationMain>(null);

  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //#endregion

  // #region pagination

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // #endregion

  const [
    publishPage,
    { loading: publishPageLoading, error: publishPageError },
  ] = useMutation<PublishApplicationMainMutation>(
    PublishApplicationMainDocument,
    {
      variables: {
        id: chosenMain?.id,
      },
      refetchQueries: [
        {
          query: GetApplicationsMainDocument,
          variables: {
            limit: rowsPerPage,
            skip: page * rowsPerPage,
            applicationId: appId,
          },
        },
      ],
    }
  );

  const {
    data: mainsData,
    loading: mainsLoading,
    error: mainsError,
    refetch: refetchMains,
  } = useQuery<GetApplicationsMainQuery>(GetApplicationsMainDocument, {
    variables: {
      limit: rowsPerPage,
      skip: page * rowsPerPage,
      applicationId: appId,
    },
  });

  const error = publishPageError || mainsError;
  const errorMessage =
    (publishPageError && "При публикации страницы произошла обишка") ||
    (mainsError && "При загрузке списка приложений");
  const { requestError, setRequestError } = useRequestError(error);

  const handlePublishPage = async (id: string) => {
    try {
      await publishPage({ variables: { id } });
    } catch (e) {
      console.error(e);
    }
  };

  let content;

  if (mainsLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (mainsError) {
    content = <ErrorSnackbar error={mainsError} />;
  }

  if (mainsData) {
    const appsPagesList = mainsData.getApplicationsMain.applicationsMain;

    const menuItems = (page: ApplicationMain) => [
      {
        name: "Опубликовать",
        action: () => handlePublishPage(page.id),
      },
      {
        name: "Редактировать",
        action: () => push(`/mobileApps/${appId}/pages/${page.id}/edit`),
      },
      {
        name: "Удалить",
        action: () => { setDeleteModal(true); setChosenMain(page)},
        color: "red",
      },
    ];

    content = (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          mt={"40px"}
          mb={"26px"}
        >
          <NavBar items={navItems} margin={"0"} />

          <ButtonPlus
            width={179}
            variant="contained"
            disabled={publishPageLoading}
            onClick={() => setCreateModal(true)}
          >
            Добавить
          </ButtonPlus>
        </Box>

        <Table>
          <MobileAppPagesTableRow>
            {headers.map((header) => (
              <MainTableHeaderTitle key={header}>{header}</MainTableHeaderTitle>
            ))}
          </MobileAppPagesTableRow>
        </Table>

        {appsPagesList.length ? (
          appsPagesList.map((page) => (
            <MobileAppPagesTableRow
              key={page.id}
              onClick={() => push(`/mobileApps/${appId}/pages/${page.id}/edit`)}
            >
              <MainTableCell>
                <MainTableImage
                  prim={page.photo ? page.photo : DEFAULT_AVATAR_URL}
                />
              </MainTableCell>

              <MainTableCell>{page.name}</MainTableCell>

              <MainTableCell>
                {cutStringHandler(page.description)}
              </MainTableCell>

              <MainTableCell>
                {getPermissionLabel(page.availableFor)}
              </MainTableCell>

              <MainTableStatusCell status={page.isPublished}>
                {page.isPublished ? "Опубликовано" : "Не опубликовано"}
              </MainTableStatusCell>

              <MainTableCell >
                <Menu
                  vertical={36}
                  horizontal={12}
                  width={170}
                  disabled={publishPageLoading}
                  items={menuItems(page)}
                />
              </MainTableCell>
            </MobileAppPagesTableRow>
          ))
        ) : (
          <EmptyMobileAppPagesTableRow>
            Главные странцы отсутствуют
          </EmptyMobileAppPagesTableRow>
        )}

        <Box mt={2}>
          <MainTableFooter>
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              count={mainsData.getApplicationsMain?.total || 0}
              component={"div"}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MainTableFooter>
        </Box>
      </>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header>Главные страницы приложения</Header>

      {content}

      <MobileAppCreateModal
        open={createModal}
        close={() => setCreateModal(false)}
        refetchVariables={{
          limit: rowsPerPage,
          skip: page * rowsPerPage,
          applicationId: appId,
        }}
        entity="страницы приложения"
      />

      <MobileAppDeleteModal
        open={deleteModal}
        close={() => setDeleteModal(false)}
        item={chosenMain}
        refetch={() => refetchMains()}
      />

      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
    </Container>
  );
};
