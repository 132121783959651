import React, { useEffect, useState } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export function ErrorSnackbar({ error }: any) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, [error]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error">
          {error?.message ? (
            <span>{error.message}</span>
          ) : (
            <span>Произошла ошибка</span>
          )}
        </Alert>
      </Snackbar>
      <div>Блок недоступен из-за ошибки</div>
    </>
  );
}
