import React from "react";

import { useQuery } from "@apollo/client";
import {
  GetDashboardCompletionPctReportDocument,
  GetDashboardCompletionPctReportQuery,
} from "../../../../generated/graphql";
import { IDashboardReportProps } from "../../Dashboard";

import {
  Table,
  TableRow,
  TableBody,
  CircularProgress,
} from "@material-ui/core";
import { CustomTable } from "../../../shared/CustomTable";

import {
  CustomTableCell,
  CustomTableHead,
  CustomTableHeaderCell,
} from "../../../shared/CustomTable/CustomTable.style";
import { LoadingBox } from "../../../shared/Style/Style";
import {
  PercentLinearProgressBody,
  PercentLinearProgressRate,
} from "../../Dashboard.style";

export const CompletionPercent = ({
  dateRange,
  projectId,
}: IDashboardReportProps) => {
  const { data, loading, error } =
    useQuery<GetDashboardCompletionPctReportQuery>(
      GetDashboardCompletionPctReportDocument,
      {
        variables: {
          projectId,
          date: dateRange.endDate,
        },
        fetchPolicy: "cache-and-network",
      }
    );

  let content;

  if (loading) {
    content = (
      <TableBody>
        <TableRow>
          <CustomTableCell>
            <LoadingBox>
              <CircularProgress color="inherit" />
            </LoadingBox>
          </CustomTableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (error) {
    content = (
      <TableBody>
        <TableRow>
          <CustomTableCell>Не удалось загрузить данные</CustomTableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (data) {
    const projects = data.getDashboardCompletionPctReport;

    content = (
      <TableBody>
        {projects.length ? (
          projects.map((project) => (
            <TableRow key={project.projectId}>
              <CustomTableCell>{project.projectName}</CustomTableCell>
              <CustomTableCell>
                <PercentLinearProgressBody>
                  {project.pct || 0}%
                  <PercentLinearProgressRate percent={project.pct} />
                </PercentLinearProgressBody>
              </CustomTableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <CustomTableCell>Не удалось загрузить данные</CustomTableCell>
          </TableRow>
        )}
      </TableBody>
    );
  }

  const table = (
    <Table>
      <CustomTableHead>
        <TableRow>
          <CustomTableHeaderCell colSpan={2}>
            Процент выполнения (план и факт)
          </CustomTableHeaderCell>
        </TableRow>
      </CustomTableHead>

      {content}
    </Table>
  );

  return (
    <>
      <CustomTable
        data={data?.getDashboardCompletionPctReport}
        tableContent={table}
        height={247}
      />
    </>
  );
};
