import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  GetChartData2Document,
  GetChartData2Query,
} from "../../generated/graphql";
import { TimelineNavbar } from "./TimelineNavbar";
import { GanttTaskReact } from "./GantTask/GanttTask";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { ProjectEditNavBar } from "../ProjectEditNavBar";

import { Box, CircularProgress, Container } from "@material-ui/core";
import { Header, LoadingBox } from "../shared/Style/Style";
import { GanttContainer, HeaderContainer } from "./KanbanTimeline.style";

export const KanbanTimeline = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [errorMessage, setErrorMessage] = useState("");
  const [requestError, setRequestError] = useState(false);

  const {
    data: chartDataData,
    loading: chartDataLoading,
    error: chartDataError,
  } = useQuery<GetChartData2Query>(GetChartData2Document, {
    variables: { projectId, isEpic: true },
  });

  useEffect(() => {
    if (chartDataError) {
      setRequestError(true);
      setErrorMessage("Ошибка при загрузке таблицы");
    }
  }, [chartDataError]);

  let timelineContent: JSX.Element;

  if (chartDataLoading) {
    timelineContent = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (chartDataData) {
    const epicsChart = chartDataData.getChartData2;

    timelineContent = (
      <GanttContainer>
        <GanttTaskReact epics={epicsChart} />
      </GanttContainer>
    );
  }

  return (
    <Container maxWidth="lg">
      <HeaderContainer>
        <Header>График</Header>
        <Box style={{ position: "absolute", right: "0", top: "-20px" }}>
          <TimelineNavbar />
        </Box>

        <ProjectEditNavBar />
      </HeaderContainer>

      {timelineContent}

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Container>
  );
};
