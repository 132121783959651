import { useState, useEffect } from "react";
import { SubscriptionPlanStatusEnum } from "../../../../../generated/graphql";
import { useReactiveVar } from "@apollo/client";
import { mobileAppModel } from "../../state";
import { ModalWithPhotoInput as PriceInput } from "../../../../shared/Style/Style";
import { Box } from "@material-ui/core";
import { validatePrice } from "../../utils";
import { initialData } from "./constants";
import { costText } from "../../../../School/constants";

export const AppModelPrice = () => {
  const { libraryContent, subscriptionPlan } =
    useReactiveVar(mobileAppModel.model) ?? initialData;
  const isLoading = useReactiveVar(mobileAppModel.updateLoading);

  const isPurchases = subscriptionPlan === SubscriptionPlanStatusEnum.Purchases;
  const { price } = libraryContent;
  const [value, setValue] = useState(price > 0 ? String(price) : "");

  const [hasError, errorMessage] = validatePrice(String(value));

  const onChangeHandler = ({ target: { value } }) => {
    setValue(value);

    const [hasError] = validatePrice(value);
    if (hasError) return mobileAppModel.setPrice(0);

    mobileAppModel.setPrice(Number(value));
  };

  useEffect(() => {
    if (!hasError && !price) setValue("");
  }, [hasError, price]);

  if (!isPurchases) {
    if (price > 0) mobileAppModel.setPrice(0);
    return null;
  }

  return (
    <Box mt={2}>
      <PriceInput
        label={costText}
        value={value}
        disabled={isLoading}
        fullWidth
        required
        helperText={errorMessage}
        error={hasError}
        onChange={onChangeHandler}
      />
    </Box>
  );
};
