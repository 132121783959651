import React, { FC, useMemo } from "react";
import { options, getChartData } from "../../Charts/chartConfig";
import {
  ChartDataType,
  ChartHeaderType,
  ChartInfoMessageDataType,
} from "../types";
import { Typography } from "@material-ui/core";
import { LoadingProgress } from "../../shared/LoadingProgress";
import {
  VerticalBarChartFilterProps,
  VerticalBarChartFilter,
} from "../VerticalBarChartFilter/VerticalBarChartFilter";
import { VerticalBarChartContainer } from "../VerticalBarChartContainer/VerticalBarChartContainer";
import {
  VerticalChartBarContainer,
  VerticalChartBarContainerBody,
} from "../../shared/Style/Style";
import { getNewWidth } from "../utils";
import { VerticalBarChartAdapter } from "../../Charts/VerticalBarChartAdapter";

interface VerticalBarChartProps {
  chartHeader: ChartHeaderType;
  chart: ChartDataType;
  chartFilter?: VerticalBarChartFilterProps;
  isLoadingProgress: boolean;
  message: ChartInfoMessageDataType;
  noFilters?: boolean;
  unit?: string;
}

export const VerticalBarChart: FC<VerticalBarChartProps> = ({
  chartHeader,
  chart,
  chartFilter,
  isLoadingProgress,
  message,
  noFilters = false,
  unit,
}) => {
  const { text, isChartDisplay } = message;
  const dataChart = useMemo(() => getChartData(chart), [chart]);
  const totalLabel = chart.label?.length ?? 0;
  const newWidth = getNewWidth(totalLabel);

  const ChartBody = (
    <div>
      <Typography variant="h6" align="center">
        {text}
      </Typography>
      {isChartDisplay && (
        <VerticalChartBarContainer noFilters={noFilters}>
          <VerticalChartBarContainerBody style={{ width: `${newWidth}px` }}>
            <VerticalBarChartAdapter
              data={dataChart}
              options={options}
              unit={unit}
            />
          </VerticalChartBarContainerBody>
        </VerticalChartBarContainer>
      )}
    </div>
  );

  const VerticalChart = !isLoadingProgress ? ChartBody : <LoadingProgress />;

  return (
    <VerticalBarChartContainer {...chartHeader}>
      {chartFilter && <VerticalBarChartFilter {...chartFilter} />}
      {VerticalChart}
    </VerticalBarChartContainer>
  );
};
