import React from "react";

import { useParams, useHistory } from "react-router-dom";

import { useQuery } from "@apollo/client";
import {
  GetKnowledgeBaseBlocksTestHistoryDocument,
  GetKnowledgeBaseBlocksTestHistoryQuery
} from "../../generated/graphql";

import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";


import { Container, Box, CircularProgress, Table, TableBody, TableHead } from "@material-ui/core";

import {
  Header,
  ButtonCustomWithDarkText,
  LoadingBox,
  MainTableHeaderTitle,
  MainTableCell,
  UserAvatarDefaultStyle,
  UserAvatarStyle,
  MainTableStatusCell
} from "../shared/Style/Style";
import { TestHistoryTableRow, EmptyTestHistoryTableRow } from "./KnowledgeBaseTestsHistory.style";

import { DEFAULT_AVATAR_URL } from "../shared/constants";


const headers = [
  'Фото',
  'Фамилия',
  'Имя',
  'Профессия',
  'Статус',
  'Результат'
];


export const KnowledgeBaseTestsHistory = () => {
  const { themeId, themeName } = useParams<{ themeId: string, themeName: string }>();
  const history = useHistory();

  const { data, loading, error } = useQuery<GetKnowledgeBaseBlocksTestHistoryQuery>(
    GetKnowledgeBaseBlocksTestHistoryDocument,
    { variables: { themeId } }
  );

  let content;

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit' />
      </LoadingBox>
    )
  }

  if (error) {
    content = <ErrorSnackbar error={error}/>;
  }

  if (data) {
    const people = data.getKnowledgeBaseBlocksTestHistory;

    content = (
      <Table>
        <TableHead>
          <TestHistoryTableRow>
            {headers.map(header => (
              <MainTableHeaderTitle key={header}>{header}</MainTableHeaderTitle>
            ))}
          </TestHistoryTableRow>
        </TableHead>

        <TableBody>
          {people?.length
            ? people?.map(({ user, answers }) => {
              const personRightAnswers = answers.reduce((acc, cur) => cur.isAnswerTrue ? ++acc : acc, 0 );
              const status = personRightAnswers >= (answers.length / 2);

              return (
                <TestHistoryTableRow>
                  <MainTableCell>
                    {!!user.photo ? (
                      <UserAvatarStyle
                        prim={user.photo ? user.photo : undefined}
                        alt=" "
                      />
                    ) : (
                      <UserAvatarDefaultStyle src={DEFAULT_AVATAR_URL} alt=" " />
                    )}
                  </MainTableCell>

                  {[user.lastName, user.firstName, user?.job?.name].map((field, i) => (
                    <MainTableCell key={i}>{field ? field : '-'}</MainTableCell>
                  ))}

                  <MainTableStatusCell status={status}>
                    {status ? 'Пройден' : 'Провален'}
                  </MainTableStatusCell>

                  <MainTableCell>
                    {personRightAnswers} / {answers.length}
                  </MainTableCell>

                </TestHistoryTableRow>
              )
            })
            : (
              <EmptyTestHistoryTableRow>
                <MainTableCell>
                  Пользователи отсутствуют
                </MainTableCell>
              </EmptyTestHistoryTableRow>
            )}
        </TableBody>
      </Table>
    )
  }



  return (
    <Container maxWidth="lg">
      <Box
        mb='30px'
      >
        <Header>Ответы сотрудников</Header>
      </Box>

      <Box
        display='flex'
        justifyContent='flex-end'
        mb='30px'
      >
        <Box
          mr='20px'
        >
          <ButtonCustomWithDarkText
            active={true}
            width='190'
            onClick={() => history.push(`/knowledgeBase/tests/${themeName}/${themeId}`)}
          >
            Тесты
          </ButtonCustomWithDarkText>
        </Box>

        <ButtonCustomWithDarkText
          active={true}
          width='190'
          onClick={() => history.push(`/knowledgeBase/theme/${themeName}/${themeId}`)}
        >
          Тема
        </ButtonCustomWithDarkText>
      </Box>

      {content}

    </Container>
  )
}