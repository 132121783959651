import React, { useState } from "react";
import { Box, Card, List, Paper, Popover } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { CustomIconButton } from "../Buttons/CustomIconButton";
import { MenuItem } from "./Menu.style";
import { secondary } from "../Style/Style";

interface ITaskDeskMenuProps {
  vertical: number;
  horizontal: number;
  width: number;
  disabled?: boolean;
  onClose?: any;
  setItem?: any;
  items: {
    name: string;
    action: (e) => void;
    color?: string;
  }[];
}

export const Menu = ({
  vertical,
  horizontal,
  width,
  disabled,
  onClose = () => {},
  setItem,
  items,
}: ITaskDeskMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (e) => {
    e.stopPropagation();
    if (setItem) setItem();
    setAnchorEl(e.currentTarget);
  }

  const handleClose = (e) => {
    e.stopPropagation()
    setAnchorEl(null);
  }

  return (
    <>
      <CustomIconButton
        callback={handleOpen}
        mainColor={secondary}
        children={<MoreVert />}
      />

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical,
          horizontal,
        }}
      >
        <Box maxWidth={width}>
          <Card>
            <Paper elevation={4}>
              <List>
                {items.map(({ name, action, color }) => (
                  <MenuItem
                    key={name}
                    disabled={!!disabled}
                    onClick={(e) => {
                      action(e)
                      handleClose(e)
                    }}
                    button={true}
                    color={color}
                  >
                    {name}
                  </MenuItem>
                ))}
              </List>
            </Paper>
          </Card>
        </Box>
      </Popover>
    </>
  );
};
