export const convertISOStringToDateWithOffset = (isoString?: string): Date => {
  if (!isoString) {
    return new Date();
  }

  const milisecondsInMinute = 60 * 1000;

  const rawDate = new Date(isoString);

  return new Date(rawDate.getTime() + rawDate.getTimezoneOffset() * milisecondsInMinute);
}
