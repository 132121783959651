import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  AssignUsersToProjectDocument,
  AssignUsersToProjectMutation,
  GetPmForDropDownQuery,
  GetPmForDropDownDocument,
  UpdateProjectMutation,
  UpdateProjectDocument,
  GetProjectDocument,
} from "../../../generated/graphql";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListItem from "@material-ui/core/ListItem";
import FormControl from "@material-ui/core/FormControl";
import { Box, Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { PersonalData } from "../UserEdit/UserEdit.style";
import {
  ButtonCustom,
  ButtonCustomWithoutBoxShadowCancel,
  useProjectFormStyles,
} from "../../shared/Style/Style";
import { AddContactContainer, AddContactList } from "./ProjectModal.style";

interface AddPMProps {
  projectId: string;
  closeModal(e: boolean): void;
}

export const AddPM = ({ projectId, closeModal }: AddPMProps) => {
  const [requestError, setRequestError] = useState(false);
  const [projectManagerId, setProjectManagerId] = useState("");

  const {
    data: pmData,
    loading: pmLoading,
    error: pmError,
  } = useQuery<GetPmForDropDownQuery>(GetPmForDropDownDocument, {
    variables: {
      getUsersData: {
        role: "MANAGER",
        statuses: ["VERIFIED"],
      },
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const [
    updateProject,
    { data: addPmData, loading: addPmLoading, error: addPmError },
  ] = useMutation<UpdateProjectMutation>(UpdateProjectDocument);

  useEffect(() => {
    if (pmError || addPmError) {
      setRequestError(true);
    }
  }, [pmError, addPmError]);

  useEffect(() => {
    if (addPmData) {
      closeModal(false);
    }
  }, [addPmData]);

  const classes = useProjectFormStyles();
  let content: JSX.Element;

  if (pmLoading) {
    content = (
      <Backdrop open={pmLoading} style={{ color: "#FFB61D" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (pmData) {
    const projectManagers = pmData.getUsers.users;

    content = (
      <FormGroup aria-label="position">
        {projectManagers.map(({ id, firstName, lastName }, index) => (
          <ListItem button key={id}>
            <FormControlLabel
              value={id}
              control={
                <Checkbox
                  onChange={(e) => setProjectManagerId(e.target.value)}
                  checked={id === projectManagerId}
                  color="default"
                  name={id}
                />
              }
              label={`${index + 1}. ${firstName}${lastName}`}
            />
          </ListItem>
        ))}
      </FormGroup>
    );
  }

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    await updateProject({
      variables: {
        projectData: {
          projectManagerId: projectManagerId,
          id: projectId,
        },
      },
      refetchQueries: [{ query: GetProjectDocument, variables: { projectId } }],
    });
  };

  return (
    <>
      <AddContactContainer>
        <AddContactList>
          <Box component="div" width="100%">
            <Typography
              component={PersonalData}
              variant="subtitle1"
              gutterBottom
            >
              Прожект менеджеры
            </Typography>

            <form onSubmit={handleSubmit} className={classes.root}>
              <FormControl
                component="fieldset"
                className={classes.rootFormControl}
              >
                {content}
              </FormControl>

              <Box display="flex">
                <ButtonCustomWithoutBoxShadowCancel
                  style={{ marginRight: "20px" }}
                  onClick={() => closeModal(false)}
                >
                  Отмена
                </ButtonCustomWithoutBoxShadowCancel>

                <ButtonCustom type="submit" disabled={addPmLoading}>
                  Добавить
                </ButtonCustom>
              </Box>
            </form>
          </Box>
        </AddContactList>
      </AddContactContainer>

      {pmError && (
        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={"Ошибка при получении проджект менеджеров"}
        />
      )}
    </>
  );
};
