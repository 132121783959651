import { gql } from "@apollo/client";

export const UPDATE_APPLICATION_MODEL_PHOTO = gql`
  mutation updateApplicationModelPhoto($modelId: String!, $file: Upload!) {
    updateApplicationModelPhoto(modelId: $modelId, file: $file)
  }
`;

export const UPDATE_APPLICATION_MODEL_MARKER_PHOTO = gql`
  mutation updateApplicationModelMarkerPhoto(
    $modelId: String!
    $file: Upload
    $isMarker: Boolean!
  ) {
    updateApplicationModelMarkerPhoto(
      modelId: $modelId
      file: $file
      isMarker: $isMarker
    )
  }
`;

// export const UPDATE_APPLICATION_MODEL_MARKER_PHOTO = gql`
//   mutation updateApplicationModelMarkerPhoto($modelId: String!, $file: Upload, $isMarker: Boolean!) {
//     updateApplicationModelMarkerPhoto(modelId: $modelId, file: $file, isMarker: $isMarker)
//   }
// `;

export const UPDATE_APPLICATION_MODEL_MAP_MARKER_PHOTO = gql`
  mutation updateApplicationModelMapMarkerPhoto(
    $modelId: String!
    $file: Upload!
  ) {
    updateApplicationModelMapMarkerPhoto(modelId: $modelId, file: $file)
  }
`;

export const CREATE_MODEL_BUNDLE_FILE = gql`
  mutation createApplicationMainModelFile(
    $fileData: CreateApplicationMainModelFileInput!
  ) {
    createApplicationMainModelFile(fileData: $fileData) {
      id
    }
  }
`;

export const UPDATE_APPLICATION_MODEL_BUNDLE = gql`
  mutation updateApplicationModelBundle(
    $modelId: String!
    $bundleType: ApplicationBundleTypeEnum!
    $bundleFileType: BundleFileTypeEnum
    $file: Upload
    $bundleLink: String
  ) {
    updateApplicationModelBundle(
      modelId: $modelId
      bundleType: $bundleType
      bundleFileType: $bundleFileType
      file: $file
      bundleLink: $bundleLink
    )
  }
`;

export const DELETE_APPLICATION_MAIN_MODEL_FILES = gql`
  mutation deleteApplicationMainModelFiles(
    $modelId: String!
    $modelFileType: ModelFileTypeEnum!
  ) {
    deleteApplicationModelFiles(
      modelId: $modelId
      modelFileType: $modelFileType
    )
  }
`;

export const DELETE_APPLICATION_MODEL_FILE = gql`
  mutation deleteApplicationMainModelFile($fileId: String!) {
    deleteApplicationMainModelFile(fileId: $fileId)
  }
`;

export const UPDATE_APPLICATION_MAIN_MODEL_FILE = gql`
  mutation updateApplicationMainModelFile(
    $fileData: UpdateApplicationMainModelFileInput!
  ) {
    updateApplicationMainModelFile(fileData: $fileData) {
      id
    }
  }
`;

export const UPDATE_APPLICATION_MODEL_COLORING_PAGE_FILE = gql`
  mutation UpdateApplicationModelColoringPageFile(
    $modelId: String!
    $coloringPageFile: Upload!
  ) {
    updateApplicationModelColoringPageFile(
      modelId: $modelId
      coloringPageFile: $coloringPageFile
    )
  }
`;
