import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { CloseIconButton } from "../shared/Buttons/CloseIconButton";
import { SmartNotificationTypeEnum } from "../../generated/graphql";
import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
  ModalWithPhotoInput,
} from "../shared/Style/Style";
import { useApolloClient, useReactiveVar } from "@apollo/client";
import { useParams } from "react-router-dom";
import { mobileAppModel } from "../MobileAppModal/MobileAppModelModal/state";

interface ICreateSmartNotificationModal {
  open: boolean;
  applicationMainModelId: string;
  close(): void;
}

export const CreateSmartNotificationModal = ({
  open,
  close,
  applicationMainModelId,
}: ICreateSmartNotificationModal) => {
  const { appId } = useParams<{ appId: string }>();
  const apolloClient = useApolloClient();
  mobileAppModel.initMobileAppModel(apolloClient);

  const isUpdateLoading = useReactiveVar(mobileAppModel.updateLoading);

  const [description, setDescription] = useState("");

  const closeHandler = () => {
    setDescription("");
    close();
  };

  const createSmartNotificationHandler = () => {
    mobileAppModel.createSmartNotification(
      description,
      SmartNotificationTypeEnum.ContentRating,
      appId,
      applicationMainModelId
    );
    closeHandler();
  }

  return (
    <Dialog open={open}>
      <CloseIconButton close={closeHandler} />
      <DialogContent style={{ minWidth: "440px", padding: "30px" }}>
        <DialogTitle>Создать Уведомление</DialogTitle>
        <ModalWithPhotoInput
          label="Описание"
          value={description}
          onChange={({ target: { value } }) => setDescription(value)}
        />
        <ButtonsWrapper mt={0}>
          <CancelButton
            disabled={isUpdateLoading}
            onClick={closeHandler}
          >
            Отменить
          </CancelButton>

          <ButtonCustom
            disabled={!description || isUpdateLoading}
            onClick={createSmartNotificationHandler}
          >
            Сохранить
          </ButtonCustom>
        </ButtonsWrapper>
      </DialogContent>
    </Dialog>
  );
};
