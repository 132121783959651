import { Award } from "../../../generated/graphql";
import { AwardField, DeleteAwardType, ErrorType, InputErrors } from "./types";

export const awardsPageTitle = "Дейли логин";
export const awardsPageLink = "awards";
export const tableHeader = ["Фото", "Название", "День", "Количество энергии", "Статус"];
export const awardsQuantity = 8;

export const initialAward: Award = {
    name: "",
    photo: "",
    day: null,
    background: "",
    animation: "",
    energyQuantity: null,
    type: null
};

export const initialNotifications = {
  error: false,
  success: false,
  message: "",
};

export const initialError: ErrorType = { error: false, message: '' };

export const initialInputErrors: InputErrors = {
    [AwardField.Name]: initialError,
    [AwardField.Photo]: initialError,
    [AwardField.Day]: initialError,
    [AwardField.Background]: initialError,
    [AwardField.Animation]: initialError,
    [AwardField.Energy]: initialError,
    [AwardField.IsPublished]: initialError,
    [AwardField.Type]: initialError,
};

export const initialDeleteAward: DeleteAwardType = {
  isOpen: false, award: null
};
