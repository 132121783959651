import styled from "styled-components";
import {ModalInput, primary, secondary} from "../../shared/Style/Style";

export const TextArea = styled(ModalInput)`
  & .MuiInputBase-root {
    display: block;
  }
  & .MuiFilledInput-underline:before {
    border-bottom: 1px solid ${secondary};
  }
  & .MuiFilledInput-underline:after {
      border-bottom: 2px solid ${primary};
  }

  & .MuiFilledInput-underline.Mui-error:after{
    border-bottom-color: red;
  }
`

export const CustomTextField = styled(ModalInput)`
  
  & .MuiFilledInput-underline:before {
    border-bottom: 1px solid ${secondary};
  }
  
  & .MuiFilledInput-underline:after {
    border-bottom: 2px solid ${primary};
  }

  & .MuiFilledInput-underline.Mui-error:after{
    border-bottom-color: red;
  }
`