import React, { useEffect, useState } from "react";

import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GetProjectsDocument, GetProjectsQuery,
  GetTasksWithProblemsDocument,
  GetTasksWithProblemsQuery,
  Project
} from "../../generated/graphql";

import { Box, CircularProgress, Container, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { Assignee } from "./ProblemTasksTables/Assignee";
import { DateEstimate } from "./ProblemTasksTables/DateEstimate";
import { Done } from "./ProblemTasksTables/Done";
import { NoEpic } from "./ProblemTasksTables/NoEpic";

import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { ButtonCustomWithDarkText, CustomNavBar, Header, LoadingBox } from "../shared/Style/Style";


const buttons: {[key: string]: string} = {
  done: 'Нехватает дат',
  dateEstimate: 'Нет даты начала или конца',
  assignee: 'Нет ответственного',
  noEpic: 'Нет этапа'
}

export const ProblemTasks = () => {
  const [project, setProject] = useState<Project | null>(null);
  const [activeTable, setActiveTable] = useState('Нет ответственного');

  const {
    data: projectsData,
    loading: projectsLoading,
    error: projectsError,
  } = useQuery<GetProjectsQuery>(
    GetProjectsDocument,
    {
      variables: {
        getProjectsInput: {}
      }
    }
  );

  const [getTasksWithProblems, {
    data: projectTasksData,
    loading: projecTaskstLoading,
    error: projectTasksError,
  }] = useLazyQuery<GetTasksWithProblemsQuery>(GetTasksWithProblemsDocument, {
    variables: {
      projectId: project?.id
    }
  });

  console.log(project);

  useEffect(() => {
    if (projectsData?.getProjects?.projects?.length) {
      setProject(projectsData.getProjects.projects[projectsData.getProjects.projects.length - 1])
    }
  }, [projectsData?.getProjects?.projects])

  useEffect(() => {
    if (project?.id) {
      getTasksWithProblems();
    }
  }, [project?.id])

  let content;

  if (projectsLoading || projecTaskstLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit' />
      </LoadingBox>
    )
  }

  if (projectsError || projectTasksError) {
    content = <ErrorSnackbar error={projectsError || projectTasksError} />
  }

  if (projectsData && projectTasksData) {
    const projects = projectsData.getProjects.projects;
    const { done, dateEstimate, assignee, noEpic } = projectTasksData.getTasksWithProblems;
    const problems = Object.keys(projectTasksData.getTasksWithProblems);

    let tableToShow;

    switch (activeTable) {
      case 'Нет ответственного':
        tableToShow = <Assignee tasks={assignee} />
        break;

      case 'Нехватает дат':
        tableToShow = <Done tasks={done} />
        break;

      case 'Нет даты начала или конца':
        tableToShow = <DateEstimate tasks={dateEstimate} />
        break;

      case 'Нет этапа':
        tableToShow = <NoEpic tasks={noEpic} />
        break;

      default:
        tableToShow = <Assignee tasks={assignee} />
        break;
    }

    content = (
      <>
        <CustomNavBar>
          <Box display='flex' justifyContent='space-between'>
            {problems.map(problem => {
              if (problem.includes('_')) {
                return;
              }

              return (
                <Box
                  key={problem}
                  mr={1}
                >
                  <ButtonCustomWithDarkText
                    active={activeTable === buttons[problem]}
                    onClick={() => setActiveTable(buttons[problem])}
                  >
                    {buttons[problem]}
                  </ButtonCustomWithDarkText>
                </Box>
              )
            })}
          </Box>

          <Box width={300}>
            <Autocomplete
              disablePortal
              disableClearable
              options={projects.map(project => project.name)}
              renderInput={(params) => <TextField {...params} label="Проект" />}
              value={project?.name || ''}
              onChange={(e, value) => setProject(projects.find(project => project?.name === value))}
            />
          </Box>
        </CustomNavBar>

        {tableToShow}
      </>
    )
  }

  return (
    <Container>
      <Header>
        проблемные Задачи Проектов
      </Header>

     

      {content}

    </Container>
  )
}