import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  ClientSiteModel,
  GetModelsByClientSiteIdDocument,
  GetModelsByClientSiteIdQuery,
} from "../../../generated/graphql";
import {
  Box,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableHead,
} from "@material-ui/core";
import { ModelModal } from "./ModelModals/ModelModal";
import { DeleteModal } from "../DeleteModal";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { ModelItem } from "./ModelItem";
import { CustomTablePagination } from "../../shared/CustomTablePagination";
import { EmptyMobileAppTableRow } from "../../MobileApps/MobileApps.style";
import { ModelsTableRow } from "./Models.style";
import {
  ButtonPlus,
  Header,
  LoadingBox,
  MainTableHeaderTitle,
} from "../../shared/Style/Style";

const headers = ["Название", "Добавленные файлы", "Ссылка", " "];

export const Models = () => {
  const { siteId } = useParams<{ siteId: string }>();

  const [currentModel, setCurrentModel] = useState<ClientSiteModel>(null);
  const [openedModel, setOpenedModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [requestError, setRequestError] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    data: modelsData,
    loading: modelsLoading,
    error: modelsError,
  } = useQuery<GetModelsByClientSiteIdQuery>(GetModelsByClientSiteIdDocument, {
    variables: {
      clientSiteId: siteId,
      limit: 5,
      skip: 0,
    },
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (modelsError) {
      setRequestError(true);
    }
  }, [modelsError]);

  let content;

  if (modelsLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  const menuItems = [
    {
      name: "Редактировать",
      action: () => {
        setOpenedModel(true);
      },
    },
    {
      name: " Удалить",
      action: () => setDeleteModel(true),
      color: "red",
    },
  ];

  if (modelsData) {
    const models = modelsData.getModelsByClientSiteId.models;

    const pagination = (
      <CustomTablePagination
        rowsPerPageOptions={[10, 20]}
        count={modelsData.getModelsByClientSiteId.total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    );

    content = (
      <>
        <Box textAlign="right" mt={3} mb={3}>
          <ButtonPlus
            width={179}
            variant="contained"
            onClick={() => {
              setCurrentModel(null);
              setOpenedModel(true)
            }}
          >
            Добавить модель
          </ButtonPlus>
        </Box>

        <Box mb={2}>{pagination}</Box>

        <Table>
          <TableHead>
            <ModelsTableRow>
              {headers.map((header) => (
                <MainTableHeaderTitle key={header}>
                  {header}
                </MainTableHeaderTitle>
              ))}
            </ModelsTableRow>
          </TableHead>

          <TableBody>
            {models?.length ? (
              models.map((model) => (
                <ModelItem
                  key={model.id}
                  model={model}
                  setCurrentModel={setCurrentModel}
                  menuItems={menuItems}
                />
              ))
            ) : (
              <EmptyMobileAppTableRow>
                <td>Модели отсутствуют</td>
              </EmptyMobileAppTableRow>
            )}
          </TableBody>
        </Table>

        <Box mt={2}>{pagination}</Box>
      </>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header>Модели</Header>

      {content}

      {openedModel && (
        <ModelModal
          open={openedModel}
          close={() => {
            setOpenedModel(false);
            setCurrentModel(null);
          }}
          refetchVariables={{
            limit: rowsPerPage,
            skip: page * rowsPerPage,
            clientSiteId: siteId,
          }}
          model={currentModel}
          modelId={currentModel?.id}
          siteId={siteId}
        />
      )}

      <DeleteModal
        open={deleteModel}
        close={() => setDeleteModel(false)}
        item={currentModel}
        refetchVariables={{
          limit: rowsPerPage,
          skip: page * rowsPerPage,
          clientSiteId: siteId,
        }}
      />

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={"Ошибка при получении моделей сайта"}
      />
    </Container>
  );
};
