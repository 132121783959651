import React from "react";

import { Container } from "@material-ui/core";

import { Header } from "../../shared/Style/Style";

import { Job } from "./Job";
import { Agreement } from "./Agreement";
import { Department } from "./Department";
import { SettingsInner } from "./Settings.style";
import { ProjectType } from "./ProjectType";
import { BaseTaskStatus } from "./BaseTaskStatus";
import { ThemeBackground } from "./ThemeBackground";

export const Settings = () => {
  return (
    <Container maxWidth="lg">
      <Header>Настройки</Header>

      <SettingsInner>
        <Job />
        <Agreement />
        <Department />
        <ProjectType />
        <BaseTaskStatus />
        <ThemeBackground />
      </SettingsInner>
    </Container>
  );
};
