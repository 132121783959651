import React, { useEffect, useState } from "react";
import { ProjectAdditionalContact } from "../../../generated/graphql";
import MuiPhoneNumber from "material-ui-phone-number";
import { Box, Dialog, Grid, TextField, Typography } from "@material-ui/core";
import { PersonalData } from "../UserEdit/UserEdit.style";
import { useStyles } from "../../shared/Style/Style";
import { ButtonCustom } from "../../shared/Style/Style";
import { ProjectModalContainer } from "./ProjectModal.style";

interface ProjectAddAdditionalContactsModalProps {
  open: boolean;
  showModal(param: boolean): void;

  modalHandleSubmit?(data: {
    name: string;
    phone: string;
    description: string;
  }): void;
  data?: ProjectAdditionalContact;
  modalHandleUpdateSubmit?(data: {
    id: string;
    name: string;
    phone: string;
    description: string;
  }): void;
}

export const ProjectAddAdditionalContactsModal = ({
  open,
  showModal,
  modalHandleSubmit,
  data,
  modalHandleUpdateSubmit,
}: ProjectAddAdditionalContactsModalProps) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setResponsibility] = useState("");

  const handleChange = (
    e: React.ChangeEvent<{ value: any; name: string }>
  ): void => {
    e.target.name === "name" && setName(e.target.value);
    e.target.name === "description" && setResponsibility(e.target.value);
  };

  const handlePhoneChange = (e: string): void => {
    setPhone(e);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (modalHandleSubmit) {
      const contactData = {
        name,
        phone,
        description,
      };

      modalHandleSubmit(contactData);
    }

    if (modalHandleUpdateSubmit) {
      const contactData = {
        id: data?.id as string,
        name,
        phone,
        description,
      };

      modalHandleUpdateSubmit(contactData);
    }
  };

  useEffect(() => {
    setName(data ? (data.name as string) : "");
    setPhone(data ? (data?.phone as string) : "");
    setResponsibility(data ? (data?.description as string) : "");
  }, []);

  return (
    <Dialog
      open={open}
      aria-labelledby="simple-dialog-title"
      onClose={() => showModal(false)}
    >
      <ProjectModalContainer>
        <Typography component={PersonalData} variant="subtitle1" gutterBottom>
          Дополнительные контакты клиента
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid xs={6} item>
              <TextField
                name="name"
                id="name"
                className={classes.root}
                margin="normal"
                fullWidth
                label="Имя"
                variant="filled"
                type="text"
                value={name}
                onChange={handleChange}
              />
            </Grid>

            <Grid xs={6} item>
              <MuiPhoneNumber
                className={classes.root}
                fullWidth
                defaultCountry="ua"
                label={"Телефон"}
                variant="filled"
                margin="normal"
                onChange={handlePhoneChange}
                name="phone"
                value={phone}
              />
            </Grid>

            <TextField
              name="description"
              id="description"
              className={classes.root}
              margin="normal"
              fullWidth
              label="За что ответственный"
              variant="filled"
              type="text"
              value={description}
              onChange={handleChange}
            />
          </Grid>
          <Box display="flex" justifyContent="center" mt={1}>
            {modalHandleSubmit && (
              <ButtonCustom type="submit">Создать</ButtonCustom>
            )}
            {modalHandleUpdateSubmit && (
              <ButtonCustom type="submit">Сохранить</ButtonCustom>
            )}
          </Box>
        </form>
      </ProjectModalContainer>
    </Dialog>
  );
};
