import React, {useEffect, useState} from "react";
import { Box, CircularProgress, Container } from "@material-ui/core";
import {
  ButtonPlus,
  Header,
  LoadingBox,
} from "../shared/Style/Style";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateLessonBlockDocument,
  CreateLessonBlockMutation,
  CreateLessonDocument,
  CreateLessonMutation,
  GetLessonDocument,
  GetLessonQuery,
} from "../../generated/graphql";
import { useParams } from "react-router-dom";
import { LessonAddBlockModal } from "../LessonAddBlockModal/LessonAddBlockModal";
import { LessonBlockItem } from "./LessonBlockItem/LessonBlockItem";

export const Lesson = () => {
  const { lessonId } = useParams<{ lessonId: string }>();

  const [isOpenModalAddBlock, setIsOpenModalAddBlock] = useState(false);

  const {
    data: dataLesson,
    loading: loadingLesson,
    error: errorLesson,
  } = useQuery<GetLessonQuery>(GetLessonDocument, {
    variables: {
      lessonId,
    },
  });

  const [createLessonBlock, {data: dataCreateLessonBlock, loading: loadingCreateLesson }] =
    useMutation<CreateLessonMutation>(CreateLessonDocument, {
      variables: {
        lessonId,
      },
      refetchQueries: [GetLessonDocument],
    });

  const handlerCreateLesson = async () => {
    await createLessonBlock()
    setIsOpenModalAddBlock(false)
  }

  const disableOnLoading = loadingLesson || loadingCreateLesson;

  let content;

  if (disableOnLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (dataLesson) {
    const blocks = dataLesson.getLesson.pages;


    content = (
      <Box display="flex" flexDirection="column">
        {(blocks.length &&
          blocks.map((block) => (
            <LessonBlockItem block={block} disable={disableOnLoading} />
          ))) || <div>Добавьте первый блок</div>}
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header>{dataLesson?.getLesson.name}</Header>

      {content}

      <Box mt="30px" display="flex" justifyContent="space-between">
        <ButtonPlus
          width={179}
          padding="6px 16px"
          variant="contained"
          disabled={disableOnLoading}
          onClick={() => setIsOpenModalAddBlock(true)}
        >
          Добавить блок
        </ButtonPlus>

        <LessonAddBlockModal
          open={isOpenModalAddBlock}
          closeModal={setIsOpenModalAddBlock}
          createLessonBlock={createLessonBlock}
        />
      </Box>
    </Container>
  );
};
