import React, { useState } from "react";
import { Box, Card, List, ListItem, Paper, Popover } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { CustomIconButton } from "../shared/Buttons/CustomIconButton";

interface ITaskDeskMenuProps {
  variant: string;
  column?: string;
  actions?: {[key: string]: () => void}
}

export const KanbanMenu = ({ variant, column, actions } : ITaskDeskMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const menuItems = [
    {
      name: 'Добавить задачу',
      action: actions.add,
    },
  ];

  return (
    <>
      <CustomIconButton
        callback={(e) => setAnchorEl(e.currentTarget)}
        mainColor={variant === 'column' ? '#fff' : '#000'}
        children={<MoreHoriz />}
      />

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 36,
          horizontal: 12,
        }}
      >
        <Box
          maxWidth={166}
        >
          <Card>
            <Paper
              elevation={4}
            >
              <List>
                {menuItems.map(({ name, action }) => (
                  <ListItem
                    key={name}
                    onClick={action}
                    style={{ cursor: 'pointer' }}
                  >
                    {name}
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Card>
        </Box>
      </Popover>
    </>
  );
}