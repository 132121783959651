import { useHistory, useParams } from "react-router-dom";
import { Achieve } from "../../../generated/graphql";
import { Box, Table } from "@material-ui/core";
import { Menu } from "../../shared/Menu";
import { InfoTooltip } from "../../shared/InfoTooltip";
import { SmallUserAvatarStyle } from "../../shared/Style/Style";
import { DEFAULT_AVATAR_URL } from "../../shared/constants";

import {
  AchievementsTableHeadCell,
  AchievementsTableCell,
  AchievementsTableRow,
  WithoutAchievementsTableRow,
} from "./AchievementsTable.styles";
import {
  displayParticipants,
  getAchieveLockedState,
  getAchievePublishedStateTitle,
  getAchievePublishedStateColors,
} from "../utils";
import { tableHeaderMap, TOOLTIP_MSG } from "../constants";

interface AchievementsTableProps {
  achievements: Achieve[];
  setAchievementIdToDelete(id: string): void;
  openConfirmationModal(): void;
  publishAchieve(id: string): void;
}

export const AchievementsTable = ({
  achievements = [],
  setAchievementIdToDelete,
  openConfirmationModal,
  publishAchieve,
}: AchievementsTableProps) => {
  const { push } = useHistory();
  const { appId } = useParams<{ [key: string]: string }>();
  const getEditAchievementPath = (id: string) => `/mobileApps/${appId}/achievements/${id}/edit`;

  const menuItem = (id: string, isPublished: boolean) => [
    ...[(isPublished ? {
      name: "Снять с публикации",
      action: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        publishAchieve(id);
      },
    } : {
      name: "Опубликовать",
      action: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        publishAchieve(id);
      },
    })],
    {
      name: "Редактировать",
      action: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        push(getEditAchievementPath(id));
      },
    },
    {
      name: "Удалить",
      action: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setAchievementIdToDelete(id);
        openConfirmationModal();
      },
      color: "red",
    },
  ];

  return (
    <Table>
      <thead>
        <AchievementsTableRow>
          {Array.from(tableHeaderMap).map(([title, displayTooltip], i) => (
            <AchievementsTableHeadCell key={i}>
              <Box display="flex" alignItems="center">
                <span>{title}</span>
                {displayTooltip ? <InfoTooltip title={TOOLTIP_MSG} /> : null}
              </Box>
            </AchievementsTableHeadCell>
          ))}
        </AchievementsTableRow>
      </thead>
      <tbody>
        {achievements.length > 0 ? (achievements.map(
          ({ id, photo, name, isLocked, isPublished, isForTEACHER, isForPUPIL, isForPARENT, isForGUEST }) => (
            <AchievementsTableRow
              key={id}
              onClick={() => push(getEditAchievementPath(id))}
            >
              <AchievementsTableCell>
                <SmallUserAvatarStyle src={photo ? photo : DEFAULT_AVATAR_URL} />
              </AchievementsTableCell>
              <AchievementsTableCell>{name}</AchievementsTableCell>
              <AchievementsTableCell>{displayParticipants(isForTEACHER, isForPUPIL, isForPARENT, isForGUEST)}</AchievementsTableCell>
              <AchievementsTableCell>{getAchieveLockedState(isLocked)}</AchievementsTableCell>
              <AchievementsTableCell>
                <span style={{ color: getAchievePublishedStateColors(isPublished)}}>
                  {getAchievePublishedStateTitle(isPublished)}
                </span>
              </AchievementsTableCell>
              <AchievementsTableCell>
                <Menu
                  vertical={36}
                  horizontal={12}
                  width={170}
                  items={menuItem(id, isPublished)}
                />
              </AchievementsTableCell>
            </AchievementsTableRow>
          )
        )) : (
          <WithoutAchievementsTableRow>
            <AchievementsTableCell>Список наград пуст</AchievementsTableCell>
          </WithoutAchievementsTableRow>
        )}
      </tbody>
    </Table>
  );
};
