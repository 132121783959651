import { useMutation } from "@apollo/client";
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  InputAdornment,
  List,
  ListItem,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  ChangePtCheckListItemStatusDocument,
  ChangePtCheckListItemStatusMutation,
  CreatePtCheckListItemDocument,
  CreatePtCheckListItemMutation,
  DeletePtCheckListItemDocument,
  DeletePtCheckListItemMutation,
  GetProjectTaskDocument,
  ProjectTaskCheckListItem,
  UpdatePtCheckListItemDocument,
  UpdatePtCheckListItemMutation,
} from "../../../generated/graphql";
import { DeleteIconButton } from "../../shared/Buttons/DeleteIconButton";
import { EditIconButton } from "../../shared/Buttons/EditIconButton";
import { SaveIconButton } from "../../shared/Buttons/SaveIconButton";
import { DEFAULT_AVATAR_URL } from "../../shared/constants";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import {
  ButtonPlusSmall,
  ButtonCustom,
  ModalInput,
  primary,
} from "../../shared/Style/Style";
import { cutStringHandler } from "../../shared/Utils/StringOperations/cutStringHandler";
import {
  KanbanModalSubtitle,
  KanbanModalUserAvatar,
  CheckListItem,
} from "../KanbanTaskModal.style";

interface IChecklistProps {
  disabledOnLoading?: boolean;
  items: ProjectTaskCheckListItem[];
  taskId: string;
  description: string | null;
  saveTaskHandler(): void;
}

export const Checklist = ({
  disabledOnLoading,
  items,
  taskId,
                            description,
  saveTaskHandler,
}: IChecklistProps) => {
  const [creating, setCreating] = useState(false);
  const [chosenItem, setChosenItem] = useState("");

  const [name, setName] = useState("");
  const [error, setError] = useState(false);

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const hideHandler = () => {
    setCreating(false);
    setName("");
  };

  const [
    createItem,
    {
      data: createItemData,
      loading: createItemLoading,
      error: createItemError,
    },
  ] = useMutation<CreatePtCheckListItemMutation>(
    CreatePtCheckListItemDocument,
    {
      variables: {
        projectTaskId: taskId,
        name,
      },
      refetchQueries: [
        { query: GetProjectTaskDocument, variables: { id: taskId } },
      ],
    }
  );

  const [
    updateItem,
    {
      data: updateItemData,
      loading: updateItemLoading,
      error: updateItemError,
    },
  ] = useMutation<UpdatePtCheckListItemMutation>(
    UpdatePtCheckListItemDocument,
    {
      variables: {
        id: chosenItem,
        name,
      },
      refetchQueries: [
        { query: GetProjectTaskDocument, variables: { id: taskId } },
      ],
    }
  );

  const [
    deleteItem,
    {
      data: deleteItemData,
      loading: deleteItemLoading,
      error: deleteItemError,
    },
  ] = useMutation<DeletePtCheckListItemMutation>(
    DeletePtCheckListItemDocument,
    {
      refetchQueries: [
        { query: GetProjectTaskDocument, variables: { id: taskId } },
      ],
    }
  );

  const [
    changeItemStatus,
    {
      data: changeItemStatusData,
      loading: changeItemStatusLoading,
      error: changeItemStatusError,
    },
  ] = useMutation<ChangePtCheckListItemStatusMutation>(
    ChangePtCheckListItemStatusDocument,
    {
      refetchQueries: [
        { query: GetProjectTaskDocument, variables: { id: taskId } },
      ],
    }
  );

  //#region effects

  useEffect(() => {
    if (
      changeItemStatusData ||
      deleteItemData ||
      updateItemData ||
      createItemData
    ) {
      setRequestError(false);
      setName("");
      setChosenItem("");
    }
  }, [changeItemStatusData, deleteItemData, updateItemData, createItemData]);

  useEffect(() => {
    if (createItemData) {
      setCreating(false);
    }
  }, [createItemData]);

  useEffect(() => {
    if (
      changeItemStatusError ||
      deleteItemError ||
      updateItemError ||
      createItemError
    ) {
      setRequestError(true);
      setChosenItem("");
    }
  }, [
    changeItemStatusError,
    deleteItemError,
    updateItemError,
    createItemError,
  ]);

  useEffect(() => {
    if (deleteItemError) {
      setErrorMessage("При удалении пункта произошла ошибка");
    }
  }, [deleteItemError]);

  useEffect(() => {
    if (updateItemError) {
      setErrorMessage("При изменении пункта произошла ошибка");
    }
  }, [updateItemError]);

  useEffect(() => {
    if (createItemError) {
      setErrorMessage("При создании пункта произошла ошибка");
    }
  }, [createItemError]);

  useEffect(() => {
    if (changeItemStatusError) {
      setErrorMessage("При изменении статуса пункта произошла ошибка");
    }
  }, [changeItemStatusError]);

  // #endregion

  const saveItemHandler = () => {
    if (!name) {
      setError(true);
      return;
    }

    if (creating) {
      createItem();
      !description && saveTaskHandler();
      return;
    }
    updateItem();
  };

  const editHandler = (id: string, itemName: string): void => {
    if (chosenItem === id) {
      itemName !== name && saveItemHandler();
      setChosenItem("");
      return;
    }

    setName(itemName);
    setChosenItem(id);
  };

  // const setErrors = (): void => {
  //   setRequestError(true);
  //   setErrorMessage("Добавить чеклист можно только при редактировании");
  // };

  const disableOnItemsLoading =
    changeItemStatusLoading ||
    deleteItemLoading ||
    updateItemLoading ||
    createItemLoading;

  return (
    <>
      <Box
        mt={"20px"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <KanbanModalSubtitle>Чек-лист</KanbanModalSubtitle>

        <ButtonPlusSmall
          disabled={disabledOnLoading || creating || disableOnItemsLoading}
          //onClick={() => (taskId ? setCreating(true) : setErrors())}
          onClick={() => setCreating(true)}
        />
      </Box>

      <Collapse in={creating} collapsedSize={"0px"}>
        <Box mt={"10px"}>
          <ModalInput
            label="Описание пункта"
            value={name}
            error={error}
            onChange={({ target: { value } }) => setName(value)}
            disabled={disabledOnLoading || disableOnItemsLoading}
            fullWidth
            multiline
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box mr="-24px" display="flex" flexDirection="column">
                    <SaveIconButton
                      disabled={disabledOnLoading || disableOnItemsLoading}
                      save={saveItemHandler}
                    />

                    <DeleteIconButton
                      disabled={disabledOnLoading || disableOnItemsLoading}
                      deleteHandler={hideHandler}
                      item="пункт"
                    />
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Collapse>

      <List disablePadding>
        {items.length ? (
          items.map((item) => (
            <ListItem key={item.id} disableGutters>
              <CheckListItem
                control={
                  <Checkbox
                    disabled={
                      disabledOnLoading ||
                      disableOnItemsLoading ||
                      chosenItem === item.id
                    }
                    onChange={() =>
                      changeItemStatus({ variables: { id: item.id } })
                    }
                    checked={item.isCompleted}
                    color="default"
                  />
                }
                label={
                  <Box>
                    <ModalInput
                      value={chosenItem === item.id ? name : item.name}
                      onChange={({ target: { value } }) => setName(value)}
                      disabled={
                        disabledOnLoading ||
                        disableOnItemsLoading ||
                        chosenItem !== item.id
                      }
                      fullWidth
                      error={error}
                      InputProps={{
                        endAdornment: (
                          <Box mr="-13px" display="flex">
                            <EditIconButton
                              edit={() => editHandler(item.id, item.name)}
                              disabled={
                                disabledOnLoading || disableOnItemsLoading
                              }
                              active={chosenItem === item.id}
                            />
                            <DeleteIconButton
                              disabled={
                                disabledOnLoading ||
                                disableOnItemsLoading ||
                                chosenItem === item.id
                              }
                              deleteHandler={() =>
                                deleteItem({ variables: { id: item.id } })
                              }
                              item="пункт"
                            />
                          </Box>
                        ),
                      }}
                    />
                  </Box>
                }
              />
            </ListItem>
          ))
        ) : (
          <ListItem>
            <Box textAlign="center" width="100%">
              Чек-лист отсутствует
            </Box>
          </ListItem>
        )}
      </List>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </>
  );
};
