import { FC } from "react";
import { ButtonCustom } from "../../../../shared/Style/Style";
import { EditRobotButton, EditRobotContainer, EditRobotForm, EditRobotTitle } from "../../style";
import { RobotGeneralInfo } from "../RobotGeneralInfo";

interface RobotEditBlockProps {
    isLoading: boolean;
    updateRobotModel: () => void;
}

export const RobotEditBlock: FC<RobotEditBlockProps> = ({
    isLoading,
    updateRobotModel
}) => { 
    return (
        <EditRobotContainer elevation={6}>
            <EditRobotTitle>Основная информация</EditRobotTitle>
            
            <EditRobotForm>
                <RobotGeneralInfo />
            </EditRobotForm >

            <EditRobotButton>
                <ButtonCustom
                    disabled={isLoading}
                    onClick={updateRobotModel}
                >
                    СОХРАНИТЬ
                </ButtonCustom>
            </EditRobotButton>                  
        </EditRobotContainer>
    );
};
