import { useState, useEffect } from "react";
import { ApolloError } from "@apollo/client";

export const useRequestError = (error?: ApolloError | boolean, message = "") => {
  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (error) {
      setRequestError(true);

      if (message) {
        setErrorMessage(message);
      }
    }
  }, [error, message]);

  return { requestError, setRequestError, errorMessage };
};
