import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container } from "@material-ui/core";
import {
  ButtonCustom,
  ButtonPlusSmall,
  Header,
  LoadingBox,
} from "../shared/Style/Style";
import { Subtitle, VerticalLine } from "../UserEditApp/Style";
import { AvatarPicker } from "../shared/AvatarPicker";
import { DateAndLinkEvent, EventField } from "./Event.style";
import { useParams } from "react-router-dom";
import {
  Event,
  GetEventDatesDocument,
  GetEventDocument,
  GetEventQuery,
  UpdateEventDocument,
  UpdateEventMutation,
} from "../../generated/graphql";
import { useMutation, useQuery } from "@apollo/client";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { CreateEventDateModal } from "./EventDate/CreateEventDateModal";
import { EventDates } from "./EventDate/EventDates";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { EventDatesList } from "./EventDate/EventDatesList";

export const UpdateEvent = () => {
  const { id } = useParams<{ id: string }>();

  const [editEventData, setEditEventData] = useState<Event>(null);

  const [file, setFile] = useState(null);
  const [isEventDateModal, setIsEventDateModal] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    data: eventData,
    loading: eventLoading,
    error: eventError,
  } = useQuery<GetEventQuery>(GetEventDocument, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });

  const [
    updateEvent,
    {
      data: updateEventData,
      loading: updateEventLoading,
      error: updateEventError,
    },
  ] = useMutation<UpdateEventMutation>(UpdateEventDocument, {
    variables: {
      id: editEventData?.id,
      name: editEventData?.name,
      photo: file,
      description: editEventData?.description,
      speaker: editEventData?.speaker,
    },
    refetchQueries: [
      {
        query: GetEventDocument,
        variables: { id },
      },
    ],
  });

  const updateEventHandler = async (id: string) => {
    try {
      await updateEvent();
    } catch (e) {
      setRequestError(true);
      setErrorMessage("При редактировании произошла ошибка");
    }
  };

  useEffect(() => {
    if (eventData) {
      setEditEventData(eventData.getEvent);
    }
  }, [eventData]);

  let content;

  if (eventLoading || updateEventLoading) {
    return (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (eventError || updateEventError) {
    return <ErrorSnackbar error={eventError} />;
  }

  if (eventData) {
    content = (
      <>
        <Header>Редактирования Мероприятия</Header>
        <Box
          display="flex"
          boxShadow={3}
          p="20px 36px"
          borderRadius="5px"
          mt="40px"
          mb="26px"
          flexWrap="wrap"
        >
          <Box flex="0 0 100%" display="flex" flexDirection="column">
            <Subtitle>Редактирования мероприятия</Subtitle>
            <Box display="flex">
              <Box flex="0 0 15%">
                <AvatarPicker
                  photo={editEventData?.photo}
                  uploadHandler={(newFile) => setFile(newFile)}
                />
              </Box>
              <Box flex="0 0 35%" pr="20px" flexDirection="column">
                <EventField
                  fullWidth
                  label="Название мероприятия"
                  value={editEventData?.name}
                  onChange={({ target: { value } }) =>
                    setEditEventData({ ...editEventData, name: value })
                  }
                />
                <DateAndLinkEvent
                  style={{ paddingLeft: 0 }}
                  button
                  onClick={() => setIsEventDateModal(true)}
                >
                  Дата и ссылка на мероприятие
                  <ButtonPlusSmall />
                </DateAndLinkEvent>

                {editEventData?.dates?.length ? (
                  <EventDates dates={editEventData?.dates} eventId={id} />
                ) : null}

                <EventField
                  fullWidth
                  label="Спикер"
                  value={editEventData?.speaker}
                  onChange={({ target: { value } }) =>
                    setEditEventData({ ...editEventData, speaker: value })
                  }
                />
                <EventField
                  style={{ padding: 0 }}
                  fullWidth
                  multiline
                  rows={2}
                  label="Описание"
                  value={editEventData?.description}
                  onChange={({ target: { value } }) =>
                    setEditEventData({ ...editEventData, description: value })
                  }
                />
              </Box>
              <VerticalLine />
              <Box width="50%" display="flex" justifyContent="space-between">
                <Box mt={2} ml={2} display="flex">
                  <EventDatesList dates={editEventData?.dates} />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mt={2} display="flex" width="100%" justifyContent="center">
            <ButtonCustom onClick={() => updateEventHandler(id)}>
              Сохранить
            </ButtonCustom>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <Container>
      {content}

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
      <CreateEventDateModal
        eventId={id}
        open={isEventDateModal}
        close={() => setIsEventDateModal(false)}
        requestError={requestError}
        setRequestError={setRequestError}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </Container>
  );
};
