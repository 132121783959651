import React, { createRef, useState } from "react";
import { Redirect } from "react-router-dom";
import { Form } from "react-final-form";
import { useMutation } from "@apollo/client";
import {CreateUserDocument, CreateUserMutation, IsFromEnum} from "../../generated/graphql";
import { Toolbar } from "@material-ui/core";
import { BaseInfo } from "./BaseInfo";
import { PasswordInfo } from "./PasswordInfo";
import { RegistrationSuccess } from "./RegistrationSuccess";
import { InitialFormError } from "./Registration.type";
import {
  AppBarStyle,
  BaseContainer,
  BlockTitle,
  LoginForm,
  RegistrationWrapper,
} from "../Login/Login.style";

export const initialFormError: InitialFormError = {
  firstNameError: "",
  lastNameError: "",
  phoneError: "",
  emailError: "",
  passwordError: "",
  confirmPasswordError: "",
};

export function Registration() {
  const [createUser, { data, loading }] = useMutation<CreateUserMutation>(CreateUserDocument);
  const [password, setPassword] = useState<string>("");
  const [formError, setFormError] = useState(initialFormError);
  const swipableViews = createRef<any>();

  if (data) {
    return <Redirect to="/register/success" />;
  }

  return (
    <LoginForm>
      <AppBarStyle position="fixed">
        <Toolbar />
      </AppBarStyle>
      <RegistrationWrapper>
        {data && <RegistrationSuccess password={password} />}
        {!data && (
          <Form onSubmit={() => {}}>
            {() => (
              <>
                <BlockTitle>Регистрация</BlockTitle>

                <BaseContainer minHeight={583}>
                  <BaseInfo formError={formError} setFormError={setFormError} />

                  <PasswordInfo
                    title={"Зарегистрироваться"}
                    loading={loading}
                    onError={() => {
                      if (swipableViews.current) {
                        swipableViews.current.updateHeight();
                      }
                    }}
                    onNext={async (nextData) => {
                      const { confirmPassword, ...userData } = nextData;
                      setPassword(confirmPassword);
                      await createUser({
                        variables: {
                          userData,
                          isFrom: IsFromEnum.AdminPanel
                        },
                      });
                    }}
                    formError={formError}
                    setFormError={setFormError}
                  />
                </BaseContainer>
              </>
            )}
          </Form>
        )}
      </RegistrationWrapper>
    </LoginForm>
  );
}
