import React, { useState, useEffect } from "react";

import { CircularProgress, Container, List, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import {
  Header,
  ButtonPlus,
  LoadingBox,
  MainTableImage,
} from "../../shared/Style/Style";

import { AnnouncementsNavBar } from "../../AnnouncementsNavBar";

import {
  AddButtonWrapper,
  AnnouncementsText,
  AnnouncementsTitle,
  AnnouncementsListItem,
  NoAnnouncementsListText,
} from "../Announcements.style";

import { useQuery, useMutation } from "@apollo/client";
import {
  CreateNewsDocument,
  CreateNewsMutation,
  GetNewsDocument,
  GetNewsQuery,
  News,
  NewsIsForEnum,
} from "../../../generated/graphql";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";

import { NewsListItem } from "./News.style";
import { NewsMenu } from "../../NewsMenu";
import { NewsModal } from "../../NewsModal";
import { DEFAULT_AVATAR_URL } from "../../shared/constants";
import { cutStringHandler } from "../../shared/Utils/StringOperations/cutStringHandler";
import { CustomTablePagination } from "../../shared/CustomTablePagination";

export const NewsPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [chosenNews, setChosenNews] = useState<News | null>(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const modalOpenHandler = (): void => {
    setModalOpen(true);
  };

  const modalCloseHandler = (): void => {
    setModalOpen(false);
  };

  //#region create notification
  const [createError, setCreateError] = useState(false);

  const [
    createNews,
    {
      data: createNewsData,
      loading: createNewsLoading,
      error: createNewsError,
    },
  ] = useMutation<CreateNewsMutation>(CreateNewsDocument, {
    variables: {
      newsData: { name: "", description: "", isFor: NewsIsForEnum.AdminPanel },
    },
  });

  const createNewsHandler = () => {
    setChosenNews(null);
    createNews();
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (createNewsError) {
      setCreateError(true);
    }

    if (createNewsData) {
      setCreateError(false);
      setModalOpen(true);
    }
  }, [createNewsError, createNewsData]);

  //#endregion

  const {
    data: newsData,
    loading: newsLoading,
    error: newsError,
  } = useQuery<GetNewsQuery>(GetNewsDocument, {
    variables: {
      limit: rowsPerPage,
      skip: page * rowsPerPage,
      isPublished: null,
    },
    fetchPolicy: "cache-and-network",
  });
  const pagination = (
    <CustomTablePagination
      rowsPerPageOptions={[10, 20]}
      count={newsData?.getNews?.total || 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );

  let content;

  if (newsLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (newsError) {
    content = <ErrorSnackbar error={newsError} />;
  }

  if (newsData) {
    const newsList = newsData.getNews?.news as News[];

    content = newsList?.length ? (
      newsList.map((news: News) => (
        <NewsListItem key={news?.id} onClick={() => setChosenNews(news)}>
          <div>
            {news?.publishedAt
              ? new Date(news?.publishedAt).toLocaleDateString()
              : "Не опубликовано"}
          </div>

          <div>
            {news?.publishedAt
              ? new Date(news?.publishedAt)
                  .toLocaleTimeString()
                  .split(":")
                  .slice(0, 2)
                  .join(":")
              : ""}
          </div>

          <MainTableImage src={news.photo ? news.photo : DEFAULT_AVATAR_URL} />

          <div>
            <AnnouncementsTitle>{news?.name}</AnnouncementsTitle>
            <AnnouncementsText>
              {cutStringHandler(news?.description)}
            </AnnouncementsText>
          </div>

          <NewsMenu
            openModal={modalOpenHandler}
            newsId={news.id}
            isPublished={news.isPublished}
          />
        </NewsListItem>
      ))
    ) : (
      <AnnouncementsListItem>
        <NoAnnouncementsListText>Новости отсутствуют</NoAnnouncementsListText>
      </AnnouncementsListItem>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header>Новости</Header>

      <AddButtonWrapper>
        <AnnouncementsNavBar />

        <ButtonPlus
          width={180}
          disabled={createNewsLoading}
          variant="contained"
          onClick={createNewsHandler}
        >
          Добавить
        </ButtonPlus>
      </AddButtonWrapper>
      {pagination}
      <List>{content}</List>
      {pagination}
      <NewsModal
        news={chosenNews || createNewsData?.createNews}
        open={modalOpen}
        close={modalCloseHandler}
      />

      {createError && (
        <Snackbar
          open={createError}
          autoHideDuration={6000}
          onClose={() => setCreateError(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity="error">Не удалось создать уведомление</Alert>
        </Snackbar>
      )}
    </Container>
  );
};
