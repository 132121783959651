import React, { Fragment } from "react";
import { Grid, TextField } from "@material-ui/core";
import { Field } from "react-final-form";
import { isNumber } from "../../../../core/validators/validators";
import { ThemeProvider } from "@material-ui/styles";
import { DatePicker } from "@material-ui/pickers";
import { DictionarySelect } from "../DictionarySelect";
import { defaultMaterialTheme, useStyles } from "../../../shared/Style/Style";
import {
  UserAgreementType,
  UserOptionFields,
} from "../../../../generated/graphql";

export const BaseRateSalary = ({ options }: { options: UserOptionFields }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item={true} xs={12}>
          <Field name="hireDate">
            {({ input, meta }) => (
              <>
                <ThemeProvider theme={defaultMaterialTheme}>
                  <DatePicker
                    error={meta.error && meta.touched}
                    helperText={meta.touched && meta.error}
                    fullWidth
                    margin="normal"
                    variant="inline"
                    format="dd.MM.yyyy"
                    id="date-picker-hireDate"
                    label="Дата принятия на работу"
                    inputVariant="filled"
                    value={input.value || null}
                    onChange={input.onChange}
                  />
                </ThemeProvider>
              </>
            )}
          </Field>
        </Grid>

        <Grid item={true} xs={12}>
          <Field name="salary" validate={isNumber}>
            {({ input, meta }) => (
              <TextField
                className={classes.root}
                error={meta.error && meta.touched}
                margin="normal"
                fullWidth
                helperText={meta.touched && meta.error}
                label="Ставка"
                variant="filled"
                type="text"
                disabled
                {...input}
              />
            )}
          </Field>
        </Grid>

        <Grid item={true} xs={12}>
          <Field name="bonuses">
            {({ input, meta }) => (
              <TextField
                className={classes.root}
                error={meta.error && meta.touched}
                margin="normal"
                fullWidth
                helperText={meta.touched && meta.error}
                label="Бонусы"
                variant="filled"
                type="text"
                {...input}
              />
            )}
          </Field>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item={true} xs={12}>
          <Field name="rate" validate={isNumber}>
            {({ input, meta }) => (
              <TextField
                className={classes.root}
                error={meta.error && meta.touched}
                margin="normal"
                fullWidth
                helperText={meta.touched && meta.error}
                label="Рейт"
                variant="filled"
                type="text"
                disabled
                {...input}
              />
            )}
          </Field>
        </Grid>

        <Grid xs={12} item>
          <Field name="agreementType">
            {({ input, meta }) => (
              <DictionarySelect
                label="Занятость"
                input={input}
                meta={meta}
                values={options?.agreementType as UserAgreementType[]}
              />
            )}
          </Field>
        </Grid>
      </Grid>
    </>
  );
};
