import React, { Fragment } from "react";
import { Grid, TextField } from "@material-ui/core";
import { Field } from "react-final-form";
import { isNumber } from "../../../../core/validators/validators";
import { ThemeProvider } from "@material-ui/styles";
import { DatePicker } from "@material-ui/pickers";
import { defaultMaterialTheme, useStyles } from "../../../shared/Style/Style";

export const BaseDateCard = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item={true} xs={12}>
          <Field name="loadPercent" validate={isNumber}>
            {({ input, meta }) => (
              <Fragment>
                <TextField
                  className={classes.root}
                  error={meta.error && meta.touched}
                  margin="normal"
                  fullWidth
                  helperText={meta.touched && meta.error}
                  label="Процент занятости"
                  variant="filled"
                  type="text"
                  {...input}
                />
              </Fragment>
            )}
          </Field>
        </Grid>
        <Grid item={true} xs={12}>
          <Field name="promotionDate">
            {({ input, meta }) => (
              <>
                <ThemeProvider theme={defaultMaterialTheme}>
                  <DatePicker
                    error={meta.error && meta.touched}
                    helperText={meta.touched && meta.error}
                    fullWidth
                    margin="normal"
                    variant="inline"
                    format="dd.MM.yyyy"
                    id="date-picker-inline"
                    label="Дата пересмотра ЗП"
                    inputVariant="filled"
                    value={input.value || null}
                    onChange={input.onChange}
                  />
                </ThemeProvider>
              </>
            )}
          </Field>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item={true} xs={12}>
          <Field name="previousSalary" validate={isNumber}>
            {({ input, meta }) => (
              <Fragment>
                <TextField
                  className={classes.root}
                  error={meta.error && meta.touched}
                  margin="normal"
                  fullWidth
                  helperText={meta.touched && meta.error}
                  label="ЗП до повышения"
                  variant="filled"
                  type="text"
                  disabled
                  {...input}
                />
              </Fragment>
            )}
          </Field>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item={true} xs={12}>
          <Field name="promotionReason">
            {({ input, meta }) => (
              <Fragment>
                <TextField
                  className={classes.root}
                  error={meta.error && meta.touched}
                  margin="normal"
                  fullWidth
                  helperText={meta.touched && meta.error}
                  label="Причина повышения"
                  variant="filled"
                  type="text"
                  {...input}
                />
              </Fragment>
            )}
          </Field>
        </Grid>
        <Grid item={true} xs={12}>
          <Field name="creditCard" validate={isNumber}>
            {({ input, meta }) => (
              <Fragment>
                <TextField
                  className={classes.root}
                  error={meta.error && meta.touched}
                  margin="normal"
                  fullWidth
                  helperText={meta.touched && meta.error}
                  label="Номер карты"
                  variant="filled"
                  type="text"
                  {...input}
                />
              </Fragment>
            )}
          </Field>
        </Grid>
      </Grid>
    </>
  );
};
