import React, { useEffect, useState } from "react";
import { Box, Grid, TextField } from "@material-ui/core";
import { SaveIconButton } from "../../../shared/Buttons/SaveIconButton";
import { EditIconButton } from "../../../shared/Buttons/EditIconButton";
import { DeleteIconButton } from "../../../shared/Buttons/DeleteIconButton";
import {
  DeleteProjectLinkDocument,
  DeleteProjectLinkMutation,
  GetProjectDocument,
  ProjectLink,
  UpdateProjectLinkDocument,
  UpdateProjectLinkMutation,
} from "../../../../generated/graphql";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { useMutation } from "@apollo/client";
import { LinkData } from "../ProjectAddLinkModal";
import { ErrorMessageSnackbar } from "../../../shared/ErrorMessageSnackbar";

interface ProjectLinkItemProps {
  link: ProjectLink;
  projectId: string;
  classes: ClassNameMap<"root">;
}

interface EditLinkData extends LinkData {
  id: string;
}

export const ProjectLinkItem = ({
  link,
  projectId,
  classes,
}: ProjectLinkItemProps) => {
  const [currentLink, setCurrentLink] = useState<EditLinkData>({
    id: "",
    title: "",
    link: "",
  });

  const [requestError, setRequestError] = useState(false);

  const [
    updateProjectLink,
    { data: updateProjectLinkData, loading: updateLoading, error: updateError },
  ] = useMutation<UpdateProjectLinkMutation>(UpdateProjectLinkDocument, {
    refetchQueries: [{ query: GetProjectDocument, variables: { projectId } }],
  });

  const [deleteProjectLink, { loading: deleteLoading, error: deleteError }] =
    useMutation<DeleteProjectLinkMutation>(DeleteProjectLinkDocument, {
      refetchQueries: [{ query: GetProjectDocument, variables: { projectId } }],
    });

  useEffect(() => {
    if (updateProjectLinkData) {
      setCurrentLink({ id: "", title: "", link: "" });
    }
  }, [updateProjectLinkData]);

  useEffect(() => {
    if (updateError || deleteError) {
      setRequestError(true);
    }
  }, [updateError, deleteError]);

  return (
    <Box key={link?.id} mb={1}>
      <Grid
        container
        justifyContent="center"
        spacing={2}
        style={{ position: "relative" }}
      >
        <Grid xs={6} item>
          <TextField
            className={classes.root}
            fullWidth
            label="Название"
            variant="filled"
            type="text"
            disabled={currentLink.id !== link.id}
            value={currentLink.id === link.id ? currentLink.title : link.title}
            onChange={(e) =>
              setCurrentLink({
                ...currentLink,
                title: e.target.value,
              })
            }
          />
        </Grid>

        <Grid xs={6} item>
          <TextField
            className={classes.root}
            fullWidth
            label="Ссылка"
            variant="filled"
            type="text"
            disabled={currentLink.id !== link.id}
            value={currentLink.id === link.id ? currentLink.link : link.link}
            onChange={(e) =>
              setCurrentLink({
                ...currentLink,
                link: e.target.value,
              })
            }
          />

          <Box style={{ position: "absolute", right: "40px", top: "10px" }}>
            {currentLink.id === link.id ? (
              <SaveIconButton
                disabled={updateLoading || deleteLoading}
                fz={14}
                save={() =>
                  updateProjectLink({
                    variables: { projectLinkData: currentLink },
                  })
                }
              />
            ) : (
              <EditIconButton
                disabled={link.id === currentLink.id}
                fz={14}
                edit={() =>
                  setCurrentLink({
                    id: link.id,
                    title: link.title,
                    link: link.link,
                  })
                }
              />
            )}
          </Box>

          <Box style={{ position: "absolute", right: "8px", top: "8px" }}>
            <DeleteIconButton
              disabled={updateLoading || deleteLoading}
              deleteHandler={() =>
                deleteProjectLink({
                  variables: { projectLinkId: link.id },
                })
              }
              item="ссылку"
              fz={14}
            />
          </Box>
        </Grid>
      </Grid>

      {link?.login && (
        <Box mt={-2}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid xs={6} item>
              <TextField
                className={classes.root}
                fullWidth
                label="Логин"
                variant="filled"
                type="text"
                disabled={currentLink.id !== link.id}
                value={currentLink.login || link.login}
                onChange={(e) =>
                  setCurrentLink({
                    ...currentLink,
                    login: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid xs={6} item>
              <TextField
                className={classes.root}
                fullWidth
                label="Пароль"
                variant="filled"
                type="text"
                disabled={currentLink.id !== link.id}
                value={currentLink.password || link.password}
                onChange={(e) =>
                  setCurrentLink({
                    ...currentLink,
                    password: e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={
          (updateError && "Ошибка при редактировании ссылки") ||
          (deleteError && "Ошибка при удалении ссылки")
        }
      />
    </Box>
  );
};
