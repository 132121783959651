import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

import { useMutation } from "@apollo/client";
import {
  CreateProjectByJiraDataDocument,
  CreateProjectByJiraDataMutation,
  CreateProjectDocument,
  CreateProjectMutation,
  GetProjectsDocument,
} from "../../../generated/graphql";

import { Box, TextField, Modal, ThemeProvider } from "@material-ui/core";
import {
  ProjectModalButtonsContainer,
  ProjectModalContainer,
  ProjectModalDialogContent,
} from "./AddProjectModal.style";

import { ButtonCustom, ButtonCustomCancel, ButtonCustomWithDarkText, defaultMaterialTheme, ModalInput } from "../../shared/Style/Style";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";

interface AddProjectModalProps {
  open: boolean;
  handleClose(): void;
  limit: number;
  skip: number;
  statuses: string[];
}

export const AddProjectModal = ({
  open,
  handleClose,
  limit,
  skip,
  statuses
}: AddProjectModalProps) => {
  const [isJiraProject, setJiraProject] = useState(true);

  const [jira, setJira] = useState("");
  const [jiraKey, setJiraKey] = useState("");
  const [name, setName] = useState("");

  const [errors, setErrors] = useState({
    name: false,
    jira: false,
    jiraKey: false,
    uniqueKey: false,
  });

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [createProject, {
      data: createProjectData,
      loading: createProjectLoading,
      error: createProjectError,
  }] = useMutation<CreateProjectMutation>(
    CreateProjectDocument, {
      variables: {
        name,
        jiraKey
      },
      refetchQueries: [
        {
          query: GetProjectsDocument,
          variables: {
            getProjectsInput: {
              limit,
              skip,
              statuses,
            },
          },
        },
      ],
    }
  );

  const [createProjectByJira, {
     data: createProjectByJiraData,
     loading: createProjectByJiraLoading,
     error: createProjectByJiraError
  }] = useMutation<CreateProjectByJiraDataMutation>(
    CreateProjectByJiraDataDocument, {
      variables: {
        jira,
        jiraKey
      },
      refetchQueries: [
        {
          query: GetProjectsDocument,
          variables: {
            getProjectsInput: {
              limit,
              skip,
              statuses,
            },
          },
        },
      ],
    }
  );

  const disabled = createProjectByJiraLoading || createProjectLoading;

  useEffect(() => {
    if (createProjectError) {
      setRequestError(true);
      setErrorMessage('При загрузке проекта произошла ошибка');
    }
  }, [createProjectError]);

  useEffect(() => {
    if (createProjectByJiraError) {
      setRequestError(true);
      setErrorMessage('При создании проекта произошла ошибка');
    }
  }, [createProjectByJiraError]);

  if (createProjectData) {
    return (
      <Redirect to={`/project/${createProjectData.createProject.id}/edit`} />
    );
  }

  if (createProjectByJiraData) {
    return (
      <Redirect to={`/project/${createProjectByJiraData.createProjectByJiraData.id}/edit`} />
    );
  }


  const checkUniqueKeyHandler = async(value: string): Promise<boolean> => {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/validate/project/jiraKey/${value.toUpperCase()}`
    );

    return res.data.exists
  }

  const closeHandler = () => {
    setJiraKey('');
    setJira('');
    setName('');
    setErrors({
      name: false,
      jira: false,
      jiraKey: false,
      uniqueKey: false,
    });
    handleClose();
  }

  const changeTabHandler = (tab: string): void => {
    setJiraKey('');
    setName('');
    setJira('');
    setErrors({
      name: false,
      jira: false,
      jiraKey: false,
      uniqueKey: false,
    });
    setJiraProject(tab === 'jira' ? true : false)
  }

  const createHandler = async () => {
    if (!jiraKey) {
      setErrors(state => ({
        ...state,
        jiraKey: true
      }))
    }

    if (isJiraProject) {
      if (!jira) {
        setErrors(state => ({
          ...state,
          jira: true
        }))
      }

      if (!jira || !jiraKey) {
        return
      }

    } else {
      if (!name) {
        setErrors(state => ({
          ...state,
          name: true
        }))
      }

      if (!name || !jiraKey) {
        return
      }
    }

    const checkUniqueKey = await checkUniqueKeyHandler(jiraKey);

    console.log(checkUniqueKey);

    if (checkUniqueKey) {
      setErrors(state => ({
        ...state,
        uniqueKey: true
      }))
      return;
    }

    isJiraProject ? createProjectByJira() : createProject()
  }

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ProjectModalContainer>
        <ProjectModalDialogContent>
          <Box
            display='flex'
            justifyContent='space-between'
            mb={1}
          >
            <Box mr={1}>
              <ButtonCustomWithDarkText
                active={isJiraProject}
                disabled={disabled}
                onClick={() => changeTabHandler('jira')}
              >
                Выбрать проект из Jira
              </ButtonCustomWithDarkText>
            </Box>

            <ButtonCustomWithDarkText
              active={!isJiraProject}
              disabled={disabled}
              onClick={() => changeTabHandler('local')}
            >
              Создать свой проект
            </ButtonCustomWithDarkText>
          </Box>

          <ThemeProvider theme={defaultMaterialTheme}>
            <TextField
              autoFocus
              disabled={disabled}
              margin="dense"
              label={isJiraProject ? "Введите jira" : "Введите название проекта"}
              fullWidth
              error={isJiraProject ? errors.jira : errors.name}
              helperText={
                (errors.jira && 'Jira отсутствует')
                || (errors.name && 'Имя проекта отсутствует')
              }
              value={isJiraProject ? jira : name}
              onChange={({ target }) => {
                isJiraProject
                ? setJira(target.value)
                : setName(target.value)

                setErrors({...errors, [isJiraProject ? 'jira' : 'name']: false})
              }}
            />

            <TextField
              margin="dense"
              disabled={disabled}
              label="Введите ключ проекта"
              fullWidth
              error={errors.jiraKey || errors.uniqueKey}
              helperText={
                (errors.jiraKey && 'Ключ проекта отсутствует')
                || (errors.uniqueKey && 'Проект с таким ключём уже существует')
              }
              value={jiraKey}
              onChange={({ target }) => {
                setJiraKey(target.value);
                setErrors({...errors, jiraKey: false, uniqueKey: false})
              }}
            />
          </ThemeProvider>

          <ProjectModalButtonsContainer>
            <Box mr={2}>
              <ButtonCustom
                onClick={createHandler}
                disabled={disabled}
              >
                Добавить
              </ButtonCustom>
            </Box>

            <ButtonCustomCancel
              disabled={disabled}
              onClick={closeHandler}
            >
              Отмена
            </ButtonCustomCancel>
          </ProjectModalButtonsContainer>
        </ProjectModalDialogContent>

        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => {
            setRequestError(false);
            setErrorMessage('')
          }}
          message={errorMessage}
        />
      </ProjectModalContainer>
    </Modal>
  );
};
