import styled from "styled-components";
import { Button, DialogContent, MenuItem } from "@material-ui/core";

export const MediaCardContainer = styled.div<{ $isLocked?: boolean; }>`
  position: relative;
  pointer-events: ${({ $isLocked }) => ($isLocked ? "none" : "auto")};
  opacity: ${({ $isLocked }) => ($isLocked ? 0.5 : 1)};
  padding: 16px 0;
  opacity: 1;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 8px;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 256px;
  height: 146px;
  border-radius: 8px;
  border: 2px solid #DDDFE5;
  background: #F1F2F3;

  & img,
  iframe {
    width: 256px;
    height: 146px;
    border-radius: 8px;
    outline: 2px solid #FFFFFF;
    pointer-events: none;
  }
`;

export const DeleteButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;

  & svg {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }

  & svg > path {
    fill: #f50057;
    stroke: #FFFFFF;
  }
`;

export const CardMediaContainer = styled(CardContainer)`
  position: relative;

  &:hover {
    ${DeleteButtonContainer} {
      display: flex;
      z-index: 10;
    }
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 1;
      transition: opacity 0.3s ease;
      z-index: 5;
      border-radius: 8px;
    }
  }
`;

export const DeleteMediaButton = styled(Button)`
  &.MuiButton-root {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    text-transform: capitalize;
    outline: none;
    background: #f50057;
    color: #FFFFFF;
    padding: 6px 12px;
    transition: outline 0.3s ease;

    &:hover {
      outline: 1px solid #FFFFFF;
      background: #f50057;
      color: #FFFFFF;
    }
  }
`;

export const AddMediaButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  border-radius: 6px;
  border: 2px solid #DDDFE5;
  background: ${({ disabled }) => (disabled ? "inherit" : "#FFFFFF")};
  box-shadow: 0 1px 2px 0 rgba(31, 36, 44, 0.12);
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  padding: 8px;

  & svg {
    width: 16px;
    height: 16px;
  }

  & svg > path {
    fill: ${({ disabled }) => (disabled ? "#CACCCF" : "#1F1F1F")};
  }
`;

export const MenuItemContainer = styled(MenuItem)`
 & > div {
    display: flex;
    align-items: center;
    & > svg {
      margin-right: 8px;
    }
 }
`;

export const StyledDialogContent = styled(DialogContent)`
  & div > .cropper-crop-box, .cropper-view-box {
    border-radius: 0;
  }
`;