import {KnowledgeBaseBlockTypeEnum} from "./../../../../generated/graphql";

export const getKnowledgeBaseInputLabel = (type: KnowledgeBaseBlockTypeEnum): string => {
  switch (type) {
    case KnowledgeBaseBlockTypeEnum.Header:
      return 'Заголовок';

    case KnowledgeBaseBlockTypeEnum.Subtitle:
      return 'Подзаголовок';

    case KnowledgeBaseBlockTypeEnum.Text:
      return 'Тектсовый блок';

    case KnowledgeBaseBlockTypeEnum.Formula:
      return 'Формула';

    case KnowledgeBaseBlockTypeEnum.Question:
      return 'Вопрос';

    case KnowledgeBaseBlockTypeEnum.Test:
      return 'Тестовый вопрос';

    case KnowledgeBaseBlockTypeEnum.Video:
      return 'Ссылка на видео';

    case KnowledgeBaseBlockTypeEnum.MultiList:
      return 'Название шага'

    default:
      return 'Тектсовый блок';
  }
}