import React, { useEffect, useState } from "react";

import { useParams } from "react-router";

import { useMutation, useQuery } from "@apollo/client";
import {
  Application,
  ApplicationFileTypeEnum,
  DeleteApplicationFileDocument,
  DeleteApplicationFileMutation,
  GetApplicationDocument,
  GetApplicationQuery,
  UpdateApplicationDocument,
  UpdateApplicationFilesDocument,
  UpdateApplicationFilesMutation,
  UpdateApplicationMutation
} from "../../generated/graphql";

import { Box, CircularProgress, Container, Divider, Paper } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ThemeProvider } from "@material-ui/styles";


import {
  ButtonCustom,
  defaultMaterialTheme,
  Header,
  LoadingBox,
  HiddenInput,
  UnderlayButton,
  HiddenInputLabel,
  secondary
} from "../shared/Style/Style";

import {
  MobileAppInput,
  MobileAppSubtitle,
  MobileAppInputTitle,
  FileName
} from "./MobileAppEdit.style";

import { NavBar } from "../shared/NavBar";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { MobileAppEditLinksList } from "./MobileAppEditLinksList";
import { CustomIconButton } from "../shared/Buttons/CustomIconButton";
import { AvatarPicker } from "../shared/AvatarPicker";

import { getFileExtensionHandler } from "../shared/Utils/StringOperations/getFileExtensionHandler";

import { navItems } from "../../constants/navItemMobileApp";

const InitialErrors: {[key: string]: boolean} = {
  name: false
}

export const MobileAppEdit = () => {
  const { appId } = useParams<{appId: string}>();

  const [ editingData, setEditingData ] = useState<Application>(null);

  const [errors, setErrors] = useState(InitialErrors);

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  //#region files

  const [updateFile, {
    loading: updateFileLoading,
    error: updateFileError,
  }] = useMutation<UpdateApplicationFilesMutation>(
    UpdateApplicationFilesDocument, {
      refetchQueries: [{ query: GetApplicationDocument, variables: { id: appId } }]
    }
  );

  const [deleteFile, {
    loading: deleteFileLoading,
    error: deleteFileError,
  }] = useMutation<DeleteApplicationFileMutation>(
    DeleteApplicationFileDocument, {
      refetchQueries: [{ query: GetApplicationDocument, variables: { id: appId } }]
    }
  );

  //#endregion

  //#region update

  const [updateApp, {
    loading: updateAppLoading,
    error: updateAppError,
  }] = useMutation<UpdateApplicationMutation>(
    UpdateApplicationDocument, {
      variables: {
        applicationData: {
          id: editingData?.id,
          name: editingData?.name,
          description: editingData?.description,
          appStoreLink: editingData?.appStoreLink,
          playMarketLink: editingData?.playMarketLink,
          mainPhoto: editingData?.mainPhoto,
          modelPhoto: editingData?.modelPhoto,
          email: editingData?.email,
          phone: editingData?.phone,
          comments: editingData?.comments,
        }
      },
      refetchQueries: [{ query: GetApplicationDocument, variables: { id: appId } }]
    }
  );

  const updateAppHandler = () => {
    if (!editingData.name) {
      setErrors({ ...errors, name: true });
      return;
    }

    updateApp();
  }

  //#endregion

  const {
    data: appData,
    loading: appLoading,
    error: appError,
  } = useQuery<GetApplicationQuery>(
    GetApplicationDocument, {
      variables: {
        id: appId,
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  //#region effects

  useEffect(() => {
    if (appData) {
      setEditingData(appData.getApplication);
    }
  }, [appData]);

  useEffect(() => {
    if (updateAppError) {
      setRequestError(true);
      setErrorMessage('При обновлении информации о приложении произошла ошибка');
    }
  }, [updateAppError]);

  useEffect(() => {
    if (updateFileError) {
      setRequestError(true);
      setErrorMessage('При обновлении файла произошла ошибка');
    }
  }, [updateFileError]);

  useEffect(() => {
    if (deleteFileError) {
      setRequestError(true);
      setErrorMessage('При удалении файла произошла ошибка');
    }
  }, [deleteFileError]);

  //#endregion

  const disabledOnLoading = updateAppLoading || updateFileLoading || deleteFileLoading;

  let content;

  if (appLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit' />
      </LoadingBox>
    )
  }

  if (appError) {
    content = <ErrorSnackbar error={appError} />
  }

  if (editingData) {
    const { id, description, photo, name, email, mainPhoto, modelPhoto, phone, comments, links, appStoreLink, playMarketLink, privacyPolicy, userAgreement } = editingData;

    content = (
      <Box
        display='flex'
        justifyContent='space-between'
        padding={'20px 30px 0 30px'}
      >
        <Box
          mr={2}
          flexBasis={'50%'}
        >
          <MobileAppSubtitle>
            Основная информация
          </MobileAppSubtitle>

          <Box
            mb={2}
            display='flex'
            justifyContent='space-between'
          >
            <Box
              mr={2}
              display='flex'
              justifyContent='center'
              alignItems='center'
            >

              <AvatarPicker
                disableResize={true}
                photo={photo}
                uploadHandler={newPhoto => updateFile({
                  variables: {
                    applicationId: appId,
                    file: newPhoto,
                    ApplicationFileType: ApplicationFileTypeEnum.Photo,
                  }
                })}
              />
            </Box>

            <Box>
              <MobileAppInput
                label='Название'
                id='name'
                value={name}
                fullWidth
                error={errors.name}
                onChange={({target: { value }}) => setEditingData({...editingData, name: value})}
                onFocus={() => setErrors({ ...errors, name: false })}
              />

              <MobileAppInput
                label='Описание'
                id='description'
                value={description}
                onChange={({target: { value }}) => setEditingData({...editingData, description: value})}
                fullWidth
                multiline
                rows={6}
              />
            </Box>
          </Box>

          <MobileAppInput
            type='email'
            label='Почта'
            id='email'
            value={email}
            onChange={({target: { value }}) => setEditingData({...editingData, email: value})}
            fullWidth
          />

          <MobileAppInputTitle>
            Фото для главной (технические характеристики)
          </MobileAppInputTitle>

          <MobileAppInput
            id='mainPhoto'
            value={mainPhoto}
            onChange={({target: { value }}) => setEditingData({...editingData, mainPhoto: value})}
            fullWidth
          />

          <MobileAppInputTitle>
            Фото для моделей (технические характеристики)
          </MobileAppInputTitle>

          <MobileAppInput
            id='modelPhoto'
            value={modelPhoto}
            onChange={({target: { value }}) => setEditingData({...editingData, modelPhoto: value})}
            fullWidth
          />

          <MobileAppEditLinksList
            links={links}
            applicationId={id}
          />

          <MobileAppInputTitle>
            Ссылка на  App Store
          </MobileAppInputTitle>

          <MobileAppInput
            id='playMarketLink'
            value={playMarketLink}
            onChange={({target: { value }}) => setEditingData({...editingData, playMarketLink: value})}
            fullWidth
          />

          <MobileAppInputTitle>
            Ссылка на Play Market
          </MobileAppInputTitle>

          <MobileAppInput
            id='appStoreLink'
            value={appStoreLink}
            onChange={({target: { value }}) => setEditingData({...editingData, appStoreLink: value})}
            fullWidth
          />

          <MobileAppInput
            type='tel'
            label='Номер техподдержки'
            value={phone}
            onChange={({target: { value }}) => setEditingData({...editingData, phone: value})}
            id='phone'
            fullWidth
          />
        </Box>

        <Divider orientation='vertical' flexItem />

        <Box
          flexBasis={'50%'}
          ml={2}
        >
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <MobileAppInputTitle>
              Политика Конфиденциальности
            </MobileAppInputTitle>

            {privacyPolicy
              ? (
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  ml={2}
                >
                  <FileName
                    href={privacyPolicy}
                  >
                    Файл{getFileExtensionHandler(privacyPolicy)}
                  </FileName>

                  <CustomIconButton
                    disabled={disabledOnLoading}
                    callback={() => deleteFile({
                      variables: {
                        applicationId: appId,
                        ApplicationFileType: ApplicationFileTypeEnum.PrivacyPolicy,
                      }
                    })}
                    mainColor='red'
                    disabledColor={secondary}
                    children={<Close />}
                  />
                </Box>
              )
              : (
                <HiddenInputLabel
                  htmlFor="privacyPolicy"
                >
                  <UnderlayButton
                    disabled={disabledOnLoading}
                  >
                    Прикрепить
                  </UnderlayButton>
                </HiddenInputLabel>
              )
            }
          </Box>

          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <MobileAppInputTitle>
              Пользовательское Соглашение
            </MobileAppInputTitle>

            {userAgreement
              ? (
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  ml={2}
                >
                  <FileName
                    href={userAgreement}
                  >
                    Файл{getFileExtensionHandler(userAgreement)}
                  </FileName>

                  <CustomIconButton
                    disabled={disabledOnLoading}
                    callback={() => deleteFile({
                      variables: {
                        applicationId: appId,
                        ApplicationFileType: ApplicationFileTypeEnum.UserAgreement,
                      }
                    })}
                    mainColor='red'
                    disabledColor={secondary}
                    children={<Close />}
                  />
                </Box>
              )
              : (
                <HiddenInputLabel
                  htmlFor="userAgreement"
                >
                  <UnderlayButton
                    disabled={disabledOnLoading}
                  >
                    Прикрепить
                  </UnderlayButton>
                </HiddenInputLabel>
              )
            }
          </Box>

          <Box>
            <MobileAppInputTitle>
              Комментарии
            </MobileAppInputTitle>

            <MobileAppInput
              fullWidth
              multiline
              value={comments}
              onChange={({target: { value }}) => setEditingData({...editingData, comments: value})}
              rows={10}
            />
          </Box>

          <HiddenInput
            id='userAgreement'
            type='file'
            disabled={disabledOnLoading}
            onChange={({ target: { files } }) => updateFile({
              variables: {
                applicationId: appId,
                file: files[0],
                ApplicationFileType: ApplicationFileTypeEnum.UserAgreement,
              }
            })}
          />

          <HiddenInput
            id='privacyPolicy'
            type='file'
            disabled={disabledOnLoading}
            onChange={({ target: { files } }) => updateFile({
              variables: {
                applicationId: appId,
                file: files[0],
                ApplicationFileType: ApplicationFileTypeEnum.PrivacyPolicy,
              }
            })}
          />
        </Box>
      </Box>
    )
  }



  return (
    <Container maxWidth="lg"
      // onClick={() => window.scrollTo({ top: 0 })}
    >
      <Header>Информация о приложении</Header>

      <Box
        mt={'40px'}
        mb={'40px'}
      >
        <NavBar items={navItems} />
      </Box>

      <Paper elevation={4}>
        <ThemeProvider theme={defaultMaterialTheme}>
          {content}
        </ThemeProvider>

        <Box
          textAlign='center'
          mt={'40px'}
          paddingBottom={'60px'}
        >
          <ButtonCustom
            disabled={disabledOnLoading}
            onClick={updateAppHandler}
          >
            СОХРАНИТЬ
          </ButtonCustom>
        </Box>

        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={errorMessage}
        />
      </Paper>
    </Container>
  )
}