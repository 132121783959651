import React, { useEffect } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { UserFields } from "../Style"
import { useQuery } from "@apollo/client";
import { GetSchoolCountriesDocument, GetSchoolCountriesQuery, User } from "../../../generated/graphql";
import { LoadingBox } from "../../shared/Style/Style";
import { CircularProgress } from "@material-ui/core";

interface IUserCountry{
  getAllItemsParams:{
    limit: number;
    skip: number;
  };
  countryId:string;
  mobileUsers:User;
  setMobileUsers(mobileUsers):void;
}

export const UserCountrySelect = ({getAllItemsParams,countryId,setMobileUsers,mobileUsers}:IUserCountry) => {

  const {
    data: getSchoolCountriesData,
    loading: getSchoolCountriesLoading,
    error: getSchoolCountriesError,
  } = useQuery<GetSchoolCountriesQuery>(GetSchoolCountriesDocument,{variables:{
      ...getAllItemsParams,
    }});


  if(getSchoolCountriesLoading) {
    return (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    )
  }

  const schoolCountries = getSchoolCountriesData?.getSchoolCountries || [];

  return (
    <Autocomplete
      options={schoolCountries?.map((schoolCountry) => schoolCountry.id)}
      getOptionLabel={(option) => schoolCountries?.find((schoolCountry) => schoolCountry?.id === option)?.nameUKR || ''}
      onChange={(e, value) => setMobileUsers(value)}
      value={schoolCountries.find((schoolCountry) => schoolCountry?.id === countryId)?.id}
      disabled={getSchoolCountriesLoading||!!getSchoolCountriesError}
      renderInput={(params) => (
        <UserFields
          {...params}
          label="Страна"
          variant="standard"
        />
      )}
    />
  )
};