import { useReactiveVar } from "@apollo/client";
import { EducationArea} from "../../../../../generated/graphql";
import { mobileAppModel } from "../../state";
import { Autocomplete } from "@material-ui/lab";
import { Chip, IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { ModalInput } from "../../../../shared/Style/Style";

export const SelectEducationAreas = () => { 
    const model = useReactiveVar(mobileAppModel.model);
    const educationAreasList = useReactiveVar(mobileAppModel.educationAreasList);
    const isLoading = useReactiveVar(mobileAppModel.updateLoading);
    
    if (!model) return null;
    const { educationAreas } = model;

    const getEducationAreasToRender = (id: string) => {
        return findEducationAreasById(educationAreasList, id)?.name || "";
    };

    const findEducationAreasById = (areas: EducationArea[], id: string) => {
        return areas.find((area) => area.id === id);
    };

    const handleRemoveEducationAreasIds = (areaId: string) => {
        const educationAreasToSet = educationAreas.filter(({ id }) => id !== areaId).map(({ id }) => id) || [];
        mobileAppModel.setEducationAreaIds(educationAreasToSet);
    };

    const deleteIcon = (<IconButton><CancelIcon/></IconButton>);

    return (
        <Autocomplete
                multiple
                disabled={isLoading}
                filterSelectedOptions
                style={{ maxWidth: "520px"}}
                options={educationAreasList?.map(({ id }) => id)}
                onChange={(_, value) => {
                    mobileAppModel.setEducationAreaIds(value);
                }}
                onFocus={() =>
                    mobileAppModel.setInputErrors("educationAreas", false)
                }
                getOptionLabel={(option) => {
                    return getEducationAreasToRender(option);
                }}
                value={educationAreas.map(({ id }) => id)}
                    renderTags={(value: string[], getTagProps) => {
                    return value.map((tagId: string, index: number) => (
                    <Chip
                        variant="default"
                        label={findEducationAreasById(educationAreas, tagId)?.name}
                        deleteIcon={deleteIcon}
                        {...getTagProps({ index })}
                        onDelete={() => handleRemoveEducationAreasIds(tagId)}
                    />
                ));
            }}
            renderInput={(params) => (
                <ModalInput
                    label="Напрям освіти"
                    margin="normal"
                    {...params}
                />
            )}
        />
    );
};
