import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { User } from "../../../generated/graphql";
import React from "react";
import { FieldInputProps, FieldMetaState } from "react-final-form";
import { FieldValueGetter } from "@apollo/client/cache/inmemory/entityStore";
import {useStyles} from "../Style/Style";

interface valueProps {
  id: number | string;
  title?: string;
}

interface DictionaryProjectSelectProps {
  label: string;
  values: valueProps[];
  input: FieldInputProps<User>;
  meta: FieldMetaState<FieldValueGetter>;
}

export const DictionaryProjectStatusSelect = ({
  label,
  values,
  input,
  meta,
}: DictionaryProjectSelectProps) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.root} variant="filled" error={meta.error && meta.touched} fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={input.value.id}
        onChange={(e) => {
          input.onChange({ id: e.target.value });
        }}
        fullWidth
      >
        {values?.map((value: valueProps, index) => {
          return (
            <MenuItem key={index} value={value.id}>
              {value.title}
            </MenuItem>
          );
        })}
      </Select>
      {meta.error && meta.touched && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};
