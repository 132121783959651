import React, { MouseEvent, useState, useEffect } from "react";

import { useMutation } from "@apollo/client";
import {
  DeleteNotificationDocument,
  DeleteNotificationMutation,
  Maybe,
  GetNotificationsByManagerDocument,
  PublishNotificationMutation,
  PublishNotificationDocument,
} from "../../generated/graphql";

import { IconButton, Menu, Snackbar } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Alert } from "@material-ui/lab";

import {
  MenuItemStyle,
  MenuItemDelete,
  secondary,
} from "../shared/Style/Style";

const ITEM_HEIGHT = 48;

interface INotificationMenuProps {
  openModal(): void;

  notificationId?: Maybe<string>;
  isPublished?: boolean;
}

export const NotificationMenu = ({
  openModal,
  notificationId,
  isPublished,
}: INotificationMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setDeleteSnackbarOpen(false);
    setPublishSnackbarOpen(false);
    setAnchorEl(null);
  };

  const editHandler = () => {
    openModal();
    handleClose();
  };

  const [isDeleteSnackbarOpen, setDeleteSnackbarOpen] = useState(false);
  const [isPublishSnackbarOpen, setPublishSnackbarOpen] = useState(false);

  const [
    deleteNotification,
    {
      data: deleteNotificationData,
      loading: deleteNotificationLoading,
      error: deleteNotificationError,
    },
  ] = useMutation<DeleteNotificationMutation>(DeleteNotificationDocument, {
    variables: {
      id: notificationId,
    },
    refetchQueries: [
      {
        query: GetNotificationsByManagerDocument,
        variables: { limit: 1000, skip: 0, isPublished: null },
      },
    ],
  });

  const [
    publishNotification,
    {
      data: publishNotificationData,
      loading: publishNotificationLoading,
      error: publishNotificationError,
    },
  ] = useMutation<PublishNotificationMutation>(PublishNotificationDocument, {
    variables: {
      id: notificationId,
    },
    refetchQueries: [
      {
        query: GetNotificationsByManagerDocument,
        variables: { limit: 1000, skip: 0, isPublished: null },
      },
    ],
  });

  useEffect(() => {
    if (deleteNotificationError) {
      setPublishSnackbarOpen(false);
      setDeleteSnackbarOpen(true);
    }

    if (deleteNotificationData) {
      handleClose();
    }
  }, [deleteNotificationError, deleteNotificationData]);

  useEffect(() => {
    if (publishNotificationError) {
      setDeleteSnackbarOpen(false);
      setPublishSnackbarOpen(true);
    }

    if (publishNotificationData) {
      handleClose();
    }
  }, [publishNotificationError, publishNotificationData]);

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        style={{ color: secondary }}
        onClick={handleClick}
      >
        <MoreVertIcon fontSize="large" />
      </IconButton>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItemStyle
          prim={false}
          onClick={() => publishNotification()}
          disabled={
            deleteNotificationLoading ||
            publishNotificationLoading ||
            isPublished
          }
        >
          Опубликовать
        </MenuItemStyle>

        <MenuItemStyle
          prim={false}
          onClick={() => publishNotification()}
          disabled={
            deleteNotificationLoading ||
            publishNotificationLoading ||
            !isPublished
          }
        >
          Не публиковать
        </MenuItemStyle>

        <MenuItemStyle
          prim={false}
          disabled={deleteNotificationLoading || publishNotificationLoading}
          onClick={editHandler}
        >
          Редактировать
        </MenuItemStyle>

        <MenuItemDelete
          prim={false}
          disabled={deleteNotificationLoading || publishNotificationLoading}
          onClick={() => deleteNotification()}
        >
          Удалить
        </MenuItemDelete>
      </Menu>

      <Snackbar
        open={isDeleteSnackbarOpen || isPublishSnackbarOpen}
        onClose={() =>
          isPublishSnackbarOpen
            ? setPublishSnackbarOpen(false)
            : setDeleteSnackbarOpen(false)
        }
        autoHideDuration={6000}
        transitionDuration={300}
      >
        <Alert severity="error">
          {isPublishSnackbarOpen
            ? "Не удалось опубликовать уведомление"
            : "Не удалось удалить уведомление"}
        </Alert>
      </Snackbar>
    </div>
  );
};
