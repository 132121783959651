import React, {useState} from 'react';
import {IKnowledgeBaseBlockInnerProps} from "../../KnowledgeBaseBlockItem";
import {
  ButtonPlus,
} from "../../../shared/Style/Style";

import {ModalAddStep} from "./ModalAddStep";
import {Box, Paper} from "@material-ui/core";
import {KnowledgeBaseBlockList} from "../../../../generated/graphql";
import { DeleteIconButton } from "../../../shared/Buttons/DeleteIconButton";
import {EditIconButton} from "../../../shared/Buttons/EditIconButton";

export const StepsBlock = ({
  disabled,
  block,
  createListItem,
  updateListItem,
  deleteListItem,
  successRequest
}:IKnowledgeBaseBlockInnerProps) => {
  const {lists, id} = block
  const [open, setOpen] = useState(false)
  const [chosenItem, setChosenItem] = useState<KnowledgeBaseBlockList | null>(null)

  return (
    <>
      <Box mb={3}>
        <ButtonPlus
          width={180}
          padding="6px 16px"
          variant='contained'
          disabled={disabled}
          onClick={() => setOpen(true)}
        >
          Добавить шаг
        </ButtonPlus>
      </Box>

      {!!lists.length && lists.map(item => (
        <Paper
          key={item.id}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            marginBottom: '10px'
        }}
        >
          <Box>{item.content}</Box>
          <Box>
            <DeleteIconButton
              item={item.content}
              disabled={disabled}
              deleteHandler={() => {deleteListItem(item.id)}}
            />
            <EditIconButton
              disabled={disabled}
              edit={() => {
                setChosenItem(item)
                setOpen(true)
              }}
            />
          </Box>
        </Paper>
      ))}

      <ModalAddStep
        open={open}
        setOpen={setOpen}
        blockId={id}
        blockListItem={chosenItem}
        createListItem={createListItem}
        updateListItem={updateListItem}
        setChosenItem={setChosenItem}
        successRequest={successRequest}
      />
    </>
  )
}
