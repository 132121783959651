import React from "react";
import { ExpandMore } from "@material-ui/icons";
import { DropdownIconWrapper } from "./DropdownArrowButton.style";

interface IDropdownArrowButtonProps {
  expanded: boolean;
}

export const DropdownArrowButton = ({ expanded }: IDropdownArrowButtonProps) => {
  return (
    <DropdownIconWrapper
      expanded={expanded}
    >
      <ExpandMore color='inherit'/>
    </DropdownIconWrapper>
  )
}