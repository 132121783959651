import styled from 'styled-components';
import { RadioGroup, Radio } from '@material-ui/core';
import { primary } from '../Style/Style';

interface ICustomRadioGroupBodyProps {
  horizontal?: boolean;
}

export const CustomRadioGroupBody = styled(RadioGroup)<ICustomRadioGroupBodyProps>`
  flex-direction: ${props => props.horizontal ? 'row' : 'column'};
`;

export const CustomRadioControl = styled(Radio)`
  color: ${primary};

  &.Mui-checked {
    color: ${primary};
  }
`;