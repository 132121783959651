import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import { primary, secondary } from "../Style/Style";

export const MonthsDropdown = styled.ul`
  width: 145px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  max-height: 230px;
  padding: 20px;
  margin: 0;
  border-radius: 10px 10px 0px 0px;
  background-color: #fff;
  position: absolute;
  bottom: 45px;
  left: 1px;
  list-style: none;
  text-align: center;
  overflow-y: auto;

  &::-webkit-scrollbar {
    visibility: hidden;
    width: 0;
  }
`;

interface IMonthDropdownItem {
  active?: boolean;
}

export const MonthDropdownItem = styled.li<IMonthDropdownItem>`
  color: ${(props) => (props.active ? primary : "#000")};
  transition: color 0.3s;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const MonthsWrapper = styled.div`
  position: relative;
`;

export const MonthsList = styled.ul`
  display: flex;
  position: relative;
  min-width: 100%;
  margin: 0;
  padding: 0 104px 0 0;
  overflow-x: auto;
  list-style: none;
  background-color: #eee;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    visibility: hidden;
    height: 0;
  }
`;

interface IMonthCustomProps {
  active: boolean;
}

export const Month = styled.li<IMonthCustomProps>`
  padding: 14px 0;
  text-align: center;
  min-width: 136px;
  height: 44px;
  border-color: ${secondary};
  border-width: 1px 1px 1px 0;
  border-style: solid;
  background-color: ${(props) => (props.active ? "#fff" : "#eee")};
  color: ${(props) => (props.active ? primary : secondary)};
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;

  &:first-of-type {
    border-left-width: 1px;
  }
`;

export const ShowAllMonthsBtnWrapper = styled.div`
  position: sticky;
  display: block;
  background: #eee;
  width: 91px;
  height: 100%;
  top: 0;
  left: 0;
`;

export const ShowAllMonthsBtn = styled(IconButton)`
  display: block;
  margin-left: 33px;
  margin-right: 22px;
  padding: 5px;
  color: ${secondary};
`;

export const ScrollButtonsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 104px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #eee;
`;

export const ScrollMonthsControlButton = styled(IconButton)`
  color: ${secondary};
`;
