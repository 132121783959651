import { useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { ArtifactRankEnum } from "../../../../../../generated/graphql";
import { robotsState } from "../../../state";
import { Autocomplete } from "@material-ui/lab";
import { ModalInput } from "../../../../../shared/Style/Style";

export const SelectArtifactRank = () => { 
    const artifact = useReactiveVar(robotsState.artifact);
    const isLoading = useReactiveVar(robotsState.isLoading);

    const selectOptions = Object.values(ArtifactRankEnum);

    const handleRankChange = (_, value: string) => {
        return robotsState.setArtifactRank(value as ArtifactRankEnum);
    }

    const getOptionLabel = (option: ArtifactRankEnum) => {
        return selectOptions.find(item => item === option) || null;
    };

    useEffect(() => {
        if (artifact?.isDefault)
            robotsState.setArtifactRank(undefined);
    }, [artifact?.isDefault]);

    return (
        <Autocomplete
          disabled={isLoading || artifact?.isDefault}
          options={selectOptions}
          value={artifact?.rank ?? null}
          onChange={handleRankChange}
          getOptionLabel={getOptionLabel}
            renderInput={(params) => {
                return (
                    <ModalInput
                        {...params}
                        label="Ранг"
                        fullWidth
                        variant="filled"
                        margin="normal"
                    />
                );
            }}
        />
    );
};
