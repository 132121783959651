import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import { secondary } from "../../Style/Style";

interface ICustomIconButton {
  disabled?: boolean;
  disabled_color?: string;
  main_color?: string;
  bg_color?: string;
  fz?: number;
}

export const CustomIconButtonBody = styled(IconButton)<ICustomIconButton>`
  max-width: fit-content;
  line-height: 1;
  font-size: ${({ fz }) => fz ? `${fz}px` : '24px'};
  padding: ${({ fz }) => fz ? `${fz / 2}px` : '12px'};
  color: ${({ disabled, disabled_color, main_color }) => {
    if (disabled) {
      return disabled_color;
    }

    if (main_color && !disabled) {
      return main_color;
    }

    return secondary;
  }};
  background-color: ${({bg_color}) => bg_color ? bg_color : 'inherit' };
`;