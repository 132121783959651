import React, { useRef } from "react";
import { Box, Collapse, SvgIcon } from "@material-ui/core";
import { getExercisesHeaderType } from "../../shared/Utils/OtherOperations/getExercisesHeaderType";
import { AddContentText } from "../../Training/Training.style";
import {
  ButtonCustomWithDarkText,
  HiddenInput,
} from "../../shared/Style/Style";
import { DeleteIconButton } from "../../shared/Buttons/DeleteIconButton";
import { convertSecondToMinutes } from "../../shared/Utils/ConvertOperations/convertSecondToMinutesView";

export const Exercises = ({
  block,
  setVariables,
  updateHandleExercises,
  deleteHandleExercises,
  file,
  setFile,
}) => {
  const inputVideo = useRef(null);

  const uploadVideoExercises = (e) => {
    const video = e.target.files[0];
    setFile(video);
  };

  return (
    <>
      <Box ml={5} mt={4}>
        <Box display="flex" justifyContent="space-between">
          <h1>{getExercisesHeaderType(block.type)}</h1>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
            mr={3}
          >
            <DeleteIconButton
              deleteHandler={deleteHandleExercises}
              item={getExercisesHeaderType(block.type)}
            />
          </Box>
        </Box>

        <Box mt={3} mr={5} pb={4}>
          {!!block?.video ? (
            <Box display="flex" justifyContent="center">
              <Collapse in={!!block?.video} collapsedSize={"0px"}>
                <video width="750" height="500" controls>
                  <source src={block?.video} type="video/mp4" />
                </video>
              </Collapse>
            </Box>
          ) : (
            !block?.video && (
              <Box display="flex" alignItems="center">
                {!!file ? (
                  <Collapse in={!!file} collapsedSize={"0px"}>
                    <Box
                      ml={2}
                      key={file.size}
                      textAlign="center"
                      display="flex"
                      justifyContent="start"
                      alignItems="center"
                    >
                      {file.name}
                      <DeleteIconButton
                        deleteHandler={() => {
                          inputVideo.current.value = "";
                          setFile(null);
                        }}
                        item="файл"
                        fz={20}
                      />
                    </Box>
                  </Collapse>
                ) : (
                  " "
                )}
                <ButtonCustomWithDarkText
                  style={{ marginLeft: "10px" }}
                  active={true}
                  width="179"
                  onClick={() => {
                    inputVideo.current.click();
                  }}
                >
                  Выбрать видео
                </ButtonCustomWithDarkText>
              </Box>
            )
          )}
        </Box>
        <Box mt={3} mr={5} pb={4}>
          <AddContentText
            disabled={!!block.content}
            value={block.content}
            label="ВВЕДИТЕ ТЕКСТ К ВИДЕО"
            multiline
            rows={5}
            fullWidth
            onChange={({ target: { value } }) =>
              setVariables((prev) => ({ ...prev, content: value }))
            }
          />
        </Box>
        <Box mt={3} mr={5} pb={4} width="150px">
          {!!block.pauseTime ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <SvgIcon viewBox="0 0 26 30">
                <path
                  d="M13 27.1429C10.3184 27.1429 7.74657 26.0893 5.85036 24.2139C3.95416 22.3386 2.88889 19.795 2.88889 17.1429C2.88889 14.4907 3.95416 11.9472 5.85036 10.0718C7.74657 8.19643 10.3184 7.14286 13 7.14286C15.6816 7.14286 18.2534 8.19643 20.1496 10.0718C22.0458 11.9472 23.1111 14.4907 23.1111 17.1429C23.1111 19.795 22.0458 22.3386 20.1496 24.2139C18.2534 26.0893 15.6816 27.1429 13 27.1429ZM23.1544 9.12857L25.2056 7.1C24.5556 6.37143 23.9056 5.71429 23.1689 5.08571L21.1178 7.14286C18.8789 5.34286 16.0622 4.28571 13 4.28571C9.55219 4.28571 6.24558 5.6403 3.80761 8.05148C1.36964 10.4627 0 13.7329 0 17.1429C0 20.5528 1.36964 23.823 3.80761 26.2342C6.24558 28.6454 9.55219 30 13 30C20.2222 30 26 24.2429 26 17.1429C26 14.1143 24.9311 11.3286 23.1544 9.12857ZM11.5556 18.5714H14.4444V10H11.5556V18.5714ZM17.3333 0H8.66667V2.85714H17.3333V0Z"
                  fill="black"
                />
              </SvgIcon>
              {convertSecondToMinutes(block.pauseTime)}
            </Box>
          ) : (
            <AddContentText
              disabled={!!block.pauseTime}
              value={block.pauseTime}
              label="Секунды"
              multiline
              onChange={({ target: { value } }) =>
                setVariables((prev) => ({ ...prev, pauseTime: +value }))
              }
            />
          )}
        </Box>
      </Box>
      {!block.video || !block.content || !block.pauseTime ? (
        <Box display="flex" justifyContent="center" pb={5}>
          <ButtonCustomWithDarkText
            active={true}
            width="179"
            style={{ marginRight: "5px" }}
            onClick={updateHandleExercises}
          >
            Сохранить
          </ButtonCustomWithDarkText>
        </Box>
      ) : (
        ""
      )}
      <HiddenInput
        ref={inputVideo}
        type="file"
        onChange={uploadVideoExercises}
      />
    </>
  );
};
