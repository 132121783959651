import React, { useEffect, useRef, useState } from "react";
import {
  CreateTraningInput,
  GetTraningDocument,
  UpdateTraningDocument,
  UpdateTraningMutation,
} from "../../generated/graphql";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Box, Collapse, Paper } from "@material-ui/core";
import { DeleteIconButton } from "../shared/Buttons/DeleteIconButton";
import { AddContentText } from "../Training/Training.style";
import { ButtonCustomWithDarkText, HiddenInput } from "../shared/Style/Style";
import { ImageContent } from "./TrainingForm.style";
import { EditIconButton } from "../shared/Buttons/EditIconButton";

import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";

export const TrainingForm = ({
  setRequestError,
  trainingData,
  disabled,
  setErrorMessage,
  errorMessage,
  requestError,
}) => {

  const { trainingsId } = useParams<{ trainingsId: string }>();
  const { contentUKR, startTitleUKR, endTitleUKR,  startVideo, endVideo, photo } =
    trainingData;

  const [files, setFiles] = useState({
    photo: null,
    startVideoFile: null,
    endVideoFile: null,
  });

  const [chosenFileInput, setChosenFileInput] = useState("");
  const fileInput = useRef<HTMLInputElement>(null);
  const [trainingBlock, setTrainingBlock] = useState<CreateTraningInput>({
    contentUKR,
    startTitleUKR,
    endTitleUKR,
  });

  const [isEditing, setIsEditing] = useState(false);

  const [
    updateTraining,
    {
      data: updateTrainingData,
      loading: updateTrainingLoading,
      error: updateTrainingError,
    },
  ] = useMutation<UpdateTraningMutation>(UpdateTraningDocument, {
    variables: {
      id: trainingsId,
      traningData: {
        ...trainingBlock,
        photo: files?.photo,
        startVideo: files?.startVideoFile,
        endVideo: files?.endVideoFile,
      },
    },
    refetchQueries: [GetTraningDocument],
  });

  useEffect(() => {
    if (updateTrainingData) {
      setTrainingBlock({ ...trainingBlock });
      setFiles(null);
    }
  }, [updateTrainingData]);

  const updateContentTraining = async () => {
    try {
      await updateTraining();
      setIsEditing(false);
    } catch (e) {
      setRequestError(true);
    }
  };

  const clickUploadFileHandle = (e) => {
    const fileTraining = e.target.files[0];
    setFiles({ ...files, [chosenFileInput]: fileTraining });
  };

  const clickUploadFiles = (name: string) => {
    fileInput.current.click();
    fileInput.current.value = "";
    setChosenFileInput(name);
  };

  useEffect(() => {
    if (updateTrainingData) {
      setRequestError(false);
      setErrorMessage("При загрузке данных произошла ошибка");
    }
  }, [updateTrainingData]);

  useEffect(() => {
    if (trainingData) {
      setRequestError(false);
    }
  }, [trainingData]);

  const disabledOnFormLoading = disabled || updateTrainingLoading;

  return (
    <>
      <Paper>
        <Box ml={5} mt={4}>
          <Box display="flex" justifyContent="space-between">
            <h1>Начало (*обязательно)</h1>
            <Box display="flex" alignItems="center" mr={5}>
              <EditIconButton
                disabled={isEditing}
                edit={() => setIsEditing(!isEditing)}
                fz={20}
              />
            </Box>
          </Box>

          <h3>Картинка всплывающего окна</h3>
          <Box display="flex" alignItems="center">
            <Box mr={3}>
              {!isEditing && !!photo ? (
                <Collapse in={!!photo} collapsedSize={"0px"}>
                  <Box
                    key={photo}
                    textAlign="center"
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <ImageContent src={photo} />
                  </Box>
                </Collapse>
              ) : (
                (isEditing || !photo) && (
                  <Box display="flex" alignItems="center">
                    {!!files?.photo ? (
                      <Collapse in={!!files?.photo} collapsedSize={"0px"}>
                        <Box
                          ml={2}
                          key={files.photo?.size}
                          textAlign="center"
                          display="flex"
                          justifyContent="start"
                          alignItems="center"
                        >
                          {files.photo?.name}
                          <DeleteIconButton
                            deleteHandler={() => {
                              fileInput.current.value = "";
                              setFiles({ ...files, photo: null });
                            }}
                            item="файл"
                            fz={20}
                          />
                        </Box>
                      </Collapse>
                    ) : (
                      "ИМЯ ФАЙЛА"
                    )}
                    <ButtonCustomWithDarkText
                      style={{ marginLeft: "10px" }}
                      active={true}
                      width="179"
                      onClick={() => clickUploadFiles("photo")}
                    >
                      Выбрать файл
                    </ButtonCustomWithDarkText>
                  </Box>
                )
              )}
            </Box>
          </Box>
          <Box mt={3} mr={5}>
            <AddContentText
              disabled={(!isEditing && !!contentUKR) || disabledOnFormLoading}
              value={trainingBlock.contentUKR}
              label="ВВЕДИТЕ ТЕКСТ ВСПЛЫВАЩЕГО ОКНА"
              multiline
              type="text"
              rows={4}
              fullWidth
              onChange={({ target: { value } }) =>
                setTrainingBlock((prev) => ({ ...prev, contentUKR: value }))
              }
            />
          </Box>

          <Box mt={3} mr={5}>
            {!isEditing && !!startVideo ? (
              <Box display="flex" justifyContent="center">
                <Collapse in={!!startVideo} collapsedSize={"0px"}>
                  <video width="750" height="500" controls>
                    <source src={startVideo} type="video/mp4" />
                  </video>
                </Collapse>
              </Box>
            ) : (
              (isEditing || !startVideo) && (
                <Box display="flex" alignItems="center">
                  {!!files?.startVideoFile ? (
                    <Collapse
                      in={!!files?.startVideoFile}
                      collapsedSize={"0px"}
                    >
                      <Box
                        ml={2}
                        key={files.startVideoFile?.size}
                        textAlign="center"
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                      >
                        {files.startVideoFile?.name}
                        <DeleteIconButton
                          deleteHandler={() => {
                            fileInput.current.value = "";
                            setFiles({ ...files, startVideoFile: null });
                          }}
                          item="файл"
                          fz={20}
                        />
                      </Box>
                    </Collapse>
                  ) : (
                    ""
                  )}
                  <ButtonCustomWithDarkText
                    style={{ marginLeft: "10px" }}
                    active={true}
                    width="179"
                    onClick={() => clickUploadFiles("startVideoFile")}
                  >
                    Выбрать видео
                  </ButtonCustomWithDarkText>
                </Box>
              )
            )}
          </Box>

          <Box mt={3} mr={5}>
            <AddContentText
              disabled={(!isEditing && !!startTitleUKR) || disabledOnFormLoading}
              value={trainingBlock.startTitleUKR}
              label="ВВЕДИТЕ ТЕКСТ К ВИДЕО"
              multiline
              rows={4}
              fullWidth
              onChange={({ target: { value } }) =>
                setTrainingBlock((prev) => ({ ...prev, startTitleUKR: value }))
              }
            />
          </Box>
        </Box>

        <Box ml={5} mt={3}>
          <h1>Окончание (*обязательно)</h1>
          <Box mt={3} mr={5} pb={5}>
            <AddContentText
              disabled={(!isEditing && !!endTitleUKR) || disabledOnFormLoading}
              value={trainingBlock.endTitleUKR}
              label="ВВЕДИТЕ ТЕКСТ ВСПЛЫВАЩЕГО ОКНА"
              multiline
              rows={10}
              fullWidth
              onChange={({ target: { value } }) =>
                setTrainingBlock((prev) => ({ ...prev, endTitleUKR: value }))
              }
            />
          </Box>

          <Box pb={5}>
            {!isEditing && !!endVideo ? (
              <Box display="flex" justifyContent="center">
                <Collapse in={!!endVideo} collapsedSize={"0px"}>
                  <video width="750" height="500" controls>
                    <source src={endVideo} type="video/mp4" />
                  </video>
                </Collapse>
              </Box>
            ) : (
              (isEditing || !endVideo) && (
                <Box display="flex" alignItems="center">
                  {!!files?.endVideoFile ? (
                    <Collapse in={!!files?.endVideoFile} collapsedSize={"0px"}>
                      <Box
                        ml={2}
                        key={files.endVideoFile?.size}
                        textAlign="center"
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                      >
                        {files.endVideoFile?.name}
                        <DeleteIconButton
                          deleteHandler={() => {
                            fileInput.current.value = "";
                            setFiles({ ...files, endVideoFile: null });
                          }}
                          item="файл"
                          fz={20}
                        />
                      </Box>
                    </Collapse>
                  ) : (
                    " "
                  )}
                  <ButtonCustomWithDarkText
                    style={{ marginLeft: "10px" }}
                    active={true}
                    width="179"
                    onClick={() => clickUploadFiles("endVideoFile")}
                  >
                    Выбрать видео
                  </ButtonCustomWithDarkText>
                </Box>
              )
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" pb={5}>
          <ButtonCustomWithDarkText
            active={true}
            width="179"
            style={{ marginRight: "5px" }}
            onClick={updateContentTraining}
          >
            Сохранить
          </ButtonCustomWithDarkText>
        </Box>
      </Paper>

      <HiddenInput
        ref={fileInput}
        type="file"
        onChange={clickUploadFileHandle}
      />

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </>
  );
};
