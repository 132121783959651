import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { primary, secondary } from "../../../../shared/Style/Style";

export const Input = styled(TextField)`
  display: block;
  width: 100%;
  border: 1px solid ${secondary};

  & .MuiInputBase-input {
    box-sizing: border-box;
    font-size: 0.875rem;
    text-align: center;
    height: 28px;
  }

  &:has(.Mui-focused, .Mui-error) {
    border: 1px solid ${primary};
  }

  &:has(.Mui-error) {
    border: 1px solid red;
  }

  & .MuiInput-underline {
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
`;
