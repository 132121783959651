import { ExerciseTypeEnum } from "../../../../generated/graphql";

export const getExercisesHeaderType = (type: ExerciseTypeEnum) => {
  switch (type) {
    case ExerciseTypeEnum.Exercise:
      return "Упражнение";
    case ExerciseTypeEnum.Pause:
      return "Перерыв";
    default:
      return "Текст блока";
  }
};

