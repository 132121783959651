import styled from "styled-components";
import { DialogTitle } from "@material-ui/core";

export const Container = styled.div`
  position: relative;
  padding: 20px;
  width: 100%;
`;

export const Title = styled(DialogTitle)`
   display: flex;
   align-items: center;
   font-size: 24px;
   font-weight: 700;
   line-height: 28px;
 `;
 
export const Context = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const FormContainer = styled.div`
  width: 426px;
  margin: 0 20px;
`;

export const BundleBlockTitle = styled.h4`
   margin-top: 20px;
   padding: 0;
   font-size: 16px;
   font-weight: 700;
   line-height: 18px;
`;
