import React from "react";
import styled from "styled-components";

import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { secondary } from "../../Style/Style";

interface ICloseIconButtonProps {
  close(): void;
  disabled?: boolean;
  main_color?: string;
  position?: string;
}

const CloseBtn = styled(IconButton)<{main_color?: string, position?: string}>`
  position: ${({ position }) => position ? position : 'absolute' };
  top: 0;
  right: 0;
  z-index: 2;
  color: ${({ main_color }) => main_color ? main_color : secondary };
`;

export const CloseIconButton = ({
  close,
  disabled,
  main_color,
  position,
}: ICloseIconButtonProps) => {
  return (
    <CloseBtn
      disabled={disabled}
      onClick={close}
      main_color={main_color}
      position={position}
    >
      <Close fontSize="small" />
    </CloseBtn>
  );
};
