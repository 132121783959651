import { ApolloClient, makeVar } from "@apollo/client";
import { Award, AwardTypeEnum, CreateAwardMutation, CreateAwardMutationVariables, GetAwardsQuery, PublishAwardsMutation, PublishAwardsMutationVariables, UnpublishAwardsMutation, UnpublishAwardsMutationVariables, UpdateAwardMutation, UpdateAwardMutationVariables } from "../../../generated/graphql";
import { CREATE_AWARD, PUBLISH_AWARDS, UNPUBLISH_AWARDS, UPDATE_AWARD } from "../../../graphql/mutations/gamification";
import { GET_AWARDS } from "../../../graphql/queries/gamification";
import { isValidHexColor } from "./utils";
import { awardsQuantity, initialAward, initialDeleteAward, initialInputErrors, initialNotifications } from "./constants";
import { AwardField, DeleteAwardType, InputErrors, NotificationsType } from "./types";

export class MobileAppAwardsPageState { 
    apolloClient: ApolloClient<any> | null = null;

    initMobileAppAwards(client: ApolloClient<any>) {
        this.apolloClient = client;
    }

    award = makeVar<Award>(initialAward);

    awardsList = makeVar<Award[]>(null);

    photoFileUpload = makeVar<File | null>(null);

    animationFileUpload = makeVar<File | null>(null);

    isAwardModalOpen = makeVar(false);

    deleteAward = makeVar<DeleteAwardType>(initialDeleteAward);
    
    inputErrors = makeVar<InputErrors>(initialInputErrors);

    notifications = makeVar<NotificationsType>(initialNotifications);

    isLoading = makeVar(false);

    clearAward() {
        this.award(initialAward);
        this.photoFileUpload(null);
        this.animationFileUpload(null);
        this.inputErrors(initialInputErrors);
    }

    setAwardsList(awards: Award[]) {
        this.awardsList(awards);
    }

    setCurrentAward(award: Award) {
        this.award(award);
    }

    setAwardName = (name: string) => {
        if (name.length > 20) {
            awardsState.setInputErrors(AwardField.Name, true, "Название не должно превышать 20 символов");
        } else {
            awardsState.setInputErrors(AwardField.Name, false);
        }

        this.award({ ...this.award(), name });
    }

    setAwardBackground = (background: string) => {
        if (!isValidHexColor(background)) {
            awardsState.setInputErrors(AwardField.Background, true, "Неверный формат цвета фона");
        } else {
            awardsState.setInputErrors(AwardField.Background, false);
        }

        this.award({ ...this.award(), background });
    }

    setAwardEnergyQuantity = (energyQuantity: number) => {
        this.award({ ...this.award(), energyQuantity });
    }

    setAwardPhotoLink = (photo: string) => {
        this.award({ ...this.award(), photo });
    }

    setAwardPhotoFile = (photoFileUpload: File) => {
        this.photoFileUpload(photoFileUpload);
    }

    setAwardAnimationLink = (animation: string) => {
        this.award({ ...this.award(), animation });
    }

    setAwardAnimationFile = (animationFileUpload: File) => {
        this.animationFileUpload(animationFileUpload);
    }

    setAwardType = (type: AwardTypeEnum) => {
        if (type === AwardTypeEnum.Bonus) {
            this.setAwardDay(awardsQuantity)
        }

        this.award({ ...this.award(), type });
    }

    setAwardDay = (day: number) => {
        this.award({ ...this.award(), day });
    }

    setIsAwardModalOpen = (isOpen: boolean) => {
        this.isAwardModalOpen(isOpen);
    }

    setDeleteAward = (deleteAward: DeleteAwardType) => {
  	    this.deleteAward(deleteAward);
  	}
  	
    setInputErrors(field: keyof InputErrors, error: boolean, message: string = "Заполните все поля для сохранения награды") {
        this.inputErrors({ ...this.inputErrors(), [field]: { error, message } });

        if (error) {
            this.setNotifications({ error, message });
        } else {
            this.setNotifications(initialNotifications);
        }
    }

    setNotifications(notifications: NotificationsType) {
        this.notifications(notifications);
    }

    setLoading = (isLoading: boolean) => {
        this.isLoading(isLoading);
    }

    async getAwards() {
        try { 
            this.setLoading(true);
            const result = await this.apolloClient.query<GetAwardsQuery>({
                query: GET_AWARDS,
                fetchPolicy: "network-only",
            });

            if (result?.data?.getAwards) {
                this.setAwardsList(result.data.getAwards);
                return;
            }

            this.notifications({ error: true, message: "Произошла ошибка при загрузке наград" });
        } catch { 
            this.notifications({ error: true, message: "Произошла ошибка при загрузке наград" });
        } finally { 
            this.setLoading(false);
        }
    }
    
    async createAward(variables: CreateAwardMutationVariables) {
        try {
            this.setLoading(true);
            const result = await this.apolloClient.mutate<CreateAwardMutation>({
                mutation: CREATE_AWARD,
                variables,
            });

            if (result?.data?.createAward?.id) {
                return this.notifications({ success: true, message: "Награда успешно создана" });
            }

            this.notifications({ error: true, message: "Произошла ошибка при создании награды" });
        } catch (error) {
            this.notifications({ error: true, message: "Произошла ошибка при создании награды" });
        } finally {
            this.setLoading(false);
        }
    }

    async updateAward(variables: UpdateAwardMutationVariables) {
        try {
            this.setLoading(true);
            const result = await this.apolloClient.mutate<UpdateAwardMutation>({
                mutation: UPDATE_AWARD,
                variables,
            });

            if (result?.data?.updateAward?.id) {
                return this.notifications({ success: true, message: "Награда успешно обновлена" });
            }

            this.notifications({ error: true, message: "Произошла ошибка при редактировании награды" });
        } catch (error) {
            this.notifications({ error: true, message: "Произошла ошибка при редактировании награды" });
        } finally {
            this.setLoading(false);
        }
    }

    async publishAwards(variables: PublishAwardsMutationVariables) { 
        try {
            this.setLoading(true);
            const result = await this.apolloClient.mutate<PublishAwardsMutation>({
                mutation: PUBLISH_AWARDS,
                variables,
            });

            if (result?.data?.publishAwards) { 
                return this.notifications({ success: true, message: "Награды успешно опубликованы" });
            }
            
            this.notifications({ error: true, message: "Произошла ошибка при публикации наград" });
        } catch {
            this.notifications({ error: true, message: "Произошла ошибка при публикации наград" });
        } finally {
            this.setLoading(false);
        }
    }

    async unPublishAwards(variables: UnpublishAwardsMutationVariables) {
        try {
            this.setLoading(true);
            const result = await this.apolloClient.mutate<UnpublishAwardsMutation>({
                mutation: UNPUBLISH_AWARDS,
                variables,
            });

            if (result?.data?.unpublishAwards) { 
                return this.notifications({ success: true, message: "Награды успешно сняты с публикации" });
            }
            
            this.notifications({ error: true, message: "Произошла ошибка при снятии с публикации наград" });
        } catch {
            this.notifications({ error: true, message: "Произошла ошибка при снятии с публикации наград" });
        } finally {
            this.setLoading(false);
        }
    }

}

export const awardsState = new MobileAppAwardsPageState();
