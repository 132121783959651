import { useReactiveVar } from "@apollo/client";
import { mobileAppModel } from "../../state";
import { Autocomplete } from "@material-ui/lab";
import { Chip, IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { ModalInput } from "../../../../shared/Style/Style";
import { SchoolGrade } from "../../../../../generated/graphql";


export const SelectSchoolGrades = () => {
    const model = useReactiveVar(mobileAppModel.model);
    const schoolGradesList = useReactiveVar(mobileAppModel.schoolGradesList);
    const isLoading = useReactiveVar(mobileAppModel.updateLoading);
    const inputErrors = useReactiveVar(mobileAppModel.inputErrors);
    
    if (!model) return null;
    const { schoolGrades } = model;
    
    const getSchoolGradesToRender = (id: string) => {
        return findSchoolGradeById(schoolGradesList, id)?.name.toString() || "";
    };

    const findSchoolGradeById = (grades: SchoolGrade[], id: string) => {
        return grades.find((grade) => grade.id === id);
    };

    const handleRemoveSchoolGradeIds = (gradeId: string) => {
        const gradesToSet = schoolGrades.filter(({ id }) => id !== gradeId).map(({ id }) => id) || [];
        mobileAppModel.setSchoolGradeIds(gradesToSet);
    };

    const deleteIcon = (<IconButton><CancelIcon/></IconButton>);

    return (
        <>
            <Autocomplete
                multiple
                disabled={isLoading}
                filterSelectedOptions
                style={{ maxWidth: "520px"}}
                options={schoolGradesList?.map(({ id }) => id)}
                onChange={(_, value) => {
                    mobileAppModel.setSchoolGradeIds(value);
                }}
                onFocus={() =>
                    mobileAppModel.setInputErrors("schoolGrades", false)
                }
                getOptionLabel={(option) => {
                    return getSchoolGradesToRender(option);
                }}
                value={schoolGrades.map(({ id }) => id)}
                    renderTags={(value: string[], getTagProps) => {
                    return value.map((tagId: string, index: number) => (
                    <Chip
                        variant="default"
                        label={findSchoolGradeById(schoolGrades, tagId)?.name}
                        deleteIcon={deleteIcon}
                        {...getTagProps({ index })}
                        onDelete={() => handleRemoveSchoolGradeIds(tagId)}
                    />
                ));
            }}
            renderInput={(params) => (
                <ModalInput
                    required
                    error={inputErrors.schoolGrades.error}  
                    label="Классы"
                    margin="normal"
                    {...params}
                />
            )}
            />
        </>
    );
};
 