import styled from "styled-components";
import { List, ListItem, IconButton } from "@material-ui/core";
import { ButtonCustom, secondary } from "../Style/Style";


const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

export const DropzoneContent = styled.div`
  padding: 24px;
  position: relative;
  min-width: 450px;
  text-align: center;
`;

export const DropzoneContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 20px 0;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: ${secondary};
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export const CloseDropzoneButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  color: ${secondary};
`;

export const DropzoneFilesListItem = styled(ListItem)`
  justify-content: space-between;
`;

export const DeleteAddedFileBtn = styled(IconButton)`
  color: red;
`;

export const AddButton = styled(ButtonCustom)`
  transition: background-color 0.3s;

  &:disabled {
    background-color: ${secondary};
  }
`;