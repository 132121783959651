import { PaymentType } from "../../../../generated/graphql";

export type SubscriptionFormValues = {
  paymentDate: string;
  validity?: string;
  paymentType?: PaymentType | "";
  planId: string;
  pupilsCount?: string;
  teachersCount?: string;
  total: string;
  paymentTypeId?: string;
  payerId?: string;
};

export const validateForm = (
  values: SubscriptionFormValues,
  isPersonal: boolean
) => {
  const errors: { [key: string]: string } = {};

  const fieldsToSkipInPersonal = ["pupilsCount", "teachersCount"];

  for (let key in values) {
    if (key === "total") continue;
    if (fieldsToSkipInPersonal.includes(key) && isPersonal) continue;

    if (!values[key]) {
      errors[key] = "Это поле обязательно";
    }
  }

  if (
    values.total?.length &&
    (isNaN(Number(values.total)) || Number(values.total) < 0)
  ) {
    errors.total = "Значение должно быть числом и не меньше 0";
  }

  if (!isPersonal) {
    if (values.validity?.length) {
      if (
        isNaN(Number(values.validity)) ||
        Number(values.validity) < 1 ||
        Number(values.validity) % 1 !== 0
      ) {
        errors.validity = "Значение должно быть целым числом >= 1";
      }
    }

    if (
      values.pupilsCount?.length &&
      (isNaN(Number(values.pupilsCount)) || Number(values.pupilsCount) <= 0)
    ) {
      errors.pupilsCount = "Значение должно быть положительным числом";
    }

    if (
      values.teachersCount?.length &&
      (isNaN(Number(values.teachersCount)) || Number(values.teachersCount) <= 0)
    ) {
      errors.teachersCount = "Значение должно быть положительным числом";
    }
  }

  return errors;
};
