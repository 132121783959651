import styled from "styled-components";
import { Table } from "@material-ui/core";
import { CustomTableCell } from "../shared/CustomTable/CustomTable.style";
import { ButtonCustom, primary, secondary } from "../shared/Style/Style";

export const CreateTaskCell = styled(CustomTableCell)`
  height: 52px;
  padding: 0;
`;

export const CreateTaskButton = styled(ButtonCustom)`
  height: 100%;

  &.MuiButton-root {
    border-radius: 0px;
  }

  &::after {
    content: "+";
    width: 18px;
    line-height: 18px;
    background-color: #fff;
    border-radius: 4px;
    display: block;
    position: absolute;
    color: ${(props) => (props.disabled ? secondary : primary)};
    right: 40%;
  }
`;

export const Label = styled.span<{ bgColor?: string }>`
  margin-right: 10px;
  background: ${({ bgColor }) => bgColor};
  border-radius: 5px;
  padding: 3px 6px;

  &:last-of-type {
    margin-right: 0;
  }
`;
