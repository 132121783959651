import { FC } from "react";
import { useReactiveVar } from "@apollo/client";
import { Dialog } from "@material-ui/core";
import { awardsState } from "../../state";
import { Container, Title, Context, FormContainer } from "./style";
import { CloseIconButton } from "../../../../shared/Buttons/CloseIconButton";
import { AwardPhotoDropzone } from "./AwardPhotoDropzone";
import { AwardForm } from "./AwardForm";
import { ModalButtons } from "../../../shared/ModalButtons";
import { isValidHexColor } from "../../utils";
import { AwardField } from "../../types";

interface AwardModalProps {
    refetchAwards: () => void;
}

export const AwardModal: FC<AwardModalProps> = ({ refetchAwards }) => {
    const award = useReactiveVar(awardsState.award);
    const photoFileUpload = useReactiveVar(awardsState.photoFileUpload);
    const animationFileUpload = useReactiveVar(awardsState.animationFileUpload);
    const isLoading = useReactiveVar(awardsState.isLoading);

    const title = award?.id ? "Редактирование награды" : "Создание награды";

    const commonAwardParams = {
        name: award?.name,
        day: award?.day,
        background: award?.background,
        energyQuantity: award?.energyQuantity,
        type: award?.type,
    };
    
    const closeHandler = () => {
        awardsState.clearAward();
        awardsState.setIsAwardModalOpen(false);
    };

    const validateFields = () => { 
        const isFieldNotEmpty = Object.values(commonAwardParams).every(value => Boolean(value));
        
        if (!isFieldNotEmpty) {
            for (const [field, value] of Object.entries(commonAwardParams)) {
                if (!Boolean(value)) {
                    awardsState.setInputErrors(field as AwardField, true);
                }
            }
            return;
        };

        if (commonAwardParams.name.length > 20) {
            return awardsState.setInputErrors(AwardField.Name, true);
        };

        if (!isValidHexColor(commonAwardParams.background)) {
            return awardsState.setInputErrors(AwardField.Background, true);
        };

        if (!photoFileUpload && !award?.photo) {
            return awardsState.setNotifications({ error: true, message: "Необходимо загрузить фото награды" });
        };

        if (!animationFileUpload && !award?.animation) {
            return awardsState.setNotifications({ error: true, message: "Необходимо загрузить анимацию награды" });
        };

        return true;
    }
    
    const submitHandler = async () => {
        if (!validateFields()) return;
    
        if (award?.id) {
            await awardsState.updateAward({
                input: {
                    id: award?.id,
                    isPublished: award?.isPublished,
                    ...commonAwardParams,
                    ...(photoFileUpload ? { photo: photoFileUpload } : {}),
                    ...(animationFileUpload ? { animation: animationFileUpload } : {}),
                }
            });
        } else { 
            await awardsState.createAward({
                input: {
                    photo: photoFileUpload,
                    animation: animationFileUpload,
                    ...commonAwardParams,
                }
            });
        }

        refetchAwards();

        closeHandler();
    };

    return (
        <Dialog open maxWidth="md">
            <Container>
                <CloseIconButton close={closeHandler} />

                <Title>{title}</Title>

                <Context>
                    <AwardPhotoDropzone />

                    <FormContainer>
                        <AwardForm />

                        <ModalButtons
                            isLoading={isLoading}
                            cancelHandler={closeHandler} 
                            submitHandler={submitHandler}
                        />
                    </FormContainer>
                </Context>

            </Container>
        </Dialog>
    );
};
