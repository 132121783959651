import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { MobileAppInput } from "../MobileAppPageEdit.style";
import { DeleteIconButton } from "../../shared/Buttons/DeleteIconButton";
import { SaveIconButton } from "../../shared/Buttons/SaveIconButton";
import { EditIconButton } from "../../shared/Buttons/EditIconButton";
import { LanguageSwitch } from "../../shared/LanguageSwitch/LanguageSwitch";
import { languages } from "../../../constants/language";
import { PhotoPickerWithIcon } from "../../shared/PhotoPicketWithIcon/PhotoPickerWithIcon";
import { Autocomplete } from "@material-ui/lab";
import { SelectSubscriptionsPlan } from "../Selectors/SelectSubscriptionsPlan";
import { SubscriptionPlanStatusEnum } from "../../../generated/graphql";

const InitialErrors: { [key: string]: boolean } = {
  nameUKR: false,
  nameEN: false,
};

export const ThemeItem = ({
  backgrounds,
  theme,
  addPhoto,
  deleteThemePhoto,
  disableOnLoading,
  isThemeCreating,
  deleteTheme,
  updateTheme,
  setRequestError,
  setErrorMessage,
}) => {
  const { id, photoName, photo } = theme;

  const [editValue, setEditValue] = useState(false);
  const [language, setLanguage] = useState("UKR");
  const [themeValue, setThemeValue] = useState(theme);
  const [errors, setErrors] = useState(InitialErrors);

  useEffect(() => {
    if (errors.nameUKR || errors.nameEN) {
      setErrors({ nameUKR: false, nameEN: false });
    }
  }, [language]);

  useEffect(() => {
    if (errors[`name${language}`]) {
      setThemeValue({
        ...themeValue,
        [`name${language}`]: theme[`name${language}`],
      });
    }
  }, [errors.nameUKR, errors.nameEN]);

  const updateThemeHandler = async () => {
    try {
      if (!themeValue?.id || !themeValue[`name${language}`]) {
        setErrors({ ...errors, [`name${language}`]: true });
        return;
      }
      await updateTheme({
        variables: {
          id: themeValue?.id,
          backgroundId: themeValue.backgroundId,
          [`name${language}`]: themeValue[`name${language}`],
          analyticsMark: themeValue.analyticsMark,
          subscriptionPlan: themeValue.subscriptionPlan,
        },
      });
    } catch (e) {
      setRequestError(true);
      setErrorMessage("При редактировании темы произошла ошибка");
    }
  };

  const deleteThemeHandler = async (id) => {
    try {
      await deleteTheme({ variables: { id } });
    } catch (e) {
      setRequestError(true);
      setErrorMessage("При удалении темы произошла ошибка");
    }
  };

  const handlerAddPhoto = async (photo: File) => {
    try {
      await addPhoto({
        variables: { id, photo },
      });
    } catch (e) {
      setRequestError(true);
      setErrorMessage("При загрузке фото произошла ошибка");
    }
  };

  const handlerDeleteThemePhoto = async () => {
    try {
      await deleteThemePhoto({
        variables: {
          id: theme.id,
        },
      });
    } catch (e) {
      setRequestError(true);
      setErrorMessage("При удалении фото произошла ошибка");
    }
  };
  const subscriptionAccessHandler = useCallback(
    (subscriptionPlan: SubscriptionPlanStatusEnum) => {
      setThemeValue({ ...themeValue, subscriptionPlan });
    },
    []
  );

  return (
    <Box mb="40px">
      <Box key={id} mb="5px" display="flex">
        <MobileAppInput
          fullWidth
          disabled={disableOnLoading || isThemeCreating || !editValue}
          value={themeValue[`name${language}`] || ""}
          error={errors[`name${language}`]}
          onChange={({ target: { value } }) =>
            setThemeValue({ ...themeValue, [`name${language}`]: value })
          }
          InputProps={{
            endAdornment: (
              <Box display="flex">
                <DeleteIconButton
                  fz={20}
                  disabled={disableOnLoading || !editValue}
                  deleteHandler={() => deleteThemeHandler(id)}
                  item="тему"
                />

                {editValue ? (
                  <SaveIconButton
                    fz={20}
                    disabled={disableOnLoading}
                    save={async () => {
                      await updateThemeHandler();
                      setEditValue(false);
                    }}
                  />
                ) : (
                  <EditIconButton
                    fz={20}
                    disabled={disableOnLoading}
                    edit={() => setEditValue(true)}
                  />
                )}
              </Box>
            ),
          }}
        />
        <LanguageSwitch
          switchLanguage={setLanguage}
          languages={languages}
          error={errors}
          disabled={disableOnLoading}
        />
      </Box>

      <Box mt="10px" display="flex" justifyContent="space-between">
        <Box flexBasis="70%">
          <Autocomplete
            fullWidth
            disableClearable={true}
            disabled={disableOnLoading || isThemeCreating || !editValue}
            options={backgrounds.map((background) => background?.id)}
            getOptionLabel={(option) =>
              backgrounds.find((background) => background.id === option).name
            }
            value={themeValue.backgroundId}
            onChange={(_, value) => {
              if (!value) {
                setThemeValue({ ...themeValue, backgroundId: "" });
              }

              if (typeof value === "string") {
                setThemeValue({ ...themeValue, backgroundId: value });
              }
            }}
            renderInput={(params) => (
              <MobileAppInput {...params} placeholder="Фон" />
            )}
          />
        </Box>

        <PhotoPickerWithIcon
          imageUrl={photo}
          photoName={photoName}
          addPhoto={handlerAddPhoto}
          disabled={disableOnLoading}
          deletePhoto={handlerDeleteThemePhoto}
        />
      </Box>
      <Box mt={2} display="flex" width="70%">
        <MobileAppInput
          fullWidth
          label="Метка"
          disabled={disableOnLoading || isThemeCreating || !editValue}
          value={themeValue.analyticsMark}
          onChange={({ target: { value } }) =>
            setThemeValue({ ...themeValue, analyticsMark: value })
          }
        />
      </Box>
      <Box mt={2} display="flex">
        <Box flexBasis="70%">
          <SelectSubscriptionsPlan
            disabled={disableOnLoading || isThemeCreating || !editValue}
            status={themeValue?.subscriptionPlan}
            setSubscriptionAccess={subscriptionAccessHandler}
          />
        </Box>
      </Box>
    </Box>
  );
};
