import { useQuery } from "@apollo/client";
import {
  GetSchoolCitiesDocument,
  GetSchoolCitiesQuery,
} from "../../../generated/graphql";
import { useRequestError } from "../../../hooks";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { StyledTextField } from "../Style";
import { LoadingBox } from "../../shared/Style/Style";

interface SelectCityProps {
  regionId: string;
  districtId?: string;
  cityId: string;
  inputError: boolean;
  setCityId: (cityId: string) => void;
}

export const SelectCities = ({ regionId, districtId, cityId, inputError, setCityId }: SelectCityProps) => {
  const { data, loading, error } = useQuery<GetSchoolCitiesQuery>(GetSchoolCitiesDocument, {
    variables: {
      regionId,
      districtId,
      limit: 99999,
    },
  });

  const { requestError, setRequestError } = useRequestError(error);

  if (loading) {
    return (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  const schoolCities = data?.getSchoolCities ?? [];

  return (
    <>
      <Autocomplete
        options={schoolCities.map((schoolCity) => schoolCity.id)}
        getOptionLabel={(option) => schoolCities.find((schoolCity) => schoolCity.id === option)?.name || ""}
        onChange={(_, value) => setCityId(value)}
        value={schoolCities.find((schoolCity) => schoolCity.id === cityId)?.id || null}
        renderInput={(params) => (
          <StyledTextField
            label="Населённый пункт"
            variant="filled"
            error={inputError}
            {...params}
          />
        )}
      />

      <ErrorMessageSnackbar
        open={requestError}
        message="При загрузке списка населённых пунктов произошла ошибка"
        closeHandler={() => setRequestError(false)}
      />
    </>
  );
};
