import { FC } from "react";
import { ParticipantsTableHeader } from "./ParticipantsTableHeader";
import { ParticipantsTableBody } from "./ParticipantsTableBody";
import { LoadingProgress } from "../../../shared/LoadingProgress";
import { User } from "../../../../generated/graphql";

interface ParticipantsTableProps {
    participants: User[];
    isLoading: boolean;
}

export const ParticipantsTable: FC<ParticipantsTableProps> = ({ participants, isLoading }) => {
    return (
        <>
            <ParticipantsTableHeader />   
            {isLoading ?
                <LoadingProgress /> :
                <ParticipantsTableBody
                    participants={participants}
                />
            }
        </>
    );
};
