import React from "react";
import { Rating } from "@material-ui/lab";
import {
  Box,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import {
  GetAnswersBySmartNotificationIdDocument,
  GetAnswersBySmartNotificationIdQuery,
  GetRatingAnswersCountBySmartNotificationIdDocument,
  GetRatingAnswersCountBySmartNotificationIdQuery,
} from "../../../generated/graphql";
import { LoadingBox, MainTableCell } from "../../shared/Style/Style";
import { convertDateStringToLocaleHandler } from "../../shared/Utils/ConvertOperations/convertDateStringToLocaleHandler";
import { convertDateAndTimeToLocaleHandler } from "../../shared/Utils/ConvertOperations/convertTimeToLocalHandler";
import { RatingScrollBox } from "../SmartNotification.style";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";
import { ExportUserCSV } from "./ExportUserCSV";

export const SmartNotificationRatting = ({ answers, pollId }) => {
  const arrRating = [5, 4, 3, 2, 1];
  const max = Math.max(...arrRating);
  const colorRating = {
    colors: ["#7BA705", "#87CE65", "#F0FA39", "#FFA425", "#E85050"],
  };

  const {
    data: answersNotificationData,
    loading: answersNotificationLoading,
    error: answersNotificationError,
  } = useQuery<GetAnswersBySmartNotificationIdQuery>(
    GetAnswersBySmartNotificationIdDocument,
    {
      variables: {
        smartNotificationId: pollId,
      },
    }
  );

  const {
    data: getRatingData,
    loading: getRatingLoading,
    error: getRatingError,
  } = useQuery<GetRatingAnswersCountBySmartNotificationIdQuery>(
    GetRatingAnswersCountBySmartNotificationIdDocument,
    {
      variables: {
        smartNotificationId: pollId,
      },
    }
  );

  const disableOnLoading = answersNotificationLoading || getRatingLoading;

  let content;

  if (disableOnLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (answersNotificationError || getRatingError) {
    content = (
      <ErrorSnackbar error={answersNotificationError || getRatingError} />
    );
  }

  if (answersNotificationData) {
    const answer = answersNotificationData?.getAnswersBySmartNotificationId;
    const sumAllRating =
      getRatingData?.getRatingAnswersCountBySmartNotificationId;

    content = (
      <>
        <Box display="flex" height="30%" mb={4}>
          <Box fontSize="18px" p={2} width="15%">
            <Box>Всего:</Box>
            <Box sx={{ fontWeight: "bold" }}>{answers?.total} оценок</Box>
          </Box>
          <Box
            display="flex"
            width="15%"
            alignItems="center"
            flexDirection="column"
          >
            <Box sx={{ fontWeight: "bold" }} m={0} fontSize="60px">
              {sumAllRating}
            </Box>
            <Box>из 5</Box>
          </Box>

          <Box width="70%" display="flex" alignItems="flex-start">
            <Box display="flex" flexDirection="column">
              {arrRating.map((rating, index) => (
                <Rating key={index} value={rating} disabled={true} />
              ))}
            </Box>

            <Table>
              <TableBody>
                {answers?.answers?.map((answer, index) => (
                  <TableRow>
                    <MainTableCell
                      style={{ borderBottom: "0", padding: "2px" }}
                      align="left"
                    >
                      <Box
                        key={index}
                        style={{
                          backgroundColor: colorRating.colors[index],
                          textAlign: "start",
                          height: "auto",
                          width: `${(answer.total / max) * 100 + "%"}`,
                        }}
                      >
                        {answer.total}
                      </Box>
                    </MainTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>

        <Box> <ExportUserCSV answers={answer} /></Box>

        <Box width="100%" boxShadow={3} borderRadius={5} mb={4} p="10px">
          <RatingScrollBox>
            {answer?.map(
              ({
                createdDate,
                createdBy,
                ratting,
              }) => (
                <Box display="flex" width="33%">
                  <MainTableCell>
                    {convertDateStringToLocaleHandler(createdDate)}
                  </MainTableCell>
                  <MainTableCell>
                    {convertDateAndTimeToLocaleHandler(createdDate)}
                  </MainTableCell>
                  <MainTableCell>
                    <Box display="flex" flexDirection="column" ml={2}>
                      {createdBy?.firstName?createdBy?.firstName:"Удаленый"} {createdBy?.lastName?createdBy?.lastName:"пользователь"}
                      <Rating value={ratting} disabled={true} />
                    </Box>
                  </MainTableCell>
                </Box>
              )
            )}
          </RatingScrollBox>
        </Box>
      </>
    );
  }

  return <Container>{content}</Container>;
};
