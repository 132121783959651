import styled from "styled-components";

export const TableHeader = styled.div<{ primary?: boolean }>`
  display: grid;
  margin: 1rem 0 0.8rem;
  padding: 14px;
  grid-template-columns: ${(props) =>
    props.primary ? "repeat(6, 1fr) 70px" : "repeat(5, 1fr) 70px"};
  grid-gap: 10px;
  border-radius: 14px;
  background-color: white;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  justify-items: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const Title = styled.div`
  flex-grow: 4;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: 42px;
  text-transform: uppercase;
  color: #c4c4c4;
`;

export const Table = styled.div`
  width: 100%;
  min-height: 75px;
  display: flex;
  flex-direction: column;
  z-index: -1;
`;

export const TableHeaderTitle = styled.span`
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-right: 20px;
`;

export const TableRows = styled.div`
  overflow-y: auto;
  padding: 3px;
  display: flex;
  flex-direction: column;
`;

export const InfoRow = styled.div`
  width: 99.7%;
  padding-bottom: 8px;
`;

export const TableRow = styled.div<{ primary?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) =>
    props.primary ? "repeat(6, 1fr) 50px" : " repeat(5, 1fr) 50px"};
  grid-gap: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  justify-items: center;
  align-items: center;
  word-wrap: break-word;
  flex: 1 0 10%;
  min-width: 150px;
  padding: 14px;
  background-color: white;
  box-shadow: 0 0 10px rgb(0 0 0 / 8%);
  border-radius: 14px;

  position: relative;
  z-index: 0;
`;

// export const Status = styled.span`
//   &.UserStatus__PENDING {
//     color: #efd473;
//   }
//
//   &.UserStatus__ACCEPTED {
//     color: #50e896;
//   }
//
//   &.UserStatus__NOT_ACCEPTED {
//     color: #e85050;
//   }
// `;

export const TableFooterCommon = styled.div`
  padding: 0 14px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
`;

export const ProjectEmployeesList = styled.div`
  max-height: 262px;
  overflow-y: auto;
`;

export const ProjectName = styled.span``;
export const Status = styled.span``;
export const Responsible = styled.span``;
export const Deadline = styled.span``;
export const CurrentStage = styled.span``;
