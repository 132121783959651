import React, { useEffect, useState } from "react";
import { navItemsMobileAppsMainPages } from "../../constants/navItemMobileApp";
import { Box, CircularProgress, Container } from "@material-ui/core";
import {
  ButtonPlus,
  Header,
  LoadingBox,
  MainTableCell,
  MainTableHeaderTitle,
} from "../shared/Style/Style";
import { NavBar } from "../shared/NavBar";
import {
  MobileAppLessonTableRow,
  MobileAppLessonsTitleTableRow, EmptyMobileAppLessonTableRow,
} from "./Lessons.style";
import {useHistory, useParams} from "react-router-dom";
import { LessonModalSelect } from "./CreateLessonModals/LessonModalSelect";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateLessonBlockDocument,
  CreateLessonDocument,
  CreateLessonMutation,
  DeleteLessonDocument,
  DeleteLessonMutation,
  GetApplicationMainModelsQuery,
  GetApplicationMainModelsSelectDocument,
  GetApplicationMainThemesByMainIdDocument,
  GetApplicationMainThemesByMainIdQuery,
  GetLessonsByMasterIdDocument,
  GetLessonsByMasterIdQuery,
  PublishLessonDocument,
  PublishLessonMutation,
} from "../../generated/graphql";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { CustomTablePagination } from "../shared/CustomTablePagination";
import { LessonModalTextField } from "./CreateLessonModals/LessonModalTextField";
import { Menu } from "../shared/Menu";
import {ErrorMessageSnackbar} from "../shared/ErrorMessageSnackbar";

const headers = ["Заголовок", "Описание", "Статус"];

export enum Entity {
  Theme = "тему",
  Model = "модель",
  Lesson = "урока",
};

export const Lessons = () => {
  const { pageId, appId } = useParams<{ pageId: string, appId: string }>();
  const { push } = useHistory()

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [chosenLessonIdModal, setChosenLessonIdModal] = useState("");

  const [isOpenModalModel, setIsOpenModalModel] = useState(false);
  const [selectedModel, setSelectedModel] = useState("");

  const [isOpenModalTheme, setIsOpenModalTheme] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState("");

  const [isOpenModalNameLesson, setIsOpenModalNameLesson] = useState(false);

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    data: lessonsList,
    loading: lessonsListLoading,
    error: lessonsListError,
  } = useQuery<GetLessonsByMasterIdQuery>(GetLessonsByMasterIdDocument, {
    variables: {
      getLessonsParams: {
        mainId: pageId,
        limit: rowsPerPage,
        skip: page * rowsPerPage,
      },
    },
  });

  const {
    data: models,
    loading: loadingModels,
    error: errorModels,
  } = useQuery<GetApplicationMainModelsQuery>(
    GetApplicationMainModelsSelectDocument,
    {
      variables: {
        applicationMainId: pageId,
        limit: 1000,
      },
    }
  );

  const {
    data: themes,
    loading: loadingThemes,
    error: themeError,
  } = useQuery<GetApplicationMainThemesByMainIdQuery>(
    GetApplicationMainThemesByMainIdDocument,
    {
      variables: {
        applicationMainId: pageId,
      },
    }
  );

  const [
    createLesson,
    { loading: loadingLesson, error: errorLesson },
  ] = useMutation<CreateLessonMutation>(CreateLessonDocument, {
    variables: {
      mainId: pageId,
      themeId: selectedTheme,
      modelId: selectedModel,
    },
    refetchQueries: [
      GetLessonsByMasterIdDocument
    ]
  });

  const handlerCreateLesson = async (name: string) => {
    try {
      await createLesson({
        variables: { name }
      })
    }catch (e) {
      setRequestError(true)
      setErrorMessage("При создании урока произошла ошибка, привяжите другую модель")
    }
  }

  const [
    publishLesson,
    { loading: publishLessonLoading, error: publishLessonError },
  ] = useMutation<PublishLessonMutation>(PublishLessonDocument, {
    variables: {
      lessonId: chosenLessonIdModal,
    },
    refetchQueries: [
      GetLessonsByMasterIdDocument
    ]
  });

  const handlerPublishLesson = async () => {
    try {
      await publishLesson()
    }catch (e) {
      setRequestError(true)
      setErrorMessage('При публикации урока произшла ошибка')
    }
  }

  const [
    deleteLesson,
    { loading: deleteLessonLoading, error: deleteLessonError },
  ] = useMutation<DeleteLessonMutation>(DeleteLessonDocument, {
    variables: {
      lessonId: chosenLessonIdModal,
    },
    refetchQueries: [
      GetLessonsByMasterIdDocument
    ]
  });

  const handlerDeleteLesson = async () => {
    try {
      await deleteLesson()
    }catch (e) {
      setRequestError(true)
      setErrorMessage('При удалении урока произошла ошибка')
    }
  }

  useEffect(() => {
    if (models) {
      setSelectedModel(models.getApplicationMainModels.models[0]?.id);
    }
  }, [models]);

  useEffect(() => {
    if (themes) {
      setSelectedTheme(themes.getApplicationMainThemesByMainId[0]?.id);
    }
  }, [themes]);

  useEffect(() => {
    if(themeError) {
      setRequestError(true)
      setErrorMessage('При загрузке тем произошла ошибка')
    }
  }, [themeError])

  useEffect(() => {
    if(errorModels) {
      setRequestError(true)
      setErrorMessage('При загрузке моделей произошла ошибка')
    }
  }, [errorModels])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const disableOnLoading =
    loadingModels ||
    loadingLesson ||
    loadingThemes ||
    lessonsListLoading ||
    publishLessonLoading ||
    deleteLessonLoading;

  const pagination = (
    <CustomTablePagination
      rowsPerPageOptions={[10, 20]}
      count={lessonsList?.getLessonsByMasterId?.total || 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );

  let content;

  if (disableOnLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (errorModels) {
    content = <ErrorSnackbar error={errorModels} />;
  }

  if (lessonsList) {
    const lessons = lessonsList.getLessonsByMasterId.lessons;

    const menuItems = [
      {
        name: "Опубликовать",
        action: () => handlerPublishLesson(),
      },
      {
        name: "Редактировать урок",
        action: () => {},
      },
      {
        name: "Удалить",
        action: () => handlerDeleteLesson(),
        color: "red",
      },
    ];

    content = (
      <Box>
        <Header>Уроки</Header>

        <Box
          display="flex"
          justifyContent="space-between"
          mt={"40px"}
          mb={"26px"}
        >
          <NavBar items={navItemsMobileAppsMainPages} margin={"0"} />

          <ButtonPlus
            width={179}
            variant="contained"
            disabled={false}
            onClick={() => setIsOpenModalModel(true)}
          >
            Создать урок
          </ButtonPlus>
        </Box>

        {pagination}

        <MobileAppLessonsTitleTableRow>
          {headers.map((header) => (
            <MainTableHeaderTitle key={header}>{header}</MainTableHeaderTitle>
          ))}
        </MobileAppLessonsTitleTableRow>

        {lessons.length &&
          lessons.map(({ name, isPublished, id }) => (
            <MobileAppLessonTableRow onClick={() => push(`/mobileApps/${appId}/pages/${pageId}/lesson/${id}`)}>
              <MainTableCell>{name}</MainTableCell>

              <MainTableCell>Описание</MainTableCell>

              <MainTableCell>
                {(isPublished && "Опубликованно") || "Не опликованно"}
              </MainTableCell>

              <MainTableCell
                onClick={(e) => {
                  e.stopPropagation();
                  setChosenLessonIdModal(id);
                }}
              >
                <Menu
                  vertical={36}
                  horizontal={12}
                  width={170}
                  disabled={disableOnLoading}
                  items={menuItems}
                />
              </MainTableCell>
            </MobileAppLessonTableRow>
          ))
        || <EmptyMobileAppLessonTableRow>Уроки отсутствуют</EmptyMobileAppLessonTableRow>
        }
      </Box>
    );
  }

  const modelsItems = models?.getApplicationMainModels?.models;
  const themeItems = themes?.getApplicationMainThemesByMainId;

  return (
    <Container maxWidth="lg" onClick={() => window.scrollTo({ top: 0 })}>

      {content}

      <LessonModalSelect
        open={isOpenModalModel}
        items={modelsItems}
        selectedItem={selectedModel}
        setSelectedItem={setSelectedModel}
        disableOnLoading={disableOnLoading}
        closeModal={() => setIsOpenModalModel(false)}
        nextModal={() => setIsOpenModalTheme(true)}
        entity={Entity.Model}
      />

      <LessonModalSelect
        open={isOpenModalTheme}
        items={themeItems}
        selectedItem={selectedTheme}
        setSelectedItem={setSelectedTheme}
        closeModal={() => setIsOpenModalTheme(false)}
        nextModal={() => setIsOpenModalNameLesson(true)}
        disableOnLoading={disableOnLoading}
        entity={Entity.Theme}
      />

      <LessonModalTextField
        open={isOpenModalNameLesson}
        createLesson={handlerCreateLesson}
        disableOnLoading={disableOnLoading}
        closeModal={() => setIsOpenModalNameLesson(false)}
        entity={Entity.Lesson}
      />

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />

    </Container>
  );
};
