export type ParticipantsType = {
      "adminsActual": number,
      "teachersActual": number,
      "teachersPlan": number,
      "pupilsActual": number,
      "pupilsPlan": number,
      "parentsActual": number,
};

export const createAllParticipants = (data: ParticipantsType) => {
    const { adminsActual, teachersPlan, teachersActual, pupilsPlan, pupilsActual, parentsActual} = data;
    const totalActual = teachersActual + pupilsActual;
    const totalPlan = teachersPlan + pupilsPlan;

    return [
    createParticipants('Администраторы:', '', adminsActual, ''),
    createParticipants('', 'План', 'Факт', '% подключения'),
    createParticipants('Учителя:', teachersPlan, teachersActual, calculateRate(teachersPlan,teachersActual)),
    createParticipants('Ученики:', pupilsPlan, pupilsActual, calculateRate(pupilsPlan,pupilsActual)),
    createParticipants('Всего:', totalPlan, totalActual, calculateRate(totalPlan,totalActual)),
    createParticipants('Родители:', '', parentsActual, ''),
  ];
}

const createParticipants = (
    name: string,
    plan: number | string,
    actual: number | string,
    rate: string
) => {
    return { name, plan, actual, rate };
};

const calculateRate = (plan: number, actual: number) => {
    if (plan === 0) return '';
    return `${Math.round((actual / plan) * 100)}%`;
};
  