import { FC } from "react";
import { useHistory } from "react-router-dom";
import { ParticipantsTableCell, ParticipantsTableCellName, ParticipantsTableRow } from "../style";
import { User } from "../../../../generated/graphql";
import { getParticipantsRoles } from "../utils";

interface ParticipantsTableBodyProps {
    participants: User[];
}

export const ParticipantsTableBody: FC<ParticipantsTableBodyProps> = ({
    participants
}) => {
    const { push } = useHistory();

    const handleRedirect = (id: string) => push(`/mobileAppUsers/${id}/editUser`);

    return (
        <tbody>{participants.map(participant => (
            <ParticipantsTableRow>
                <ParticipantsTableCell>{participant?.id}</ParticipantsTableCell>
                <ParticipantsTableCellName onClick={() => handleRedirect(participant?.id)}>
                    {participant?.fullName}
                </ParticipantsTableCellName>
                <ParticipantsTableCell>{participant?.email}</ParticipantsTableCell>
                <ParticipantsTableCell>{getParticipantsRoles(participant?.role)}</ParticipantsTableCell>
                <ParticipantsTableCell>{participant?.phone}</ParticipantsTableCell>
                <ParticipantsTableCell>{participant?.country?.name}</ParticipantsTableCell>
            </ParticipantsTableRow>
        ))}</tbody>
    );
};
