import React from "react";
import { useQuery } from '@apollo/client';
import { GetProjectEpicsDocument, GetProjectEpicsQuery, ProjectEpic } from "../../../generated/graphql";
import { IDateRange } from "../../shared/DateRangePicker/DateRangePicker";

import { LoadingBox } from "../../shared/Style/Style";
import { CircularProgress } from "@material-ui/core";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";

import { DifferenceInHoursContent } from "./Contents/DifferenceInHoursContent";
import { DifferenceInMoneyContent } from "./Contents/DifferenceInMoneyContent";
import { DifferenceInDateContent } from "./Contents/DifferenceInDateContent";
import { CompletionPercentContent } from "./Contents/CompletionPercentContent";
import { AddedTasksContent } from "./Contents/AddedTasksContent";

interface IProjectReportModalProps {
  projectId: string;
  reportType: string;
  dateRange?: IDateRange;
}

export interface IProjectReportModalContentProps {
  epics?: ProjectEpic[];
  projectId?: string;
  dateRange?: IDateRange;
}

export const ProjectReportModal = ({ projectId, reportType, dateRange }: IProjectReportModalProps) => {
  const {
    data: epicsData,
    loading: epicsLoading,
    error: epicsError,
  } = useQuery<GetProjectEpicsQuery>(GetProjectEpicsDocument, { variables: {projectId} });

  let content;

  if (epicsLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit' />
      </LoadingBox>
    )
  }

  if (epicsError) {
    content = <ErrorSnackbar error={epicsError} />
  }

  if (epicsData) {
    const epics = epicsData.getProjectEpics;

    switch (reportType) {
      case 'differenceInHours':
        content = <DifferenceInHoursContent epics={epics} dateRange={dateRange}/>;
        break;

      case 'differenceInMoney':
        content = <DifferenceInMoneyContent epics={epics} dateRange={dateRange}/>;
        break;

      case 'differenceInDate':
        content = <DifferenceInDateContent epics={epics} dateRange={dateRange}/>;
        break;

      case 'completionPercent':
        content = <CompletionPercentContent epics={epics} />;
        break;

      case 'addedTasks':
        content = <AddedTasksContent projectId={projectId} dateRange={dateRange}/>;
        break;

      default:
        content = <DifferenceInHoursContent epics={epics} dateRange={dateRange}/>;
        break;
    }
  }

  return (
    <>
      {content}
    </>
  )
}