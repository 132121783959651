import React, { useEffect, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ClientSiteModel,
  CreateClientSiteModelMutation,
  CreateClientSiteModelDocument,
  UpdateClientSiteModelMutation,
  UpdateClientSiteModelDocument,
  GetModelsByClientSiteIdDocument,
  GetClientSiteModelQuery,
  GetClientSiteModelDocument,
} from "../../../../../generated/graphql";

import { Dialog, DialogContent, Box, TextField } from "@material-ui/core";

import { ModelFileList } from "./ModelFileList";
import { ModelItemsList } from "./ModelItemsList";

import { CloseIconButton } from "../../../../shared/Buttons/CloseIconButton";
import { ErrorMessageSnackbar } from "../../../../shared/ErrorMessageSnackbar";

import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
  useStyles,
} from "../../../../shared/Style/Style";

import {
  initialModelData,
  initialModelErrors,
  initialModelFilesErrors,
  ModelDataErrors,
  ModelFilesErrors,
} from "./constants";


interface CreateModelModalProps {
  open: boolean;
  close(): void;
  refetchVariables: {
    limit: number;
    skip: number;
    clientSiteId: string;
  };
  model: ClientSiteModel;
  modelId: string;
  siteId: string;
}


export const ModelModal = ({
  open,
  close,
  refetchVariables,
  modelId,
  siteId,
}: CreateModelModalProps) => {
  const classes = useStyles();

  const [itemIds, setItemIds] = useState<string[]>([]);

  const [modelData, setModelData] = useState<ClientSiteModel>({});

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const requestErrorHandler = (message: string) => {
    setRequestError(true);
    setErrorMessage(message);
  };

  const requestSuccessHandler = () => {
    setRequestError(false);
    setErrorMessage('');
  };

  const [modelDataError, setModelDataError] =
    useState<ModelDataErrors>(initialModelErrors);
  const [modelFilesError, setModelFilesError] = useState<ModelFilesErrors>(
    initialModelFilesErrors
  );

  const [
    getModelData,
    {
      data: clientModelData,
      loading: clientModelDataLoading,
      error: clientModelDataError,
    },
  ] = useLazyQuery<GetClientSiteModelQuery>(GetClientSiteModelDocument, {
    fetchPolicy: "network-only",
  });

  const [
    createModel,
    {
      data: createModelData,
      loading: createModelLoading,
      error: createModelError,
    },
  ] = useMutation<CreateClientSiteModelMutation>(CreateClientSiteModelDocument);

  const [
    updateModel,
    {
      data: updateModelData,
      loading: updateModelLoading,
      error: updateModelError,
    },
  ] = useMutation<UpdateClientSiteModelMutation>(
    UpdateClientSiteModelDocument,
    {
      refetchQueries: [
        { query: GetModelsByClientSiteIdDocument, variables: refetchVariables },
      ],
    }
  );

  useEffect(() => {
    if (modelId) {
      getModelData({ variables: { id: modelId } });
    }
  }, [modelId]);

  useEffect(() => {
    if (!modelId) {
      setModelData({});
    }
  }, [modelId]);

  useEffect(() => {
    if (createModelData) {
      getModelData({ variables: { id: createModelData.createClientSiteModel.id } });
    }
  }, [createModelData]);

  useEffect(() => {
    if (
      clientModelData ||
      clientModelData?.getClientSiteModel?.gltfFile ||
      clientModelData?.getClientSiteModel?.glbFile ||
      clientModelData?.getClientSiteModel?.usdzFile
    ) {
      const model = clientModelData.getClientSiteModel;
      setModelData(model);
    }
  }, [
    clientModelData,
    clientModelData?.getClientSiteModel?.gltfFile,
    clientModelData?.getClientSiteModel?.glbFile,
    clientModelData?.getClientSiteModel?.usdzFile,
  ]);

  useEffect(() => {
    if (updateModelData) {
      closeHandler();
      setModelDataError(initialModelErrors);
    }
  }, [updateModelData]);

  useEffect(() => {
    if (createModelError) {
      requestErrorHandler("При создании модели произошла ошибка")
    }
  }, [createModelError]);

  useEffect(() => {
    if (updateModelError) {
      requestErrorHandler("При редактировании модели произошла ошибка")
    }
  }, [updateModelError]);

  useEffect(() => {
    if (clientModelDataError) {
      requestErrorHandler("При загрузке модели произошла ошибка")
    }
  }, [clientModelDataError]);

  const closeHandler = () => {
    setModelData(initialModelData);
    setModelDataError(initialModelErrors);
    requestSuccessHandler();
    close();
  };

  const onSave = () => {
    if (!modelData.name) {
      setModelDataError((errors) => ({
        ...errors,
        nameError: "Обязательное поле",
      }));
    }

    if (modelData.id && !modelData.items?.length) {
      setModelDataError((errors) => ({
        ...errors,
        itemIdError: "Обязательное поле",
      }));
    }

    if (!modelData.id && !itemIds.length) {
      setModelDataError((errors) => ({
        ...errors,
        itemIdError: "Обязательное поле",
      }));
    }

    if (!modelData.name || (modelData.id && !modelData.items?.length) || (!modelData.id && !itemIds.length)) {
      return;
    }

    if (
      modelData.id &&
      (!modelData?.glbFile || !modelData?.gltfFile || !modelData?.usdzFile)
    ) {
      setModelFilesError({
        glbFileError: !modelData?.glbFile ? "Обязательное поле" : "",
        gltfFileError: !modelData?.gltfFile ? "Обязательное поле" : "",
        usdzFileError: !modelData?.usdzFile ? "Обязательное поле" : "",
      });

      return;
    }

    modelData.id
      ? updateModel({
          variables: {
            ...modelData,
            id: modelData.id,
          },
        })
      : createModel({
          variables: {
            ...modelData,
            clientSiteId: siteId,
            itemIds,
          },
        });
  };

  const disableOnLoading = createModelLoading || updateModelLoading || clientModelDataLoading;


  return (
    <Dialog open={open}>
      <DialogContent style={{ width: "500px" }}>
        <CloseIconButton close={closeHandler} />
        <Box style={{ padding: "15px" }}>
          <TextField
            disabled={disableOnLoading}
            className={classes.root}
            fullWidth
            variant="filled"
            type="text"
            label={`Название*`}
            value={modelData.name}
            onChange={({ target: { value } }) =>
              setModelData({ ...modelData, name: value })
            }
            onFocus={() =>
              setModelDataError({ ...modelDataError, nameError: "" })
            }
            error={!!modelDataError.nameError}
            helperText={modelDataError.nameError}
            InputLabelProps={{
              shrink: !!modelData.name || !modelData.id,
            }}
          />

          {modelData.id && (
            <ModelFileList
              model={modelData}
              clientSiteModelId={modelData?.id}
              disabled={disableOnLoading}
              modelFilesError={modelFilesError}
              setModelFilesError={setModelFilesError}
            />
          )}

          <ModelItemsList
            clientSiteModelId={modelId || createModelData?.createClientSiteModel?.id}
            disabled={disableOnLoading}
            modelData={modelData}
            itemIds={itemIds}
            setItemIds={setItemIds}
            modelDataError={modelDataError}
            setModelDataError={setModelDataError}
            requestErrorHandler={requestErrorHandler}
            requestSuccessHandler={requestSuccessHandler}
          />

          <ButtonsWrapper>
            <CancelButton
              onClick={closeHandler}
              disabled={disableOnLoading}
              width={"150"}
            >
              Отмена
            </CancelButton>

            <ButtonCustom
              onClick={onSave}
              disabled={disableOnLoading}
              width={"190"}
            >
              {modelData?.id ? "Сохранить" : "Создать"}
            </ButtonCustom>
          </ButtonsWrapper>
        </Box>
      </DialogContent>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Dialog>
  );
};
