import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  GetProjectDocument,
  GetProjectQuery,
} from "../../../generated/graphql";
import {
  Backdrop,
  CircularProgress,
  Paper,
  Box,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ProjectEditForm } from "./ProjectEditForm";
import { ProjectAddContact } from "./ProjectAddContact";
import { ProjectEditNavBar } from "../../ProjectEditNavBar";
import { AddPM } from "./AddPM";
import { ProjectManagerInfo } from "../../shared/ProjectManagerInfo";
import { FormSmallTitleWithBtn } from "../../shared/FormSmallTitleWithBtn/FormSmallTitleWithBtn";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";
import { ContactPersonInfo } from "../../shared/ContactPersonInfo";
import { primary, secondary } from "../../shared/Style/Style";
import { Header, ProjectEmployeesList, Title } from "./Projects.style";
import { PersonalData } from "../UserEdit/UserEdit.style";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: `${primary}`,
    },
  })
);

export const ProjectEdit = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const [addContact, setAddContact] = useState(false);
  const [addPM, setAddPM] = useState(false);

  const {
    data: projectData,
    loading: projectLoading,
    error: projectError,
  } = useQuery<GetProjectQuery>(GetProjectDocument, {
    variables: {
      projectId,
    },
  });

  const classes = useStyles();
  let content;

  if (projectLoading) {
    content = (
      <Backdrop className={classes.backdrop} open={projectLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (projectError) {
    content = <ErrorSnackbar error={projectError} />;
  }

  const project = projectData?.getProject;

  if (project) {
    const contacts = project.contactPersons;
    const employees = project.employees;

    content = (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ProjectEditForm project={project} />
        </Grid>

        <Grid item xs={12} md={6} style={{ marginBottom: "60px" }}>
          <Box position="relative" mb={2}>
            {project?.projectManager ? (
              <>
                <Typography
                  component={PersonalData}
                  variant="subtitle1"
                  gutterBottom
                >
                  Проджект менеджер проекта
                </Typography>
                <ProjectManagerInfo
                  projectManager={project?.projectManager}
                  edit={setAddPM}
                />
              </>
            ) : (
              <>
                <FormSmallTitleWithBtn
                  title="Проджект менеджер проекта"
                  onClickBtn={setAddPM}
                />
                <Box margin={5}>
                  <Typography>
                    Проджект менеджер появится после его добавления.
                  </Typography>
                </Box>
              </>
            )}

            {addPM && <AddPM projectId={projectId} closeModal={setAddPM} />}
          </Box>

          <Box width={"100%"} height={"1px"} bgcolor={secondary} mb={2} />

          <FormSmallTitleWithBtn
            title="Контактные лица проекта"
            onClickBtn={setAddContact}
          />

          {addContact && (
            <ProjectAddContact projectId={projectId} open={setAddContact} />
          )}

          {contacts.length ? (
            contacts?.map((user) => (
              <ContactPersonInfo
                key={user?.id}
                person={user}
                projectId={project.id}
              />
            ))
          ) : (
            <Box margin={5}>
              <Typography>
                Контактные лица появятся после их добавления.
              </Typography>
            </Box>
          )}

          <Box width={"100%"} height={"1px"} bgcolor={secondary} mb={2} />

          <Box mb={4}>
            <Box mb={-1}>
              <Typography
                component={PersonalData}
                variant="subtitle1"
                gutterBottom
              >
                Сотрудники проекта
              </Typography>

              {employees && employees.length ? (
                <ProjectEmployeesList>
                  {employees?.map((user) => (
                    <ProjectManagerInfo key={user?.id} projectManager={user} />
                  ))}
                </ProjectEmployeesList>
              ) : (
                <Box margin={5}>
                  <Typography>
                    Сотрудники появятся после их добавления.
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Container maxWidth="lg" style={{ position: "relative" }}>
        <Header>
          <Box display="flex" justifyContent="center" width="100%">
            <Title>Настройки проекта</Title>
          </Box>
        </Header>

        <ProjectEditNavBar />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{ padding: "16px" }}>{content}</Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
