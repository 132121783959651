import styled from "styled-components";
import { List, ListItem } from "@material-ui/core";
import { lightBlack } from "../../shared/Style/Style";

export const SettingsInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-top: 40px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const Content = styled.div`
  width: 463px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 0 auto;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${lightBlack};
`;

export const TitleContainer = styled.div`
  margin: 1rem;
`;

export const SettingsItemsList = styled(List)`
  height: 350px;
  overflow-y: auto;
`;

export const SettingsListElement = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

export const SettingsEmptyListPlaceholder = styled.p`
  color: ${lightBlack};
  font-size: 14px;
  text-align: center;
`;