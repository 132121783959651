import styled from "styled-components";
import { Box } from "@material-ui/core";
import { ButtonCustomWithDarkText } from "../shared/Style/Style";

export const ReportNavBarBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 40px 0;
`;


export const ReportNavBarButton = styled(ButtonCustomWithDarkText)`
  text-transform: none;
  &:not(:last-of-type) {
    margin-right: 10px;
  }
  &:first-of-type {
    margin-bottom: 10px;
  }
`;