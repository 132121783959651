import styled from "styled-components";
import {Avatar} from "@material-ui/core";

export const AvatarContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const InfoContainer = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 8px;

  .InfoContainer__name {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
  }
  .InfoContainer__role {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    color: #c4c4c4;
  }
`;

export const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    width: 60px;
    height: 60px;
  }
`;
