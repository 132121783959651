import styled from "styled-components";
import { DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import { ButtonsWrapper, lightBlack, primary } from "../Style/Style";
import { DatePicker } from "@material-ui/pickers";

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const DateRangeTitle = styled(DialogTitle)`
  text-transform: uppercase;
  text-align: center;
  color: ${lightBlack};

  & .MuiTypography-h6 {
    font-size: 16px;
  }
`;

export const DateRangeContent = styled(DialogContent)`
  display: flex;
  gap: 40px;
  text-align: center;
`;

export const DateRangeButtonsWrapper = styled(ButtonsWrapper)`
  margin: 0;
  padding: 20px;
  justify-content: space-between;
`;


export const DateRangeInput = styled(DatePicker)`
  & .MuiFormLabel-root.Mui-focused {
    color: ${primary};
  }

  & .MuiInput-underline {
    &:after {
      border-bottom-color: ${primary};
    }

    &:hover:not(.Mui-disabled):before {
      border-bottom-width: 1px;
    }

  }
`;



