export const choseOrToggleIdHandler = (
  incomingId: string,
  currentId: string,
  setId: (state: string) => void
): void => {
  if (incomingId === currentId) {
    setId('');
    return;
  }

  setId(incomingId);
}