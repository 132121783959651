export const tableHeaders = [
  {
    title: "Этап проекта",
    width: 330,
  },
  {
    title: "Задача",
    width: 300,
  },
  {
    title: "Статус",
    width: 200,
  },
  {
    title: "Ответственный",
    width: 150,
  },
  {
    title: "Плановая дата начала",
    width: 110,
  },
  {
    title: "Плановая дата конца",
    width: 110,
  },
  {
    title: "Фактическая дата начала",
    width: 110,
  },
  {
    title: "Фактическая дата конца",
    width: 110,
  },
  {
    title: "Плановое время",
    width: 110,
  },
  {
    title: "Затраченое время",
    width: 110,
  },
  {
    title: "Метки",
    width: 330,
  },
];