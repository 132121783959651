import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  DeleteSchoolDocument,
  GetSchoolsForAdminPanelDocument,
  GetSchoolsForAdminPanelQuery,
  OrderTypeEnum,
  QueryGetSchoolsForAdminPanelArgs,
  SchoolsSortType,
  SchoolsSubscriptionStatus,
} from "../../generated/graphql";
import { Box, CircularProgress, Container } from "@material-ui/core";
import { useRequestError } from "../../hooks";
import { SchoolsTable } from "./SchoolsTable";
import { SearchBar } from "./SearchBar/SearchBar";
import { ConfirmationModal } from "./ConfirmationModal";
import { CustomTablePagination } from "../shared/CustomTablePagination";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { LoadingProgress } from "../shared/LoadingProgress";
import { ButtonPlus, Header, LoadingBox } from "../shared/Style/Style";
import { SchoolPaths } from "../shared/constants";
import { SchoolsFilters } from "./SchoolsFilters";
import { SchoolsFiltersType } from "./types";
import { API } from "../../core/axios/client";
import { downloadFile } from "../../helpers/downloadFile.helper";
import { InfoTooltip } from "../shared/InfoTooltip";
import { endOfSubscriptionOptionsMapper } from "./constants";
import { setUrlQueryParams } from "../../helpers/setUrlQueryParams.helper";

export const Schools = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);

  const isPersonal = pathname.includes(SchoolPaths.personal);
  const storageKey = isPersonal ? "personalSchoolsFilters" : "schoolsFilters";

  const loadSavedFilters = (): SchoolsFiltersType => {
    const savedFilters = localStorage.getItem(storageKey);
    if (savedFilters) {
      return JSON.parse(savedFilters);
    }
    return {
      searchKey: query.get("searchKey") || null,
      subscriptionStatus:
        (query.get("subscriptionStatus") as SchoolsSubscriptionStatus) || null,
      daysRangeUntilLicenseExpiration:
        (query.get(
          "daysRangeUntilLicenseExpiration"
        ) as keyof typeof endOfSubscriptionOptionsMapper) || null,
      minPupilsCount: query.get("minPupilsCount")
        ? Number(query.get("minPupilsCount"))
        : null,
      maxPupilsCount: query.get("maxPupilsCount")
        ? Number(query.get("maxPupilsCount"))
        : null,
      minTeachersCount: query.get("minTeachersCount")
        ? Number(query.get("minTeachersCount"))
        : null,
      maxTeachersCount: query.get("maxTeachersCount")
        ? Number(query.get("maxTeachersCount"))
        : null,
      sort: (query.get("sort") as SchoolsSortType) || null,
      order: (query.get("order") as OrderTypeEnum) || null,
    };
  };

  const [page, setPage] = useState(Number(query.get("page")) || 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    +query.get("rowsPerPage") || 10
  );
  const [schoolIdToDelete, setSchoolIdToDelete] = useState<string | null>(null);
  const [confirmationModalOpened, setConfirmationModalOpened] = useState(false);
  const [isSchoolsFileLoading, setSchoolsFileLoading] = useState(false);
  const [schoolsFileError, setSchoolsFileError] = useState(null);

  const [filterVariables, setFilterVariables] =
    useState<SchoolsFiltersType>(loadSavedFilters);

  useEffect(() => {
    setUrlQueryParams({ ...filterVariables, page, rowsPerPage });
    localStorage.setItem(storageKey, JSON.stringify(filterVariables));
  }, [filterVariables, page, rowsPerPage, storageKey]);

  const setFilterVariablesMemorized = useCallback(
    (data: SchoolsFiltersType) => {
      setFilterVariables(data);
      if (data === filterVariables) {
        setPage(Number(query.get("page")));
        return;
      }
      setPage(0);
    },
    []
  ) as Dispatch<SetStateAction<SchoolsFiltersType>>;

  const getSchoolVariables: QueryGetSchoolsForAdminPanelArgs = {
    params: {
      limit: rowsPerPage,
      skip: page * rowsPerPage,
      isPersonal,
      ...filterVariables,
      daysRangeUntilLicenseExpiration:
        endOfSubscriptionOptionsMapper[
          filterVariables.daysRangeUntilLicenseExpiration
        ],
    },
  };

  const {
    data: getSchoolsData,
    loading: getSchoolsLoading,
    error: getSchoolsError,
  } = useQuery<GetSchoolsForAdminPanelQuery>(GetSchoolsForAdminPanelDocument, {
    variables: getSchoolVariables,
    fetchPolicy: "cache-and-network",
  });

  const [
    deleteSchool,
    { loading: deleteSchoolLoading, error: deleteSchoolError },
  ] = useMutation(DeleteSchoolDocument, {
    refetchQueries: [
      {
        query: GetSchoolsForAdminPanelDocument,
        variables: getSchoolVariables,
      },
    ],
  });

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSchoolDeletion = () => {
    deleteSchool({ variables: { id: schoolIdToDelete } });
    setConfirmationModalOpened(false);
    setSchoolIdToDelete(null);
  };

  const handleOpen = () => {
    setConfirmationModalOpened(true);
  };

  const handleClose = () => {
    setConfirmationModalOpened(false);
    setSchoolIdToDelete(null);
  };

  const downloadSchools = async () => {
    try {
      setSchoolsFileLoading(true);

      const response = await API.post("/schools/export", {
        isPersonal,
        ...filterVariables,
        daysRangeUntilLicenseExpiration:
          endOfSubscriptionOptionsMapper[
            filterVariables.daysRangeUntilLicenseExpiration
          ],
      });

      downloadFile(response, "schools.csv");
    } catch (error) {
      console.error(error);
      setSchoolsFileError(error);
    } finally {
      setSchoolsFileLoading(false);
    }
  };

  const error = getSchoolsError || deleteSchoolError || schoolsFileError;
  const { requestError, setRequestError } = useRequestError(error);
  const errorMessage =
    (getSchoolsError && "При загрузке списка школ произошла ошибка") ||
    (deleteSchoolError && "При удалении школы произошла ошибка") ||
    (schoolsFileError && "При загрузке списка школ (файл) произошла ошибка");

  const disableOnLoading = getSchoolsLoading || deleteSchoolLoading;

  let content = null;
  let pagination = null;

  if (disableOnLoading) {
    content = <LoadingProgress />;
  }

  if (getSchoolsData) {
    const schools = getSchoolsData?.getSchoolsForAdminPanel?.schools;

    content = (
      <SchoolsTable
        schools={schools}
        isPersonal={isPersonal}
        setSchoolIdToDelete={setSchoolIdToDelete}
        openModal={handleOpen}
        setFilterVariables={setFilterVariablesMemorized}
        filterVariables={filterVariables}
      />
    );

    pagination = (
      <>
        {schools?.length > 0 ? (
          <CustomTablePagination
            rowsPerPageOptions={[10, 20]}
            count={getSchoolsData?.getSchoolsForAdminPanel?.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header>{!isPersonal ? "Школы" : "Персональные школы"}</Header>

      <ConfirmationModal
        open={confirmationModalOpened}
        onConfirm={handleSchoolDeletion}
        onClose={handleClose}
      />

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <SearchBar
            label="Поиск..."
            activeFilter={filterVariables}
            setActiveFilter={setFilterVariablesMemorized}
            minCharsLimit={3}
            defaultValue={filterVariables.searchKey}
            disabled={disableOnLoading}
          />
          <InfoTooltip
            title="Пошук відбувається по ID, назві, електронній адресі та номеру телефону школи, електронній адресі та номеру телефону  адміністратора школи."
            overrideStyles={{ width: 60, height: 60, margin: "30px 0 0 20px" }}
          />
        </Box>

        <Box display="flex">
          <Box>
            {!isSchoolsFileLoading ? (
              <ButtonPlus
                style={{ marginRight: 25 }}
                width={180}
                variant="contained"
                noPlus
                disabled={disableOnLoading}
                onClick={downloadSchools}
              >
                Скачать
              </ButtonPlus>
            ) : (
              <LoadingBox width={30} style={{ marginRight: 25 }}>
                <CircularProgress color="inherit" />
              </LoadingBox>
            )}
          </Box>

          {!isPersonal ? (
            <Box>
              <ButtonPlus
                width={180}
                variant="contained"
                disabled={disableOnLoading}
                onClick={() => history.push(`/${SchoolPaths.school}/create`)}
              >
                Добавить
              </ButtonPlus>
            </Box>
          ) : null}
        </Box>
      </Box>

      <SchoolsFilters
        setFilterVariables={setFilterVariablesMemorized}
        filterVariables={filterVariables}
        isLoading={disableOnLoading}
      />

      {pagination}

      {content}

      {pagination}

      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
    </Container>
  );
};
