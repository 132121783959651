import React, { useState, useEffect, useMemo } from "react";

import { useParams } from "react-router-dom";

import { DragDropContext } from "react-beautiful-dnd";

import { useMutation, useQuery } from "@apollo/client";
import {
  ProjectTask,
  GetProjectKanbanQuery,
  GetProjectKanbanDocument,
  ClearProjectKanbanMutation,
  ClearProjectKanbanDocument,
  GetKanbanWorkflowStepsByProjectQuery,
  GetKanbanWorkflowStepsByProjectDocument,
  ChangeProjectTaskWorkflowStepMutation,
  ChangeProjectTaskWorkflowStepDocument,
  GetProjectTasksByWorkflowStepDocument,
} from "../../generated/graphql";

import { Box, Container, CircularProgress } from "@material-ui/core";

import {
  ButtonCustom,
  Header,
  LoadingBox,
  secondary,
  StyledLink,
} from "../shared/Style/Style";
import { KanbanScrollContainer } from "./Kanban.style";

import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { ProjectEditNavBar } from "../ProjectEditNavBar";
import { KanbanColumn } from "../KanbanColumn";
import { KanbanCreateModal } from "../KanbanCreateModal";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";

const initialDropData = {
  tasksId: "",
  workflowStepId: "",
  workflowStepOrder: 0,
  sourceStepId: "",
  sourceStepOrder: 0,
};

export const Kanban = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const [tasks, setTasks] = useState<{ [key: string]: ProjectTask[] }>({});
  const [dropData, setDropData] = useState(initialDropData);
  const [createKanbanModal, setCreateKanbanModal] = useState(false);

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const tasksIds = useMemo(
    () =>
      Object.values(tasks).flatMap((taskInColumn) =>
        taskInColumn.map((task) => task.id)
      ),
    [tasks]
  );

  const [
    clearKanban,
    {
      data: clearKanbanData,
      loading: clearKanbanLoading,
      error: clearKanbanError,
    },
  ] = useMutation<ClearProjectKanbanMutation>(ClearProjectKanbanDocument, {
    refetchQueries: [
      { query: GetProjectKanbanDocument, variables: { projectId } },
    ],
  });

  const [
    dropTask,
    { data: dropTaskData, loading: dropTaskLoading, error: dropTaskError },
  ] = useMutation<ChangeProjectTaskWorkflowStepMutation>(
    ChangeProjectTaskWorkflowStepDocument
  );

  const {
    data: kanbanData,
    loading: kanbanLoading,
    error: kanbanError,
  } = useQuery<GetProjectKanbanQuery>(GetProjectKanbanDocument, {
    variables: {
      projectId,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: columnsData,
    loading: columnLoading,
    error: columnError,
  } = useQuery<GetKanbanWorkflowStepsByProjectQuery>(
    GetKanbanWorkflowStepsByProjectDocument,
    {
      variables: {
        projectId,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    if (dropTaskError) {
      const droppedTask = tasks[dropData.workflowStepId].find(
        (t) => t.id === dropData.tasksId
      );
      const destinationTasks = [...tasks[dropData.sourceStepId]].splice(
        dropData.sourceStepOrder,
        0,
        droppedTask
      );

      setRequestError(true);
      setErrorMessage("При перемещении задачи произошла ошибка");

      setTasks((state) => ({
        ...state,
        [dropData.sourceStepId]: tasks[dropData.sourceStepId].filter(
          (task) => task.id !== dropData.tasksId
        ),
      }));

      setTasks((state) => ({
        ...state,
        [dropData.workflowStepId]: destinationTasks,
      }));
    }
  }, [dropTaskError]);

  useEffect(() => {
    if (dropTaskData) {
      setDropData(initialDropData);
    }
    setRequestError(false);
    setErrorMessage("");
  }, [dropTaskData]);

  useEffect(() => {
    if (clearKanbanError) {
      setRequestError(true);
      setErrorMessage("При завершении спринта произошла ошибка");
    }
  }, [clearKanbanError]);

  useEffect(() => {
    if (clearKanbanData) {
      setRequestError(false);
      setErrorMessage("");
      setTasks({});
    }
  }, [clearKanbanData]);

  const dropHandler = (
    tasksId: string,
    workflowStepId: string,
    workflowStepOrder: number,
    sourceStepId: string,
    sourceStepOrder: number
  ): void => {
    const droppedTask = tasks[sourceStepId].find((t) => t.id === tasksId);
    const destinationTasks = [...tasks[workflowStepId]];

    if (sourceStepId === workflowStepId) {
      destinationTasks.splice(sourceStepOrder, 1);
      destinationTasks.splice(workflowStepOrder, 0, droppedTask);

      setTasks((state) => ({
        ...state,
        [workflowStepId]: destinationTasks,
      }));
      return;
    }

    destinationTasks.splice(workflowStepOrder, 0, droppedTask);

    setTasks((state) => ({
      ...state,
      [sourceStepId]: tasks[sourceStepId].filter((task) => task.id !== tasksId),
      [workflowStepId]: destinationTasks,
    }));

    setDropData({
      tasksId,
      workflowStepId,
      workflowStepOrder,
      sourceStepId,
      sourceStepOrder,
    });

    dropTask({
      variables: {
        tasksId,
        workflowStepId,
        workflowStepOrder,
      },
      refetchQueries: [
        {
          query: GetProjectTasksByWorkflowStepDocument,
          variables: {
            projectId,
            workflowStepId: sourceStepId,
            limit: 1000,
            skip: 0,
          },
        },
        {
          query: GetProjectTasksByWorkflowStepDocument,
          variables: {
            projectId,
            workflowStepId,
            limit: 1000,
            skip: 0,
          },
        },
      ],
    });
  };

  let content;

  if (kanbanLoading || columnLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (kanbanError || columnError) {
    content = <ErrorSnackbar error={kanbanError || columnError} />;
  }

  if (kanbanData && columnsData) {
    const kanban = kanbanData.getProjectKanban;
    const columns = columnsData.getKanbanWorkflowStepsByProject;

    content = (
      <>
        <Box mb={2} display="flex" justifyContent="flex-end">
          {!kanban?.name && (
            <ButtonCustom
              disabled={false}
              onClick={() => setCreateKanbanModal(true)}
            >
              Создать спринт
            </ButtonCustom>
          )}

          <Box ml={2}>
            <ButtonCustom
              disabled={clearKanbanLoading}
              onClick={() => {
                kanban?.isCompleted
                  ? setCreateKanbanModal(true)
                  : clearKanban({ variables: { id: kanban.id } });
              }}
            >
              {kanban?.isCompleted ? "Запустить спринт" : "Завершить спринт"}
            </ButtonCustom>
          </Box>

          <Box ml={2}>
            <StyledLink to={`/project/${projectId}/kanban/settings`}>
              <ButtonCustom>Настройки доски</ButtonCustom>
            </StyledLink>
          </Box>
        </Box>

        <KanbanScrollContainer>
          <Box display="flex" justifyContent="space-between">
            {kanban?.name ? (
              columns.map((column, i) => (
                <Box mr={columns?.length === i + 1 ? 0 : 4} key={column.id}>
                  <KanbanColumn
                    column={column}
                    tasks={tasks}
                    setTasks={setTasks}
                    projectId={projectId}
                    disabledOnClear={clearKanbanLoading}
                    tasksIds={tasksIds}
                    dropTaskLoading={dropTaskLoading}
                  />
                </Box>
              ))
            ) : (
              <Box
                mt="100px"
                display="flex"
                justifyContent="center"
                flexGrow="1"
                fontSize="24px"
                color={secondary}
              >
                Активный спринт отсутсвует
              </Box>
            )}
          </Box>
        </KanbanScrollContainer>
      </>
    );
  }

  return (
    <DragDropContext
      onDragEnd={({ destination, draggableId, source }) => {
        if (!destination || !draggableId || !source) {
          return;
        }

        dropHandler(
          draggableId,
          destination.droppableId,
          destination.index,
          source.droppableId,
          source.index
        );
      }}
    >
      <Container maxWidth="lg">
        <Header>
          Доска{" "}
          {!kanbanData?.getProjectKanban?.isCompleted &&
            kanbanData?.getProjectKanban?.name &&
            `«${kanbanData?.getProjectKanban?.name}»`}
        </Header>

        <ProjectEditNavBar />

        {content}
      </Container>

      {columnsData && (
        <KanbanCreateModal
          open={createKanbanModal}
          close={() => setCreateKanbanModal(false)}
          id={kanbanData?.getProjectKanban?.id}
          projectId={projectId}
          tasksIds={tasksIds}
          newStepId={
            columnsData?.getKanbanWorkflowStepsByProject?.find(
              (step) => step.name === "new"
            )?.id
          }
        />
      )}

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </DragDropContext>
  );
};
