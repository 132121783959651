import { Box } from "@material-ui/core";
import { useScrollbarWidth } from "../../../../hooks/useScrollbarWidth";
import {
  containerGap,
  containerIndent,
  containerMaxHeight,
} from "./constants";
import { calculateIsScrollable } from "./utils";

interface ContainerProps {
  children: JSX.Element | JSX.Element[];
}
export const Container = ({ children }: ContainerProps) => {
  const scrollBarWidth = useScrollbarWidth();
  const itemsLength = Array.isArray(children) && children.length;
  const withScroll = calculateIsScrollable(itemsLength);

  return (
    <Box
      maxHeight={containerMaxHeight}
      display="flex"
      flexDirection="column"
      gridGap={containerGap}
      overflow={withScroll && "auto"}
      mr={withScroll && `-${containerIndent}px`}
      pr={withScroll && `${containerIndent - scrollBarWidth}px`}
    >
      {children}
    </Box>
  );
};
