import React, { useState } from "react";
import { Box } from "@material-ui/core";

import { convertDateAndTimeToLocaleHandler } from "../../shared/Utils/ConvertOperations/convertDateAndTimeToLocaleHandler";
import { ButtonCustom } from "../../shared/Style/Style";
import { EventDatesUserListModal } from "../EventDateModal/EventDatesUserListModal";
import { EventWerePresentModal } from "../EventDateModal/EventWerePresentModal";
import { EventNotPresentModal } from "../EventDateModal/EventNotPresentModal";

export const EventDatesList = ({ dates }) => {
  const [checkUser, setCheckUser] = useState(false);
  const [isWerePresent, setIsWerePresent] = useState(false);
  const [isNotPresent, setIsNotPresent] = useState(false);

  return (
    <>
      <Box>
        {dates?.length && dates ? (
          dates.map((event) => (
            <Box
              mt={2}
              display="flex"
              flexDirection="column"
              width="570px"
              key={event.id}
            >
              <Box
                fontWeight="bold"
                display="flex"
                flexDirection="column"
                mt={2}
              >
                {convertDateAndTimeToLocaleHandler(event?.date)}
                <Box
                  mt={1}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <ButtonCustom
                    width="100px"
                    onClick={() => {
                      setIsWerePresent(!isWerePresent);
                    }}
                  >
                    Посетившие
                  </ButtonCustom>
                  <ButtonCustom
                    width="100px"
                    onClick={() => {
                      setIsNotPresent(!isNotPresent);
                    }}
                  >
                    Не посетившие
                  </ButtonCustom>
                  <ButtonCustom
                    width="100px"
                    onClick={() => {
                      setCheckUser(!checkUser);
                    }}
                  >
                    Участники
                  </ButtonCustom>
                </Box>
              </Box>

              <EventDatesUserListModal
                eventDateId={event?.id}
                open={checkUser}
                close={() => setCheckUser(false)}
              />
              <EventWerePresentModal
                eventDateId={event?.id}
                open={isWerePresent}
                close={() => setIsWerePresent(false)}
              />
              <EventNotPresentModal
                eventDateId={event?.id}
                open={isNotPresent}
                close={() => setIsNotPresent(false)}
              />
            </Box>
          ))
        ) : (
          <Box>На данный момент участников нету</Box>
        )}
      </Box>
    </>
  );
};
