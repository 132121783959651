import {
  GetAdminReportsType,
  ProductTypeAdminReportEnum,
  ProductTypeEnum,
  TeacherPupilRoleEnum,
  UserTypeAdminReportEnum,
} from "../../generated/graphql";
import {
  TopFilterType,
  FilterType,
  TopFilterConnectionParticipantsType,
} from "./types";
import {
  PaymentSpaceType,
  PeriodAdminReportEnum,
  PaymentType,
  PaymentFromType,
} from "../../generated/graphql";
import { setUTCDateFormate } from "../shared/Utils/UTCDateFormate/setUTCDateFormate";

export const NAV_ITEMS = [
  {
    name: "Учителя и уроки",
    link: "/reportsARBook/teachersPupils",
  },
  {
    name: "Рефлексия",
    link: "/reportsARBook/reflexion",
  },
  {
    name: "Типы платежей",
    link: "/reportsARBook/paymentTypes",
  },
  {
    name: "Регистрация пользователей",
    link: "/reportsARBook/usersRegistration",
  },
  // TODO: uncomment when will be ready
  // {
  // name: "Среднее время",
  // link: "/reportsARBook/averageTime"
  // }
];

export const REPORTS_AR_BOOK_TITLE = "отчеты по AR Book";

export const SPACE_FILTER_TYPES = [
  { name: "Все пространства", value: null },
  { name: "Персональное пространство", value: PaymentSpaceType.Personal },
  { name: "Школьное пространство", value: PaymentSpaceType.School },
];

export const PRODUCT_FILTER_TYPES = [
  { name: "Все продукты", value: null },
  { name: "AR Book Web", value: PaymentFromType.ArTeacher },
  { name: "AR Book App", value: PaymentFromType.ArBook },
  // TODO: uncomment when will be ready,according to the acceptance criteria
  // { name: "VR Lab", value: PaymentFromType.VrLab},
  // { name: "Market", value: PaymentFromType.Market},
];

export const PAYMENT_FILTER_TYPES = [
  { name: "Все типы платежей", value: null },
  { name: "Fondy", value: [PaymentType.Fondy] },
  { name: "Stripe", value: [PaymentType.Stripe] },
  { name: "Безнальный (вручную)", value: [PaymentType.Manual] },
  { name: "Маркетинговая акция", value: [PaymentType.Promotion] },
  { name: "Технический", value: [PaymentType.Technical] },
  { name: "LiqPay", value: [PaymentType.Liqpay] },
  { name: "App Store", value: [PaymentType.AppStore, PaymentType.Application] },
  {
    name: "Google Play Market",
    value: [PaymentType.GoogleStore, PaymentType.Application],
  },
];

export const PERIOD_FILTER_TYPES = [
  { name: "По дням", value: PeriodAdminReportEnum.Day },
  { name: "Неделям", value: PeriodAdminReportEnum.Week },
  { name: "Месяцам", value: PeriodAdminReportEnum.Month },
];

export const ACCESS_FILTER_TYPES = [
  { name: "Все виды доступов", value: null },
  {
    name: "Платные (Fondy, Stripe, Безнальный (вручную))",
    value: [PaymentType.Manual, PaymentType.Fondy, PaymentType.Stripe],
  },
  {
    name: "Бесплатные (Маркетинговая акция, Технический) ",
    value: [PaymentType.Promotion, PaymentType.Technical],
  },
];

export const USERS_FILTER_TYPES = [
  { name: "Все пользователи", value: null },
  { name: "Учителя", value: UserTypeAdminReportEnum.Teacher },
  { name: "Ученики", value: UserTypeAdminReportEnum.Pupil },
  { name: "Родители", value: UserTypeAdminReportEnum.Parent },
];

export const USERS_PRODUCT_FILTER_TYPES = [
  { name: "Все продукты", value: null },
  { name: "AR Book Mobile", value: ProductTypeAdminReportEnum.Arbook },
  { name: "AR Book Web", value: ProductTypeAdminReportEnum.Arteacher },
];

export const TOP_FILTER: TopFilterType = {
  from: null,
  to: null,
  spaceType: null,
  productType: null,
};
export const TOP_FILTER_PARTICIPANTS: TopFilterConnectionParticipantsType = {
  from: null,
  to: null,
  roles: TeacherPupilRoleEnum.All,
};

export const FILTER_INTERNAL: FilterType = {
  periodType: PeriodAdminReportEnum.Day,
  paymentTypes: null,
};

export const FILTER_INTERNAL_USERS: FilterType = {
  periodType: PeriodAdminReportEnum.Day,
  userType: null,
};

export const PAYMENTS_REPORT_DATA: GetAdminReportsType = {
  dataTotal: 0,
  dataChart: null,
};

export const ALL_PERIOD_DATE_RANGE = {
  startDate: setUTCDateFormate(new Date(Date.UTC(2023, 0, 1))),
  endDate: setUTCDateFormate(new Date()),
};

export const CHART_INFO_MESSAGE = {
  empty: "Для просмотра графика выберите период",
  notFound: "Для выбранных параметров нет данных для отображения",
  default: null,
};

export const CHART_WIDTH_CONTAINER = 1100;
export const MAX_LABEL_QUANTITY = 15;
export const LABEL_WIDTH = 75;
export const AXIOS_LABEL_WIDTH = 60;
export const MAX_CHART_WIDTH = 32790;

export const REPORT_VARIANTS = {
  registeredUsers: {
    field: "userType",
    allPeriodDate: ALL_PERIOD_DATE_RANGE,
    metric: "чел.",
  },
  paymentsTypes: {
    field: "paymentTypes",
    allPeriodDate: ALL_PERIOD_DATE_RANGE,
    metric: "шт.",
  },
  createdLessonCounts: {
    field: "createdLessonCounts",
    allPeriodDate: ALL_PERIOD_DATE_RANGE,
    metric: "шт.",
  },
  connectionParticipants: {
    field: "connectionParticipants",
    allPeriodDate: ALL_PERIOD_DATE_RANGE,
    metric: "%",
  },
};

export const ERROR_MESSAGE_PAYMENTS = "Ошибка при загрузке отчета по платежам";
export const ERROR_MESSAGE_USERS =
  "Ошибка при загрузке отчета по зарегистрированным пользователям";

export const ERROR_MESSAGE_ACTIVITY_OF_TEACHERS =
  "Ошибка при загрузки отчета по количеству созданых уроков";

export const PAYMENT_FILTER_TYPES_FOR_APP = [
  { name: "Все типы платежей", value: null },
  { name: "App Store", value: [PaymentType.AppStore, PaymentType.Application] },
  {
    name: "Google Play Market",
    value: [PaymentType.GoogleStore, PaymentType.Application],
  },
];

export const PAYMENT_FILTER_TYPES_FOR_TL_TEACHER = [
  { name: "Все типы платежей", value: null },
  { name: "Fondy", value: [PaymentType.Fondy] },
  { name: "Stripe", value: [PaymentType.Stripe] },
  { name: "Безнальный (вручную)", value: [PaymentType.Manual] },
  { name: "Маркетинговая акция", value: [PaymentType.Promotion] },
  { name: "Технический", value: [PaymentType.Technical] },
  { name: "LiqPay", value: [PaymentType.Liqpay] },
];

export const PAYMENT_FILTER_TYPES_MAPS_BY_PAYMENT_FROM = {
  [PaymentFromType.ArBook]: PAYMENT_FILTER_TYPES_FOR_APP,
  [PaymentFromType.ArTeacher]: PAYMENT_FILTER_TYPES_FOR_TL_TEACHER,
  null: PAYMENT_FILTER_TYPES,
};

export const ANALYTICS_USERS_TYPES = [
  { name: "Все пользователи", value: TeacherPupilRoleEnum.All },
  { name: "Учителя", value: TeacherPupilRoleEnum.Teacher },
  { name: "Ученики", value: TeacherPupilRoleEnum.Pupil },
];
