export const getMonthsDatesRanges = (maxDate: Date = new Date()): string[] => {
  const rangesArray: string[] = [];

  const year = maxDate.getFullYear();
  const month = maxDate.getMonth() + 1;

  let range = 5;

  while (range >= 0) {
    const newMonth = month - range;

    let lastDayOfNewMonth = newMonth > 0
      ? (new Date(year, newMonth, 0)).getDate()
      : (new Date(year - 1, 12 + newMonth, 0)).getDate();

    rangesArray.push(`${
      newMonth > 0 ? year : year -1
    }-${
      newMonth > 0 ? newMonth : 12 + newMonth
    }-${
      lastDayOfNewMonth
    } 00:00:00 GMT+00:00`)

    range--;
  }

  return rangesArray;
}
