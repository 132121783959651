import React from "react";
import { Box, Typography } from "@material-ui/core";
import { PersonalData } from "../../Main/UserEdit/UserEdit.style";
import { ButtonPlusSmall } from "../Style/Style";

interface FormSmallTitleWithBtnProps {
  title: string;
  onClickBtn(e: boolean): void;
}

export const FormSmallTitleWithBtn = ({
  title,
  onClickBtn,
}: FormSmallTitleWithBtnProps) => {
  return (
    <Box display="flex" justifyContent="start" style={{ whiteSpace: "nowrap" }}>
      <Box mr={2}>
        <Typography component={PersonalData} variant="subtitle1" gutterBottom>
          {title}
        </Typography>
      </Box>

      <ButtonPlusSmall onClick={() => onClickBtn(true)} />
    </Box>
  );
};
