import { useRef } from "react";
import { useReactiveVar } from "@apollo/client";
import { robotsState } from "../../state";
import { BundleMode, BundleNames } from "../../../../MobileAppModal/MobileAppModelModal/constants";
import { CurrentBundleContainer, MobileAppModalBundleName } from "../../../../MobileAppModal/MobileAppModal.style";
import { ButtonCustom, ImportFileUpload, BundleLink } from "../../../../shared/Style/Style";
import { IconButton } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { GameModelStateFileTypeEnum } from "../../../../../generated/graphql";
import { BundleTypesMap } from "../../maps";

export const StageBundleBlock = () => { 
    const iosInput = useRef<HTMLInputElement | null>(null);
    const androidInput = useRef<HTMLInputElement | null>(null);
    const manifestInputIos = useRef<HTMLInputElement | null>(null);
    const manifestInputAndroid = useRef<HTMLInputElement | null>(null);

    const currentBundles = useReactiveVar(robotsState.stageBundles);
    const stageGeneralInfo = useReactiveVar(robotsState.stageGeneralInfo);
    const isLoading = useReactiveVar(robotsState.isLoading);

    const uploadStageBundle = async (file: File, fileType: GameModelStateFileTypeEnum) => {
        if (!file) return;

        await robotsState.updateRobotStageFile({
            updateGameModelStateFileId: stageGeneralInfo.id,
            file,
            fileType,
        });

        await robotsState.getRobotStage({ getGameModelStateId: stageGeneralInfo?.id });
    };

    const deleteStageBundle = async (fileType: GameModelStateFileTypeEnum) => {
        await robotsState.deleteRobotStageFile({ 
            deleteGameModelStateFileId: stageGeneralInfo.id,
            fileType,
        });
        
        await robotsState.getRobotStage({ getGameModelStateId: stageGeneralInfo?.id });
    };

    const bundlesData = [
        {
            name: BundleNames.Ios,
            currentBundle: currentBundles?.iosBundle,
            type: BundleMode.Bundle,
            inputRef: iosInput,
            handleBundle: (file: File) => uploadStageBundle(file, GameModelStateFileTypeEnum.IosBundle),
        },
        {
            name: BundleNames.Android,
            currentBundle: currentBundles?.androidBundle,
            type: BundleMode.Bundle,
            inputRef: androidInput,
            handleBundle: (file: File) => uploadStageBundle(file, GameModelStateFileTypeEnum.AndroidBundle),
        },
        {
            name: BundleNames.IosManifest,
            currentBundle: currentBundles?.iosManifest,
            type: BundleMode.Manifest,
            inputRef: manifestInputIos,
            handleBundle: (file: File) => uploadStageBundle(file, GameModelStateFileTypeEnum.IosManifest),
        },
        {
            name: BundleNames.AndroidManifest,
            currentBundle: currentBundles?.androidManifest,
            type: BundleMode.Manifest,
            inputRef: manifestInputAndroid,
            handleBundle: (file: File) => uploadStageBundle(file, GameModelStateFileTypeEnum.AndroidManifest),
        },
    ];

    const renderCurrentBundle = (
        name: BundleNames,
        currentBundle: string,
        type:  BundleMode,
        inputRef: React.RefObject<HTMLInputElement>,
        handleBundle: (file: File) => void,
    ) => {
        const label = type === BundleMode.Manifest ? `${name}` : `${name} ${type}`;
        const fileType = BundleTypesMap.get(name);

        return (
            <CurrentBundleContainer key={name}>
                <MobileAppModalBundleName>{name}</MobileAppModalBundleName>

                {!!currentBundle ? (
                    <span>
                        <BundleLink href={currentBundle}>{label}</BundleLink>

                        <IconButton
                            disabled={isLoading}
                            onClick={() => deleteStageBundle(fileType)}
                        >
                            <ClearIcon color="error" fontSize="small"/>
                        </IconButton>
                    </span>
                ) : (
                      <ButtonCustom
                        disabled={isLoading}
                        onClick={() => inputRef.current.click()}
                      >
                      <span>Прикрепить</span>
                      <ImportFileUpload
                        type="file"
                        ref={inputRef}
                        onChange={({ target: { files } }) =>
                          files[0] && handleBundle(files[0])
                        }
                      />
                    </ButtonCustom>                         
                )}

            </CurrentBundleContainer>
        );
    };

    
    if (!currentBundles) return null;
    
    return (
        <>
            {bundlesData.map(({
                name, currentBundle, type, inputRef, handleBundle
            }) =>
                renderCurrentBundle(
                    name,
                    currentBundle,
                    type,
                    inputRef,
                    handleBundle,
                ))
            }
        </>
    );
};
