import React, { useEffect } from "react";

import { FormControlLabel } from "@material-ui/core";
import { CustomSwitch } from "../ParticipantsModal.style";
import { UserRoleEnum } from "../../../generated/graphql";

interface IGetUserVariables {
  variables: {
    getUsersData: {
      limit: number;
      skip: number;
      roles?: UserRoleEnum[],
      statuses: string[],
    }
  }
}

interface IAvailableForTogglerProps {
  activeFilter: string | undefined;
  setAvailableFor(value: string | null): void;
  getUsers(variables: IGetUserVariables): void;
  togglerActive: boolean;
  setTogglerActive(value: boolean): void;
}

export const AvailableForToggler = ({
  activeFilter,
  setAvailableFor,
  getUsers,
  togglerActive,
  setTogglerActive
}: IAvailableForTogglerProps) => {
  let togglerValue: string;

  switch (activeFilter) {
    case 'STAFF':
      togglerValue = 'STAFF';
      break;

    case 'CLIENT':
      togglerValue = 'CLIENTS';
      break;

    case 'TEACHER':
      togglerValue = 'TEACHER';
      break;

    default:
      togglerValue = 'ALL';
      break;
  }

  useEffect(() => {
    if (!togglerActive) {
      setAvailableFor(null);

      switch (activeFilter) {
        case 'STAFF':
          getUsers({
            variables: {
              getUsersData: {
                limit: 1000,
                skip: 0,
                roles: [UserRoleEnum.Staff],
                statuses: ['VERIFIED'],
              }
            }
          });
          break;

        case 'CLIENT':
          getUsers({
            variables: {
              getUsersData: {
                limit: 1000,
                skip: 0,
                roles: [UserRoleEnum.Client],
                statuses: ['VERIFIED'],
              }
            }
          });
          break;

        case 'TEACHER':
          getUsers({
            variables: {
              getUsersData: {
                limit: 1000,
                skip: 0,
                roles: [UserRoleEnum.Teacher],
                statuses: ['VERIFIED'],
              }
            }
          });
          break;

        default:
          getUsers({
            variables: {
              getUsersData: {
                limit: 1000,
                skip: 0,
                statuses: ['VERIFIED'],
              }
            }
          });
          break;
      }
      return;
    }

    setAvailableFor(togglerValue);
  }, [togglerActive]);

  return (
    <FormControlLabel
      style={{ display: 'block' }}
      control={
        <CustomSwitch
          checked={togglerActive}
          onChange={() => setTogglerActive(!togglerActive)}
          value={togglerValue}
        />
      }
      label="Выбрать всех"
    />
  )
}