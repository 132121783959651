import React, { useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import { DEFAULT_AVATAR_URL } from "../../shared/constants";
import { ButtonCustom, ImageWrapper } from "../../shared/Style/Style";
import { AvatarEditor } from "../../shared/Common/AvatarEditor";
import {User} from "../../../generated/graphql";

const AvatarImage = styled(ImageWrapper)`
  border-radius: 100%;
  margin-top: 16px;
  img {
    width: 174px;
    height: 174px;
  }
  &:hover {
    cursor: pointer;
    .AvatarImage__edit {
      opacity: 1;
    }
  }
  .AvatarImage__edit {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    opacity: 0;
    transition: all 200ms linear;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

const UploadButton = styled(Button)`
  &.MuiButton-root {
    color: white;
    border-color: white;
    &:hover {
      color: white;
      border-color: white;
    }
  }
`;

const blobToFile = (theBlob: Blob, fileName: string): File => {
  return new File([theBlob], fileName);
};

export function UserAvatar({ onChange, user }: {onChange(file: File): void, user: User}) {
  const [open, setOpen] = useState<boolean>(false);
  const [cropper, setCropper] = useState<Cropper | null>();
  const [cropped, setCropped] = useState<string>();

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <AvatarImage>
        <div className="AvatarImage__edit" onClick={handleClickOpen}>
          <UploadButton
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<EditIcon />}
          >
            Редактировать
          </UploadButton>
        </div>
        <div className="AvatarImage__image">
          <img src={cropped || user?.photo || DEFAULT_AVATAR_URL} alt="avatar"/>
        </div>
      </AvatarImage>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Редактировать аватар</DialogTitle>

        <DialogContent>
          <AvatarEditor cropper={cropper} setCropper={setCropper} />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>

          <ButtonCustom
            disabled={!cropper}
            onClick={async () => {
              if (cropper) {
                const canvasScaled = cropper.getCroppedCanvas({maxWidth: 720});
                const blob = await fetch(canvasScaled.toDataURL()).then((res) =>
                  res.blob()
                );
                const result = blobToFile(blob, "avatar.jpg");
                setCropped(canvasScaled.toDataURL());
                onChange(result);
                handleClose();
              }
            }}
            color="primary"
            autoFocus
          >
           Установить
          </ButtonCustom>
        </DialogActions>
      </Dialog>
    </>
  );
}
