import styled from "styled-components";
import { ButtonCustom, secondary } from "../../shared/Style/Style";
import { AnnouncementsListItem } from "../Announcements.style";

export const NotificationListItem = styled(AnnouncementsListItem)`
  grid-template-columns: 96px 1fr 10fr 2fr 1fr;
`;

export const StatisticsBtn = styled(ButtonCustom)`
  width: 142px;

  &:disabled {
    background-color: ${secondary};
  }
`;