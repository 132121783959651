export interface User {
  id: string;

  timeStamp?: string;

  firstName: string;

  lastName: string;

  middleName?: string;

  photo?: string | null;

  phone: string;

  birthday?: string;

  nickname?: string;

  email: string;

  isDeleted?: boolean;

  isPublished?: boolean;

  registrationAddress?: string;

  residenceAddress?: string;

  profession?: string;
  role: UserRolePropsType;
}

export interface UserPropsType extends User {
  // city: City;
  // comment?: string | null;
  // hasPassportIssues: string | null;
  monetary?: number;
  instagram?: string;
  telegram?: string;
  payCard?: string;
  __typename: string;
}
export interface UserInfoPropsType {
  getUserInfo: UserPropsType;
}

export interface UserStatusPropsType {}
export interface UserRolePropsType {
  __typename: string;
  role: string;
}

// export interface Status {
//   id: string;
//   status: string;
//   displayName: string;
// }
//
// export interface UserInfo {
//   name: string;
//   email: string;
//   id: string;
//   role: Role;
//   isDeleted: boolean;
// }
//
// export type DocumentsPropsType = {
//   id: string;
//   name: string;
// };
//
// export interface CurrentClubPropsType {
//   id: string;
//   name: string;
// }
//
// export interface DataTrackUserData {
//   getTrackUser: TrackUserPropsType[];
// }
//
// export interface TrackUserVariables {
//   userId: string;
// }
//
// interface TrackUserPropsType {
//   id: string;
//   club: { address: string };
//   timeStamp: string;
//   status: string;
// }
//
export const USER_ROLE_MAP = {
  CLIENT: {
    Title: "КЛИЕНТ",
    // Clubs: "Клубы в которых состоит клиент",
    // Groups: "Группы в которых состоит клиент",
  },
  STAFF: {
    Title: "СОТРУДНИК",
    // Clubs: "Клубы в которых работает сотрудник",
    // Groups: "Группы в которых состоит сотрудник",
  },
};
//
// export interface UpdatePropsType {
//   update?: PushType | EventType | NewsType | null;
// }
// export interface UserSelectPropsType {
//   update: UpdatePropsType;
//   participants: Array<string>;
//   setParticipants: (participants: Array<string>) => void;
//   field: any;
//   active: ActiveStatePropType;
//   setActive: Dispatch<SetStateAction<ActiveStatePropType>>;
// }
//
// export type ActiveStatePropType =
//   | "group"
//   | `club`
//   | `city`
//   | `availableForEveryone`;
//
// export interface FieldFormPropsType {
//   input: any;
//   meta: any;
// }
//
// export interface UserSimplePropsType {
//   lastName: string;
//   firstName: string;
//   id: string;
//   photo: string;
//   __typename: string;
// }
// export interface GetUsersDetailedType {
//   getUsers: GetUsersType;
// }
//
// export interface GetUsersType {
//   total: number;
//   users: UserPropsType[];
// }
