import React, {memo, useMemo, Dispatch, SetStateAction} from 'react'
import { format } from "date-fns";
import {Menu} from "../../shared/Menu";
import {useMutation} from "@apollo/client";
import {
  DeleteOnboardingThemeDocument,
  DeleteOnboardingThemeMutation,
  GetOnboardingThemesEditDocument,
  PublishOnboardingThemeDocument,
  PublishOnboardingThemeMutation,
  UnpublishOnboardingThemeDocument,
  UnpublishOnboardingThemeMutation
} from "../../../generated/graphql";
import {cutString} from "../utils";
import { DragNDropIcon } from "../icons"
import {MENU_ITEM_STATUS, PUBLISHED, UNPUBLISHED} from "../constants";
import {OnboardingTableCell, OnboardingTableRow} from "../Onboarding.style";

export interface ThemeItemProps {
  themeId: string,
  title: string,
  description: string,
  isPublished: boolean,
  publishedAt?: string,
  video?: string,
  setOpenModal: Dispatch<SetStateAction<boolean>>,
  setCurrentThemeId: Dispatch<SetStateAction<string>>
}

export const ThemeItem = memo(({
 themeId,
 publishedAt,
 title,
 description,
 isPublished,
 setOpenModal,
 setCurrentThemeId
}: ThemeItemProps) => {

  const [deleteTheme] = useMutation<DeleteOnboardingThemeMutation>(
    DeleteOnboardingThemeDocument, {
      variables: { themeId },
      refetchQueries: [{ query: GetOnboardingThemesEditDocument }]
    });

  const [ publishTheme ] = useMutation<PublishOnboardingThemeMutation>(PublishOnboardingThemeDocument, {
      variables: { themeId },
      refetchQueries: [{ query: GetOnboardingThemesEditDocument }]
  });

  const [ unpublishTheme ] = useMutation<UnpublishOnboardingThemeMutation>(UnpublishOnboardingThemeDocument, {
      variables: { themeId },
      refetchQueries: [{ query: GetOnboardingThemesEditDocument }]
    })

  const dateMemo = useMemo(() => {
    return publishedAt ? format(new Date(publishedAt), "dd.MM.yyyy") : "Не опубликованно"
  }, [publishedAt, isPublished])

  const descriptionMemo = useMemo(() => {
    return cutString(description, 100)
  }, [description])

  const statusMemo = useMemo(() => {
    return isPublished ? PUBLISHED : UNPUBLISHED
  }, [isPublished, themeId])

  const menuItemsMemo = useMemo(() => {
    return [{
      name: isPublished ? MENU_ITEM_STATUS.unPublish : MENU_ITEM_STATUS.publish,
      action: isPublished ? unpublishTheme : publishTheme,
    }, {
      name: "Редактировать",
      action: () => {
        setOpenModal(true);
        setCurrentThemeId(themeId);
      }
    }, {
      name: "Удалить",
      action: deleteTheme,
      color: "red"
    }]
  }, [isPublished, themeId])

  return (
    <OnboardingTableRow >
      <OnboardingTableCell>
        <DragNDropIcon/>
      </OnboardingTableCell>
      <OnboardingTableCell>
        {dateMemo}
      </OnboardingTableCell>
      <OnboardingTableCell>{title}</OnboardingTableCell>
      <OnboardingTableCell>{descriptionMemo}</OnboardingTableCell>
      <OnboardingTableCell className={statusMemo.className}>
        {statusMemo.name}
      </OnboardingTableCell>
      <OnboardingTableCell>
        <Menu
          vertical={36}
          horizontal={12}
          width={170}
          items={menuItemsMemo}
        />
      </OnboardingTableCell>
    </OnboardingTableRow>
  )
});
