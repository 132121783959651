import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { ButtonCustomWithDarkText, CustomNavBarNoMargin, CustomFilterContainer } from '../../shared/Style/Style';
import { FilterType, FiltersArrayType, PaymentsType, PeriodsType, ReportTopFilterVariantType } from '../types';
import { FiltersSelect } from '../../shared/Selects/FiltersSelect';

export interface VerticalBarChartFilterProps {
    period: PeriodsType[];
    activePeriod: string;
    paymentsType?: FiltersArrayType | null;
    setInternalChartFilter: Dispatch<SetStateAction<FilterType>>;
    usersType?: FiltersArrayType | null;
    variant: ReportTopFilterVariantType;
    isSchoolSpaceType?: boolean;
    isResetSelectorNeeded?: boolean;
};

export const VerticalBarChartFilter: React.FC<VerticalBarChartFilterProps> = ({
    period,
    activePeriod,
    paymentsType,
    setInternalChartFilter,
    usersType,
    variant,
    isSchoolSpaceType,
    isResetSelectorNeeded,
}) => {
    const { field } = variant;
    const fieldsTypeData = paymentsType ?? usersType;
    const isFilterDisplay = !!(paymentsType ?? (usersType && !isSchoolSpaceType));

    useEffect(() => {
        if (isSchoolSpaceType && !!usersType) {
            setInternalChartFilter(preState => ({ ...preState, userType: null }))
        };
    }, [isSchoolSpaceType]);

    const handleSetFilter = (value: PaymentsType['value']) => {
        setInternalChartFilter(preState => ({ ...preState, [field]: value }));
    };
        
    const handleSetFilterPeriod = (value: string ) => {
        setInternalChartFilter(preState =>({ ...preState, periodType: value }))
    };

    return (
        <CustomFilterContainer>
            <Box>
                <CustomNavBarNoMargin>
                    {period.map(({ name, value }) => (
                        <Box mr={1} key={name} >
                            <ButtonCustomWithDarkText
                                type="button"
                                variant="contained"
                                size="small"
                                active={value ===  activePeriod}
                                onClick={() => handleSetFilterPeriod(value)}
                            >{name}
                            </ButtonCustomWithDarkText>
                        </Box>))
                    }
                </CustomNavBarNoMargin>
            </Box>
            {isFilterDisplay && <FiltersSelect fieldsData={fieldsTypeData} handleSetFilter={handleSetFilter} isResetSelectorNeeded={isResetSelectorNeeded} />}
        </CustomFilterContainer>
    );
};