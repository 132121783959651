import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { CloseIconButton } from "../shared/Buttons/CloseIconButton";
import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
  ModalInput,
} from "../shared/Style/Style";
import { useMutation } from "@apollo/client";
import {
  CreateEventDocument,
  CreateEventMutation,
  GetEventsDocument,
} from "../../generated/graphql";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { useHistory } from "react-router-dom";

interface ICreateEventModal {
  open: boolean;
  close(): void;
  getAllItemsParams: {
    limit: number;
    skip: number;
  };
}

export const CreateEventModal = ({
  open,
  close,
  getAllItemsParams,
}: ICreateEventModal) => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [
    createEvent,
    {
      data: createEventData,
      loading: createEventLoading,
      error: createEventError,
    },
  ] = useMutation<CreateEventMutation>(CreateEventDocument, {
    variables: {
      name,
    },
    refetchQueries: [
      {
        query: GetEventsDocument,
        variables: {
          ...getAllItemsParams,
        },
      },
    ],
  });

  const createEventHandle = async () => {
    try {
      await createEvent();
    } catch (e) {
      setRequestError(true);
      setErrorMessage("При создании произошла ошибка");
    }
  };

  const closeHandler = () => {
    setName("");
    close();
  };

  const disabledOnLoading = createEventLoading;

  useEffect(() => {
    if (createEventData) {
      history.push(`/event/${createEventData.createEvent.id}/edit`);
      closeHandler();
    }
  }, [createEventData]);

  return (
    <Dialog open={open}>
      <DialogContent style={{ minWidth: "400px" }}>
        <CloseIconButton close={closeHandler} />
        <Box textAlign="center" mb={2}>
          <DialogTitle>Создание мероприятия</DialogTitle>
        </Box>
        <ModalInput
          fullWidth
          label="Название"
          value={name}
          onChange={({ target: { value } }) => setName(value)}
          disabled={disabledOnLoading}
        />

        <ButtonsWrapper>
          <CancelButton onClick={closeHandler} disabled={disabledOnLoading}>
            Отмена
          </CancelButton>

          <ButtonCustom
            onClick={createEventHandle}
            disabled={disabledOnLoading}
          >
            Создать
          </ButtonCustom>
        </ButtonsWrapper>
      </DialogContent>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Dialog>
  );
};
