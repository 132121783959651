import React, { useState } from "react";
import { Field } from "react-final-form";
import { Box, TextField } from "@material-ui/core";
import {
  isRequired,
  validateForgotEmailField,
} from "../../core/validators/validators";
import { InitialErrorsValues } from "./Login";
import { VisibilityButton } from "../shared/Buttons/VisibilityButton";
import { useStyles } from "../shared/Style/Style";

interface LoginFormProps {
  onSubmit(event: any): Promise<void>;
  requiredFieldsError: InitialErrorsValues;
  setRequiredFieldsError(state: InitialErrorsValues): void;
}

export function LoginForm({
  onSubmit,
  requiredFieldsError,
  setRequiredFieldsError,
}: LoginFormProps) {
  const [visiblePassword, setVisiblePassword] = useState(false);

  const classes = useStyles();
  return (
    <>
      <Field name="email" validate={validateForgotEmailField}>
        {({ input, meta }) => (
          <TextField
            className={classes.root}
            error={
              (meta.error && meta.touched) || !!requiredFieldsError.emailError
            }
            margin="normal"
            fullWidth
            helperText={
              (meta.touched && meta.error) || requiredFieldsError.emailError
            }
            label="Email"
            variant="filled"
            type="text"
            {...input}
            value={input.value}
            onChange={(event) => {
              setRequiredFieldsError({
                ...requiredFieldsError,
                emailError: "",
              });
              input.onChange(event.target?.value);
              input.onBlur();
            }}
          />
        )}
      </Field>
      <Field name="password" validate={isRequired}>
        {({ input, meta }) => (
          <Box position="relative">
            <TextField
              className={classes.root}
              error={
                (meta.error && meta.touched) ||
                !!requiredFieldsError.passwordError
              }
              margin="normal"
              fullWidth
              helperText={
                (meta.touched && meta.error) ||
                requiredFieldsError.passwordError
              }
              label="Пароль"
              variant="filled"
              type={visiblePassword ? "text" : "password"}
              onKeyDown={(event) => {
                if (event.code === "Enter" || event.code === "NumpadEnter") {
                  onSubmit(event);
                }
              }}
              value={input.value}
              onChange={(event) => {
                setRequiredFieldsError({
                  ...requiredFieldsError,
                  passwordError: "",
                });
                input.onChange(event.target?.value);
                input.onBlur();
              }}
            />

            <Box position="absolute" right={5} top={2}>
              <VisibilityButton
                active={visiblePassword}
                show={() => setVisiblePassword(true)}
                hide={() => setVisiblePassword(false)}
              />
            </Box>
          </Box>
        )}
      </Field>
    </>
  );
}
