import { useReactiveVar } from "@apollo/client";
import { robotsState } from "../state";
import { isValidHexColor } from "../../Awards/utils";
import { descriptionMaxLength, nameMaxLength, RobotGeneralFields } from "../constants";

export const useValidateRobotGeneralFields = () => {
    const robotGeneralInfo = useReactiveVar(robotsState.robotGeneralInfo);
    
    const validateHandler = () => {
        const isFieldNotEmpty = Object.values(robotGeneralInfo).every(value => Boolean(value));
        const isRobotNameInvalid = robotGeneralInfo?.name?.length > nameMaxLength;
        const isRobotDescriptionInvalid = robotGeneralInfo?.description?.length > descriptionMaxLength;
        const isRobotBackgroundInvalid = !isValidHexColor(robotGeneralInfo?.background);
        
        if (!isFieldNotEmpty) {
            for (const [field, value] of Object.entries(robotGeneralInfo)) {
                if (!Boolean(value)) {
                    robotsState.setInputErrors(field as RobotGeneralFields, true);
                }
            }
            return;
        };

        if (isRobotNameInvalid) {
            return robotsState.setInputErrors(RobotGeneralFields.Name, true, "Название не должно превышать 20 символов");
        }

        if (isRobotDescriptionInvalid) {
            return robotsState.setInputErrors(RobotGeneralFields.Description, true, "Описание не должно превышать 200 символов");
        }

        if (isRobotBackgroundInvalid) {
            return robotsState.setInputErrors(RobotGeneralFields.Background, true, "Неверный формат цвета фона");
        }

        return true;
    };

    return validateHandler;
};
