import styled from "styled-components";
import { Filter, lightBlack, white } from "../shared/Style/Style";
import { Select, TableSortLabel, TextField } from "@material-ui/core";

export const SearchBar = styled(Filter)`
  display: flex;
  justify-content: space-between;
`;
export const SearchInput = styled(TextField)`
  & .MuiInput-root {
    min-width: 500px;
  }

  & .MuiInput-formControl {
    margin-top: 5px;
  }
`;
export const SortArrow = styled(TableSortLabel)`
  &.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
    .MuiTableSortLabel-icon {
    font-size: 30px;
  }
`;
export const SortOfType = styled(Select)`
  padding: 5px 10px 5px 20px;
  background-color: ${white};
  width: 240px;
  font-weight: 500;
  color: ${lightBlack};

  & .MuiSelect-select:focus {
    background-color: unset;
  }

  &::after {
    content: none;
  }

  &::before {
    content: none;
  }

  &:hover {
    border: none;
  }

  & .MuiSelect-icon {
    right: 10px;
  }
`;
