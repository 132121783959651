import React from "react";

import { TableRow, MenuItem, CircularProgress } from "@material-ui/core";
import { CustomTableCell, CustomTableInputWithoutBorders } from "../../../shared/CustomTable/CustomTable.style";
import { useQuery } from "@apollo/client";
import { GetProjectsForEmployeesDistributionReportDocument, GetProjectsForEmployeesDistributionReportQuery } from "../../../../generated/graphql";
import { LoadingBox } from "../../../shared/Style/Style";
import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { ICreateData } from "./EmployeesDistributionReport";
import { ReportSelect, ReportFormControl } from "../../Reports.style";

interface ICreateDistributionFormProps {
  data: ICreateData;
  existingProjects: string[];
  errors: {
    [key: string]: boolean;
  };
  setCreatingDistribution(state: {
    status: boolean;
    data: ICreateData;
  }): void
}

export const CreateDistributionForm = ({data, existingProjects, errors, setCreatingDistribution} : ICreateDistributionFormProps) => {
  const {
    data: projectsData,
    loading: projectsLoading,
    error: projectsError,
  } = useQuery<GetProjectsForEmployeesDistributionReportQuery>(
    GetProjectsForEmployeesDistributionReportDocument,
    { variables: {getProjectsInput: { statuses: ["IN_PROGRESS"] }} }
  );

  const changeHandler = (value: string | number, field: string) => {
    if(!value) {
      return;
    }

    setCreatingDistribution({
      status: true,
      data: {
        ...data,
        [field]: value,
      }
    })
  }

  let content;

  if (projectsLoading) {
    content = (
      <CustomTableCell colSpan={3}>
        <LoadingBox>
          <CircularProgress color='inherit'/>
        </LoadingBox>
      </CustomTableCell>
    )
  }

  if (projectsError) {
    content = (
      <CustomTableCell colSpan={3}>
        <ErrorSnackbar error={projectsError}/>
      </CustomTableCell>
    )
  }

  if (projectsData) {
    const projects = projectsData.getProjects.projects;
    const visibleProjects = projects.filter(project => !existingProjects.includes(project.name));

    content = (
      <>
        <CustomTableCell
          padding='none'
        >
          <ReportFormControl error={errors.projectId} fullWidth>
            <ReportSelect
              value={data.projectId}
              onChange={({ target }) => {
                const value: any = target.value;
                changeHandler(value, 'projectId')
              }}
              fullWidth
            >
              {visibleProjects.map(project => {
                return (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                );
              })}
            </ReportSelect>
          </ReportFormControl>
        </CustomTableCell>

        <CustomTableCell
          padding='none'
        >
          <CustomTableInputWithoutBorders
            type='number'
            value={data.employeesNumber}
            onChange={({ target }) => {
              const value: any = target.value;
              changeHandler(+value, 'employeesNumber')
            }}
          />
        </CustomTableCell>

        <CustomTableCell />
      </>
    )
  }

  return (
    <>
      <TableRow>
        {content}
      </TableRow>
    </>
  )
}