import { AwardTypeEnum } from "../../../generated/graphql";

export const AwardDaysMap = new Map<number, string>([
  [1, "1 день"],
  [2, "2 день"],
  [3, "3 день"],
  [4, "4 день"],
  [5, "5 день"],
  [6, "6 день"],
  [7, "7 день"],
]);

export const AwardNameMap = new Map<AwardTypeEnum, string>([
  [AwardTypeEnum.Day, "День"],
  [AwardTypeEnum.Bonus, "Бонус"],
]);
