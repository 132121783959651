import React, { useState } from "react";
import {Box} from "@material-ui/core";
import { BlockInputLesson, ButtonUpload, PreviewImage } from "./Style";
import { useDropzone } from "react-dropzone";

export const VideoBlock = ({ disabled, block, setVariables, video, videoPreview }) => {
  const [image, setImage] = useState<string | ArrayBuffer | null>(videoPreview);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
  });

  const handleDrop = (dropped: File): void => {
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
    };

    reader.readAsDataURL(dropped);
  };

  return (
    <>
      <Box width="100%" mb={5}>
        <BlockInputLesson
          type={block.type}
          disabled={disabled}
          label="Ссылка на видео"
          value={video}
          onChange={({ target: { value } }) => setVariables(prev => ({...prev, video: value}))}
        />
      </Box>

      <Box width="100%" mb={3}>
        <ButtonUpload {...getRootProps()} disabled={disabled}>
          Выбрать файл...
          <input
            style={{cursor: "pointer"}}
            type="file"
            disabled={disabled}
            {...getInputProps()}
            onChange={({ target }) => {
              setVariables(prev => ({...prev, videoPreview: target.files[0]}));
              handleDrop(target.files[0]);
            }}
          />
        </ButtonUpload>
      </Box>

      <Box width="100%">
        <PreviewImage img={image as string} />
      </Box>
    </>
  );
};
