import { useReactiveVar } from "@apollo/client";
import { awardsState } from "../../state";
import { Autocomplete } from "@material-ui/lab";
import { ModalInput } from "../../../../shared/Style/Style";
import { getSelectDaysOptions } from "../../utils";
import { AwardField } from "../../types";
import { AwardDaysMap } from "../../maps";

export const SelectAwardDay = () => { 
  const award = useReactiveVar(awardsState.award);
  const awardsList = useReactiveVar(awardsState.awardsList);
  const inputErrors = useReactiveVar(awardsState.inputErrors);
  const isLoading = useReactiveVar(awardsState.isLoading);
  
  const { day } = award;
  const selectOptions = getSelectDaysOptions(awardsList, day);
  const selectValue = AwardDaysMap.get(day) || null;
  
    const handleDayChange = (_, value: string) => {
      const key = Array.from(AwardDaysMap.entries()).find(([, v]) => v === value)?.[0] ?? 0;
      awardsState.setAwardDay(key);
    };
    const getOptionLabel = (option: string) => {
      return selectOptions.find(item => item === option) || null;
    };


    return (
        <Autocomplete
          disabled={isLoading}
          options={selectOptions}
          value={selectValue}
          onChange={handleDayChange}
          getOptionLabel={getOptionLabel}
          onFocus={() =>
            awardsState.setInputErrors(AwardField.Day, false)
          }
        renderInput={(params) => {
            return (
              <ModalInput
                {...params}
                error={inputErrors.day.error}
                label="За какой день награда"
                fullWidth
                variant="filled"
                margin="normal"
                required
              />
            );
          }}
        />
    );
};
