import React, { useState } from "react";
import { CustomTextField } from "../Style";
import { Box } from "@material-ui/core";
import { DeleteIconButton } from "../../../shared/Buttons/DeleteIconButton";
import { SaveIconButton } from "../../../shared/Buttons/SaveIconButton";
import { EditIconButton } from "../../../shared/Buttons/EditIconButton";

interface VariantItemProps {
  id: string;
  name: string;
  handleUpdateVariant: (id: string, name: string) => void;
  handleDeleteVariant: (id: string) => void;
}

export const VariantItem = ({
  id,
  name,
  handleUpdateVariant,
  handleDeleteVariant,
}: VariantItemProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [pollName, setPollName] = useState<string>(name);

  return (
    <Box mb={3}>
      <CustomTextField
        disabled={!isEdit}
        fullWidth
        variant="filled"
        value={pollName}
        onChange={({ target: { value } }) => setPollName(value)}
        InputProps={{
          endAdornment: (
            <Box display="flex">
              <DeleteIconButton
                fz={20}
                item="фото"
                disabled={isEdit}
                deleteHandler={() => handleDeleteVariant(id)}
              />
              {(isEdit && (
                <SaveIconButton
                  fz={20}
                  save={async () => {
                    await handleUpdateVariant(id, pollName);
                    setIsEdit(false);
                  }}
                />
              )) || <EditIconButton fz={20} edit={() => setIsEdit(true)} />}
            </Box>
          ),
        }}
      />
    </Box>
  );
};
