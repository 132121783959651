import { useRef } from "react";
import { useReactiveVar } from "@apollo/client";
import { awardsState } from "../../state";
import { ButtonCustom, ImportFileUpload as AnimationFileUpload, BundleLink } from "../../../../shared/Style/Style";
import { AnimationInput, AnimationTitle } from "./style";
import { IconButton } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { acceptedImageTypes } from "../../../../shared/constants";

export const AwardAnimation = () => { 
    const animationInput = useRef<HTMLInputElement | null>(null);
    
    const award = useReactiveVar(awardsState.award);
    const isLoading = useReactiveVar(awardsState.isLoading);

    const animationLink = award.animation.includes("https:") ? award.animation : "#";

    const uploadAnimationHandler = (file: File) => {
        if (!file) return;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        
        reader.onload = () => {
            awardsState.setAwardAnimationLink(reader.result as string);
        };

        awardsState.setAwardAnimationFile(file);
    };

    return (
        <AnimationInput>
            <AnimationTitle>Анимация награды*</AnimationTitle>

            {award.animation ? (
                <span>
                    <BundleLink href={animationLink}>Animation</BundleLink>

                    <IconButton
                        disabled={isLoading}
                        onClick={() => awardsState.setAwardAnimationLink("")}
                    >
                        <ClearIcon color="error" fontSize="small"/>
                    </IconButton>
                </span>
                ) : (
                <ButtonCustom
                    onClick={() => animationInput.current.click()}
                >
                    <span>Прикрепить</span>
                    <AnimationFileUpload
                        type="file"
                        accept={acceptedImageTypes}
                        ref={animationInput}
                        onChange={({ target: { files } }) =>
                          files[0] && uploadAnimationHandler(files[0])
                        }
                    />
                </ButtonCustom>                         
            )}
        </AnimationInput>        
    );
};
