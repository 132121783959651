import React, { useState, useEffect } from "react";

import { useMutation, useQuery } from "@apollo/client";
import {
  CreateNotificationDocument,
  CreateNotificationMutation,
  GetNotificationsByManagerDocument,
  GetNotificationsByManagerQuery,
  Notification
} from "../../../generated/graphql";

import { Container, List, CircularProgress, Snackbar} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { Header, ButtonPlus, LoadingBox } from "../../shared/Style/Style";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";

import {
  NotificationListItem,
  StatisticsBtn,
} from "./Notifications.style";

import {
  AddButtonWrapper,
  AnnouncementsTitle,
  AnnouncementsText,
  AnnouncementsListItem,
  NoAnnouncementsListText
} from "../Announcements.style";

import { NotificationMenu } from "../../NotificationMenu";
import { NotificationModal } from "../../NotificationModal";
import { NotificationStatisticsModal } from "../../ParticipantsModal/NotificationStatisticsModal";
import { AnnouncementsNavBar } from "../../AnnouncementsNavBar";


export const Notifications = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [statisticsModalOpen, setStatisticsModalOpen] = useState(false);
  const [chosenNotification, setChosenNotification] = useState<Notification | null>(null);

  //#region create notification
  const [ createError, setCreateError ] = useState(false);

  const [
    createNotification,
    {
      data: createNotificationData,
      loading: createNotificationLoading,
      error: createNotificationError
    }
  ] = useMutation<CreateNotificationMutation>(
    CreateNotificationDocument,
    {
      variables: { notificationData: { name: '', description: '' }}
    }
  );

  const createNotificationHandler = () => {
    setChosenNotification(null);
    createNotification();
  }

  useEffect(() => {
    if (createNotificationError) {
      setCreateError(true);
    }

    if (createNotificationData) {
      setCreateError(false);
      setModalOpen(true);
    }
  }, [createNotificationError, createNotificationData])

  //#endregion

  const {
    data: notificationsData,
    loading: notificationsLoading,
    error: notificationsError
  } = useQuery<GetNotificationsByManagerQuery>(
    GetNotificationsByManagerDocument,
    {
      variables: { limit: 1000, skip: 0, isPublished: null },
      fetchPolicy: 'cache-and-network'
    }
  );

  const modalOpenHandler = (): void => {
    setModalOpen(true);
  }

  const modalCloseHandler = (): void => {
    setModalOpen(false);
  }

  let content;

  if (notificationsLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit' />
      </LoadingBox>
    );
  }

  if (notificationsError) {
    content = <ErrorSnackbar error={notificationsError}/>;
  }

  if (notificationsData) {
    const notifications = notificationsData.getNotificationsByManager?.notifications as Notification[];

    content = notifications?.length
      ? (notifications.map((notification) => (
        <NotificationListItem
          key={notification.id}
          onClick={() => setChosenNotification(notification)}
        >
          <div>
            {notification.publishedAt ? (new Date(notification.publishedAt)).toLocaleDateString() : 'Не опубликовано'}
          </div>

          <div>
            {notification.publishedAt
              ? (new Date(notification?.publishedAt)).toLocaleTimeString().split(':').slice(0, 2).join(':')
              : ''
            }
          </div>

          <div>
            <AnnouncementsTitle>{notification.name}</AnnouncementsTitle>
            <AnnouncementsText>{notification.description}</AnnouncementsText>
          </div>
          <div>
            <StatisticsBtn
              disabled={!notification.isPublished}
              onClick={() => setStatisticsModalOpen(true)}
            >
              Статистика
            </StatisticsBtn>
          </div>

          <NotificationMenu
            openModal={modalOpenHandler}
            notificationId={notification.id}
            isPublished={notification.isPublished}
          />
        </NotificationListItem>))
      )
      : (
        <AnnouncementsListItem>
          <NoAnnouncementsListText>Уведомления отсутствуют</NoAnnouncementsListText>
        </AnnouncementsListItem>
      )
  }

  return (
    <Container  maxWidth="lg">
      <Header>
        Уведомления
      </Header>

      <AddButtonWrapper>
        <AnnouncementsNavBar />

        <ButtonPlus
          width={180}
          disabled={createNotificationLoading}
          variant="contained"
          onClick={createNotificationHandler}
        >
          Добавить
        </ButtonPlus>
      </AddButtonWrapper>


      <List>
        {content}
      </List>

      <NotificationModal
        notification={chosenNotification || createNotificationData?.createNotification}
        open={modalOpen}
        close={modalCloseHandler}
      />

      <NotificationStatisticsModal
        notification={chosenNotification}
        open={statisticsModalOpen}
        close={() => setStatisticsModalOpen(false)}
      />

      {createError && (
        <Snackbar
          open={createError}
          autoHideDuration={6000}
          onClose={() => setCreateError(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity='error'>Не удалось создать  уведомление</Alert>
        </Snackbar>
      )}
    </Container>
  )
}
