import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { MainTableRow, primary } from "../shared/Style/Style";

export const AddContentText = styled(TextField)`
  & .MuiInput-underline:before {
    border-bottom-color: #eee;
  }
  & .MuiInput-underline:before {
    border-bottom-color: ${({ disabled }) => disabled ? 'transparent' : '#eee'};
  }
  & .MuiFormLabel-root{
    color: ${({ disabled }) => disabled ? 'transparent' : '#eee'};
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${primary};
  }

  & .MuiInputBase-multiline {
    padding: 13px 14px 13px 10px;
  }
  & .MuiFormLabel-root.Mui-focused{
    color: ${primary};
  }
`;

export const TrainingTableRows=styled(MainTableRow)`
  grid-template-columns: 2.5fr 6fr 2.5fr 0.4fr;;
  grid-gap: 10px;
  cursor: pointer;
`;

export const AddModalName = styled(TextField)`
  & .MuiInput-underline:before {
    border-bottom-color: #eee;
  }

  & .MuiInput-underline:before {
    border-bottom-color: ${({disabled}) => disabled ? 'transparent' : '#eee'};
  }

  & .MuiFormLabel-root {
    color: ${({disabled}) => disabled ? 'transparent' : '#0c0c0c'};
  }

  & .MuiInput-underline:after {
    border-bottom-color: #ffb61c;
  }

  & .MuiInputBase-multiline {
    padding: 13px 14px 13px 10px;
  }

  & .MuiFormLabel-root.Mui-focused {
    color: #ffb61c;
  }
`;