import React, { memo, useCallback, useState } from "react"
import { useMutation, useQuery } from "@apollo/client";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { OnboardingContainer, Title, ButtonInner, List, NoList } from "./Onboarding.style";
import { ButtonPlus } from "../shared/Style/Style";
import { TableHeader } from "./TableHeader";
import { CreateEditThemeModal } from "./Modal";
import { ThemeItemDnd } from "./ThemeItem";
import {
  GetOnboardingRoutesEditDocument, GetOnboardingRoutesEditQuery,
  GetOnboardingThemesEditDocument,
  GetOnboardingThemesEditQuery, ReorderOnboardingThemeDocument, ReorderOnboardingThemeMutation
} from "../../generated/graphql";
import { LoadingProgress } from "../shared/LoadingProgress";

export const Onboarding = memo(() => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentThemeId, setCurrentThemeId] = useState<string | null>(null);

  const { data, loading } = useQuery<GetOnboardingThemesEditQuery>(GetOnboardingThemesEditDocument)

  const { loading: routesDataLoading } = useQuery<GetOnboardingRoutesEditQuery>(GetOnboardingRoutesEditDocument);

  const [ reorderTheme] = useMutation<ReorderOnboardingThemeMutation>(ReorderOnboardingThemeDocument, {
    refetchQueries: [{ query: GetOnboardingThemesEditDocument }]
  })

  const disableOnLoading = routesDataLoading || loading;
  const themes = data?.getOnboardingThemesEdit || [];

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const themeId = themes.find(({ position }) => position === result.source.index).id
    const position = result.destination.index

    await reorderTheme({
      variables: { themeId, position }
    })
  }

  const handleOpenModal = useCallback(
    () => setIsOpen(true),
    []);

  const handleCloseModal = useCallback(
    () => {
    setIsOpen(false);
    setCurrentThemeId(null)
  }, [])

  if (disableOnLoading) {
    return ( <LoadingProgress />);
  }

  return (
    <OnboardingContainer className="onboarding">

      <Title>Онбординг</Title>

      <ButtonInner>
        <ButtonPlus
          variant="contained"
          onClick={handleOpenModal}
        >
          добавить тему
        </ButtonPlus>
      </ButtonInner>

      {themes.length
        ? (
          <>
            <TableHeader/>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <List
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {themes.map(({
                       id,
                       video,
                       position,
                       isPublished,
                       title,
                       description,
                       publishedAt,
                      ...rest
                     }) => (
                      <ThemeItemDnd
                        key={id}
                        setOpenModal={setIsOpen}
                        setCurrentThemeId={setCurrentThemeId}
                        themeId={id}
                        position={position}
                        video={video}
                        title={title}
                        description={description}
                        isPublished={isPublished}
                        publishedAt={publishedAt}
                      />
                    ))}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )
        : (
          <NoList>Создайте первый элемент</NoList>
        )}

      <CreateEditThemeModal
        open={isOpen}
        close={handleCloseModal}
        themeId={currentThemeId}
      />
    </OnboardingContainer>
  )
});
