import Tooltip from '@material-ui/core/Tooltip'
import { SvgIcon } from '@material-ui/core'
import { CSSProperties } from 'react'

interface InfoTooltipProps {
  title: string
  overrideStyles?: CSSProperties
}

export const InfoTooltip = ({ title, overrideStyles }: InfoTooltipProps) => {
  return (
    <Tooltip title={title}>
      <SvgIcon width='10' height='10' fill='none' style={overrideStyles}>
        <path
          d='M5 0C2.24 0 0 2.24 0 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5Zm.5 7.5h-1v-3h1v3Zm0-4h-1v-1h1v1Z'
          fill='#FFB61D'
        />
      </SvgIcon>
    </Tooltip>
  )
}
