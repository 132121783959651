import React from "react";

import { Dialog, DialogContent } from "@material-ui/core";
import { ButtonCustom, ButtonCustomCancel, ButtonsWrapper } from "../shared/Style/Style";
import { ModalDialogTitle } from "./Payments.style";


interface IPaymentConfirmationModalProps {
  open: boolean;
  close(): void;
  paymentId?: string;
  payPayment(paymentId: string): void;
}

export const PaymentConfirmationModal = ({ open, close, paymentId, payPayment }: IPaymentConfirmationModalProps) => {
  return (
    <Dialog
      open={open}
    >
      <DialogContent>
        <ModalDialogTitle >Вы уверены что хотите отметить платеж как оплаченный?</ModalDialogTitle>

        <ButtonsWrapper>
          <ButtonCustomCancel
            onClick={close}
          >
            Отмена
          </ButtonCustomCancel>

          <ButtonCustom
            onClick={() => {
              payPayment(paymentId);
              close();
            }}
          >
            Подтвердить
          </ButtonCustom>
        </ButtonsWrapper>
      </DialogContent>
    </Dialog>
  )
}