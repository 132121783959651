export const headerTableCreatedProjects = [
  { title: "Название" },
  { title: "Статус" },
  { title: "Ответственный" },
  { title: "Дедлайн" },
  { title: "Текущий этап" },
  { title: " " },
];

export const headerTableNotCreatedProjects = [
  { title: "Аватар" },
  { title: "Название" },
  { title: "id" },
  { title: "key" },
  { title: " " },
];