import React, { useState } from "react";
import { Box, Dialog, SvgIcon, DialogContent } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { DeleteIconButton } from "../Buttons/DeleteIconButton";
import { EditIconButton } from "../Buttons/EditIconButton";
import { CloseIconButton } from "../Buttons/CloseIconButton";
import { TextPhotoName } from "../Style/Style";
import { ModalPreviewPhoto } from "../Modal/ModalPreviewPhoto";

const CustomIconButton = styled(IconButton)`
  padding: 0;
`;

const PreviewImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

const CustomSvgIcon = styled(SvgIcon)`
  &.MuiSvgIcon-root {
    width: 30px;
    height: 30px;
    padding: 0;
  }
`;

const IconCamera = () => (
  <CustomSvgIcon viewBox="-3 -5 30 30">
    <path
      d="M2 1.88889H5L7 0H13L15 1.88889H18C18.5304 1.88889 19.0391 2.0879 19.4142 2.44213C19.7893 2.79637 20 3.27681 20 3.77778V15.1111C20 15.6121 19.7893 16.0925 19.4142 16.4468C19.0391 16.801 18.5304 17 18 17H2C1.46957 17 0.960859 16.801 0.585786 16.4468C0.210714 16.0925 0 15.6121 0 15.1111V3.77778C0 3.27681 0.210714 2.79637 0.585786 2.44213C0.960859 2.0879 1.46957 1.88889 2 1.88889ZM10 4.72222C8.67392 4.72222 7.40215 5.21974 6.46447 6.10533C5.52678 6.99092 5 8.19203 5 9.44444C5 10.6969 5.52678 11.898 6.46447 12.7836C7.40215 13.6691 8.67392 14.1667 10 14.1667C11.3261 14.1667 12.5979 13.6691 13.5355 12.7836C14.4732 11.898 15 10.6969 15 9.44444C15 8.19203 14.4732 6.99092 13.5355 6.10533C12.5979 5.21974 11.3261 4.72222 10 4.72222ZM10 6.61111C10.7956 6.61111 11.5587 6.90962 12.1213 7.44097C12.6839 7.97233 13 8.693 13 9.44444C13 10.1959 12.6839 10.9166 12.1213 11.4479C11.5587 11.9793 10.7956 12.2778 10 12.2778C9.20435 12.2778 8.44129 11.9793 7.87868 11.4479C7.31607 10.9166 7 10.1959 7 9.44444C7 8.693 7.31607 7.97233 7.87868 7.44097C8.44129 6.90962 9.20435 6.61111 10 6.61111Z"
      fill="black"
    />
    <circle cx="21" cy="11" r="6" fill="#FFA425" />
    <path
      d="M25 11.5714H21.5714V15H20.4286V11.5714H17V10.4286H20.4286V7H21.5714V10.4286H25V11.5714Z"
      fill="black"
    />
  </CustomSvgIcon>
);

interface PhotoPickerWithIconProps {
  photoName: string;
  imageUrl: string;
  disabled: boolean;
  addPhoto: (file: File) => void;
  deletePhoto: () => void;
}

export const PhotoPickerWithIcon = ({
  photoName,
  imageUrl,
  disabled,
  addPhoto,
  deletePhoto,
}: PhotoPickerWithIconProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(true);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/*",
    multiple: false,
    noClick: true,
  });

  // @ts-ignore
  return (
    <Box display="flex">
      {(!!imageUrl && (
        <Box display="flex" alignItems="center" style={{ cursor: "pointer" }}>
          <Box {...getRootProps()} mr={2}>
            <TextPhotoName
              onClick={isEdit ? () => setIsOpen(true) : () => open()}
            >
              {photoName}
            </TextPhotoName>
            <input
              type="file"
              {...getInputProps()}
              onChange={async ({ target: { files } }) => {
                await addPhoto(files[0]);
                setIsEdit(true);
              }}
            />
          </Box>
          <Box>
            <DeleteIconButton
              deleteHandler={deletePhoto}
              disabled={isEdit}
              item="фото"
              fz={18}
            />
            <EditIconButton
              edit={() => setIsEdit((prevState) => !prevState)}
              disabled={disabled}
              fz={18}
            />
          </Box>
        </Box>
      )) || (
        <Box {...getRootProps()} onClick={open} style={{ cursor: "pointer" }}>
          <CustomIconButton disabled={disabled}>
            <IconCamera />
            <input
              type="file"
              {...getInputProps()}
              onChange={async ({ target: { files } }) => {
                await addPhoto(files[0]);
                setIsEdit(true);
              }}
            />
          </CustomIconButton>
        </Box>
      )}

      <ModalPreviewPhoto
        isOpen={isOpen}
        close={setIsOpen}
        imageUrl={imageUrl}
      />
    </Box>
  );
};
