import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetUsersDocument,
  GetUsersQuery,
  Maybe,
  News,
  NewsIsForEnum,
  SetNewsParticipantsDocument,
  SetNewsParticipantsMutation,
  User,
  UserJob,
} from "../../../generated/graphql";

import {
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  ListItem,
  Radio,
  Snackbar,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Alert } from "@material-ui/lab";

import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";
import { LoadingBox } from "../../shared/Style/Style";
import { DEFAULT_AVATAR_URL } from "../../shared/constants";

import {
  AddUsersToNotificationModalContent,
  BtnsWrapper,
  ButtonCancelFixedWidth,
  ButtonFixedWidth,
  DropdownIconWrapper,
  MainList,
  UserAvatarXS,
  UserLabel,
  UserLabelText,
  UserListItem,
  UsersFilter,
  UsersFilterForm,
  UsersFilterFormLabel,
  UsersFilters,
  UsersGroupButton,
  UsersGroupByJob,
  UsersGroupList,
} from "../ParticipantsModal.style";

import { AvailableForToggler } from "../AvailableForToggler";

interface IAddUsersToNotificationModalProps {
  news: News | null | undefined;
  participants: [] | (Maybe<string> | undefined)[] | null | undefined;
  setParticipants: Dispatch<SetStateAction<[] | (Maybe<string> | undefined)[] | null | undefined>>;
  open: boolean;

  close(): void;

  isFor: NewsIsForEnum;
}

export const AddNewsParticipantsModal = ({
                                           open,
                                           close,
                                           news,
                                           participants,
                                           setParticipants,
                                           isFor,
                                         }: IAddUsersToNotificationModalProps) => {
  const [activeFilter, setActiveFilter] = useState<string | undefined>(
    undefined
  );
  const [togglerActive, setTogglerActive] = useState( true );

  const [chosenJob, setChosenJob] = useState<UserJob | null>( null );

  const choseJobHandler = (job: UserJob) => {
    if (job.id === chosenJob?.id) {
      setChosenJob( null );
      return;
    }

    setChosenJob( job );
  };

  // #region set participants
  const [availableFor, setAvailableFor] = useState<string | null>( "ALL" );

  const changeParticipantStatusHandler = (id: string | undefined | null) => {
    if (!id) {
      return;
    }

    if (
      participants?.some(
        (participant: Maybe<string | undefined> | null | undefined) =>
          participant === id
      )
    ) {
      setParticipants( participants.filter( (participant) => participant !== id ) );
      return;
    }

    if (participants && participants?.length) {
      setParticipants( [...participants, id] );
      return;
    }

    setParticipants( [id] );
  };

  const [
    SetNewsParticipants,
    {
      data: setNewsParticipantsData,
      loading: setNewsParticipantsLoading,
      error: setNewsParticipantsError,
    },
  ] = useMutation<SetNewsParticipantsMutation>( SetNewsParticipantsDocument );

  const setParticipantsHandler = () => {
    if (!availableFor && !participants) {
      return;
    }

    let notificationParticipants;

    availableFor
      ? (notificationParticipants = {
        id: news?.id,
        availableFor,
      })
      : (notificationParticipants = {
        id: news?.id,
        participants: participants,
        availableFor: null,
      });

    SetNewsParticipants( {
      variables: {
        newsData: {
          ...notificationParticipants,
        },
      },
    } );
  };

  const [isParticipantsErrorSnackbarOpen, setParticipantsErrorSnackbarOpen] =
    useState( false );

  useEffect( () => {
    if (setNewsParticipantsData) {
      setParticipantsErrorSnackbarOpen( false );
      close();
    }

    if (setNewsParticipantsError) {
      setParticipantsErrorSnackbarOpen( true );
    }
  }, [setNewsParticipantsData, setNewsParticipantsError] );

  // #endregion

  const [getUsers, {loading, error, data}] =
    useLazyQuery<GetUsersQuery>( GetUsersDocument );

  let content;

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit"/>
      </LoadingBox>
    );
  }

  if (error) {
    content = <ErrorSnackbar error={error}/>;
  }

  if (data && !availableFor) {
    const users = data?.getUsers?.users as User[];

    interface usersByJob {
      job: UserJob;
      users: User[];
    }

    let usersByJobs = [] as usersByJob[];

    if (activeFilter === "STAFF") {
      users.forEach( (user: User) => {
        if (
          user.job &&
          !usersByJobs.find( ({job}: usersByJob) => job.id === user.job?.id )
        ) {
          usersByJobs.push( {job: user.job, users: [user]} );
          return;
        }

        if (
          user.job &&
          usersByJobs.find( ({job}: usersByJob) => job.id === user.job?.id )
        ) {
          usersByJobs
            ?.find( ({job}: usersByJob) => job.id === user.job?.id )
            ?.users.push( user );
        }
      } );
    }

    content = (
      <>
        {activeFilter === "STAFF" ? (
          usersByJobs?.length ? (
            <MainList>
              {usersByJobs.map( ({job, users}: usersByJob) => (
                <UsersGroupByJob
                  key={job.id}
                  expanded={job.id === chosenJob?.id}
                  usersCount={users.length}
                >
                  <UsersGroupButton onClick={() => choseJobHandler( job )}>
                    <span>{job.name}</span>

                    <DropdownIconWrapper expanded={job.id === chosenJob?.id}>
                      <ExpandMoreIcon/>
                    </DropdownIconWrapper>
                  </UsersGroupButton>

                  <UsersGroupList>
                    {users.map( ({id, photo, firstName, lastName}: User) => (
                      <ListItem key={`${job.id} ${firstName} ${lastName}`}>
                        <FormControlLabel
                          value="end"
                          control={
                            <Checkbox
                              disabled={setNewsParticipantsLoading}
                              onChange={() =>
                                changeParticipantStatusHandler( id )
                              }
                              checked={participants?.some(
                                (
                                  participant:
                                    | Maybe<string | undefined>
                                    | null
                                    | undefined
                                ) => participant === id
                              )}
                              color="default"
                            />
                          }
                          label={
                            <UserLabel>
                              <UserAvatarXS src={photo || DEFAULT_AVATAR_URL}/>
                              <UserLabelText
                                primary={`${lastName} ${firstName}`}
                              />
                            </UserLabel>
                          }
                        />
                      </ListItem>
                    ) )}
                  </UsersGroupList>
                </UsersGroupByJob>
              ) )}
            </MainList>
          ) : (
            <p>Нет доступных пользователей</p>
          )
        ) : users?.length ? (
          <MainList>
            {users.map(
              ({id, photo, firstName, lastName}: User, i: number) => (
                <ListItem button key={`${i} ${firstName} ${lastName}`}>
                  <UserListItem
                    value="end"
                    control={
                      <Checkbox
                        disabled={setNewsParticipantsLoading}
                        onChange={() => changeParticipantStatusHandler( id )}
                        checked={participants?.some(
                          (
                            participant:
                              | Maybe<string | undefined>
                              | null
                              | undefined
                          ) => participant === id
                        )}
                        color="default"
                      />
                    }
                    label={
                      <UserLabel>
                        <UserAvatarXS src={photo || DEFAULT_AVATAR_URL}/>
                        <UserLabelText primary={`${lastName} ${firstName}`}/>
                      </UserLabel>
                    }
                  />
                </ListItem>
              )
            )}
          </MainList>
        ) : (
          <p>Нет доступных пользователей</p>
        )}
      </>
    );
  }

  return (
    <Dialog open={open} onClose={close} TransitionProps={{timeout: 300}}>
      <AddUsersToNotificationModalContent>
        <UsersFilterForm>
          <UsersFilterFormLabel>Отметьте участников</UsersFilterFormLabel>
          <UsersFilters
            aria-label="users filter"
            value={activeFilter || ""}
            onChange={({target: {value}}) => {
              setTogglerActive( true );

              if (!value) {
                setActiveFilter( undefined );
                return;
              }

              setActiveFilter( value );
            }}
          >
            {isFor === NewsIsForEnum.AdminPanel && (
              <>
                <UsersFilter value={""} control={<Radio/>} label="Все"/>
                <UsersFilter
                  value="STAFF"
                  control={<Radio/>}
                  label="Сотрудники"
                />
                <UsersFilter
                  value="CLIENT"
                  control={<Radio/>}
                  label="Клиенты"
                />
              </>
            )}

            {isFor === NewsIsForEnum.LkTeacher && (
              <>
                <UsersFilter value={""} control={<Radio/>} label="Все"/>
                <UsersFilter
                  value="TEACHER"
                  control={<Radio/>}
                  label="Учителя"
                />
              </>
            )}
            {isFor === NewsIsForEnum.AdminPanelAndLkTeacher && (
              <>
                <UsersFilter value={""} control={<Radio/>} label="Все"/>
                <UsersFilter
                  value="STAFF"
                  control={<Radio/>}
                  label="Сотрудники"
                />
                <UsersFilter
                  value="CLIENT"
                  control={<Radio/>}
                  label="Клиенты"
                />
                <UsersFilter
                  value="TEACHER"
                  control={<Radio/>}
                  label="Учителя"
                />
              </>
            )}
          </UsersFilters>
        </UsersFilterForm>

        <AvailableForToggler
          activeFilter={activeFilter}
          setAvailableFor={setAvailableFor}
          getUsers={getUsers}
          togglerActive={togglerActive}
          setTogglerActive={setTogglerActive}
        />

        {content}

        <BtnsWrapper>
          <ButtonCancelFixedWidth
            disabled={setNewsParticipantsLoading}
            onClick={close}
          >
            отмена
          </ButtonCancelFixedWidth>

          <ButtonFixedWidth
            disabled={setNewsParticipantsLoading}
            onClick={setParticipantsHandler}
          >
            ок
          </ButtonFixedWidth>
        </BtnsWrapper>
      </AddUsersToNotificationModalContent>

      <Snackbar
        open={isParticipantsErrorSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setParticipantsErrorSnackbarOpen( false )}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
      >
        <Alert severity="error">
          При изменении получателей произошла ошибка!
        </Alert>
      </Snackbar>
    </Dialog>
  );
};
