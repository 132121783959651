import React from "react";
import {
  Container,
  Title,
  Text,
  ListItem,
  SubtitleCenter,
  TableHeaderCell,
  TableBodyCell,
} from "./PrivatePolicy.style";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

function createData(
  type: string,
  name: string,
  target: string,
  period: string,
  recipient: string | JSX.Element
) {
  return { type, name, target, period, recipient };
}

const rows = [
  createData(
    "Technical cookies",
    "user_id",
    "For the identification of user",
    "10 years",
    <a href="https://accounts.google.com">accounts.google.com</a>
  ),
  createData(
    "Technical cookies",
    "Token",
    "Security code during the authorization that changes regularly",
    "session",
    <a href="https://teacher.arbook.info">teacher.arbook.info</a>
  ),
  createData(
    "Technical cookies",
    "Session",
    "Session code for the Fondy payment system. Transmits information about whether the user successfully completed the payment.",
    "session",
    <a href="https://docs.fondy.eu/">pay.fondy.eu</a>
  ),
  createData(
    "Technical cookies",
    "lastGclid",
    "Google account ID. It is used for quick registration and authorization through a Google account.",
    "7 days",
    <a href="https://www.smartlook.com/">smartlook.com/</a>
  ),
  createData(
    "Technical cookies",
    "lang_actual",
    "Is used by the Fondy payment system to determine the current user interface language to display the payment page in the same language.",
    "30 days",
    <a href="https://docs.fondy.eu/">pay.fondy.eu</a>
  ),
  createData(
    "Technical cookies",
    "Lang",
    "Is used by the Fondy payment system to determine the current user interface language.",
    "30 days",
    <a href="https://docs.fondy.eu/">pay.fondy.eu</a>
  ),
  createData(
    "Technical cookies",
    "device-source",
    "Fixation which device was used to enter the system. It is used for security measures.",
    "30 days",
    <a href="https://arbook.info">arbook.info</a>
  ),
  createData(
    "Technical cookies",
    "planPromoFromUrl",
    "The selected plan is saved on the promo site so that when switching to the web system, the page of the previously selected tariff is displayed.",
    "30 days",
    <>
      AR_Book, Market, AR Teacher
      <br />
      <a href="https://arbook.info">arbook.info</a>
    </>
  ),
  createData(
    "Technical cookies",
    "isShowTrialPeriodModal",
    "We calculate whether it is necessary to show the user a prompt about the end of the paid period.",
    "10 years",
    <>
      AR_Book, Market, AR Teacher
      <br />
      <a href="https://arbook.info">arbook.info</a>
    </>
  ),
  createData(
    "Statistical cookies",
    "data.ai",
    "The analytics of key application metrics on Google Play and the App Store: downloads, ratings, searches, and more.",
    "5 years",
    "Application AR Book"
  ),
  createData(
    "Marketing cookies",
    "Netpeak Spider",
    "Collection of SEO site analytics: attendance, traffic, search queries, and more.",
    "5 years or deleted at the request of the user.",
    <a href="https://arbook.info">arbook.info</a>
  ),
  createData(
    "Marketing cookies",
    "Serpstat",
    "Collection of SEO site analytics: attendance, traffic, search queries, and more.",
    "10 years or deleted at the request of the user.",
    <>
      AR_Book, Market
      <br />
      <a href="https://arbook.info">arbook.info</a>
    </>
  ),
  createData(
    "Statistical cookies",
    "Smartlook",
    "Recording of the Application screen as well as user behavior on the site to see where an error might occur. Personal data that is entered by the user, names, e-mails, phone numbers and similar are not recorded. A heat map of behavior is also reproduced. Among the data that is recorded, in addition to the screen recording, those are also the device, device version, operating system version, browser version, and the city of recording.",
    "From the moment the account is created to the moment it is closed.",
    "Application AR Book, AR_Book Market, AR Teacher"
  ),
  createData(
    "Statistical cookies",
    "Wix",
    "Data which is sent from Wix forms. To collect feedback and comments about the system. These are email addresses.",
    "Stored in the Wix internal storage until deleted by the platform.",
    <a href="https://arbook.info">arbook.info</a>
  ),
  createData(
    "Marketing cookies",
    "Google Analytics",
    "Analytical data about the system and application. Traffic data, interactions, geographic data, data on the model of device, version of operating system, etc. are stored.",
    "26 months",
    <>
      Application AR Book, AR_Book Market, AR Teacher
      <br />
      <a href="https://arbook.info">arbook.info</a>
    </>
  ),
  createData(
    "Statistical cookies",
    "Google Tag Manager",
    "A supplement to Google Analytics, because it allows analytics to be configured.",
    "5  years.",
    <>
      AR_Book, Market, AR Teacher
      <br />
      <a href="https://arbook.info">arbook.info</a>
    </>
  ),
  createData(
    "Marketing cookies",
    "SendPulse",
    "Email addresses, phone numbers, names and other data are used to set up mailshots.",
    "2 years or deleted at the request of the user",
    <>
      AR_Book, Market, AR Teacher
      <br />
      <a href="https://arbook.info">arbook.info</a>
    </>
  ),
  createData(
    "Marketing cookies",
    "Facebook and Facebook Pixel",
    "Data on page visits, traffic, interaction, targeted actions on the site (customized conversions), data on the gender and age of the audience, etc.",
    "30 days",
    <>
      AR_Book, Market, AR Teacher
      <br />
      <a href="https://arbook.info">arbook.info</a>
    </>
  ),
  createData(
    "Marketing cookies",
    "Firebase",
    "Data is used by application analytics. Information from Google Analytics of the AR Book application.",
    "2 years",
    "Application AR Book"
  ),
];
const headersTable = [
  "Type",
  "Name",
  "The purpose of use",
  "Term of storage",
  "Who uses",
];

const platforms = [
  {
    url: "https://www.facebook.com/business/help/471978536642445?id=1205376682832142",
    name: "Facebook",
  },
  {
    url: "https://support.google.com/youtube/answer/2801895?hl=en-GB",
    name: "Youtube",
  },
  {
    url: "https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=ru",
    name: "Google Analytics",
  },
];

const cookiesManagedUrls = [
  {
    url: "https://support.microsoft.com/ru-ru/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy",
    name: "Microsoft edge",
  },
  {
    url: "https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666",
    name: "Google Chrome",
  },
  {
    url: "https://support.apple.com/en-gb/HT201265",
    name: "Safari mobile",
  },
  {
    url: "https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac",
    name: "Safari desktop",
  },
  {
    url: "https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies",
    name: "Mozilla Firefox",
  },
  {
    url: "https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies",
    name: "Internet Explorer",
  },
];

export const CookiesPolicyEn = () => (
  <Container>
    <Title>Policy on the use of AR_BOOK cookies</Title>

    <Box mt={6} style={{ textIndent: "40px" }}>
      <Text style={{ margin: "10px 0 0 0" }}>
        This Policy on the use of cookies (hereinafter referred to as the
        <strong>“Policy”</strong>) is a legally binding document and is part of
        our user agreement, representing the rules for the collection and use of
        cookies by the ArBook interactive system, which includes such products
        as ArBook, VR_Lab, AR_Teacher and AR_Book Market; all the
        above-mentioned products are part of an interactive system and use this
        Policy.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        <strong>Limited liability company "AR BOOK UA"</strong>, EDRPOU:
        44973818, in the person of the director Olga Mykolaivna Holovakhina,
        acting in accordance with the Charter, has developed this Policy on the
        following.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        In this Policy, we will explain what cookies are, what cookies are used
        by the ArBook interactive system, which includes products such as
        ArBook, VR_Lab, AR_Teacher and AR_Book Market, how we process files
        collected by cookies and how you can withdraw your consent and disable
        cookies.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Cookies are small text files that are downloaded to your computer or
        other device when you access a website. Cookies allow the site to
        recognize your device and store information about your preferences or
        past actions.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        What cookies does the ArBook interactive system use?
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Some cookies used by the Platform are technically necessary for the
        operation of the website. Other cookies are usually set in response to
        actions requested by the User, for example, cookies to remember the
        decisions you have made, such as: your name as a user of the platform,
        your interests, location, date of birth and other data specified in your
        personal profile. Such cookies help you improve your website experience
        with a more personalized service.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Also, the ArBook interactive system uses statistical cookies to count
        visits and traffic sources so that we can evaluate and improve the
        performance of our website. It informs us which pages are the most
        popular and the least visited, and we can see how visitors move around
        the site. For example, we use Google Analytics to understand how often
        the same people return to the site, how they find it (by ads or links on
        the sites) and which pages are viewed most often. Such information is
        combined with data from thousands of other users to create an overall
        picture of website usage. They are never identified individually or
        personally and are not related to other information we possess about
        you.
      </Text>
    </Box>

    <Box mt={6} style={{ textIndent: "40px" }}>
      <SubtitleCenter>
        The following is a detailed list of cookies used by the ArBook
        interactive system which are included in such products as: ArBook,
        AR_Teacher and AR_Book Market.
      </SubtitleCenter>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headersTable.map((header) => (
                <TableHeaderCell key={header}>{header}</TableHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableBodyCell scope="row">{row.type}</TableBodyCell>
                <TableBodyCell>{row.name}</TableBodyCell>
                <TableBodyCell>{row.target}</TableBodyCell>
                <TableBodyCell>{row.period}</TableBodyCell>
                <TableBodyCell>{row.recipient}</TableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>

    <Box mt={6} style={{ textIndent: "40px" }}>
      <SubtitleCenter>
        How does the ArBook interactive system work with information obtained
        with the help of cookies?
      </SubtitleCenter>
      <Text style={{ margin: "10px 0 0 0" }}>
        When visiting the platform, you may have noticed a pop-up banner about
        consent to the use of cookies. To be more specific, we ask for
        permission to use statistical and marketing cookies, this does not apply
        to technical cookies without which the site cannot function properly,
        therefore, we reasonably assume that by visiting our site, you have
        automatically given your consent to the use of such cookies. If you set
        your browser to block technical cookies, some parts of the site will not
        work.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        The information we collect through cookies is handled in accordance with
        our <strong>data protection policy</strong>. For example, such data may
        be available to our employees, contractors, and our affiliates.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        The Platform, in accordance with GOOGLE's policy, may request access to the 
        User's Google Drive for productivity and learning purposes, using only limited
        areas to process Drive files (or their methods or extensions) through the 
        user interface. After the User gives permission to use data from Drive, the 
        Platform gets access to his files on Google Drive. We use the additional 
        information obtained in this way to manage, maintain, improve and provide you
        with the Platform's features to the fullest extent. This function will allow 
        the Platform to improve interaction with the User.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        To access Google Drive, the Platform requests permission from the User to access
        files on Drive. The platform gets the opportunity to integrate the User's 
        Google Drive into the work for the entire duration of such permission. 
        In turn, the User can restrict the Platform from integrating his Drive at any time.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        For better performance of the site, its interactivity, remembering your
        preferences and simply comfortable use of our products by you, it uses
        third-party services. Such services include Facebook, YouTube, Google,
        etc.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Accordingly, the Platform, when interacting with the specified
        companies, can also transfer certain data to these services located
        within the EU and the USA. We have no direct control over the
        information collected by these companies; regarding the collection and
        processing of your data by such companies, please refer to the following
        websites:
      </Text>
      <ul>
        {platforms.map(({ url, name }) => (
          <ListItem key={name}>
            <a href={url}>{name}</a>
          </ListItem>
        ))}
      </ul>
    </Box>

    <Box mt={6} mb={6} style={{ textIndent: "40px" }}>
      <SubtitleCenter>How can cookies be managed?</SubtitleCenter>
      <Text style={{ margin: "10px 0 0 0" }}>
        You can easily disable/delete cookies in your browser on your own. This
        is performed differently in each browser, here are links that can help
        you:
      </Text>
      <ul>
        {cookiesManagedUrls.map(({ url, name }) => (
          <ListItem key={name}>
            <a href={url}>{name}</a>
          </ListItem>
        ))}
      </ul>
    </Box>
  </Container>
);
