import React from "react";
import { Box, Typography } from "@material-ui/core";
import { DEFAULT_AVATAR_URL } from "../constants";
import { SmallUserAvatarStyle, UserName } from "../Style/Style";
import { User } from "../../../generated/graphql";
import { EditIconButton } from "../Buttons/EditIconButton";

export const ProjectManagerInfo = ({
  projectManager,
  edit,
}: {
  projectManager: User;
  edit?(state: boolean): void;
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mt={2}
      mb={2}
      width={280}
    >
      <Box display="flex" alignItems="center" mt={2} mb={2}>
        <Box mr={2}>
          <SmallUserAvatarStyle
            src={
              projectManager?.photo ? projectManager.photo : DEFAULT_AVATAR_URL
            }
          />
        </Box>
        <UserName>
          {projectManager && projectManager.firstName}{" "}
          {projectManager && projectManager.lastName}
        </UserName>
        {edit && <EditIconButton disabled={false} edit={() => edit(true)} />}
      </Box>
    </Box>
  );
};
