import React from "react";

import { FilterButton, Filter } from "../../shared/Style/Style";

interface ProjectFilterProps {
  activeFilter: string[] | [];
  setActiveFilter(filter: string[] | []): void;
}

export const ProjectsFilter = ({
  activeFilter,
  setActiveFilter,
}: ProjectFilterProps) => {
  return (
    <Filter>
      <FilterButton
        active={activeFilter.length === 0}
        onClick={() => setActiveFilter([])}
      >
        Все
      </FilterButton>
      <FilterButton
        active={activeFilter.includes("NEW" as never)}
        onClick={() => setActiveFilter(["NEW"])}
      >
        Новые
      </FilterButton>
      <FilterButton
        active={activeFilter.includes("IN_PROGRESS" as never)}
        onClick={() => setActiveFilter(["IN_PROGRESS"])}
      >
        В работе
      </FilterButton>
      <FilterButton
        active={activeFilter.includes("DONE" as never)}
        onClick={() => setActiveFilter(["DONE"])}
      >
        Завершены
      </FilterButton>
    </Filter>
  );
};
