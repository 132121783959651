import React, { useEffect, useRef } from "react";
import MuiPhoneNumber from "material-ui-phone-number";
import { Field } from "react-final-form";
import { Grid, TextField } from "@material-ui/core";
import {
  userNameValidation,
  userUnique,
  validateEmailField,
} from "../../core/validators/validators";
import { useStyles } from "../shared/Style/Style";
import { InitialFormError } from "./Registration.type";

interface BaseInfoProps {
  formError: InitialFormError;
  setFormError(state: InitialFormError): void;
}

export const BaseInfo = ({ formError, setFormError }: BaseInfoProps) => {
  const classes = useStyles();
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef?.current?.state) {
      setFormError({
        ...formError,
        phoneError: "",
      });
    }
  }, [inputRef?.current?.state]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Field name="lastName" validate={userNameValidation}>
          {({ input, meta }) => (
            <TextField
              className={classes.root}
              error={(meta.error && meta.touched) || !!formError.lastNameError}
              margin="normal"
              fullWidth
              helperText={
                (meta.touched && meta.error) || formError.lastNameError
              }
              label="Ваша фамилия"
              variant="filled"
              type="text"
              {...input}
              onChange={(event) => {
                setFormError({
                  ...formError,
                  lastNameError: "",
                });
                input.onChange(event.target?.value);
                input.onBlur();
              }}
            />
          )}
        </Field>
      </Grid>
      <Grid item={true} xs={12}>
        <Field name="firstName" validate={userNameValidation}>
          {({ input, meta }) => (
            <TextField
              className={classes.root}
              error={(meta.error && meta.touched) || !!formError.firstNameError}
              helperText={
                (meta.touched && meta.error) || formError.firstNameError
              }
              margin="normal"
              fullWidth
              label="Ваше имя"
              variant="filled"
              type="text"
              {...input}
              onChange={(event) => {
                setFormError({
                  ...formError,
                  firstNameError: "",
                });
                input.onChange(event.target?.value);
                input.onBlur();
              }}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field name="phone" validate={userUnique("phone", false)}>
          {({ input, meta }) => (
            <MuiPhoneNumber
              ref={inputRef}
              fullWidth
              className={classes.root}
              countryCodeEditable={false}
              error={(meta.error && meta.touched) || !!formError.phoneError}
              helperText={(meta.touched && meta.error) || formError.phoneError}
              preferredCountries={["ua"]}
              defaultCountry="ua"
              label={"Телефон"}
              variant="filled"
              margin="normal"
              {...input}
            />
          )}
        </Field>
      </Grid>
      <Grid item={true} xs={12}>
        <Field name="email" validate={validateEmailField}>
          {({ input, meta }) => (
            <TextField
              className={classes.root}
              error={(meta.error && meta.touched) || !!formError.emailError}
              helperText={(meta.touched && meta.error) || formError.emailError}
              margin="normal"
              fullWidth
              label="Email"
              variant="filled"
              type="email"
              {...input}
              onChange={(event) => {
                setFormError({
                  ...formError,
                  emailError: "",
                });
                input.onChange(event.target?.value);
                input.onBlur();
              }}
            />
          )}
        </Field>
      </Grid>
    </Grid>
  );
};
