import styled from "styled-components";

import { TextField } from "@material-ui/core";
import { Filter } from "../../shared/Style/Style";

export const StyledSearchBar = styled(Filter)`
  justify-content: flex-end;
`;

export const SearchBarInput = styled(TextField)`
  & .MuiInput-root {
    min-width: 300px;
  }
`;
