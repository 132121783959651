import { useEffect } from "react";
import { useApolloClient, useReactiveVar } from "@apollo/client";
import { robotsState } from "./state";
import { Table } from "@material-ui/core";
import { RobotsTableHeader } from "./style";
import { EmptyPageTitle } from "../Awards/style";
import { MainTableHeaderTitle } from "../../shared/Style/Style";
import { ContainerWithNavBar } from "../shared/ContainerWithNavBar";
import { RobotsList } from "./components/RobotsList";
import { CreateRobotModal } from "./components/Modals/CreateRobotModal";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { SuccessSnackbar } from "../../shared/SuccessSnackbar";
import { MobileAppDeleteModal } from "../../MobileAppModal/MobileAppDeleteModal";
import {
    initialDeleteRobot,
    initialNotifications,
    robotsPageTitle,
    robotsTableHeader
} from "./constants";

export const RobotsPage = () => {
    const apolloClient = useApolloClient();
    robotsState.initMobileAppRobots(apolloClient);

    const robotsList = useReactiveVar(robotsState.robotsList);
    const notifications = useReactiveVar(robotsState.notifications);
    const isCreateRobotModalOpen = useReactiveVar(robotsState.isCreateRobotModalOpen);
    const deleteRobot = useReactiveVar(robotsState.deleteRobot);
    const isLoading = useReactiveVar(robotsState.isLoading);

    const fetchRobotsList = async () => await robotsState.getRobots();

    useEffect(() => { 
        fetchRobotsList();
    }, []);

    const createRobotHandler = () => robotsState.setIsCreateRobotModalOpen(true);

    const renderEmptyPage = !isLoading && (
        <EmptyPageTitle>
            У Вас пока нет роботов, нажмите “Добавить”, чтобы создать
        </EmptyPageTitle>
    );
    
    return (
        <ContainerWithNavBar
            header={robotsPageTitle}
            addButton={{
                handler: createRobotHandler,
                isDisabled: false
            }}
        >
            
            <Table>
                <RobotsTableHeader>
                    {robotsTableHeader.map((item) => (
                        <MainTableHeaderTitle key={item}>{item}</MainTableHeaderTitle>
                    ))}
                </RobotsTableHeader>
            </Table>

            {robotsList?.length > 0 ? <RobotsList /> : renderEmptyPage}

            {isCreateRobotModalOpen && <CreateRobotModal refetch={fetchRobotsList} />}

            {deleteRobot.isOpen &&
  		        <MobileAppDeleteModal
  		            open={deleteRobot.isOpen}
  		            close={() => robotsState.setDeleteRobot(initialDeleteRobot)}
  		            item={deleteRobot.robot}
  		            refetch={() => fetchRobotsList()}
  		        />
  		    }

            {notifications?.error && (
                <ErrorMessageSnackbar
                    open={notifications?.error}
                    closeHandler={() => robotsState.setNotifications(initialNotifications)}
                    message={notifications?.message}
                />
            )}
            
            {notifications?.success && (
                <SuccessSnackbar
                    open={notifications?.success}
                    handleClose={() => robotsState.setNotifications(initialNotifications)}
                    message={notifications?.message}
                    snackbarProps={{
                        anchorOrigin: { vertical: "bottom", horizontal: "center" },
                    }}
                />
            )}
        </ContainerWithNavBar>
    );
};
