import React from "react";
import { Box } from "@material-ui/core";
import {
  MarkerListItem,
  Subtitle,
  Title,
  WeightText,
  List,
  WeightTextTitle,
  Container,
  Text,
} from "./PrivatePolicy.style";

export const PrivatePolicyEN = () => (
  <Container>
    <Title>DATA PROTECTION POLICY</Title>

    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        This Data Protection Policy (hereinafter referred to as the{" "}
        <strong>“Policy”</strong>) is a legally binding document and is part of
        our user agreement, representing the rules for the collection and use of
        your personal data by the ArBook interactive system, which includes such
        products as ArBook, VR_Lab, AR_Teacher and AR_Book Market; all
        above-mentioned products are part of the interactive system and use this
        Policy
      </Text>
    </Box>

    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        <strong> Limited liability company “AR BOOK UA”</strong>, EDRPOU:
        44973818, in the person of the director Holovakhina Olga Mykolayivna,
        acting in accordance with the Charter, has developed this Policy on the
        following because it takes a responsible approach to the issue of
        confidentiality of information of its users and visitors of the ArBook
        Platform (hereinafter – <strong>“Web-site”, “Platform”, “Site”</strong>
        ).
      </Text>
      <Box mt={3} style={{ textIndent: "20px" }}>
        <Text>
          Definitions in this Data Protection Policy have the same meanings as
          the ones provided to them in the User Agreement.
        </Text>
      </Box>
      <Box mt={3} style={{ textIndent: "20px" }}>
        <Text>
          This Data Protection Policy describes what you can expect from ArBook
          interactive system in relation to your personal data when we act as a
          data controller. This document will provide answers to the following
          questions:
        </Text>
      </Box>

      <Box mt={3}>
        <List>
          <MarkerListItem>
            What is personal information and its processing?
          </MarkerListItem>
          <MarkerListItem>What information do we collect?</MarkerListItem>
          <MarkerListItem>Grounds for data use.</MarkerListItem>
          <MarkerListItem>How long do we keep data?</MarkerListItem>
          <MarkerListItem>Who do we share data with?</MarkerListItem>
          <MarkerListItem>How do we protect personal data?</MarkerListItem>
          <MarkerListItem>Security of online payments.</MarkerListItem>
          <MarkerListItem>Cookies.</MarkerListItem>
          <MarkerListItem>
            The User's rights in accordance with the GDPR
          </MarkerListItem>
          <MarkerListItem>Requests and deadline for answers.</MarkerListItem>
          <MarkerListItem>Liability limitations.</MarkerListItem>
          <MarkerListItem>
            Notification on data protection violation.
          </MarkerListItem>
          <MarkerListItem>
            Changes to this Data Protection Policy.
          </MarkerListItem>
          <MarkerListItem>Information on how to contact us.</MarkerListItem>
        </List>
      </Box>

      <Title>What is personal information and its processing?</Title>

      <Box mt={3} style={{ textIndent: "20px" }}>
        <Text>
          <strong>Personal information </strong> (or{" "}
          <strong>personal data</strong>) is any information about the User
          that, on its own or in combination with other pieces of information,
          allows the User to be identified as an individual. This may include
          first and last name, login, company name, e-mail address and member
          ID, data on location, IP address of the User's device, etc., this
          concept is not limited to this list of examples.
        </Text>
      </Box>

      <Box mt={3} style={{ textIndent: "20px" }}>
        <Text>
          Information <strong>processing</strong> means any actions (operations)
          with the User's personal data, for example, collection, recording,
          systematization, accumulation, storage, clarification (updating,
          changing), extraction, use, transfer (distribution, provision,
          access), depersonalization, blocking, deletion, destruction of
          personal data, which are carried out either with the use of automation
          tools or without such use.
        </Text>
      </Box>
    </Box>

    <Title>What information do we collect?</Title>

    <Box mt={3} style={{ textIndent: "20px" }}>
      <Subtitle>Information from User accounts.</Subtitle>
      <Text>
        {" "}
        We collect, process and store data that the User voluntarily provides to
        us at the end of registration for using any Product of the ArBook
        interactive system.
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        Such data, which is collected and processed by the Platform, include:
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        Full name and patronymic of the user, date of birth, passwords, phone
        number, photos or other images uploaded to your personal profile, data
        about your location (city, region, country), place of work and position,
        IP address of the user's device is captured for security and to learn
        roughly where you are in the world to personalize your experience. The
        email address of the parents of the Ar_book product user who is under 14
        years old.
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        The platform also processes information about statistics of your
        progress, the amount of paid content and payment history, in products
        containing paid content, as well as your data, which we can receive from
        the social networks in which you are registered, provided that you
        register on our platform using certain social networks, etc.
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        The platform, and in particular the AR_Book product, uses the user's
        profile camera, uses the Face tracking facial recognition system, which,
        with the user's consent, accesses the front camera on the device and
        captures the position, location and movements of the user's face. The
        application is prohibited from using the front-facing camera on the
        device without the user's consent. If so desired, the user can disable
        this function in the settings. The Face tracking facial recognition
        system does not record the received data and does not store it, it
        functions exclusively in real time mode to help the user while using the
        AR Book application. At the same time, the application receives the data
        necessary for its proper functioning, as well as receives the
        accompanying additional information that comes as a result of using the
        front camera on the device. The facial recognition function uses special
        algorithms on the user's device to process information from sensors to
        determine the position of the user's face in physical space.
      </Text>
    </Box>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Text>
        The major part of information that we collect is received directly from
        you. Sometimes we may collect personal data about you from other
        sources, such as publicly available materials or trusted third parties,
        such as our marketing and research partners. We use this information to
        supplement the personal data we already have about you, to better
        inform, personalize and improve our Application, as well as to verify
        the personal data you have provided.
      </Text>
    </Box>
    <Box mt={2} style={{ textIndent: "20px" }}>
      <Text>
        Some of this information is collected using cookies and similar tracking
        technologies, which are described in detail in the AR_BOOK Cookies
        Policy.
      </Text>
    </Box>
    <Box mt={2} style={{ textIndent: "20px" }}>
      <Text>
        The type of information we collect will depend on your interaction with
        the Platform.
      </Text>
    </Box>
    <Box mt={2} style={{ textIndent: "20px" }}>
      <Text>
        With the User's permission, the platform also gains access to the
        microphone of the device, from which the user directly uses the platform
        product, and the audio files on that device.
      </Text>

      <Text>
        The Platform, in accordance with GOOGLE's policy, may request access to 
        the User's Google Drive for productivity and learning purposes, using only
        limited areas to process Drive files (or their methods or extensions) 
        through the user interface. After the User gives permission to use data from Drive,
        the Platform gets access to his files on Google Drive. We use the additional 
        information obtained in this way to manage, maintain, improve and provide 
        you with the Platform's features to the fullest extent. This function will 
        allow the Platform to improve interaction with the User.
      </Text>

      <Text>
        To access Google Drive, the Platform requests permission from the User to access
         files on Drive. The platform gets the opportunity to integrate the User's 
         Google Drive into the work for the entire duration of such permission. 
         In turn, the User can restrict the Platform from integrating his Drive at any time.
      </Text>
    </Box>
    <Box mt={2} style={{ textIndent: "20px" }}>
      <Subtitle>Information about the corporate clients.</Subtitle>
      <Text>
        At the same time, we may collect and process the payment details of our
        corporate partners. These data include the name of the corporate
        customer or private entrepreneur, his registration data, including the
        registration number, date of registration, legal and postal addresses,
        invoice data on the number and list of paid services, current accounts
        and other bank data of the partner, contact telephone numbers, as well
        as data on the representative or other authorized person of such client.
      </Text>
    </Box>
    <Title>Grounds for data use.</Title>

    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        We use data from the User's account, as he voluntarily shares it with
        us, what makes us believe that you do not object to the collection and
        use of such information. By your explicit positive action, you give us
        your consent to the processing of this data.
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        The ArBook interactive system collects information for the following
        purposes:
      </Text>
    </Box>

    <Box mt={3}>
      <List style={{ paddingLeft: "10px" }}>
        <MarkerListItem>to identify of you as a Site User;</MarkerListItem>
        <MarkerListItem>
          to confirm the User's access to the account;
        </MarkerListItem>
        <MarkerListItem>
          to determine preferences, interests and goals, which will further help
          improve your user experience when using Platform products;
        </MarkerListItem>
        <MarkerListItem>
          to distribute marketing information related to accompanying services,
          which also includes sending e-mails;
        </MarkerListItem>
        <MarkerListItem>
          to collect and analyze statistics on the use of platform products.
        </MarkerListItem>
      </List>
    </Box>
    <Box mt={5} style={{ textIndent: "40px" }}>
      <Text>
        The basis for data processing of our employees/contractors may be as
        follows:
      </Text>
    </Box>

    <Box mt={3}>
      <List style={{ paddingLeft: "10px" }}>
        <MarkerListItem>
          the need to comply with the law, for example, with the labor
          legislation or AML when making payments;
        </MarkerListItem>
        <MarkerListItem>personal consent;</MarkerListItem>
        <MarkerListItem>
          our company possesses a legitimate interest in such processing;
        </MarkerListItem>
        <MarkerListItem>
          the need to fulfill an already concluded contract or the need to
          conclude a contract at the request of the owner of personal data
          himself.
        </MarkerListItem>
      </List>
    </Box>

    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        We also use the data of our corporate partners in accordance with the
        rules for providing specific Services to fulfill the relevant
        contractual obligations. Such information is necessary for preparation
        and invoicing.
      </Text>
    </Box>

    <Title>How long do we keep data?</Title>

    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        We will not retain User data for longer than it is necessary to fulfill
        the purpose for which it is processed or to comply with established
        legal requirements.
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        Because we are required to administer your account, information is
        stored for at least as long as your account is active on any of the
        ArBook, VR_Lab, AR_Teacher, and AR_Book Market products, as it is
        required by the processing purpose.
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        We also store information about our employees/contractors for the entire
        period of their employment with us, after which such information is
        destroyed, except for those cases when it is impossible to delete it
        (for example, data from tax documents, employment information, etc.).
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        As for our corporate customers (partners), we retain their data until
        the termination of our legal relationship with them, unless otherwise
        provided by the laws of a specific country, or until they wish to delete
        it, provided that this is possible.
      </Text>
    </Box>

    <Title>Who do we share data with?</Title>

    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        We hope that you understand that our Platform does not function
        independently and there is a circle of individuals allowed to process
        personal data from the side of the ArBook interactive system.
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        To achieve the goals of this document, only those of our
        contractors/consultants and employees who are assigned such an
        obligation in accordance with their official, labor or contractual
        obligations are allowed to process personal data.
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        Access to the data of corporate partners is only available to our
        employees who interact directly with them.
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        At the same time, the directors of the company as well as employees of
        the HR and financial departments have access to data about
        employees/contractors according to their functional obligations.
      </Text>
    </Box>

    <Title>How do we protect personal data?</Title>

    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        We provide both organizational and technical data protection measures
        against illegal or unauthorized processing as well as against any
        accidental loss or damage. These security measures are constantly
        changing to meet the latest technological advances.
      </Text>
    </Box>

    <Title>Organizational measures to protect personal data.</Title>

    <Box mt={3}>
      <List style={{ paddingLeft: "10px" }}>
        <MarkerListItem>
          Initiated the conduction of systematic training of personnel on
          working with users' personal data.
        </MarkerListItem>
        <MarkerListItem>
          Introduced internal documentation with rules on how to work with
          confidential information.
        </MarkerListItem>
        <MarkerListItem>
          We sign Data Processing Agreements with contractors, in which we
          specify in detail the rights and obligations of contractors regarding
          the protection of personal data.
        </MarkerListItem>
        <MarkerListItem>
          We additionally conclude non-disclosure agreements with employees and
          contractors/consultants.
        </MarkerListItem>
        <MarkerListItem>
          Areas of responsibility were demarcated according to the functional
          responsibilities of employees in order to exclude cases of important
          data being held by many employees at the same time.
        </MarkerListItem>
      </List>
    </Box>

    <Title>Technical measures to protect personal data.</Title>

    <Box mt={3}>
      <List style={{ paddingLeft: "10px" }}>
        <MarkerListItem>
          We use certificates to ensure the security of User’s data and
          encryption of the communication.
        </MarkerListItem>
        <MarkerListItem>
          Implemented password hashing for added security to help you avoid
          unauthorized use of your account.
        </MarkerListItem>
        <MarkerListItem>
          All accesses to databases are made only by trusted IPs.
        </MarkerListItem>
      </List>
    </Box>

    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        We do not disclose the personalized data of some visitors of the Site to
        other visitors of the Site. We never publish personalized information in
        the public domain and do not transfer it to third parties. The only
        exception is situations where the provision of such information to
        authorized state bodies is provided for by current legislation. We
        publish and distribute only those reports that were compiled on the
        basis of collected anonymous data. At the same time, the reports do not
        contain information by which it would be possible to identify
        personalized data of service users. We also use anonymous data for our
        internal analysis to improve our products and services.
      </Text>
    </Box>

    <Title>Security of online payments.</Title>

    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        For full access to all functions and content on such products of the
        interactive system as AR_Teacher and AR_Book Market, there is a system
        of online payments, which are regulated by our payment policy.
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        Personal information provided by the User (name, address, phone number,
        e-mail, credit card number) is confidential and not subject to
        disclosure. Your bank card data is transmitted only in an encrypted form
        and is not stored on our Web server.
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        We recommend the User of the above-mentioned products to check whether
        your browser is secure enough to make online payments on a special page
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        All operations with payment cards take place in accordance with the
        requirements of VISA International, MasterCard and other payment
        systems. When transmitting information, special security technologies
        for online card payments are used; data processing is carried out on a
        secure high-tech server of the processing company.
      </Text>
    </Box>

    <Title>Cookies.</Title>

    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text>
        Like many other websites, all of our products which are based on the
        ArBook Platform use cookies. These are small data files that act as
        technical tracking identifiers for statistical, marketing, analytical
        and other purposes. You can change your cookie settings at any time
        using your browser settings. More detailed information can be found in
        our <strong>Cookie Policy.</strong>
      </Text>
    </Box>

    <Title>The User's rights in accordance with the GDPR.</Title>

    <Box mt={3}>
      <List style={{ paddingLeft: "10px" }}>
        <MarkerListItem>The right to access one's own data.</MarkerListItem>
        <MarkerListItem>The right to rectification.</MarkerListItem>
        <MarkerListItem>
          The right to erasure ("the right to be forgotten").
        </MarkerListItem>
        <MarkerListItem>The right to restriction of processing.</MarkerListItem>
        <MarkerListItem>The right to withdraw consent.</MarkerListItem>
        <MarkerListItem>The right to object to processing.</MarkerListItem>
        <MarkerListItem>The right to data portability.</MarkerListItem>
      </List>
    </Box>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Subtitle>The right to access your own data.</Subtitle>
      <Text>
        The user has the right to know how his personal information is processed
        by the ArBook interactive system and to receive details of such
        processing.
      </Text>
    </Box>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Text>
        This means that you have the right to ask us to provide you with
        confirmation of whether your personal data is being processed by the
        company, as well as to receive full information about the conditions of
        such processing, i.e. about the purposes of collection and processing,
        the period of processing, third parties who have access to the
        information.
      </Text>
    </Box>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Subtitle>Right to rectification.</Subtitle>
      <Text>
        On our website, you can also fill in and change this information
        yourself in your personal user profile.
      </Text>
    </Box>
    <Box mt={2} style={{ textIndent: "20px" }}>
      <Text>
        At the same time, keep in mind that your data may not always be changed.
        One of the examples is that your personal information has already been
        used in the performance of relevant contracts and/or it is reflected in
        tax documents drawn up in accordance with the Tax Law.
      </Text>
    </Box>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Subtitle>
        The right to delete your data ("the right to be forgotten").
      </Subtitle>
      <Text>
        The User can ask the Platform to delete his personal data, provided that
        their processing is unlawful, or if the processing of the data is no
        longer necessary to achieve the purposes for which they were collected
        or there are other grounds stipulated by the GDPR rules.
      </Text>
    </Box>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Subtitle>The right to restriction of processing.</Subtitle>
      <Text>
        The user has the right to request the restriction of the processing of
        his personal data in the cases provided for by the current legislation,
        namely the provisions provided for in Article 18 of the GDPR.
      </Text>
    </Box>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Subtitle>The right to withdraw your consent.</Subtitle>
      <Text>
        If we process your personal data on the basis of your consent, you have
        the right to contact us to withdraw such consent to the relevant
        processing. To do this, it is enough to write to us at the following
        address: support@arbook.info
      </Text>
    </Box>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Subtitle>The right to object to processing.</Subtitle>
      <Text>
        This means that the User has the right to object to the processing of
        their data for use in marketing, socio-demographic profiling and other
        activities provided for in Article 21 GDPR.
      </Text>
    </Box>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Subtitle>The right to data portability.</Subtitle>
      <Text>
        You have the right to receive your personal data that you provided to us
        as a controller in a structured, universal and machine-readable format,
        you have the right to transfer the specified data to another controller,
        provided that this is technically possible and there are no restrictions
        specified in Article 20 of the GDPR.
      </Text>
    </Box>

    <Title>Requests and the deadline for answers.</Title>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Text>
        Our Platform will make every reasonable effort to process your requests
        regarding the rights described above in a timely manner, but you should
        expect a response from us no later than one month from the date you
        submit such a request. Our response time may be extended by an
        additional two months if there are many user requests on the ArBook
        system. The platform will inform you about such an extension within one
        month from the moment of receiving the request along with the reasons
        for the delay.
      </Text>
    </Box>

    <Title>Liability limitations.</Title>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Text>
        We do our best to comply with the provisions of this Data Protection
        Policy, however, we cannot guarantee the preservation of information in
        the event of factors beyond our control that result in disclosure of
        information. The Site and all information posted on it are presented on
        an "as is" basis without any guarantees. We are not responsible for
        adverse consequences, as well as for any damages caused as a result of
        restricting access to the site or as a result of visiting it and using
        the information posted on it.
      </Text>
    </Box>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Text>
        At the same time, our Site may contain links to other online resources
        that are not related to our company and belong to third parties. We are
        not responsible for the accuracy, completeness and reliability of
        information posted on third-party sites, and we do not undertake any
        obligations to maintain the confidentiality of information left by you
        on such sites. In this case, please read the terms of use and privacy
        policy of the specified online resources.{" "}
      </Text>
    </Box>

    <Title>Notification on data protection violation.</Title>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Text>
        In the event of personal data protection violation or a high risk of
        violation of your rights as a data subject, we will immediately notify
        you about it as well as the competent data protection supervisory
        authority. We also make efforts to reduce any of such risks.
      </Text>
    </Box>

    <Title>Changes to this Data Protection Policy.</Title>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Text>
        Any changes to the Data Protection Policy will be published on the Site
        with an indication of the date.
      </Text>
    </Box>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Text>
        If you continue to use our Platform product after making changes to this
        document, we will consider it as acceptance of such changes.
      </Text>
    </Box>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Text>
        If the User does not agree with the changes, he should stop using our
        Platform. If the changes are so significant that we need to ask for your
        consent again, we will do so.
      </Text>
    </Box>

    <Title>Information on how to contact us.</Title>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Text>
        We hope this Data Protection Policy has helped you understand how your
        personal information is handled by the ArBook, VR_Lab, AR_Teacher and
        AR_Book Market products, each of which is part of the ArBook interactive
        system.
      </Text>
    </Box>

    <Box mt={2} style={{ textIndent: "20px" }}>
      <Text>
        If you have any questions, please write to us at
        <a href="support@arbook.info"> support@arbook.info.</a>.
      </Text>
    </Box>
  </Container>
);
