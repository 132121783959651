import styled from "styled-components"
import { Box } from "@material-ui/core";
import { MainTableCell, MainTableRow } from "../shared/Style/Style";

export const OnboardingContainer = styled(Box)`
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 40px;
  }
`;

export const Title = styled(Box)`
  font-weight: 700;
  font-size: 35px;
  line-height: 41px;
  text-transform: uppercase;
  text-align: center;
  color: #C4C4C4;
`

export const ButtonInner = styled(Box)`
  width: 100%;
  margin: 40px 0 0 0;
  display: flex;
  justify-content: flex-end;
`

export const List = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`

export const NoList = styled(Box)`
  margin-top: 40px;
  color: #9b9a9a;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`

export const OnboardingTableHeaderRow = styled(MainTableRow)`
  grid-template-columns: 0.7fr 1.2fr 1.2fr 0.8fr;
  grid-gap: 14px;
  cursor: pointer;
  padding: 14px 50px;
  margin-top: 50px;
`;

export const OnboardingTableRow = styled(MainTableRow)`
  height: 116px;
  grid-template-columns: 0.1fr 0.5fr 1fr 1fr 0.5fr 55px;
  grid-gap: 14px;
`;

export const OnboardingTableCell = styled(MainTableCell)`
  font-size: 14px;
  line-height: 1.2;
  
  &.published {
    color: #FFB61D;
  }

  &.unpublished {
    color: #E85050;
  }
  p {
    margin: 0;
  }
`;

export const OnboardingTableHeadCell = styled(OnboardingTableCell)`
  font-weight: bold;
`;

export const TextEditorWrap = styled.div`
  .quill{}

  .ql-toolbar.ql-snow {
    border-radius: 6px 6px 0 0;
    border: none;
    border-bottom: 1px solid #ccc;
    background: #f6f5f5;
  }

  .ql-container.ql-snow {
    border-radius: 0 0 6px 6px;
    border: 1px solid #f6f5f5;
    background: #f6f5f5;
  }

  &.error {
    .ql-container.ql-snow{
      border: 1px solid #f44336;
    }
  }

  .ql-editor {
    height: 100px;
  }
`

export const ErrorText = styled.div`
  height: 15px;
  color: #f44336;
  margin-bottom: 8px;
  font-size: 13.5px;
`