import React from "react";
import { Box, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { CloseIconButton } from "../shared/Buttons/CloseIconButton";
import { useQuery } from "@apollo/client";
import {
  GetUsersBySmartNotificationAnswerDocument,
  GetUsersBySmartNotificationAnswerQuery,
} from "../../generated/graphql";
import { SmallUserAvatarStyle } from "../shared/Style/Style";
import { DEFAULT_AVATAR_URL } from "../shared/constants";

interface IMobileAppStatisticUsersListModal {
  open: boolean;
  smartNotificationId: string;
  ratting: number;

  close(): void;
}

export const MobileAppStatisticUsersListModal = ({
  open,
  close,
  ratting,
  smartNotificationId,
}: IMobileAppStatisticUsersListModal) => {

  const {data, loading, error} =
    useQuery<GetUsersBySmartNotificationAnswerQuery>(
      GetUsersBySmartNotificationAnswerDocument,
      {
        variables: {
          smartNotificationId,
          ratting,
        },
      }
    );

  const users = data?.getUsersBySmartNotificationAnswer?.users;

  const closeHandler = () => {
    close();
  };

  return (
    <Dialog open={open}>
      <Box display="flex" justifyContent="space-between">
        <DialogTitle>Пользователи</DialogTitle>
        <CloseIconButton close={closeHandler}/>
      </Box>
      <DialogContent style={{minWidth: "400px", padding: "30px"}}>
        {users?.length &&
        users.map( (user) => (
          <Box mt={2} display="flex" key={user.id}>
            <SmallUserAvatarStyle
              src={user.photo ? user.photo : DEFAULT_AVATAR_URL}
            />
            <Box display="flex" alignItems="center" ml={2} fontSize="20px">
              {user.firstName} {user.lastName}
            </Box>
          </Box>
        ) )}
      </DialogContent>
    </Dialog>
  );
};

