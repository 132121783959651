import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from "@apollo/client";

import {
  GetAchieveDocument,
  GetAchieveQuery,
  UpdateAchieveDocument,
  UpdateAchieveMutation,
  UpdateAchieveMutationVariables
} from '../../generated/graphql';
import { Achievement } from "./Achievement";
import { useRequestError } from "../../hooks";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";

export const UpdateAchievement = () => {
  const { achievementId } = useParams<{ achievementId: string }>();
  const { goBack } = useHistory();

  const {
    data: getAchieveData,
    loading: getAchieveLoading,
    error: getAchieveError,
  } = useQuery<GetAchieveQuery>(GetAchieveDocument, {
    variables: { id: achievementId },
    fetchPolicy: "network-only",
  });

  const [updateAchieve, {
    loading: updateAchieveLoading,
    error: updateAchieveError,
  }] = useMutation<UpdateAchieveMutation>(UpdateAchieveDocument);

  const disabledOnLoading = getAchieveLoading || updateAchieveLoading;
  const error = getAchieveError || updateAchieveError;
  const { requestError, setRequestError } = useRequestError(error);
  
  const errorMessage = (getAchieveLoading && "При загрузке списка наград произошла ошибка")
    || (updateAchieveLoading && "При обновлении награды произошла ошибка")

  const handleAchieveUpdate = async (variables: UpdateAchieveMutationVariables) => {
    try {
      await updateAchieve({ variables });
    } catch (e) {
      console.error(e);
    } finally {
      goBack();
    }
  }

  return (
    <>
      <Achievement
        title="Редактирование награды"
        achievementData={getAchieveData?.getAchieve}
        loading={disabledOnLoading}
        updateAchievement={handleAchieveUpdate}
      />

      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
    </>
  );
};
