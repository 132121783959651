import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ProjectInfoRow } from "./ProjectInfoRow";
import {
  DeleteProjectDocument,
  GetProjectsDocument,
  GetProjectsQuery,
  DeleteProjectMutation,
} from "../../../generated/graphql";
import { Box, Container } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ProjectsFilter } from "./ProjectFilter";
import { AddProjectModal } from "./AddProjectModal";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { CustomTablePagination } from "../../shared/CustomTablePagination";
import { ButtonPlus, LoadingBox } from "../../shared/Style/Style";
import { headerTableCreatedProjects } from "../../shared/Constants/HeaderTable";
import {
  Header,
  Table,
  TableHeader,
  TableHeaderTitle,
  TableRows,
  Title,
} from "./Projects.style";

export const Projects = () => {
  const refTable = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [addProjectModalActive, setAddProjectModalActive] =
    useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<string[] | []>([]);
  const [requestError, setRequestError] = useState<boolean>(false);

  const {
    data: projectsData,
    loading: projectDataLoading,
    error: projectDataError,
  } = useQuery<GetProjectsQuery>(GetProjectsDocument, {
    variables: {
      getProjectsInput: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        statuses: activeFilter,
      },
    },
    nextFetchPolicy: "cache-first",
    fetchPolicy: "network-only",
  });

  const [
    mutationDeleteProject,
    { loading: deleteProjectLoading, error: deleteProjectError },
  ] = useMutation<DeleteProjectMutation>(DeleteProjectDocument);

  const deleteProject = (projectId: string): void => {
    mutationDeleteProject({
      variables: {
        projectId: projectId,
      },
      refetchQueries: [
        {
          query: GetProjectsDocument,
          variables: {
            getProjectsInput: {
              limit: 10,
              skip: 0,
              statuses: activeFilter,
            },
          },
        },
      ],
    });
  };

  useEffect(() => {
    if (deleteProjectError || projectDataError) {
      setRequestError(true);
    }
  }, [deleteProjectError, projectDataError]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const pagination = (
    <CustomTablePagination
      rowsPerPageOptions={[10, 20]}
      count={projectsData?.getProjects.total || 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );

  let content: JSX.Element;

  if (projectDataLoading || deleteProjectLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (projectsData) {
    const projects = projectsData.getProjects.projects;

    content = (
      <>
        <Box mt={2}>{pagination}</Box>

        <Table>
          <TableHeader>
            {headerTableCreatedProjects.map((el: any, i: number) => (
              <TableHeaderTitle key={i}>{el.title}</TableHeaderTitle>
            ))}
          </TableHeader>

          <TableRows ref={refTable}>
            {projects.map((project, i) => (
              <ProjectInfoRow
                key={i}
                project={project}
                deleteProject={deleteProject}
              />
            ))}
          </TableRows>
        </Table>

        <Box mt={1}>{pagination}</Box>
      </>
    );
  }

  return (
    <div>
      <Container maxWidth="lg">
        <Header>
          <Title>Проекты</Title>
          <Box display="flex" position="absolute" right={0}>
            <ButtonPlus
              width={250}
              variant="contained"
              onClick={() => setAddProjectModalActive(true)}
            >
              Добавить проект
            </ButtonPlus>
          </Box>
        </Header>

        <ProjectsFilter
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />

        {content}
      </Container>

      <AddProjectModal
        open={addProjectModalActive}
        handleClose={() => setAddProjectModalActive(false)}
        limit={rowsPerPage}
        skip={page * rowsPerPage}
        statuses={activeFilter}
      />

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={
          (deleteProjectError && "Ошибка удаления проекта") ||
          (projectDataError && "Ошибка получения проектов")
        }
      />
    </div>
  );
};
