import { navLink, name } from "./constants";
import { ContainerWithNavBar } from "../shared/ContainerWithNavBar";
import { Box, Paper } from "@material-ui/core";
import { BlockTitle } from "../shared/BlockTitle";
import { VideoSidebar } from "./VideoSidebar";
import { VideoInputs } from "./VideoInputs";

export const RobotsHistory = () => {
  return (
    <ContainerWithNavBar header={name}>
      <Paper elevation={3}>
        <Box p="20px 32px 32px" mt="40px">
          <BlockTitle>{name}</BlockTitle>
          <Box width="100%" display="flex" gridGap={20} mt={2}>
            <VideoSidebar />
            <VideoInputs />
          </Box>
        </Box>
      </Paper>
    </ContainerWithNavBar>
  );
};

RobotsHistory.link = navLink;
RobotsHistory.chapterName = name;
