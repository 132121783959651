import { useMutation, useLazyQuery } from "@apollo/client";
import { DialogContent, Box, DialogTitle, CircularProgress, FormControlLabel, ListItemText, Checkbox, Dialog, List, ListItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApplicationMainAvailableForEnum, GetApplicationMainDocument, GetUsersWithoutApplicationMainDocument, GetUsersWithoutApplicationMainQuery, QueryGetUsersWithoutApplicationMainArgs, SetAccessToApplicationMainDocument, SetAccessToApplicationMainMutation, User } from "../../../generated/graphql";
import { ModalInput } from "../../SalaryModal/SalaryModal.style";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { DEFAULT_AVATAR_URL } from "../../shared/constants";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { ButtonsWrapper, CancelButton, ButtonCustom, LoadingBox, SmallUserAvatarStyle } from "../../shared/Style/Style";
import { ClientLabel, ClientAvatar, ClientsList } from "./MobileAppAccessModal.style";

interface IMobileAppAccessModalProps {
  open: boolean;
  close(): void;
  initialParticipants: string[];
}

export const MobileAppAccessModal = ({ open, close, initialParticipants }: IMobileAppAccessModalProps) => {
  const { pageId } = useParams<{pageId: string}>();

  const [participants, setParticipants] = useState<string[]>([]);

  const [requestError, setRequestError] = useState(false);

  const [getClients, { data, loading, error }] = useLazyQuery<GetUsersWithoutApplicationMainQuery>(
    GetUsersWithoutApplicationMainDocument, {
      variables: {
        applicationMainId: pageId,
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const [setAccess, {
    data: setAccessData,
    loading: setAccessLoading,
    error: setAccessError,
  }] = useMutation<SetAccessToApplicationMainMutation>(
    SetAccessToApplicationMainDocument, {
      refetchQueries: [{ query: GetApplicationMainDocument, variables: { id: pageId } }]
    }
  );

  //#region effects

  useEffect(() => {
    getClients()
  }, [])

  useEffect(() => {
    if (setAccessError) {
      setRequestError(true);
    }
  }, [setAccessError]);

  useEffect(() => {
    if (setAccessData) {
      setRequestError(false);
      getClients();
      closeHandler();
    }
  }, [setAccessData]);

  //#endregion

  const changeParticipantStatusHandler = (clientId: string): void => {
    if (participants.includes(clientId)) {
      setParticipants(participants.filter(participant => participant !== clientId));
      return;
    }

    setParticipants([...participants, clientId])
  }

  const closeHandler = (): void => {
    setParticipants([]);
    close();
  }

  let clientsList;

  if (loading) {
    clientsList = (
      <LoadingBox>
        <CircularProgress color='inherit' />
      </LoadingBox>
    )
  }

  if (error) {
    clientsList = <ErrorSnackbar error={error} />
  }

  if (data) {
    const clients = data.getUsersWithoutApplicationMain.users;

    clientsList = (
      <>
        {clients.length
          ? (
            <ClientsList
              disablePadding
            >
              {clients.map(({ id, photo, firstName, lastName }) => (
                <ListItem
                  key={id}
                >
                  <ClientLabel
                    value="end"
                    control={
                      <Checkbox
                        disabled={setAccessLoading}
                        onChange={() => changeParticipantStatusHandler(id)}
                        checked={
                          participants.some(participant => participant === id)
                        }
                        color="default"
                      />
                    }
                    label={
                      <Box
                        minWidth='100%'
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Box
                          mr={3}
                        >
                          <ClientAvatar prim={photo || DEFAULT_AVATAR_URL} />
                        </Box>

                        <ListItemText primary={`${lastName} ${firstName}`} />
                      </Box>
                    }
                  />
                </ListItem>
              ))}
            </ClientsList>
          )
          : <p>Нет доступных пользователей</p>
        }
      </>
    )
  }

  return (
    <Dialog
      open={open}
    >
      <DialogContent
        style={{ minWidth: '400px' }}
      >
        <CloseIconButton
          close={closeHandler}
        />

        <Box
          textAlign='center'
          mb={2}
        >
          <DialogTitle>Отметьте участников</DialogTitle>
        </Box>


        <Box>
          {clientsList}
        </Box>

        <ButtonsWrapper>
          <CancelButton
            onClick={closeHandler}
            disabled={setAccessLoading}
          >
            Отмена
          </CancelButton>

          <ButtonCustom
            onClick={() => setAccess({ variables: {
              applicationMainData: {
                applicationMainId: pageId,
                availableFor: ApplicationMainAvailableForEnum.Clients,
                participantsIds: [...initialParticipants, ...participants]
              }
            }})}
            disabled={setAccessLoading}
          >
            Добавить
          </ButtonCustom>
        </ButtonsWrapper>
      </DialogContent>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={`При добавлении пользователей произошла ошибка`}
      />
    </Dialog>
  )
}