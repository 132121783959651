import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import {
  CreateKnowledgeBaseBlockDocument,
  CreateKnowledgeBaseBlockMutation,
  GetKnowledgeBaseBlocksByThemeIdDocument,
  KnowledgeBaseBlockTypeEnum
} from "../../../generated/graphql";

import { HeaderIcon, ListIcon, PhotoIcon, QuestionIcon, SubtitleIcon, TextIcon, VideoIcon, EditorIcon, } from "./AddBlockModalIcons";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";

import { Box, Dialog, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";

import { AddBlockModalHeader } from "./AddBlockModal.style";

interface IBlockFields {
  name: string;
  type: KnowledgeBaseBlockTypeEnum;
  icon: JSX.Element;
}

const blocks: IBlockFields[] = [
  {
    name: 'Заголовок',
    type: KnowledgeBaseBlockTypeEnum.Header,
    icon: <HeaderIcon />
  },
  {
    name: 'Подзаголовок',
    type: KnowledgeBaseBlockTypeEnum.Subtitle,
    icon: <SubtitleIcon />
  },
  {
    name: 'Основной текст',
    type: KnowledgeBaseBlockTypeEnum.Text,
    icon: <TextIcon />
  },
  {
    name: 'Список',
    type: KnowledgeBaseBlockTypeEnum.List,
    icon: <ListIcon />
  },
  {
    name: 'Изображение',
    type: KnowledgeBaseBlockTypeEnum.Photo,
    icon: <PhotoIcon />
  },
  {
    name: 'Видео',
    type: KnowledgeBaseBlockTypeEnum.Video,
    icon: <VideoIcon />
  },
  {
    name: 'Вопросы',
    type: KnowledgeBaseBlockTypeEnum.Question,
    icon: <QuestionIcon />
  },
  {
    name: "Шаги",
    type: KnowledgeBaseBlockTypeEnum.MultiList,
    icon: <TextIcon />
  },
  {
    name: "Формула",
    type: KnowledgeBaseBlockTypeEnum.Formula,
    icon: <TextIcon />
  },
  {
    name: "Редактор",
    type: KnowledgeBaseBlockTypeEnum.Editor,
    icon: <EditorIcon />
  }
]

interface IAddBlockModalProps {
  open: boolean;
  close(): void;
  themeId: string;
}

export const AddBlockModal = ({ open, close, themeId }: IAddBlockModalProps) => {
  const [requestError, setRequestError] = useState(false);

  const closeHandler = () => {
    setRequestError(false);
    close();
  }

  const [createBlock, {
    data: createBlockData,
    loading: createBlockLoading
  }] = useMutation<CreateKnowledgeBaseBlockMutation>(CreateKnowledgeBaseBlockDocument, {
    refetchQueries: [{ query: GetKnowledgeBaseBlocksByThemeIdDocument, variables: { themeId } }]
  });

  const createBlockHandler = async (type: KnowledgeBaseBlockTypeEnum) => {
    if (!type || createBlockLoading) {
      return;
    }

    try {
      await createBlock({ variables: { type, themeId, blockTypeData: {} } })
    } catch (e) {
      setRequestError(true);
    }
  }

  useEffect(() => {
    if (createBlockData) {
      closeHandler();
    }
  }, [createBlockData])

  return (
    <Dialog
      open={open}
    >
      <AddBlockModalHeader>
        Выберите тип блока

        <Box
          position='relative'
          padding='22px'
        >
          <CloseIconButton
            close={closeHandler}
            main_color='black'
          />
        </Box>
      </AddBlockModalHeader>

      <List
        disablePadding
        style={{
          width: '370px'
        }}
      >
        {blocks.map(({ name, type, icon }) => (
          <ListItem
            button
            key={type}
            onClick={() => createBlockHandler(type)}
          >
            <ListItemAvatar>
              <Box
                textAlign='center'
              >
                {icon}
              </Box>
            </ListItemAvatar>

            <ListItemText>
              {name}
            </ListItemText>
          </ListItem>
        ))}
      </List>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message="При создании блока произошла ошибка"
      />
    </Dialog>
  )
}