import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import {
  GetCompletionPctReportByEpicDocument,
  GetCompletionPctReportByEpicQuery,
} from "../../../../generated/graphql";
import { IProjectReportModalContentProps } from "../ProjectReportModal";

import {
  Table,
  TableRow,
  TableBody,
  CircularProgress
} from "@material-ui/core";
import {
  CustomTableHeaderCell,
  CustomTableCell,
  ColorizedTableCell,
  CustomTableRow,
  CustomTableHead
} from "../../../shared/CustomTable/CustomTable.style";

import { LoadingBox } from "../../../shared/Style/Style";
import { CustomTable } from "../../../shared/CustomTable";
import { DropdownArrowButton } from "../../../shared/Buttons/DropdownArrowButton";
import { ErrorMessageSnackbar } from "../../../shared/ErrorMessageSnackbar";

import { choseOrToggleIdHandler } from "../../../shared/Utils/StateOperations/choseOrToggleId";


export const CompletionPercentContent = ({ epics }: IProjectReportModalContentProps) => {
  const headers = [
    'Этап',
    'Задача',
    'Ответственный',
    'Статус',
    'Выполнение этапа',
    'Выполнение проекта',
  ];

  const [chosenEpic, setChosenEpic] = useState('');
  const [requestError, setRequestError] = useState(false);

  const [getTasks, { data, loading, error }] = useLazyQuery<GetCompletionPctReportByEpicQuery>(
    GetCompletionPctReportByEpicDocument,
    { variables: {
       epicKey: chosenEpic
      }
    }
  );

  useEffect(() => {
    if (chosenEpic) {
      getTasks();
    }
  }, [chosenEpic]);

  useEffect(() => {
    if (error) {
      setRequestError(true);
    }
  }, [error]);

  let tasksList: JSX.Element | JSX.Element[];
  let epicPercentComplition: number;
  let projectPercentComplition: number;

  if (loading) {
    tasksList = (
      <CustomTableCell colSpan={6}>
        <LoadingBox>
          <CircularProgress color='inherit'/>
        </LoadingBox>
      </CustomTableCell>
    )
  }

  if (data) {
    const tasks = data.getCompletionPctReportByEpic.tasks
    epicPercentComplition = data.getCompletionPctReportByEpic.epicCompletionPct;
    projectPercentComplition = data.getCompletionPctReportByEpic.projectCompletionPct;

    tasksList =
      tasks.length
        ? tasks.map(task => (
          <TableRow key={task.id}>
            <CustomTableCell />
            {[
              task.name,
              task.assigneeName,
              task.status,
              task.epicPrc,
              task.projectPrc,
            ].map((field, i) => (
              <ColorizedTableCell
                key={`${i} ${field}`}
                number={task.epicPrc}
              >
                {field}
              </ColorizedTableCell>
            ))}
          </TableRow>
        ))
      : (
        <TableRow>
          <CustomTableCell colSpan={6}>У данного этапа отсутствуют задачи</CustomTableCell>
        </TableRow>
      )
  }

  const content = (
    <>
      <Table>
        <CustomTableHead>
          <TableRow>
            {headers.map(header => (
            <CustomTableHeaderCell
              key={header}
              width={(header === 'Этап' || header === 'Задача') ? 250 : 150}
            >
              {header}
            </CustomTableHeaderCell>))}
          </TableRow>
        </CustomTableHead>

        <TableBody>
          {epics.map(epic => (
            <>
              <CustomTableRow
                clickable={true}
                key={epic.epicKey}
                onClick={() => choseOrToggleIdHandler(epic.epicKey, chosenEpic, setChosenEpic)}
              >
                <CustomTableCell>
                  {epic.epicName}

                  <DropdownArrowButton
                    expanded={chosenEpic === epic.epicKey}
                  />
                </CustomTableCell>

                <CustomTableCell colSpan={3} />

                {[
                  epicPercentComplition,
                  projectPercentComplition,
                ].map((field, i) => (
                  <CustomTableCell key={`${i} ${field}`}>
                    {chosenEpic === epic.epicKey && field}
                  </CustomTableCell>
                ))}
              </CustomTableRow>
              {chosenEpic === epic.epicKey && tasksList && (
                tasksList
              )}
            </>
          ))}
        </TableBody>
      </Table>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message='При загрузке задач произошла ошибка'
      />
    </>
  )

  return <CustomTable data={data?.getCompletionPctReportByEpic?.tasks || epics} tableContent={content} />
}