import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  DeleteProjectTaskLabelDocument,
  DeleteProjectTaskLabelMutation,
  GetProjectTaskLabelsByProjectDocument,
  ProjectTaskLabel,
  UpdateProjectTaskLabelDocument,
  UpdateProjectTaskLabelMutation,
} from "../../../generated/graphql";
import { Grid, TextField } from "@material-ui/core";
import { ColorPicker } from "material-ui-color";
import { SaveIconButton } from "../../shared/Buttons/SaveIconButton";
import { EditIconButton } from "../../shared/Buttons/EditIconButton";
import { DeleteIconButton } from "../../shared/Buttons/DeleteIconButton";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import {
  ButtonsContainer,
  ColorPickerContainer,
  ItemContainer,
} from "../KanbanSettings.style";
import { useStyles } from "../../shared/Style/Style";

const initialLabelData: ProjectTaskLabel = {
  color: "",
  id: "",
  name: "",
};

interface ProjectTaskLabelProps {
  label: ProjectTaskLabel;
  projectId: string;
}

export const ProjectTaskLabelItem = ({
  label,
  projectId,
}: ProjectTaskLabelProps) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [labelData, setLabelData] = useState(initialLabelData);
  const [requestError, setRequestError] = useState<boolean>(false);

  const [
    updateLabel,
    {
      data: updateLabelData,
      loading: updateLabelLoading,
      error: updateLabelError,
    },
  ] = useMutation<UpdateProjectTaskLabelMutation>(
    UpdateProjectTaskLabelDocument,
    {
      refetchQueries: [
        {
          query: GetProjectTaskLabelsByProjectDocument,
          variables: {
            projectId,
          },
        },
      ],
    }
  );

  const [
    deleteLabel,
    { loading: deleteLabelLoading, error: deleteLabelError },
  ] = useMutation<DeleteProjectTaskLabelMutation>(
    DeleteProjectTaskLabelDocument,
    {
      refetchQueries: [
        {
          query: GetProjectTaskLabelsByProjectDocument,
          variables: {
            projectId,
          },
        },
      ],
    }
  );

  useEffect(() => {
    setLabelData({ name: label.name, color: label.color, id: label.id });
  }, []);

  useEffect(() => {
    if (updateLabelData) {
      setEdit(false);
    }
  }, [updateLabelData]);

  useEffect(() => {
    if (updateLabelError || deleteLabelError) {
      setRequestError(true);
    }
  }, [updateLabelError, deleteLabelError]);

  const onSave = (): void => {
    updateLabel({
      variables: {
        ...labelData,
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={6} item>
        <TextField
          disabled={label.isBase || !edit}
          className={classes.root}
          fullWidth
          label="метка"
          variant="filled"
          type="text"
          value={
            (labelData.name === "bug" && "баг") ||
            (labelData.name === "additionalTask" && "доп. задача") ||
            labelData.name
          }
          onChange={(e) => {
            setLabelData({ ...labelData, name: e.target.value });
          }}
        />
      </Grid>

      <Grid xs={6} item>
        <ItemContainer>
          <ColorPickerContainer disabled={!edit}>
            <ColorPicker
              disableTextfield={!edit}
              value={labelData.color}
              onChange={(color) =>
                setLabelData({
                  ...labelData,
                  color: color?.css.backgroundColor,
                })
              }
            />
          </ColorPickerContainer>

          {!label.isBase && (
            <ButtonsContainer>
              {edit ? (
                <SaveIconButton
                  disabled={
                    labelData.name === label.name &&
                    labelData.color === label.color
                  }
                  save={onSave}
                />
              ) : (
                <EditIconButton
                  disabled={edit || updateLabelLoading || deleteLabelLoading}
                  active={!edit}
                  edit={() => {
                    setEdit(true);
                  }}
                />
              )}
              <DeleteIconButton
                disabled={false}
                deleteHandler={() => {
                  deleteLabel({
                    variables: {
                      id: label.id,
                    },
                  });
                }}
                item="метку"
                fz={20}
              />
            </ButtonsContainer>
          )}
        </ItemContainer>
      </Grid>
      {
        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={
            (updateLabelError && "Ошибка обновления метки для поекта") ||
            (deleteLabelError && "Ошибка при удалении метки поекта")
          }
        />
      }
    </Grid>
  );
};
