import { SchoolFundingType } from "../../../generated/graphql";
import { schoolFundingType } from "../SchoolSubscription/maps";
import { Autocomplete } from "@material-ui/lab";
import { StyledTextField } from "../Style";

interface SchoolFundingTypeProps {
  fundingType: SchoolFundingType;
  setFundingType: (fundingType: SchoolFundingType) => void;
}

export const SelectSchoolFundingType = ({
  fundingType,
  setFundingType,
}: SchoolFundingTypeProps) => {
  return (
    <>
      <Autocomplete
        options={Object.keys(schoolFundingType)}
        getOptionLabel={(option) => schoolFundingType[option] || ""}
        value={fundingType ?? ""}
        onChange={(_, newValue) =>
          setFundingType(newValue as SchoolFundingType)
        }
        renderInput={(params) => (
          <StyledTextField {...params} label="Тип школы" variant="filled" />
        )}
      />
    </>
  );
};
