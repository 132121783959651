import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import {
  UpdateProjectAdditionalContactDocument,
  DeleteProjectAdditionalContactDocument,
  DeleteProjectAdditionalContactMutation,
  Maybe,
  UpdateProjectAdditionalContactMutation,
  ProjectAdditionalContact,
  Project,
  GetProjectDocument,
} from "../../../generated/graphql";
import { Box, Grid, IconButton, TextField } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { useParams } from "react-router-dom";
import { ProjectAddAdditionalContactsModal } from "./ProjectAddAdditionalContactsModal";

interface ProjectAdditionalContactsProps {
  classes: ClassNameMap<"root">;
  project: Maybe<Project> | undefined;
}

export const ProjectAdditionalContacts = ({
  project,
  classes,
}: ProjectAdditionalContactsProps) => {
  const { projectId } = useParams<{ projectId: string }>();
  const [currentContact, setCurrentContact] =
    useState<ProjectAdditionalContact | null>(null);
  const [updateAdditionalContact] =
    useMutation<UpdateProjectAdditionalContactMutation>(
      UpdateProjectAdditionalContactDocument
    );
  const [deleteContact] = useMutation<DeleteProjectAdditionalContactMutation>(
    DeleteProjectAdditionalContactDocument
  );

  const [isAdditionalContactModal, setIsAdditionalContactModal] =
    useState<boolean>(false);

  const updateAdditionalContactHandleSubmit = ({
    id,
    name,
    phone,
    description,
  }: {
    id: string;
    name: string;
    phone: string;
    description?: string;
  }): void => {
    id &&
      updateAdditionalContact({
        variables: {
          projectAdditionalContactData: {
            id,
            name,
            phone,
            description,
          },
        },
        refetchQueries: [
          { query: GetProjectDocument, variables: { projectId } },
        ],
      });
    setIsAdditionalContactModal(false);
  };

  return (
    <>
      {project?.additionalContacts?.map((contact) => (
        <Box key={contact?.id} position="relative">
          <Box
            display="flex"
            position="absolute"
            right={2}
            top="10px"
            zIndex={100}
          >
            <IconButton
              style={{ color: "#c4c4c4" }}
              aria-label="edit"
              onClick={() => {
                setIsAdditionalContactModal(true);
                setCurrentContact(contact as ProjectAdditionalContact);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              style={{ color: "#c4c4c4" }}
              onClick={async () => {
                await deleteContact({
                  variables: { projectAdditionalContactId: contact?.id },
                  refetchQueries: [
                    { query: GetProjectDocument, variables: { projectId } },
                  ],
                });
              }}
            >
              <Delete />
            </IconButton>
          </Box>
          <Grid container justifyContent="center" spacing={2}>
            <Grid xs={6} item>
              <TextField
                disabled
                className={classes.root}
                margin="normal"
                fullWidth
                label="Имя"
                variant="filled"
                type="text"
                value={contact?.name}
              />
            </Grid>
            <Grid xs={6} item>
              <TextField
                disabled
                className={classes.root}
                margin="normal"
                fullWidth
                label="Телефон"
                variant="filled"
                type="text"
                value={contact?.phone}
              />
            </Grid>
          </Grid>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            {contact?.description && (
              <TextField
                disabled
                className={classes.root}
                fullWidth
                label="За что ответственный"
                variant="filled"
                type="text"
                value={contact.description}
              />
            )}
          </Box>
        </Box>
      ))}

      {isAdditionalContactModal && (
        <ProjectAddAdditionalContactsModal
          open={isAdditionalContactModal}
          showModal={setIsAdditionalContactModal}
          modalHandleUpdateSubmit={updateAdditionalContactHandleSubmit}
          data={currentContact as ProjectAdditionalContact}
        />
      )}
    </>
  );
};
