import React, { useState, useEffect } from "react";

import { useMutation, useQuery } from "@apollo/client";
import {
  CreateUserJobDocument,
  CreateUserJobMutation,
  DeleteUserJobDocument,
  DeleteUserJobMutation,
  GetUserJobsDocument,
  GetUserJobsQuery,
  UpdateUserJobDocument,
  UpdateUserJobMutation,
} from "../../../../generated/graphql";

import {  CircularProgress, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { LoadingBox } from "../../../shared/Style/Style";
import { SettingsList } from "../SettingsList";
import { SettingsCreateItemForm } from "../SettingsCreateItemForm";

import { Content } from "../Settings.style";

export const Job = () => {
  const [inputError, setInputError] = useState(false);
  const [requestError, setRequestError] = useState(false);

  const [isCreating, setCreating] = useState(false);
  const [isEditing, setEditing] = useState({
    itemId: '',
    status: false,
  })

  const {
    data: JobsData,
    loading: JobsLoading,
    error: JobsError,
  } = useQuery<GetUserJobsQuery>(GetUserJobsDocument);

  const [
    createJob,
    {
      data: createJobData,
      loading: createJobLoading,
      error: createJobError,
    }
  ] = useMutation<CreateUserJobMutation>(
    CreateUserJobDocument,
    { refetchQueries: [{query: GetUserJobsDocument}] }
  );

  const createHandler = (name: string): void => {
    if (!name) {
      setInputError(true);
      return;
    }

    createJob({ variables: {name} })
  }

  const [
    updateJob,
    {
      data: updateJobData,
      loading: updateJobLoading,
      error: updateJobError,
    }
  ] = useMutation<UpdateUserJobMutation>(
    UpdateUserJobDocument,
    { refetchQueries: [{query: GetUserJobsDocument}] }
  );

  const updateHandler = (id: string, name: string): void => {
    if(!id || !name) {
      setInputError(true);
      return;
    }

    updateJob({ variables: {userJobId: id, name} })
  }

  const [
    deleteJob,
    {
      data: deleteJobData,
      loading: deleteJobLoading,
      error: deleteJobError,
    }
  ] = useMutation<DeleteUserJobMutation>(
    DeleteUserJobDocument,
    { refetchQueries: [{query: GetUserJobsDocument}] }
  );

  const deleteHandler = (id: string): void => {
    if(!id) {
      setInputError(true);
      return;
    }
    deleteJob({ variables: {userJobId: id} })
  }

  useEffect(() => {
    if(createJobError || updateJobError || deleteJobError) {
      setRequestError(true);
    }
  }, [createJobError, updateJobError, deleteJobError]);

  useEffect(() => {
    if(deleteJobData || updateJobData || createJobData) {
      setRequestError(false);
    }
  }, [deleteJobData, updateJobData, createJobData]);

  useEffect(() => {
    if(updateJobData) {
      setEditing({
        itemId: '',
        status: false,
      });
    }

    if(createJobData) {
      setCreating(false);
    }
  }, [updateJobData, createJobData]);

  const areEditingButtonsDisabled = createJobLoading || updateJobLoading || deleteJobLoading || isCreating;
  const areCreatingButtonsDisabled = createJobLoading || updateJobLoading || deleteJobLoading || isEditing.status;

  let content;

  if (JobsLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    )
  }

  if (JobsError) {
    content = <ErrorSnackbar error={JobsError}/>
  }

  if (JobsData) {
    const userJobs = JobsData.getUserJobs;

    content = (
      <>
        <SettingsCreateItemForm
          header={'Профессии'}
          createItem={createHandler}
          setCreating={setCreating}
          inputError={inputError}
          setInputError={setInputError}
          areButtonsDisabled={areCreatingButtonsDisabled}
          isRequestSuccessful={Boolean(createJobData)}
        />

        <SettingsList
          list={userJobs}
          updateItem={updateHandler}
          deleteItem={deleteHandler}
          buttonsDisabled={areEditingButtonsDisabled}
          isEditing={isEditing}
          setEditing={setEditing}
        />
      </>
    )
  }

  return (
    <Content>
      {content}

      <Snackbar
        open={inputError}
        onClose={() => setInputError(false)}
        autoHideDuration={6000}
      >
        <Alert severity="error">
          Вы ввели некорректное название
        </Alert>
      </Snackbar>

      <Snackbar
        open={requestError}
        onClose={() => setRequestError(false)}
        autoHideDuration={6000}
      >
        <Alert severity="error">
          {createJobError?.message || updateJobError?.message || deleteJobError?.message}
        </Alert>
      </Snackbar>
    </Content>
  )
}
