import React, { useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import {
  GetUserDocument,
  AssignUsersToGroupDocument,
  AssignUsersToGroupMutation,
  GetUserGroupsDocument,
  GetUserGroupsQuery,
  User,
  UserGroup
} from "../../../generated/graphql";

import { ListItem, TextField, Backdrop, CircularProgress } from "@material-ui/core";

import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";
import {
  GroupModalContent,
  GroupModalBtnsWrapper,
  GroupModalBtnOrange,
  GroupModalCheckbox,
  GroupModalControlLabel,
  GroupModalGroupsList,
  GroupModalNoGroups
} from "../GroupModal.style";
import { useParams } from "react-router-dom";
import { useEffect } from "react";


interface IAddGroupToUserModalProps {
  user: User | null;
  handleClose(): void;
  setGroupsModalRole(e: string): void;
}


export const AddGroupToUserModal = ({user, handleClose, setGroupsModalRole}: IAddGroupToUserModalProps) => {
  const { userId } = useParams<{userId: string}>();

  const { data, loading, error } = useQuery<GetUserGroupsQuery>(GetUserGroupsDocument);

  const [searchedGroup, setSerchedGroup] = useState<string>('');
  const [chosenGroups, setChosenGroups] = useState<string[] | []>([]);

  const choseGroupHandler = (groupId: string | null) => {
    if (!groupId) {
      return;
    }

    if (chosenGroups.find(id => id === groupId)) {
      setChosenGroups([...chosenGroups.filter(id => groupId !== id)]);
      return;
    }

    setChosenGroups([...chosenGroups, groupId])
  }


  const [
    assignToGroups,
    {data: addGropsData, loading: addGropsLoading, error: addGropsError}
  ] = useMutation<AssignUsersToGroupMutation>(AssignUsersToGroupDocument, {
    refetchQueries: [{query: GetUserDocument, variables: { userId }}]
  });

  const assignToGroupsHandler = (): void => {
    if (!chosenGroups.length) {
      return;
    }

    chosenGroups.forEach(groupId => assignToGroups({ variables: { userIds: [userId], userGroupId: groupId } }))
  }


  useEffect(() => {
    if (addGropsData) {
      setChosenGroups([]);
      setSerchedGroup('');
      handleClose();
    }
  }, [addGropsData])
  const groupsList: UserGroup[] = data?.getUserGroups as UserGroup[];

  const preparedGroupsList = groupsList
    ?.map((group: UserGroup) => {
      if (user?.groups?.find((userGroup: UserGroup | null) => userGroup?.id === group.id)) {
        return null;
      }

      if (!group.name?.toLocaleLowerCase().includes(searchedGroup.toLocaleLowerCase())) {
        return null;
      }

      return group;
    })
    .filter(group => group).reverse();


  if (loading) {
    return (
      <Backdrop style={{ color: '#FFB61D' }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <GroupModalContent>
      <TextField
        autoFocus
        disabled={addGropsLoading}
        label="Введите назване группы"
        type="text"
        fullWidth
        value={searchedGroup}
        onChange={({ target }) => setSerchedGroup(target.value)}
      />

      {preparedGroupsList?.length
        ? (<GroupModalGroupsList>
          {preparedGroupsList.map((group: UserGroup | null) => (
            <ListItem button>
              <GroupModalControlLabel
                value="end"
                control={
                  <GroupModalCheckbox
                    disabled={addGropsLoading}
                    onChange={() =>  group?.id && choseGroupHandler(group?.id)}
                    checked={!!chosenGroups.find(id => id === group?.id)}
                    name={group?.name?.toString()}
                    color="default"
                  />
                }
                label={group?.name}
              />
            </ListItem>
          ))}
        </GroupModalGroupsList>
      ) : <GroupModalNoGroups>Нет доступных групп</GroupModalNoGroups>}

      <GroupModalBtnsWrapper>
        <GroupModalBtnOrange
          disabled={addGropsLoading}
          onClick={() => setGroupsModalRole('create')}
        >
          создать
        </GroupModalBtnOrange>

        <GroupModalBtnOrange
          disabled={addGropsLoading}
          onClick={assignToGroupsHandler}
        >
          добавить
        </GroupModalBtnOrange>
      </GroupModalBtnsWrapper>

      {error && <ErrorSnackbar error={error}/>}
      {addGropsError && <ErrorSnackbar error={addGropsError}/>}
    </GroupModalContent>
  )
}
