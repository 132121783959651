import React, { useState } from "react";
import { Box, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { CloseIconButton } from "../shared/Buttons/CloseIconButton";
import { FaqFields, PublishSwitch } from "./AskedQuestions.style";
import { ButtonCustom, ButtonsWrapper, CancelButton } from "../shared/Style/Style";

interface IFAQCreateModal {
  answer:string;
  questions:string;
  open: boolean;
  isWatched:boolean;
  close():void;
  setIsWatched(isWatched:boolean):void;
}

export const FaqCreateModal = ({ open,close,isWatched,questions,answer,setIsWatched}:IFAQCreateModal) => {
  const [toggle,setToggle]=useState(true)

  const closeHandle=()=>{
    close()
  }
  return (
    <>
      <Dialog open={open}>

          {
            isWatched?(

              <DialogContent style={{ minWidth: "400px", padding: "30px" }}>
                <CloseIconButton
                  close={closeHandle}
                  position="absolute"
                  main_color="black"
                />
                <FaqFields fullWidth label="Вопрос"   disabled={true} value={questions}/>
                <FaqFields fullWidth multiline rows={4} label="Ответ" disabled={true} value={answer} />
              </DialogContent>

            ):(
              <DialogContent style={{ minWidth: "400px", padding: "30px" }}>
            <Box textAlign="center" mb={2}>
            <DialogTitle>Создание вопроса</DialogTitle>
            </Box>
            <FaqFields fullWidth label="Вопрос" />
            <FaqFields fullWidth multiline rows={4} label="Ответ" />
            <Box ml={1}>
          {toggle?"Опубликовать":"Не опубликовывать"}
            </Box>
            <PublishSwitch
            checked={toggle}
            onChange={()=>setToggle(!toggle)}
            />
            <ButtonsWrapper>
            <CancelButton onClick={closeHandle} disabled={false}>
            Отмена
            </CancelButton>

            <ButtonCustom
            onClick={()=>{}}
            disabled={false}
            >
            Создать
            </ButtonCustom>
            </ButtonsWrapper>
              </DialogContent>
            )
          }
      </Dialog>
    </>
  );
};