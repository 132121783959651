import styled from "styled-components";
import { FormHelperText } from "@material-ui/core";
import { red } from "../../../../shared/Style/Style";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ModelModalInputTitle = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #101010;
`;

export const InputHelperText = styled(FormHelperText)`
  color: ${red};
`;

export const FileList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FileItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const FileForLoad = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 44px;
  padding-right: 55px;
`;
