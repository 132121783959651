import { RobotsHistory } from "./RobotsHistory";
import { Economics } from "./Economics";
import { awardsPageLink, awardsPageTitle } from "./Awards/constants";
import { robotsPageLink, robotsPageTitle } from "./Robots/constants";

export const robotsNavItems = [
  {
    name: awardsPageTitle,
    link: awardsPageLink,
  },
  {
    name: RobotsHistory.chapterName,
    link: RobotsHistory.link,
  },
  {
    name: robotsPageTitle,
    link: robotsPageLink,
  },
  {
    name: Economics.chapterName,
    link: Economics.link,
  },
];
