import styled from 'styled-components'
import {ModalInput} from "../../shared/Style/Style";
import {LessonBlockTypeEnum} from "../../../generated/graphql";
import {
  getFontSizeOfBlockInputLesson
} from "../../shared/Utils/OtherOperations/getFontSizeOfBlockInput";
import {
  getMarginOfBlockInputLabelLesson
} from "../../shared/Utils/OtherOperations/getMarginOfBlockInputLabel";
import {Button} from "@material-ui/core";

const titles = [ LessonBlockTypeEnum.Header, LessonBlockTypeEnum.Subtitle ];

export const BlockInputLesson = styled(ModalInput)<{ type?: LessonBlockTypeEnum }>`
  & .MuiFormLabel-root {
    font-size: ${({ type }) => getFontSizeOfBlockInputLesson(type)};
    font-weight: ${({ type }) => titles.includes(type) ? 'bold' : 'normal'};
  }

  & .MuiInputBase-root {
    font-size: ${({ type }) => getFontSizeOfBlockInputLesson(type)};
    font-weight: ${({ type }) => titles.includes(type) ? 'bold' : 'normal'};
  }

  & .MuiInput-underline:before {
    border-bottom-color: ${({ disabled }) => disabled ? 'transparent' : '#eee'};
  }

  & label + .MuiInput-formControl {
    margin-top: ${({ type }) => getMarginOfBlockInputLabelLesson(type)};
  }

  & .MuiInputBase-input {
    padding: ${({ type }) => type === LessonBlockTypeEnum.List ? '14px 7px' : '6px 0 7px'}
  }
`;

export const PreviewImage = styled.div<{img: string}>`
  height: 300px;
  width: 100%;
  background: ${({img}) => img ? `url(${img}) center/contain no-repeat` : '#ccc'};
`

export const ButtonUpload = styled.div<{disabled: boolean}>`
  cursor: pointer;
  width: 150px;
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 500;
  padding: 14px;
  text-align: center;
  background: ${({disabled}) => disabled && '#ccc' || '#FFB61D'};
`





