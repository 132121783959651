import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  CreateKanbanWorkflowStepForProjectDocument,
  CreateKanbanWorkflowStepForProjectMutation,
  GetKanbanWorkflowStepsByProjectDocument,
  KanbanWorkflowStep,
  ProjectTaskStatus,
} from "../../../generated/graphql";
import { SectionTitle } from "../SectionTitle/SectionTitle";
import { NewItem } from "../NewItem/NewItem";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { WorkflowStepItem } from "./WorkflowStepItem";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { ItemsContainer, SectionContainer } from "../KanbanSettings.style";
import { useStyles } from "../../shared/Style/Style";

interface WorkflowStepsSectionProps {
  workflowSteps: KanbanWorkflowStep[];
  projectId: string;
  projectTaskStatuses: ProjectTaskStatus[];
}

export const WorkflowStepsSection = ({
  workflowSteps,
  projectId,
  projectTaskStatuses,
}: WorkflowStepsSectionProps) => {
  const classes = useStyles();
  const [requestError, setRequestError] = useState(false);

  const [isCreating, setIsCreating] = useState(false);
  const [workflowStepsName, setWorkflowStepsName] = useState("");
  const [taskStatusId, setTaskStatusId] = useState("");
  const [order, setOrder] = useState(0);

  const [createKanbanWorkflowStep, { data, error }] =
    useMutation<CreateKanbanWorkflowStepForProjectMutation>(
      CreateKanbanWorkflowStepForProjectDocument,
      {
        refetchQueries: [
          {
            query: GetKanbanWorkflowStepsByProjectDocument,
            variables: {
              projectId,
            },
          },
        ],
      }
    );

  useEffect(() => {
    if (data) {
      setIsCreating(false);
      setWorkflowStepsName("");
      setOrder(0);
      setTaskStatusId("");
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setRequestError(true);
    }
  }, [error]);

  const onSave = (): void => {
    createKanbanWorkflowStep({
      variables: {
        projectId,
        taskStatusId,
        name: workflowStepsName,
        order,
      },
    });
  };

  return (
    <SectionContainer>
      <SectionTitle
        title="Колонки доски"
        onClickBtn={() => {
          setIsCreating(true);
        }}
      />
      {isCreating && (
        <>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <FormControl className={classes.root} variant="filled" fullWidth>
                <InputLabel>Статус проекта</InputLabel>
                <Select
                  value={taskStatusId}
                  onChange={(e) => {
                    setTaskStatusId(e.target.value as string);
                  }}
                  fullWidth
                >
                  {projectTaskStatuses?.map((status) => {
                    return (
                      <MenuItem key={status.id} value={status.id}>
                        {(status.name === "new" && "новые") ||
                          (status.name === "inProgress" && "в работе") ||
                          (status.name === "done" && "выполнены") ||
                          status.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={6} item>
              <TextField
                disabled={false}
                className={classes.root}
                fullWidth
                label="порядковый номер"
                variant="filled"
                type="text"
                value={order}
                onChange={(e) => setOrder(+e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid>
            <Grid xs={12}>
              <NewItem
                value={workflowStepsName}
                setValue={setWorkflowStepsName}
                onSave={onSave}
                label="колонка доски"
                disabledSaveButton={
                  !taskStatusId || !order || !workflowStepsName
                }
              />
            </Grid>
          </Grid>
        </>
      )}

      <ItemsContainer>
        {workflowSteps.map((step) => (
          <WorkflowStepItem
            key={step.id}
            step={step}
            label="колонка"
            projectId={projectId}
          />
        ))}
      </ItemsContainer>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={"Ошибка создания колонки для доски"}
      />
    </SectionContainer>
  );
};
