import { PurchaseFormValues } from "./types";
import { ApolloClient } from "@apollo/client";
import { CheckContentByProductIdDocument } from "../../../../generated/graphql";
import { validatePrice } from "../../../MobileAppModal/MobileAppModelModal/utils";
import { PRODUCT_ID_LENGTH } from "./constants";

const isNumber = (value: string) => !isNaN(Number(value));

let cachedProductId = {};
const checkIsProductIdExist = async (productId: string, apolloClient) => {
  if (cachedProductId.hasOwnProperty(productId)) {
    return cachedProductId[productId];
  }

  cachedProductId[productId] = await checkIfProductIdExist(
      Number(productId),
      apolloClient
  );

  return cachedProductId[productId];
};

const validateProductId = (productId = "") => {
  const isValidProductIdLength = productId.length === PRODUCT_ID_LENGTH;

  if (!isNumber(productId)) {
    return "Значение должно быть числом";
  }

  if (isNumber(productId) && !isValidProductIdLength) {
    return "ID товара равно 7 символов";
  }

  return false;
};

export const validateForm = async (
  values: PurchaseFormValues,
  apolloClient: ApolloClient<any>
) => {
  const errors: { [key: string]: string } = {};
  const productId = values?.productId || "";

  for (let key in values) {
    if (!values[key]) {
      errors[key] = "Это поле обязательное";
    }
  }
  const [priceError, priceErrorMessage] = validatePrice(values.price);
  const productIdError = validateProductId(productId);

  if (priceError) {
    errors.price = priceErrorMessage;
  }

  if (productIdError) {
    errors.productId = productIdError;
  } else {
    const isProductIdExist = await checkIsProductIdExist(productId, apolloClient);

    if (!isProductIdExist) {
      errors.productId = "ID товара не существует!";
    }
  }

  return errors;
};

export const checkIfProductIdExist = async (
  productId: number,
  apolloClient: ApolloClient<any>
) => {
  const { data } = await apolloClient.query({
    query: CheckContentByProductIdDocument,
    variables: { productId },
    fetchPolicy: "network-only",
  });

  return data?.checkContentByProductId;
};

export const handleErrorMessage = (errorMessage: string) => {
  const error = errorMessage.split(":")[1]?.trim();
  switch (error) {
    case "School already purchased this content":
      return "Не удалось создать платёж: Школа уже приобрела этот контент.";
    case "Content is not paid":
      return "Этот контент не платный";
    default:
      return errorMessage;
  }
};
