import React from "react";

import { useQuery } from "@apollo/client";
import {
  GetUsersCountLightDocument,
  GetUsersCountLightQuery,
  UserRoleEnum,
  UsersCountStatusesEnum,
  UserStatusEnum,
} from "../../generated/graphql";
import { IActiveFilterState } from "../People/People";

import { CircularProgress } from "@material-ui/core";

import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";

import {
  Filter,
  FilterButton,
  FilterButtonEndIcon,
  LoadingBox,
} from "../shared/Style/Style";

interface ProjectFilterProps {
  activeFilter: IActiveFilterState;
  setActiveFilter(filter: IActiveFilterState): void;
  setPage(page: number): void;
}

export const PeopleFilterBar = ({
  activeFilter,
  setActiveFilter,
  setPage
}: ProjectFilterProps) => {
  const { data, loading, error } = useQuery<GetUsersCountLightQuery>(
    GetUsersCountLightDocument,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const changeFilter = (activeFilter: IActiveFilterState, statuses: UserStatusEnum[], role?: UserRoleEnum[] ) => {
    if (
      activeFilter.role === role &&
      activeFilter.statuses.every((status, i) => status === statuses[i])
    ) {
      return;
    }

    setActiveFilter({
      statuses,
      role
    });

    setPage(0);
  };

  const filters = [
    {
      name: 'Сотрудники',
      active: activeFilter.role.includes(UserRoleEnum.Staff) ,
      count: UsersCountStatusesEnum.Staff,
      changeFilterHandler: () => changeFilter(activeFilter, [UserStatusEnum.Verified], [UserRoleEnum.Staff,UserRoleEnum.Super,UserRoleEnum.Manager]),
    },
    {
      name: 'Клиенты',
      active: activeFilter.role.includes(UserRoleEnum.Client),
      count: UsersCountStatusesEnum.Client,
      changeFilterHandler: () => changeFilter(activeFilter, [UserStatusEnum.Verified], [UserRoleEnum.Client]),
    },
    {
      name: 'Новые',
      active: activeFilter.statuses.includes(UserStatusEnum.Pending),
      count: UsersCountStatusesEnum.New,
      changeFilterHandler: () => changeFilter(activeFilter, [UserStatusEnum.Pending, UserStatusEnum.NotVerified], []),
    }
  ]


  let content;

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />;
      </LoadingBox>
    );
  }

  if (error) {
    content = <ErrorSnackbar error={error} />;
  }

  if (data) {
    const usersCounts = data.getUsersCountLight;

    content = (
      <>
        {filters.map(({ name, active, count, changeFilterHandler }) => (
          <FilterButton
            key={name}
            active={active}
            onClick={changeFilterHandler}
            endIcon={
              <FilterButtonEndIcon>
                {"(" +
                  usersCounts?.find((usersCount) => usersCount?.status === count)
                    ?.count +
                  ")" || ""}
              </FilterButtonEndIcon>
            }
          >
            {name}
          </FilterButton>
        ))}
      </>
    );
  }

  return <Filter>{content}</Filter>;
};
