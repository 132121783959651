import styled from "styled-components";
import { PhotoPicker } from "../shared/PhotoPicker";
import { ButtonCustomWithDarkText, lightBlack } from "../shared/Style/Style";
import { Box, DialogTitle } from "@material-ui/core";

export const MobileAppPhotoDropzone = styled(PhotoPicker)`
  flex-basis: 20%;
  flex-shrink: 0;
`;

export const MobileAppModalBundleName = styled.span`
  color: ${lightBlack};
  font-size: 14px;
  line-height: 36px;
`;

interface IErrorButtonProps {
  error?: boolean
}

export const LanguageButton = styled(ButtonCustomWithDarkText)<IErrorButtonProps>`
  background-color: ${props => props.error && 'red'} !important;
`;

export const PermissionTitle = styled.p`
  margin: 0;
`;

 export const ModelModalTitle = styled(DialogTitle)`
  display: flex;
	align-items: center;
  margin-bottom: 8px;
 `;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top:  40px;
`;

export const CoordinatesContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  & > div {
    width: 46%;
    margin-bottom: 0;
  }
`;

export const ButtonsContainer = styled(Box)`
   width: 100%;
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   margin: 16px 0;
 `;

export const CurrentBundleContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;
