import { useReactiveVar } from "@apollo/client";
import { awardsState } from "../../state";
import { AwardTypeEnum } from "../../../../../generated/graphql";
import { ModalInput } from "../../../../shared/Style/Style";
import { SelectAwardType } from "./SelectAwardType";
import { SelectAwardDay } from "./SelectAwardDay";
import { AwardAnimation } from "./AwardAnimation";
import { AwardField } from "../../types";

export const AwardForm = () => {
    const award = useReactiveVar(awardsState.award);
    const inputErrors = useReactiveVar(awardsState.inputErrors);
    const isBonus = award.type === AwardTypeEnum.Bonus;

    const awardInputData = [
        {
            field: AwardField.Name, label: "Название награды",
            handleValueChange: (value: string) => awardsState.setAwardName(value),
            helperText: "Максимальное кол-во символов 20"
        },
        {
            field: AwardField.Background, label: "Фон награды",
            handleValueChange: (value: string) => awardsState.setAwardBackground(value),
            helperText: "Введите цвет фона в формате HEX"
        },
        {
            field: AwardField.Energy, label: "Количество энергии за полученную награду",
            handleValueChange: (value: number) => awardsState.setAwardEnergyQuantity(Number(value)),
            helperText: "Введите числовое значение"
        }
    ];

    const renderModalInput = (field: string, label: string, handleValueChange: (value) => void, helperText: string ) => {
        return (
            <ModalInput
                key={field}
                type={field === AwardField.Energy ? "number" : "text"}
                variant="filled"
                margin="normal"
                required
                label={label}
                value={award[field]}
                onChange={({ target: { value } }) => handleValueChange(value)}
                error={inputErrors[field].error}
                helperText={helperText}
                onFocus={() => awardsState.setInputErrors(field as AwardField, false)}
            />
        );
    }
    
    return (
        <>
            {awardInputData.map(({ field, label, handleValueChange, helperText }) => (
                renderModalInput(field, label, handleValueChange, helperText)
            ))}

            <AwardAnimation />

            <SelectAwardType />

            {!isBonus && <SelectAwardDay />}
        </>
    );
};
