import React, { useEffect, useRef, useState } from "react";
import {
  AddCommentBtnFeedback,
  AddCommentFeedback,
  Comment,
  FeedbackAvatar,
  FeedbackComment,
  FeedbackCommentContent,
  FeedbackTitle,
} from "./FeedBack.style";
import { AddCommentBar } from "../Corrections/Corrections.style";
import {
  Box,
  CircularProgress,
  Collapse,
  InputAdornment,
} from "@material-ui/core";
import { NoteAdd } from "@material-ui/icons";
import { DEFAULT_AVATAR_URL } from "../shared/constants";
import { EditIconButton } from "../shared/Buttons/EditIconButton";
import { SaveIconButton } from "../shared/Buttons/SaveIconButton";
import { DeleteIconButton } from "../shared/Buttons/DeleteIconButton";
import {
  HiddenInput,
  lightBlack,
  LoadingBox,
  PrimaryLink,
} from "../shared/Style/Style";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { CustomIconButton } from "../shared/Buttons/CustomIconButton";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CreateFeedbackCommentDocument,
  CreateFeedbackCommentMutation,
  DeleteFeedbackCommentDocument,
  DeleteFeedbackCommentMutation,
  GetCommentsByFeedbackIdDocument,
  GetCommentsByFeedbackIdQuery,
  GetFeedbacksDocument,
  UpdateFeedbackCommentDocument,
  UpdateFeedbackCommentMutation,
} from "../../generated/graphql";


const FeedBackComments = ({
                            choseFeedback,
                            activeButton,
                            setChoseFeedback,
                            requestError,
                            setRequestError,
                            messageError,
                            setMessageError
                          }) => {

  const [creatComment, setCreatComment] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);
  const [commentId, setCommentId] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [text, setText] = useState("");

  const [
    createFeedbackComment,
    {
      data: createFeedbackCommentData,
      loading: createFeedbackCommentLoading,
      error: createFeedbackCommentError,
    },
  ] = useMutation<CreateFeedbackCommentMutation>(
    CreateFeedbackCommentDocument,
    {
      refetchQueries: [
        {query: GetFeedbacksDocument, variables: {statuses: activeButton}},
        {query: GetCommentsByFeedbackIdDocument, variables: {feedbackId: choseFeedback}},
      ],
    }
  );

  const [
    updateFeedbackComment,
    {
      data: updatedFeedbackCommentData,
      loading: updatedFeedbackCommentLoading,
      error: updatedFeedbackCommentError,
    },
  ] = useMutation<UpdateFeedbackCommentMutation>(
    UpdateFeedbackCommentDocument,
    {
      variables: {
        id: commentId,
        text,
        files
      },
      refetchQueries: [
        {query: GetCommentsByFeedbackIdDocument, variables: {feedbackId: choseFeedback}},
      ],
    }
  );

  const [
    deleteComment,
    {
      data: deleteCommentData,
      loading: deleteCommentLoading,
      error: deleteCommentError,
    },
  ] = useMutation<DeleteFeedbackCommentMutation>(
    DeleteFeedbackCommentDocument,
    {
      refetchQueries: [
        {query: GetFeedbacksDocument, variables: {statuses: activeButton}},
        {query: GetCommentsByFeedbackIdDocument, variables: {feedbackId: choseFeedback}},
      ],
    }
  );

  const [getComment, {
    data: getCommentData,
    loading: getCommentLoading,
    error: getCommentError
  }] = useLazyQuery<GetCommentsByFeedbackIdQuery>(
    GetCommentsByFeedbackIdDocument,
    {
      variables: {feedbackId: choseFeedback},
    }
  )

  useEffect(() => {
    if (choseFeedback) {
      getComment({variables: {feedbackId: choseFeedback}})
    }
  }, [choseFeedback])


  useEffect(() => {
    if (
      createFeedbackCommentData ||
      updatedFeedbackCommentData ||
      deleteCommentData
    ) {
      setText("");
      setFiles(null);
      setCommentId("");
      setCreatComment(false);
    }
  }, [
    createFeedbackCommentData,
    deleteCommentData,
    updatedFeedbackCommentData,
  ]);

  useEffect(() => {
    if (createFeedbackCommentData?.createFeedbackComment?.id) {
      setText("");
      setFiles(null);
    }
  }, [createFeedbackCommentData]);


  useEffect(() => {
    if (
      deleteCommentError ||
      updatedFeedbackCommentError ||
      createFeedbackCommentError
    ) {
      setRequestError(true);
      setChoseFeedback("");
    }
  }, [
    deleteCommentError,
    updatedFeedbackCommentError,
    createFeedbackCommentError,
  ]);

  const createCommentHandle = async (feedbackId: string) => {
    if (!feedbackId || !text) {
      return;
    }
    try {
      await createFeedbackComment({
        variables: {
          feedbackId,
          text,
          files: [...files],
        },
      });

    } catch (e) {
      setRequestError(true)
      setMessageError('При создании сообщения произошла ошибка')
    }

  };

  const saveHandle = async () => {
    try {
      if (creatComment) {
        await createFeedbackComment();
        return;
      }
      await updateFeedbackComment();
    } catch (e) {
      setRequestError(true)
      setMessageError("При сохранении произшла ошибка")
    }
  };

  const editCommentHandle = (id: string, commentText: string) => {
    if (choseFeedback === id) {
      setChoseFeedback("");
      return;
    }
    setText(commentText);
    setCommentId(id);
  };

  const clickUploadFileHandle = (e) => {
    e.preventDefault();
    const files = e.target.files;
    setFiles([...files]);
  };

  const disableLoading =
    createFeedbackCommentLoading ||
    deleteCommentLoading ||
    updatedFeedbackCommentLoading;

  let content;
  if (getCommentError) {
    content = <ErrorSnackbar error={getCommentError}/>;
  }

  if (getCommentLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit"/>
      </LoadingBox>
    );
  }
  if (getCommentData) {
    const comments = getCommentData.getCommentsByFeedbackId;
    content = (
      <>
        {comments.length ? (
          comments.map((comment) =>
            comment.feedback.id === choseFeedback ? (
              <Comment key={comment?.id}>
                <FeedbackAvatar
                  src={
                    comment?.createdBy?.photo
                      ? comment?.createdBy?.photo
                      : DEFAULT_AVATAR_URL
                  }
                />
                <FeedbackCommentContent>
                  <FeedbackTitle>
                    {`${comment?.createdBy?.firstName}
                  ${comment?.createdBy?.lastName}`}
                  </FeedbackTitle>
                  <Box display="flex" justifyContent="space-between">
                    <Box>
                      <FeedbackComment>{comment?.text}</FeedbackComment>
                    </Box>
                    <Box>
                      {commentId !== comment.id ? (
                        <EditIconButton
                          disabled={disableLoading || commentId === comment.id}
                          edit={() => {
                            editCommentHandle(comment.id, comment.text);
                          }}
                          fz={20}
                        />
                      ) : (
                        commentId === comment.id && (
                          <SaveIconButton
                            disabled={disableLoading || commentId !== comment.id}
                            save={saveHandle}
                            fz={20}
                          />
                        )
                      )}
                      <DeleteIconButton
                        disabled={
                          disableLoading ||
                          commentId === comment.id ||
                          !!commentId
                        }
                        deleteHandler={() =>
                          deleteComment({
                            variables: {feedbackCommentId: comment.id},
                          })
                        }
                        item="коментарий"
                        fz={20}
                      />
                    </Box>
                  </Box>

                  {!!comment?.files ? (
                    <Collapse in={!!comment.files}>
                      <Box
                        textAlign="center"
                        mt="8px"
                        mr="-10px"
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Box
                          display="flex"
                          alignItems="flex-start"
                          flexDirection="column"
                        >
                          {comment.files.map(commentFiles => (
                            <Box key={commentFiles.id}>
                              <NoteAdd fontSize="small"/>
                              <PrimaryLink href={commentFiles.file}>
                                {commentFiles.name}
                              </PrimaryLink>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Collapse>
                  ) : (
                    ""
                  )}
                </FeedbackCommentContent>
              </Comment>
            ) : ""
          )) : ""}

        <AddCommentBar>
          <AddCommentFeedback
            value={text}
            label={"Введите комментраий"}
            fullWidth
            multiline
            onChange={({target: {value}}) => setText(value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position={"end"}>
                  <CustomIconButton
                    mainColor={lightBlack}
                    callback={() => {
                      fileInput.current.click();
                    }}
                    children={<NoteAdd fontSize="inherit"/>}
                  />
                </InputAdornment>
              ),
            }}
          />
          <AddCommentBtnFeedback
            active={true}
            disabled={createFeedbackCommentLoading || !!commentId}
            onClick={() => createCommentHandle(choseFeedback)}
          >
            {createFeedbackCommentLoading ? <CircularProgress color="inherit"/> : 'Написать'}
          </AddCommentBtnFeedback>
        </AddCommentBar>
        {!!files ? (
          <Collapse in={!!files} collapsedSize={"0px"}>
            {files.map(itemFiles => (
              <Box
                key={itemFiles.size}
                textAlign="center"
                mt="10px"
                display="flex"
                justifyContent="start"
                alignItems="center"
              >
                <NoteAdd fontSize="medium"/>
                {itemFiles.name}
                <DeleteIconButton
                  deleteHandler={() => {
                    fileInput.current.value = "";
                    setFiles(null);
                  }}
                  item="файл"
                  fz={20}
                />
              </Box>
            ))}

          </Collapse>
        ) : (
          ""
        )}

        <HiddenInput ref={fileInput} multiple type="file" onChange={clickUploadFileHandle}/>

        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={messageError}
        />
      </>
    );
  }

  return <div>{content}</div>;
};

export default FeedBackComments;