import React from "react";
import {
  ProjectFile,
  ProjectLink,
  ProjectPresentation,
} from "../../../generated/graphql";
import { Box } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { ProjectFileItem } from "./ProjectFileItem";
import { ProjectPresentationItem } from "./ProjectPresentationItem";
import { ProjectLinkItem } from "./ProjectLinkItem";

export const ProjectLinks = ({
  links,
  classes,
  projectId,
  projectPresentation,
  projectLinks,
  projectFiles,
}: {
  links: ProjectLink[] | ProjectPresentation[] | ProjectFile[] | undefined;
  classes: ClassNameMap<"root">;
  projectId: string;
  projectPresentation?: boolean;
  projectLinks?: boolean;
  projectFiles?: boolean;
}) => {
  return (
    <Box>
      {projectPresentation &&
        (links as ProjectPresentation[]).map((presentation) => (
          <ProjectPresentationItem
            key={presentation.id}
            presentation={presentation}
            projectId={projectId}
            classes={classes}
          />
        ))}

      {projectLinks &&
        (links as ProjectLink[]).map((link) => (
          <ProjectLinkItem
            key={link.id}
            link={link}
            projectId={projectId}
            classes={classes}
          />
        ))}

      {projectFiles &&
        (links as ProjectFile[]).map((file) => (
          <ProjectFileItem
            key={file.id}
            projectId={projectId}
            file={file}
            classes={classes}
          />
        ))}
    </Box>
  );
};
