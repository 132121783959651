import styled from "styled-components";
import {MainTableRow} from "../shared/Style/Style";
import {MobileAppModelsTableRow} from "../MobileAppModelsList/MobileAppModels.style";

export const MobileAppLessonsTitleTableRow = styled(MainTableRow)`
  grid-template-columns:  1.5fr 3fr 1.5fr 0.4fr;
  grid-gap: 10px;
`;

export const MobileAppLessonTableRow = styled(MainTableRow)`
  grid-template-columns: 1.5fr 3fr 1.5fr 0.4fr;
  grid-gap: 10px;
  cursor: pointer;
`;


export const EmptyMobileAppLessonTableRow = styled(MobileAppModelsTableRow)`
  grid-template-columns: 1fr;
`;