import { UserRole } from "../../../generated/graphql";
import { UserRoleEnum } from "../../../generated/graphql";

export const ParticipantsRoles = new Map([
    [UserRoleEnum.Teacher, "Учитель"],
    [UserRoleEnum.Pupil, 'Ученик'],
    [UserRoleEnum.Parent, 'Родитель']
]);


export const getParticipantsRoles = (role: UserRole) => {
    if (role?.role) {
        return ParticipantsRoles.get(role.role) || role?.role;
    }
};
