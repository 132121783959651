import React, { memo } from "react";
import {
  Box,
} from "@material-ui/core";
import { User } from "../../../../generated/graphql";

import { Field } from "react-final-form";

import { Grid, TextField } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { DatePicker } from "@material-ui/pickers";

import { UserAvatar } from "../UserAvatar";
import { defaultMaterialTheme, useStyles } from "../../../shared/Style/Style";
import { isRequired } from "../../../../core/validators/validators";

export const BaseFields = memo(({ user }: { user: User }) => {
  const classes = useStyles();

  return (
    <>
      <Grid xs={5} item>
        <Field name="photo">
          {({ input, meta }) => (
            <Box component="div" display="flex" justifyContent="center">
              <UserAvatar
                key={user?.id}
                onChange={(file: File) => {
                  input.onChange(file);
                }}
                user={user}
              />
            </Box>

          )}
        </Field>
      </Grid>

      <Grid xs={7} item>
        <Grid container>
          <Grid item={true} xs={12}>
            <Field name="lastName" validate={isRequired}>
              {({ input, meta }) => (
                <TextField
                  className={classes.root}
                  error={meta.error && meta.touched}
                  margin="normal"
                  fullWidth
                  helperText={meta.touched && meta.error}
                  label="Фамилия"
                  variant="filled"
                  type="text"
                  {...input}
                />
              )}
            </Field>
          </Grid>

          <Grid item={true} xs={12}>
            <Field name="firstName" validate={isRequired}>
              {({ input, meta }) => (
                <TextField
                  className={classes.root}
                  error={meta.error && meta.touched}
                  margin="normal"
                  fullWidth
                  helperText={meta.touched && meta.error}
                  label="Имя"
                  variant="filled"
                  type="text"
                  {...input}
                />
              )}
            </Field>
          </Grid>

          <Grid item={true} xs={12}>
            <Field name="middleName">
              {({ input, meta }) => (
                <TextField
                  className={classes.root}
                  error={meta.error}
                  margin="normal"
                  fullWidth
                  helperText={meta.error}
                  label="Отчество"
                  variant="filled"
                  type="text"
                  {...input}
                />
              )}
            </Field>
          </Grid>

          <Grid item={true} xs={12}>
            <Field name="birthday">
              {({ input, meta }) => (
                <ThemeProvider theme={defaultMaterialTheme}>
                  <DatePicker
                    error={meta.error && meta.touched}
                    helperText={meta.touched && meta.error}
                    fullWidth
                    margin="normal"
                    variant="inline"
                    format="dd.MM.yyyy"
                    id="date-picker-birthday"
                    label="Дата рождения"
                    inputVariant="filled"
                    value={input.value || null}
                    maxDate={new Date()}
                    onChange={input.onChange}
                  />
                </ThemeProvider>
              )}
            </Field>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});
