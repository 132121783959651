import React, { useState, useEffect } from "react";

import {
  News,
  Maybe,
  UpdateNewsMutation,
  UpdateNewsDocument,
  GetNewsDocument,
  AvailableForEnum,
  NewsIsForEnum,
  SmartNotificationTypeEnum,
} from "../../generated/graphql";

import { Box, Dialog, Radio, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
} from "../shared/Style/Style";

import {
  NewsModalContent,
  CloseBtn,
  NewsModalInputs,
  PhotoDropzone,
  NewsModalForm,
  NewsModalInput,
  CheckBoxCheck,
} from "./NewsModal.style";
import { useMutation } from "@apollo/client";
import { AddNewsParticipantsModal } from "../ParticipantsModal/AddNewsParticipantsModal";
import { checkYoutubeLink } from "../shared/Utils/OtherOperations/checkYoutubeLink";

interface INewsModalProps {
  open: boolean;

  close(): void;

  news: Maybe<News> | undefined;
}

export const NewsModal = ({ open, close, news }: INewsModalProps) => {
  const [nameNews, setNameNews] = useState("");
  const [description, setDescription] = useState("");
  const [youTubeLink, setYouTubeLink] = useState("");
  const [participants, setParticipants] = useState<
    (Maybe<string> | undefined)[] | null | undefined | []
  >([]);

  const [newPhoto, setNewPhoto] = useState<File | undefined>();

  const [isFor, setIsFor] = useState(NewsIsForEnum.AdminPanel);

  const [addUsersModalOpen, setAddUsersModalOpen] = useState(false);

  const openAddUsersModalHandler = () => {
    setAddUsersModalOpen(true);
  };

  const closeAddUsersModalHandler = () => {
    setAddUsersModalOpen(false);
  };

  // #region update news

  const [
    updateNews,
    {
      data: updateNewsData,
      loading: updateNewsLoading,
      error: updateNewsError,
    },
  ] = useMutation<UpdateNewsMutation>(UpdateNewsDocument, {
    variables: {
      newsData: {
        id: news?.id,
        name: nameNews,
        description,
        youTubeLink,
        isFor,
      },
      photo: newPhoto ? newPhoto : undefined,
    },
    refetchQueries: [
      {
        query: GetNewsDocument,
        variables: { limit: 1000, skip: 0, isPublished: null },
      },
    ],
  });

  const [openUpdateErrorSnackbar, setOpenUpdateErrorSnackbar] = useState(false);

  useEffect(() => {
    if (updateNewsError) {
      setOpenUpdateErrorSnackbar(true);
    }
  }, [updateNewsError]);

  const [updateInputErrorsSnackbar, setUpdateInputErrorsSnackbar] =
    useState(false);

  const [updateNewsInputErrors, setUpdateNewsInputErrors] = useState({
    name: false,
    youTubeLink: false,
  });

  const updateNewsHandler = () => {
    const youTubeUrlCheck =
      !checkYoutubeLink(youTubeLink) || !youTubeLink.startsWith("https");
    if (!nameNews || !nameNews.trim()) {
      setUpdateNewsInputErrors((state) => ({
        ...state,
        name: true,
      }));
      return;
    }
    if (youTubeLink && youTubeUrlCheck) {
      setUpdateNewsInputErrors((state) => ({
        ...state,
        youTubeLink: true,
      }));
    }

    if (!nameNews || youTubeUrlCheck) {
      setUpdateInputErrorsSnackbar(true);
      return;
    }

    updateNews();

    setUpdateNewsInputErrors({
      name: false,
      youTubeLink: false,
    });
  };
  const errorsHandler = (e: React.FocusEvent<HTMLInputElement>): void => {
    const {
      target: { name },
    } = e;
    setUpdateNewsInputErrors({
      ...updateNewsInputErrors,
      [name]: false,
    });
  };

  const blurHandler = (e: React.FocusEvent<HTMLInputElement>): void => {
    const {
      target: { name, value },
    } = e;
    if (name === "name") {
      const nameError = !nameNews.trim();

      if (nameError) {
        setUpdateNewsInputErrors((prev) => ({ ...prev, name: nameError }));
      }
    }

    if (!value) {
      setUpdateNewsInputErrors((prev) => ({ ...prev, [name]: true }));
    }
  };

  // #endregion

  useEffect(() => {
    setNameNews(news?.name || "");
    setDescription(news?.description || "");
    setYouTubeLink(news?.youTubeLink || "");
    setParticipants(
      news?.participants.map((participant) => participant?.user?.id) || []
    );
    setIsFor(news?.isFor);
    if (updateNewsData) {
      close();
    }
  }, [
    news?.id,
    news?.name,
    news?.description,
    news?.participants,
    news?.youTubeLink,
    news?.isFor,
    updateNewsData,
  ]);

  return (
    <Dialog open={open} TransitionProps={{ timeout: 300 }}>
      <NewsModalContent>
        <CloseBtn disabled={updateNewsLoading} onClick={close}>
          <CloseIcon fontSize="small" />
        </CloseBtn>

        <NewsModalInputs>
          <PhotoDropzone photo={news?.photo} setNewPhoto={setNewPhoto} />

          <NewsModalForm>
            <NewsModalInput
              label="Заголовок"
              name="name"
              value={nameNews}
              onFocus={errorsHandler}
              onBlur={blurHandler}
              error={updateNewsInputErrors.name}
              onChange={({ target: { value } }) => setNameNews(value)}
              helperText={
                updateNewsInputErrors?.name &&
                "Поле не может содержать только пробелы"
              }
            />

            <NewsModalInput
              multiline
              label="Введите текст новости"
              value={description}
              onChange={({ target: { value } }) => setDescription(value)}
            />

            <NewsModalInput
              label="Ссылка на YouTube"
              value={youTubeLink}
              onFocus={() =>
                setUpdateNewsInputErrors({
                  ...updateNewsInputErrors,
                  youTubeLink: false,
                })
              }
              onChange={({ target: { value } }) => setYouTubeLink(value)}
            />
            <Box>Опубликовать в ...</Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={2}
            >
              <Box display="flex" alignItems="center">
                <CheckBoxCheck
                  checked={NewsIsForEnum.LkTeacher === isFor}
                  onChange={() => setIsFor(NewsIsForEnum.LkTeacher)}
                  value={NewsIsForEnum.LkTeacher}
                  name="radio-buttons"
                />
                <Box>Личный кабинет учителя</Box>
                <CheckBoxCheck
                  checked={NewsIsForEnum.AdminPanel === isFor}
                  onChange={() => setIsFor(NewsIsForEnum.AdminPanel)}
                  value={NewsIsForEnum.AdminPanel}
                  name="radio-buttons"
                />
                <Box>Личный кабинет сотрудника</Box>
              </Box>

              <Box display="flex" alignItems="center">
                <CheckBoxCheck
                  checked={NewsIsForEnum.AdminPanelAndLkTeacher === isFor}
                  onChange={() =>
                    setIsFor(NewsIsForEnum.AdminPanelAndLkTeacher)
                  }
                  value={NewsIsForEnum.AdminPanelAndLkTeacher}
                  name="radio-buttons"
                />
                <Box>Личный кабинет сотрудника и учителя</Box>
              </Box>
            </Box>
            <Box textAlign="start" margin={2}>
              <ButtonCustom
                disabled={false}
                onClick={() => setAddUsersModalOpen(!addUsersModalOpen)}
              >
                Добавить участников
              </ButtonCustom>
            </Box>
          </NewsModalForm>
        </NewsModalInputs>

        <ButtonsWrapper>
          <CancelButton disabled={updateNewsLoading} onClick={close}>
            Отменить
          </CancelButton>

          <ButtonCustom
            disabled={updateNewsLoading}
            onClick={updateNewsHandler}
          >
            Сохранить
          </ButtonCustom>
        </ButtonsWrapper>
        <AddNewsParticipantsModal
          open={addUsersModalOpen}
          close={() => setAddUsersModalOpen(false)}
          news={news}
          participants={participants}
          setParticipants={setParticipants}
          isFor={isFor}
        />
      </NewsModalContent>

      {(updateNewsInputErrors.name || updateNewsInputErrors.youTubeLink) && (
        <Snackbar
          open={updateInputErrorsSnackbar}
          autoHideDuration={6000}
          onClose={() => setUpdateInputErrorsSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity="error">
            <p>
              {updateNewsInputErrors.name && "Отсутствует название уведомления"}
            </p>
            <p>
              {updateNewsInputErrors.youTubeLink &&
                "Неправильный адресс ссылки"}
            </p>
          </Alert>
        </Snackbar>
      )}

      <Snackbar
        open={openUpdateErrorSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenUpdateErrorSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error">При сохранении новости произошла ошибка!</Alert>
      </Snackbar>
    </Dialog>
  );
};
