import React, {useEffect, useState} from "react";
import { Box } from "@material-ui/core";
import { LanguageButton } from "./LanguageSwitch.style";

interface LanguageSwitchProps {
  languages: string[],
  disabled: boolean,
  switchLanguage?: (lang: string) => void,
  error?: { [key: string]: boolean } ,
}

export const LanguageSwitch: React.FC<LanguageSwitchProps> = ({
  languages,
  disabled,
  switchLanguage,
  error
}) => {
  const [language, setLanguage] = useState(languages[0])

  useEffect(() => {
    if(switchLanguage) {
      switchLanguage(language)
    }
  }, [language])

  return (
    <Box ml={2} display="flex" justifyContent="flex-end">
      {languages.map((lang) => (
        <Box key={lang} mr={1}>
          <LanguageButton
            disabled={disabled}
            error={error ? error[`name${lang}`] : false}
            active={language === lang}
            onClick={() => setLanguage(lang)}
          >
            {lang}
          </LanguageButton>
        </Box>
      ))}
    </Box>
  )
}

