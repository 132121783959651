import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import {
  SetAccessToApplicationMainMutation,
  SetAccessToApplicationMainDocument,
  GetApplicationMainDocument,
  ApplicationMainAvailableForEnum
} from "../../../generated/graphql";

import { DialogContent, Box, DialogTitle, Dialog, DialogContentText } from "@material-ui/core";

import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";

import { ButtonsWrapper, CancelButton, ButtonCustom, ModalInput } from "../../shared/Style/Style";


interface IMobileAppQrModalProps {
  open: boolean;
  close(): void;
}

const initialQrOptions = {
  dotColor: '',
  backgroundColor: '',
  size: 0,
}

export const MobileAppQrModal = ({ open, close }: IMobileAppQrModalProps) => {
  const { pageId } = useParams<{pageId: string}>();

  const [qrOptions, setQrOptions] = useState(initialQrOptions);
  const [confirmModal, setConfirmModal] = useState(false);

  const [requestError, setRequestError] = useState(false);

  const closeHandler = (): void => {
    setQrOptions(initialQrOptions);
    setConfirmModal(false);
    close();
  }


  const [setAccess, {
    data: setAccessData,
    loading: setAccessLoading,
    error: setAccessError,
  }] = useMutation<SetAccessToApplicationMainMutation>(
    SetAccessToApplicationMainDocument, {
      refetchQueries: [{ query: GetApplicationMainDocument, variables: { id: pageId } }]
    }
  );

  useEffect(() => {
    if (setAccessError) {
      setRequestError(true);
    }
  }, [setAccessError]);

  useEffect(() => {
    if (setAccessData) {
      setRequestError(false);
      closeHandler();
    }
  }, [setAccessData]);

  return (
    <Dialog
      open={open}
    >
      <DialogContent
        style={{ minWidth: '400px' }}
      >
        <CloseIconButton
          close={closeHandler}
        />

        <Box
          textAlign='center'
          mb={2}
        >
          <DialogTitle>Создание QR кода</DialogTitle>
        </Box>


        <Box>
          <ModalInput
            fullWidth
            label='Размер QR кода'
            value={qrOptions.size}
            onChange={({ target: { value } }) => setQrOptions({...qrOptions, size: +value })}
            disabled={setAccessLoading}
          />

          <ModalInput
            fullWidth
            label='Цвет QR кода'
            value={qrOptions.backgroundColor}
            onChange={({ target: { value } }) => setQrOptions({...qrOptions, backgroundColor: value })}
            disabled={setAccessLoading}
          />

          <ModalInput
            fullWidth
            label='Цвет QR кода'
            value={qrOptions.dotColor}
            onChange={({ target: { value } }) => setQrOptions({...qrOptions, dotColor: value })}
            disabled={setAccessLoading}
          />
        </Box>

        <ButtonsWrapper>
          <CancelButton
            onClick={closeHandler}
            disabled={setAccessLoading}
          >
            Отмена
          </CancelButton>

          <ButtonCustom
            onClick={() => setConfirmModal(true)}
            disabled={setAccessLoading}
          >
            Сгенерировать
          </ButtonCustom>
        </ButtonsWrapper>
      </DialogContent>

      <Dialog
        open={confirmModal}
      >
        <DialogContent>
          <DialogTitle>Доступ по QR коду</DialogTitle>

          <CloseIconButton
            close={() => setConfirmModal(false)}
          />

          <DialogContentText>
            При выборе доступа по QR-коду вы не сможете внести изменения касательно доступа. Вы уверены, что хотите ограничить доступ по QR-коду?
          </DialogContentText>

          <ButtonsWrapper>
            <CancelButton
              onClick={() => setConfirmModal(false)}
              disabled={setAccessLoading}
            >
              Отмена
            </CancelButton>

            <ButtonCustom
              onClick={() => {
                setAccess({variables: {
                  applicationMainData: {
                    applicationMainId: pageId,
                    availableFor: ApplicationMainAvailableForEnum.Qr,
                    qrOptions,
                  }
                }})
              }}
              disabled={setAccessLoading}
            >
              Подтвердить
            </ButtonCustom>
          </ButtonsWrapper>
        </DialogContent>

        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={`При создании QR кода произошла ошибка`}
        />
      </Dialog>
    </Dialog>
  )
}