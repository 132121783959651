import { useEffect } from "react";

interface UseResetPageOnSearch {
  searchKey?: string;
  setPage(number: number): void;
}

export const useResetPageOnSearch = ({ searchKey, setPage }: UseResetPageOnSearch) => {
  useEffect(() => {
    if (searchKey?.length > 0) {
      setPage(0);
    }
  }, [searchKey, setPage]);
};
