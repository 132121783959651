import React, { useState } from "react";

import { useQuery } from "@apollo/client";
import { GetAddedTasksReportDocument, GetAddedTasksReportQuery } from "../../../../generated/graphql";
import { IDateRange } from "../../../shared/DateRangePicker/DateRangePicker";

import { TableHead, Table, TableBody, TableRow, CircularProgress } from "@material-ui/core";

import { CustomTable } from "../../../shared/CustomTable";
import { ReportModal } from "../../../ReportModal";
import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";

import { CustomTableCell, CustomTableHeaderCell, CustomTableRow } from "../../../shared/CustomTable/CustomTable.style";
import { LoadingBox } from "../../../shared/Style/Style";

import { convertSecondsToHoursHandler } from "../../../shared/Utils/ConvertOperations/convertSecondsToHoursHandler";

const headers = [
  'Название проекта',
  'Количество задач',
  'Часов всего',
  'Затраты',
];


export const AddedTasksReport = ({ dateRange }: {dateRange: IDateRange}) => {
  const [openModal, setOpenModal] = useState(false);
  const [chosenProject, setChosenProject] = useState('');

  const { data, loading, error } = useQuery<GetAddedTasksReportQuery>(
    GetAddedTasksReportDocument, {
      variables: dateRange
    }
  );

  let content;

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit'/>
      </LoadingBox>
    )
  }

  if (error) {
    content = <ErrorSnackbar error={error} />
  }

  if (data) {
    const projects = data.getAddedTasksReport;

    content = (
      <Table>
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <CustomTableHeaderCell
                key={header}
                width={200}
              >
                {header}
              </CustomTableHeaderCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {projects.length
            ? projects.map(project => (
              <CustomTableRow
                clickable={true}
                key={project.projectId}
                onClick={() => {
                  setChosenProject(project.projectId);
                  setOpenModal(true);
                }}
              >
                {[
                  project.projectName,
                  project.count,
                  convertSecondsToHoursHandler(project.timeSpent),
                  project.cost,
                ].map(field => (
                  <CustomTableCell>
                    {field}
                  </CustomTableCell>
                ))}
              </CustomTableRow>
            ))
            : (
              <TableRow>
                <CustomTableCell colSpan={4}>Проекты отсутствуют</CustomTableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    )
  }

  return (
    <>
      <CustomTable data={data?.getAddedTasksReport} tableContent={content}/>
      {openModal && chosenProject && (
        <ReportModal
          title={data?.getAddedTasksReport?.find(project => project.projectId === chosenProject)?.projectName}
          reportType='addedTasks'
          open={openModal}
          closeHandler={() => {
            setOpenModal(false);
            setChosenProject('');
          }}
          projectId={chosenProject}
          dateRange={dateRange}
        />
      )}
    </>
  )
}
