import React from "react";

import { Dialog } from "@material-ui/core";

import { User } from "../../generated/graphql";

import { AddGroupToUserModal } from "./AddGroupToUserModal";
import { CreateGroupModal } from "./CreateGroupModal";

interface IGroupModalProps {
  role: string;
  user: User | null;
  open: boolean;
  setGroupsModal(e: boolean): void;
  setGroupsModalRole(e: string): void;
}

export const GroupModal = ({ role, user, open, setGroupsModal, setGroupsModalRole }: IGroupModalProps) => {

  const handleClose = () => {
    setGroupsModal(false);
  }

  let content;

  switch (role) {
    case 'create':
      content = <CreateGroupModal handleClose={handleClose} setGroupsModalRole={setGroupsModalRole}/>
      break;

    case 'add':
      content = <AddGroupToUserModal user={user} handleClose={handleClose} setGroupsModalRole={setGroupsModalRole} />;
      break;

    default:
      content = <AddGroupToUserModal user={user} handleClose={handleClose} setGroupsModalRole={setGroupsModalRole}/>;
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
    >
      {content}
    </Dialog>
  )
}
