import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react";
import { mobileAppModel } from "../../state";
import { imageValidation } from "../../utils";
import { qualityArgument } from "../../constants";
import { Paper, Popover } from "@material-ui/core";
import { ImportFileUpload } from "../../../../shared/Style/Style";
import { ImageIcon } from "../../../../shared/Icons/ImageIcon";
import { VideoIcon } from "../../../../shared/Icons/VideoIcon";
import { MenuItemContainer } from "./Style";
import { ImageCropperDialog } from "./ImageCropperDialog";
import { CurrentFileType } from "../../types";
import { acceptedImageTypes } from "../../../../shared/constants";

interface UploadMenuProps {
  anchorEl: Element | null;
  setAnchorEl: Dispatch<SetStateAction<Element | null>>;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export const UploadMenu: FC<UploadMenuProps> = ({
  anchorEl,
  setAnchorEl,
  setOpenModal,
}) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const [currentFile, setCurrentFile] = useState<CurrentFileType>(null);

  const onDialogClose = useCallback(() => {
    setCurrentFile(null);
    setAnchorEl(null);
  }, [setCurrentFile, setAnchorEl]);

  const handleDrop = (file: File) => {
    const { isValidImage, isInvalidExtension, errorMessage } =
      imageValidation(file);

    if (isValidImage && !isInvalidExtension) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setCurrentFile({
          image: reader.result as string,
          name: file?.name,
          type: file?.type,
        });
      };
    } else {
      onDialogClose();
      mobileAppModel.setError(true, errorMessage);
    }
  };

  const handleUpload = () => {
    setOpenModal(true);
    setAnchorEl(null);
  };

  const onSubmitCropper = useCallback(
    (canvasImage: HTMLCanvasElement) => {
      const format = currentFile?.name.endsWith(".png")
        ? "image/png"
        : "image/jpeg";
      canvasImage.toBlob(
        (blob) => {
          const file = new File([blob], currentFile?.name, {
            type: currentFile.type,
          });
          mobileAppModel.createContentPreview(file);
        },
        currentFile.type,
        qualityArgument
      );
      onDialogClose();
    },
    [onDialogClose, currentFile]
  );

  const items = [
    {
      variant: "image",
      name: "Изображение",
      iconButton: <ImageIcon />,
    },
    {
      variant: "video",
      name: "Видео YouTube",
      iconButton: <VideoIcon />,
    },
  ];

  return (
    <>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ marginTop: "8px" }}
      >
        <Paper elevation={4}>
          {items?.map(({ name, iconButton, variant }) => (
            <MenuItemContainer key={name}>
              {variant === "image" ? (
                <div
                  onClick={() => {
                    fileInput.current.click();
                  }}
                >
                  {iconButton}
                  <span>{name}</span>
                  <ImportFileUpload
                    type="file"
                    accept={acceptedImageTypes}
                    ref={fileInput}
                    onChange={({ target: { files } }) => {
                      handleDrop(files[0]);
                    }}
                  />
                </div>
              ) : (
                <div onClick={handleUpload}>
                  {iconButton}
                  <span>{name}</span>
                </div>
              )}
            </MenuItemContainer>
          ))}
        </Paper>
      </Popover>
      <ImageCropperDialog
        image={currentFile?.image}
        onClose={onDialogClose}
        onSubmit={onSubmitCropper}
        open={currentFile !== null}
      />
    </>
  );
};
