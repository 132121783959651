import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import {secondary, lightBlack, primary, ValueTxt, Cell} from "../shared/Style/Style";

export const HeadersGrid = styled.div`
  grid-column: 1 / span 4;
  grid-row: 1;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 2fr;
  grid-template-rows: 46px;
  background-color: ${lightBlack};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  align-items: center;
`;

export const InnerTableHeadersGrid = styled(HeadersGrid)`
  grid-template-columns: 1fr 1fr 1fr;
`;

export const  ProjectsHeadersGrid = styled(HeadersGrid)`
  grid-template-columns: 1fr 0.7fr;
  position: sticky;
  top: 0;
  z-index: 2;
  border-right: 1px solid rgba(196,196,196,0.3);
`;

export const ProjectsTableContainer = styled.div`
  overflow-y: auto;
`;

export const ProjectsTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  max-height: ${`${(46 * 4)}px`}
`;

export const InnerTableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const InnerRateGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 46px;
  grid-column: 1 / 3;
  grid-row: 2;
`;

export const ProjectRateGrid = styled(InnerRateGrid)`
  grid-template-columns: 1fr 0.7fr;
  grid-column: 1 / 3;
  grid-row: unset;
`;

export const EmployeeRateGrid = styled.div`
  display: grid;
  grid-template-columns:  3fr 4fr;
  grid-column: 1 / 3;
  grid-row: 2;
`;

export const LeftTableSection = styled.div`
  display: grid;
  grid-template-columns:  2fr 1fr;
`;

export const RightTableSection = styled.div`
  display: grid;
  grid-template-columns:  2fr 1fr 1fr;
`;

export const KPIRateGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 1fr 1fr;
  grid-template-rows: 46px;
  grid-column: 1 / 3;
  grid-row: 2;
`;

export const InnerTableRateGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-template-rows: 46px;
  grid-column: 1 / 3;
  grid-row: 2;
`;

export const EmptyRateGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 46px;
`;

interface ICustomTotalSalaryGridProps {
  bonusesLength: number;
}

export const TotalSalaryGrid = styled.div<ICustomTotalSalaryGridProps>`
  display: grid;
  grid-template-columns: 266px 165px;
  grid-template-rows: ${(props) =>
    props.bonusesLength ? `repeat(${props.bonusesLength + 1}, 44px)` : ""};
  grid-column-end: 2;
  grid-row: 3;
`;

export const PaymentsGrid = styled.div`
  display: grid;
  grid-template-columns: 206px 166px;
  grid-template-rows: repeat(2, 44px);
  grid-column: 2 / 3;
  grid-row: 3;
`;

export const DaysGrid = styled.div<{days: number[]}>`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.days.length}, ${1280 / props.days.length}px)`};
  grid-template-rows: 44px;
  grid-column-start: 3;
  grid-row: 2;
`;

export const EmptyCell = styled.div`
  box-sizing: border-box;
  margin: 0;
  position: relative;
`;

export const HeaderCell = styled(Cell)`
  border-left: none;
  border-bottom: none;
  border-top: none;
  position: relative;
  border-right: 1px solid rgba(196, 196, 196, 0.3);
  &:last-of-type {
    border-right: none;
  }
`;

export const DayDateCell = styled(Cell)`
  font-size: 13px;
  background-color: #000;
  grid-row: 1;
`;

export const GreenCell = styled(Cell)`
  background-color: #409b6a;
`;

export const CursorPointer = styled.div`
  cursor: pointer;
`;

export const BonusCell = styled(Cell)`
  background-color: #697c86;
  grid-column: 1;
`;

export const TotalValueCell = styled(Cell)`
  grid-column: 2;
`;

export const TotalCell = styled(BonusCell)`
  background-color: ${primary};
  cursor: pointer;
  text-transform: uppercase;
`;

const Txt = styled.p`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fff;
`;

export const LightValueTxt = styled.p`
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #fff;
`;

export const ValueHoursTxt = styled(ValueTxt)`
  font-size: 12px;
`;

export const EditBtn = styled(IconButton)`
  position: absolute;
  padding: 0;
  z-index: 2;
  top: 47%;
  transform: translateY(-50%);
  right: 40px;
  color: ${secondary};
  cursor: pointer;
`;

export const EditBtnForSmCell = styled(EditBtn)`
  right: 17px;
`;

export const DeleteBtn = styled(IconButton)`
  position: absolute;
  z-index: 2;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  cursor: pointer;
  right: 15px;
  color: #e85050;
`;

interface IBonusInputTitleProps {
  value: string | number | undefined;
}

export const BonusInputTitle = styled.input<IBonusInputTitleProps>`
  text-align: center;
  cursor: auto;
  width: 100%;
  display: block;
  margin: 13px auto;
  padding: 0;
  padding: 0 50px 0 25px;
  background-color: transparent;
  border: none;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  font-weight: bold;
  outline: none;
`;

export const BonusInputValue = styled(BonusInputTitle)`
  text-align: center;
  padding: 0 40px;
  font-weight: normal;
  color: ${lightBlack};
`;

export const AddBonusBtn = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 4px;
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #fff;
  top: 50%;
  right:0;
  transform: translate(-50%, -50%);
  color: ${primary};

  &::after {
    content: '+';
    display: block;
    position: absolute;
    top: 1px;
    left: 0;
    right: 0px;
    margin: auto;
  }
`;

export const NoPayrollsTablePlaceholder = styled.div`
  margin-top: 40px;
  height: 250px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: ${lightBlack};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  color: ${secondary};
`;
