import styled from 'styled-components';
import { Button, Select, FormControl, Box } from '@material-ui/core';
import {ButtonCustom, primary, secondary, lightBlack, white} from '../shared/Style/Style';

export const ArrowBox = styled(Box)<{variant?: string}>`
  position: absolute;
  top: 50%;
  ${props => props.variant === 'left'
    ? 'left: 0;'
    : 'right: 0;'
  }
  transform: translateY(-50%);
`;

export const ReportNavButtonCustom = styled(Button)<{
  isActive?: boolean;
}>`
  &.MuiButton-root {
    background-color: ${(props) =>
  props.isActive
    ? props.color
      ? `${props.color}`
      : `${primary}`
    : `${secondary}`};
    color: ${white};
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    border-radius: 2px;

    &:hover {
      background-color: ${(props) => (props.isActive ? `${primary}` : `${secondary}`)};
    }

    &:focus {
      background-color: ${(props) => (props.isActive ? `${primary}` : `${secondary}`)};
     box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    }

    &:disabled {
      background-color: ${secondary};
    }
  }
`;

export const CreatButton = styled(ButtonCustom)`
  background-color: ${primary};
  width: 100%;
  height: 45px;

  &::after {
    content: '+';
    position: relative;
    right: 45%;
    bottom: 1px;
    color: ${props => props.disabled ? `${secondary}` : `${primary}`};
    width: 18px;
    font-size: 18px;
    line-height: 18px;
    background-color: #fff;
    margin-left: 20px;
    border-radius: 4px;
  }
`;

export const ReportFormControl = styled(FormControl)`
  color: ${lightBlack};
  background-color: ${white};

  & .MuiInput-underline {
    border-bottom-color: transparent;

    &:after {
      border-bottom-color: transparent;
    }

    &:before {
      border-bottom-color: transparent;
    }

    &:hover:not(.Mui-disabled):before {
      border-bottom-color: transparent;
    }
  }

  .MuiInput-underline.Mui-error:after {
    border-bottom-color: red;
  }
`;

export const ReportSelect = styled(Select)`
  & .MuiSelect-select {
    padding: 7px 24px;
    line-height: 40px;
  }
`;
