export const increaseWeek = (currentMonth: Date = new Date(), lastDate: Date): Date => {
  const limit = [
    lastDate.getFullYear(),
    lastDate.getMonth() + 1,
    lastDate.getDate(),
  ];

  const year = currentMonth.getFullYear();
  const month = currentMonth.getMonth() + 1;
  const newDay = currentMonth.getDate() + 7 * 6;

  const lastDayOfMonthAfterNext = (new Date(year, month + 2, 0)).getDate();
  const lastDayOfNextMonth = (new Date(year, month + 1, 0)).getDate();
  const lastDayOfCurrentMonth = (new Date(year, month, 0)).getDate();

  let result;

  if (newDay > lastDayOfCurrentMonth) {
    let diff = newDay - lastDayOfCurrentMonth;

    if (diff > lastDayOfNextMonth) {
      diff -= lastDayOfMonthAfterNext;

      month + 2 > 12
        ? result = [year + 1, month + 2 - 12, diff]
        : result = [year, month + 2, diff];
    } else {
      month + 1 > 12
        ? result = [year + 1, month + 1 - 12, diff]
        : result = [year, month + 1, diff];
    }
  } else {
    result = [year, month, newDay];
  }

  if (
    result[0] < limit[0]
    || (result[0] === limit[0] && result[1] < limit[1])
    || (result[0] === limit[0] && result[1] === limit[1] && result[2] < limit[2])
  ) {
    return new Date(result[0], result[1] - 1, result[2]);
  }

  return new Date(limit[0], limit[1] - 1, limit[2]);
};



export const decreaseWeek = (currentMonth: Date = new Date(), lastMonth: Date = new Date('2016-12')): Date => {
  const limit = [
    lastMonth.getFullYear(),
    lastMonth.getMonth() + 1,
    lastMonth.getDate(),
  ];

  const year = currentMonth.getFullYear();
  const month = currentMonth.getMonth() + 1;
  const newDay = currentMonth.getDate() - 7 * 6;

  const lastDayOfPrevMonth = (new Date(year, month - 1, 0)).getDate();
  const lastDayOfMonthBeforePrev = (new Date(year, month - 2, 0)).getDate();

  let result;

  if (newDay < 1) {
    let diff = newDay + lastDayOfPrevMonth;

    if (diff < 1) {
      diff += lastDayOfMonthBeforePrev;

      month - 2 < 1
        ? result = [year - 1, month - 2 + 12, diff]
        : result = [year, month - 2, diff];
    } else {
      month - 1 < 1
        ? result = [year - 1, month - 1 + 12, diff]
        : result = [year, month - 1, diff];
    }
  } else {
    result = [year, month, newDay];
  }

  if (
    result[0] > limit[0]
    || (result[0] === limit[0] && result[1] > limit[1])
    || (result[0] === limit[0] && result[1] === limit[1] && result[2] > limit[2])
  ) {
    return new Date(result[0], result[1] - 1, result[2]);
  }

  return new Date(limit[0], limit[1] - 1, limit[2]);
}
