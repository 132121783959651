import React, { useState } from "react";

import { Box, Container } from "@material-ui/core";
import { DateRange } from "@material-ui/icons";

import { MasteringMoney } from "./Tables/MasteringMoney";
import { CompletionPercent } from "./Tables/CompletionPercent";
import { TasksInProgress } from "./Tables/TasksInProgress";
import { AddedTasks } from "./Tables/AddedTasks";
import { OverdueTasks } from "./Tables/OverdueTasks";
import { EmployeesWorkload } from "./Tables/EmployeesWorkload";

import {
  DateRangePicker,
  IDateRange,
} from "../shared/DateRangePicker/DateRangePicker";

import {
  ButtonCustomWithDarkText,
  DatePickerButton,
  Header,
} from "../shared/Style/Style";
import { DashboardGrid } from "./Dashboard.style";

export interface IDashboardReportProps {
  projectId: string;
  setProjectId?(id: string): void;
  dateRange: IDateRange;
}

export const Dashboard = () => {
  const InitialDateRange: IDateRange = {
    startDate: undefined,
    endDate: undefined,
  };
  const [dateRange, setDateRange] = useState(InitialDateRange);
  const [isDateRangePickerOpen, setDateRangePickerOpen] = useState(false);

  const [projectId, setProjectId] = useState("");

  return (
    <Container maxWidth="lg">
      <Header>Дашборд</Header>

      <Box display="flex" alignItems="center" mt={5} mb={5}>
        <DatePickerButton
          onClick={() => setDateRangePickerOpen(true)}
          isActive={!!dateRange.endDate || !!dateRange.startDate}
        >
          <Box mr={1} lineHeight={1}>
            <DateRange />
          </Box>
          Период
        </DatePickerButton>

        <Box ml={2}>
          <ButtonCustomWithDarkText
            active={!dateRange.endDate && !dateRange.startDate}
            onClick={() => setDateRange(InitialDateRange)}
            style={{ textTransform: "none" }}
          >
            За всё время
          </ButtonCustomWithDarkText>
        </Box>
      </Box>

      <Box mb={2.5}>
        <MasteringMoney
          dateRange={dateRange}
          projectId={projectId}
          setProjectId={setProjectId}
        />
      </Box>

      <DashboardGrid>
        <CompletionPercent dateRange={dateRange} projectId={projectId} />

        <TasksInProgress dateRange={dateRange} projectId={projectId} />

        <EmployeesWorkload dateRange={dateRange} projectId={projectId} />

        <AddedTasks dateRange={dateRange} projectId={projectId} />

        <OverdueTasks dateRange={dateRange} projectId={projectId} />
      </DashboardGrid>

      <DateRangePicker
        setDateRange={setDateRange}
        open={isDateRangePickerOpen}
        onClose={() => setDateRangePickerOpen(false)}
      />
    </Container>
  );
};
