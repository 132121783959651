import React from "react";

import { useRouteMatch } from "react-router-dom";

import { Box } from "@material-ui/core";

import { ButtonCustomWithDarkText, CustomNavBar, CustomNavLink } from "../shared/Style/Style";

export const AnnouncementsNavBar = () => {
  const { path } = useRouteMatch<{ path: string }>();

  return (
    <Box
      display='flex'
      alignItems='center'
    >
      <CustomNavBar>
        <CustomNavLink
          to={'/announcements/notifications'}
        >
          <Box mr={1}>
            <ButtonCustomWithDarkText
              type="button"
              variant="contained"
              size="small"
              active={path.includes("notifications")}
            >
              Уведомления
            </ButtonCustomWithDarkText>
          </Box>
        </CustomNavLink>

        <CustomNavLink
          to={'/announcements/news'}
        >
          <Box mr={1}>
            <ButtonCustomWithDarkText
              type="button"
              variant="contained"
              size="small"
              active={path.includes("news")}
            >
              Новости
            </ButtonCustomWithDarkText>
          </Box>
        </CustomNavLink>
      </CustomNavBar>
    </Box>
  )
}
