export const inputButtonText = "Прикрепить";
export const inputPlaceholderText = "Ссылка";

export const requiredInputsNumber = 1;
export const inputsArray = Array(requiredInputsNumber).fill("");

export const initialData = [
  {
    id: "",
    link: "",
    order: -1,
  },
];

export const refetchMessage = {
  create: "Видео успешно добавлено",
  delete: "Видео успешно удалено"
};
