import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  DeleteUserDocument,
  DeleteUserMutation,
  GetArBookUsersCountLightDocument,
  GetUsersAppDocument,
  GetUsersAppQuery,
  GetUsersInput,
  User,
  UserRoleEnum,
} from "../../generated/graphql";
import { MobileAppUsersNavBar } from "./MobileAppUsersNavBar";
import { PeopleSearchBar } from "../PeopleSearchBar";
import { useResetPageOnSearch, useRequestError } from "../../hooks"
import { CustomTablePagination } from "../shared/CustomTablePagination";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { Menu } from "../shared/Menu";
import { CircularProgress, Container, Table } from "@material-ui/core";
import {
  MainTableHeaderTitleTeacher,
  MobileAppUsersRow,
  MobileAppWithoutUsersRow,
} from "./MobileAppUsers.style";
import {
  Header,
  LoadingBox,
  MainTableCell,
} from "../shared/Style/Style";
import { TABLE_HEADER, USER_ROLES } from "./constants";
import {useGetArBookUsersCountLight} from "./hooks";
import { API } from '../../core/axios/client';
import { CustomIconButton } from '../shared/Buttons/CustomIconButton';
import { GetApp } from '@material-ui/icons';
import { downloadFile } from '../../helpers/downloadFile.helper';

export interface ActiveFilterMobUserState {
  role?: UserRoleEnum[];
  statuses: string[];
  searchKey?: string;
}

export const MobileAppUsers = () => {
  const { push } = useHistory();
  const [choseMobUser, setChoseEvent] = useState<User>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [mobileAppUsersError, setMobileAppUsersError] = useState(null);
  const [mobileAppUsersLoading, setMobileAppUsersLoading] = useState(false);
 

  const [activeFilter, setActiveFilter] = useState<ActiveFilterMobUserState>({
    role: [UserRoleEnum.Teacher],
    statuses: ["VERIFIED"],
  });

  const searchKey = activeFilter.searchKey ? activeFilter.searchKey : undefined;

  useResetPageOnSearch({ searchKey, setPage });

  const getUsersData: GetUsersInput = {
    limit: rowsPerPage,
    skip: page * rowsPerPage,
    roles: activeFilter.role,
    statuses: activeFilter.statuses,
    searchKey,
  };

  const { data, loading, error } = useQuery<GetUsersAppQuery>(GetUsersAppDocument, {
    variables: { getUsersData },
    fetchPolicy: "cache-and-network",
  });

  const [deleteUser, {
    loading: deleteUserLoading,
    error: deleteUserError,
  }] = useMutation<DeleteUserMutation>(DeleteUserDocument, {
    variables: { userId: choseMobUser?.id },
    refetchQueries: [{
      query: GetArBookUsersCountLightDocument
    }, {
      query: GetUsersAppDocument,
      variables: {getUsersData},
    }],
  });

  const deleteMobUserHandler = async (e: React.MouseEvent<HTMLElement>, id: string) => {
    try {
      e.stopPropagation();
      await deleteUser({ variables: { userId: id } });
    } catch (error) {
      console.error(error);
    }
  };

  const { getARBookUsersCountLight, getArBookUsersCountLightLoading, getArBookUsersCountLightError } = useGetArBookUsersCountLight();
  const totalUserCountByRole = getARBookUsersCountLight?.find(
    (usersCount) => usersCount?.role === activeFilter?.role[0]
  )?.count;

  const downloadUsers = async () => {
    try {
      setMobileAppUsersLoading(true);
      
      const response = await API.post('/users/export', {
        roles: activeFilter.role,
      })

      downloadFile(response, 'users.csv')
    } catch (error) {
      console.error(error)
      setMobileAppUsersError(error)
    } finally {
      setMobileAppUsersLoading(false);
    }
  }


  const gqlError = deleteUserError || mobileAppUsersError;
  const errorMessage = (deleteUserError && "При удалении пользователя произошла ошибка")
    || (mobileAppUsersError && "Ошибка при загрузке списка пользователей");

  const { requestError, setRequestError } = useRequestError(gqlError);

  const menuItem = (id: string) => [{
    name: "Редактировать",
    action: () => push(`/mobileAppUsers/${id}/editUser`),
  }, {
    name: "Удалить",
    action: (e: React.MouseEvent<HTMLElement>) => deleteMobUserHandler(e, id),
    color: "red",
  }];

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let content = null;
  let pagination = null;

  if (loading) {
    content = (
      <tbody>
        <MobileAppWithoutUsersRow>
          <MainTableCell>
            <LoadingBox>
              <CircularProgress color="inherit" />
            </LoadingBox>
          </MainTableCell>
        </MobileAppWithoutUsersRow>
      </tbody>
    );
  }

  if (error) {
    content = (
      <tbody>
        <MobileAppWithoutUsersRow>
          <MainTableCell>
            При загрузке пользователей произошла ошибка
          </MainTableCell>
        </MobileAppWithoutUsersRow>
      </tbody>
    );
  }

  const disabledOnLoading = deleteUserLoading || loading;

  if (data) {
    const users = data.getUsers.users;

    content = users?.length > 0 ? (
      <>
        <thead>
          <MobileAppUsersRow>
            {TABLE_HEADER.map((header) => (
              <MainTableHeaderTitleTeacher key={header}>{header}</MainTableHeaderTitleTeacher>
            ))}
             <MainTableHeaderTitleTeacher>
                {!mobileAppUsersLoading ? (
                  <CustomIconButton
                    mainColor='black'
                    callback={downloadUsers}
                    children={<GetApp />}
                  />
                ) : (
                  <LoadingBox width={30}>
                    <CircularProgress color='inherit' />
                  </LoadingBox>
                )}
              </MainTableHeaderTitleTeacher>
          </MobileAppUsersRow>
        </thead>
        <tbody>
          {users?.map((user) => (
            <MobileAppUsersRow
              key={user.id}
              style={{ cursor: "pointer" }}
              onClick={() => push(`/mobileAppUsers/${user.id}/editUser`)}
            >
              <MainTableCell>{user.firstName}</MainTableCell>
              <MainTableCell>{user.lastName}</MainTableCell>
              <MainTableCell>+{user.phone}</MainTableCell>
              <MainTableCell>{user.country?.nameUKR}</MainTableCell>
              <MainTableCell>
                {USER_ROLES?.find((userRole) => userRole.role === user.role.role)?.name}
              </MainTableCell>
              <MainTableCell onMouseOver={() => setChoseEvent(user)}>
                <Menu
                  vertical={36}
                  horizontal={12}
                  width={170}
                  disabled={disabledOnLoading}
                  items={menuItem(user.id)}
                />
              </MainTableCell>
            </MobileAppUsersRow>
          ))}
        </tbody>
      </>
    ) : (
      <tbody>
        <MobileAppWithoutUsersRow>
          <MainTableCell>Список пользователей пуст</MainTableCell>
        </MobileAppWithoutUsersRow>
      </tbody>
    );

    pagination = (
      <>
        {users?.length > 0 ? (
          <CustomTablePagination
            rowsPerPageOptions={[10, 20]}
            count={totalUserCountByRole || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header>Пользователи</Header>

      <PeopleSearchBar
        label="Поиск по имени или номеру"
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />

      <MobileAppUsersNavBar
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        setPage={setPage}
      />

      {pagination}

      <Table>
        {content}
      </Table>

      {pagination}

      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
    </Container>
  );
};
