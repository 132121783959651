import React, { useState } from "react";
import { UserFields } from "../Style";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@apollo/client";
import {
  GetSchoolRegionsDocument,
  GetSchoolRegionsQuery,
  School,
} from "../../../generated/graphql";
import { LoadingBox } from "../../shared/Style/Style";
import { CircularProgress } from "@material-ui/core";

interface IUserRegionSelect {
  getAllItemsParams: {
    limit: number;
    skip: number;
  };
  schoolRegionId: string;
  countryId: string;

  setMobileUsers(regionId: string): void;

  schools: School[];
}

export const UserRegionSelect = ({
  getAllItemsParams,
  countryId,
  schoolRegionId,
  setMobileUsers,
  schools,
}: IUserRegionSelect) => {
  const {
    data: getSchoolRegionsData,
    loading: getSchoolRegionsLoading,
    error: getSchoolRegionsError,
  } = useQuery<GetSchoolRegionsQuery>(GetSchoolRegionsDocument, {
    variables: {
      ...getAllItemsParams,
      countryId,
    },
  });

  if (getSchoolRegionsLoading) {
    return (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  const schoolRegions = getSchoolRegionsData?.getSchoolRegions || [];

  return (
    <Autocomplete
      options={schoolRegions?.map((schoolRegion) => schoolRegion?.id)}
      getOptionLabel={(option) =>
        schoolRegions?.find((schoolRegion) => schoolRegion?.id === option)
          ?.name || ""
      }
      onChange={(e, value) => setMobileUsers(value)}
      value={
        schoolRegions?.find(
          (schoolRegion) => schoolRegion?.id === schoolRegionId
        )?.id || null
      }
      disabled={
        getSchoolRegionsLoading ||
        !!getSchoolRegionsError ||
        !countryId ||
        !!schools.length
      }
      renderInput={(params) => (
        <UserFields {...params} label="Регион" variant="standard" />
      )}
    />
  );
};
