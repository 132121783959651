import { useState, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  GetNewLessonReflectionReportsDocument,
  GetNewLessonReflectionReportsQuery,
} from "../../../generated/graphql";
import { useRequestError } from "../../../hooks";
import { DateRange } from "@material-ui/icons";
import { Box, Grid } from "@material-ui/core";
import { DateRangePicker, IDateRange } from "../../shared/DateRangePicker/DateRangePicker";
import { ExcelExporter } from "../../shared/ExcelExporter";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { LoadingProgress } from "../../shared/LoadingProgress";
import { ReportsContainer } from "../ReportsContainer";
import { DatePickerButton } from "../../shared/Style/Style";

const InitialDateRange: IDateRange = {
  startDate: undefined,
  endDate: undefined,
};

export const ReflexionReport = () => {
  const [dateRange, setDateRange] = useState(InitialDateRange);
  const [isDateRangePickerOpen, setDateRangePickerOpen] = useState(false);
  const startDate = dateRange.startDate;
  const endDate = dateRange.endDate;
  const isDatePickerActive = !!dateRange.endDate || !!dateRange.startDate;
  const isExportNewLessonReflectionReportsDisabled = !dateRange.endDate && !dateRange.startDate;

  const [getNewLessonReflectionReports, {
    data: newLessonReflectionReportsData,
    loading: newLessonReflectionReportsLoading,
    error: newLessonReflectionReportsError,
  }] = useLazyQuery<GetNewLessonReflectionReportsQuery>(GetNewLessonReflectionReportsDocument, {
    fetchPolicy: "network-only",
  });

  const newLessonReflectionReports = newLessonReflectionReportsData?.getNewLessonReflectionReports;

  const { requestError, setRequestError, errorMessage } = useRequestError(
    newLessonReflectionReportsError,
    "Ошибка при загрузке отчета по рефлексии"
  );

  const handleNewLessonReflectionReports = () => {
    return getNewLessonReflectionReports({
      variables: {
        from: startDate,
        to: endDate,
      },
    });
  };

  const loading = <LoadingProgress />;

  return (
    <ReportsContainer>
      <Grid container justifyContent="center" spacing={2}>
        <Grid xs={3} item>
          <h2>По Рефлексии</h2>
        </Grid>
        <Grid xs={9} item>
          <Box display="flex" alignItems="center" height="100%">
            <DatePickerButton
              onClick={() => setDateRangePickerOpen(true)}
              isActive={isDatePickerActive}
            >
              <DateRange />
              Период
            </DatePickerButton>

            {!newLessonReflectionReportsLoading ? (
              <ExcelExporter
                data={newLessonReflectionReports}
                fileName="По рефлексии"
                disabled={isExportNewLessonReflectionReportsDisabled}
                onDataLoad={handleNewLessonReflectionReports}
              />
            ) : loading}
          </Box>
        </Grid>
      </Grid>

      <DateRangePicker
        setDateRange={setDateRange}
        open={isDateRangePickerOpen}
        onClose={() => setDateRangePickerOpen(false)}
      />

      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
    </ReportsContainer>
  );
};
