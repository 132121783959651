import { useReactiveVar } from "@apollo/client";
import { robotsState } from "../state";

export const useGetStageTypeId = () => {
    const stage = useReactiveVar(robotsState.stageGeneralInfo);
    const robotStageTypes = useReactiveVar(robotsState.robotStageTypes);

    if (stage?.name && robotStageTypes.length > 0) {
        const stageType = robotStageTypes.find(stageType => stageType.name.toString() === stage?.name?.slice(-1)) ?? {};

        return stageType?.id;
    }
};
 