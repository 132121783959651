import { AchieveTypeEnum } from "../../generated/graphql";
import { participantsMap } from "./constants";

export const getAchieveLockedState = (isLocked: boolean) => {
  return isLocked ? "Закрытая" : "Открытая";
};

export const getAchievePublishedStateTitle = (isPublished: boolean) => {
  return isPublished ? "Опубликовано" : "Не опубликовано";
};

export const getAchievePublishedStateColors = (isPublished: boolean) => {
  return isPublished ? "#FFB61D" : "red";
};

export  const displayParticipants = (...participants: boolean[]) => {
  return Array.from(participantsMap)
    .filter((_, i) => participants[i])
    .map(([participant]) => `${participant} `);
};

export type AchievementFormValues = {
  name: string,
  descriptionProgress: string,
  descriptionDone: string,
  type: AchieveTypeEnum,
}

const requiredValues = ['name', 'descriptionDone', 'descriptionProgress', 'type'];

export const validateForm = (values: AchievementFormValues) => {
  const errors: {[key:string]: string} = {};

  for (let key in values) {
    if (!values[key] && requiredValues.find(v => v === key)) {
      errors[key] = "Это поле обязательно";
    }
  }

  if (values.name?.length > 20) {
    errors.name = "Максимальное кол-во символов 20";
  }

  if (values.descriptionProgress?.length > 200) {
    errors.descriptionProgress = "Максимальное кол-во символов 200";
  }

  if (values.descriptionDone?.length > 200) {
    errors.descriptionDone = "Максимальное кол-во символов 200";
  }

  return errors;
};
