import React from "react";
import { CreatedLessonsStats } from "./TeachersActivity/CreatedLessonsStats";
import { ConnectionParticipants } from "./ConnectionParticipants/ConnectionParticipants";

export const Analytics = () => {
  return (
    <div>
      <ConnectionParticipants />
      <CreatedLessonsStats />
    </div>
  );
};
