import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  DeleteProjectTaskStatusDocument,
  DeleteProjectTaskStatusMutation,
  GetProjectTaskStatusesByProjectDocument,
  ProjectTaskStatus,
  UpdateProjectTaskStatusDocument,
  UpdateProjectTaskStatusMutation,
} from "../../../generated/graphql";
import { Grid, TextField } from "@material-ui/core";
import { EditIconButton } from "../../shared/Buttons/EditIconButton";
import { DeleteIconButton } from "../../shared/Buttons/DeleteIconButton";
import { SaveIconButton } from "../../shared/Buttons/SaveIconButton";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { useStyles } from "../../shared/Style/Style";
import { ItemContainer, ButtonsContainer } from "../KanbanSettings.style";

interface ProjectTaskStatusItemProps {
  status: ProjectTaskStatus;
  label: string;
  projectId: string;
}

export const ProjectTaskStatusItem = ({
  status,
  label,
  projectId,
}: ProjectTaskStatusItemProps) => {
  const classes = useStyles();
  const [requestError, setRequestError] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);

  const [value, setValue] = useState<string>(status.name);

  const [
    updateProjectTaskStatus,
    {
      data: updateProjectTaskStatusData,
      loading: updateProjectTaskStatusLoading,
      error: updateProjectTaskStatusError,
    },
  ] = useMutation<UpdateProjectTaskStatusMutation>(
    UpdateProjectTaskStatusDocument,
    {
      refetchQueries: [
        {
          query: GetProjectTaskStatusesByProjectDocument,
          variables: {
            projectId,
          },
        },
      ],
    }
  );

  const [
    deleteStatus,
    { loading: deleteStatusLoading, error: deleteStatusError },
  ] = useMutation<DeleteProjectTaskStatusMutation>(
    DeleteProjectTaskStatusDocument,
    {
      refetchQueries: [
        {
          query: GetProjectTaskStatusesByProjectDocument,
          variables: {
            projectId,
          },
        },
      ],
    }
  );

  useEffect(() => {
    if (updateProjectTaskStatusData) {
      setEdit(false);
    }
  }, [updateProjectTaskStatusData]);

  useEffect(() => {
    if (updateProjectTaskStatusError || deleteStatusError) {
      setRequestError(true);
    }
  }, [updateProjectTaskStatusError, deleteStatusError]);

  const onSave = (): void => {
    if (value !== status.name) {
      updateProjectTaskStatus({
        variables: {
          name: value,
          id: status.id,
        },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12} item>
        <ItemContainer>
          <TextField
            disabled={!edit}
            className={classes.root}
            fullWidth
            label={label}
            variant="filled"
            type="text"
            value={
              (value === "new" && "новые") ||
              (value === "inProgress" && "в работе") ||
              (value === "done" && "выполнены") ||
              value
            }
            onChange={(e) => setValue(e.target.value)}
          />

          {!status.isBase && (
            <ButtonsContainer>
              {edit ? (
                <SaveIconButton
                  disabled={edit && value === status.name}
                  save={() => onSave}
                />
              ) : (
                <EditIconButton
                  disabled={edit || updateProjectTaskStatusLoading || deleteStatusLoading}
                  active={!edit}
                  edit={() => {
                    setEdit(true);
                  }}
                />
              )}
              <DeleteIconButton
                disabled={false}
                deleteHandler={() => {
                  deleteStatus({
                    variables: {
                      id: status.id,
                    },
                  });
                }}
                item="статус"
                fz={20}
              />
            </ButtonsContainer>
          )}
        </ItemContainer>
      </Grid>
      {
        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={
            (updateProjectTaskStatusError &&
              "Ошибка обновления статуса для поекта") ||
            (deleteStatusError && "Ошибка при удалении статуса для поекта")
          }
        />
      }
    </Grid>
  );
};
