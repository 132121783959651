import React, { useState } from "react";

import { IKnowledgeBaseBlockInnerProps } from "../../KnowledgeBaseBlockItem";
import { KnowledgeBaseBlockTypeEnum } from "../../../../generated/graphql";

import { FormatBold, FormatItalic, FormatUnderlined } from "@material-ui/icons";

import { Collapse, Box } from "@material-ui/core";
import { BlockInput, TextBlockLabel, TextBlockContent } from "../../KnowledgeBaseBlockItem.style";

import { getKnowledgeBaseInputLabel } from "../../../shared/Utils/OtherOperations/getKnowledgeBaseInputLabel";
import { CustomIconButton } from "../../../shared/Buttons/CustomIconButton";

import { lightBlack } from "../../../shared/Style/Style";


const formatButtons = [
  { name: 'b', icon: <FormatBold /> },
  { name: 'i', icon: <FormatItalic /> },
  { name: 'u', icon: <FormatUnderlined /> },
];


export const TextBlock = ({ disabled, block, blockTypeData, setBlockTypeData }: IKnowledgeBaseBlockInnerProps) => {
  const isVideoType = block.type === KnowledgeBaseBlockTypeEnum.Video;
  const isTextType = block.type === KnowledgeBaseBlockTypeEnum.Text;

  const [selectedRange, setSelectedRange] = useState([0, 0]);

  const textWrapHandler = (tag: string) => {
    const chosenPart = blockTypeData.content.slice(...selectedRange);
    const result = blockTypeData.content.slice(0, selectedRange[0]) + `<${tag}>${chosenPart}</${tag}>` + blockTypeData.content.slice(selectedRange[1])

    setBlockTypeData({
      ...blockTypeData,
      content: result
    })
  }

  return (
    <>
      {isTextType
        ? (disabled
            ? (
              <>
                <TextBlockLabel
                  content={blockTypeData.content}
                >
                  {getKnowledgeBaseInputLabel(block.type)}
                </TextBlockLabel>

                <TextBlockContent dangerouslySetInnerHTML={{ __html: blockTypeData.content }}/>
              </>
            )
            : (
            <BlockInput
              fullWidth
              multiline={isTextType}
              rows={5}
              disabled={disabled}
              type={block.type}
              label={getKnowledgeBaseInputLabel(block.type)}
              value={isVideoType ? blockTypeData.video : blockTypeData.content }
              onChange={({ target: { value } }) => {
                isVideoType
                  ? setBlockTypeData({ ...blockTypeData, video: value })
                  : setBlockTypeData({ ...blockTypeData, content: value })
              }}
              onSelect={((event) => {
                const target = event.target as HTMLInputElement
                setSelectedRange([target.selectionStart, target.selectionEnd]);
              })}
              InputProps={{
                startAdornment: isTextType && (
                  <Box
                    display='flex'
                    position='absolute'
                    top={-36}
                    right={0}
                  >
                    {formatButtons.map(({ name, icon }) => (
                      <CustomIconButton
                        key={name}
                        disabled={disabled}
                        children={icon}
                        mainColor={lightBlack}
                        callback={() => textWrapHandler(name)}
                      />
                    ))}
                  </Box>
                )
              }}
            />
          )
        )
        : (
          <BlockInput
            fullWidth
            multiline={isTextType}
            rows={5}
            disabled={disabled}
            type={block.type}
            label={getKnowledgeBaseInputLabel(block.type)}
            value={isVideoType ? blockTypeData.video : blockTypeData.content }
            onChange={({ target: { value } }) => {
              isVideoType
                ? setBlockTypeData({ ...blockTypeData, video: value })
                : setBlockTypeData({ ...blockTypeData, content: value })
            }}
          />
        )
      }

      <Collapse
        in={isVideoType && !!block.video}
      >
        <Box
          mt='20px'
          textAlign='center'
        >
          <iframe
            frameBorder="0"
            width="100%"
            height="480"
            src={block.video}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </Box>
      </Collapse>
    </>
  )
}