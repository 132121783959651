import React from 'react';
import { Box, Dialog, DialogContent } from "@material-ui/core";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { ButtonCustom, ButtonsWrapper, CancelButton } from "../../shared/Style/Style";
import { AddModalName } from "../Training.style";



export const TrainingModalName = ({setName,createHandleTraining,open,close}) => {

  const closeHandler = () => {
    close();
  };


  return (

      <Dialog open={open}>
        <DialogContent style={{ minWidth: "400px", padding: '30px' }}>
          <CloseIconButton close={closeHandler} position="absolute" main_color="black" />
          <Box textAlign="center" mb={2} mt={3}>
            <AddModalName
              label="Название"
              fullWidth
              variant="filled"
              onChange={(e)=>setName(e.target.value)}
            />
          </Box>

          <ButtonsWrapper>
            <CancelButton
              onClick={closeHandler}
              disabled={false}
            >
              Отмена
            </CancelButton>

            <ButtonCustom
              onClick={createHandleTraining}
              disabled={false}
            >
              Продолжить
            </ButtonCustom>
          </ButtonsWrapper>
        </DialogContent>
      </Dialog>

  );
};
