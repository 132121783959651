import React, { useEffect, useState } from "react";
import {
  GetUserAppDocument,
  GetUserAppQuery,
  GetUserRolesDocument,
  GetUserRolesQuery,
  User,
  UserRole,
  UserRoleEnum,
} from "../../../generated/graphql";
import { Autocomplete } from "@material-ui/lab";
import { UserFields } from "../Style";
import { useQuery } from "@apollo/client";

interface IUserRole{
  role:UserRole;
  setUserRoleId(number:number):void;
}


export const UserRoleSelect = ({ role, setUserRoleId }:IUserRole) => {
  const [userRoles,setUserRoles]=useState<UserRole[]>([])

  const { data, loading, error } =
    useQuery<GetUserRolesQuery>(GetUserRolesDocument,{
      fetchPolicy:"cache-and-network"
    });

  useEffect(() => {
    if (data) {
      setUserRoles(data.getUserRoles);
    }
  }, [data]);

  const filterRole = userRoles?.filter(
    (role) =>
      role.role === UserRoleEnum.Teacher ||
      role.role === UserRoleEnum.Pupil ||
      role.role === UserRoleEnum.Parent
  );
  let content;

  if (data) {
    content = (
      <Autocomplete
        options={filterRole?.map((role) => role.id)}
        getOptionLabel={(option) =>
          filterRole?.find((userRole) => userRole?.id === option)?.displayName || ""
        }
        value={
          filterRole?.find((userRole) => userRole.id === role.id)?.id || ""
        }
        onChange={(e, value) => setUserRoleId(+value)}
        renderInput={(params) => (
          <UserFields {...params} label="Роль" variant="standard" />
        )}
      />
    );
  }

  return (
    <div>
      {content}
    </div>

  );
};

