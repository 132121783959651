import React, { useEffect, useState } from "react";
import { Header } from "../../../shared/Style/Style";

import { VerticalBarChart } from "../../../ReportsARBook";
import { useParams } from "react-router-dom";
import { IDateRange } from "../../../shared/DateRangePicker/DateRangePicker";
import {
  ERROR_MESSAGE_ACTIVITY_OF_TEACHERS,
  REPORT_VARIANTS,
  TOP_FILTER,
} from "../../../ReportsARBook/constants";
import {
  PeriodAdminReportEnum,
  useGetCreatedLessonsBySchoolTeachersLazyQuery,
} from "../../../../generated/graphql";
import {
  getChartLabelsData,
  getChartQuantityData,
  getMessage,
} from "../../../ReportsARBook/utils";
import { AnalyticsTopFilters } from "../AnalyticsTopFilters/AnalyticsTopFilters";
import { ErrorMessageSnackbar } from "../../../shared/ErrorMessageSnackbar";
import { useRequestError } from "../../../../hooks";

export const CreatedLessonsStats = () => {
  const { schoolId } = useParams<{ schoolId: string }>();
  const [isDateRangePickerOpen, setDateRangePickerOpen] = useState(false);
  const [isAllPeriodDateRangeData, setAllPeriodDateRangeData] = useState(false);
  const [topFilterVariables, setTopFilterVariables] = useState(TOP_FILTER);

  const { from: topFilterFrom, to: topFilterTo } = topFilterVariables;
  const isDatePickerActive =
    (!!topFilterFrom || !!topFilterTo) && !isAllPeriodDateRangeData;

  const variant = REPORT_VARIANTS.createdLessonCounts;
  const { allPeriodDate } = variant;

  const [
    getCreatedLessonByTeachers,
    {
      data: createdLessonsByTeachersData,
      loading: createdLessonsByTeachersLoading,
      error: createdLessonsByTeachersError,
    },
  ] = useGetCreatedLessonsBySchoolTeachersLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const { requestError, setRequestError, errorMessage } = useRequestError(
    createdLessonsByTeachersError,
    ERROR_MESSAGE_ACTIVITY_OF_TEACHERS
  );

  const createdLessonsReport =
    createdLessonsByTeachersData?.getCreatedLessonsBySchoolTeachers
      ?.dataChart ?? [];

  const totalCreatedLessonsByTeachers =
    createdLessonsByTeachersData?.getCreatedLessonsBySchoolTeachers
      ?.dataTotal ?? null;

  const chartLabels = getChartLabelsData(
    createdLessonsReport,
    PeriodAdminReportEnum.Day
  );

  const chartQuantity = getChartQuantityData(
    totalCreatedLessonsByTeachers,
    variant.metric
  );

  const handleSetTopFilterVariables = (newValue) => {
    setTopFilterVariables((prevState) => ({ ...prevState, ...newValue }));
  };

  const handleSetFilterPeriod = (newDateRange: IDateRange) => {
    const { startDate, endDate } = newDateRange;
    handleSetTopFilterVariables({ from: startDate, to: endDate });
  };

  const handleSetDatePickerButton = () => {
    setDateRangePickerOpen(true);
    setAllPeriodDateRangeData(false);
  };

  const handleSetAllDatePeriod = () => {
    handleSetFilterPeriod(allPeriodDate);
    setAllPeriodDateRangeData(true);
  };

  const dataVerticalChartTypeCreatedLesson = {
    chartHeader: {
      chartTitle: "Количество созданных уроков",
      chartSubtitle: "Среднее количество созданных уроков",
      totalValue: chartQuantity,
      isTooltipVisible: true,
      tooltipText:
        "Як рахуються створені уроки? До створених уроків ми відносимо будь-які кейси створення уроків: через+, створення у плані, додавання з АРбук+, додавання плану через АРбук+ - в такому випадку рахуємо всі уроки, що були у плані, наприклад 40 шт.",
      tooltipPlacement: "right",
    },
    noFilters: true,
    chart: chartLabels,
    isLoadingProgress: createdLessonsByTeachersLoading,
    message: getMessage(totalCreatedLessonsByTeachers),
  };

  const dataActivityOfTeachersTopFilters = {
    handleDateRangePickerOpen: handleSetFilterPeriod,
    handleAllPeriodDateRangeData: handleSetAllDatePeriod,
    handleSetDatePickerButton,
    isDatePickerActive,
    titleDatePickerButton: "Период",
    titleDateButton: "За все время",
    isDateRangePickerOpen,
    isAllPeriodDateRangeData,
    setTopFilterVariables,
    handleDateRangePickerClose: setDateRangePickerOpen,
  };

  useEffect(() => {
    if (topFilterFrom && topFilterTo) {
      const fromDate = new Date(topFilterFrom);
      const toDate = new Date(topFilterTo);

      if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
        return;
      }

      getCreatedLessonByTeachers({
        variables: {
          schoolId,
          from: fromDate,
          to: toDate,
        },
      });
    }
  }, [topFilterFrom, topFilterTo]);

  return (
    <>
      <Header>АКТИВНОСТЬ УЧИТЕЛЕЙ</Header>

      <AnalyticsTopFilters {...dataActivityOfTeachersTopFilters} />

      <VerticalBarChart {...dataVerticalChartTypeCreatedLesson} />

      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
    </>
  );
};
