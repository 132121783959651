import React, { useState } from "react";

import { useParams } from "react-router-dom";


import { useMutation, useQuery } from "@apollo/client";
import {
  GetProjectsQuery,
  GetProjectsDocument,
  User, Project,
  GetUserDocument,
  AssignProjectsToUserDocument,
  AssignProjectsToUserMutation
} from "../../../generated/graphql";

import { Backdrop, Box, CircularProgress, Dialog, TextField, Checkbox, ListItem } from "@material-ui/core";

import { ButtonCustom } from "../../shared/Style/Style";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";

import {
  ProjectModalContainer,
  ProjectModalDialogContent,
  ProjectModalFormControl,
  ProjectModalFormGroup,
  ProjectModalFormControlLabel,
  ProjectModalNoProjects,
} from "./ProjectModal.style";

interface IProjectModalProps {
  setIsProjectModal(e: boolean): void;
  open: boolean;
  user: User;
}

export const ProjectModal = ({ setIsProjectModal, open, user }: IProjectModalProps) => {

  const { userId } = useParams<{userId: string}>();

  const [ChosenProjects, setChosenProjects] = useState<[] | string[]>([]);

  const choseProjectHandler = (projectId: string | null) => {
    if (!projectId) {
      return;
    }

    if (ChosenProjects.find(id => id === projectId)) {
      setChosenProjects([...ChosenProjects.filter(id => projectId !== id)]);
      return;
    }

    setChosenProjects([...ChosenProjects, projectId])
  }

  const [searchedProject, setSerchedProject] = useState<string>('');

  const { data, loading, error } = useQuery<GetProjectsQuery>(
    GetProjectsDocument,
    {
      variables: {
        getProjectsInput: {
          limit: 1000,
          skip: 0,
          statuses: ['NEW', 'IN_PROGRESS'],
        },
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const [
    assignProjectsToUser,
    {loading: userAsignLoading, error: userAssignError}
  ] = useMutation<AssignProjectsToUserMutation>(
    AssignProjectsToUserDocument,
    {refetchQueries: [{
        query: GetUserDocument, variables: { userId }
      }]}
  )

  const assignUserHandler = (): void => {
    if (!ChosenProjects.length) {
      return;
    }
    assignProjectsToUser({variables: { userId, projectIds: ChosenProjects }})
    handleClose();
  }

  const handleClose = (): void => {
    setIsProjectModal(false)
  }

  let content;

  if (loading) {
    content = (
      <Backdrop style={{ color: '#FFB61D' }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (error) {
    content = (
      <ProjectModalDialogContent >
        <ErrorSnackbar error={error} />
      </ProjectModalDialogContent>
    );
  }

  if (data && data.getProjects) {

    const projectsList = data.getProjects.projects;

    const preparedProjectList = projectsList
      ?.map((project) => {
        if (user.projects?.find((userProject: Project | null) => userProject?.id === project.id)) {
          return null;
        }

        if (!project.name?.toLocaleLowerCase().includes(searchedProject.toLocaleLowerCase())) {
          return null;
        }

        return project;
      })
      .filter(project => project);

    content = (
      <ProjectModalContainer>
        <ProjectModalDialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Введите название проекта"
            type="email"
            fullWidth
            value={searchedProject}
            onChange={({ target }) => setSerchedProject(target.value)}
          />

          <ProjectModalFormControl>
            <ProjectModalFormGroup aria-label="position">
              {preparedProjectList.length ? (
                preparedProjectList.map((project, i: number) => {
                  if (project === null) {
                    return null;
                  }

                  return (<ListItem button key={project?.id}>
                    <ProjectModalFormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          style={{ color: '#000' }}
                          onChange={() =>  project?.id && choseProjectHandler(project.id)}
                          checked={!!ChosenProjects.find(id => id === project!.id)}
                          color="default"
                          name={project?.name?.toString()}
                        />
                      }
                      label={`${i + 1}. ${project?.name}`}
                    />
                  </ListItem>)
                })
              ) : <ProjectModalNoProjects>Нет доступных проектов</ProjectModalNoProjects>}
            </ProjectModalFormGroup>
          </ProjectModalFormControl>

          <ButtonCustom
            disabled={userAsignLoading}
            style={{ marginTop: "10px" }}
            onClick={assignUserHandler}
          >
            Добавить
          </ButtonCustom>
        </ProjectModalDialogContent>
      </ProjectModalContainer>
    )
  }


  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="simple-dialog-title"
    >
      {content}

      {userAssignError && (
        <Box position='absolute' bottom='10px' left='0' right='0' color='red' textAlign='center'>
          <ErrorSnackbar error={userAssignError}/>
        </Box>
      )}
    </Dialog>
  );
};