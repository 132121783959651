import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  GetEventDateDocument,
  GetEventDateQuery,
} from "../../../generated/graphql";
import { LoadingBox } from "../../shared/Style/Style";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  ListItem,
} from "@material-ui/core";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";
import {
  EventDatesUserContent,
  EventField,
  EventGroupUserList,
} from "../Event.style";
import { ExportUserExel } from "../ExportUsersExel/ExportUsersExel";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";

interface IEventNotPresent{
  eventDateId:string;
  open:boolean;
  close():void;
}

export const EventNotPresentModal = ({ eventDateId, open, close }:IEventNotPresent) => {
  const [searchedUser, setSearchedUser] = useState("");

  const { data, loading, error } = useQuery<GetEventDateQuery>(
    GetEventDateDocument,
    {
      variables: {
        id: eventDateId,
      },
    }
  );

  const closeHandler = () => {
    setSearchedUser("");
    close();
  };

  let content;

  if (loading) {
    return (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }
  if (error) {
    return <ErrorSnackbar error={error} />;
  }
  if (data) {
    const notPresentUser = data?.getEventDate?.wereNotPresent;

    const searchUsers = notPresentUser
      .map((user) => {
        if (
          !user.firstName
            .toLocaleLowerCase()
            .includes(searchedUser.toLocaleLowerCase()) &&
          !user.lastName
            .toLocaleLowerCase()
            .includes(searchedUser.toLocaleLowerCase())
        ) {
          return null;
        }
        return user;
      })
      .filter((user) => user);

    content = (
      <>
        <Box display="flex" alignItems="center">
          <EventField
            fullWidth
            label="Поиск участников"
            value={searchedUser}
            onChange={({ target }) => setSearchedUser(target.value)}
          />
          <ExportUserExel data={notPresentUser} />
        </Box>
        <EventGroupUserList>
          {searchUsers?.length
            ? searchUsers?.map((user, i) => {
                if (user === null) {
                  return null;
                }

                return (
                  <ListItem button key={user.id} disabled={false}>
                    <Box display="flex" alignItems="center">
                      {user?.firstName} {user?.lastName}
                    </Box>
                  </ListItem>
                );
              })
            : "На данный момент нету не посетивших участников"}
        </EventGroupUserList>
      </>
    );
  }

  return (
    <Dialog open={open}>
      <CloseIconButton close={closeHandler} />
      <Box textAlign="center" mb={2}>
        <DialogTitle>Посетившие участники</DialogTitle>
        <EventDatesUserContent style={{ width: "400px" }}>
          {content}
        </EventDatesUserContent>
      </Box>
    </Dialog>
  );
};