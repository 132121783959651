import { useState } from "react";
import { useParams } from "react-router-dom";
import { ParticipantsContainer, SearchSortWrapper } from "./style";
import { CustomTablePagination } from "../../shared/CustomTablePagination";
import { ParticipantsSearchBar } from "./components/ParticipantsSearchBar";
import { FiltersSelect } from "../../shared/Selects/FiltersSelect";
import { ParticipantsTable } from "./components/ParticipantsTable";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { SchoolParticipantSortKey, useGetSchoolParticipantsQuery} from "../../../generated/graphql";
import { useRequestError } from "../../../hooks";
import { SelectOptionType } from "../../shared/Selects/types";

export const ACCESS_FILTER_TYPES = [
  { name: "Все пользователи", value: null },
  { name: "Учителя", value: SchoolParticipantSortKey.Teachers },
  { name: "Ученики", value: SchoolParticipantSortKey.Pupils },
];

export const ParticipantsList = () => {
    const {  schoolId } = useParams<{ schoolId: string }>();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [searchKey, setSearchKey] = useState<string>("");
    const [sortValue, setSortValue] = useState<SchoolParticipantSortKey>(null);

    const { data, loading, error } = useGetSchoolParticipantsQuery({
        variables: {
            schoolId,
            limit: rowsPerPage,
            skip: page * rowsPerPage,
            searchKey: searchKey || undefined,
            sort: sortValue || undefined,
        },
    });
    const { requestError, setRequestError, errorMessage } = useRequestError(error, "При загрузке списка участников произошла ошибка");

    const participants = data?.getSchoolParticipants?.users || [];
    const participantsCount = data?.getSchoolParticipants?.total || 0;

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSetFilter = (value: SelectOptionType["value"]) => {
        setSortValue(value as SchoolParticipantSortKey);
        setPage(0);
    }

    const pagination = participants?.length > 0 && (
        <CustomTablePagination
            rowsPerPageOptions={[10, 20]}
            count={participantsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
    
    return (
        <>
            <ParticipantsContainer>
                <SearchSortWrapper>
                    <ParticipantsSearchBar
                        setSearchKey={setSearchKey}
                        setPage={setPage}
                    />

                    <FiltersSelect
                        fieldsData={ACCESS_FILTER_TYPES}
                        handleSetFilter={handleSetFilter}
                    />
                </SearchSortWrapper>
                
                { pagination }
                
                <ParticipantsTable
                    participants={participants}
                    isLoading={loading}
                />

                { pagination }
            </ParticipantsContainer>

            <ErrorMessageSnackbar
                open={requestError}
                message={errorMessage}
                closeHandler={() => setRequestError(false)}
            />
        </>
    );
};
