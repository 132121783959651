import { useReactiveVar } from "@apollo/client";
import { PhotoPicker } from "../../../../../shared/PhotoPicker";
import { robotsState } from "../../../state";
import { GameModelArtifactFileTypeEnum } from "../../../../../../generated/graphql";


export const ArtifactPhotoDropzone = () => {
  const artifact = useReactiveVar(robotsState.artifact);

  const uploadPhotoHandler = (file: File) => {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => { 
      robotsState.updateArtifactFile({
        updateGameModelArtifactFileId: artifact?.id,
        file,
        fileType: GameModelArtifactFileTypeEnum.Image,
      }, () => { 
        robotsState.setArtifactFile(
          GameModelArtifactFileTypeEnum.Image,
          reader.result as string
        );
      });
    };
    reader.readAsDataURL(file);
  };
    
  const uploadPhotoErrorHandler = (error: boolean, message: string) => {
    robotsState.setNotifications({ error, message });
  };

  return (
    <PhotoPicker
      photo={artifact?.image}
        setNewPhoto={uploadPhotoHandler}
        showError={uploadPhotoErrorHandler}
    />
  );
};
