import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import {
  GetGiveawayTeachersQuery,
  GetGiveawayTeachersDocument,
  GetGiveawayTeachersTwoConditionsQuery,
  GetGiveawayTeachersTwoConditionsDocument,
  User,
} from "../../generated/graphql";

import {
  Container,
  TableHead,
  CircularProgress,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";

import { CustomTablePagination } from "../shared/CustomTablePagination";
import {
  Header,
  Filter,
  FilterButton,
  LoadingBox,
  MainTableCell,
  MainTableHeaderTitle,
} from "../shared/Style/Style";

import {
  EmptyGiveawayTeachersTableRow,
  GiveawayTeachersTableRow,
} from "./Giveaway.style";

import { noData } from "../Main/Project/contentConstants";

export const Giveaway = () => {
  const headers = ["Имя", "Телефон", "Email", "Город", "Школа"];

  const [activeFilter, setActiveFilter] = useState("ТРИ УСЛОВИЯ");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [teachers, setTeachers] = useState<User[]>([]);

  const [
    getAllConditionsTeachers,
    {
      data: getAllConditionsTeachersData,
      loading: getAllConditionsTeachersLoading,
      error: getAllConditionsTeachersError,
    },
  ] = useLazyQuery<GetGiveawayTeachersQuery>(GetGiveawayTeachersDocument, {
    fetchPolicy: "no-cache",
    variables: {
      getUsersData: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
      },
    },
  });

  const [
    getTwoConditionsTeachers,
    {
      data: getTwoConditionsTeachersData,
      loading: getTwoConditionsTeachersLoading,
      error: getTwoConditionsTeachersError,
    },
  ] = useLazyQuery<GetGiveawayTeachersTwoConditionsQuery>(
    GetGiveawayTeachersTwoConditionsDocument,

    {
      fetchPolicy: "no-cache",
      variables: {
        getUsersData: {
          limit: rowsPerPage,
          skip: page * rowsPerPage,
        },
      },
    }
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setPage(0);

    if (activeFilter === "ДВА УСЛОВИЯ") {
      getTwoConditionsTeachers();
      return;
    }

    getAllConditionsTeachers();
  }, [activeFilter]);

  useEffect(() => {
    if (getAllConditionsTeachersData) {
      setTeachers(getAllConditionsTeachersData.getGiveawayTeachers.users);
    }
  }, [getAllConditionsTeachersData]);

  useEffect(() => {
    if (getTwoConditionsTeachersData) {
      setTeachers(
        getTwoConditionsTeachersData.getGiveawayTeachersTwoConditions.users
      );
    }
  }, [getTwoConditionsTeachersData]);

  let content;

  if (getAllConditionsTeachersLoading || getTwoConditionsTeachersLoading) {
    content = (
      <EmptyGiveawayTeachersTableRow>
        <MainTableCell>
          <LoadingBox>
            <CircularProgress color="inherit" />
          </LoadingBox>
        </MainTableCell>
      </EmptyGiveawayTeachersTableRow>
    );
  }

  if (getAllConditionsTeachersError || getTwoConditionsTeachersError) {
    content = (
      <EmptyGiveawayTeachersTableRow>
        <MainTableCell>При загрузке Учителя произошла ошибка</MainTableCell>
      </EmptyGiveawayTeachersTableRow>
    );
  }

  if (teachers) {
    content = (
      <>
        {teachers?.length ? (
          teachers?.map(
            ({ id, firstName, lastName, phone, email, school, schoolCity }) => (
              <GiveawayTeachersTableRow key={id}>
                {[
                  lastName + " " + firstName,
                  phone,
                  email,
                  schoolCity?.name,
                  school?.shortName,
                ].map((field, i) => (
                  <MainTableCell key={i}>
                    {field ? field : noData}
                  </MainTableCell>
                ))}
              </GiveawayTeachersTableRow>
            )
          )
        ) : (
          <EmptyGiveawayTeachersTableRow>
            <MainTableCell>Учителя отсутствуют</MainTableCell>
          </EmptyGiveawayTeachersTableRow>
        )}
      </>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header>Розыгрыш</Header>

      <Filter>
        {["ТРИ УСЛОВИЯ", "ДВА УСЛОВИЯ"].map((filter) => (
          <FilterButton
            key={filter}
            active={activeFilter === filter}
            onClick={() => setActiveFilter(filter)}
          >
            {filter}
          </FilterButton>
        ))}
      </Filter>

      <Table>
        <TableHead>
          <GiveawayTeachersTableRow>
            {headers.map((header) => (
              <MainTableHeaderTitle key={header}>{header}</MainTableHeaderTitle>
            ))}
          </GiveawayTeachersTableRow>
        </TableHead>

        <TableBody>{content}</TableBody>

        <TableRow>
          <CustomTablePagination
            rowsPerPageOptions={[10, 20]}
            count={
              activeFilter === "ТРИ УСЛОВИЯ"
                ? getAllConditionsTeachersData?.getGiveawayTeachers?.total || 0
                : getTwoConditionsTeachersData?.getGiveawayTeachersTwoConditions
                    ?.total || 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </Table>
    </Container>
  );
};
