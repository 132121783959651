import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { Field } from "react-final-form";
import { isRequired } from "../../../core/validators/validators";
import { DictionaryProjectRiskLevel } from "../../shared/DictionaryProjectRiskLevel/DictionaryProjectRiskLevel";

interface ProjectRequiredLinksProps {
  className: string;
}

export const ProjectRequiredLinks = ({
  className,
}: ProjectRequiredLinksProps) => {
  return (
    <Grid
      container
      justifyContent="center"
      spacing={2}
    >
      <Grid xs={12} item>
        <Field name="blockDiagrams" validate={isRequired}>
          {({ input, meta }) => (
            <TextField
              className={className}
              error={meta.error && meta.touched}
              fullWidth
              helperText={meta.touched && meta.error}
              label="Блок-схемы Микро"
              variant="filled"
              type="text"
              {...input}
            />
          )}
        </Field>
      </Grid>
      <Grid xs={6} item>
        <Field name="contractTemplates" validate={isRequired}>
          {({ input, meta }) => (
            <TextField
              className={className}
              error={meta.error && meta.touched}
              fullWidth
              helperText={meta.touched && meta.error}
              label="Шаблоны договоров"
              variant="filled"
              type="text"
              {...input}
            />
          )}
        </Field>
      </Grid>
      <Grid xs={6} item>
        <Field name="finalAgreement" validate={isRequired}>
          {({ input, meta }) => (
            <TextField
              className={className}
              error={meta.error && meta.touched}
              fullWidth
              helperText={meta.touched && meta.error}
              label="Договора Финал"
              variant="filled"
              type="text"
              {...input}
            />
          )}
        </Field>
      </Grid>
      <Grid xs={6} item>
        <Field name="brief" validate={isRequired}>
          {({ input, meta }) => (
            <TextField
              className={className}
              error={meta.error && meta.touched}
              fullWidth
              helperText={meta.touched && meta.error}
              label="Бриф"
              variant="filled"
              type="text"
              {...input}
            />
          )}
        </Field>
      </Grid>
      <Grid xs={6} item>
        <Field name="testTaskFile" validate={isRequired}>
          {({ input, meta }) => (
            <TextField
              className={className}
              error={meta.error && meta.touched}
              fullWidth
              helperText={meta.touched && meta.error}
              label="Файл ТЗ"
              variant="filled"
              type="text"
              {...input}
            />
          )}
        </Field>
      </Grid>
      <Grid xs={6} item>
        <Field name="renderingFile" validate={isRequired}>
          {({ input, meta }) => (
            <TextField
              className={className}
              error={meta.error && meta.touched}
              fullWidth
              helperText={meta.touched && meta.error}
              label="Файл просчета(2)"
              variant="filled"
              type="text"
              {...input}
            />
          )}
        </Field>
      </Grid>
      <Grid xs={6} item>
        <Field name="designFigma" validate={isRequired}>
          {({ input, meta }) => (
            <TextField
              className={className}
              error={meta.error && meta.touched}
              fullWidth
              helperText={meta.touched && meta.error}
              label="Дизайн в фигме"
              variant="filled"
              type="text"
              {...input}
            />
          )}
        </Field>
      </Grid>
      <Grid xs={6} item>
        <Field name="risks" validate={isRequired}>
          {({ input, meta }) => (
            <TextField
              className={className}
              error={meta.error && meta.touched}
              fullWidth
              helperText={meta.touched && meta.error}
              label="Риски"
              variant="filled"
              type="text"
              {...input}
            />
          )}
        </Field>
      </Grid>
      <Grid xs={6} item>
        <Field name="riskScore" validate={isRequired}>
          {({ input, meta }) => (
            <DictionaryProjectRiskLevel
              label="Уровень риска"
              input={input}
              meta={meta}
              values={[1, 2, 3, 4, 5]}
              className={className}
            />
          )}
        </Field>
      </Grid>
    </Grid>
  );
};
