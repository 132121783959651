import { FC } from "react";
import { ButtonCustom, CancelButton } from "../../shared/Style/Style";
import { ButtonsWrapper } from "../Awards/components/Modal/style";

interface ModalButtonsProps {
    isLoading: boolean;
    isPublished?: boolean;
    submitHandler: () => void;
    cancelHandler: () => void;
    publishHandler?: () => void;
}

export const ModalButtons: FC<ModalButtonsProps> = ({
    isLoading,
    isPublished,
    submitHandler,
    cancelHandler,
    publishHandler,
}) => {
    const publishButtonTitle = isPublished ? "Обновить" : "Опубликовать";

    return (
        <ButtonsWrapper>
            <CancelButton
                disabled={isLoading}
                onClick={cancelHandler}
            >
                Отменить
            </CancelButton>
                            
            {!isPublished && <ButtonCustom
                disabled={isLoading}
                onClick={submitHandler}
            >
                Сохранить
            </ButtonCustom>}

            {publishHandler && <ButtonCustom
                disabled={isLoading}
                onClick={publishHandler}
            >
                {publishButtonTitle}
            </ButtonCustom>}
        </ButtonsWrapper>
    );
};
