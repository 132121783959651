import { Award } from "../../../generated/graphql";

export type ErrorType = { error: boolean, message: string };

export type NotificationsType = {
  error?: boolean;
  success?: boolean;
  message: string;
};

export type InputErrors = {
  [key in AwardField]?: ErrorType;
};

export type DeleteAwardType = {
  isOpen: boolean;
  award: Award;
};

export enum AwardField {
  Name = 'name',
  Photo = 'photo',
  Day = 'day',
  Background = 'background',
  Animation = 'animation',
  Energy = 'energyQuantity',
  IsPublished = 'isPublished',
  Type = 'type',
}
