import React from "react";
import { IconButton } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import { primary, secondary } from "../../Style/Style";
import { getSize } from "../../Utils/OtherOperations/getSize";

interface ISaveIconButtonProps {
  disabled?: boolean;
  save(): void;
  fz?: number;
}

export const SaveIconButton = ({ disabled, save, fz }: ISaveIconButtonProps) => {
  return (
    <IconButton
      disabled={disabled}
      onClick={save}
      style={{ color: disabled ? secondary : primary, fontSize: fz ? `${fz}px` : '24px', padding: fz ? `${fz/2}px` : '12px', lineHeight: 1 }}
    >
      <Save
        color='inherit'
        fontSize='inherit'
      />
    </IconButton>
  )
}