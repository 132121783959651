import { Box } from "@material-ui/core";
import { videoDescriptionText, videoText } from "./constants";

export const VideoSidebar = () => {
  return (
    <Box minWidth={50}>
      <Box component="p" m="0">
        {videoText}
      </Box>
      <Box fontSize={10} component="span" color="#A5A5A5">
        {videoDescriptionText}
      </Box>
    </Box>
  );
};
