import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Field, Form } from "react-final-form";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateProjectAdditionalContactDocument,
  CreateProjectLinkDocument,
  GetProjectsDocument,
  UpdateProjectDocument,
  Project,
  UpdateProjectMutation,
  CreateProjectLinkMutation,
  CreateProjectAdditionalContactMutation,
  User,
  GetUsersQuery,
  GetUsersDocument,
  GetProjectDocument,
  GetProjectTypesQuery,
  GetProjectTypesDocument,
  CreateProjectPresentationMutation,
  CreateProjectPresentationDocument,
  UserRoleEnum,
} from "../../../generated/graphql";
import { ProjectLinks } from "./ProjectLinks";
import { ProjectRequiredLinks } from "./ProjectRequiredLinks";
import { ProjectDatePickers } from "./ProjectDatePickers";
import { ProjectBaseFields } from "./ProjectBaseFields";
import { ProjectAddLinkModal } from "./ProjectAddLinkModal";
import { ProjectAddAdditionalContactsModal } from "./ProjectAddAdditionalContactsModal";
import { ProjectAdditionalContacts } from "./ProjectAdditionalContacts";
import { FormSmallTitleWithBtn } from "../../shared/FormSmallTitleWithBtn/FormSmallTitleWithBtn";
import { DEFAULT_AVATAR_URL } from "../../shared/constants";
import { Box, Grid, Snackbar, TextField } from "@material-ui/core";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { ButtonCustom, useStylesSnackbar } from "../../shared/Style/Style";
import { ProjectAvatarStyle, useStyles } from "../../shared/Style/Style";
import { AddProjectFileModal } from "./AddProjectFileModal";

interface ProjectEditFormProps {
  project: Project;
}

export const ProjectEditForm = ({ project }: ProjectEditFormProps) => {
  const { projectId } = useParams<{ projectId: string }>();
  const classesSnackbar = useStylesSnackbar();
  const [requestError, setRequestError] = useState(false);
  const [isLinkModal, setIsLinkModal] = useState(false);
  const [isRequiredLinkModal, setRequiredLinkModal] = useState(false);
  const [isAdditionalContactModal, setIsAdditionalContactModal] =
    useState(false);
  const [isProjectFileModal, setIsProjectFileModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const { data: clientData, error: clientDataError } = useQuery<GetUsersQuery>(
    GetUsersDocument,
    {
      variables: {
        getUsersData: {
          roles: [UserRoleEnum.Client],
        },
      },
    }
  );

  const { data: projectTypesData, error: ProjectTypesError } =
    useQuery<GetProjectTypesQuery>(GetProjectTypesDocument);

  const users = clientData?.getUsers?.users as User[];
  const projectTypes = projectTypesData?.getProjectTypes;

  const [update, { data: projectData, loading: projectLoading }] =
    useMutation<UpdateProjectMutation>(UpdateProjectDocument);

  useEffect(() => {
    if (projectData) {
      setOpenSnackbar(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [projectData]);

  const [createLink] = useMutation<CreateProjectLinkMutation>(
    CreateProjectLinkDocument
  );

  const [createRequiredLink] = useMutation<CreateProjectPresentationMutation>(
    CreateProjectPresentationDocument
  );

  const [createAdditionalContact] =
    useMutation<CreateProjectAdditionalContactMutation>(
      CreateProjectAdditionalContactDocument
    );

  const classes = useStyles();

  useEffect(() => {
    if (clientDataError || ProjectTypesError) {
      setRequestError(true);
    }
  }, [clientDataError, ProjectTypesError]);

  const addAdditionalContactHandleSubmit = ({
    name,
    phone,
    description,
  }: {
    name: string;
    phone: string;
    description?: string;
  }): void => {
    name &&
      phone &&
      createAdditionalContact({
        variables: {
          projectAdditionalContactData: {
            projectId,
            name,
            phone,
            description,
          },
        },
        refetchQueries: [
          { query: GetProjectDocument, variables: { projectId } },
        ],
      });

    setIsAdditionalContactModal(false);
  };

  const modalAddLinkHandleSubmit = ({
    title,
    link,
    login,
    password,
    values,
  }: {
    title: string;
    link: string;
    login?: string;
    password?: string;
    values: Project;
  }): void => {
    createLink({
      variables: {
        projectLinkData: {
          title,
          link,
          login,
          password,
          projectId,
        },
      },
      //refetchQueries: [{ query: GetProjectDocument, variables: { projectId } }],
    });
    onFormSubmit(values);
    setIsLinkModal(false);
  };

  const modalRequiredLinkHandleSubmit = ({
    title,
    link,
    values,
  }: {
    title: string;
    link: string;
    values: Project;
  }): void => {
    createRequiredLink({
      variables: {
        title,
        link,
        projectId,
      },
    });
    onFormSubmit(values);
    setRequiredLinkModal(false);
  };

  const onFormSubmit = async (values: Project): Promise<void> => {
    await update({
      variables: {
        projectData: {
          id: projectId,
          cost: Number(values?.cost) || null,
          budget: Number(values?.budget) || null,
          clientId: values.client?.id ? values.client.id : null,
          statusId: values.status?.id,
          projectTypeId: values.projectType.id,
          startDateEstimate: values.startDateEstimate,
          finishDateEstimate: values.finishDateEstimate,
          startDate: values.startDate,
          finishDate: values.finishDate,
          stack: values.stack,
          blockDiagrams: values.blockDiagrams,
          contractTemplates: values.contractTemplates,
          finalAgreement: values.finalAgreement,
          brief: values.brief,
          testTaskFile: values.testTaskFile,
          renderingFile: values.renderingFile,
          designFigma: values.designFigma,
          risks: values.risks,
          riskScore: values.riskScore,
        },
      },
      refetchQueries: [
        { query: GetProjectDocument, variables: { projectId } },
        {
          query: GetProjectsDocument,
          variables: {
            getProjectsInput: {
              limit: 10,
              skip: 0,
              statuses: [],
            },
          },
        },
      ],
    });
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        className={classesSnackbar.root}
        onClose={() => setOpenSnackbar(false)}
      >
        <ButtonCustom>Сохранено</ButtonCustom>
      </Snackbar>

      <Form onSubmit={onFormSubmit} initialValues={{ ...project }}>
        {({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid xs={6} item>
                <Box
                  display="flex"
                  justifyContent="center"
                  paddingLeft={2}
                  paddingRight={2}
                >
                  <ProjectAvatarStyle
                    prim={project?.photo ? project.photo : DEFAULT_AVATAR_URL}
                  />
                </Box>
              </Grid>

              <Grid xs={6} item>
                <ProjectBaseFields
                  className={classes.root}
                  users={users}
                  values={values}
                  projectTypes={projectTypes}
                />

                {isAdditionalContactModal && (
                  <ProjectAddAdditionalContactsModal
                    open={isAdditionalContactModal}
                    showModal={setIsAdditionalContactModal}
                    modalHandleSubmit={addAdditionalContactHandleSubmit}
                  />
                )}
              </Grid>

              <Grid container justifyContent="center" spacing={2}>
                <Grid xs={8} item>
                  <FormSmallTitleWithBtn
                    title="Дополнительные контакты клиента"
                    onClickBtn={setIsAdditionalContactModal}
                  />
                </Grid>

                <Grid xs={12} item>
                  <ProjectAdditionalContacts
                    project={project}
                    classes={classes}
                  />
                </Grid>
              </Grid>

              <Grid container justifyContent="center" spacing={2}>
                <ProjectDatePickers values={values} />
                <Grid xs={12} sm={6} item>
                  <Field name="budget">
                    {({ input, meta }) => (
                      <TextField
                        className={classes.root}
                        error={meta.error && meta.touched}
                        fullWidth
                        helperText={meta.touched && meta.error}
                        label="Сумма контракта"
                        variant="filled"
                        type="text"
                        {...input}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid xs={12} sm={6} item>
                  <Field name="cost">
                    {({ input, meta }) => (
                      <TextField
                        className={classes.root}
                        error={meta.error && meta.touched}
                        fullWidth
                        helperText={meta.touched && meta.error}
                        label="Сумма себестоимости"
                        variant="filled"
                        type="text"
                        {...input}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid xs={12} item>
                  <Box mt={-2}>
                    <Field name="stack">
                      {({ input, meta }) => (
                        <TextField
                          className={classes.root}
                          error={meta.error && meta.touched}
                          fullWidth
                          helperText={meta.touched && meta.error}
                          label="Технологии"
                          variant="filled"
                          type="text"
                          value={input.value ? input.value.join(",") : ""}
                          onChange={(event) => {
                            const modifiedEvent = {
                              ...event,
                              target: {
                                value: event.target?.value?.split(",") || null,
                              },
                            };

                            input.onChange(modifiedEvent);
                          }}
                        />
                      )}
                    </Field>
                  </Box>
                </Grid>
              </Grid>

              <ProjectRequiredLinks className={classes.root} />

              <Grid container justifyContent="center" spacing={2}>
                <Grid xs={6} item>
                  <FormSmallTitleWithBtn
                    title="Презентация о продукте"
                    onClickBtn={setRequiredLinkModal}
                  />
                </Grid>

                {isRequiredLinkModal && (
                  <ProjectAddLinkModal
                    open={isRequiredLinkModal}
                    showModal={setRequiredLinkModal}
                    modalHandleSubmit={modalRequiredLinkHandleSubmit}
                    values={values}
                  />
                )}

                {project?.presentations?.length ? (
                  <Grid xs={12} item>
                    <ProjectLinks
                      links={project.presentations}
                      classes={classes}
                      projectId={projectId}
                      projectPresentation
                    />
                  </Grid>
                ) : null}
              </Grid>

              <Grid container justifyContent="center" spacing={2}>
                <Grid xs={6} item>
                  <FormSmallTitleWithBtn
                    title="Ссылки проекта"
                    onClickBtn={setIsLinkModal}
                  />
                </Grid>

                {isLinkModal && (
                  <ProjectAddLinkModal
                    open={isLinkModal}
                    showModal={setIsLinkModal}
                    modalHandleSubmit={modalAddLinkHandleSubmit}
                    projectLink
                    values={values}
                  />
                )}

                {project?.links?.length ? (
                  <Grid xs={12} item>
                    <ProjectLinks
                      links={project.links}
                      classes={classes}
                      projectId={projectId}
                      projectLinks
                    />
                  </Grid>
                ) : null}

              </Grid>

              <Grid container justifyContent="center" spacing={2} style={{marginBottom: '60px'}}>
                <Grid xs={6} item>
                  <FormSmallTitleWithBtn
                    title="Дополнительные файлы"
                    onClickBtn={setIsProjectFileModal}
                  />
                </Grid>

                {isProjectFileModal && (
                  <AddProjectFileModal
                    open={isProjectFileModal}
                    showModal={setIsProjectFileModal}
                    projectId={projectId}
                  />
                )}

                {project.files?.length ? (
                  <Grid xs={12} item>
                    <ProjectLinks
                      links={project.files}
                      classes={classes}
                      projectId={projectId}
                      projectFiles
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>

            <Box
              position="absolute"
              bottom={25}
              textAlign="center"
              right={0}
              left={0}
            >
              <ButtonCustom type="submit" disabled={projectLoading}>
                Сохранить
              </ButtonCustom>
            </Box>
          </form>
        )}
      </Form>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={
          (clientDataError && "Ошибка получения клиентов") ||
          (ProjectTypesError && "Ошибка получения типов проектов")
        }
      />
    </>
  );
};
