import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { MobileAppInput } from "../MobileAppPageEdit.style";
import { SubscriptionPlanStatusEnum } from "../../../generated/graphql";

interface typeAccessSubscriptions {
  accessType: SubscriptionPlanStatusEnum;
  text: string;
  disabled?: boolean;
}

interface SelectSubscriptionsPlanProps {
  status: SubscriptionPlanStatusEnum;
  disabled?: boolean;
  setSubscriptionAccess(subscriptionPlan: string): void;
}

export const SelectSubscriptionsPlan = ({
  status,
  setSubscriptionAccess,
  disabled,
}: SelectSubscriptionsPlanProps) => {
  const typeSubscriptionAccess: typeAccessSubscriptions[] = [
    {
      accessType: SubscriptionPlanStatusEnum.Unauthorized,
      text: "Бесплатно (без регистрации)",
    },
    {
      accessType: SubscriptionPlanStatusEnum.Authorized,
      text: "Бесплатно (с регистрацией)",
    },
    {
      accessType: SubscriptionPlanStatusEnum.Paid,
      text: "По подписке",
    },
    {
      accessType: SubscriptionPlanStatusEnum.Purchases,
      text: "Покупка",
    },
  ];

  return (
    <>
      <Autocomplete
        disabled={disabled}
        options={typeSubscriptionAccess.map((sub) => sub.accessType)}
        onChange={(_, value) => setSubscriptionAccess(value)}
        getOptionLabel={(option) =>
          typeSubscriptionAccess.find((sub) => sub.accessType === option)?.text
        }
        value={
          typeSubscriptionAccess.find((sub) => sub.accessType === status)
            ?.accessType || null
        }
        renderInput={(params) => (
          <MobileAppInput
            {...params}
            label="Доступ к контенту"
            variant="standard"
          />
        )}
      />
    </>
  );
};
