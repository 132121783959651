import { GraphQLError } from "graphql";
import { CourseActionType, CourseItemType } from "./types";
import { errorMessages } from "./constants";

export const getAvailableCourses = (courses: CourseItemType[]) =>
  courses.filter(({ courseIsAvailable }) => courseIsAvailable);

export const getCourseById = (courses, id) =>
  courses.find((course) => course?.id === id);

export const getCourseErrorMessage = (
  errors: readonly GraphQLError[],
  variant: CourseActionType,
) => { 
  const { responseError, forbidden, defaultMessage } = errorMessages;

  if (errors[0]?.message?.includes(responseError)) {
    return forbidden[variant];
  };

  return defaultMessage[variant];
};
