import React, { useEffect } from "react";

import { useLazyQuery, useQuery } from "@apollo/client";
import { GetDashboardMasteringMoneyReportQuery, GetDashboardMasteringMoneyReportDocument, GetProjectsLightDocument, GetProjectsLightQuery } from "../../../../generated/graphql";
import { IDashboardReportProps } from "../../Dashboard";

import { Box, TableBody, TableHead, Table, TableRow, CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { defaultMaterialTheme, LoadingBox } from "../../../shared/Style/Style";
import { CustomTableCell, CustomTableHeaderCell } from "../../../shared/CustomTable/CustomTable.style";
import { ReportBody } from "../../Dashboard.style";
import {
  ContentTable,
  ItemColor,
  ItemContainer,
} from "./MasteringMoney.style";

import { SpeedometerReport } from "../../../shared/SpeedometerReport/SpeedometerReport";
import { CustomTable } from "../../../shared/CustomTable";

import { yellowColors } from "../../../../constants/speedometersData";

export const MasteringMoney = ({ dateRange, projectId, setProjectId }: IDashboardReportProps) => {
  let arrContent = yellowColors;

  const [getMoneyReport, {
    data: moneyData,
    loading: moneyLoading,
    error: moneyError
  }] = useLazyQuery<GetDashboardMasteringMoneyReportQuery>(
    GetDashboardMasteringMoneyReportDocument, {
      fetchPolicy: 'cache-and-network',
    }
  );

  const {
    data: projectsData,
    loading: projectsLoading,
    error: projectsError,
  } = useQuery<GetProjectsLightQuery>(GetProjectsLightDocument);

  useEffect(() => {
    if (projectsData && projectsData?.getProjectsLight?.length) {
      setProjectId(projectsData.getProjectsLight[0].id);
    }
  }, [projectsData?.getProjectsLight])

  useEffect(() => {
    if (projectId) {
      getMoneyReport({
        variables: {
          projectId,
          ...dateRange
        }
      })
    }
  }, [projectId]);

  let content;

  if (projectsLoading) {
    content = (
      <TableRow>
        <CustomTableCell>
          <LoadingBox>
            <CircularProgress color='inherit' />
          </LoadingBox>
        </CustomTableCell>
      </TableRow>
    )
  }

  if (projectsError || moneyError) {
    content = (
      <TableRow>
        <CustomTableCell>
          При загрузке данных произошла ошибка
        </CustomTableCell>
      </TableRow>
    )
  }

  if (projectsData) {
    const projects = projectsData.getProjectsLight;

    if (!projects.length) {
      content = (
        <TableRow>
          <CustomTableCell>
            Проекты отсутствуют
          </CustomTableCell>
        </TableRow>
      )
    }
  }

  if (projectsData) {
    const projects = projectsData.getProjectsLight;

    const report = moneyData?.getDashboardMasteringMoneyReport[0];

    const additionalData = [report?.costFact, report?.costEstimate];

    content = (
      <TableRow>
        <ReportBody>
          <SpeedometerReport
            value={report?.pct || 0}
            additionalData={additionalData}
          />

          <ContentTable>
            <FormControl fullWidth>
              {!projectId && <InputLabel>Выберите проект...</InputLabel>}
                <ThemeProvider theme={defaultMaterialTheme}>
                  <Select
                    disabled={moneyLoading}
                    value={projectId}
                    onChange={({ target: { value } }) => {
                      if (typeof value === 'string') {
                        value ? setProjectId(value) : setProjectId(undefined);
                      }
                    }}
                    fullWidth
                  >
                    <MenuItem value={''}>
                      Нет проекта
                    </MenuItem>
                    {projects.map(project => {
                      return (
                        <MenuItem key={project.id} value={project.id}>
                          {project.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </ThemeProvider>
              </FormControl>


            <Box mt={12}>
              {arrContent.map((el, i) => (
                <ItemContainer>
                  <ItemColor
                    style={{
                      backgroundColor: `${el.color}`,
                    }}
                  />
                  {el.name}
                </ItemContainer>
              ))}
            </Box>
          </ContentTable>
        </ReportBody>
      </TableRow>
    )
  }

  const table = (
    <Table>
      <TableHead>
        <TableRow>
          <CustomTableHeaderCell>Освоение денег</CustomTableHeaderCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {content}
      </TableBody>
    </Table>
  )

  return (
    <CustomTable data={moneyData?.getDashboardMasteringMoneyReport} tableContent={table}/>
  );
};
