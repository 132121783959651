import { useReactiveVar } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { GameModelState } from "../../../../../generated/graphql";
import { robotsState } from "../../state";
import { RobotMenu as StageMenu, StageListItem } from "../../style";
import { MainTableCell, MainTableImage, MainTableStatusCell } from "../../../../shared/Style/Style";
import { Menu } from "../../../../shared/Menu";
import { robotModelPageLink, robotsPageLink, robotStagePageLink } from "../../constants";

export const StagesList = () => {
    const { appId, robotId } = useParams<{ appId: string, robotId: string }>();
    const { push } = useHistory();
    
    const robotStagesList = useReactiveVar(robotsState.robotStagesList);

    const redirectToStageRobotPage = (stageId: string) => {
      push(`/mobileApps/${appId}/${robotsPageLink}/${robotId}/${robotModelPageLink}/${stageId}/${robotStagePageLink}`);
    };

    const toggleStagePublication = async (stage: GameModelState) => {
        if (!stage?.isCanBePublished && !stage?.isPublished) {
            return robotsState.setNotifications({
                error: true,
                message: "Для публикации стадии необходимо загрузить фото, бандлы/манифесты, артефакты по дефолту"
            });
        }

        await robotsState.updateRobotStage({
            updateGameModelStateId: stage?.id,
            input: { isPublished: !stage?.isPublished },
        });
        await robotsState.getRobotStages({ gameModelId: robotId });
    };

    const menuItems = (stage: GameModelState) => [
      {
        name: stage?.isPublished ? "Снять с публикации" : "Опубликовать",
        action: () => toggleStagePublication(stage),
      },
      {
        name: "Редактировать",
        action: () => redirectToStageRobotPage(stage.id),
      },
      {
        name: "Удалить",
        action: () => robotsState.setDeleteStage({ isOpen: true, stage }),
        color: "red",
      },
    ];

    return (
        <>
            {robotStagesList.map((stage) => (
                <StageListItem key={stage.id} onClick={() => redirectToStageRobotPage(stage.id)}>
                    <MainTableCell>
                        <MainTableImage
                            prim={stage?.image}
                        />
                    </MainTableCell>

                    <MainTableCell>{stage.name}</MainTableCell>

                    <MainTableCell>{stage.description}</MainTableCell>

                    <MainTableStatusCell status={stage.isPublished}>
                        {stage.isPublished ? "Опубликовано" : "Не опубликовано"}
                    </MainTableStatusCell>

                    <StageMenu >
                        <Menu
                            vertical={36}
                            horizontal={12}
                            width={170}
                            items={menuItems(stage)}
                        />
                    </StageMenu>
                </StageListItem>
            ))}
        </>
    );
};
