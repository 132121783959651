import { useMemo } from 'react';
import { Box, TableCell, TableRow } from '@material-ui/core';
import { columns, ColumnsIdEnum } from './constants';
import { AdminReport } from '../../../generated/graphql';
import { Menu } from '../../shared/Menu';
import { toLocaleDateString } from '../../shared/Utils/DateOperations/toLocaleDateString';

interface ReportsListProps {
  reports: AdminReport[];
  handleDeleteReport: (id: string) => void;
}
export const ReportsList = ({
  reports,
  handleDeleteReport,
}: ReportsListProps) => (
  <>
    {useMemo(
      () =>
        reports.map((row, id) => {
          return (
            <TableRow hover role="checkbox" tabIndex={-1} key={id}>
              {columns.map((column) => {
                let value = row[column.id];

                if (column.id === ColumnsIdEnum.Link) {
                  value = (
                    <Box marginRight={2}>
                      <a href={row.link}>Скачать отчет</a>
                    </Box>
                  );
                }

                if (column.id === ColumnsIdEnum.Date) {
                  if (row.startDate && row.endDate) {
                    value = `${toLocaleDateString(
                      row.startDate
                    )} - ${toLocaleDateString(row.endDate)}`;
                  }
                }

                if (column.id === ColumnsIdEnum.Delete) {
                  value = (
                    <Menu
                      vertical={36}
                      horizontal={12}
                      width={170}
                      items={[
                        {
                          name: 'Удалить отчёт',
                          action: () => handleDeleteReport(row.id),
                        },
                      ]}
                    />
                  );
                }

                return (
                  <TableCell key={column.id} align="center">
                    {value}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        }),
      [reports]
    )}
  </>
);
