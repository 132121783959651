import React, { MouseEvent, useState, useEffect } from "react";

import { useMutation } from "@apollo/client";
import {
  Maybe,
  DeleteNewsMutation,
  DeleteNewsDocument,
  GetNewsDocument,
  PublishNewsMutation,
  PublishNewsDocument,
} from "../../generated/graphql";

import { IconButton, Menu, Snackbar } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Alert } from "@material-ui/lab";

import {
  MenuItemStyle,
  MenuItemDelete,
  secondary,
} from "../shared/Style/Style";

const ITEM_HEIGHT = 48;

interface INotificationMenuProps {
  openModal(): void;

  newsId: Maybe<string> | undefined;
  isPublished: boolean;
}

export const NewsMenu = ({
  openModal,
  newsId,
  isPublished,
}: INotificationMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setDeleteSnackbarOpen(false);
    setPublishSnackbarOpen(false);
    setAnchorEl(null);
  };

  const editHandler = () => {
    openModal();
    handleClose();
  };

  const [isDeleteSnackbarOpen, setDeleteSnackbarOpen] = useState(false);
  const [isPublishSnackbarOpen, setPublishSnackbarOpen] = useState(false);

  const [
    deleteNews,
    {
      data: deleteNewsData,
      loading: deleteNewsLoading,
      error: deleteNewsError,
    },
  ] = useMutation<DeleteNewsMutation>(DeleteNewsDocument, {
    variables: {
      id: newsId,
    },
    refetchQueries: [
      {
        query: GetNewsDocument,
        variables: { limit: 1000, skip: 0, isPublished: null },
      },
    ],
  });

  const [
    publishNews,
    {
      data: publishNewsData,
      loading: publishNewsLoading,
      error: publishNewsError,
    },
  ] = useMutation<PublishNewsMutation>(PublishNewsDocument, {
    variables: {
      id: newsId,
    },
    refetchQueries: [
      {
        query: GetNewsDocument,
        variables: { limit: 1000, skip: 0, isPublished: null },
      },
    ],
  });

  useEffect(() => {
    if (deleteNewsError) {
      setPublishSnackbarOpen(false);
      setDeleteSnackbarOpen(true);
    }

    if (deleteNewsData) {
      handleClose();
    }
  }, [deleteNewsError, deleteNewsData]);

  useEffect(() => {
    if (publishNewsError) {
      setDeleteSnackbarOpen(false);
      setPublishSnackbarOpen(true);
    }

    if (publishNewsData) {
      handleClose();
    }
  }, [publishNewsError, publishNewsData]);

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        style={{ color: secondary }}
        onClick={handleClick}
      >
        <MoreVertIcon fontSize="large" />
      </IconButton>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItemStyle
          prim={false}
          onClick={() => publishNews()}
          disabled={deleteNewsLoading || publishNewsLoading || isPublished}
        >
          Опубликовать
        </MenuItemStyle>

        <MenuItemStyle
          prim={false}
          onClick={() => publishNews()}
          disabled={deleteNewsLoading || publishNewsLoading || !isPublished}
        >
          Не публиковать
        </MenuItemStyle>

        <MenuItemStyle
          prim={false}
          disabled={deleteNewsLoading || publishNewsLoading}
          onClick={editHandler}
        >
          Редактировать
        </MenuItemStyle>

        <MenuItemDelete
          prim={false}
          disabled={deleteNewsLoading || publishNewsLoading}
          onClick={() => deleteNews()}
        >
          Удалить
        </MenuItemDelete>
      </Menu>

      <Snackbar
        open={isDeleteSnackbarOpen || isPublishSnackbarOpen}
        onClose={() =>
          isPublishSnackbarOpen
            ? setPublishSnackbarOpen(false)
            : setDeleteSnackbarOpen(false)
        }
        autoHideDuration={6000}
        transitionDuration={300}
      >
        <Alert severity="error">
          {isPublishSnackbarOpen
            ? "Не удалось опубликовать новость"
            : "Не удалось удалить новость"}
        </Alert>
      </Snackbar>
    </div>
  );
};
