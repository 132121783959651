import styled from "styled-components";
import { Box, IconButton } from "@material-ui/core";
import { ModalInput } from "../shared/Style/Style";

export const NotificationModalContent = styled(Box)`
  padding: 30px;
  text-align: center;
  position: relative;
`;

export const CloseBtn = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const NotificationModalInput = styled(ModalInput)`
  margin-bottom: 60px;

  &:first-of-type {
    margin-bottom: 20px;
  }
`;
