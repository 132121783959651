import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  height: 450px;

  model-viewer {
    width: 100%;
    height: 400px;
    background-color: transparent;
    --poster-color: #ffffff00;
    margin-bottom: 20px;
    padding-bottom: 50px;
  }
`;

export const ButtonStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
`;

interface ILogoLinkContainerProps {
  b: string;
  l: string;
  r?: string;
}

export const LogoLinkContainer = styled.a<ILogoLinkContainerProps>`
  margin: 20px auto 0;
  display: block;
  position: absolute;
  bottom: ${props => props.b};
  left: ${props => props.l};
  right: ${props => props.r || ''};
`;
