import React, { useEffect, useState } from "react";

import { IKnowledgeBaseBlockInnerProps } from "../../KnowledgeBaseBlockItem";

import { Collapse, InputAdornment } from "@material-ui/core";
import { FormatListNumbered, FormatListBulleted, RadioButtonUnchecked } from "@material-ui/icons";

import { CloseIconButton } from "../../../shared/Buttons/CloseIconButton";
import { CustomIconButton } from "../../../shared/Buttons/CustomIconButton";
import { DeleteIconButton } from "../../../shared/Buttons/DeleteIconButton";
import { EditIconButton } from "../../../shared/Buttons/EditIconButton";
import { SaveIconButton } from "../../../shared/Buttons/SaveIconButton";

import { BlockInput } from "../../KnowledgeBaseBlockItem.style";

export const ListBlock = ({
  disabled,
  block,
  createListItem,
  updateListItem,
  deleteListItem,
  successRequest
}: IKnowledgeBaseBlockInnerProps) => {
  const { lists, id, type } = block;

  const [content, setContent] = useState('');
  const [isNumbered, setNumbered] = useState(true);
  const [chosenItemId, setChosenItemId] = useState('');
  const [isCreating, setCreating] = useState(false);

  useEffect(() => {
    if (successRequest) {
      setContent('');
      setChosenItemId('');
      setNumbered(true);
      setCreating(false);
    }
  }, [successRequest]);

  useEffect(() => {
    if (disabled) {
      setContent('');
      setChosenItemId('');
      setNumbered(true);
      setCreating(false);
    }
  }, [disabled]);


  return (
    <>
      {!!lists.length
        && lists.map((item, i) => (
          <BlockInput
            key={item.id}
            fullWidth
            disabled={disabled || !chosenItemId || chosenItemId !== item.id}
            type={type}
            value={chosenItemId === item.id ? content : item.content}
            onChange={({ target: { value } }) => setContent(value)}
            InputProps={{
              startAdornment: (
                item.isNumbered
                  ? <InputAdornment position="end">{`${i + 1}. `}</InputAdornment>
                  : <RadioButtonUnchecked style={{ fontSize: '8px' }} />
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Collapse
                    in={!disabled}
                  >
                    {chosenItemId === item.id
                      ? (
                        <>
                          <SaveIconButton
                            save={() => updateListItem(item.id, content)}
                            disabled={disabled}
                          />

                          <CloseIconButton
                            close={() => {
                              setContent('');
                              setChosenItemId('');
                            }}
                            disabled={disabled}
                            position='static'
                          />
                        </>
                      )
                      : (
                        <>
                          <EditIconButton
                            edit={() => {
                              setChosenItemId(item.id)
                              setContent(item.content)
                            }}
                            disabled={disabled || isCreating || (!!chosenItemId && chosenItemId !== item.id)}
                          />

                          <DeleteIconButton
                            disabled={disabled || isCreating || (!!chosenItemId && chosenItemId !== item.id)}
                            deleteHandler={() => deleteListItem(item.id)}
                            item='пункт списка'
                          />
                        </>
                      )
                    }
                  </Collapse>
                </InputAdornment>
              )
            }}
          />
        ))
      }

      <BlockInput
        fullWidth
        disabled={disabled || !!chosenItemId}
        type={type}
        placeholder='Создать пункт списка'
        value={chosenItemId ? '' : content}
        onFocus={() => setCreating(true)}
        onBlur={() => setCreating(false)}
        onChange={({ target: { value } }) => setContent(value)}
        InputProps={{
          startAdornment: (
            isNumbered
              ? <InputAdornment position="end">{`${lists.length + 1}. `}</InputAdornment>
              : <RadioButtonUnchecked style={{ fontSize: '8px' }} />
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Collapse
                in={!disabled}
              >
                <>
                  <CustomIconButton
                    callback={() => setNumbered(!isNumbered)}
                    disabled={disabled || !!chosenItemId}
                    children={isNumbered
                      ? (
                        <FormatListNumbered />
                      )
                      : (
                        <FormatListBulleted />
                      )
                    }
                    mainColor='black'
                  />

                  <SaveIconButton
                    save={() => createListItem(id, content, isNumbered)}
                    disabled={disabled || !!chosenItemId}
                  />
                </>
              </Collapse>
            </InputAdornment>
          )
        }}
      />
    </>
  )
}