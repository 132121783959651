import React from "react";
import {
  ClientSiteModel,
} from "../../../../../generated/graphql";
import { ModelFileItem } from "./ModelFileItem";
import { FileList } from "./ModelModal.style";
import { ModelFilesErrors } from "./constants";

interface ModelFileListProps {
  model: ClientSiteModel;
  clientSiteModelId: string;
  disabled: boolean;
  modelFilesError: ModelFilesErrors;
  setModelFilesError(state: ModelFilesErrors): void;
}

export enum ClientSiteModelFilesEnum {
  GlbFile = "glbFile",
  UsdzFile = "usdzFile",
  GltfFile = "gltfFile",
}

export const ModelFileList = ({
  model,
  clientSiteModelId,
  disabled,
  modelFilesError,
  setModelFilesError,
}: ModelFileListProps) => {
  return (
    <FileList>
      <ModelFileItem
        inputId="glb"
        fileType={ClientSiteModelFilesEnum.GlbFile}
        link={model?.glbFile}
        clientSiteModelId={clientSiteModelId}
        disabled={disabled}
        error={modelFilesError.glbFileError}
        setError={() =>
          setModelFilesError({ ...modelFilesError, glbFileError: "" })
        }
      />

      <ModelFileItem
        inputId="usdz"
        fileType={ClientSiteModelFilesEnum.UsdzFile}
        link={model?.usdzFile}
        clientSiteModelId={clientSiteModelId}
        disabled={disabled}
        error={modelFilesError.usdzFileError}
        setError={() =>
          setModelFilesError({ ...modelFilesError, usdzFileError: "" })
        }
      />

      <ModelFileItem
        inputId="gltf"
        fileType={ClientSiteModelFilesEnum.GltfFile}
        link={model?.gltfFile}
        clientSiteModelId={clientSiteModelId}
        disabled={disabled}
        error={modelFilesError.gltfFileError}
        setError={() =>
          setModelFilesError({ ...modelFilesError, gltfFileError: "" })
        }
      />
    </FileList>
  );
};
