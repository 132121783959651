import React, { useContext, useState } from "react";

import { ISalaryModalContext, SalaryModalContext } from "../SalaryModalContext";

import { stringifyDateHandler } from "../../shared/Utils/DateOperations/stringifyDateHandler";

import { ISalaryModalInnerProps } from "../SalaryModal";

import { useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import {
  GetUserPayrollsDocument,
  CreateUserPayrollDocument,
  CreateUserPayrollMutation,
} from "../../../generated/graphql";

import KeyboardArrowLeftRoundedIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";

import {
  ModalBody,
  ModalHeader,
  BtnsWrapper,
  CloseBtn,
  SubmitBtn,
  ModalForm,
  ModalField,
  ModalLabel,
  ModalInput,
  DateControlBtn,
  DateDisplay,
  DatePicker,
  CenteredErrorTxt,
  ErrorDateTxt,
} from "../SalaryModal.style";

export const PayrollModal = ({
  close,
  payrolls,
}: ISalaryModalInnerProps) => {
  const { userId } = useParams<{ userId: string }>();

  const { decreaseMonthHandler, increaseMonthHandler }: ISalaryModalContext = useContext(SalaryModalContext);

  const today = new Date();

  const currentMonth = today
    .toISOString()
    .split("-")
    .slice(0, 2)
    .join("-");

  const [date, setDate] = useState(currentMonth);

  const stringifiedDate = stringifyDateHandler(date);

  const [createPayroll] = useMutation<CreateUserPayrollMutation>(
    CreateUserPayrollDocument,
    {
      refetchQueries: [
        { query: GetUserPayrollsDocument, variables: { userId } },
      ],
    }
  );

  const [newPayrollData, setNewPayrollData] = useState({
    userId,
    hoursPlan: 0,
  });

  const [errors, setErrors] = useState({
    nameError: false,
    hoursError: false,
  });

  const createPayrollHandler = () => {
    const isHoursError = newPayrollData.hoursPlan <= 0;
    const isNameError = payrolls?.some((payroll) =>
      payroll?.name?.includes(date)
    );

    if (isNameError) {
      setErrors((state) => ({
        ...state,
        nameError: true,
      }));
    }

    if (isHoursError) {
      setErrors((state) => ({
        ...state,
        hoursError: true,
      }));
    }

    if (isNameError || isHoursError) {
      return;
    }

    setErrors({
      nameError: false,
      hoursError: false,
    });

    createPayroll({
      variables: { payrollData: { ...newPayrollData, name: date } },
    });
    close();
  };

  return (
    <ModalBody>
      <ModalHeader>создание платежной ведомости</ModalHeader>
      <ModalForm>
        <ModalField>
          <DateControlBtn
            edge="end"
            aria-label="decrease"
            onClick={() => {
              setErrors({ ...errors, nameError: false });
              decreaseMonthHandler({ date, setDate });
            }}
          >
            <KeyboardArrowLeftRoundedIcon fontSize={"large"} />
          </DateControlBtn>

          <DateDisplay error={errors.nameError}>{stringifiedDate}</DateDisplay>

          <DateControlBtn
            edge="end"
            aria-label="increase"
            onClick={() => {
              setErrors({ ...errors, nameError: false });
              increaseMonthHandler({ date, setDate });
            }}
          >
            <KeyboardArrowRightRoundedIcon fontSize={"large"} />
          </DateControlBtn>

          {errors.nameError && (
            <ErrorDateTxt>
              Платёжная ведомость за этот период уже существует
            </ErrorDateTxt>
          )}
        </ModalField>

        <ModalField>
          <ModalLabel>План часов на месяц</ModalLabel>

          <ModalInput
            type="number"
            error={errors.hoursError}
            value={newPayrollData.hoursPlan}
            onChange={({ target: { value } }) => {
              setNewPayrollData({ ...newPayrollData, hoursPlan: +value });
              setErrors({ ...errors, hoursError: false });
            }}
          />

          {errors.hoursError && (
            <CenteredErrorTxt>
              {" "}
              Количество часов должно быть больше 0{" "}
            </CenteredErrorTxt>
          )}
        </ModalField>
      </ModalForm>

      <DatePicker type="month" min="2015-01" value={date} />

      <BtnsWrapper>
        <CloseBtn onClick={close}>закрыть</CloseBtn>

        <SubmitBtn
          disabled={Object.values(errors).some((err) => err)}
          onClick={() => createPayrollHandler()}
        >
          создать ведомость
        </SubmitBtn>
      </BtnsWrapper>
    </ModalBody>
  );
};
