import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  DialogContentText,
} from "@material-ui/core";

import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";

import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
} from "../../shared/Style/Style";
import {Item} from "./types";
import {DeleteErrorMessages, INITIAL_DATA} from "./constants";
import {getErrorArtifactMessage, getItemData} from "./utils";
import { useDeleteApp, useDeletePage, useDeleteModel, useDeleteNotification, useDeleteAward, useDeleteRobot, useDeleteStage, useDeleteArtefact } from "./hooks";

interface IMobileAppDeleteModalProps {
  open: boolean;

  close(): void;

  refetch?(): void;

  item?: Item;
}

export const MobileAppDeleteModal = ({
  open,
  close,
  item,
  refetch,
}: IMobileAppDeleteModalProps) => {
  const [data, setData] = useState(INITIAL_DATA);
  const [requestError, setRequestError] = useState(false);
  
  const { deleteApp, deleteAppData, deleteAppLoading, deleteAppError } = useDeleteApp(data?.id);
  const { deletePage, deletePageData, deletePageLoading, deletePageError } = useDeletePage(data?.id); 
  const { deleteModel, deleteModelData, deleteModelLoading, deleteModelError} = useDeleteModel(data?.id);
  const { deleteNotification, deleteNotificationData, deleteNotificationLoading, deleteNotificationError } = useDeleteNotification(data?.id);
  const { deleteAward, deleteAwardData, deleteAwardLoading, deleteAwardError } = useDeleteAward(data?.id);
  const { deleteRobot, deleteRobotData, deleteRobotLoading, deleteRobotError } = useDeleteRobot(data?.id);
  const { deleteStage, deleteStageData, deleteStageLoading, deleteStageError } = useDeleteStage(data?.id);
  const { deleteArtifact, deleteArtifactData, deleteArtifactLoading, deleteArtifactError } = useDeleteArtefact(data?.id);

  const deleteError = deleteAppError || deletePageError || deleteModelError || deleteNotificationError || deleteAwardError || deleteRobotError || deleteStageError || deleteArtifactError;
  const deleteData = deleteAppData || deletePageData || deleteModelData || deleteNotificationData || deleteAwardData || deleteRobotData || deleteStageData || deleteArtifactData;
  const disableOnLoading = deletePageLoading || deleteAppLoading || deleteModelLoading || deleteNotificationLoading || deleteAwardLoading || deleteRobotLoading || deleteStageLoading || deleteArtifactLoading;
  const errorMessage = DeleteErrorMessages.get(deleteError?.message) ?? getErrorArtifactMessage(deleteError?.message) ?? `При удалении ${data.entityName} произошла ошибка`;

  useEffect(() => {
    if (item?.id) {
      setData(getItemData(item));
    }
  }, [item?.id]);

  useEffect(() => {
    if (deleteError) {
      setRequestError(true);
    }
  }, [deleteError]);

  useEffect(() => {
    if (deleteData) {
      refetch();
      close();
    }
  }, [deleteData]);

  const callbacks: { [key: string]: () => {} } = {
    Application: deleteApp,
    ApplicationMain: deletePage,
    ApplicationMainModel: deleteModel,
    MobileNotification: deleteNotification,
    Award: deleteAward,
    GameModel: deleteRobot,
    GameModelState: deleteStage,
    GameModelArtifact: deleteArtifact,
  };

  return (
    <Dialog open={open}>
      <DialogContent style={{ minWidth: "400px", marginBottom: "20px" }}>
        <CloseIconButton close={close} />

        <Box textAlign="center" mb={2}>
          <DialogTitle>Удаление {data.entityName}</DialogTitle>
        </Box>

        <DialogContentText align="center">
          При удалении {data.entityName} {data.name} пропадут данные. Вы
          уверены, что хотите удалить?
        </DialogContentText>

        <ButtonsWrapper>
          <CancelButton onClick={close} disabled={disableOnLoading}>
            Отмена
          </CancelButton>

          <ButtonCustom
            onClick={callbacks[item?.__typename]}
            disabled={disableOnLoading}
          >
            удалить
          </ButtonCustom>
        </ButtonsWrapper>
      </DialogContent>

    <ErrorMessageSnackbar
      open={requestError}
      closeHandler={() => setRequestError(false)}
      message={errorMessage}
    />
  </Dialog>
  );
};
