import styled from "styled-components";
import { ListItem, Box, RadioGroup, FormControlLabel, FormLabel, FormControl, List, ListItemText, Button, Switch } from "@material-ui/core";
import { ButtonCustomCancel, ButtonCustom, primary, secondary, SmallUserAvatarStyle, lightBlack } from "../shared/Style/Style";

export const AddUsersToNotificationModalContent = styled(Box)`
  min-width: 430px;
  padding: 20px;
`;

export const BtnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

export const UsersFilters = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & .MuiRadio-colorSecondary {
    color: ${primary};
  }
`;

export const UsersFilter = styled(FormControlLabel)`
  & .MuiTypography-body1 {
    font-size: 12px;
    color: ${lightBlack};
  }

  &.Mui-disabled .MuiRadio-colorSecondary {
   color: #ccc;
  }
`;


export const UsersFilterFormLabel = styled(FormLabel)`
  font-size: 12px;
  color: ${lightBlack};
  
  &.Mui-focused {
    color: ${lightBlack};

  }
`;

export const UsersFilterForm = styled(FormControl)<{width?: string}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: ${({ width }) => width || '569px'};
`;

export const MainList = styled(List)`
  min-height: 240px;
  max-height: 240px;
  overflow-y: auto;
`;

export const UserLabel = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  overflow-y: hidden;
`;

export const UserAvatarXS = styled(SmallUserAvatarStyle)`
  min-width: 30px;
  width: 30px;
  height: 30px;
`;

export const UserLabelText = styled(ListItemText)`
  font-size: 12px;
  width: 100%;
`;

interface IUsersGroupProps {
  expanded: boolean;
  usersCount: number;
}

export const UsersGroupByJob = styled.li<IUsersGroupProps>`
  display: block;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid ${secondary};
  height: ${ props => props.expanded ? `${(props.usersCount * 58) + 47}px` : '45px'};
  overflow-y: hidden;
  transition: height 0.5s;
`;

export const UsersGroupButton = styled(Button)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const UsersGroupButtonStatistics = styled(UsersGroupButton)`
  padding: 6px 10px 6px 45px;
`;

export const UsersGroupList = styled(List)`
  width: 100%;
  padding: 0;
`;

export const UserListItem = styled(FormControlLabel)`
  width: 100%;
`;

interface IDropdownIconProps {
  expanded: boolean;
}

export const DropdownIconWrapper = styled.span<IDropdownIconProps>`
  transition: transform 0.5s;
  transform: ${ props => props.expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export const ButtonFixedWidth = styled(ButtonCustom)`
  width: 113px;
`;

export const ButtonCancelFixedWidth = styled(ButtonCustomCancel)`
  width: 113px;
`;

export const CustomSwitch = styled(Switch)`
  & .Mui-checked + .MuiSwitch-track {
    background-color: ${primary};
  }

  & .MuiSwitch-thumb {
    color: ${primary};
  }

  & .MuiSwitch-track {
    background-color: ${secondary};
  }
`;

export const WatchedText = styled.span`
  color: ${primary};
  font-size: 12px;
`;

export const UnwatchedText = styled(WatchedText)`
  color: red;
`;

export const WatchHeadersBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 0 95px;
  margin-bottom: 15px;
`;

export const StatisticsListItem = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  padding: 13.25px 45px;
`;

export const EmptyList = styled.p`
  text-align: center;
`;
