import { useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  ChartConfiguration,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

interface PieChartAdapterProps {
  data: ChartConfiguration["data"];
  options: ChartConfiguration["options"];
}

export const PieChartAdapter = ({ data, options }: PieChartAdapterProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    let chartInstance: ChartJS | null = null;

    if (canvasRef.current) {
      ChartJS.register(
        BarController,
        BarElement,
        CategoryScale,
        LinearScale,
        Title,
        Tooltip,
        Legend
      );

      chartInstance = new ChartJS(canvasRef.current, {
        type: "pie",
        data,
        options,
      });
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [canvasRef, data, options]);

  return <canvas ref={canvasRef} />;
};
