import { CircularProgress } from "@material-ui/core";
import { LoadingBox } from "../Style/Style";

export const LoadingProgress = () => {
  return (
    <LoadingBox>
      <CircularProgress color="inherit" />
    </LoadingBox>
  )
};
