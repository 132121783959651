import React, { useState, useEffect } from "react";

import { KnowledgeBaseCategory, KnowledgeBaseTheme } from "../../generated/graphql";

import { KnowledgeBaseList } from "../KnowledgeBaseList";

import { Container, Divider, Paper, Box } from "@material-ui/core";

import { ButtonCustomWithDarkText, ButtonPlus, Header, Subtitle } from "../shared/Style/Style";
import { KnowledgeBaseParticipantsModal } from "../ParticipantsModal/KnowledgeBaseParticipantsModal";


export const KnowledgeBase = () => {
  const [chosenCategory, setChosenCategory] = useState<KnowledgeBaseCategory>(null);
  const [chosenTheme, setChosenTheme] = useState<KnowledgeBaseTheme>(null);

  const [isEditing, setEditing] = useState(false);
  const [isParticipantsModalOpen, setParticipantsModalOpen] = useState(false);

  const [participants, setParticipants] = useState<string[]>([]);

  useEffect(() => {
    if (chosenCategory) {
      setParticipants(chosenCategory.participants?.map(participants => participants?.user?.id))
    }
  }, [chosenCategory])



  return (
    <Container maxWidth="lg">
      <Header>База знаний</Header>

      <Box display="flex" justifyContent="right" mt="40px" mb="40px">
        <Box mr={2}>
          <ButtonCustomWithDarkText
            active={true}
            disabled={!chosenCategory}
            onClick={() => setParticipantsModalOpen(true)}
            width="190"
          >
            Доступ
          </ButtonCustomWithDarkText>
        </Box>

        <ButtonPlus
          variant="contained"
          noPlus={true}
          width={190}
          padding="6px 16px"
          onClick={() => setEditing(!isEditing)}
        >
          Редактировать
        </ButtonPlus>
      </Box>

      <Paper elevation={2}>
        <Box p="50px" display="flex" justifyContent="space-between">
          <Box mr={2} flexBasis={"46%"}>
            <Box pl="12px">
              <Subtitle>Категории</Subtitle>
            </Box>

            <KnowledgeBaseList
              variant="category"
              isEditing={isEditing}
              setEditing={setEditing}
              chosenCategory={chosenCategory}
              chosenTheme={chosenTheme}
              setChosenCategory={setChosenCategory}
              setChosenTheme={setChosenTheme}
            />
          </Box>

          <Divider flexItem orientation="vertical" />

          <Box ml={2} flexBasis={"46%"}>
            <Box pl="12px">
              <Subtitle>Темы</Subtitle>
            </Box>

            <KnowledgeBaseList
              variant="theme"
              isEditing={isEditing}
              setEditing={setEditing}
              chosenCategory={chosenCategory}
              chosenTheme={chosenTheme}
              setChosenCategory={setChosenCategory}
              setChosenTheme={setChosenTheme}
            />
          </Box>
        </Box>
      </Paper>

      <KnowledgeBaseParticipantsModal
        category={chosenCategory}
        participants={participants}
        setParticipants={setParticipants}
        open={isParticipantsModalOpen}
        close={() => {
          setParticipantsModalOpen(false);
          setChosenCategory(null);
        }}
      />
    </Container>
  );
}