import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GetBaseProjectTaskStatusesDocument,
  GetPmProjectsLightDocument,
  GetBaseProjectTaskStatusesQuery,
  GetPmProjectsLightQuery,
  GetLateTasksDocument,
  GetLateTasksQuery,
  SetProjectTaskLateReasonDocument,
  SetProjectTaskLateReasonMutation,
} from "../../generated/graphql";

import { tableHeaders } from "./constants";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { DictionaryProjectSelect } from "../shared/DictionaryProjectSelect/DictionaryProjectSelect";
import { DictionaryProjectTaskStatusSelect } from "../shared/DictionaryProjectTaskStatusSelect/DictionaryProjectTaskStatusSelect";

import { CustomTable } from "../shared/CustomTable";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableRow,
  TextField,
} from "@material-ui/core";
import {
  CustomContainer,
  Header,
  LoadingBox,
  useTransparentDictionaryStyles,
  useWhiteDictionaryStyles,
} from "../shared/Style/Style";
import {
  CustomTableCell,
  CustomTableHeaderCell,
  CustomTableHead,
  CustomTableRow,
  EmptyTableRow,
} from "../shared/CustomTable/CustomTable.style";

import { SelectsContainer } from "./OverdueTasks.style";
import { SaveIconButton } from "../shared/Buttons/SaveIconButton";
import { EditIconButton } from "../shared/Buttons/EditIconButton";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { convertDateStringToLocaleHandler } from "../shared/Utils/ConvertOperations/convertDateStringToLocaleHandler";

export interface ProjectDataForRequest {
  projectId: string;
  statusId: string;
}

export const OverdueTasks = () => {
  const classes = useWhiteDictionaryStyles();
  const textFieldClasses = useTransparentDictionaryStyles();
  const [requestError, setRequestError] = useState<boolean>(false);
  const [projectData, setProjectData] = useState<ProjectDataForRequest>({
    projectId: "",
    statusId: "",
  });
  const [edit, setEdit] = useState<boolean>(false);
  const [currentTaskId, setCurrentTaskId] = useState("");
  const [value, setValue] = useState<string>("");

  const {
    data: statusesData,
    loading: statusesLoading,
    error: statusesError,
  } = useQuery<GetBaseProjectTaskStatusesQuery>(
    GetBaseProjectTaskStatusesDocument
  );

  const [
    getTasks,
    { data: tasksData, loading: tasksLoading, error: tasksError },
  ] = useLazyQuery<GetLateTasksQuery>(GetLateTasksDocument);

  const {
    data: projectsData,
    loading: projectsLoading,
    error: projectsError,
  } = useQuery<GetPmProjectsLightQuery>(GetPmProjectsLightDocument);

  const [
    setProjectTaskLateReason,
    {
      data: setProjectTaskLateReasonData,
      loading: setProjectTaskLateReasonLoading,
      error: setProjectTaskLateReasonError,
    },
  ] = useMutation<SetProjectTaskLateReasonMutation>(
    SetProjectTaskLateReasonDocument,
    {
      refetchQueries: [
        {
          query: GetLateTasksDocument,
          variables: {
            projectId: projectData.projectId,
            taskStatusId: projectData.statusId,
          },
        },
      ],
    }
  );

  useEffect(() => {
    if (setProjectTaskLateReasonLoading || setProjectTaskLateReasonData) {
      setEdit(false);
    }
  }, [setProjectTaskLateReasonLoading, setProjectTaskLateReasonData]);

  useEffect(() => {
    if (setProjectTaskLateReasonError || tasksError) {
      setRequestError(true);
    }
  }, [setProjectTaskLateReasonError, tasksError]);

  useEffect(() => {
    if (projectData.statusId && projectData.projectId) {
      getTasks({
        variables: {
          projectId: projectData.projectId,
          taskStatusId: projectData.statusId,
        },
      });
    }
  }, [projectData]);

  let selectContent: JSX.Element;
  let content: JSX.Element;

  if (statusesLoading || projectsLoading || tasksLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (projectsError || statusesError || tasksError) {
    selectContent = (
      <ErrorSnackbar error={projectsError || statusesError || tasksError} />
    );
  }

  if ((statusesData && projectsData) || tasksData) {
    const statuses = statusesData.getBaseProjectTaskStatuses;
    const projects = projectsData.getPMProjectsLight;
    const tasks = tasksData?.getLateTasks;
    const status = statuses.find((status) => status.name === "inProgress");

    selectContent = (
      <SelectsContainer>
        {projects.length ? (
          <DictionaryProjectSelect
            customClasses={classes}
            values={projects}
            projectData={projectData}
            setProjectId={setProjectData}
          />
        ) : (
          <Box marginBottom={2} mt={2}>Нет проектов с просроченными задачами</Box>
        )}
        {projects.length ? (
          <DictionaryProjectTaskStatusSelect
            label="Статус"
            values={statuses}
            currentStatus={status}
            projectData={projectData}
            setStatus={setProjectData}
          />
        ) : null}
      </SelectsContainer>
    );

    content = (
      <Table>
        <CustomTableHead>
          <TableRow>
            {tableHeaders.map((header, i) => (
              <CustomTableHeaderCell key={i} width={header.width}>
                {header.title}
              </CustomTableHeaderCell>
            ))}
          </TableRow>
        </CustomTableHead>

        <TableBody>
          <EmptyTableRow />
          {tasks?.length &&
            tasks.map((task) => (
              <CustomTableRow key={task.id}>
                <CustomTableCell>{task.name}</CustomTableCell>
                <CustomTableCell>{`${task.assigneeName}`}</CustomTableCell>
                <CustomTableCell>
                  {convertDateStringToLocaleHandler(task.startDateEstimate)}
                </CustomTableCell>
                <CustomTableCell>
                  {convertDateStringToLocaleHandler(task.finishDateEstimate)}
                </CustomTableCell>
                <CustomTableCell>
                  {convertDateStringToLocaleHandler(task.startDateFact)}
                </CustomTableCell>
                <CustomTableCell>
                  {convertDateStringToLocaleHandler(task.finishDateFact)}
                </CustomTableCell>
                <CustomTableCell>{task.lateStart}</CustomTableCell>
                <CustomTableCell>{task.lateFinish}</CustomTableCell>
                <CustomTableCell>{task.cost}</CustomTableCell>
                <CustomTableCell>{task.costEstimate}</CustomTableCell>
                <CustomTableCell
                  onClick={() => {
                    setEdit(true);
                    setCurrentTaskId(task.id);
                    setValue(task.lateReason);
                  }}
                >
                  <TextField
                    disabled={!edit}
                    className={textFieldClasses.root}
                    fullWidth
                    variant="filled"
                    type="text"
                    value={task.id === currentTaskId ? value : task.lateReason}
                    onBlur={() =>
                      setProjectTaskLateReason({
                        variables: {
                          tasksId: currentTaskId,
                          lateReason: value,
                        },
                      })
                    }
                    onChange={(e) =>
                      task.id === currentTaskId
                        ? setValue(e.target.value)
                        : null
                    }
                  />
                  {/*{edit ? (*/}
                  {/*  <SaveIconButton disabled={false} save={() => {}} />*/}
                  {/*) : (*/}
                  {/*  <EditIconButton*/}
                  {/*    disabled={edit}*/}
                  {/*    active={!edit}*/}
                  {/*    edit={() => {*/}
                  {/*      setEdit(true);*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*)}*/}
                </CustomTableCell>
              </CustomTableRow>
            ))}
        </TableBody>
      </Table>
    );
  }

  return (
    <CustomContainer>
      <Header>Просроченные задачи</Header>
      {selectContent}
      <CustomTable data={undefined} tableContent={content} unlimited={true} />
      {
        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={
            (setProjectTaskLateReasonError &&
              "Ошибка при установлении причины") ||
            (tasksError && "Ошибка при получении задач")
          }
        />
      }
    </CustomContainer>
  );
};
