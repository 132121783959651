import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GetProjectTasksByEpicQuery,
  GetProjectTasksByEpicDocument,
  GetProjectLocalStatusQuery,
  GetProjectLocalStatusDocument,
  ProjectStep,
  GetProjectStepsFromJiraQuery,
  GetProjectStepsFromJiraDocument,
  GetProjectStepsByProjectQuery,
  GetProjectStepsByProjectDocument,
  Issue,
  CreateProjectStepMutation,
  CreateProjectStepDocument,
  UpdateProjectStepMutation,
  UpdateProjectStepDocument,
  DeleteProjectStepDocument,
  DeleteProjectStepMutation,
} from "../../generated/graphql";

import {
  CircularProgress,
  Table,
  TableBody,
  TableRow,
  Box,
} from "@material-ui/core";

import {
  ButtonPlus,
  CustomContainer,
  Header,
  LoadingBox,
} from "../shared/Style/Style";
import {
  CustomTableCell,
  CustomTableHeaderCell,
  CustomTableCellNoBorder,
  CustomTableEpicRow,
  CustomTableHead,
} from "../shared/CustomTable/CustomTable.style";

import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { CustomTable } from "../shared/CustomTable";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { DropdownArrowButton } from "../shared/Buttons/DropdownArrowButton";
import { SaveIconButton } from "../shared/Buttons/SaveIconButton";

import { ProjectEditNavBar } from "../ProjectEditNavBar";
import { CreateEpicModal } from "./CreateEpicModal";
import { TasksListLocal } from "./TasksListLocal";
import { TasksListJira } from "./TasksListJira";

import { convertDateToISOStringWithOffset } from "../shared/Utils/ConvertOperations/convertDateToISOStringWithOffset";
import { convertISOStringToDateWithOffset } from "../shared/Utils/ConvertOperations/convertISOStringToDateWithOffset";
import { tableHeaders } from "./tableHeaders";
import { EpicsDatePicker } from "./EpicsDatePicker";
import { DeleteIconButton } from "../shared/Buttons/DeleteIconButton";

const initialEpicData = {
  name: "",
  startDateEstimate: null as Date,
  startDateFact: null as Date,
  finishDateEstimate: null as Date,
  finishDateFact: null as Date,
  statusId: "",
};

export const Tasks = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [chosenEpic, setChosenEpic] = useState<Issue | ProjectStep>(null);

  const [showTasksList, setShowTasksList] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [nameError, setNameError] = useState(false);

  const [disabledOnLoading, setDisabledOnLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [epicData, setEpicData] = useState(initialEpicData);

  const [
    getIssues,
    { data: issuesData, loading: issuesLoading, error: issuesError },
  ] = useLazyQuery<GetProjectStepsFromJiraQuery>(
    GetProjectStepsFromJiraDocument,
    {
      variables: { projectId },
    }
  );

  const [
    getProjectSteps,
    {
      data: projectStepsData,
      loading: projectStepsLoading,
      error: projectStepsError,
    },
  ] = useLazyQuery<GetProjectStepsByProjectQuery>(
    GetProjectStepsByProjectDocument,
    {
      variables: { projectId },
    }
  );

  const [
    getTasks,
    { data: tasksData, loading: tasksLoading, error: tasksError },
  ] = useLazyQuery<GetProjectTasksByEpicQuery>(GetProjectTasksByEpicDocument);

  const {
    data: projectStatusData,
    loading: projectStatusLoading,
    error: projectStatusError,
  } = useQuery<GetProjectLocalStatusQuery>(GetProjectLocalStatusDocument, {
    variables: { projectId },
  });

  const [
    createProjectStep,
    {
      data: createProjectStepData,
      loading: createProjectStepLoading,
      error: createProjectStepError,
    },
  ] = useMutation<CreateProjectStepMutation>(CreateProjectStepDocument, {
    refetchQueries: [
      {
        query: GetProjectStepsByProjectDocument,
        variables: { projectId },
      },
    ],
  });

  const [
    updateProjectStep,
    {
      data: updateProjectStepData,
      loading: updateProjectStepLoading,
      error: updateProjectStepError,
    },
  ] = useMutation<UpdateProjectStepMutation>(UpdateProjectStepDocument, {
    refetchQueries: [
      {
        query: GetProjectStepsByProjectDocument,
        variables: { projectId },
      },
    ],
  });

  const [
    deleteProjectStep,
    {
      data: deleteProjectStepData,
      loading: deleteProjectStepLoading,
      error: deleteProjectStepError,
    },
  ] = useMutation<DeleteProjectStepMutation>(DeleteProjectStepDocument, {
    refetchQueries: [
      {
        query: GetProjectStepsByProjectDocument,
        variables: { projectId },
      },
    ],
  });

  useEffect(() => {
    if (projectStatusData) {
      projectStatusData.getProject.isLocal ? getProjectSteps() : getIssues();
    }
  }, [projectStatusData]);

  useEffect(() => {
    if (
      updateProjectStepData ||
      createProjectStepData ||
      issuesData ||
      projectStepsData ||
      deleteProjectStepData
    ) {
      setRequestError(false);
      setDisabledOnLoading(false);
      setErrorMessage("");
      setEpicData(initialEpicData);
      setModal(false);
    }

    if (updateProjectStepData) {
      setShowTasksList(false);
    }
  }, [
    updateProjectStepData,
    createProjectStepData,
    issuesData,
    projectStepsData,
    deleteProjectStepData,
  ]);

  useEffect(() => {
    if (
      updateProjectStepLoading ||
      issuesLoading ||
      projectStepsLoading ||
      deleteProjectStepLoading
    ) {
      setDisabledOnLoading(true);
    }
  }, [
    updateProjectStepLoading,
    issuesLoading,
    projectStepsLoading,
    deleteProjectStepLoading,
  ]);

  useEffect(() => {
    if (createProjectStepError) {
      setRequestError(true);
      setDisabledOnLoading(false);
      setErrorMessage("При создании этапа произошла ошибка");
    }
    if (updateProjectStepError) {
      setDisabledOnLoading(false);
      setRequestError(true);
      setErrorMessage("При изменении этапа произошла ошибка");
    }
    if (issuesError || projectStepsError) {
      setDisabledOnLoading(false);
      setRequestError(true);
      setErrorMessage("При загрузке этапов произошла ошибка");
    }
  }, [
    createProjectStepError,
    updateProjectStepError,
    issuesError,
    projectStepsError,
  ]);

  let content;

  const showTasksListHandler = (epic?: Issue | ProjectStep) => {
    if (!epic) {
      return;
    }

    if (chosenEpic?.key === epic.key) {
      setShowTasksList(!showTasksList);
      setChosenEpic(null);
      return;
    }

    setShowTasksList(true);
    setChosenEpic(epic);
    getTasks({ variables: { epicKey: epic.key } });
  };

  const createProjectStepHandler = () => {
    if (!epicData.name) {
      setNameError(true);
      return;
    }

    createProjectStep({
      variables: {
        projectStepData: {
          projectId,
          name: epicData.name,
          startDateEstimate: epicData.startDateEstimate
            ? convertDateToISOStringWithOffset(epicData.startDateEstimate)
            : null,
          startDateFact: epicData.startDateFact
            ? convertDateToISOStringWithOffset(epicData.startDateFact)
            : null,
          finishDateEstimate: epicData.finishDateEstimate
            ? convertDateToISOStringWithOffset(epicData.finishDateEstimate)
            : null,
          finishDateFact: epicData.finishDateFact
            ? convertDateToISOStringWithOffset(epicData.finishDateFact)
            : null,
        },
      },
    });
  };

  let tasksList: JSX.Element | JSX.Element[];

  if (projectStatusLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (projectStatusError) {
    content = <ErrorSnackbar error={projectStatusError} />;
  }

  if (tasksLoading) {
    tasksList = (
      <TableRow>
        <CustomTableCell colSpan={11}>
          <LoadingBox>
            <CircularProgress color="inherit" />
          </LoadingBox>
        </CustomTableCell>
      </TableRow>
    );
  }

  if (tasksError) {
    tasksList = (
      <TableRow>
        <CustomTableCell colSpan={11}>
          <ErrorSnackbar error={tasksError} />
        </CustomTableCell>
      </TableRow>
    );
  }

  if (tasksData && projectStatusData) {
    const tasks = tasksData.getProjectTasksByEpic;

    projectStatusData.getProject.isLocal
      ? (tasksList = (
          <TasksListLocal
            tasks={tasks}
            epicId={chosenEpic?.id}
            epicKey={chosenEpic?.key}
          />
        ))
      : (tasksList = <TasksListJira tasks={tasks} />);
  }

  if ((issuesData || projectStepsData) && projectStatusData) {
    const isLocal = projectStatusData.getProject.isLocal;

    const epics: ProjectStep[] | Issue[] = isLocal
      ? projectStepsData.getProjectStepsByProject
      : issuesData.getProjectStepsFromJira;

    content = (
      <Table>
        <CustomTableHead>
          <TableRow>
            {tableHeaders.map((header, i) => (
              <CustomTableHeaderCell key={i} width={header.width}>
                {header.title}
              </CustomTableHeaderCell>
            ))}
          </TableRow>
        </CustomTableHead>

        <TableBody>
          {epics?.length ? (
            epics.map((epic) =>
              isLocal ? (
                <React.Fragment key={epic.id}>
                  <CustomTableEpicRow clickable={true}>
                    <CustomTableCellNoBorder
                      onClick={() => showTasksListHandler(epic)}
                      fontSize={12}
                    >
                      <div>{(epic as ProjectStep).name}</div>
                      <div>{epic.key}</div>

                      <DropdownArrowButton
                        expanded={chosenEpic?.id === epic.id && showTasksList}
                      />
                    </CustomTableCellNoBorder>

                    <CustomTableCellNoBorder
                      onClick={() => showTasksListHandler(epic)}
                    />

                    <CustomTableCellNoBorder
                      padding="none"
                      onClick={() => showTasksListHandler(epic)}
                    />

                    <CustomTableCellNoBorder
                      onClick={() => showTasksListHandler(epic)}
                    />

                    <CustomTableCellNoBorder
                      padding="none"
                      onClick={() => setChosenEpic(epic)}
                    >
                      <EpicsDatePicker
                        disabled={chosenEpic?.id !== epic.id || showTasksList}
                        maxDate={
                          chosenEpic?.id === epic.id
                            ? epicData.finishDateEstimate ||
                              (epic as ProjectStep).finishDateEstimate
                            : null
                        }
                        value={
                          chosenEpic?.id === epic.id &&
                          epicData.startDateEstimate
                            ? epicData.startDateEstimate
                            : (epic as ProjectStep).startDateEstimate
                            ? convertISOStringToDateWithOffset(
                                (epic as ProjectStep).startDateEstimate
                              )
                            : null
                        }
                        onChange={(date) => {
                          chosenEpic?.id === epic.id &&
                            setEpicData({
                              ...epicData,
                              startDateEstimate: date,
                            });
                        }}
                      />
                    </CustomTableCellNoBorder>

                    <CustomTableCellNoBorder
                      padding="none"
                      onClick={() => setChosenEpic(epic)}
                    >
                      <EpicsDatePicker
                        disabled={chosenEpic?.id !== epic.id || showTasksList}
                        minDate={
                          chosenEpic?.id === epic.id
                            ? epicData.startDateEstimate ||
                              (epic as ProjectStep).startDateEstimate
                            : null
                        }
                        value={
                          chosenEpic?.id === epic.id &&
                          epicData.finishDateEstimate
                            ? epicData.finishDateEstimate
                            : (epic as ProjectStep).finishDateEstimate
                            ? convertISOStringToDateWithOffset(
                                (epic as ProjectStep).finishDateEstimate
                              )
                            : null
                        }
                        onChange={(date) =>
                          chosenEpic?.id === epic.id &&
                          setEpicData({
                            ...epicData,
                            finishDateEstimate: date,
                          })
                        }
                      />
                    </CustomTableCellNoBorder>

                    <CustomTableCellNoBorder
                      padding="none"
                      onClick={() => setChosenEpic(epic)}
                    >
                      <EpicsDatePicker
                        disabled={chosenEpic?.id !== epic.id || showTasksList}
                        maxDate={
                          chosenEpic?.id === epic.id
                            ? epicData.finishDateFact ||
                              (epic as ProjectStep).finishDateFact
                            : null
                        }
                        value={
                          chosenEpic?.id === epic.id && epicData.startDateFact
                            ? epicData.startDateFact
                            : (epic as ProjectStep).startDateFact
                            ? convertISOStringToDateWithOffset(
                                (epic as ProjectStep).startDateFact
                              )
                            : null
                        }
                        onChange={(date) =>
                          chosenEpic?.id === epic.id &&
                          setEpicData({ ...epicData, startDateFact: date })
                        }
                      />
                    </CustomTableCellNoBorder>

                    <CustomTableCellNoBorder
                      padding="none"
                      onClick={() => setChosenEpic(epic)}
                    >
                      <EpicsDatePicker
                        disabled={chosenEpic?.id !== epic.id || showTasksList}
                        minDate={
                          chosenEpic?.id === epic.id
                            ? epicData.startDateFact ||
                              (epic as ProjectStep).startDateFact
                            : null
                        }
                        value={
                          chosenEpic?.id === epic.id && epicData.finishDateFact
                            ? epicData.finishDateFact
                            : (epic as ProjectStep).finishDateFact
                            ? convertISOStringToDateWithOffset(
                                (epic as ProjectStep).finishDateFact
                              )
                            : null
                        }
                        onChange={(date) =>
                          chosenEpic?.id === epic.id &&
                          setEpicData({ ...epicData, finishDateFact: date })
                        }
                      />
                    </CustomTableCellNoBorder>

                    <CustomTableCellNoBorder
                      colSpan={2}
                      onClick={() => showTasksListHandler(epic)}
                    />

                    <CustomTableCellNoBorder>
                      <SaveIconButton
                        disabled={chosenEpic?.id !== epic.id || showTasksList || disabledOnLoading}
                        save={() =>
                          updateProjectStep({
                            variables: {
                              projectStepData: {
                                id: epic.id,
                                name: (epic as ProjectStep).name,
                                startDateEstimate: epicData.startDateEstimate
                                  ? convertDateToISOStringWithOffset(
                                      epicData.startDateEstimate
                                    )
                                  : (epic as ProjectStep).startDateEstimate,
                                startDateFact: epicData.startDateFact
                                  ? convertDateToISOStringWithOffset(
                                      epicData.startDateFact
                                    )
                                  : (epic as ProjectStep).startDateFact,
                                finishDateEstimate: epicData.finishDateEstimate
                                  ? convertDateToISOStringWithOffset(
                                      epicData.finishDateEstimate
                                    )
                                  : (epic as ProjectStep).finishDateEstimate,
                                finishDateFact: epicData.finishDateFact
                                  ? convertDateToISOStringWithOffset(
                                      epicData.finishDateFact
                                    )
                                  : (epic as ProjectStep).finishDateFact,
                              },
                            },
                          })
                        }
                      />
                      <DeleteIconButton
                        disabled={showTasksList || disabledOnLoading}
                        deleteHandler={() =>
                          deleteProjectStep({
                            variables: { projectStepId: epic.id },
                          })
                        }
                        item="этап"
                      />
                    </CustomTableCellNoBorder>
                  </CustomTableEpicRow>

                  {chosenEpic?.id === epic.id && showTasksList && tasksList}
                </React.Fragment>
              ) : (
                <React.Fragment key={epic.id}>
                  <CustomTableEpicRow clickable={true}>
                    <CustomTableCellNoBorder
                      style={{ cursor: "pointer" }}
                      onClick={() => showTasksListHandler(epic)}
                    >
                      <div>{(epic as Issue).fields.summary}</div>
                      <div>{epic.key}</div>

                      <DropdownArrowButton
                        expanded={chosenEpic?.id === epic.id && showTasksList}
                      />
                    </CustomTableCellNoBorder>

                    <CustomTableCellNoBorder
                      colSpan={11}
                      style={{ cursor: "pointer" }}
                      onClick={() => showTasksListHandler(epic)}
                    />
                  </CustomTableEpicRow>

                  {chosenEpic?.id === epic.id && showTasksList && tasksList}
                </React.Fragment>
              )
            )
          ) : (
            <TableRow>
              <CustomTableCell colSpan={11}>
                У этого проекта нет этапов
              </CustomTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  }

  return (
    <CustomContainer>
      <Header>Задачи</Header>

      <ProjectEditNavBar isLocal={projectStatusData?.getProject?.isLocal} />

      {projectStatusData?.getProject?.isLocal && (
        <Box textAlign="right" mb={3}>
          <ButtonPlus
            width={179}
            variant="contained"
            disabled={disabledOnLoading}
            onClick={() => setModal(true)}
          >
            Добавить этап
          </ButtonPlus>
        </Box>
      )}

      <CustomTable
        data={tasksData?.getProjectTasksByEpic}
        tableContent={content}
        unlimited={true}
      />

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />

      <CreateEpicModal
        epicData={epicData}
        setEpicData={setEpicData}
        disabled={createProjectStepLoading}
        open={modal}
        nameError={nameError}
        setNameError={setNameError}
        closeHandler={() => {
          setModal(false);
          setEpicData(initialEpicData);
        }}
        createHandler={createProjectStepHandler}
      />
    </CustomContainer>
  );
};
