import React, { useEffect, useState } from "react";

import { useParams, useHistory } from "react-router-dom";

import { useMutation, useQuery } from "@apollo/client";
import {
  GetKnowledgeBaseBlocksTestByThemeIdQuery,
  GetKnowledgeBaseBlocksTestByThemeIdDocument,
  CreateKnowledgeBaseBlockDocument,
  CreateKnowledgeBaseBlockMutation,
  KnowledgeBaseBlockTypeEnum,
  PublishKnowledgeBaseTestMutation,
  PublishKnowledgeBaseTestDocument
} from "../../generated/graphql";

import { KnowledgeBaseBlockItem } from "../KnowledgeBaseBlock";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";

import { Container, Box, CircularProgress } from "@material-ui/core";

import { Header, ButtonPlus, LoadingBox, PlaceholderBlock, ButtonCustomWithDarkText } from "../shared/Style/Style";

export const KnowledgeBaseTests = () => {
  const { themeId, themeName } = useParams<{ themeId: string, themeName: string }>();
  const history = useHistory();

  const [editedBlockId, setEditedBlockId] = useState('');

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { data, loading, error } = useQuery<GetKnowledgeBaseBlocksTestByThemeIdQuery>(
    GetKnowledgeBaseBlocksTestByThemeIdDocument, {
      variables: {
        themeId
      },
    }
  );

  const [publishThemeTest, {
    data: publishThemeTestData,
    loading: publishThemeTestLoading
  }] = useMutation<PublishKnowledgeBaseTestMutation>(PublishKnowledgeBaseTestDocument, {
    variables: { themeId }
  });

  const publishThemeTestHandler = async () => {
    try {
      await publishThemeTest();
    } catch (e) {
      setRequestError(true);
      setErrorMessage('При публикации тестов произошла ошибка');
    }
  };


  const [createTest, {
    data: createTestData,
    loading: createTestLoading
  }] = useMutation<CreateKnowledgeBaseBlockMutation>(CreateKnowledgeBaseBlockDocument, {
    refetchQueries: [{ query: GetKnowledgeBaseBlocksTestByThemeIdDocument, variables: { themeId } }]
  });

  const createTestHandler = async () => {
    try {
      await createTest({
        variables: {
          type: KnowledgeBaseBlockTypeEnum.Test,
          themeId,
          blockTypeData: {}
        }
      })
    } catch (e) {
      setRequestError(true);
      setErrorMessage('При создании теста произошла ошибка')
    }
  }

  useEffect(() => {
    if (createTestData || publishThemeTestData) {
      setRequestError(false);
      setErrorMessage('')
    }
  }, [createTestData, publishThemeTestData]);

  const disabledOnLoading = createTestLoading || publishThemeTestLoading;

  let content;

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit' />
      </LoadingBox>
    )
  }

  if (error) {
    content = (
      <PlaceholderBlock>При загрузке тестов темы произошла ошибка</PlaceholderBlock>
    )
  }

  if (data) {
    const tests = data.getKnowledgeBaseBlocksTestByThemeId;

    content = (
      tests.length
        ? (tests.map(block => (
          <KnowledgeBaseBlockItem
            key={block.id}
            disabled={disabledOnLoading}
            block={block}
            editedBlockId={editedBlockId}
            setEditedBlockId={setEditedBlockId}
            setRequestError={setRequestError}
            setErrorMessage={setErrorMessage}
          />
        )))
        : (
          <PlaceholderBlock>По теме пока нет ни одного теста</PlaceholderBlock>
        )
    )
  }

  return (
    <Container maxWidth="lg">
      <Box
        mb='30px'
      >
        <Header>Тесты {themeName}</Header>
      </Box>

      <Box
        display='flex'
        justifyContent='flex-end'
        mb='30px'
      >
        <Box
          mr='20px'
        >
          <ButtonCustomWithDarkText
            active={true}
            disabled={disabledOnLoading}
            width='190'
            onClick={() => history.push(`/knowledgeBase/answers/${themeName}/${themeId}`)}
          >
            Ответы
          </ButtonCustomWithDarkText>
        </Box>

        <ButtonCustomWithDarkText
          active={true}
          disabled={disabledOnLoading}
          width='190'
          onClick={() => history.push(`/knowledgeBase/theme/${themeName}/${themeId}`)}
        >
          Тема
        </ButtonCustomWithDarkText>
      </Box>

      {content}

      <Box
        mt='30px'
        display='flex'
        justifyContent='space-between'
      >
        <ButtonPlus
          disabled={disabledOnLoading}
          width={179}
          padding="6px 16px"
          variant='contained'
          onClick={createTestHandler}
        >
          Добавить тест
        </ButtonPlus>

        <ButtonCustomWithDarkText
          disabled={disabledOnLoading}
          active={true}
          width='179'
          onClick={publishThemeTestHandler}
        >
          Опубликовать тест
        </ButtonCustomWithDarkText>
      </Box>


      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Container>
  )
}