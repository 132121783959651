import styled from 'styled-components';
import { Button, TextField, FormControlLabel, Checkbox, List } from '@material-ui/core';

export const GroupModalContent = styled.div`
  width: 538px;
  height: 406px;
  padding: 20px;
  text-align: center;
  position: relative;
`;

export const GroupModalContentCreate = styled(GroupModalContent)`
  height: unset;
`;

export const GroupModalTitle = styled.h3`
  font-size: 14px;
  font-weight: normal;
  margin: 0;
`;

export const GroupModalTextField = styled(TextField)`
  margin: 40px 0 45px;
`;

export const GroupModalBtnsWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GroupModalBtn = styled(Button)`
  height: 36px;
  min-width: 130px;
  background-color: #fff;
`;

export const GroupModalBtnOrange = styled(GroupModalBtn)`
  background-color: #FFB61D;
  color: #fff;

  &:hover {
    background-color: rgba(255, 183, 29, 0.7);
  }
`;

export const GroupModalGroupsList = styled(List)`
    max-height: 65%;
    overflow-y: auto;
    margin: 20px 0;
`;

export const GroupModalControlLabel = styled(FormControlLabel)`
    min-width: 100%;
    display: block;
    padding: 0;
`;

export const GroupModalCheckbox = styled(Checkbox)`
  color: #000;
`;

export const GroupModalNoGroups = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
