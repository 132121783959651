import React, { ReactNode } from "react";

interface SalaryModalProviderProps {
  children: ReactNode;
}

interface IChangeMonthHandlerArgs {
  date: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
}

export interface ISalaryModalContext {
  increaseMonthHandler({date, setDate}: IChangeMonthHandlerArgs): void;
  decreaseMonthHandler({date, setDate}: IChangeMonthHandlerArgs): void;
}


export const SalaryModalContext = React.createContext({
  increaseMonthHandler: ({date, setDate}: IChangeMonthHandlerArgs) => {},
  decreaseMonthHandler: ({date, setDate}: IChangeMonthHandlerArgs) => {},
} as ISalaryModalContext)


export const SalaryModalProvider = ({ children }: SalaryModalProviderProps) => {

  const increaseMonthHandler = ({date, setDate }: IChangeMonthHandlerArgs): void => {
    const newMonth = +date.split('-')[1] + 1;

    if (newMonth > 12) {
      const newYear = +date.split('-')[0] + 1;

      setDate(`${newYear}-01`);
      return;
    }

    newMonth >= 10
      ? setDate(`${date.split('-')[0]}-${newMonth}`)
      : setDate(`${date.split('-')[0]}-0${newMonth}`);
  }

  const decreaseMonthHandler = ({date, setDate }: IChangeMonthHandlerArgs): void => {
    const newMonth = +date.split('-')[1] - 1;

    if (newMonth < 1) {
      const newYear = +date.split('-')[0] - 1;

      setDate(`${newYear}-12`);
      return;
    }

    newMonth >= 10
      ? setDate(`${date.split('-')[0]}-${newMonth}`)
      : setDate(`${date.split('-')[0]}-0${newMonth}`);
  }


  const contextValue = {
    increaseMonthHandler,
    decreaseMonthHandler,
  }

  return (
    <SalaryModalContext.Provider value={contextValue}>
      {children}
    </SalaryModalContext.Provider>
  )
}
