import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { useMutation, useQuery } from "@apollo/client";
import {
  ApplicationMainModel,
  CreateApplicationMainModelDocument,
  CreateApplicationMainModelMutation,
  GetApplicationMainModelsDocument,
  GetApplicationMainModelsQuery,
  OrderTypeEnum,
  PublishApplicationMainModelDocument,
  PublishApplicationMainModelMutation,
  SortTypeEnum,
} from "../../generated/graphql";

import {
  Box,
  CircularProgress,
  Container,
  Table,
  TablePagination,
} from "@material-ui/core";

import {
  ButtonCustom,
  ButtonPlus,
  Header,
  LoadingBox,
  MainTableCell,
  MainTableFooter,
  MainTableHeaderTitle,
  MainTableImage,
  MainTableStatusCell,
} from "../shared/Style/Style";
import {
  EmptyMobileAppModelsTableRow,
  MobileAppModelsTableRow,
} from "./MobileAppModels.style";

import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { NavBar } from "../shared/NavBar";
import { Menu } from "../shared/Menu";
import { MobileAppDeleteModal } from "../MobileAppModal/MobileAppDeleteModal";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { MobileAppModelModal } from "../MobileAppModal/MobileAppModelModal";

import { DEFAULT_AVATAR_URL } from "../shared/constants";
import { cutStringHandler } from "../shared/Utils/StringOperations/cutStringHandler";
import { navItemsMobileAppsMainPages } from "../../constants/navItemMobileApp";
import { MobileAppModalStatistic } from "../MobileAppModalStatistic/MobileAppModalStatistic";
import {
  ActiveSearchAndSortState,
  SearchAndSortBar,
} from "../SearchAndSortBar/SearchAndSortBar";

const headers = ["Фото", "Название", "Описание", "Статус", "Опрос/оценка", " "];

export const MobileAppModelsList = () => {
  const { pageId } = useParams<{ pageId: string }>();

  const [chosenModel, setChosenModel] = useState<ApplicationMainModel>(null);

  const [modelModal, setModelModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isOpenStatistic, setIsOpenStatistic] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [activeFilter, setActiveFilter] = useState<ActiveSearchAndSortState>({
    sortKey: SortTypeEnum.Name,
    orderKey: OrderTypeEnum.Asc,
  });

  // #region pagination

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // #endregion

  // #region actions

  const [
    createModel,
    {
      data: createModelData,
      loading: createModelLoading,
      error: createModelError,
    },
  ] = useMutation<CreateApplicationMainModelMutation>(
    CreateApplicationMainModelDocument,
    {
      variables: {
        modelData: {
          mainId: pageId,
          name: "Назва експерименту",
          contentAuthor: "AR Book",
        },
      },
    }
  );

  const [
    publishModel,
    { loading: publishModelLoading, error: publishModelError },
  ] = useMutation<PublishApplicationMainModelMutation>(
    PublishApplicationMainModelDocument,
    {
      refetchQueries: [
        {
          query: GetApplicationMainModelsDocument,
          variables: {
            limit: rowsPerPage,
            skip: page * rowsPerPage,
            applicationMainId: pageId,
          },
        },
      ],
    }
  );

  // #endregion

  const {
    data: appModelsData,
    loading: appModelsLoading,
    error: appModelsError,
    refetch: refetchAppModal,
  } = useQuery<GetApplicationMainModelsQuery>(
    GetApplicationMainModelsDocument,
    {
      variables: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        applicationMainId: pageId,
        search: activeFilter.searchKey ? activeFilter.searchKey : undefined,
        sort: activeFilter.sortKey,
        order: activeFilter.orderKey ? activeFilter.orderKey : undefined,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  //#region effects

  useEffect(() => {
    if (publishModelError) {
      setRequestError(true);
      setErrorMessage("При публикации модели произошла ошибка");
    }
  }, [publishModelError]);

  useEffect(() => {
    if (createModelError) {
      setRequestError(true);
      setErrorMessage("При создании модели произошла ошибка");
    }
  }, [createModelError]);

  useEffect(() => {
    if (createModelData) {
      setRequestError(false);
      setChosenModel(createModelData.createApplicationModel);
      setModelModal(true);
    }
  }, [createModelData]);

  //#endregion

  let content;

  const disableOnLoading = createModelLoading || publishModelLoading;

  if (appModelsLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (appModelsError) {
    content = <ErrorSnackbar error={appModelsError} />;
  }

  if (appModelsData) {
    const models = appModelsData.getApplicationMainModels.models;

    const pusblishedHandler = async (id: string) => {
      try {
        await publishModel({
          variables: {
            id,
          },
        });
      } catch (e) {
        console.error(e);
      }
    };

    const menuItems = (menuData: ApplicationMainModel) => [
      {
        name: "Опубликовать",
        action: () => pusblishedHandler(menuData.id),
      },
      {
        name: "Удалить",
        action: () => {
          setDeleteModal(true);
          setChosenModel(menuData);
        },
        color: "red",
      },
    ];

    content = (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          mt={"40px"}
          mb={"26px"}
        >
          <NavBar items={navItemsMobileAppsMainPages} margin={"0"} />

          <ButtonPlus
            width={179}
            variant="contained"
            disabled={disableOnLoading}
            onClick={() => createModel()}
          >
            Добавить
          </ButtonPlus>
        </Box>
        <SearchAndSortBar
          label="Введите имя или фамилию"
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          setPage={setPage}
        />
        <Table>
          <MobileAppModelsTableRow>
            {headers.map((header) => (
              <MainTableHeaderTitle key={header}>{header}</MainTableHeaderTitle>
            ))}
          </MobileAppModelsTableRow>
        </Table>

        {models.length ? (
          models.map(
            ({
              id,
              photo,
              name,
              smartNotificationId,
              description,
              isPublished,
              marketId,
              __typename,
            }) => (
              <MobileAppModelsTableRow
                key={id}
                onClick={(e) => {
                  setChosenModel({
                    id,
                    name,
                    description,
                    isPublished,
                    __typename,
                  });
                  setModelModal(true);
                }}
              >
                <MainTableCell>
                  <MainTableImage src={photo ? photo : DEFAULT_AVATAR_URL} />
                </MainTableCell>

                <MainTableCell>{name}</MainTableCell>

                <MainTableCell>{cutStringHandler(description)}</MainTableCell>

                <MainTableStatusCell status={isPublished}>
                  {isPublished ? "Опубликовано" : "Не опубликовано"}
                </MainTableStatusCell>

                <MainTableCell>
                  <ButtonCustom
                    disabled={!smartNotificationId}
                    onClick={(e) => {
                      e.stopPropagation();
                      setChosenModel({ smartNotificationId });
                      setIsOpenStatistic(true);
                    }}
                  >
                    Статистика
                  </ButtonCustom>
                </MainTableCell>

                <MainTableCell
                  onClick={(e) => {
                    e.stopPropagation();
                    setChosenModel({
                      id,
                      name,
                      description,
                      isPublished,
                      __typename,
                    });
                  }}
                >
                  <Menu
                    vertical={36}
                    horizontal={12}
                    width={170}
                    disabled={disableOnLoading}
                    items={menuItems({ id, name, __typename })}
                  />
                </MainTableCell>
              </MobileAppModelsTableRow>
            )
          )
        ) : (
          <EmptyMobileAppModelsTableRow>
            Модели отсутствуют
          </EmptyMobileAppModelsTableRow>
        )}

        <Box mt={2}>
          <MainTableFooter>
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              count={appModelsData?.getApplicationMainModels?.total || 0}
              component={"div"}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MainTableFooter>
        </Box>
      </>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header>Модели</Header>

      {content}

      <MobileAppDeleteModal
        open={deleteModal}
        close={() => {
          setDeleteModal(false);
          setChosenModel(null);
        }}
        item={chosenModel}
        refetch={() => refetchAppModal()}
      />

      {modelModal && (
        <MobileAppModelModal
          open={modelModal}
          close={() => {
            setModelModal(false);
            setChosenModel(null);
          }}
          refetchVariables={{
            limit: rowsPerPage,
            skip: page * rowsPerPage,
            applicationMainId: pageId,
          }}
          modelId={chosenModel.id}
          refetch={() => refetchAppModal()}
        />
      )}

      <MobileAppModalStatistic
        open={isOpenStatistic}
        close={() => setIsOpenStatistic(false)}
        smartNotificationId={chosenModel?.smartNotificationId}
      />
      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Container>
  );
};
