import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import {
  GetTimeReportByEpicDocument,
  GetTimeReportByEpicQuery
} from "../../../../generated/graphql";
import { IProjectReportModalContentProps } from "../ProjectReportModal";

import {
  Table,
  TableRow,
  TableBody,
  CircularProgress
} from "@material-ui/core";
import {
  CustomTableHeaderCell,
  CustomTableCell,
  ColorizedTableCell,
  CustomTableRow,
  CustomTableHead
} from "../../../shared/CustomTable/CustomTable.style";

import { LoadingBox } from "../../../shared/Style/Style";
import { CustomTable } from "../../../shared/CustomTable";

import {
  convertSecondsToHoursHandler
} from "../../../shared/Utils/ConvertOperations/convertSecondsToHoursHandler";
import { ErrorMessageSnackbar } from "../../../shared/ErrorMessageSnackbar";
import { DropdownArrowButton } from "../../../shared/Buttons/DropdownArrowButton";

import { choseOrToggleIdHandler } from "../../../shared/Utils/StateOperations/choseOrToggleId";

export const DifferenceInHoursContent = ({ epics, dateRange }: IProjectReportModalContentProps) => {
  const headers = [
    'Этап',
    'Задача',
    'Ответственный',
    'Статус',
    'Длительность план',
    'Длительность факт'
  ];

  const [chosenEpic, setChosenEpic] = useState('');
  const [requestError, setRequestError] = useState(false);

  const [getTasks, { data, loading, error }] = useLazyQuery<GetTimeReportByEpicQuery>(
    GetTimeReportByEpicDocument, {
      variables: {
        epicKey: chosenEpic,
        ...dateRange
      }
    }
  );

  useEffect(() => {
    if (chosenEpic) {
      getTasks();
    }
  }, [chosenEpic]);

  useEffect(() => {
    if (error) {
      setRequestError(true);
    }
  }, [error]);

  let tasksList: JSX.Element | JSX.Element[];
  let epicTimeEstimate: number;
  let epicTimeSpent: number;

  if (loading) {
    tasksList = (
      <CustomTableCell colSpan={6}>
        <LoadingBox>
          <CircularProgress color='inherit'/>
        </LoadingBox>
      </CustomTableCell>
    )
  }

  if (data) {
    const tasks = data.getTimeReportByEpic.tasks
    epicTimeEstimate = data.getTimeReportByEpic.totalTimeEstimate;
    epicTimeSpent = data.getTimeReportByEpic.totalTimeSpent;


    tasksList =
      tasks.length
        ? tasks.map(task => (
          <TableRow key={task.id}>
            <ColorizedTableCell
              number={task.timeEstimate - task.timeSpent}
            />
            {[
              task.name,
              task.assigneeName,
              task.status,
              convertSecondsToHoursHandler(task.timeEstimate),
              convertSecondsToHoursHandler(task.timeSpent)
            ].map((field, i) => (
              <ColorizedTableCell
                key={`${i} ${field}}`}
                number={task.timeEstimate - task.timeSpent}
              >
                {field}
              </ColorizedTableCell>
            ))}
          </TableRow>
        ))
        : (
          <TableRow>
            <CustomTableCell colSpan={6}>У данного этапа отсутствуют задачи</CustomTableCell>
          </TableRow>
        )
  }

  const content = (
    <>
      <Table>
        <CustomTableHead>
          <TableRow>
            {headers.map(header => (
              <CustomTableHeaderCell
                key={header}
                width={(header === 'Этап' || header === 'Задача') ? 250 : 150}
              >
                {header}
              </CustomTableHeaderCell>
            ))}
          </TableRow>
        </CustomTableHead>

        <TableBody>
          {epics.map(epic => (
            <>
              <CustomTableRow
                clickable={true}
                key={epic.epicKey}
                onClick={() => choseOrToggleIdHandler(epic.epicKey, chosenEpic, setChosenEpic)}
              >
                <CustomTableCell>
                  {epic.epicName}

                  <DropdownArrowButton
                    expanded={chosenEpic === epic.epicKey}
                  />
                </CustomTableCell>

                <CustomTableCell colSpan={3} />

                <CustomTableCell>
                  {chosenEpic === epic.epicKey
                    && convertSecondsToHoursHandler(epicTimeEstimate)
                  }
                </CustomTableCell>

                <CustomTableCell>
                  {chosenEpic === epic.epicKey
                    && convertSecondsToHoursHandler(epicTimeSpent)
                  }
                </CustomTableCell>
              </CustomTableRow>
              {chosenEpic === epic.epicKey && tasksList && (
                tasksList
              )}
            </>
          ))}
        </TableBody>
      </Table>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message='При загрузке задач произошла ошибка'
      />
    </>
  )

  return <CustomTable data={data?.getTimeReportByEpic?.tasks || epics} tableContent={content} />
}
