import { gql } from "@apollo/client";

export const GET_USER_INFO = gql`
  query getUserInfo {
    getUserInfo {
      id
      firstName
      lastName
      phone
      email
      photo
      role {
        role
      }
      job {
        name
      }
      status {
        status
      }
    }
  }
`;

export const GET_USER_INFO_LIGHT = gql`
  query getUserInfoLight {
    getUserInfo {
      id
      firstName
      lastName
      photo
    }
  }
`;

const GET_PM_FOR_DROP_DOWN = gql`
  query getPmForDropDown($getUsersData: GetUsersInput!) {
    getUsers(getUsersData: $getUsersData) {
      users {
        id
        firstName
        lastName
        photo
        job {
          name
        }
      }
      total
    }
  }
`;

export const GET_USERS = gql`
  query getUsers($getUsersData: GetUsersInput!) {
    getUsers(getUsersData: $getUsersData) {
      users {
        id
        firstName
        lastName
        middleName
        phone
        birthday
        email
        address
        addressReal
        photo
        instagram
        telegram
        contract
        drfo
        nda
        inn
        diploma
        passport
        salary
        creditCard
        bonuses
        role {
          id
          role
          displayName
        }
        status {
          id
          displayName
          status
        }
        position {
          id
          position
          displayName
        }
        stats {
          flex
          kpi
          levelValue
        }
        projects {
          id
          jiraId
          name
          links {
            id
            title
            link
            login
            password
          }
          status {
            displayName
          }
        }
        groups {
          id
          name
          members {
            id
            firstName
          }
        }
        friends {
          id
          firstName
          lastName
          middleName
        }
        createdBy {
          firstName
          lastName
          phone
          id
          email
        }
        job {
          id
          name
        }
        updatedDate
      }
      total
    }
  }
`;

export const GET_USERS_FOR_EMPLOYEES_REPORT = gql`
  query getUsersForEmployeesReport($getUsersData: GetUsersInput!) {
    getUsers(getUsersData: $getUsersData) {
      users {
        id
        lastName
        firstName
        city {
          name
        }
        job {
          name
        }
        department {
          name
        }
        isRemote
        agreement {
          name
        }
      }
      total
    }
  }
`;

export const GET_USERS_FOR_PARTICIPANTS = gql`
  query getUsersParticipants($getUsersData: GetUsersInput!) {
    getUsers(getUsersData: $getUsersData) {
      users {
        id
        photo
        lastName
        firstName
      }
    }
  }
`;

export const GET_USERS_FOR_PARTICIPANTS_MODAL_LIGHT = gql`
  query getUsersForParticipantsModalLight($getUsersData: GetUsersInput!) {
    getUsers(getUsersData: $getUsersData) {
      users {
        id
        photo
        lastName
        firstName
      }
    }
  }
`;

export const GET_USERS_FOR_DROPDOWN = gql`
  query getUsersForDropdown($getUsersData: GetUsersInput!) {
    getUsers(getUsersData: $getUsersData) {
      users {
        id
        lastName
        firstName
        role {
          id
          role
          displayName
        }
      }
    }
  }
`;

export const GET_USERS_WITHOUT_APPLICATION_MAIN = gql`
  query getUsersWithoutApplicationMain(
    $applicationMainId: String!
    $limit: Int
    $skip: Int
  ) {
    getUsersWithoutApplicationMain(
      applicationMainId: $applicationMainId
      limit: $limit
      skip: $skip
    ) {
      users {
        id
        photo
        lastName
        firstName
      }
      total
    }
  }
`;

export const GET_USER = gql`
  query getUser($userId: String!) {
    getUser(userId: $userId) {
      id
      oneCId
      trackerId
      jiraId
      firstName
      lastName
      middleName
      phone
      birthday
      email
      address
      addressReal
      diploma
      contractType
      passport
      idCard
      excerpt
      photo
      instagram
      telegram
      contract
      contractType
      drfo
      nda
      inn
      diploma
      passport
      bonuses
      salary
      creditCard
      rate
      previousSalary
      promotionDate
      promotionReason
      loadPercent
      hireDate
      isRemote
      agreementType {
        id
        type
        displayName
      }
      agreement {
        id
        name
      }
      role {
        id
        role
        displayName
      }
      status {
        id
        displayName
        status
      }
      position {
        id
        position
        displayName
      }
      job {
        id
        name
      }
      projects {
        id
        name
        status {
          id
        }
        stages
      }
      groups {
        id
        name
      }
      city {
        id
        name
      }
      department {
        id
        name
      }
      trackerContracts {
        id
        project {
          id
          name
        }
      }
    }
  }
`;

export const GET_USERS_WITHOUT_PROJECT = gql`
  query getUsersWithoutProject($projectId: String!, $limit: Int, $skip: Int) {
    getUsersWithoutProject(projectId: $projectId, limit: $limit, skip: $skip) {
      total
      users {
        id
        firstName
        lastName
        photo
      }
    }
  }
`;

const GET_USER_INFO_PAYROLL = gql`
  query getUserInfoPayroll($userId: String!) {
    getUser(userId: $userId) {
      salary
      job {
        id
        name
      }
      firstName
      lastName
    }
  }
`;

export const GET_USER_STATUSES = gql`
  query getUserStatuses {
    getUserStatuses {
      id
      status
      displayName
    }
  }
`;

export const GET_USER_ROLES = gql`
  query getUserRoles {
    getUserRoles {
      id
      role
      displayName
    }
  }
`;

export const GET_USER_POSITIONS = gql`
  query getUserPositions {
    getUserPositions {
      id
      position
      displayName
    }
  }
`;

export const GET_USER_OPTION_FIELDS = gql`
  query getUserOptionFields {
    getUserOptionFields {
      roles {
        id
        role
        displayName
      }
      statuses {
        id
        status
        displayName
      }
      jobs {
        id
        name
      }
      positions {
        id
        position
        displayName
      }
      cities {
        id
        name
      }
      agreements {
        id
        name
      }
      departments {
        id
        name
      }
      agreementType {
        id
        type
        displayName
      }
    }
  }
`;

const GET_USER_OPTION_FIELDS_FOR_CREATE_VACANCY_REPORT = gql`
  query getUserOptionFieldsForCreateVacancyReport {
    getUserOptionFields {
      jobs {
        id
        name
      }
      positions {
        id
        position
        displayName
      }
    }
  }
`;

export const GET_USERS_COUNT_LIGHT = gql`
  query getUsersCountLight {
    getUsersCountLight {
      status
      count
    }
  }
`;

export const GET_USERS_BY_APPLICATION = gql`
  query getUsersApp($getUsersData: GetUsersInput!) {
    getUsers(getUsersData: $getUsersData) {
      users {
        id
        firstName
        lastName
        email
        phone
        birthday
        country {
          id
          nameUKR
        }
        role {
          id
          role
          displayName
        }
      }
      total
    }
  }
`;

export const GET_USERS_BY_APPLICATION_ID = gql`
  query getUsersByApplicationId(
    $applicationId: String!
    $limit: Int
    $skip: Int
  ) {
    getUsersByApplicationId(
      applicationId: $applicationId
      limit: $limit
      skip: $skip
    ) {
      users {
        id
        firstName
        lastName
        phone
        instagram
        telegram
      }
      total
    }
  }
`;

export const GET_USER_APP = gql`
  query getUserApp($userId: String!) {
    getUser(userId: $userId) {
      id
      firstName
      lastName
      birthday
      phone
      email
      photo
      role {
        id
        role
        displayName
      }
      countryId
      schoolRegionId
      schoolClassroomId
      schoolCityId
      schoolId
      schools {
        id
        name
        isPersonal
      }
      applicationId
      schoolStatus
      status {
        id
        status
      }
      subjects {
        id
        name
        applicationId
        application {
          id
          name
        }
      }
      application {
        id
        name
      }
      createdDate
    }
  }
`;

export const GET_ARBOOK_USERS_COUNT_LIGHT = gql`
  query getARBookUsersCountLight {
    getARBookUsersCountLight {
      role
      count
    }
  }
`;
