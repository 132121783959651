import styled from "styled-components";

export const SectionContainer = styled.div`
  width: 100%;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemContainer = styled.div`
  position: relative;
`;

export const ColorPickerContainer = styled.div<{ disabled: boolean }>`
  &  div {
    display: flex;
    align-items: center;
    height: 56px;
    max-width: 190px;
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
    
    & button {
      overflow: hidden;
    }
  }
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;
