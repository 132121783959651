import styled from 'styled-components';

import { IconButton, List } from '@material-ui/core';

export const CloseBtn = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
`;

export const ProjectStageList = styled(List)`
  min-width: 200px;
  padding: 24px 0;
  text-align: center;
`;
