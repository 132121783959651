import validate from "validate.js";
import axios from "axios";
import { LoginPhoneFormType } from "../../components/Login/Login.type";
import {
  EmptyObjectAny,
  EmptyObjectString,
} from "../../components/shared/Type/Type";
import {
  PasswordEnterValidationType,
  PasswordValidationType,
} from "./validators.type";
import { Maybe } from "../../generated/graphql";


export function getError(meta: any) {
  if (meta.error && meta.touched) {
    return meta.error;
  }

  return undefined;
}

const constraints = {
  email: {
    email: true,
  },
};

export const isRequired = (value: any) =>
  value ? undefined : "Обязательное поле";

export const isNumber = (value: any) => {
  return validate.isNumber(+value) ? undefined : "Это числовое поле";
};

export const isInteger = (value: any) => {
  return validate.isInteger(+value) ? undefined : "Это целое число";
};

export const isEmail = (email: any) => {
  return !validate({ email }, constraints)
    ? undefined
    : "Это электронная почта";
};

export const notRequiredAndIsNumber = (value: any) => {
  if (value === undefined || value === null) {
    return undefined;
  }

  return isNumber(value);
};

export const isRequiredAndIsNumber = (value: any) => {
  return isRequired(value) || isNumber(value);
};

export const isRequiredAndIsInteger = (value: any) => {
  return isRequired(value) || isInteger(value);
};

export const validateEmail = (email: string) => {
  return !isRequired(email) && !validate({ email }, constraints);
};

export const validatePhone = (phone: string) => {
  //const regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
  const regex = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){12,14}(\s*)?$/

  return regex.test(phone);
};

export const validatePassword = (password: string) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,30}[#$@!%&*?]*?$/;

  return regex.test(password);
};

export const validateName = (name: string) => {
  const regex = /^[a-zA-Zа-яА-Я\-]+$/;

  return regex.test(name);
};

export const validateLoginForm = (form: LoginPhoneFormType) => {
  const errors: any = {};
  if (!validatePhone(form.phone)) {
    errors.phone = "Неверно указан номер телефона";
  }

  return errors;
};

const timeouts: EmptyObjectAny = {};

export const userUnique = (fieldName: string, required = true) => {
  return async (value: string) => {
    if (timeouts[fieldName]) {
      clearTimeout(timeouts[fieldName]);
    }

    if(fieldName === 'phone') {
      if (!validatePhone(value)) {
       return "Неверно указан номер телефона";
      }
    }

    const promise = new Promise((resolve) => {
      timeouts[fieldName] = setTimeout(async () => {
        if (required && isRequired(value)) {
          timeouts[fieldName] = null;
          resolve("Обязательное поле");
          return;
        }

        const {
          data: { exists },
        } = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/validate/user/${fieldName}/${value}`
        );

        if (exists) {

          timeouts[fieldName] = null;
          resolve("Пользователь с такими данными существует");
          return;
        }
        timeouts[fieldName] = null;
        resolve(undefined);
      }, 300);
    });

    return await promise;
  };
};

export const urlPatternValidation = (url: string) => {
  const regex = new RegExp('(http(s)?://)');
  //const regex = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
  return regex.test(url);
};

export const userExist = (fieldName: string, required = true) => {
  return async (value: string) => {
    if (timeouts[fieldName]) {
      clearTimeout(timeouts[fieldName]);
    }

    const promise = new Promise((resolve) => {
      timeouts[fieldName] = setTimeout(async () => {
        if (required && isRequired(value)) {
          timeouts[fieldName] = null;
          resolve("Обязательное поле");
          return;
        }

        const {
          data: { exists },
        } = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/validate/user/${fieldName}/${value}`
        );

        if (exists) {
          timeouts[fieldName] = null;
          resolve(undefined);
          return;
        }

        timeouts[fieldName] = null;
        resolve("Пользователя с таким e-mail не существует");
      }, 300);
    });

    return await promise;
  };
};

export const validateEmailField = (value: string) => {
  if (!value) {
    return false;
  }
  const valid = validateEmail(value);

  if (!valid) {
    return "Неправильный email";
  }

  return userUnique("email", false)(value);
};

export const validateForgotEmailField =  (value: string) => {
  if (!value) {
    return false;
  }
  const valid = validateEmail(value);

  if (!valid) {
    return "Неправильный email";
  }

  return userExist("email", false)(value);
};

export const userNameValidation = (value: any) => {
  if (!value) {
    return "Обязательное поле";
  }

  if (!validateName(value)) {
    return "Неправильные данные";
  }
};

export const passwordLoginValidation = (value: any) => {
  const errors: EmptyObjectString | PasswordEnterValidationType = {};
  if (!value) {
    errors.password = "Обязательное поле";
    return errors;
  }

  if (!validatePassword(value)) {
    errors.password =
      "Пароль должен содержать минимум 8 символов, большие и маленькие буквы латинского алфавита, а также цифры";
    return errors;
  }
};

export const passwordValidation = (formValues: PasswordValidationType) => {
  const errors: EmptyObjectString | PasswordValidationType = {};

  if (!formValues.password) {
    errors.password = "Обязательное поле";
    return errors;
  }

  if (!validatePassword(formValues.password)) {
    if (formValues.password.length < 8) {
      errors.password =
        "Пароль должен содержать минимум 8 символов, большие и маленькие буквы латинского алфавита, а также цифры";
      return errors;
    }

    if (formValues.password.length > 30) {
      errors.password = "Пароль не должен содержать больше 30 символов";
      return errors;
    }

    if (formValues.password.length >= 8 && formValues.password.length < 30) {
      errors.password =
        "Пароль должен содержать минимум одну заглавную букву, минимум одну цифру и минимум одну маленькую букву";
      return errors;
    }
  }

  if (validatePassword(formValues.password)) {
    errors.password = "";
  }

  if (formValues.password !== formValues.confirmPassword) {
    errors.confirmPassword = "Пароли не совпадают";
    return errors;
  }

  return errors;
};

export const userUniqueExisting = (
  fieldName: string,
  userId: Maybe<string> | undefined,
  required = true
) => {
  return async (value: any) => {
    if (timeouts[fieldName]) {
      clearTimeout(timeouts[fieldName]);
    }

    const promise = new Promise((resolve) => {
      timeouts[fieldName] = setTimeout(async () => {
        if (required && isRequired(value)) {
          timeouts[fieldName] = null;
          resolve("Обязательное поле");
          return;
        }

        const {
          data: { exists },
        } = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/validate/existingUser/${userId}/${fieldName}/${value}`
        );
        if (exists) {
          timeouts[fieldName] = null;
          resolve("Пользователь с такими данными существует");
          return;
        }
        timeouts[fieldName] = null;
        resolve(undefined);
      }, 300);
    });

    return await promise;
  };
};
