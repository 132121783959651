export const tableHeaders = [
  {
    title: "Задача",
    width: 190,
  },
  {
    title: "Исполнитель",
    width: 190,
  },
  {
    title: "Начало план",
    width: 90,
  },
  {
    title: "Конец план",
    width: 90,
  },
  {
    title: "Начало факт",
    width: 90,
  },
  {
    title: "Конец факт",
    width: 90,
  },
  {
    title: "Время задержки начала",
    width: 90,
  },
  {
    title: "Время задержки конца",
    width: 90,
  },
  {
    title: "Стоимость план",
    width: 90,
  },
  {
    title: "Стоимость факт",
    width: 90,
  },
  {
    title: "Причина",
    width: 210,
  },
];
