import { columns } from "./constants";
import { TableCell, TableHead, TableRow } from "@material-ui/core";

const RenderHeadColumns = () => (
  <>
    {columns.map((column) => (
      <TableCell key={column.id} align="center">
        {column.label}
      </TableCell>
    ))}
  </>
);

export const HeadColumns = () => {
  return (
    <TableHead>
      <TableRow>
        <RenderHeadColumns />
      </TableRow>
    </TableHead>
  );
};
