import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { FeatureFlagsProvider } from "./contexts/FeatureFlags";

const root = (
  <FeatureFlagsProvider>
    <App />
  </FeatureFlagsProvider>
);

ReactDOM.render(root, document.getElementById("root"));
