import React, { useRef, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  DeleteClientSiteModelFileDocument,
  DeleteClientSiteModelFileMutation,
  GetClientSiteModelDocument,
  UploadClientSiteModelFileDocument,
  UploadClientSiteModelFileMutation,
} from "../../../../../generated/graphql";
import { CloseIconButton } from "../../../../shared/Buttons/CloseIconButton";
import { getFileExtensionHandler } from "../../../../shared/Utils/StringOperations/getFileExtensionHandler";
import { ErrorMessageSnackbar } from "../../../../shared/ErrorMessageSnackbar";
import { ClientSiteModelFilesEnum } from "./ModelFileList";
import {
  FileForLoad,
  FileItem,
  InputContainer,
  InputHelperText,
  ModelModalInputTitle,
} from "./ModelModal.style";
import {
  HiddenInput,
  HiddenInputLabel,
  UnderlayButton,
} from "../../../../shared/Style/Style";
import { ModalsLink } from "../../Models.style";

interface ModelFileItemProps {
  inputId: string;
  fileType: ClientSiteModelFilesEnum;
  link: string;
  clientSiteModelId: string;
  disabled: boolean;
  error: string;
  setError(): void;
}

export const ModelFileItem = ({
  inputId,
  fileType,
  link,
  clientSiteModelId,
  disabled,
  error,
  setError,
}: ModelFileItemProps) => {
  const [requestError, setRequestError] = useState(false);
  const input = useRef<HTMLInputElement | null>(null);

  const [uploadFile, { loading: uploadFileLoading, error: uploadFileError }] =
    useMutation<UploadClientSiteModelFileMutation>(
      UploadClientSiteModelFileDocument,
      {
        refetchQueries: [
          {
            query: GetClientSiteModelDocument,
            variables: { id: clientSiteModelId },
          },
        ],
        fetchPolicy: "network-only",
      }
    );

  const [deleteFile, { loading: deleteFileLoading, error: deleteFileError }] =
    useMutation<DeleteClientSiteModelFileMutation>(
      DeleteClientSiteModelFileDocument,
      {
        refetchQueries: [
          {
            query: GetClientSiteModelDocument,
            variables: { id: clientSiteModelId },
          },
        ],
      }
    );

  useEffect(() => {
    if (uploadFileError || deleteFileError) {
      setRequestError(true);
    }
  }, [uploadFileError, deleteFileError]);

  return (
    <>
      <FileItem>
        <InputContainer>
          <ModelModalInputTitle>Файл {inputId}</ModelModalInputTitle>
          <InputHelperText>{error}</InputHelperText>
        </InputContainer>

        {link && (
          <FileForLoad>
            <ModalsLink href={link}>
              Файл {getFileExtensionHandler(link)}
            </ModalsLink>

            <CloseIconButton
              disabled={disabled}
              close={() =>
                deleteFile({
                  variables: {
                    clientSiteModelId,
                    fileType,
                  },
                })
              }
              main_color='red'
            />
          </FileForLoad>
        )}

        <HiddenInputLabel
          htmlFor={inputId}
          onClick={() => {
            input.current.value = ''
          }}
        >
          <UnderlayButton disabled={uploadFileLoading || deleteFileLoading}>
            Прикрепить
          </UnderlayButton>
        </HiddenInputLabel>
      </FileItem>

      <HiddenInput
        id={inputId}
        type="file"
        ref={input}
        disabled={uploadFileLoading || deleteFileLoading}
        onChange={({ target: { files } }) => {
          setError();
          uploadFile({
            variables: {
              clientSiteModelId: clientSiteModelId,
              file: files[0],
              fileType,
            },
          });
        }}
      />

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={
          (deleteFileError && "При удалении файла произошла ошибка") ||
          (uploadFileError && "При загрузке файла произошла ошибка")
        }
      />
    </>
  );
};
