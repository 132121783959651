export const increaseMonth = (currentMonth: Date = new Date(), lastDate: Date): Date => {
  const limit = [
    lastDate.getMonth() + 1,
    lastDate.getFullYear(),
  ];

  const year = currentMonth.getFullYear();
  const newMonth = currentMonth.getMonth() + 7;


  const result = newMonth > 12
    ? [newMonth - 12, year + 1]
    : [newMonth, year];

  if (result[1] < limit[1] || (result[0] < limit[0] && result[1] === limit[1])) {
    return new Date(result[1], result[0] - 1);
  }

  return new Date(limit[1], limit[0] - 1);
}

export const decreaseMonth = (currentMonth: Date = new Date(), lastMonth: Date = new Date('2016-12')): Date => {
  const limit = [
    lastMonth.getMonth() + 1,
    lastMonth.getFullYear(),
  ];

  const year = currentMonth.getFullYear();
  const newMonth = currentMonth.getMonth() - 5;

  const result = newMonth < 0
    ? [newMonth + 12, year - 1]
    : [newMonth, year];

    if (result[1] < limit[1] || (result[0] < limit[0] && result[1] === limit[1])) {
      return new Date(limit[1], limit[0] - 1);
    }

  return new Date(result[1], result[0] - 1);
}