import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  AddTeacherToSchoolDocument,
  AddTeacherToSchoolMutation,
  CreateSchoolDocument,
  CreateSchoolMutation,
  CreateSchoolMutationVariables,
  SchoolUserStatusEnum,
} from "../../generated/graphql";
import { School } from "./School";
import { useRequestError } from "../../hooks";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { SchoolPaths } from "../shared/constants";
import { Header } from "../shared/Style/Style";
import { Container } from "@material-ui/core";

export const CreateSchool = () => {
  const { push } = useHistory();
  const [admins, setAdmins] = useState<string[]>([]);

  const [
    createSchool,
    {
      data: createSchoolData,
      loading: createSchoolLoading,
      error: createSchoolError,
    },
  ] = useMutation<CreateSchoolMutation>(CreateSchoolDocument);

  const [
    addTeacherToSchool,
    {
      data: addTeacherToSchoolData,
      loading: addTeacherToSchoolLoading,
      error: addTeacherToSchoolError,
    },
  ] = useMutation<AddTeacherToSchoolMutation>(AddTeacherToSchoolDocument);

  const schoolId = createSchoolData?.createSchool.id || null;
  const isAdminAdded = addTeacherToSchoolData?.addTeacherToSchool || null;
  const loading = createSchoolLoading || addTeacherToSchoolLoading;
  const error = createSchoolError || addTeacherToSchoolError;

  const errorMessage =
    (createSchoolError && "При создании школы произошла ошибка") ||
    (addTeacherToSchoolError &&
      "При добавлении администратора школы произошла ошибка");

  const { requestError, setRequestError } = useRequestError(error);

  const handleAddAdmins = async () => {
    try {
      await Promise.all(
        admins.map((teacherId) =>
          addTeacherToSchool({
            variables: {
              schoolId,
              teacherId,
              teacherStatus: SchoolUserStatusEnum.Admin,
            },
          })
        )
      );
      push(`/${SchoolPaths.school}/${schoolId}/edit`, { isAdminAdded });
    } catch (e) {
      console.error(e);
    }
  };

  const handleSchoolCreation = async (
    variables: CreateSchoolMutationVariables,
    admins: string[]
  ) => {
    setAdmins((prev) => [...prev, ...admins]);

    try {
      await createSchool({ variables });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (schoolId && admins) {
      handleAddAdmins();
    }
  }, [schoolId, admins]);

  return (
    <>
      <Container>
        <Header>Создание школы</Header>
        <School disable={loading} createSchool={handleSchoolCreation} />
      </Container>

      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
    </>
  );
};
