import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { ParticipantsSearchContainer, ParticipantsSearchInput } from "../style";
import { InfoTooltip } from "../../../shared/InfoTooltip";
import { useDebounce } from "../../../shared/Utils/OtherOperations/useDebounce";

interface ParticipantsSearchBarProps {
    setSearchKey: Dispatch<SetStateAction<string>>;
    setPage: Dispatch<SetStateAction<number>>;
}

const MIN_CHARS_LIMIT = 3;

export const ParticipantsSearchBar: FC<ParticipantsSearchBarProps> = ({
    setSearchKey,
    setPage,
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 500);

    useEffect(() => {
        if (searchTerm.length >= MIN_CHARS_LIMIT || searchTerm === '') {
            setSearchKey(debouncedSearchTerm);
            setPage(0);
        }
    }, [debouncedSearchTerm]);


    return (
        <ParticipantsSearchContainer>
            <ParticipantsSearchInput>
                <TextField
                    label="Поиск..."
                    value={searchTerm}
                    onChange={({ target: { value } }) => setSearchTerm(value)}
                    fullWidth
                />
            </ParticipantsSearchInput>
            <div>
                <InfoTooltip
                    title="Поиск производится по ФИО, электронному адресу и номеру телефона"
                    overrideStyles={{ width: 40, height: 40 }}
                />
            </div>
        </ParticipantsSearchContainer>
    );
};
