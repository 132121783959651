import styled from "styled-components"
import { Box, TextField } from "@material-ui/core";
import { primary, secondary } from "../../shared/Style/Style";

export const Container= styled(Box)`
  max-width: 500px;
  width: 479px;
  position: relative;
  padding: 34px 32px 40px 32px;
  background: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`

export const ButtonWrap = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px
`

export const CustomInput = styled(TextField)`
  margin-bottom: 22px;
  
  & .MuiInputBase-root {
    position: relative;
    border-radius: 5px 5px 0 0;
    padding: 6.5px 5px;
    background: #f6f5f5;
    
    & p {
      display: block;
    }
  }
  
  & .MuiFormHelperText-root {
    height: 20px;
    position: absolute;
    bottom: -21px;
  }

  & .MuiInput-underline.Mui-error:after {
    border-bottom-color: red;
  }

  & .MuiInput-underline {
    &:before {
      border-bottom-color: #eee;
    }

    &:after {
      border-bottom-color: ${primary};
    }

    &:hover:not(.Mui-disabled):before {
      border-bottom-color: ${secondary};
    }
  }
`