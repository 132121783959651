import React from "react";
import { IDateRange } from "../shared/DateRangePicker/DateRangePicker";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { CloseIconButton } from "../shared/Buttons/CloseIconButton";
import { ProjectReportModal } from "./ProjectReportModal";
import { EmployeeReportModal } from "./EmployeeReportModal";
import { EmployeeWorkloadReportByUser } from "../../generated/graphql";

interface IReportModal {
  title: string;
  reportType: string;
  open: boolean;
  closeHandler(): void;
  projectId?: string;
  dateRange?: IDateRange;
  user?: EmployeeWorkloadReportByUser;
}

export const ReportModal = ({
  reportType,
  title,
  open,
  closeHandler,
  projectId,
  dateRange,
  user
}: IReportModal) => {
  let content = <ProjectReportModal reportType={reportType} projectId={projectId} dateRange={dateRange}/>;

  if (reportType === 'employee') {
    content = <EmployeeReportModal user={user} dateRange={dateRange} />
  }

  return (
    <Dialog
      open={open}
      maxWidth={false}
      fullWidth
    >
      <DialogContent>
        <CloseIconButton close={closeHandler}/>

        <DialogTitle>{title}</DialogTitle>

        {content}
      </DialogContent>
    </Dialog>
  )
}