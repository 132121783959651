import React from "react";
import { Box, Grid, TextField } from "@material-ui/core";
import { Field } from "react-final-form";
import { useStyles } from "../../../shared/Style/Style";

export const BaseSocial = () => {
  const classes = useStyles();

  return (
    <Box
      mb={'60px'}
    >
      <Grid item={true} xs={12} >
        <Field name="telegram">
          {({ input, meta }) => (
            <>
              <TextField
                className={classes.root}
                error={meta.error && meta.touched}
                margin="normal"
                fullWidth
                helperText={meta.touched && meta.error}
                label="Telegram"
                variant="filled"
                type="text"
                {...input}
              />
            </>
          )}
        </Field>
      </Grid>
      <Grid item={true} xs={12}>
        <Field name="instagram">
          {({ input, meta }) => (
            <>
              <TextField
                className={classes.root}
                error={meta.error && meta.touched}
                margin="normal"
                fullWidth
                helperText={meta.touched && meta.error}
                label="Instagram"
                variant="filled"
                type="text"
                {...input}
              />
            </>
          )}
        </Field>
      </Grid>
    </Box>
  );
};
