import React, { useState } from "react";

import { useQuery } from "@apollo/client";
import { GetDateReportDocument, GetDateReportQuery } from "../../../../generated/graphql";
import { IDateRange } from "../../../shared/DateRangePicker/DateRangePicker";

import { TableHead, Table, TableBody, TableRow, CircularProgress } from "@material-ui/core";

import { CustomTableRow, CustomTableCell, CustomTableHeaderCell, ColorizedTableCell } from "../../../shared/CustomTable/CustomTable.style";
import { LoadingBox } from "../../../shared/Style/Style";

import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { ReportModal } from "../../../ReportModal";
import { CustomTable } from "../../../shared/CustomTable";
import {
  convertDateStringToLocaleHandler
} from "../../../shared/Utils/ConvertOperations/convertDateStringToLocaleHandler";

const headers = [
  'Название проекта',
  'Дата начала',
  'Разница',
  'Дата конца',
  'Разница',
];

const headersSecondRow = [
  '',
  'План',
  'Факт',
  '',
  'План',
  'Факт',
  '',
];

export const DifferenceInDaysReport = ({ dateRange }: {dateRange: IDateRange}) => {
  const [openModal, setOpenModal] = useState(false);
  const [chosenProject, setChosenProject] = useState('');

  const { data, loading, error } = useQuery<GetDateReportQuery>(GetDateReportDocument);

  let content;

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit'/>
      </LoadingBox>
    )
  }

  if (error) {
    content = <ErrorSnackbar error={error} />
  }

  if (data) {
    const projects = data.getDateReport;

    content = (
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, i) => (
              <CustomTableHeaderCell
                key={`${i} ${header}`}
                colSpan={header.includes('Дата') ? 2 : 1}
              >
                {header}
              </CustomTableHeaderCell>
            ))}
          </TableRow>
          <TableRow>
            {headersSecondRow.map((header, i) =>
              !header
              ? <CustomTableCell />
              : (
                <CustomTableHeaderCell
                  key={`${i} ${header}`}
                >
                  {header}
                </CustomTableHeaderCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {projects.length
            ? projects.map(project => (
              <CustomTableRow
                clickable={true}
                key={project.projectId}
                onClick={() => {
                  setChosenProject(project.projectId);
                  setOpenModal(true);
                }}
              >
                {[
                  project.projectName,
                  convertDateStringToLocaleHandler(project.startDateEstimate),
                  convertDateStringToLocaleHandler(project.startDate),
                ].map((field, i) => (
                  <CustomTableCell
                    key={`${field} ${i}`}
                  >
                    {field}
                  </CustomTableCell>
                ))}
                <ColorizedTableCell
                  number={project.startDifference}
                >
                  {project.startDifference}
                </ColorizedTableCell>

                {[
                  convertDateStringToLocaleHandler(project.finishDateEstimate),
                  convertDateStringToLocaleHandler(project.finishDate)
                ].map((field, i) => (
                  <CustomTableCell
                    key={`${field} ${i}`}
                  >
                    {field}
                  </CustomTableCell>
                ))}
                <ColorizedTableCell
                  number={project.finishDifference}
                >
                  {project.finishDifference}
                </ColorizedTableCell>
              </CustomTableRow>
            ))
            : (
              <TableRow>
                <CustomTableCell colSpan={7}>Проекты отсутствуют</CustomTableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    )
  }

  return (
    <>
      <CustomTable data={data?.getDateReport} tableContent={content}/>
      {openModal && chosenProject && (
        <ReportModal
          title={data?.getDateReport?.find(project => project.projectId === chosenProject)?.projectName}
          reportType='differenceInDate'
          open={openModal}
          closeHandler={() => {
            setOpenModal(false);
            setChosenProject('');
          }}
          projectId={chosenProject}
          dateRange={dateRange}
        />
      )}
    </>
  )
}