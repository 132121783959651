import { Box, Paper } from "@material-ui/core";
import { Container } from "./Container";
import { EditCredits } from "./EditCredits";
import { containerIndent, containerItemHeight } from "./constants";
import { Reward } from "../../../../generated/graphql";

interface ChapterBlockProps {
  name: string;
  rewards?: Reward[];
}
export const ChapterBlock = ({ name, rewards }: ChapterBlockProps) => {
  return (
    <Paper elevation={3}>
      <Box height={320} padding={`28px ${containerIndent}px`}>
        <Box component="h4" mb="30px" mt={0} fontSize={16}>
          {name.toUpperCase()}
        </Box>
        <Box mb={2.5}>
          <hr />
        </Box>
        <Container>
          {rewards.map(({ id, name, value }) => (
            <Box
              key={id}
              minHeight={containerItemHeight}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box maxWidth={340}>
                <Box lineHeight="16px" component="p" m={0}>
                  {name}
                </Box>
              </Box>
              <EditCredits id={id} value={value} />
            </Box>
          ))}
        </Container>
      </Box>
    </Paper>
  );
};
