import axios from 'axios'
import Cookies from 'js-cookie'
import { COOKIE_NAME_TOKEN } from '../../constants/cookies'

export const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    'x-client-type': 'adminPanel',
  },
})

API.interceptors.request.use(function (config) {
  config.headers['Authorization'] = Cookies.get(COOKIE_NAME_TOKEN) || ''
  return config
})
