import { FC } from "react";
import { useReactiveVar } from "@apollo/client";
import { robotsState } from "../../state";
import { ModalInput } from "../../../../shared/Style/Style";
import { RobotGeneralFields } from "../../constants";

export const RobotGeneralInfo: FC = () => { 
    const robot = useReactiveVar(robotsState.robotGeneralInfo);
    const inputErrors = useReactiveVar(robotsState.inputErrors);

    const robotInputData = [
        {
            field: RobotGeneralFields.Order, label: "№",
            handleValueChange: (value: number) => robotsState.setRobotOrder(Number(value)),
            helperText: "Введите порядковый номер робота"
        },
        {
            field: RobotGeneralFields.Name, label: "Название робота",
            handleValueChange: (value: string) => robotsState.setRobotName(value),
            helperText: "Максимальное кол-во символов 20"
        },
        {
            field: RobotGeneralFields.Description, label: "Описание робота",
            handleValueChange: (value: string) => robotsState.setRobotDescription(value),
            helperText: "Максимальное кол-во символов 200"
        },
        {
            field: RobotGeneralFields.Background, label: "Фон робота",
            handleValueChange: (value: string) => robotsState.setRobotBackground(value),
            helperText: "Введите цвет фона в формате HEX"
        }
    ];

    const renderModalInput = (field: string, label: string, handleValueChange: (value) => void, helperText: string ) => {
        const inputType = field === RobotGeneralFields.Order ? "number" : "text";
        return (
            <ModalInput
                key={field}
                type={inputType}
                variant="filled"
                margin="normal"
                multiline = {field === RobotGeneralFields.Description}
                minRows={4}
                required
                label={label}
                value={robot[field] ?? ""}
                onChange={({ target: { value } }) => handleValueChange(value)}
                error={!robot[field] && inputErrors[field].error}
                helperText={helperText}
                onFocus={() => robotsState.setInputErrors(field as RobotGeneralFields, false)}
            />
        );
    };

    return (
        <>
            {robotInputData.map(({ field, label, handleValueChange, helperText }) => (
                renderModalInput(field, label, handleValueChange, helperText)
            ))}
        </>
    );
};
