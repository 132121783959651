import React, { useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { useTransparentDictionaryStyles } from "../../../shared/Style/Style";
import {
  UserJob,
  UserPosition,
  VacanciesPlanStatus,
} from "../../../../generated/graphql";

interface VacancyDictionarySelectProps {
  stateField: string;
  values: UserPosition[] | UserJob[] | VacanciesPlanStatus[];
  error?: boolean;
  setVacancyData(e: any): void;
  vacancyData: any;
  disabled?: boolean;
}

export const VacancyDictionarySelect = ({
  stateField,
  values,
  setVacancyData,
  vacancyData,
  disabled,
}: VacancyDictionarySelectProps) => {
  const classes = useTransparentDictionaryStyles();
  const [id, setId] = useState<number>();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setId(event.target.value as number);
    setVacancyData({
      type: "setVacancyData",
      payload: { ...vacancyData, [`${stateField}`]: event.target.value },
    });
  };

  return (
    <FormControl variant="filled" fullWidth>
      <Select
        className={classes.root}
        value={vacancyData[`${stateField}`] || id}
        onChange={handleChange}
        fullWidth
        disabled={disabled}
        style={{
          backgroundColor: "inherit",
        }}
      >
        {values.map((value: UserJob | UserPosition | VacanciesPlanStatus) => {
          return (
            <MenuItem key={value.id} value={value.id}>
              {(value as UserPosition | VacanciesPlanStatus).displayName ||
                (value as UserJob).name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
