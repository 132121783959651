import React from "react";
import { Container, Title, Text, ListItem, SubtitleCenter, TableHeaderCell, TableBodyCell } from "./PrivatePolicy.style";
import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";

function createData(type: string, name: string, target: string, period: string, recipient: string | JSX.Element) {
  return { type, name, target, period, recipient };
}

const rows = [
  createData('Технічні cookies', 'user_id', 'Для ідентифікації користувача', '10 рокiв', <a href="https://accounts.google.com">accounts.google.com</a>),
  createData('Технічні cookies', 'token', 'Код безпеки при авторизації, кий регулярно змінюється', 'сеанс', <a href="https://teacher.arbook.info">teacher.arbook.info</a>),
  createData('Технічні cookies', 'session', 'Код сесії для платіжної системи Fondy. Передає інформацію про те чи користувач успішно завершив здійснення оплати.', 'сеанс', <a href="https://docs.fondy.eu/">pay.fondy.eu</a>),
  createData('Технічні cookies', 'lastGclid', 'ID Google аккаунта. Використовуємо для швидкої реєстрації та авторизації через гугл-аккаунт.', '7 днiв', <a href="https://www.smartlook.com/">smartlook.com/</a>),
  createData('Технічні cookies', 'lang_actual', 'Використовує платіжна система Fondy для визначення актуальної мови інтерфейсу користувача, щоб такою ж мовою відобразити сторінку оплати.', '30 днів', <a href="https://docs.fondy.eu/">pay.fondy.eu</a>),
  createData('Технічні cookies', 'lang', 'Використовує платіжна система Fondy для визначення мови інтерфейсу користувача.', '30 днів', <a href="https://docs.fondy.eu/">pay.fondy.eu</a>),
  createData('Технічні cookies', 'device-source', 'Фіксування з якого девайсу зайшов користувач. Використовуємо для заходів безпеки.', '30 днів', <a href="https://arbook.info">arbook.info</a>),
  createData('Технічні cookies', 'planPromoFromUrl', 'Зберігаємо обраний план на промо сайті, щоб при переході в веб-систему відобразити сторінку обраного раніше тарифу.',
    '30 днів', <>AR_Book, Market, AR Teacher<br /><a href="https://arbook.info">arbook.info</a></>),
  createData('Технічні cookies', 'isShowTrialPeriodModal', 'Вираховуємо чи необхідно показувати користувачу підказку про завершення платного періоду.',
    '10 років', <>AR_Book, Market, AR Teacher<br /><a href="https://arbook.info">arbook.info</a></>),
  createData('Статистичні cookies', 'data.ai', 'Аналітика основних показників додатків у Google Play та App Store: завантаження, оцінка, пошуки тощо.', '5 років', 'Застосунок AR Book'),
  createData('Маркетингові cookies', 'Netpeak Spider', 'Збір SEO аналітики сайту: відвідуваність, трафік, пошукові запити тощо.',
  '5 років або за запитом користувача видаляємо.', <a href="https://arbook.info">arbook.info</a>),
  createData('Маркетингові cookies', 'Serpstat', 'Збір SEO аналітики сайту: відвідуваність, трафік, пошукові запити тощо.',
  '10 років або за запитом користувача видаляємо.', <>AR_Book, Market<br /><a href="https://arbook.info">arbook.info</a></>),
  createData('Статистичні cookies', 'Smartlook', 'Запис екрану Застосунока та поведінки користувача на сайті, щоб бачити, де може виникнути помилка. Особисті дані, що вводить користувач, імена, пошти, номера та подібне не записується. Також відтворюється теплова карта поведінки. Серед даних, які записуються, крім запису екрану, пристрій, версія пристрою, версія операційної системи, версія браузера та місто запису.',
  'Від моменту створення акаунту до моменту його закриття.', 'Застосунок AR Book, AR_Book, Market, AR Teacher'),
  createData('Статистичні cookies', 'Wix', 'Дані, які надсилаються з форм Wix. Для збору відгуків та коментарів про систему. Це адреси електронної скриньки.',
    'Зберігаються у внутрішньому сховищі Wix, допоки  не будуть видалені платформою.', <a href="https://arbook.info">arbook.info</a>),
  createData('Маркетингові cookies', 'Google Analytics', 'Аналітичні дані про систему та додаток. Зберігаються дані трафіку, взаємодії, геодані, дані про модель пристрою, версію операційної системи тощо.',
    '26 місяців', <>Застосунок AR Book, AR_Book, Market, AR Teacher<br /><a href="https://arbook.info">arbook.info</a></>),
  createData('Статистичні cookies', 'Google Tag Manager', 'Доповнення до Google Analytics, бо дозволяє налаштувати аналітику.',
    '5  рокiв.', <>AR_Book, Market, AR Teacher<br /><a href="https://arbook.info">arbook.info</a></>),
  createData('Маркетингові cookies', 'SendPulse', 'Електронні адреси, номери, імена та інші дані використовуються для налаштування розсилок.',
    '2 роки. Або за запитом користувача видаляємо.', <>AR_Book, Market, AR Teacher<br /><a href="https://arbook.info">arbook.info</a></>),
  createData('Маркетингові cookies', 'Facebook та Facebook Pixel', 'Дані про відвідування сторінок, трафік, взаємодію, цільові дії на сайті (налаштовані конверсії), дані про стать та вік аудиторії тощо.',
    '30 днiв', <>AR_Book, Market, AR Teacher<br /><a href="https://arbook.info">arbook.info</a></>),
  createData('Маркетингові cookies', 'Firebase', 'Дані аналітики додатку. Інформацію з Google Analytics додатка AR Book.',
    '2 роки', 'Застосунок AR Book'),
];

export const CookiesPolicyUkr = () => (
  <Container>

    <Title>Політика використання cookies-файлів AR_BOOK</Title>

    <Box mt={6} style={{textIndent:"40px"}}>
      <Text style={{ margin: "10px 0 0 0" }}>
        Ця Політика використання cookies-файлів (далі — <strong>«Політика»</strong>) є юридично обов’язковим документом і
        є частиною нашої угоди користувача, представляє собою правила збирання, використання cookies-файлів
        інтерактивною системою ArBook яка включає в себе такі продукти як ArBook, VR_Lab, AR_Teacher та AR_Book Market,
        всі згадані продукти є частиною інтерактивної системи та використовують цю Політику.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        <strong>Товариство з обмеженою відповідальністю «АР БУК ЮА»</strong>, ЄДРПОУ: 44973818, в особі директора Головахінoї Ольги Миколаївни,
        що діє на підставі Статуту розробило цю Політику про нижчевикладене.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        У цій Політиці ми розповімо що таке cookies-файли, які cookies використовує інтерактивна система ArBook, яка включає в себе такі
        продукти як ArBook, VR_Lab, AR_Teacher та AR_Book Market, ми обробляємо файли, зібрані за допомогою cookies і як ви можете відкликати
        свою згоду і відключити cookies.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Файли cookie - це невеликі текстові файли, які завантажуються на ваш комп`ютер або інший пристрій при доступі до веб-сайту. Файли
        cookie дозволяють сайту  розпізнавати ваш пристрій і зберігати інформацію про ваші уподобання чи минулі дії.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Які cookies використовує інтерактивна система ArBook?
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Деякі cookie-файли, які використовує Платформа, технічно необхідні для роботи веб-сайту. Інші cookie зазвичай встановлюються у відповідь
        на запитувані Користувачем дії, наприклад, cookie-файли для запам’ятовування прийнятих вами рішень, таких як: ваше ім’я як користувача платформи,
        ваші інтереси, місцезнаходження, дата народження та інші дані, зазначені у вашому особистому профілі. Такі файли cookie допомагають вам поліпшити
        роботу з веб-сайтом за допомогою більш персоналізованого сервісу.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Також інтерактивна система ArBook використовує статистичні файли cookie для підрахунку відвідувань і джерел трафіку, щоб ми могли оцінити і поліпшити
        продуктивність нашого веб-сайту. Він повідомляє нам, які сторінки є найбільш популярними і найменш відвідуваними, і ми бачимо, як відвідувачі
        переміщуються сайтом. Наприклад, ми використовуємо Google Analytics, щоб розуміти, як часто одні і ті ж люди знову заходять на сайт, як його
        знаходять (за оголошеннями або посиланнями на сайтах) і які сторінки переглядають найчастіше. Така інформація об’єднується з даними тисяч інших
        користувачів для створення загальної картини використання веб-сайту. Вони ніколи не ідентифікуються індивідуально або особисто і не пов’язані з іншою
        інформацією, яку ми зберігаємо про вас.
      </Text>
    </Box>

    <Box mt={6} style={{textIndent:"40px"}}>
      <SubtitleCenter>Нижче наведено детальний перелік сookies, які використовуються інтерактивною системою ArBook.та включені в такі продукти
        як: ArBook, AR_Teacher та AR_Book Market.
      </SubtitleCenter>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableHeaderCell>Тип</TableHeaderCell>
              <TableHeaderCell>Назва</TableHeaderCell>
              <TableHeaderCell>Ціль використання</TableHeaderCell>
              <TableHeaderCell>Термін зберігання</TableHeaderCell>
              <TableHeaderCell>Хто використовує</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableBodyCell scope="row">
                  {row.type}
                </TableBodyCell>
                <TableBodyCell>{row.name}</TableBodyCell>
                <TableBodyCell>{row.target}</TableBodyCell>
                <TableBodyCell>{row.period}</TableBodyCell>
                <TableBodyCell>{row.recipient}</TableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>

    <Box mt={6} style={{textIndent:"40px"}}>
      <SubtitleCenter>
        Як інтерактивна система ArBook працює з інформацією, отриманою за допомогою cookies?
      </SubtitleCenter>
      <Text style={{ margin: "10px 0 0 0" }}>
        При відвідуванні платформи, ви могли помітити спливаючий банер про згоду на використання cookies-файлів. Якщо конкретніше, ми запитуємо дозвіл
        на використання статистичних і маркетингових cookies, це не стосується технічних cookies без яких сайт не може нормально функціонувати, отже,
        ми розумно припускаємо, що зайшовши до нас на сайт, ви автоматично дали свою згоду на використання таких cookie. Якщо ви влаштовуєте браузер
        на блокування технічних файлів cookie, деякі частини сайту не будуть працювати.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Інформація, яку ми збираємо за допомогою cookie-файлів, обробляється відповідно до нашої <strong>політики конфіденційності</strong>. Наприклад, такі дані
        можуть бути доступні нашим працівникам, підрядникам, нашим філіям.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Платформа, у відповідності з політикою GOOGLE, може запитувати доступ до Google Диску Користувача для підвищення продуктивності та навчання, що використовує
        тільки обмежені області для опрацювання файлів Диска (чи їх методів або розширень) через інтерфейс користувача. Після надання Користувачем дозволу на 
        використання даних з Диска, Платформа отримує доступ до його файлів на Google Диску. Ми використовуємо отриману таким чином додаткову інформацію, щоб керувати,
        підтримувати, покращувати та надавати вам функції Платформи у повній мірі. Дана функція дозволить Платформі покращити взаємодію з Користувачем.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Для здійснення доступу до Google Диску Платформа здійснює запит на отримання дозволу від Користувача отримати доступ до файлів на Диску. Платформа отримує 
        можливість інтегрувати в роботу Google Диск Користувача на весь час дії такого дозволу. В свою чергу Користувач може обмежити Платформу у інтеграції його 
        Диску в будь який момент.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Для кращої продуктивності сайту, його інтерактивності, запам’ятовування ваших уподобань і просто комфортного використання вами наших продуктів,
        використовує сторонні сервіси. До таких сервісів відносяться Facebook, Youtube, Google, тощо.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Відповідно, Платформа, при взаємодії із зазначеними компаніями, також може передавати визначені дані цим сервісам, що знаходяться в межах ЄС та США.
        Ми не маємо прямого контролю над інформацією, яка збирається цими компаніями, з питань збору та обробки ваших даних такими компаніями, будь ласка,
        звертайтесь на такі веб-сайти:
      </Text>
      <ul>
        <ListItem><a href="https://www.facebook.com/business/help/471978536642445?id=1205376682832142">Facebook</a></ListItem>
        <ListItem><a href="https://support.google.com/youtube/answer/2801895?hl=en-GB">Youtube</a></ListItem>
        <ListItem><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=ru">Google Analytics</a></ListItem>
      </ul>
    </Box>

    <Box mt={6} mb={6} style={{textIndent:"40px"}}>
      <SubtitleCenter>
        Як можна керувати cookies файлами?
      </SubtitleCenter>
      <Text style={{ margin: "10px 0 0 0" }}>
        Ви можете легко самостійно відключити/видалити cookies файли у вашому браузері.
        Це робиться в кожному браузері по-різному, ось посилання які можуть Вам допомогти:
      </Text>
      <ul>
        <ListItem><a href="https://support.microsoft.com/ru-ru/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy">Microsoft edge</a></ListItem>
        <ListItem><a href="https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666">Google Chrome</a></ListItem>
        <ListItem><a href="https://support.apple.com/en-gb/HT201265">Safari mobile</a></ListItem>
        <ListItem><a href="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac">Safari desktop</a></ListItem>
        <ListItem><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies">Mozilla Firefox</a></ListItem>
        <ListItem><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a></ListItem>
      </ul>
    </Box>

  </Container>
);
