import styled from "styled-components";

import { Table } from "@material-ui/core";
import { primary } from "../shared/Style/Style";

export const DashboardGrid = styled(Table)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
`;

export const ReportBody = styled.div`
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 35px;
`;

export const PercentLinearProgressBody = styled.div`
  position: relative;
  width: 200px;
  height: 20px;
  background-color: rgba(255, 182, 29, 0.2);;
  text-align: center;
  margin: auto;
`;

export const PercentLinearProgressRate = styled.div<{percent?: number}>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => !!props.percent ? `${props.percent * 2}px`: 0};
  height: 20px;
  background-color: ${primary};
  transition: width 0.5s;
  z-index: -1;
`;
