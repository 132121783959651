import styled from "styled-components";
import { MainTableCell, MainTableRow } from "../../shared/Style/Style";

export const SchoolTableRow = styled(MainTableRow)`
  grid-template-columns: 90px 240px 1fr 1fr 90px 100px 0.7fr 0.3fr;
  grid-gap: 14px;
  cursor: pointer;
`;

export const WithoutSchoolTableRow = styled(MainTableRow)`
  grid-template-columns: 12fr;
  grid-gap: 10px;
  cursor: pointer;
`;

export const SchoolTableCell = styled(MainTableCell)`
  font-size: 14px;
  line-height: 1.2;

  p {
    margin: 0;
  }
`;

export const SchoolTableHeadCell = styled(SchoolTableCell)`
  font-weight: bold;
`;
