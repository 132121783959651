import React from "react";

import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

interface IRequestErrorSnackbarProps {
  open: boolean;
  closeHandler(): void;
  message: string;
}

export const ErrorMessageSnackbar = ({ open, closeHandler, message }: IRequestErrorSnackbarProps) => {
  return (
    <Snackbar
      open={open}
      onClose={closeHandler}
      autoHideDuration={6000}
      transitionDuration={300}
    >
      <Alert severity='error'>{message}</Alert>
    </Snackbar>
  )
}
