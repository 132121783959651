import { useState, ChangeEvent } from "react";
import {
  SaveOutlined as SaveOutlinedIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { Input, IconButton } from "./shared";
import { Box } from "@material-ui/core";
import { verifyInput } from "./utils";
import { useUpdateRewardMutation } from "../../../../generated/graphql";
import { SuccessSnackbar } from "../../../shared/SuccessSnackbar";

interface EditCreditsProps {
  id: string;
  value: number;
}
export const EditCredits = ({ id, value: savedValue }: EditCreditsProps) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(savedValue || "0");
  const [inputError, setInputError] = useState(false);
  const [isSuccessRequest, setIsSuccessRequest] = useState(false);

  const [updateRewardMutation, { loading, error }] = useUpdateRewardMutation({
    variables: {
      updateRewardId: id,
      input: {
        value: Number(value),
      },
    },
    onCompleted: () => {
      if (error) return;
      setIsSuccessRequest(true);
    },
  });

  const handleInputChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    const hasError = verifyInput(value);

    setValue(value);
    setInputError(hasError);
  };

  const handleSaveValue = async () => {
    try {
      await updateRewardMutation();
    } catch (e) {
      console.log(e);
    } finally {
      setEditMode(false);
    }
  };

  const handleEditValue = () => {
    setEditMode(true);
  };

  return (
    <Box
      display="flex"
      flex={1}
      alignItems="center"
      gridGap={16}
      maxWidth={102}
    >
      {editMode ? (
        <>
          <Input
            id={id}
            value={value}
            disabled={loading}
            onChange={handleInputChange}
            error={inputError}
          />
          <IconButton onClick={handleSaveValue} disabled={inputError}>
            <SaveOutlinedIcon />
          </IconButton>
        </>
      ) : (
        <>
          <Box display="flex" flex={1} justifyContent="center" pl="3px" pb="1px">
            <span>{value}</span>
          </Box>
          <IconButton onClick={handleEditValue} disabled={loading}>
            <EditIcon />
          </IconButton>
        </>
      )}
      <SuccessSnackbar
        open={isSuccessRequest}
        handleClose={() => setIsSuccessRequest(false)}
        snackbarProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        }}
      />
    </Box>
  );
};
