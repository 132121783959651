import React from "react";
import { TableRow } from "@material-ui/core";
import { ProjectTask } from "../../generated/graphql";
import { CustomTableCell, CustomTableCellNoBottom } from "../shared/CustomTable/CustomTable.style";
import { convertDateStringToLocaleHandler } from "../shared/Utils/ConvertOperations/convertDateStringToLocaleHandler";
import { convertSecondsToHoursHandler } from "../shared/Utils/ConvertOperations/convertSecondsToHoursHandler";

export const TasksListJira = ({tasks}: { tasks: ProjectTask[] }) => {
  return (
    <>
      {tasks.length
        ? tasks.map((task: ProjectTask, i: number) => (
          <TableRow key={task.id}>
            {tasks.length === i + 1 ? <CustomTableCell /> : <CustomTableCellNoBottom />}
            {[
              task.name,
              task.taskStatus,
              task.assigneeName,
              convertDateStringToLocaleHandler(task.startDateEstimate),
              convertDateStringToLocaleHandler(task.finishDateEstimate),
              convertDateStringToLocaleHandler(task.startDateFact),
              convertDateStringToLocaleHandler(task.finishDateFact),
              convertSecondsToHoursHandler(task.timeEstimate),
              convertSecondsToHoursHandler(task.timeSpent),
              // task?.labels?.map(label => label.name).join(' ')
            ].map((field, i) => <CustomTableCell fontSize={12} key={`${field} ${i}`}>{field}</CustomTableCell>)}
          </TableRow>
        ))
        : (
          <TableRow>
            <CustomTableCell colSpan={11}>Задачи отсутствуют</CustomTableCell>
          </TableRow>
        )
      }
    </>
  )
}