import styled from "styled-components";
import { MainTableRow, primary, secondary } from "../shared/Style/Style";
import { Switch, TextField } from "@material-ui/core";

export const MainTableHeaderAskedTitle = styled.td`
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-right: 20px;

  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;
export const QuestionsTableRows=styled(MainTableRow)`
  grid-template-columns: 2.5fr 6fr 2.5fr 0.4fr;;
  grid-gap: 10px;
  cursor: pointer;
`;
export const PublishSwitch = styled(Switch)`
  & .Mui-checked + .MuiSwitch-track {
    background-color: ${primary};
  }

  & .MuiSwitch-thumb {
    color: ${primary};
  }

  & .MuiSwitch-track {
    background-color: ${secondary};
  }
`;


export const FaqFields = styled(TextField)`
  margin-bottom: 20px;
  margin-left: 10px;
  & .MuiInput-underline:before {
    border-bottom-color: #eee;
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${primary};
  }

  & .MuiInputBase-multiline {
    padding: 13px 14px 13px 20px;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${primary};
  }
`;