import { useEffect, useState } from "react";
import { Input } from "./Input";
import {
  inputButtonText,
  inputPlaceholderText,
  refetchMessage,
} from "./constants";
import { Box, Button } from "@material-ui/core";
import { primary } from "../../../shared/Style/Style";
import { useCreateRobotsHistoryLinkMutation } from "../../../../generated/graphql";
import { getYoutubeVideoId } from "../../../MobileAppModal/MobileAppModelModal/utils";

interface AddVideoInputProps {
  order: number;
  handleRefetch: (message: string) => void;
}
export const AddVideoInput = ({ order, handleRefetch }: AddVideoInputProps) => {
  const [videoUrl, setVideoUrl] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const [createRobotsHistoryLinkMutation, { data, loading, error }] =
    useCreateRobotsHistoryLinkMutation({
      variables: {
        input: {
          link: videoUrl,
          order,
        },
      },
      onCompleted: () => {
        if (error) return;
        handleRefetch(refetchMessage.create);
      },
    });

  const ytId = getYoutubeVideoId(inputValue);

  useEffect(() => {
    const url = ytId ? `https://www.youtube.com/embed/${ytId}` : "";

    setVideoUrl(url);
  }, [inputValue, ytId]);

  return (
    <Box display="flex" gridGap={16} maxWidth={500}>
      <Input
        type="text"
        placeholder={inputPlaceholderText}
        value={inputValue}
        error={inputValue.length && !ytId}
        onChange={({ target }) => setInputValue(target.value)}
      />
      <Box display="flex" alignItems="center">
        <Button
          variant="contained"
          style={{ backgroundColor: primary, maxHeight: 36 }}
          color="primary"
          disabled={loading || !videoUrl || Boolean(data)}
          onClick={() => createRobotsHistoryLinkMutation()}
        >
          <span>{inputButtonText}</span>
        </Button>
      </Box>
    </Box>
  );
};
