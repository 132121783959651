import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { EventField } from "../Event.style";
import {
  DeleteEventDateDocument,
  DeleteEventDateMutation,
  EventDate,
  GetEventDatesDocument,
  GetEventDocument,
  UpdateEventDateDocument,
  UpdateEventDateMutation,
} from "../../../generated/graphql";
import { SaveIconButton } from "../../shared/Buttons/SaveIconButton";
import { EditIconButton } from "../../shared/Buttons/EditIconButton";
import { DeleteIconButton } from "../../shared/Buttons/DeleteIconButton";
import { useMutation } from "@apollo/client";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";

export const EventDates = ({ dates, eventId }) => {
  const [eventDates, setEventDates] = useState<EventDate>({
    id: "",
    date: "",
    link: "",
    userLimit: +"",
  });
  const [requestError, setRequestError] = useState(false);

  const [
    updateEventDate,
    {
      data: updateEventDateData,
      loading: updateEventDateLoading,
      error: updateEventDateError,
    },
  ] = useMutation<UpdateEventDateMutation>(UpdateEventDateDocument, {
    variables: {
      id: eventDates?.id,
      date: eventDates?.date,
      link: eventDates?.link,
      userLimit: eventDates?.userLimit,
    },
    refetchQueries: [
      {
        query: GetEventDocument,
        variables: { id: eventId },
      },
      {
        query: GetEventDatesDocument,
        variables: { eventId },
      },
    ],
  });

  const [
    deleteEventDate,
    { loading: deleteEventDateLoading, error: deleteEventDateError },
  ] = useMutation<DeleteEventDateMutation>(DeleteEventDateDocument, {
    refetchQueries: [
      {
        query: GetEventDocument,
        variables: { id: eventId },
      },
      {
        query: GetEventDatesDocument,
        variables: { eventId },
      },
    ],
  });

  useEffect(() => {
    if (updateEventDateData) {
      setEventDates({ id: "", date: "", link: "", userLimit: +"" });
    }
  }, [updateEventDateData]);
  useEffect(() => {
    if (deleteEventDateError || updateEventDateError) {
      setRequestError(true);
    }
  }, [deleteEventDateError, updateEventDateError]);

  return (
    <>
      {dates &&
        dates.map((date) => (
          <Box key={date.id}>
            <EventField
              style={{ marginBottom: "10px" }}
              fullWidth
              id="datetime-local"
              label="Дата и Время"
              type="datetime-local"
              disabled={eventDates?.id !== date.id}
              value={eventDates?.id === date.id ? eventDates?.date : date.date}
              onChange={(e) =>
                setEventDates({ ...eventDates, date: e.target.value })
              }
              InputProps={{
                endAdornment: (
                  <Box display="flex">
                    {eventDates?.id === date.id ? (
                      <SaveIconButton
                        fz={14}
                        save={() => updateEventDate({ variables: eventDates })}
                      />
                    ) : (
                      <EditIconButton
                        fz={14}
                        active={date.id === eventDates?.id}
                        edit={() =>
                          setEventDates({
                            id: date.id,
                            date: date.date,
                            link: date.link,
                            userLimit: date.userLimit,
                          })
                        }
                      />
                    )}
                    <Box>
                      <DeleteIconButton
                        disabled={eventDates?.id === date.id}
                        fz={14}
                        item="Дата и ссылка на мероприятие"
                        deleteHandler={() =>
                          deleteEventDate({ variables: { id: date.id } })
                        }
                      />
                    </Box>
                  </Box>
                ),
              }}
            />

            <EventField
              style={{ marginBottom: "10px" }}
              fullWidth
              label="Ссылка"
              disabled={eventDates?.id !== date.id}
              value={
                date?.id === eventDates?.id ? eventDates?.link : date?.link
              }
              onChange={({ target: { value } }) =>
                setEventDates({ ...eventDates, link: value })
              }
            />
            <EventField
              style={{ marginBottom: "10px" }}
              fullWidth
              label="Лимит по количеству людей"
              disabled={eventDates?.id !== date.id}
              value={
                date?.id === eventDates?.id
                  ? eventDates?.userLimit
                  : date?.userLimit
              }
              onChange={({ target: { value } }) =>
                setEventDates({ ...eventDates, userLimit: +value })
              }
            />
          </Box>
        ))}

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={
          (updateEventDateError &&
            "Ошибка при редактировании даты и ссылки мероприятия") ||
          (deleteEventDateError &&
            "Ошибка при удалении даты и ссылки мероприятия")
        }
      />
    </>
  );
};
