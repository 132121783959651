import React from 'react';
import {Dialog, DialogContent} from "@material-ui/core";
import {CloseIconButton} from "../../../shared/Buttons/CloseIconButton";

interface ModalImageProps {
  img: string | ArrayBuffer | null;
  open: boolean;
  close: () => void;
}

export const ModalImage = ({
 img,
 open,
 close
}:ModalImageProps) => {
  return(
    <Dialog open={open}>
      <CloseIconButton close={close} />
      <DialogContent style={{minWidth: '400px', padding: '40px'}}>
        <img
          style={{width: '100%'}}
          src={img as string}
          alt="Фото"
        />
      </DialogContent>
    </Dialog>
  )
}