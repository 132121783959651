import React from "react";
import * as XLSX from "xlsx";
import { CustomIconButton } from "../../shared/Buttons/CustomIconButton";
import { GetApp } from "@material-ui/icons";

interface IUserExportFile{
  firstName?:string,
  lastName?:string,
  middleName?:string,
  phone?:string,
  email?:string,
  birthday?:string,
  role?:{ displayName?:string },
}

export const ExportUserExel = ({ data }) => {
  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FileUsers");
    XLSX.writeFile(workbook, "data.xlsx");
  };

  const getUsers = data?.map((user) => {
    const {
      firstName,
      lastName,
      middleName,
      phone,
      email,
      birthday,
      role: {displayName} ,
    }:IUserExportFile = user;

    return {
      firstName,
      lastName,
      middleName,
      email,
      phone,
      birthday,
      role: displayName,
    };
  });

  return (
    <>
      <CustomIconButton
        callback={() => downloadExcel(getUsers)}
        mainColor="black"
        children={<GetApp />}
      />
    </>
  );
};
