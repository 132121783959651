import React, { useEffect, useState } from "react";
import { Box, Dialog, List, ListItem, ListItemText } from "@material-ui/core";
import { AddBlockModalHeader } from "../KnowledgeBaseModals/AddBlockModal/AddBlockModal.style";
import { CloseIconButton } from "../shared/Buttons/CloseIconButton";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import {
  CreateExerciseDocument,
  CreateExerciseMutation,
  ExerciseTypeEnum,
  GetTraningDocument,
} from "../../generated/graphql";
import { useMutation } from "@apollo/client";

interface IAddBlockModalTrainingProps {
  open: boolean;
  close(): void;
  trainingsId: string;
  errorMessage: string;
  setErrorMessage(message: string): void;
}
interface IBlockTrainingFields {
  name: string;
  type: ExerciseTypeEnum;
}

const blocks: IBlockTrainingFields[] = [
  {
    name: "Упражнение",
    type: ExerciseTypeEnum.Exercise,
  },
  {
    name: "Перерыв",
    type: ExerciseTypeEnum.Pause,
  },
];

export const AddBlockTraining = ({
  open,
  close,
  trainingsId,
  errorMessage,
  setErrorMessage,
}: IAddBlockModalTrainingProps) => {
  const [requestError, setRequestError] = useState(false);

  const closeHandler = () => {
    setRequestError(false);
    close();
  };

  const [
    createExerciseBlock,
    { data: createExerciseBlockData, loading: createExerciseBlockLoading },
  ] = useMutation<CreateExerciseMutation>(CreateExerciseDocument, {
    refetchQueries: [GetTraningDocument],
  });

  const createExerciseBlockHandle = async (type: ExerciseTypeEnum) => {
    if (!type || createExerciseBlockLoading) {
      return;
    }

    try {
      await createExerciseBlock({
        variables: {
          exerciseData: {
            traningId: trainingsId,
            type,
          },
        },
      });
      close();
    } catch (e) {
      setRequestError(true);
      setErrorMessage("Произошла ошибка при создании упражнения...");
    }
  };

  useEffect(() => {
    if (createExerciseBlockData) {
      closeHandler();
    }
  }, [createExerciseBlockData]);

  return (
    <Dialog open={open}>
      <AddBlockModalHeader>
        Выберите тип блока
        <Box position="relative" padding="22px">
          <CloseIconButton close={closeHandler} main_color="black" />
        </Box>
      </AddBlockModalHeader>

      <List
        disablePadding
        style={{
          width: "370px",
        }}
      >
        {blocks.map(({ name, type }) => (
          <ListItem
            button
            key={type}
            onClick={() => createExerciseBlockHandle(type)}
          >
            <ListItemText>{name}</ListItemText>
          </ListItem>
        ))}
      </List>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Dialog>
  );
};
