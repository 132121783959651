import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { Box, SvgIcon } from "@material-ui/core";
import styled from "styled-components";
import { StyledDropzone } from "./Dropzone";
import "cropperjs/dist/cropper.css";

const EditorContainer = styled.div<{ border_radius?: string }>`
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;

  & .cropper-crop-box, .cropper-view-box {
    border-radius: ${({ border_radius }) => border_radius ? border_radius : '50%'};
  }
`;

const StyledCrossIcon = styled(CancelRoundedIcon)`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
`;

const LocalDropzone = styled.div`
  .container .dropzone-instance {
    min-width: 400px;
    min-height: 300px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #c4c4c4;
`;

const SVG = styled(SvgIcon)`
  &.MuiSvgIcon-root {
    width: 61px;
    height: 61px;
    margin-bottom: 16px;
    justify-self: center;
    align-self: center;
  }
`;

interface AvatarEditorProps {
  cropper: Cropper | null | undefined;
  setCropper(cropper: Cropper | null | undefined): void;
  disableResize?: boolean;
  border_radius?: string;
}

export function AvatarEditor({ cropper, setCropper, disableResize, border_radius }: AvatarEditorProps) {
  const [image, setImage] = useState<string | ArrayBuffer | null>();
  const cropperRef = useRef<HTMLImageElement  | null>(null);

  const handleDrop = (dropped: File): void => {
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
    };

    reader.readAsDataURL(dropped);
  };

  return (
    <>
      {!image && (
        <LocalDropzone>
          <StyledDropzone
            onDrop={handleDrop}
            dropzoneContent={
              <IconWrapper>
                <SVG viewBox="0 0 61 61">
                  <path
                    d="M51.1667 16.1666V25.6349C51.1667 25.6349 44.865 25.6666 44.8333 25.6349V16.1666H35.3333C35.3333 16.1666 35.365 9.86492 35.3333 9.83325H44.8333V0.333252H51.1667V9.83325H60.6667V16.1666H51.1667ZM41.6667 28.8333V19.3333H32.1667V9.83325H6.83333C3.35 9.83325 0.5 12.6833 0.5 16.1666V54.1666C0.5 57.6499 3.35 60.4999 6.83333 60.4999H44.8333C48.3167 60.4999 51.1667 57.6499 51.1667 54.1666V28.8333H41.6667ZM6.83333 54.1666L16.3333 41.4999L22.6667 50.9999L32.1667 38.3333L44.8333 54.1666H6.83333Z"
                    fill="#C4C4C4"
                  />
                </SVG>
                <Label>Прикрепите фото</Label>
              </IconWrapper>
            }
          />
        </LocalDropzone>
      )}

      {image && (
        <>
          <EditorContainer
            border_radius={border_radius}
          >
            <Cropper
              dragMode={"move"}
              style={{ height: 400, width: "100%" }}
              initialAspectRatio={1}
              zoomable={!disableResize}
              preview=".img-preview"
              src={image as string}
              ref={cropperRef}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              onInitialized={(instance) => {
                setCropper(instance);
                setTimeout(() => {
                  instance.zoomTo(1);
                });
              }}
              guides={true}
            />
            <StyledCrossIcon
              color="secondary"
              onClick={() => {
                setCropper(null);
                setImage(null);
              }}
            />
          </EditorContainer>

          {!disableResize && (
            <>
              <Box>
                <Typography id="discrete-slider" gutterBottom={true}>
                  Размер
                </Typography>
                <Slider
                  defaultValue={1}
                  onChange={(e, value: number | number[]) => {
                    cropper?.zoomTo(value as number);
                  }}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={0.1}
                  marks={true}
                  min={0.1}
                  max={3}
                />
              </Box>

              <Box>
                <Typography id="discrete-slider" gutterBottom={true}>
                  Выровнять
                </Typography>
                <Slider
                  defaultValue={1}
                  onChange={(e, value: number | number[]) => {
                    cropper?.rotateTo(value as number);
                  }}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks={true}
                  min={0}
                  max={360}
                />
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
