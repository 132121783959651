import React, { memo, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {ErrorText, TextEditorWrap} from "../Onboarding.style";
import {moduleConfig} from "./textEditorConfig";

interface TextEditorProps {
  value?: string;
  onChange?: (e: any) => void;
  onFocus?: (e: any) => void;
  onBlur?: (e: any) => void;
  error: boolean;
  helperText: string
}

export const TextEditor = memo(({
  value,
  onChange,
  onFocus,
  onBlur,
  error,
  helperText
}: TextEditorProps) => {
  const errorClassName = useMemo(() => error && "error", [error])

  return (
    <TextEditorWrap className={errorClassName}>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        modules={moduleConfig}
      />
      <ErrorText>{helperText}</ErrorText>
    </TextEditorWrap>
  )
})