export const INITIAL_DATA: { [key: string]: string } = {
  id: "",
  name: "",
  entityName: "",
};

export const DeleteErrorMessages = new Map([
  ["Cannot delete GameModelState with existing GameModelArtifacts",
    "Нельзя удалить стадию с существующими артефактами"],
]);
