import React, { useEffect, useState } from "react";
import { CustomTable } from "../../../shared/CustomTable";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GetBugsAdditionalTasksReportDocument,
  GetBugsAdditionalTasksReportQuery,
  GetProjectsLightDocument,
  GetProjectsLightQuery,
} from "../../../../generated/graphql";
import {
  TableRow,
  Table,
  CircularProgress,
  TableBody,
  Box,
} from "@material-ui/core";

import { DropdownArrowButtonBold } from "../../../shared/Buttons/DropdownArrowButtonBold";
import {
  CustomTableCell,
  CustomTableHead,
  CustomTableHeaderCell,
  CustomTableRow,
} from "../../../shared/CustomTable/CustomTable.style";
import { LoadingBox } from "../../../shared/Style/Style";

const headers = [
  { name: "Проект", width: 528 },
  { name: "Количество задач", width: 222 },
  { name: "% соотношение", width: 205 },
  { name: "Стоимость", width: 185 },
];

export const RatioReport = () => {
  const [currentProjectId, setCurrentProjectId] = useState("");

  const {
    data: projectsData,
    loading: projectsLoading,
    error: projectsError,
  } = useQuery<GetProjectsLightQuery>(GetProjectsLightDocument);

  const [
    getBugsReport,
    {
      data: bugsReportData,
      loading: bugsReportLoading,
      error: bugsReportError,
    },
  ] = useLazyQuery<GetBugsAdditionalTasksReportQuery>(
    GetBugsAdditionalTasksReportDocument
  );

  useEffect(() => {
    if (!!currentProjectId) {
      getBugsReport({ variables: { projectId: currentProjectId } });
    }
  }, [currentProjectId]);

  let content: JSX.Element;
  let tasksList: JSX.Element;

  if (bugsReportLoading) {
    tasksList = (
      <TableRow>
        <CustomTableCell colSpan={4}>
          <LoadingBox>
            <CircularProgress color='inherit' />
          </LoadingBox>
        </CustomTableCell>
      </TableRow>
    )
  }

  if (bugsReportError) {
    tasksList = (
      <TableRow>
        <CustomTableCell colSpan={4}>
          При загрузке данных произошла ошибка
        </CustomTableCell>
      </TableRow>
    )
  }

  if (bugsReportData) {
    const bagsReport = bugsReportData.getBugsAdditionalTasksReport;

    tasksList = (
      <>
        {bagsReport.map((report, i) => (
          <CustomTableRow
            key={i}
          >
            {[
              (report.name === "bug" && "Баги") ||
                (report.name === "additionalTask" && "Доп. задачи") ||
                "Остальные",
              report.tasks,
              report.percents,
              report.cost,
            ].map((field, i) => (
              <CustomTableCell key={i}>{field}</CustomTableCell>
            ))}
          </CustomTableRow>
        ))}
      </>
    );
  }

  if (projectsLoading) {
    content = (
      <TableRow>
        <CustomTableCell colSpan={7}>
          <LoadingBox>
            <CircularProgress color="inherit" />
          </LoadingBox>
        </CustomTableCell>
      </TableRow>
    );
  }

  if (projectsError) {
    content = (
      <TableRow>
        <CustomTableCell colSpan={7}>
          При загрузке данных произошла ошибка
        </CustomTableCell>
      </TableRow>
    );
  }

  if (projectsData) {
    const projects = projectsData.getProjectsLight;

    content = (
      <Table>
        <CustomTableHead>
          <TableRow>
            {headers.map((header, i) => (
              <CustomTableHeaderCell
                key={header.name}
                width={header.width}
              >
                {header.name}
              </CustomTableHeaderCell>
            ))}
          </TableRow>
        </CustomTableHead>

        <TableBody>
          {projects.length ? (
            projects.map((project) => (
              <>
                <CustomTableRow
                  clickable={true}
                  onClick={() => {
                    setCurrentProjectId(project.id);
                  }}
                >
                  <CustomTableCell >
                    <Box style={{ width:'100%', fontWeight: 'bolder', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {project.name}
                      <DropdownArrowButtonBold
                        expanded={currentProjectId === project.id}
                      />
                    </Box>

                  </CustomTableCell>

                  <CustomTableCell colSpan={3} />
                </CustomTableRow>

                {currentProjectId === project.id && tasksList}
              </>
            ))
          ) : (
            <TableRow>
              <CustomTableCell colSpan={7}>Проекты отсутствуют</CustomTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  }

  return (
      <CustomTable
        data={projectsData?.getProjectsLight}
        tableContent={content}
      />
  );
};
