import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container } from "@material-ui/core";
import { ButtonPlus, Header, LoadingBox } from "../shared/Style/Style";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  DeleteEventDocument,
  DeleteEventMutation,
  Event,
  GetEventsDocument,
  GetEventsQuery,
} from "../../generated/graphql";
import { EventTableCell, EventTableRow } from "./Events.style";
import { convertDateStringToLocaleHandler } from "../shared/Utils/ConvertOperations/convertDateStringToLocaleHandler";
import { SchoolTableCell } from "../Schools/SchoolsTable/SchoolsTable.style";
import { Menu } from "../shared/Menu";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { CreateEventModal } from "./CreateEventModal";

export const Events = () => {
  const { push } = useHistory();
  const [choseEvent, setChoseEvent] = useState<Event>(null);
  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isCreateEvent, setIsCreateEvent] = useState(false);

  const getAllItemsParams = {
    limit: 500,
    skip: 0,
  };

  const { data, loading, error } = useQuery<GetEventsQuery>(GetEventsDocument, {
    variables: {
      ...getAllItemsParams,
    },
  });

  const [
    deleteEvent,
    {
      data: deleteEventData,
      loading: deleteEventLoading,
      error: deleteEventError,
    },
  ] = useMutation<DeleteEventMutation>(DeleteEventDocument, {
    variables: {
      id: choseEvent?.id,
    },
    refetchQueries: [
      {
        query: GetEventsDocument,
        variables: {
          ...getAllItemsParams,
        },
      },
    ],
  });

  const deleteEventHandle = async (e, id) => {
    try {
      e.stopPropagation();
      await deleteEvent({ variables: { id } });
    } catch (e) {
      setRequestError(true);
      setErrorMessage("При удалении произошла ошибка");
    }
  };

  const menuItem = (id: string) => [
    {
      name: "Редактировать",
      action: () => push(`/event/${id}/edit`),
    },
    {
      name: "Удалить",
      action: (e) => deleteEventHandle(e, id),
      color: "red",
    },
  ];

  useEffect(() => {
    if (deleteEventData) {
      setChoseEvent(null);
    }
  }, [deleteEventData]);

  const disableOnLoading = deleteEventLoading;

  let content;

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (data) {
    const events = data.getEvents?.events;

    content = (
      <>
        {events &&
          events.map((event) => (
            <EventTableRow
              key={event.id}
              onClick={() => push(`/event/${event.id}/edit`)}
            >
              <EventTableCell>{event?.name}</EventTableCell>
              <EventTableCell>
                {convertDateStringToLocaleHandler(event?.createdDate)}
              </EventTableCell>
              <EventTableCell>
                {event?.createdBy?.firstName} {event?.createdBy?.lastName}{" "}
                {event?.createdBy?.middleName}
              </EventTableCell>
              <SchoolTableCell onMouseOver={() => setChoseEvent(event)}>
                <Menu
                  vertical={36}
                  horizontal={12}
                  width={170}
                  disabled={disableOnLoading}
                  items={menuItem(event.id)}
                />
              </SchoolTableCell>
            </EventTableRow>
          ))}

        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={errorMessage}
        />
      </>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header>Мероприятия</Header>
      <Box display="flex" justifyContent="flex-end">
        <ButtonPlus
          width={179}
          variant="contained"
          disabled={false}
          onClick={() => setIsCreateEvent(!isCreateEvent)}
        >
          Добавить
        </ButtonPlus>
      </Box>
      {content}
      <CreateEventModal
        open={isCreateEvent}
        close={() => setIsCreateEvent(false)}
        getAllItemsParams={getAllItemsParams}
      />
    </Container>
  );
};