import React, { Fragment, memo } from "react";
import { Grid, TextField } from "@material-ui/core";
import { Field } from "react-final-form";
import { isRequired } from "../../../../core/validators/validators";
import { DictionarySelect } from "../DictionarySelect";
import {
  City,
  UserAgreement,
  UserDepartment,
  UserJob,
  UserOptionFields,
  UserPosition,
  UserRole,
  UserStatus,
} from "../../../../generated/graphql";
import { useStyles } from "../../../shared/Style/Style";
import { UploadButtons } from "../../../shared/UploadButton/UploadButton";
import { Autocomplete } from "@material-ui/lab";

export const BaseStatus = memo((props: { isStaff: boolean, options: UserOptionFields, setUserRoleId(id: number): void}) => {
  const { isStaff, options, setUserRoleId } = props;
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={5} item>
          <Field name="status" validate={isRequired}>
            {({ input, meta }) => (
              <DictionarySelect
                label="Статус"
                input={input}
                meta={meta}
                values={options.statuses as UserStatus[]}
              />
            )}
          </Field>
        </Grid>

        <Grid xs={7} item>
          <Field name="role" validate={isRequired}>
            {({ input, meta }) => (
              <DictionarySelect
                label="Роль"
                input={input}
                meta={meta}
                values={options.roles as UserRole[]}
                setUserRoleId={setUserRoleId}
              />
            )}
          </Field>
        </Grid>
      </Grid>

      {isStaff && (
        <>
          <Grid container spacing={2}>
            <Grid item={true} xs={5}>
              <Field name="position" validate={isRequired}>
                {({ input, meta }) => (
                  <DictionarySelect
                    label="Позиция"
                    input={input}
                    meta={meta}
                    values={options.positions as UserPosition[]}
                  />
                )}
              </Field>
            </Grid>

            <Grid item={true} xs={7}>
              <Field name="job" validate={isRequired}>
                {({ input, meta }) => (
                  <DictionarySelect
                    label="Профессия"
                    input={input}
                    meta={meta}
                    values={options.jobs as UserJob[]}
                  />
                )}
              </Field>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item={true} xs={12}>
              <Field name="department" validate={isRequired}>
                {({ input, meta }) => (
                  <DictionarySelect
                    label="Отдел"
                    input={input}
                    meta={meta}
                    values={options.departments as UserDepartment[]}
                  />
                )}
              </Field>
            </Grid>

            <Grid item={true} xs={12}>
              <Field name="agreement" validate={isRequired}>
                {({ input, meta }) => (
                  <DictionarySelect
                    label="Оформление"
                    input={input}
                    meta={meta}
                    values={options.agreements as UserAgreement[]}
                  />
                )}
              </Field>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item={true} xs={12}>
              <Field name="jiraId">
                {({ input, meta }) => (
                  <TextField
                    className={classes.root}
                    error={meta.error}
                    margin="normal"
                    fullWidth
                    helperText={meta.error}
                    label="Идентификатор в Jira"
                    variant="filled"
                    type="text"
                    {...input}
                  />
                )}
              </Field>
            </Grid>

            <Grid item={true} xs={12}>
              <Field name="oneCId">
                {({ input, meta }) => (
                  <TextField
                    disabled
                    className={classes.root}
                    error={meta.error}
                    margin="normal"
                    fullWidth
                    helperText={meta.error}
                    label="Идентификатор в 1C"
                    variant="filled"
                    type="text"
                    {...input}
                  />
                )}
              </Field>
            </Grid>

            <Grid item={true} xs={12}>
              <Field name="trackerId">
                {({ input, meta }) => (
                  <TextField
                    disabled
                    className={classes.root}
                    error={meta.error}
                    margin="normal"
                    fullWidth
                    helperText={meta.error}
                    label="Идентификатор в трекере"
                    variant="filled"
                    type="text"
                    {...input}
                  />
                )}
              </Field>
            </Grid>

            <Grid item={true} xs={12}>
              <Field name="city">
                {({ input, meta }) => (
                  <Autocomplete
                    disablePortal
                    disableClearable
                    options={options.cities.map(city => city.id)}
                    getOptionLabel={option => options.cities.find(city => city.id === option)?.name || ''}
                    value={input.value}
                    onBlur={input.onBlur}
                    onChange={(_, value) => {
                      input.onChange(
                        value
                      );
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          error={meta?.error && meta?.touched}
                          helperText={meta?.touched && meta?.error}
                          margin="normal"
                          fullWidth
                          label="Город"
                          variant="filled"
                        />
                      );
                    }}
                  />
                )}
              </Field>
            </Grid>

            <Grid item={true} xs={12}>
              <Field name="addressReal">
                {({ input, meta }) => (
                  <TextField
                    className={classes.root}
                    error={meta.error && meta.touched}
                    margin="normal"
                    fullWidth
                    helperText={meta.touched && meta.error}
                    label="Адрес прописки"
                    variant="filled"
                    type="text"
                    {...input}
                  />
                )}
              </Field>
            </Grid>

            <Grid item={true} xs={12}>
              <Field name="address">
                {({ input, meta }) => (
                  <TextField
                    className={classes.root}
                    error={meta.error && meta.touched}
                    margin="normal"
                    fullWidth
                    helperText={meta.touched && meta.error}
                    label="Адрес проживания"
                    variant="filled"
                    type="text"
                    {...input}
                  />
                )}
              </Field>
            </Grid>

            <Grid item={true} xs={12}>
              <Field name="contractType">
                {({ input, meta }) => (
                  <TextField
                    className={classes.root}
                    error={meta.error && meta.touched}
                    margin="normal"
                    fullWidth
                    helperText={meta.touched && meta.error}
                    label="Оформнение"
                    variant="filled"
                    type="text"
                    {...input}
                  />
                )}
              </Field>
            </Grid>

            <Grid item={true} xs={12}>
              <Field name="cvs">
                {({ input, meta }) => (
                  <UploadButtons label="Загрузить CV" input={input}  />
                )}
              </Field>
            </Grid>

            <Grid item={true} xs={12}>
              <Field name="contractType">
                {({ input, meta }) => (
                  <Fragment>
                    <TextField
                      className={classes.root}
                      error={meta.error && meta.touched}
                      margin="normal"
                      fullWidth
                      helperText={meta.touched && meta.error}
                      label="Оформление"
                      variant="filled"
                      type="text"
                      {...input}
                    />
                  </Fragment>
                )}
              </Field>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
});
