import React, { useState } from "react";

import { IKnowledgeBaseBlockInnerProps } from "../../KnowledgeBaseBlockItem";

import { MathComponent } from 'mathjax-react'
import { Box, Collapse, Paper } from "@material-ui/core";

import { BlockInput, FormulaButton } from "../../KnowledgeBaseBlockItem.style";

import { getKnowledgeBaseInputLabel } from "../../../shared/Utils/OtherOperations/getKnowledgeBaseInputLabel";


const charButtons = ['\\sqrt{a}', '\\frac{a}{b}', 'x_{a}', 'x^{b}', 'x_{a}^{b}', '\\int_{a}^{b}', '\\iint_{a}^{b}', '\\iiint_{a}^{b}', '\\sum_{a}^{b}', '\\sin{a}', '\\cos{a}', '\\tan{a}', '\\cot{a}', '\\lim_{x\\to 0}' ];
const letterButtons = ['\\alpha', '\\beta', '\\gamma', '\\delta', '\\epsilon', '\\zeta', '\\eta', '\\theta', '\\iota', '\\kappa', '\\lambda', '\\mu', '\\nu', '\\pi', '\\varpi', '\\sigma', '\\tau', '\\phi', '\\varphi', '\\chi', '\\psi', '\\omega'];
const operationButtons = ['\\approx', '\\sim','\\vert{a}\\vert', '\\langle{a}\\rangle', '\\infty', '\\partial', '\\nabla', '\\dots', '\\equiv', '\\to', '\\gets', '\\cup', '\\cap', '\\setminus', '\\subset', '\\supset', '\\subseteq', '\\supseteq', '\\in', '\\notin', '\\emptyset', '\\lt', '\\gt', '\\le', '\\ge', '\\neq',];

const buttons = [
  { type: 'charButtons', icon: '\\sqrt x', buttons: charButtons },
  { type: 'letterButtons', icon: '\\alpha', buttons: letterButtons },
  { type: 'operationButtons', icon: '\\neq', buttons: operationButtons },
];

const initialShowIconState = {
  chosenType: '',
  isOpen: false
}


export const FormulaBlock = ({ block, disabled, blockTypeData, setBlockTypeData }: IKnowledgeBaseBlockInnerProps) => {
  const [showIcons, setShowIcons] = useState(initialShowIconState);

  const setShowIconsHandler = (type: string) => {
    const { chosenType } = showIcons;
    if (type === chosenType) {
      setShowIcons(initialShowIconState);
      return;
    }

    setShowIcons({
      chosenType: type,
      isOpen: true
    });
  }

  const addFormulaHandler = (formula: string) => {
    setBlockTypeData({
      ...blockTypeData,
      content: (blockTypeData.content || "") + " " + formula
    })
  }

  return (
    <div>
      <BlockInput
        fullWidth
        multiline
        rows={5}
        disabled={disabled}
        type={block.type}
        label={getKnowledgeBaseInputLabel(block.type)}
        value={blockTypeData.content}
        onChange={({ target: { value } }) => setBlockTypeData({ ...blockTypeData, content: value })}
        InputProps={{
          startAdornment: (
            <Box
              display='flex'
              position='absolute'
              top={-36}
              right={0}
            >
              {buttons.map(({ type, icon, buttons }) => (
                <>
                  <FormulaButton
                    disabled={disabled}
                    children={<MathComponent tex={icon} />}
                    onClick={() => setShowIconsHandler(type)}
                  />
                  <Collapse
                    in={showIcons.isOpen && showIcons.chosenType === type}
                  >
                    <Box
                      position='absolute'
                      top={45}
                      right={0}
                      maxHeight={100}
                      width={240}
                      overflow='auto visible'
                    >
                      <Paper
                        elevation={1}
                      >
                        {buttons.map(button => (
                          <FormulaButton
                            key={button}
                            disabled={disabled}
                            children={<MathComponent tex={button} />}
                            onClick={() => {
                              addFormulaHandler(button);
                              setShowIconsHandler(type);
                            }}
                          />
                        ))}
                      </Paper>
                    </Box>
                  </Collapse>
                </>
              ))}
            </Box>
          )
        }}
      />

      <MathComponent tex={blockTypeData.content || ' '} />
    </div>
  )
}