import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { lightBlack } from "../shared/Style/Style";

export const MobileAppInput = styled(TextField)`
  &:not(:first-of-type) {
    margin-top: ${props => props.label ? '10px' : 0 };
  }
`;

export const MobileAppSubtitle = styled.h3`
  color: ${lightBlack};
  margin-top: 0;
  font-size: 16px;
`;

export const MobileAppInputTitle = styled(MobileAppSubtitle)`
  margin: 30px 0 20px;
  font-size: 14px;
`;

export const FileName = styled.a`
  color: ${lightBlack};
  text-decoration: none;
`;