import React, { FC } from "react";
import {
  ButtonCustomWithDarkTextTopFilter,
  DatePickerButton,
} from "../../../shared/Style/Style";
import { Box } from "@material-ui/core";
import { DateRange } from "@material-ui/icons";
import { DateRangePicker } from "../../../shared/DateRangePicker";
import { FiltersSelect } from "../../../shared/Selects/FiltersSelect";
import { UsersType } from "../../../ReportsARBook/types";
import { TeacherPupilRoleEnum } from "../../../../generated/graphql";

interface AnalyticsTopFiltersProps {
  handleDateRangePickerOpen(newValue): void;
  handleDateRangePickerClose(open: boolean): void;
  handleSetDatePickerButton(): void;
  handleAllPeriodDateRangeData(): void;
  isDatePickerActive: boolean;
  titleDatePickerButton: string;
  titleDateButton: string;
  isDateRangePickerOpen: boolean;
  isAllPeriodDateRangeData: boolean;
  setTopFilterVariables: (newValue) => void;
  isUsersFiltering?: boolean;
  usersFilterFieldsData?: UsersType[];
  handleSetFilterUsers?: (newValue) => void;
}

export const AnalyticsTopFilters: FC<AnalyticsTopFiltersProps> = ({
  handleDateRangePickerOpen,
  handleAllPeriodDateRangeData,
  isDatePickerActive,
  titleDatePickerButton,
  titleDateButton,
  isDateRangePickerOpen,
  isAllPeriodDateRangeData,
  handleDateRangePickerClose,
  handleSetDatePickerButton,
  isUsersFiltering = false,
  usersFilterFieldsData,
  setTopFilterVariables,
  handleSetFilterUsers,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      mt={5}
      mb={5}
      justifyContent="space-between"
      maxWidth="1140px"
    >
      <Box display="flex" alignItems="center">
        <DatePickerButton
          onClick={handleSetDatePickerButton}
          isActive={isDatePickerActive}
        >
          <DateRange />
          {titleDatePickerButton}
        </DatePickerButton>

        <ButtonCustomWithDarkTextTopFilter
          onClick={handleAllPeriodDateRangeData}
          active={isAllPeriodDateRangeData && !isDatePickerActive}
        >
          {titleDateButton}
        </ButtonCustomWithDarkTextTopFilter>
      </Box>

      {isUsersFiltering && (
        <Box>
          <FiltersSelect
            fieldsData={usersFilterFieldsData}
            handleSetFilter={handleSetFilterUsers}
            defaultValue={TeacherPupilRoleEnum.All}
          />
        </Box>
      )}
      <DateRangePicker
        setDateRange={handleDateRangePickerOpen}
        open={isDateRangePickerOpen}
        onClose={() => handleDateRangePickerClose(false)}
      />
    </Box>
  );
};
