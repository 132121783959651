import { useReactiveVar } from "@apollo/client";
import { mobileAppModel } from "../../state";
import { Autocomplete } from "@material-ui/lab";
import { ContentTypeEnum } from "../../../../../generated/graphql";
import { ModalInput } from "../../../../shared/Style/Style";
import { experiments } from "../../constants";

export const SelectExperimentType = () => {
  const model = useReactiveVar(mobileAppModel.model);
  const isLoading = useReactiveVar(mobileAppModel.updateLoading);
  const inputErrors = useReactiveVar(mobileAppModel.inputErrors);

  if (!model) return null;
  const { id, contentType, isUploadedToMarket } = model;

  const setContentTypeHandler = (
    type: ContentTypeEnum | null
  ) => {
    if (type) {
      mobileAppModel.updateExperimentType({
        setApplicationMainModelExperimentTypeId: id,
        contentType: type,
      });
    } else {
      mobileAppModel.setContentType(type);
    }

    mobileAppModel.cleanUpTags();
  };

  return (
    <>
      <Autocomplete
        disabled={isLoading || isUploadedToMarket}
        options={experiments.map((item) => item.type)}
        onChange={(_, value) =>
          setContentTypeHandler(value as ContentTypeEnum)
        }
        onFocus={() => mobileAppModel.setInputErrors("contentType", false)}
        getOptionLabel={(option) =>
          experiments.find((item) => item.type === option)?.text
        }
        value={
          experiments.find((item) => item.type === contentType?.name)?.type || null
        }
        renderInput={(params) => (
          <ModalInput
            {...params}
            error={inputErrors.contentType.error}
            label="Tип контента"
            required
            margin="normal"
          />
        )}
      />
    </>
  );
};
