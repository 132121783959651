import styled from "styled-components";
import { lightBlack, MainSalaryGrid, MainTableRow, primary } from "../shared/Style/Style";
import { Radio } from "@material-ui/core";

export const MobileAppNotificationTableRow = styled(MainTableRow)`
  grid-template-columns: 1.5fr 1.5fr 5fr 1.5fr 1.5fr 1.5fr;
  grid-gap: 10px;
  cursor: pointer;
`;

export const WithoutNotification = styled(MainTableRow)`
  grid-template-columns: 5fr;
  grid-gap: 10px;
  cursor: pointer;
`

export const MobileNotificationStatisticRow = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 7fr 2fr;
  grid-gap: 10px;
  cursor: pointer;
  padding: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${lightBlack};
`

export const MobileAnswersStatisticRow = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 9fr;
  grid-gap: 10px;
  cursor: pointer;
  margin-bottom: 20px;
`

export const CustomScrollBox = styled(MainSalaryGrid)`
  max-height: 300px;
  overflow-y: auto;
`

export const RatingScrollBox = styled(MainSalaryGrid)`
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
`
export const CheckRudioButton = styled(Radio)`
  & .MuiRadio-colorSecondary {
    color: ${primary};
  }

  &.MuiIconButton-colorSecondary {
    color: ${primary};
  }
`;