import styled from "styled-components";
import { MainTableRow } from "../shared/Style/Style";

export const TestHistoryTableRow = styled(MainTableRow)`
  grid-template-columns: 1fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr;
  grid-gap: 10px;
`;

export const EmptyTestHistoryTableRow = styled(TestHistoryTableRow)`
  grid-template-columns: 1fr;
`;
