import React, { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";

import { useQuery, useMutation } from "@apollo/client";
import {
  GetKnowledgeBaseBlocksByThemeIdQuery,
  GetKnowledgeBaseBlocksByThemeIdDocument,
  PublishKnowledgeBaseThemeDocument,
  PublishKnowledgeBaseThemeMutation
} from "../../generated/graphql";

import { KnowledgeBaseBlockItem } from "../KnowledgeBaseBlock";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { AddBlockModal } from "../KnowledgeBaseModals/AddBlockModal";

import { Container, CircularProgress, Box } from "@material-ui/core";

import {
  ButtonCustomWithDarkText,
  ButtonPlus,
  Header,
  LoadingBox,
  PlaceholderBlock
} from "../shared/Style/Style";


export const KnowledgeBaseTheme = () => {
  const { themeId, themeName } = useParams<{ themeId: string, themeName: string }>();
  const history = useHistory();

  const [editedBlockId, setEditedBlockId] = useState('');
  const [addBlockModalOpen, setAddBlockModalOpen] = useState(false);

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [publishTheme, {
    data: publishThemeData,
    loading: publishThemeLoading
  }] = useMutation<PublishKnowledgeBaseThemeMutation>(PublishKnowledgeBaseThemeDocument, {
    variables: { id: themeId }
  });

  const publishThemeHandler = async () => {
    try {
      await publishTheme();
    } catch (e) {
      setRequestError(true);
      setErrorMessage('При публикации темы произошла ошибка');
    }
  };

  useEffect(() => {
    if (publishThemeData) {
      setRequestError(false);
      setErrorMessage('')
    }
  }, [publishThemeData])

  const { data, loading, error } = useQuery<GetKnowledgeBaseBlocksByThemeIdQuery>(
    GetKnowledgeBaseBlocksByThemeIdDocument, {
      variables: {
        themeId
      },
    }
  );


  let content;

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit' />
      </LoadingBox>
    )
  }

  if (error) {
    content = (
      <PlaceholderBlock>При загрузке данных темы произошла ошибка</PlaceholderBlock>
    )
  }

  if (data) {
    const blocks = data.getKnowledgeBaseBlocksByThemeId;

    content = (
      blocks.length
        ? (blocks.map(block => (
          <KnowledgeBaseBlockItem
            key={block.id}
            disabled={publishThemeLoading}
            block={block}
            editedBlockId={editedBlockId}
            setEditedBlockId={setEditedBlockId}
            setRequestError={setRequestError}
            setErrorMessage={setErrorMessage}
          />
        )))
        : (
          <PlaceholderBlock>По теме пока нет ни одного блока</PlaceholderBlock>
        )
    )
  }


  return (
    <Container maxWidth="lg">
      <Box
        mb='30px'
      >
        <Header>{themeName}</Header>
      </Box>

      <Box
        textAlign='right'
        mb='30px'
      >
        <ButtonCustomWithDarkText
          active={true}
          width='190'
          disabled={publishThemeLoading}
          onClick={() => history.push(`/knowledgeBase/tests/${themeName}/${themeId}`)}
        >
          Тесты
        </ButtonCustomWithDarkText>
      </Box>

      {content}

      <Box
        mt='30px'
        display='flex'
        justifyContent='space-between'
      >
        <ButtonPlus
          width={179}
          padding="6px 16px"
          variant='contained'
          disabled={publishThemeLoading}
          onClick={() => setAddBlockModalOpen(true)}
        >
          Добавить блок
        </ButtonPlus>

        <ButtonCustomWithDarkText
          active={true}
          width='179'
          disabled={publishThemeLoading}
          onClick={publishThemeHandler}
        >
          Опубликовать тему
        </ButtonCustomWithDarkText>
      </Box>

      <AddBlockModal
        open={addBlockModalOpen}
        close={() => setAddBlockModalOpen(false)}
        themeId={themeId}
      />

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Container>
  )
}