import { FC } from "react";
import { useReactiveVar } from "@apollo/client";
import { mobileAppModel } from "../../state";
import { ButtonCustom, CancelButton } from "../../../../shared/Style/Style";
import { ButtonsWrapper } from "../../../MobileAppModal.style";

interface ModalControlButtonsProps {
    cancel(): void;
    saveModel(): void;
    addToMarket(): void;
};

export const ModalControlButtons: FC<ModalControlButtonsProps> = ({
    cancel,
    saveModel,
    addToMarket
}) => {
    const isLoading = useReactiveVar(mobileAppModel.updateLoading);
    const model = useReactiveVar(mobileAppModel.model);

    if (!model) return null;
    
    const { isUploadedToMarket } = model;

    return (
        <ButtonsWrapper>
            <CancelButton
                disabled={isLoading}
                onClick={cancel}
            >
                Закрыть
            </CancelButton>

            <ButtonCustom
                disabled={isLoading}
                onClick={saveModel}
            >
                Сохранить
            </ButtonCustom>
            
            <ButtonCustom
                disabled={isLoading}
                onClick={addToMarket}
                width={!isUploadedToMarket && "100%"}
            >
                {isUploadedToMarket ? "Обновить маркет" : "Опубликовать на маркет"}
            </ButtonCustom>
        </ButtonsWrapper>
    );
};
