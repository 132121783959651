import { useState, ChangeEvent, Dispatch, SetStateAction } from "react";
import {
  Payment,
  useDeactivatePurchasePaymentMutation,
  useGetPurchasePaymentsBySchoolQuery,
} from "../../../../generated/graphql";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { MobileAppSubtitle } from "../../../MobileAppEdit/MobileAppEdit.style";
import {
  confirmText,
  deactivatePaymentText,
  rowsPerPageOptions,
} from "./constants";
import { HeadColumns } from "./HeadColumns";
import { PaymentsList } from "./PaymentsList";
import "./styles.css";
import { CustomTablePagination } from "../../../shared/CustomTablePagination";
import { ConfirmationModal } from "../../../shared/ConfirmationModal";
import { RefetchPaymentType } from "../types";
import { LoadingProgressOverlay } from "../../../shared/LoadingProgress";

export const PaymentsHistory = ({
  setRefetchPayments,
}: {
  setRefetchPayments: Dispatch<SetStateAction<{ refetch: RefetchPaymentType }>>;
}) => {
  const { schoolId } = useParams<{ schoolId: string }>();
  const [payments, setPayments] = useState<Payment[]>([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(rowsPerPageOptions[0]);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(0);

  const [paymentId, setPaymentId] = useState(null);

  const { error, loading, refetch } = useGetPurchasePaymentsBySchoolQuery({
    variables: { schoolId, limit, skip },
    fetchPolicy: "cache-and-network",
    onCompleted: (e) => {
      if (error) return;

      setRefetchPayments({ refetch });

      const { payments, total } = e.getPurchasePaymentsBySchool;
      if (total) {
        setTotal(total);
        setPayments(payments);
      }
    },
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setSkip(newPage * limit);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(+event.target.value);
    setSkip(0);
    setPage(0);
  };

  const [confirmationModalOpened, setConfirmationModalOpened] = useState(false);

  const [deactivatePurchasePaymentMutation, { loading: deactivateLoading }] =
    useDeactivatePurchasePaymentMutation({
      variables: {
        paymentId,
      },
      onCompleted: async () => {
        await refetch();
        setConfirmationModalOpened(false);
      },
    });

  const handleDeactivatePayment = (paymentId: string) => {
    setPaymentId(paymentId);
    setConfirmationModalOpened(true);
  };

  return (
    <>
      <Box mt={4}>
        <MobileAppSubtitle>История платежей</MobileAppSubtitle>
      </Box>
      <Paper>
        <TableContainer className="paymentsHistory">
          <Table stickyHeader aria-label="sticky table">
            <HeadColumns />
            <TableBody>
              <PaymentsList
                payments={payments}
                handleDeactivatePayment={handleDeactivatePayment}
              />
            </TableBody>
          </Table>
        </TableContainer>

        {total > rowsPerPageOptions[0] ? (
          <CustomTablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={total}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Paper>

      <ConfirmationModal
        open={confirmationModalOpened}
        confirmButtonText={deactivatePaymentText}
        content={confirmText}
        disabled={deactivateLoading}
        onConfirm={deactivatePurchasePaymentMutation}
        onClose={() => setConfirmationModalOpened(false)}
      />
      {(loading || deactivateLoading) && <LoadingProgressOverlay />}
    </>
  );
};
