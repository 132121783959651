import { useMutation } from "@apollo/client";
import {
  Box,
  Collapse,
  InputAdornment,
  FormControlLabel,
  List,
  ListItem,
  Checkbox,
} from "@material-ui/core";
import { AttachFile, Cancel } from "@material-ui/icons";
import React, { useState, useEffect, useRef } from "react";
import {
  CreateProjectTaskCommentDocument,
  CreateProjectTaskCommentMutation,
  DeleteProjectTaskCommentDocument,
  DeleteProjectTaskCommentFileDocument,
  DeleteProjectTaskCommentFileMutation,
  GetProjectTaskDocument,
  ProjectTaskComment,
  UpdateProjectTaskCommentDocument,
  UpdateProjectTaskCommentMutation,
  User,
} from "../../../generated/graphql";
import { CustomIconButton } from "../../shared/Buttons/CustomIconButton";
import { DeleteIconButton } from "../../shared/Buttons/DeleteIconButton";
import { EditIconButton } from "../../shared/Buttons/EditIconButton";
import { SaveIconButton } from "../../shared/Buttons/SaveIconButton";
import { DEFAULT_AVATAR_URL } from "../../shared/constants";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import {
  ButtonPlusSmall,
  HiddenInput,
  ModalInput,
  primary,
  PrimaryLink,
  secondary,
} from "../../shared/Style/Style";
import { getFileExtensionHandler } from "../../shared/Utils/StringOperations/getFileExtensionHandler";
import {
  AttachmentImage,
  KanbanModalSubtitle,
  KanbanModalUserAvatar,
} from "../KanbanTaskModal.style";

interface ICommentsProps {
  disabledOnLoading?: boolean;
  taskId: string;
  author: User;
  comments: ProjectTaskComment[];
  description: string | null;
  saveTaskHandler(): void;
}

const imgExtensions: string[] = [".png", ".jpg", ".jpeg", ".svg"];

export const Comments = ({
  author,
  disabledOnLoading,
  taskId,
  comments,
                           description,
  saveTaskHandler,
}: ICommentsProps) => {
  const [creating, setCreating] = useState(false);
  const [chosenComment, setChosenComment] = useState("");
  const fileInput = useRef<HTMLInputElement>(null);

  const [text, setText] = useState("");
  const [file, setFile] = useState<File>(null);

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const hideHandler = () => {
    setCreating(false);
    setText("");
    setFile(null);
  };

  const [
    createComment,
    {
      data: createCommentData,
      loading: createCommentLoading,
      error: createCommentError,
    },
  ] = useMutation<CreateProjectTaskCommentMutation>(
    CreateProjectTaskCommentDocument,
    {
      variables: {
        projectTaskId: taskId,
        text,
        file: file,
      },
      refetchQueries: [
        { query: GetProjectTaskDocument, variables: { id: taskId } },
      ],
    }
  );

  const [
    updateComment,
    {
      data: updateCommentData,
      loading: updateCommentLoading,
      error: updateCommentError,
    },
  ] = useMutation<UpdateProjectTaskCommentMutation>(
    UpdateProjectTaskCommentDocument,
    {
      variables: {
        id: chosenComment,
        text,
        file,
      },
      refetchQueries: [
        { query: GetProjectTaskDocument, variables: { id: taskId } },
      ],
    }
  );

  const [
    deleteComment,
    {
      data: deleteCommentData,
      loading: deleteCommentLoading,
      error: deleteCommentError,
    },
  ] = useMutation<DeleteProjectTaskCommentFileMutation>(
    DeleteProjectTaskCommentDocument,
    {
      refetchQueries: [
        { query: GetProjectTaskDocument, variables: { id: taskId } },
      ],
    }
  );

  const [
    deleteCommentFile,
    {
      data: deleteCommentFileData,
      loading: deleteCommentFileLoading,
      error: deleteCommentFileError,
    },
  ] = useMutation<DeleteProjectTaskCommentFileMutation>(
    DeleteProjectTaskCommentFileDocument,
    {
      refetchQueries: [
        { query: GetProjectTaskDocument, variables: { id: taskId } },
      ],
    }
  );

  //#region effects

  useEffect(() => {
    if (
      deleteCommentFileData ||
      deleteCommentData ||
      updateCommentData ||
      createCommentData
    ) {
      setRequestError(false);
      setFile(null);
      setText("");
      setChosenComment("");
    }
  }, [
    deleteCommentFileData,
    deleteCommentData,
    updateCommentData,
    createCommentData,
  ]);

  useEffect(() => {
    if (createCommentData) {
      setCreating(false);
    }
  }, [createCommentData]);

  useEffect(() => {
    if (
      deleteCommentFileError ||
      deleteCommentError ||
      updateCommentError ||
      createCommentError
    ) {
      setRequestError(true);
      setChosenComment("");
    }
  }, [
    deleteCommentFileError,
    deleteCommentError,
    updateCommentError,
    createCommentError,
  ]);

  useEffect(() => {
    if (deleteCommentError) {
      setErrorMessage("При удалении комментария произошла ошибка");
    }
  }, [deleteCommentError]);

  useEffect(() => {
    if (updateCommentError) {
      setErrorMessage("При изменении комментария произошла ошибка");
    }
  }, [updateCommentError]);

  useEffect(() => {
    if (createCommentError) {
      setErrorMessage("При создании комментария произошла ошибка");
    }
  }, [createCommentError]);

  useEffect(() => {
    if (deleteCommentFileError) {
      setErrorMessage("При изменении статуса комментария произошла ошибка");
    }
  }, [deleteCommentFileError]);

  // #endregion

  const saveCommentHandler = () => {
    if (creating) {
      createComment();
      !description && saveTaskHandler();
      return;
    }
    updateComment();
  };

  const editHandler = (id: string, commentText: string): void => {
    if (chosenComment === id) {
      saveCommentHandler();
      setChosenComment("");
      return;
    }

    setText(commentText);
    setChosenComment(id);
  };

  // const setErrors = (): void => {
  //   setRequestError(true);
  //   setErrorMessage("Добавить комментарий можно только при редактировании");
  // };

  const disableOnCommentsLoading =
    deleteCommentFileLoading ||
    deleteCommentLoading ||
    updateCommentLoading ||
    createCommentLoading;

  return (
    <>
      <Box
        mt={"20px"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <KanbanModalSubtitle>Комментарии</KanbanModalSubtitle>

        <ButtonPlusSmall
          disabled={
            disabledOnLoading ||
            creating ||
            disableOnCommentsLoading ||
            !!chosenComment
          }
          // onClick={() => taskId ? setCreating(true): setErrors()}
          onClick={() => setCreating(true)}
        />
      </Box>

      <Collapse in={creating} collapsedSize="0px">
        <Box display="flex" mt="10px">
          <Box mr="10px" mt="12px">
            <KanbanModalUserAvatar prim={author.photo || DEFAULT_AVATAR_URL} />
          </Box>

          <ModalInput
            label="Комментарий"
            value={text}
            onChange={({ target: { value } }) => setText(value)}
            disabled={disabledOnLoading || disableOnCommentsLoading}
            fullWidth
            multiline
            rows={5}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box mr="-20px" display="flex" flexDirection="column">
                    <SaveIconButton
                      disabled={disabledOnLoading || disableOnCommentsLoading}
                      save={saveCommentHandler}
                      fz={20}
                    />

                    <DeleteIconButton
                      disabled={disabledOnLoading || disableOnCommentsLoading}
                      deleteHandler={hideHandler}
                      item="комментарий"
                      fz={20}
                    />

                    {!file && (
                      <CustomIconButton
                        disabled={disabledOnLoading || disableOnCommentsLoading}
                        callback={() => fileInput.current.click()}
                        fz={20}
                        mainColor={secondary}
                        children={<AttachFile fontSize="inherit" />}
                      />
                    )}
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Collapse in={!!file} collapsedSize="0px">
          <Box
            textAlign="center"
            mt="8px"
            mr="-10px"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <AttachFile fontSize="small" />

            {file?.name}

            <DeleteIconButton
              disabled={disabledOnLoading || disableOnCommentsLoading}
              deleteHandler={() => {
                fileInput.current.value = "";
                setFile(null);
              }}
              item="файл"
              fz={20}
            />
          </Box>
        </Collapse>
      </Collapse>

      {comments.length ? (
        comments.map((comment) => (
          <React.Fragment key={comment.id}>
            <Box display="flex" mt="10px">
              <Box mr="10px" mt="24px">
                <KanbanModalUserAvatar
                  prim={comment.createdBy?.photo || DEFAULT_AVATAR_URL}
                />
              </Box>

              <ModalInput
                value={chosenComment === comment.id ? text : comment.text}
                onChange={({ target: { value } }) => setText(value)}
                disabled={
                  disabledOnLoading ||
                  disableOnCommentsLoading ||
                  chosenComment !== comment.id
                }
                fullWidth
                multiline
                rows={5}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box mr="-20px" display="flex" flexDirection="column">
                        <EditIconButton
                          edit={() => editHandler(comment.id, comment.text)}
                          disabled={
                            disabledOnLoading ||
                            disableOnCommentsLoading ||
                            author.id !== comment.createdBy.id ||
                            creating
                          }
                          active={chosenComment === comment.id}
                          fz={20}
                        />

                        <DeleteIconButton
                          disabled={
                            disabledOnLoading ||
                            disableOnCommentsLoading ||
                            chosenComment === comment.id ||
                            author.id !== comment.createdBy.id ||
                            creating
                          }
                          deleteHandler={() =>
                            deleteComment({ variables: { id: comment.id } })
                          }
                          item="комментарий"
                          fz={20}
                        />

                        {!comment.file && !file && (
                          <CustomIconButton
                            disabled={
                              disabledOnLoading ||
                              disableOnCommentsLoading ||
                              chosenComment !== comment.id ||
                              author.id !== comment.createdBy.id ||
                              creating
                            }
                            callback={() => fileInput.current.click()}
                            fz={20}
                            mainColor={primary}
                            children={<AttachFile fontSize="inherit" />}
                          />
                        )}
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            {!!comment.file ? (
              <Collapse in={!!comment.file} collapsedSize="0px">
                <Box
                  textAlign="center"
                  mt="8px"
                  mr="-10px"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {imgExtensions.includes(
                    getFileExtensionHandler(comment.file)
                  ) ? (
                    <Box position="relative">
                      <AttachmentImage src={comment.file} alt={comment.file} />

                      <Box position="absolute" top={0} right={0}>
                        <CustomIconButton
                          mainColor="rgba(255, 0, 0, 0.5);"
                          disabled={
                            disabledOnLoading ||
                            disableOnCommentsLoading ||
                            author.id !== comment.createdBy.id
                          }
                          callback={() => {
                            fileInput.current.value = "";
                            setFile(null);
                            deleteCommentFile({
                              variables: { id: comment.id },
                            });
                          }}
                          fz={14}
                          children={<Cancel color="inherit" />}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <AttachFile fontSize="small" />
                      <PrimaryLink href={comment.file}>
                        Вложение{getFileExtensionHandler(comment.file)}
                      </PrimaryLink>

                      <DeleteIconButton
                        disabled={
                          disabledOnLoading ||
                          disableOnCommentsLoading ||
                          author.id !== comment.createdBy.id
                        }
                        deleteHandler={() => {
                          fileInput.current.value = "";
                          setFile(null);
                          deleteCommentFile({ variables: { id: comment.id } });
                        }}
                        item="файл"
                        fz={20}
                      />
                    </>
                  )}
                </Box>
              </Collapse>
            ) : (
              chosenComment === comment.id && (
                <Collapse in={!!file} collapsedSize="0px">
                  <Box
                    textAlign="center"
                    mt="8px"
                    mr="-10px"
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <AttachFile fontSize="small" />

                    {file?.name}

                    <DeleteIconButton
                      disabled={
                        disabledOnLoading ||
                        disableOnCommentsLoading ||
                        author.id !== comment.createdBy.id
                      }
                      deleteHandler={() => {
                        fileInput.current.value = "";
                        setFile(null);
                      }}
                      item="файл"
                      fz={20}
                    />
                  </Box>
                </Collapse>
              )
            )}
          </React.Fragment>
        ))
      ) : (
        <Box mt="8px" textAlign="center" width="100%">
          Комментарии отсутствует
        </Box>
      )}

      <HiddenInput
        ref={fileInput}
        type="file"
        onChange={({ target: { files } }) => setFile(files[0])}
      />

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </>
  );
};
