import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/client/react/context";
import { client } from "./core/apollo/client";
import { Login } from "./components/Login";
import { Main } from "./components/Main";
import { Registration } from "./components/Registration/Registration";
import { RegistrationSuccess } from "./components/Registration/RegistrationSuccess";
import { Forgot } from "./components/Login/Forgot";
import { Reset } from "./components/Login/Reset";
import { Confirm } from "./components/Login/Confirm";
import {ModelIFrame} from "./components/ModelIFrame";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ruLocale from "date-fns/locale/ru";
import {PrivatePolicyUkr} from "./components/PrivatePolicy/PrivatePolicyUkr";
import {PrivatePolicyEN} from "./components/PrivatePolicy/PrivatePolicyEN";
import {UserAgreementUkr} from "./components/PrivatePolicy/UserAgreementUkr";
import {UserAgreementEn} from "./components/PrivatePolicy/UserAgreementEn";
import { PaymentPolicyUkr } from "./components/PrivatePolicy/PaymentPolicyUkr";
import { CookiesPolicyUkr } from "./components/PrivatePolicy/CookiesPolicyUkr";
import {CookiesPolicyEn} from "./components/PrivatePolicy/CookiesPolicyEn";
import {PaymentPolicyEn} from "./components/PrivatePolicy/PaymentPolicyEn";

function App() {
  return (
    <ApolloProvider client={client}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <Router basename={process.env.PUBLIC_URL}>
          <Switch>
            <Route exact path="/iframes/:siteKey">
              <ModelIFrame />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route exact path="/register/success">
              <RegistrationSuccess />
            </Route>
            <Route path="/register">
              <Registration />
            </Route>
            <Route path="/confirm/:token">
              <Confirm />
            </Route>
            <Route path="/forgot">
              <Forgot />
            </Route>
            <Route path="/resetPassword/:token">
              <Reset />
            </Route>
            <Route path="/privatePolicyUkr">
              <PrivatePolicyUkr />
            </Route>
            <Route path="/privatePolicyEn">
              <PrivatePolicyEN />
            </Route>
            <Route path="/userAgreementUkr">
              <UserAgreementUkr />
            </Route>
            <Route path="/userAgreementEn">
              <UserAgreementEn />
            </Route>
            <Route path="/paymentPolicyUkr">
              <PaymentPolicyUkr />
            </Route>
            <Route path="/cookiesPolicyUkr">
              <CookiesPolicyUkr />
            </Route>
            <Route path="/paymentPolicyEn">
              <PaymentPolicyEn />
            </Route>
            <Route path="/cookiesPolicyEn">
              <CookiesPolicyEn />
            </Route>
            <Route path="/">
              <Main />
            </Route>
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </ApolloProvider>
  );
}

export default App;
