import React, {memo, useMemo} from "react";
import {Field, Form} from 'react-final-form'
import {ButtonWrap, Container, CustomInput} from "./CreateEditThemeModal.style";
import {ButtonCustom, CancelButton} from "../../shared/Style/Style";
import {Autocomplete} from "@material-ui/lab";
import {Checkbox, Dialog, FormControlLabel} from "@material-ui/core";
import {CloseBtn} from "../../NotificationModal/NotificationModal.style";
import CloseIcon from "@material-ui/icons/Close";
import {StateType} from "./types";
import {INITIAL_STATE, tooltipTitle} from "../constants";
import {formValidate} from "../utils";
import {useApolloClient, useMutation} from "@apollo/client";
import {
  CreateOnboardingThemeDocument,
  CreateOnboardingThemeMutation,
  GetOnboardingRoutesEditDocument,
  GetOnboardingRoutesEditQuery,
  GetOnboardingThemesEditDocument,
  GetOnboardingThemesEditQuery,
  UpdateOnboardingThemeDocument,
  UpdateOnboardingThemeMutation
} from "../../../generated/graphql";
import Tooltip from "@material-ui/core/Tooltip";
import {TextEditor} from "../TextEditor";

interface CreateEditThemeModalProps {
  themeId?: string,
  open: boolean,
  close: () => void,
}

export const CreateEditThemeModal = memo(({
  themeId,
  open,
  close,
}: CreateEditThemeModalProps) => {
  const client = useApolloClient();

  const [createTheme] = useMutation<CreateOnboardingThemeMutation>(CreateOnboardingThemeDocument, {
    refetchQueries: [{query: GetOnboardingThemesEditDocument}],
    onCompleted: close
  });

  const [updateTheme] = useMutation<UpdateOnboardingThemeMutation>(UpdateOnboardingThemeDocument, {
    refetchQueries: [{query: GetOnboardingThemesEditDocument}],
    onCompleted: close
  })
  const validateFunc = (values: StateType) => formValidate(values)

  const onSubmit = async (values: StateType) => {
    const {title, description, video, routeId, isFinished} = values;

    const variables = {
      themeId: themeId ? themeId : undefined,
      title,
      description,
      video,
      routeId: routeId || null,
      isFinishedReset: themeId ? isFinished : undefined,
    };

    const fetchFunc = themeId ? updateTheme : createTheme;

    try {
      await fetchFunc({variables});
    } catch (err) {
      console.log("error")
    }
  }

  const routesMemo = useMemo(() => {
    const routes = client.readQuery<GetOnboardingRoutesEditQuery>({
      query: GetOnboardingRoutesEditDocument
    })

    return routes?.getOnboardingRoutesEdit || []
  }, [client, open]);

  const themeMemo = useMemo(() => {
    const themes = client.readQuery<GetOnboardingThemesEditQuery>({
      query: GetOnboardingThemesEditDocument
    })

    const theme = themes?.getOnboardingThemesEdit.find(({id}) => id === themeId)

    if (theme) {
      return Object.entries(theme).reduce((acc, [key, value]) => {
        acc[key] = key === "routeId" && !value ? "" : value
        return acc
      }, {})
    }

    return INITIAL_STATE

  }, [client, themeId, open])


  return (
    <Dialog
      open={open}
      onClose={close}
    >
      <Container className="container">
        <CloseBtn size="small" onClick={close}>
          <CloseIcon fontSize="small"/>
        </CloseBtn>
        <Form
          initialValues={themeMemo}
          validateOnBlur
          validate={validateFunc}
          onSubmit={onSubmit}
          render={({handleSubmit, submitting, values}) => (
            <form className="container__form" onSubmit={handleSubmit}>
              <Field name="title">
                {({input, meta}) => (
                  <CustomInput
                    {...input}
                    fullWidth
                    error={meta.error && meta.touched && !meta.active}
                    helperText={meta.touched && !meta.active && meta.error}
                    placeholder="Тема (заголовок) *"
                  />
                )
                }
              </Field>

              <Field name="description">
                {({input: {value, onChange, onBlur, onFocus}, meta}) => (
                  <TextEditor
                    value={value}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    error={meta.error && meta.touched && !meta.active}
                    helperText={meta.touched && !meta.active && meta.error}
                  />
                )
                }
              </Field>

              <Field name="video">
                {({input, meta}) => (
                  <Tooltip
                    placement="top-start"
                    title={tooltipTitle}
                  >
                    <CustomInput
                      {...input}
                      fullWidth
                      placeholder="Ссылка на YouTube *"
                      error={meta.error && meta.touched && !meta.active}
                      helperText={meta.touched && !meta.active && meta.error}
                    />
                  </Tooltip>
                )
                }
              </Field>

              <Field name="routeId">
                {({input}) => (
                  <Autocomplete
                    value={values.routeId}
                    options={routesMemo.map(({id}) => id)}
                    getOptionLabel={(option) => routesMemo.find(({id}) => id === option)?.name}
                    onChange={(_, value) => input.onChange(value)}
                    renderInput={props => (
                      <CustomInput
                        {...props}
                        placeholder="Страница для автоматического отображения"
                      />
                    )}
                  />
                )}
              </Field>

              {themeId && (
                <Field name="isFinished">
                  {({input}) => {
                    return (
                      <FormControlLabel
                        label="Обновить публикацию темы на AR_Teacher"
                        control={<Checkbox {...input} color="default"/>}
                      />
                    )
                  }}
                </Field>
              )}

              <ButtonWrap className="container__form__btns-wrap">
                <CancelButton onClick={close}>
                  Отменить
                </CancelButton>

                <ButtonCustom
                  type="submit"
                  disabled={submitting}
                >
                  Сохранить
                </ButtonCustom>
              </ButtonWrap>
            </form>
          )}
        />
      </Container>
    </Dialog>
  )
});
