import { memo, useContext } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { FeatureFlags } from "../../../contexts/FeatureFlags";
import { useTheme } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronRightIcon from "@material-ui/core/SvgIcon/SvgIcon";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { StyledDrawer, useStyles } from "./Sidebar.style";
import { SIDEBAR_ROUTES } from "./routes";
import { SIDEBAR_ICONS } from "./icons";

interface SidebarProps {
  open: boolean;
  handleDrawerClose: () => void;
}

export const Sidebar = memo(({ open, handleDrawerClose }: SidebarProps) => {
  const { features } = useContext(FeatureFlags);
  const theme = useTheme();
  const drawerStyles = useStyles();

  return (
    <StyledDrawer
      variant="permanent"
      className={clsx(drawerStyles.drawer, {
        [drawerStyles.drawerOpen]: open,
        [drawerStyles.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [drawerStyles.drawerOpen]: open,
          [drawerStyles.drawerClose]: !open,
        }),
      }}
      open={open}
    >
      <div className={drawerStyles.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />

      <List disablePadding={true}>
        {SIDEBAR_ROUTES
          .filter(({ feature }) => features[feature])
          .map(({ link, name, icon }, i) => (
            <ListItem
              key={i}
              button
              component={NavLink}
              to={link}
              activeClassName="Mui-selected"
            >
              <ListItemIcon>{SIDEBAR_ICONS[icon]}</ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          ))
        }
      </List>
      <Divider />
    </StyledDrawer>
  );
});
