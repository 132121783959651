import React from "react";
import {
  PercentItem1,
  PercentItem2,
  PercentItem3,
  PercentItem4,
  PercentItem5,
  SpeedometerArrowContainer,
  SpeedometerContainer,
  CostField,
} from "./Speedometer.style";
import {Arrow, Speedometer, SpeedometerGreen} from "../../../constants/speedometersData";

const percents = [
  { name: "0%", component: PercentItem1 },
  { name: "25%", component: PercentItem2 },
  { name: "50%", component: PercentItem3 },
  { name: "75%", component: PercentItem4 },
  { name: "100%", component: PercentItem5 },
];

export const SpeedometerReport = ({
  value,
  green,
  additionalData,
}: {
  value: number;
  green?: boolean;
  additionalData?: number[];
}) => {
  const sector = 258; // total speedometer sector deg

  const angle = 231 + (sector / 100 * value);

  return (
    <SpeedometerContainer>
      {green ? <SpeedometerGreen /> : <Speedometer />}
      {percents.map((el, i) => (
        <el.component key={i}>{el.name}</el.component>
      ))}
      <SpeedometerArrowContainer
        style={{
          transform: `rotate(
            ${angle}deg
          )`,
        }}
      >
        <Arrow />
      </SpeedometerArrowContainer>

      {additionalData?.length && (
        <CostField>
          {additionalData[0] || 0} / {additionalData[1] || 0} грн.
        </CostField>
      )}
    </SpeedometerContainer>
  );
};
