export const currentDateCheckHandler = (
  dateToCompare: Date,
  rangeType: string
): boolean => {
  const currentDate = new Date();
  const millisecondsInDay = 24 * 60 * 60 * 1000;

  let result: boolean;


  switch (rangeType) {
    case 'month':
      if (
        currentDate.getMonth() === dateToCompare.getMonth()
        && currentDate.getFullYear() === dateToCompare.getFullYear()
      ) {
        return result = true;
      }

      return result = false;

    case 'week':
      const dayOrder = currentDate.getDay();
      let currentSunday: Date;

      switch (dayOrder) {
        case 0:
          currentSunday = currentDate
          break;

        default:
          currentSunday = new Date(currentDate.getTime() + (7 - dayOrder) * millisecondsInDay);
          break;
      }

      if (
        currentSunday.getDate() === dateToCompare.getDate()
        && currentSunday.getMonth() === dateToCompare.getMonth()
        && currentSunday.getFullYear() === dateToCompare.getFullYear()
      ) {
        return result = true;
      }

      return result = false;
  }

  return result;
}