import { Award, AwardTypeEnum } from "../../../generated/graphql";
import { AwardDaysMap, AwardNameMap } from "./maps";

export const isValidHexColor = (hexColor: string) => {
    const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    return regex.test(hexColor);
};

export const getPublishedAwardsIds = (awardsList: Award[]) => {
    return awardsList?.filter(award => award.isPublished).map(award => award.id);
};

export const getUnpublishedAwardsIds = (awardsList: Award[]) => {
    return awardsList?.filter(award => !award.isPublished).map(award => award.id);
};

export const getSelectedDays = (awardsList: Award[], day: number) => {
    return awardsList?.map(award => award.day)?.filter(selectedDay => selectedDay !== day);
}

export const getSelectDaysOptions = (awardsList: Award[], day: number) => {
    const selectedDays = getSelectedDays(awardsList, day) || [];
    
    if (selectedDays?.length > 0) {
        return Array.from(AwardDaysMap.entries())?.filter(([key]) => !selectedDays?.includes(key))?.map(([, value]) => value);
    };

    return Array.from(AwardDaysMap.values());
};

export const getSelectedTypes = (awardsList: Award[]) => {
    return awardsList?.map(award => award.type) || [];
};

export const getSelectTypesOptions = (awardsList: Award[], type: string) => {
    const selectedTypes = getSelectedTypes(awardsList);
    
    return selectedTypes.includes(AwardTypeEnum.Bonus) && type !== AwardTypeEnum.Bonus ?
       [AwardNameMap.get(AwardTypeEnum.Day)] :  Array.from(AwardNameMap.values());
};
