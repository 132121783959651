import { gql } from "@apollo/client";

export const CREATE_ROBOTS_HISTORY_LINK = gql`
  mutation CreateRobotsHistoryLink($input: CreateRobotsHistoryInput!) {
    createRobotsHistoryLink(input: $input) {
      id
      link
      order
    }
  }
`;

export const DELETE_ROBOTS_HISTORY_ID = gql`
  mutation DeleteRobotsHistory($deleteRobotsHistoryId: UUID!) {
    deleteRobotsHistory(id: $deleteRobotsHistoryId)
  }
`;

export const CREATE_AWARD = gql`
  mutation createAward($input: CreateAwardInput!) {
    createAward(input: $input) {
      id
    }
  }
`;

export const UPDATE_AWARD = gql`
  mutation updateAward($input: UpdateAwardInput!) {
    updateAward(input: $input) {
      id
    }
  }
`;

export const DELETE_AWARD = gql`
  mutation deleteAward($deleteAwardId: UUID!) {
    deleteAward(id: $deleteAwardId)
  }
`;

export const PUBLISH_AWARDS = gql`
  mutation publishAwards($ids: [UUID!]!) {
    publishAwards(ids: $ids)
  }
`;

export const UNPUBLISH_AWARDS = gql`
  mutation unpublishAwards($ids: [UUID!]!) {
    unpublishAwards(ids: $ids)
  }
`;

export const REORDER_AWARDS = gql`
  mutation reorderAwards($input: [AwardReorderInput!]!) {
    reorderAwards(input: $input)
  }
`;

export const CREATE_GAME_MODEL = gql`
  mutation createGameModel($input: GameModelInput!) {
    createGameModel(input: $input) {
      id
    }
  }
`;

export const DELETE_GAME_MODEL = gql`
  mutation DeleteGameModel($deleteGameModelId: UUID!) {
    deleteGameModel(id: $deleteGameModelId)
  }
`;

export const UPDATE_GAME_MODEL = gql`
  mutation updateGameModel($updateGameModelId: UUID!, $input: GameModelInput!) {
    updateGameModel(id: $updateGameModelId, input: $input) {
      id
    }
  }
`;

export const CREATE_GAME_MODEL_STATE = gql`
  mutation createGameModelState($input: GameModelStateInput!) {
    createGameModelState(input: $input) {
      id
    }
  }
`;

export const DELETE_GAME_MODEL_STATE = gql`
  mutation deleteGameModelState($deleteGameModelStateId: UUID!) {
    deleteGameModelState(id: $deleteGameModelStateId)
  }
`;

export const UPDATE_GAME_MODEL_STATE = gql`
  mutation updateGameModelState(
    $updateGameModelStateId: UUID!
    $input: GameModelStateInput!
  ) {
    updateGameModelState(id: $updateGameModelStateId, input: $input) {
      id
    }
  }
`;

export const UPDATE_GAME_MODEL_STATE_FILE = gql`
  mutation updateGameModelStateFile(
    $updateGameModelStateFileId: UUID!
    $file: Upload!
    $fileType: GameModelStateFileTypeEnum!
  ) {
    updateGameModelStateFile(
      id: $updateGameModelStateFileId
      file: $file
      fileType: $fileType
    )
  }
`;

export const DELETE_GAME_MODEL_STATE_FILE = gql`
  mutation deleteGameModelStateFile(
    $deleteGameModelStateFileId: UUID!
    $fileType: GameModelStateFileTypeEnum!
  ) {
    deleteGameModelStateFile(
      id: $deleteGameModelStateFileId
      fileType: $fileType
    )
  }
`;

export const UPDATE_REWARD = gql`
  mutation updateReward($updateRewardId: UUID!, $input: RewardInput!) {
    updateReward(id: $updateRewardId, input: $input) {
      id
      name
      order
      type
    }
  }
`;

export const CREATE_GAME_MODEL_ARTIFACT = gql`
  mutation createGameModelArtifact($input: GameModelArtifactInput!) {
    createGameModelArtifact(input: $input) {
      id
    }
  }
`;

export const DELETE_GAME_MODEL_ARTIFACT = gql`
  mutation deleteGameModelArtifact($deleteGameModelArtifactId: UUID!) {
    deleteGameModelArtifact(id: $deleteGameModelArtifactId)
  }
`;

export const UPDATE_GAME_MODEL_ARTIFACT = gql`
  mutation updateGameModelArtifact($updateGameModelArtifactId: UUID!, $input: GameModelArtifactInput!) {
    updateGameModelArtifact(id: $updateGameModelArtifactId, input: $input) {
      id
    }
  }
`;

export const UPDATE_GAME_MODEL_ARTIFACT_FILE = gql`
  mutation updateGameModelArtifactFile($updateGameModelArtifactFileId: UUID!, $file: Upload!, $fileType: GameModelArtifactFileTypeEnum!) {
    updateGameModelArtifactFile(id: $updateGameModelArtifactFileId, file: $file, fileType: $fileType)
  }
`;

export const DELETE_GAME_MODEL_ARTIFACT_FILE = gql`
  mutation deleteGameModelArtifactFile($deleteGameModelArtifactFileId: UUID!, $fileType: GameModelArtifactFileTypeEnum!) {
    deleteGameModelArtifactFile(id: $deleteGameModelArtifactFileId, fileType: $fileType)
  }
`;
