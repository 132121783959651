import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";

import {useQuery, useMutation} from "@apollo/client";
import {
  CreateMobileNotificationDocument,
  CreateMobileNotificationMutation,
  GetMobileNotificationsByAppDocument,
  GetMobileNotificationsByAppQuery,
  MobileNotification,
  PublishMobileNotificationDocument,
  PublishMobileNotificationMutation,
} from "../../generated/graphql";

import {
  Box,
  CircularProgress,
  Container,
  Table,
  TablePagination,
} from "@material-ui/core";

import {NavBar} from "../shared/NavBar";
import {ErrorSnackbar} from "../shared/Error/ErrorSnackbar";
import {MobileAppNotificationModal} from "../MobileAppModal/MobileAppNotificationModal";
import {MobileAppDeleteModal} from "../MobileAppModal/MobileAppDeleteModal";
import {Menu} from "../shared/Menu";
import {ErrorMessageSnackbar} from "../shared/ErrorMessageSnackbar";

import {
  ButtonPlus,
  Header,
  LoadingBox,
  MainTableCell,
  MainTableFooter,
  MainTableHeaderTitle,
  MainTableStatusCell,
} from "../shared/Style/Style";
import {
  EmptyNotificationTableRow,
  MobileAppNotificationRow,
} from "./MobileAppNotifications.style";

import {cutStringHandler} from "../shared/Utils/StringOperations/cutStringHandler";

import {navItems} from "../../constants/navItemMobileApp";

interface IMobileAppNotificationsProps {
  disabled?: boolean;
}

const headers = [
  "Дата",
  "Время",
  "Заголовок",
  "Текст уведомления",
  "Статус",
  " ",
];

export const MobileAppNotifications = ({
   disabled,
 }: IMobileAppNotificationsProps) => {
  const {appId} = useParams<{ appId: string }>();
  const [chosenNotification, setChosenNotification] =
  useState<MobileNotification>(null);

  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // #region pagination

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
  event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // #endregion

  const {
    data: mobileNotificationsData,
    loading: mobileNotificationsLoading,
    error: mobileNotificationsError,
    refetch: refetchMobileNotifications,
  } = useQuery<GetMobileNotificationsByAppQuery>(
  GetMobileNotificationsByAppDocument,
  {
    variables: {
      limit: rowsPerPage,
      skip: rowsPerPage * page,
      appId,
    },
    fetchPolicy: "network-only",
  }
  );

  // #region actions

  const [
    createNotification,
    {
      data: createNotificationData,
      loading: createNotificationLoading,
      error: createNotificationError,
    },
  ] = useMutation<CreateMobileNotificationMutation>(
  CreateMobileNotificationDocument,
  {
    variables: {
      notificationData: {
        applicationId: appId,
      },
    },
    refetchQueries: [{
      query: GetMobileNotificationsByAppDocument,
      variables: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        appId,
      },
    }]
  }
  );

  const [
    publishNotification,
    {loading: publishNotificationLoading, error: publishNotificationError},
  ] = useMutation<PublishMobileNotificationMutation>(
  PublishMobileNotificationDocument,
  {
    variables: {
      id: chosenNotification?.id,
    },
    refetchQueries: [
      {
        query: GetMobileNotificationsByAppDocument,
        variables: {
          limit: rowsPerPage,
          skip: page * rowsPerPage,
          appId,
        },
      },
    ],
  }
  );

  // #endregion

  //#region effects

  useEffect(() => {
    if (publishNotificationError) {
      setRequestError(true);
      setErrorMessage("При публикации уведомления произошла ошибка");
    }
  }, [publishNotificationError]);

  useEffect(() => {
    if (createNotificationError) {
      setRequestError(true);
      setErrorMessage("При создании уведомления произошла ошибка");
    }
  }, [createNotificationError]);

  useEffect(() => {
    if (createNotificationData) {
      setRequestError(false);
      setChosenNotification(createNotificationData.createMobileNotification);
      setCreateModal(true);
    }
  }, [createNotificationData]);

  //#endregion

  const disableOnLoading =
  createNotificationLoading || publishNotificationLoading;

  let content;

  if (mobileNotificationsLoading) {
    content = (
    <LoadingBox>
      <CircularProgress color="inherit"/>
    </LoadingBox>
    );
  }

  if (mobileNotificationsError) {
    content = <ErrorSnackbar error={mobileNotificationsError}/>;
  }

  if (mobileNotificationsData) {
    const notifications =
    mobileNotificationsData.getMobileNotificationsByApp.notifications;

    const funcMenuItems = (id: string) => {
      const notification = notifications.find(({id: notificationId}) => notificationId === id)
      return [
        {
          name: "Опубликовать",
          action: () =>
          publishNotification({
            variables: {id},
            refetchQueries: [
              {
                query: GetMobileNotificationsByAppDocument,
                variables: {
                  limit: rowsPerPage,
                  skip: page * rowsPerPage,
                  appId,
                },
              },
            ],
          }),
        },
        {
          name: "Редактировать",
          action: () => {
            setChosenNotification(notification)
            setCreateModal(true)
          },
        },
        {
          name: "Удалить",
          action: () => {
            setChosenNotification(notification)
            setDeleteModal(true)
          },
          color: "red",
        },
      ];
    };

    content = (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        mt={"40px"}
        mb={"26px"}
      >
        <NavBar items={navItems} margin={"0"}/>

        <ButtonPlus
          width={180}
          variant="contained"
          disabled={disableOnLoading}
          onClick={() => createNotification()}
        >
          Добавить
        </ButtonPlus>
      </Box>

      <Table>
        <MobileAppNotificationRow>
          {headers.map((header) => (
          <MainTableHeaderTitle key={header}>{header}</MainTableHeaderTitle>
          ))}
        </MobileAppNotificationRow>
      </Table>

      {notifications.length ? (
      notifications.map((notification) => (
      <MobileAppNotificationRow
        key={notification.id}
        onClick={() => {
          setChosenNotification(notification);
          setCreateModal(true);
      }}
      >
        <MainTableCell>
          {notification.isPublished
          ? new Date(notification.publishedAt).toLocaleDateString()
          : "-"}
        </MainTableCell>

        <MainTableCell>
          {notification.isPublished
          ? notification.publishedAt?.slice(
          notification.publishedAt?.indexOf("T") + 1,
          notification.publishedAt?.lastIndexOf(":")
          )
          : "-"}
        </MainTableCell>

        <MainTableCell>{notification.name}</MainTableCell>

        <MainTableCell>
          {cutStringHandler(notification.description)}
        </MainTableCell>

        <MainTableStatusCell status={notification.isPublished}>
          {notification.isPublished ? "Опубликовано" : "Не опубликовано"}
        </MainTableStatusCell>

        <MainTableCell
          onClick={(e) => {
            e.stopPropagation();
            setChosenNotification(notification);
          }}
        >
          <Menu
            vertical={36}
            horizontal={12}
            width={170}
            disabled={disableOnLoading}
            items={funcMenuItems(notification.id)}
          />
        </MainTableCell>
      </MobileAppNotificationRow>
      ))
      ) : (
      <EmptyNotificationTableRow>
        Уведомления отсутствуют
      </EmptyNotificationTableRow>
      )}

      <Box mt={2}>
        <MainTableFooter>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            count={
            mobileNotificationsData?.getMobileNotificationsByApp?.total || 0
            }
            component={"div"}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </MainTableFooter>
      </Box>
    </>
    );
  }

  return (
  <Container maxWidth="lg">
    <Header>Уведомления приложения</Header>

    {content}

    {createModal && (
    <MobileAppNotificationModal
      open={createModal}
      close={() => setCreateModal(false)}
      notification={chosenNotification}
      refetchVariables={{
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        appId,
      }}
    />
    )}

    <MobileAppDeleteModal
      open={deleteModal}
      close={() => setDeleteModal(false)}
      item={chosenNotification}
      refetch={() => refetchMobileNotifications()}
    />

    <ErrorMessageSnackbar
      open={requestError}
      closeHandler={() => setRequestError(false)}
      message={errorMessage}
    />
  </Container>
  );
};
