import styled from "styled-components";
import { IconButton, Radio } from "@material-ui/core";
import { ModalInput, primary } from "../shared/Style/Style";
import { PhotoPicker } from "../shared/PhotoPicker";

export const NewsModalContent = styled.div`
  padding: 25px;
  min-width: 550px;
`;

export const CloseBtn = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const NewsModalInputs = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PhotoDropzone = styled(PhotoPicker)`
  flex-basis: 20%;
  flex-shrink: 0;
`;

export const NewsModalForm = styled.div`
  flex-basis: 75%
`;

export const NewsModalInput = styled(ModalInput)`
  margin-bottom: 15px;
  box-sizing: border-box;

  &:last-of-type {
    margin-bottom: 0;
  }

  & .MuiInputBase-root {
    padding: 0 10px;
  }

  & .MuiFormLabel-root {
    padding: 0 10px;
  }
`;

export const CheckBoxCheck = styled(Radio)`
  & .MuiRadio-colorSecondary {
    color: ${primary};
  }

  &.MuiIconButton-colorSecondary {
    color: ${primary};
  }
`;