import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import {
  GetCostReportByEpicQuery,
  GetCostReportByEpicDocument,
} from "../../../../generated/graphql";
import { IProjectReportModalContentProps } from "../ProjectReportModal";

import {
  Table,
  TableRow,
  TableBody,
  CircularProgress
} from "@material-ui/core";
import {
  CustomTableHeaderCell,
  CustomTableCell,
  ColorizedTableCell,
  CustomTableRow,
  CustomTableHead
} from "../../../shared/CustomTable/CustomTable.style";

import { LoadingBox } from "../../../shared/Style/Style";
import { CustomTable } from "../../../shared/CustomTable";
import { DropdownArrowButton } from "../../../shared/Buttons/DropdownArrowButton";
import { ErrorMessageSnackbar } from "../../../shared/ErrorMessageSnackbar";

import { choseOrToggleIdHandler } from "../../../shared/Utils/StateOperations/choseOrToggleId";

export const DifferenceInMoneyContent = ({ epics, dateRange }: IProjectReportModalContentProps) => {
  const headers = [
    'Этап',
    'Задача',
    'Ответственный',
    'Статус',
    'Затраты план',
    'Затраты факт',
    'Разница'
  ];

  const [chosenEpic, setChosenEpic] = useState('');
  const [requestError, setRequestError] = useState(false);

  const [getTasks, { data, loading, error }] = useLazyQuery<GetCostReportByEpicQuery>(
    GetCostReportByEpicDocument,
    { variables: {
       epicKey: chosenEpic,
       ...dateRange
      }
    }
  );

  useEffect(() => {
    if (chosenEpic) {
      getTasks();
    }
  }, [chosenEpic]);

  useEffect(() => {
    if (error) {
      setRequestError(true);
    }
  }, [error]);

  let tasksList: JSX.Element | JSX.Element[];
  let epicCostEstimate: number;
  let epicCost: number;
  let epicDifference: number;

  if (loading) {
    tasksList = (
      <CustomTableCell colSpan={7}>
        <LoadingBox>
          <CircularProgress color='inherit'/>
        </LoadingBox>
      </CustomTableCell>
    )
  }

  if (data) {
    const tasks = data.getCostReportByEpic.tasks
    epicCostEstimate = data.getCostReportByEpic.totalCostEstimate;
    epicCost = data.getCostReportByEpic.totalCost;
    epicDifference = data.getCostReportByEpic.totalDifference;

    tasksList =
      tasks.length
        ? tasks.map(task => (
          <TableRow key={task.id}>
            <ColorizedTableCell
              number={task.difference}
            />
            {[
              task.name,
              task.assigneeName,
              task.status,
              task.costEstimate,
              task.cost,
              task.difference
            ].map((field, i) => (
              <ColorizedTableCell
                key={`${i} ${field}`}
                number={task.difference}
              >
                {field}
              </ColorizedTableCell>
            ))}
          </TableRow>
        ))
        : (
          <TableRow>
            <CustomTableCell colSpan={7}>У данного этапа отсутствуют задачи</CustomTableCell>
          </TableRow>
        )
  }

  const content = (
    <>
      <Table>
        <CustomTableHead>
          <TableRow>
            {headers.map(header => (
            <CustomTableHeaderCell
              key={header}
              width={(header === 'Этап' || header === 'Задача') ? 250 : 150}
            >
              {header}
            </CustomTableHeaderCell>))}
          </TableRow>
        </CustomTableHead>

        <TableBody>
          {epics.map(epic => (
            <>
              <CustomTableRow
                clickable={true}
                key={epic.epicKey}
                onClick={() => choseOrToggleIdHandler(epic.epicKey, chosenEpic, setChosenEpic)}
              >
                <CustomTableCell>
                  {epic.epicName}

                  <DropdownArrowButton
                    expanded={chosenEpic === epic.epicKey}
                  />
                </CustomTableCell>

                <CustomTableCell colSpan={3} />

                {[
                  epicCostEstimate,
                  epicCost,
                ].map((field, i) => (
                  <CustomTableCell key={`${i} ${field}`}>
                    {chosenEpic === epic.epicKey && field}
                  </CustomTableCell>
                ))}

                <ColorizedTableCell
                  number={chosenEpic === epic.epicKey ? epicDifference : 0}
                >
                  {chosenEpic === epic.epicKey && epicDifference}
                </ColorizedTableCell>
              </CustomTableRow>
              {chosenEpic === epic.epicKey && tasksList && (
                tasksList
              )}
            </>
          ))}
        </TableBody>
      </Table>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message='При загрузке задач произошла ошибка'
      />
    </>
  )

  return <CustomTable data={data?.getCostReportByEpic?.tasks || epics} tableContent={content} />
}
