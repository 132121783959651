import styled from "styled-components";
import { Box } from "@material-ui/core";

export const ContentTable = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 45%;
  padding-top: 30px;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #101010;
`;

export const ItemColor = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;
