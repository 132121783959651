import React, { useState } from "react";

import { IDateRange } from "../shared/DateRangePicker/DateRangePicker";

import { Container, Box } from "@material-ui/core";
import { DateRange } from "@material-ui/icons";

import { ReportsNavBar } from "../ReportsNavBar";
import { DateRangePicker } from "../shared/DateRangePicker";
import { DifferenceInHoursReport } from "./Reports/DifferenceInHoursReport";
import { DifferenceInMoneyReport } from "./Reports/DifferenceInMoneyReport";
import { DifferenceInDaysReport } from "./Reports/DifferenceInDaysReport";
import { CompletionPercentReport } from "./Reports/CompletionPercentReport";
import { AddedTasksReport } from "./Reports/AddedTasksReport";
import { EmployeesWorkloadReport } from "./Reports/EmployeesWorkloadReport";
import { EmployeesReport } from "./Reports/EmployeesReport";
import { ProjectSpendingReport } from "./Reports/ProjectSpendingReport";
import { EmployeesDistributionReport } from "./Reports/EmployeesDistributionReport";
import { VacancyReport } from "./Reports/VacancyReport/VacancyReport";

import { Header, DatePickerButton } from "../shared/Style/Style";
import { ReportNavButtonCustom } from "./Reports.style";
import { RatioReport } from "./Reports/RatioReport";
import { BugsReport } from "./Reports/BugsReport";

export const Reports = () => {
  const [activeReport, setActiveReport] = useState("diffInHours");
  const [reports, setReports] = useState(true);

  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: undefined,
    endDate: undefined,
  });

  const [isDateRangePickerOpen, setDateRangePickerOpen] = useState(false);

  let report;

  switch (activeReport) {
    case "diffInHours":
      report = <DifferenceInHoursReport dateRange={dateRange} />;
      break;

    case "diffInMoney":
      report = <DifferenceInMoneyReport dateRange={dateRange} />;
      break;

    case "diffInDays":
      report = <DifferenceInDaysReport dateRange={dateRange} />;
      break;

    case "completionPercent":
      report = <CompletionPercentReport />;
      break;

    case "addedTasks":
      report = <AddedTasksReport dateRange={dateRange} />;
      break;

    case "employeesWorkload":
      report = <EmployeesWorkloadReport dateRange={dateRange} />;
      break;

    case "employees":
      report = <EmployeesReport />;
      break;

    case "projectSpending":
      report = <ProjectSpendingReport dateRange={dateRange} />;
      break;

    case "vacancyClosingRate":
      report = <VacancyReport reports={reports} />;
      break;

    case "employeesDistribution":
      report = <EmployeesDistributionReport reports={reports} />;
      break;

    case "ratioReport": {
      report = <RatioReport />;
      break;
    }

    case "bugs": {
      report = <BugsReport />;
      break;
    }

    default:
      report = <DifferenceInHoursReport dateRange={dateRange} />;
      break;
  }

  const reportsWithDateRange = [
    "diffInHours",
    "diffInMoney",
    "diffInDays",
    "addedTasks",
    "projectSpending",
    "employeesWorkload",
  ];

  const isDatePickerVisible = reportsWithDateRange.includes(activeReport);

  return (
    <Container maxWidth="lg">
      <Header>{reports ? "Отчеты" : "Планирование"}</Header>

      <Box component="div" display="flex" justifyContent="center">
        <Box mr={2}>
          <ReportNavButtonCustom
            onClick={() => setReports(true)}
            isActive={reports}
          >
            Отчеты
          </ReportNavButtonCustom>
        </Box>

        <ReportNavButtonCustom
          onClick={() => setReports(false)}
          isActive={!reports}
        >
          Планирование
        </ReportNavButtonCustom>
      </Box>

      <ReportsNavBar
        activeReport={activeReport}
        setActiveReport={setActiveReport}
        setDateRange={setDateRange}
      />

      {isDatePickerVisible && (
        <Box mb={5}>
          <DatePickerButton onClick={() => setDateRangePickerOpen(true)}>
            <Box mr={1} lineHeight={1}>
              <DateRange />
            </Box>
            Период
          </DatePickerButton>
        </Box>
      )}

      {report}

      <DateRangePicker
        setDateRange={setDateRange}
        open={isDateRangePickerOpen}
        onClose={() => setDateRangePickerOpen(false)}
      />
    </Container>
  );
};
