import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateProjectTaskDocument,
  CreateProjectTaskMutation,
  DeleteProjectTaskDocument,
  DeleteProjectTaskMutation,
  GetProjectTasksByEpicDocument,
  GetProjectTaskStatusesByProjectDocument,
  GetProjectTaskStatusesByProjectQuery,
  GetUsersForDropdownDocument,
  GetUsersForDropdownQuery,
  ProjectTask,
  SyncProjectTaskWithTrackerDocument,
  SyncProjectTaskWithTrackerMutation,
  UpdateProjectTaskDocument,
  UpdateProjectTaskMutation,
} from "../../generated/graphql";

import { Autocomplete } from "@material-ui/lab";
import { MenuItem, TableRow, CircularProgress } from "@material-ui/core";

import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { convertDateToISOStringWithOffset } from "../shared/Utils/ConvertOperations/convertDateToISOStringWithOffset";
import { DeleteIconButton } from "../shared/Buttons/DeleteIconButton";
import { SaveIconButton } from "../shared/Buttons/SaveIconButton";
import { EpicsDatePicker } from "./EpicsDatePicker";
import { translateBaseTaskStatuses } from "../shared/Utils/OtherOperations/translateBaseTaskStatuses";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { SyncButton } from "../shared/Buttons/SyncButton";
import {
  CustomTableTextfield,
  CustomTableSelect,
  CustomTableCell,
  CustomTableCellNoBottom,
  CustomTableInput,
  CustomTableRow,
} from "../shared/CustomTable/CustomTable.style";
import {  LoadingBox } from "../shared/Style/Style";
import {CreateTaskButton, CreateTaskCell, Label} from "./Tasks.style";

interface ITasksListLocalProps {
  tasks: ProjectTask[];
  epicId: string;
  epicKey: string;
}

const InitialErrors = {
  name: false,
  assigneeId: false,
  startDateEstimate: false,
  finishDateEstimate: false,
};

const InitialTaskData = {
  name: "",
  taskStatusId: "",
  assigneeId: "",
  startDateEstimate: null as Date,
  finishDateEstimate: null as Date,
  startDateFact: null as Date,
  finishDateFact: null as Date,
  timeEstimate: 0,
  timeSpent: 0,
};

export const TasksListLocal = ({
  tasks,
  epicId,
  epicKey,
}: ITasksListLocalProps) => {
  const { projectId } = useParams<{ projectId: string }>();
  const [isCreating, setCreating] = useState(false);
  const [editedTask, setEditedTask] = useState("");

  const [disabledOnLoading, setDisabledOnLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [taskData, setTaskData] = useState(InitialTaskData);
  const [errors, setErrors] =
    useState<{ [key: string]: boolean }>(InitialErrors);

  const {
    data: projectTaskStatusesData,
    loading: projectTaskStatusesLoading,
    error: projectTaskStatusesError,
  } = useQuery<GetProjectTaskStatusesByProjectQuery>(
    GetProjectTaskStatusesByProjectDocument,
    {
      variables: {
        projectId,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [
    createTask,
    {
      data: createTaskData,
      loading: createTaskLoading,
      error: createTaskError,
    },
  ] = useMutation<CreateProjectTaskMutation>(CreateProjectTaskDocument, {
    variables: {
      taskData: {
        epicId,
        ...taskData,
        startDateEstimate:
          taskData.startDateEstimate &&
          convertDateToISOStringWithOffset(taskData.startDateEstimate),
        finishDateEstimate:
          taskData.finishDateEstimate &&
          convertDateToISOStringWithOffset(taskData.finishDateEstimate),
        startDateFact:
          taskData.startDateFact &&
          convertDateToISOStringWithOffset(taskData.startDateFact),
        finishDateFact:
          taskData.finishDateFact &&
          convertDateToISOStringWithOffset(taskData.finishDateFact),
      },
    },
    refetchQueries: [
      {
        query: GetProjectTasksByEpicDocument,
        variables: { epicKey },
      },
    ],
  });

  const [
    updateTask,
    {
      data: updateTaskData,
      loading: updateTaskLoading,
      error: updateTaskError,
    },
  ] = useMutation<UpdateProjectTaskMutation>(UpdateProjectTaskDocument, {
    refetchQueries: [
      {
        query: GetProjectTasksByEpicDocument,
        variables: { epicKey },
      },
    ],
  });

  const [
    deleteTask,
    {
      data: deleteTaskData,
      loading: deleteTaskLoading,
      error: deleteTaskError,
    },
  ] = useMutation<DeleteProjectTaskMutation>(DeleteProjectTaskDocument, {
    refetchQueries: [
      {
        query: GetProjectTasksByEpicDocument,
        variables: { epicKey },
      },
    ],
  });

  const [
    syncTracker,
    {
      data: syncTrackerData,
      loading: syncTrackerLoading,
      error: syncTrackerError,
    },
  ] = useMutation<SyncProjectTaskWithTrackerMutation>(
    SyncProjectTaskWithTrackerDocument,
    {
      fetchPolicy: "network-only",
      refetchQueries: [
        {
          query: GetProjectTasksByEpicDocument,
          variables: { epicKey },
        },
      ],
    }
  );

  const checkErrors = (): boolean => {
    const data = Object.entries({
      name: taskData.name,
      assigneeId: taskData.assigneeId,
      startDateEstimate: taskData.startDateEstimate,
      finishDateEstimate: taskData.finishDateEstimate,
    });

    data.forEach((field: any[]) => {
      if (!field[1]) {
        setErrors((state) => ({
          ...state,
          [field[0] as string]: true,
        }));
      }
    });

    return data.some((field: any[]) => !field[1]);
  };

  const createTaskHandler = () => {
    const isErrors = checkErrors();

    !isErrors && createTask();
  };

  const updateTaskHandler = (id: string) => {
    const isErrors = checkErrors();

    !isErrors &&
      updateTask({
        variables: {
          taskData: {
            id,
            ...taskData,
            startDateEstimate: convertDateToISOStringWithOffset(
              taskData.startDateEstimate
            ),
            finishDateEstimate: convertDateToISOStringWithOffset(
              taskData.finishDateEstimate
            ),
            startDateFact: taskData.startDateFact
              ? convertDateToISOStringWithOffset(taskData.startDateFact)
              : undefined,
            finishDateFact: taskData.finishDateFact
              ? convertDateToISOStringWithOffset(taskData.finishDateFact)
              : undefined,
          },
        },
      });
  };

  useEffect(() => {
    if (
      deleteTaskLoading ||
      updateTaskLoading ||
      createTaskLoading ||
      projectTaskStatusesLoading ||
      syncTrackerLoading
    ) {
      setDisabledOnLoading(true);
    }
  }, [
    deleteTaskLoading,
    updateTaskLoading,
    createTaskLoading,
    projectTaskStatusesLoading,
    syncTrackerLoading,
  ]);

  useEffect(() => {
    if (
      createTaskData ||
      updateTaskData ||
      setDisabledOnLoading ||
      deleteTaskData ||
      syncTrackerData
    ) {
      setDisabledOnLoading(false);
    }

    if (createTaskData || updateTaskData) {
      setTaskData(InitialTaskData);
      setErrors(InitialErrors);
      setRequestError(false);
      setErrorMessage("");
    }

    if (createTaskData) {
      setCreating(false);
    }

    if (updateTaskData) {
      setEditedTask("");
    }

    if (deleteTaskData) {
      setRequestError(false);
      setErrorMessage("");
    }
  }, [
    createTaskData,
    projectTaskStatusesData,
    deleteTaskData,
    updateTaskData,
    syncTrackerData,
  ]);

  useEffect(() => {
    if (deleteTaskError) {
      setRequestError(true);
      setErrorMessage("При удалении задачи произошла ошибка");
      setDisabledOnLoading(false);
    }
  }, [deleteTaskError]);

  useEffect(() => {
    if (updateTaskError) {
      setRequestError(true);
      setErrorMessage("При изменении задачи произошла ошибка");
      setDisabledOnLoading(false);
    }
  }, [updateTaskError]);

  useEffect(() => {
    if (syncTrackerError) {
      setRequestError(true);
      setErrorMessage("При синхронизации задачи произошла ошибка");
      setDisabledOnLoading(false);
    }
  }, [syncTrackerError]);

  useEffect(() => {
    if (createTaskError || projectTaskStatusesError) {
      setRequestError(true);
      setDisabledOnLoading(false);
    }

    if (createTaskError) {
      setErrorMessage("При создании задачи произошла ошибка");
    }

    if (projectTaskStatusesError) {
      setErrorMessage("При получении статусов проекта произошла ошибка");
    }
  }, [createTaskError, projectTaskStatusesError]);

  const {
    data: assigneeData,
    loading: assigneeLoading,
    error: assigneeError,
  } = useQuery<GetUsersForDropdownQuery>(GetUsersForDropdownDocument, {
    variables: {
      getUsersData: {
        role: "STAFF",
        limit: 100,
        skip: 0,
      },
    },
  });

  let content;

  if (assigneeLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (assigneeError) {
    content = <ErrorSnackbar error={assigneeError} />;
  }

  if (assigneeData && projectTaskStatusesData) {
    const assignees = assigneeData.getUsers.users;
    const projectTaskStatuses =
      projectTaskStatusesData.getProjectTaskStatusesByProject;

    content = (
      <>
        {!isCreating && (
          <CustomTableRow clickable={true}>
            <CreateTaskCell colSpan={11}>
              <CreateTaskButton
                fullWidth
                disabled={!!editedTask}
                onClick={() => {
                  setCreating(true);
                  setTaskData(InitialTaskData);
                }}
              >
                Создать задачу
              </CreateTaskButton>
            </CreateTaskCell>
          </CustomTableRow>
        )}

        {isCreating && (
          <TableRow>
            {tasks.length < 1 ? (
              <CustomTableCell padding="none" fontSize={24}>
                <SaveIconButton
                  disabled={disabledOnLoading}
                  save={createTaskHandler}
                />
                <DeleteIconButton
                  disabled={disabledOnLoading}
                  deleteHandler={() => {
                    setCreating(false);
                    setTaskData(InitialTaskData);
                  }}
                  item="задачу"
                />
              </CustomTableCell>
            ) : (
              <CustomTableCellNoBottom padding="none">
                <SaveIconButton
                  disabled={disabledOnLoading}
                  save={createTaskHandler}
                />
                <DeleteIconButton
                  disabled={disabledOnLoading}
                  deleteHandler={() => {
                    setCreating(false);
                    setTaskData(InitialTaskData);
                  }}
                  item="задачу"
                />
              </CustomTableCellNoBottom>
            )}

            <CustomTableCell padding="none">
              <CustomTableInput
                fontSize={12}
                value={taskData.name}
                onChange={({ target: { value } }) =>
                  setTaskData({ ...taskData, name: value })
                }
                error={errors.name}
              />
            </CustomTableCell>

            <CustomTableCell />

            <CustomTableCell padding="none" fontSize={12}>
              <Autocomplete
                fullWidth
                disableClearable
                options={assignees}
                getOptionLabel={(option) =>
                  option.lastName + " " + option.firstName
                }
                value={assignees.find(
                  (assignee) => assignee.id === taskData.assigneeId
                )}
                onChange={(e, value) => {
                  if (typeof value !== "string" && value?.id) {
                    setTaskData({ ...taskData, assigneeId: value.id });
                    setErrors({ ...errors, assigneeId: false });
                  }
                }}
                renderInput={(params) => (
                  <CustomTableTextfield
                    {...params}
                    fontSize={12}
                    error={errors.assigneeId}
                  />
                )}
              />
            </CustomTableCell>

            <CustomTableCell padding="none">
              <EpicsDatePicker
                disabled={false}
                maxDate={taskData.finishDateEstimate}
                value={taskData.startDateEstimate}
                onChange={(date) => {
                  setTaskData({ ...taskData, startDateEstimate: date });
                  setErrors({ ...errors, startDateEstimate: false });
                }}
                error={errors.startDateEstimate}
              />
            </CustomTableCell>

            <CustomTableCell padding="none">
              <EpicsDatePicker
                disabled={false}
                minDate={taskData.startDateEstimate}
                value={taskData.finishDateEstimate}
                onChange={(date) => {
                  setErrors({ ...errors, finishDateEstimate: false });
                  setTaskData({ ...taskData, finishDateEstimate: date });
                }}
                error={errors.finishDateEstimate}
              />
            </CustomTableCell>

            <CustomTableCell padding="none">
              <EpicsDatePicker
                disabled={false}
                maxDate={taskData.finishDateFact}
                value={taskData.startDateFact}
                onChange={(date) =>
                  setTaskData({ ...taskData, startDateFact: date })
                }
              />
            </CustomTableCell>

            <CustomTableCell padding="none">
              <EpicsDatePicker
                disabled={false}
                minDate={taskData.startDateFact}
                value={taskData.finishDateFact}
                onChange={(date) =>
                  setTaskData({ ...taskData, finishDateFact: date })
                }
              />
            </CustomTableCell>

            <CustomTableCell padding="none">
              <CustomTableInput
                type="number"
                fontSize={12}
                value={taskData.timeEstimate}
                onChange={({ target: { value } }) =>
                  setTaskData({ ...taskData, timeEstimate: +value })
                }
              />
            </CustomTableCell>

            <CustomTableCell />
            <CustomTableCell />
          </TableRow>
        )}
        {tasks.length ? (
          tasks.map((task: ProjectTask, i: number) => (
            <CustomTableRow
              clickable={editedTask !== task.id || disabledOnLoading}
              key={task.id}
              onClick={() => {
                if (editedTask !== task.id && !isCreating) {
                  setEditedTask(task.id);
                  setTaskData({
                    name: task.name,
                    taskStatusId: task.taskStatusId,
                    assigneeId: task?.assignee?.id,
                    startDateEstimate: task.startDateEstimate
                      ? new Date(task.startDateEstimate)
                      : undefined,
                    finishDateEstimate: task.finishDateEstimate
                      ? new Date(task.finishDateEstimate)
                      : undefined,
                    startDateFact: task.startDateFact
                      ? new Date(task.startDateFact)
                      : undefined,
                    finishDateFact: task.finishDateFact
                      ? new Date(task.finishDateFact)
                      : undefined,
                    timeEstimate: task.timeEstimate,
                    timeSpent: task.timeSpent,
                  });
                }
              }}
            >
              {tasks.length === i + 1 ? (
                <CustomTableCell
                  padding="none"
                  onClick={(e) => e.stopPropagation()}
                >
                  {editedTask === task.id ? (
                    <SaveIconButton
                      disabled={disabledOnLoading || isCreating}
                      save={() => updateTaskHandler(task.id)}
                    />
                  ) : (
                    <>
                      <SyncButton
                        disabled={disabledOnLoading}
                        onClick={() =>
                          syncTracker({ variables: { id: task.id } })
                        }
                      />

                      <DeleteIconButton
                        disabled={disabledOnLoading || isCreating}
                        deleteHandler={() =>
                          deleteTask({ variables: { id: task.id } })
                        }
                        item="задачу"
                      />
                    </>
                  )}
                </CustomTableCell>
              ) : (
                <CustomTableCellNoBottom
                  padding="none"
                  onClick={(e) => e.stopPropagation()}
                >
                  {editedTask === task.id ? (
                    <SaveIconButton
                      disabled={disabledOnLoading || isCreating}
                      save={() => updateTaskHandler(task.id)}
                    />
                  ) : (
                    <>
                      <SyncButton
                        disabled={disabledOnLoading}
                        onClick={() =>
                          syncTracker({ variables: { id: task.id } })
                        }
                      />
                      <DeleteIconButton
                        disabled={disabledOnLoading || isCreating}
                        deleteHandler={() =>
                          deleteTask({ variables: { id: task.id } })
                        }
                        item="задачу"
                      />
                    </>
                  )}
                </CustomTableCellNoBottom>
              )}

              <CustomTableCell padding="none">
                <CustomTableInput
                  fontSize={12}
                  disabled={
                    isCreating || editedTask !== task.id || disabledOnLoading
                  }
                  value={editedTask === task.id ? taskData.name : task.name}
                  onChange={({ target: { value } }) =>
                    setTaskData({ ...taskData, name: value })
                  }
                  error={errors.name}
                />
              </CustomTableCell>

              <CustomTableCell padding="none" fontSize={12}>
                <CustomTableSelect
                  fullWidth
                  fontSize={12}
                  disabled={
                    isCreating || editedTask !== task.id || disabledOnLoading
                  }
                  value={
                    editedTask === task.id
                      ? taskData.taskStatusId
                      : task.taskStatusId
                  }
                  onChange={({ target }) => {
                    if (typeof target.value === "string") {
                      setTaskData({ ...taskData, taskStatusId: target.value });
                    }
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {projectTaskStatuses?.length &&
                    projectTaskStatuses.map((status) => (
                      <MenuItem key={status.id} value={status.id}>
                        {translateBaseTaskStatuses(status.name)}
                      </MenuItem>
                    ))}
                </CustomTableSelect>
              </CustomTableCell>

              <CustomTableCell padding="none">
                <Autocomplete
                  fullWidth
                  disableClearable
                  options={assignees}
                  disabled={
                    isCreating || editedTask !== task.id || disabledOnLoading
                  }
                  getOptionLabel={(option) =>
                    option.lastName + " " + option.firstName
                  }
                  value={
                    editedTask === task?.id
                      ? assignees.find(
                          (assignee) => assignee.id === taskData.assigneeId
                        )
                      : assignees.find(
                          (assignee) => assignee.id === task?.assignee?.id
                        )
                  }
                  onChange={(e, value) => {
                    if (typeof value !== "string" && value?.id) {
                      setTaskData({ ...taskData, assigneeId: value.id });
                      setErrors({ ...errors, assigneeId: false });
                    }
                  }}
                  renderInput={(params) => (
                    <CustomTableTextfield
                      {...params}
                      fontSize={12}
                      error={errors.assigneeId}
                    />
                  )}
                />
              </CustomTableCell>

              <CustomTableCell padding="none">
                <EpicsDatePicker
                  disabled={
                    isCreating || editedTask !== task.id || disabledOnLoading
                  }
                  maxDate={
                    editedTask === task.id
                      ? taskData.finishDateEstimate
                      : new Date(task.finishDateEstimate)
                  }
                  value={
                    editedTask === task.id
                      ? taskData.startDateEstimate
                      : task.startDateEstimate
                        ? new Date(task.startDateEstimate)
                        : null
                  }
                  onChange={(date) =>
                    setTaskData({ ...taskData, startDateEstimate: date })
                  }
                  error={errors.startDateEstimate}
                />
              </CustomTableCell>

              <CustomTableCell padding="none">
                <EpicsDatePicker
                  disabled={
                    isCreating || editedTask !== task.id || disabledOnLoading
                  }
                  minDate={
                    editedTask === task.id
                      ? taskData.startDateEstimate
                      : new Date(task.startDateEstimate)
                  }
                  value={
                    editedTask === task.id
                      ? taskData.finishDateEstimate
                      : task.finishDateEstimate
                        ? new Date(task.finishDateEstimate)
                        : null
                  }
                  onChange={(date) =>
                    setTaskData({ ...taskData, finishDateEstimate: date })
                  }
                  error={errors.finishDateEstimate}
                />
              </CustomTableCell>

              <CustomTableCell padding="none">
                <EpicsDatePicker
                  disabled={
                    isCreating || editedTask !== task.id || disabledOnLoading
                  }
                  maxDate={
                    editedTask === task.id
                      ? taskData.finishDateFact
                      : task.finishDateFact
                        ? new Date(task.finishDateFact)
                        : undefined
                  }
                  value={
                    editedTask === task.id
                      ? taskData.startDateFact
                      : task.startDateFact
                        ? new Date(task.startDateFact)
                        : null
                  }
                  onChange={(date) =>
                    setTaskData({ ...taskData, startDateFact: date })
                  }
                />
              </CustomTableCell>

              <CustomTableCell padding="none">
                <EpicsDatePicker
                  disabled={
                    isCreating || editedTask !== task.id || disabledOnLoading
                  }
                  minDate={
                    editedTask === task.id
                      ? taskData.startDateFact
                      : task.startDateFact
                        ? new Date(task.startDateFact)
                        : undefined
                  }
                  value={
                    editedTask === task.id
                      ? taskData.finishDateFact
                      : task.finishDateFact
                        ? new Date(task.finishDateFact)
                        : null
                  }
                  onChange={(date) =>
                    setTaskData({ ...taskData, finishDateFact: date })
                  }
                />
              </CustomTableCell>

              <CustomTableCell padding="none">
                <CustomTableInput
                  type="number"
                  fontSize={12}
                  disabled={
                    isCreating || editedTask !== task.id || disabledOnLoading
                  }
                  value={
                    editedTask === task.id
                      ? Math.round(taskData.timeEstimate / 60)
                      : Math.round(task.timeEstimate / 60)
                  }
                  onChange={({ target: { value } }) =>
                    setTaskData({ ...taskData, timeEstimate: +value * 60 })
                  }
                />
              </CustomTableCell>

              <CustomTableCell padding="none">
                <CustomTableInput
                  type="number"
                  fontSize={12}
                  disabled={
                    isCreating || editedTask !== task.id || disabledOnLoading
                  }
                  value={
                    editedTask === task.id
                      ? (taskData.timeSpent / 3600).toFixed(2)
                      : (task.timeSpent / 3600).toFixed(2)
                  }
                  // onChange={({ target: { value } }) =>
                  //   setTaskData({ ...taskData, timeSpent: +value * 60 })
                  // }
                />
              </CustomTableCell>
              <CustomTableCell padding="none">
                {task.labels.length ?
                  task.labels.map((label) => (
                    <Label bgColor={label?.color}>
                      {(label.name === "bug" && "Баг") ||
                        (label.name === "additionalTask" && "Доп. задача") ||
                        label.name}
                    </Label>
                  )): 'Метки отсутствуют'}
              </CustomTableCell>
            </CustomTableRow>
          ))
        ) : (
          <TableRow>
            <CustomTableCell colSpan={11}>Задачи отсутствуют</CustomTableCell>
          </TableRow>
        )}
      </>
    );
  }

  console.log(taskData);

  return (
    <>
      {content}

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </>
  );
};
