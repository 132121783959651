import React from "react";
import { TextField } from "@material-ui/core";
import { ColorPicker } from "material-ui-color";
//import ColorPicker from "material-ui-color-picker";
import { SaveIconButton } from "../../shared/Buttons/SaveIconButton";
import {
  ButtonsContainer,
  ColorPickerContainer,
  ItemContainer,
} from "../KanbanSettings.style";
import { useStyles } from "../../shared/Style/Style";

interface NewItemProps {
  value: string;
  setValue(state: string): void;
  onSave(): void;
  label: string;
  color?: boolean;
  disabledSaveButton?: boolean;
}

export const NewItem = ({
  value,
  setValue,
  onSave,
  label,
  color,
                          disabledSaveButton
}: NewItemProps) => {
  const classes = useStyles();

  return (
    <ItemContainer>
      {color ? (
        <ColorPickerContainer disabled={false}>
          <ColorPicker
            value={value}
            defaultValue={'#FFF8F8'}
            onChange={(color) => setValue(color?.css.backgroundColor)}
          />
        </ColorPickerContainer>
      ) : (
        <TextField
          disabled={false}
          className={classes.root}
          fullWidth
          label={label}
          variant="filled"
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      )}

      <ButtonsContainer>
        <SaveIconButton disabled={disabledSaveButton} save={onSave} />
      </ButtonsContainer>
    </ItemContainer>
  );
};
