import React, { useEffect, useState } from "react";
import { SectionTitle } from "../SectionTitle/SectionTitle";
import { ItemsContainer, SectionContainer } from "../KanbanSettings.style";
import { useStyles } from "../../shared/Style/Style";
import { useMutation } from "@apollo/client";
import {
  CreateProjectTaskLabelForProjectDocument,
  CreateProjectTaskLabelForProjectMutation,
  GetProjectTaskLabelsByProjectDocument,
  ProjectTaskLabel,
} from "../../../generated/graphql";
import { Grid, TextField } from "@material-ui/core";
import { NewItem } from "../NewItem/NewItem";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { ProjectTaskLabelItem } from "./ProjectTaskLabelItem";

interface ProjectTaskLabelsSectionProps {
  projectId: string;
  projectTaskLabels: ProjectTaskLabel[];
}

export const ProjectTaskLabelsSection = ({
  projectId,
  projectTaskLabels,
}: ProjectTaskLabelsSectionProps) => {
  const classes = useStyles();
  const [requestError, setRequestError] = useState(false);

  const [isCreating, setIsCreating] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [labelColor, setLabelColor] = useState("");

  const [createProjectTaskLabel, { data, loading, error }] =
    useMutation<CreateProjectTaskLabelForProjectMutation>(
      CreateProjectTaskLabelForProjectDocument,
      {
        refetchQueries: [
          {
            query: GetProjectTaskLabelsByProjectDocument,
            variables: {
              projectId,
            },
          },
        ],
      }
    );

  useEffect(() => {
    if (data) {
      setIsCreating(false);
      setLabelName("");
      setLabelColor("");
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setRequestError(true);
    }
  }, [error]);

  const onSave = (): void => {
    createProjectTaskLabel({
      variables: {
        name: labelName,
        color: labelColor,
        projectId,
      },
    });
  };

  return (
    <SectionContainer>
      <SectionTitle
        title="Метки задач"
        onClickBtn={() => {
          setIsCreating(true);
        }}
      />

      {isCreating && (
        <>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextField
                disabled={false}
                className={classes.root}
                fullWidth
                label="название метки"
                variant="filled"
                type="text"
                value={labelName}
                onChange={(e) => setLabelName(e.target.value)}
              />
            </Grid>
            <Grid xs={6} item>
              <NewItem
                label="цвет"
                value={labelColor}
                setValue={setLabelColor}
                onSave={onSave}
                color
                disabledSaveButton={!labelName || !labelColor || loading}
              />
            </Grid>
          </Grid>
        </>
      )}

      <ItemsContainer>
        {projectTaskLabels.map((label) => (
          <ProjectTaskLabelItem key={label.id} label={label} projectId={projectId} />
        ))}
      </ItemsContainer>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={"Ошибка создания метки для задачи"}
      />
    </SectionContainer>
  );
};
