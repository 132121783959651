import React, { useEffect, useState } from "react";

import { useLazyQuery, useQuery } from "@apollo/client";
import { GetDashboardAddedTasksDocument, GetDashboardAddedTasksQuery, GetDashboardAddedTasksReportDocument, GetDashboardAddedTasksReportQuery } from "../../../../generated/graphql";

import { TableBody, TableRow, CircularProgress, Table } from "@material-ui/core";

import { CustomTable } from "../../../shared/CustomTable";
import { DropdownArrowButton } from "../../../shared/Buttons/DropdownArrowButton";
import { Dropdown } from "../../Dropdown";

import { LoadingBox } from "../../../shared/Style/Style";
import { CustomTableCell, CustomTableHead, CustomTableHeaderCell, CustomTableRow } from "../../../shared/CustomTable/CustomTable.style";

import { choseOrToggleIdHandler } from "../../../shared/Utils/StateOperations/choseOrToggleId";
import { IDashboardReportProps } from "../../Dashboard";


export const AddedTasks = ({ dateRange, projectId }: IDashboardReportProps) => {
  const [chosenProjectId, setChosenProjectId] = useState('');

  const [getTasks, {
    data: tasksData,
    loading: tasksLoading,
    error: tasksError,
  }] = useLazyQuery<GetDashboardAddedTasksQuery>(
    GetDashboardAddedTasksDocument, {
      fetchPolicy: 'cache-and-network'
    }
  );

  useEffect(() => {
    if (chosenProjectId) {
      getTasks({
        variables: {
          projectId: chosenProjectId,
          ...dateRange,
        }
      });
    }
  }, [chosenProjectId, dateRange.startDate, dateRange.endDate]);

  let tasksList: JSX.Element;

  if (tasksLoading) {
    tasksList = (
      <TableRow>
        <CustomTableCell colSpan={2}>
          <LoadingBox>
            <CircularProgress color='inherit'/>
          </LoadingBox>
        </CustomTableCell>
      </TableRow>
    )
  }

  if (tasksError) {
    tasksList = (
      <TableRow>
        <CustomTableCell colSpan={2}>
          Не удалось загрузить данные
        </CustomTableCell>
      </TableRow>
    )
  }

  if (tasksData) {
    const tasks = tasksData.getDashboardAddedTasks;

    tasksList = <Dropdown type='task' items={tasks} />
  }

  const { data, loading, error } = useQuery<GetDashboardAddedTasksReportQuery>(
    GetDashboardAddedTasksReportDocument, {
      variables: {
        projectId,
        ...dateRange
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  let content;

  if (loading) {
    content = (
      <TableBody>
        <TableRow>
          <CustomTableCell>
            <LoadingBox>
              <CircularProgress color='inherit'/>
            </LoadingBox>
          </CustomTableCell>
        </TableRow>
      </TableBody>
    )
  }

  if (error) {
    content = (
      <TableBody>
        <TableRow>
          <CustomTableCell>
            Не удалось загрузить данные
          </CustomTableCell>
        </TableRow>
      </TableBody>
    )
  }

  if (data) {
    const projects = data.getDashboardAddedTasksReport;

    content = (
      <TableBody>
        {projects.length
          ? projects.map(project => (
            <React.Fragment key={project.projectId}>
              <CustomTableRow
                clickable={true}
                onClick={() => choseOrToggleIdHandler(project.projectId, chosenProjectId, setChosenProjectId)}
              >
                <CustomTableCell width={100}>
                  {project.projectName}

                  <DropdownArrowButton expanded={project.projectId === chosenProjectId}/>
                </CustomTableCell>

                <CustomTableCell width={100}>
                  {project.tasksCount}
                </CustomTableCell>
              </CustomTableRow>

              {(project.projectId === chosenProjectId) && tasksList}
            </React.Fragment>

          ))
          : (
            <TableRow>
              <CustomTableCell>
                Не удалось загрузить данные
              </CustomTableCell>
            </TableRow>
          )
        }
      </TableBody>
    )
  }

  const table = (
    <Table>
      <CustomTableHead>
        <TableRow>
          <CustomTableHeaderCell colSpan={2} width={100}>
            Добавленные задачи
          </CustomTableHeaderCell>
        </TableRow>
      </CustomTableHead>

      {content}
    </Table>
  )

  return (
    <>
      <CustomTable data={data?.getDashboardAddedTasksReport} tableContent={table} height={247}/>
    </>
  )
}