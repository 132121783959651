import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Gantt, Task, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import {
  ChartData2,
  GetChartData2Document,
  GetChartData2Query,
  SetTaskBoundariesDocument,
  SetTaskBoundariesMutation,
} from "../../../generated/graphql";
import {
  getStartEndDateForProject,
  initTasks,
} from "../../shared/Utils/OtherOperations/initGanttTasks";
import { useLazyQuery, useMutation } from "@apollo/client";
import { LoadingBox } from "../../shared/Style/Style";
import { CircularProgress } from "@material-ui/core";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";
interface GanttTaskReactProps {
  epics: ChartData2[];
}

export const GanttTaskReact = ({ epics }: GanttTaskReactProps) => {
  const { search } = useLocation();
  const { projectId } = useParams<{ projectId: string }>();

  const [view, setView] = useState<ViewMode>(ViewMode.Day);
  const [tasks, setTasks] = useState<Task[]>(
    initTasks({ epics: epics, tasks: [] })
  );

  const [
    getTasks,
    { data: tasksData, loading: tasksLoading, error: tasksError },
  ] = useLazyQuery<GetChartData2Query>(GetChartData2Document);

  const [
    setTaskBoundaries,
    {
      data: taskBoundariesData,
      loading: taskBoundariesLoading,
      error: taskBoundariesError,
    },
  ] = useMutation<SetTaskBoundariesMutation>(SetTaskBoundariesDocument);

  useEffect(() => {
    search.includes("month") && setView(ViewMode.Month);
    search.includes("weeks") && setView(ViewMode.Week);
    search.includes("days") && setView(ViewMode.Day);
  }, [search]);

  useEffect(() => {
    if (tasksData) {
      const tasks = tasksData.getChartData2;
      setTasks(initTasks({ epics: epics, tasks: tasks }));
    }
  }, [tasksData]);

  let columnWidth = 60;

  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleTaskChange = (task: Task) => {
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));

    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);

      setTaskBoundaries({
        variables: {
          id: task.id,
          isEpic: false,
          startDateEstimate: task.start.toISOString(),
          finishDateEstimate: task.end.toISOString(),
        },
        refetchQueries: [
          {
            query: GetChartData2Document,
            variables: {
              projectId,
              isEpic: false,
              epicId: task.id,
            },
          },
        ],
      });

      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];

      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };

        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
    }

    setTasks(newTasks);
  };

  const handleExpanderClick = (task: Task) => {
    getTasks({
      variables: {
        projectId,
        isEpic: false,
        epicId: task.id,
      },
    });
    tasksData && setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  let content: JSX.Element;

  if (tasksLoading || taskBoundariesLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (tasksError || taskBoundariesError) {
    content = (
      <ErrorSnackbar
        error={
          (tasksError && "ошибка загрузки") ||
          (taskBoundariesError && "Ошибка при изменении даты")
        }
      />
    );
  }

  if (tasks?.length) {
    content = (
      <Gantt
        tasks={tasks}
        viewMode={view}
        locale="ru"
        onDateChange={handleTaskChange}
        onExpanderClick={handleExpanderClick}
        columnWidth={columnWidth}
        barFill={50}
      />
    );
  }

  return <>{content}</>;
};
