import {LessonBlockTypeEnum} from "../../../generated/graphql";

export const getLabel = (type) => {
  switch (type) {
    case LessonBlockTypeEnum.Header:
      return "Заголовок"

    case LessonBlockTypeEnum.Subtitle:
      return "Подзаголовок"

    case LessonBlockTypeEnum.Text:
      return 'Текст'

  }
}


