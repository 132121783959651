import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/client";
import {
  CreateApplicationMutation,
  CreateApplicationDocument,
  GetApplicationsDocument,
  CreateApplicationMainDocument,
  GetApplicationsMainDocument,
  CreateApplicationMainMutation,
} from "../../../generated/graphql";

import { Dialog, DialogContent, DialogTitle, Box } from "@material-ui/core";

import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";

import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
  ModalInput,
} from "../../shared/Style/Style";
import { LanguageSwitch } from "../../shared/LanguageSwitch/LanguageSwitch";
import { languages } from "../../../constants/language";

interface IMobileAppCreateModalProps {
  open: boolean;
  close(): void;
  refetchVariables: {
    limit: number;
    skip: number;
    applicationId?: string;
    isPublished?: boolean;
  };
  entity: string;
}

const InitialErrors: { [key: string]: boolean } = {
  name: false,
};

const InitialNames: { [key: string]: string } = {
  name: "",
};

export const MobileAppCreateModal = ({
  open,
  close,
  refetchVariables,
  entity,
}: IMobileAppCreateModalProps) => {
  const history = useHistory();

  const [names, setNames] = useState(InitialNames);
  const [namesErrors, setNamesErrors] = useState(InitialErrors);
  const [language, setLanguage] = useState<string>("UKR");

  const [requestError, setRequestError] = useState(false);

  const [
    createApp,
    { data: createAppData, loading: createAppLoading, error: createAppError },
  ] = useMutation<CreateApplicationMutation>(CreateApplicationDocument, {
    refetchQueries: [
      { query: GetApplicationsDocument, variables: refetchVariables },
    ],
  });

  const [
    createPage,
    {
      data: createPageData,
      loading: createPageLoading,
      error: createPageError,
    },
  ] = useMutation<CreateApplicationMainMutation>(
    CreateApplicationMainDocument,
    {
      refetchQueries: [
        { query: GetApplicationsMainDocument, variables: refetchVariables },
      ],
    }
  );

  const closeHandler = () => {
    setNames({
      name: "" 
    });
    setNamesErrors({
      name: false
    });
    setRequestError(false);
    close();
  };

  const createHandler = () => {
    if (!names.name) {
      setNamesErrors({ name: true });
      return;
    }

    entity === "приложения"
      ? createApp({
          variables: {
            applicationData: {
              name: names.name,
            },
          },
        })
      : createPage({
          variables: {
            applicationMainData: {
              name: names.name,
              applicationId: refetchVariables?.applicationId,
            },
          },
        });
  };

  //#region

  useEffect(() => {
    if (createPageError) {
      setRequestError(true);
    }
  }, [createPageError]);

  useEffect(() => {
    if (createPageData) {
      history.push(
        `${history.location.pathname}/${createPageData.createApplicationMain.id}/edit`
      );
      closeHandler();
    }
  }, [createPageData]);

  useEffect(() => {
    if (createAppError) {
      setRequestError(true);
    }
  }, [createAppError]);

  useEffect(() => {
    if (createAppData) {
      history.push(`/mobileApps/${createAppData.createApplication.id}/edit`);
      closeHandler();
    }
  }, [createAppData]);

  //#endregion

  const disableOnLoading = createPageLoading || createAppLoading;

  return (
    <Dialog open={open}>
      <DialogContent style={{ minWidth: "400px" }}>
        <CloseIconButton close={closeHandler} />

        <Box textAlign="center" mb={2}>
          <DialogTitle>Создание {entity}</DialogTitle>
        </Box>

        <Box>
          <ModalInput
            fullWidth
            label={`Название ${entity}`}
            value={names.name}
            onChange={({ target: { value } }) =>
              setNames({ name: value })
            }
            onFocus={() => setNamesErrors({ name: false})}
            error={namesErrors.name}
            disabled={disableOnLoading}
          />
        </Box>

        {entity !== "приложения" && (
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <LanguageSwitch
              switchLanguage={setLanguage}
              languages={languages}
              error={namesErrors}
              disabled={disableOnLoading}
            />
          </Box>
        )}

        <ButtonsWrapper>
          <CancelButton onClick={closeHandler} disabled={disableOnLoading}>
            Отмена
          </CancelButton>

          <ButtonCustom onClick={createHandler} disabled={disableOnLoading}>
            Создать
          </ButtonCustom>
        </ButtonsWrapper>
      </DialogContent>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={`При создании ${entity} произошла ошибка`}
      />
    </Dialog>
  );
};
