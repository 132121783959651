import styled from "styled-components";
import { TableHead } from "@material-ui/core";

export const HeaderContainer = styled.div`
  position: relative;
`;
export const CustomTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const GanttContainer = styled.div`
  width: 100%;
`;
