import { ButtonPlus, Header } from "../../shared/Style/Style";
import { Box, Container } from "@material-ui/core";
import { NavBar } from "../../shared/NavBar";
import { navItems } from "../../../constants/navItemMobileApp";
import React from "react";

// TODO: reuse this component for tabs: ОСНОВНАЯ ИНФОРМАЦИЯ УВЕДОМЛЕНИЯ ГЛАВНЫЕ СТРАНИЦЫ ПРИЛОЖЕНИЯ ВЕРСИИ ОПРОСЫ НАГРАДЫ
interface ContainerWithNavBarProps {
  header: string;
  children: JSX.Element | JSX.Element[];
  addButton?: {
    handler: () => void;
    isDisabled: boolean;
  };
}
export const ContainerWithNavBar = ({
  header,
  children,
  addButton,
}: ContainerWithNavBarProps) => {
  return (
    <Container maxWidth="lg">
      <Header>{header}</Header>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        mt={"40px"}
      >
        <NavBar items={navItems} />

        {addButton && (
          <ButtonPlus
            variant="contained"
            disabled={addButton.isDisabled}
            onClick={addButton.handler}
          >
            Добавить
          </ButtonPlus>
        )}
      </Box>

      {children}
    </Container>
  );
};
