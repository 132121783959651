import { FC } from "react";
import { useReactiveVar } from "@apollo/client";
import { robotsState } from "../../state";
import {
    EditStageButton,
    EditStageBundle,
    EditRobotContainer as EditStageContainer,
    EditRobotForm as EditStageForm,
    EditRobotTitle as EditStageTitle,
    EditStageContent,
} from "../../style";
import { ButtonCustom } from "../../../../shared/Style/Style";
import { PhotoPicker as EditStagePhoto } from "../../../../shared/PhotoPicker";
import { StageGeneralInfo } from "../StageGeneralInfo";
import { StageBundleBlock } from "../StageBundleBlock";
import { StageArtifactsBlock } from "../StageArtifactsBlock";

interface StageEditBlockProps {
    updateStageGeneralInfo: () => void;
    uploadStagePhoto: (file: File) => void;
}

export const StageEditBlock: FC<StageEditBlockProps> = ({
    updateStageGeneralInfo,
    uploadStagePhoto,
}) => {
    const stage = useReactiveVar(robotsState.stageGeneralInfo);
    const isLoading = useReactiveVar(robotsState.isLoading);

    return (
        <EditStageContainer elevation={6}>
            <div>
                <EditStageTitle>Основная информация</EditStageTitle>
                
                <EditStageContent>
                    <EditStagePhoto
                        key={stage?.id}
                        photo={stage?.image}
                        setNewPhoto={uploadStagePhoto}
                    />
                    
                    <EditStageForm>
                        <StageGeneralInfo />

                        <EditStageButton>
                            <ButtonCustom
                                disabled={isLoading}
                                onClick={updateStageGeneralInfo}
                            >
                                СОХРАНИТЬ
                            </ButtonCustom>
                        </EditStageButton>                  
                    </EditStageForm >
                    
                    <EditStageBundle>
                        <EditStageTitle>Bundle, manifest</EditStageTitle>
    
                        <StageBundleBlock />
                    </EditStageBundle>
                </EditStageContent>
            </div>
            
            <div>
                <EditStageTitle>Одежда и анимации</EditStageTitle>
                
                <StageArtifactsBlock />
            </div>
        </EditStageContainer>
    );
};
