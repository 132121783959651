import styled from "styled-components";
import {
  lightBlack,
  MainTableRow,
} from "../shared/Style/Style";

export const SitesHeaderTableRow = styled(MainTableRow)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  position: relative;
`;

export const SitesTableRow = styled(MainTableRow)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 50px;
  //cursor: pointer;
  position: relative;
`;

export const EmptyTableRow = styled(SitesTableRow)`
  grid-template-columns: 1fr;
`;

export const SiteLink = styled.a`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${lightBlack};
  cursor: pointer;
`;
