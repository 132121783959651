import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  GetClientSiteModelByItemIdDocument,
  GetClientSiteModelByItemIdQuery,
} from "../../generated/graphql";
import QRCode from "react-qr-code";
import { Box, CircularProgress } from "@material-ui/core";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import {
  ImgContainer,
  LogoLinkContainer,
  PageContainer,
} from "./ModelIFrame.style";
import { LoadingBox } from "../shared/Style/Style";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "model-viewer": any;
    }
  }
}

const FlexRealityLogoIcon = () => {
  return (
    <Box m="auto 0">
      <svg
        width="40"
        height="30"
        viewBox="0 0 199 191"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M198.808 95.5C198.808 148.243 154.303 191 99.4038 191C44.5046 191 0 148.243 0 95.5C0 42.7568 44.5046 0 99.4038 0C154.303 0 198.808 42.7568 198.808 95.5Z"
          fill="#FF7600"
        />
        <path
          d="M137.418 155.021H101.338L75.5677 116.773L49.7968 155.021H13.54L54.951 96.6242L16.206 41.1305H52.2851L75.5677 76.8172L98.4947 41.1305H134.752L96.1842 96.4534L137.418 155.021Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};

export const ModelIFrame = () => {
  const { search, pathname } = useLocation();

  const device = window.navigator.userAgent;
  const isMobile =
    device.includes("Android") ||
    device.includes("iPhone") ||
    device.includes("iPad");

  const [requestError, setRequestError] = useState(false);
  const itemId = search.split("=")[1];
  const siteKey = pathname.split("/")[pathname.split("/").length - 1];

  const {
    data: modelData,
    loading: modelLoading,
    error: modelError,
  } = useQuery<GetClientSiteModelByItemIdQuery>(
    GetClientSiteModelByItemIdDocument,
    {
      variables: {
        itemId,
        siteKey,
      },
    }
  );

  useEffect(() => {
    if (modelError) {
      setRequestError(true);
    }
  }, [modelError]);

  return (
    <>
      {!modelLoading ? (
        <>
          <PageContainer>
            {!isMobile && (
              <Box mt={4} position="relative" height="90vh">
                <Box
                  textAlign="center"
                  maxWidth="256px"
                  fontWeight="bold"
                  mb="20px"
                >
                  Для перехода к модели отсканируйте QR код с помощью камеры
                  смартфона
                </Box>

                <QRCode
                  style={{ marginBottom: "20px" }}
                  value={`https://lk.flexreality.pro/iframes/${siteKey}?itemId=${itemId}`}
                />

                <LogoLinkContainer
                  b="0"
                  l="0"
                  r="0"
                  href="https://flexreality.pro/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FlexRealityLogoIcon />
                </LogoLinkContainer>
              </Box>
            )}
          </PageContainer>

          {isMobile && (
            <Box position="relative" height="100vh">
              <ImgContainer>
                <model-viewer
                  alt="Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum"
                  src={modelData?.getClientSiteModelByItemId?.glbFile}
                  ios-src={modelData?.getClientSiteModelByItemId?.usdzFile}
                  ar
                  ar-mode
                  ar-modes="webxr scene-viewer quick-look"
                  shadow-intensity="1"
                  auto-totate
                  camera-controls
                >
                  <button
                    slot="ar-button"
                    style={{
                      backgroundColor: "#FF7B05",
                      width: "max-content",
                      color: "#fff",
                      fontWeight: "bold",
                      borderRadius: "4px",
                      fontSize: "14px",
                      padding: "5px 30px",
                      textTransform: "uppercase",
                      fontFamily: "'Montserrat', sans-serif",
                      border: "none",
                      position: "absolute",
                      bottom: "0",
                      marginTop: "10px",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    Попробовать в AR
                  </button>
                </model-viewer>
              </ImgContainer>

              <LogoLinkContainer
                b="0"
                l="0"
                href="https://flexreality.pro/"
                target="_blank"
                rel="noreferrer"
              >
                <FlexRealityLogoIcon />
              </LogoLinkContainer>
            </Box>
          )}

          <ErrorMessageSnackbar
            open={requestError}
            closeHandler={() => setRequestError(false)}
            message={"Ошибка при получении модели"}
          />
        </>
      ) : (
        <LoadingBox>
          <CircularProgress color="inherit" />
        </LoadingBox>
      )}
    </>
  );
};
