import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  GetSchoolCitiesDocument,
  GetSchoolCitiesQuery,
  School,
} from "../../../generated/graphql";
import { LoadingBox } from "../../shared/Style/Style";
import { CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { UserFields } from "../Style";

interface UserCitiesSelectProps {
  getAllItemsParams: {
    limit: number;
    skip: number;
  };
  regionId: string;
  cityId: string;
  schools: School[];

  setMobileUsers(cityId: string): void;
}

export const UserCitiesSelect = ({
  getAllItemsParams,
  regionId,
  cityId,
  setMobileUsers,
  schools,
}: UserCitiesSelectProps) => {
  const {
    data: getSchoolCitiesData,
    loading: getSchoolCitiesLoading,
    error: getSchoolCitiesError,
  } = useQuery<GetSchoolCitiesQuery>(GetSchoolCitiesDocument, {
    variables: {
      ...getAllItemsParams,
      regionId,
    },
  });

  if (getSchoolCitiesLoading) {
    return (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  const schoolCities = getSchoolCitiesData?.getSchoolCities ?? [];

  const disabledOnLoading =
    getSchoolCitiesLoading ||
    !!getSchoolCitiesError ||
    !regionId ||
    !!schools.length;

  return (
    <Autocomplete
      options={schoolCities.map((schoolCity) => schoolCity.id)}
      getOptionLabel={(option) =>
        schoolCities.find((schoolCity) => schoolCity.id === option)?.name || ""
      }
      onChange={(e, value) => setMobileUsers(value)}
      value={
        schoolCities.find((schoolCity) => schoolCity.id === cityId)?.id || null
      }
      disabled={disabledOnLoading}
      renderInput={(params) => (
        <UserFields {...params} label="Населённый пункт" variant="standard" />
      )}
    />
  );
};
