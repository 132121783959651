import { gql } from "@apollo/client";

export const GET_SMART_NOTIFICATIONS = gql`
  query getSmartNotifications(
    $limit: Int
    $skip: Int
    $isPublished: Boolean
    $type: SmartNotificationTypeEnum
  ) {
    getSmartNotifications(
      limit: $limit
      skip: $skip
      isPublished: $isPublished
      type: $type
    ) {
      notifications {
        id
        description
        link
        isPublished
        createdDate
        photo
        photoName
        applicationMainModelId
      }
      total
    }
  }
`;

export const GET_SMART_NOTIFICATION = gql`
  query getSmartNotification($id: String) {
    getSmartNotification(id: $id) {
      id
      description
      photo
      photoName
      pollLists {
        id
        name
      }
      participants {
        user {
          id
        }
      }
    }
  }
`;

export const GET_SMART_NOTIFICATION_BY_ID = gql`
  query getSmartNotificationById($id: String, $contentType: ContentTypeInput) {
    getSmartNotification(id: $id, contentType: $contentType) {
      id
      type
      description
      isPublished
      createdDate
      pollLists {
        id
      }
      applicationMainModelId
    }
  }
`;

export const GET_ANSWERS_WITH_PERCENTS_BY_SMART_NOTIFICATION_ID = gql`
  query getAnswersWithPercentsBySmartNotificationId(
    $smartNotificationId: String!
  ) {
    getAnswersWithPercentsBySmartNotificationId(
      smartNotificationId: $smartNotificationId
    ) {
      total
      answers {
        total
        pollListId
        ratting
        percents
      }
    }
  }
`;

export const GET_ANSWERS_BY_SMART_NOTIFICATION_ID = gql`
  query getAnswersBySmartNotificationId($smartNotificationId: String!) {
    getAnswersBySmartNotificationId(smartNotificationId: $smartNotificationId) {
      id
      ratting
      createdBy {
        id
        firstName
        lastName
        middleName
        email
        phone
        birthday
        deletedDate
        city {
          name
        }
        role {
          role
          displayName
        }
      }
      createdDate
    }
  }
`;

export const GET_ANSWERS_BY_SMART_NOTIFICATION_POLL_LIST_ID = gql`
  query getAnswersBySmartNotificationPollListId($pollListId: String!) {
    getAnswersBySmartNotificationPollListId(pollListId: $pollListId) {
      id
      ratting
    }
  }
`;

export const GET_RATING_ANSWER_COUNT_BY_SMART_NOTIFICATION_ID = gql`
  query getRatingAnswersCountBySmartNotificationId(
    $smartNotificationId: String!
  ) {
    getRatingAnswersCountBySmartNotificationId(
      smartNotificationId: $smartNotificationId
    )
  }
`;

export const GET_USERS_BY_SMART_NOTIFICATION_ANSWER = gql`
  query getUsersBySmartNotificationAnswer(
    $smartNotificationId: String!
    $ratting: Int
    $pollListId: String
  ) {
    getUsersBySmartNotificationAnswer(
      smartNotificationId: $smartNotificationId
      ratting: $ratting
      pollListId: $pollListId
    ) {
      users {
        id
        firstName
        lastName
        photo
      }
      total
    }
  }
`;

export const GET_USER_BY_SMART_NOTIFICATION_ANSWER = gql`
  query getUserBySmartNotificationAnswer(
    $smartNotificationId: String!
    $ratting: Int
  ) {
    getUsersBySmartNotificationAnswer(
      smartNotificationId: $smartNotificationId
      ratting: $ratting
    ) {
      users {
        id
        firstName
        lastName
        middleName
        phone
        email
        birthday
        role {
          displayName
        }
        city {
          name
        }
      }
      total
    }
  }
`;
