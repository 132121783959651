import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  GetKanbanWorkflowStepsByProjectDocument,
  GetKanbanWorkflowStepsByProjectQuery,
  GetProjectKanbanDocument,
  GetProjectKanbanQuery,
  GetProjectTaskLabelsByProjectDocument,
  GetProjectTaskLabelsByProjectQuery,
  GetProjectTaskStatusesByProjectDocument,
  GetProjectTaskStatusesByProjectQuery,
} from "../../generated/graphql";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
} from "@material-ui/core";
import { StatusesSection } from "./StatusesSection/StatusesSection";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { Header, Title } from "../Main/Project/Projects.style";
import { LoadingBox } from "../shared/Style/Style";
import { WorkflowStepsSection } from "./WorkflowStepsSection/WorkflowSteps";
import { ProjectTaskLabelsSection } from "./ProjectTaskLabelsSection/ProjectTaskLabelsSection";

export const KanbanSettings = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [requestError, setRequestError] = useState(false);

  const {
    data: projectTaskStatusesData,
    loading: projectTaskStatusesLoading,
    error: projectTaskStatusesError,
  } = useQuery<GetProjectTaskStatusesByProjectQuery>(
    GetProjectTaskStatusesByProjectDocument,
    {
      variables: {
        projectId,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const { data: kanbanData } = useQuery<GetProjectKanbanQuery>(
    GetProjectKanbanDocument,
    {
      variables: {
        projectId,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const {
    data: columnsData,
    loading: columnLoading,
    error: columnError,
  } = useQuery<GetKanbanWorkflowStepsByProjectQuery>(
    GetKanbanWorkflowStepsByProjectDocument,
    {
      variables: {
        projectId,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const {
    data: projectTaskLabelsData,
    loading: projectTaskLabelLoading,
    error: projectTaskLabelError,
  } = useQuery<GetProjectTaskLabelsByProjectQuery>(
    GetProjectTaskLabelsByProjectDocument,
    {
      variables: {
        projectId,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    if (projectTaskStatusesError || columnError || projectTaskLabelError) {
      setRequestError(true);
    }
  }, [projectTaskStatusesError, columnError, projectTaskLabelError]);

  let content: JSX.Element;

  if (columnLoading || projectTaskStatusesLoading || projectTaskLabelLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (projectTaskStatusesData && columnsData && projectTaskLabelsData) {
    const projectTaskStatuses =
      projectTaskStatusesData.getProjectTaskStatusesByProject;
    const workflowSteps = columnsData.getKanbanWorkflowStepsByProject;

    const projectTaskLabels =
      projectTaskLabelsData.getProjectTaskLabelsByProject;

    content = (
      <Grid container justifyContent="center" spacing={2}>
        <Grid xs={6} item>
          <StatusesSection
            projectTaskStatuses={projectTaskStatuses}
            projectId={projectId}
          />

          <WorkflowStepsSection
            workflowSteps={workflowSteps}
            projectId={projectId}
            projectTaskStatuses={projectTaskStatuses}
          />
        </Grid>

        <Grid xs={6} item>
          <ProjectTaskLabelsSection
            projectTaskLabels={projectTaskLabels}
            projectId={projectId}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Container maxWidth="lg" style={{ position: "relative" }}>
      <Header>
        <Box display="flex" justifyContent="center" width="100%">
          <Title>Настройки доски</Title>
        </Box>
      </Header>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: "16px" }}>{content}</Paper>
        </Grid>
      </Grid>

      {projectTaskStatusesError && (
        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={"ошибка загрузки статусов"}
        />
      )}
      {columnError && (
        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={"ошибка загрузки колонок"}
        />
      )}
      {projectTaskLabelError && (
        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={"ошибка загрузки меток"}
        />
      )}
    </Container>
  );
};
