import React from "react";
import { useQuery } from "@apollo/client";
import {
  GetUserBySmartNotificationAnswerDocument,
  GetUserBySmartNotificationAnswerQuery,
  User,
} from "../../generated/graphql";
import * as XLSX from "xlsx";
import { CustomIconButton } from "../shared/Buttons/CustomIconButton";
import { GetApp } from "@material-ui/icons";
import { LoadingBox } from "../shared/Style/Style";
import { CircularProgress } from "@material-ui/core";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";

interface IExportAllUsers {
  smartNotificationId: string;
  ratting: number;
  total: number;
}

export const ExportAllUsers = ({
  smartNotificationId,
  ratting,
  total,
}: IExportAllUsers) => {

  const {data, loading, error} =
    useQuery<GetUserBySmartNotificationAnswerQuery>(
      GetUserBySmartNotificationAnswerDocument,
      {
        variables: {
          smartNotificationId,
          ratting,
        },
      }
    );

  const downloadExelHandler = (data) => {
    const worksheet = XLSX.utils.json_to_sheet( data );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet( workbook, worksheet, "FileUsers" );
    XLSX.writeFile( workbook, `user_${ratting}_points.xlsx` );
  };
  let content;

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit"/>
      </LoadingBox>
    );
  }
  if (error) {
    content = <ErrorSnackbar error={error}/>;
  }

  if (data) {
    const users = data.getUsersBySmartNotificationAnswer.users;

    const getUser = users.map( (user) => {
      return {
        firstName: user?.firstName,
        lastName: user?.lastName,
        middleName: user?.middleName,
        email: user?.email,
        phone: user?.phone,
        birthday: user?.birthday,
        role: user?.role?.displayName,
        city: user?.city?.name,
      };
    } );

    content = (
      <>
        <CustomIconButton
          disabled={!total}
          callback={() => downloadExelHandler( getUser )}
          mainColor="black"
          children={<GetApp/>}
        />
      </>
    );
  }

  return <div>{content}</div>;
};
