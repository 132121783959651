import styled from "styled-components";


export const ImageContent = styled.img<{ src: string }>`
  margin: 0 auto;
  width: 250px;
  height: 250px;
  overflow: hidden;
  img {
    ${({ src }) => `content: url(${src})`};
    overflow: hidden;
    width: 100px;
    height: 100px;
  }
`;