import { SvgIcon } from "@material-ui/core";
import React from "react";

export const DragNDropIcon = () => (
  <SvgIcon width="10" height="17" viewBox="0 0 10 17">
    <path
      d="M1.6998 2.5998C2.19686 2.5998 2.5998 2.19686 2.5998 1.6998C2.5998 1.20275 2.19686 0.799805 1.6998 0.799805C1.20275 0.799805 0.799805 1.20275 0.799805 1.6998C0.799805 2.19686 1.20275 2.5998 1.6998 2.5998Z"
      fill="#1F1F1F"/>
    <path
      d="M8.29981 2.5998C8.79686 2.5998 9.19981 2.19686 9.19981 1.6998C9.19981 1.20275 8.79686 0.799805 8.29981 0.799805C7.80275 0.799805 7.39981 1.20275 7.39981 1.6998C7.39981 2.19686 7.80275 2.5998 8.29981 2.5998Z"
      fill="#1F1F1F"/>
    <path
      d="M8.29981 9.1998C8.79686 9.1998 9.19981 8.79686 9.19981 8.2998C9.19981 7.80275 8.79686 7.3998 8.29981 7.3998C7.80275 7.3998 7.39981 7.80275 7.39981 8.2998C7.39981 8.79686 7.80275 9.1998 8.29981 9.1998Z"
      fill="#1F1F1F"/>
    <path
      d="M1.6998 9.1998C2.19686 9.1998 2.5998 8.79686 2.5998 8.2998C2.5998 7.80275 2.19686 7.3998 1.6998 7.3998C1.20275 7.3998 0.799805 7.80275 0.799805 8.2998C0.799805 8.79686 1.20275 9.1998 1.6998 9.1998Z"
      fill="#1F1F1F"/>
    <path
      d="M8.29981 15.7998C8.79686 15.7998 9.19981 15.3969 9.19981 14.8998C9.19981 14.4027 8.79686 13.9998 8.29981 13.9998C7.80275 13.9998 7.39981 14.4027 7.39981 14.8998C7.39981 15.3969 7.80275 15.7998 8.29981 15.7998Z"
      fill="#1F1F1F"/>
    <path
      d="M1.6998 15.7998C2.19686 15.7998 2.5998 15.3969 2.5998 14.8998C2.5998 14.4027 2.19686 13.9998 1.6998 13.9998C1.20275 13.9998 0.799805 14.4027 0.799805 14.8998C0.799805 15.3969 1.20275 15.7998 1.6998 15.7998Z"
      fill="#1F1F1F"/>
    <path
      d="M1.6998 2.5998C2.19686 2.5998 2.5998 2.19686 2.5998 1.6998C2.5998 1.20275 2.19686 0.799805 1.6998 0.799805C1.20275 0.799805 0.799805 1.20275 0.799805 1.6998C0.799805 2.19686 1.20275 2.5998 1.6998 2.5998Z"
      stroke="#1F1F1F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M8.29981 2.5998C8.79686 2.5998 9.19981 2.19686 9.19981 1.6998C9.19981 1.20275 8.79686 0.799805 8.29981 0.799805C7.80275 0.799805 7.39981 1.20275 7.39981 1.6998C7.39981 2.19686 7.80275 2.5998 8.29981 2.5998Z"
      stroke="#1F1F1F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M8.29981 9.1998C8.79686 9.1998 9.19981 8.79686 9.19981 8.2998C9.19981 7.80275 8.79686 7.3998 8.29981 7.3998C7.80275 7.3998 7.39981 7.80275 7.39981 8.2998C7.39981 8.79686 7.80275 9.1998 8.29981 9.1998Z"
      stroke="#1F1F1F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M1.6998 9.1998C2.19686 9.1998 2.5998 8.79686 2.5998 8.2998C2.5998 7.80275 2.19686 7.3998 1.6998 7.3998C1.20275 7.3998 0.799805 7.80275 0.799805 8.2998C0.799805 8.79686 1.20275 9.1998 1.6998 9.1998Z"
      stroke="#1F1F1F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M8.29981 15.7998C8.79686 15.7998 9.19981 15.3969 9.19981 14.8998C9.19981 14.4027 8.79686 13.9998 8.29981 13.9998C7.80275 13.9998 7.39981 14.4027 7.39981 14.8998C7.39981 15.3969 7.80275 15.7998 8.29981 15.7998Z"
      stroke="#1F1F1F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M1.6998 15.7998C2.19686 15.7998 2.5998 15.3969 2.5998 14.8998C2.5998 14.4027 2.19686 13.9998 1.6998 13.9998C1.20275 13.9998 0.799805 14.4027 0.799805 14.8998C0.799805 15.3969 1.20275 15.7998 1.6998 15.7998Z"
      stroke="#1F1F1F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </SvgIcon>
)