import React from "react";
import { TablePagination } from "@material-ui/core";
import { CustomTablePaginationContainer } from "./CustomTablePagination.styles";

interface CustomTablePaginationProps {
  rowsPerPageOptions: number[];
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange(event: unknown, newPage: number): void;
  onRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

export const CustomTablePagination = ({
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}: CustomTablePaginationProps) => {
  return (
    <CustomTablePaginationContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        count={count}
        component={"div"}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </CustomTablePaginationContainer>
  );
};
