import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import imageCompression from 'browser-image-compression';
import { acceptedImageTypes } from '../constants';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

export const DropzoneContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const compressOptions = {
  maxSizeMB: 0.3,
  maxWidthOrHeight: 1920,
};

export function StyledDropzone(props: any) {
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: acceptedImageTypes,
    onDrop: async acceptedFiles => {
      const imageFile = acceptedFiles[0];
      const compressedImageFile = await imageCompression(imageFile, compressOptions);
      props.onDrop(compressedImageFile);
    },
  });

  return (
    <div className="container">
      <DropzoneContainer className="dropzone-instance" {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        {props.dropzoneContent}
      </DropzoneContainer>
    </div>
  );
}
