import React from "react";
import { FieldValueGetter } from "@apollo/client/cache/inmemory/entityStore";
import { FieldInputProps, FieldMetaState } from "react-final-form";
import { User } from "../../../generated/graphql";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

interface DictionaryProjectRiskLevelProps {
  label: string;
  values: number[];
  input: FieldInputProps<User>;
  meta: FieldMetaState<FieldValueGetter>;
  className: string;
}

export const DictionaryProjectRiskLevel = ({
  label,
  values,
  input,
  meta,
  className,
}: DictionaryProjectRiskLevelProps) => {
  return (
    <FormControl
      className={className}
      variant="filled"
      error={meta.error && meta.touched}
      fullWidth
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={input.value}
        onChange={(e) => {
          input.onChange(+e.target.value);
        }}
        fullWidth
      >
        {values?.map((value, index) => {
          return (
            <MenuItem key={index} value={value.toString()}>
              {value}
            </MenuItem>
          );
        })}
      </Select>
      {meta.error && meta.touched && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};
