import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetUserDocument,
  GetUserOptionFieldsDocument,
  UpdateUserDocument,
  GetUserOptionFieldsQuery,
  UpdateUserMutation,
  User,
  UserOptionFields,
} from "../../../../generated/graphql";
import { Form } from "react-final-form";
import { Grid, Snackbar, IconButton, Box } from "@material-ui/core";
import { Error } from '@material-ui/icons';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ButtonCustom, useStylesSnackbar } from "../../../shared/Style/Style";
import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { BaseSocial } from "./BaseSocial";
import { BaseDateCard } from "./BaseDateCard";
import { BaseRateSalary } from "./BaseRateSalary";
import { BasePhone } from "./BasePhone";
import { BaseFields } from "./BaseFields";
import { BaseCheckBoxes } from "./BaseCheckBoxes";
import { BaseStatus } from "./BaseStatus";
import { useStyles } from "../../../Login";
import { Alert } from "@material-ui/lab";

interface UserEditFormProps {
  user: User;
  setUserRoleId(id: number): void;
}

export const UserEditForm = ({ user, setUserRoleId }: UserEditFormProps) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isUserSalaryError, setUserSalaryError] = useState(false);

  const {
    data: dataOptions,
    loading: loadingOptions,
    error: errorOptions,
  } = useQuery<GetUserOptionFieldsQuery>(GetUserOptionFieldsDocument);

  const [updateUser, { data, loading: loadingUpdate, error: updateUserError }] =
    useMutation<UpdateUserMutation>(UpdateUserDocument, {
      refetchQueries: [
        {
          query: GetUserDocument,
          variables: {
            userId: user?.id,
          },
        },
      ],
    });

  const options = dataOptions?.getUserOptionFields as UserOptionFields;
  const classes = useStyles();
  const classesSnackbar = useStylesSnackbar();

  const onSubmitForm = async (values: User): Promise<void> => {
    const {
      cart,
      trackerId,
      job,
      creditCard,
      loadPercent,
      previousSalary,
      status,
      role,
      position,
      projects,
      groups,
      photo,
      __typename,
      agreementType,
      department,
      agreement,
      city,
      isRemote,
      updatedBy,
      rate,
      salary,
      trackerContracts,
      ...managerData
    } = values;

    const {
      oneCId,
      inn,
      hireDate,
      drfo,
      diploma,
      passport,
      idCard,
      excerpt,
      contractType,
      address,
      addressReal,
      promotionReason,
      promotionDate,
      instagram,
      cvs,
      nda,
      bonuses,
      ...clientData
    } = managerData;

    const userData =
      user?.role?.id !== 3
        ? {
            ...managerData,
            statusId: status?.id,
            creditCard,
            agreementTypeId: agreementType?.id,
            departmentId: department?.id,
            agreementId: agreement?.id,
            cityId: city,
            roleId: role?.id,
            jobId: job?.id,
            positionId: position?.id,
            loadPercent: Number(loadPercent),
            excerpt: idCard ? excerpt : false,
          }
        : { ...clientData };

    await updateUser({
      variables: {
        userData,
        photo: typeof values?.photo === "string" ? null : values?.photo,
      },
    });
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (data?.updateUser) {
      setOpenSnackbar(true);
    }
  }, [data]);

  if (loadingOptions || loadingUpdate) {
    return (
      <Backdrop
        className={classes.backdrop}
        open={loadingOptions || loadingUpdate}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (errorOptions || updateUserError) {
    return <ErrorSnackbar error={errorOptions} />;
  }

  if (dataOptions) {
    return (
      <>
        <Form
          onSubmit={onSubmitForm}
          initialValues={{
            ...user,
            city: user?.city?.id,
            salary: user?.salary,
            previousSalary: user?.previousSalary ? user.previousSalary : "",
            loadPercent: user?.loadPercent ? user.loadPercent : "",
            creditCard: user?.creditCard ? user.creditCard : "",
            rate: user?.rate ? user.rate : "",
            bonuses: user?.bonuses ? user.bonuses.map((bonus, i) => (bonus && i > 0 && bonus[0] !== ' ') ? ' ' + bonus : bonus) : "",
          }}
        >
          {({ invalid, values }) => (
            <form onSubmit={() => onSubmitForm(values)}>
              <Grid container justifyContent="center" spacing={2}>
                <BaseFields user={user} />
              </Grid>

              <BasePhone />
              <BaseStatus options={options} setUserRoleId={setUserRoleId} isStaff={values?.role?.id !== 3} />

              <BaseCheckBoxes user={user} values={values} />

              {values?.role?.id !== 3 && (
                <>
                  <BaseRateSalary options={options} />
                  <BaseDateCard />
                  <BaseSocial />
                </>
              )}

              <Box
                left={0}
                right={0}
                bottom={45}
                position={'absolute'}
                width={'100%'}
                textAlign={'center'}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ButtonCustom
                      type="submit"
                      disabled={values?.role?.id === 3
                        ? false
                        : invalid || !user?.salary
                      }
                    >
                      Сохранить
                    </ButtonCustom>

                    {values?.role?.id !== 3 && !user?.salary && (
                      <>
                        <IconButton
                          onMouseOver={() => setUserSalaryError(true)}
                        >
                          <Error color='secondary'/>
                        </IconButton>

                        <Snackbar
                          open={isUserSalaryError}
                          autoHideDuration={6000}
                          onClose={() => setUserSalaryError(false)}
                        >
                          <Alert severity='error'>
                            <Box textAlign='center'>
                              Прежде чем продолжить установите размер зарплаты и рейта сотрудника на странице «История зарплат»
                            </Box>
                          </Alert>
                        </Snackbar>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </form>
          )}
        </Form>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          className={classesSnackbar.root}
          onClose={handleCloseSnackbar}
        >
          <ButtonCustom>Сохранено</ButtonCustom>
        </Snackbar>
      </>
    );
  }
};
