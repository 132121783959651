import React from "react";

import { useQuery } from "@apollo/client";
import { GetMasteringMoneyReportDocument, GetMasteringMoneyReportQuery} from "../../../../generated/graphql";
import { IDateRange } from "../../../shared/DateRangePicker/DateRangePicker";

import { TableHead, Table, TableBody, TableRow, CircularProgress } from "@material-ui/core";

import {
  CustomTableRow,
  CustomTableCell,
  CustomTableHeaderCell,
} from "../../../shared/CustomTable/CustomTable.style";
import { LoadingBox } from "../../../shared/Style/Style";

import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { CustomTable } from "../../../shared/CustomTable";

const headers = [
  "Проекты",
  "Сумма",
  "Всего",
  "Осталось",
];

export const ProjectSpendingReport = ({ dateRange }: {dateRange: IDateRange}) => {
  const { data, loading, error } = useQuery<GetMasteringMoneyReportQuery>(
    GetMasteringMoneyReportDocument, {
      variables: dateRange
    }
  );

  let content;

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit'/>
      </LoadingBox>
    )
  }

  if (error) {
    content = <ErrorSnackbar error={error} />
  }

  if (data) {
    const projects = data.getMasteringMoneyReport;

    content = (
      <Table>
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <CustomTableHeaderCell
                key={header}
                width={300}
              >
                {header}
              </CustomTableHeaderCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {projects.length
            ? projects.map(project => (
              <CustomTableRow
                key={project.projectId}
              >
                {[
                  project.projectName,
                  project.cost,
                  project.costEstimate,
                  project.costFact,
                ].map(field => (
                  <CustomTableCell>
                    {field}
                  </CustomTableCell>
                ))}
              </CustomTableRow>
            ))
            : (
              <TableRow>
                <CustomTableCell colSpan={4}>Проекты отсутствуют</CustomTableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    )
  }

  return (
    <CustomTable data={data?.getMasteringMoneyReport} tableContent={content}/>
  )
}