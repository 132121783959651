import { FC } from "react";
import { useParams } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { robotsState } from "../../state";
import { Dialog, Box} from "@material-ui/core";
import { CloseIconButton } from "../../../../shared/Buttons/CloseIconButton";
import { ModalButtons } from "../../../shared/ModalButtons";
import { ModalTitle } from "../../style";
import { StageGeneralInfo } from "../StageGeneralInfo";
import { useGetStageTypeId } from "../../hooks/useGetStageTypeId";
import { useValidateStageFields } from "../../hooks/useValidateStageFields";

interface CreateStageModalProps {
    refetch: () => void;
}

export const CreateStageModal: FC<CreateStageModalProps> = ({ refetch }) => {
    const { robotId } = useParams<{ robotId: string }>();

    const stage = useReactiveVar(robotsState.stageGeneralInfo);
    const isLoading = useReactiveVar(robotsState.isLoading);
    const { validateStageFields } = useValidateStageFields();
    const stageTypeId = useGetStageTypeId();


    const closeHandler = () => {
        robotsState.setIsCreateRobotStageModalOpen(false);
        robotsState.clearStage();
    };

    const submitHandler = async () => {
        const isValidFields = validateStageFields();
        
        if (!isValidFields) return;

        await robotsState.createRobotStage({
            input: {
                ...stage,
                gameModelId: robotId,
                stageId: stageTypeId,
                experiencePrice: stage.experiencePrice ?? 0,
            }
        });
        refetch();
        closeHandler();
     };

    return (
        <Dialog open maxWidth="sm">
            <Box p={2} px={5} py={5} width={500}>
                <CloseIconButton close={closeHandler} />
                
                <ModalTitle>Создание стадии</ModalTitle>

                <StageGeneralInfo />
                
                <ModalButtons
                    isLoading={isLoading}
                    cancelHandler={closeHandler} 
                    submitHandler={submitHandler}
                />
            </Box>
        </Dialog>
    );
};
