import styled from "styled-components";

export const ProjectModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProjectModalDialogContent = styled.div`
  width: 516px;
  padding: 50px 30px;
  border-radius: 10px;
  background: #fff;
`;

export const ProjectModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
`;
