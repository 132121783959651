import React from "react";
import { primary } from "../../Style/Style";

export const ImageIcon = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.04488 16.7581L8.98177 10.8945C9.33818 10.5425 9.51639 10.3665 9.72188 10.3006C9.90264 10.2426 10.0974 10.2426 10.2781 10.3006C10.4836 10.3665 10.6618 10.5425 11.0182 10.8946L16.9155 16.719M11.8 11.6667L14.3818 9.11677C14.7382 8.76476 14.9164 8.58875 15.1219 8.52281C15.3026 8.4648 15.4974 8.4648 15.6781 8.52281C15.8836 8.58875 16.0618 8.76476 16.4182 9.11677L19 11.6667M8.2 6.33333C8.2 7.31517 7.39411 8.11111 6.4 8.11111C5.40589 8.11111 4.6 7.31517 4.6 6.33333C4.6 5.35149 5.40589 4.55556 6.4 4.55556C7.39411 4.55556 8.2 5.35149 8.2 6.33333ZM5.32 17H14.68C16.1921 17 16.9482 17 17.5258 16.7094C18.0338 16.4537 18.4469 16.0457 18.7057 15.544C19 14.9735 19 14.2268 19 12.7333V5.26667C19 3.77319 19 3.02646 18.7057 2.45603C18.4469 1.95426 18.0338 1.54631 17.5258 1.29065C16.9482 1 16.1921 1 14.68 1H5.32C3.80786 1 3.05179 1 2.47423 1.29065C1.96619 1.54631 1.55314 1.95426 1.29428 2.45603C1 3.02646 1 3.77319 1 5.26667V12.7333C1 14.2268 1 14.9735 1.29428 15.544C1.55314 16.0457 1.96619 16.4537 2.47423 16.7094C3.05179 17 3.80786 17 5.32 17Z"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
