import React, { useEffect, useState } from "react";
import { Box, Paper } from "@material-ui/core";
import {
  ButtonTxt,
  ButtonWithIcon,
} from "../../KnowledgeBaseBlock/KnowledgeBaseBlockItem.style";
import { Check, Delete, Edit } from "@material-ui/icons";
import {
  CreateLessonListItemDocument,
  CreateLessonListItemMutation,
  DeleteLessonBlockDocument, DeleteLessonListItemDocument,
  DeleteLessonListItemMutation,
  DeleteLessonMutation,
  GetLessonDocument,
  LessonBlock,
  LessonBlockTypeEnum,
  UpdateLessonBlockDocument, UpdateLessonBlockMutation, UpdateLessonListItemDocument,
  UpdateLessonListItemMutation,
} from "../../../generated/graphql";
import { PhotoBlock } from "../LessonBlocks/PhotoBlock";
import { useMutation } from "@apollo/client";
import { green, secondary } from "../../shared/Style/Style";
import { ListBlock } from "../LessonBlocks/ListBlock";
import { VideoBlock } from "../LessonBlocks/VideoBlock";
import { TextBlock } from "../LessonBlocks/TextBlock";

export interface LessonBaseBlockInnerProps {
  disabled: boolean;
  block: LessonBlock;
  createListItem?(
    lessonBlockId?: string,
    isNumbered?: boolean,
    contentUKR?: string,
    contentEN?: string
  ): void;
  updateListItem?(
    listItemId: string,
    contentUKR?: string,
    contentEN?: string,
    isNumbered?: boolean
  ): void;
  deleteListItem?(id: string): void;
  uploadPhoto?: ({
    variables,
  }: {
    variables: { lessonBlockData: { lessonBlockId: string; photo: File } };
  }) => void;
  successRequest?:
    | CreateLessonListItemMutation
    | UpdateLessonListItemMutation;
}

const typesNotForSave = [LessonBlockTypeEnum.List, LessonBlockTypeEnum.Photo];

export const LessonBlockItem = ({ block, disable }) => {
  const { id, video, videoPreview, contentEN, contentUKR } = block;

  const [variables, setVariables] = useState({
    lessonBlockId: id,
    video,
    videoPreview,
    contentEN,
    contentUKR,
  });

  const [editedBlockId, setEditedBlockId] = useState("");
  const isEditing = block.id === editedBlockId;

  const [
    updateBlock,
    { data: updateData, loading: loadingUpdateBlock, error: errorUpdateBlock },
  ] = useMutation<UpdateLessonBlockMutation>(UpdateLessonBlockDocument, {
    refetchQueries: [GetLessonDocument],
  });

  const saveHandler = async () => {
    try {
      typesNotForSave.includes(block.type)
        ? setEditedBlockId("")
        : await updateBlock({
            variables: {
              lessonBlockData: {
                lessonBlockId: variables.lessonBlockId,
                contentUKR: variables.contentUKR || undefined,
                contentEN: variables.contentEN || undefined,
                videoData: block.type === LessonBlockTypeEnum.Video ? {
                  video: variables.video,
                  videoPreview:
                    typeof variables.videoPreview === "string"
                      ? undefined
                      : variables.videoPreview,
                } : undefined,
              },
            },
          });
    } catch (e) {

    }
  };

  const [deleteBlock, { loading: loadingDelete, error: errorDelete }] =
    useMutation<DeleteLessonMutation>(DeleteLessonBlockDocument, {
      variables: { lessonBlockId: block.id },
      refetchQueries: [GetLessonDocument],
    });

  const [
    createLessonListItem,
    {
      data: createLessonBlockListItemData,
      loading: createLessonBlockListItemLoading,
      error: createLessonBlockListItemError,
    },
  ] = useMutation<CreateLessonListItemMutation>(
    CreateLessonListItemDocument,
    {
      refetchQueries: [GetLessonDocument],
    }
  );

  const createLessonBlockListItemHandler = async (
    lessonBlockId: string,
    isNumbered: boolean,
    contentUKR: string,
    contentEN: string
  ) => {
    try {
      await createLessonListItem({
        variables: {
          lessonBlockId: block.id,
          lessonBlockListItemData: {
            isNumbered,
            contentUKR,
            contentEN,
          },
        },
      });
    } catch (e) {

    }
  };

  const [
    updateLessonListItem,
    {
      data: updateLessonListItemData,
      loading: updateLessonListItemLoading,
      error: updateLessonBlockListItemError,
    },
  ] = useMutation<UpdateLessonListItemMutation>(
    UpdateLessonListItemDocument,
    {
      refetchQueries: [GetLessonDocument],
    }
  );

  const updateLessonBlockListItemHandler = async (
    listItemId: string,
    contentUKR: string,
    contentEN: string,
    isNumbered: boolean
  ) => {
    try {
      await updateLessonListItem({
        variables: {
          listItemId,
          lessonBlockListItemData: {
            contentUKR,
            contentEN,
            isNumbered,
          },
        },
      });
    } catch (e) {

    }
  };

  const [
    deleteLessonListItem,
    {
      loading: deleteLessonListItemLoading,
      error: deleteLessonBlockListItemError,
    },
  ] = useMutation<DeleteLessonListItemMutation>(
    DeleteLessonListItemDocument
  );

  const deleteLessonBlockListItemHandler = async (listItemId: string) => {
    await deleteLessonListItem({
      variables: { listItemId },
    });
  };

  useEffect(() => {
    if (updateData) {
      setEditedBlockId("");
    }
  }, [updateData]);

  const disabledOnLoading =
    disable ||
    loadingUpdateBlock ||
    loadingDelete ||
    deleteLessonListItemLoading ||
    updateLessonListItemLoading ||
    createLessonBlockListItemLoading;

  const getBlockByType = (type: LessonBlockTypeEnum): JSX.Element => {
    switch (type) {
      case LessonBlockTypeEnum.Photo:
        return (
          <PhotoBlock
            disabled={
              (!isEditing && !!editedBlockId) ||
              !editedBlockId ||
              disabledOnLoading
            }
            block={block}
            uploadPhoto={updateBlock}
          />
        );

      case LessonBlockTypeEnum.List:
        return (
          <ListBlock
            disabled={
              (!isEditing && !!editedBlockId) ||
              !editedBlockId ||
              disabledOnLoading
            }
            block={block}
            createListItem={createLessonBlockListItemHandler}
            updateListItem={updateLessonBlockListItemHandler}
            deleteListItem={deleteLessonBlockListItemHandler}
            successRequest={
              updateLessonListItemData || createLessonBlockListItemData
            }
          />
        );

      case LessonBlockTypeEnum.Video:
        return (
          <VideoBlock
            block={block}
            video={variables.video}
            videoPreview={variables.videoPreview}
            setVariables={setVariables}
            disabled={(!isEditing && !!editedBlockId) || !editedBlockId || disabledOnLoading}
          />
        );

      default:
        return <TextBlock
          block={block}
          variables={variables}
          setVariables={setVariables}
          disabled={(!isEditing && !!editedBlockId) || !editedBlockId ||disabledOnLoading}
        />;
    }
  };

  const blockContent = getBlockByType(block.type);

  return (
    <Paper elevation={2} style={{ margin: "0 0 30px 0" }}>
      <Box p="50px" mb="30px" position="relative">
        {blockContent}

        <Box
          mt="20px"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <ButtonWithIcon variant="contained" disabled={disabledOnLoading}>
            <ButtonTxt onClick={() => deleteBlock()}>Удалить блок</ButtonTxt>
            <Delete color="secondary" />
          </ButtonWithIcon>

          <ButtonWithIcon
            variant="contained"
            width={189}
            disabled={disabledOnLoading}
            onClick={() =>
              isEditing ? saveHandler() : setEditedBlockId(block.id)
            }
          >
            <ButtonTxt>{isEditing ? "Готово" : "Редактировать"}</ButtonTxt>

            {isEditing ? (
              <Check htmlColor={green} />
            ) : (
              <Edit htmlColor={secondary} />
            )}
          </ButtonWithIcon>
        </Box>
      </Box>
    </Paper>
  );
};
