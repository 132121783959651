import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  DeleteProjectPresentationDocument,
  DeleteProjectPresentationMutation,
  GetProjectDocument,
  ProjectPresentation,
  UpdateProjectPresentationDocument,
  UpdateProjectPresentationMutation,
} from "../../../../generated/graphql";
import { Box, Grid, TextField } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { LinkData } from "../ProjectAddLinkModal";
import { SaveIconButton } from "../../../shared/Buttons/SaveIconButton";
import { EditIconButton } from "../../../shared/Buttons/EditIconButton";
import { DeleteIconButton } from "../../../shared/Buttons/DeleteIconButton";
import { ErrorMessageSnackbar } from "../../../shared/ErrorMessageSnackbar";

interface ProjectPresentationItemProps {
  presentation: ProjectPresentation;
  projectId: string;
  classes: ClassNameMap<"root">;
}

interface EditLinkData extends LinkData {
  id: string;
}

export const ProjectPresentationItem = ({
  presentation,
  projectId,
  classes,
}: ProjectPresentationItemProps) => {
  const [requestError, setRequestError] = useState(false);
  const [currentLink, setCurrentLink] = useState<EditLinkData>({
    id: "",
    title: "",
    link: "",
  });

  const [
    updateProjectPresentation,
    {
      data: updateProjectPresentationData,
      loading: updateProjectPresentationLoading,
      error: updateError,
    },
  ] = useMutation<UpdateProjectPresentationMutation>(
    UpdateProjectPresentationDocument,
    {
      refetchQueries: [{ query: GetProjectDocument, variables: { projectId } }],
    }
  );

  const [
    deleteProjectPresentation,
    { loading: deleteLoading, error: deleteError },
  ] = useMutation<DeleteProjectPresentationMutation>(
    DeleteProjectPresentationDocument,
    {
      refetchQueries: [{ query: GetProjectDocument, variables: { projectId } }],
    }
  );

  useEffect(() => {
    if (updateProjectPresentationData) {
      setCurrentLink({ id: "", title: "", link: "" });
    }
  }, [updateProjectPresentationData]);

  useEffect(() => {
    if (updateError || deleteError) {
      setRequestError(true);
    }
  }, [updateError, deleteError]);

  return (
    <Box mb={1}>
      <Grid
        container
        justifyContent="center"
        spacing={2}
        style={{ position: "relative" }}
      >
        <Grid xs={6} item>
          <TextField
            className={classes.root}
            fullWidth
            label="Название"
            variant="filled"
            type="text"
            disabled={currentLink.id !== presentation.id}
            value={
              currentLink.id === presentation.id
                ? currentLink.title
                : presentation.title
            }
            onChange={(e) =>
              setCurrentLink({
                ...currentLink,
                title: e.target.value,
              })
            }
          />
        </Grid>

        <Grid xs={6} item>
          <TextField
            className={classes.root}
            fullWidth
            label="Ссылка"
            variant="filled"
            type="text"
            disabled={currentLink.id !== presentation.id}
            value={
              currentLink.id === presentation.id
                ? currentLink.link
                : presentation.link
            }
            onChange={(e) =>
              setCurrentLink({
                ...currentLink,
                link: e.target.value,
              })
            }
          />

          <Box style={{ position: "absolute", right: "40px", top: "10px" }}>
            {currentLink.id === presentation.id ? (
              <SaveIconButton
                disabled={updateProjectPresentationLoading || deleteLoading}
                fz={14}
                save={() =>
                  updateProjectPresentation({ variables: currentLink })
                }
              />
            ) : (
              <EditIconButton
                disabled={presentation.id === currentLink.id}
                fz={14}
                edit={() =>
                  setCurrentLink({
                    id: presentation.id,
                    title: presentation.title,
                    link: presentation.link,
                  })
                }
              />
            )}
          </Box>

          <Box style={{ position: "absolute", right: "8px", top: "10px" }}>
            <DeleteIconButton
              disabled={false}
              deleteHandler={() =>
                deleteProjectPresentation({
                  variables: { projectPresentationId: presentation.id },
                })
              }
              item="презентацию продукта"
              fz={14}
            />
          </Box>
        </Grid>
      </Grid>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={
          (updateError && "Ошибка при редактировании презентации") ||
          (deleteError && "Ошибка при удалении презентации")
        }
      />
    </Box>
  );
};
