import React from "react";

export const PlusIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 7C14 7.55228 13.5523 8 13 8H8V13C8 13.5523 7.55228 14 7 14C6.44771 14 6 13.5523 6 13V8H1C0.447715 8 0 7.55228 0 7C0 6.44771 0.447715 6 1 6H6V1C6 0.447715 6.44771 0 7 0C7.55228 0 8 0.447715 8 1V6H13C13.5523 6 14 6.44771 14 7Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};
