import styled from "styled-components";
import { FormControlLabel } from "@material-ui/core";
import { lightBlack, SmallUserAvatarStyle } from "../shared/Style/Style";

export const KanbanModalSubtitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  margin: auto 0;
`;


export const KanbanModalText = styled.div`
  font-size: 16px;
  line-height: 1;
  margin: auto 0;
  margin-left: 10px;
`;

export const KanbanModalUserAvatar = styled(SmallUserAvatarStyle)`
  min-width: 36px;
  width: 36px;
  height: 36px;
`;

export const CheckListItem = styled(FormControlLabel)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 0;
  margin-left: 0;

  & .MuiCheckbox-root {
    margin-left: -11px;
  }

  & .MuiFormControlLabel-label {
    flex-grow: 1;
  }
`;

export const AttachmentImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 10px;
  justify-self: center;
`;

export const LabelItem = styled.div<{color: string}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  padding: 5px 0 5px 10px;
  border-radius: 4px;
  background-color: ${props => props.color};
  margin-bottom: 10px;
  color: ${lightBlack};
  font-weight: bold;

  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;
