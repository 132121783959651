import { PurchaseFormValuesKeys } from "../Purchase/types";
import { costText } from "../../constants";

export const confirmText =
  "Вы действительно хотите деактивировать этот платеж?\n" +
  "После деактивации отменить операцию невозможно.";

export const deactivatePaymentText = "Закрыть доступ";

export enum ColumnsIdEnum {
  PaymentDateId = "paymentDate",
  PaymentTypeId = "paymentType",
  ProductIdId = "productId",
  PriceId = "price",
  IsActivatedId = "isActivated",
  ChangeStatusId = "changeStatus",
}

export const rowsPerPageOptions = [10, 25, 100];

export const getStatusText = (isActive: boolean) =>
  isActive ? "Activated" : "Deactivated";

interface Column {
  id:
    | PurchaseFormValuesKeys
    | ColumnsIdEnum.IsActivatedId
    | ColumnsIdEnum.ChangeStatusId;
  label: string;
}
export const columns: readonly Column[] = [
  { id: ColumnsIdEnum.PaymentDateId, label: "Дата платежа" },
  { id: ColumnsIdEnum.PaymentTypeId, label: "Тип оплаты" },
  { id: ColumnsIdEnum.ProductIdId, label: "ID товара" },
  { id: ColumnsIdEnum.PriceId, label: costText },
  { id: ColumnsIdEnum.IsActivatedId, label: "Статус" },
  { id: ColumnsIdEnum.ChangeStatusId, label: "" },
];
