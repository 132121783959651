import { ChangeEvent, useEffect, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { robotsState } from "../../state";
import { Typography, IconButton } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { AddBox } from "@material-ui/icons";
import { AccordionDetailsStyled, AccordionStyled, AccordionSummaryStyled, StageArtifactsContainer } from "../../style";
import { StageArtifactItem } from "../StageArtifactItem";
import { ArtifactModal } from "../Modals/ArtifactModal";
import { MobileAppDeleteModal } from "../../../../MobileAppModal/MobileAppDeleteModal";
import { useGetArtifactsTypes } from "../../hooks/useGetArtifactsTypes";
import { ArtifactTypeEnum } from "../../../../../generated/graphql";
import { ArtifactNamesMap } from "../../maps";
import { ArtifactsNameEnum } from "../../types";
import { artifactPerPage, initialDeleteArtifact } from "../../constants";

export const StageArtifactsBlock = () => {
    const [expanded, setExpanded] = useState<ArtifactsNameEnum>(null);
    const stageGeneralInfo = useReactiveVar(robotsState.stageGeneralInfo);
    const isAddArtifactModalOpen = useReactiveVar(robotsState.isArtifactModalOpen);
    const deleteArtifact = useReactiveVar(robotsState.deleteArtifact);
    const artifactMode = useReactiveVar(robotsState.currentArtifactMode);
    const artifactsSkipPage = useReactiveVar(robotsState.artifactsSkipPage);
    const getArtifactTypes = useGetArtifactsTypes();
    
    const artifactsList = Array.from(ArtifactNamesMap.keys());

    const fetchArtifacts = async (
        type: ArtifactTypeEnum,
        clothTypeId: string,
        skip: number = 0,
    ) => {
        const params = {
            gameModelStateId: stageGeneralInfo?.id,
            type,
            clothTypeId,
        };
        await robotsState.getRobotArtifacts({
            input: params,
            take: artifactPerPage,
            skip,
        });
    };

    useEffect(() => {
        if (!expanded) robotsState.clearArtifacts();
        if (expanded && artifactMode)
            fetchArtifacts(artifactMode.type, artifactMode.clothTypeId);
    }, [expanded, artifactMode]);

    const openArtifactListHandler = (value: ArtifactsNameEnum) => (
        event: ChangeEvent<{}>,
        isExpanded: boolean
    ) => {
        event.preventDefault();
        const { type, clothTypeId } = getArtifactTypes(value);
        
        setExpanded(isExpanded ? value : null);       
        robotsState.clearArtifacts();
        robotsState.setCurrentArtifactMode({ type, clothTypeId });
    };

    const addArtifactHandler = (value: ArtifactsNameEnum) => {
        const { type, clothTypeId } = getArtifactTypes(value);
        
        robotsState.setIsArtifactModalOpen({
            isOpen: true,
            type,
            clothTypeId,
        });
    };

    const renderAccordionItem = (
        name: ArtifactsNameEnum,
    ) => { 
        return (
            <AccordionStyled
                key={name}
                expanded={expanded === name} 
                onChange={openArtifactListHandler(name)}
            >
                <AccordionSummaryStyled
                    expandIcon={<ExpandMore />}
                    id={name.toString()}
                >
                    <Typography variant="h6" style={{ fontSize: "16px" }}>
                        {name}
                    </Typography>

                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            addArtifactHandler(name);
                        }}
                    >
                        <AddBox fontSize="medium" />
                    </IconButton>
                </AccordionSummaryStyled>

                <AccordionDetailsStyled>
                    <StageArtifactItem
                        key={name}
                        getNextPage={fetchArtifacts}
                    />
                </AccordionDetailsStyled>
            </AccordionStyled>
        );
    };

    return (
        <>
            <StageArtifactsContainer>
                {artifactsList.map(item => renderAccordionItem(item))}
            </StageArtifactsContainer>

            {isAddArtifactModalOpen.isOpen &&
                <ArtifactModal refetch={fetchArtifacts} />
            }
            
            {deleteArtifact.isOpen &&
  		        <MobileAppDeleteModal
  		            open={deleteArtifact.isOpen}
  		            close={() => robotsState.setDeleteArtifact(initialDeleteArtifact)}
  		            item={deleteArtifact.artifact}
                    refetch={() =>
                        fetchArtifacts(
                            artifactMode?.type,
                            artifactMode?.clothTypeId,
                            artifactsSkipPage
                        )
                    }
  		        />
  		    }
        </>
    );
};
