import React from "react";
import { Box, FormControlLabel } from "@material-ui/core";
import { CustomRadioGroupBody, CustomRadioControl } from "./CustomRadioGroup.style";

interface IRadioGroupProps {
  items: {
    label: string;
    entity: any;
  }[];
  chosenItem: any;
  state: {[key: string]: any};
  setNewState(state: {[key: string]: any}): void;
  fieldName: string;
  horizontal?: boolean;
  disabled?: boolean;
}

export const CustomRadioGroup = ({ items, chosenItem, state, setNewState, fieldName, horizontal, disabled }: IRadioGroupProps) => {
  return (
    <Box>
      <CustomRadioGroupBody
        value={chosenItem}
        horizontal={horizontal}
      >
        {items.map(item => (
          <FormControlLabel
            key={item.label}
            control={
              <CustomRadioControl
                value={item.entity}
                disabled={disabled}
                onChange={() => setNewState({
                  ...state,
                  [fieldName]: item.entity,
                })}
              />
            }
            label={item.label}
          />
        ))}
      </CustomRadioGroupBody>
    </Box>
  )
}
