import {TextareaAutosize} from "@material-ui/core";
import styled from "styled-components";
import { primary } from "../Style/Style";

export const ContainerTextArea = styled.div`
  position: relative;
`

export const Label = styled.label`
  color: #c5c4c4;
  font-weight: 400;
  font-size: 14px;
`

export const StyledTextAreaAutosize = styled(TextareaAutosize)<{ err: boolean }>`
  border: none;
  outline: none;
  border-bottom: 1px solid ${({err}) => (err ? `red` : `#d9d8d8`)};

  &:focus {
    border-bottom: 2px solid ${primary};
  }
`

export const DEFAULT_INLINE_STYLE_CONFIG = {
  width: "400px",
  maxWidth: "402px",
  minWidth: "402px"
}