import styled from "styled-components";

export const LinkContainer = styled.div`
  background-color: #f9f9f9;
  height: 100%;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.42);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 27px 12px 10px;
  position: relative;
  overflow: hidden;
`;

export const LinkLabel = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  color: rgba(0, 0, 0, 0.38);
  padding: 0;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
`;

export const Link = styled.a`
  color: black;
  width: 100%;
  overflow: hidden;
`;
