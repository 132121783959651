import React, {useState} from 'react';
import {BlockInputLesson} from "./Style";
import {getLabel} from "./untils";
import {LessonBlockTypeEnum} from "../../../generated/graphql";
import {Box} from "@material-ui/core";
import {LanguageSwitch} from "../../shared/LanguageSwitch/LanguageSwitch";
import {languages} from "../../../constants/language";

export const TextBlock = ({block, disabled, setVariables, variables}) => {
  const [language, setLanguage] = useState('UKR')
  const isTextType = block.type === LessonBlockTypeEnum.Text
  const label = getLabel(block.type)

  return (
    <Box display="flex" alignItems="flex-end">
      <BlockInputLesson
        fullWidth
        label={label}
        type={block.type}
        multiline={isTextType}
        disabled={disabled}
        value={variables[`content${language}`] || ''}
        onChange={({target: {value}}) => setVariables(prev => ({...prev, [`content${language}`]: value }))}
      />

      <LanguageSwitch languages={languages} switchLanguage={setLanguage} disabled={disabled} />
    </Box>

  )
}