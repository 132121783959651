import { useMutation } from "@apollo/client";
import { Dialog, DialogContent, DialogTitle, TextField, Box } from "@material-ui/core";

import { ThemeProvider } from '@material-ui/styles'
import React, { useEffect, useState } from "react";
import { ImportProjectTasksFromGSheetsDocument, ImportProjectTasksFromGSheetsMutation } from "../../../generated/graphql";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { ButtonCustom, ButtonsWrapper, CancelButton, defaultMaterialTheme } from "../../shared/Style/Style";

interface IGSheetsProjectModalProps {
  open: boolean;
  close(): void;
  projectId: string;
}

const initialErrors: {[key: string]: boolean} = {
  gSheetId: false,
  gSheetTitle: false,
}

export const GSheetsProjectModal = ({ open, close, projectId }: IGSheetsProjectModalProps) => {
  const [gSheetId, setGSheetId] = useState('');
  const [gSheetTitle, setGSheetTitle] = useState('');

  const [errors, setErrors] = useState(initialErrors);
  const [requestError, setRequestError] = useState(false);

  const closeHandler = (): void => {
    setGSheetId('');
    setGSheetTitle('');
    setErrors(initialErrors);
    setRequestError(false);
    close();
  };

  const [importGSheet, {
    data: importGSheetData,
    loading: importGSheetLoading,
    error: importGSheetError,
  }] = useMutation<ImportProjectTasksFromGSheetsMutation>(
    ImportProjectTasksFromGSheetsDocument, {
      variables: {
        projectId,
        gSheetId,
        gSheetTitle
      }
    }
  );

  const importHandler = (): void => {
    const data = Object.entries({ gSheetTitle, gSheetId });

    data.forEach((field: string[]) => {
      if(!field[1]) {
        setErrors(state => ({
          ...state,
          [field[0]]: true,
        }))
      }
    });

    if (data.some((field: string[]) => !field[1])) {
      return;
    }

    importGSheet();
  }

  useEffect(() => {
    if (importGSheetError) {
      setRequestError(true);
    }
  }, [importGSheetError])

  useEffect(() => {
    if (importGSheetData) {
      closeHandler();
    }
  }, [importGSheetData])


  return (
    <Dialog
      open={open}
    >
      <CloseIconButton
        close={closeHandler}
        disabled={importGSheetLoading}
      />
      <DialogContent>
        <DialogTitle>
          Импорт задач из Google Sheets
        </DialogTitle>

        <ThemeProvider theme={defaultMaterialTheme}>
          <Box
            mb={2}
          >
            <TextField
              fullWidth
              label='Id документа'
              disabled={importGSheetLoading}
              value={gSheetId}
              onChange={({ target: { value } }) => setGSheetId(value)}
              error={errors.gSheetId}
              onFocus={() => setErrors({ ...errors, gSheetId: false})}
            />
          </Box>


          <TextField
            fullWidth
            label='Заголовок'
            disabled={importGSheetLoading}
            value={gSheetTitle}
            onChange={({ target: { value } }) => setGSheetTitle(value)}
            error={errors.gSheetTitle}
            onFocus={() => setErrors({ ...errors, gSheetTitle: false})}
          />
        </ThemeProvider>

        <ButtonsWrapper>
          <CancelButton
            onClick={closeHandler}
            disabled={importGSheetLoading}
          >
            Отмена
          </CancelButton>

          <ButtonCustom
            disabled={importGSheetLoading}
            onClick={importHandler}
          >
            Импортировать
          </ButtonCustom>
        </ButtonsWrapper>
      </DialogContent>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message='При ипортирвании задач из Google Sheets документа произогла ошибка'
      />
    </Dialog>
  )
}