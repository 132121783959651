import { Dispatch, SetStateAction } from "react";
import { DateRange } from "@material-ui/icons";
import {
    CustomTopFilterContainer,
    TopFilterPeriodContainer,
    TopFilterSelectContainer,
    DatePickerButtonTopFilter,
    ButtonCustomWithDarkTextTopFilter,
} from "../../shared/Style/Style";
import { DateRangePicker, IDateRange } from "../../shared/DateRangePicker/DateRangePicker";
import { FiltersSelect } from "../../shared/Selects/FiltersSelect";
import { PaymentsType, TopFilterType, ReportTopFilterVariantType } from "../types";

interface ReportTopFilterProps {
    handleDateRangePickerOpen: (value: boolean) => void;
    handleAllPeriodDateRangeData: (value: boolean) => void;
    isDatePickerActive: boolean;
    titleDatePickerButton: string;
    titleDateButton: string;
    spaceFilterFieldsData: PaymentsType[];
    productFilterFieldsData: PaymentsType[];
    isDateRangePickerOpen: boolean;
    isAllPeriodDateRangeData: boolean;
    setTopFilterVariables: (newValue) => void;
    variant: ReportTopFilterVariantType;
};

export const ReportTopFilter: React.FC<ReportTopFilterProps> = ({
    handleDateRangePickerOpen,
    handleAllPeriodDateRangeData,
    isDatePickerActive,
    titleDatePickerButton,
    titleDateButton,
    spaceFilterFieldsData,
    productFilterFieldsData,
    isDateRangePickerOpen,
    isAllPeriodDateRangeData,
    setTopFilterVariables,
    variant,
}) => { 
    
    const { allPeriodDate } = variant;

    const handleSetFilter = (value: PaymentsType['value']) => {
        setTopFilterVariables({ spaceType: (value as string) })
    };
    
    const handleSetFilterProduct = (value: PaymentsType['value']) => {
        setTopFilterVariables({ productType: (value as string[]) })
    };
    
    const handleSetFilterPeriod = (newDateRange: IDateRange) => {
        const { startDate, endDate } = newDateRange;
        setTopFilterVariables({ from: startDate, to: endDate });
    };

    const handleSetDatePickerButton = () => {
        handleDateRangePickerOpen(true);
        handleAllPeriodDateRangeData(false);
    };

    const handleSetAllDatePeriod = () => {
        handleSetFilterPeriod(allPeriodDate);
        handleAllPeriodDateRangeData(true);
    };

    return (
        <>
            <CustomTopFilterContainer >
                <TopFilterPeriodContainer>
                    <DatePickerButtonTopFilter
                        onClick={handleSetDatePickerButton}
                        isActive={isDatePickerActive} 
                    >
                        <DateRange />
                        {titleDatePickerButton}
                    </DatePickerButtonTopFilter>
                    <ButtonCustomWithDarkTextTopFilter
                        onClick={handleSetAllDatePeriod}
                        active={isAllPeriodDateRangeData && !isDatePickerActive}
                    >{titleDateButton}
                    </ButtonCustomWithDarkTextTopFilter>
                </TopFilterPeriodContainer>
                <TopFilterSelectContainer>
                    <FiltersSelect fieldsData={spaceFilterFieldsData} handleSetFilter={handleSetFilter} />
                    <FiltersSelect fieldsData={productFilterFieldsData} handleSetFilter={handleSetFilterProduct} />
                </TopFilterSelectContainer>
            </CustomTopFilterContainer>
            <DateRangePicker
                setDateRange={handleSetFilterPeriod}
                open={isDateRangePickerOpen}
                onClose={() => handleDateRangePickerOpen(false)} />
        </>
    );
};