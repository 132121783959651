import React from "react";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { ArrowIconButton } from "./ArrowButton.style";

interface IArrowButtonProps {
  callback(): any;
  variant?: string;
  color?: string;
  disabled?: boolean;
}

export const ArrowButton = ({ variant, color, callback, disabled }: IArrowButtonProps) => {
  return (
    <ArrowIconButton
      customColor={color}
      disabled={disabled}
      onClick={callback}
    >
      {variant === 'left' ? <ArrowLeft color='inherit' /> :  <ArrowRight color='inherit'/>}
    </ArrowIconButton>
  )
}
