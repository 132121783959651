import { useRef } from "react";
import { useReactiveVar } from "@apollo/client";
import { IconButton } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { BundleBlockTitle } from "./style";
import {
    CurrentBundleContainer as BundleContainer,
    MobileAppModalBundleName as BundleTitle,
} from "../../../../../MobileAppModal/MobileAppModal.style";
import { ButtonCustom, ImportFileUpload, BundleLink } from "../../../../../shared/Style/Style";
import { robotsState } from "../../../state";
import { GameModelArtifactFileTypeEnum } from "../../../../../../generated/graphql";
import { BundleMode, BundleNames } from "../../../../../MobileAppModal/MobileAppModelModal/constants";
import { ArtifactBundleTypesMap } from "../../../maps";

export const ArtifactBundles = () => { 
    const iosInput = useRef<HTMLInputElement | null>(null);
    const androidInput = useRef<HTMLInputElement | null>(null);
    const manifestInputIos = useRef<HTMLInputElement | null>(null);
    const manifestInputAndroid = useRef<HTMLInputElement | null>(null);

    const artifact = useReactiveVar(robotsState.artifact);
    const isLoading = useReactiveVar(robotsState.isLoading);

    const uploadArtifactBundle = async (file: File, fileType: GameModelArtifactFileTypeEnum) => {
        if (!file) return;

        const reader = new FileReader();
        reader.onload = () => {
            robotsState.updateArtifactFile({
                updateGameModelArtifactFileId: artifact?.id,
                file,
                fileType,
            }, () => {
                robotsState.setArtifactFile(fileType, "#");
            });
        };
        reader.readAsDataURL(file);
    };

    const deleteArtifactBundle = async (fileType: GameModelArtifactFileTypeEnum) => {
        robotsState.deleteArtifactFile({
            deleteGameModelArtifactFileId: artifact?.id,
            fileType,
        }, () => { 
            robotsState.setArtifactFile(fileType, "");
        });
    };

    const bundlesData = [
        {
            name: BundleNames.Ios,
            currentBundle: artifact?.iosBundle,
            type: BundleMode.Bundle,
            inputRef: iosInput,
            handleBundle: (file: File) => uploadArtifactBundle(
                file,
                GameModelArtifactFileTypeEnum.IosBundle
            ),
        },
        {
            name: BundleNames.Android,
            currentBundle: artifact?.androidBundle,
            type: BundleMode.Bundle,
            inputRef: androidInput,
            handleBundle: (file: File) => uploadArtifactBundle(
                file,
                GameModelArtifactFileTypeEnum.AndroidBundle
            ),
        },
        {
            name: BundleNames.IosManifest,
            currentBundle: artifact?.iosManifest,
            type: BundleMode.Manifest,
            inputRef: manifestInputIos,
            handleBundle: (file: File) => uploadArtifactBundle(
                file,
                GameModelArtifactFileTypeEnum.IosManifest
            ),
        },
        {
            name: BundleNames.AndroidManifest,
            currentBundle: artifact?.androidManifest,
            type: BundleMode.Manifest,
            inputRef: manifestInputAndroid,
            handleBundle: (file: File) => uploadArtifactBundle(
                file,
                GameModelArtifactFileTypeEnum.AndroidManifest
            ),
        },
    ];

    const renderCurrentBundle = (
        name: BundleNames,
        currentBundle: string,
        type:  BundleMode,
        inputRef: React.RefObject<HTMLInputElement>,
        handleBundle: (file: File) => void,
    ) => {
        const label = type === BundleMode.Manifest ? `${name}` : `${name} ${type}`;
        const fileType = ArtifactBundleTypesMap.get(name);

        return (
            <BundleContainer key={name}>
                <BundleTitle>{name}</BundleTitle>
                {!!currentBundle ? (
                    <span>
                        <BundleLink href={currentBundle}>{label}</BundleLink>

                        <IconButton
                            disabled={isLoading}
                            onClick={() => deleteArtifactBundle(fileType)}
                        >
                            <ClearIcon color="error" fontSize="small"/>
                        </IconButton>
                    </span>
                ) : (
                      <ButtonCustom
                        disabled={isLoading}
                        onClick={() => inputRef.current.click()}
                      >
                      <span>Прикрепить</span>
                      <ImportFileUpload
                        type="file"
                        ref={inputRef}
                        onChange={({ target: { files } }) =>
                          files[0] && handleBundle(files[0])
                        }
                      />
                    </ButtonCustom>                         
                )}
            </BundleContainer>
        );
    };

    return (
        <>
            <BundleBlockTitle>Bundle, manifest</BundleBlockTitle>
            {bundlesData.map(({
                name, currentBundle, type, inputRef, handleBundle
            }) =>
                renderCurrentBundle(
                    name,
                    currentBundle,
                    type,
                    inputRef,
                    handleBundle,
                ))
            }
        </>
    );
};
