import React from "react";

export const Arrow = () => {
  return (
    <svg
      width="186"
      height="151"
      viewBox="0 0 186 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        top: "-37px",
        left: "11px",
        height: "152px",
        transform: "rotate(-41deg)",
      }}
    >
      <path
        d="M70.1513 134.726C60.3185 145.811 43.6914 146.604 32.6021 136.774C21.5128 126.945 20.7198 110.323 30.5526 99.2375C40.3854 88.1518 153.065 0.305628 153.065 0.305628C153.065 0.305628 79.9841 123.64 70.1513 134.726Z"
        fill="#101010"
      />
      <path
        d="M51.6112 128.298C58.162 128.298 63.4724 122.989 63.4724 116.441C63.4724 109.892 58.162 104.583 51.6112 104.583C45.0604 104.583 39.75 109.892 39.75 116.441C39.75 122.989 45.0604 128.298 51.6112 128.298Z"
        fill="white"
      />
    </svg>
  );
};

export const Speedometer = () => {
  return (
    <svg
      width="454"
      height="454"
      viewBox="0 0 454 454"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M368.305 226.728C368.305 304.454 305.256 367.491 227.447 367.491C149.639 367.491 86.5898 304.454 86.5898 226.728C86.5898 149.003 149.639 85.9663 227.447 85.9663C305.256 85.9663 368.305 149.003 368.305 226.728Z"
        stroke="#F9F9F9"
        stroke-opacity="0.1"
        stroke-width="58"
      />
      <mask id="path-2-inside-1" fill="white">
        <path d="M226.949 56.967C259.111 56.967 290.608 66.1329 317.763 83.3942C344.918 100.655 366.609 125.3 380.303 154.448L326.629 179.746C317.728 160.8 303.629 144.781 285.978 133.561C268.328 122.342 247.854 116.384 226.949 116.384L226.949 56.967Z" />
      </mask>
      <path
        d="M226.949 56.967C259.111 56.967 290.608 66.1329 317.763 83.3942C344.918 100.655 366.609 125.3 380.303 154.448L326.629 179.746C317.728 160.8 303.629 144.781 285.978 133.561C268.328 122.342 247.854 116.384 226.949 116.384L226.949 56.967Z"
        fill="#FFD885"
        stroke="#FFB61D"
        stroke-opacity="0.7"
        stroke-width="60"
        mask="url(#path-2-inside-1)"
      />
      <mask id="path-3-inside-2" fill="white">
        <path d="M379.668 152.635C393.773 181.586 399.362 213.955 395.781 245.962C392.2 277.969 379.597 308.293 359.444 333.391L313.2 296.16C326.3 279.846 334.491 260.136 336.819 239.331C339.147 218.527 335.514 197.487 326.346 178.669L379.668 152.635Z" />
      </mask>
      <path
        d="M379.668 152.635C393.773 181.586 399.362 213.955 395.781 245.962C392.2 277.969 379.597 308.293 359.444 333.391L313.2 296.16C326.3 279.846 334.491 260.136 336.819 239.331C339.147 218.527 335.514 197.487 326.346 178.669L379.668 152.635Z"
        fill="#FFD885"
        stroke="#FFB61D"
        stroke-width="60"
        mask="url(#path-3-inside-2)"
      />
      <mask id="path-4-inside-3" fill="white">
        <path d="M226.949 56.967C194.788 56.967 163.29 66.1329 136.135 83.3942C108.98 100.655 87.2891 125.3 73.5953 154.448L127.269 179.746C136.17 160.8 150.269 144.781 167.92 133.561C185.571 122.342 206.044 116.384 226.949 116.384L226.949 56.967Z" />
      </mask>
      <path
        d="M226.949 56.967C194.788 56.967 163.29 66.1329 136.135 83.3942C108.98 100.655 87.2891 125.3 73.5953 154.448L127.269 179.746C136.17 160.8 150.269 144.781 167.92 133.561C185.571 122.342 206.044 116.384 226.949 116.384L226.949 56.967Z"
        fill="#FFD885"
        stroke="#FFB61D"
        stroke-opacity="0.5"
        stroke-width="60"
        mask="url(#path-4-inside-3)"
      />
      <mask id="path-5-inside-4" fill="white">
        <path d="M74.2979 152.581C60.1991 181.535 54.6163 213.909 58.2013 245.923C61.7863 277.937 74.3912 308.268 94.5438 333.373L140.774 296.143C127.675 279.824 119.481 260.109 117.151 239.3C114.821 218.491 118.45 197.448 127.614 178.628L74.2979 152.581Z" />
      </mask>
      <path
        d="M74.2979 152.581C60.1991 181.535 54.6163 213.909 58.2013 245.923C61.7863 277.937 74.3912 308.268 94.5438 333.373L140.774 296.143C127.675 279.824 119.481 260.109 117.151 239.3C114.821 218.491 118.45 197.448 127.614 178.628L74.2979 152.581Z"
        fill="#FFD885"
        stroke="#FFB61D"
        stroke-opacity="0.3"
        stroke-width="60"
        mask="url(#path-5-inside-4)"
      />
    </svg>
  );
};

export const SpeedometerGreen = () => {
  return (
    <svg
      width="454"
      height="454"
      viewBox="0 0 454 454"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1" fill="white">
        <path d="M226.949 56.9672C259.111 56.9672 290.608 66.1332 317.763 83.3944C344.918 100.656 366.609 125.3 380.303 154.448L326.629 179.747C317.728 160.8 303.629 144.781 285.978 133.562C268.328 122.342 247.854 116.384 226.949 116.384L226.949 56.9672Z" />
      </mask>
      <path
        d="M226.949 56.9672C259.111 56.9672 290.608 66.1332 317.763 83.3944C344.918 100.656 366.609 125.3 380.303 154.448L326.629 179.747C317.728 160.8 303.629 144.781 285.978 133.562C268.328 122.342 247.854 116.384 226.949 116.384L226.949 56.9672Z"
        fill="white"
        stroke="#50E896"
        stroke-opacity="0.8"
        stroke-width="60"
        mask="url(#path-1-inside-1)"
      />
      <mask id="path-2-inside-2" fill="white">
        <path d="M379.668 152.635C393.773 181.586 399.362 213.955 395.781 245.962C392.2 277.969 379.597 308.293 359.444 333.391L313.2 296.16C326.3 279.847 334.491 260.136 336.819 239.332C339.147 218.527 335.514 197.487 326.346 178.669L379.668 152.635Z" />
      </mask>
      <path
        d="M379.668 152.635C393.773 181.586 399.362 213.955 395.781 245.962C392.2 277.969 379.597 308.293 359.444 333.391L313.2 296.16C326.3 279.847 334.491 260.136 336.819 239.332C339.147 218.527 335.514 197.487 326.346 178.669L379.668 152.635Z"
        fill="white"
        stroke="#50E896"
        stroke-width="60"
        mask="url(#path-2-inside-2)"
      />
      <mask id="path-3-inside-3" fill="white">
        <path d="M226.949 56.9672C194.788 56.9672 163.29 66.1332 136.135 83.3944C108.98 100.656 87.2891 125.3 73.5953 154.448L127.269 179.747C136.17 160.8 150.269 144.781 167.92 133.562C185.571 122.342 206.044 116.384 226.949 116.384L226.949 56.9672Z" />
      </mask>
      <path
        d="M226.949 56.9672C194.788 56.9672 163.29 66.1332 136.135 83.3944C108.98 100.656 87.2891 125.3 73.5953 154.448L127.269 179.747C136.17 160.8 150.269 144.781 167.92 133.562C185.571 122.342 206.044 116.384 226.949 116.384L226.949 56.9672Z"
        fill="white"
        stroke="#50E896"
        stroke-opacity="0.5"
        stroke-width="60"
        mask="url(#path-3-inside-3)"
      />
      <mask id="path-4-inside-4" fill="white">
        <path d="M74.2979 152.581C60.1991 181.535 54.6163 213.91 58.2013 245.923C61.7863 277.937 74.3912 308.268 94.5438 333.374L140.774 296.143C127.675 279.824 119.481 260.109 117.151 239.3C114.821 218.491 118.45 197.448 127.614 178.628L74.2979 152.581Z" />
      </mask>
      <path
        d="M74.2979 152.581C60.1991 181.535 54.6163 213.91 58.2013 245.923C61.7863 277.937 74.3912 308.268 94.5438 333.374L140.774 296.143C127.675 279.824 119.481 260.109 117.151 239.3C114.821 218.491 118.45 197.448 127.614 178.628L74.2979 152.581Z"
        fill="white"
        stroke="#50E896"
        stroke-opacity="0.3"
        stroke-width="60"
        mask="url(#path-4-inside-4)"
      />
    </svg>
  );
};

export const yellowColors = [
  { name: "от 0-25%", color: "#ffce66" },
  { name: "от 25-50%", color: "#ffc751" },
  { name: "от 50-75%", color: "#ffc03c" },
  { name: "от 75-100%", color: "#ffb61d" },
];

export const greenColors = [
  { name: "от 0-25%", color: "#caf8df" },
  { name: "от 25-50%", color: "#a7f3ca" },
  { name: "от 50-75%", color: "#73edab" },
  { name: "от 75-100%", color: "#50e896" },
];