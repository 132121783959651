import React, { useEffect, useState } from "react";
import { BlockInput } from "../../KnowledgeBaseBlock/KnowledgeBaseBlockItem.style";
import { Box, Collapse, InputAdornment } from "@material-ui/core";
import {
  FormatListBulleted,
  FormatListNumbered,
  RadioButtonUnchecked,
} from "@material-ui/icons";
import { SaveIconButton } from "../../shared/Buttons/SaveIconButton";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { EditIconButton } from "../../shared/Buttons/EditIconButton";
import { DeleteIconButton } from "../../shared/Buttons/DeleteIconButton";
import { CustomIconButton } from "../../shared/Buttons/CustomIconButton";
import { LessonBaseBlockInnerProps } from "../LessonBlockItem/LessonBlockItem";
import { BlockInputLesson } from "./Style";
import { LanguageSwitch } from "../../shared/LanguageSwitch/LanguageSwitch";
import { languages } from "../../../constants/language";
import { CreatedListItem } from "../LessonBlockItem/CreatedListItem";

const InitialContentLanguage = {
  EN: "",
  UKR: "",
};

export const ListBlock = ({
  disabled,
  block,
  createListItem,
  updateListItem,
  deleteListItem,
  successRequest,
}: LessonBaseBlockInnerProps) => {
  const { lists, id, type } = block;

  const [language, setLanguage] = useState("UKR");
  const [content, setContent] = useState(InitialContentLanguage);
  const [isNumbered, setNumbered] = useState(true);
  const [chosenItemId, setChosenItemId] = useState("");
  const [isCreating, setCreating] = useState(false);

  useEffect(() => {
    if (successRequest) {
      setContent({ UKR: "", EN: "" });
      setChosenItemId("");
      setNumbered(true);
      setCreating(false);
    }
  }, [successRequest]);

  useEffect(() => {
    if (disabled) {
      setContent({ UKR: "", EN: "" });
      setChosenItemId("");
      setNumbered(true);
      setCreating(false);
    }
  }, [disabled]);

  return (
    <>
      {!!lists.length &&
        lists.map((item, i) => (
          <CreatedListItem
            type={type}
            key={item.id}
            disabled={disabled}
            listItem={item}
            isCreating={isCreating}
            deleteListItem={deleteListItem}
            updateListItem={updateListItem}
            chosenItemId={chosenItemId}
            setChosenItemId={setChosenItemId}
            content={content}
            setContent={setContent}
            index={i}
          />
        ))}

      <Box display="flex" alignItems="center">
        <BlockInputLesson
          fullWidth
          disabled={disabled || !!chosenItemId}
          type={type}
          placeholder="Создать пункт списка"
          value={chosenItemId ? "" : content[language]}
          onFocus={() => setCreating(true)}
          onBlur={() => setCreating(false)}
          onChange={({ target: { value } }) =>
            setContent({ ...content, [language]: value })
          }
          InputProps={{
            startAdornment: isNumbered ? (
              <InputAdornment position="end">{`${lists.length + 1}. `}</InputAdornment>
            ) : (
              <RadioButtonUnchecked style={{ fontSize: "8px" }} />
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Collapse in={!disabled}>
                  <>
                    <CustomIconButton
                      callback={() => setNumbered(!isNumbered)}
                      disabled={disabled || !!chosenItemId}
                      children={
                        isNumbered ? (
                          <FormatListNumbered />
                        ) : (
                          <FormatListBulleted />
                        )
                      }
                      mainColor="black"
                    />

                    <SaveIconButton
                      save={() => createListItem(id, isNumbered, content.UKR, content.EN)}
                      disabled={disabled || !!chosenItemId}
                    />
                  </>
                </Collapse>
              </InputAdornment>
            ),
          }}
        />

        <LanguageSwitch
          switchLanguage={setLanguage}
          languages={languages}
          disabled={disabled || !!chosenItemId}
        />
      </Box>
    </>
  );
};
