import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  GetUsersForSchoolDocument,
  GetUsersForSchoolQuery,
  User,
  UserRoleEnum,
} from "../../../generated/graphql";
import { useRequestError } from "../../../hooks";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Chip, IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { LoadingProgress } from "../../shared/LoadingProgress";
import { QUERY_LIMIT } from "../constants";
import { useDebounce } from "../../shared/Utils/OtherOperations/useDebounce";
import { useInfiniteScrollForQuery } from "../hooks";
import { StyledTextField } from "../Style";

import { primary } from "../../shared/Style/Style";

interface SelectAdminsProps {
  admins: User[];
  inputError: boolean;
  setAdmins: (admins: User[]) => void;
}

const deleteIcon = (
  <IconButton>
    <CancelIcon />
  </IconButton>
);

export const SelectAdmins = ({
  admins = [],
  inputError,
  setAdmins,
}: SelectAdminsProps) => {
  const [searchKey, setSearchKey] = useState("");
  const debouncedValue = useDebounce(searchKey, 1200);

  const variables = useMemo(
    () => ({
      skip: 0,
      roles: [UserRoleEnum.Teacher],
      searchKey: debouncedValue,
    }),
    [debouncedValue]
  );

  const {
    items: users,
    loading,
    error,
    handleScroll,
  } = useInfiniteScrollForQuery<GetUsersForSchoolQuery>({
    query: GetUsersForSchoolDocument,
    variables,
    key: "getUsersData",
    getItems: (data) => data.getUsers?.users || [],
    limit: QUERY_LIMIT,
    limitKey: "limit",
  });

  const handleSetAdmins = (adminsIds: any[]) => {
    setAdmins(adminsIds);
  };

  const handleRemoveAdmin = (adminId: string) => {
    const adminsToSet = admins.filter(({ id }) => id !== adminId);
    setAdmins(adminsToSet);
  };

  const { requestError, setRequestError, errorMessage } = useRequestError(
    error,
    "При загрузке списка пользователей произошла ошибка"
  );

  return (
    <>
      <Autocomplete
        multiple
        filterSelectedOptions
        getOptionSelected={(option, value) => option.id === value.id}
        options={users}
        getOptionLabel={({ firstName, lastName, email }) =>
          `${firstName} ${lastName} | ${email}`
        }
        onChange={(_, value) => {
          handleSetAdmins(value);
          setSearchKey("");
        }}
        value={admins}
        ListboxProps={{
          onScroll: handleScroll,
        }}
        inputValue={searchKey}
        noOptionsText={"Администратор с данной почтой не найден"}
        renderTags={(value: any[], getTagProps) => {
          return value.map((tagData: any, index: number) => (
            <Chip
              variant="outlined"
              label={
                <Link
                  style={{ color: primary }}
                  to={`/mobileAppUsers/${tagData.id}/editUser`}
                >
                  {tagData.lastName}
                </Link>
              }
              deleteIcon={deleteIcon}
              style={{ maxWidth: "200px" }}
              {...getTagProps({ index })}
              onDelete={() => handleRemoveAdmin(tagData.id)}
            />
          ));
        }}
        renderInput={(params) => (
          <>
            <StyledTextField
              label="Администраторы"
              variant="filled"
              error={inputError}
              onChange={({ target: { value: inputValue } }) =>
                setSearchKey(inputValue)
              }
              {...params}
            />
            {loading && <LoadingProgress />}
          </>
        )}
      />

      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
    </>
  );
};
