import React, { ChangeEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  AssignUsersToProjectDocument,
  GetUsersWithoutProjectDocument,
  AssignUsersToProjectMutation,
  User,
  GetUsersWithoutProjectQuery,
  GetProjectDocument,
} from "../../../generated/graphql";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListItem from "@material-ui/core/ListItem";
import FormControl from "@material-ui/core/FormControl";
import { Box, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PersonalData } from "../UserEdit/UserEdit.style";
import {
  ButtonCustom,
  ButtonCustomBackgroundTransparent,
  ButtonCustomWithoutBoxShadowCancel,
  useProjectFormStyles,
} from "../../shared/Style/Style";
import {
  AddContactContainer,
  AddContactList,
  Backdrop,
  ContactListContainer,
} from "./ProjectModal.style";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";

interface ProjectAddContactProps {
  projectId: string;
  open(e: boolean): void;
}

interface Contact {
  title: string;
  id: string;
  check: boolean;
}

export const ProjectAddContact = ({
  projectId,
  open,
}: ProjectAddContactProps) => {
  const [requestError, setRequestError] = useState(false);
  const [contactIsChecked, setContactIsChecked] = useState<Contact[] | []>([]);
  const [idsForRequest, setIdsForRequest] = useState<string[] | []>([]);
  const [skip, setSkip] = useState<number>(0);

  const {
    data: usersWithoutProjectData,
    loading: usersWithoutProjectDataLoading,
    error: usersWithoutProjectDataError,
  } = useQuery<GetUsersWithoutProjectQuery>(GetUsersWithoutProjectDocument, {
    variables: {
      projectId,
      skip: skip,
      limit: 4,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const [
    assignUsersToProject,
    {
      data: assignUserData,
      loading: assignUserLoading,
      error: assignUserError,
    },
  ] = useMutation<AssignUsersToProjectMutation>(AssignUsersToProjectDocument);

  const classes = useProjectFormStyles();

  useEffect(() => {
    const usersWithoutProjects =
      usersWithoutProjectData?.getUsersWithoutProject?.users;

    if (usersWithoutProjects && usersWithoutProjects.length) {
      const createUser = usersWithoutProjects.reduce(
        (state: any, user: User) => {
          return [
            ...state,
            {
              title: `${user?.firstName} ${user?.lastName}`,
              id: user?.id,
              check: false,
            },
          ];
        },
        []
      );

      setContactIsChecked([...contactIsChecked, ...createUser]);
    }
  }, [usersWithoutProjectData?.getUsersWithoutProject?.users]);

  useEffect(() => {
    if (usersWithoutProjectDataError || assignUserError) {
      setRequestError(true);
    }
  }, [usersWithoutProjectDataError, assignUserError]);

  useEffect(() => {
    if (assignUserData) {
      open(false);
    }
  }, [assignUserData]);

  let content: JSX.Element;

  if (usersWithoutProjectDataLoading || assignUserLoading) {
    content = (
      <Backdrop style={{ color: "#FFB61D" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (contactIsChecked.length) {
    content = (
      <ContactListContainer>
        <FormGroup aria-label="position">
          {contactIsChecked.map(({ title, id, check }, index) => (
            <ListItem button key={title}>
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    onChange={(e) => onCheck(e)}
                    checked={check}
                    color="default"
                    name={id}
                  />
                }
                label={`${index + 1}. ${title}`}
              />
            </ListItem>
          ))}
        </FormGroup>
      </ContactListContainer>
    );
  }

  const onCheck = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { name, checked },
    } = event;

    setContactIsChecked(
      contactIsChecked.map(({ title, id, check }) => {
        if (id === name) {
          return {
            title,
            id,
            check: checked,
          };
        }

        return {
          title,
          id,
          check,
        };
      })
    );

    if (checked) {
      setIdsForRequest([...idsForRequest, name]);
    } else {
      setIdsForRequest(idsForRequest.filter((project) => project !== name));
    }
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    await assignUsersToProject({
      variables: {
        userIds: idsForRequest,
        projectId,
      },
      refetchQueries: [{ query: GetProjectDocument, variables: { projectId } }],
    });
  };

  const total = usersWithoutProjectData?.getUsersWithoutProject?.total;

  const getMoreUsers = (): void => {
    total > skip && setSkip(skip + 4);
  };

  return (
    <>
      <AddContactContainer>
        <AddContactList>
          <Box component="div" width="100%">
            <Typography
              component={PersonalData}
              variant="subtitle1"
              gutterBottom
            >
              Сотрудники
            </Typography>

            <form onSubmit={handleSubmit} className={classes.root}>
              <FormControl
                component="fieldset"
                className={classes.rootFormControl}
              >
                {content}
              </FormControl>
              {total > 4 && contactIsChecked.length !== total && (
                <Box mb={2} mt={-1}>
                  <ButtonCustomBackgroundTransparent onClick={getMoreUsers}>
                    Показать ещё сотрудников...
                  </ButtonCustomBackgroundTransparent>
                </Box>
              )}

              <Box display="flex">
                <ButtonCustomWithoutBoxShadowCancel
                  style={{ marginRight: "20px" }}
                  onClick={() => open(false)}
                >
                  Отмена
                </ButtonCustomWithoutBoxShadowCancel>
                <ButtonCustom type="submit">Добавить</ButtonCustom>
              </Box>
            </form>
          </Box>
        </AddContactList>
      </AddContactContainer>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={
          (usersWithoutProjectDataError &&
            "Ошибка при получении сотрудников") ||
          (assignUserError && "Ошибка при добавлении сотрудников")
        }
      />
    </>
  );
};
