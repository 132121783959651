import React, { useEffect, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ClientSiteModel,
  CreateClientSiteModelItemMutation,
  CreateClientSiteModelItemDocument,
  GetClientSiteModelItemsByModelIdDocument,
  GetClientSiteModelItemsByModelIdQuery,
  ClientSiteModelItem,
  UpdateClientSiteModelItemDocument,
  UpdateClientSiteModelItemMutation,
  DeleteClientSiteModelItemMutation,
  DeleteClientSiteModelItemDocument,
} from "../../../../../generated/graphql";

import { Box, CircularProgress, Collapse, TextField } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

import { CustomIconButton } from "../../../../shared/Buttons/CustomIconButton";
import { SaveIconButton } from "../../../../shared/Buttons/SaveIconButton";
import { ButtonPlusSmall, LoadingBox, primary, red, useStyles } from "../../../../shared/Style/Style";
import { EditIconButton } from "../../../../shared/Buttons/EditIconButton";
import { DeleteIconButton } from "../../../../shared/Buttons/DeleteIconButton";

import { ModelDataErrors } from "./constants";


interface IModelItemsListProps {
  clientSiteModelId?: string;
  disabled: boolean;
  modelData: ClientSiteModel;
  itemIds: string[];
  setItemIds(itemIds: string[]): void;
  modelDataError: ModelDataErrors;
  setModelDataError(errors: ModelDataErrors): void;
  requestErrorHandler(message: string): void;
  requestSuccessHandler(): void;
}


export const ModelItemsList = ({
  clientSiteModelId,
  disabled,
  modelData,
  itemIds,
  setItemIds,
  modelDataError,
  setModelDataError,
  requestErrorHandler,
  requestSuccessHandler,
}: IModelItemsListProps) => {
  const classes = useStyles();

  const [isItemCreating, setItemCreating] = useState(false);
  const [editedItem, setEditedItem] = useState<ClientSiteModelItem>(null);
  const [editedItemIndex, setEditedItemIndex] = useState('');

  const [itemId, setItemId] = useState('');


  const [getItems, {
    data: getItemsData,
    loading: getItemsLoading,
    error: getItemsError
  }] = useLazyQuery<GetClientSiteModelItemsByModelIdQuery>(GetClientSiteModelItemsByModelIdDocument, {
    variables: { clientSiteModelId }
  });

  const [updateItem, {
    data: updateItemData,
    loading: updateItemLoading,
  }] = useMutation<UpdateClientSiteModelItemMutation>(UpdateClientSiteModelItemDocument, {
    refetchQueries: [{ query: GetClientSiteModelItemsByModelIdDocument, variables: { clientSiteModelId }}]
  });


  const updateItemHandler = async (updatedIndex?: number) => {
    if (!itemId) {
      return;
    }

    if (!clientSiteModelId) {
      itemIds[updatedIndex] = itemId;
      setItemId('');
      return;
    }

    try {
      await updateItem({ variables: {
        id: editedItem?.id,
        itemId
      }})
    } catch (e) {
      requestErrorHandler('При изменении id товара произошла ошибка')
    }
  };

  const [createItem, {
    data: createItemData,
    loading: createItemLoading,
  }] = useMutation<CreateClientSiteModelItemMutation>(CreateClientSiteModelItemDocument, {
    refetchQueries: [{ query: GetClientSiteModelItemsByModelIdDocument, variables: { clientSiteModelId }}]
  });


  const createItemHandler = async () => {
    if (!itemId) {
      setModelDataError({ ...modelDataError, itemIdError: "Обязательное поле" })
      return;
    }

    if (!clientSiteModelId) {
      setItemIds([ ...itemIds, itemId ]);
      setItemCreating(false);
      setItemId('');
      return;
    }

    try {
      await createItem({ variables: {
        clientSiteModelId,
        itemId
      }})
    } catch (e) {
      requestErrorHandler('При создании id товара произошла ошибка')
    }
  };


  const [deleteItem, {
    data: deleteItemData,
    loading: deleteItemLoading,
  }] = useMutation<DeleteClientSiteModelItemMutation>(DeleteClientSiteModelItemDocument, {
    refetchQueries: [{ query: GetClientSiteModelItemsByModelIdDocument, variables: { clientSiteModelId }}]
  });


  const deleteItemHandler = async (id: string) => {
    if (!clientSiteModelId) {
      console.log(id);

      setItemIds(itemIds.filter(item => item !== id));
      return;
    }

    try {
      await deleteItem({ variables: {
        id
      }})
    } catch (e) {
      requestErrorHandler('При удалении id товара произошла ошибка')
    }
  };


  //#region effects

  useEffect(() => {
    if (editedItemIndex) {
      setItemId(itemIds[editedItemIndex]);
    }
  }, [editedItemIndex]);

  useEffect(() => {
    if (editedItem) {
      setItemId(editedItem.itemId);
    }
  }, [editedItem]);

  useEffect(() => {
    if (clientSiteModelId) {
      getItems();
    }
  }, [clientSiteModelId]);

  useEffect(() => {
    if (createItemData) {
      setItemCreating(false);
      setItemId('');
      requestSuccessHandler();
    }
  }, [createItemData]);

  useEffect(() => {
    if (updateItemData) {
      setEditedItem(null);
      setItemId('');
      requestSuccessHandler();
    }
  }, [updateItemData]);

  useEffect(() => {
    if (deleteItemData) {
      requestSuccessHandler();
    }
  }, [deleteItemData]);

  //#endregion

  const disabledOnLoading = deleteItemLoading || updateItemLoading || createItemLoading;

  let itemsList: JSX.Element;

  if (!clientSiteModelId) {
    itemsList = (
      <>
        {itemIds.length
          ? (itemIds.map((id, i) => (
            <Box
              key={id}
            >
              <TextField
                disabled={disabled || disabledOnLoading || `${i}` !== editedItemIndex}
                className={classes.root}
                fullWidth
                variant="filled"
                type="text"
                label={"id товара"}
                value={`${i}` === editedItemIndex ? itemId : id }
                onChange={({ target: { value } }) =>
                  setItemId(value)
                }
                InputLabelProps={{
                  shrink: !!modelData.name || !modelData.id,
                }}
                InputProps={{
                  endAdornment: (
                    <Box
                      display='flex'
                    >
                      {`${i}` !== editedItemIndex
                        ? (
                          <EditIconButton
                            disabled={isItemCreating}
                            edit={() => {
                              setEditedItemIndex(`${i}`);
                            }}
                          />
                        )
                        : (
                          <SaveIconButton
                            disabled={isItemCreating}
                            save={() => {
                              updateItemHandler(i);
                              setEditedItemIndex('');
                              setItemId('');
                            }}
                          />
                        )
                      }

                      <DeleteIconButton
                        disabled={isItemCreating}
                        deleteHandler={() => deleteItemHandler(id)}
                        item='id товара'
                      />
                    </Box>
                  )
                }}
              />
            </Box>
          )))
          : (
            <Box
              m='10px 0'
              textAlign='center'
              color={(!clientSiteModelId && !itemIds.length) ? red : primary}
            >
              Список id товаров пуст
            </Box>
          )
        }
      </>
    )
  }

  if (getItemsLoading) {
    itemsList = (
      <LoadingBox>
        <CircularProgress color='inherit' />
      </LoadingBox>
    )
  }

  if (getItemsError) {
    itemsList = <div>При загрузке списка id товаров произошла ошибка</div>
  }

  if (getItemsData) {
    const items = getItemsData.getClientSiteModelItemsByModelId;

    itemsList = (
      <>
        {items.length
          ? (items.map(item => (
            <Box
              key={item.id}
            >
              <TextField
                disabled={disabled || disabledOnLoading || item.id !== editedItem?.id}
                className={classes.root}
                fullWidth
                variant="filled"
                type="text"
                label={"id товара"}
                value={item.id === editedItem?.id ? itemId : item.itemId }
                onChange={({ target: { value } }) =>
                  setItemId(value)
                }
                InputLabelProps={{
                  shrink: !!modelData.name || !modelData.id,
                }}
                InputProps={{
                  endAdornment: (
                    <Box
                      display='flex'
                    >
                      {editedItem?.id !== item.id
                        ? (
                          <EditIconButton
                            disabled={
                              disabled ||
                              disabledOnLoading ||
                              isItemCreating ||
                              (editedItem && item.id !== editedItem?.id)
                            }
                            edit={() => {
                              setEditedItem(item);
                            }}
                          />
                        )
                        : (
                          <SaveIconButton
                            disabled={disabled || disabledOnLoading}
                            save={() => updateItemHandler()}
                          />
                        )
                      }

                      <DeleteIconButton
                        disabled={
                          disabled ||
                          disabledOnLoading ||
                          isItemCreating ||
                          !!editedItem ||
                          items.length < 2
                        }
                        deleteHandler={() => deleteItemHandler(item.id)}
                        item='id товара'
                      />
                    </Box>
                  )
                }}
              />
            </Box>
          )))
          : (
            <Box>Список id товаров пуст</Box>
          )
        }
      </>
    )
  }


  return (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        fontWeight='bold'
        fontSize='16px'
      >
        ID ТОВАРОВ

        <ButtonPlusSmall
          disabled={disabled || disabledOnLoading || isItemCreating || !!editedItem}
          onClick={() => setItemCreating(true)}
        />
      </Box>

      {itemsList}

      <Collapse
        in={isItemCreating}
      >
        <TextField
          disabled={disabled}
          className={classes.root}
          fullWidth
          variant="filled"
          type="text"
          label={"id товара"}
          value={itemId}
          onChange={({ target: { value } }) =>
            setItemId(value)
          }
          onFocus={() =>
            setModelDataError({ ...modelDataError, itemIdError: "" })
          }
          error={!!modelDataError.itemIdError}
          helperText={modelDataError.itemIdError}
          InputLabelProps={{
            shrink: !!modelData.name || !modelData.id,
          }}
          InputProps={{
            endAdornment: (
              <Box
                display='flex'
              >
                <SaveIconButton
                  save={createItemHandler}
                />

                <CustomIconButton
                  children={<Cancel color='inherit'/>}
                  mainColor={red}
                  callback={() => {
                    setItemCreating(false);
                    setItemId('');
                  }}
                />
              </Box>
            )
          }}
        />
      </Collapse>
    </>
  )
}
