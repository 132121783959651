import React, { useCallback, useEffect, useRef, useState } from "react";

import { useParams } from "react-router";

import { useMutation, useQuery } from "@apollo/client";
import {
  AddApplicationMainThemePhotoDocument,
  AddApplicationMainThemePhotoMutation,
  ApplicationMain,
  ApplicationMainAvailableForEnum,
  CreateApplicationMainThemeDocument,
  CreateApplicationMainThemeMutation,
  DeleteApplicationMainIconDocument,
  DeleteApplicationMainIconMutation,
  DeleteApplicationMainThemeDocument,
  DeleteApplicationMainThemeMutation,
  DeleteApplicationMainThemePhotoDocument,
  GetApplicationMainDocument,
  GetApplicationMainQuery,
  GetAppMainThemeBackgroundsDocument,
  GetAppMainThemeBackgroundsQuery,
  SetAccessToApplicationMainDocument,
  SetAccessToApplicationMainMutation,
  SubscriptionPlanStatusEnum,
  UpdateApplicationMainDocument,
  UpdateApplicationMainMutation,
  UpdateApplicationMainPhotoDocument,
  UpdateApplicationMainPhotoMutation,
  UpdateApplicationMainThemeDocument,
  UpdateApplicationMainThemeMutation,
} from "../../generated/graphql";

import { ThemeProvider } from "@material-ui/styles";

import {
  Box,
  Chip,
  CircularProgress,
  Collapse,
  Container,
  Divider,
  FormControlLabel,
  Paper,
} from "@material-ui/core";
import {
  ButtonCustom,
  ButtonPlusSmall,
  defaultMaterialTheme,
  Header,
  HiddenInput,
  LoadingBox,
} from "../shared/Style/Style";

import {
  MobileAppInput,
  MobileAppSubtitle,
  MobileAppInputTitle,
} from "./MobileAppPageEdit.style";

import { NavBar } from "../shared/NavBar";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { AvatarPicker } from "../shared/AvatarPicker";
import { CustomRadioGroup } from "../shared/CustomRadioGroup";

import { MobileAppAccessModal } from "../MobileAppModal/MobileAppAccessModal";
import { ParticipantsHiddenPart } from "./ParticipantsHiddenPart";
import { MobileAppQrModal } from "../MobileAppModal/MobileAppQrModal";
import { SaveIconButton } from "../shared/Buttons/SaveIconButton";
import { DeleteIconButton } from "../shared/Buttons/DeleteIconButton";
import { languages } from "../../constants/language";
import { navItemsMobileAppsMainPages } from "../../constants/navItemMobileApp";
import { LanguageSwitch } from "../shared/LanguageSwitch/LanguageSwitch";
import { ThemeItem } from "./ThemeItem/ThemeItem";
import { CustomIconButton } from "../shared/Buttons/CustomIconButton";
import { Image } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { SelectSubscriptionsPlan } from "./Selectors/SelectSubscriptionsPlan";
import { CustomSwitch } from "../ParticipantsModal/ParticipantsModal.style";

const InitialErrors: { [key: string]: boolean } = {
  nameUKR: false,
  nameEN: false,
  name: false,
};

const InitialNames: { [key: string]: string } = {
  nameUKR: "",
  nameEN: "",
  name: "",
};

export const MobileAppPageEdit = () => {
  const { pageId } = useParams<{ pageId: string }>();

  const [editingData, setEditingData] = useState<ApplicationMain>(null);
  const [accessModal, setAccessModal] = useState(false);
  const [qrModal, setQrModal] = useState(false);

  const [isThemeCreating, setThemeCreating] = useState(false);
  const [newThemeName, setNewThemeName] = useState(InitialNames);

  const [langTheme, setLangTheme] = useState("UKR");
  const [language, setLanguage] = useState("UKR");

  const [themeError, setThemeError] = useState(InitialErrors);
  const [errors, setErrors] = useState(InitialErrors);
  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fileIcon, setFileIcon] = useState(null);
  const fileInput = useRef<HTMLInputElement>(null);

  //#region photo

  const [
    updatePhoto,
    { loading: updatePhotoLoading, error: updatePhotoError },
  ] = useMutation<UpdateApplicationMainPhotoMutation>(
    UpdateApplicationMainPhotoDocument,
    {
      refetchQueries: [
        { query: GetApplicationMainDocument, variables: { id: pageId } },
      ],
    }
  );

  //#endregion

  //#region update

  const [updateMain, { loading: updateMainLoading, error: updateMainError }] =
    useMutation<UpdateApplicationMainMutation>(UpdateApplicationMainDocument, {
      variables: {
        applicationMainData: {
          id: editingData?.id,
          name: editingData?.name,
          description: editingData?.description,
          isMap: editingData?.isMap,
          background1: editingData?.background1,
          background2: editingData?.background2,
          background3: editingData?.background3,
          background4: editingData?.background4,
          background5: editingData?.background5,
          display: editingData?.display,
          priority: editingData?.priority,
          showInArBook: editingData?.showInArBook,
          analyticsMark: editingData?.analyticsMark,
          subscriptionPlan: editingData?.subscriptionPlan,
        },
      },
      refetchQueries: [
        { query: GetApplicationMainDocument, variables: { id: pageId } },
      ],
    });

  const updateMainHandler = async () => {
    if (!editingData.name) {
      setErrors({ ...errors, name: true });
      return;
    }

    await updateMain();
  };

  //#endregion

  //#region access

  const [setAccess, { loading: setAccessLoading, error: setAccessError }] =
    useMutation<SetAccessToApplicationMainMutation>(
      SetAccessToApplicationMainDocument,
      {
        refetchQueries: [
          { query: GetApplicationMainDocument, variables: { id: pageId } },
        ],
      }
    );

  //#endregion

  //#region theme

  const [createTheme, { data: createThemeData, loading: createThemeLoading }] =
    useMutation<CreateApplicationMainThemeMutation>(
      CreateApplicationMainThemeDocument,
      {
        refetchQueries: [
          { query: GetApplicationMainDocument, variables: { id: pageId } },
        ],
      }
    );

  const createThemeHandler = async () => {
    try {
      if (
        !mainData?.getApplicationMain?.id ||
        !newThemeName[`name${langTheme}`]
      ) {
        setThemeError({ ...themeError, [`name${langTheme}`]: true });
        return;
      }
      await createTheme({
        variables: {
          applicationMainId: mainData?.getApplicationMain?.id,
          [`name${langTheme}`]: newThemeName[`name${langTheme}`],
        },
      });
    } catch (e) {
      setRequestError(true);
      setErrorMessage("При создании темы произошла ошибка");
    }
  };

  const [updateTheme, { data: updateThemeData, loading: updateThemeLoading }] =
    useMutation<UpdateApplicationMainThemeMutation>(
      UpdateApplicationMainThemeDocument,
      {
        refetchQueries: [
          { query: GetApplicationMainDocument, variables: { id: pageId } },
        ],
      }
    );

  const [deleteTheme, { data: deleteThemeData, loading: deleteThemeLoading }] =
    useMutation<DeleteApplicationMainThemeMutation>(
      DeleteApplicationMainThemeDocument,
      {
        refetchQueries: [
          { query: GetApplicationMainDocument, variables: { id: pageId } },
        ],
      }
    );

  const [
    addThemePhoto,
    {
      data: addThemePhotoData,
      loading: addThemePhotoLoading,
      error: addThemePhotoError,
    },
  ] = useMutation<AddApplicationMainThemePhotoMutation>(
    AddApplicationMainThemePhotoDocument,
    {
      refetchQueries: [
        { query: GetApplicationMainDocument, variables: { id: pageId } },
      ],
      notifyOnNetworkStatusChange: true,
    }
  );

  const [
    deleteThemePhoto,
    {
      data: deleteThemePhotoData,
      loading: deleteThemePhotoLoading,
      error: deleteThemePhotoError,
    },
  ] = useMutation<DeleteApplicationMainThemeMutation>(
    DeleteApplicationMainThemePhotoDocument,
    {
      refetchQueries: [
        { query: GetApplicationMainDocument, variables: { id: pageId } },
      ],
    }
  );
  const [
    deleteApplicationMainIcon,
    {
      loading: deleteApplicationMainIconLoading,
      error: deleteApplicationMainIconError,
    },
  ] = useMutation<DeleteApplicationMainIconMutation>(
    DeleteApplicationMainIconDocument,
    {
      refetchQueries: [
        { query: GetApplicationMainDocument, variables: { id: pageId } },
      ],
    }
  );

  //#endregion

  const {
    data: mainData,
    loading: mainLoading,
    error: mainError,
  } = useQuery<GetApplicationMainQuery>(GetApplicationMainDocument, {
    variables: {
      id: pageId,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: backgroundsData,
    loading: backgroundsLoading,
    error: backgroundsError,
  } = useQuery<GetAppMainThemeBackgroundsQuery>(
    GetAppMainThemeBackgroundsDocument,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  //#region effects

  useEffect(() => {
    if (mainData) {
      setEditingData(mainData.getApplicationMain);
    }
  }, [mainData]);

  useEffect(() => {
    if (
      editingData?.availableFor !==
        mainData?.getApplicationMain?.availableFor &&
      editingData?.availableFor === ApplicationMainAvailableForEnum.All
    ) {
      setAccess({
        variables: {
          applicationMainData: {
            applicationMainId: editingData?.id,
            availableFor: editingData?.availableFor,
          },
        },
      });
    }
  }, [editingData?.availableFor]);

  useEffect(() => {
    if (
      createThemeData ||
      updateThemeData ||
      deleteThemeData ||
      addThemePhotoData ||
      deleteThemePhotoData
    ) {
      setRequestError(false);
      setErrorMessage("");
    }
  }, [createThemeData, updateThemeData, deleteThemeData]);

  useEffect(() => {
    if (createThemeData) {
      setNewThemeName({ nameUKR: "", nameEN: "" });
      setThemeCreating(false);
    }
  }, [createThemeData]);

  useEffect(() => {
    if (updateMainError) {
      setRequestError(true);
      setErrorMessage(
        "При обновлении информации о странице приложения произошла ошибка"
      );
    }
  }, [updateMainError]);

  useEffect(() => {
    if (updatePhotoError || setAccessError) {
      setRequestError(true);
      setErrorMessage("При обновлении фото произошла ошибка");
    }
  }, [updatePhotoError, setAccessError]);

  useEffect(() => {
    if (errors.nameUKR || errors.nameEN) {
      setErrors({ ...errors, nameUKR: false, nameEN: false });
    }
  }, [language]);

  useEffect(() => {
    if (themeError[`name${langTheme}`]) {
      setThemeError({ ...errors, [`name${langTheme}`]: false });
    }
  }, [langTheme]);

  useEffect(() => {
    if (fileIcon) {
      updatePhoto({
        variables: {
          applicationMainId: pageId,
          icon: fileIcon,
        },
      });
    }
  }, [fileIcon]);

  const subscriptionAccessHandler = useCallback(
    (subscriptionPlan: SubscriptionPlanStatusEnum) => {
      setEditingData((prev) => ({ ...prev, subscriptionPlan }));
    },
    []
  );

  //#endregion

  const disableOnLoading =
    updateMainLoading ||
    updatePhotoLoading ||
    setAccessLoading ||
    createThemeLoading ||
    updateThemeLoading ||
    deleteThemeLoading ||
    deleteThemePhotoLoading ||
    addThemePhotoLoading ||
    deleteApplicationMainIconLoading;

  let content;

  if (mainLoading || backgroundsLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (mainError || backgroundsError) {
    content = <ErrorSnackbar error={mainError || backgroundsError} />;
  }

  if (editingData && backgroundsData) {
    const {
      photo,
      isMap,
      availableFor,
      background1,
      background2,
      background3,
      background4,
      background5,
      display,
      participants,
      priority,
      analyticsMark,
      subscriptionPlan,
    } = editingData;

    const themes = editingData.themes;

    const backgrounds = backgroundsData.getAppMainThemeBackgrounds;

    let accessHiddenPart;

    switch (availableFor) {
      case ApplicationMainAvailableForEnum.All:
        accessHiddenPart = <div></div>;
        break;

      case ApplicationMainAvailableForEnum.Clients:
        accessHiddenPart = (
          <ParticipantsHiddenPart
            participants={participants}
            setAccessModal={setAccessModal}
          />
        );
        break;

      case ApplicationMainAvailableForEnum.Qr:
        accessHiddenPart = (
          <Box textAlign="center" mt={4}>
            <ButtonCustom onClick={() => setQrModal(true)}>
              Сгенерировать QR код
            </ButtonCustom>

            <Box mt={2}>
              {editingData.qr && <img src={editingData.qr} alt="QR код" />}
            </Box>
          </Box>
        );
        break;

      default:
        accessHiddenPart = <div></div>;
        break;
    }

    const addCircuitIcon = (e) => {
      const icon = e.target.files[0];
      setFileIcon(icon);
    };

    const deleteCircuitIcon = async () => {
      try {
        await deleteApplicationMainIcon({
          variables: {
            applicationMainId: pageId,
          },
        });
      } catch (e) {
        setRequestError(true);
        setErrorMessage("При удалении картинки произошла ошибка");
      }
    };

    content = (
      <Box
        display="flex"
        justifyContent="space-between"
        padding={"20px 30px 0 30px"}
      >
        <Box mr={2} flexBasis={"50%"}>
          <MobileAppSubtitle>Основная информация</MobileAppSubtitle>

          <Box mb={2} display="flex" justifyContent="space-between">
            <Box
              mr={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <AvatarPicker
                disabled={disableOnLoading}
                disableResize={true}
                photo={photo}
                uploadHandler={(newPhoto) =>
                  updatePhoto({
                    variables: {
                      applicationMainId: pageId,
                      photo: newPhoto,
                    },
                  })
                }
              />
            </Box>

            <Box>
              <LanguageSwitch
                switchLanguage={setLanguage}
                error={errors}
                disabled={disableOnLoading}
                languages={languages}
              />

              <MobileAppInput
                label="Название"
                id="name"
                fullWidth
                disabled={disableOnLoading}
                value={editingData.name || ""}
                error={errors.name}
                onChange={({ target: { value } }) =>
                  setEditingData({ ...editingData, name: value })
                }
                onFocus={() =>
                  setErrors({ ...errors, name: false })
                }
              />

              <MobileAppInput
                label="Описание"
                id="description"
                disabled={disableOnLoading}
                value={editingData.description || ""}
                onChange={({ target: { value } }) =>
                  setEditingData({
                    ...editingData,
                    description: value,
                  })
                }
                fullWidth
                multiline
                rows={6}
              />
            </Box>
          </Box>

          <MobileAppInputTitle>Фон</MobileAppInputTitle>

          <Box display="flex" justifyContent="space-between" mb={2}>
            <Box mr={2} flexBasis={"50%"}>
              <MobileAppInput
                fullWidth
                disabled={disableOnLoading}
                value={background1}
                onChange={({ target: { value } }) =>
                  setEditingData({ ...editingData, background1: value })
                }
              />
            </Box>

            <Box flexBasis={"50%"}>
              <MobileAppInput
                fullWidth
                disabled={disableOnLoading}
                value={background2}
                onChange={({ target: { value } }) =>
                  setEditingData({ ...editingData, background2: value })
                }
              />
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" mb={2}>
            <Box mr={2} flexBasis={"50%"}>
              <MobileAppInput
                fullWidth
                disabled={disableOnLoading}
                value={background3}
                onChange={({ target: { value } }) =>
                  setEditingData({ ...editingData, background3: value })
                }
              />
            </Box>

            <Box flexBasis={"50%"}>
              <MobileAppInput
                fullWidth
                disabled={disableOnLoading}
                value={background4}
                onChange={({ target: { value } }) =>
                  setEditingData({ ...editingData, background4: value })
                }
              />
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" mb={2}>
            <Box mr={2} flexBasis={"50%"}>
              <MobileAppInput
                fullWidth
                disabled={disableOnLoading}
                value={background5}
                onChange={({ target: { value } }) =>
                  setEditingData({ ...editingData, background5: value })
                }
              />
            </Box>
          </Box>

          <MobileAppInputTitle>Карта</MobileAppInputTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <CustomRadioGroup
              items={[
                { label: "Да", entity: true },
                { label: "Нет", entity: false },
              ]}
              chosenItem={isMap}
              state={editingData}
              setNewState={setEditingData}
              fieldName={"isMap"}
              horizontal={true}
              disabled={disableOnLoading}
            />
          </Box>
          <MobileAppInputTitle>Отображение</MobileAppInputTitle>

          <CustomRadioGroup
            items={[
              { label: "Да", entity: true },
              { label: "Нет", entity: false },
            ]}
            chosenItem={display}
            state={editingData}
            setNewState={setEditingData}
            fieldName={"display"}
            horizontal={true}
            disabled={disableOnLoading}
          />
          <MobileAppInputTitle>Метка</MobileAppInputTitle>
          <Box mt={2} display="flex" width="70%">
            <MobileAppInput
              fullWidth
              label="Метка"
              disabled={disableOnLoading}
              value={analyticsMark}
              onChange={({ target: { value } }) =>
                setEditingData({ ...editingData, analyticsMark: value })
              }
            />
          </Box>
          {editingData?.icon ? (
            <Collapse in={!!fileIcon || !!editingData?.icon}>
              <Box
                mt={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>Контурная картинка</Box>
                <Box display="flex" justifyContent="center">
                  <img src={editingData?.icon} width="50px" height="50px" />
                  <DeleteIconButton
                    deleteHandler={deleteCircuitIcon}
                    item="файл"
                    fz={20}
                  />
                </Box>
              </Box>
            </Collapse>
          ) : (
            <Box
              mt={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>Контурная картинка</Box>
              <CustomIconButton
                callback={() => {
                  fileInput.current.click();
                }}
                children={<Image fontSize="inherit" color="primary" />}
              />
            </Box>
          )}

          <SelectSubscriptionsPlan
            status={subscriptionPlan}
            setSubscriptionAccess={subscriptionAccessHandler}
          />
        </Box>

        <Divider orientation="vertical" flexItem />

        <Box flexBasis={"50%"} ml={2}>
          <MobileAppInputTitle>Доступ</MobileAppInputTitle>

          <CustomRadioGroup
            disabled={disableOnLoading || !!editingData.qr}
            items={[
              {
                label: "Не ограничен",
                entity: ApplicationMainAvailableForEnum.All,
              },
              {
                label: "Некоторым участникам",
                entity: ApplicationMainAvailableForEnum.Clients,
              },
              { label: "По QR", entity: ApplicationMainAvailableForEnum.Qr },
            ]}
            chosenItem={availableFor}
            state={editingData}
            setNewState={setEditingData}
            fieldName={"availableFor"}
            horizontal={true}
          />

          <Box mt="20px">
            <FormControlLabel
              style={{ display: "block" }}
              label="Отображать на главном меню"
              control={
                <CustomSwitch
                  disabled={disableOnLoading}
                  checked={editingData.showInArBook}
                  onChange={() =>
                    setEditingData((prev) => ({
                      ...prev,
                      showInArBook: !prev.showInArBook,
                    }))
                  }
                />
              }
            />
          </Box>

          <Collapse
            in={availableFor !== ApplicationMainAvailableForEnum.All}
            collapsedSize={"0px"}
            style={{
              transitionDuration: "500ms",
            }}
          >
            {accessHiddenPart}
          </Collapse>

          <MobileAppInputTitle>Приоритетность</MobileAppInputTitle>

          <MobileAppInput
            type="number"
            fullWidth
            disabled={disableOnLoading}
            value={priority}
            onChange={({ target: { value } }) =>
              setEditingData({ ...editingData, priority: +value })
            }
          />

          <MobileAppInputTitle>
            <Box
              justifyContent="space-between"
              alignItems="center"
              display="flex"
            >
              Темы
              <ButtonPlusSmall onClick={() => setThemeCreating(true)} />
            </Box>
          </MobileAppInputTitle>

          <Box>
            {!!themes.length &&
              themes.map((theme) => (
                <ThemeItem
                  key={theme.id}
                  theme={theme}
                  backgrounds={backgrounds}
                  isThemeCreating={isThemeCreating}
                  deleteTheme={deleteTheme}
                  updateTheme={updateTheme}
                  addPhoto={addThemePhoto}
                  deleteThemePhoto={deleteThemePhoto}
                  disableOnLoading={disableOnLoading}
                  setRequestError={setRequestError}
                  setErrorMessage={setErrorMessage}
                />
              ))}
          </Box>

          <Collapse in={isThemeCreating}>
            <Box display="flex">
              <MobileAppInput
                fullWidth
                disabled={disableOnLoading}
                value={newThemeName[`name${langTheme}`]}
                onChange={({ target: { value } }) =>
                  setNewThemeName({
                    ...newThemeName,
                    [`name${langTheme}`]: value,
                  })
                }
                InputProps={{
                  endAdornment: (
                    <Box display="flex">
                      <DeleteIconButton
                        fz={20}
                        disabled={disableOnLoading}
                        deleteHandler={() => {
                          setNewThemeName({ nameUKR: "", nameEN: "" });
                          setThemeCreating(false);
                        }}
                        item="название темы"
                      />
                      <SaveIconButton
                        fz={20}
                        disabled={disableOnLoading}
                        save={() => createThemeHandler()}
                      />
                    </Box>
                  ),
                }}
              />
              <LanguageSwitch
                switchLanguage={setLangTheme}
                languages={languages}
                error={themeError}
                disabled={disableOnLoading}
              />
            </Box>
          </Collapse>
        </Box>
        <HiddenInput ref={fileInput} type="file" onChange={addCircuitIcon} />
      </Box>
    );
  }

  return (
    <Container
      maxWidth="lg"
      // onClick={() => window.scrollTo({ top: 0 })}
    >
      <Header>Информация о приложении</Header>

      <Box mt={"40px"} mb={"40px"}>
        <NavBar items={navItemsMobileAppsMainPages} />
      </Box>

      <Paper elevation={4}>
        <ThemeProvider theme={defaultMaterialTheme}>{content}</ThemeProvider>

        <Box textAlign="center" mt={"40px"} paddingBottom={"60px"}>
          <ButtonCustom disabled={disableOnLoading} onClick={updateMainHandler}>
            СОХРАНИТЬ
          </ButtonCustom>
        </Box>

        <MobileAppAccessModal
          open={accessModal}
          close={() => setAccessModal(false)}
          initialParticipants={editingData?.participants?.map(
            (participant) => participant.id
          )}
        />

        <MobileAppQrModal open={qrModal} close={() => setQrModal(false)} />

        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={errorMessage}
        />
      </Paper>
    </Container>
  );
};
