import React from "react";

import { Table, TableRow, TableBody } from "@material-ui/core";
import { DoneProblems } from "../../../generated/graphql";
import { CustomTable } from "../../shared/CustomTable";
import { ColorizedTableCell, CustomTableHead, CustomTableHeaderCell, CustomTableCell } from "../../shared/CustomTable/CustomTable.style";
import { convertDateStringToLocaleHandler } from "../../shared/Utils/ConvertOperations/convertDateStringToLocaleHandler";

interface IDoneProps {
  tasks: DoneProblems[];
}

const headers = [
  'Ключ задачи',
  'Название',
  'Дата начала план',
  'Дата начала факт',
  'Дата конца план',
  'Дата конца факт',
  'Jira Id ответственного'
];

export const Done = ({ tasks }: IDoneProps) => {
  const content = (
    <Table>
      <CustomTableHead>
        <TableRow>
          {headers.map(header => (
            <CustomTableHeaderCell
              key={header}
              width={150}
            >
              {header}
            </CustomTableHeaderCell>
          ))}
        </TableRow>
      </CustomTableHead>

      <TableBody>
        {tasks.length
          ? (tasks.map(task => (
            <TableRow
              key={task.key}
            >
              {[
                task.key,
                task.name,
                convertDateStringToLocaleHandler(task.startDateEstimate),
                convertDateStringToLocaleHandler(task.startDateFact),
                convertDateStringToLocaleHandler(task.finishDateEstimate),
                convertDateStringToLocaleHandler(task.finishDateFact),
                task.assigneeJiraId,
              ].map((field, i) => (
                <ColorizedTableCell
                  key={`${i} ${field}`}
                  number={field ? 0 : -1 }
                >
                  {field}
                </ColorizedTableCell>
              ))}
            </TableRow>
          )))
          : (
            <TableRow>
              <CustomTableCell colSpan={7} >Задачи отсутствуют</CustomTableCell>
            </TableRow>
          )}
      </TableBody>
    </Table>
  )

  return (
    <CustomTable data={tasks} tableContent={content} />
  )
}