import { Settings } from "./Settings";
import { UserEdit } from "./UserEdit";
import { Salary } from "../Salary/Salary";
import { Payments } from "../Payments";
import { ProjectEdit } from "./Project/ProjectEdit";
import { Projects } from "./Project/Projects";
import { Tasks } from "../Tasks";
import { People } from "../People/People";
import { Corrections } from "../Corrections";
import { Notifications } from "../Announcements/Notifications";
import { NewsPage } from "../Announcements/News";
import { SalaryHistory } from "../SalaryHistory";
import { Reports } from "../Reports";
import {
  TeachersPupilsReport,
  ReflexionReport,
  PaymentTypesReport,
  UsersRegistrationReport,
  AverageTimeReport
} from "../ReportsARBook";
import { Dashboard } from "../Dashboard";
import { MobileAppEdit } from "../MobileAppEdit";
import { SalaryRateHistory } from "../SalaryRateHistory";
import { ProblemTasks } from "../ProblemTasks";
import { Kanban } from "../Kanban";
import { MobileApps } from "../MobileApps";
import { MobileAppPages } from "../MobileAppPages";
import { MobileAppNotifications } from "../MobileAppNotifications";
import { MobileAppPageEdit } from "../MobileAppPageEdit";
import { MobileAppModelsList } from "../MobileAppModelsList";
import { KanbanSettings } from "../KanbanSettings/KanbanSettings";
import { KanbanTimeline } from "../KanbanTimeline/KanbanTimeline";
import { OverdueTasks } from "../OverdueTasks";
import { Sites } from "../Sites/Sites";
import { Models } from "../Sites/Models";
import { KnowledgeBase } from "../KnowledgeBase";
import { KnowledgeBaseTheme } from "../KnowledgeBaseTheme";
import { KnowledgeBaseTests } from "../KnowledgeBaseTests";
import { KnowledgeBaseTestsHistory } from "../KnowledgeBaseTestsHistory";
import { MobileVersions} from "../MobileVersions";
import { FeedBack } from "../FeedBack";
import { MobileAppUsers } from "../MobileAppUsers/MobileAppUsers";
import { UserEditApp } from "../UserEditApp/UserEditApp";
import { Lessons } from "../Lessons/Lessons";
import { Lesson } from "../Lesson/Lesson";
import { SmartNotification } from "../SmartNotification/SmartNotification";
import { SmartNotificationStatisticPollList } from "../SmartNotification/Statistic/SmartNotificationStatisticPollList";
import { Schools} from "../Schools/Schools";
import { CreateSchool } from "../School/CreateSchool";
import { UpdateSchool } from "../School/UpdateSchool";
import { Training } from "../Training";
import { TrainingList } from "../TrainingList";
import { Events } from "../Events";
import { UpdateEvent } from "../Event/UpdateEvent";
import { AskedQuestions } from "../AskedQuestions/AskedQuestions";
import { Giveaway } from "../Giveaway";
import { Achievements, CreateAchievement, UpdateAchievement } from "../Achievements";
import { Onboarding } from "../Onboarding";
import { SchoolPaths } from "../shared/constants";
import { robotsRoutes } from "../Gamification/routes";

export const MAIN_ROUTES = [
  {
    path: "/user/:userId/edit",
    component: UserEdit,
    exact: true,
  },
  {
    path: "/project/:projectId/edit",
    component: ProjectEdit,
    exact: true,
  },
  {
    path: "/project/:projectId/info",
    component: ProjectEdit,
    exact: true,
  },
  {
    path: "/project/:projectId/payments",
    component: Payments,
    exact: true,
  },
  {
    path: "/project/:projectId/tasks",
    component: Tasks,
    exact: true,
  },
  {
    path: "/project/:projectId/corrections",
    component: Corrections,
    exact: true,
  },
  {
    path: "/project/:projectId/kanban",
    component: Kanban,
    exact: true,
  },
  {
    path: "/project/:projectId/kanban/settings",
    component: KanbanSettings,
    exact: true,
  },
  {
    path: "/project/:projectId/timeline",
    component: KanbanTimeline,
    exact: false,
  },
  {
    path: "/projects",
    component: Projects,
    exact: true,
  },
  {
    path: "/settings",
    component: Settings,
    exact: true,
  },
  {
    path: "/user/:userId/salary",
    component: Salary,
    exact: true,
  },
  {
    path: "/user/:userId/salaryHistory",
    component: SalaryHistory,
    exact: true,
  },
  {
    path: "/user/:userId/salaryRateHistory",
    component: SalaryRateHistory,
    exact: true,
  },
  {
    path: "/people",
    component: People,
    exact: true,
  },
  {
    path: "/problemTasks",
    component: ProblemTasks,
    exact: true,
  },
  {
    path: "/overdueTasks",
    component: OverdueTasks,
    exact: false,
  },
  {
    path: "/announcements/notifications",
    component: Notifications,
    exact: true,
  },
  {
    path: "/announcements/news",
    component: NewsPage,
    exact: true,
  },
  {
    path: "/reports",
    component: Reports,
    exact: true,
  },
  {
    path: "/reportsARBook/teachersPupils",
    component: TeachersPupilsReport,
    exact: true,
  },
  {
    path: "/reportsARBook/reflexion",
    component: ReflexionReport,
    exact: true,
  },
  {
    path: "/reportsARBook/paymentTypes",
    component: PaymentTypesReport,
    exact: true,
  },
  {
    path: "/reportsARBook/usersRegistration",
    component: UsersRegistrationReport,
    exact: true,
  },
  {
    path: "/reportsARBook/averageTime",
    component: AverageTimeReport,
    exact: true,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/mobileApps",
    component: MobileApps,
    exact: true,
  },
  {
    path: "/mobileApps/:appId/edit",
    component: MobileAppEdit,
    exact: true,
  },
  {
    path: "/mobileApps/:appId/pages",
    component: MobileAppPages,
    exact: true,
  },
  {
    path: "/mobileApps/:appId/pages/:pageId/edit",
    component: MobileAppPageEdit,
    exact: true,
  },
  {
    path: "/mobileApps/:appId/pages/:pageId/models",
    component: MobileAppModelsList,
    exact: true,
  },
  {
    path: "/mobileApps/:appId/pages/:pageId/lessons",
    component: Lessons,
    exact: true
  },
  {
    path: "/mobileApps/:appId/pages/:pageId/lesson/:lessonId",
    component: Lesson,
    exact: true
  },
  {
    path: "/mobileApps/:appId/notifications",
    component: MobileAppNotifications,
    exact: true,
  },
  {
    path: "/mobileApps/:appId/versions",
    component: MobileVersions,
    exact: true,
  },
  ...robotsRoutes,
  {
    path: "/mobileAppUsers",
    component: MobileAppUsers,
    exact: true
  },
  {
    path: "/mobileApps/:appId/polls",
    component: SmartNotification,
    exact: true,
  },
  {
    path: "/mobileApps/:appId/polls/:pollId",
    component: SmartNotificationStatisticPollList,
    exact: true
  },
  {
    path: "/mobileApps/:appId/achievements",
    component: Achievements,
    exact: true
  },
  {
    path: "/mobileApps/:appId/achievements/create",
    component: CreateAchievement,
    exact: true
  },
  {
    path: "/mobileApps/:appId/achievements/:achievementId/edit",
    component: UpdateAchievement,
    exact: true,
  },
  {
    path: "/mobileAppUsers/:userId/editUser",
    component: UserEditApp,
    exact: true
  },
  {
    path: "/sites",
    component: Sites,
    exact: true,
  },
  {
    path: "/knowledgeBase",
    component: KnowledgeBase,
    exact: true,
  },
  {
    path: "/knowledgeBase/theme/:themeName/:themeId",
    component: KnowledgeBaseTheme,
    exact: true,
  },
  {
    path: "/knowledgeBase/tests/:themeName/:themeId",
    component: KnowledgeBaseTests,
    exact: true,
  },
  {
    path: "/knowledgeBase/answers/:themeName/:themeId",
    component: KnowledgeBaseTestsHistory,
    exact: true,
  },
  {
    path: "/sites/:siteId/Models",
    component: Models,
    exact: true,
  },
  {
    path: "/feedback",
    component: FeedBack,
    exact: true,
  },
  {
    path: `/${SchoolPaths.school}s`,
    component: Schools,
    exact: true,
  },
  {
    path: `/${SchoolPaths.school}/create`,
    component: CreateSchool,
    exact: true,
  },
  {
    path: `/${SchoolPaths.school}/:schoolId/edit`,
    component: UpdateSchool,
    exact: true,
  },
  {
    path: `/${SchoolPaths.personal}s`,
    component: Schools,
    exact: true,
  },
  {
    path: `/${SchoolPaths.personal}/:schoolId/edit`,
    component: UpdateSchool,
    exact: true,
  },
  {
    path: "/giveaway",
    component: Giveaway,
    exact: true,
  },
  {
    path: "/training",
    component: Training,
    exact: true
  },
  {
    path: "/training/list/:trainingsId",
    component: TrainingList,
    exact: true
  },
  {
    path: "/events",
    component: Events,
    exact: true
  },
  {
    path: "/event/:id/edit",
    component: UpdateEvent,
    exact: true
  },
  {
    path: "/askedQuestions",
    component: AskedQuestions,
    exact: true
  },
  {
    path: "/onboarding",
    component: Onboarding,
    exact: true
  },
  {
    path: "*",
    component: Dashboard,
    exact: false,
  },
];
