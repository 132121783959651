import React from "react";
import { Box, Typography } from "@material-ui/core";
import { PersonalData } from "../../Main/UserEdit/UserEdit.style";
import { ButtonPlusSmall } from "../../shared/Style/Style";

interface FormSmallTitleWithBtnProps {
  title: string;
  onClickBtn(e: boolean): void;
  withoutButton?: boolean;
}

export const SectionTitle = ({
  title,
  onClickBtn,
  withoutButton,
}: FormSmallTitleWithBtnProps) => {
  return (
    <Box display="flex" justifyContent="start" mb={2}>
      <Box mr={2}>
        <Typography component={PersonalData} variant="subtitle1" gutterBottom>
          {title}
        </Typography>
      </Box>

      {!withoutButton && <ButtonPlusSmall onClick={() => onClickBtn(true)} />}
    </Box>
  );
};
