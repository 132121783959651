import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Box, CircularProgress, Container, Paper} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import {NavBar} from "../shared/NavBar";
import {ErrorSnackbar} from "../shared/Error/ErrorSnackbar";
import {ErrorMessageSnackbar} from "../shared/ErrorMessageSnackbar";
import {ButtonCustom, defaultMaterialTheme, Header, LoadingBox,} from "../shared/Style/Style";
import {AddVersions, MobileVersionInfo, MobileVersionList,} from "./MobileVersions.style";
import {useMutation, useQuery} from "@apollo/client";
import {convertDateAndTimeToLocaleHandler} from "../shared/Utils/ConvertOperations/convertDateAndTimeToLocaleHandler";
import {
  CreateApplicationVersionDocument,
  CreateApplicationVersionMutation,
  GetApplicationVersionsDocument,
  GetApplicationVersionsQuery,
  Os,
} from "../../generated/graphql";

import {navItems} from "../../constants/navItemMobileApp";

export const MobileVersions = () => {
  const { appId } = useParams<{ appId: string }>();

  const [mobileIOS, setMobileIOS] = useState<string>();
  const [mobileAndroid, setMobileAndroid] = useState<string>();

  console.log('mobileIOS', mobileIOS)
  console.log('mobileAndroid', mobileAndroid)

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { data, loading, error } = useQuery<GetApplicationVersionsQuery>(
    GetApplicationVersionsDocument,
    {
      variables: {
        appId,
      },
    }
  );

  const [
    createMobileVersion,
    {
      data: createMobileVersionData,
      loading: createMobileVersionLoading,
      error: createMobileVersionError,
    },
  ] = useMutation<CreateApplicationVersionMutation>(
    CreateApplicationVersionDocument,
    {
      variables: { appId },
      refetchQueries: [
        { query: GetApplicationVersionsDocument, variables: { appId } },
      ],
    }
  );

  const errorHandler = (message: string) => {
    setRequestError(true);
    setErrorMessage(message);
  };

  const createVersionHandle = async (type: Os) => {
    try {
      if (!mobileIOS && !mobileAndroid) {
        return;
      }

      await createMobileVersion({
        variables: {
          os: type,
          version: type === Os.IOs ? mobileIOS : mobileAndroid,
        },
      });
    } catch (e) {
      errorHandler("При сохранении версии произошла ошибка");
    }
  };

  useEffect(() => {
    if (createMobileVersionData?.createApplicationVersion.os === Os.IOs) {
      setMobileIOS("");
    }
    if(createMobileVersionData?.createApplicationVersion.os === Os.Android) {
      setMobileAndroid("");
    }
  }, [createMobileVersionData]);

  const disableOnLoading = createMobileVersionLoading || loading;

  let content;

  if (disableOnLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (error) {
    content = <ErrorSnackbar error={error} />;
  }

  if (data) {
    const versionsData = data.getApplicationVersions;

    const versionsIOS = versionsData.filter((version) => version.os === Os.IOs);
    const versionsAndroid = versionsData.filter((version) => version.os === Os.Android);

    content = (
      <>
        <Box p={'60px 0 120px 0'}>
          <Box display="flex" justifyContent="space-around">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              width={"32%"}
            >
              <h2>Версии для IOS</h2>
              <Box display="flex">
                <AddVersions
                  value={mobileIOS}
                  label="Введите версию"
                  multiline
                  fullWidth
                  onChange={({ target: { value } }) => setMobileIOS(value)}
                />
                <ButtonCustom
                  disabled={disableOnLoading}
                  onClick={() => createVersionHandle(Os.IOs)}
                >
                  СОХРАНИТЬ
                </ButtonCustom>
              </Box>

              <MobileVersionList>
                <Box mt={2} display="flex" justifyContent="space-between">
                  <MobileVersionInfo>
                    {versionsIOS[0]?.version || ""}
                  </MobileVersionInfo>
                  <MobileVersionInfo>
                    {convertDateAndTimeToLocaleHandler(
                      versionsIOS[0]?.createdDate
                    ) || ""}
                  </MobileVersionInfo>
                </Box>
              </MobileVersionList>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              width={"30%"}
            >
              <h2>Версии для Android</h2>
              <Box display="flex">
                <AddVersions
                  value={mobileAndroid}
                  label="Введите версию"
                  multiline
                  fullWidth
                  onChange={({ target: { value } }) => setMobileAndroid(value)}
                />
                <ButtonCustom
                  disabled={disableOnLoading}
                  onClick={() => createVersionHandle(Os.Android)}
                >
                  СОХРАНИТЬ
                </ButtonCustom>
              </Box>

              <MobileVersionList>
                <Box mt={2} display="flex" justifyContent="space-between">
                  <MobileVersionInfo>
                    {versionsAndroid[0]?.version || ""}
                  </MobileVersionInfo>
                  <MobileVersionInfo>
                    {convertDateAndTimeToLocaleHandler(
                      versionsAndroid[0]?.createdDate
                    ) || ""}
                  </MobileVersionInfo>
                </Box>
              </MobileVersionList>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <h2>История обновления приложения </h2>
          </Box>
          <Box display="flex" justifyContent="space-around">
            <Box
              mt="25px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              width={"32%"}
            >
              {versionsIOS?.map((versionIOS) => (
                <MobileVersionList key={versionIOS.id}>
                  <Box mt={2} display="flex" justifyContent="space-between">
                    <MobileVersionInfo>{versionIOS.version}</MobileVersionInfo>
                    <MobileVersionInfo>
                      {convertDateAndTimeToLocaleHandler(
                        versionIOS.createdDate
                      ) || ""}
                    </MobileVersionInfo>
                  </Box>
                </MobileVersionList>
              ))}
            </Box>
            <Box
              mt="25px"
              display="flex"
              alignItems="center"
              flexDirection="column"
              width={"30%"}
            >
              {versionsAndroid?.map((versionAndroid) => (
                <MobileVersionList key={versionAndroid.os}>
                  <Box mt={2} display="flex" justifyContent="space-between">
                    <MobileVersionInfo>
                      {versionAndroid.version}
                    </MobileVersionInfo>
                    <MobileVersionInfo>
                      {convertDateAndTimeToLocaleHandler(
                        versionAndroid.createdDate
                      )}
                    </MobileVersionInfo>
                  </Box>
                </MobileVersionList>
              ))}
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <Container>
      <Header>Версии</Header>

      <Box mt={"40px"} mb={"40px"}>
        <NavBar items={navItems} />
      </Box>

      <Paper elevation={4}>
        <ThemeProvider theme={defaultMaterialTheme}>{content}</ThemeProvider>
      </Paper>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Container>
  );
};
