import styled from "styled-components";
import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  TextField,
} from "@material-ui/core";
import { primary, secondary } from "../shared/Style/Style";

export const EventField = styled(TextField)`
  margin-bottom: 20px;
  margin-left: 10px;
  & .MuiInput-underline:before {
    border-bottom-color: #eee;
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${primary};
  }

  & .MuiInputBase-multiline {
    padding: 5px 5px 5px 10px;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${primary};
  }
`;

export const DateAndLinkEvent = styled(ListItem)`
  margin-bottom: 20px;
  margin-left: 10px;
  &.MuiListItem {
    &-root {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${({ selected }) => (selected ? secondary : "inherit")};
    &-gutters {
      padding: 14px 12px;
    }
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${primary};
  color: black;
  font-size: 20px;
  padding: 8px 0 8px 16px;
`;

export const CheckBoxUser = styled(Checkbox)`
  & .MuiRadio-colorSecondary {
    color: ${primary};
  }

  &.MuiIconButton-colorSecondary {
    color: ${primary};
  }
`;

export const EventContainer = styled(List)`
  width: 400px;
  height: 297px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const EventGroupUserControl = styled(FormControlLabel)`
  min-width: 90%;
  display: block;
  padding: 0;
`;
export const EventGroupUserList = styled(List)`
  max-height: 65%;
  overflow-y: auto;
  margin: 10px 0;
`;

export const EventDatesUserContent = styled.div`
  width: 400px;
  height: 406px;
  padding: 20px;
  text-align: center;
  position: relative;
`;

export const EventDatesNoUserModal = styled(List)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 65%;
  height: 210px
`;