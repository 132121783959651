import styled from "styled-components";
import { List, TextField } from "@material-ui/core";
import {ButtonCustomWithDarkText, lightBlack} from "../shared/Style/Style";

export const MobileAppInput = styled(TextField)`
  & .MuiInputBase-input {
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  & .MuiInput-underline.Mui-disabled:before {
    border-bottom: none;
  }

  &:not(:first-of-type) {
    margin-top: ${props => props.label ? '10px' : 0 };
  }
`;

export const MobileAppSubtitle = styled.h3`
  color: ${lightBlack};
  margin-top: 0;
  font-size: 16px;
`;

export const MobileAppInputTitle = styled(MobileAppSubtitle)`
  margin: 30px 0 20px;
  font-size: 14px;
`;

export const MobileAppParticipantsList = styled(List)`
  max-height: 244px;
  overflow-y: auto;
`;