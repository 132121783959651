import React, { useState, useRef } from "react";

import { ArrowRight, ArrowLeft, Menu } from "@material-ui/icons";

import { stringifyDateHandler } from "../../shared/Utils/DateOperations/stringifyDateHandler";

import {
  MonthsWrapper,
  MonthsDropdown,
  MonthDropdownItem,
  MonthsList,
  ShowAllMonthsBtnWrapper,
  ShowAllMonthsBtn,
  Month,
  ScrollButtonsWrapper,
  ScrollMonthsControlButton
} from "./MonthNavigationBar.style";

interface IMonthNavigationBarProps {
  months: any[];
  chosenMonth: string;
  setChosenMonth( state: string ): void;
  field: string;
}


export const MonthNavigationBar = ({
  months,
  chosenMonth,
  setChosenMonth,
  field
}: IMonthNavigationBarProps) => {
  const monthsList = useRef<HTMLUListElement | null>(null);
  const [isDropdownActive, setDropdownActive] = useState(false);

  return (
    <MonthsWrapper>
      {isDropdownActive && (
        <MonthsDropdown>
          {months
            ?.map((month) => (
              <MonthDropdownItem
                active={chosenMonth === month[field]}
                key={month[field]}
                onClick={() => {
                  setChosenMonth(month[field]);
                  setDropdownActive(false);
                }}
              >
                {stringifyDateHandler(month.name)}
              </MonthDropdownItem>
            ))
            .reverse()}
        </MonthsDropdown>
      )}

      <MonthsList ref={monthsList}>
        <ShowAllMonthsBtnWrapper>
          <ShowAllMonthsBtn
            edge='end'
            aria-label="payrollsList"
            onClick={() =>
              setDropdownActive(!isDropdownActive)
            }
          >
            <Menu fontSize={"large"} />
          </ShowAllMonthsBtn>
        </ShowAllMonthsBtnWrapper>

        {months
          ?.map((month) => (
            <Month
              active={chosenMonth === month[field]}
              key={month[field]}
              onClick={() => {
                setChosenMonth(month[field]);
                setDropdownActive(false);
              }}
            >
              {stringifyDateHandler(month.name)}
            </Month>
          ))
          .reverse()}
      </MonthsList>

      <ScrollButtonsWrapper>
        <ScrollMonthsControlButton
          onClick={() => (monthsList!.current!.scrollLeft = 0)}
        >
          <ArrowLeft fontSize={"small"} />
        </ScrollMonthsControlButton>

        <ScrollMonthsControlButton
          onClick={() => (monthsList!.current!.scrollLeft = monthsList!.current!.scrollWidth + 1)}
        >
          <ArrowRight fontSize={"small"} />
        </ScrollMonthsControlButton>
      </ScrollButtonsWrapper>
    </MonthsWrapper>
  )
}