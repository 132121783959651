import React from "react";
import { Box, IconButton } from "@material-ui/core";
import { DEFAULT_AVATAR_URL } from "../constants";
import { SmallUserAvatarStyle, UserName } from "../Style/Style";
import {
  GetProjectDocument,
  RemoveUsersFromProjectDocument,
  RemoveUsersFromProjectMutation,
  User,
} from "../../../generated/graphql";
import DeleteIcon from "@material-ui/icons/Delete";
import { useMutation } from "@apollo/client";
import { ErrorSnackbar } from "../Error/ErrorSnackbar";

export const ContactPersonInfo = ({
  person,
  projectId,
}: {
  person: User;
  projectId: string;
}) => {
  const [
    removeUserFromProject,
    {
      loading: removeUserFromProjectLoading,
      error: removeUserFromProjectError,
    },
  ] = useMutation<RemoveUsersFromProjectMutation>(
    RemoveUsersFromProjectDocument,
    {
      refetchQueries: [
        { query: GetProjectDocument, variables: { projectId } },
      ],
    }
  );

  if (removeUserFromProjectError) {
  }

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2} width={280}>
      <Box display="flex" alignItems="center">
        <Box mr={2}>
          <SmallUserAvatarStyle
            src={person?.photo ? person.photo : DEFAULT_AVATAR_URL}
          />
        </Box>
        <UserName>
          {person && person.firstName} {person && person.lastName}
        </UserName>
      </Box>

      <IconButton
        disabled={removeUserFromProjectLoading}
        color="secondary"
        aria-label="delete"
        onClick={(e) => {
          e.stopPropagation();
          removeUserFromProject({
            variables: {
              userIds: [person.id],
              projectId: projectId,
            },
          });
        }}
      >
        <DeleteIcon fontSize={"small"} />
      </IconButton>
      {removeUserFromProjectError && (
        <ErrorSnackbar error={removeUserFromProjectError} />
      )}
    </Box>
  );
};
