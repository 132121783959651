import { gql } from "@apollo/client";

export const GET_ROBOTS_HISTORY = gql`
  query GetRobotsHistory {
    getRobotsHistory {
      id
      link
      order
    }
  }
`;

export const GET_AWARD = gql`
  query getAward($getAwardId: UUID!) {
    getAward(id: $getAwardId) {
      id
      name
      photo
      day
      background
      animation
      energyQuantity
      isPublished
      type
    }
  }
`;

export const GET_AWARDS = gql`
  query getAwards {
    getAwards {
      id
      name
      photo
      day
      background
      animation
      energyQuantity
      isPublished
      type
    }
  }
`;

export const GET_GAME_MODELS = gql`
  query getGameModels {
    getGameModels {
      id
      type
      name
      description
      background
      isPublished
      isCanBePublished
      statesCount
      order
    }
  }
`;

export const GET_GAME_MODEL = gql`
  query getGameModel($getGameModelId: UUID!) {
    getGameModel(id: $getGameModelId) {
      id
      name
      description
      background
      order
    }
  }
`;

export const GET_GAME_MODEL_STATES = gql`
  query getGameModelStates($gameModelId: UUID!) {
    getGameModelStates(gameModelId: $gameModelId) {
      id
      stageId
      image
      name
      description
      isPublished
      isCanBePublished
    }
  }
`;

export const GET_GAME_MODEL_STATES_FOR_SELECT = gql`
  query getGameModelStatesForSelect($gameModelId: UUID!) {
    getGameModelStates(gameModelId: $gameModelId) {
      stageId
      name
    }
  }
`;

export const GET_GAME_MODEL_STATE = gql`
  query getGameModelState($getGameModelStateId: UUID!) {
    getGameModelState(id: $getGameModelStateId) {
      id
      stageId
      image
      name
      description
      experiencePrice
      energyPrice
      iosBundle
      iosManifest
      androidBundle
      androidManifest
      gameModelId
    }
  }
`;

export const GET_GAME_MODEL_ARTIFACTS = gql`
  query GetGameModelArtifacts($input: GetGameModelArtifactsInput!, $take: Int, $skip: Int) {
    getGameModelArtifacts(input: $input, take: $take, skip: $skip) {
      artifacts {
        id
        name
        image
        type
        isPublished
        isDefault
      }
      total
    }
  }
`;

export const GET_GAME_MODEL_ARTIFACT_CLOTH_TYPES = gql`
  query getGameModelArtifactClothTypes {
    getGameModelArtifactClothTypes {
      id
      name
      displayName
    }
  }
`;

export const GET_GAME_MODEL_STATE_STAGES = gql`
  query getGameModelStateStages {
    getGameModelStateStages {
      id
      name
    }
  }
`;

export const GET_REWARD_GROUPS = gql`
  query getRewardGroups {
    getRewardGroups {
      id
      name
      order
      rewards {
        id
        name
        group {
          id
          name
        }
        groupId
        order
        value
        type
      }
    }
  }
`;

export const GET_GAME_MODEL_ARTIFACT_ANIMATION_TYPES = gql`
  query getGameModelArtifactAnimationTypes {
    getGameModelArtifactAnimationTypes {
      id
      name
      displayName
    }
  }
`;

export const GET_GAME_MODEL_ARTIFACT = gql`
  query getGameModelArtifact($getGameModelArtifactId: UUID!) {
    getGameModelArtifact(id: $getGameModelArtifactId) {
      id
      name
      energyPrice
      type
      clothTypeId
      animationTypeId
      rank
      image
      iosBundle
      iosManifest
      androidBundle
      androidManifest
      animation
      gameModelStateId
      experiencePrice
      isPublished
      isCanBePublished
      isDefault
    }
  }
`;
