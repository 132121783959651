import { useReactiveVar } from "@apollo/client";
import { robotsState } from "../../state";
import { Autocomplete } from "@material-ui/lab";
import { ModalInput } from "../../../../shared/Style/Style";
import { RobotStageFields } from "../../constants";

export const StageGeneralInfo = () => {
    
    const stage = useReactiveVar(robotsState.stageGeneralInfo);
    const robotStagesList = useReactiveVar(robotsState.robotStagesList);
    const robotStageTypes = useReactiveVar(robotsState.robotStageTypes);
    const inputErrors = useReactiveVar(robotsState.inputErrors);
    const isLoading = useReactiveVar(robotsState.isLoading);
    
    const selectedStageList = robotStagesList?.map(item => item.name?.slice(-1)) ?? [];
    const selectStageOptions = robotStageTypes?.map(item => `Стадія ${item.name}`) ?? [];
    
    const stageInputData = [
        {
            field: RobotStageFields.Description, label: "Описание",
            handleValueChange: (value: string) =>
                robotsState.setStageGeneralInfo(RobotStageFields.Description, value),
        },
        {
            field: RobotStageFields.EnergyPrice, label: "Стоимость (в энергии)",
            handleValueChange: (value: number) =>
                robotsState.setStageGeneralInfo(RobotStageFields.EnergyPrice, Number(value)),
        },
        {
            field: RobotStageFields.ExperiencePrice, label: "Стоимость (опыт)",
            handleValueChange: (value: number) =>
                robotsState.setStageGeneralInfo(RobotStageFields.ExperiencePrice, Number(value)),
        },
    ];

    const handleNameChange = (_, value: string) => { 
        robotsState.setStageGeneralInfo(RobotStageFields.Name, value);
    };

    const getOptionLabel = (option: string) => { 
        return selectStageOptions.find(item => item.slice(-1) === option.slice(-1)) || "";
    };

    const renderModalInput = (field: string, label: string, handleValueChange: (value) => void ) => {
        const isDescription = field === RobotStageFields.Description;
        const isExperiencePrice = field === RobotStageFields.ExperiencePrice;

        return (
            <ModalInput
                key={field}
                type={isDescription ? "text" : "number"}
                variant="filled"
                margin="normal"
                multiline = {isDescription}
                minRows={4}
                required={!isExperiencePrice}
                label={label}
                value={stage[field] ?? ""}
                onChange={({ target: { value } }) => handleValueChange(value)}
                error={inputErrors[field].error}
                onFocus={() =>
                    robotsState.setInputErrors(field as RobotStageFields, false)}
            />
        );
    };
    
    return (
        <>
            <Autocomplete
                disabled={isLoading}
                options={selectStageOptions}
                value={stage?.name || null}
                onChange={handleNameChange}
                getOptionLabel={getOptionLabel}
                filterOptions={(options, _) => {
                    return options.filter(option => !selectedStageList?.includes(option.slice(-1)));
                }}
                onFocus={() =>
                    robotsState.setInputErrors(RobotStageFields.Name, false)
                }
                renderInput={(params) => {
                    return (
                        <ModalInput
                            {...params}
                            error={inputErrors[RobotStageFields.Name].error}
                            label="Стадия"
                            fullWidth
                            variant="filled"
                            margin="normal"
                            required
                        />
                    );
                }}
            />

            {stageInputData.map(({ field, label, handleValueChange }) => (
                renderModalInput(field, label, handleValueChange)
            ))}
        </>
    );
};
