import { Modal } from "@material-ui/core";
import { ButtonCustom, ButtonCustomCancel } from "../../shared/Style/Style";
import { Container, Content, Buttons, Text } from "./ConfirmationModal.style";

interface ConfirmationModalProps {
  open: boolean;
  onConfirm(): void;
  onClose(): void;
}

export const ConfirmationModal = ({ open, onConfirm, onClose }: ConfirmationModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Container>
        <Content>
          <Text>Вы уверены что хотите удалить школу?
            При удалении школы будут удалены все ее данные: классы, список учителей и учеников, учебные планы и уроки.
          </Text>

          <Buttons>
            <ButtonCustom
              onClick={onConfirm}
            >
              Удалить
            </ButtonCustom>
            <ButtonCustomCancel
              onClick={onClose}
            >
              Отмена
            </ButtonCustomCancel>
          </Buttons>
        </Content>
      </Container>
    </Modal>
  );
};
