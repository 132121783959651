import React, { useState, useEffect } from "react";

import { useMutation, useQuery } from "@apollo/client";
import {
  CreateCorrectionCommentDocument,
  CreateCorrectionCommentMutation,
  GetProjectCorrectionsDocument,
  GetProjectCorrectionsQuery,
  UpdateProjectCorrectionDocument,
  UpdateProjectCorrectionMutation,
  GetProjectCorrectionsLightDocument
} from "../../generated/graphql";

import { useParams } from "react-router-dom";

import { Box, CircularProgress, Container, MenuItem, } from "@material-ui/core";

import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { Header } from "../shared/Style/Style";
import { ProjectEditNavBar } from "../ProjectEditNavBar";
import { DEFAULT_AVATAR_URL } from "../shared/constants";

import { CorrectionsNavBar } from "../CorrectionsNavBar";
import { CorrectionsErrorSnackbar } from "./CorrectionsErrorSnackbar";

import {
  CorrectionsList,
  CorrectionsListItem,
  EmptyCorrectionsListItem,
  CorrectionContentBox,
  CorrectionTextBox,
  DateBox,
  CommentsControl,
  AddCommentBar,
  AddCommentBtn,
  AddCommentTextField,
  CommentsList,
  Comment,
  CommentContent,
  CommentTitle,
  CommentText,
  CommentDate,
  CommentAvatar,
  CorrectionStatusSelect,
} from "./Corrections.style";



export const Corrections = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const [activeFilter, setActiveFilter] = useState(['NEW']);

  const [showComments, setShowComments] = useState(false);

  const [chosenCorrection, setChosenCorrection] = useState<string | undefined | null>(undefined);
  const [commentText, setCommentText] = useState('');

  const { data, loading, error } = useQuery<GetProjectCorrectionsQuery>(
    GetProjectCorrectionsDocument,
    {
      variables: { projectId, statuses: activeFilter },
      fetchPolicy: 'cache-and-network',
    }
  );

  //#region create comment

  const [correctionStatus, setCorrectionStatus] = useState('default');

  const [createComment,
    {
      data: createCommentData,
      loading: createCommentLoading,
      error: createCommentError,
    }
  ] = useMutation<CreateCorrectionCommentMutation>(
    CreateCorrectionCommentDocument,
    { refetchQueries: [{ query: GetProjectCorrectionsDocument, variables: { projectId, statuses: activeFilter } }] }
  );

  const createCommentHandler = async (correctionId: string | null | undefined): Promise<void> => {
    if (!correctionId || !commentText) {
      return;
    }

    await createComment({
      variables: {
        correctionCommentData: {
          correctionId,
          text: commentText,
        }
      }
    });

    setCommentText('');
  }

  //#endregion

  //#region update correction

  const [
    updateCorrection,
    {
      loading: updateCorrectionLoading,
      error: updateCorrectionError
    }
  ] = useMutation<UpdateProjectCorrectionMutation>(
    UpdateProjectCorrectionDocument,
    { refetchQueries: [
      {query: GetProjectCorrectionsDocument, variables: { projectId, statuses: activeFilter }},
      {query: GetProjectCorrectionsLightDocument, variables: { projectId }}
    ]}
  );

  const updateCorrectionHandler = async () => {
    if (!chosenCorrection || correctionStatus === 'default') {
      return;
    }

    await updateCorrection({
      variables: {
        projectCorrectionData: {
          id: chosenCorrection,
          statusId: +correctionStatus,
        }
      }
    });

    setCorrectionStatus('default');
  }

  useEffect(() => {
    updateCorrectionHandler();
  }, [correctionStatus]);

  //#endregion

  useEffect(() => {
    setCommentText('');
  }, [chosenCorrection]);

  useEffect(() => {
    if (createCommentData?.createCorrectionComment?.id) {
      setCommentText('');
    }
  }, [createCommentData]);

  let content;

  if (error) {
    content = <ErrorSnackbar error={error} />;
  }

  if (loading) {
    content = <CircularProgress color="inherit" />
  }

  if (data) {
    const corrections = data.getProjectCorrections;

    content = (
      <CorrectionsList>
        {corrections?.length
          ? corrections?.map(correction => (
            <CorrectionsListItem
              key={correction?.id}
              onClick={() => setChosenCorrection(correction?.id)}
            >
              <DateBox>
                <Box>{correction?.createdDate.split('T')[0].split('-').reverse().join('.')}</Box>
                <Box>{correction?.createdDate.split('T')[1].split(':').slice(0, 2).join(':')}</Box>
              </DateBox>

              <CorrectionContentBox>
                <CorrectionTextBox>
                  <Box>{correction?.text}</Box>
                  <Box>
                    <CorrectionStatusSelect
                      value={(correctionStatus && correction?.id === chosenCorrection) ? correctionStatus : 'default'}
                      disabled={updateCorrectionLoading}
                      onChange={({ target: { value } }) => {
                        if (typeof value === 'string') {
                          setCorrectionStatus(value);
                        }
                      }}
                    >
                      <MenuItem button value='default'>Переместить...</MenuItem>
                      <MenuItem button value='1'>Новые</MenuItem>
                      <MenuItem button value='2'>На рассмотрении</MenuItem>
                      <MenuItem button value='3'>На обсуждении</MenuItem>
                      <MenuItem button value='4'>Принято</MenuItem>
                      <MenuItem button value='5'>Сделано</MenuItem>
                      <MenuItem button value='6'>Отклонено</MenuItem>
                    </CorrectionStatusSelect>

                    {updateCorrectionError &&
                      <CorrectionsErrorSnackbar role={'updateCorrectionError'} message={updateCorrectionError.message} />
                    }
                  </Box>
                </CorrectionTextBox>

                <Box>
                  <CommentsControl
                    onClick={() => setShowComments(!showComments)}
                  >
                    Комментарии {correction?.comments?.length ? `(${correction?.comments?.length})` : ''}
                  </CommentsControl>

                  <CommentsList
                    visible={(showComments && correction?.id === chosenCorrection)}
                  >
                    {correction?.comments?.map(comment => (
                      <Comment key={comment?.id}>
                        <CommentAvatar
                          src={comment?.createdBy?.photo ? comment?.createdBy?.photo : DEFAULT_AVATAR_URL}
                        />

                        <CommentContent>
                          <CommentTitle>
                            {`
                              ${comment?.createdBy?.lastName}
                              ${comment?.createdBy?.firstName}
                              ${comment?.createdBy?.job?.name || ''}
                            `}
                          </CommentTitle>

                          <CommentText>{comment?.text}</CommentText>

                          <CommentDate>
                            {`${correction?.createdDate.split('T')[0].split('-').reverse().join('.')} в
                            ${correction?.createdDate.split('T')[1].split(':').slice(0, 2).join(':')}`}
                          </CommentDate>
                        </CommentContent>

                      </Comment>
                    ))}
                  </CommentsList>

                  {createCommentError &&
                    <CorrectionsErrorSnackbar role={'createCommentError'} message={createCommentError.message} />
                  }

                  <AddCommentBar>
                    <AddCommentTextField
                      label={'Введите комментарий...'}
                      multiline
                      fullWidth
                      value={(correction?.id === chosenCorrection) ? commentText : ''}
                      onChange={({ target: { value } }) => setCommentText(value)}
                    />

                    <AddCommentBtn
                      active={true}
                      onClick={() => createCommentHandler(correction?.id)}
                      disabled={createCommentLoading}
                    >
                      {createCommentLoading ? <CircularProgress color="inherit" /> : 'Написать'}
                    </AddCommentBtn>
                  </AddCommentBar>
                </Box>
              </CorrectionContentBox>
            </CorrectionsListItem>
          ))
          : <EmptyCorrectionsListItem>В этой категории правки отсутсвуют</EmptyCorrectionsListItem>
        }
      </CorrectionsList>
    )
  }

  return (
    <Container>
      <Header>
        правки
      </Header>

      <ProjectEditNavBar />

      <CorrectionsNavBar activeFilter={activeFilter} setActiveFilter={setActiveFilter} />

      {content}
    </Container>
  )
}