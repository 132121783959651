import { useReactiveVar } from "@apollo/client";
import { Award } from "../../../../../generated/graphql";
import { awardsState } from "../../state";
import { AwardsListItem, AwardMenu } from "../../style";
import { MainTableCell, MainTableImage, MainTableStatusCell } from "../../../../shared/Style/Style";
import { Menu } from "../../../../shared/Menu";
import { awardsQuantity } from "../../constants";


export const AwardsList = () => {
    const awardsList = useReactiveVar(awardsState.awardsList);

    const openAwardModal = (award: Award) => {
        awardsState.setCurrentAward(award);
        awardsState.setIsAwardModalOpen(true);
    }

    const deleteAward = (award: Award) => {
        if (award?.isPublished) { 
            awardsState.setNotifications({ error: true, message: "Нельзя удалить опубликованную награду" });
            return;
        };
        awardsState.setDeleteAward({isOpen: true, award});
    }

    const menuItems = (award: Award) => [
      {
        name: "Редактировать",
        action: () => openAwardModal(award),
      },
      {
        name: "Удалить",
        action: () => deleteAward(award),
        color: "red",
      },
    ];

    return (
        <>
            {awardsList.map((award) => (
                <AwardsListItem key={award.id} onClick={() => openAwardModal(award)}>
                    <MainTableCell>
                        <MainTableImage
                            prim={award.photo}
                        />
                    </MainTableCell>

                    <MainTableCell>{award.name}</MainTableCell>

                    <MainTableCell>{award.day < awardsQuantity ? award.day : ""}</MainTableCell>

                    <MainTableCell>{award.energyQuantity}</MainTableCell>

                    <MainTableStatusCell status={award.isPublished}>
                        {award.isPublished ? "Опубликовано" : "Не опубликовано"}
                    </MainTableStatusCell>

                    <AwardMenu >
                        <Menu
                            vertical={36}
                            horizontal={12}
                            width={170}
                            items={menuItems(award)}
                        />
                    </AwardMenu>
                </AwardsListItem>
            ))}
        </>
    );
};
