import React from "react";
import * as XLSX from "xlsx";
import { CustomIconButton } from "../../shared/Buttons/CustomIconButton";
import { GetApp } from "@material-ui/icons";
import { User } from "../../../generated/graphql";

interface IUserExportFile{
  createdBy:User;
}

export const ExportUserCSV = ({ answers }) => {
  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FileUsers");
    XLSX.writeFile(workbook, "data.xlsx");
  };

  const getUserAnswers = answers?.map((user) => {
    const { ratting } = user;
    const {
      createdBy,
    }:IUserExportFile = user;

    return {
      firstName:createdBy?.firstName,
      lastName:createdBy?.lastName,
      middleName:createdBy?.middleName,
      email:createdBy?.email,
      phone:createdBy?.phone,
      ratting,
      birthday:createdBy?.birthday,
      displayName: createdBy?.role?.displayName,
    };
  });

  return (
    <>
      <CustomIconButton
        callback={() => downloadExcel(getUserAnswers)}
        mainColor="black"
        children={<GetApp />}
      />
    </>
  );
};
