import { useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  ChartConfiguration,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

interface VerticalBarChartAdapterProps {
  data: ChartConfiguration["data"];
  options: ChartConfiguration["options"];
  unit?: string;
}

export const VerticalBarChartAdapter = ({
  data,
  options,
  unit = "шт.",
}: VerticalBarChartAdapterProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    let chartInstance: ChartJS | null = null;

    if (canvasRef.current) {
      ChartJS.register(
        BarController,
        BarElement,
        CategoryScale,
        LinearScale,
        Title,
        Tooltip,
        Legend
      );
      const updatedOptions = {
        ...options,
        maintainAspectRatio: false,
        scales: {
          ...options.scales,
          y: {
            ...options.scales?.y,
            ticks: {
              ...options.scales?.y?.ticks,
              callback: function (value: any) {
                return value + " " + unit;
              },
            },
          },
        },
      };

      chartInstance = new ChartJS(canvasRef.current, {
        type: "bar",
        data,
        options: updatedOptions,
      });
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [canvasRef, data, options]);

  return <canvas ref={canvasRef} />;
};
