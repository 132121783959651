import React from "react";
import {
  Container,
  List,
  ListItem,
  Subtitle,
  Text,
  Title,
  WeightText,
} from "./PrivatePolicy.style";
import { Box } from "@material-ui/core";

export const UserAgreementEn = () => (
  <Container>
    <Title>TERMS OF USE</Title>
    <Box mt={3}>
      <Text>
        PLEASE READ THESE RULES CAREFULLY. BY USING THE SERVICES, YOU AGREE TO
        CONFORM TO THE TERMS OF USE AND TO THE DATA PROTECTION GUIDELINES.
      </Text>
    </Box>
    <Box mt={3}>
      <Text>
        IF YOU DO NOT CONFORM TO THE TERMS, PLEASE DO NOT USE THE SERVICES
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "40px" }}>
      <Text style={{ margin: "10px 0 0 0" }}>
        This User Agreement (hereinafter referred to as the Agreement)
        represents an open offer (Offer) for the use of the ArBook interactive
        system, which consists of such products as: AR_Teacher, AR_Book, AR_Book
        Market, which are available on the following website{" "}
        <a href="https://arbook.info/">https://arbook.info/</a>, as well as
        VR_Lab, which is available on{" "}
        <a href="https://www.oculus.com/">https://www.oculus.com/</a>.
      </Text>
    </Box>

    <Box mt={3} style={{ textIndent: "40px" }}>
      <Text style={{ margin: "10px 0 0 0" }}>
        Please read this Agreement thoroughly. You agree to the Agreement and
        accept all its provisions from the moment of installing the application
        or using the site.{" "}
      </Text>
    </Box>

    <Box mt={3} style={{ textIndent: "40px" }}>
      <Text style={{ margin: "10px 0 0 0" }}>
        If you do not agree to the terms and conditions specified in this
        Agreement, please do not use the services of the ArBook interactive
        system and the products that this interactive system includes.
      </Text>
    </Box>

    <Box mt={3} style={{ textIndent: "40px" }}>
      <Subtitle>1. TERMS AND DEFINITIONS</Subtitle>
      <Text>
        <strong>Visitor </strong>is an individual person who uses the Website
        without registering a Personal Account.
      </Text>
      <Text>
        <strong>Agreement </strong>– This User Agreement, which means an
        agreement between the User and the Platform regarding all the conditions
        established by the Agreement, as well as consent to the possibility of
        exercising rights and the need to fulfill obligations
      </Text>
      <Text>
        <strong>Personal account </strong> (hereinafter — "Account", "Profile")
        — a personal account of the User on the Website, the registration of
        which allows to receive Services under the conditions defined by these
        Terms of Use.
      </Text>
      <Text>
        <strong>Product </strong>– ArBook interactive system consisting of such
        products as: AR_Teacher, AR_Book, AR_Book Market and VR_Lab.
      </Text>
      <Text>
        <strong>Platform Services </strong> (hereinafter — "Services") —
        provision of the User with access to the internal functionality of the
        site.
      </Text>
      <Text>
        <strong>Platform </strong> – the owner of the Product or individuals
        authorized by the owner of the Product to manage it and other actions
        related to its use.
      </Text>
      <Text>
        <strong>Site </strong> – A set of web pages available on the Internet,
        which are united in terms of content and navigation under a single
        domain name <a href="https://arbook.info/">https://arbook.info/</a> with
        access to the products AR_Teacher, AR_Book, AR_Book Market and the web
        page <a href="https://www.oculus.com/">https://www.oculus.com/</a> with
        access to the VR_Lab product.
      </Text>
      <Text>
        <strong>Content </strong>- any information materials, including textual,
        graphical, audiovisual and other materials, to which the User can gain
        access in the process of using the Services.{" "}
      </Text>
      <Text>
        <strong>User </strong> – a Visitor who has registered in the Personal
        Account and joined this Agreement in an appropriate manner, and uses the
        internal functionality of the Product.
      </Text>
      <Text>
        <strong>A consultant </strong> - is an individual person who provides
        remote consultations on specific issues of using the internal
        functionality of the Website.
      </Text>
      <Text>
        <strong>Platform </strong> - the owner of the Product or persons
        authorized by the owner of the Product to manage it and other actions
        related to its use.
      </Text>
      <Text>
        <strong>Personal data</strong> – information or a set of information
        about an individual person who is identified or can be specifically
        identified.
      </Text>
      <Text>
        <strong>Processing of personal data </strong> – any action or set of
        actions, such as collection, registration, accumulation, storage,
        adaptation, change, update, use and distribution (distribution,
        implementation), depersonalization, destruction of personal data,
        including, using information (automated) systems.
      </Text>
      <Text>
        <strong>AR_Book </strong> - a mobile application that allows you to
        conduct interactive experiments on academic subjects that correspond to
        the school curriculum.
      </Text>
      <Text>
        <strong>AR_Teacher </strong> - a system in the form of a web version for
        teachers, which is used to create and conduct a lesson.
      </Text>
      <Text>
        <strong>AR_Book Market </strong> - a platform in the form of a web
        version, where the content provided by the platform is placed.
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "40px" }}>
      <Subtitle> 2. WHAT YOU AGREE TO BY ACCEPTING THESE TERMS OF USE</Subtitle>
    </Box>

    <Box mt={3} style={{ textIndent: "40px" }}>
      <Text style={{ margin: "10px 0 0 0" }}>
        2.1. General terms of the Agreement:
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        2.1.1. Installing the Product on the User's mobile device, as well as
        visiting the Site, constitutes acceptance of this Agreement and confirms
        the User's agreement with its terms.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        2.1.2. The terms of this Agreement, in accordance with Article 641 of
        the Civil Code of Ukraine, are a public offer - a proposal to conclude
        an affiliation agreement with the Platform, according to which the
        Platform provides the User with access to the Product and the
        possibility of using the Site under the terms of this Agreement.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        2.1.3. By agreeing to the terms of this Agreement, the User confirms his
        legal status and capacity, confirms the authenticity of his data and
        assumes all responsibility for their accuracy, completeness and
        reliability. Also, the User is independently responsible and liable for
        the possibility of using the Site and the Product in connection with
        possible age restrictions.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        2.1.4. The Platform reserves the right to change the terms of this
        Agreement at any time and improve it. The user is considered to have
        been informed of the changes from the moment the new version of the
        Agreement is published on the website. If the User gains access to the
        services/service in any way after this Agreement has been modified, the
        User will be deemed to have read, understood and unconditionally agreed
        to such modifications. The most recent version of this Agreement will be
        available on the website and will supersede all previous versions of
        this Agreement. Changes to the terms and conditions of this public offer
        agreement come into force the moment the User makes the relevant changes
        to the User agreement (public offer contract) posted on the website
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        2.1.5. In case the User does not agree with the terms of this Agreement,
        his use of the Product must be stopped immediately.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>2.2. Parties:</Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        2.2.1. This Agreement regulates contractual relations between the
        Platform and the User, in accordance with the legislation of Ukraine;
        issues not regulated by the Agreement are subject to resolution in
        accordance with the legislation of Ukraine and norms of international
        law
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>2.3. Regarding AR_Book:</Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        2.3.1. To use the AR_Book service, the User must download the mobile
        application and register in the personal account. The condition for
        providing the User with the Services is the registration and
        authorization of the Personal Account on the Website. By registering an
        Account, the User guarantees that the information provided by him is
        true, up-to-date, accurate and complete. When registering, the User
        provides the following information: Surname, First name, e-mail address,
        age. Chooses his user role (parent, teacher, student).
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>2.4. Regarding AR_Teacher</Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        2.4.1. The AR_Teacher service is available in the web version. To use
        AR_Teacher, the User must register and log in on the website. When
        registering, the User provides the following information: Name, e-mail
        address, name of the educational institution. For access to certain
        functionalities of AR_Teacher, the User may be charged a fee in
        accordance with these Rules. Information regarding the cost of Services
        (Tariffs) is indicated on the website. The User confirms that he is
        familiar with the Tariffs. Payment for Services is carried out in the
        User's Personal Account. Payment for Services is carried out through one
        hundred percent advance payment in accordance with the selected Tariff.
        The moment of payment is considered to be the time when the funds are
        credited to our account. AR_Teacher does not process personal data of
        payers provided to the processing center for payment settlements.
        Neither does it store bank card details on its resources, including
        servers, cloud storage, etc. The user is solely responsible for the
        correctness of the payments made by him. All commissions and fees are
        paid by the User himself. In case of insufficient cash balance for
        access to any functionality of the Platform Services, the funds are
        considered the benefit of the company and are not subject to return.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        2.5. regarding AR_Book Market
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        2.5.1. The AR_Book Market service is available in a web version. To use
        AR_Book Market, the User must log in to the website. On the website, the
        User will have access to all content formats for teachers, children and
        parents. AR_Book Market is a library of all educational content that the
        User can add to any other AR_book service.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>2.6. regarding VR_Lab</Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        2.6.1. The VR_Lab service hosts virtual experiments and lessons based on
        content from the AR_Book Market service. The User has access to VR_Lab
        free of charge and without registration.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        2.7. Access to the internal functionality of the Product is provided on
        a paid and/or free basis. All information about the functionality of the
        Platform and the terms of their use are available to the User on the
        Website. The User agrees that he is responsible for familiarizing
        himself with the terms of access to the functionality of the Website.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        2.8. In accordance with Article 7 of the GDPR, the User gives his
        unequivocal and unconditional consent to the processing of his personal
        data by the products of the ArBook system, including such as: Surname,
        First name, e-mail address, age and phone number
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        2.9. In accordance with Art. 8 of the GDPR, if the user is under 14
        years of age, consent to the use of his personal data is granted by
        parents, adoptive parents or guardians. Users and/or parents, adoptive
        parents, guardians, in accordance with the current legislation of
        Ukraine, are responsible for the use of the Program and the Site by
        persons from 14 to 18 years of age.
      </Text>
    </Box>

    <Box mt={3} style={{ textIndent: "40px" }}>
      <Subtitle>3. RIGHTS AND OBLIGATIONS OF THE USER</Subtitle>
      <Text style={{ margin: "10px 0 0 0" }}>
        3.1. The User has the right to use the Product in the manner and under
        the conditions stipulated in this Agreement. The User's use of the
        features/services of the Platform means the User's unconditional
        agreement with all clauses of this agreement, with all its amendments
        and additions, and undeniable acceptance of its terms. If the User does
        not agree with any of the terms of this Agreement, the User is obliged
        to refuse further use of the features/services of the Platform.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        3.2. The User has the right to use the features of the Product provided
        by the Platform only for legal purposes, to comply with current
        legislation, as well as the terms of this Agreement, not to violate the
        rights and legitimate interests of the Platform.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        3.3. The User is obliged to fully familiarize himself with this
        Agreement before accepting it. In case of disagreement with the terms of
        the Agreement, the use of the Product by the User must be stopped
        immediately.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        3.4. The User agrees to do nothing that may be considered as contrary to
        the legislation of Ukraine or the norms of international law, including
        in the field of intellectual property, copyright and/or related rights,
        as well as any actions that lead or may lead to the violation of normal
        functioning of the Product.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        3.5. When using the Product, the User is obliged to provide truthful,
        accurate and complete information in his possession, as well as to keep
        this information up to date.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        3.6. The User assures that he will not use independently or with the
        involvement of third parties the features of the Product for purposes
        that may be qualified as the violation of the rights of third parties to
        intellectual property objects, unfair competition, or other violations
        of the current legislation of Ukraine.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        3.7. The User has no right to take actions that affect the normal
        operation of the Product and constitute its dishonest use.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        3.8. Users undertake to comply with the provisions of this Agreement,
        the legislation of Ukraine, including the Law of Ukraine “On the
        Protection of Consumer Rights”.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        3.9. The User is prohibited to decompile the Product on his own or with
        the involvement of third parties, as well as distribute, make public and
        provide access to others, reverse-engineer the Program, the Site or
        their individual elements.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        3.10. The User has no right to disclose confidential information of the
        Platform or third parties obtained during the conclusion, execution or
        termination of this Agreement.
      </Text>
    </Box>

    <Box mt={3} style={{ textIndent: "40px" }}>
      <Subtitle>4.RIGHTS AND OBLIGATIONS OF THE PLATFORM</Subtitle>
      <Text style={{ margin: "10px 0 0 0" }}>
        4.1. The Platform has the right to transfer the rights and obligations
        under this Agreement to third parties for the purpose of fulfilling this
        Agreement, without additional consent of the User.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        4.2. For the above-mentioned purpose, the Platform reserves the right to
        delete the information posted on the Site, to initiate technical and
        legal measures to terminate access to the Product of those users who,
        according to the Platform's conclusion, create problems for the use of
        the Product by other Users, or users who violate the requirements of the
        Agreement.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        4.3. In case of providing inaccurate information, the Platform has the
        right to suspend or terminate the use of the Product by the User.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        4.4. The platform does not carry out mandatory verification of
        information posted by Users.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        4.5. The Platform reserves the right to unilaterally terminate this
        Agreement at any time for organizational or technical reasons.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        4.6. The Platform processes the user's personal data in order to fulfill
        the Agreement between the Platform and the User in accordance with the
        Law of Ukraine "On the Protection of Personal Data". By accepting the
        terms of this Agreement, the User consents to the use of his personal
        data by the Platform.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        4.7. The Platform has the right to change, edit or delete any content of
        the Product at its discretion.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        4.8. In order to improve and increase the stability of the Product’s
        functionality, the Platform has the right to collect, store and process
        statistical information about the User's use, which includes:
      </Text>
    </Box>
    <Box mt={3} pl="20%">
      <Text style={{ margin: "10px 0 0 0" }}>
        - data on the model of User's mobile device (device identifier);
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - statistical information about the use of the Program and the Site;
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - account logins used by the User of mobile applications of social
        networks and other mobile applications, for users with the Android
        operating system, as well as for users with other operating systems when
        using the Product functions related to social networks (Facebook).
      </Text>
    </Box>
    <Box mt={3} style={{ textIndent: "40px" }}>
      <Text style={{ margin: "10px 0 0 0" }}>
        4.9. The Platform has the right to unilaterally change the terms of this
        Agreement without notifying the User.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        4.10. The Platform is obliged to provide the features/services listed on
        the Site.
      </Text>
    </Box>

    <Box mt={3} style={{ textIndent: "40px" }}>
      <Subtitle>5. PROCEDURE FOR USING THE PRODUCT</Subtitle>
      <Text style={{ margin: "10px 0 0 0" }}>
        5.1. The product consists of such services as: AR_Book, AR_Teacher,
        AR_Book Market, VR_Lab. Services are interconnected as follows: AR_Book
        Market is the library of all content; from this service the content is
        added to other services. AR_Teacher, which is based on the content from
        the AR_Book Market, creates lessons, plans, quizzes etc. The content
        created in AR_Teacher is published on the AR_Book service, where other
        users can familiarize themselves with it. VR_Lab hosts virtual
        experiments based on the content from the AR_Book Market.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        5.2. According to this Agreement, the User has the right to download the
        Product from the site, having previously authorized himself. To use the
        AR_Book service, the User must download the mobile application and
        register in the personal account. The AR_Teacher service is available in
        web version. To use AR_Teacher, the User must register and log in on the
        website. The AR_Book Market service is available in a web version. To
        use AR_Book Market, the User must log in to the website. The VR_Lab
        service hosts virtual experiments and lessons based on the content from
        the AR_Book Market service. Access to VR_Lab is free of charge and
        without registration.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        5.3. To use the Product, mandatory authorization on the site is
        required, and it includes the provision of the following data about the
        User: the User's name, city of residence, age and email.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        5.4. The product is available to Users without any age restrictions.
        Parents, adoptive parents, guardians are responsible for its use and for
        any damage related to such use by persons under 14 years of age. Users
        and/or parents, adoptive parents, guardians, in accordance with the
        current legislation of Ukraine, are responsible for the use of the
        Program and the Site by individuals from 14 to 18 years of age. If
        according to the current legislation of the country in which the User
        lives or in which he plans to use the Product, its use is prohibited,
        the User has no right to such use and is responsible for such illegal
        actions.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        5.5. To use the Product, you need a compatible Device that meets the
        system requirements, as well as an Internet connection and compatible
        software. The possibility of using the Program and the efficiency of its
        operation depend on these factors. The User is responsible for meeting
        the system requirements.
      </Text>
    </Box>

    <Box mt={3} style={{ textIndent: "20px" }} pl="calc(20% - 20px)">
      <Subtitle>Requirements:</Subtitle>
    </Box>
    <Box mt={3} style={{ textIndent: "20px" }} pl="20%">
      <Text style={{ margin: "10px 0 0 0" }}>
        - Works on devices that support AR technologies;
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - Provision of a personal computer (desktop computer/laptop);
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - Availability of properly working microphone and headphones;
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - Camera: Photo and video recording;
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - Microphone: Audio recording;
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - The required Internet connection speed is from 5 Mbit/s, ping not
        higher than 50;
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - Wi-Fi connection data: View Wi-Fi connections.
      </Text>
    </Box>

    <Box mt={3} style={{ textIndent: "40px" }}>
      <Text style={{ margin: "10px 0 0 0" }}>
        5.6. The use of the Product may require the installation of updates from
        time to time.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        5.7. When downloading, third parties (for example, an Internet service
        provider or a mobile operator) may charge for data transfer or access to
        them. Such payments are made at the expense of the User and the Platform
        bears no responsibility related to such payments.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        5.8. In accordance with the above-mentioned provisions, the User has the
        right to use the Product as follows:
      </Text>
    </Box>

    <Box mt={3} style={{ textIndent: "20px" }} pl="20%">
      <Text style={{ margin: "10px 0 0 0" }}>
        - Limited, which means that the User can use the Product only for the
        purposes established in this Agreement;
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - Non-exclusive, which means that the Platform can grant the same and
        similar rights to other individuals;
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - Operation of this Agreement is applicable worldwide, meaning no
        territorial limitation, unless applicable law prohibits such License in
        certain countries.
      </Text>
    </Box>

    <Box mt={3} style={{ textIndent: "20px" }} pl="calc(20% - 20px)">
      <Subtitle>6. USER WARRANTIES</Subtitle>
    </Box>
    <Box mt={3} style={{ textIndent: "20px" }} pl="20%">
      <Text style={{ margin: "10px 0 0 0" }}>6.1. The user guarantees:</Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - to perform actions not aimed at misleading other users;
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - not to post information that violates copyrights, rights to marks for
        goods and services, industrial property rights and/or rights to other
        objects of intellectual property belonging to the Platform and/or third
        parties, as well as not to violate other rights in the field of
        intellectual property, in accordance with the current legislation of
        Ukraine;
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - not to post information that violates the rights and legitimate
        interests of third parties (including posting photos and videos, the
        main object of which is a person, if this person did not consent to the
        posting of photos or videos with his participation);
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - not to post materials of an promotional, erotic, pornographic or
        offensive nature;
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - not to use any computer programs for automated information collection;
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - not to illegally collect, systematize, store or distribute personal
        information of other users;
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - not to publish content that: dishonors or justifies the breach of
        dignity of any person. This includes "junk mail," viral emails,
        unsolicited bulk mailings or spam, URL-addresses, e-mail addresses, or
        telephone and fax numbers of third parties.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - not to distribute false information that misleads or promotes illegal
        activity or behavior, is offensive, threatening, obscene, shameful, if
        you know in advance of the nature of such information.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - not to solicit users' passwords or personal information for commercial
        or illegal use.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        - not to engage other users in commercial activities without prior
        written agreement with the Platform.
      </Text>
    </Box>

    <Box mt={3} style={{ textIndent: "20px" }}>
      <Text style={{ margin: "10px 0 0 0" }}>
        6.2. Recognizing the international nature of the Internet, the User
        assumes responsibility for compliance with all the relevant rules and
        laws related to the User's actions on the Internet.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        6.3. Unless the contrary is proven by the User, any actions performed
        using his login and password are considered to have been performed by
        the following User. In case of unauthorized access to the User's login
        and password, or the distribution of the login and password, the User is
        obliged to immediately notify the Platform in accordance with the
        established procedure.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        6.4. The User guarantees that he will not take any actions aimed
        exclusively at causing damage to the Platform, Platform customers,
        cellular mobile operators, rights holders or other persons.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        6.5. The user guarantees that he will not violate the rights to the
        objects of intellectual property that do not belong to him.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        6.6. Unless the contrary is proven by the User, any actions which were
        performed using his mobile device are considered to have been performed
        by the respective User.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        6.7. The User guarantees to use the Product exclusively for the purposes
        permitted by this Agreement in compliance with their provisions, as well
        as the requirements of applicable law and generally accepted practice
        and does not violate property and/or personal non-proprietary rights of
        third parties.
      </Text>
    </Box>

    <Box mt={3} style={{ textIndent: "20px" }}>
      <Subtitle>7. LIABILITY OF THE PARTIES</Subtitle>
      <Text style={{ margin: "10px 0 0 0" }}>
        7.1. The User is solely responsible for any violation of the terms and
        obligations established by this Agreement and/or applicable law, as well
        as for all consequences of such violations (including any loss or damage
        that the Platform and/or third parties may suffer). In the case of
        bringing to responsibility or imposition of fines on the Platform in
        connection with violations of the rights of third parties committed by
        the User, as well as prohibitions or restrictions established by law,
        the User is obliged to compensate the Platform for damages in full.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        7.2. Services are provided on an "as is" and "as available" basis. The
        platform does not carry responsibility for and does not give any
        guarantees regarding the completeness, reliability and quality of the
        Services; accuracy and reliability of the Content; any results of the
        provision of Services; compliance of the Services, Content with the
        User's requirements and/or expectations; the suitability of the Services
        for any particular purpose; absence of violations in the provision of
        Services.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        7.3. The User agrees that access to the Product may from time to time be
        limited or provided more slowly, including due to software failure;
        failure of servers, networks, equipment, other electronic or mechanical
        equipment; rebooting system capacities; interruption of power supply,
        other service maintenance, strike or other work stoppage (partial or
        full); state or regulatory restrictions; for any other reason which is
        beyond the control of the Platform.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        7.4. In case of violation of the rules of use of the Product specified
        in this Agreement, as well as in case of the violation of clause 6.4. of
        this Agreement, the User undertakes to compensate the Platform for
        damage caused by such actions.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        7.5. The platform does not carry responsibility for negative
        consequences and damages arising as a result of events and circumstances
        beyond its scope of competence, as well as for the actions (inactions)
        of third parties.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        7.6. The platform does not carry responsibility for any technical
        failures or other problems of any telephone networks or services,
        computer systems, servers or providers, computer or telephone equipment,
        software, failures of email services or scripts due to technical
        reasons.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        7.7. The platform does not carry responsibility for any damage to the
        user's or another person's computer, mobile device, any other hardware
        or software caused by or related to the downloading of Product
        materials.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        7.8. The platform does not carry responsibility for the effect of the
        Product Materials on the physical features of person's body, such as
        health, vision, psychological state, and others.
      </Text>
    </Box>

    <Box mt={3} style={{ textIndent: "20px" }}>
      <Subtitle>8. RESOLUTION OF DISPUTES</Subtitle>
      <Text style={{ margin: "10px 0 0 0" }}>
        8.1. Appeals, proposals and claims of individuals and legal entities to
        the Platform, related to the maintenance and functioning of the Product,
        violations of the rights and interests of third parties, requirements of
        the legislation of Ukraine, as well as for requests of persons
        authorized by the legislation of Ukraine, may be directed to the
        following e-mail address:
        <a href="https://arbook.info/"> https://arbook.info/</a>
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        8.2. The User bears full responsibility for relations with other Users,
        including for the resolution of disputes between themselves.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        8.3. The parties are responsible for the violation of the provisions and
        conditions stipulated by these Rules, in accordance with the current
        legislation of Ukraine.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        8.4. The parties will endeavor to resolve through negotiation any
        disputes and/or claims that may arise in connection with these Terms,
        including any questions relating to their validity, breach, termination
        or invalidity.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        8.5. The User and the Platform agree that any legal dispute shall be
        considered a dispute between the Platform and the User personally. To
        the maximum extent permitted by law, no litigation shall be consolidated
        with any other proceeding, no litigation shall be resolved on a class
        basis or through the use of class proceedings, and no litigation shall
        be referred to representatives on behalf of the general public or
        otherwise persons.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        8.6. The above-mentioned provisions on mandatory court proceedings do
        not apply to the following disputes: (1) any disputes aimed at ensuring
        compliance or protection of any intellectual property rights belonging
        to the User or to the Platform (2) on complaints about taking measures
        to secure the claim.
      </Text>
    </Box>

    <Box mt={3} style={{ textIndent: "20px" }}>
      <Subtitle>9. INTELLECTUAL PROPERTY</Subtitle>
      <Text style={{ margin: "10px 0 0 0" }}>
        9.1. All content, design, graphics, combinations, translation, digital
        reproduction and other materials related to the provision of services
        for the use of the Product are protected in accordance with the current
        laws on copyright, trademark and property (including but not limited to
        intellectual property rights) and belong to the Platform. Copying,
        rearrangement, redistribution or publication by the User of any
        intellectual property objects, unless expressly provided for in this
        Agreement, is strictly prohibited.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        9.2. This Agreement does not transfer to the User any rights to any
        objects of intellectual property of the Platform (including, but not
        limited to, marks for goods and services present on the Site or those
        used by the Platform) or third parties, if the transfer of these rights
        is clearly not specified, and all rights to property and profits in
        relation to such property remain exclusively with the Platform.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        9.3. The User's right to use the intellectual property of the Platform
        is limited to the rights expressly granted in accordance with these
        Rules and are subject to their full compliance by the User.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        9.4. Use of the Product does not grant the User any rights or licenses
        for any use of any marks for goods and services.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        9.5. The User is prohibited from any commercial use of any information
        in any form posted by the Platform. In case of non-commercial use by the
        User of the information posted for the functioning of the Product, the
        User is obliged to indicate the authorship and hyperlink to the Platform
        or other owner of intellectual information rights (if such information
        does not belong to the Platform) in each individual case of information
        use.
      </Text>
    </Box>

    <Box mt={3} style={{ textIndent: "20px" }}>
      <Subtitle>10. OTHER TERMS</Subtitle>
      <Text style={{ margin: "10px 0 0 0" }}>
        10.1. The failure to exercise or enforce any right or provision of this
        Agreement shall not constitute a waiver of such right or provision. This
        Agreement is a complete agreement between the User and the Platform
        regarding the use of the Product, as well as the Services, and any
        previous Agreements that may have existed between the User and the
        Platform are replaced
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        10.2. If any provision of this Agreement is proven to be unenforceable,
        such term or provision shall be superseded by a term or provision that
        most closely matches the intent underlying such term or provision, other
        provisions.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        10.3. The name and list of functionality of the Product may change from
        time to time without prior notice to the User. The Platform has the
        right, at its sole discretion, to discontinue (temporarily or
        permanently) any individual functionality without prior notice.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        10.4. If one or more provisions of the Agreement are for any reason
        invalid, have no legal force, then such invalidity does not affect the
        validity of any other provision of the Agreement that remains in force.
      </Text>
    </Box>
  </Container>
);
