import { useReactiveVar } from "@apollo/client";
import { AwardTypeEnum } from "../../../../../generated/graphql";
import { awardsState } from "../../state";
import { Autocomplete } from "@material-ui/lab";
import { ModalInput } from "../../../../shared/Style/Style";
import { getSelectTypesOptions } from "../../utils";
import { AwardField } from "../../types";
import { AwardNameMap } from "../../maps";

export const SelectAwardType = () => { 
  const award = useReactiveVar(awardsState.award);
  const awardsList = useReactiveVar(awardsState.awardsList);
  const inputErrors = useReactiveVar(awardsState.inputErrors);
  const isLoading = useReactiveVar(awardsState.isLoading);

  const { type } = award;
  const selectOptions = getSelectTypesOptions(awardsList, type);
  const isBonusSelected = !!(award?.id && type && type === AwardTypeEnum.Bonus);
  const isDisabled = isLoading || isBonusSelected;

  const handleTypeChange = (_, selectedValue: string) => {
    const key = Array.from(AwardNameMap).find(([_, value]) => value === selectedValue)?.[0];
    return awardsState.setAwardType(key);
  };
  const getOptionLabel = (option: string) => {
    return selectOptions.find(item => item === option) || null;
  };

    return (
        <Autocomplete
          disabled={isDisabled}
          options={selectOptions}
          value={AwardNameMap.get(type)}
          onChange={handleTypeChange}
          getOptionLabel={getOptionLabel}
          onFocus={() =>
            awardsState.setInputErrors(AwardField.Type, false)
          }
          renderInput={(params) => {
            return (
              <ModalInput
                {...params}
                error={inputErrors.type.error}
                label="Тип награды"
                fullWidth
                variant="filled"
                margin="normal"
                required
              />
            );
          }}
        />
    );
};
