import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  GetProjectDocument,
  Project,
  ProjectFile,
  UpdateProjectFilesDocument,
  UpdateProjectFilesMutation,
} from "../../../generated/graphql";
import { Close } from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import { Box, TextField, Typography } from "@material-ui/core";
import { getFileExtensionHandler } from "../../shared/Utils/StringOperations/getFileExtensionHandler";
import { CustomIconButton } from "../../shared/Buttons/CustomIconButton";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import {
  HiddenInput,
  HiddenInputLabel,
  secondary,
  UnderlayButton,
  useStyles,
} from "../../shared/Style/Style";
import { ButtonCustom } from "../../shared/Style/Style";
import { ProjectAddLinkModalContainer } from "./ProjectModal.style";
import { FileName } from "../../MobileAppEdit/MobileAppEdit.style";
import { PersonalData } from "../UserEdit/UserEdit.style";

export interface ProjectFilesData {
  title: string;
  values?: Project;
}

export const AddProjectFileModal = ({
  open,
  showModal,
  projectId,
  file,
}: {
  open: boolean;
  showModal(param: boolean): void;
  projectId: string;
  file?: ProjectFile;
}) => {
  const classes = useStyles();
  const input = useRef<HTMLInputElement | null>(null);
  const [fileName, setFileName] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const [requestError, setRequestError] = useState(false);

  const [
    updateFile,
    { data: fileData, loading: updateFileLoading, error: updateFileError },
  ] = useMutation<UpdateProjectFilesMutation>(UpdateProjectFilesDocument, {
    refetchQueries: [{ query: GetProjectDocument, variables: { projectId } }],
  });

  useEffect(() => {
    if (fileData) {
      showModal(false);
    }
    if (updateFileError) {
      setRequestError(true);
    }
  }, [fileData, updateFileError]);

  return (
    <Dialog
      open={open}
      aria-labelledby="simple-dialog-title"
      onClose={() => showModal(false)}
    >
      <ProjectAddLinkModalContainer>
        <Box mb={4}>
          <Typography component={PersonalData} variant="subtitle1" gutterBottom>
            Дополнительные файлы
          </Typography>
        </Box>

        <Box component="div" width="100%">
          <form>
            <Box display="flex" alignItems="center">
              <TextField
                style={{ marginBottom: "0" }}
                name="name"
                id="name"
                className={classes.root}
                margin="normal"
                fullWidth
                label="Название"
                variant="filled"
                type="text"
                value={fileName || file?.name}
                onChange={(e) => {
                  setFileName(e.target.value);
                  setShowErrors(false);
                }}
                error={showErrors && !fileName}
                helperText={showErrors && !fileName && "Обязательное поле"}
              />
              {file ? (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml={2}
                >
                  <FileName href="projectFile">
                    Файл{getFileExtensionHandler(file.file)}
                  </FileName>

                  <CustomIconButton
                    disabled={updateFileLoading}
                    callback={() => {
                      updateFile({
                        variables: { projectId, deleteFileId: file.id },
                      });
                    }}
                    mainColor="red"
                    disabledColor={secondary}
                    children={<Close />}
                  />
                </Box>
              ) : (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml={2}
                >
                  <HiddenInputLabel
                    htmlFor="projectFile"
                    onClick={() => {
                      {
                        input.current.value = "";
                        setShowErrors(true);
                      }
                    }}
                  >
                    <UnderlayButton
                      disabled={(showErrors && !fileName) || updateFileLoading}
                    >
                      Прикрепить
                    </UnderlayButton>
                  </HiddenInputLabel>
                </Box>
              )}
            </Box>
            <HiddenInput
              id="projectFile"
              type="file"
              ref={input}
              disabled={showErrors && !fileName}
              onChange={({ target: { files } }) => {
                  updateFile({
                    variables: {
                      projectId,
                      uploadFile: {
                        file: files[0],
                        name: fileName,
                      },
                    },
                  });
              }}
            />

            {/*<Box display="flex" justifyContent="center" mb={3} mt={4}>*/}
            {/*  <ButtonCustom type="submit">Со[</ButtonCustom>*/}
            {/*</Box>*/}
          </form>
        </Box>

        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={updateFileError && "При удалении файла произошла ошибка"}
        />
      </ProjectAddLinkModalContainer>
    </Dialog>
  );
};
