import { useEffect } from "react";
import { useApolloClient, useReactiveVar } from "@apollo/client";
import { useParams } from "react-router-dom";
import { robotsState } from "../../state";
import { GameModelStateFileTypeEnum } from "../../../../../generated/graphql";
import { Container } from "@material-ui/core";
import { Header } from "../../../../shared/Style/Style";
import { ErrorMessageSnackbar } from "../../../../shared/ErrorMessageSnackbar";
import { SuccessSnackbar } from "../../../../shared/SuccessSnackbar";
import { StageEditBlock } from "../../components";
import { initialNotifications } from "../../constants";
import { useGetStageTypeId } from "../../hooks/useGetStageTypeId";
import { useValidateStageFields } from "../../hooks/useValidateStageFields";

export const RobotStagePage = () => {
  const apolloClient = useApolloClient();
  robotsState.initMobileAppRobots(apolloClient);
  
  const { stageId, robotId } = useParams<{ stageId: string, robotId: string }>();
  const stage = useReactiveVar(robotsState.stageGeneralInfo);
  const notifications = useReactiveVar(robotsState.notifications);
  const { validateStageFields } = useValidateStageFields();
  const stageTypeId = useGetStageTypeId();

  const stageParams = {
    updateGameModelStateId: stageId,
    input: {
      name: stage.name,
      description: stage.description,
      energyPrice: stage.energyPrice,
      experiencePrice: stage.experiencePrice ?? 0,
      stageId: stageTypeId,
    },
  };

  const fetchRobotStage = async () => await robotsState.getRobotStage({ getGameModelStateId: stageId });
  const fetchRobotStagesForSelect = async () => await robotsState.getRobotStagesForSelect({ gameModelId: robotId });
  const fetchStageTypes = async () => {
    await robotsState.getRobotStageTypes();
    await robotsState.getArtifactClothTypes();
    await robotsState.getArtifactAnimationTypes();
  };

  useEffect(() => {
    fetchRobotStage();
    fetchRobotStagesForSelect();
    fetchStageTypes();
    return () => robotsState.clearStage();
  }, []);

  const updateStageInfoHandler = async () => { 
    const isValidFields = validateStageFields();
    
    if (!isValidFields) return;

    await robotsState.updateRobotStage(stageParams);
    await fetchRobotStage();
  };

  const uploadPhotoHandler = async (file: File) => {
    if (!file) return;

    await robotsState.updateRobotStageFile({
      updateGameModelStateFileId: stage?.id,
      file,
      fileType: GameModelStateFileTypeEnum.Image,
    });

    await fetchRobotStage();
  };
  
  return (
    <Container maxWidth="lg">
      <Header>Редактирование стадии робота</Header>

      <StageEditBlock
        updateStageGeneralInfo={updateStageInfoHandler}
        uploadStagePhoto={uploadPhotoHandler}
      />

      {notifications?.error && (
        <ErrorMessageSnackbar
          open={notifications?.error}
          closeHandler={() => robotsState.setNotifications(initialNotifications)}
          message={notifications?.message}
        />
      )}

      {notifications?.success && (
        <SuccessSnackbar
          open={notifications?.success}
          handleClose={() => robotsState.setNotifications(initialNotifications)}
          message={notifications?.message}
          snackbarProps={{
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          }}
        />
      )}
    </Container>
  );
};
