import { useQuery } from "@apollo/client";
import { Dialog, DialogContent, DialogTitle, Table, CircularProgress, TableBody, TableRow } from "@material-ui/core";
import React from "react";
import { GetEmployeeWorkloadDayReportDocument, GetEmployeeWorkloadDayReportQuery } from "../../../generated/graphql";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { CustomTable } from "../../shared/CustomTable";
import { CustomTableCell, CustomTableHead, CustomTableHeaderCell } from "../../shared/CustomTable/CustomTable.style";
import { LoadingBox } from "../../shared/Style/Style";
import { convertSecondsToHoursHandler } from "../../shared/Utils/ConvertOperations/convertSecondsToHoursHandler";

interface IEmployeeReportDayModalProps {
  open: boolean;
  closeHandler(): void;
  date: string;
  userId: string;
}

const headers = [
  'Проект',
  'Этап',
  'Задача',
  'Ключ задачи',
  'Затраченое время'
]

export const EmployeeReportDayModal = ({ open, closeHandler, date, userId } : IEmployeeReportDayModalProps) => {

  const { data, loading, error } = useQuery<GetEmployeeWorkloadDayReportQuery>(
    GetEmployeeWorkloadDayReportDocument, {
      variables: {
        userId,
        date,
      }
    }
  );

  let content;

  if (loading) {
    content = (
      <TableRow>
        <CustomTableCell colSpan={5} padding='none'>
          <LoadingBox>
            <CircularProgress
              color='inherit'
              size={36}
            />
          </LoadingBox>
        </CustomTableCell>
      </TableRow>
    )
  }

  if (error) {
    content = (
      <TableRow>
        <CustomTableCell colSpan={5}>
          При загрузке задач произошла ошибка
        </CustomTableCell>
      </TableRow>
    )
  }

  if (data) {
    const rows = data.getEmployeeWorkloadDayReport;

    content = (
      <>
        {rows.length
          ? rows.map(row => (
            <TableRow>
              {[
                row.projectName,
                row.epicKey,
                row.taskName,
                row.key,
                convertSecondsToHoursHandler(row.seconds),
              ].map((field, i) => (
                <CustomTableCell
                  key={`${i} ${field}`}
                >
                  {field}
                </CustomTableCell>
              ))}
            </TableRow>
          ))
          : (
            <TableRow>
              <CustomTableCell colSpan={5}>
                Задачи отсутствуют
              </CustomTableCell>
            </TableRow>
          )
        }
      </>
    )
  }

  const table = (
    <Table>
      <CustomTableHead>
        <TableRow>
          {headers.map(header => (
            <CustomTableHeaderCell
              key={header}
              width={200}
            >
              {header}
            </CustomTableHeaderCell>
          ))}
        </TableRow>
      </CustomTableHead>

      <TableBody>
        {content}
      </TableBody>
    </Table>
  )

  return (
    <Dialog
      open={open}
    >
      <DialogContent>
        <CloseIconButton
          close={closeHandler}
        />

        <DialogTitle>Отчет за день</DialogTitle>

        <CustomTable data={data?.getEmployeeWorkloadDayReport || []} tableContent={table} />
      </DialogContent>
    </Dialog>
  )
}