import { SvgIcon } from "@material-ui/core";

export const SubtitleIcon = (props: Record<string, never>) => {
  return (
    <SvgIcon {...props} viewBox="0 0 38 22">
      <path d="M12.75 0.582977H16.9167V21.4163H12.75V13.083H4.41667V21.4163H0.25V0.582977H4.41667V8.91631H12.75V0.582977ZM29.4167 17.2496C30.4792 16.3955 30.6667 15.958 31.625 15.0621C32.5354 14.2288 33.3917 13.3371 34.1875 12.3955C34.8833 11.583 35.4792 10.6871 35.9583 9.72881C36.375 8.91631 36.5937 8.01631 36.6042 7.10381C36.6146 6.18714 36.4229 5.27464 36.0417 4.43714C35.6729 3.63506 35.1312 2.92464 34.4583 2.35381C33.7375 1.76423 32.9104 1.31839 32.0208 1.04131C31.0104 0.72256 29.9583 0.568393 28.8958 0.582977C28.1458 0.582977 27.4021 0.651727 26.6667 0.79131C25.9521 0.91631 25.2542 1.12048 24.5833 1.39548C23.95 1.66631 23.3479 2.01006 22.7917 2.41631C22.1937 2.85381 21.625 3.32673 21.0833 3.83298L23.6667 6.37464C24.3083 5.81631 25.0062 5.32881 25.75 4.91631C26.4792 4.49964 27.3062 4.28298 28.1458 4.29131C29.0937 4.22881 30.0333 4.51214 30.7917 5.08298C31.4375 5.66214 31.7854 6.50798 31.7292 7.37464C31.7 8.19964 31.4375 8.99964 30.9792 9.68714C30.3854 10.6309 29.6875 11.5038 28.8958 12.2913C27.9792 13.1871 26.8542 14.208 25.5833 15.2705C24.3125 16.333 22.6458 17.4788 21.0833 18.708V21.4163H37.75V17.2496H29.4167Z" fill="black"/>
    </SvgIcon>
  );
};

export const HeaderIcon = (props: Record<string, never>) => {
  return (
    <SvgIcon {...props} viewBox="0 0 30 22">
      <path d="M12.916 0.583984H17.0827V21.4173H12.916V13.084H4.58268V21.4173H0.416016V0.583984H4.58268V8.91732H12.916V0.583984ZM26.6035 0.583984C25.366 2.56315 23.4702 4.03815 21.2494 4.75065V6.83398H25.416V21.4173H29.5827V0.583984H26.6035Z" fill="black"/>
    </SvgIcon>
  );
};

export const ListIcon = (props: Record<string, never>) => {
  return (
    <SvgIcon {...props} viewBox="0 0 30 30">
      <path d="M5.97548 0.0820312H0V6.05752H5.97548V0.0820312Z" fill="black"/>
      <path d="M30.0006 1.68164H8.47656V4.32311H30.0006V1.68164Z" fill="black"/>
      <path d="M5.97548 12.0078H0V17.9833H5.97548V12.0078Z" fill="black"/>
      <path d="M30.0006 13.6074H8.47656V16.2489H30.0006V13.6074Z" fill="black"/>
      <path d="M5.97548 23.9414H0V29.9169H5.97548V23.9414Z" fill="black"/>
      <path d="M30.0006 25.5332H8.47656V28.1747H30.0006V25.5332Z" fill="black"/>
    </SvgIcon>
  );
};

export const TextIcon = (props: Record<string, never>) => {
  return (
    <SvgIcon {...props} viewBox="0 0 44 18">
      <path d="M4.57 17V3.36H0.39V0.499999H11.99V3.36H7.8V17H4.57ZM17.5308 17.32C16.4241 17.32 15.4308 17.0833 14.5508 16.61C13.6774 16.13 12.9841 15.48 12.4708 14.66C11.9574 13.84 11.7008 12.9133 11.7008 11.88C11.7008 11.12 11.8441 10.41 12.1308 9.75C12.4241 9.09 12.8308 8.51333 13.3508 8.02C13.8708 7.52 14.4741 7.13333 15.1608 6.86C15.8474 6.58 16.5908 6.44 17.3908 6.44C18.4574 6.44 19.3908 6.66333 20.1908 7.11C20.9974 7.55 21.6241 8.16333 22.0708 8.95C22.5241 9.73667 22.7508 10.6533 22.7508 11.7C22.7508 11.7867 22.7474 11.8633 22.7408 11.93C22.7408 11.99 22.7308 12.1067 22.7108 12.28H14.8608C14.8608 12.8 14.9774 13.2533 15.2108 13.64C15.4508 14.0267 15.7841 14.33 16.2108 14.55C16.6374 14.7633 17.1341 14.87 17.7008 14.87C18.2341 14.87 18.7041 14.7667 19.1108 14.56C19.5241 14.3533 19.9208 14.0233 20.3008 13.57L22.5108 14.68C22.0308 15.5067 21.3508 16.1533 20.4708 16.62C19.5908 17.0867 18.6108 17.32 17.5308 17.32ZM14.9708 10.58H19.7308C19.6641 10.1867 19.5208 9.84667 19.3008 9.56C19.0874 9.27333 18.8141 9.05333 18.4808 8.9C18.1474 8.74667 17.7708 8.67 17.3508 8.67C16.9041 8.67 16.5108 8.74667 16.1708 8.9C15.8308 9.05333 15.5574 9.27333 15.3508 9.56C15.1441 9.84667 15.0174 10.1867 14.9708 10.58ZM23.6209 17L27.9909 11.65L24.2409 6.76H27.8009L29.8609 9.51L31.9209 6.76H35.4809L31.7309 11.65L36.1009 17H32.4209L29.8609 13.76L27.3009 17H23.6209ZM41.3556 17.32C40.3356 17.32 39.5423 16.9967 38.9756 16.35C38.409 15.6967 38.1256 14.7867 38.1256 13.62V9.09H36.7556V6.76H38.1256V2.92H41.1956V6.76H43.4456V9.09H41.1956V13.4C41.1956 13.7867 41.2723 14.08 41.4256 14.28C41.5856 14.48 41.809 14.58 42.0956 14.58C42.4156 14.58 42.7223 14.4733 43.0156 14.26L43.7856 16.72C43.439 16.9067 43.049 17.0533 42.6156 17.16C42.189 17.2667 41.769 17.32 41.3556 17.32Z" fill="black"/>
    </SvgIcon>
  );
};

export const PhotoIcon = (props: Record<string, never>) => {
  return (
    <SvgIcon {...props} viewBox="0 0 36 28">
      <path d="M0.373047 27.9984H35.6272V0H0.373047V27.9984ZM2.57643 2.20339H33.4238V25.795H2.57643V2.20339Z" fill="black"/>
      <path d="M15.7415 15.5398L13.1435 12.5374L5.0957 21.8379H12.7007H21.1915H30.9018L20.5965 9.92969L15.7415 15.5398ZM12.7007 19.6345H9.9156L13.1424 15.9042L16.3715 19.6345H12.7007ZM19.2848 19.6345L17.1983 17.2233L20.5965 13.2965L26.0818 19.6346H19.2848V19.6345Z" fill="black"/>
      <path d="M15.5883 11.3537C17.1505 11.3537 18.4207 10.0856 18.4207 8.52899C18.4207 6.97011 17.1505 5.70312 15.5883 5.70312C14.0261 5.70312 12.7559 6.97004 12.7559 8.52899C12.7559 10.0867 14.0261 11.3537 15.5883 11.3537ZM15.5883 7.90543C15.9353 7.90543 16.2173 8.18523 16.2173 8.52791C16.2173 8.87053 15.9353 9.14925 15.5883 9.14925C15.2412 9.14925 14.9592 8.87053 14.9592 8.52791C14.9592 8.18523 15.2413 7.90543 15.5883 7.90543Z" fill="black"/>
    </SvgIcon>
  );
};

export const VideoIcon = (props: Record<string, never>) => {
  return (
    <SvgIcon {...props} viewBox="0 0 36 27">
      <path d="M35.6355 0.000159639L0.000906888 0.000291133C1.32074 -0.000324115 1.31983 0.00021512 0.000906888 0.000291133L0 23.9219V26.3967C1.31983 26.3967 1.31983 26.3967 0 26.3967H35.6355L35.6355 23.922V0.000159639ZM2.96965 2.96959H32.666V23.427H2.96965V2.96959Z" fill="black"/>
      <path d="M23 13.1383L13 7C13.0006 8.6628 13.0006 7.74359 13.0006 8.6628V20C14.7497 18.8279 14.0021 19.3379 14.7497 18.8279L23 13.1383ZM15.2506 15.7197V10.8134L18.8475 13.2665L15.2506 15.7197Z" fill="black"/>
    </SvgIcon>
  );
};

export const QuestionIcon = (props: Record<string, never>) => {
  return (
    <SvgIcon {...props} viewBox="0 0 13 21">
      <path d="M8.05105 14.5504H4.37181C4.38143 13.7036 4.44876 12.9745 4.57381 12.3629C4.70847 11.7419 4.93452 11.1821 5.25194 10.6835C5.57899 10.1848 6.01184 9.69086 6.5505 9.20161C7.00259 8.80645 7.39697 8.43011 7.73363 8.07258C8.07029 7.71505 8.33481 7.34812 8.52719 6.97177C8.71957 6.58602 8.81576 6.15793 8.81576 5.6875C8.81576 5.1418 8.72919 4.69019 8.55605 4.33266C8.39253 3.96573 8.14243 3.68817 7.80577 3.5C7.47873 3.31183 7.06511 3.21774 6.56493 3.21774C6.15131 3.21774 5.76656 3.30712 5.41065 3.48589C5.05475 3.65524 4.76138 3.91868 4.53052 4.27621C4.30929 4.63374 4.19386 5.10417 4.18424 5.6875H0C0.0288568 4.39852 0.331853 3.33535 0.90899 2.49798C1.49575 1.65121 2.27969 1.02554 3.26082 0.620968C4.24195 0.206989 5.34332 0 6.56493 0C7.91158 0 9.06585 0.216398 10.0277 0.649194C10.9896 1.07258 11.7255 1.69825 12.2353 2.52621C12.7451 3.34476 13 4.34207 13 5.51815C13 6.33669 12.8365 7.06586 12.5094 7.70565C12.1824 8.33602 11.7543 8.92406 11.2253 9.46976C10.6963 10.0155 10.1143 10.58 9.47947 11.1633C8.93119 11.6431 8.55605 12.1465 8.35405 12.6734C8.16167 13.2003 8.06067 13.8259 8.05105 14.5504ZM3.93896 18.9113C3.93896 18.3091 4.15057 17.8105 4.57381 17.4153C4.99704 17.0108 5.56456 16.8085 6.27636 16.8085C6.97854 16.8085 7.54125 17.0108 7.96448 17.4153C8.39734 17.8105 8.61376 18.3091 8.61376 18.9113C8.61376 19.4946 8.39734 19.9886 7.96448 20.3931C7.54125 20.7977 6.97854 21 6.27636 21C5.56456 21 4.99704 20.7977 4.57381 20.3931C4.15057 19.9886 3.93896 19.4946 3.93896 18.9113Z" fill="black"/>
    </SvgIcon>
  );
};


export const EditorIcon = (props: Record<string, never>) => {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <path d="M32 17.3c-4.9 1.6-13.3 10.2-14.8 15.3-1.8 6-1.8 360.8 0 366.8 1.6 5.5 9.9 13.8 15.4 15.4 3.1.9 28.4 1.2 103.6 1.2h99.6l4.8 8.7c35.8 64.1 112.3 88.9 178.1 57.8 38.3-18.1 66.5-54.7 75-97.3 2.4-12.2 2.4-38.6 0-50.2-8.8-41.4-33.3-75-69.3-94.6l-8.4-4.6v-99.5c0-75.3-.3-100.6-1.2-103.7-1.6-5.5-9.9-13.8-15.4-15.4-5.6-1.6-362.1-1.6-367.4.1zM64 56v8H48V48h16v8zm32 0v8H80V48h16v8zm32 0v8h-16V48h16v8zm256 0v8h-16V48h16v8zm16 107.1c0 63.5-.1 67.1-1.7 66.4-1-.4-6.1-1.6-11.3-2.8-12.7-2.8-39.3-3-52.2-.4-55.3 11.2-97.3 53.2-108.5 108.5-2.6 12.9-2.4 39.5.4 52.2 1.2 5.2 2.4 10.3 2.8 11.3.7 1.6-4.3 1.7-95.9 1.7H36.9l-2.4-2.5-2.5-2.4V96h368v67.1zM368 360v72h-16V288h16v72zm-39.9-64.1c2.7 2.7 4.9 5.3 4.9 5.7 0 .5-12.9 13.8-28.7 29.6L275.5 360l29 29 29 29-5.8 5.7-5.7 5.8-33-33c-30.5-30.6-33-33.3-33-36.5 0-3.2 2.5-5.9 32.7-36.2 18-18 33.1-32.8 33.6-32.8s3.1 2.2 5.8 4.9zm103.2 27.9c30.2 30.3 32.7 33 32.7 36.2 0 3.2-2.5 5.9-33 36.5l-33 33-5.7-5.8-5.8-5.7 29-29 29-29-28.8-28.8c-15.8-15.8-28.7-29.1-28.7-29.6 0-1.1 9.3-10.5 10.5-10.6.6 0 15.8 14.8 33.8 32.8z" />
    </SvgIcon>
  );
};
