import { gql } from "@apollo/client";

export const CREATE_APPLICATION_MODEL_IN_MARKET_CONTENT = gql`
  mutation createApplicationModelInMarketContent(
    $id: String!
    $lang: LanguagesEnum!
  ) {
    createApplicationModelInMarketContent(id: $id, lang: $lang)
  }
`;
export const UPDATE_APPLICATION_MODEL_IN_MARKET_CONTENT = gql`
  mutation updateApplicationModelInMarketContent(
    $id: String!
    $lang: LanguagesEnum!
  ) {
    updateApplicationModelInMarketContent(id: $id, lang: $lang)
  }
`;
