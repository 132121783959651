import { useReactiveVar } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { robotsState } from "../../state";
import { RobotMenu, RobotsListItem } from "../../style";
import { MainTableCell, MainTableStatusCell } from "../../../../shared/Style/Style";
import { Menu } from "../../../../shared/Menu";
import { GameModel } from "../../../../../generated/graphql";
import { robotModelPageLink, robotsPageLink } from "../../constants";

export const RobotsList = () => { 
  const { appId } = useParams<{ appId: string }>();
  const { push } = useHistory();
  const robotsList = useReactiveVar(robotsState.robotsList);

  const redirectToRobotModelPage = (robotId: string) => {
    push(`/mobileApps/${appId}/${robotsPageLink}/${robotId}/${robotModelPageLink}`);
  };
    
  const toggleRobotPublication = async (robot: GameModel) => {
    if (!robot?.isCanBePublished && !robot?.isPublished) {
      return robotsState.setNotifications({ error: true, message: "Робот не может быть опубликован, без опубликованных стадий" });
    };

    await robotsState.updateRobot({
      updateGameModelId: robot?.id,
      input: { isPublished: !robot?.isPublished },
    });
    await robotsState.getRobots();
  };
  
  const deleteRobot = (robot: GameModel) => {
    if (Number(robot.statesCount) > 0) {
      return robotsState.setNotifications({ error: true, message: "Робот не может быть удален, при наличии стадий" });
    };
    robotsState.setDeleteRobot({ isOpen: true, robot })
  };
    
  const menuItems = (robot: GameModel) => [
   {
      name: robot?.isPublished ? "Снять с публикации" : "Опубликовать",
      action: () => toggleRobotPublication(robot),
    },
    {
      name: "Редактировать",
      action: () => redirectToRobotModelPage(robot?.id),
    },
    {
      name: "Удалить",
      action: () => deleteRobot(robot),
      color: "red",
    },
  ];
    
  return (
    <>
      {robotsList.map((robot) => (
        <RobotsListItem
          key={robot.id}
          onClick={() => redirectToRobotModelPage(robot.id)}
        >
          <MainTableCell>{robot.order}</MainTableCell>

          <MainTableCell>{robot.name}</MainTableCell>

          <MainTableCell>{robot.description}</MainTableCell>

          <MainTableCell>{Number(robot.statesCount)}</MainTableCell>

          <MainTableStatusCell status={robot.isPublished}>
            {robot.isPublished ? "Опубликовано" : "Не опубликовано"}
          </MainTableStatusCell>

          <RobotMenu >
            <Menu
              vertical={36}
              horizontal={12}
              width={170}
              items={menuItems(robot)}
            />
          </RobotMenu>
        </RobotsListItem>
      ))}
    </>
  );
};
