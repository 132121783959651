import { useEffect, useMemo, memo } from "react";
import { CustomIconButton } from "../Buttons/CustomIconButton";
import { GetApp } from "@material-ui/icons";
import { ExcelHandler } from "./ExcelHandler";

interface ExcelExporterProps<QueryData> {
  data: QueryData[] | undefined;
  fileName: string;
  disabled?: boolean;
  onDataLoad(): void;
}

const FIELDS_TO_EXCLUDE = ["id", "__typename"];

export const ExcelExporter = memo(<QueryData, >({
  data,
  fileName,
  disabled = false,
  onDataLoad,
}: ExcelExporterProps<QueryData>) => {
  const excelHandler = useMemo(() => {
    return new ExcelHandler<QueryData>(data, fileName, FIELDS_TO_EXCLUDE);
  }, [data, fileName]);

  useEffect(() => {
    if (Array.isArray(data)) {
      excelHandler
        .setData(data)
        .exportData();
    }
  }, [data, excelHandler]);

  return (
    <CustomIconButton
      disabled={disabled}
      mainColor="black"
      callback={() => onDataLoad()}
      children={<GetApp/>}
    />
  );
});
