import styled from "styled-components";
import { ListItem } from "@material-ui/core";
import { secondary } from "../shared/Style/Style";

export const KnowledgeBaseListItem = styled(ListItem)`
  &.MuiListItem {
    &-root {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${({ selected }) => selected ? secondary : 'inherit'};

      &:not(:first-of-type) {
        border-top: 1px solid ${secondary};
      }
    }

    &-gutters {
      padding: 14px 12px;
    }
  }
`;
