import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import {
  DeleteExerciseDocument,
  DeleteExerciseMutation,
  ExerciseTypeEnum,
  GetTraningDocument,
  UpdateExerciseDocument,
  UpdateExerciseInput,
  UpdateExerciseMutation,
} from "../../generated/graphql";
import { Exercises } from "./Exercises/Exercises";
import { Pause } from "./Pause/Pause";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";

export const TrainingListItem = ({
  block,
  setRequestError,
  setErrorMessage,
  errorMessage,
  requestError,
}) => {

  const { pauseTime, contentUKR } = block;

  const [variables, setVariables] = useState<UpdateExerciseInput>({
    contentUKR,
    pauseTime,
  });
  const [file, setFile] = useState(null);

  const [
    updateBlock,
    {
      data: updateBlockData,
      loading: updateBlockLoading,
      error: updateBlockError,
    },
  ] = useMutation<UpdateExerciseMutation>(UpdateExerciseDocument, {
    refetchQueries: [GetTraningDocument],
  });

  const [
    deleteBlock,
    {
      data: deleteBlockData,
      loading: deleteBlockLoading,
      error: deleteBlockError,
    },
  ] = useMutation<DeleteExerciseMutation>(DeleteExerciseDocument, {
    refetchQueries: [GetTraningDocument],
  });

  const updateHandleExercises = async () => {
    try {
      await updateBlock({
        variables: {
          id: block.id,
          exerciseData: {
            ...variables,
            video: file,
          },
        },
      });
    } catch (e) {
      setRequestError(true);
      setErrorMessage("При удаление обновлении ошибка");
    }
  };

  const deleteHandleExercises = async () => {
    try {
      await deleteBlock({
        variables: {
          id: block.id,
        },
      });
    } catch (e) {
      setRequestError(true);
      setErrorMessage("При удаление произошла ошибка");
    }
  };

  useEffect(() => {
    if (updateBlockData) {
      setRequestError(false);
      setErrorMessage("При обновлении данных произошла ошибка");
    }
  }, [updateBlockData]);

  const getBlockTypeExercise = (type: ExerciseTypeEnum) => {
    switch (type) {
      case ExerciseTypeEnum.Exercise:
        return (
          <Exercises
            file={file}
            setFile={setFile}
            block={block}
            setVariables={setVariables}
            updateHandleExercises={updateHandleExercises}
            deleteHandleExercises={deleteHandleExercises}
          />
        );
      case ExerciseTypeEnum.Pause:
        return (
          <Pause
            block={block}
            setVariables={setVariables}
            updateHandleExercises={updateHandleExercises}
            deleteHandleExercises={deleteHandleExercises}
          />
        );
    }
  };
  const testBlock = getBlockTypeExercise(block.type);

  return (
    <>
      <Paper>{testBlock}</Paper>
      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </>
  );
};
