import styled from "styled-components";
import { AppBar, Button, DialogActions, DialogTitle } from "@material-ui/core";
import { red, white } from "../shared/Style/Style";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";

export const LoginForm = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${white};
`;

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${white};
`;

export const AppBarStyle = styled(AppBar)`
  &.MuiAppBar-root {
    background-color: ${white};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;

export const LoginContainer = styled.div`
  width: 550px;
  margin-top: 120px;
  padding: 24px;
  background: ${white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  align-self: flex-start;
  margin-bottom: 32px;
`;

export const LoginTitle = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  justify-self: center;
  margin-bottom: 24px;
  text-align: center;
`;

export const RegistrationWrapper = styled(LoginContainer)`
  padding: 24px 64px;
`;

export const LoginContainerPromo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Input = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  flex-direction: column;
`;

export const InputError = styled.div`
  font-size: 12px;
  color: ${red}
  text-align: center;
  margin-top: 8px;
`;
export const LoginLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }

  &.link-like {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ButtonRegister = styled(Button)`
  &.MuiButton-root {
    margin-top: 16px;
  }
`;

export const BlockTitle = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 24px;
`;
export const BaseContainer = styled.div<{ minHeight: number }>`
  overflow: hidden;
  ${({ minHeight }) => `min-height: ${minHeight}px`};
`;

export const ButtonForgotStyle = styled(Button)`
  &.MuiButton-root {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    text-decoration-line: underline;
    color: #c4c4c4;
  }
`;

export const ContainerPromo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DialogActionsStyle = styled(DialogActions)`
  &.MuiDialogActions-root {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
`;

export const Close = styled(CloseIcon)`
  color: #a6a4a4;
`;
export const ClosePromo = styled.div`
  border-radius: 20%;
  margin-right: 0.5rem;
`;

export const Title = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    font-weight: 400;
    padding:16px 36px;
    font-size: 1rem;
  }
`;

export const TitlePromo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ForgotContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
