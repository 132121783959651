import { OrderTypeEnum, School, SchoolsSortType, SchoolsSubscriptionStatus, User } from '../../../generated/graphql'
import { Box, Table, TableSortLabel } from '@material-ui/core'
import { Menu } from '../../shared/Menu'
import { SchoolTableHeadCell, SchoolTableCell, SchoolTableRow, WithoutSchoolTableRow } from './SchoolsTable.style'
import { SchoolPaths } from '../../shared/constants'
import { SchoolAdminResolver } from '../../shared/Utils/OtherOperations/schoolAdminResolver'
import { secondary } from '../../shared/Style/Style'
import { Dispatch, SetStateAction } from 'react'
import { SchoolsFiltersType } from '../types'
import styled from 'styled-components'

const TABLE_HEADER = [
  'ID',
  'Название',
  'Электронный адрес и номер телефона',
  'Администратор',
  'Подписка',
  'Кол-во учителей',
  'Кол-во учеников',
  '',
]

interface SchoolsTableProps {
  schools: School[]
  isPersonal: boolean
  setSchoolIdToDelete(id: string): void
  openModal(): void
  setFilterVariables: Dispatch<SetStateAction<SchoolsFiltersType>>
  filterVariables: SchoolsFiltersType
}

const schoolAdminData = (admins: User[]) => {
  return SchoolAdminResolver.getDataForSchools(admins).map((d, i) => <p key={i}>{d}</p>)
}

const schoolSubscriptionData = (status: SchoolsSubscriptionStatus, expiredDate: string, isTrial: boolean) => {
  let text = 'Активная'
  let textColor = '#FFB61D'
  if (status === SchoolsSubscriptionStatus.Finished) {
    text = 'Завершилась'
    textColor = '#E85050'
  }
  if (status === SchoolsSubscriptionStatus.NoSubscriptionHistory) {
    text = 'Без истории подписок'
    textColor = 'black'
  }

  return (
    <>
      <span style={{ color: textColor }}>{text}</span>
      {isTrial ? (
        <span>
          <br />
          (Триалка)
        </span>
      ) : null}
      {status === SchoolsSubscriptionStatus.Active ? (
        <span>
          <br />
          Окончание: {new Date(expiredDate).toLocaleDateString()}
        </span>
      ) : null}
    </>
  )
}

export const SchoolsTable = ({
  schools = [],
  isPersonal,
  setFilterVariables,
  filterVariables,
  setSchoolIdToDelete,
  openModal,
}: SchoolsTableProps) => {
  const getSchoolRoute = (id: string) =>
    !isPersonal ? `/${SchoolPaths.school}/${id}/edit` : `/${SchoolPaths.personal}/${id}/edit`

  const menuItem = (id: string) => [
    {
      name: 'Редактировать',
      action: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        window.location.href = getSchoolRoute(id)
      },
    },
    {
      name: 'Удалить',
      action: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        //TODO: until backend is ready
        // setSchoolIdToDelete(id);
        // openModal();
      },
      color: secondary,
    },
  ]

  const sortHandler = (column: SchoolsSortType) => {
    setFilterVariables((prevState) => ({
      ...prevState,
      sort: column,
      order: prevState.order === OrderTypeEnum.Asc ? OrderTypeEnum.Desc : OrderTypeEnum.Asc,
    }))
  }

  return (
    <Table>
      <thead>
        <SchoolTableRow>
          {TABLE_HEADER.map((el, i) => {
            if (el === 'Подписка') {
              return (
                <Box key={i} display='flex'>
                  <SchoolTableHeadCell>{el}</SchoolTableHeadCell>
                  <SortArrow
                    active={filterVariables.sort === SchoolsSortType.Subscription}
                    direction={filterVariables.order === OrderTypeEnum.Asc ? 'asc' : 'desc'}
                    onClick={() => sortHandler(SchoolsSortType.Subscription)}
                  />
                </Box>
              )
            }

            return <SchoolTableHeadCell key={i}>{el}</SchoolTableHeadCell>
          })}
        </SchoolTableRow>
      </thead>
      <tbody>
        {schools.length > 0 ? (
          schools.map(
            ({
              id,
              idShort,
              name,
              phone,
              email,
              admins,
              subscriptionStatus,
              isTrialSubscription,
              subscriptionExpiredDate,
              teachersCount,
              pupilsCount,
            }) => (
              <SchoolTableRow key={id} onClick={() => (window.location.href = getSchoolRoute(id))}>
                <SchoolTableCell>{idShort}</SchoolTableCell>
                <SchoolTableCell>{name}</SchoolTableCell>
                <SchoolTableCell>
                  {email} <br /> {phone}
                </SchoolTableCell>
                <SchoolTableCell>{schoolAdminData(admins)}</SchoolTableCell>
                <SchoolTableCell>
                  {schoolSubscriptionData(subscriptionStatus, subscriptionExpiredDate, isTrialSubscription)}
                </SchoolTableCell>
                <SchoolTableCell>{teachersCount}</SchoolTableCell>
                <SchoolTableCell>{pupilsCount}</SchoolTableCell>
                <SchoolTableCell>
                  <Menu vertical={36} horizontal={12} width={170} items={menuItem(id)} />
                </SchoolTableCell>
              </SchoolTableRow>
            )
          )
        ) : (
          <WithoutSchoolTableRow>
            <SchoolTableCell>Список школ пуст</SchoolTableCell>
          </WithoutSchoolTableRow>
        )}
      </tbody>
    </Table>
  )
}

export const SortArrow = styled(TableSortLabel)`
  &.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
    .MuiTableSortLabel-icon {
    font-size: 22px;
  }
`
