import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import {
  AddProjectTasksToKanbanMutation,
  AddProjectTasksToKanbanDocument,
  GetProjectKanbanDocument,
  GetProjectStepsByProjectLightQuery,
  GetProjectStepsByProjectLightDocument,
  GetProjectTasksListByEpicQuery,
  GetProjectTasksListByEpicDocument,
  GetProjectTasksByWorkflowStepDocument,
} from "../../generated/graphql";

import { CloseIconButton } from "../shared/Buttons/CloseIconButton";
import { DropdownArrowButton } from "../shared/Buttons/DropdownArrowButton";
import { CustomTable } from "../shared/CustomTable";
import { TasksList } from "./TasksList";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import {
  DialogTitle,
  DialogContent,
  Dialog,
  CircularProgress,
  TableRow,
  Table,
} from "@material-ui/core";
import {
  CustomTableCell,
  CustomTableCellNoBorder,
  CustomTableEpicRow,
  CustomTableHead,
  CustomTableHeaderCell,
} from "../shared/CustomTable/CustomTable.style";
import {
  ButtonsWrapper,
  CancelButton,
  ButtonCustom,
  LoadingBox,
} from "../shared/Style/Style";

interface IKanbanAddTasksModal {
  projectId: string;
  open: boolean;
  close(): void;
  tasksIds: string[];
  workflowStepId: string;
  workflowStepStartOrder: number;
}

const headers = [
  { title: "Этап проекта", width: 250 },
  { title: "Задача", width: 500 },
  { title: "Ответственный", width: 150 },
];

export const KanbanAddTasksModal = ({
  projectId,
  open,
  close,
  tasksIds,
  workflowStepId,
  workflowStepStartOrder,
}: IKanbanAddTasksModal) => {
  const [ids, setIds] = useState<string[]>([]);
  const [chosenEpic, setChosenEpic] = useState("");
  const [showTasks, setShowTasks] = useState(false);

  const [requestError, setRequestError] = useState(false);

  const closeHandler = () => {
    setIds([]);
    setRequestError(false);
    close();
  };

  const [
    addTasks,
    { data: addTasksData, loading: addTasksLoading, error: addTasksError },
  ] = useMutation<AddProjectTasksToKanbanMutation>(
    AddProjectTasksToKanbanDocument,
    {
      variables: {
        tasksIds: ids.length ? ids : [""],
        workflowStepId: workflowStepId,
        workflowStepStartOrder: workflowStepStartOrder,
      },
      refetchQueries: [
        { query: GetProjectKanbanDocument, variables: { projectId } },
        {
          query: GetProjectTasksByWorkflowStepDocument,
          variables: {
            projectId,
            workflowStepId,
            limit: 1000,
            skip: 0,
          },
        },
      ],
    }
  );

  const [
    getTasks,
    { data: tasksData, loading: tasksLoading, error: tasksError },
  ] = useLazyQuery<GetProjectTasksListByEpicQuery>(
    GetProjectTasksListByEpicDocument,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (addTasksError) {
      setRequestError(true);
    }
    if (addTasksData) {
      closeHandler();
    }
  }, [addTasksError, addTasksData]);

  useEffect(() => {
    setShowTasks(false);
  }, []);

  const showTasksListHandler = (epicKey: string): void => {
    if (!epicKey) {
      return;
    }

    if (chosenEpic === epicKey) {
      setShowTasks(!showTasks);
      setChosenEpic("");
      return;
    }

    setShowTasks(true);
    setChosenEpic(epicKey);

    getTasks({ variables: { epicKey } });
  };

  let tasksList: JSX.Element;

  if (tasksLoading) {
    tasksList = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (tasksError) {
    tasksList = (
      <TableRow>
        <CustomTableCell>При загрузке задач произошла ошибка</CustomTableCell>
      </TableRow>
    );
  }

  if (tasksData) {
    tasksList = (
      <TasksList
        tasks={tasksData.getProjectTasksByEpic.filter(
          (task) => !tasksIds.includes(task.id)
        )}
        ids={ids}
        setIds={setIds}
        disabled={addTasksLoading}
      />
    );
  }

  const {
    data: epicsData,
    loading: epicsLoading,
    error: epicsError,
  } = useQuery<GetProjectStepsByProjectLightQuery>(
    GetProjectStepsByProjectLightDocument,
    {
      variables: { projectId },
    }
  );

  let content;

  if (epicsLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (epicsError) {
    content = <ErrorSnackbar error={epicsError} />;
  }

  if (epicsData) {
    const epics = epicsData.getProjectStepsByProject;
    const showTasksList = true;

    content = (
      <Table>
        <CustomTableHead>
          <TableRow>
            {headers.map(({ title, width }) => (
              <CustomTableHeaderCell width={width} key={title}>
                {title}
              </CustomTableHeaderCell>
            ))}
          </TableRow>
        </CustomTableHead>

        {epics.length ? (
          epics.map(({ id, name, key }) => (
            <React.Fragment key={id}>
              <CustomTableEpicRow
                clickable={true}
                onClick={() => showTasksListHandler(key)}
              >
                <CustomTableCellNoBorder>
                  <div>{name}</div>
                  <div>{key}</div>

                  <DropdownArrowButton
                    expanded={chosenEpic === key && showTasksList}
                  />
                </CustomTableCellNoBorder>

                <CustomTableCellNoBorder colSpan={2} />
              </CustomTableEpicRow>

              {chosenEpic === key && showTasksList && tasksList}
            </React.Fragment>
          ))
        ) : (
          <CustomTableEpicRow>
            <CustomTableCellNoBorder colSpan={3}>
              Этапы проекта отсутствуют
            </CustomTableCellNoBorder>
          </CustomTableEpicRow>
        )}
      </Table>
    );
  }

  return (
    <>
      <Dialog open={open} maxWidth="lg">
        <CloseIconButton disabled={addTasksLoading} close={closeHandler} />
        <DialogTitle>Добавление задач</DialogTitle>

        <DialogContent>
          <CustomTable
            data={epicsData?.getProjectStepsByProject}
            tableContent={content}
          />

          <ButtonsWrapper>
            <CancelButton onClick={closeHandler} disabled={addTasksLoading}>
              Отмена
            </CancelButton>

            <ButtonCustom
              onClick={() => {
                addTasks();
              }}
              disabled={
                addTasksLoading ||
                !tasksData?.getProjectTasksByEpic?.filter(
                  (task) => !tasksIds.includes(task.id)
                )?.length
              }
            >
              Добавить
            </ButtonCustom>
          </ButtonsWrapper>
        </DialogContent>
      </Dialog>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message="При добавлении задач произошла ошибка"
      />
    </>
  );
};
