import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ButtonUploadCustom } from "../Style/Style";
import { FieldInputProps } from "react-final-form";
import { useMutation } from "@apollo/client";
import {
  GetUserDocument,
  UpdateUserDocument,
  UpdateUserMutation,
} from "../../../generated/graphql";
import { useParams } from "react-router-dom";

const useStylesInput = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: "none",
    },
  })
);

interface UploadButtonProps {
  label?: string;
  input: FieldInputProps<any, HTMLElement>;
}

export const UploadButtons = ({ label, input }: UploadButtonProps) => {
  const { userId } = useParams<{ userId: string }>();
  const classesInput = useStylesInput();
  const [updateUser, { data, loading: loadingUpdate, error: updateUserError }] =
    useMutation<UpdateUserMutation>(UpdateUserDocument, {
      refetchQueries: [
        {
          query: GetUserDocument,
          variables: {
            userId,
          },
        },
      ],
    });

  const handleChangeFile = (event: any) => {
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
  };

  return (
    <div>
      <input
        accept="image/*"
        {...input}
        type="file"
        className={classesInput.input}
        id="contained-button-file"
        multiple
        onChange={handleChangeFile}
      />
      <label htmlFor="contained-button-file">
        <ButtonUploadCustom>{label}</ButtonUploadCustom>
      </label>
    </div>
  );
};
