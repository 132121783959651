import { User, CsvValidationErrors } from "../../generated/graphql";

export const getAdminIdsToUpdate = (adminsIds: string[], adminsIdsBeforeUpdate: string[]) => {
  const adminsToAdd = [...adminsIds];
  const adminsToDelete: string[] = [];

  for (const adminId of adminsIdsBeforeUpdate) {
    const existingIdIndex = adminsToAdd.findIndex(el => el === adminId);

    if (existingIdIndex !== -1) {
      adminsToAdd.splice(existingIdIndex, 1);
    } else {
      adminsToDelete.push(adminId);
    }
  }

  return { adminsToAdd, adminsToDelete };
};

export const mapUsersIds = (users: User[] = []) => users.map((users) => users.id);

export const findUserById = (users: User[], id: string) => users.find((user) => user.id === id);

export const setErrorMessage = (errors: CsvValidationErrors[]) => { 
  return errors.map(error => `\nСтрока ${error.line}: ${error.errors.join(', ')}`).join('\n') || 'Невідома помилка';
};

export const getEmails = (inviteUsersResult: CsvValidationErrors[]) => {
  const emailRegex = /\S+@\S+\.\S+/;
  const emails = inviteUsersResult.map(item => {
    const match = item.errors[0].match(emailRegex);
    return match ? match[0] : '';
  }).filter(email => email !== '').join(', ');
  
  return emails;
};
