import { useQuery } from "@apollo/client";
import {
  GetSchoolCountriesDocument,
  GetSchoolCountriesQuery
} from "../../../generated/graphql";
import { useRequestError } from "../../../hooks";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { StyledTextField } from "../Style";
import { LoadingBox } from "../../shared/Style/Style";

interface SelectCountryProps {
  countryId: string;
  inputError: boolean;
  setCountryId: (countryId: string) => void;
}

export const SelectCountry = ({ countryId, inputError, setCountryId }: SelectCountryProps) => {
  const { data, loading, error } = useQuery<GetSchoolCountriesQuery>(GetSchoolCountriesDocument, {
    variables: {
      limit: 500,
    },
  });

  const { requestError, setRequestError } = useRequestError(error);

  if (loading) {
    return (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  const schoolCountries = data?.getSchoolCountries ?? [];

  return (
    <>
      <Autocomplete
        options={schoolCountries.map((schoolCountry) => schoolCountry.id)}
        getOptionLabel={(option) => schoolCountries.find((schoolCountry) => schoolCountry.id === option)?.nameUKR || ""}
        onChange={(_, value) => setCountryId(value)}
        value={schoolCountries.find((schoolCountry) => schoolCountry.id === countryId)?.id || null}
        renderInput={(params) => (
          <StyledTextField
            label="Страны"
            variant="filled"
            error={inputError}
            {...params}
          />
        )}
      />

      <ErrorMessageSnackbar
        open={requestError}
        message="При загрузке списка стран произошла ошибка"
        closeHandler={() => setRequestError(false)}
      />
    </>
  );
};
