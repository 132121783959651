import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { Field } from "react-final-form";
import MuiPhoneNumber from "material-ui-phone-number";
import { useStyles } from "../../../shared/Style/Style";

export const BasePhone = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={5} item>
          <Field
            name="phone"
          >
            {({ input, meta }) => (
              <div>
                <MuiPhoneNumber
                  className={classes.root}
                  fullWidth
                  error={meta.error && meta.touched}
                  helperText={meta.touched && meta.error}
                  defaultCountry="ua"
                  label={"Телефон"}
                  variant="filled"
                  margin="normal"
                  {...input}
                />
              </div>
            )}
          </Field>
        </Grid>
        <Grid xs={7} item>
          <Field
            name="email"
          >
            {({ input, meta }) => {
              return (
                <>
                  <TextField
                    className={classes.root}
                    error={meta.error && meta.touched}
                    helperText={meta.touched && meta.error}
                    margin="normal"
                    fullWidth
                    label="Email"
                    variant="filled"
                    type="email"
                    {...input}
                  />
                </>
              );
            }}
          </Field>
        </Grid>
      </Grid>
    </>
  );
};
