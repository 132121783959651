import React, { useState, useEffect } from "react";

import { useMutation, useQuery } from "@apollo/client";
import {
  CreateBaseProjectTaskStatusDocument,
  CreateBaseProjectTaskStatusMutation,
  DeleteProjectTaskStatusDocument,
  DeleteProjectTaskStatusMutation,
  GetBaseProjectTaskStatusesDocument,
  GetBaseProjectTaskStatusesQuery,
  UpdateProjectTaskStatusDocument,
  UpdateProjectTaskStatusMutation,
} from "../../../../generated/graphql";

import {  CircularProgress, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { LoadingBox } from "../../../shared/Style/Style";
import { SettingsList } from "../SettingsList";
import { SettingsCreateItemForm } from "../SettingsCreateItemForm";

import { Content } from "../Settings.style";

export const BaseTaskStatus = () => {
  const [inputError, setInputError] = useState(false);
  const [requestError, setRequestError] = useState(false);

  const [isCreating, setCreating] = useState(false);
  const [isEditing, setEditing] = useState({
    itemId: '',
    status: false,
  })

  const {
    data: statusesData,
    loading: statusesLoading,
    error: statusesError,
  } = useQuery<GetBaseProjectTaskStatusesQuery>(GetBaseProjectTaskStatusesDocument);

  const [
    createStatus,
    {
      data: createStatusData,
      loading: createStatusLoading,
      error: createStatusError,
    }
  ] = useMutation<CreateBaseProjectTaskStatusMutation>(
    CreateBaseProjectTaskStatusDocument,
    { refetchQueries: [{query: GetBaseProjectTaskStatusesDocument}] }
  );

  const createHandler = (name: string): void => {
    if (!name) {
      setInputError(true);
      return;
    }

    createStatus({ variables: {name} })
  }

  const [
    updateStatus,
    {
      data: updateStatusData,
      loading: updateStatusLoading,
      error: updateStatusError,
    }
  ] = useMutation<UpdateProjectTaskStatusMutation>(
    UpdateProjectTaskStatusDocument,
    { refetchQueries: [{query: GetBaseProjectTaskStatusesDocument}] }
  );

  const updateHandler = (id: string, name: string): void => {
    if(!id || !name) {
      setInputError(true);
      return;
    }

    updateStatus({ variables: {id, name} })
  }

  const [
    deleteStatus,
    {
      data: deleteStatusData,
      loading: deleteStatusLoading,
      error: deleteStatusError,
    }
  ] = useMutation<DeleteProjectTaskStatusMutation>(
    DeleteProjectTaskStatusDocument,
    { refetchQueries: [{query: GetBaseProjectTaskStatusesDocument}] }
  );

  const deleteHandler = (id: string): void => {
    if(!id) {
      setInputError(true);
      return;
    }
    deleteStatus({ variables: { id } })
  }

  useEffect(() => {
    if(createStatusError || updateStatusError || deleteStatusError) {
      setRequestError(true);
    }
  }, [createStatusError, updateStatusError, deleteStatusError]);

  useEffect(() => {
    if(deleteStatusData || updateStatusData || createStatusData) {
      setRequestError(false);
    }
  }, [deleteStatusData, updateStatusData, createStatusData]);

  useEffect(() => {
    if(updateStatusData) {
      setEditing({
        itemId: '',
        status: false,
      });
    }

    if(createStatusData) {
      setCreating(false);
    }
  }, [updateStatusData, createStatusData]);

  const areEditingButtonsDisabled = createStatusLoading || updateStatusLoading || deleteStatusLoading || isCreating;
  const areCreatingButtonsDisabled = createStatusLoading || updateStatusLoading || deleteStatusLoading || isEditing.status;

  let content;

  if (statusesLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    )
  }

  if (statusesError) {
    content = <ErrorSnackbar error={statusesError}/>
  }

  if (statusesData) {
    const tasksStatuses = statusesData.getBaseProjectTaskStatuses;

    content = (
      <>
        <SettingsCreateItemForm
          header={'Основные статусы задачи'}
          createItem={createHandler}
          setCreating={setCreating}
          inputError={inputError}
          setInputError={setInputError}
          areButtonsDisabled={areCreatingButtonsDisabled}
          isRequestSuccessful={Boolean(createStatusData)}
        />

        <SettingsList
          list={tasksStatuses}
          updateItem={updateHandler}
          deleteItem={deleteHandler}
          buttonsDisabled={true}
          isEditing={isEditing}
          setEditing={setEditing}
        />
      </>
    )
  }

  return (
    <Content>
      {content}

      <Snackbar
        open={inputError}
        onClose={() => setInputError(false)}
        autoHideDuration={6000}
      >
        <Alert severity="error">
          Вы ввели некорректное название
        </Alert>
      </Snackbar>

      <Snackbar
        open={requestError}
        onClose={() => setRequestError(false)}
        autoHideDuration={6000}
      >
        <Alert severity="error">
          {createStatusError?.message || updateStatusError?.message || deleteStatusError?.message}
        </Alert>
      </Snackbar>
    </Content>
  )
}
