import React from "react";
import { IconButton } from "@material-ui/core";
import { secondary } from "../../Style/Style";
import { Sync } from "@material-ui/icons";

export const SyncButton = ({
  disabled,
  fz,
  onClick,
}: {
  disabled?: boolean;
  fz?: number;
  onClick(): void;
}) => {
  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      style={{
        color: disabled ? secondary : "#FFB61D",
        fontSize: fz ? `${fz}px` : "24px",
        padding: fz ? `${fz / 2}px` : "12px",
        lineHeight: 1,
      }}
    >
      <Sync color="inherit" fontSize="inherit" />
    </IconButton>
  );
};
