import { useReactiveVar } from "@apollo/client";
import { SubscriptionPlanStatusEnum } from "../../../../../generated/graphql";
import { mobileAppModel } from "../../state";
import { getTextHelperTitle } from "../../utils";
import { maxDescriptionSymbols } from "../../constants";
import { Box } from "@material-ui/core";
import {
  ModalWithPhotoForm,
  ModalWithPhotoInput,
} from "../../../../shared/Style/Style";
import { TextareaResize } from "../../../../shared/TextareaResize/TextareaResize";
import { SelectSubscriptionsPlan } from "../../../../MobileAppPageEdit/Selectors/SelectSubscriptionsPlan";
import { MapCoordinates } from "../MapCoordinates";
import { AppModelBundles } from "../AppModelBundles";
import { AppModelMarker } from "../AppModelMarker";
import { AppModelRates } from "../AppModelRates";
import { MediaCards } from "../MediaCards";
import { SelectExperimentType } from "../ModelSelectors/SelectExperimentType";
import { SelectSchoolGrades } from "../ModelSelectors/SelectSchoolGrades";
import { SelectSubjectCategory } from "../ModelSelectors/SelectSubjectCategory";
import { SelectEducationAreas } from "../ModelSelectors/SelectEducationAreas";
import { SelectLanguage } from "../ModelSelectors/SelectLanguage";
import { SelectTheme } from "../ModelSelectors/SelectTheme";
import { SelectTagCategory } from "../ModelSelectors/SelectTagCategory";
import { AppModelColoringFile } from "../AppModelColoringFile";
import { AppModelPrice } from "../AppModelPrice";

export const ModelForm = () => {
  const model = useReactiveVar(mobileAppModel.model);
  const inputErrors = useReactiveVar(mobileAppModel.inputErrors);
  const isLoading = useReactiveVar(mobileAppModel.updateLoading);

  if (!model) return null;

  const {
    name,
    description,
    subscriptionPlan,
    isMap,
    inGradeOrder,
    clicks,
    contentAuthor,
  } = model;

  const subscriptionPlanHandler = (
    subscriptionPlan: SubscriptionPlanStatusEnum | null
  ) => {
    mobileAppModel.setSubscriptionPlan(subscriptionPlan);
  };

  return (
    <ModalWithPhotoForm style={{ marginLeft: "24px" }}>
      <ModalWithPhotoInput
        disabled={isLoading}
        required
        label="Заголовок"
        error={inputErrors.name.error}
        value={name}
        helperText={getTextHelperTitle(name)}
        onFocus={() => mobileAppModel.setInputErrors("name", false)}
        onChange={({ target: { value } }) => {
          mobileAppModel.setTitle(value);
        }}
      />

      <TextareaResize
        disabled={isLoading}
        value={description}
        error={inputErrors.description.error}
        label="Описание *"
        minRows={7}
        style={{ width: "100%" }}
        onFocus={() => mobileAppModel.setInputErrors("description", false)}
        onChange={({ target: { value } }) => {
          if (value.length >= maxDescriptionSymbols) return;
          mobileAppModel.setDescription(value);
        }}
      />

      <Box mt={2}>
        <SelectSubscriptionsPlan
          disabled={isLoading}
          status={subscriptionPlan}
          setSubscriptionAccess={subscriptionPlanHandler}
        />
      </Box>

      <AppModelPrice />

      <SelectExperimentType />

      <SelectTagCategory />

      <AppModelBundles />

      <AppModelColoringFile />

      <MediaCards />

      {/* 
        //TODO: temporarily unavailable component AD-184
        <AppModelMarker /> 
        */}

      <SelectLanguage />

      <SelectSubjectCategory />

      <SelectTheme />

      <SelectSchoolGrades />

      <ModalWithPhotoInput
        disabled={isLoading}
        fullWidth
        margin="normal"
        label="Порядок МОН"
        value={inGradeOrder}
        onChange={({ target: { value } }) =>
          mobileAppModel.setInGradeOrder(+value)
        }
      />

      <SelectEducationAreas />

      <ModalWithPhotoInput
        disabled={isLoading}
        fullWidth
        label="Автор"
        value={contentAuthor}
        style={{ marginTop: "22px" }}
        onChange={({ target: { value } }) =>
          mobileAppModel.setContentAuthor(String(value))
        }
      />

      <AppModelRates />

      {/* 
        //TODO: temporarily unavailable component AD-184
        <ModalWithPhotoInput
          disabled={isLoading}
          fullWidth
          label="Клики пользователей"
          value={clicks}
          onChange={({target:{ value }}) => mobileAppModel.setUserClicks(+value)}
        /> 
        */}
    </ModalWithPhotoForm>
  );
};
