import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { EmployeeWorkloadReportByUser, GetEmployeeWorkloadReportDocument, GetEmployeeWorkloadReportQuery } from '../../../generated/graphql';
import { IDateRange } from '../../shared/DateRangePicker/DateRangePicker';

import { Table, TableRow, CircularProgress, TableBody } from '@material-ui/core';
import { ColorizedTableCell, CustomTableCell, CustomTableHead, CustomTableHeaderCell } from '../../shared/CustomTable/CustomTable.style';
import { LoadingBox } from '../../shared/Style/Style';

import { EmployeeReportDayModal } from './EmployeeReportDayModal';
import { CustomTable } from '../../shared/CustomTable';

import { convertDateStringToLocaleHandler } from '../../shared/Utils/ConvertOperations/convertDateStringToLocaleHandler';
import { convertSecondsToHoursHandler } from '../../shared/Utils/ConvertOperations/convertSecondsToHoursHandler';

interface IEmployeeReportModalProps {
  dateRange: IDateRange;
  user: EmployeeWorkloadReportByUser;
}

export const EmployeeReportModal = ({ dateRange, user }: IEmployeeReportModalProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [date, setDate] = useState('');

  const modalOpenHandler = (day: string, seconds: number): void => {
    if (!seconds) {
      return;
    }

    setDate(day);
    setModalOpen(true);
  }

  const { data, loading, error } = useQuery<GetEmployeeWorkloadReportQuery>(
    GetEmployeeWorkloadReportDocument, {
      variables: {
        userId: user.userId,
        ...dateRange,
      }
    }
  );

  let content;

  if (loading) {
    content = (
      <Table>
        <TableRow>
          <CustomTableCell>
            <LoadingBox>
              <CircularProgress color='inherit'/>
            </LoadingBox>
          </CustomTableCell>
        </TableRow>
      </Table>
    )
  }

  if (error) {
    content = (
      <Table>
        <TableRow>
          <CustomTableCell>При загрузке данных произошла ошибка</CustomTableCell>
        </TableRow>
      </Table>
    )
  }

  if (data) {
    const projects = data.getEmployeeWorkloadReport;

    console.log(projects);

    content = (
      <Table>
        {projects.length
          ? (
            <>
              <CustomTableHead>
                <TableRow>
                  <CustomTableHeaderCell style={{ minWidth: '200px' }}>Проекты</CustomTableHeaderCell>

                  {projects[0]?.report?.map(rep => (
                    <CustomTableHeaderCell
                      key={rep.date}
                      width={70}
                    >
                      {convertDateStringToLocaleHandler(rep.date).split('.').slice(0, 2).join('.')}
                    </CustomTableHeaderCell>
                  ))}
                  </TableRow>
                </CustomTableHead>

                <TableBody>
                  {projects.map(project => (
                    <TableRow
                      key={project.projectName}
                    >
                      <CustomTableCell>{project.projectName}</CustomTableCell>

                      {project.report.map(day => (
                        <ColorizedTableCell
                          style={{ cursor: !!day.seconds && 'pointer' }}
                          number={(new Date(day.date).getDay() === 0
                            || new Date(day.date).getDay() === 6)
                              ? -1
                              : 0
                          }
                          onClick={() => modalOpenHandler(day.date, day.seconds)}
                        >
                          {convertSecondsToHoursHandler(day.seconds)}
                        </ColorizedTableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
            </>
          )
          : (
            <TableRow>
              <CustomTableCell>
                Проекты отсутсвуют
              </CustomTableCell>
            </TableRow>
          )
        }
      </Table>
    )
  }

  return (
    <>
      <CustomTable data={data?.getEmployeeWorkloadReport || []} tableContent={content} />
      {modalOpen && (
        <EmployeeReportDayModal
          open={modalOpen}
          closeHandler={() => {
            setModalOpen(false);
            setDate('');
          }}
          date={date}
          userId={user.userId}
        />
      )}
    </>
  );
}