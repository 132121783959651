import { useState } from "react";
import { Container } from "./Container";
import { Purchase } from "./Purchase";
import { PaymentsHistory } from "./PaymentsHistory";
import { RefetchPaymentType } from "./types";

export const Purchases = () => {
  const [refetchPayments, setRefetchPayments] =
    useState<{ refetch: RefetchPaymentType }>();

  return (
    <Container>
      <Purchase refetchPayments={refetchPayments} />
      <PaymentsHistory setRefetchPayments={setRefetchPayments} />
    </Container>
  );
};
