import React from "react";

import { useQuery } from "@apollo/client";
import {
    GetFeedbacksLightDocument,
    GetFeedbacksLightQuery,
} from "../../generated/graphql";

import { CircularProgress } from "@material-ui/core";

import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { FeedBackNavBarBlock } from "./FeedBackNavBar.style";
import { FilterButton, FilterButtonEndIcon } from "../shared/Style/Style";


interface IFeedBackNavBar {
    activeButton: string[];
    setActiveButton(e: string[]): void;
}

export const FeedBackNavBar = ({
 activeButton,
 setActiveButton,
 }: IFeedBackNavBar) => {
    const {data, loading, error} = useQuery<GetFeedbacksLightQuery>(
        GetFeedbacksLightDocument,
        {
            variables: { statuses:activeButton},
        }
    );

    let content;

    if (loading) {
        content = <CircularProgress color="inherit"/>;
    }

    if (error) {
        content = <ErrorSnackbar error={error}/>;
    }

    if (data) {
        const statuses = data.getFeedbacksLight;
        content = (
            <>
                <FilterButton
                    active={activeButton.includes("NEW")}
                    onClick={() => setActiveButton(["NEW"])}
                    endIcon={
                        <FilterButtonEndIcon>
                            {statuses.find((feedbacks) => feedbacks.status === "NEW")?.count
                                ? `(${
                                    statuses.find((feedbacks) =>(
                                        feedbacks.status === "NEW")).count
                                
                                })`
                                : ""}
                        </FilterButtonEndIcon>
                    }
                >
                    Новые
                </FilterButton>

                <FilterButton
                    active={activeButton.includes("REVIEW")}
                    onClick={() => setActiveButton(["REVIEW"])}
                    endIcon={
                        <FilterButtonEndIcon>
                            {statuses?.find((feedbacks) => feedbacks?.status === "REVIEW")?.count
                                ? `(${
                                    statuses?.find((feedbacks) => feedbacks?.status === "REVIEW")?.count
                                })`
                                : ""}
                        </FilterButtonEndIcon>
                    }
                >
                    На рассмотрении
                </FilterButton>

                <FilterButton
                    active={activeButton.includes("DISCUSSION")}
                    onClick={() => setActiveButton(["DISCUSSION"])}
                    endIcon={
                        <FilterButtonEndIcon>
                            {statuses?.find((feedbacks) => feedbacks?.status === "DISCUSSION")?.count
                                ? `(${
                                    statuses?.find((feedbacks) => feedbacks?.status === "DISCUSSION")?.count
                                })`
                                : ""}
                        </FilterButtonEndIcon>
                    }
                >
                    На обсуждении
                </FilterButton>

                <FilterButton
                    active={activeButton.includes("ACCEPTED")}
                    onClick={() => setActiveButton(["ACCEPTED"])}
                    endIcon={
                        <FilterButtonEndIcon>
                            {statuses?.find((feedbacks) => feedbacks?.status === "ACCEPTED")?.count
                                ? `(${
                                    statuses?.find((feedbacks) => feedbacks?.status === "ACCEPTED")?.count
                                })`
                                : ""}
                        </FilterButtonEndIcon>
                    }
                >
                    Принято
                </FilterButton>

                <FilterButton
                    active={activeButton.includes("DONE")}
                    onClick={() => setActiveButton(["DONE"])}
                    endIcon={
                        <FilterButtonEndIcon>
                            {statuses?.find((feedbacks) => feedbacks?.status === "DONE")?.count
                                ? `(${
                                    statuses?.find((feedbacks) => feedbacks?.status === "DONE")?.count
                                })`
                                : ""}
                        </FilterButtonEndIcon>
                    }
                >
                    Сделано
                </FilterButton>

                <FilterButton
                    active={activeButton.includes("REJECTED")}
                    onClick={() => setActiveButton(["REJECTED"])}
                    endIcon={
                        <FilterButtonEndIcon>
                            {statuses?.find((feedbacks) => feedbacks?.status === "REJECTED")?.count
                                ? `(${
                                    statuses?.find((feedbacks) => feedbacks?.status === "REJECTED")?.count
                                })`
                                : ""}
                        </FilterButtonEndIcon>
                    }
                >
                    Отклонено
                </FilterButton>
            </>
        );
    }

    return (
        <FeedBackNavBarBlock>
                {content}
        </FeedBackNavBarBlock>

    );
};
