import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useRequestError } from "../../../hooks";
import { useQuery } from "@apollo/client";
import {
  AvailableForMobileNotificationEnum,
  GetMobileNotificationQuery,
  GetMobileNotificationDocument,
} from "../../../generated/graphql";
import { Box, Dialog, DialogTitle, Radio, } from "@material-ui/core";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { ButtonsWrapper } from "../../shared/Style/Style";
import {
  ButtonCancelFixedWidth,
  ButtonFixedWidth,
  UsersFilter,
  UsersFilterForm,
  UsersFilters,
} from "../ParticipantsModal.style";


interface IAddClientsModalProps {
  open: boolean;
  close(): void;
  notificationId: string;
  onChangeNotification: Dispatch<SetStateAction<string>>;
}

export const AddClientsToMobileNotificationModal = ({
  open,
  close,
  notificationId,
  onChangeNotification,
}: IAddClientsModalProps) => {

  const [availableFor, setAvailableFor] = useState<string>(
    AvailableForMobileNotificationEnum.Everyone
  );

  const {
    data: notificationResponse,
    error: notificationError,
  } = useQuery<GetMobileNotificationQuery>(GetMobileNotificationDocument, {
    variables: { id: notificationId }
  })

  const { requestError, setRequestError, errorMessage } = useRequestError(notificationError, "Ошибка при загрузке отмеченных участников");

  useEffect(() => {
    if(notificationResponse) {
      setAvailableFor(notificationResponse?.getMobileNotification?.availableFor)
    }
  }, [notificationResponse]);

  const saveHandler = () => { 
    onChangeNotification(availableFor);
    closeHandler();
  };

  const closeHandler = () => {
    setAvailableFor(AvailableForMobileNotificationEnum.Everyone)
    close();
  };

  return (
    <Dialog open={open} onClose={close} TransitionProps={{ timeout: 300 }}>
      <Box p="20px">
        <DialogTitle style={{ padding: 0, marginBottom: "20px" }}>
          Отметьте участников
        </DialogTitle>
        <UsersFilterForm width={"auto"}>
          <UsersFilters
            aria-label="users filter"
            value={availableFor}
            onChange={({ target: { value } }) => setAvailableFor(value)}
          >
            <UsersFilter
              value={AvailableForMobileNotificationEnum.Everyone}
              control={<Radio />}
              label="Все"
            />
            <UsersFilter
              value={AvailableForMobileNotificationEnum.Pupil}
              control={<Radio />}
              label="Ученики"
            />
            <UsersFilter
              value={AvailableForMobileNotificationEnum.Teacher}
              control={<Radio />}
              label="Учителя"
            />
            <UsersFilter
              value={AvailableForMobileNotificationEnum.Parent}
              control={<Radio />}
              label="Родители"
            />
            <UsersFilter
              value={AvailableForMobileNotificationEnum.Guest}
              control={<Radio />}
              label="Гость"
            />
          </UsersFilters>
        </UsersFilterForm>

        <ButtonsWrapper>
          <ButtonCancelFixedWidth
            onClick={closeHandler}
          >
            отмена
          </ButtonCancelFixedWidth>

          <ButtonFixedWidth
            onClick={saveHandler}
          >
            ок
          </ButtonFixedWidth>
        </ButtonsWrapper>
      </Box>

      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
      
    </Dialog>
  );
};
