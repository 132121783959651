import React from "react";
import { IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { primary, secondary } from "../../Style/Style";

interface IEditIconButtonProps {
  disabled?: boolean;
  active?: boolean;
  edit(): void;
  fz?: number;
}

const getColor = (disabled?: boolean, active?: boolean): string => {
  if (!disabled && !active) {
    return secondary;
  }

  if (active && !disabled) {
    return primary;
  }

  if (disabled && !active) {
    return secondary;
  }
}

export const EditIconButton = ({ disabled, edit, active, fz }: IEditIconButtonProps) => {
  return (
    <IconButton
      disabled={disabled}
      onClick={edit}
      style={{ color: getColor(disabled, active), fontSize: fz ? `${fz}px` : '24px', padding: fz ? `${fz/2}px` : '12px', lineHeight: 1 }}
    >
      <Edit
        color='inherit'
        fontSize='inherit'
      />
    </IconButton>
  )
}