import React from 'react';

import { useHistory } from 'react-router-dom';

import { User, Project } from '../../../generated/graphql';
import { IPeopleInnerProps } from '../People';

import { TableBody, TableHead } from '@material-ui/core';

import {
  PeopleTableRowsWrapper,
  PeopleTableContentCell,
  PeopleTableHeaderTitle,
  PeopleTableHeadRow,
  PeopleTableContentRow,
  PeopleTableNoUsersRow
} from '../People.style';

import { UserMenu } from '../../shared/Options/UserMenu';


export const Clients = ({ noData, users, getUserData }: IPeopleInnerProps) => {
  interface IHeader {
    title: string;
    maxWidth?: string;
  }

  const headers: IHeader[] = [
    { title: 'Фамилия' },
    { title: 'Имя' },
    { title: 'Телефон' },
    { title: 'Проект' },
    { title: 'Статус проекта' },
    {
      title: '',
      maxWidth: '48px',
    },
  ];

  const history = useHistory();

  return (
    <>
      <TableHead>
        <PeopleTableHeadRow columns={headers.length - 1}>
          {headers.map((header: IHeader) => (
            <PeopleTableHeaderTitle style={{ maxWidth: `${header.maxWidth ? header.maxWidth : 'unset' } ` }} key={header.title}>{header.title}</PeopleTableHeaderTitle>
          ))}
        </PeopleTableHeadRow>
      </TableHead>

      <TableBody>
        <PeopleTableRowsWrapper>

        {users?.length
          ? users?.map((user: User) => (
            <PeopleTableContentRow
              key={user.id}
              columns={headers.length - 1}
              onClick={() => history.push(`/user/${user.id}/edit`)}
            >
              {[
                user.lastName,
                user.firstName,
                user.phone,
                user.projects?.map((project: Project | null | undefined) => <div>{project?.name}</div>),
                user.projects?.map((project: Project | null | undefined) => <div>{project?.status?.displayName}</div>),
              ].map((field, i) => (
                <PeopleTableContentCell key={i}>
                  {field ? field : noData}
                </PeopleTableContentCell>
              ))}
              <PeopleTableContentCell>
                <UserMenu
                  user={user}
                  getUserData={getUserData}
                />
              </PeopleTableContentCell>
            </PeopleTableContentRow>
          ))
          : (
            <PeopleTableNoUsersRow>
              <PeopleTableContentCell>
                Пользователи отсутствуют
              </PeopleTableContentCell>
            </PeopleTableNoUsersRow>
          )}
        </PeopleTableRowsWrapper>
      </TableBody>
    </>
  )
}
