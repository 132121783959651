import React, { useState } from "react";
import { CustomTable } from "../../../shared/CustomTable";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";
import {
  CustomTableCell,
  CustomTableHead,
  CustomTableHeaderCell,
  CustomTableRow,
} from "../../../shared/CustomTable/CustomTable.style";
import { useQuery } from "@apollo/client";
import {
  GetProjectsLightDocument,
  GetProjectsLightQuery,
} from "../../../../generated/graphql";
import { LoadingBox } from "../../../shared/Style/Style";
import { DropdownArrowButtonBold } from "../../../shared/Buttons/DropdownArrowButtonBold";

const headers = [
  { name: "Проект", width: 304 },
  { name: "Дата", width: 114 },
  { name: "Суть проблемы", width: 238 },
  { name: "Статус", width: 134 },
  { name: "Ответственный", width: 165 },
  { name: "Потраченное время", width: 185 },
];

export const BugsReport = () => {
  const [currentProjectId, setCurrentProjectId] = useState("");

  const {
    data: projectsData,
    loading: projectsLoading,
    error: projectsError,
  } = useQuery<GetProjectsLightQuery>(GetProjectsLightDocument);

  let content: JSX.Element;
  const bugList = (
    <TableRow>
      <CustomTableCell colSpan={6}>bugList</CustomTableCell>
    </TableRow>
  );

  if (projectsLoading) {
    content = (
      <TableRow>
        <CustomTableCell colSpan={6}>
          <LoadingBox>
            <CircularProgress color="inherit" />
          </LoadingBox>
        </CustomTableCell>
      </TableRow>
    );
  }

  if (projectsError) {
    content = (
      <TableRow>
        <CustomTableCell colSpan={7}>
          При загрузке данных произошла ошибка
        </CustomTableCell>
      </TableRow>
    );
  }

  if (projectsData) {
    const projects = projectsData.getProjectsLight;

    content = (
      <Table>
        <CustomTableHead>
          <TableRow>
            {headers.map((header, i) => (
              <CustomTableHeaderCell key={i} width={header.width}>
                {header.name}
              </CustomTableHeaderCell>
            ))}
          </TableRow>
        </CustomTableHead>

        <TableBody>
          {projects.length ? (
            projects.map((project) => (
              <>
                <CustomTableRow
                  clickable={true}
                  onClick={() => {
                    setCurrentProjectId(project.id);
                  }}
                >
                  <CustomTableCell>
                    <Box
                      style={{
                        width: "100%",
                        fontWeight: "bolder",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {project.name}
                      <DropdownArrowButtonBold
                        expanded={currentProjectId === project.id}
                      />
                    </Box>
                  </CustomTableCell>

                  <CustomTableCell colSpan={5} />
                </CustomTableRow>

                {currentProjectId === project.id && bugList}
              </>
            ))
          ) : (
            <TableRow>
              <CustomTableCell colSpan={7}>Проекты отсутствуют</CustomTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  }

  return (
    <>
      <CustomTable data={undefined} tableContent={content} />
    </>
  );
};
