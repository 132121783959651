import React, { memo, useRef, useState } from "react";
import { Box, CircularProgress, Grid, TextField } from "@material-ui/core";
import { Field, Form, FormSpy, useForm } from "react-final-form";
import {
  isRequired,
  passwordValidation,
} from "../../core/validators/validators";
import { PasswordInfoType } from "./Registration.type";
import { ButtonCustom, useStyles } from "../shared/Style/Style";
import { VisibilityButton } from "../shared/Buttons/VisibilityButton";

export const PasswordInfo = memo(
  ({
    loading,
    onNext,
    onError,
    title,
    formError,
    setFormError,
  }: PasswordInfoType) => {
    const inputRef = useRef(null);
    const classes = useStyles();
    const form = useForm();
    const [visiblePassword, setVisiblePassword] = useState(false);

    return (
      <Form
        onSubmit={() => {}}
        validate={passwordValidation}
      >
        {({ values }) => {
          return (
            <>
              <FormSpy
                onChange={() => {
                  onError();
                }}
              />
              <Grid item={true} xs={12} style={{ position: "relative" }}>
                <Field name="password"  >
                  {({ input, meta }) => (
                    <TextField
                      ref={inputRef}
                      className={classes.root}
                      error={
                        (meta.touched && meta.error) ||
                        !!formError.passwordError
                      }
                      helperText={
                        (meta.touched && meta.error) || formError.passwordError
                      }
                      margin="normal"
                      fullWidth
                      label="Пароль"
                      variant="filled"
                      type={visiblePassword ? "text" : "password"}
                      {...input}
                      onChange={(event) => {
                        setFormError({
                          ...formError,
                          passwordError: "",
                        });
                        input.onChange(event.target?.value);
                        input.onBlur();
                      }}
                    />
                  )}
                </Field>
                <Box position="absolute" right={5} top={3}>
                  <VisibilityButton
                    active={visiblePassword}
                    show={() => setVisiblePassword(true)}
                    hide={() => setVisiblePassword(false)}
                  />
                </Box>
              </Grid>
              <Grid item={true} xs={12}>
                <Field name="confirmPassword" >
                  {({ input, meta }) => (
                    <>
                      <TextField
                        ref={inputRef}
                        className={classes.root}
                        error={
                          (meta.touched && meta.error) ||
                          !!formError.confirmPasswordError
                        }
                        helperText={
                          (meta.touched && meta.error) ||
                          formError.confirmPasswordError
                        }
                        margin="normal"
                        fullWidth
                        label="Введите пароль ещё раз"
                        variant="filled"
                        type={visiblePassword ? "text" : "password"}
                        {...input}
                        onChange={(event) => {
                          setFormError({
                            ...formError,
                            confirmPasswordError: "",
                          });
                          input.onChange(event.target?.value);
                          input.onBlur();
                        }}
                      />
                    </>
                  )}
                </Field>
              </Grid>
              <Box mt={2}>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item>
                    <FormSpy>
                      {() => {
                        return (
                          <ButtonCustom
                            disabled={loading}
                            onClick={() => {
                              const baseFieldsValues = form.getState().values;
                              setFormError({
                                firstNameError: baseFieldsValues.firstName
                                  ? ""
                                  : "Обязательное поле",
                                lastNameError: baseFieldsValues.lastName
                                  ? ""
                                  : "Обязательное поле",
                                phoneError: baseFieldsValues.phone
                                  ? ""
                                  : "Обязательное поле",
                                emailError: baseFieldsValues.email
                                  ? ""
                                  : "Обязательное поле",
                                passwordError: values.password
                                  ? ""
                                  : "Обязательное поле",
                                confirmPasswordError: values.confirmPassword
                                  ? ""
                                  : "Обязательное поле",
                              });

                              const errors = {
                                ...formError,
                                ...form.getState().errors,
                              };

                              const passwordErrors = passwordValidation(values);

                              if (passwordErrors.password || passwordErrors.confirmPassword) {
                                return;
                              }

                              if (
                                Object.values(errors).every((value) => !value)
                              ) {
                                onNext({
                                  ...form.getState().values,
                                  ...values,
                                });
                              }
                            }}
                          >
                            {loading && <CircularProgress size={24} />}
                            {title}
                          </ButtonCustom>
                        );
                      }}
                    </FormSpy>
                  </Grid>
                </Grid>
              </Box>
            </>
          );
        }}
      </Form>
    );
  }
);
