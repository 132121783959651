import styled from "styled-components";
import {
  ButtonCustomWithDarkText,
  lightBlack,
  primary,
  SmallUserAvatarStyle,
} from "../shared/Style/Style";
import { Box, List, Select, TextField } from "@material-ui/core";

export const FeedbackList = styled(List)`
  max-height: 52vh;
`;
export const FeedbackListItem = styled.li`
  display: flex;
  gap: 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 50px 20px 35px 40px;
  color: ${lightBlack};
  margin-bottom: 10px;
`;
export const FeedbackDateBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 20px;
`;
export const FeedbackTitle = styled.h3`
  font-size: 12px;
  line-height: 14px;
  margin: 0 5px 0 0;
  height: 16px;
`;
export const FeedbackContent = styled(Box)`
  display: flex;
`;
export const FeedbackAvatar = styled(SmallUserAvatarStyle)`
  flex-shrink: 0;
`;
export const FeedbackContentBox = styled(Box)`
  display: block;
  width: 100%;
`;
export const FeedbackTextBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 20px;
`;
export const FeedbackStatusSelect = styled(Select)`
  padding: 5px 10px 5px 20px;
  background-color: #e9e9e9;
  width: 240px;
  font-weight: 500;
  color: ${lightBlack};

  & .MuiSelect-select:focus {
    background-color: unset;
  }

  &::after {
    content: none;
  }

  &::before {
    content: none;
  }

  &:hover {
    border: none;
  }

  & .MuiSelect-icon {
    right: 10px;
  }
`;

interface IFeedbackCustomListProps {
  visible: boolean;
}

export const FeedbackCommentList = styled(List)<IFeedbackCustomListProps>`
  padding: 0;
  transition: all 0.5s;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  max-height: ${(props) => (props.visible ? "auto" : 0)};
`;
export const FeedbackControl = styled.p`
  margin: 0 0 20px;
  color: ${primary};
  cursor: pointer;
`;

export const FeedbackCommentContent = styled(Box)`
  flex-grow: 1;
`;
export const FeedbackComment = styled.p`
  margin: 10px 0;
`;
export const Comment = styled.li`
  width: 75%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  color: ${lightBlack};
`;
export const AddCommentBtnFeedback = styled(ButtonCustomWithDarkText)`
  text-transform: unset;
  line-height: 45px;
  max-height: 45px;
  width: 145px;
  margin-left: 70px;
`;
export const AddCommentFeedback = styled(TextField)`
  & .MuiInput-underline:before {
    border-bottom-color: #eee;
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${primary};
  }

  & .MuiInputBase-multiline {
    padding: 13px 14px 13px 20px;
  }
`;
