import styled from "styled-components";
import { lightBlack, primary, secondary } from "../../../shared/Style/Style";
import { Button } from "@material-ui/core";

export const HeadersGrid = styled.div`
  grid-column: 1 / span 4;
  grid-row: 1;
  display: grid;
  grid-template-columns: 180px 1fr 1fr 2fr 2fr;
  grid-template-rows: 46px;
  background-color: ${lightBlack};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  align-items: center;
`;

export const RowGrid = styled.div`
  grid-column: 1 / span 4;
  grid-row: 1;
  display: grid;
  grid-template-columns: 180px 1fr 1fr 2fr 2fr;
  grid-template-rows: 46px;
  align-items: center;
  position: relative;

  &  div:nth-child(2) {
    min-width: 180px;
  }
`;

export const SaveButtonContainer = styled.div`
  position: absolute;
  right: 0;
`;

export const ButtonAddGrid = styled.div`
  display: flex;
  background-color: ${primary};
  align-items: center;
  justify-content: center;
  height: 44px;
`;

export const ButtonCustomWithIcon = styled(Button)`
  background-color: transparent;
  color: ${secondary};
  text-transform: none;
  width: 24px;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: inherit;
    background-color: transparent;
  }
`;

export const ChartContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;
