export enum ColumnsIdEnum {
  Link = 'link',
  Date = 'name',
  Delete = 'delete',
}

export const rowsPerPageOptions = [10, 25, 100];

interface Column {
  id: string;
  label: string;
}
export const columns: readonly Column[] = [
  { id: ColumnsIdEnum.Link, label: 'Сcылка' },
  { id: ColumnsIdEnum.Date, label: 'Дата / Период' },
  { id: ColumnsIdEnum.Delete, label: '' },
];
