import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { LoadingBox, MainTableCell } from "../../shared/Style/Style";
import {
  CustomScrollBox,
  MobileAnswersStatisticRow,
} from "../SmartNotification.style";
import { useQuery } from "@apollo/client";
import {
  GetSmartNotificationPollListByIdDocument,
  GetSmartNotificationPollListByIdQuery,
} from "../../../generated/graphql";
import { convertDateStringToLocaleHandler } from "../../shared/Utils/ConvertOperations/convertDateStringToLocaleHandler";
import { convertDateAndTimeToLocaleHandler } from "../../shared/Utils/ConvertOperations/convertTimeToLocalHandler";
import { ExportUserCSV } from "./ExportUserCSV";

export const StatisticColumn = ({ id }) => {

  const { data, loading, error } =
    useQuery<GetSmartNotificationPollListByIdQuery>(
      GetSmartNotificationPollListByIdDocument,
      {
        variables: { id },
      }
    );

  let content;

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (data) {

    const { answers, name } = data.getSmartNotificationPollList;

    content = (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={"40px"}
          mr={"30px"}
        >
          <Box>Ответ: {name}</Box>
          <Box display="flex" alignItems="center">
            <Box>Всего: {answers.length} ответов </Box>
            <Box><ExportUserCSV answers={answers}/></Box>
          </Box>
        </Box>

        <CustomScrollBox>
          {answers.map(
            ({ createdDate, createdBy}) => (
              <MobileAnswersStatisticRow>
                <MainTableCell>
                  {convertDateStringToLocaleHandler(createdDate)}
                </MainTableCell>
                <MainTableCell>
                  {convertDateAndTimeToLocaleHandler(createdDate)}
                </MainTableCell>
                <MainTableCell>
                  {createdBy?.firstName?createdBy?.firstName:"Удаленый"} {createdBy?.lastName?createdBy?.lastName:"пользователь"}
                </MainTableCell>
              </MobileAnswersStatisticRow>
            )
          )}
        </CustomScrollBox>
      </>
    );
  }

  return (
    <Box
      width="500px"
      boxShadow={3}
      borderRadius={5}
      mb={4}
      p={"40px 10px 40px 40px"}
    >
      {content}
    </Box>
  );
};
