import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  GetUsersDocument,
  GetUsersInput,
  GetUsersQuery,
  User,
  UserRoleEnum,
  Maybe,
} from "../../generated/graphql";
import {
  CircularProgress,
  Container,
  Table,
  TableRow,
} from "@material-ui/core";
import { CustomTablePagination } from "../shared/CustomTablePagination";
import { PeopleFilterBar } from "../PeopleFilterBar";
import { PeopleSearchBar } from "../PeopleSearchBar";
import { Header, LoadingBox } from "../shared/Style/Style";
import {
  PeopleTableContentCell,
  PeopleTableNoUsersRow,
} from "./People.style";
import { New } from "./New";
import { Staff } from "./Staff";
import { Clients } from "./Clients";

export interface IPeopleInnerProps {
  noData: string;
  users: User[] | [];
  getUserData: GetUsersInput;
}

export interface IActiveFilterState {
  role?: UserRoleEnum[];
  statuses: string[];
  searchKey?: string;
}

const noData = "Данные отсутствуют";

export const People = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [activeFilter, setActiveFilter] = useState<IActiveFilterState>({
    role: [UserRoleEnum.Staff,UserRoleEnum.Super,UserRoleEnum.Manager],
    statuses: ["VERIFIED"],
  });

  const getUserData: GetUsersInput = {
    limit: rowsPerPage,
    skip: page * rowsPerPage,
    roles: activeFilter.role,
    statuses: activeFilter.statuses,
    searchKey: activeFilter.searchKey ? activeFilter.searchKey : undefined,
  };

  const { data, loading, error } = useQuery<GetUsersQuery>(GetUsersDocument, {
    variables: {
      getUsersData: getUserData,
    },
    fetchPolicy: "cache-and-network",
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const pagination = (
    <CustomTablePagination
      rowsPerPageOptions={[10, 20]}
      count={data?.getUsers?.total || 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );

  let content;

  if (loading) {
    content = (
      <PeopleTableNoUsersRow>
        <PeopleTableContentCell>
          <LoadingBox>
            <CircularProgress color="inherit" />
          </LoadingBox>
        </PeopleTableContentCell>
      </PeopleTableNoUsersRow>
    );
  }

  if (error) {
    content = (
      <PeopleTableNoUsersRow>
        <PeopleTableContentCell>
          При загрузке пользователей произошла ошибка
        </PeopleTableContentCell>
      </PeopleTableNoUsersRow>
    );
  }

  if (data) {
    const users = data.getUsers.users;

    switch (activeFilter.role) {
      case ["CLIENT"]:
        content = (
          <Clients noData={noData} users={users} getUserData={getUserData} />
        );
        break;

      case ["STAFF"]:
        content = (
          <Staff noData={noData} users={users} getUserData={getUserData} />
        );
        break;

      default:
        content = (
          <New noData={noData} users={users} getUserData={getUserData} />
        );
    }
  }

  return (
    <Container maxWidth="lg">
      <Header>Люди</Header>

      <Table>
        <TableRow>
          <PeopleSearchBar
            label='Введите имя или фамилию'
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
          />
        </TableRow>
        <TableRow>
          <PeopleFilterBar
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            setPage={setPage}
          />
        </TableRow>
        <TableRow>
          {pagination}
        </TableRow>
      </Table>

      <Table>
        {content}
        <TableRow>{pagination}</TableRow>
      </Table>
    </Container>
  );
};
