import { gql } from "@apollo/client";

export const GET_APPLICATION_MAIN_THEMES_BY_MAIN_ID = gql`
  query getApplicationMainThemesByMainId($applicationMainId: String!) {
    getApplicationMainThemesByMainId(applicationMainId: $applicationMainId) {
      id
      nameEN
      nameUKR
      analyticsMark
      subscriptionPlan
    }
  }
`;
