import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import {
  KnowledgeBaseBlock,
  BlockTypeInput,
  UpdateKnowledgeBaseBlockMutation,
  UpdateKnowledgeBaseBlockDocument,
  GetKnowledgeBaseBlocksByThemeIdDocument,
  KnowledgeBaseBlockTypeEnum,
  DeleteKnowledgeBaseBlockMutation,
  DeleteKnowledgeBaseBlockDocument,
  CreateKnowledgeBaseBlockListItemDocument,
  CreateKnowledgeBaseBlockListItemMutation,
  UpdateKnowledgeBaseBlockListItemMutation,
  UpdateKnowledgeBaseBlockListItemDocument,
  UploudKnowledgeBaseBlockPhotoMutation,
  UploudKnowledgeBaseBlockPhotoDocument,
  GetKnowledgeBaseBlocksTestByThemeIdDocument,
  DeleteKnowledgeBaseBlockListItemMutation,
  DeleteKnowledgeBaseBlockListItemDocument,
  CreateKnowledgeBaseBlockTestAnswerMutation,
  CreateKnowledgeBaseBlockTestAnswerDocument,
  UpdateKnowledgeBaseBlockTestAnswerMutation,
  UpdateKnowledgeBaseBlockTestAnswerDocument,
  DeleteKnowledgeBaseBlockTestAnswerMutation,
  DeleteKnowledgeBaseBlockTestAnswerDocument,
  HandleKnowledgeBaseBlockEditorPhotoMutation,
  HandleKnowledgeBaseBlockEditorPhotoDocument
} from "../../generated/graphql";

import { TextBlock } from "./Blocks/TextBlock";
import { ListBlock } from "./Blocks/ListBlock";
import { PhotoBlock } from "./Blocks/PhotoBlock";
import { QuestionBlock } from "./Blocks/QuestionBlock";
import { StepsBlock } from "./Blocks/StepsBlock/StepsBlock";
import { FormulaBlock } from "./Blocks/FormulaBlock";

import { Box, Paper } from "@material-ui/core";
import { Delete, Check, Edit } from "@material-ui/icons";

import { ButtonTxt, ButtonWithIcon } from "./KnowledgeBaseBlockItem.style";
import { green, secondary } from "../shared/Style/Style";

import { videoUrlNormalize } from "../shared/Utils/ConvertOperations/videoUrlNormalize";
import { EditorBlock } from "./Blocks/EditorBlock";


interface IKnowledgeBaseBlockItemProps {
  disabled: boolean;
  block: KnowledgeBaseBlock;
  editedBlockId: string;
  setEditedBlockId(blockId: string): void;
  setRequestError(error: boolean): void;
  setErrorMessage(message: string): void;
}

export interface IKnowledgeBaseBlockInnerProps {
  disabled: boolean;
  block: KnowledgeBaseBlock;
  blockTypeData?: BlockTypeInput;
  setBlockTypeData?(blockTypeData: BlockTypeInput): void;
  createListItem?(blockId: string, content: string, isNumbered: boolean, photo?: File, video?: string, photoTitle?: string): void;
  updateListItem?(listItemId: string, content: string, photo?: File, video?: string, photoTitle?: string): void;
  deleteListItem?(id: string): void;
  createAnswer?(blockId: string, content: string, isAnswerTrue: boolean): void;
  updateAnswer?(answerId: string, content: string, isAnswerTrue: boolean): void;
  deleteAnswer?(id: string): void;
  uploadPhoto?({ variables }: { variables: { blockId: string, photo: File } }): void;
  handleEditorBlockPhoto?({ variables }: { variables: { blockId: string, photo?: File } }): void;
  successRequest?: CreateKnowledgeBaseBlockListItemMutation | UpdateKnowledgeBaseBlockListItemMutation | CreateKnowledgeBaseBlockTestAnswerMutation | UpdateKnowledgeBaseBlockTestAnswerMutation;
}

const typesNotForSave = [
  KnowledgeBaseBlockTypeEnum.List,
  KnowledgeBaseBlockTypeEnum.Photo,
]

export const KnowledgeBaseBlockItem = ({
  disabled,
  block,
  editedBlockId,
  setEditedBlockId,
  setRequestError,
  setErrorMessage
}: IKnowledgeBaseBlockItemProps) => {
  const { themeId } = useParams<{ themeId: string }>();

  const { id, type, content, testQuestion, video } = block;

  const isEditing = id === editedBlockId;
  const isTestBlock = type === KnowledgeBaseBlockTypeEnum.Test;

  const [blockTypeData, setBlockTypeData] = useState<BlockTypeInput>({
    content, testQuestion, video
  });

  const errorHandler = (message: string): void => {
    setRequestError(true);
    setErrorMessage(message);
  }

  const [updateBlock, {
    data: updateBlockData,
    loading: updateBlockLoading
  }] = useMutation<UpdateKnowledgeBaseBlockMutation>(UpdateKnowledgeBaseBlockDocument, {
    variables: {
      id: editedBlockId,
      blockTypeData: {
        ...blockTypeData,
        video: blockTypeData.video?.includes('watch')
          ? videoUrlNormalize(blockTypeData.video)
          : blockTypeData.video
      }
    },
    refetchQueries: [{
      query: isTestBlock
        ? GetKnowledgeBaseBlocksTestByThemeIdDocument
        : GetKnowledgeBaseBlocksByThemeIdDocument,
      variables: { themeId } }]
  });

  const saveHandler = async() => {
    try {
      typesNotForSave.includes(type)
        ? setEditedBlockId('')
        : updateBlock()
    } catch (e) {
      errorHandler(`При сохранении ${isTestBlock ? 'теста' : 'блока'} произошла ошибка`);
    }
  };

  const [deleteBlock, {
    data: deleteBlockData,
    loading: deleteBlockLoading
  }] = useMutation<DeleteKnowledgeBaseBlockMutation>(DeleteKnowledgeBaseBlockDocument, {
    refetchQueries: [{
      query: isTestBlock
        ? GetKnowledgeBaseBlocksTestByThemeIdDocument
        : GetKnowledgeBaseBlocksByThemeIdDocument,
      variables: { themeId } }]
  });

  const deleteBlockHandler = async() => {
    try {
      deleteBlock({ variables: { id } })
    } catch (e) {
      errorHandler(`При удалении ${isTestBlock ? 'теста' : 'блока'} произошла ошибка`);
    }
  }

  const [createListItem, {
    data: createListItemData,
    loading: createListItemLoading
  }] = useMutation<CreateKnowledgeBaseBlockListItemMutation>(CreateKnowledgeBaseBlockListItemDocument, {
    refetchQueries: [{ query: GetKnowledgeBaseBlocksByThemeIdDocument, variables: { themeId } }]
  });

  const createListItemHandler = async(
    blockId: string,
    content: string,
    isNumbered: boolean,
    photo?: File,
    video?: string,
    photoTitle?: string
  ) => {
    try {
      await createListItem({
        variables: { blockId, content, isNumbered, photo, video, photoTitle }
      })
    } catch (e) {
      errorHandler('При создании пункта произошла ошибка');
    }
  };

  const [updateListItem, {
    data: updateListItemData,
    loading: updateListItemLoading
  }] = useMutation<UpdateKnowledgeBaseBlockListItemMutation>(UpdateKnowledgeBaseBlockListItemDocument, {
    refetchQueries: [{ query: GetKnowledgeBaseBlocksByThemeIdDocument, variables: { themeId } }]
  });

  const updateListItemHandler = async(
    listItemId: string,
    content: string,
    photo?: File,
    video?: string,
    photoTitle?: string
  ) => {
    try {
      await updateListItem({
        variables: { listItemId, content, photo, video, photoTitle  }
      })
    } catch (e) {
      errorHandler('При изменении пункта произошла ошибка');
    }
  };

  const [deleteListItem, {
    data: deleteListItemData,
    loading: deleteListItemLoading
  }] = useMutation<DeleteKnowledgeBaseBlockListItemMutation>(DeleteKnowledgeBaseBlockListItemDocument, {
    refetchQueries: [{ query: GetKnowledgeBaseBlocksByThemeIdDocument, variables: { themeId } }]
  });

  const deleteListItemHandler = async(id: string) => {
    try {
      await deleteListItem({
        variables: { id }
      })
    } catch (e) {
      errorHandler('При удалении пункта произошла ошибка');
    }
  };

  const [createTestAnswer, {
    data: createTestAnswerData,
    loading: createTestAnswerLoading
  }] = useMutation<CreateKnowledgeBaseBlockTestAnswerMutation>(CreateKnowledgeBaseBlockTestAnswerDocument, {
    refetchQueries: [{
      query: isTestBlock
        ? GetKnowledgeBaseBlocksTestByThemeIdDocument
        : GetKnowledgeBaseBlocksByThemeIdDocument,
      variables: { themeId } }]
  });

  const createAnswerHandler = async(blockId: string, content: string, isAnswerTrue: boolean) => {
    try {
      await createTestAnswer({
        variables: { blockId, content, isAnswerTrue}
      })
    } catch (e) {
      errorHandler('При создании ответа произошла ошибка');
    }
  };

  const [updateTestAnswer, {
    data: updateTestAnswerData,
    loading: updateTestAnswerLoading
  }] = useMutation<UpdateKnowledgeBaseBlockTestAnswerMutation>(UpdateKnowledgeBaseBlockTestAnswerDocument, {
    refetchQueries: [{
      query: isTestBlock
        ? GetKnowledgeBaseBlocksTestByThemeIdDocument
        : GetKnowledgeBaseBlocksByThemeIdDocument,
      variables: { themeId } }]
  });

  const updateAnswerHandler = async(answerId: string, content: string, isAnswerTrue: boolean) => {
    try {
      await updateTestAnswer({
        variables: { answerId, content, isAnswerTrue}
      })
    } catch (e) {
      errorHandler('При изменении ответа произошла ошибка');
    }
  };

  const [deleteTestAnswer, {
    data: deleteTestAnswerData,
    loading: deleteTestAnswerLoading
  }] = useMutation<DeleteKnowledgeBaseBlockTestAnswerMutation>(DeleteKnowledgeBaseBlockTestAnswerDocument, {
    refetchQueries: [{
      query: isTestBlock
        ? GetKnowledgeBaseBlocksTestByThemeIdDocument
        : GetKnowledgeBaseBlocksByThemeIdDocument,
      variables: { themeId } }]
  });

  const deleteAnswerHandler = async(id: string) => {
    try {
      await deleteTestAnswer({
        variables: { id }
      })
    } catch (e) {
      errorHandler('При удалении ответа произошла ошибка');
    }
  };

  const [uploadPhoto, {
    data: uploadPhotoData,
    loading: uploadPhotoLoading,
    error: uploadPhotoError
  }] = useMutation<UploudKnowledgeBaseBlockPhotoMutation>(UploudKnowledgeBaseBlockPhotoDocument, {
    refetchQueries: [{ query: GetKnowledgeBaseBlocksByThemeIdDocument, variables: { themeId } }]
  });

  const [handleEditorBlockPhoto, {
    data: editorBlockPhotoData,
    loading: editorBlockPhotoLoading,
    error: editorBlockPhotoError
  }] = useMutation<HandleKnowledgeBaseBlockEditorPhotoMutation>(HandleKnowledgeBaseBlockEditorPhotoDocument, {
    refetchQueries: [{ query: GetKnowledgeBaseBlocksByThemeIdDocument, variables: { themeId } }]
  });

  //#region effects

  useEffect(() => {
    if (block) {
      const { content, testQuestion, video } = block;

      if (block.type !== KnowledgeBaseBlockTypeEnum.Editor) {
        setBlockTypeData({ content, testQuestion, video });
      }
    }
  }, [block]);

  useEffect(() => {
    if (
      updateBlockData
      || deleteBlockData
      || createListItemData
      || updateListItemData
      || uploadPhotoData
      || deleteListItemData
      || createTestAnswerData
      || updateTestAnswerData
      || deleteTestAnswerData
      || editorBlockPhotoData
    ) {
      setRequestError(false);
      setErrorMessage('');
    }
  }, [
    updateBlockData,
    deleteBlockData,
    createListItemData,
    updateListItemData,
    uploadPhotoData,
    deleteListItemData,
    createTestAnswerData,
    updateTestAnswerData,
    deleteTestAnswerData,
    editorBlockPhotoData,
  ]);

  useEffect(() => {
    if (updateBlockData || deleteBlockData || uploadPhotoData) {
      setEditedBlockId('');
    }
  }, [updateBlockData, deleteBlockData, uploadPhotoData]);

  useEffect(() => {
    if (uploadPhotoError) {
      errorHandler('При загрузке картинки произошла ошибка');
    }
  }, [uploadPhotoError]);
  
  useEffect(() => {
    if (editorBlockPhotoError) {
      errorHandler('При загрузке картинки в редактор произошла ошибка');
    }
  }, [editorBlockPhotoError]);

  //#endregion


  const disabledOnLoading = disabled
    || deleteBlockLoading
    || updateBlockLoading
    || createListItemLoading
    || updateListItemLoading
    || deleteListItemLoading
    || createTestAnswerLoading
    || updateTestAnswerLoading
    || deleteTestAnswerLoading
    || uploadPhotoLoading
    || editorBlockPhotoLoading;

  const getBlockByType = (type: KnowledgeBaseBlockTypeEnum): JSX.Element => {
    switch (type) {
      case KnowledgeBaseBlockTypeEnum.Photo:
        return <PhotoBlock
          disabled={(!isEditing && !!editedBlockId) || !editedBlockId || disabledOnLoading}
          block={block}
          uploadPhoto={uploadPhoto}
        />;

      case KnowledgeBaseBlockTypeEnum.List:
        return <ListBlock
          disabled={(!isEditing && !!editedBlockId) || !editedBlockId || disabledOnLoading}
          block={block}
          createListItem={createListItemHandler}
          updateListItem={updateListItemHandler}
          deleteListItem={deleteListItemHandler}
          successRequest={createListItemData || updateListItemData}
        />;

      case KnowledgeBaseBlockTypeEnum.Question:
        return <QuestionBlock
          disabled={(!isEditing && !!editedBlockId) || !editedBlockId || disabledOnLoading}
          block={block}
          blockTypeData={blockTypeData}
          setBlockTypeData={setBlockTypeData}
          createAnswer={createAnswerHandler}
          updateAnswer={updateAnswerHandler}
          deleteAnswer={deleteAnswerHandler}
          successRequest={createTestAnswerData || updateTestAnswerData}
        />;

      case KnowledgeBaseBlockTypeEnum.Test:
        return <QuestionBlock
          disabled={(!isEditing && !!editedBlockId) || !editedBlockId || disabledOnLoading}
          block={block}
          blockTypeData={blockTypeData}
          setBlockTypeData={setBlockTypeData}
          createAnswer={createAnswerHandler}
          updateAnswer={updateAnswerHandler}
          deleteAnswer={deleteAnswerHandler}
          successRequest={createTestAnswerData || updateTestAnswerData}
        />;

      case KnowledgeBaseBlockTypeEnum.MultiList:
        return <StepsBlock
          disabled={(!isEditing && !!editedBlockId) || !editedBlockId || disabledOnLoading}
          block={block}
          createListItem={createListItemHandler}
          updateListItem={updateListItemHandler}
          deleteListItem={deleteListItemHandler}
          successRequest={createListItemData || updateListItemData}
        />;

      case KnowledgeBaseBlockTypeEnum.Formula:
        return <FormulaBlock
          disabled={(!isEditing && !!editedBlockId) || !editedBlockId || disabledOnLoading}
          block={block}
          blockTypeData={blockTypeData}
          setBlockTypeData={setBlockTypeData}
        />;

      case KnowledgeBaseBlockTypeEnum.Editor:
        return <EditorBlock
          disabled={(!isEditing && !!editedBlockId) || !editedBlockId}
          block={block}
          blockTypeData={blockTypeData}
          setBlockTypeData={setBlockTypeData}
          handleEditorBlockPhoto={handleEditorBlockPhoto}
        />;

      default:
        return <TextBlock
          disabled={(!isEditing && !!editedBlockId) || !editedBlockId || disabledOnLoading}
          block={block}
          blockTypeData={blockTypeData}
          setBlockTypeData={setBlockTypeData}
        />;
    }
  };

  const blockContent = getBlockByType(type);

  return (
    <Paper elevation={2}>
      <Box
        p='50px'
        mb='30px'
        position='relative'
      >
        {blockContent}
        <Box
          mt='20px'
          display='flex'
          justifyContent='flex-end'
          alignItems='center'
        >
          <ButtonWithIcon
            variant='contained'
            disabled={!!editedBlockId || disabledOnLoading}
            onClick={deleteBlockHandler}
          >
            <ButtonTxt>
              Удалить блок
            </ButtonTxt>

            <Delete
              color='secondary'
            />
          </ButtonWithIcon>

          <ButtonWithIcon
            variant='contained'
            width={189}
            disabled={(!isEditing && !!editedBlockId) || disabledOnLoading}
            onClick={() => {
              isEditing
                ? saveHandler()
                : setEditedBlockId(id)
            }}
          >
            <ButtonTxt>
              {isEditing ? 'Готово' : 'Редактировать'}
            </ButtonTxt>

            {isEditing
              ? <Check htmlColor={green}/>
              : <Edit htmlColor={secondary} />
            }
          </ButtonWithIcon>
        </Box>
      </Box>
    </Paper>
  )
}
