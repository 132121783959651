import { FC } from "react";
import { useRequestError } from "../../../hooks";
import { useGetLazySchoolUsersCsvFile } from "./hooks";
import { ImportExportContainer } from "../Style";
import { ButtonCustom } from "../../shared/Style/Style";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";

interface ImportExportParticipantsProps {
    schoolId: string;
    openImportModal(): void;
}

export const ImportExportParticipants: FC<ImportExportParticipantsProps> = ({ 
    schoolId,
    openImportModal 
}) => {
    const {
        schoolUsersCsv,
        schoolUsersCsvLoading,
        schoolUsersCsvError,
    } = useGetLazySchoolUsersCsvFile();

    const errorMessage = schoolUsersCsvError && "Ошибка при загрузке списка учителей, учеников и родителей";
    const { requestError, setRequestError } = useRequestError(schoolUsersCsvError);
    
    const handleFileExport = async () => {
        const { data } = await schoolUsersCsv({ variables: { schoolId } });
        const link = data?.getSchoolUsersCSV || '';
        
        if (link) { openExportLink(link) }; 
    }

    const openExportLink = (url: string) => {
        window.location.href = url;
    };

    return (
        <>
            <ImportExportContainer>
                <ButtonCustom onClick={openImportModal}>
                  ИМПОРТ пользователей школы
                </ButtonCustom>

                <ButtonCustom
                    disabled={schoolUsersCsvLoading}
                    onClick={handleFileExport}
                >
                    ЭКСПОРТ пользователей школы
                </ButtonCustom>
            </ImportExportContainer>

            <ErrorMessageSnackbar
                open={requestError}
                closeHandler={() => setRequestError(false)}
                message={errorMessage}
            />
        </>
    )
};
