import styled from "styled-components";
import { MainTableRow, primary } from "../../shared/Style/Style";

export const ModelsTableRow = styled(MainTableRow)`
  grid-template-columns: 0.8fr 1.2fr 2fr 48px;
  grid-gap: 10px;
  cursor: pointer;
  position: relative;
`;

export const ModalsLink = styled.a`
  color: ${primary};
`;

export const LinksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  min-width: 250px;
`;
