import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { CreateAchieveDocument, CreateAchieveMutation, CreateAchieveMutationVariables } from "../../generated/graphql";
import { Achievement } from "./Achievement";
import { useRequestError } from "../../hooks";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";

export const CreateAchievement = () => {
  const { goBack } = useHistory();
  const [createAchieve, { loading, error }] = useMutation<CreateAchieveMutation>(CreateAchieveDocument);

  const { requestError, setRequestError, errorMessage } = useRequestError(
    error,
    "При создании награды произошла ошибка",
  );

  const handleAchieveCreation = async (variables: CreateAchieveMutationVariables) => {
    try {
      await createAchieve({ variables });
    } catch (e) {
      console.error(e);
    } finally {
      goBack();
    }
  }

  return (
    <>
      <Achievement
        title="Создание награды"
        loading={loading}
        createAchievement={handleAchieveCreation}
      />

      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
    </>
  );
};
