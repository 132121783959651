import React from "react";
import { ClientSiteModel } from "../../../../generated/graphql";
import { getFileExtensionHandler } from "../../../shared/Utils/StringOperations/getFileExtensionHandler";
import { Menu } from "../../../shared/Menu";
import { LinksContainer, ModalsLink, ModelsTableRow } from "../Models.style";
import { MainTableCell } from "../../../shared/Style/Style";

interface ModelItemProps {
  model: ClientSiteModel;
  setCurrentModel(state: ClientSiteModel): void;
  menuItems: ({name: string, action: () => void, color?: undefined} | {name: string, action: () => void, color: string})[];
}

export const ModelItem = ({
  model,
  setCurrentModel,
  menuItems
}: ModelItemProps) => {
  return (
    <ModelsTableRow
      key={model.id}
      onClick={() => setCurrentModel({})}
    >
      <MainTableCell>{model.name}</MainTableCell>

      <MainTableCell>
        <LinksContainer>
          {model?.glbFile ? (
            <ModalsLink href={model.glbFile}>
              Файл {getFileExtensionHandler(model.glbFile)}
            </ModalsLink>
          ) : (
            <span>-</span>
          )}
          {model?.usdzFile ? (
            <ModalsLink href={model.usdzFile}>
              Файл {getFileExtensionHandler(model.usdzFile)}
            </ModalsLink>
          ) : (
            <span>-</span>
          )}
          {model?.gltfFile ? (
            <ModalsLink href={model.gltfFile}>
              Файл {getFileExtensionHandler(model.gltfFile)}
            </ModalsLink>
          ) : (
            <span>-</span>
          )}
        </LinksContainer>
      </MainTableCell>

      <MainTableCell>{`${model.clientSite.iframe}?itemId=${model.itemId}`}</MainTableCell>

      <MainTableCell
        onClick={(e) => {
          e.stopPropagation();
          setCurrentModel(model);
        }}
      >
        <Menu vertical={36} horizontal={12} width={170} items={menuItems} onClose={() => setCurrentModel(null)} />
      </MainTableCell>
    </ModelsTableRow>
  );
};
