import { DeleteArtifact, DeleteRobot, DeleteStage, ErrorType, InputErrors } from "./types";

export const robotsPageTitle = "Роботы";
export const robotsPageLink = "robots_list";
export const robotModelPageLink = "robot_model";
export const robotStagePageLink = "robot_stage";

export const robotsTableHeader = ["№", "Название", "Описание", "Стадии эволюции", "Статус"];
export const stagesTableHeader = ["Фото", "Стадия", "Описание", "Статус"];

export const nameMaxLength = 20;
export const descriptionMaxLength = 200;

export const defaultArtifactName = "Название артефакта";
export const defaultEnergyPrice = 1;
export const artifactPerPage = 10;

export const initialNotifications = {
  error: false,
  success: false,
  message: "",
};

export const initialRobotGeneralInfo = {
  name: "",
  description: "",
  background: "",
  order: null,
};

export enum RobotGeneralFields { 
  Name = 'name',
  Description = 'description',
  Background = 'background',
  Order = 'order',
};

export const initialStageGeneralInfo = {
  name: "",
  description: "",
  energyPrice: null,
  experiencePrice: null,
};

export enum RobotStageFields {
  Name = 'name',
  Description = 'description',
  EnergyPrice = 'energyPrice',
  ExperiencePrice = 'experiencePrice',
};

export const initialError: ErrorType = { error: false, message: '' };

export const initialRobotInputErrors: InputErrors = {
  [RobotGeneralFields.Name]: initialError,
  [RobotGeneralFields.Description]: initialError,
  [RobotGeneralFields.Background]: initialError,
  [RobotGeneralFields.Order]: initialError,
};

export const initialDeleteRobot: DeleteRobot = {
  isOpen: false, robot: null
};

export const initialStageInputErrors: InputErrors = {
  [RobotStageFields.Name]: initialError,
  [RobotStageFields.Description]: initialError,
  [RobotStageFields.EnergyPrice]: initialError,
  [RobotStageFields.ExperiencePrice]: initialError,
};

export const initialInputErrors: InputErrors = {
  ...initialRobotInputErrors,
  ...initialStageInputErrors
}; 

export const initialDeleteStage: DeleteStage = {
  isOpen: false,
  stage: null,
};

export const initialAddArtifactItem = {
  isOpen: false,
  type: undefined,
  clothTypeId: undefined,
};

export const initialDeleteArtifact: DeleteArtifact = {
  isOpen: false,
  artifact: null,
};

export const requiredArtifactFields = ["name", "rank", "energyPrice", "image", "iosBundle", "androidBundle", "iosManifest", "androidManifest"];
export const requiredDefaultClothArtifactFields = ["name", "image", "iosBundle", "androidBundle", "iosManifest", "androidManifest"];
export const requiredArtifactAnimationFields = ["name", "animationTypeId", "rank", "energyPrice", "animation"];
export const requiredStageFields = ["name", "description", "energyPrice"];
