import { useReactiveVar } from "@apollo/client";
import { robotsState } from "../state";
import { requiredStageFields, RobotStageFields } from "../constants";

export const useValidateStageFields = () => { 
    const stage = useReactiveVar(robotsState.stageGeneralInfo);
    
    const validateStageFields = () => {
        if (!stage) return false; 

        for (const field of requiredStageFields) {
            if (!stage[field]) {
                robotsState.setInputErrors(field as RobotStageFields, true);
                return false;
            }
        }

        return true;
    };

    return { validateStageFields };
};
