import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { useMutation, useQuery } from "@apollo/client";
import {
  CreateSalaryHistoryDocument,
  CreateSalaryHistoryMutation,
  DeleteSalaryHistoryDocument,
  DeleteSalaryHistoryMutation,
  GetSalaryHistoryDocument,
  GetSalaryHistoryQuery,
  UpdateSalaryHistoryDocument,
  UpdateSalaryHistoryMutation,
  UserSalaryHistory
} from "../../generated/graphql";

import {
  Container,
  Box,
  TableHead,
  Table,
  TableRow,
  CircularProgress,
  TableBody,
  ThemeProvider,
} from "@material-ui/core";
import { Edit, Delete, Save} from '@material-ui/icons';

import { defaultMaterialTheme, Header, ButtonPlus, LoadingBox, ButtonCustomWithDarkText, StyledLink } from "../shared/Style/Style";
import { CustomTable } from "../shared/CustomTable";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import {
  CustomTableCell,
  CustomTableHeaderCell,
  CustomTableInput,
  CustomTableDatePicker,
} from "../shared/CustomTable/CustomTable.style";

import { EditButton, DeleteButton } from "./SalaryHistory.style";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { getMinCreationDateHandler } from "../shared/Utils/DateOperations/getMinCreationDate";

export const SalaryHistory = () => {
  const { userId } = useParams<{ userId: string }>();

  const [isSalaryCreating, setSalaryCreating] = useState(false);
  const [isSalaryEditing, setSalaryEditing] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [chosenSalary, setChosenSalary] = useState('');

  const [salaryData, setSalaryData] = useState({
    newSalary: 0,
    changeDate: new Date(),
    description: '',
  });

  const [errors, setErrors] = useState({
    newSalary: false,
    changeDate: false,
  });


  const todayDate = new Date();
  const today = todayDate.getTime();
  const tomorrow = (new Date()).setDate(todayDate.getDate() + 1);

  const {
    data: salaryHistoryData,
    loading: salaryHistoryLoading,
    error: salaryHistoryError,
  } = useQuery<GetSalaryHistoryQuery>(GetSalaryHistoryDocument, { variables: { userId } })

  const editSalaryHandler = async (salary: UserSalaryHistory) => {
    if (!isSalaryEditing && !isSalaryCreating) {
      await setChosenSalary(salary.id);
      setSalaryEditing(true);
      setSalaryData({
        newSalary: salary.current,
        changeDate: salary.changeDate,
        description: salary.description || '',
      })
    }
  }

  const [
    deleteSalary,
    {
      data: deleteSalaryData,
      loading: deleteSalaryLoading,
      error: deleteSalaryError
    }
  ] = useMutation<DeleteSalaryHistoryMutation>(
    DeleteSalaryHistoryDocument,
    { refetchQueries: [{ query:  GetSalaryHistoryDocument, variables: { userId } }] }
  );

  const [
    createSalary,
    {
      data: createSalaryData,
      loading: createSalaryLoading,
      error: createSalaryError
    }
  ] = useMutation<CreateSalaryHistoryMutation>(
    CreateSalaryHistoryDocument,
    {
      variables: {
        userId,
        newSalary: salaryData.newSalary,
        changeDate: salaryData.changeDate,
        description: salaryData.description,
      },
      refetchQueries: [{ query:  GetSalaryHistoryDocument, variables: { userId } }]
    }
  );

  const [
    updateSalary,
    {
      data: updateSalaryData,
      loading: updateSalaryLoading,
      error: updateSalaryError
    }
  ] = useMutation<UpdateSalaryHistoryMutation>(
    UpdateSalaryHistoryDocument,
    {
      variables: {
        salaryHistoryId: chosenSalary,
        newSalary: salaryData.newSalary,
        changeDate: salaryData.changeDate,
        description: salaryData.description,
      },
      refetchQueries: [{ query:  GetSalaryHistoryDocument, variables: { userId } }]
    }
  );

  const saveSalaryHandler = () => {
    if (!salaryData.newSalary) {
      setErrors(state => ({
        ...state,
        newSalary: true,
      }))
    }

    if (!salaryData.changeDate) {
      setErrors(state => ({
        ...state,
        changeDate: true,
      }))
    }

    if (!salaryData.newSalary || !salaryData.changeDate) {
      return;
    }

    isSalaryCreating ? createSalary() : updateSalary();
  }

  // #region effects

  useEffect(() => {
    if (createSalaryData || updateSalaryData || deleteSalaryData) {
      setSalaryCreating(false);
      setSalaryEditing(false);
      setChosenSalary('');
      setSalaryData({
        newSalary: 0,
        changeDate: new Date(),
        description: '',
      });
    }
  },[createSalaryData, updateSalaryData, deleteSalaryData]);

  useEffect(() => {
    if (createSalaryError || updateSalaryError || deleteSalaryError) {
      setRequestError(true);
    }
  },[createSalaryError, updateSalaryError, deleteSalaryError]);

  useEffect(() => {
    if (createSalaryError) {
      setErrorMessage('При создании истории изменения зарплаты произошла ошибка');
    }
  },[createSalaryError]);

  useEffect(() => {
    if (updateSalaryError) {
      setErrorMessage('При обновлении истории изменения зарплаты произошла ошибка');
    }
  },[updateSalaryError]);

  useEffect(() => {
    if (deleteSalaryError) {
      setErrorMessage('При удалении истории изменения зарплаты произошла ошибка');
    }
  },[deleteSalaryError]);

  useEffect(() => {
    if (salaryHistoryData?.getSalaryHistory?.length) {
      setSalaryData({
        ...salaryData,
        changeDate: tomorrow > (new Date(salaryHistoryData.getSalaryHistory[0].changeDate).getTime())
          ? new Date(tomorrow)
          : new Date(salaryHistoryData.getSalaryHistory[0].changeDate)
      });
    }
  }, [isSalaryCreating]);

  // #endregion

  let content;

  if (salaryHistoryLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit'/>
      </LoadingBox>
    )
  }

  if (salaryHistoryError) {
    content = <ErrorSnackbar error={salaryHistoryError}/>
  }

  if (salaryHistoryData) {
    const tableHeaders = [
      'Дата',
      'Причина изменения',
      'До изменения',
      'После изменения',
      'Разница',
      '',
    ];

    const salaryHistory = salaryHistoryData.getSalaryHistory;

    const minCreationDate = getMinCreationDateHandler(salaryHistory);

    const disableButtonHandler = (salary: UserSalaryHistory): boolean => {
      if (today - 86400000 >= (new Date(salary.createdDate)).getTime()) {
        return true;
      }

      if (isSalaryCreating) {
        return true;
      }

      if (isSalaryEditing && salary.id !== chosenSalary) {
        return true;
      }

      if (salaryHistory.length && salaryHistory[0].id !== salary.id) {
        return true;
      }

      return false;
    }

    content = (
      <>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map(header =>
                <CustomTableHeaderCell key={header}>
                  {header}
                </CustomTableHeaderCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {isSalaryCreating && (
              <TableRow>
                <CustomTableCell padding='none'>
                  <ThemeProvider theme={defaultMaterialTheme}>
                    <CustomTableDatePicker
                      fullWidth
                      error={errors.changeDate}
                      disabled={createSalaryLoading || updateSalaryLoading || deleteSalaryLoading}
                      variant="inline"
                      format="dd.MM.yyyy"
                      helperText={''}
                      minDate={minCreationDate}
                      value={salaryData.changeDate}
                      onChange={(value) => {
                        setErrors({
                          ...errors,
                          changeDate: false,
                        });
                        setSalaryData({ ...salaryData, changeDate: value });
                      }}
                    />
                  </ThemeProvider>
                </CustomTableCell>

                <CustomTableCell
                  style={{ minWidth: '300px' }}
                  padding='none'
                >
                  <CustomTableInput
                    disabled={createSalaryLoading || updateSalaryLoading || deleteSalaryLoading}
                    value={salaryData.description}
                    onChange={({ target }) => {
                      setSalaryData({...salaryData, description: target.value})
                    }}
                  />
                </CustomTableCell>

                <CustomTableCell padding='none'/>

                <CustomTableCell padding='none'>
                  <CustomTableInput
                    type='number'
                    error={errors.newSalary}
                    disabled={createSalaryLoading || updateSalaryLoading || deleteSalaryLoading}
                    value={salaryData.newSalary}
                    onChange={({ target }) => {
                      setErrors({...errors, newSalary: false})
                      setSalaryData({...salaryData, newSalary: +target.value})
                    }}
                  />
                </CustomTableCell>

                <CustomTableCell />

                <CustomTableCell style={{ position: 'relative' }} padding='none'>
                  <EditButton
                    disabled={createSalaryLoading || updateSalaryLoading || deleteSalaryLoading}
                    onClick={() => saveSalaryHandler()}
                  >
                    <Save fontSize='small'/>
                  </EditButton>

                  <DeleteButton
                    disabled={createSalaryLoading || updateSalaryLoading || deleteSalaryLoading}
                    onClick={() => {
                      setSalaryCreating(false);
                      setErrors({
                        newSalary: false,
                        changeDate: false,
                      })
                      setSalaryData({
                        newSalary: 0,
                        changeDate: new Date(),
                        description: '',
                      });
                    }}
                  >
                    <Delete fontSize='small'/>
                  </DeleteButton>
                </CustomTableCell>
              </TableRow>
            )}

            {!!salaryHistory.length
              ? salaryHistory.map(salary => (
                <TableRow key={salary.id}>
                  <CustomTableCell padding='none'>
                    <ThemeProvider theme={defaultMaterialTheme}>
                      <CustomTableDatePicker
                        fullWidth
                        error={errors.changeDate}
                        disabled={chosenSalary !== salary.id}
                        variant="inline"
                        format="dd.MM.yyyy"
                        minDate={new Date(salary.changeDate)}
                        value={chosenSalary !== salary.id ? new Date(salary.changeDate) : salaryData.changeDate}
                        onChange={(value) => {
                          setErrors({
                            ...errors,
                            changeDate: false,
                          });
                          setSalaryData({ ...salaryData, changeDate: value });
                        }}
                      />
                    </ThemeProvider>
                  </CustomTableCell>

                  <CustomTableCell
                    style={{ minWidth: '300px' }}
                    padding='none'
                  >
                    <CustomTableInput
                      disabled={chosenSalary !== salary.id}
                      value={chosenSalary !== salary.id ? salary.description : salaryData.description}
                      onChange={({ target }) => {
                        setSalaryData({...salaryData, description: target.value})
                      }}
                    />
                  </CustomTableCell>

                  <CustomTableCell padding='none'>{salary.previous}</CustomTableCell>

                  <CustomTableCell padding='none'>
                    <CustomTableInput
                      type='number'
                      error={errors.newSalary}
                      disabled={chosenSalary !== salary.id}
                      value={chosenSalary !== salary.id ? salary.current : salaryData.newSalary}
                      onChange={({ target }) => {
                        setErrors({...errors, newSalary: false})
                        setSalaryData({...salaryData, newSalary: +target.value})
                      }}
                    />
                  </CustomTableCell>

                  <CustomTableCell style={{ position: 'relative' }} padding='none'>
                    {salary.difference}
                  </CustomTableCell>

                  <CustomTableCell style={{ position: 'relative' }} padding='none'>
                    <EditButton
                      disabled={disableButtonHandler(salary)}
                      onClick={() => isSalaryEditing ? saveSalaryHandler() : editSalaryHandler(salary)}
                    >
                      {isSalaryEditing
                        ? <Save fontSize='small'/>
                        : <Edit fontSize='small'/>
                      }
                    </EditButton>

                    <DeleteButton
                      disabled={disableButtonHandler(salary)}
                      onClick={() => deleteSalary({ variables: { salaryHistoryId: salary.id } })}
                    >
                      <Delete fontSize='small'/>
                    </DeleteButton>
                  </CustomTableCell>
                </TableRow>
              ))
              : (
                <TableRow>
                  <CustomTableCell colSpan={6}>История зарплат пуста</CustomTableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </>
    )
  }

  return (
    <Container maxWidth="lg" style={{ position: "relative" }}>
      <Header>История иЗМЕНЕНИЙ ЗараБОТНОЙ ПЛАТЫ</Header>

      <Box
        mb={5}
        mt={4}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <ButtonCustomWithDarkText
          disabled={createSalaryLoading || updateSalaryLoading || deleteSalaryLoading || isSalaryEditing}
        >
          <StyledLink
            to={`/user/${userId}/salaryRateHistory`}
          >
            История рейта
          </StyledLink>
        </ButtonCustomWithDarkText>

        <ButtonPlus
          width={180}
          variant="contained"
          disabled={createSalaryLoading || updateSalaryLoading || deleteSalaryLoading || isSalaryEditing}
          onClick={() => setSalaryCreating(true)}
        >
          Добавить
        </ButtonPlus>
      </Box>

      <CustomTable data={salaryHistoryData?.getSalaryHistory} tableContent={content} />

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Container>
  )
}