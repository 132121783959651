import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { lightBlack, secondary } from "../shared/Style/Style";

export const SalaryInputField = styled(TextField)`

  & .MuiInputBase-input {
    text-align: center;
    color: ${lightBlack};
    font-size: 14px;
    padding: 0;
    cursor: pointer;

    &::-webkit-outer-spin-button {
      appearance: none;
    }

    &::-webkit-inner-spin-button {
      appearance: none;
    }

    &:disabled{
      color: ${secondary};
    }
  }

  & .MuiInput-underline {
    &::before,
    &::after {
      content: none;
    }
  }
`;