import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container, MenuItem } from "@material-ui/core";
import { Header, Title } from "../Main/Project/Projects.style";
import {
  FeedbackAvatar,
  FeedbackCommentList,
  FeedbackContent,
  FeedbackContentBox,
  FeedbackControl,
  FeedbackDateBox,
  FeedbackList,
  FeedbackListItem,
  FeedbackStatusSelect,
  FeedbackTextBox,
  FeedbackTitle,
} from "./FeedBack.style";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetFeedbacksDocument,
  GetFeedbacksLightDocument,
  GetFeedbacksQuery,
  UpdateFeedbackDocument,
  UpdateFeedbackMutation,
} from "../../generated/graphql";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { DEFAULT_AVATAR_URL } from "../shared/constants";
import { EmptyCorrectionsListItem } from "../Corrections/Corrections.style";
import { LoadingBox } from "../shared/Style/Style";
import { FeedBackNavBar } from "../FeedBackNavBar";
import FeedBackComments from "./FeedBackComments";
import {ErrorMessageSnackbar} from "../shared/ErrorMessageSnackbar";

export const FeedBack = () => {
  const [activeButton, setActiveButton] = useState(["NEW"]);
  const [showComments, setShowComments] = useState(false);
  const [choseFeedback, setChoseFeedback] = useState("");
  const [feedbackStatus, setFeedbackStatus] = useState("default");
  const [requestError, setRequestError] = useState(false);
  const [messageError, setMessageError] = useState("");
  const { data, loading, error } = useQuery<GetFeedbacksQuery>(
    GetFeedbacksDocument,
    {
      variables: { statuses: activeButton },
      fetchPolicy: 'cache-and-network',
    }
  );

  const [
    updateFeedbackStatus,
    {
      data: updateFeedbackStatusData,
      loading: updateFeedbackStatusLoading,
      error: updateFeedbackStatusError,
    },
  ] = useMutation<UpdateFeedbackMutation>(UpdateFeedbackDocument, {
    refetchQueries: [
      {
        query: GetFeedbacksDocument,
        variables: {statuses: activeButton },
      },
      {
        query: GetFeedbacksLightDocument,
        variables: { statuses: activeButton },
      },
    ],
  });

  const updateFeedbackStatusHandle = async () => {
    if (!choseFeedback || feedbackStatus === "default") {
      return;
    }
    try {
      await updateFeedbackStatus({
        variables: {
          feedbackData: {
            id: choseFeedback,
            status: feedbackStatus,
          },
        },
      });
    } catch (e) {
      setRequestError(true)
      setMessageError("При изменении статуса произошла ошибка")
    }
  };

  useEffect(() => {
    updateFeedbackStatusHandle();
    setFeedbackStatus("default");
  }, [feedbackStatus]);


  const menuItem = [
    { value: "default", name: "Переместить..." },
    { value: "NEW", name: "Новые" },
    { value: "REVIEW", name: "На рассмотрении" },
    { value: "DISCUSSION", name: "На обсуждении" },
    { value: "ACCEPTED", name: "Принято" },
    { value: "DONE", name: "Сделано" },
    { value: "REJECTED", name: "Отклонено" },
  ];


  let content;

  if (error) {
    content = <ErrorSnackbar error={error} />;
  }

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (data) {
    const feedbacks = data.getFeedbacks;
    content = (
      <>
        <FeedbackList>
          {feedbacks?.length ? (
            feedbacks.map((feedbackItem) => (
              <FeedbackListItem
                key={feedbackItem?.id}
                onClick={() => setChoseFeedback(feedbackItem?.id)}
              >
                <FeedbackDateBox>
                  <Box>
                    {feedbackItem?.createdDate
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join(".")}
                  </Box>
                  <Box>
                    {feedbackItem?.createdDate
                      .split("T")[1]
                      .split(":")
                      .slice(0, 2)
                      .join(":")}
                  </Box>
                </FeedbackDateBox>
                <FeedbackContent>
                  <FeedbackAvatar
                    src={
                      feedbackItem?.createdBy?.photo
                        ? feedbackItem?.createdBy?.photo
                        : DEFAULT_AVATAR_URL
                    }
                  />
                  <FeedbackTitle>
                    {feedbackItem?.createdBy?.firstName}
                  </FeedbackTitle>
                  <FeedbackTitle>
                    {feedbackItem?.createdBy?.lastName}
                  </FeedbackTitle>
                </FeedbackContent>
                <FeedbackContentBox>
                  <FeedbackTextBox>
                    <Box>{feedbackItem?.text}</Box>
                    <Box>
                      <FeedbackStatusSelect
                        value={
                          feedbackStatus && feedbackItem?.id === choseFeedback
                            ? feedbackStatus
                            : menuItem[0].value
                        }
                        disabled={updateFeedbackStatusLoading}
                        onChange={({ target: { value } }) => {
                          if (typeof value === "string") {
                            setFeedbackStatus(value);
                          }
                        }}
                      >
                        {menuItem.map(select=>(
                            <MenuItem key={select.value} button value={select.value}>
                              {select.name}
                            </MenuItem>

                        ))

                        }
                      </FeedbackStatusSelect>
                    </Box>
                  </FeedbackTextBox>
                  <Box>
                    <FeedbackControl
                      onClick={() => {
                        setShowComments(!showComments);
                        setChoseFeedback(choseFeedback);
                      }}
                    >
                      Коменнтарии{" "}
                      {feedbackItem?.comments?.length
                        ? `(${feedbackItem?.comments?.length})`
                        : ""}
                    </FeedbackControl>
                    <FeedbackCommentList
                      visible={
                        showComments && feedbackItem?.id === choseFeedback
                      }
                    >
                      <FeedBackComments
                        choseFeedback={choseFeedback}
                        activeButton={activeButton}
                        setChoseFeedback={setChoseFeedback}
                        requestError={requestError}
                        setRequestError={setRequestError}
                        messageError={messageError}
                        setMessageError={setMessageError}
                      />
                    </FeedbackCommentList>
                  </Box>
                </FeedbackContentBox>
              </FeedbackListItem>
            ))
          ) : (
            <EmptyCorrectionsListItem>
              В этой категории сообщения отсутсвуют
            </EmptyCorrectionsListItem>
          )}
        </FeedbackList>
        <ErrorMessageSnackbar
            open={requestError}
            closeHandler={() => setRequestError(false)}
            message={messageError}
        />
      </>
    );
  }
  return (
    <div>
      <Container maxWidth={"lg"}>
        <Header>
          <Title>Обратная связь</Title>
        </Header>
        <FeedBackNavBar
          activeButton={activeButton}
          setActiveButton={setActiveButton}
        />
        {content}
      </Container>

    </div>
  );
};
