import React from "react";
import { AvatarPicker } from "../../../shared/AvatarPicker";
import { IKnowledgeBaseBlockInnerProps } from "../../KnowledgeBaseBlockItem";

export const PhotoBlock = ({
  disabled,
  block,
  uploadPhoto,
}: IKnowledgeBaseBlockInnerProps) => {
  return (
    <>
      <AvatarPicker
        variant="pic"
        border_radius="0%"
        disabled={disabled}
        disableResize={true}
        photo={block.photo}
        uploadHandler={(newPhoto) =>
          uploadPhoto({
            variables: {
              blockId: block.id,
              photo: newPhoto,
            },
          })
        }
      />
    </>
  );
};
