import React from "react";
import {
  Container,
  Title,
  Text,
  SubtitleCenter,
} from "./PrivatePolicy.style";
import { Box } from "@material-ui/core";

export const PaymentPolicyUkr = () => (
  <Container>

    <Title>ПЛАТІЖНА ПОЛІТИКА</Title>

    <Box mt={4} style={{textIndent:"40px"}}>
      <Text>Ця Платіжна Політика (далі — <strong>«Політика»</strong>) є юридично обов’язковим документом і є частиною нашої угоди користувача,
        представляє собою правила збирання, здійснення платежів в інтерактивній системоі ArBook яка включає в себе такі продукти як як ArBook,
        VR_Lab, AR_Teacher та AR_Book Market, всі згадані продукти є частиною інтерактивної системи та підпорядковуються відповідним правилам та політиці. 
        <strong> Товариство з обмеженою відповідальністю «АР БУК ЮА»</strong>, ЄДРПОУ: 44973818, в особі директора Головахінoї Ольги Миколаївни, що діє на підставі
        Статуту розробило цю Політику про нижчевикладене.</Text>
    </Box>

    <Box mt={4} style={{textIndent:"40px"}}>
      <Text style={{ margin: "10px 0 0 0" }}>
        Ця Політика представлена і діє від юридичної особи, інтерактивної системи ArBook, яка надає Користувачам можливість повною мірою працювати з такими продуктами
        системи як AR_Teacher та AR_Book Market.
      </Text>
    </Box>

    <Box mt={4} style={{textIndent:"40px"}}>
      <SubtitleCenter>ЗАГАЛЬНІ ПОЛОЖЕННЯ ВАРТОСТІ ПОСЛУГ</SubtitleCenter>
      <Text style={{ margin: "10px 0 0 0" }}>
        Платформа надає набір функціональних можливостей для створення або вибору готових уроків, використовуючи зрозумілі інструменти,
        та збільшення ефективності навчання, у вигляді послуг, перелік яких зазначений на сайті <a href="https://arbook.info/">https://arbook.info/</a>.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        За доступ до окремих функціональних можливостей Веб-Сайту з Користувача може стягуватись винагорода
        відповідно до угоди користувача. Інформація про вартість
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Послуг (Тарифи) вказана на Веб-Сайті <a href="https://arbook.info/">https://arbook.info/</a> Користувач підтверджує, що ознайомлений
        з Тарифами та спеціальними умовами.
      </Text>
    </Box>

    <Box mt={4} style={{textIndent:"40px"}}>
      <SubtitleCenter>СПОСОБИ ОПЛАТИ</SubtitleCenter>
      <Text style={{ margin: "10px 0 0 0" }}>
        Оплати здійснюються способами, зазначеним на сайті, а саме банківською карткою, через платіжні системи,
        а для корпоративних клієнтів за розрахунковим рахунком на підставі договору.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        При цьому Користувач визнає, що ми можемо змінювати наших партнерів-постачальників платіжних послуг і
        вимагати від них передачі його інформації іншим постачальникам послуг.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Оплата Послуг здійснюється в Особистому кабінеті Користувача. Моментом оплати вважається
        надходження коштів на наш рахунок. Платні послуги можуть не надаватись до моменту оплати.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Користувач самостійно несе відповідальність за правильність здійснюваних ним платежів.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Після підтвердження обраної послуги, відкриється захищене вікно з платіжною сторінкою процесингового центру,
        де Користувачеві необхідно ввести дані його банківської картки. Для додаткової аутентифікації власника картки
        використовується протокол 3D Secure. Якщо банк Користувача підтримує цю технологію, він буде перенаправлений на
        його сервер для додаткової ідентифікації. Інформацію про правила і методи додаткової ідентифікації Користувач
        повинен уточнити в Банку, що видав йому банківську карту.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Процесинговий центр захищає і обробляє дані банківської картки Користувача за стандартом PSI DSS 3.0. Передача інформації в
        платіжний шлюз відбувається із застосуванням технології шифрування SSL. Подальша передача інформації відбувається за закритими
        банківськими мережами, які мають найвищий рівень надійності. Платіжний провайдер не передає дані карти Користувача Платформі або
        іншим третім особам. Для додаткової аутентифікації власника картки використовується протокол 3D Secure.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Надані Користувачем персональні дані (ім’я, адреса, телефон, e-mail, номер кредитної картки) є конфіденційними і не підлягають розголошенню.
        Дані кредитної картки Користувача передаються тільки в зашифрованому вигляді і не зберігаються на WEB-сервері компанії.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Всі операції з платіжними картами відбуваються відповідно до вимог Visa International, MasterCard та інших платіжних систем. При передачі інформації
        використовуються спеціальні технології безпеки карткових онлайн платежів, обробка даних ведеться на безпечному високотехнологічному сервері процесингової компанії.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Всі комісії і збори оплачуються Користувачем самостійно. Платформа має право в будь-який час змінити вартість Послуг шляхом оновлення
        інформації на Веб-Сайті або повідомлення Користувача засобами комунікації, що зазначені на Платформі.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        У разі недостатнього грошового балансу для доступу до будь-якого функціоналу Послуг Платформи, грошові кошти після спливу 18 місяців
        з дати припинення навчання, вважаються благом компанії і поверненню не підлягають. При цьому компанія дає право в індивідуальному порядку
        звернутися через персонального куратора з питання виставлення рахунку на доплату до повної вартості надання послуг.
      </Text>
    </Box>
        
    <Box mt={4} style={{ textIndent: "40px" }}>
      <SubtitleCenter>ПОВЕРНЕННЯ КОШТІВ</SubtitleCenter>
      <Text style={{ margin: "10px 0 0 0" }}>
        В окремих випадках, на вимогу Користувача може бути здійснене повернення грошових коштів за Послуги.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Перерахунок коштів, які підлягають поверненню, відбувається без урахування спеціальних умов за
        максимальним тарифом за фактично надані Послуги. Права на отримання безкоштовних Послуг анулюються.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        У разі наявності у нас підозр щодо зловживання зазначеною можливістю, ми маємо право відмовити в поверненні коштів.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Всі комісії і збори оплачуються Користувачем самостійно.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Користувач може подати запит на повернення коштів і отримати допомогу з інших питань, звернувшись
        до нас за електронною адресою: <a href="mailto:Info@arbook.info">Info@arbook.info</a>. 
      </Text>
    </Box>

    <Box mt={4} style={{ textIndent: "40px" }}>
      <SubtitleCenter>ПЕРЕРАХУНОК ВАРТОСТІ ПОСЛУГ</SubtitleCenter>
      <Text style={{ margin: "10px 0 0 0" }}>
        У разі якщо Користувач вирішить відновити користування після їх тривалого невикористання
        (120 календарних днів з моменту припинення використання), Платформа має право перерахувати ціну
        занять відповідно до вартості, що діє на момент такого відновлення або перерахувати після 540
        календарних днів з дня покупки таких послуг.
      </Text>
    </Box>

    <Box mt={4} style={{ textIndent: "40px" }}>
      <SubtitleCenter>ДОСТАВКА</SubtitleCenter>
      <Text style={{ margin: "10px 0 0 0" }}>
        Платформа  надає послуги і доступ до цифрових продуктів, створені на базі інтерактивної 
        системи ArBook, а також сторонні послуги які використовє відповідно до закону у своїй комерційній діяльності.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Надання Послуг здійснюється за допомогою Інтернету, у тому числі з використанням програмного забезпечення,
        що забезпечує можливість комунікації та передачі контенту в мережі Інтернет між Користувачем і Платформою.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        У зв’язку з цим доставка у вигляді доступу до цифрового контенту і навчальних послуг здійснюється в залежності
        від обраної послуги/продукту відразу після реєстрації в особистому кабінеті та/або здійснення оплати.
      </Text>
    </Box>
    
    <Box mt={4} style={{ textIndent: "40px" }}>
      <SubtitleCenter>КОНТРОЛЬ БЕЗПЕКИ ПЕРЕДАЧІ ПЛАТІЖНИХ ДАНИХ</SubtitleCenter>
      <Text style={{ margin: "10px 0 0 0" }}>
        Для забезпечення надійності здійснення платежів використовується криптографічний протокол Secure
        Sockets Layer (SSL-сертифікат). Ця технологія використовує асиметричну криптографію для аутентифікації
        ключів обміну, симетричне шифрування для збереження конфіденційності, коди аутентифікації повідомлень для їх цілісності,
        що дозволяє більш надійно обробляти ваші платежі.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Платформа не обробляє персональні дані платників, надані в зв’язку з проведенням процесинговим центром розрахунків за платежами.
        Платформа не зберігає реквізити банківських карт на своїх ресурсах, у тому числі серверах, хмарних сховищах тощо.
        Зазначені дані обробляються і надійно зберігаються відповідними платіжними операторами, радимо більш детально ознайомитись з
        політикою конфіденційності таких платіжних систем.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        При здійсненні оплат через платіжні сервіси наша платформа дотримується правил міжнародних платіжних систем VISA і MasterCard,
        логотипи яких розміщені на нашому сайті.
      </Text>
    </Box>

    <Box mt={4} style={{ textIndent: "40px" }}>
      <SubtitleCenter>ВІДОБРАЖЕННЯ УМОВ ПОКУПКИ ДЛЯ ВЛАСНИКА КАРТКИ В ПРОЦЕСІ ЗАМОВЛЕННЯ</SubtitleCenter>
      <Text style={{ margin: "10px 0 0 0" }}>
        У розділі «ЦІНИ», при виборі необхідного тарифу Користувачу запропоновано обрати необхідні умови тарифу та натиснути на кнопку «КУПИТИ», після
        чого з’являється  вікно з платіжною сторінкою, де Користувач заповнює необхідні графи реквізитами своєї банківської карти.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Після завершення оформлення замовлення на платформі Користувач повинен вказати адресу своєї електронної пошти та натиснути кнопку «Оплатити».
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Корпоративні Користувачі, що уклали договір з платформою, здійснюють оплату на підставі такого договору на розрахунковий рахунок
        платформи, що буде вказаний у відповідному договорі.
      </Text>
    </Box>
    
    <Box mt={4} style={{ textIndent: "40px" }}>
      <SubtitleCenter>ІНШІ УМОВИ</SubtitleCenter>
      <Text style={{ margin: "10px 0 0 0" }}>
        Наша Платформа  не є банком або будь-якою фінансовою установою, ми не надаємо жодних фінансових послуг.
        Будь-які платежі, що здійснюються через нашу Платформу, проводяться виключно з метою отримання і надання навчальних послуг.
        Такі платежі не можуть проводитися і використовується для будь-якої шахрайської, злочинної або незаконної діяльності.
      </Text>
      <Text style={{ margin: "10px 0 0 0" }}>
        Порядок та умови оплати визначені цією політикою за повний доступ до функціоналу AR_Teacher та AR_Book Market є тотожними.
      </Text>
    </Box>

    <Box mt={4} mb={4} style={{ textIndent: "40px" }}>
      <SubtitleCenter>КОНТАКТИ З ВІДДІЛОМ ОБСЛУГОВУВАННЯ КЛІЄНТІВ</SubtitleCenter>
      <Text style={{ margin: "10px 0 0 0" }}>
        Ми сподіваємось, що ця Платіжна Політика відповіла на більшість питань щодо здійснення платежів на нашому веб-сайті,
        також ми сподіваємось, що ви нам довіряєте. Але якщо ви все ще відчуваєте, що у вас лишились нез’ясовані моменти щодо платежів,
        зв’яжіться з нами. Електронна адреса: <a href="mailto:sales@arbook.info">sales@arbook.info</a>
      </Text>
    </Box>
    
  </Container>
);
