import styled from "styled-components";
import { MainTableRow, primary } from "../shared/Style/Style";
import { Button } from "@material-ui/core";

export const MobileAppModelsTableRow = styled( MainTableRow )`
  grid-template-columns: 1fr 1.5fr 3fr 1.5fr 1.4fr 0.4fr;
  grid-gap: 10px;
  cursor: pointer;
`;

export const EmptyMobileAppModelsTableRow = styled( MobileAppModelsTableRow )`
  grid-template-columns: 1fr;
`;

export const InformationControl = styled( Button )`
  color: ${primary};
  cursor: pointer;
  text-decoration: underline;
`;
