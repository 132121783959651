import React, { createRef, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  AppBarStyle,
  BaseContainer,
  BlockTitle,
  LoginForm,
  RegistrationWrapper,
} from "../Login.style";
import { Toolbar } from "@material-ui/core";
import { Form } from "react-final-form";
import { PasswordInfo } from "../../Registration/PasswordInfo";
import { useMutation } from "@apollo/client";
import { SnackMessage } from "../../shared/Modal/SnackMessage";
import Cookies from "js-cookie";
import {
  IsFromEnum,
  ResetPasswordDocument,
  ResetPasswordMutation,
} from "../../../generated/graphql";
import { InitialFormError } from "../../Registration/Registration.type";
import { COOKIE_NAME_TOKEN } from '../../../constants/cookies';

export const initialFormError: InitialFormError = {
  passwordError: "",
  confirmPasswordError: "",
};

export const Reset = () => {
  const { token } = useParams() as any;
  const [successReset, setSuccessReset] = useState(false);
  const [formError, setFormError] = useState(initialFormError);

  const [reset, { data: resetData, loading: resetLoading }] =
    useMutation<ResetPasswordMutation>(ResetPasswordDocument);

  const swipableViews = createRef<any>();

  useEffect(() => {
    const success = setTimeout(() => setSuccessReset(false), 5000);
    return () => clearTimeout(success);
  }, [successReset]);

  if (resetData?.resetPassword?.token) {
    Cookies.set(COOKIE_NAME_TOKEN, resetData.resetPassword.token, { expires: 1 });
    return <Redirect to="/" />;
  }

  return (
    <LoginForm>
      <AppBarStyle position="fixed">
        <Toolbar />
      </AppBarStyle>
      <RegistrationWrapper>
        <Form onSubmit={() => {}}>
          {() => {
            return (
              <>
                <BlockTitle>Изменение пароля</BlockTitle>
                <BaseContainer minHeight={150}>
                  <PasswordInfo
                    title={"Восстановить"}
                    loading={resetLoading}
                    onError={() => {
                      if (swipableViews.current) {
                        swipableViews.current.updateHeight();
                      }
                    }}
                    onNext={async (nextData) => {
                      const { confirmPassword, ...password } = nextData;
                      await reset({
                        variables: {
                          ...password,
                          token,
                          isFrom: IsFromEnum.AdminPanel,
                        },
                      });
                    }}
                    formError={formError}
                    setFormError={setFormError}
                  />
                </BaseContainer>
              </>
            );
          }}
        </Form>
      </RegistrationWrapper>
      {resetData && (
        <SnackMessage message={`Пароль успешно изменен`} type={"success"} />
      )}
    </LoginForm>
  );
};
