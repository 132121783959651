import { RobotsHistory } from "./RobotsHistory";
import { AwardsPage } from "./Awards";
import { Economics } from "./Economics";
import { RobotsPage } from "./Robots";
import { awardsPageLink } from "./Awards/constants";
import { robotModelPageLink, robotsPageLink, robotStagePageLink } from "./Robots/constants";
import { RobotModelPage, RobotStagePage } from "./Robots/pages";

export const robotsRoutes = [
  {
    path: `/mobileApps/:appId/${awardsPageLink}`,
    component: AwardsPage,
    exact: true,
  },
  {
    path: `/mobileApps/:appId/${RobotsHistory.link}`,
    component: RobotsHistory,
    exact: true,
  },
  {
    path: `/mobileApps/:appId/${robotsPageLink}`,
    component: RobotsPage,
    exact: true,
  },
  {
    path: `/mobileApps/:appId/${robotsPageLink}/:robotId/${robotModelPageLink}`,
    component: RobotModelPage,
    exact: true,
  },
  {
    path: `/mobileApps/:appId/${robotsPageLink}/:robotId/${robotModelPageLink}/:stageId/${robotStagePageLink}`,
    component: RobotStagePage,
    exact: true,
  },
  {
    path: `/mobileApps/:appId/${Economics.link}`,
    component: Economics,
    exact: true,
  },
] as const;
