import { Box, Paper } from "@material-ui/core";
import React from "react";

export const Container = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <Paper elevation={3}>
      <Box p="18px 32px 32px" mt="40px">
        {children}
      </Box>
    </Paper>
  );
};
