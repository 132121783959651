export const nullifyFalseValuesHandler = (incomingObj: {[key: string]: any}): {[key: string]: any} => {
  const incomingArray = Object.entries(incomingObj);

  const result: {[key: string]: any} = {};

  incomingArray.forEach(([key, value]) => {
    if (!value && typeof value !== "boolean") {
      result[key] = null;
      return;
    }

    result[key] = value;
  })

  return result;
}