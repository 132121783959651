import React, { useMemo, useState } from "react";

import { useQuery } from "@apollo/client";
import { GetCompletionPctReportDocument, GetCompletionPctReportQuery, GetOldestTaskDateDocument, GetOldestTaskDateQuery } from "../../../../generated/graphql";

import { TableHead, Table, TableBody, TableRow, Box, CircularProgress } from "@material-ui/core";

import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { ReportModal } from "../../../ReportModal";
import { ArrowButton } from "../../../shared/Buttons/ArrowButton";
import { CustomTable } from "../../../shared/CustomTable";

import { ButtonCustomWithDarkText, LoadingBox, primary } from "../../../shared/Style/Style";

import { CustomTableRow, CustomTableCell, CustomTableHeaderCell } from "../../../shared/CustomTable/CustomTable.style";
import { ArrowBox } from "../../Reports.style";

import { getMonthsDatesRanges } from "../../../shared/Utils/DateOperations/getMonthsDatesRanges";
import { getWeekDatesRanges } from "../../../shared/Utils/DateOperations/getWeekDatesRanges";
import { stringifyDateHandler } from "../../../shared/Utils/DateOperations/stringifyDateHandler";
import { convertDateStringToLocaleHandler } from "../../../shared/Utils/ConvertOperations/convertDateStringToLocaleHandler";
import { decreaseMonth, increaseMonth } from "../../../shared/Utils/DateOperations/changeMonth";
import { increaseWeek, decreaseWeek } from "../../../shared/Utils/DateOperations/changeWeek";
import { currentDateCheckHandler } from "../../../shared/Utils/CheckOperations/currentDateCheckHandler";
import { convertISOStringToDateWithOffset } from "../../../shared/Utils/ConvertOperations/convertISOStringToDateWithOffset";


const ranges: {[key: string]: (maxDate?: Date) => string[] } = {
  week: getWeekDatesRanges,
  month: getMonthsDatesRanges
}


export const CompletionPercentReport = () => {
  const [openModal, setOpenModal] = useState(false);
  const [chosenProject, setChosenProject] = useState('');
  const [chosenRange, setChosenRange] = useState('month');
  const [maxDate, setMaxDate] = useState<Date | undefined>(undefined);

  console.log(maxDate);

  const dates = useMemo(() => {
    return maxDate
      ? ranges[chosenRange](maxDate)
      : ranges[chosenRange]();
  }, [maxDate, chosenRange]);

  const { data, loading, error } = useQuery<GetCompletionPctReportQuery>(
    GetCompletionPctReportDocument,
    {
      variables: {
        dates: maxDate ? ranges[chosenRange](maxDate) : ranges[chosenRange](),
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const {
    data: dateData,
    loading: dateLoading,
    error: dateError
  } = useQuery<GetOldestTaskDateQuery>(GetOldestTaskDateDocument);

  let content;

  if (loading || dateLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit' />
      </LoadingBox>
    )
  }

  if (error || dateError) {
    content = <ErrorSnackbar error={error || dateError} />
  }

  if (data && dateData) {
    const projects = data.getCompletionPctReport;

    const lastTaskDate = convertISOStringToDateWithOffset(dateData.getOldestTaskDate);

    content = (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableHeaderCell>Название проекта</CustomTableHeaderCell>
              {dates.map((date, i) => (
                <CustomTableHeaderCell
                  colSpan={2}
                  key={date}
                >
                  {chosenRange === 'month'
                    ? stringifyDateHandler(date)
                    : convertDateStringToLocaleHandler(date)
                  }

                  {i === 0 && (
                    <ArrowBox
                      variant='left'
                    >
                      <ArrowButton
                        callback={chosenRange === 'month'
                          ? () => setMaxDate(decreaseMonth(maxDate ? maxDate : undefined))
                          : () => setMaxDate(decreaseWeek(maxDate ? maxDate : undefined))
                        }
                        variant='left'
                      />
                    </ArrowBox>

                  )}

                  {i === dates.length - 1 && (
                    <ArrowBox>
                      <ArrowButton
                        callback={chosenRange === 'month'
                          ? () => setMaxDate(increaseMonth(maxDate ? maxDate : undefined, lastTaskDate))
                          : () => setMaxDate(increaseWeek(maxDate ? maxDate : undefined, lastTaskDate))
                        }
                      />
                    </ArrowBox>
                  )}
                </CustomTableHeaderCell>
              ))}
            </TableRow>

            <TableRow>
              <CustomTableCell />
              {dates.map((_, i) => (
                <>
                  <CustomTableHeaderCell
                    width={80}
                  >
                    План
                  </CustomTableHeaderCell>
                  <CustomTableHeaderCell
                    width={80}
                    style={{ backgroundColor: ((
                      i === dates.length - 1
                      && currentDateCheckHandler(new Date(dates[dates.length - 1]), chosenRange)
                    ))
                      ? primary
                      : '#000'
                    }}
                  >
                    {(i === dates.length - 1
                      && currentDateCheckHandler(new Date(dates[dates.length - 1]), chosenRange)
                    )
                      ? 'Сейчас'
                      : 'Факт'
                    }
                  </CustomTableHeaderCell>
                </>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {projects.map(project => (
              <CustomTableRow
                key={project.projectId}
                clickable={true}
                onClick={() => {
                  setChosenProject(project.projectId);
                  setOpenModal(true);
                }}
              >
                <CustomTableCell>{project.projectName}</CustomTableCell>

                {project.prc.map((prc, i) => (
                  <React.Fragment key={`${i} ${prc.plan} ${prc.fact}`}>
                    <CustomTableCell>{prc.plan || 0}%</CustomTableCell>
                    <CustomTableCell>{prc.fact || 0}%</CustomTableCell>
                  </React.Fragment>
                ))}
              </CustomTableRow>
            ))}
          </TableBody>
        </Table>
      </>
    )
  }

  return (
    <>
      <Box display='flex' mt={2} mb={2}>
        <Box mr={2}>
          <ButtonCustomWithDarkText
            onClick={() => {
              setMaxDate(undefined);
              setChosenRange('week');
            }}
            active={chosenRange === 'week'}
          >
            Неделя
          </ButtonCustomWithDarkText>
        </Box>

        <ButtonCustomWithDarkText
          onClick={() => {
            setMaxDate(undefined);
            setChosenRange('month');
          }}
          active={chosenRange === 'month'}
        >
          Месяц
        </ButtonCustomWithDarkText>
      </Box>

      <CustomTable data={[]} tableContent={content}/>

      {openModal && chosenProject && (
        <ReportModal
          title={data?.getCompletionPctReport?.find(project => project.projectId === chosenProject)?.projectName}
          reportType='completionPercent'
          open={openModal}
          closeHandler={() => {
            setOpenModal(false);
            setChosenProject('');
          }}
          projectId={chosenProject}
        />
      )}
    </>
  )
}