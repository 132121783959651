import styled from 'styled-components';
import { TableRow } from '@material-ui/core';
import { lightBlack } from '../shared/Style/Style';

interface ITableRowCustomProps {
  columns: number;
}

export const PeopleTableNoUsersRow = styled(TableRow)`
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px 15px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

export const PeopleTableHeadRow = styled(TableRow)<ITableRowCustomProps>`
  display: grid;
  grid-template-columns:${props => `repeat(${props.columns}, 1fr) 48px`};
  padding: 20px 15px;
  margin: 20px 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

export const PeopleTableHeaderTitle = styled.div`
  color: black;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;

export const PeopleTableContentRow = styled(TableRow)<ITableRowCustomProps>`
  display: grid;
  max-width: calc(100% - 2px);
  margin: 0 auto 20px;
  grid-template-columns:${props => `repeat(${props.columns}, 1fr) 48px`};
  padding: 20px 15px;
  color: ${lightBlack};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  cursor: pointer;
`;

export const PeopleTableContentCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  line-height: 17px;
`;

export const PeopleTableRowsWrapper = styled.div`
`;

// export const TableFooterCommon = styled.div`
//   padding: 0 14px;
//   background-color: white;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//   display: flex;
//   justify-content: flex-end;
// `;
