import styled from "styled-components";
import { MainTableCell, MainTableRow } from "../../shared/Style/Style";

export const AchievementsTableRow = styled(MainTableRow)`
  grid-template-columns: 70px 400px 1.5fr 1fr 1fr 0.3fr;
  grid-gap: 14px;
  cursor: pointer;
`;

export const WithoutAchievementsTableRow = styled(MainTableRow)`
  grid-template-columns: 12fr;
  grid-gap: 10px;
  cursor: pointer;
`;

export const AchievementsTableCell = styled(MainTableCell)`
  font-size: 14px;
  line-height: 1.2;

  p {
    margin: 0;
  }
`;

export const AchievementsTableHeadCell = styled(AchievementsTableCell)`
  font-weight: bold;
`;
