import React, { useState } from "react";
import {
  Box,
  Collapse,
  Dialog,
  DialogContent,
  SvgIcon,
} from "@material-ui/core";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
  TextPhotoName,
} from "../../shared/Style/Style";
import {
  SmartNotification,
  SmartNotificationTypeEnum,
} from "../../../generated/graphql";
import { CustomTextField, TextArea } from "./Style";
import { CustomIconButton } from "../../shared/Buttons/CustomIconButton";
import { useDropzone } from "react-dropzone";
import { DeleteIconButton } from "../../shared/Buttons/DeleteIconButton";
import { ModalPreviewPhoto } from "../../shared/Modal/ModalPreviewPhoto";
import { ChooseVariantAnswers } from "./ChooseVariantAnswers/ChooseVariantAnswers";
import { CheckRudioButton } from "../../SmartNotification/SmartNotification.style";
import { AddParcipiantClientModal } from "./AddParcipiantClientModal.";
import { AddClientsToMobileNotificationModal } from "../../ParticipantsModal/AddClientsToMobileNotificationModal";

const PlusIcon = () => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18">
    <path
      d="M14 10H10V14H8V10H4V8H8V4H10V8H14V10ZM16 0H2C0.89 0 0 0.89 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 0.89 17.1 0 16 0Z"
      fill="#FFB61D"
    />
  </SvgIcon>
);

const AddPhotoIcon = () => (
  <SvgIcon width="15" height="16" viewBox="-2 -2 18 19" fill="none">
    <path
      d="M7.17619 13.7144H1.57883L6.31567 9.14298L7.79988 10.5754C8.28935 9.82869 8.9683 9.21917 9.78935 8.83822L11.0525 7.61917V8.44965C11.313 8.41155 11.5736 8.38107 11.842 8.38107C12.1104 8.38107 12.3709 8.41155 12.6315 8.44965V4.57155L7.89462 0.00012207H1.57883C0.70251 0.00012207 -0.00012207 0.678217 -0.00012207 1.52393V13.7144C-0.00012207 14.5525 0.70251 15.2382 1.57883 15.2382H7.74462C7.4683 14.7735 7.27093 14.263 7.17619 13.7144ZM7.10514 1.14298L11.4472 5.33346H7.10514V1.14298ZM3.15777 5.33346C4.03409 5.33346 4.73672 6.01917 4.73672 6.85726C4.73672 7.69536 4.03409 8.38107 3.15777 8.38107C2.28146 8.38107 1.57883 7.70298 1.57883 6.85726C1.57883 6.01155 2.28935 5.33346 3.15777 5.33346ZM12.6315 9.90488V12.1906H14.9999V13.7144H12.6315V16.0001H11.0525V13.7144H8.68409V12.1906H11.0525V9.90488H12.6315Z"
      fill="#FFA425"
    />
  </SvgIcon>
);

const AddVideoIcon = () => {
  return (
    <SvgIcon width="15" height="16" viewBox="0 0 36 27">
      <path
        d="M35.6355 0.000159639L0.000906888 0.000291133C1.32074 -0.000324115 1.31983 0.00021512 0.000906888 0.000291133L0 23.9219V26.3967C1.31983 26.3967 1.31983 26.3967 0 26.3967H35.6355L35.6355 23.922V0.000159639ZM2.96965 2.96959H32.666V23.427H2.96965V2.96959Z"
        fill="#FFA425"
      />
      <path
        d="M23 13.1383L13 7C13.0006 8.6628 13.0006 7.74359 13.0006 8.6628V20C14.7497 18.8279 14.0021 19.3379 14.7497 18.8279L23 13.1383ZM15.2506 15.7197V10.8134L18.8475 13.2665L15.2506 15.7197Z"
        fill="#FFA425"
      />
    </SvgIcon>
  );
};

interface MobileAppModalSMartNotificationProps {
  notificationId: string;
  type: SmartNotificationTypeEnum;
  isOpen: boolean;
  disable?: boolean;
  modalType: string;
  close: () => void;
  handleFetch: (
    description?: string,
    photo?: File,
    video?: File,
    link?: string
  ) => void;
  smartNotification?: SmartNotification;
}

export const MobileAppModalSMartNotification = ({
  notificationId,
  type,
  isOpen,
  close,
  handleFetch,
  smartNotification,
  disable,
  modalType,
}: MobileAppModalSMartNotificationProps) => {
  const [description, setDescription] = useState(
    smartNotification?.description || ""
  );
  const [openPreviewPhoto, setOpenPreviewPhoto] = useState(false);
  const [photoFile, setPhotoFile] = useState<File | null>();
  const [photoName, setPhotoName] = useState<string>(
    smartNotification?.photoName || ""
  );

  const [photoUrl, setPhotoUrl] = useState<string | ArrayBuffer | null>(
    smartNotification?.photo || null
  );
  const [errorDescription, setErrorDescription] = useState(false);
  const [link, setLink] = useState(smartNotification?.link || "");
  const [checkFile, setCheckFile] = useState("Image");
  const [videoFile, setVideoFile] = useState<File | null>();
  const [isOpenClientsParcipiant, setIsOpenClientsParcipiant] = useState(false);

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    noClick: true,
  });

  const handleDrop = (drop: File) => {
    setPhotoName(drop.name);
    setPhotoFile(drop);

    const reader = new FileReader();

    reader.onload = () => {
      setPhotoUrl(reader.result);
    };

    reader.readAsDataURL(drop);
  };

  const deletePhotoUrlHandle = () => {
    setPhotoName("");
    setPhotoUrl(null);
    setVideoFile(null);
  };

  const handlerClose = () => {
    setPhotoFile(null);
    setPhotoName("");
    setDescription("");
    setLink("");
    setPhotoUrl(null);
    setVideoFile(null);
    close();
  };

  const handleChangeNotification = async () => {
    if (!description) {
      setErrorDescription(true);
      return;
    }

    await handleFetch(description, photoFile, videoFile, link);
    handlerClose();
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent
        style={{ minWidth: "400px", padding: "50px 40px 60px 40px" }}
      >
        <CloseIconButton close={handlerClose} />

        <Box mb="40px">
          <TextArea
            fullWidth
            multiline={true}
            placeholder="Введите текст уведомления..."
            variant="filled"
            value={description}
            error={errorDescription}
            onChange={({ target: { value } }) => setDescription(value)}
            onFocus={() => setErrorDescription(false)}
            InputProps={{
              endAdornment:
                type === SmartNotificationTypeEnum.Poll &&
                modalType === "update" ? (
                  <Box display="flex" justifyContent="flex-end">
                    {!!photoUrl ? (
                      <Collapse in={!!photoUrl}>
                        <Box display="flex" alignItems="center">
                          <TextPhotoName
                            onClick={() => setOpenPreviewPhoto(true)}
                          >
                            {photoName}
                          </TextPhotoName>
                          <DeleteIconButton
                            deleteHandler={deletePhotoUrlHandle}
                            item="фото"
                          />
                        </Box>
                      </Collapse>
                    ) : (
                      ""
                    )}
                    <CustomIconButton
                      {...getRootProps()}
                      callback={() => open()}
                    >
                      <AddPhotoIcon />
                      <input
                        type="file"
                        {...getInputProps()}
                        onChange={({ target: { files } }) =>
                          handleDrop(files[0])
                        }
                      />
                    </CustomIconButton>
                  </Box>
                ) : (
                  type === SmartNotificationTypeEnum.Link &&
                  modalType === "update" && (
                    <Box display="flex" justifyContent="flex-end">
                      {!!photoUrl ? (
                        <Collapse in={!!photoUrl}>
                          <Box display="flex" alignItems="center">
                            <TextPhotoName
                              onClick={() => setOpenPreviewPhoto(true)}
                            >
                              {photoName}
                            </TextPhotoName>
                            <DeleteIconButton
                              deleteHandler={deletePhotoUrlHandle}
                              item="фото"
                            />
                          </Box>
                        </Collapse>
                      ) : (
                        <Collapse in={!!videoFile}>
                          <Box display="flex" alignItems="center">
                            {videoFile?.name}
                            <DeleteIconButton
                              deleteHandler={deletePhotoUrlHandle}
                              item="фото"
                            />
                          </Box>
                        </Collapse>
                      )}
                      <CustomIconButton
                        {...getRootProps()}
                        callback={() => open()}
                      >
                        {checkFile === "Image" ? (
                          <>
                            <AddPhotoIcon />
                            <input
                              type="file"
                              accept="video/*"
                              {...getInputProps()}
                              onChange={({ target: { files } }) =>
                                handleDrop(files[0])
                              }
                            />
                          </>
                        ) : (
                          <>
                            <AddVideoIcon />
                            <input
                              type="file"
                              {...getInputProps()}
                              onChange={({ target: { files } }) =>
                                setVideoFile(files[0])
                              }
                            />
                          </>
                        )}
                      </CustomIconButton>
                    </Box>
                  )
                ),
            }}
          />
          {type === SmartNotificationTypeEnum.Link && modalType === "update" && (
            <Box display="flex" flexDirection="column" mt={2}>
              <Box display="flex" alignItems="center">
                <CheckRudioButton
                  checked={checkFile === "Image"}
                  onChange={({ target: { value } }) => setCheckFile(value)}
                  value={"Image"}
                  name="radio-buttons"
                />
                <Box>Картинка</Box>
                <CheckRudioButton
                  checked={checkFile === "Video"}
                  onChange={({ target: { value } }) => setCheckFile(value)}
                  value="Video"
                  name="radio-buttons"
                />
                <Box>Видео</Box>
              </Box>
              <Box mt={2}>
                <CustomTextField
                  fullWidth
                  placeholder="Введите ссылку"
                  variant="standard"
                  value={link}
                  onChange={({ target: { value } }) => setLink(value)}
                />
              </Box>
            </Box>
          )}
        </Box>

        {type === SmartNotificationTypeEnum.Poll && modalType === "update" && (
          <ChooseVariantAnswers notificationId={notificationId} />
        )}
        {notificationId && modalType === "update" && (
          <Box textAlign="center" margin={3}>
            <ButtonCustom
              disabled={false}
              onClick={() => {
                setIsOpenClientsParcipiant(true);
              }}
            >
              Добавить участников
            </ButtonCustom>
          </Box>
        )}

        <Box>
          <ButtonsWrapper>
            <CancelButton onClick={handlerClose}>Отменить</CancelButton>
            <ButtonCustom onClick={handleChangeNotification} disabled={disable}>
              Сохранить
            </ButtonCustom>
          </ButtonsWrapper>
        </Box>
      </DialogContent>

      <ModalPreviewPhoto
        isOpen={openPreviewPhoto}
        close={setOpenPreviewPhoto}
        imageUrl={photoUrl}
      />
      <AddParcipiantClientModal
        open={isOpenClientsParcipiant}
        close={() => setIsOpenClientsParcipiant(false)}
        notificationId={notificationId}
      />
    </Dialog>
  );
};
