import styled from "styled-components";
import { Button } from "@material-ui/core";

export const IconButton = styled(Button)<{ $iconSize?: number }>`
  &.MuiButtonBase-root {
    cursor: pointer;
    padding: 0;
    min-width: 0;

    svg {
      opacity: 0.85;
      font-size: ${({ $iconSize = 12 }) => `${$iconSize}px`}
    }
  }
`;
