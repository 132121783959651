import {
  RobotsHistory,
  useDeleteRobotsHistoryMutation,
} from "../../../../generated/graphql";
import { Box } from "@material-ui/core";
import { CloseRounded as CloseRoundedIcon } from "@material-ui/icons";
import { refetchMessage } from "./constants";

interface HistoryLinkProps {
  item: RobotsHistory;
  handleRefetch: (message: string) => void;
}
export const HistoryLink = ({ item, handleRefetch }: HistoryLinkProps) => {
  const { id, link } = item;

  const [deleteRobotsHistoryMutation] = useDeleteRobotsHistoryMutation({
    variables: {
      deleteRobotsHistoryId: id,
    },
  });

  const handleDelete = async () => {
    await deleteRobotsHistoryMutation();
    handleRefetch(refetchMessage.delete);
  };

  return (
    <Box display="flex" alignItems="center">
      <Box
        color="rgba(7, 68, 187, 1)"
        textOverflow="ellipsis"
        overflow="hidden"
        maxWidth={220}
        whiteSpace="nowrap"
      >
        <a href={link} target="_blank">
          {link}
        </a>
      </Box>
      <CloseRoundedIcon
        color="error"
        onClick={handleDelete}
        style={{ cursor: "pointer" }}
      />
    </Box>
  );
};
