import React, { useState, useEffect } from "react";

import { useMutation, useQuery } from "@apollo/client";
import {
  CreateProjectTypeDocument,
  CreateProjectTypeMutation,
  DeleteUserJobDocument,
  DeleteUserJobMutation,
  GetProjectTypesDocument,
  GetProjectTypesQuery,
  GetUserJobsDocument,
  UpdateProjectTypeDocument,
  UpdateProjectTypeMutation,
} from "../../../../generated/graphql";

import {  CircularProgress, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { LoadingBox } from "../../../shared/Style/Style";
import { SettingsList } from "../SettingsList";
import { SettingsCreateItemForm } from "../SettingsCreateItemForm";

import { Content } from "../Settings.style";

export const ProjectType = () => {
  const [inputError, setInputError] = useState(false);
  const [requestError, setRequestError] = useState(false);

  const [isCreating, setCreating] = useState(false);
  const [isEditing, setEditing] = useState({
    itemId: '',
    status: false,
  })

  const {
    data: ProjectTypesData,
    loading: ProjectTypesLoading,
    error: ProjectTypesError,
  } = useQuery<GetProjectTypesQuery>(GetProjectTypesDocument);

  const [
    createProjectType,
    {
      data: createProjectTypeData,
      loading: createProjectTypeLoading,
      error: createProjectTypeError,
    }
  ] = useMutation<CreateProjectTypeMutation>(
    CreateProjectTypeDocument,
    { refetchQueries: [{query: GetProjectTypesDocument}] }
  );

  const createHandler = (name: string): void => {
    if (!name) {
      setInputError(true);
      return;
    }

    createProjectType({ variables: {name} })
  }

  const [
    updateProjectType,
    {
      data: updateProjectTypeData,
      loading: updateProjectTypeLoading,
      error: updateProjectTypeError,
    }
  ] = useMutation<UpdateProjectTypeMutation>(
    UpdateProjectTypeDocument,
    { refetchQueries: [{query: GetProjectTypesDocument}] }
  );

  const updateHandler = (id: string, name: string): void => {
    if(!id || !name) {
      setInputError(true);
      return;
    }

    updateProjectType({ variables: {typeId: id, name} })
  }

  const [
    deleteProjectType,
    {
      data: deleteProjectTypeData,
      loading: deleteProjectTypeLoading,
      error: deleteProjectTypeError,
    }
  ] = useMutation<DeleteUserJobMutation>(
    DeleteUserJobDocument,
    { refetchQueries: [{query: GetUserJobsDocument}] }
  );

  const deleteHandler = (id: string): void => {
    if(!id) {
      setInputError(true);
      return;
    }
    deleteProjectType({ variables: {typeId: id} })
  }

  useEffect(() => {
    if(createProjectTypeError || updateProjectTypeError || deleteProjectTypeError) {
      setRequestError(true);
    }
  }, [createProjectTypeError, updateProjectTypeError, deleteProjectTypeError]);

  useEffect(() => {
    if(deleteProjectTypeData || updateProjectTypeData || createProjectTypeData) {
      setRequestError(false);
    }
  }, [deleteProjectTypeData, updateProjectTypeData, createProjectTypeData]);

  useEffect(() => {
    if(updateProjectTypeData) {
      setEditing({
        itemId: '',
        status: false,
      });
    }

    if(createProjectTypeData) {
      setCreating(false);
    }
  }, [updateProjectTypeData, createProjectTypeData]);

  const areEditingButtonsDisabled = createProjectTypeLoading || updateProjectTypeLoading || deleteProjectTypeLoading || isCreating;
  const areCreatingButtonsDisabled = createProjectTypeLoading || updateProjectTypeLoading || deleteProjectTypeLoading || isEditing.status;

  let content;

  if (ProjectTypesLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    )
  }

  if (ProjectTypesError) {
    content = <ErrorSnackbar error={ProjectTypesError}/>
  }

  if (ProjectTypesData) {
    const userProjectTypes = ProjectTypesData.getProjectTypes;

    content = (
      <>
        <SettingsCreateItemForm
          header={'Тип проекта'}
          createItem={createHandler}
          setCreating={setCreating}
          inputError={inputError}
          setInputError={setInputError}
          areButtonsDisabled={areCreatingButtonsDisabled}
          isRequestSuccessful={Boolean(createProjectTypeData)}
        />

        <SettingsList
          list={userProjectTypes}
          updateItem={updateHandler}
          deleteItem={deleteHandler}
          buttonsDisabled={areEditingButtonsDisabled}
          isEditing={isEditing}
          setEditing={setEditing}
        />
      </>
    )
  }

  return (
    <Content>
      {content}

      <Snackbar
        open={inputError}
        onClose={() => setInputError(false)}
        autoHideDuration={6000}
      >
        <Alert severity="error">
          Вы ввели некорректное название
        </Alert>
      </Snackbar>

      <Snackbar
        open={requestError}
        onClose={() => setRequestError(false)}
        autoHideDuration={6000}
      >
        <Alert severity="error">
          {createProjectTypeError?.message || updateProjectTypeError?.message || deleteProjectTypeError?.message}
        </Alert>
      </Snackbar>
    </Content>
  )
}
