export function setUrlQueryParams(params: Record<string, unknown>) {
  const urlObj = new URL(window.location.href)

  Object.keys(params).forEach((key) => {
    if (params[key]) {
      urlObj.searchParams.set(key, params[key] as string)

      window.history.replaceState({}, '', urlObj.toString())
      return
    }

    urlObj.searchParams.delete(key)
  })

  window.history.replaceState({}, '', urlObj.toString())
}
