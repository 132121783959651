import styled from "styled-components";
import { TableRow } from "@material-ui/core";
import { lightBlack, MainTableRow } from "../shared/Style/Style";

interface ITableRowCustomProps {
  columns: number;
}

export const GiveawayTeachersTableRow = styled(MainTableRow)`
  grid-template-columns: 1.5fr 1fr 1.5fr 1fr 1.5fr;
  grid-gap: 10px;
`;

export const EmptyGiveawayTeachersTableRow = styled(GiveawayTeachersTableRow)`
  grid-template-columns: 1fr;
`;

export const PeopleTableHeadRow = styled(TableRow)<ITableRowCustomProps>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr) 48px`};
  padding: 20px 15px;
  margin: 20px 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

export const PeopleTableHeaderTitle = styled.div`
  color: black;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;

export const PeopleTableContentRow = styled(TableRow)<ITableRowCustomProps>`
  display: grid;
  max-width: calc(100% - 2px);
  margin: 0 auto 20px;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr) 48px`};
  padding: 20px 15px;
  color: ${lightBlack};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  cursor: pointer;
`;

export const PeopleTableContentCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  line-height: 17px;
`;

export const PeopleTableRowsWrapper = styled.div``;
