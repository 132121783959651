import { useMemo } from "react";
import { HandlePaymentActivationMutationVariables, Payment, PaymentType } from "../../../../generated/graphql";
import { PaymentItem } from '../PaymentItem';
import { Box, Paper } from "@material-ui/core";
import { Subtitle, StyledSwitch } from "../../../shared/Style/Style";
import { DeleteIconButton } from "../../../shared/Buttons/DeleteIconButton";
import { PaymentsContainer } from "../../Style";
import { getActivePayments, getPaymentsHistory, isExternalPayment } from "../utils"

interface SchoolPaymentsProps {
  payments: Payment[],
  isPersonal: boolean;
  activatePayment(variables: HandlePaymentActivationMutationVariables): void;
  deletePayment(id: string): void;
}

export const SchoolPayments = ({
  payments = [],
  isPersonal,
  activatePayment,
  deletePayment,
}: SchoolPaymentsProps) => {
  const activePayments = useMemo(() => getActivePayments(payments), [payments]);
  const paymentsHistory = useMemo(() => getPaymentsHistory(payments), [payments]);

  const onPaymentActivation = (id: string, isActivated: boolean) => {
    activatePayment({ id, isActivated: !isActivated });
  };

  if (!activePayments?.length && !paymentsHistory.length) return null;

  const showActionButtons = (paymentType: PaymentType) => !isExternalPayment(paymentType);

  return (
    <Paper elevation={3}>
      <PaymentsContainer>
        {activePayments?.length > 0 ? (
          <>
            <Subtitle>Список оплат</Subtitle>
              {activePayments?.map(({ id, ...props }) => (
                <Box key={id} display="flex">
                  <PaymentItem
                    key={id}
                    isPersonal={isPersonal}
                    payment={props}
                  />

                  {showActionButtons(props?.paymentType) ? (
                  <Box paddingLeft="18px">
                    <Box display="flex">
                      <p>{!props?.isActivated ? "Предоставить доступ" : "Отозвать доступ"}</p>
                      <StyledSwitch
                        checked={props?.isActivated}
                        onChange={() => onPaymentActivation(id, props?.isActivated)}
                      />
                    </Box>

                    <Box display="flex">
                      <p>Удалить платеж</p>
                      <DeleteIconButton
                        item="платеж"
                        fz={20}
                        deleteHandler={() => deletePayment(id)}
                        disabled={!props?.isManual}
                      />
                    </Box>
                  </Box>
                  ) : null}
                </Box>
            ))}
          </>
        ) : null}

        {paymentsHistory.length > 0 ? (
          <>
            <Subtitle>История оплат</Subtitle>
            {paymentsHistory?.map(({ id, ...paymentItemProps }) => (
              <PaymentItem
                key={id}
                payment={paymentItemProps}
                isPersonal={isPersonal}
                isHistory
              />
            ))}
          </>
        ) : null}
      </PaymentsContainer>
    </Paper>
  );
};
