import { Dispatch, FC, SetStateAction } from "react";
import { useReactiveVar } from "@apollo/client";
import { ArtifactTypeEnum, GameModelArtifact } from "../../../../../generated/graphql";
import { robotsState } from "../../state";
import { IconButton, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { Create, Delete, CheckCircle } from "@material-ui/icons";
import { ArtifactInfo, ArtifactPhoto, ArtifactItemContainer, PaginationContainer } from "../../style";
import { artifactPerPage } from "../../constants";

interface StageArtifactItemProps {
    getNextPage: (type: ArtifactTypeEnum, clothTypeId: string, skip: number) => void;
}

export const StageArtifactItem: FC<StageArtifactItemProps> = ({getNextPage}) => {
    const artifactsData = useReactiveVar(robotsState.artifacts);
    const artifactMode = useReactiveVar(robotsState.currentArtifactMode);
    const isLoading = useReactiveVar(robotsState.isLoading);
    
    const artifacts = artifactsData?.artifacts ?? [];
    const total = artifactsData?.total ?? 0;
    const shouldPaginate = total > artifactPerPage;
    const pageCount = Math.ceil(total / artifactPerPage) ?? 0;

    const handleEditArtifact = async (id: string, type: ArtifactTypeEnum) => {
        await robotsState.getRobotArtifact({ getGameModelArtifactId: id });
        robotsState.setIsArtifactModalOpen({ isOpen: true, type });
     };

    const handleDeleteArtifact = (id: string) => {
        const artifact = artifacts?.find(item => item.id === id);
        robotsState.setDeleteArtifact({ isOpen: true, artifact });
    };

    const handleChangePage = (page: number) => {
        const skip = (page - 1) * artifactPerPage;
        robotsState.setArtifactsSkipPage(skip);
        getNextPage(
            artifactMode?.type,
            artifactMode?.clothTypeId,
            skip,
        );
    };

    const renderArtifactItem = (item: GameModelArtifact) => {
        return (
            <ArtifactItemContainer  key={item.id}>
                <ArtifactInfo>
                    <CheckCircle fontSize="small" color={item.isPublished ? "primary" : "disabled"}/> 
                    {item?.image && <ArtifactPhoto src={item.image} alt={item.name} />}
                    <Typography variant="body1">{item.name}</Typography>
                </ArtifactInfo>

                <div>
                    {item?.isDefault &&
                        <Typography variant="body2" align="right" color="textSecondary" display="inline">
                            *По дефолту
                        </Typography>
                    }
                    
                    <IconButton
                        disabled={isLoading}
                        onClick={() => handleEditArtifact(item.id, item.type)} 
                    >
                        <Create fontSize="small" color="action" />
                    </IconButton>

                    <IconButton
                        disabled={isLoading}
                        onClick={() => handleDeleteArtifact(item.id)}
                    >
                        <Delete fontSize="small" color="action" />
                    </IconButton>    
                </div>
            </ArtifactItemContainer >
        );
    };

    if (!artifacts?.length && !total && !isLoading) {
        return (
            <Typography variant="body1">
                У Вас пока нет артефактов, нажмите “+”, чтобы создать
            </Typography>
        );
    };

    return (
        <>
            {artifacts.map(item => renderArtifactItem(item))}

            {shouldPaginate &&
                <PaginationContainer>
                    <Pagination
                        shape="rounded"
                        count={pageCount}
                        onChange={(_, page) => handleChangePage(page)}
                    />
                </PaginationContainer>
            }
        </>
    );
};
