import React, { useEffect, useState } from "react";

import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import { Edit, Delete, Save } from '@material-ui/icons';

import { useMutation, useQuery } from "@apollo/client";
import {
  CreatePayrollBonusDocument,
  CreatePayrollBonusMutation,
  DeletePayrollBonusDocument,
  DeletePayrollBonusMutation,
  GetPayrollBonusesDocument,
  GetPayrollBonusesQuery,
  PayrollBonus,
  UpdatePayrollBonusDocument,
  UpdatePayrollBonusMutation
} from "../../../generated/graphql";

import { CustomTable } from "../../shared/CustomTable";
import {
  CustomTableHeaderCell,
  CustomTableCell,
  CustomTableInput
} from "../../shared/CustomTable/CustomTable.style";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";

import {
  ButtonCustom,
  ButtonCustomCancel,
  ButtonPlusSmall,
  ButtonsWrapper,
  LoadingBox
} from "../../shared/Style/Style";
import { ModalHeader, BottomErrorTxt, ModalBody } from "../SalaryModal.style";
import { ISalaryModalInnerProps } from "../SalaryModal";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";


export const BonusModal = ({ payroll, close, refetchPayroll }: ISalaryModalInnerProps) => {
  const [isCreatingBonus, setCreatingBonus] = useState(false);
  const [isEditingBonus, setEditingBonus] = useState(false);

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')

  const closeModalHandler = () => {
    refetchPayroll();
    setBonusData({
      name: '',
      value: 0,
    });
    setChosenBonus('');
    setErrors({
      name: false,
      value: false,
    })
    setCreatingBonus(false);
    setEditingBonus(false)
    close();
  }

  const [chosenBonus, setChosenBonus] = useState<string>('');

  const [bonusData, setBonusData] = useState({
    name: '',
    value: 0,
  });

  const [errors, setErrors] = useState({
    name: false,
    value: false,
  });

  const [
    createBonus,
    {
      data: createData,
      loading: createLoading,
      error: createError,
    }
  ] = useMutation<CreatePayrollBonusMutation>(
    CreatePayrollBonusDocument,
    {
      variables: {
        payrollId: payroll.id,
        name: bonusData.name,
        value: bonusData.value,
      },
      refetchQueries: [{
        query: GetPayrollBonusesDocument,
        variables: { payrollId: payroll.id },
      }]
    }
  );

  const [
    updateBonus,
    {
      data: updateData,
      loading: updateLoading,
      error: updateError,
    }
  ] = useMutation<UpdatePayrollBonusMutation>(
    UpdatePayrollBonusDocument,
    {
      variables: {
        id: chosenBonus,
        name: bonusData.name,
        value: bonusData.value,
      },
      refetchQueries: [{
        query: GetPayrollBonusesDocument,
        variables: { payrollId: payroll.id },
      }]
    }
  );

  const [
    deleteBonus,
    {
      data: deleteData,
      loading: deleteLoading,
      error: deleteError,
    }
  ] = useMutation<DeletePayrollBonusMutation>(
    DeletePayrollBonusDocument,
    {
      refetchQueries: [{
        query: GetPayrollBonusesDocument,
        variables: { payrollId: payroll.id },
      }]
    }
  );

  const saveBonusHandler = () => {
    if (!bonusData.name) {
      setErrors(state => ({
        ...state,
        name: true,
      }))
    }

    if (!bonusData.value) {
      setErrors(state => ({
        ...state,
        value: true,
      }))
    }

    if (!bonusData.name || !bonusData.value) {
      return;
    }

    isCreatingBonus ? createBonus() : updateBonus();
  };

  const editBonusHandler = async (bonus: PayrollBonus) => {
    if (!isEditingBonus && !isCreatingBonus) {
      await setChosenBonus(bonus.id);
      setEditingBonus(true);
      setBonusData({
        name: bonus.name,
        value: bonus.value,
      })
    }
  }

  useEffect(() => {
    if (deleteData) {
      setChosenBonus('');
    }
  }, [deleteData])

  useEffect(() => {
    if (createData || updateData) {
      setBonusData({
        name: '',
        value: 0,
      });
      setChosenBonus('');
      createData ? setCreatingBonus(false) : setEditingBonus(false);
    }
  }, [createData, updateData]);

  useEffect(() => {
    if (createError) {
      setErrorMessage('При создании бонуса произошла ошибка');
    }
  }, [createError]);

  useEffect(() => {
    if (updateError) {
      setErrorMessage('При изменении бонуса произошла ошибка');
    }
  }, [updateError]);

  useEffect(() => {
    if (deleteError) {
      setErrorMessage('При удалении бонуса произошла ошибка');
    }
  }, [deleteError]);

  useEffect(() => {
    if (createError || updateError || deleteError) {
      setRequestError(true)
    }
  }, [createError, updateError, deleteError]);

  const {
    data: bonusesData,
    loading: bonusesLoading,
    error: bonusesError,
  } = useQuery<GetPayrollBonusesQuery>(
    GetPayrollBonusesDocument,
    { variables: { payrollId: payroll.id } }
  );


  let content;

  if (bonusesLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit' />
      </LoadingBox>
    )
  }

  if (bonusesError) {
    content = <ErrorSnackbar error={bonusesError} />
  }

  if (bonusesData) {
    const bonuses = bonusesData.getPayrollBonuses;

    content = (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableHeaderCell> Сумма </CustomTableHeaderCell>
              <CustomTableHeaderCell> Коментарий </CustomTableHeaderCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isCreatingBonus && (
              <TableRow>
                <CustomTableCell
                  padding='none'
                  style={{ maxWidth: '85px' }}
                >
                  <CustomTableInput
                    type='number'
                    disabled={createLoading}
                    value={bonusData.value}
                    onChange={({ target }) => {
                      setErrors({...errors, value: false})
                      setBonusData({...bonusData, value: +target.value})
                    }}
                  />
                </CustomTableCell>

                <CustomTableCell
                  padding='none'
                >
                  <CustomTableInput
                    disabled={createLoading}
                    value={bonusData.name}
                    onChange={({ target }) => {
                      setErrors({...errors, name: false})
                      setBonusData({...bonusData, name: target.value})
                    }}
                  />
                </CustomTableCell>
              </TableRow>
            )}

            {!!bonuses.length && bonuses.map(bonus => (
              <TableRow key={bonus.id}>
                <CustomTableCell
                  style={{ maxWidth: '85px' }}
                  padding='none'
                >
                  <CustomTableInput
                    type='number'
                    disabled={chosenBonus !== bonus.id}
                    value={chosenBonus !== bonus.id ? bonus.value : bonusData.value}
                    onChange={({ target }) => {
                      setErrors({...errors, value: false})
                      setBonusData({...bonusData, value: +target.value})
                    }}
                  />
                </CustomTableCell>

                <CustomTableCell
                  padding='none'
                  style={{ display: 'flex' }}
                >
                  <CustomTableInput
                    disabled={chosenBonus !== bonus.id}
                    value={chosenBonus !== bonus.id ? bonus.name : bonusData.name}
                    onChange={({ target }) => {
                      setErrors({...errors, name: false})
                      setBonusData({...bonusData, name: target.value})
                    }}
                  />

                  <IconButton
                    disabled={createLoading || updateLoading || deleteLoading}
                    onClick={() => isEditingBonus && chosenBonus === bonus.id
                      ? saveBonusHandler()
                      : editBonusHandler(bonus)
                    }
                  >
                    {(isEditingBonus && chosenBonus === bonus.id)
                      ? <Save
                        fontSize='small'
                      />
                      : <Edit
                        fontSize='small'
                      />
                    }
                  </IconButton>

                  <IconButton
                    disabled={createLoading || updateLoading || deleteLoading}
                    onClick={() => {
                      if (!isEditingBonus && !isCreatingBonus) {
                        deleteBonus({variables: {bonusId: bonus.id}});
                      }
                    }}
                  >
                    <Delete color='secondary' fontSize='small'/>
                  </IconButton>
                </CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {!bonuses.length && <p style={{ textAlign: 'center' }}>Бонусы отсутствуют</p>}
      </>
    )
  }

  return (
    <>
      <ModalBody>
        <Box component="div" display="flex" justifyContent="space-between" mb={2} alignItems="center">
          <ModalHeader>Бонусы</ModalHeader>

          <ButtonPlusSmall
            disabled={createLoading || updateLoading || deleteLoading}
            onClick={() => setCreatingBonus(true)}
          />

          <CloseIconButton
            disabled={createLoading || updateLoading || deleteLoading}
            close={closeModalHandler}
          />
        </Box>

        <Box position="relative" pb={5}>
            <CustomTable data={bonusesData?.getPayrollBonuses} tableContent={content}/>

            <BottomErrorTxt>
              {errors.name &&
                <Box>
                  Коментарий должен быть заполнен
                </Box>
              }

              {errors.value &&
                <Box>
                  Сумма должна быть болше нуля
                </Box>
              }
            </BottomErrorTxt>
        </Box>


        <ButtonsWrapper>
          <ButtonCustomCancel
            disabled={createLoading || updateLoading || deleteLoading}
            onClick={closeModalHandler}
          >
            Отмена
          </ButtonCustomCancel>

          <ButtonCustom
            onClick={saveBonusHandler}
            disabled={!isCreatingBonus || createLoading}
          >
            Сохранить
          </ButtonCustom>
        </ButtonsWrapper>
      </ModalBody>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </>
  )
}