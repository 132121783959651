import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import addHours from "date-fns/addHours";
import { FilterType } from "./types";
import {
  ChartDataWithTotal,
  GetAdminReportsType,
  PeriodAdminReportEnum,
} from "../../generated/graphql";
import {
  AXIOS_LABEL_WIDTH,
  CHART_INFO_MESSAGE,
  CHART_WIDTH_CONTAINER,
  LABEL_WIDTH,
  MAX_CHART_WIDTH,
  MAX_LABEL_QUANTITY,
} from "./constants";
import { formatInTimeZone } from "date-fns-tz";
import { ru as ruLocale } from "date-fns/locale";

const ISO_HOURS_DIFF = 3;

const compose =
  (...fns: Function[]) =>
  (initial: Date) =>
    fns.reduce((val, fn) => fn(val), initial);

const getStartOfDay = (date: Date) => startOfDay(date ?? new Date());
const getEndOfDay = (date: Date) => endOfDay(date ?? new Date());
const addHoursForISOFormat = (date: Date) => addHours(date, ISO_HOURS_DIFF);

export const transformStartDate = compose(getStartOfDay, addHoursForISOFormat);
export const transformEndDate = compose(getEndOfDay, addHoursForISOFormat);

const setFormatInTimeZone = (date: string, pattern: string) => {
  return formatInTimeZone(date.split("T")[0], "Europe/Kyiv", pattern, {
    locale: ruLocale,
  });
};

const getChartLabels = (
  reportData:
    | GetAdminReportsType["dataChart"]
    | ChartDataWithTotal["dataChart"] = [],
  periodType: string
) => {
  const isMonth = periodType === PeriodAdminReportEnum.Month;
  const isWeek = periodType === PeriodAdminReportEnum.Week;
  const patternFrom = isMonth || isWeek ? "dd MMM" : "eeeeee";
  const patternTo = isMonth || isWeek ? "dd MMM" : "dd.MM";
  const patternYear = "yyyy";

  return reportData?.map((item) => {
    const [from, to] = item.tag;
    return [
      setFormatInTimeZone(from, patternFrom),
      setFormatInTimeZone(to, patternTo),
      setFormatInTimeZone(to, patternYear),
    ];
  });
};

export const getChartLabelsData = (
  report: GetAdminReportsType["dataChart"] | ChartDataWithTotal["dataChart"],
  period: FilterType["periodType"]
) => {
  const chartLabels = getChartLabels(report, period);
  const chartValue = report?.map((item) => item.value);

  return { label: chartLabels, value: chartValue };
};

export const getChartQuantityData = (total: number, metric: string) => {
  const quantityFormatted = total ? `${total} ${metric}` : `0 ${metric}`;

  return quantityFormatted;
};

export const getMessage = (total: number | undefined | null) => {
  if (!total && total !== 0) {
    return { text: CHART_INFO_MESSAGE.empty, isChartDisplay: false };
  } else if (total === 0) {
    return { text: CHART_INFO_MESSAGE.notFound, isChartDisplay: false };
  } else {
    return { text: CHART_INFO_MESSAGE.default, isChartDisplay: true };
  }
};

export const getNewWidth = (totalLabel: number) => {
  if (totalLabel > MAX_LABEL_QUANTITY) {
    const chartWidth =
      CHART_WIDTH_CONTAINER +
      (totalLabel - MAX_LABEL_QUANTITY) * LABEL_WIDTH +
      AXIOS_LABEL_WIDTH;
    return chartWidth > MAX_CHART_WIDTH ? MAX_CHART_WIDTH : chartWidth;
  } else {
    return totalLabel * LABEL_WIDTH + AXIOS_LABEL_WIDTH;
  }
};
