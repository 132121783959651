import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import {
  ButtonCustomWithDarkText,
  ButtonsWrapper,
  secondary,
} from "../../Style/Style";

interface IDeleteIconButtonProps {
  disabled?: boolean;
  deleteHandler(): void;
  item: string;
  fz?: number;
  withStopPropagation?: boolean;
}

export const DeleteIconButton = ({
  disabled,
  deleteHandler,
  item,
  fz,
  withStopPropagation,
}: IDeleteIconButtonProps) => {
  const [confirmModal, setConfirmModal] = useState(false);

  return (
    <>
      <IconButton
        disabled={disabled}
        onClick={
          withStopPropagation
            ? (e) => {
                e.stopPropagation();
                setConfirmModal(true);
              }
            : () => setConfirmModal(true)
        }
        style={{
          color: disabled ? secondary : "red",
          fontSize: fz ? `${fz}px` : "24px",
          padding: fz ? `${fz / 2}px` : "12px",
          lineHeight: 1,
        }}
      >
        <Delete color="inherit" fontSize="inherit" />
      </IconButton>

      <Dialog
        open={confirmModal}
        transitionDuration={500}
        onClick={
          withStopPropagation
            ? (e) => {
                e.stopPropagation();
                setConfirmModal(false);
              }
            : () => setConfirmModal(false)
        }
      >
        <DialogContent>
          <DialogTitle>Вы уверены что хотите удалить {item}?</DialogTitle>

          <ButtonsWrapper>
            <ButtonCustomWithDarkText
              active={false}
              onClick={
                withStopPropagation
                  ? (e) => {
                      e.stopPropagation();
                      setConfirmModal(false);
                    }
                  : () => setConfirmModal(false)
              }
            >
              Отменить
            </ButtonCustomWithDarkText>

            <ButtonCustomWithDarkText
              active={true}
              onClick={
                withStopPropagation
                  ? (e) => {
                      e.stopPropagation();
                      deleteHandler();
                      setConfirmModal(false);
                    }
                  : () => {
                      deleteHandler();
                      setConfirmModal(false);
                    }
              }
            >
              Подтвердить
            </ButtonCustomWithDarkText>
          </ButtonsWrapper>
        </DialogContent>
      </Dialog>
    </>
  );
};
