import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { useQuery, useMutation } from "@apollo/client";
import {
  GetRateHistoryQuery,
  GetRateHistoryDocument,
  DeleteSRateHistoryMutation,
  DeleteSRateHistoryDocument,
  UpdateRateHistoryMutation,
  UpdateRateHistoryDocument,
  UserRateHistory,
  CreateRateHistoryMutation,
  CreateRateHistoryDocument
} from "../../generated/graphql";

import {
  Container,
  Box,
  TableHead,
  CircularProgress,
  Table,
  TableBody,
  TableRow
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import { Save, Delete, Edit } from "@material-ui/icons";

import { CustomTable } from "../shared/CustomTable";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";

import {
  Header,
  ButtonPlus,
  ButtonCustomWithDarkText,
  StyledLink,
  LoadingBox,
  defaultMaterialTheme
} from "../shared/Style/Style";
import {
  CustomTableHeaderCell,
  CustomTableCell,
  CustomTableInput,
  CustomTableDatePicker
} from "../shared/CustomTable/CustomTable.style";
import {
  EditButton,
  DeleteButton
} from "../SalaryHistory/SalaryHistory.style";
import { getMinCreationDateHandler } from "../shared/Utils/DateOperations/getMinCreationDate";


export const SalaryRateHistory = () => {
  const { userId } = useParams<{ userId: string }>();

  const [isRateCreating, setRateCreating] = useState(false);
  const [isRateEditing, setRateEditing] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [chosenRate, setChosenSalary] = useState('');

  const tomorrow = new Date((new Date()).setDate((new Date()).getDate() + 1));

  const [rateData, setRateData] = useState({
    rate: 0,
    changeDate: tomorrow,
  });

  const [errors, setErrors] = useState({
    rate: false,
    changeDate: false,
  });


  const {
    data: rateHistoryData,
    loading: rateHistoryLoading,
    error: rateHistoryError,
  } = useQuery<GetRateHistoryQuery>(GetRateHistoryDocument, { variables: { userId } })

  const editRateHandler = async (rate: UserRateHistory) => {
    if (!isRateEditing && !isRateCreating) {
      await setChosenSalary(rate.id);
      setRateEditing(true);
      setRateData({
        rate: rate.rate,
        changeDate: rate.changeDate,
      })
    }
  }

  const [
    deleteRate,
    {
      data: deleteRateData,
      loading: deleteRateLoading,
      error: deleteRateError
    }
  ] = useMutation<DeleteSRateHistoryMutation>(
    DeleteSRateHistoryDocument,
    { refetchQueries: [{ query:  GetRateHistoryDocument, variables: { userId } }] }
  );

  const [
    createRate,
    {
      data: createRateData,
      loading: createRateLoading,
      error: createRateError
    }
  ] = useMutation<CreateRateHistoryMutation>(
    CreateRateHistoryDocument,
    {
      variables: {
        userId,
        rate: rateData.rate,
        changeDate: rateData.changeDate,
      },
      refetchQueries: [{ query:  GetRateHistoryDocument, variables: { userId } }]
    }
  );

  const [
    updateRate,
    {
      data: updateRateData,
      loading: updateRateLoading,
      error: updateRateError
    }
  ] = useMutation<UpdateRateHistoryMutation>(
    UpdateRateHistoryDocument,
    {
      variables: {
        id: chosenRate,
        rate: rateData.rate,
        changeDate: rateData.changeDate,
      },
      refetchQueries: [{ query:  GetRateHistoryDocument, variables: { userId } }]
    }
  );

  const saveRateHandler = () => {
    if (!rateData.rate) {
      setErrors(state => ({
        ...state,
        rate: true,
      }))
    }

    if (!rateData.changeDate) {
      setErrors(state => ({
        ...state,
        changeDate: true,
      }))
    }

    if (!rateData.rate || !rateData.changeDate) {
      return;
    }

    isRateCreating ? createRate() : updateRate();
  };

  // #region effects

  useEffect(() => {
    if (createRateData || updateRateData || deleteRateData) {
      setRateCreating(false);
      setRateEditing(false);
      setChosenSalary('');
      setRateData({
        rate: 0,
        changeDate: tomorrow,
      });
    }
  },[createRateData, updateRateData, deleteRateData]);

  useEffect(() => {
    if (createRateError || updateRateError || deleteRateError) {
      setRequestError(true);
    }
  },[createRateError, updateRateError, deleteRateError]);

  useEffect(() => {
    if (createRateError) {
      setErrorMessage('При создании рейта произошла ошибка');
    }
  },[createRateError]);

  useEffect(() => {
    if (updateRateError) {
      setErrorMessage('При изменении рейта произошла ошибка');
    }
  },[updateRateError]);

  useEffect(() => {
    if (deleteRateError) {
      setErrorMessage('При удалении рейта произошла ошибка');
    }
  },[deleteRateError]);

  // #endregion

  let content;

  if (rateHistoryLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit'/>
      </LoadingBox>
    )
  }

  if (rateHistoryError) {
    content = <ErrorSnackbar error={rateHistoryError}/>
  }

  if (rateHistoryData) {
    const tableHeaders = [
      'Дата',
      'Рейт',
      '',
    ];

    const rateHistory = rateHistoryData.getRateHistory;

    const minCreationDate = getMinCreationDateHandler(rateHistory);

    content = (
      <>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map(header =>
                <CustomTableHeaderCell
                  key={header}
                  width={!header && 200}
                >
                  {header}
                </CustomTableHeaderCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {isRateCreating && (
              <TableRow>
                <CustomTableCell padding='none'>
                  <ThemeProvider theme={defaultMaterialTheme}>
                    <CustomTableDatePicker
                      fullWidth
                      disabled={createRateLoading || updateRateLoading || deleteRateLoading}
                      error={errors.changeDate}
                      variant="inline"
                      format="dd.MM.yyyy"
                      helperText={''}
                      minDate={minCreationDate}
                      value={rateData.changeDate}
                      onChange={(value) => {
                        setErrors({
                          ...errors,
                          changeDate: false,
                        });
                        setRateData({ ...rateData, changeDate: value });
                      }}
                    />
                  </ThemeProvider>
                </CustomTableCell>


                <CustomTableCell padding='none'>
                  <CustomTableInput
                    type='number'
                    error={errors.rate}
                    disabled={createRateLoading || updateRateLoading || deleteRateLoading}
                    value={rateData.rate}
                    onChange={({ target }) => {
                      setErrors({...errors, rate: false})
                      setRateData({...rateData, rate: +target.value})
                    }}
                  />
                </CustomTableCell>

                <CustomTableCell style={{ position: 'relative' }} padding='none'>
                  <EditButton
                    disabled={createRateLoading || updateRateLoading || deleteRateLoading}
                    onClick={() => saveRateHandler()}
                  >
                    <Save fontSize='small'/>
                  </EditButton>

                  <DeleteButton
                    disabled={createRateLoading || updateRateLoading || deleteRateLoading}
                    onClick={() => {
                      setRateCreating(false);
                      setErrors({
                        rate: false,
                        changeDate: false,
                      })
                      setRateData({
                        rate: 0,
                        changeDate: tomorrow,
                      });
                    }}
                  >
                    <Delete fontSize='small'/>
                  </DeleteButton>
                </CustomTableCell>
              </TableRow>
            )}

            {!!rateHistory.length
              ? rateHistory.map(rate => (
                <TableRow key={rate.id}>
                  <CustomTableCell padding='none'>
                    <ThemeProvider theme={defaultMaterialTheme}>
                      <CustomTableDatePicker
                        fullWidth
                        error={errors.changeDate}
                        disabled={chosenRate !== rate.id}
                        variant="inline"
                        format="dd.MM.yyyy"
                        minDate={new Date(rate.changeDate)}
                        value={chosenRate !== rate.id ? new Date(rate.changeDate) : rateData.changeDate}
                        onChange={(value) => {
                          setErrors({
                            ...errors,
                            changeDate: false,
                          });
                          setRateData({ ...rateData, changeDate: value });
                        }}
                      />
                    </ThemeProvider>
                  </CustomTableCell>

                  <CustomTableCell padding='none'>
                    <CustomTableInput
                      type='number'
                      error={errors.rate}
                      disabled={chosenRate !== rate.id}
                      value={chosenRate !== rate.id ? rate.rate : rateData.rate}
                      onChange={({ target }) => {
                        setErrors({...errors, rate: false})
                        setRateData({...rateData, rate: +target.value})
                      }}
                    />
                  </CustomTableCell>

                  <CustomTableCell style={{ position: 'relative' }} padding='none'>
                    <EditButton
                      disabled={createRateLoading
                        || updateRateLoading
                        || deleteRateLoading
                        || ((isRateEditing || isRateCreating) && chosenRate !== rate.id)}
                      onClick={() => isRateEditing ? saveRateHandler() : editRateHandler(rate)}
                    >
                      {isRateEditing
                        ? <Save fontSize='small'/>
                        : <Edit fontSize='small'/>
                      }
                    </EditButton>

                    <DeleteButton
                      disabled={createRateLoading
                        || updateRateLoading
                        || deleteRateLoading
                        || isRateEditing
                        || isRateCreating}
                      onClick={() => deleteRate({ variables: { id: rate.id } })}
                    >
                      <Delete fontSize='small'/>
                    </DeleteButton>
                  </CustomTableCell>
                </TableRow>
              ))
              : (
                <TableRow>
                  <CustomTableCell colSpan={3}>История рейта пуста</CustomTableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </>
    )
  }

  return (
    <Container maxWidth="lg" style={{ position: "relative" }}>
      <Header>История иЗМЕНЕНИЙ РЕЙТА</Header>

      <Box
        mb={5}
        mt={4}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <StyledLink
          to={`/user/${userId}/salaryHistory`}
        >
          <ButtonCustomWithDarkText
            disabled={createRateLoading || updateRateLoading || deleteRateLoading || isRateEditing}
            onClick={() => setRateCreating(true)}
          >
              История зарплат
          </ButtonCustomWithDarkText>
        </StyledLink>

          <ButtonPlus
            width={180}
            variant="contained"
            disabled={createRateLoading || updateRateLoading || deleteRateLoading || isRateEditing}
            onClick={() => setRateCreating(true)}
          >
            Добавить
          </ButtonPlus>
      </Box>

      <CustomTable data={rateHistoryData?.getRateHistory} tableContent={content} />

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />
    </Container>
  )
}
