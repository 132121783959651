import React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { SnackbarProps } from "@material-ui/core/Snackbar/Snackbar";

interface SuccessSnackbarProps {
  open: boolean;
  handleClose(): void;
  message?: string;
  snackbarProps?: Omit<SnackbarProps, "open">;
}

export const SuccessSnackbar = ({
  open,
  handleClose,
  message = "Сохранено",
  snackbarProps,
}: SuccessSnackbarProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      {...snackbarProps}
    >
      <Alert onClose={handleClose} severity="success">
        <span>{message}</span>
      </Alert>
    </Snackbar>
  );
};
