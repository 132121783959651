import { useQuery } from "@apollo/client";
import { Box, CircularProgress, Table, TableBody, TableRow, TableHead } from "@material-ui/core";
import React from "react";
import { GetDayReportDocument, GetDayReportQuery } from "../../../generated/graphql";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { CustomTable } from "../../shared/CustomTable";
import { CustomTableCell, CustomTableHeaderCell } from "../../shared/CustomTable/CustomTable.style";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";
import { LoadingBox } from "../../shared/Style/Style";
import { convertMinuteToHoursHandler } from "../../shared/Utils/ConvertOperations/convertMinuteToHoursHandler";
import { ISalaryModalInnerProps } from "../SalaryModal";
import { ModalBody, ModalHeader } from "../SalaryModal.style";

export const DayReportModal = ({ close, payroll, date }: ISalaryModalInnerProps) => {
  console.log(date);
  
  const {data, loading, error} = useQuery<GetDayReportQuery>(
    GetDayReportDocument,
    { variables: {
      payrollId: payroll.id,
      date
    }}
  );

  let content;

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit' />
      </LoadingBox>
    )
  }

  if (error) {
    content = <ErrorSnackbar error={error} />
  }

  if (data) {
    const tasks = data.getDayReport;

    console.log(data.getDayReport);

    content = (
      <Table>
        <TableHead>
          <TableRow>
            <CustomTableHeaderCell width={450}>Задача</CustomTableHeaderCell>
            <CustomTableHeaderCell width={150}>Часов потрачено</CustomTableHeaderCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {tasks.length
            ? (
              tasks.map(task => (
                <TableRow>
                  <CustomTableCell>{task.taskName}</CustomTableCell>
                  <CustomTableCell>{convertMinuteToHoursHandler(task.time)}</CustomTableCell>
                </TableRow>
              ))
            )
            : (
              <TableRow>
                <CustomTableCell colSpan={2} >Задачи отсутсвуют</CustomTableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    )
  }

  return (
    <ModalBody>
      <Box
        component="div"
        display="flex"
        mb={2}
        alignItems="center"
      >
        <ModalHeader>СПИСОК ЗАДАЧ И ПОТРАЧЕННОЕ ВРЕМЯ</ModalHeader>
        <CloseIconButton close={close}/>
      </Box>

      <CustomTable data={data?.getDayReport} tableContent={content} />
    </ModalBody>
  )
}