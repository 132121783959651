import React, { useState } from "react";

import { User, UserJob, Notification, NotificationParticipant } from "../../../generated/graphql";

import {  Dialog, Radio } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { DEFAULT_AVATAR_URL } from "../../shared/constants";
import {
  MainList,
  UsersGroupByJob,
  UsersGroupButtonStatistics,
  DropdownIconWrapper,
  UsersGroupList,
  UserLabel,
  UserAvatarXS,
  UserLabelText,
  AddUsersToNotificationModalContent,
  UsersFilterForm,
  UsersFilterFormLabel,
  UsersFilters,
  UsersFilter,
  BtnsWrapper,
  ButtonCancelFixedWidth,
  ButtonFixedWidth,
  UnwatchedText,
  WatchedText,
  WatchHeadersBox,
  StatisticsListItem,
  EmptyList 
} from "../ParticipantsModal.style";

interface INotificationStatisticsModalProps {
  notification: Notification | null | undefined;
  open: boolean;
  close(): void;
}

export const NotificationStatisticsModal = ({ notification, open, close }: INotificationStatisticsModalProps) => {
  const [activeFilter, setActiveFilter] = useState<string | undefined>(undefined);
  const [chosenJob, setChosenJob] = useState<UserJob | null>(null);

  const choseJobHandler = (job: UserJob) => {
    if (job.id === chosenJob?.id) {
      setChosenJob(null);
      return;
    }

    setChosenJob(job);
  }

  interface usersByJob {
    job: UserJob;
    users: User[];
    isWatched: User[]
  }

  let usersByJobs = [] as usersByJob[];

  if (activeFilter === 'STAFF') {
    notification?.participants?.forEach((participant: NotificationParticipant | null | undefined) => {
      if (participant?.user?.job && !usersByJobs.find(({ job }: usersByJob) => job.id === participant?.user?.job?.id)) {
        usersByJobs.push({
          job: participant?.user?.job,
          users: [participant?.user],
          isWatched: participant?.isWatched ? [participant?.user] : [] as User[],
        });
        return;
      }

      if (participant?.user?.job && usersByJobs.find(({ job }: usersByJob) => job.id === participant?.user?.job?.id)) {
        const findedJob = usersByJobs?.find(({ job }: usersByJob) => job.id === participant?.user?.job?.id);
        findedJob?.users.push(participant?.user);
        participant?.isWatched && findedJob?.isWatched.push(participant?.user);
      }
    });
  }

  const preparedParticipantsList = activeFilter === 'CLIENT'
    ? (
      notification?.participants?.filter((participant: NotificationParticipant | null ) => participant?.user?.role?.id === 3)
    )
    : (
      notification?.participants
    );

  const content = (
    <>
      {activeFilter === 'STAFF'
        ? (usersByJobs?.length
          ? (<MainList>
            {usersByJobs.map(({ job, users, isWatched }: usersByJob) => (
              <UsersGroupByJob
                key={job.id}
                expanded={job.id === chosenJob?.id}
                usersCount={users.length}
              >
                <UsersGroupButtonStatistics
                  onClick={() => choseJobHandler(job)}
                >
                  <span>
                    {job.name}
                  </span>

                  <DropdownIconWrapper
                    expanded={job.id === chosenJob?.id}
                  >
                    <ExpandMoreIcon />
                  </DropdownIconWrapper>
                </UsersGroupButtonStatistics>

                <UsersGroupList>
                  {users?.map((user: User | null | undefined) => (
                    <StatisticsListItem
                      button={true}
                      key={user?.id}
                    >
                      <UserLabel>
                        <UserAvatarXS src={user?.photo || DEFAULT_AVATAR_URL} />
                        <UserLabelText primary={`${user?.lastName} ${user?.firstName}`} />
                      </UserLabel>

                      <div>
                        {
                          isWatched.find((wathcedUser: User) => wathcedUser.id === user?.id )
                            ? <WatchedText>Провсмотрено</WatchedText>
                            : <UnwatchedText>Не просмотрено</UnwatchedText>
                        }
                      </div>
                    </StatisticsListItem>
                    ))}
                  </UsersGroupList>
                </UsersGroupByJob>
              ))}
            </MainList>
          ) : <EmptyList>Нет доступных пользователей</EmptyList>
        )
        : ( preparedParticipantsList?.length
          ? (
            <MainList>
              {preparedParticipantsList?.map((participant: NotificationParticipant | null | undefined) => (
                <StatisticsListItem
                  button={true}
                  key={participant?.id}
                >

                <UserLabel>
                  <UserAvatarXS src={participant?.user?.photo || DEFAULT_AVATAR_URL} />
                  <UserLabelText primary={`${participant?.user?.lastName} ${participant?.user?.firstName}`} />
                </UserLabel>

                <div>
                  {participant?.isWatched ? <WatchedText>Просмотрено</WatchedText> : <UnwatchedText>Не просмотрено</UnwatchedText>}
                </div>
                </StatisticsListItem>
              ))}
            </MainList>
          ) : <EmptyList>Нет доступных пользователей</EmptyList>
        )
      }

    </>
  );

  return (
    <Dialog
      open={open}
      onClose={close}
      TransitionProps={{timeout: 300}}
    >
      <AddUsersToNotificationModalContent>
        <UsersFilterForm>
          <UsersFilterFormLabel>Статистика просмотров уведомления</UsersFilterFormLabel>
          <UsersFilters
            aria-label="users filter"
            value={activeFilter || ''}
            onChange={({ target: { value } }) => {
              if (!value) {
                setActiveFilter(undefined);
                return;
              }

              setActiveFilter(value);
            }}
          >
            <UsersFilter value={''} control={<Radio />} label="Все" />
            <UsersFilter value="STAFF" control={<Radio />} label="Сотрудники" />
            <UsersFilter value="CLIENT" control={<Radio />} label="Клиенты" />
          </UsersFilters>
        </UsersFilterForm>

        <WatchHeadersBox>
          <span>Пользователи</span>
          <span>Статус</span>
        </WatchHeadersBox>

        {content}

        <BtnsWrapper>
          <ButtonCancelFixedWidth
            onClick={close}
          >
            отмена
          </ButtonCancelFixedWidth>

          <ButtonFixedWidth
            onClick={close}
          >
            ок
          </ButtonFixedWidth>
        </BtnsWrapper>
      </AddUsersToNotificationModalContent>
    </Dialog>
  )
}