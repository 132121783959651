import React, { useState } from "react";

import { useQuery } from "@apollo/client";
import { GetCostReportQuery, GetCostReportDocument } from "../../../../generated/graphql";
import { IDateRange } from "../../../shared/DateRangePicker/DateRangePicker";

import { TableHead, Table, TableBody, TableRow, CircularProgress } from "@material-ui/core";

import { CustomTableCell, CustomTableHeaderCell, ColorizedTableCell, CustomTableRow } from "../../../shared/CustomTable/CustomTable.style";
import { LoadingBox } from "../../../shared/Style/Style";

import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { ReportModal } from "../../../ReportModal";
import { CustomTable } from "../../../shared/CustomTable";

const headers = [
  'Название проекта',
  'Всего по проекту',
  'План',
  'Факт',
  'Разница',
];

export const DifferenceInMoneyReport = ({ dateRange }: {dateRange: IDateRange}) => {
  const [openModal, setOpenModal] = useState(false);
  const [chosenProject, setChosenProject] = useState('');

  const { data, loading, error } = useQuery<GetCostReportQuery>(
    GetCostReportDocument, {
      variables: {
        ...dateRange
      }
    }
  );

  let content;

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit'/>
      </LoadingBox>
    )
  }

  if (error) {
    content = <ErrorSnackbar error={error} />
  }

  if (data) {
    const projects = data.getCostReport;

    content = (
      <Table>
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <CustomTableHeaderCell
                key={header}
                width={200}
              >
                {header}
              </CustomTableHeaderCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {projects.length
            ? projects.map(project => (
              <CustomTableRow
                clickable={true}
                key={project.projectId}
                onClick={() => {
                  setChosenProject(project.projectId);
                  setOpenModal(true);
                }}
              >
                {[
                  project.projectName,
                  project.totalEstimate,
                  project.costEstimate,
                  project.cost
                ].map(field => (
                  <CustomTableCell>
                    {field}
                  </CustomTableCell>
                ))}
                <ColorizedTableCell
                  number={project.difference}
                >
                  {project.difference}
                </ColorizedTableCell>
              </CustomTableRow>
            ))
            : (
              <TableRow>
                <CustomTableCell colSpan={5}>Проекты отсутствуют</CustomTableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    )
  }

  return (
    <>
      <CustomTable data={data?.getCostReport} tableContent={content}/>
      {openModal && chosenProject && (
        <ReportModal
          title={data?.getCostReport?.find(project => project.projectId === chosenProject)?.projectName}
          reportType='differenceInMoney'
          open={openModal}
          closeHandler={() => {
            setOpenModal(false);
            setChosenProject('');
          }}
          projectId={chosenProject}
          dateRange={dateRange}
        />
      )}
    </>
  )
}