import React from "react";
import { TableRow, Box } from "@material-ui/core";
import { CustomTableCell, CustomTableCellNoBottom } from "../../../shared/CustomTable/CustomTable.style";
import { convertSecondsToHoursHandler } from "../../../shared/Utils/ConvertOperations/convertSecondsToHoursHandler";

export const WorkloadTable = ({ projects }: { projects: any[] }) => {
  return (
    <>
      {projects.length
        ? projects.map((project, i) => (
          <TableRow key={project.projectName}>
            {projects.length - 1 !== i ? <CustomTableCellNoBottom /> : <CustomTableCell />}
            <CustomTableCell width={100}>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <span>{project.projectName}</span>
                <span>{convertSecondsToHoursHandler(project.totalSeconds) || 0}ч</span>
              </Box>
            </CustomTableCell>
          </TableRow>
        ))
        : (
          <TableRow>
            <CustomTableCell colSpan={2}>
              Проекты отсутствуют
            </CustomTableCell>
          </TableRow>
        )
      }
    </>
  )
}