import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  ListItem,
} from "@material-ui/core";
import {
  CheckBoxUser,
  EventDatesUserContent,
  EventField,
  EventGroupUserControl,
  EventGroupUserList,
} from "../Event.style";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { useMutation, useQuery } from "@apollo/client";
import {
  EventDatePresentUserDocument,
  EventDatePresentUserMutation,
  GetEventDateDocument,
  GetEventDateQuery,
} from "../../../generated/graphql";
import { LoadingBox, ButtonCustom } from "../../shared/Style/Style";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";

interface IEventDatesUserList{
  eventDateId:string;
  open:boolean;
  close():void;
}

export const EventDatesUserListModal = ({ open, close, eventDateId }:IEventDatesUserList) => {
  const [searchedUser, setSearchedUser] = useState("");
  const [userList, setUserList] = useState([]);
  const [requestError, setRequestError] = useState(false);

  const { data, loading, error } = useQuery<GetEventDateQuery>(
    GetEventDateDocument,
    {
      variables: {
        id: eventDateId,
      },
    }
  );

  const [
    eventDatePresentUser,
    { loading: eventDatePresentUserLoading, error: eventDatePresentUserError },
  ] = useMutation<EventDatePresentUserMutation>(EventDatePresentUserDocument, {
    refetchQueries: [
      {
        query: GetEventDateDocument,
        variables: {
          id: eventDateId,
        },
      },
    ],
  });

  const choseUserHandler = (userId: string | null) => {
    if (!userId) {
      return;
    }
    if (userList.find((id) => id === userId)) {
      setUserList([...userList.filter((id) => id !== userId)]);
      return;
    }
    setUserList([...userList, userId]);
  };

  const eventDatePresentUserHandler = async () => {
    try {
      userList.forEach((userIds) =>
        eventDatePresentUser({ variables: { userIds: [userIds], eventDateId } })
      );
      close();
    } catch (e) {
      setRequestError(true);
    }
  };

  const closeHandler = () => {
    setUserList([]);
    setSearchedUser("");
    close();
  };

  let content;

  if (loading) {
    return (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }
  if (error) {
    return <ErrorSnackbar error={error} />;
  }

  if (data) {
    const users = data?.getEventDate?.wereNotPresent;

    const searchUsers = users
      .map((user) => {
        if (
          !user.firstName
            .toLocaleLowerCase()
            .includes(searchedUser.toLocaleLowerCase()) &&
          !user.lastName
            .toLocaleLowerCase()
            .includes(searchedUser.toLocaleLowerCase())
        ) {
          return null;
        }
        return user;
      })
      .filter((user) => user);

    content = (
      <>
        <EventField
          fullWidth
          label="Поиск участников"
          value={searchedUser}
          onChange={({ target }) => setSearchedUser(target.value)}
        />

        <EventGroupUserList>
          {searchUsers?.length
            ? searchUsers?.map((user, i) => {
                if (user === null) {
                  return null;
                }

                return (
                  <ListItem button key={user.id} disabled={false}>
                    <EventGroupUserControl
                      control={
                        <CheckBoxUser
                          checked={!!userList.find((id) => id === user?.id)}
                          onChange={() => choseUserHandler(user?.id)}
                        />
                      }
                      label={`${user?.firstName} ${user?.lastName}`}
                    />
                  </ListItem>
                );
              })
            : "Нету участников"}
        </EventGroupUserList>
        <ButtonCustom onClick={eventDatePresentUserHandler}>
          Добавить
        </ButtonCustom>

        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={
            eventDatePresentUserError && "Ошибка при добавении участников"
          }
        />
      </>
    );
  }

  return (
    <Dialog open={open}>
      <CloseIconButton close={closeHandler} />
      <Box textAlign="center" mb={2}>
        <DialogTitle>Список участников </DialogTitle>
      </Box>
      <EventDatesUserContent style={{ width: "400px" }}>
        {content}
      </EventDatesUserContent>
    </Dialog>
  );
};
