import styled from "styled-components";
import { Box, DialogTitle } from "@material-ui/core";
import { lightBlack } from "../../../../shared/Style/Style";

export const Container = styled.div`
  position: relative;
  padding: 20px;
  width: 605px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top:  40px;
  margin-bottom: 20px;
  gap: 10px;
`;

export const Title = styled(DialogTitle)`
   display: flex;
   align-items: center;
   margin-bottom: 20px;
   font-size: 24px;
   font-weight: 700;
   line-height: 28px;
 `;

export const Context = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const FormContainer = styled.div`
  width: 75%;
`;

export const AnimationInput = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

export const AnimationTitle = styled.span`
  color: ${lightBlack};
  font-size: 14px;
  line-height: 36px;
`;
