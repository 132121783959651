import { TableRow, Box, Collapse } from "@material-ui/core";
import React, { useState } from "react";
import { ProjectTask } from "../../generated/graphql";
import { DEFAULT_AVATAR_URL } from "../shared/constants";
import { CustomTableCell, CustomTableCellNoBorder, CustomTableRow } from "../shared/CustomTable/CustomTable.style";
import { CheckboxStyled, SmallUserAvatarStyle, UserAvatarStyle } from "../shared/Style/Style";
import { HoverNameContainer } from "./KanbanAddTasksModal.style";

interface ITasksListProps {
  tasks: ProjectTask[];
  ids: string[];
  setIds(ids: string[]): void;
  disabled?: boolean;
}

export const TasksList = ({ tasks, ids, setIds, disabled, }: ITasksListProps) => {
  const [hover, setHover] = useState(false);

  const toggleTaskHandler = (incomingId: string): void => {
    if (disabled) {
      return;
    }

    setIds([...ids, incomingId]);
  }

  return (
    <>
      {tasks.length
        ? tasks.map(({ id, name, assignee }) => (
          <CustomTableRow
            key={id}
            clickable={true}
            onClick={() => toggleTaskHandler(id)}
          >
            <CustomTableCellNoBorder
              transparent={true}
              padding='none'
            >
              <CheckboxStyled
                checked={ids.includes(id)}
              />
            </CustomTableCellNoBorder>

            <CustomTableCellNoBorder
              transparent={true}
            >
              {name}
            </CustomTableCellNoBorder>

            <CustomTableCellNoBorder
              padding='none'
              transparent={true}
            >
              <UserAvatarStyle
                prim={assignee?.photo || DEFAULT_AVATAR_URL}
                size={36}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              />

              <Collapse
                in={hover}
                collapsedSize='0px'
              >
                <HoverNameContainer>
                  {assignee?.firstName} {assignee?.lastName}
                </HoverNameContainer>
              </Collapse>
            </CustomTableCellNoBorder>
          </CustomTableRow>
        ))
        : (
          <TableRow>
            <CustomTableCell colSpan={3}>В данном этапе задачи отсутствуют</CustomTableCell>
          </TableRow>
        )
      }
    </>
  )
}