import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  DeleteAchieveDocument,
  GetAchievesDocument,
  GetAchievesQuery,
  PublishAchieveDocument,
  QueryGetAchievesArgs
} from "../../generated/graphql";
import { useRequestError } from "../../hooks";
import { AchievementsTable } from "./AchievementsTable";
import { Box, Container } from "@material-ui/core";
import { NavBar } from "../shared/NavBar";
import { CustomTablePagination } from "../shared/CustomTablePagination";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { LoadingProgress } from "../shared/LoadingProgress";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { Header, ButtonPlus } from "../shared/Style/Style";
import { navItems } from "../../constants/navItemMobileApp";

export const Achievements = () => {
  const { push } = useHistory();
  const { appId } = useParams<{ [key: string]: string }>();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [achievementIdToDelete, setAchievementIdToDelete] = useState<string | null>(null);
  const [confirmationModalOpened, setConfirmationModalOpened] = useState(false);

  const getAchievesVariables: QueryGetAchievesArgs = {
    limit: rowsPerPage,
    skip: page * rowsPerPage,
  };

  const {
    data: getAchievesData,
    loading: getAchievesLoading,
    error: getAchievesError,
  } = useQuery<GetAchievesQuery>(GetAchievesDocument, {
    variables: getAchievesVariables,
    fetchPolicy: "network-only",
  });

  const [publishAchieve, {
    loading:publishAchieveLoading,
    error: publishAchieveError,
  }] = useMutation(PublishAchieveDocument, {
    refetchQueries: [{
      query: GetAchievesDocument,
      variables: getAchievesVariables,
    }],
  });

  const [deleteAchieve, {
    loading: deleteAchieveLoading,
    error: deleteAchieveError,
  }] = useMutation(DeleteAchieveDocument, {
    refetchQueries: [{
      query: GetAchievesDocument,
      variables: getAchievesVariables,
    }],
  });

  const handleAchieveDeletion = async () => {
    try {
      await deleteAchieve({ variables: { id: achievementIdToDelete } });
    } catch (e) {
      console.error(e);
    } finally {
      setConfirmationModalOpened(false);
      setAchievementIdToDelete(null);
    }
  };

  const handleAchievePublication = async (id: string) => {
    try {
      await publishAchieve({ variables: { id } });
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpen = () => {
    setConfirmationModalOpened(true);
  };

  const handleClose = () => {
    setConfirmationModalOpened(false);
    setAchievementIdToDelete(null);
  };

  const disableOnLoading = getAchievesLoading || publishAchieveLoading || deleteAchieveLoading;
  const error = getAchievesError || publishAchieveError || deleteAchieveError;
  const { requestError, setRequestError } = useRequestError(error);

  const errorMessage = (getAchievesError && "При загрузке списка наград произошла ошибка")
    || (publishAchieveError && "При публикации награды произошла ошибка")
    || (deleteAchieveError && "При удалении награды произошла ошибка");

  let content = null;
  let pagination = null;

  if (disableOnLoading) {
    content = <LoadingProgress />;
  }

  if (getAchievesData) {
    const getAchieves = getAchievesData?.getAchieves;
    const achievements = getAchieves?.achieves;

    content = (
      <AchievementsTable
        achievements={achievements}
        setAchievementIdToDelete={setAchievementIdToDelete}
        openConfirmationModal={handleOpen}
        publishAchieve={handleAchievePublication}
      />
    );

    pagination = (
      <>
        {achievements?.length > 0 ? (
          <CustomTablePagination
            rowsPerPageOptions={[10, 20]}
            count={getAchieves?.total ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header>Награды</Header>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        mt="40px"
        mb="26px"
      >
        <NavBar items={navItems} margin="0" />
        <ButtonPlus
          width={179}
          variant="contained"
          disabled={disableOnLoading}
          onClick={() => push(`/mobileApps/${appId}/achievements/create`)}
        >
          Добавить
        </ButtonPlus>
      </Box>

      {pagination}

      {content}

      {pagination}

      <ConfirmationModal
        open={confirmationModalOpened}
        content="Вы уверены, что хотите удалить награду?"
        onConfirm={handleAchieveDeletion}
        onClose={handleClose}
      />

      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
    </Container>
  );
};
