import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import { useQuery } from "@apollo/client";
import {
  GetApplicationsDocument,
  GetApplicationsQuery,
  Application,
} from "../../generated/graphql";

import { Box, CircularProgress, Container, Table } from "@material-ui/core";

import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { Menu } from "../shared/Menu";
import { MobileAppCreateModal } from "../MobileAppModal/MobileAppCreateModal";
import { MobileAppDeleteModal } from "../MobileAppModal/MobileAppDeleteModal";

import {
  ButtonCustom,
  ButtonPlus,
  Header,
  LoadingBox,
  MainTableCell,
  MainTableHeaderTitle,
  MainTableImage,
  WhiteLink,
} from "../shared/Style/Style";
import { MobileAppTableRow, EmptyMobileAppTableRow } from "./MobileApps.style";

import { DEFAULT_AVATAR_URL } from "../shared/constants";
import { cutStringHandler } from "../shared/Utils/StringOperations/cutStringHandler";
import { CustomTablePagination } from "../shared/CustomTablePagination";

const headers = ["Фото", "Название", "Описание", "IOS", "Android", " "];

export const MobileApps = () => {
  const history = useHistory();

  //#region state

  const [chosenApp, setChosenApp] = useState<Application>(null);

  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //#endregion

  // #region pagination

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // #endregion

  const {
    data: appsData,
    loading: appsLoading,
    error: appsError,
    refetch: refetchApps,
  } = useQuery<GetApplicationsQuery>(GetApplicationsDocument, {
    variables: {
      limit: rowsPerPage,
      skip: page * rowsPerPage,
    },
  });

  let content;

  if (appsLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (appsError) {
    content = <ErrorSnackbar error={appsError} />;
  }

  if (appsData) {
    const appsList = appsData.getApplications?.applications;

    const menuItems = [
      {
        name: "Редактировать",
        action: () => history.push(`/mobileApps/${chosenApp.id}/edit`),
      },
      {
        name: " Удалить",
        action: () => setDeleteModal(true),
        color: "red",
      },
    ];

    const pagination = (
      <CustomTablePagination
        rowsPerPageOptions={[10, 20]}
        count={appsData.getApplications?.total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    );

    content = (
      <>
        <Box textAlign="right" mt={3} mb={3}>
          <ButtonPlus
            width={179}
            variant="contained"
            onClick={() => setCreateModal(true)}
          >
            Добавить
          </ButtonPlus>
        </Box>

        <Box mb={2}>{pagination}</Box>

        <Table>
          <MobileAppTableRow>
            {headers.map((header) => (
              <MainTableHeaderTitle key={header}>{header}</MainTableHeaderTitle>
            ))}
          </MobileAppTableRow>
        </Table>

        {appsList?.length ? (
          appsList?.map((app) => (
            <MobileAppTableRow
              key={app.id}
              onClick={() => history.push(`/mobileApps/${app.id}/edit`)}
            >
              <MainTableCell>
                <MainTableImage
                  prim={app.photo ? app.photo : DEFAULT_AVATAR_URL}
                />
              </MainTableCell>

              <MainTableCell>{app.name}</MainTableCell>

              <MainTableCell>{cutStringHandler(app.description)}</MainTableCell>

              <MainTableCell>
                {app.appStoreLink ? (
                  <ButtonCustom>
                    <WhiteLink href={app.appStoreLink}>скачать</WhiteLink>
                  </ButtonCustom>
                ) : (
                  "-"
                )}
              </MainTableCell>

              <MainTableCell>
                {app.playMarketLink ? (
                  <ButtonCustom>
                    <WhiteLink href={app.playMarketLink}>скачать</WhiteLink>
                  </ButtonCustom>
                ) : (
                  "-"
                )}
              </MainTableCell>

              <MainTableCell>
                <Menu
                  vertical={36}
                  horizontal={12}
                  width={170}
                  setItem={() => setChosenApp(app)}
                  items={menuItems}
                />
              </MainTableCell>
            </MobileAppTableRow>
          ))
        ) : (
          <EmptyMobileAppTableRow>
            Приложения отсутствуют
          </EmptyMobileAppTableRow>
        )}

        <Box mt={2}>{pagination}</Box>
      </>
    );
  }

  return (
    <Container maxWidth="lg">
      <Header>Приложение</Header>

      {content}

      <MobileAppCreateModal
        open={createModal}
        close={() => setCreateModal(false)}
        refetchVariables={{
          limit: rowsPerPage,
          skip: page * rowsPerPage,
        }}
        entity="приложения"
      />

      <MobileAppDeleteModal
        open={deleteModal}
        close={() => setDeleteModal(false)}
        item={chosenApp}
        refetch={() => refetchApps()}
      />
    </Container>
  );
};
