import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  AssignProjectTaskLabelDocument,
  AssignProjectTaskLabelMutation,
  GetAvailableProjectTaskLabelsDocument,
  GetAvailableProjectTaskLabelsQuery,
  GetProjectTaskDocument,
  ProjectTaskLabel,
  RemoveProjectTaskLabelDocument,
  RemoveProjectTaskLabelMutation,
} from "../../../generated/graphql";

import { CustomIconButton } from "../../shared/Buttons/CustomIconButton";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";

import { CircularProgress, Box, TextField, Collapse } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Close } from "@material-ui/icons";

import { lightBlack, LoadingBox } from "../../shared/Style/Style";
import { KanbanModalSubtitle, LabelItem } from "../KanbanTaskModal.style";

interface ILabelsProps {
  projectId: string;
  projectTaskId: string;
  disabledOnLoading?: boolean;
  labels?: ProjectTaskLabel[];
}

export const Labels = ({
  disabledOnLoading,
  projectId,
  projectTaskId,
  labels,
}: ILabelsProps) => {
  const [currentLabel, setCurrentLabel] = useState("");

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [
    addLabel,
    { data: addLabelData, loading: addLabelLoading, error: addLabelError },
  ] = useMutation<AssignProjectTaskLabelMutation>(
    AssignProjectTaskLabelDocument,
    {
      refetchQueries: [
        {
          query: GetAvailableProjectTaskLabelsDocument,
          variables: {
            projectId,
            projectTaskId,
          },
        },
        {
          query: GetProjectTaskDocument,
          variables: {
            id: projectTaskId,
          },
        },
      ],
    }
  );

  const [
    removeLabel,
    {
      data: removeLabelData,
      loading: removeLabelLoading,
      error: removeLabelError,
    },
  ] = useMutation<RemoveProjectTaskLabelMutation>(
    RemoveProjectTaskLabelDocument,
    {
      refetchQueries: [
        {
          query: GetAvailableProjectTaskLabelsDocument,
          variables: {
            projectId,
            projectTaskId,
          },
        },
        {
          query: GetProjectTaskDocument,
          variables: {
            id: projectTaskId,
          },
        },
      ],
    }
  );

  useEffect(() => {
    if (addLabelError) {
      setRequestError(true);
      setCurrentLabel("");
      setErrorMessage("При добавлении метки произошла ошибка");
    }
  }, [addLabelError]);

  useEffect(() => {
    if (removeLabelError) {
      setRequestError(true);
      setErrorMessage("При удалении метки произошла ошибка");
    }
  }, [removeLabelError]);

  useEffect(() => {
    if (addLabelData) {
      setRequestError(false);
      setErrorMessage("");
      setCurrentLabel("");
    }
  }, [addLabelData]);

  useEffect(() => {
    if (removeLabelData) {
      setRequestError(false);
      setErrorMessage("");
    }
  }, [removeLabelData]);

  const disableOnLabelsLoading = removeLabelLoading || addLabelLoading;

  const [
    getLabels,
    { data: labelsData, loading: labelsLoading, error: labelsError },
  ] = useLazyQuery<GetAvailableProjectTaskLabelsQuery>(
    GetAvailableProjectTaskLabelsDocument,
    {
      variables: {
        projectId,
        projectTaskId,
      },
    }
  );

  useEffect(() => {
    if (projectTaskId) {
      getLabels();
    }
  }, [projectTaskId]);

  let content;

  if (labelsLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (labelsError) {
    content = <ErrorSnackbar error={labelsError} />;
  }

  if (labelsData) {
    const labelsFromServer = labelsData.getAvailableProjectTaskLabels;

    content = (
      <Box>
        <Collapse in={!!labelsFromServer.length} collapsedSize="0px">
          <Box mb="10px">
            <Autocomplete
              fullWidth
              disabled={disabledOnLoading || disableOnLabelsLoading}
              options={labelsFromServer?.map((label) => label.id)}
              getOptionLabel={(option) => {
                const name = labelsFromServer?.find(
                  (label) => label.id === option
                )?.name;
                return (
                  (name === "bug" && "баг") ||
                  (name === "additionalTask" && "доп. задача") ||
                  name
                );
              }}
              value={currentLabel}
              onChange={(_, value) => {
                if (!value) {
                  return;
                }

                if (typeof value === "string") {
                  setCurrentLabel(value);
                  addLabel({ variables: { projectTaskId, labelId: value } });
                }
              }}
              renderInput={(params) => <TextField {...params} label="Метки" />}
            />
          </Box>
        </Collapse>

        <Box display="flex" alignItems="center">
          {labels?.length ? (
            labels.map((label) => (
              <LabelItem key={label.id} color={label.color}>
                <Box mr={1}>
                  {(label.name === "bug" && "баг") ||
                    (label.name === "dopTask" && "доп. задача") ||
                    label.name}
                </Box>

                <CustomIconButton
                  mainColor={lightBlack}
                  disabled={disabledOnLoading || disableOnLabelsLoading}
                  fz={14}
                  callback={() =>
                    removeLabel({
                      variables: { projectTaskId, labelId: label.id },
                    })
                  }
                  children={<Close fontSize="inherit" />}
                />
              </LabelItem>
            ))
          ) : (
            <Box display="flex" justifyContent="center" flexGrow={1}>
              Метки отсутствуют
            </Box>
          )}
        </Box>

        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message={errorMessage}
        />
      </Box>
    );
  }

  return (
    <>
      <KanbanModalSubtitle>Метки</KanbanModalSubtitle>
      {content}
    </>
  );
};
