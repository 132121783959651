import styled from "styled-components";

import { KnowledgeBaseBlockTypeEnum } from "../../generated/graphql";

import { lightBlack, ModalInput, primary, secondary } from "../shared/Style/Style";

import { Button, IconButton } from "@material-ui/core";
import { CustomIconButton } from "../shared/Buttons/CustomIconButton";

import { getFontSizeOfBlockInput } from "../shared/Utils/OtherOperations/getFontSizeOfBlockInput";
import { getMarginOfBlockInputLabel } from "../shared/Utils/OtherOperations/getMarginOfBlockInputLabel";

const titles = [ KnowledgeBaseBlockTypeEnum.Header, KnowledgeBaseBlockTypeEnum.Subtitle ];

export const BlockInput = styled(ModalInput)<{ type?: KnowledgeBaseBlockTypeEnum }>`
  & .MuiFormLabel-root {
    font-size: ${({ type }) => getFontSizeOfBlockInput(type)};
    font-weight: ${({ type }) => titles.includes(type) ? 'bold' : 'normal'};
  }

  & .MuiInputBase-root {
    font-size: ${({ type }) => getFontSizeOfBlockInput(type)};
    font-weight: ${({ type }) => titles.includes(type) ? 'bold' : 'normal'};
  }

  & .MuiInput-underline:before {
    border-bottom-color: ${({ disabled }) => disabled ? 'transparent' : '#eee'};
  }

  & label + .MuiInput-formControl {
    margin-top: ${({ type }) => getMarginOfBlockInputLabel(type)};
  }

  & .MuiInputBase-input {
    padding: ${({ type }) => type === KnowledgeBaseBlockTypeEnum.List ? '14px 7px' : '6px 0 7px'}
  }
`;

export const ButtonWithIcon = styled(Button)<{ width?: number }>`
  display: flex;
  width: ${({ width }) => width ? `${width}px` : 'inherit'};
  justify-content: space-between;
  align-items: center;
  background-color: transparent;

  &:not(:first-of-type) {
    margin-left: 24px;
  }
`;

export const ButtonTxt = styled.div`
  margin-right: 10px;
`;

export const EditorContainer = styled.div<{ border_radius?: string }>`
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;

  & .cropper-crop-box, .cropper-view-box {
    border-radius: 0%;
  }
`;

export const TextBlockLabel = styled.label<{ content: string }>`
  color: ${secondary};
  font-size: ${(({ content }) => content ? '11px' : '14px')};
`;

export const TextBlockContent = styled.div`
  white-space: break-spaces;
  word-break: break-word;
`;

export const FormulaButton = styled(IconButton)`
  font-size: 15px;
  color: ${lightBlack};

  & * {
    margin: 0 !important;
    max-height: 30px !important;
    max-width: 30px !important;
  }
`;
