import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { defaultMaterialTheme } from "../shared/Style/Style";
import { CustomTableDatePicker } from "../shared/CustomTable/CustomTable.style";

interface EpicsDatePickerProps {
  disabled: boolean;
  maxDate?: Date | null | string;
  minDate?: Date | null | string;
  value: Date;
  onChange(date: Date): void;
  error?: boolean;
}

export const EpicsDatePicker = ({disabled, maxDate,minDate, value, onChange, error}: EpicsDatePickerProps) => {
  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <CustomTableDatePicker
        fontSize={12}
        disabled={disabled}
        fullWidth
        variant="inline"
        format="dd.MM.yyyy"
        maxDate={
          maxDate
        }
        minDate={minDate}
        value={
          value
        }
        onChange={(date) =>
          onChange(date)
        }
        autoOk={true}
        error={error}
      />
    </ThemeProvider>
  );
};
