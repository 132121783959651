import styled from "styled-components";
import { FormControlLabel, List } from "@material-ui/core";
import { SmallUserAvatarStyle } from "../../shared/Style/Style";

export const ClientsList = styled(List)`
  max-height: 240px;
  overflow-y: auto;
`;

export const ClientLabel = styled(FormControlLabel)`
  width: 100%;
`;

export const ClientAvatar = styled(SmallUserAvatarStyle)`
  width: 36px;
  height: 36px;
`;