import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CONFIRM_USER } from "../../../graphql/mutations/auth";
import { Wrapper } from "../../shared/Style/Style";
import { useStyles } from "../Login";
import { Toolbar, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AppBarStyle, LoginForm, LoginLink } from "../Login.style";

export const Confirm = () => {
  const [active, setActive] = useState({});
  const { token } = useParams() as any;
  const [confirmUser, { loading: confirmLoading }] = useMutation(CONFIRM_USER, {
    variables: {
      token,
    },
  });

  const classes = useStyles();

  useEffect(() => {
    confirmUser().then((res) => {
      setActive(res);
    });
  }, [confirmUser]);

  if (confirmLoading) {
    return (
      <Backdrop className={classes.backdrop} open={confirmLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <LoginForm>
      <AppBarStyle position="fixed">
        <Toolbar />
      </AppBarStyle>
      {active && (
        <Wrapper>
          <Typography variant="h5" component="h5" gutterBottom>
            Спасибо за регистрацию
          </Typography>
          <Typography variant="subtitle2" component="h5">
            Ваша почта подтверждена. Вы сможете войти после проверки ваших данных администратором
          </Typography>

          <Button
            className={"button__backLogin"}
            color="primary"
            component={LoginLink}
            to={"/login"}
          >
            Вернуться на главную
          </Button>
        </Wrapper>
      )}
    </LoginForm>
  );
};
