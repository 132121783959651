import React, { useState } from "react";

import {
  MutationFunctionOptions,
  OperationVariables,
  DefaultContext,
  ApolloCache
} from "@apollo/client";
import {
  User,
  CreateTrackerContractMutation,
  DeleteTrackerContractMutation
} from "../../../generated/graphql";

import { DeleteIconButton } from "../../shared/Buttons/DeleteIconButton";
import { ButtonPlus } from "../../shared/Style/Style";

import { ProjectContractModal } from "../Project/ProjectContractModal";

import { Box, Grid, Typography, ListItemText,  List, ListItem } from "@material-ui/core";

import { FlexBetween, PersonalData, ListCardClubsGroups } from "./UserEdit.style";

interface IUserContractsProps {
  disabledOnLoading: boolean;
  user?: User;
  createContract: (options?: MutationFunctionOptions<CreateTrackerContractMutation, OperationVariables, DefaultContext, ApolloCache<any>>) => Promise<any>;
  deleteContract: (options?: MutationFunctionOptions<DeleteTrackerContractMutation, OperationVariables, DefaultContext, ApolloCache<any>>) => Promise<any>;
}

export const UserContracts = ({ disabledOnLoading, user, createContract, deleteContract }: IUserContractsProps) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <FlexBetween style={{ marginTop: '20px' }}>
      <Box>
        <Grid container>
          <Typography
            component={PersonalData}
            variant="subtitle1"
            gutterBottom
          >
            Контракты
          </Typography>
          <ButtonPlus
            width={250}
            variant="contained"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Добавить контракт
          </ButtonPlus>
        </Grid>

        <ListCardClubsGroups>
          <List
            component="nav"
          >
            {user?.trackerContracts?.map(({ id, project }) => (
              <Grid
                item
                md={4}
                xs={6}
                key={id}
              >
                <ListItem
                  button
                  style={{ borderBottom: "1px solid #eee", marginBottom: "0.5rem" }}
                >
                  <ListItemText primary={`${project?.name}`} />

                  <DeleteIconButton
                    disabled={disabledOnLoading}
                    deleteHandler={() => {
                      deleteContract({
                        variables: {
                          contractId: id
                        },
                      });
                    }}
                    item='контракт'
                  />

                </ListItem>
              </Grid>
            ))}
          </List>
        </ListCardClubsGroups>

        <ProjectContractModal
          disabled={disabledOnLoading}
          open={isModalOpen}
          close={() => setModalOpen(false)}
          createContract={createContract}
          user={user}
        />
      </Box>
    </FlexBetween>
  )
}
