import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Project } from "../../../generated/graphql";
import { ProjectStageModal } from "../../ProjectStageModal";
import { DeleteIconButton } from "../../shared/Buttons/DeleteIconButton";
import { ButtonCustomWithDarkText } from "../../shared/Style/Style";
import { noData, renderSwitch } from "./contentConstants";
import {
  InfoRow,
  TableRow,
  Deadline,
  ProjectName,
  Responsible,
  Status,
} from "./Projects.style";

interface ProjectInfoRowProps {
  key: number;
  project: Project;
  deleteProject(projectId: string): void;
}

export const ProjectInfoRow = ({
  key,
  project,
  deleteProject,
}: ProjectInfoRowProps) => {
  const history = useHistory();
  const [isProjectStageModalActive, setProjectStageModalActive] =
    useState(false);

  const closeProjectStageModalHandler = () => {
    setProjectStageModalActive(false);
  };

  return (
    <InfoRow key={key} style={{ cursor: "pointer" }}>
      <TableRow
        key={key}
        onClick={() => history.push(`/project/${project.id}/edit`)}
      >
        {!!project?.name ? (
          <ProjectName>
            <div>{project.name}</div>
          </ProjectName>
        ) : (
          <span>{noData}</span>
        )}
        {!!project?.status?.id ? (
          <Status>
            <div>{renderSwitch(project?.status?.id)}</div>
          </Status>
        ) : (
          <span>{noData}</span>
        )}
        {!!project?.projectManager ? (
          <Responsible>
            <div>{`${project?.projectManager.firstName}  ${project?.projectManager.lastName}`}</div>
          </Responsible>
        ) : (
          <span>{noData}</span>
        )}
        {!!project?.finishDate ? (
          <Deadline>
            <div>
              {project.finishDate
                .toString()
                .split("T")[0]
                .split("-")
                .reverse()
                .join(".")}
            </div>
          </Deadline>
        ) : (
          <span>{noData}</span>
        )}

        <ButtonCustomWithDarkText
          type="button"
          active={true}
          onClick={(e) => {
            e.stopPropagation();
            setProjectStageModalActive(true);
          }}
        >
          Посмотреть этапы
        </ButtonCustomWithDarkText>

        <DeleteIconButton
          disabled={false}
          deleteHandler={() => deleteProject(project.id)}
          item="проект"
          fz={20}
          withStopPropagation
        />
      </TableRow>

      {isProjectStageModalActive && (
        <ProjectStageModal
          isOpen={isProjectStageModalActive}
          closeProjectStageModalHandler={closeProjectStageModalHandler}
          project={project}
        />
      )}
    </InfoRow>
  );
};
