import React from "react";
import { Container, Title, Text, SubtitleCenter } from "./PrivatePolicy.style";
import { Box } from "@material-ui/core";

export const PaymentPolicyEn = () => {
  return (
    <Container>
      <Title>PAYMENT POLICY</Title>

      <Box mt={4} style={{ textIndent: "40px" }}>
        <Text>
          This Payment Policy (hereinafter referred to as the{" "}
          <strong>“Policy”</strong>)is a legally binding document and is part of
          our user agreement, representing the rules for collecting and making
          payments in the ArBook interactive system, which includes such
          products as ArBook, VR_Lab, AR_Teacher and AR_Book Market; all the
          above-mentioned products are part of the interactive system and are
          subject to the relevant rules and policies.
          <strong> Limited liability company “AR BOOK UA”</strong>, EDRPOU:
          44973818, in the person of the director Olga Mykolaivna Holovakhina,
          acting in accordance with the Charter, has developed this Policy on
          the following.
        </Text>
      </Box>

      <Box mt={4} style={{ textIndent: "40px" }}>
        <Text style={{ margin: "10px 0 0 0" }}>
          This Policy is represented by and acts on behalf of a legal entity,
          the ArBook interactive system, which provides Users with the
          opportunity to work to the full extent with such products of the
          system as AR_Teacher and AR_Book Market.
        </Text>
      </Box>

      <Box mt={4} style={{ textIndent: "40px" }}>
        <SubtitleCenter>
          GENERAL PROVISIONS ON THE COST OF SERVICES
        </SubtitleCenter>
        <Text style={{ margin: "10px 0 0 0" }}>
          The platform provides a set of functionalities for creating or
          selecting ready-made lessons, using easy-to-understand tools, and for
          increasing the effectiveness of learning, in the form of services, the
          list of which may be found on the following website:{" "}
          <a href="https://arbook.info/">https://arbook.info/</a>.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          For access to certain functionalities of the Website, the User may be
          charged a fee in accordance with the user agreement. Information on
          the cost of Services (Tariffs) is indicated on the following Website{" "}
          <a href="https://arbook.info/">https://arbook.info/</a>. КThe User
          confirms that he is familiar with the Tariffs and special conditions.
        </Text>
      </Box>

      <Box mt={4} style={{ textIndent: "40px" }}>
        <SubtitleCenter>METHODS OF PAYMENT</SubtitleCenter>
        <Text style={{ margin: "10px 0 0 0" }}>
          Payments are made by the methods indicated on the website; namely, by
          bank card, through payment systems, and for corporate clients- through
          a business account on the terms and pursuant to the conditions set out
          in the agreement.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          At the same time, the User acknowledges that we may change our payment
          service provider partners and require them to transfer his information
          to other service providers.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          Payment for Services is made in the User's Personal Account. The
          moment of payment is considered to be the receipt of funds on our
          account. Paid services may not be provided until payment is made.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          The user is solely responsible for the correctness of the payments
          made by him.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          After confirming the selected service, a secure window will pop up
          with the payment page of the processing center, where the User must
          enter his bank card details. The 3D Secure protocol is used for
          additional authentication of the cardholder. If the User's bank
          supports this technology, it will be redirected to its server for
          additional identification. Information about the rules and methods of
          additional identification, the User must clarify with the Bank that
          issued him the bank card.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          The processing center protects and processes the User's bank card data
          according to the PSI DSS 3.0 standard. Information is transferred to
          the payment gateway using SSL encryption technology. Further transfer
          of information takes place via closed banking networks, which have the
          highest level of security. The payment provider does not transfer the
          User's card data to the Platform or other third parties. The 3D Secure
          protocol is used for additional authentication of the cardholder.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          Personal data provided by the User (name, address, phone number,
          e-mail, credit card number) are confidential and not subject to
          disclosure. The User's credit card data is transmitted only in
          encrypted form and is not stored on the company's WEB server.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          All operations with payment cards take place in accordance with the
          requirements of Visa International, MasterCard and other payment
          systems. When transferring information, special online card payment
          security technologies are used, data processing is carried out on a
          secure high-tech server of the processing company.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          All commissions and fees are paid by the User himself. The Platform
          has the right to change the cost of the Services at any time by
          updating the information on the Website or notifying the User through
          the means of communication indicated on the Platform.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          In case of insufficient cash balance for access to any functionality
          of the Platform Services, cash funds after 18 months from the date of
          termination of training are considered to be the benefit of the
          company and are not subject to return. At the same time, the company
          gives the right to apply on an individual basis through a personal
          curator regarding the issue of invoicing for additional payment to the
          full cost in order to be provided services.
        </Text>
      </Box>

      <Box mt={4} style={{ textIndent: "40px" }}>
        <SubtitleCenter>REPAYMENT OF MONEY</SubtitleCenter>
        <Text style={{ margin: "10px 0 0 0" }}>
          In some cases, at the request of the User, repayment of money for the
          Services may be made.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          Recalculation of the money subject to be repaid takes place without
          taking into account special conditions at the maximum rate for the
          Services which were actually provided. Rights to receive free Services
          are cancelled.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          In case we have suspicions about the abuse of this opportunity, we
          have the right to refuse to repay the money.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          All commissions and fees are paid by the User himself.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          The user can request a repayment and get help on other issues by
          contacting us at the following email address:{" "}
          <a href="mailto:Info@arbook.info">Info@arbook.info</a>.
        </Text>
      </Box>

      <Box mt={4} style={{ textIndent: "40px" }}>
        <SubtitleCenter>RECALCULATION OF THE COST OF SERVICES</SubtitleCenter>
        <Text style={{ margin: "10px 0 0 0" }}>
          In the event that the User decides to resume the use after a long
          period of non-use (120 calendar days from the moment of termination of
          use), the Platform has the right to recalculate the price of classes
          in accordance with the price in effect at the time of such renewal or
          recalculate after 540 calendar days from the date of purchase of such
          services.
        </Text>
      </Box>

      <Box mt={4} style={{ textIndent: "40px" }}>
        <SubtitleCenter>DELIVERY</SubtitleCenter>
        <Text style={{ margin: "10px 0 0 0" }}>
          The platform provides services and access to digital products created
          on the basis of the ArBook interactive system, as well as third-party
          services that it uses in accordance with the law in the course of its
          commercial activity.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          The Services are provided via the Internet, including the use of
          software that enables communication and content transfer over the
          Internet between the User and the Platform.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          For this reason, delivery in the form of access to digital content and
          educational services is carried out depending on the selected
          service/product immediately after registration in the personal account
          and/or making payment.
        </Text>
      </Box>

      <Box mt={4} style={{ textIndent: "40px" }}>
        <SubtitleCenter>
          SECURITY CONTROL OVER TRANSMISSION OF PAYMENT DATA
        </SubtitleCenter>
        <Text style={{ margin: "10px 0 0 0" }}>
          The Secure Sockets Layer (SSL certificate) cryptographic protocol is
          used to ensure the security of payments. This technology uses
          asymmetric cryptography to authenticate exchange keys, symmetric
          encryption to preserve privacy, message authentication codes for their
          integrity, allowing for more secure processing of your payments.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          The platform does not process personal data of payers that was
          provided in order to settle payments by the processing center. The
          platform does not store bank card details on its resources, including
          servers, cloud storage, etc. The specified data is processed and
          securely stored by the relevant payment operators; we advise you to
          familiarize yourself with the privacy policy of such payment systems
          in more detail.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          When making payments through payment services, our platform follows
          the rules of international payment systems VISA and MasterCard, the
          logos of which are posted on our website.
        </Text>
      </Box>

      <Box mt={4} style={{ textIndent: "40px" }}>
        <SubtitleCenter>
          DISPLAY OF THE TERMS OF PURCHASE FOR THE CARD HOLDER DURING THE
          ORDERING PROCESS
        </SubtitleCenter>
        <Text style={{ margin: "10px 0 0 0" }}>
          In the "PRICES" section, when choosing the required tariff, the User
          is asked to select the necessary conditions of the tariff and click
          the "BUY" button, after which a window with a payment page pops up,
          where the User fills in the necessary spaces with the details of his
          bank card.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          After completing the order on the platform, the User must enter his
          e-mail address and click the "Pay" button.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          Corporate Users, who have entered into an agreement with the platform,
          make payments on the basis of such an agreement to the platform's
          current account, which will be specified in the relevant agreement.
        </Text>
      </Box>

      <Box mt={4} style={{ textIndent: "40px" }}>
        <SubtitleCenter>OTHER CONDITIONS</SubtitleCenter>
        <Text style={{ margin: "10px 0 0 0" }}>
          Our Platform is not a bank or any other financial institution, so we
          do not provide any financial services. Any payments made through our
          Platform are made solely for the purpose of obtaining and providing
          educational services. Such payments cannot be made and used for any
          fraudulent, criminal or illegal activity.
        </Text>
        <Text style={{ margin: "10px 0 0 0" }}>
          The procedure and terms of payment defined by this policy for full
          access to the functionalities of AR_Teacher and AR_Book Market are
          identical.
        </Text>
      </Box>

      <Box mt={4} mb={4} style={{ textIndent: "40px" }}>
        <SubtitleCenter>
          CONTACTS WITH CUSTOMER SERVICE DEPARTMENT
        </SubtitleCenter>
        <Text style={{ margin: "10px 0 0 0" }}>
          We hope this Payment Policy has answered most of your questions about
          making payments on our website, and we hope you trust us. However, if
          you still feel that you have some unclear points about payments,
          please do not hesitate to contact us. The following email address is
          to be used for such purpose:{" "}
          <a href="mailto:sales@arbook.info">sales@arbook.info</a>
        </Text>
      </Box>
    </Container>
  );
};
