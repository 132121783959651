import { useState } from "react";
import { MobileAppSubtitle } from "../../../MobileAppEdit/MobileAppEdit.style";
import { PurchaseForm } from "./PurchaseForm";
import {
  CreatePurchasePaymentFromAdminPanelMutationVariables,
  useCreatePurchasePaymentFromAdminPanelMutation,
} from "../../../../generated/graphql";
import { useRequestError } from "../../../../hooks";
import { ErrorMessageSnackbar } from "../../../shared/ErrorMessageSnackbar";
import { handleErrorMessage } from "./utils";
import { RefetchPaymentType } from "../types";
import { SuccessSnackbar } from "../../../shared/SuccessSnackbar";
import { LoadingProgressOverlay } from "../../../shared/LoadingProgress";

interface PurchaseProps {
  refetchPayments: { refetch: RefetchPaymentType };
}
export const Purchase = ({ refetchPayments }: PurchaseProps) => {
  const [isSuccessRequest, setIsSuccessRequest] = useState(false);

  const [createPurchase, { error, loading }] =
    useCreatePurchasePaymentFromAdminPanelMutation({
      errorPolicy: "all",
      onCompleted: async () => {
        if (error) return;
        setIsSuccessRequest(true);
        const { refetch } = refetchPayments;
        await refetch();
      },
    });

  const { requestError, setRequestError, errorMessage } = useRequestError(
    error,
    error?.message
  );

  const handleCreatePurchase = async (
    variables: CreatePurchasePaymentFromAdminPanelMutationVariables,
    reset: () => null
  ) => {
    try {
      const { data } = await createPurchase({ variables });
      if (data?.createPurchasePaymentFromAdminPanel) reset();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <MobileAppSubtitle>Товары</MobileAppSubtitle>
      <PurchaseForm
        createPurchasePayment={handleCreatePurchase}
        loading={loading}
      />
      <ErrorMessageSnackbar
        open={requestError}
        message={handleErrorMessage(errorMessage)}
        closeHandler={() => setRequestError(false)}
      />
      <SuccessSnackbar
        open={isSuccessRequest}
        handleClose={() => setIsSuccessRequest(false)}
      />
      {loading && <LoadingProgressOverlay />}
    </>
  );
};
