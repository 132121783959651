import { FC } from "react";
import { useReactiveVar } from "@apollo/client";
import { robotsState } from "../../state";
import { Dialog, Box} from "@material-ui/core";
import { CloseIconButton } from "../../../../shared/Buttons/CloseIconButton";
import { ModalButtons } from "../../../shared/ModalButtons";
import { ModalTitle } from "../../style";
import { RobotGeneralInfo } from "../RobotGeneralInfo";
import { useValidateRobotGeneralFields } from "../../hooks/useValidateRobotGeneralFields";

interface CreateRobotModalProps {
    refetch: () => void;
}

export const CreateRobotModal: FC<CreateRobotModalProps> = ({ refetch }) => {
    const robotGeneralInfo = useReactiveVar(robotsState.robotGeneralInfo);
    const isLoading = useReactiveVar(robotsState.isLoading);

    const validateFields = useValidateRobotGeneralFields();
    
    const closeHandler = () => {
        robotsState.setIsCreateRobotModalOpen(false);
        robotsState.clearRobot();
    };

    const submitHandler = async () => {
        const isValidFields = validateFields();
        
        if (!isValidFields) return;

        await robotsState.createRobot({ input: robotGeneralInfo });

        refetch();

        closeHandler();
    };

    return (
        <Dialog open maxWidth="sm">
            <Box p={2} px={5} py={5} width={500}>
                <CloseIconButton close={closeHandler} />
                
                <ModalTitle>Создание робота</ModalTitle>

                <RobotGeneralInfo />
                
                <ModalButtons
                    isLoading={isLoading}
                    cancelHandler={closeHandler} 
                    submitHandler={submitHandler}
                />
            </Box>
        </Dialog>
    );
 };
