import styled from "styled-components";
import { MainTableRow } from "../shared/Style/Style";

export const MobileAppTableRow = styled(MainTableRow)`
  grid-template-columns: 1fr 1fr 3fr 1.2fr 1.2fr 0.4fr;
  grid-gap: 10px;
  cursor: pointer;
`;

export const MobileAppNotificationsTableRow = styled(MainTableRow)`
  grid-template-columns: 1fr 1fr 5fr 1fr 0.3fr;
`;

export const EmptyMobileAppTableRow = styled(MobileAppTableRow)`
  grid-template-columns: 1fr;
`;
