export interface ModelData {
  name: string;
  itemId: string;
}

export interface ModelDataErrors {
  nameError: string;
  itemIdError: string;
}

export interface ModelFilesErrors {
  glbFileError: string;
  gltfFileError: string;
  usdzFileError: string;
}

export const initialModelData: ModelData = {
  name: "",
  itemId: "",
};

export const initialModelErrors: ModelDataErrors = {
  nameError: "",
  itemIdError: "",
};

export const initialModelFilesErrors: ModelFilesErrors = {
  glbFileError: "",
  gltfFileError: "",
  usdzFileError: "",
};
