import { useReactiveVar } from "@apollo/client";
import { mobileAppModel } from "../../state";
import { Autocomplete } from "@material-ui/lab";
import { ModalInput } from "../../../../shared/Style/Style";

export const SelectTheme = () => { 
    const model = useReactiveVar(mobileAppModel.model);
    const themes = useReactiveVar(mobileAppModel.themes);
    const isLoading = useReactiveVar(mobileAppModel.updateLoading);

    if (!model) return null;
    const { themeId } = model;

    return (
        <Autocomplete
          disabled={isLoading}
          options={themes.map(({ id }) => id)}
          value={themeId}
          onChange={(_, value) => {
            mobileAppModel.setThemeId(value);
          }}
          getOptionLabel={(option) =>
            themes.find(({ id }) => id === option)?.nameUKR || ""
          }
          renderInput={(params) => {
            return (
              <ModalInput
                {...params}
                margin="normal"
                fullWidth
                label="Темы"
              />
            );
          }}
        />
    );
};