import React, { useMemo } from "react";
import { SchoolPlanUsers } from "../../../generated/graphql";
import { createAllParticipants} from "./utils";
import { Table, TableBody, TableCell } from "@material-ui/core";
import { TableContainer, TableRows } from "../Style";

interface SchoolParticipantsProps { 
  planUsers: SchoolPlanUsers;
}

export const SchoolParticipants = (
  { planUsers}: SchoolParticipantsProps
) => {
  const { admins, teachers, pupils, parents } = planUsers || {};

  const participants = useMemo(() => {
    const data = {
      "adminsActual": admins?.current || 0,
      "teachersActual": teachers?.current || 0,
      "teachersPlan": teachers?.plan || 0,
      "pupilsActual": pupils?.current || 0,
      "pupilsPlan": pupils?.plan || 0,
      "parentsActual": parents?.current || 0,
    };
    return createAllParticipants(data);
  }, [admins, teachers, pupils, parents]);

  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          {participants.map(({ name, plan, actual, rate }) => {
            return (
              <TableRows key={name}>
                <TableCell>{name}</TableCell>
                <TableCell align="center">{plan}</TableCell>
                <TableCell align="center">{actual}</TableCell>
                <TableCell align="center">{rate}</TableCell>
              </TableRows>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
