import React, { Dispatch, useEffect, useState } from "react";
import {
  CreateVacancyPlanModalContainer,
  DataPickerContainer,
  ModalErrorTxt,
  ModalHeader,
} from "./CreateVacancyPlanModal.style";
import Modal from "@material-ui/core/Modal";
import { ThemeProvider } from "@material-ui/styles";
import { DatePicker } from "@material-ui/pickers";
import { ButtonCustom, defaultMaterialTheme } from "../../../shared/Style/Style";

interface CreateVacancyPlanModalProps {
  open: boolean;
  setOpen(): void;
  setMonths(month: { id: string; name: string }[]): void;
  months: { id: string; name: string }[];
}

export const CreateVacancyPlanModal = ({
  open,
  setOpen,
  setMonths,
  months,
}: CreateVacancyPlanModalProps) => {
  const [date, setDate] = useState<string>();

  const tomorrow = new Date().setDate(new Date().getDate() + 1);
  const minDate = new Date(tomorrow).toISOString();

  let existMonthError: boolean;
  let noDataError: boolean;

  const existMonth =
    date && months.find((month) => month.name.slice(0, 7) === date.slice(0, 7));
  existMonthError = !!existMonth;

  noDataError = !date;

  const handleClose = () => {
    setOpen();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CreateVacancyPlanModalContainer>
        <ModalHeader>Выбирите месяц</ModalHeader>
        <DataPickerContainer>
          <ThemeProvider theme={defaultMaterialTheme}>
            <DatePicker
              fullWidth
              style={{ zIndex: 4500 }}
              variant="inline"
              format="dd.MM.yyyy"
              minDate={minDate}
              label="Месяц"
              autoOk={true}
              value={date || minDate}
              onChange={(value) =>
                value && setDate(value.toISOString().slice(0, 10))
              }
            />
          </ThemeProvider>
        </DataPickerContainer>

        <ButtonCustom
          onClick={() => {
            date && setMonths([...months, { id: date, name: date }]);
            date && handleClose();
          }}
          disabled={existMonthError}
        >
          Сохранить
        </ButtonCustom>

        {existMonthError && (
          <ModalErrorTxt>Месяц уже существует!</ModalErrorTxt>
        )}
        {noDataError && <ModalErrorTxt>Выбирите дату!</ModalErrorTxt>}
      </CreateVacancyPlanModalContainer>
    </Modal>
  );
};
