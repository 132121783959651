import React from "react";
import { FEATURE_FLAGS } from "../constants/features";

interface FeatureFlagsContext {
  features: { [key: string]: boolean};
}

export const FeatureFlags = React.createContext<FeatureFlagsContext>({ features: {}});

export const FeatureFlagsProvider = ({ children }) => {
  const [features] = React.useState(FEATURE_FLAGS);

  return (
    <FeatureFlags.Provider value={{ features }}>
      {children}
    </FeatureFlags.Provider>
  );
};
