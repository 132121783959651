import styled from "styled-components";
import { Box } from "@material-ui/core";
import { lightBlack } from "../shared/Style/Style";

export const AddButtonWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin: 40px 0 45px;

  * {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const AnnouncementsListItem = styled.li`
  display: grid;
  gap: 40px;
  text-align: center;
  align-items: center;
  padding: 40px 20px 40px 40px;
  margin: 0 auto 10px;
  color: ${lightBlack};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

export const AnnouncementsText = styled.p`
  margin: 0 0 0 30px;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: anywhere;
`;

export const NoAnnouncementsListText = styled(AnnouncementsText)`
  margin: 0;
  text-align: center;
`;

export const AnnouncementsTitle = styled.h3`
  font-size: 14px;
  margin: 0 0 10px 30px;
  text-align: left;
`;
