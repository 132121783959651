import React from "react";

import { SalaryModalProvider } from "./SalaryModalContext";

import { PayrollSalaryPart, UserPayroll } from "../../generated/graphql";

import { SalaryPartModal } from "./SalaryPartModal";
import { PayrollModal } from "./PayrollModal";
import { Dialog } from "@material-ui/core";
import { BonusModal } from "./BonusModal/BonusModal";
import { WorkingHoursModal } from "./WorkingHoursModal/WorkingHoursModal";
import { KpiModal } from "./KpiModal/KpiModal";
import { DayReportModal } from "./DayReportModal";

export interface ISalaryModalProps {
  role?: string;
  open: boolean;
  data: ISalaryModalInnerProps
}

export interface ISalaryModalInnerProps {
  close(): void;
  date?: string;
  payroll?: UserPayroll | null;
  salaryPart?: PayrollSalaryPart;
  payrolls?: (UserPayroll | null)[] | undefined | null;
  refetchPayroll?(): void;
}

export const SalaryModal = ({ role, open, data }: ISalaryModalProps) => {
  let modal;

  switch (role) {
    case 'salaryPart':
      modal = <SalaryPartModal {...data} />
      break;

    case 'payroll':
      modal = <PayrollModal {...data} />
      break;

    case 'bonus':
      modal = <BonusModal {...data} />
      break;

    case 'hours':
      modal = <WorkingHoursModal {...data} />
      break;

    case 'dayReport':
      modal = <DayReportModal {...data} />
      break;

    case 'kpi':
      modal = <KpiModal {...data} />
      break;

    default:
      modal = <SalaryPartModal {...data} />
  }

  return (
    <Dialog
      open={open}
      transitionDuration={300}
      maxWidth={false}
    >
      <SalaryModalProvider>
        {modal}
      </SalaryModalProvider>
    </Dialog>
  )
}