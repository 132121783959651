import styled from "styled-components";
import { MainTableRow } from "../shared/Style/Style";

export const MobileAppNotificationRow = styled(MainTableRow)`
  grid-template-columns: 1.2fr 1.2fr 2.4fr 4fr 1.2fr 0.4fr;
`;

export const EmptyNotificationTableRow = styled(MobileAppNotificationRow)`
  grid-template-columns: 1fr;
`;
