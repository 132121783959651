import { Box } from "@material-ui/core";
import { name, navLink } from "./constants";
import { ContainerWithNavBar } from "../shared/ContainerWithNavBar";
import { ChapterBlock } from "./ChapterBlock";
import { useGetRewardGroupsQuery } from "../../../generated/graphql";
import { LoadingProgressOverlay } from "../../shared/LoadingProgress";

export const Economics = () => {
  const { data, loading } = useGetRewardGroupsQuery({
      fetchPolicy: "network-only"
  });
  const rewardGroups = data?.getRewardGroups || [];

  return (
    <ContainerWithNavBar header={name}>
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gridGap={20}>
        {rewardGroups.map(({ id, name, rewards }) => (
          <ChapterBlock key={id} name={name} rewards={rewards} />
        ))}
      </Box>
      {loading && <LoadingProgressOverlay />}
    </ContainerWithNavBar>
  );
};

Economics.link = navLink;
Economics.chapterName = name;
