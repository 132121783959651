import styled from "styled-components";

export const PaymentItemBlock = styled.ul`
  margin-top: 14px;
  padding: 0;
  list-style-type: none;
`;

export const PaymentItemElement = styled.li`
  margin-top: 4px;
`;
