
export const convertSecondToMinutes = (time: number) => {
  const min = Math.floor((time % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const sec = Math.floor(time % 60)
    .toString()
    .padStart(2, "0");

  return min + ":" + sec;
};