import React, { useEffect, useState } from "react";

import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GetAddedTasksEpicsQuery,
  GetAddedTasksEpicsDocument,
  GetAddedTasksReportByEpicQuery,
  GetAddedTasksReportByEpicDocument,
} from "../../../../generated/graphql";
import { IProjectReportModalContentProps } from "../ProjectReportModal";

import {
  Table,
  TableRow,
  TableBody,
  CircularProgress
} from "@material-ui/core";
import {
  CustomTableHeaderCell,
  CustomTableCell,
  ColorizedTableCell,
  CustomTableRow,
  CustomTableHead
} from "../../../shared/CustomTable/CustomTable.style";

import { LoadingBox } from "../../../shared/Style/Style";
import { CustomTable } from "../../../shared/CustomTable";

import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { DropdownArrowButton } from "../../../shared/Buttons/DropdownArrowButton";
import { ErrorMessageSnackbar } from "../../../shared/ErrorMessageSnackbar";

import { choseOrToggleIdHandler } from "../../../shared/Utils/StateOperations/choseOrToggleId";
import { convertSecondsToHoursHandler } from "../../../shared/Utils/ConvertOperations/convertSecondsToHoursHandler";

export const AddedTasksContent = ({ projectId, dateRange }: IProjectReportModalContentProps) => {
  const headers = [
    'Этап',
    'Задача',
    'Ответственный',
    'Статус',
    'Часы факт',
    'Затраты факт',
  ];

  const [chosenEpic, setChosenEpic] = useState('');
  const [requestError, setRequestError] = useState(false);

  const {
    data: epicsData,
    loading: epicsLoading,
    error: epicsError,
  } = useQuery<GetAddedTasksEpicsQuery>(GetAddedTasksEpicsDocument, {
    variables: {
      projectId,
      ...dateRange
    }
  });

  const [getTasks, { data, loading, error }] = useLazyQuery<GetAddedTasksReportByEpicQuery>(
    GetAddedTasksReportByEpicDocument,{
      variables: {
        epicKey: chosenEpic,
        ...dateRange
      }
    }
  );

  useEffect(() => {
    if (chosenEpic) {
      getTasks();
    }
  }, [chosenEpic]);

  useEffect(() => {
    if (error) {
      setRequestError(true);
    }
  }, [error]);

  let tasksList: JSX.Element | JSX.Element[];

  if (loading) {
    tasksList = (
      <CustomTableCell colSpan={7}>
        <LoadingBox>
          <CircularProgress color='inherit'/>
        </LoadingBox>
      </CustomTableCell>
    )
  }

  if (data) {
    const tasks = data.getAddedTasksReportByEpic;

    tasksList =
      tasks.length
        ? tasks.map(task => (
          <TableRow key={task.id}>
            <ColorizedTableCell
              number={0}
            />
            {[
              task.name,
              task.assigneeName,
              task.status,
              convertSecondsToHoursHandler(task.timeSpent),
              task.cost,
            ].map((field, i) => (
              <ColorizedTableCell
                key={`${i} ${field}`}
                number={0}
              >
                {field}
              </ColorizedTableCell>
            ))}
          </TableRow>
        ))
        : (
          <TableRow>
            <CustomTableCell colSpan={6}>У данного этапа отсутствуют задачи</CustomTableCell>
          </TableRow>
        )
  }

  let content;

  if (epicsLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit'/>
      </LoadingBox>
    )
  }

  if (epicsError) {
    content = <ErrorSnackbar error={epicsError} />
  }

  if (epicsData) {
    const epics = epicsData.getAddedTasksEpics;

    content = (
      <>
        <Table>
          <CustomTableHead>
            <TableRow>
              {headers.map(header => (
              <CustomTableHeaderCell
                key={header}
                width={(header === 'Этап' || header === 'Задача') ? 250 : 150}
              >
                {header}
              </CustomTableHeaderCell>))}
            </TableRow>
          </CustomTableHead>

          <TableBody>
            {epics.length
              ? epics.map(epic => (
                <>
                  <CustomTableRow
                    clickable={true}
                    key={epic.epicKey}
                    onClick={() => choseOrToggleIdHandler(epic.epicKey, chosenEpic, setChosenEpic)}
                  >
                    <CustomTableCell>
                      {epic.epicName}

                      <DropdownArrowButton
                        expanded={chosenEpic === epic.epicKey}
                      />
                    </CustomTableCell>

                    <CustomTableCell colSpan={3} />

                    {[
                      convertSecondsToHoursHandler(epic.totalTimeSpent),
                      epic.cost
                    ].map((field, i) => (
                      <CustomTableCell key={`${i} ${field}`}>
                        {field}
                      </CustomTableCell>
                    ))}
                  </CustomTableRow>

                  {chosenEpic === epic.epicKey && tasksList && (
                    tasksList
                  )}
                </>
              ))
              : (
                <CustomTableRow>
                  <CustomTableCell colSpan={6}>
                    Этапы отсутствуют
                  </CustomTableCell>
                </CustomTableRow>
              )
            }
          </TableBody>
        </Table>

        <ErrorMessageSnackbar
          open={requestError}
          closeHandler={() => setRequestError(false)}
          message='При загрузке задач произошла ошибка'
        />
      </>
    )
  }

  return (
    <CustomTable
      data={data?.getAddedTasksReportByEpic || epicsData?.getAddedTasksEpics}
      tableContent={content}
    />
  )
}
