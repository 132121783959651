import React, {useState} from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle, TextField,
} from "@material-ui/core";
import {CloseIconButton} from "../../shared/Buttons/CloseIconButton";
import {ButtonCustom, ButtonsWrapper, CancelButton} from "../../shared/Style/Style";
import {Autocomplete} from "@material-ui/lab";
import { Entity } from "../Lessons";

export const LessonModalSelect = ({
  open,
  items = [],
  entity,
  selectedItem,
  setSelectedItem,
  closeModal = () => {},
  disableOnLoading,
  nextModal = () => {}
}) => {

  const itemToShow = items.filter(item => {
    if (entity === Entity.Theme && !item.nameUKR) return {};
      return item
  })

  const handlerNextModal = () => {
    if(!selectedItem) return
    closeModal()
    nextModal()
  }

  const handlerCloseModal = () => {
    setSelectedItem('')
    closeModal()
  }

  const labelHandler = (option: string) => {
    const label = itemToShow.find((item) => item.id === option) || {};
    const name = entity === Entity.Theme ? label?.nameUKR : label?.name;
    return name ?? "";
  }

  return (
    <Dialog open={open}>
      <DialogContent style={{ minWidth: "400px", padding: '30px' }}>
        <CloseIconButton close={handlerCloseModal} />

        <Box textAlign="center" mb={2}>
          <DialogTitle>Привязать {entity}</DialogTitle>
        </Box>

        <Autocomplete
          id="size-small-filled"
          size="small"
          options={itemToShow.map((item) => item.id)}
          getOptionLabel={(option) => labelHandler(option)}
          value={itemToShow.find((item) => item.id === selectedItem)?.id}
          onChange={(_, value) => setSelectedItem(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={`Выбрать ${entity}`}
            />
          )}
        />

        <ButtonsWrapper>
          <CancelButton
            onClick={handlerCloseModal}
            disabled={disableOnLoading}
          >
            Отмена
          </CancelButton>

          <ButtonCustom
            onClick={handlerNextModal}
            disabled={disableOnLoading || !selectedItem}
          >
            Продолжить
          </ButtonCustom>
        </ButtonsWrapper>

      </DialogContent>
    </Dialog>
  );
};
