import { SchoolsSubscriptionStatus } from '../../generated/graphql'
import { SelectOptionType } from '../shared/Selects/types'

export const endOfSubscriptionOptionsMapper = {
  '1_7_days': { from: 1, to: 7 },
  '8_14_days': { from: 8, to: 14 },
  '15_30_days': { from: 15, to: 30 },
  '31_60_days': { from: 31, to: 60 },
  '61_90_days': { from: 61, to: 90 },
}

export const endOfSubscriptionOptions: SelectOptionType[] = [
  {
    name: 'Окончание подписки: все',
    value: null,
  },
  {
    name: 'Окончание подписки: 1-7 дней',
    value: '1_7_days',
  },
  {
    name: 'Окончание подписки: 8-14 дней',
    value: '8_14_days',
  },
  {
    name: 'Окончание подписки: 15-30 дней',
    value: '15_30_days',
  },
  {
    name: 'Окончание подписки: 31-60 дней',
    value: '31_60_days',
  },
  {
    name: 'Окончание подписки: 61-90 дней',
    value: '61_90_days',
  },
]

export const subscriptionTypesOptions: SelectOptionType[] = [
  {
    name: 'Все типы подписок',
    value: null,
  },
  {
    name: 'Активная',
    value: SchoolsSubscriptionStatus.Active,
  },
  {
    name: 'Завершилась',
    value: SchoolsSubscriptionStatus.Finished,
  },
  {
    name: 'Без истории подписок',
    value: SchoolsSubscriptionStatus.NoSubscriptionHistory,
  },
]
