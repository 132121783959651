import React, { useState, useEffect } from "react";

import { useMutation, useQuery } from "@apollo/client";
import {
  CreateUserDepartmentDocument,
  CreateUserDepartmentMutation,
  DeleteUserDepartmentDocument,
  DeleteUserDepartmentMutation,
  GetUserDepartmentsDocument,
  GetUserDepartmentsQuery,
  UpdateUserDepartmentDocument,
  UpdateUserDepartmentMutation
} from "../../../../generated/graphql";

import {  CircularProgress, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { LoadingBox } from "../../../shared/Style/Style";
import { SettingsList } from "../SettingsList";
import { SettingsCreateItemForm } from "../SettingsCreateItemForm";

import { Content } from "../Settings.style";


export const Department = () => {
  const [inputError, setInputError] = useState(false);
  const [requestError, setRequestError] = useState(false);

  const [isCreating, setCreating] = useState(false);
  const [isEditing, setEditing] = useState({
    itemId: '',
    status: false,
  })

  const {
    data: DepartmentsData,
    loading: DepartmentsLoading,
    error: DepartmentsError,
  } = useQuery<GetUserDepartmentsQuery>(GetUserDepartmentsDocument);

  const [
    createDepartment,
    {
      data: createDepartmentData,
      loading: createDepartmentLoading,
      error: createDepartmentError,
    }
  ] = useMutation<CreateUserDepartmentMutation>(
    CreateUserDepartmentDocument,
    { refetchQueries: [{query: GetUserDepartmentsDocument}] }
  );

  const createHandler = (name: string): void => {
    if (!name) {
      setInputError(true);
      return;
    }

    createDepartment({ variables: {name} })
  }

  const [
    updateDepartment,
    {
      data: updateDepartmentData,
      loading: updateDepartmentLoading,
      error: updateDepartmentError,
    }
  ] = useMutation<UpdateUserDepartmentMutation>(
    UpdateUserDepartmentDocument,
    { refetchQueries: [{query: GetUserDepartmentsDocument}] }
  );

  const updateHandler = (id: string, name: string): void => {
    if(!id || !name) {
      setInputError(true);
      return;
    }

    updateDepartment({ variables: {departmentId: id, name} })
  }

  const [
    deleteDepartment,
    {
      data: deleteDepartmentData,
      loading: deleteDepartmentLoading,
      error: deleteDepartmentError,
    }
  ] = useMutation<DeleteUserDepartmentMutation>(
    DeleteUserDepartmentDocument,
    { refetchQueries: [{query: GetUserDepartmentsDocument}] }
  );

  const deleteHandler = (id: string): void => {
    if(!id) {
      setInputError(true);
      return;
    }
    deleteDepartment({ variables: {departmentId: id} })
  }

  useEffect(() => {
    if(createDepartmentError || updateDepartmentError || deleteDepartmentError) {
      setRequestError(true);
    }
  }, [createDepartmentError, updateDepartmentError, deleteDepartmentError]);

  useEffect(() => {
    if(deleteDepartmentData || updateDepartmentData || createDepartmentData) {
      setRequestError(false);
    }
  }, [deleteDepartmentData, updateDepartmentData, createDepartmentData]);

  useEffect(() => {
    if(updateDepartmentData) {
      setEditing({
        itemId: '',
        status: false,
      });
    }

    if(createDepartmentData) {
      setCreating(false);
    }
  }, [updateDepartmentData, createDepartmentData]);

  const areEditingButtonsDisabled = createDepartmentLoading || updateDepartmentLoading || deleteDepartmentLoading || isCreating;
  const areCreatingButtonsDisabled = createDepartmentLoading || updateDepartmentLoading || deleteDepartmentLoading || isEditing.status;

  let content;

  if (DepartmentsLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    )
  }

  if (DepartmentsError) {
    content = <ErrorSnackbar error={DepartmentsError}/>
  }

  if (DepartmentsData) {
    const userDepartments = DepartmentsData.getUserDepartments;

    content = (
      <>
        <SettingsCreateItemForm
          header={'Отдел'}
          createItem={createHandler}
          setCreating={setCreating}
          inputError={inputError}
          setInputError={setInputError}
          areButtonsDisabled={areCreatingButtonsDisabled}
          isRequestSuccessful={Boolean(createDepartmentData)}
        />

        <SettingsList
          list={userDepartments}
          updateItem={updateHandler}
          deleteItem={deleteHandler}
          buttonsDisabled={areEditingButtonsDisabled}
          isEditing={isEditing}
          setEditing={setEditing}
        />
      </>
    )
  }

  return (
    <Content>
      {content}

      <Snackbar
        open={inputError}
        onClose={() => setInputError(false)}
        autoHideDuration={6000}
      >
        <Alert severity="error">
          Вы ввели некорректное название
        </Alert>
      </Snackbar>

      <Snackbar
        open={requestError}
        onClose={() => setRequestError(false)}
        autoHideDuration={6000}
      >
        <Alert severity="error">
          {createDepartmentError?.message || updateDepartmentError?.message || deleteDepartmentError?.message}
        </Alert>
      </Snackbar>
    </Content>
  )
}
