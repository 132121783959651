import styled from "styled-components";
import { primary } from "../../shared/Style/Style";

export const AddBlockModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${primary};
  color: black;
  font-size: 20px;
  padding: 8px 0 8px 16px;
`;