import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GetUserInfoPayrollDocument,
  GetUserInfoPayrollQuery,
  GetUserPayrollDocument,
  GetUserPayrollQuery,
  GetUserPayrollsQuery,
  PayrollSalaryPart,
  UserPayroll,
} from "../../generated/graphql";
import { GetUserPayrollsDocument } from "../../generated/graphql";

import { Box, CircularProgress, Container } from "@material-ui/core";

import {
  ButtonPlus,
  Header,
  LoadingBox,
  ValueTxt,
  TableWrapper,
  Cell,
  MainSalaryGrid,
  HeaderTxt,
} from "../shared/Style/Style";
import {
  HeadersGrid,
  EmployeeRateGrid,
  HeaderCell,
  GreenCell,
  BonusCell,
  TotalCell,
  AddBonusBtn,
  NoPayrollsTablePlaceholder,
  EmptyRateGrid,
  EmptyCell,
  InnerTableHeadersGrid,
  InnerRateGrid,
  CursorPointer,
  LeftTableSection,
  RightTableSection,
  InnerTableContainer,
  ProjectsTable,
  ProjectsTableContainer,
  ProjectsHeadersGrid,
  ProjectRateGrid,
} from "./Salary.style";

import { SalaryModal } from "../SalaryModal/";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { SalaryInput } from "../SalaryInput";
import { MonthNavigationBar } from "../shared/MonthNavigationBar";

import { stringifyDateHandler } from "../shared/Utils/DateOperations/stringifyDateHandler";
import { convertMinuteToHoursHandler } from "../shared/Utils/ConvertOperations/convertMinuteToHoursHandler";

const headersPaymentsTable = [
  { title: "Выплата" },
  { title: "Дата" },
  { title: "Сумма" },
];

const headersProjectsTable = [
  { title: "Проект" },
  { title: "Часов потрачено" },
];

export const Salary = () => {
  const { userId } = useParams<{ userId: string }>();

  const [chosenPayroll, setChosenPayroll] = useState<string | null>(null);
  const [chosenSalaryPart, setSalaryPart] = useState<
    PayrollSalaryPart | undefined
  >(undefined);

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalRole, setModalRole] = useState("");

  const {
    data: userInfoPayrollData,
    loading: userInfoPayrollLoading,
    error: userInfoPayrollError,
  } = useQuery<GetUserInfoPayrollQuery>(GetUserInfoPayrollDocument, {
    variables: { userId: userId },
  });

  const {
    data: payrollsData,
    loading: payrollsLoading,
    error: payrollsError,
  } = useQuery<GetUserPayrollsQuery>(GetUserPayrollsDocument, {
    variables: { userId },
  });

  const [
    getUserPayroll,
    {
      data: payrollData,
      loading: payrollLoading,
      error: payrollError,
      refetch: refetchPayroll,
    },
  ] = useLazyQuery<GetUserPayrollQuery>(GetUserPayrollDocument, {
    variables: { payrollId: chosenPayroll },
  });

  useEffect(() => {
    if (payrollsData?.getUserPayrolls.length) {
      setChosenPayroll(
        payrollsData.getUserPayrolls[payrollsData.getUserPayrolls.length - 1].id
      );
    }
  }, [payrollsLoading, payrollsData?.getUserPayrolls.length]);

  useEffect(() => {
    if (chosenPayroll) {
      getUserPayroll();
    }
  }, [chosenPayroll]);

  let content;

  if (userInfoPayrollLoading || payrollsLoading || payrollLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (payrollsError || userInfoPayrollError || payrollError) {
    content = (
      <ErrorSnackbar
        error={payrollsError || userInfoPayrollError || payrollError}
      />
    );
  }

  if (userInfoPayrollData && payrollsData && payrollData) {
    const { salary, firstName, lastName, job } = userInfoPayrollData.getUser;

    const payroll = payrollData.getUserPayroll;

    const bonusesSum = payroll.bonuses
      .map((bonus) => bonus.value)
      .reduce((acc, val) => acc + val, 0);

    const hoursFact = (
      payroll.additionalData.reports.reduce((acc, p) => acc + p.totalTime, 0) /
      60
    ).toFixed(0);

    content = (
      <TableWrapper>
        <MainSalaryGrid>
          <HeadersGrid>
            {[
              `${lastName} ${firstName}`,
              job?.name,
              stringifyDateHandler(payroll.name),
              "Руководитель - Иванов Иван",
            ].map((text) => (
              <HeaderCell>
                <HeaderTxt>{text}</HeaderTxt>
              </HeaderCell>
            ))}
          </HeadersGrid>

          <EmployeeRateGrid>
            <LeftTableSection>
              <GreenCell>
                <HeaderTxt>Ставка сотрудника</HeaderTxt>
              </GreenCell>
              <Cell>
                <ValueTxt>{salary}</ValueTxt>
              </Cell>
              <GreenCell>
                <HeaderTxt>Премиальный фонд</HeaderTxt>
              </GreenCell>
              <Cell>
                <SalaryInput
                  editedFieldName={"bonusFund"}
                  value={payroll.bonusFund}
                  chosenPayroll={chosenPayroll}
                />
              </Cell>

              <BonusCell>
                <HeaderTxt>Часы план</HeaderTxt>
              </BonusCell>
              <Cell>
                <SalaryInput
                  editedFieldName={"hoursPlan"}
                  value={payroll.hoursPlan}
                  chosenPayroll={chosenPayroll}
                />
              </Cell>

              <BonusCell
                onClick={() => {
                  setModalRole("hours");
                  setModalOpen(true);
                }}
              >
                <CursorPointer>
                  <HeaderTxt>Часы факт</HeaderTxt>
                </CursorPointer>
              </BonusCell>
              <Cell>
                <ValueTxt>
                  {/*{convertMinuteToHoursHandler(hoursFact)}*/}
                  {hoursFact}
                </ValueTxt>
              </Cell>
            </LeftTableSection>

            <RightTableSection>
              <GreenCell>
                <HeaderTxt>Оклад</HeaderTxt>
              </GreenCell>
              <Cell>
                {
                  //todo: получить правильную цифру с бека
                }

                {/*<ValueTxt>{payroll.additionalData.mainPart}</ValueTxt>*/}
                <ValueTxt>
                  {((salary / payroll.hoursPlan) * Number(hoursFact)).toFixed()}
                </ValueTxt>
              </Cell>
              <Cell>
                <SalaryInput
                  editedFieldName={"salaryDescription"}
                  value={payroll.salaryDescription}
                  chosenPayroll={chosenPayroll}
                />
              </Cell>

              <GreenCell
                onClick={() => {
                  setModalRole("kpi");
                  setModalOpen(true);
                }}
              >
                <CursorPointer>
                  <HeaderTxt>KPI</HeaderTxt>
                </CursorPointer>
              </GreenCell>

              <Cell>
                <ValueTxt>{payroll.additionalData.kpiValue}</ValueTxt>
              </Cell>

              <Cell>
                <SalaryInput
                  editedFieldName={"kpiDescription"}
                  value={payroll.kpiDescription}
                  chosenPayroll={chosenPayroll}
                />
              </Cell>

              <GreenCell
                onClick={() => {
                  setModalRole("bonus");
                  setModalOpen(true);
                }}
              >
                <CursorPointer>
                  <HeaderTxt>Бонус</HeaderTxt>
                </CursorPointer>
              </GreenCell>

              <Cell>
                <ValueTxt>{bonusesSum}</ValueTxt>
              </Cell>

              <Cell>
                <SalaryInput
                  editedFieldName={"bonusesDescription"}
                  value={payroll.bonusesDescription}
                  chosenPayroll={chosenPayroll}
                />
              </Cell>

              <GreenCell>
                <HeaderTxt>Совокупный доход</HeaderTxt>
              </GreenCell>
              <Cell>
                <ValueTxt>{payroll.additionalData.total}</ValueTxt>
              </Cell>
              <Cell>
                <SalaryInput
                  editedFieldName={"totalDescription"}
                  value={payroll.totalDescription}
                  chosenPayroll={chosenPayroll}
                />
              </Cell>
            </RightTableSection>
          </EmployeeRateGrid>

          <EmptyRateGrid />

          <EmployeeRateGrid>
            <ProjectsTableContainer>
              <ProjectsTable>
                <ProjectsHeadersGrid>
                  {headersProjectsTable.map((el) => (
                    <HeaderCell key={el.title}>
                      <HeaderTxt>{el.title}</HeaderTxt>
                    </HeaderCell>
                  ))}
                </ProjectsHeadersGrid>
                {payroll.additionalData.reportsByProjects.map((project) => (
                  <ProjectRateGrid>
                    <Cell>
                      <ValueTxt>{project.projectName}</ValueTxt>
                    </Cell>
                    <Cell>
                      <ValueTxt>
                        {convertMinuteToHoursHandler(project.time)}
                      </ValueTxt>
                    </Cell>
                  </ProjectRateGrid>
                ))}
              </ProjectsTable>
            </ProjectsTableContainer>

            <InnerTableContainer>
              <Box>
                <InnerTableHeadersGrid>
                  {headersPaymentsTable.map((el) => (
                    <HeaderCell key={el.title}>
                      <HeaderTxt>{el.title}</HeaderTxt>
                    </HeaderCell>
                  ))}
                </InnerTableHeadersGrid>

                <InnerRateGrid>
                  <TotalCell
                    onClick={() => {
                      setSalaryPart(undefined);
                      setModalRole("salaryPart");
                      setModalOpen(true);
                    }}
                  >
                    <HeaderTxt>
                      Добавить
                      <AddBonusBtn />
                    </HeaderTxt>
                  </TotalCell>
                  <EmptyCell />
                  <EmptyCell />
                </InnerRateGrid>

                {!!payroll.parts.length &&
                  payroll.parts.map((part) => (
                    <InnerRateGrid
                      onClick={() => {
                        setSalaryPart(part);
                        setModalRole("salaryPart");
                        setModalOpen(true);
                      }}
                      key={part.id}
                    >
                      <Cell>
                        <ValueTxt>{part.name}</ValueTxt>
                      </Cell>
                      <Cell>
                        <ValueTxt>
                          {new Date(part.date).toLocaleDateString()}
                        </ValueTxt>
                      </Cell>
                      <Cell>
                        <ValueTxt>{part.value}</ValueTxt>
                      </Cell>
                    </InnerRateGrid>
                  ))}

                <InnerRateGrid>
                  <BonusCell>
                    <HeaderTxt>Остаток</HeaderTxt>
                  </BonusCell>
                  <Cell />
                  <Cell>
                    <ValueTxt>
                      {payroll.additionalData.total -
                        payroll.parts
                          .map((part) => part.value)
                          .reduce((acc, cur) => acc + cur, 0)}
                    </ValueTxt>
                  </Cell>
                </InnerRateGrid>
              </Box>
            </InnerTableContainer>
          </EmployeeRateGrid>
        </MainSalaryGrid>
      </TableWrapper>
    );
  }

  if (userInfoPayrollData && payrollsData && !payrollData) {
    content = (
      <NoPayrollsTablePlaceholder>
        зарплатные ведомости отсутствуют
      </NoPayrollsTablePlaceholder>
    );
  }

  if (!userInfoPayrollData || !payrollsData) {
    return null;
  }

  const payroll = payrollData?.getUserPayroll as UserPayroll;
  const payrolls = payrollsData?.getUserPayrolls || [];

  return (
    <Container maxWidth="lg" style={{ position: "relative" }}>
      <Header>Зарплатная ведомость</Header>

      <Box mb={5} mt={4} textAlign="right">
        <ButtonPlus
          width={180}
          variant="contained"
          onClick={() => {
            setModalRole("payroll");
            setModalOpen(true);
          }}
        >
          Добавить
        </ButtonPlus>
      </Box>

      {content}

      <MonthNavigationBar
        field="id"
        months={payrolls}
        chosenMonth={chosenPayroll}
        setChosenMonth={setChosenPayroll}
      />

      {isModalOpen && (
        <SalaryModal
          role={modalRole}
          open={isModalOpen}
          data={{
            close: () => {
              setModalRole("");
              setModalOpen(false);
            },
            salaryPart: chosenSalaryPart,
            payroll,
            payrolls,
            refetchPayroll,
          }}
        />
      )}
    </Container>
  );
};
