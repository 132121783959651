import React from "react";
import { useQuery } from "@apollo/client";
import {
  GetArBookUsersCountLightDocument,
  GetArBookUsersCountLightQuery,
  UserRoleEnum, UserStatusEnum,
} from "../../generated/graphql";

import {
  FilterButton,
  FilterButtonEndIcon,
  Filter,
  LoadingBox,
} from "../shared/Style/Style";
import { CircularProgress } from "@material-ui/core";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { ActiveFilterMobUserState } from "./MobileAppUsers";
import { ExportUserCSV } from "../SmartNotification/Statistic/ExportUserCSV";
import {useGetArBookUsersCountLight} from "./hooks";

export const MobileAppUsersNavBar = ({ activeFilter, setActiveFilter,setPage }) => {
  const { getARBookUsersCountLight, getArBookUsersCountLightLoading, getArBookUsersCountLightError } = useGetArBookUsersCountLight();

  const changeFilter = (activeFilter: ActiveFilterMobUserState, statuses: UserStatusEnum[], role?: UserRoleEnum[]) => {
    if (
      activeFilter.role === role &&
      activeFilter.statuses.every((status, i) => status === statuses[i])
    ) {
      return;
    }
    setActiveFilter({
      role,
    });
    setPage(0)
  };

  const filters = [
    {
      name: "Учителя",
      active: activeFilter.role.includes( UserRoleEnum.Teacher),
      count: UserRoleEnum.Teacher,
      changeFilterHandle: () =>
        changeFilter(activeFilter,[UserStatusEnum.Verified] ,[UserRoleEnum.Teacher]),
    },
    {
      name: "Ученик",
      active: activeFilter.role.includes(UserRoleEnum.Pupil),
      count: UserRoleEnum.Pupil,
      changeFilterHandle: () => changeFilter(activeFilter,[UserStatusEnum.Verified],[ UserRoleEnum.Pupil]),
    },
    {
      name: "Родители",
      active: activeFilter.role.includes( UserRoleEnum.Parent),
      count: UserRoleEnum.Parent,
      changeFilterHandle: () => changeFilter(activeFilter,[UserStatusEnum.Verified],[ UserRoleEnum.Parent]),
    },
  ];

  let content;

  if (getArBookUsersCountLightLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />;
      </LoadingBox>
    );
  }

  if (getArBookUsersCountLightError) {
    content = <ErrorSnackbar error={getArBookUsersCountLightError} />;
  }

  if (getARBookUsersCountLight) {
    content = (
      <>
        {filters.map(({ name, active, count, changeFilterHandle }) => (
          <FilterButton
            key={name}
            active={active}
            onClick={changeFilterHandle}
            endIcon={
              <FilterButtonEndIcon>
                {"(" +
                    getARBookUsersCountLight?.find((usersCount) => usersCount.role === count)
                    ?.count +
                  ")" || ""}
              </FilterButtonEndIcon>
            }
          >
            {name}
          </FilterButton>
        ))}
      </>
    );
  }

  return <Filter>{content}</Filter>;
};
