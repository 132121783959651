import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 600px;
  padding: 50px 30px;
  border-radius: 10px;
  background-color: #fff;
`;

export const Text = styled.p`
  font-size: 16px;
  text-align: center;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  width: 100%;
  margin-top: 30px;
`;
