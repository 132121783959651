import { useReactiveVar } from "@apollo/client";
import { robotsState } from "../state";
import { ArtifactTypeEnum } from "../../../../generated/graphql";
import { requiredArtifactAnimationFields, requiredArtifactFields, requiredDefaultClothArtifactFields } from "../constants";

export const useValidateArtifactFields = () => {
    const artifact = useReactiveVar(robotsState.artifact);
    const requiredClothArtifactFields = artifact?.isDefault ? requiredDefaultClothArtifactFields : requiredArtifactFields;
    
    const checkFields = (fields: string[]) => {
        for (const field of fields) {
            if (!artifact[field]) {
                robotsState.setNotifications({ error: true, message: "Для публикации артефакта заполните все поля" });
                return false;
            }
        }
        return true;
    };

    const validateHandler = () => {
        if (!artifact) {
            return false;
        }

        switch (artifact.type) {
            case ArtifactTypeEnum.Animation:
                return checkFields(requiredArtifactAnimationFields);
            case ArtifactTypeEnum.Cloth:
                return checkFields(requiredClothArtifactFields);
            default:
                return checkFields(requiredArtifactFields);
        }
    };

    return validateHandler;
};
