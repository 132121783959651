import { useMemo } from "react";
import { Box, TableCell, TableRow } from "@material-ui/core";
import {
  columns,
  ColumnsIdEnum,
  deactivatePaymentText,
  getStatusText,
} from "./constants";
import { Menu } from "../../../shared/Menu";
import { Payment } from "../../../../generated/graphql";

interface PaymentsListProps {
  payments: Payment[];
  handleDeactivatePayment: (id: string) => void;
}
export const PaymentsList = ({
  payments,
  handleDeactivatePayment,
}: PaymentsListProps) => (
  <>
    {useMemo(
      () =>
        payments
          .map((row, id) => {
            const { content, isActivated } = row;

            if (!content?.productId) {
              return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={id}>
                    <TableCell colSpan={columns.length} align="center">
                      <Box height={45} pt={1.5}>Контент удалён из AR Book+</Box>
                    </TableCell>
                  </TableRow>
              );
            }

            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={id}>
                {columns.map((column) => {
                  let value = row[column.id];

                  if (column.id === ColumnsIdEnum.IsActivatedId) {
                    value = getStatusText(isActivated);
                  }
                  if (column.id === ColumnsIdEnum.ChangeStatusId) {
                    value = (
                      <Menu
                        vertical={36}
                        horizontal={12}
                        width={170}
                        disabled={!isActivated}
                        items={[
                          {
                            name: deactivatePaymentText,
                            action: () => handleDeactivatePayment(row.id),
                          },
                        ]}
                      />
                    );
                  }

                  return (
                    <TableCell key={column.id} align="center">
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          }),
      [payments]
    )}
  </>
);
