import { useReactiveVar } from "@apollo/client";
import { awardsState } from "../../state";
import { PhotoPicker } from "../../../../shared/PhotoPicker";

export const AwardPhotoDropzone = () => {

  const award = useReactiveVar(awardsState.award);

  const uploadPhotoHandler = (file: File) => {
    if (!file) return;
    
    const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        awardsState.setAwardPhotoLink(reader.result as string);
    };
    
    awardsState.setAwardPhotoFile(file);
  };
    
  const uploadPhotoErrorHandler = (error: boolean, message: string) => {
    awardsState.setNotifications({ error, message });
  };

  return (
    <PhotoPicker
      photo={award?.photo}
        setNewPhoto={uploadPhotoHandler}
        showError={uploadPhotoErrorHandler}
    />
  );
};
