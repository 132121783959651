export const translateBaseTaskStatuses = (columnName: string): string => {
  switch (columnName) {
    case "new": {
      return "новые";
    }
    case "inProgress": {
      return "в работе";
    }
    case "done": {
      return "выполнены";
    }
    default: {
      return columnName;
    }
  }
};