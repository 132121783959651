import React, { useEffect, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import {
  GetProjectCorrectionsLightDocument,
  GetProjectCorrectionsLightQuery,
  GetProjectDocument,
  GetProjectQuery,
  Project,
  SyncProjectDocument,
  SyncProjectMutation,
} from "../../generated/graphql";

import { Backdrop, Box, CircularProgress, Snackbar } from "@material-ui/core";

import { useParams, useRouteMatch } from "react-router-dom";

import {
  ButtonCustomWithDarkText,
  CustomNavBar,
  CustomNavLink,
} from "../shared/Style/Style";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import { Alert } from "@material-ui/lab";
import { GSheetsProjectModal } from "../Main/Project/GSheetsProjectModal";

const syncFieldsNames = {
  tasksToAdd: "Добавлено задач",
  tasksToDelete: "Удалено задач",
  tasksToUpdate: "Обновлено задач",
};

export const ProjectEditNavBar = ({ isLocal }: { isLocal?: boolean }) => {
  const { projectId } = useParams<{ projectId: string }>();

  const { path } = useRouteMatch<{ path: string }>();

  const [sheetsModal, setSheetsModal] = useState(false);

  const [requestError, setRequestError] = useState(false);
  const [syncStatus, setSyncStatus] = useState(false);

  const {
    data: projectFromJira,
    loading: projectLoading,
    error: projectError,
  } = useQuery<GetProjectQuery>(GetProjectDocument, {
    variables: {
      projectId,
    },
  });

  const {
    data: projectCorrections,
    loading: projectCorrectionsLoading,
    error: projectCorrectionsError,
  } = useQuery<GetProjectCorrectionsLightQuery>(
    GetProjectCorrectionsLightDocument,
    {
      variables: {
        projectId,
      },
    }
  );

  const [
    syncProject,
    {
      data: syncProjectData,
      loading: syncProjectLoading,
      error: syncProjectError,
    },
  ] = useMutation<SyncProjectMutation>(SyncProjectDocument, {
    variables: { projectId },
  });

  useEffect(() => {
    if (syncProjectError) {
      setRequestError(true);
    }
  }, [syncProjectError]);

  useEffect(() => {
    if (syncProjectData) {
      setSyncStatus(true);
    }
  }, [syncProjectData]);

  if (projectLoading || projectCorrectionsLoading) {
    return (
      <Backdrop open={projectLoading || projectCorrectionsLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (projectError || projectCorrectionsError) {
    return <ErrorSnackbar error={projectError || projectCorrectionsError} />;
  }

  let project;

  if (projectFromJira) {
    project = projectFromJira?.getProject as Project;
  }

  let correctionsCount;

  if (projectCorrections) {
    correctionsCount = projectCorrections.getProjectCorrectionsLight
      ?.map((correction) => correction?.count)
      ?.reduce((a, b) => {
        if (typeof a === "number" && typeof b === "number") {
          return a + b;
        }
      }, 0);
  }

  return (
    <>
      <CustomNavBar>
        <Box display="flex">
          <CustomNavLink to={`/project/${projectId}/info`}>
            <Box mr={1}>
              <ButtonCustomWithDarkText
                type="button"
                variant="contained"
                size="small"
                active={path.includes("edit")}
                disabled={syncProjectLoading}
              >
                Основная информация
              </ButtonCustomWithDarkText>
            </Box>
          </CustomNavLink>

          <CustomNavLink to="tasks">
            <Box mr={1}>
              <ButtonCustomWithDarkText
                type="button"
                variant="contained"
                size="small"
                active={path.includes("tasks")}
                disabled={syncProjectLoading}
              >
                Задачи
              </ButtonCustomWithDarkText>
            </Box>
          </CustomNavLink>

          <CustomNavLink to="kanban">
            <Box mr={1}>
              <ButtonCustomWithDarkText
                type="button"
                variant="contained"
                size="small"
                active={path.includes("kanban")}
                disabled={syncProjectLoading}
              >
                Спринты
              </ButtonCustomWithDarkText>
            </Box>
          </CustomNavLink>

          <CustomNavLink to="timeline?weeks">
            <Box mr={1}>
              <ButtonCustomWithDarkText
                type="button"
                variant="contained"
                size="small"
                active={path.includes("timeline")}
                disabled={syncProjectLoading}
              >
                График
              </ButtonCustomWithDarkText>
            </Box>
          </CustomNavLink>

          <CustomNavLink to={project?.client ? "payments" : "#"}>
            <Box mr={1}>
              <ButtonCustomWithDarkText
                disabled={!project?.client || syncProjectLoading}
                type="button"
                variant="contained"
                size="small"
                active={path.includes("payments")}
              >
                Платежи
              </ButtonCustomWithDarkText>
            </Box>
          </CustomNavLink>

          <CustomNavLink to={"corrections"}>
            <ButtonCustomWithDarkText
              type="button"
              variant="contained"
              size="small"
              active={path.includes("corrections")}
              disabled={syncProjectLoading}
            >
              Правки {correctionsCount ? `(${correctionsCount})` : ""}
            </ButtonCustomWithDarkText>
          </CustomNavLink>
        </Box>

        <Box display="flex">
          {isLocal && (
            <Box mr={1}>
              <ButtonCustomWithDarkText
                type="button"
                variant="contained"
                size="small"
                active={true}
                disabled={syncProjectLoading}
                onClick={() => setSheetsModal(true)}
              >
                Импортировать Google Sheets
              </ButtonCustomWithDarkText>
            </Box>
          )}

          {!isLocal && (
            <ButtonCustomWithDarkText
              type="button"
              variant="contained"
              size="small"
              active={true}
              disabled={syncProjectLoading}
              onClick={() => syncProject()}
            >
              Синхронизировать
            </ButtonCustomWithDarkText>
          )}
        </Box>
      </CustomNavBar>

      <GSheetsProjectModal
        open={sheetsModal}
        close={() => setSheetsModal(false)}
        projectId={projectId}
      />

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message="При синхронизации проекта произошла ошибка"
      />

      {syncProjectData?.syncProject && (
        <Snackbar
          open={syncStatus}
          onClose={() => setSyncStatus(false)}
          autoHideDuration={6000}
        >
          <Alert severity="success">
            <p>{`${syncFieldsNames.tasksToAdd}: ${syncProjectData.syncProject.tasksToAdd}`}</p>
            <p>{`${syncFieldsNames.tasksToUpdate}: ${syncProjectData.syncProject.tasksToUpdate}`}</p>
            <p>{`${syncFieldsNames.tasksToDelete}: ${syncProjectData.syncProject.tasksToDelete}`}</p>
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
