import styled from "styled-components";
import { MainTableCell, MainTableRow } from "../shared/Style/Style";

export const EventTableRow = styled(MainTableRow)`
  grid-template-columns: 2fr 2fr 2fr 2fr 0fr;
  grid-gap: 10px;
  cursor: pointer;
`

export const EventTableCell = styled(MainTableCell)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`