import styled from "styled-components";
import {MainTableRow} from "../shared/Style/Style";
import {TableRow} from "@material-ui/core";

export const MobileAppUsersRow = styled(MainTableRow)`
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 1fr;
  align-items: center;
`;

export const MobileAppWithoutUsersRow = styled(MainTableRow)`
  grid-template-columns: 2fr;
`;

export const PeopleTableNoUsersRow = styled(TableRow)`
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px 15px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

export const PeopleTableContentCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  line-height: 17px;
`;

export const MainTableHeaderTitleTeacher = styled.th`
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-right: 20px;
  &:not(:last-of-type) {
    margin-right: 0;
  }
  `;
export const MobileUserTableRowsWrapper = styled.div`
`;
