import React from "react";
import { Field } from "react-final-form";
import { TextField } from "@material-ui/core";
import { isRequired } from "../../../core/validators/validators";
import { DictionaryProjectStatusSelect } from "../../shared/DictionaryProjectStatusSelect/DictionaryProjectStatusSelect";
import { DictionaryUserSelect } from "../../shared/DictionaryUserSelect/DictionaryUserSelect";
import { Project, ProjectType, User } from "../../../generated/graphql";

interface ProjectBaseFieldsProps {
  className: string;
  users: User[];
  values: Project;
  projectTypes: ProjectType[];
}

export const ProjectBaseFields = ({
  className,
  users,
  values,
  projectTypes,
}: ProjectBaseFieldsProps) => {
  return (
    <>
      <Field name="name">
        {({ input, meta }) => (
          <TextField
            disabled
            className={className}
            error={meta.error && meta.touched}
            margin="normal"
            fullWidth
            helperText={meta.touched && meta.error}
            label="Название"
            variant="filled"
            type="text"
            {...input}
          />
        )}
      </Field>
      <Field name="status" validate={isRequired}>
        {({ input, meta }) => (
          <DictionaryProjectStatusSelect
            label="Статус"
            input={input}
            meta={meta}
            values={[
              { id: 1, title: "Новый" },
              { id: 2, title: "В работе" },
              { id: 3, title: "Выполнен" },
            ]}
          />
        )}
      </Field>
      <Field name="projectType" validate={isRequired}>
        {({ input, meta }) => (
          <DictionaryProjectStatusSelect
            label="Тип"
            input={input}
            meta={meta}
            values={
              projectTypes
                ? projectTypes?.map((type) => ({
                    id: type.id,
                    title: type.name,
                  }))
                : []
            }
          />
        )}
      </Field>
      <Field name="client" validate={isRequired}>
        {({ input, meta }) => (
          <DictionaryUserSelect
            label="Клиент"
            input={input}
            meta={meta}
            values={users}
          />
        )}
      </Field>
      <Field name="phone" key="phone">
        {({ meta }) => (
          <TextField
            disabled
            className={className}
            error={meta.error && meta.touched}
            margin="normal"
            fullWidth
            helperText={meta.touched && meta.error}
            label="Телефон"
            variant="filled"
            type="text"
            value={
              users?.find((user) => user?.id === values.client?.id)?.phone || ""
            }
          />
        )}
      </Field>
    </>
  );
};
