export const QUERY_LIMIT = 50;

export const costText = `Стоимость (${
  process.env.REACT_APP_CURRENT_LOCALE === "en" ? "$" : "грн"
})`;

export const initialFileData: {
  file: File | null;
  name: string;
} = {
  file: null,
  name: "Выберите файл",
};

export const initialUploadStatus: {
  message: string;
  status: boolean;
  variant: "success" | "info" | "error";
} = {
  message: "",
  status: false,
  variant: "info",
};
