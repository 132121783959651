import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient, useReactiveVar } from "@apollo/client";
import { robotsState } from "../../state";
import { Container, Table } from "@material-ui/core";
import { useValidateRobotGeneralFields } from "../../hooks/useValidateRobotGeneralFields";
import { AddRobotStageButton, StageTableHeader } from "../../style";
import { ButtonPlus, Header, MainTableHeaderTitle } from "../../../../shared/Style/Style";
import { ErrorMessageSnackbar } from "../../../../shared/ErrorMessageSnackbar";
import { SuccessSnackbar } from "../../../../shared/SuccessSnackbar";
import { RobotEditBlock } from "../../components/RobotEditBlock";
import { CreateStageModal } from "../../components/Modals/CreateStageModal";
import { StagesList } from "../../components";
import { MobileAppDeleteModal } from "../../../../MobileAppModal/MobileAppDeleteModal";
import { initialDeleteStage, initialNotifications, stagesTableHeader } from "../../constants";

export const RobotModelPage = () => {
    const apolloClient = useApolloClient();
    robotsState.initMobileAppRobots(apolloClient);
    
    const { robotId } = useParams<{ robotId: string }>();

    const robot = useReactiveVar(robotsState.robotGeneralInfo);
    const robotStagesList = useReactiveVar(robotsState.robotStagesList);
    const isCreateStageModalOpen = useReactiveVar(robotsState.isCreateRobotStageModalOpen);
    const deleteStage = useReactiveVar(robotsState.deleteStage);
    const notifications = useReactiveVar(robotsState.notifications);
    const isLoading = useReactiveVar(robotsState.isLoading);

    const validateFields = useValidateRobotGeneralFields();

    const fetchRobotModel = async () => {
        await robotsState.getRobot({ getGameModelId: robotId });
    };

    const fetchRobotStages = async () => {
        await robotsState.getRobotStages({ gameModelId: robotId });
    };

    const fetchRobotStageTypes = async () =>
        await robotsState.getRobotStageTypes();
    
    useEffect(() => {
        fetchRobotModel();

        fetchRobotStages();

        fetchRobotStageTypes();

        return () => robotsState.clearRobot();
    }, []);

    const updateRobotModel = async () => { 
        const isValidFields = validateFields();
        
        if (!isValidFields) return;

        await robotsState.updateRobot({
            updateGameModelId: robotId,
            input: robot,
        });
    };

    const addRobotStageHandler = () => {
        robotsState.setIsCreateRobotStageModalOpen(true);
    };

    return (
        <Container maxWidth="lg">
            <Header>Редактирование робота</Header>

            <RobotEditBlock
                isLoading={isLoading}
                updateRobotModel={updateRobotModel}
            />

            <AddRobotStageButton>
                <ButtonPlus
                    variant="contained"
                    disabled={isLoading}
                    onClick={addRobotStageHandler}
                >
                    Добавить стадию
                </ButtonPlus>
            </AddRobotStageButton>

            <Table>
                <StageTableHeader>
                    {stagesTableHeader.map((item) => (
                        <MainTableHeaderTitle key={item}>{item}</MainTableHeaderTitle>
                    ))}
                </StageTableHeader>
            </Table>

            {robotStagesList?.length > 0 && !isLoading && <StagesList />}
            
            {notifications?.error && (
                <ErrorMessageSnackbar
                    open={notifications?.error}
                    closeHandler={() => robotsState.setNotifications(initialNotifications)}
                    message={notifications?.message}
                />
            )}

            {notifications?.success && (
                <SuccessSnackbar
                    open={notifications?.success}
                    handleClose={() => robotsState.setNotifications(initialNotifications)}
                    message={notifications?.message}
                    snackbarProps={{
                        anchorOrigin: { vertical: "bottom", horizontal: "center" },
                    }}
                />
            )}

            {isCreateStageModalOpen &&
                <CreateStageModal refetch={fetchRobotStages} />
            }

            {deleteStage.isOpen &&
  		        <MobileAppDeleteModal
  		            open={deleteStage.isOpen}
  		            close={() => robotsState.setDeleteStage(initialDeleteStage)}
  		            item={deleteStage.stage}
  		            refetch={() => fetchRobotStages()}
  		        />
  		    }
        </Container>
    );
};
