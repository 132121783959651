import styled from "styled-components";
import { MainTableRow } from "../shared/Style/Style";

export const MobileAppPagesTableRow = styled(MainTableRow)`
  grid-template-columns: 1fr 1.5fr 3fr 1.2fr 1.2fr 0.4fr;
  grid-gap: 10px;
  cursor: pointer;
`;

export const EmptyMobileAppPagesTableRow = styled(MobileAppPagesTableRow)`
  grid-template-columns: 1fr;
`;
