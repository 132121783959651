import { PurchaseFormValues } from "./types";

export const MAX_GOODS_PRICE = 999999;

export const formInitialValues: PurchaseFormValues = {
  paymentDate: "",
  productId: "",
  paymentType: "",
  price: "",
};

export const PRODUCT_ID_LENGTH = 7;