import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DeleteKnowledgeBaseCategoryDocument,
  DeleteKnowledgeBaseCategoryMutation,
  DeleteKnowledgeBaseThemeDocument,
  DeleteKnowledgeBaseThemeMutation,
  GetBaseKnowledgeBaseCategoriesDocument,
  GetBaseKnowledgeBaseCategoriesQuery,
  GetKnowledgeBaseThemesByCategoryIdDocument,
  GetKnowledgeBaseThemesByCategoryIdQuery,
  KnowledgeBaseCategory,
  KnowledgeBaseTheme
} from '../../generated/graphql';

import { KnowledgeBaseMainModal } from '../KnowledgeBaseModals/KnowledgeBaseMainModal';
import { EditIconButton } from '../shared/Buttons/EditIconButton';
import { DeleteIconButton } from '../shared/Buttons/DeleteIconButton';
import { ErrorMessageSnackbar } from '../shared/ErrorMessageSnackbar';

import { CircularProgress, Collapse, List, ListItemText, Box } from '@material-ui/core';

import { ButtonPlusSmall, LoadingBox } from '../shared/Style/Style';
import { KnowledgeBaseListItem } from './KnowledgeBaseList.style';
import { ThemeBlockModal } from "../KnowledgeBaseModals/ThemeBlockModal/ThemeBlockModal";


interface IKnowledgeBaseListProps {
  variant: string;
  chosenTheme?: KnowledgeBaseTheme;
  chosenCategory?: KnowledgeBaseCategory;
  setChosenCategory?(item: KnowledgeBaseCategory): void;
  setChosenTheme?(item: KnowledgeBaseTheme): void;
  isEditing: boolean;
  setEditing(isEditing: boolean): void;
}


export const KnowledgeBaseList = ({
  variant,
  chosenTheme,
  chosenCategory,
  setChosenTheme,
  setChosenCategory,
  isEditing,
  setEditing
}: IKnowledgeBaseListProps) => {
  const [isMainModalOpen, setMainModalOpen] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [isThemeModalOpen,setThemeModalOpen]=useState(false)

  const isTheme = variant === 'theme';
  const history = useHistory();

  const [
    getCategories, {
      data: categoriesData,
      loading: categoriesLoading,
      error: categoriesError
    }
  ] = useLazyQuery<GetBaseKnowledgeBaseCategoriesQuery>(GetBaseKnowledgeBaseCategoriesDocument);

  const [
    getThemes, {
      data: themesData,
      loading: themesLoading,
      error: themesError
    }
  ] = useLazyQuery<GetKnowledgeBaseThemesByCategoryIdQuery>(GetKnowledgeBaseThemesByCategoryIdDocument);

  const [deleteCategory, {
    data: deleteCategoryData,
    loading: deleteCategoryLoading,
  }] = useMutation<DeleteKnowledgeBaseCategoryMutation>(DeleteKnowledgeBaseCategoryDocument, {
    variables: { id: chosenCategory?.id },
    refetchQueries: [{ query: GetBaseKnowledgeBaseCategoriesDocument }]
  });

  const [deleteTheme, {
    data: deleteThemeData,
    loading: deleteThemeLoading,
  }] = useMutation<DeleteKnowledgeBaseThemeMutation>(DeleteKnowledgeBaseThemeDocument, {
    variables: { id: chosenTheme?.id },
    refetchQueries: [{
      query: GetKnowledgeBaseThemesByCategoryIdDocument,
      variables: { categoryId: chosenCategory?.id }
    }]
  });

  const deleteHandler = async () => {
    try {
      isTheme ? await deleteTheme() : await deleteCategory()
    } catch (e) {
      setRequestError(true)
    }
  }

  // #region effects

  useEffect(() => {
    if (isTheme) {
      if (chosenCategory) {
        getThemes({variables: { categoryId: chosenCategory?.id }})
      }
    }

    getCategories()
  }, [isTheme, chosenCategory]);

  useEffect(() => {
    if (deleteThemeData || deleteCategoryData) {
      setEditing(false);
      isTheme && setChosenTheme(null);
    }
  }, [deleteThemeData, deleteCategoryData]);

  // #endregion

  const disabledOnLoading = deleteThemeLoading || deleteCategoryLoading;

  let content;

  if (categoriesLoading || themesLoading) {
    content = (
      <KnowledgeBaseListItem button>
        <LoadingBox>
          <CircularProgress color="inherit" />
        </LoadingBox>
      </KnowledgeBaseListItem>
    );
  }

  if (categoriesError || themesError) {
    content = (
      <KnowledgeBaseListItem button>При загрузке данных произошла ошибка</KnowledgeBaseListItem>
    );
  }

  if (categoriesData || themesData) {
    const items = isTheme
      ? chosenCategory ? themesData?.getKnowledgeBaseThemesByCategoryId : null
      : categoriesData.getBaseKnowledgeBaseCategories.categories;

    content = (
      <>
        {items?.map((item: KnowledgeBaseCategory | KnowledgeBaseTheme) => (
          <KnowledgeBaseListItem
            button
            selected={isTheme
              ? item?.id === chosenTheme?.id
              : item?.id === chosenCategory?.id
            }
            key={item?.id}
            onClick={() => {
              isTheme
                ? isEditing
                  ? setChosenTheme(item as KnowledgeBaseTheme)
                  : history.push(`/knowledgeBase/theme/${item?.name}/${item?.id}`)
                : setChosenCategory(item as KnowledgeBaseCategory)
            }}
          >
            <ListItemText>
              {item?.name}
            </ListItemText>

            <Collapse
              in={isEditing}
            >
              <Box>
                <EditIconButton
                  disabled={!isEditing || disabledOnLoading}
                  edit={() => {
                    isTheme?setThemeModalOpen(true):setMainModalOpen(true)
                  }}
                  active={true}
                  fz={16}
                />

                <DeleteIconButton
                  disabled={!isEditing || disabledOnLoading}
                  deleteHandler={deleteHandler}
                  item={isTheme ? 'тему' : 'категорию'}
                  fz={16}
                />
              </Box>
            </Collapse>
          </KnowledgeBaseListItem>
        ))}
    </>
    );
  }

  return (
    <List
      disablePadding
    >
      {content}

      {isTheme
        ? (<>
          {
            chosenCategory &&
            <KnowledgeBaseListItem
              button
              disabled={disabledOnLoading}
              onClick={() => {
                setThemeModalOpen(true);
              }}
            >
              Создать тему

              <ButtonPlusSmall />
            </KnowledgeBaseListItem>
          }
        </>)
        : (
          <KnowledgeBaseListItem
            button
            disabled={disabledOnLoading}
            onClick={() => {
              setChosenCategory(null);
              setChosenTheme(null);
              setMainModalOpen(true);
            }}
          >
            Создать категорию

            <ButtonPlusSmall />
          </KnowledgeBaseListItem>
        )

      }

      <KnowledgeBaseMainModal
        open={isMainModalOpen}
        close={() => {
          setMainModalOpen(false);
          setEditing(false);
        }}
        isTheme={isTheme}
        category={chosenCategory}
        theme={chosenTheme}
      />

      <ThemeBlockModal
      open={isThemeModalOpen}
      close={()=>{
        setThemeModalOpen(false)
        setEditing(false)
      }}
      category={chosenCategory}
      isTheme={isTheme}
      theme={chosenTheme}

      />

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={`При удалении ${isTheme ? 'темы' : 'категории'} произошла ошибка. ${!isTheme ?'Если в категории остались темы - сначала удалите их.':'Если в теме остались блоки - сначала удалите их'}`}
      />
    </List>
  )
}