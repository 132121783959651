import { ArtifactTypeEnum, GameModel, GameModelArtifact, GameModelState } from "../../../generated/graphql";
import { RobotGeneralFields, RobotStageFields } from "./constants";

export type NotificationsType = {
  error?: boolean;
  success?: boolean;
  message: string;
};
export type ErrorType = { error: boolean, message: string };

export type InputErrors = {
  [key in RobotGeneralFields | RobotStageFields]?: ErrorType;
};

export type DeleteRobot = {
  isOpen: boolean;
  robot: GameModel;
};

export type DeleteStage = {
  isOpen: boolean;
  stage: GameModelState;
};

export enum ArtifactsNameEnum { 
  Head = 'Голова',
  Body = 'Тіло',
  Legs = 'Ноги',
  Things = 'Предметы',
  Animations = 'Анимации',
};

export type AddArtifactItem = {
  isOpen: boolean;
  type: ArtifactTypeEnum;
  clothTypeId?: string;
};

export type CurrentArtifactMode = {
  type: ArtifactTypeEnum;
  clothTypeId?: string;
};

export type DeleteArtifact = {
  isOpen: boolean;
  artifact: GameModelArtifact;
};
