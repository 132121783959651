export const stringifyDateHandler = (date?: string): string => {
  if (date) {
    const month = +date.split("-")[1];

    const monthNames = [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ];

    return `${monthNames[month - 1]}, ${date.split("-")[0]}`;
  }

  return "";
};