import React, { useState } from "react";
import { Box, Container, Table } from "@material-ui/core";
import { ButtonPlus, Header, MainTableCell, MainTableStatusCell } from "../shared/Style/Style";
import {
  MainTableHeaderAskedTitle,
  QuestionsTableRows,
} from "./AskedQuestions.style";
import { Menu } from "../shared/Menu";
import { EmptyMobileAppTableRow } from "../MobileApps/MobileApps.style";
import { FaqCreateModal } from "./FAQCreateModal";

const headers = ["Вопрос", "Ответ", "Статус"];

export const AskedQuestions = () => {
  const [isOpenCreateQuestions, setIsOpenCreateQuestions] = useState(false);
  const [isWatched,setIsWatched]=useState(false)

  const mokArr = [
    {
      id: 1,
      question: "Как создать ВОпрос?",
      answer: "нажмите на кнопку",
      isPublished:true,
    },
    {
      id: 2,
      question: "Как создать ВОпрос?",
      answer: "нажмите на кнопку",
      isPublished:false,
    },
    {
      id: 3,
      question: "Как создать ВОпрос?",
      answer: "нажмите на кнопку",
      isPublished:true,
    },
    {
      id: 4,
      question: "Как создать ВОпрос?",
      answer: "нажмите на кнопку",
      isPublished:false,
    },
    {
      id: 5,
      question: "Как создать ВОпрос?",
      answer: "нажмите на кнопку",
      isPublished:false,
    },
  ];

  const menuItems=[
    {
      name: "Просмотр",
      action: () => {
        setIsWatched(true)
        setIsOpenCreateQuestions(true)
      },
    },
    {
      name: "Редактировать",
      action: () => {},
    },
    {
      name: "Удалить",
      action: () => {},
      color: "red",
    },
  ];

  return (
    <Container maxWidth="lg">
      <Header>Частые Вопросы AR_BOOK</Header>
      <Box textAlign="right" mt={3} mb={3}>
        <ButtonPlus
          width={179}
          variant="contained"
          disabled={false}
          onClick={() => {
            setIsWatched(false)
            setIsOpenCreateQuestions(!isOpenCreateQuestions)
          }}
        >
          Создать Вопрос
        </ButtonPlus>
      </Box>

      <Table>
        <QuestionsTableRows>
          {headers.map((header) => (
            <MainTableHeaderAskedTitle key={header}>
              {header}
            </MainTableHeaderAskedTitle>
          ))}
        </QuestionsTableRows>

          {mokArr?.length?
          mokArr.map((item)=>(
            <QuestionsTableRows
            key={item.id}
            >
              <MainTableCell>
                {item.question}
              </MainTableCell>
              <MainTableCell>
                {item.answer}
              </MainTableCell>
              <MainTableStatusCell status={item.isPublished}>
                {(item.isPublished && "Опубликованно") ||
                "Не опубликованое"}
              </MainTableStatusCell>
              <MainTableCell>
                <Menu
                  vertical={36}
                  horizontal={12}
                  width={170}
                  disabled={false}
                  items={menuItems}
                />
              </MainTableCell>
              <FaqCreateModal
                questions={item.question}
                answer={item.answer}
                open={isOpenCreateQuestions}
                close={()=>setIsOpenCreateQuestions(false)}
                isWatched={isWatched}
                setIsWatched={setIsWatched}

              />
            </QuestionsTableRows>
          )):(
            <EmptyMobileAppTableRow>
            Вопросов и ответов нет
            </EmptyMobileAppTableRow>
            )
          }
      </Table>

    </Container>
  );
};
