import React, { useState, useEffect } from "react";

import { useMutation } from "@apollo/client";
import { CreateUserGroupDocument, CreateUserGroupMutation, GetUserGroupsDocument,} from "../../../generated/graphql";

import { ErrorSnackbar } from "../../shared/Error/ErrorSnackbar";

import {
  GroupModalTextField,
  GroupModalBtn,
  GroupModalBtnOrange,
  GroupModalBtnsWrapper,
  GroupModalContentCreate,
  GroupModalTitle
} from "../GroupModal.style";

interface ICreateGroupModalProps {
  handleClose(): void;
  setGroupsModalRole(e: string): void;
}

export const CreateGroupModal = ({ handleClose, setGroupsModalRole }: ICreateGroupModalProps) => {
  const [groupName, setGroupName] = useState('');

  const [createGroup, { data, loading, error }] = useMutation<CreateUserGroupMutation>(CreateUserGroupDocument,
    {
      variables: {
        userGroupData : { name: groupName }
      },
      refetchQueries: [{ query: GetUserGroupsDocument }]
    }
  );

  useEffect(() => {
    if (data) {
      setGroupName('');
      setGroupsModalRole('add');
    }
  }, [data]);

  const createGroupHandler = (): void => {
    if (!groupName) {
      return;
    }

    createGroup();
  }

  return (
    <GroupModalContentCreate>
      <GroupModalTitle>Создайте группу</GroupModalTitle>

      <GroupModalTextField
        autoFocus
        disabled={loading}
        label="Введите назване группы"
        type="text"
        fullWidth
        value={groupName}
        onChange={({ target }) => setGroupName(target.value)}
      />

      <GroupModalBtnsWrapper>
        <GroupModalBtn
          disabled={loading}
          onClick={() => {
            setGroupName('');
            handleClose();
          }}
        >
          закрыть
        </GroupModalBtn>

        <GroupModalBtnOrange
          disabled={loading}
          onClick={createGroupHandler}
        >
          создать группу
        </GroupModalBtnOrange>
      </GroupModalBtnsWrapper>

      {error && <ErrorSnackbar error={error}/>}
    </GroupModalContentCreate>
  )
}
