import React, { useEffect, useState } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

interface ICorrectionsErrorSnackbarProps {
  message: string;
  role: string;
}

export const CorrectionsErrorSnackbar = ({ message, role }: ICorrectionsErrorSnackbarProps) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, [message]);

  const handleClose = () => {
    setOpen(false);
  };

  let title;

  switch (role) {
    case 'updateCorrectionError':
      title = 'При изменении статуса правки произошла ошибка.'
      break;
  
    default:
      title = 'При создании комментария произошла ошибка.';
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity="error">
        <div>{title}</div>

        {message && <div>{message}</div>}
      </Alert>
    </Snackbar>
  );
}
