import React, { memo } from "react";
import { Grid } from "@material-ui/core";
import { Field } from "react-final-form";
import { User } from "../../../../generated/graphql";

export const BaseCheckBoxes = memo(({ user, values }: { user: User, values: User }) => {

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: `${values?.role?.id === 3 ? '70px' : 0}` }}
      >
        <Grid item xs={6} >
          <>
            Договор подписан
            <Field
              name="contract"
              component="input"
              type="checkbox"
              initialValue={false}
            />
          </>
        </Grid>

        {values?.role?.id !== 3 && (
          <Grid item xs={6}>
            <>
              NDA
              <Field
                name="nda"
                component="input"
                type="checkbox"
                initialValue={false}
              />
            </>
          </Grid>
        )}
      </Grid>

      {values?.role?.id !== 3 && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <>
              Код по ДРФО
              <Field
                name="drfo"
                component="input"
                type="checkbox"
                initialValue={false}
              />
            </>
          </Grid>
          <Grid item xs={6}>
            <>
              ИНН
              <Field
                name="inn"
                component="input"
                type="checkbox"
                initialValue={false}
              />
            </>
          </Grid>
        </Grid>
      )}

      {values?.role?.id !== 3 && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <>
              Диплом
              <Field
                name="diploma"
                component="input"
                type="checkbox"
                initialValue={false}
              />
            </>
          </Grid>
          <Grid item xs={6}>
            <>
              Паспорт
              <Field
                name="passport"
                component="input"
                type="checkbox"
                initialValue={false}
              />
            </>
          </Grid>
        </Grid>
      )}

      {values?.role?.id !== 3 && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <>
              ID катра
              <Field
                name="idCard"
                component="input"
                type="checkbox"
                initialValue={false}
              />
            </>
          </Grid>

          <Grid item xs={6}>
            <>
              Выписка
              <Field
                initialValue={false}
                disabled={!values.idCard}
                name="excerpt"
                component="input"
                type="checkbox"
              />
            </>
          </Grid>
        </Grid>
      )}
    </>
  );
});
