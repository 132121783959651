import React from "react";

import { Table, TableRow, TableBody } from "@material-ui/core";
import { NoEpicProblems } from "../../../generated/graphql";
import { CustomTable } from "../../shared/CustomTable";
import { ColorizedTableCell, CustomTableCell, CustomTableHead, CustomTableHeaderCell } from "../../shared/CustomTable/CustomTable.style";

interface INoEpicProps {
  tasks: NoEpicProblems[];
}

const headers = [
  'Ключ задачи',
  'Название',
  'Ключ этапа',
];

export const NoEpic = ({ tasks }: INoEpicProps) => {
  const content = (
    <Table>
      <CustomTableHead>
        <TableRow>
          {headers.map(header => (
            <CustomTableHeaderCell
              key={header}
              width={150}
            >
              {header}
            </CustomTableHeaderCell>
          ))}
        </TableRow>
      </CustomTableHead>

      <TableBody>
        {tasks.length
          ? (tasks.map(task => (
            <TableRow
              key={task.key}
            >
              {[
                task.key,
                task.name,
                task.epicKey,
              ].map((field, i) => (
                <ColorizedTableCell
                  key={`${i} ${field}`}
                  number={field !== 'NO EPIC' ? 0 : -1 }
                >
                  {field}
                </ColorizedTableCell>
              ))}
            </TableRow>
          )))
          : (
            <TableRow>
              <CustomTableCell colSpan={3} >Задачи отсутствуют</CustomTableCell>
            </TableRow>
          )
        }
      </TableBody>
    </Table>
  )

  return (
    <CustomTable data={tasks} tableContent={content} />
  )
}