import React from "react";
import { TableRow } from "@material-ui/core";
import { CustomTableCell, CustomTableCellNoBottom } from "../../../shared/CustomTable/CustomTable.style";

export const TasksTable = ({ tasks }: { tasks: any[] }) => {
  return (
    <>
      {tasks.length
        ? tasks.map((task, i) => (
          <TableRow key={task?.id || `${i} ${task.name}`}>
            {tasks.length - 1 !== i ? <CustomTableCellNoBottom /> : <CustomTableCell />}
            <CustomTableCell width={100}>
              {task.name}
            </CustomTableCell>
          </TableRow>
        ))
        : (
          <TableRow>
            <CustomTableCell colSpan={2}>
              Задачи отсутствуют
            </CustomTableCell>
          </TableRow>
        )
      }
    </>
  )
}