import React, { useState } from "react";

import { useQuery } from "@apollo/client";
import { GetTimeReportDocument, GetTimeReportQuery } from "../../../../generated/graphql";
import { IDateRange } from "../../../shared/DateRangePicker/DateRangePicker";

import { TableHead, Table, TableBody, TableRow, CircularProgress } from "@material-ui/core";

import { CustomTableRow, CustomTableCell, CustomTableHeaderCell, ColorizedTableCell } from "../../../shared/CustomTable/CustomTable.style";
import { LoadingBox } from "../../../shared/Style/Style";

import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { ReportModal } from "../../../ReportModal";
import { CustomTable } from "../../../shared/CustomTable";

import {
  convertSecondsToHoursHandler
} from "../../../shared/Utils/ConvertOperations/convertSecondsToHoursHandler";

const headers = [
  'Название проекта',
  'Всего по проекту',
  'План',
  'Факт',
  'Разница',
];

export const DifferenceInHoursReport = ({ dateRange }: {dateRange: IDateRange}) => {
  const [openModal, setOpenModal] = useState(false);
  const [chosenProject, setChosenProject] = useState('');

  const { data, loading, error } = useQuery<GetTimeReportQuery>(
    GetTimeReportDocument, {
      variables: dateRange
    }
  );

  let content;

  if (loading) {
    content = (
      <LoadingBox>
        <CircularProgress color='inherit'/>
      </LoadingBox>
    )
  }

  if (error) {
    content = <ErrorSnackbar error={error} />
  }

  if (data) {
    const projects = data.getTimeReport;

    content = (
      <Table>
        <TableHead>
          <TableRow>
            {headers.map(header => <CustomTableHeaderCell key={header}>{header}</CustomTableHeaderCell>)}
          </TableRow>
        </TableHead>

        <TableBody>
          {projects.length
            ? projects.map(project => (
              <CustomTableRow
                clickable={true}
                key={project.projectId}
                onClick={() => {
                  setChosenProject(project.projectId);
                  setOpenModal(true);
                }}
              >
                {[
                  project.projectName,
                  convertSecondsToHoursHandler(project.total),
                  convertSecondsToHoursHandler(project.totalTimeEstimate),
                  convertSecondsToHoursHandler(project.totalTimeSpent),
                ].map(field => (
                  <CustomTableCell>
                    {field}
                  </CustomTableCell>
                ))}
                <ColorizedTableCell
                  number={project.difference}
                >
                  {convertSecondsToHoursHandler(project.difference)}
                </ColorizedTableCell>
              </CustomTableRow>
            ))
            : (
              <TableRow>
                <CustomTableCell colSpan={5}>Проекты отсутствуют</CustomTableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    )
  }

  return (
    <>
      <CustomTable data={data?.getTimeReport} tableContent={content}/>
      {openModal && chosenProject && (
        <ReportModal
          title={data?.getTimeReport?.find(project => project.projectId === chosenProject)?.projectName}
          reportType='differenceInHours'
          open={openModal}
          closeHandler={() => {
            setOpenModal(false);
            setChosenProject('');
          }}
          projectId={chosenProject}
          dateRange={dateRange}
        />
      )}
    </>
  )
}