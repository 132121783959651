import React, { useState } from "react";
import { Box } from "@material-ui/core";
import {
  DayDateCell,
  DaysGrid,
  LightValueTxt,
  ValueHoursTxt,
} from "../../Salary/Salary.style";
import { Cell } from "../../shared/Style/Style";
import { ISalaryModalInnerProps, SalaryModal } from "../SalaryModal";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { ModalBody, ModalHeaderLeftMargin, ModalSubtitle, ModalSubtitleColorPrimary } from "../SalaryModal.style";
import { convertMinuteToHoursHandler } from "../../shared/Utils/ConvertOperations/convertMinuteToHoursHandler";

export const WorkingHoursModal = ({
  payroll,
  close,
}: ISalaryModalInnerProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [date, setDate] = useState('');

  const reports = payroll.additionalData.reports;

  const report = reports && reports.length && reports[0].times;

  const monthNumber = payroll.name.split("T")[0].split("-")[1];

  const openDayReportHandler = (day: number): void => {
    const newDate = day >= 10
      ? `${payroll.name.split("T")[0].split("-").slice(0, 2).join('-')}-${day}`
      : `${payroll.name.split("T")[0].split("-").slice(0, 2).join('-')}-0${day}`;

    setDate(newDate);
    setModalOpen(true);
  }

  return (
    <ModalBody>
      <Box
        component="div"
        display="flex"
        mb={2}
        alignItems="center"
      >
        <ModalHeaderLeftMargin>ЧАСЫ ФАКТ</ModalHeaderLeftMargin>
        <ModalSubtitle>Добавленное время -</ModalSubtitle>
        <ModalSubtitleColorPrimary>10</ModalSubtitleColorPrimary>
        <CloseIconButton close={close}/>
      </Box>

      <div>
        <DaysGrid
          days={report}
        >
          {report &&
            report?.map((day, i) => (
              <DayDateCell
                key={day}
                onClick={() => openDayReportHandler(i + 1)}
                style={{ cursor: 'pointer' }}
              >
                <LightValueTxt>
                  {i + 1}.{monthNumber}
                </LightValueTxt>
              </DayDateCell>
            ))
          }
        </DaysGrid>

        {reports?.map((rep, i) => (
          <DaysGrid
            days={report}
            key={i}
          >
            {rep?.times?.map((time, i) => (
              <Cell
                key={i}
                onClick={() => openDayReportHandler(i + 1)}
                style={{ cursor: 'pointer' }}
              >
                <ValueHoursTxt>
                  {time !== 0 ? convertMinuteToHoursHandler(time) : time}
                </ValueHoursTxt>
              </Cell>
            ))}
          </DaysGrid>
        ))}
      </div>

      {isModalOpen && (
        <SalaryModal
          role='dayReport'
          open={isModalOpen}
          data={{
            close: () => {
              setModalOpen(false);
            },
            payroll,
            date,
          }}
        />
      )}
    </ModalBody>
  );
};
