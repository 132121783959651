import React, { ChangeEvent, useState, useRef } from "react";

import { useParams } from "react-router-dom";

import { useMutation, useQuery } from "@apollo/client";
import {
  CreateUserPaymentDocument,
  CreateUserPaymentMutation,
  GetUserPaymentsByProjectDocument,
  GetUserPaymentsByProjectQuery,
  PayUserPaymentDocument,
  PayUserPaymentMutation,
  PublishUserPaymentDocument,
  PublishUserPaymentMutation,
  SetUserPaymentDocumentsDocument,
  SetUserPaymentDocumentsMutation,
  UpdateUserPaymentDocument,
  UpdateUserPaymentMutation,
  UserPayment
} from "../../generated/graphql";

import {
  Backdrop,
  CircularProgress,
  Container,
  IconButton,
  TextFieldProps,
  Table,
  TableBody,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { DatePicker } from "@material-ui/pickers";

import { ButtonPlus, defaultMaterialTheme, Header, secondary } from "../shared/Style/Style";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { CustomTable } from "../shared/CustomTable";
import {
  CustomTableHead,
  CustomTableFooter,
  CustomTableHeaderCell,
  CustomTableCell,
  CustomTableInput
} from '../shared/CustomTable/CustomTable.style'

import { ProjectEditNavBar } from "../ProjectEditNavBar";
import { PaymentConfirmationModal } from './PaymentConfirmationModal';

import {
  ErrorMassage,
  PrimaryButton,
  PaymentDatePickerInput,
  ControlWrapper,
  DeleteInvoiceBtn,
  Invoice,
  InvoiceLink,
  AddInvoicesBtn,
  InvoicesList,
  CustomDatePicker
} from "./Payments.style";

import { DropzonePdf } from "../shared/DropzonePdf/DropzonePdf";


export const Payments = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const table = useRef<HTMLTableSectionElement>(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isDropzoneOpen, setDropzoneOpen] = useState({
    status: false,
    paymentId: ''
  });

  const handleChangePage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
    table.current!.scrollTop = 0;
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    table.current!.scrollTop = 0;
  };

  interface IPaymentData {
    name: string | undefined | null;
    cost: number | undefined | null;
    estimatedDate: string | undefined | null;
  }

  //#region create payment

  const tomorrow = (new Date()).setDate((new Date()).getDate() + 1);

  const [creatingPayment, setCreatingPayment] = useState(false);

  const [createPaymentData, setCreatePaymentData] = useState<IPaymentData>({
    name: '',
    cost: 0,
    estimatedDate: (new Date(tomorrow)).toISOString(),
  });

  const [createPaymentErrors, setCreatePaymentErrors] = useState({
    nameError: false,
    costError: false,
  })

  const [createPayment, { loading: createPaymentLoading, error: createPaymentError }] = useMutation<CreateUserPaymentMutation>(
    CreateUserPaymentDocument,
    { refetchQueries: [
      {
        query: GetUserPaymentsByProjectDocument,
        variables: { projectId: projectId, limit: rowsPerPage, skip: page * rowsPerPage, }
      }
    ]}
  );

  const createPaymentHandler = () => {
    if (!createPaymentData.name) {
      setCreatePaymentErrors(state => ({
        ...state,
        nameError: true,
      }));
    }

    if (!createPaymentData.cost) {
      setCreatePaymentErrors(state => ({
        ...state,
        costError: true,
      }));
    }

    if (!createPaymentData.name || !createPaymentData.cost || !createPaymentData.estimatedDate) {
      return;
    }

    createPayment({variables: {paymentData: { projectId, ...createPaymentData }}});
    setCreatingPayment(false);
    setCreatePaymentData({
      name: '',
      cost: 0,
      estimatedDate: (new Date(tomorrow)).toISOString(),
    });
  }

  //#endregion

  //#region edit payment

  interface IEditingState {
    id: string | undefined | null;
    status: boolean;
  }

  const [isEditing, setEditing] = useState<IEditingState>({
    id: '',
    status: false,
  });

  const [updatePaymentData, setUpdatePaymentData] = useState<IPaymentData>({
    name: '',
    cost: 0,
    estimatedDate: '',
  });

  const [updatePayment, { loading: updatePaymentLoading, error: updatePaymentError }] = useMutation<UpdateUserPaymentMutation>(
    UpdateUserPaymentDocument,
    { refetchQueries: [
      {
        query: GetUserPaymentsByProjectDocument,
        variables: { projectId: projectId, limit: rowsPerPage, skip: page * rowsPerPage, }
      }
    ]}
  );

  const updatePaymentHandler = (payment: UserPayment | null | undefined): void => {
    if (
      payment?.name === updatePaymentData.name
      && payment?.cost === updatePaymentData.cost
      && payment?.estimatedDate === updatePaymentData.estimatedDate
    ) {
      return;
    }

    if (payment?.id) {
      updatePayment({ variables: {paymentData: { id: payment.id, ...updatePaymentData} } });
    }
  }

  //#endregion

  // #region delete invoice

  const [
    deletePaymentDocument,
    {
      data: deletePaymentDocumentData,
      loading: deletePaymentDocumentLoading,
      error: deletePaymentDocumentError,
    }
  ] = useMutation<SetUserPaymentDocumentsMutation>(
    SetUserPaymentDocumentsDocument,
    {
      refetchQueries: [{
        query: GetUserPaymentsByProjectDocument,
        variables: { projectId: projectId, limit: rowsPerPage, skip: page * rowsPerPage, }
      }]
    }
    );

  const deleteInvoice = (paymentId: string | null | undefined, invoiceId: string | null | undefined): void => {
    if (!paymentId && !invoiceId) {
      return;
    }

    deletePaymentDocument({variables: {
      paymentId,
      deletedDocuments: [invoiceId],
    }})
  }

  // #endregion

  //#region pay payment

  const [isPaymentConfirmationOpen, setPaymentConfirmationOpen] = useState({
    status: false,
    paymentId: ''
  });

  const [payPayment, { loading: payPaymentLoading, error: payPaymentError }] = useMutation<PayUserPaymentMutation>(
    PayUserPaymentDocument,
    { refetchQueries: [
      {
        query: GetUserPaymentsByProjectDocument,
        variables: { projectId: projectId, limit: rowsPerPage, skip: page * rowsPerPage, },
      }
    ]}
  );

  const payPaymentHandler = (id: string | null | undefined): void => {
    if (id) {
      payPayment({ variables: { paymentId: id } })
    }
  }

  //#endregion

  //#region publish payment

  const [publishPayment, { loading: publishPaymentLoading, error: publishPaymentError }] = useMutation<PublishUserPaymentMutation>(
    PublishUserPaymentDocument,
    { refetchQueries: [
      {
        query: GetUserPaymentsByProjectDocument,
        variables: { projectId: projectId, limit: rowsPerPage, skip: page * rowsPerPage, }
      }
    ]}
  );

  const publishPaymentHandler = (id: string | null | undefined): void => {
    if (id) {
      publishPayment({ variables: { paymentId: id } })
    }
  }

  //#endregion

  const {
    data: paymentsData,
    loading: paymentsLoading,
    error: paymentsError,
  } = useQuery<GetUserPaymentsByProjectQuery>(
    GetUserPaymentsByProjectDocument,
    {variables: { projectId: projectId, limit: rowsPerPage, skip: page * rowsPerPage, }}
  );

  if (paymentsLoading) {
    return (
      <Backdrop open={paymentsLoading} style={{ color: '#FFB61D' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (paymentsError) {
    return (
      <ErrorMassage>
        <ErrorSnackbar error={paymentsError} />
      </ErrorMassage>
    )
  }

  if (createPaymentError) {
    return (
      <ErrorMassage>
        <ErrorSnackbar error={createPaymentError} />
      </ErrorMassage>
    )
  }

  if (updatePaymentError) {
    return (
      <ErrorMassage>
        <ErrorSnackbar error={updatePaymentError} />
      </ErrorMassage>
    )
  }

  if (payPaymentError) {
    return (
      <ErrorMassage>
        <ErrorSnackbar error={payPaymentError} />
      </ErrorMassage>
    )
  }

  if (publishPaymentError) {
    return (
      <ErrorMassage>
        <ErrorSnackbar error={payPaymentError} />
      </ErrorMassage>
    )
  }

  const payments = paymentsData.getUserPaymentsByProject.payments;

  const tableHeaders = [
    { title: 'Наименование' },
    { title: 'Дата публикации' },
    { title: 'Время публикации' },
    { title: 'Сумма' },
    { title: 'Статус' },
    { title: 'Ожидаемая дата получения' },
    { title: 'Расчетный счет' },
    { title: '' },
    { title: '' },
    { title: '' },
  ];

  const tableContent = (
    <Table>
      <CustomTableHead>
        <TableRow >
          {tableHeaders.map((header: { title: string }, i: number) =>
            <CustomTableHeaderCell
              key={i}
              width={header.title ? 150 : 46}
            >
              {header.title}
            </CustomTableHeaderCell>
          )}
        </TableRow>
      </CustomTableHead>

      <TableBody ref={table}>
        {creatingPayment && (
          <TableRow>
            <CustomTableCell padding='none'>
              <CustomTableInput
                value={createPaymentData!.name || ''}
                onChange={({ target: { value } }) => {
                  setCreatePaymentErrors({ ...createPaymentErrors, nameError: false });
                  setCreatePaymentData({...createPaymentData, name: value})
                }}
                style={{borderColor: createPaymentErrors.nameError ? 'red' : ''}}
              />
            </CustomTableCell>

            <CustomTableCell />
            <CustomTableCell />

            <CustomTableCell padding='none'>
              <CustomTableInput
                type='number'
                value={createPaymentData!.cost || 0}
                onChange={({ target: { value } }) => {
                  setCreatePaymentErrors({ ...createPaymentErrors, costError: false });
                  setCreatePaymentData({...createPaymentData, cost: +value});
                }}
                style={{borderColor: createPaymentErrors.costError ? 'red' : ''}}
              />
            </CustomTableCell>

            <CustomTableCell />

            <CustomTableCell padding='none'>
              <ThemeProvider theme={defaultMaterialTheme}>
                <CustomDatePicker
                  variant="inline"
                  format="dd.MM.yyyy"
                  inputVariant="standard"
                  minDate={(new Date(tomorrow)).toISOString()}
                  autoOk={true}
                  value={createPaymentData.estimatedDate}
                  onChange={(date) => date && setCreatePaymentData({...createPaymentData, estimatedDate: date.toISOString()})}
                />
              </ThemeProvider>
            </CustomTableCell>

            <CustomTableCell />

            <CustomTableCell padding='none'>
              <PrimaryButton
                disabled={createPaymentLoading}
                creating={true}
                onClick={() => createPaymentHandler()}
              >
                создать
              </PrimaryButton>
            </CustomTableCell>

            <CustomTableCell />
            <CustomTableCell />
          </TableRow>
        )}

        {payments.length
          ? payments.map(payment => (
            <TableRow key={payment.id}>
              <CustomTableCell padding='none'>
                <CustomTableInput
                  disabled={payment.id !== isEditing.id}
                  value={
                    payment.id === isEditing.id
                      ? updatePaymentData.name || ''
                      : payment.name || ''
                    }
                  onChange={({ target: { value } }) => setUpdatePaymentData({...updatePaymentData, name: value})}
                />
              </CustomTableCell>

              <CustomTableCell>{(new Date(payment?.createdDate)).toLocaleDateString('ru-RU')}</CustomTableCell>

              <CustomTableCell>{(new Date(payment?.createdDate)).toLocaleTimeString('ru-RU')}</CustomTableCell>

              <CustomTableCell padding='none'>
                <CustomTableInput
                  type='number'
                  disabled={payment.id !== isEditing.id}
                  value={
                    payment.id === isEditing.id
                      ? updatePaymentData.cost || 0
                      : payment.cost || 0
                    }
                  onChange={({ target: { value } }) => setUpdatePaymentData({...updatePaymentData, cost: +value})}
                />
              </CustomTableCell>

              <CustomTableCell padding='none'>
                {payment.status.status === 'PENDING'
                  ? payment.status.displayName + ' ' + (new Date(payment?.estimatedDate)).toLocaleDateString('ru-RU')
                  : payment.status.status === 'PAID'
                    ? payment.status.displayName + ' ' + (new Date(payment?.paidDate)).toLocaleDateString('ru-RU')
                    : payment.status.displayName
                }
              </CustomTableCell>

              <CustomTableCell padding='none'>
                <ThemeProvider theme={defaultMaterialTheme}>
                  <DatePicker
                    disabled={payment.id !== isEditing.id}
                    variant="inline"
                    format="dd.MM.yyyy"
                    inputVariant="standard"
                    minDate={payment?.estimatedDate}
                    autoOk={true}
                    value={
                      payment.id === isEditing.id
                      ? (updatePaymentData.estimatedDate && (new Date(updatePaymentData.estimatedDate)))
                      : (new Date(payment?.estimatedDate))
                    }
                    onChange={(date) => date && setUpdatePaymentData({...updatePaymentData, estimatedDate: date.toISOString()})}
                    TextFieldComponent={(props: TextFieldProps): any => (
                      <PaymentDatePickerInput
                        type="text"
                        onClick={props.onClick}
                        value={props.value}
                        onChange={props.onChange}
                        disabled={payment.id !== isEditing.id}
                      />
                    )}
                  />
                </ ThemeProvider>
              </CustomTableCell>

              <CustomTableCell padding='none'>
                {!!payment.documents.length &&
                  <>
                    <InvoicesList>
                      {payment.documents.map((document, i) => (
                        <Invoice key={document.fileLink}>
                          <DescriptionIcon fontSize='small'/>

                          <InvoiceLink href={document.fileLink || ''}>Invoice {i + 1}</InvoiceLink>

                          <DeleteInvoiceBtn
                            disabled={deletePaymentDocumentLoading || payment.isPaid}
                            onClick={() => deleteInvoice(payment.id, document.id)}
                          >
                            <DeleteIcon fontSize='small'/>
                          </DeleteInvoiceBtn>
                        </Invoice>
                      ))}
                    </InvoicesList>

                    <AddInvoicesBtn
                      disabled={payment.isPaid}
                      onClick={() => setDropzoneOpen({
                        status: true,
                        paymentId: payment.id
                      })}
                    >
                      <AddCircleIcon fontSize='small'/>
                    </AddInvoicesBtn>
                  </>
                }

                {!payment.documents.length &&
                  <PrimaryButton
                    onClick={() => setDropzoneOpen({
                      status: true,
                      paymentId: payment.id
                    })}
                  >
                    прикрепить
                  </PrimaryButton>
                }

                <DropzonePdf
                  open={isDropzoneOpen.status}
                  close={() => setDropzoneOpen({
                    status: false,
                    paymentId: ''
                  })}
                  limit={rowsPerPage}
                  skip={page * rowsPerPage}
                  paymentId={isDropzoneOpen.paymentId}
                />
              </CustomTableCell>
              <CustomTableCell padding='none'>
                <PrimaryButton
                  published={payment.isPublished}
                  editing={isEditing.status && isEditing.id === payment.id}
                  disabled={
                    updatePaymentLoading
                    || payPaymentLoading
                    || publishPaymentLoading
                    || !payment.documents.length
                    || payment.isPaid
                  }
                  onClick={() => {
                    if (isEditing.id === payment.id) {
                      updatePaymentHandler(payment);
                      setEditing({
                        id: undefined,
                        status: false,
                      });
                      setUpdatePaymentData({
                        name: '',
                        cost: 0,
                        estimatedDate: '',
                      });

                      return;
                    }

                    if (!payment.isPublished) {
                      publishPaymentHandler(payment.id);
                    }
                  }}
                >
                  {payment.id === isEditing.id
                    ? 'сохранить'
                    : payment.isPublished
                      ? 'опубликован'
                      : 'опубликовать'
                  }
                </PrimaryButton>
              </CustomTableCell>

              <CustomTableCell>
                <input
                  type='checkbox'
                  disabled={payPaymentLoading || payment.isPaid || !payment.isPublished}
                  checked={payment.isPaid}
                  onClick={() => setPaymentConfirmationOpen({
                    status: true,
                    paymentId: payment.id
                  })}
                />
              </CustomTableCell>

              <CustomTableCell padding='none'>
                <IconButton
                  style={{color: secondary}}
                  aria-label="edit"
                  disabled={payment.isPaid}
                  onClick={() => {
                    if (isEditing.id && payment.id !== isEditing.id) {
                      return;
                    }

                    setEditing({
                      id: payment.id,
                      status: true,
                    });
                    setUpdatePaymentData({
                      name: payment.name,
                      cost: payment.cost,
                      estimatedDate: payment?.estimatedDate,
                    })
                  }}
                >
                  <EditIcon />
                </IconButton>
              </CustomTableCell>

              <PaymentConfirmationModal
                open={isPaymentConfirmationOpen.status}
                close={() => setPaymentConfirmationOpen({
                  status: false,
                  paymentId: ''
                })}
                paymentId={isPaymentConfirmationOpen.paymentId}
                payPayment={payPaymentHandler}
              />
            </TableRow>
          ))
          : (
            <TableRow>
              <CustomTableCell colSpan={10}>
                Платежи отсутсвуют
              </CustomTableCell>
            </TableRow>
          )
        }
      </TableBody>

      <CustomTableFooter>
        <TableRow style={{width: '100%'}}>
          <TablePagination
            rowsPerPageOptions={[10, 20]}
            count={paymentsData.getUserPaymentsByProject.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </CustomTableFooter>
    </Table>
  )

  return (
    <Container  maxWidth="lg">
      <Header>
        Платежи
      </Header>

      <ProjectEditNavBar/>

      <ControlWrapper>
        <ButtonPlus
          width={180}
          variant="contained"
          onClick={() => setCreatingPayment(true)}
        >
          Добавить
        </ButtonPlus>
      </ControlWrapper>

      <CustomTable data={payments}  tableContent={tableContent}/>
    </Container>
  )
}
