import React, { useEffect, useState } from "react";

import { IKnowledgeBaseBlockInnerProps } from "../../KnowledgeBaseBlockItem";

import { Box, Collapse, InputAdornment } from "@material-ui/core";

import { CloseIconButton } from "../../../shared/Buttons/CloseIconButton";
import { DeleteIconButton } from "../../../shared/Buttons/DeleteIconButton";
import { EditIconButton } from "../../../shared/Buttons/EditIconButton";
import { SaveIconButton } from "../../../shared/Buttons/SaveIconButton";
import { CheckboxStyled } from "../../../shared/Style/Style";
import { BlockInput } from "../../KnowledgeBaseBlockItem.style";

import { getKnowledgeBaseInputLabel } from "../../../shared/Utils/OtherOperations/getKnowledgeBaseInputLabel";


export const QuestionBlock = ({
  disabled,
  blockTypeData,
  setBlockTypeData,
  block,
  createAnswer,
  updateAnswer,
  deleteAnswer,
  successRequest
}: IKnowledgeBaseBlockInnerProps) => {
  const { testAnswers, id, type } = block;

  const [content, setContent] = useState('');
  const [isAnswerTrue, setAnswerTrue] = useState(false);
  const [chosenItemId, setChosenItemId] = useState('');
  const [isCreating, setCreating] = useState(false);

  useEffect(() => {
    if (successRequest) {
      setContent('');
      setChosenItemId('');
      setAnswerTrue(false);
      setCreating(false);
    }
  }, [successRequest]);

  useEffect(() => {
    if (disabled) {
      setContent('');
      setChosenItemId('');
      setAnswerTrue(false);
      setCreating(false);
    }
  }, [disabled]);


  return (
    <>
      <Box
        mb={!!block.testQuestion ? '20px' : '0'}
      >
        <BlockInput
          fullWidth
          disabled={disabled}
          type={block.type}
          label={getKnowledgeBaseInputLabel(block.type)}
          value={blockTypeData.testQuestion}
          onChange={({ target: { value } }) => setBlockTypeData({ ...blockTypeData, testQuestion: value })}
        />
      </Box>

      <Collapse
        in={!!block.testQuestion}
      >
        {!!testAnswers.length
          && testAnswers.map(answer => (
            <BlockInput
              key={answer.id}
              fullWidth
              disabled={disabled || !chosenItemId || chosenItemId !== answer.id}
              type={type}
              value={chosenItemId === answer.id ? content : answer.content}
              onChange={({ target: { value } }) => setContent(value)}
              InputProps={{
                startAdornment: (
                  <CheckboxStyled
                    checked={chosenItemId === answer.id ? isAnswerTrue : answer.isAnswerTrue }
                    onChange={() => setAnswerTrue(!isAnswerTrue)}
                  />
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Collapse
                      in={!disabled}
                    >
                      {chosenItemId === answer.id
                        ? (
                          <>
                            <SaveIconButton
                              save={() => updateAnswer(answer.id, content, isAnswerTrue)}
                              disabled={disabled}
                            />

                            <CloseIconButton
                              close={() => {
                                setContent('');
                                setChosenItemId('');
                                setAnswerTrue(false)
                              }}
                              disabled={disabled}
                              position='static'
                            />
                          </>
                        )
                        : (
                          <>
                            <EditIconButton
                              edit={() => {
                                setChosenItemId(answer.id);
                                setContent(answer.content);
                                setAnswerTrue(answer.isAnswerTrue)
                              }}
                              disabled={disabled || isCreating || (!!chosenItemId && chosenItemId !== answer.id)}
                            />

                            <DeleteIconButton
                              disabled={disabled || isCreating || (!!chosenItemId && chosenItemId !== answer.id)}
                              deleteHandler={() => deleteAnswer(answer.id)}
                              item='ответ'
                            />
                          </>
                        )
                      }
                    </Collapse>
                  </InputAdornment>
                )
              }}
            />
          ))
        }

        <BlockInput
          fullWidth
          disabled={disabled || !!chosenItemId}
          type={type}
          placeholder='Создать ответ'
          value={chosenItemId ? '' : content}
          onFocus={() => setCreating(true)}
          onBlur={() => setCreating(false)}
          onChange={({ target: { value } }) => setContent(value)}
          InputProps={{
            startAdornment: (
              <CheckboxStyled
                checked={!chosenItemId ? isAnswerTrue : false }
                onChange={() => setAnswerTrue(!isAnswerTrue)}
              />
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Collapse
                  in={!disabled}
                >
                  <SaveIconButton
                    save={() => createAnswer(id, content, isAnswerTrue)}
                    disabled={disabled || !!chosenItemId}
                  />
                </Collapse>
              </InputAdornment>
            )
          }}
        />
      </Collapse>
    </>
  )
}