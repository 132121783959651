import {ErrorsState, } from "./Modal/types";
import {INITIAL_ERRORS} from "./constants";
import { checkYoutubeLink } from "../shared/Utils/OtherOperations/checkYoutubeLink";

const checkRequiredFields = (fields: ErrorsState) => {
  const errors: ErrorsState = {}
  for (const [key, value] of Object.entries(fields)) {
    if (!value && Object.keys(INITIAL_ERRORS).find(k => k === key)) {
      errors[key] = "Обязательное поле"
    }
  }
  return errors
}

export const formValidate = (fields: ErrorsState) => {
  const errors = checkRequiredFields(fields)

  const { video } = fields;

  if (!checkYoutubeLink(video)) {
    errors.video = "Ошибка ютюб"
  }
  return errors
}

export const cutString = (str: string, maxLength: number): string =>
  str?.length > maxLength ? str.slice(0, maxLength).concat("...") : str;