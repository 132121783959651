import { useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { mobileAppModel } from "../../state";
import { surveyLabels } from "../../constants"
import { Box, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { MobileAppModalBundleName } from "../../../MobileAppModal.style"
import { CustomRadioGroup } from "../../../../shared/CustomRadioGroup"
import { ButtonCustom } from "../../../../shared/Style/Style";
import { CreateSmartNotificationModal } from "../../../../MobileAppModalStatistic/CreateSmartNotificationModal";

export const AppModelRates = () => {
  const isLoading = useReactiveVar(mobileAppModel.updateLoading);
  const smartNotifications = useReactiveVar(mobileAppModel.smartNotifications);
  const model = useReactiveVar(mobileAppModel.model);
  
  const [isCreateSmartNotification, setIsCreateSmartNotificationModal] = useState(false);
  
  if (!model) return null;

  const { id, showSmartNotification, smartNotificationId } = model;

    
    const setShowSmartNotification = (value: { show: boolean }) => {
        mobileAppModel.setShowSmartNotification(value.show);
    };

    return (
        <>
            <MobileAppModalBundleName>Опрос\Оценка в приложении</MobileAppModalBundleName>
              <CustomRadioGroup
                items={surveyLabels}
                chosenItem={showSmartNotification}
                state={{ show: showSmartNotification }}
                setNewState={setShowSmartNotification}
                fieldName="show"
                horizontal={true}
                disabled={isLoading}
              />
              {smartNotificationId === smartNotifications?.id ? (
                <Box mt={1} display="flex" alignItems="center">
                  <span>
                    {smartNotifications?.description}
                    <IconButton
                      disabled={isLoading}
                      onClick={()=> mobileAppModel.deleteSmartNotification(id, smartNotificationId)}
                    >
                      <Delete color="secondary" />
                    </IconButton>
                  </span>
                </Box>
              ) : (
                <ButtonCustom
                  width="200"
                  disabled={isLoading}
                  onClick={() =>
                    setIsCreateSmartNotificationModal(
                      !isCreateSmartNotification
                    )
                  }
                >
                  Создать Уведомление
                </ButtonCustom>
              )}
            
            <CreateSmartNotificationModal
                open={isCreateSmartNotification}
                close={() => setIsCreateSmartNotificationModal(false)}
                applicationMainModelId={id}
            />
    
        </>
    );
};