import styled from "styled-components";
import { IconButton, Button, Dialog } from "@material-ui/core";
import { lightBlack, primary, Cell, HeaderGrid, TableGrid } from "../shared/Style/Style";
import { CustomTableInput } from "../shared/CustomTable/CustomTable.style";

export const ModalBody = styled.div`
  border-radius: 4px;
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.2);
  min-width: 450px;
  padding: 40px;
`;

export const ModalHeader = styled.h3`
  font-size: 18px;
  margin: 0;
  color: #000;
  text-transform: uppercase;
  text-align: center;
`;

export const ModalHeaderLeftMargin = styled(ModalHeader)`
  margin: 0 4rem 0 0;
`;

export const BtnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Btn = styled(Button)`
  display: block;
  transition: opacity 0.3s;
  padding: 10px 25px;

  &:hover {
    opacity: 0.85;
  }
`;

export const SubmitBtn = styled(Btn)`
  background-color: ${primary};
  color: #fff;

  &:hover {
    background-color: ${primary};
  }
`;

export const CloseBtn = styled(Btn)`
  background-color: transparent;
  transition: background-color 0.3s;
  color: #333;

  &:hover {
    background-color: #eee;
  }
`;

export const ModalForm = styled.form`
  margin-top: 40px;
`;

export const ModalField = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const ModalLabel = styled.label`
  color: ${lightBlack};
  font-size: 14px;
  line-height: 16px;
`;

interface IModalInputCustomProps {
  error?: boolean,
}

export const ModalInput = styled.input<IModalInputCustomProps>`
  appearance: textfield;
  width: 200px;
  text-align: center;
  outline: none;
  border-style: solid;
  border-width: 0 0 1px 0;
  color: ${props => props?.error ? 'red' : 'inherit'};
  border-color: ${props => props?.error ? 'transparent transparent #ff1111 transparent' : 'transparent transparent #bbb transparent'};
  font-size: 14px;
  line-height: 16px;
  transition: border-color 0.3s, color 0.3s;
  &:focus {
    border-color: ${props => props?.error 
      ? 'transparent transparent #ff1111 transparent'
      : `transparent transparent ${primary} transparent`
    };
    color: ${props => props?.error ? 'red' : 'inherit'}
  }
  &::-webkit-outer-spin-button {
    display: none;
  }
  &::-webkit-inner-spin-button {
    display: none;
  }
`;

export const ModalErrorTxt = styled.span`
  position: absolute;
  display: block;
  color: #ff1111;
  font-size: 14px;
  top: 25px;
  right: 0;
`;

export const DatePicker = styled.input`
  visibility: hidden;
  height: 0;
  width: 0;
  padding: 0;
  position: absolute;
  z-index: -1;
`;

export const DateContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 60px;
`;

export const DateControlBtn = styled(IconButton)`
  color: ${primary};
`;

interface IDateDisplayCustomProps {
  error?: boolean,
}

export const DateDisplay = styled.p<IDateDisplayCustomProps>`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: ${props => props.error ? 'red' : '#333'};
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const CenteredErrorTxt = styled(ModalErrorTxt)`
  left: 0;
  margin: auto;
  text-align: center;
`;

export const BottomErrorTxt = styled(CenteredErrorTxt)`
  top: unset;
  bottom: 0;
`;

export const ErrorDateTxt = styled(CenteredErrorTxt)`
  top: 60px;
  text-align: right;
`;

export const ModalMessage = styled.p`
  font-size: 16px;
  color: ${lightBlack};
  text-align: center;
  margin: 25px 0;
`;

export const ModalMessageHighlighted = styled.span`
  font-size: 16px;
  color: ${primary};
  font-weight: bold;
`;

export const ModalSubtitle = styled.span`
  color: #000;
  font-size: 12px;
  text-align: center;
  margin-right: 1rem;
`;

export const ModalSubtitleColorPrimary = styled(ModalSubtitle)`
  color: ${primary};
`;

export const KpiTable = styled.div`
  margin: 0 40px 0 0;
`;

export const FooterCell = styled(Cell)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeadersGrid = styled(HeaderGrid)`
  grid-template-columns: 3fr 2fr 1fr 1fr 1fr 2fr 2fr;
`;

export const BodyRowGrid = styled(TableGrid)`
  grid-template-columns: 3fr 2fr 1fr 1fr 1fr 2fr 2fr;
`;

export const FooterGrid = styled(TableGrid)`
  grid-template-columns: 3fr 2fr 1fr 1fr 1fr 2fr 2fr;
  background-color: ${primary};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const CustomTableSalaryInput = styled(CustomTableInput)`
  border: none;
`;

export const ContainerKpiSaveButton = styled.div`
  position: absolute;
  right: -51px;
  top: 9px;
`

export const ContainerKpiButtons = styled.div`
  position: absolute;
  right: -51px;
  top: 9px;
  display: flex;
  align-items: center;
`
