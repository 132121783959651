import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { CloseIconButton } from "../shared/Buttons/CloseIconButton";
import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
  LoadingBox,
} from "../shared/Style/Style";
import {
  EightPointRating,
  FivePointRating,
  FourPointRating,
  NinePointRating,
  OnePointRating,
  SevenPointRating,
  SixPointRating,
  TenPointRating,
  ThreePointRating,
  TwoPointRating,
} from "./IconPoint/IconPoint";
import { useLazyQuery } from "@apollo/client";
import {
  GetAnswersWithPercentsBySmartNotificationIdDocument,
  GetAnswersWithPercentsBySmartNotificationIdQuery,
} from "../../generated/graphql";
import { InformationControl } from "../MobileAppModelsList/MobileAppModels.style";
import { MobileAppStatisticUsersListModal } from "./MobileAppStatisticUsersListModal";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { ExportAllUsers } from "./ExportAllUsers";

interface IMobileAppModalStatistic {
  open: boolean;
  smartNotificationId?: string;

  close(): void;
}

export const MobileAppModalStatistic = ({
  open,
  close,
  smartNotificationId,
}: IMobileAppModalStatistic) => {

  const [isOpenUsersList, setIsOpenUsersList] = useState( false );
  const [choseRating, setChoseRating] = useState( 0 );

  const [
    getAnswer,
    {data: getAnswerData, loading: getAnswersLoading, error: getAnswerError},
  ] = useLazyQuery<GetAnswersWithPercentsBySmartNotificationIdQuery>(
    GetAnswersWithPercentsBySmartNotificationIdDocument
  );

  const closeHandler = () => {
    close();
  };

  useEffect( () => {
    if (smartNotificationId) {
      getAnswer( {
        variables: {
          smartNotificationId,
        },
      } );
    }
  }, [smartNotificationId] );

  const rattingIcon = [
    {
      rating: 1,
      icon: <OnePointRating/>,
    },
    {
      rating: 2,
      icon: <TwoPointRating/>,
    },
    {
      rating: 3,
      icon: <ThreePointRating/>,
    },
    {
      rating: 4,
      icon: <FourPointRating/>,
    },
    {
      rating: 5,
      icon: <FivePointRating/>,
    },
    {
      rating: 6,
      icon: <SixPointRating/>,
    },
    {
      rating: 7,
      icon: <SevenPointRating/>,
    },
    {
      rating: 8,
      icon: <EightPointRating/>,
    },
    {
      rating: 9,
      icon: <NinePointRating/>,
    },
    {
      rating: 10,
      icon: <TenPointRating/>,
    },
  ];

  let content;

  if (getAnswersLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit"/>
      </LoadingBox>
    );
  }

  if (getAnswerError) {
    content = <ErrorSnackbar error={getAnswerError}/>;
  }

  if (getAnswerData) {
    const answers =
      getAnswerData?.getAnswersWithPercentsBySmartNotificationId?.answers;

    content = (
      <>
        {answers.length && (
          <Box display="flex" justifyContent="space-between">
            <Box>
              {rattingIcon.length &&
              rattingIcon.map( ({rating, icon}) => (
                <Box
                  height="50px"
                  display="flex"
                  alignItems="center"
                  key={rating}
                >
                  {icon}
                  <Box ml={1}>{rating} балл</Box>
                </Box>
              ) )}
            </Box>
            <Box>
              {answers.map( ({ratting, total}, index) => (
                <Box
                  height="50px"
                  display="flex"
                  alignItems="center"
                  key={ratting}
                >
                  <InformationControl
                    variant="text"
                    disabled={!total}
                    onClick={() => {
                      setChoseRating( ratting );
                      setIsOpenUsersList( true );
                    }}
                  >
                    {" "}
                    {total} пользователей
                  </InformationControl>
                  <ExportAllUsers
                    total={total}
                    ratting={ratting}
                    smartNotificationId={smartNotificationId}
                  />
                </Box>
              ) )}
            </Box>
          </Box>
        )}
        <MobileAppStatisticUsersListModal
          open={isOpenUsersList}
          close={() => setIsOpenUsersList( false )}
          ratting={choseRating}
          smartNotificationId={smartNotificationId}
        />
      </>
    );
  }

  return (
    <Dialog open={open}>
      <CloseIconButton close={closeHandler}/>

      <DialogContent style={{minWidth: "400px", padding: "30px"}}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <DialogTitle>Статистика Опрос/Оценка</DialogTitle>
        </Box>
        {content}
        <ButtonsWrapper>
          <CancelButton disabled={false} onClick={closeHandler}>
            Отмена
          </CancelButton>

          <ButtonCustom disabled={false} onClick={closeHandler}>
            Ок
          </ButtonCustom>
        </ButtonsWrapper>
      </DialogContent>
    </Dialog>
  );
};
