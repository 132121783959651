import {useCallback, useEffect, useState} from "react";
import { useLazyQuery } from "@apollo/client";
import { useRequestError } from "../../../hooks";
import {
  GetCreatedPaymentsAdminReportsDocument,
  GetCreatedPaymentsAdminReportsQuery,
  PaymentFromType,
} from "../../../generated/graphql";
import { ReportsContainer } from "../ReportsContainer";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { VerticalBarChart } from "../VerticalBarChart/VerticalBarChart";
import { getChartLabelsData, getChartQuantityData, getMessage } from "../utils";
import {
  ACCESS_FILTER_TYPES,
  PAYMENT_FILTER_TYPES,
  PERIOD_FILTER_TYPES,
  PRODUCT_FILTER_TYPES,
  SPACE_FILTER_TYPES,
  TOP_FILTER,
  FILTER_INTERNAL,
  REPORT_VARIANTS,
  ERROR_MESSAGE_PAYMENTS,
  PAYMENT_FILTER_TYPES_FOR_APP, PAYMENT_FILTER_TYPES_MAPS_BY_PAYMENT_FROM,
} from "../constants";
import { ReportTopFilter } from "../ReportTopFilter/ReportTopFilter";


export const PaymentTypesReport = () => {
  
  const [topFilterVariables, setTopFilterVariables] = useState(TOP_FILTER);
  const [filterVariablesTypes, setFilterVariablesTypes] = useState(FILTER_INTERNAL);
  const [filterVariablesAccess, setFilterVariablesAccess] = useState(FILTER_INTERNAL);

  const [isAllPeriodDateRangeData, setAllPeriodDateRangeData] = useState(false);
  const [isDateRangePickerOpen, setDateRangePickerOpen] = useState(false);

  const {from:topFilterFrom, to: topFilterTo, productType} = topFilterVariables;
  const isDatePickerActive = (!!topFilterFrom || !!topFilterTo) && !isAllPeriodDateRangeData;

  const [loadPayments, { data: paymentsData, loading: paymentsLoading, error: paymentsError }] = useLazyQuery<GetCreatedPaymentsAdminReportsQuery>(
    GetCreatedPaymentsAdminReportsDocument, {
      fetchPolicy: 'cache-and-network',
  });

    const [loadAccesses, { data: accessesData, loading: accessesLoading, error: accessesError }] = useLazyQuery<GetCreatedPaymentsAdminReportsQuery>(
      GetCreatedPaymentsAdminReportsDocument, {
      fetchPolicy: 'cache-and-network',
  });
   
  useEffect(() => {
    if (topFilterFrom && topFilterTo) {
      loadPayments({
        variables: {
          topFilter: topFilterVariables,
          filter: filterVariablesTypes,
        }
      });
    }
   }, [topFilterVariables, filterVariablesTypes]);
     
  useEffect(() => {
    if (topFilterFrom && topFilterTo) {
      loadAccesses({
        variables: {
          topFilter: topFilterVariables,
          filter: filterVariablesAccess,
        }
      });
    }
  }, [topFilterVariables, filterVariablesAccess]);


  const paymentsReport =  paymentsData?.getCreatedPaymentsAdminReports.dataChart || null;
  const totalPayments = paymentsData?.getCreatedPaymentsAdminReports.dataTotal;

  const accessesReport = accessesData?.getCreatedPaymentsAdminReports.dataChart || null;
  const totalAccesses = accessesData?.getCreatedPaymentsAdminReports.dataTotal;

  const { requestError, setRequestError, errorMessage } = useRequestError(paymentsError || accessesError, ERROR_MESSAGE_PAYMENTS);

  const variant = REPORT_VARIANTS.paymentsTypes;
  const chartLabels = getChartLabelsData( paymentsReport, filterVariablesTypes.periodType);
  const chartQuantity = getChartQuantityData(totalPayments, variant.metric);
  const chartLabelsAccess = getChartLabelsData(accessesReport, filterVariablesAccess.periodType);
  const chartQuantityAccess = getChartQuantityData(totalAccesses, variant.metric);

  const handleSetTopFilterVariables = (newValue) => {
    setTopFilterVariables((prevState) => ({ ...prevState, ...newValue }));
    if ('productType' in newValue) {
      setFilterVariablesTypes((prevState) => ({ ...prevState, paymentTypes: null }));
    }
  };

  const dataReportTopFilter = {
    handleDateRangePickerOpen: setDateRangePickerOpen,
    handleAllPeriodDateRangeData: setAllPeriodDateRangeData,
    isDatePickerActive: isDatePickerActive,
    titleDatePickerButton: "Период",
    titleDateButton: "За всё время",
    spaceFilterFieldsData: SPACE_FILTER_TYPES,
    productFilterFieldsData: PRODUCT_FILTER_TYPES,
    isDateRangePickerOpen: isDateRangePickerOpen,
    isAllPeriodDateRangeData,
    setTopFilterVariables: handleSetTopFilterVariables,
    variant,
  };

  const dataVerticalChartTypePayments = {
    chartHeader: {
      chartTitle: "Распределение по типам платежей",
      chartSubtitle: "Общее количество платежей",
      totalValue: chartQuantity,
    },
    chart: chartLabels,
    chartFilter: {
      period: PERIOD_FILTER_TYPES,
      activePeriod: filterVariablesTypes.periodType,
      paymentsType: PAYMENT_FILTER_TYPES_MAPS_BY_PAYMENT_FROM[productType],
      setInternalChartFilter: setFilterVariablesTypes,
      isResetSelectorNeeded: true,
      variant,
    },
    isLoadingProgress: paymentsLoading,
    message: getMessage(totalPayments),
  };
  
  const dataVerticalChartTypeAccesses = {
    chartHeader: {
      chartTitle: "Количество платных и бесплатных доступов",
      chartSubtitle: "Общее количество открытых доступов",
      totalValue: chartQuantityAccess,
    },
    chart: chartLabelsAccess,
    chartFilter: {
      period: PERIOD_FILTER_TYPES,
      activePeriod: filterVariablesAccess.periodType,
      paymentsType: ACCESS_FILTER_TYPES,
      setInternalChartFilter: setFilterVariablesAccess,
      variant,
    },
    isLoadingProgress: accessesLoading,
    message: getMessage(totalAccesses),
  };

  return (
    <ReportsContainer>

      <ReportTopFilter {...dataReportTopFilter} />

      <VerticalBarChart {...dataVerticalChartTypePayments} />

      <VerticalBarChart {...dataVerticalChartTypeAccesses} />
      
      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
      
    </ReportsContainer>
  );
};
