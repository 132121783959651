import React from "react";

import { Project } from "../../generated/graphql";

import { Dialog, Container, ListItem, ListItemText } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import { ProjectStageList, CloseBtn } from "./ProjectStageModal.style";


interface IProjectStageModalProps {
  isOpen: boolean;
  closeProjectStageModalHandler(): void;
  project: Project | undefined;
}


export const ProjectStageModal = ({isOpen, closeProjectStageModalHandler, project}: IProjectStageModalProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={closeProjectStageModalHandler}
    >
      <Container>
        <CloseBtn
          onClick={closeProjectStageModalHandler}
        >
          <CloseIcon/>
        </CloseBtn>

        <ProjectStageList>
          {project?.stages ? (
            project?.stages.map((stage, i) => (
            <ListItem key={stage ? `${stage + i}`: i}>
              <ListItemText primary={`${i + 1}. ${stage}`}/>
            </ListItem>
          ))) : (
            <ListItem >
              <ListItemText primary={`У этого проекта пока нет этапов`}/>
            </ListItem>
          )}
        </ProjectStageList>
      </Container>
    </Dialog>
  )
}