import { Box, CircularProgress} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { KanbanMenu } from "../KanbanMenu";
import { EditIconButton } from "../shared/Buttons/EditIconButton";
import { DEFAULT_AVATAR_URL } from "../shared/constants";
import {
  LoadingBox,
} from "../shared/Style/Style";
import {
  KanbanColumnHeader,
  ListContainer,
  KanbanCard,
  KanbanCardContent,
  KanbanUserAvatar,
  KanbanColumFooter,
  KanbanColumnContainer,
} from "./KanbanColumn.style";
import {
  ProjectTask,
  GetProjectTasksByWorkflowStepDocument,
  GetProjectTasksByWorkflowStepQuery,
  KanbanWorkflowStep,
} from "../../generated/graphql";
import { KanbanTaskModal } from "../KanbanTaskModal";
import { KanbanAddTasksModal } from "../KanbanAddTasksModal";
import { useQuery } from "@apollo/client";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import {translateBaseTaskStatuses} from "../shared/Utils/OtherOperations/translateBaseTaskStatuses";

interface IKanbanColumnProps {
  column: KanbanWorkflowStep;
  projectId: string;
  disabledOnClear?: boolean;
  tasks: { [key: string]: ProjectTask[] };
  setTasks(tasks: { [key: string]: ProjectTask[] }): void;
  tasksIds: string[];
  dropTaskLoading: boolean;
}

export const KanbanColumn = React.memo(
  ({
    column,
    projectId,
    disabledOnClear,
    tasks,
    setTasks,
    tasksIds,
    dropTaskLoading,
  }: IKanbanColumnProps) => {
    const [editedTask, setEditedTask] = useState("");
    const [isCreating, setCreating] = useState(false);
    const [addTasksModal, setAddTasksModal] = useState(false);

    const columnTitle = translateBaseTaskStatuses(column.name);

    const getCloseHandler = (): void => {
      if (isCreating) {
        return setCreating(false);
      }

      return setEditedTask("");
    };

    const { data, loading, error } =
      useQuery<GetProjectTasksByWorkflowStepQuery>(
        GetProjectTasksByWorkflowStepDocument,
        {
          variables: {
            projectId,
            workflowStepId: column.id,
            limit: 1000,
            skip: 0,
          },
          fetchPolicy: 'network-only'
        }
      );

    useEffect(() => {
      if (data) {
        const tasksFromServer = data.getProjectTasksByWorkflowStep.tasks;

        setTasks({
          ...tasks,
          [column.id]: tasksFromServer,
        });
      }
    }, [data]);

    let tasksList;

    if (loading || dropTaskLoading) {
      tasksList = (
        <LoadingBox>
          <CircularProgress color="inherit" />
        </LoadingBox>
      );
    }

    if (error) {
      tasksList = <ErrorSnackbar error={error} />;
    }

    if (data) {
      tasksList = (
        <Droppable droppableId={column.id}>
          {(provided) => (
            <ListContainer ref={provided.innerRef} {...provided.droppableProps}>
              {tasks[column.id]?.map((task, i) => (
                <Draggable
                  draggableId={task.id}
                  isDragDisabled={disabledOnClear}
                  index={i}
                  key={task.id}
                >
                  {(provided) => (
                    <KanbanCard
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      innerRef={provided.innerRef}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <span>{task.name}</span>

                        <EditIconButton
                          disabled={disabledOnClear}
                          edit={() => setEditedTask(task.id)}
                        />
                      </Box>

                      <KanbanUserAvatar
                        prim={task?.assignee?.photo || DEFAULT_AVATAR_URL}
                      />
                    </KanbanCard>
                  )}
                </Draggable>
              ))}

              {provided.placeholder}
            </ListContainer>
          )}
        </Droppable>
      );
    }

    return (
      <KanbanColumnContainer>
        <KanbanColumnHeader>
          <span>{columnTitle}</span>
          <KanbanMenu
            variant="column"
            column={column.name}
            actions={{
              add: () => setAddTasksModal(true),
            }}
          />
        </KanbanColumnHeader>

        {tasksList}

        <KanbanColumFooter
          onClick={() => {
            !disabledOnClear && setCreating(true);
          }}
        >
          Создать задачу +
        </KanbanColumFooter>

        <KanbanTaskModal
          open={isCreating ? isCreating : !!editedTask}
          close={getCloseHandler}
          projectId={projectId}
          workflowStepId={column.id}
          workflowStepOrder={tasks[column.id]?.length || 0}
          taskId={isCreating ? undefined : editedTask}
        />

        <KanbanAddTasksModal
          projectId={projectId}
          open={addTasksModal}
          workflowStepId={column.id}
          workflowStepStartOrder={tasks[column.id]?.length || 0}
          close={() => {
            setAddTasksModal(false);
          }}
          tasksIds={tasksIds}
        />
      </KanbanColumnContainer>
    );
  }
);
