import { useQuery } from "@apollo/client";
import {
  GetSchoolRegionsDocument,
  GetSchoolRegionsQuery
} from "../../../generated/graphql";
import { useRequestError } from "../../../hooks";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { StyledTextField } from "../Style";
import { LoadingBox } from "../../shared/Style/Style";

interface SelectRegionProps {
  countryId: string;
  regionId: string;
  inputError: boolean;
  setRegionId: (regionId: string) => void;
}

export const SelectRegion = ({ countryId, regionId, inputError, setRegionId }: SelectRegionProps) => {
  const { data, loading, error } = useQuery<GetSchoolRegionsQuery>(GetSchoolRegionsDocument, {
    variables: {
      countryId,
      limit: 500,
    },
  });

  const { requestError, setRequestError } = useRequestError(error);

  if (loading) {
    return (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  const schoolRegions = data?.getSchoolRegions ?? [];

  return (
    <>
      <Autocomplete
        options={schoolRegions.map((schoolRegion) => schoolRegion.id)}
        getOptionLabel={(option) => schoolRegions.find((schoolRegion) => schoolRegion.id === option)?.name || ""}
        onChange={(_, value) => setRegionId(value)}
        value={schoolRegions.find(schoolRegion => schoolRegion.id === regionId)?.id || null}
        renderInput={(params) => (
          <StyledTextField
            label="Область"
            variant="filled"
            error={inputError}
            {...params}
          />
        )}
      />
    
      <ErrorMessageSnackbar
        open={requestError}
        message="При загрузке списка областей произошла ошибка"
        closeHandler={() => setRequestError(false)}
      />
    </>
  );
};
