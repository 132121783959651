import React from "react";

import { Box, ListItem } from "@material-ui/core";

import { ButtonCustom, SmallUserAvatarStyle } from "../shared/Style/Style";
import { MobileAppParticipantsList } from "./MobileAppPageEdit.style";

import { DEFAULT_AVATAR_URL } from "../shared/constants";
import { User } from "../../generated/graphql";

interface IParticipantsHiddenPartProps {
  participants: User[];
  setAccessModal(modalStatus: boolean): void;
}

export const ParticipantsHiddenPart = ({ participants, setAccessModal }: IParticipantsHiddenPartProps) => {
  return (
    <Box
      textAlign='center'
      mt={4}
    >
      <ButtonCustom
        onClick={() => setAccessModal(true)}
      >
        Добавить участников
      </ButtonCustom>

      <Box
        mt={2}
      >
        <MobileAppParticipantsList
          disablePadding
        >
          {participants.length
            ? participants.map(({ id, photo, firstName, lastName }) => (
              <ListItem key={id}>
                <Box mr={2}>
                  <SmallUserAvatarStyle prim={photo || DEFAULT_AVATAR_URL} />
                </Box>
                <Box>
                  {lastName} {firstName}
                </Box>
              </ListItem>
            ))
            : <ListItem style={{ justifyContent: 'center' }}>Получатели отсутсвуют</ListItem>
          }
        </MobileAppParticipantsList>
      </Box>
    </Box>
  )
}