import { DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { Dialog } from "@material-ui/core";
import React, { useState } from "react";
import { AvatarEditor } from "../Common/AvatarEditor";
import { DEFAULT_AVATAR_URL } from "../constants";
import { ButtonCustom } from "../Style/Style";
import { AvatarImage, UploadButton } from "./AvatarPicker.style";

const blobToFile = (theBlob: Blob, fileName: string): File => {
  return new File([theBlob], fileName);
};

interface IAvatarPickerProps {
  uploadHandler(file: File): void;
  photo?: string;
  disableResize?: boolean;
  disabled?: boolean;
  border_radius?: string;
  variant?: string;
}

export const AvatarPicker = ({ variant, uploadHandler, photo, disableResize, disabled, border_radius }: IAvatarPickerProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [cropper, setCropper] = useState<Cropper | null>();
  const [cropped, setCropped] = useState<string>();

  const handleClickOpen = (): void => {
    if (!disabled) {
      setOpen(true);
    }
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <AvatarImage
        variant={variant}
        disabled={disabled}
      >
        <div className="AvatarImage__edit" onClick={handleClickOpen}>
          <UploadButton
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<Edit />}
          >
            Редактировать
          </UploadButton>
        </div>
        <div className="AvatarImage__image">
          <img src={cropped || photo || DEFAULT_AVATAR_URL} alt="avatar" />
        </div>
      </AvatarImage>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Редактировать аватар</DialogTitle>

        <DialogContent>
          <AvatarEditor
            cropper={cropper}
            setCropper={setCropper}
            disableResize={disableResize}
            border_radius={border_radius}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>

          <ButtonCustom
            disabled={!cropper}
            onClick={async () => {
              if (cropper) {
                const canvasScaled = cropper.getCroppedCanvas({maxWidth: 720});
                const blob = await fetch(canvasScaled.toDataURL()).then((res) =>
                  res.blob()
                );
                const result = blobToFile(blob, "avatar.jpg");
                setCropped(canvasScaled.toDataURL());
                uploadHandler(result);
                handleClose();
              }
            }}
            color="primary"
            autoFocus
          >
          Установить
          </ButtonCustom>
        </DialogActions>
      </Dialog>
    </>
  );
}
