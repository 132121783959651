import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  CreateProjectKanbanDocument,
  CreateProjectKanbanMutation,
  GetProjectKanbanDocument,
  RestartSprintDocument,
  RestartSprintMutation,
} from "../../generated/graphql";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { KanbanAddTasksModal } from "../KanbanAddTasksModal";
import { CloseIconButton } from "../shared/Buttons/CloseIconButton";
import { ErrorMessageSnackbar } from "../shared/ErrorMessageSnackbar";
import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
  defaultMaterialTheme,
} from "../shared/Style/Style";

interface IKanbanCreateModalProps {
  id?: string;
  projectId: string;
  open: boolean;
  close(): void;
  tasksIds: string[];
  newStepId: string;
}

export const KanbanCreateModal = ({
  open,
  close,
  id,
  projectId,
  tasksIds,
  newStepId,
}: IKanbanCreateModalProps) => {
  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const [addTasksModal, setAddTasksModal] = useState(false);
  const [requestError, setRequestError] = useState(false);

  const [
    createKanban,
    {
      data: createKanbanData,
      loading: createKanbanLoading,
      error: createKanbanError,
    },
  ] = useMutation<CreateProjectKanbanMutation>(CreateProjectKanbanDocument, {
    variables: {
      projectId,
      name,
    },
  });

  const [
    restartKanban,
    {
      data: restartKanbanData,
      loading: restartKanbanLoading,
      error: restartKanbanError,
    },
  ] = useMutation<RestartSprintMutation>(RestartSprintDocument, {
    variables: { id, name },
    refetchQueries: [
      { query: GetProjectKanbanDocument, variables: { projectId } },
    ],
  });

  const saveHandler = () => {
    if (!name) {
      setError(true);
      return;
    }
    id ? restartKanban() : createKanban();
  };

  const closeHandler = () => {
    setName("");
    setError(false);
    setRequestError(false);
    id && name && restartKanban();
    close();
  };

  useEffect(() => {
    if (restartKanbanError || createKanbanError) {
      setRequestError(true);
    }
  }, [restartKanbanError, createKanbanError]);

  useEffect(() => {
    if (createKanbanData) {
      setAddTasksModal(true);
    }

    if (restartKanbanData) {
      setRequestError(false);
    }
  }, [createKanbanData, restartKanbanData]);

  const disableOnLoading = restartKanbanLoading || createKanbanLoading;

  return (
    <>
      <Dialog open={open}>
        <CloseIconButton disabled={disableOnLoading} close={closeHandler} />
        <DialogTitle>
          {id ? "Редактирование спринта" : "Создание спринта"}
        </DialogTitle>

        <DialogContent style={{ minWidth: "500px" }}>
          <ThemeProvider theme={defaultMaterialTheme}>
            <TextField
              fullWidth
              label="Название спринта"
              value={name}
              disabled={disableOnLoading}
              onChange={({ target: { value } }) => setName(value)}
              onFocus={() => setError(false)}
              error={error}
            />
          </ThemeProvider>

          <ButtonsWrapper>
            <CancelButton onClick={closeHandler} disabled={disableOnLoading}>
              Отмена
            </CancelButton>

            <ButtonCustom
              onClick={() => (id ? setAddTasksModal(true) : saveHandler())}
              disabled={disableOnLoading}
            >
              {id ? "запустить" : "создать"}
            </ButtonCustom>
          </ButtonsWrapper>
        </DialogContent>
      </Dialog>

      <KanbanAddTasksModal
        projectId={projectId}
        open={addTasksModal}
        workflowStepId={newStepId}
        workflowStepStartOrder={0}
        close={() => {
          setAddTasksModal(false);
          closeHandler();
        }}
        tasksIds={tasksIds}
      />
      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={
          (createKanbanError && "При создании спринта произошла ошибка") ||
          (restartKanbanError && "При завершении спринта произошла ошибка")
        }
      />
    </>
  );
};
