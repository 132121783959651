import { useEffect, useMemo } from "react";
import { useReactiveVar } from "@apollo/client";
import { mobileAppModel } from "../../state";
import { mediaCardsQuantity } from "../../constants";
import { MediaCardFilled } from "./MediaCardFilled";
import { MediaCardEmpty } from "./MediaCardEmpty";
import { MediaCardContainer } from "./Style";
import { ModalInput as MediaLabel } from "../../../../shared/Style/Style";

export const MediaCards = () => {
  const model = useReactiveVar(mobileAppModel.model);
  const inputErrors = useReactiveVar(mobileAppModel.inputErrors);
  const isLoading = useReactiveVar(mobileAppModel.updateLoading);

  const mediaCards =
    model?.contentPreviews?.length > mediaCardsQuantity
      ? model?.contentPreviews?.slice(0, mediaCardsQuantity)
      : model?.contentPreviews;

  const filledItemsCount = mediaCards?.length || 0;
  const emptyItemsCount = mediaCardsQuantity - filledItemsCount;

  useEffect(() => {
    if (filledItemsCount > 0)
      mobileAppModel.setInputErrors("contentPreviews", false);
  }, [filledItemsCount]);

  const filledItems = useMemo(
    () =>
      mediaCards?.map(({ id, url }) => (
        <MediaCardFilled key={id} id={id} mediaURL={url} />
      )),
    [mediaCards]
  );

  const emptyItems = useMemo(
    () =>
      Array.from({ length: emptyItemsCount }).map((_, index) => {
        const isDisable = index > 0 || isLoading;
        return <MediaCardEmpty key={index} isDisable={isDisable} />;
      }),
    [emptyItemsCount, isLoading]
  );

  return (
    <>
      <MediaLabel
        disabled
        required
        label="Картинки/видео"
        error={inputErrors.contentPreviews.error}
      />

      <MediaCardContainer $isLocked={isLoading}>
        {filledItems}
        {emptyItems}
      </MediaCardContainer>
    </>
  );
};
