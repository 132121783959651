import React, { useState, useEffect } from "react";

import { ISalaryModalInnerProps } from "../SalaryModal";

import { useMutation } from "@apollo/client";
import {
  GetUserPayrollDocument,
  CreatePayrollSalaryPartMutation,
  CreatePayrollSalaryPartDocument,
  UpdatePayrollSalaryPartMutation,
  UpdatePayrollSalaryPartDocument,
} from "../../../generated/graphql";

import { ThemeProvider } from "@material-ui/styles";
import { DatePicker } from "@material-ui/pickers";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import {
  ModalBody,
  CloseBtn,
  ModalHeader,
  SubmitBtn,
  BtnsWrapper,
  ModalForm,
  ModalField,
  ModalLabel,
  ModalInput,
  ModalErrorTxt,
  ErrorDateTxt,
} from "../SalaryModal.style";
import { defaultMaterialTheme } from "../../shared/Style/Style";

interface IPaymentData {
  name?: string;
  date?: Date;
  value?: number;
}

export const SalaryPartModal = ({ close, salaryPart, payroll }: ISalaryModalInnerProps) => {
  const [salaryPartData, setSalaryPartData] = useState<IPaymentData>({
    name: salaryPart?.name || "",
    date: salaryPart?.date || "",
    value: salaryPart?.value || 0,
  });

  const [requestError, setRequestError] = useState(false);

  const [
    createSalaryPart,
    {
      data: createData,
      loading: createLoading,
      error: createError,
    }
  ] = useMutation<CreatePayrollSalaryPartMutation>(
    CreatePayrollSalaryPartDocument,
    {
      variables: {
        payrollId: payroll.id,
        name: salaryPartData.name,
        value: salaryPartData.value,
        date: salaryPartData.date,
      },
      refetchQueries: [{
        query: GetUserPayrollDocument,
        variables: { payrollId: payroll.id },
      }]
    }
  )

  const [
    updateSalaryPart,
    {
      data: updateData,
      loading: updateLoading,
      error: updateError,
    }
  ] = useMutation<UpdatePayrollSalaryPartMutation>(
    UpdatePayrollSalaryPartDocument,
    {
      variables: {
        id: salaryPart?.id,
        name: salaryPartData.name,
        date: salaryPartData.date,
        value: salaryPartData.value,
      },
      refetchQueries: [{
        query: GetUserPayrollDocument,
        variables: { payrollId: payroll.id },
      }],
    }
  );

  const [errors, setErrors] = useState({
    name: false,
    date: false,
    value: false,
  });

  useEffect(() => {
    if (updateError || createError) {
      setRequestError(true);
    }
  }, [updateError, createError]);

  useEffect(() => {
    if (updateData || createData) {
      setRequestError(false);
      setErrors({
        name: false,
        date: false,
        value: false,
      });
      close();
    }
  }, [updateData, createData]);

  const submitHandler = () => {
    if (!salaryPartData.name) {
      setErrors(state => ({
        ...state,
        name: true,
      }));
    }

    if (!salaryPartData.date) {
      setErrors(state => ({
        ...state,
        date: true,
      }));
    }

    if (!salaryPartData.value) {
      setErrors(state => ({
        ...state,
        value: true,
      }));
    }

    if (!salaryPartData.name || !salaryPartData.date || !salaryPartData.value) {
      return;
    }

    salaryPart ? updateSalaryPart() : createSalaryPart();
  };

  return (
    <ModalBody>
      <ModalHeader>
        {salaryPart ? "редактировать выплату" : "создать выплату"}
      </ModalHeader>

      <ModalForm>
        <ModalField>
          <ModalLabel>{"Название выплаты: "}</ModalLabel>
          <ModalInput
            id="bonusName"
            name={errors.name ? "error" : ""}
            value={salaryPartData.name}
            onChange={({ target: { value } }) => {
              setErrors({
                ...errors,
                name: false,
              });
              setSalaryPartData({ ...salaryPartData, name: value });
            }}
          />
          {errors.name && (
            <ModalErrorTxt> У выплаты должно быть название </ModalErrorTxt>
          )}
        </ModalField>

        <ModalField>
          <ThemeProvider theme={defaultMaterialTheme}>
            <DatePicker
              fullWidth
              style={{ zIndex: 4500 }}
              variant="inline"
              format="dd.MM.yyyy"
              label="Дата выплаты"
              disablePast={true}
              value={salaryPartData?.date ? new Date(salaryPartData.date) : new Date()}
              onChange={(value) => {
                setErrors({
                  ...errors,
                  date: false,
                });
                setSalaryPartData({ ...salaryPartData, date: value });
              }}
            />
          </ThemeProvider>
          {errors.date && (
            <ErrorDateTxt> Выберите дату </ErrorDateTxt>
          )}
        </ModalField>

        <ModalField>
          <ModalLabel>{"Размер выплаты: "}</ModalLabel>
          <ModalInput
            id="bonusValue"
            type="number"
            value={Number(salaryPartData.value)}
            onChange={({ target: { value } }) => {
              setErrors({
                ...errors,
                value: false,
              });
              setSalaryPartData({ ...salaryPartData, value: +value })
            }}
          />
          {errors.value && (
            <ModalErrorTxt> Выплата должна быть больше нуля </ModalErrorTxt>
          )}
        </ModalField>

        <BtnsWrapper>
          <CloseBtn onClick={close}>Закрыть</CloseBtn>
          <SubmitBtn
            disabled={errors.date || errors.name || errors.value || createLoading || updateLoading}
            onClick={submitHandler}
          >
            {salaryPart ? "сохранить изменнения" : "создать выплату"}
          </SubmitBtn>
        </BtnsWrapper>
      </ModalForm>

      <Snackbar
        open={requestError}
        autoHideDuration={6000}
        onClose={() => setRequestError(false)}
      >
        <Alert severity='error'>{updateError?.message || createError?.message}</Alert>
      </Snackbar>
    </ModalBody>
  );
};
