import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  CreateClientSiteDocument,
  CreateClientSiteMutation,
  UpdateClientSiteDocument,
  UpdateClientSiteMutation,
  GetClientSitesDocument,
} from "../../../generated/graphql";
import { Form } from "react-final-form";
import { urlPatternValidation } from "../../../core/validators/validators";
import { Dialog, DialogContent, Box, TextField } from "@material-ui/core";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import {
  ButtonCustom,
  ButtonsWrapper,
  CancelButton,
  useStyles,
} from "../../shared/Style/Style";

interface CreateSiteModalProps {
  open: boolean;
  close(): void;
  refetchVariables: {
    limit: number;
    skip: number;
  };
  edit?: boolean;
  item: any;
}

interface SiteData {
  name: string;
  link: string;
  iframe: string;
}

const initialSiteData: SiteData = {
  name: "",
  link: "",
  iframe: "",
};

interface SiteDataError {
  nameError: string;
  linkError: string;
}

const initialSiteDataError: SiteDataError = {
  nameError: "",
  linkError: "",
};

export const SiteModal = ({
  open,
  close,
  refetchVariables,
  edit,
  item,
}: CreateSiteModalProps) => {
  const classes = useStyles();
  const [siteData, setSiteData] = useState<SiteData>(initialSiteData);
  const [siteDataError, setSiteDataError] =
    useState<SiteDataError>(initialSiteDataError);

  const [requestError, setRequestError] = useState<boolean>(false);

  const [
    createClientSite,
    {
      data: createClientSiteData,
      loading: createClientSiteLoading,
      error: createClientSiteError,
    },
  ] = useMutation<CreateClientSiteMutation>(CreateClientSiteDocument, {
    refetchQueries: [{ query: GetClientSitesDocument }],
  });

  const [
    updateClientSite,
    {
      data: updateClientSiteData,
      loading: updateClientSiteLoading,
      error: updateClientSiteError,
    },
  ] = useMutation<UpdateClientSiteMutation>(UpdateClientSiteDocument, {
    refetchQueries: [
      { query: GetClientSitesDocument, variables: refetchVariables },
    ],
  });

  useEffect(() => {
    if (edit) {
      setSiteData({ name: item.name, link: item.link, iframe: item.iframe });
    }
  }, [edit]);

  useEffect(() => {
    if (createClientSiteError || updateClientSiteError) {
      setRequestError(true);
    }
  }, [createClientSiteError, updateClientSiteError]);

  useEffect(() => {
    if (createClientSiteData || updateClientSiteData) {
      closeHandler();
    }
  }, [createClientSiteData, updateClientSiteData]);

  const disableOnLoading = createClientSiteLoading || updateClientSiteLoading;

  const onFormSubmit = () => {
    if (!siteData.name) {
      setSiteDataError({ ...siteDataError, nameError: "Обязательное поле" });
      return;
    }

    if (!siteData.link) {
      setSiteDataError({ ...siteDataError, linkError: "Обязательное поле" });
      return;
    }

    const validLink = urlPatternValidation(siteData.link);
    if (!validLink) {
      setSiteDataError({
        ...siteDataError,
        linkError: "Ссылка должна начинаться с https://",
      });
      return;
    }

    const key = siteData.link.split("/")[2].split(".")[0];

    edit
      ? updateClientSite({
          variables: {
            ...siteData,
            iframe: `https://lk.flexreality.pro/iframes/${key}`,
            key: key,
            id: item.id,
          },
        })
      : createClientSite({
          variables: {
            ...siteData,
            iframe: `https://lk.flexreality.pro/iframes/${key}`,
            key: key,
          },
        });
  };

  const closeHandler = () => {
    setSiteData(initialSiteData);
    setSiteDataError(initialSiteDataError);
    setRequestError(false);
    close();
  };

  return (
    <Dialog open={open}>
      <DialogContent style={{ minWidth: "400px" }}>
        <CloseIconButton close={closeHandler} />
        <Form onSubmit={() => {}}>
          {() => (
            <Box style={{ padding: "15px" }}>
              <TextField
                className={classes.root}
                fullWidth
                variant="filled"
                type="text"
                label={`Название*`}
                name="name"
                value={siteData.name}
                onChange={({ target: { value } }) =>
                  setSiteData({ ...siteData, name: value })
                }
                onFocus={() =>
                  setSiteDataError({ ...siteDataError, nameError: "" })
                }
                error={!!siteDataError.nameError}
                helperText={siteDataError.nameError}
                onKeyDown={async (event) => {
                  if (event.code === "Enter" || event.code === "NumpadEnter") {
                    console.log("submit");
                  }
                }}
              />
              <TextField
                multiline
                rows={4}
                className={classes.root}
                fullWidth
                variant="filled"
                type="text"
                label={"http(s)://"}
                value={siteData.link}
                onChange={({ target: { value } }) =>
                  setSiteData({ ...siteData, link: value, iframe: value })
                }
                onFocus={() =>
                  setSiteDataError({ ...siteDataError, linkError: "" })
                }
                error={!!siteDataError.linkError}
                helperText={siteDataError.linkError}
              />
              <ButtonsWrapper>
                <CancelButton
                  onClick={closeHandler}
                  disabled={disableOnLoading}
                  width={"150"}
                >
                  Отмена
                </CancelButton>

                <ButtonCustom
                  onClick={onFormSubmit}
                  disabled={disableOnLoading}
                  width={"190"}
                >
                  Сохранить
                </ButtonCustom>
              </ButtonsWrapper>
            </Box>
          )}
        </Form>
      </DialogContent>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={`При создании сайта произошла ошибка`}
      />
    </Dialog>
  );
};
