import styled from "styled-components";
import { Paper, Card } from "@material-ui/core";
import { primary, lightBlack, SmallUserAvatarStyle } from "../shared/Style/Style";

export const KanbanColumnContainer = styled(Paper)`
  background-color: #eee;
  width: 320px;
  border-radius: 10px;
  transition: height 0.5s;
`;

export const ListContainer = styled.div`
  height: fit-content;
  max-height: 50vh;
  overflow-x: auto;
  margin: 8px 0;
  padding: 2px 0;
`;

export const KanbanCard = styled(Card)`
  padding: 15px 20px;
  /* text-align: left; */
  border-radius: 10px;
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const KanbanColumnHeader = styled(KanbanCard)`
  background-color: ${lightBlack};
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  &:first-of-type {
    margin-bottom: 0;
  }
`;

export const KanbanColumFooter = styled(KanbanCard)`
  background-color: ${primary};
  color: #fff;
  font-weight: bold;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  cursor: pointer;
  text-align: left;
`;

export const KanbanCardContent = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  position: relative;
  justify-content: center;
`;

export const KanbanCardNameTxt = styled.div`
  color: ${lightBlack};
  margin-top: 12px;
`;

export const KanbanUserAvatar = styled(SmallUserAvatarStyle)`
  display: block;
  min-width: 36px;
  width: 36px;
  height: 36px;
  margin-left: auto;
  margin-right: 12px;
  margin-top: 12px;
`;