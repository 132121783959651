import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import { lightBlack, primary, secondary } from "../shared/Style/Style";
import { DatePicker } from "@material-ui/pickers";

const SalaryHistoryIconButton = styled(IconButton)`
  transition: opacity 0.3s;
  &:disabled {
    opacity: 0.5;
  }
`;

export const EditButton = styled(SalaryHistoryIconButton)`
  color: ${secondary};
`;

export const DeleteButton = styled(SalaryHistoryIconButton)`
  color: red;
`;
