import {
  Payment,
  PaymentType,
  SubscriptionPlan,
  SubscriptionPlanTypeEnum,
} from "../../../generated/graphql";

const filterBasePlan = (plans: SubscriptionPlan[]) =>
  plans.filter(({ name }) => !name.startsWith("BASE"));

export const getSchoolSubscriptionPlans = (plans: SubscriptionPlan[]) => {
  return filterBasePlan(plans).filter(
    ({ type }) => type === SubscriptionPlanTypeEnum.ForSchool
  );
};

export const TODAY_DATE = new Date(new Date().setHours(0, 0, 0, 0));

export const getActivePayments = (payments: Payment[] = []) => {
  return payments.filter(
    ({ expiredDate }) => new Date(expiredDate).getTime() >= TODAY_DATE.getTime()
  );
};

export const getPaymentsHistory = (payments: Payment[] = []) => {
  return payments.filter(
    ({ expiredDate }) => new Date(expiredDate).getTime() < TODAY_DATE.getTime()
  );
};

export const isExternalPayment = (paymentType: PaymentType) => {
  return (
    paymentType === PaymentType.Fondy ||
    paymentType === PaymentType.Stripe ||
    paymentType === PaymentType.Liqpay
  );
};

export const getFormattedError = (errorMessage: string) =>
  errorMessage.trim().replace(/\s+/g, " ");
