import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import { DateRange } from '@material-ui/icons';
import { ChangeEvent, useState } from 'react';
import {
  AdminReport,
  AdminReportType,
  useCreateTeacherGroupsClassroomsPupilsReportMutation,
  useDeleteTeacherGroupsClassroomsPupilsReportMutation,
  useGetReportsQuery,
} from '../../../generated/graphql';
import { useRequestError } from '../../../hooks';
import { CustomTablePagination } from '../../shared/CustomTablePagination';
import { DateRangePicker } from '../../shared/DateRangePicker';
import { initialDateRange } from '../../shared/DateRangePicker/DateRangePicker';
import { ErrorMessageSnackbar } from '../../shared/ErrorMessageSnackbar';
import { LoadingProgress } from '../../shared/LoadingProgress';
import { ButtonCustom, DatePickerButton } from '../../shared/Style/Style';
import { ReportsContainer } from '../ReportsContainer';
import { HeadColumns } from './HeadColumns';
import { ReportsList } from './ReportsList';
import { rowsPerPageOptions } from './constants';

export const TeachersPupilsReport = () => {
  const [reports, setReports] = useState<AdminReport[]>([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(rowsPerPageOptions[0]);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(0);
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [isDateRangePickerOpen, setDateRangePickerOpen] = useState(false);
  const { startDate, endDate } = dateRange;
  const isDatePickerActive = !!endDate || !!startDate;

  const {
    error: adminReportsError,
    loading: adminReportsLoading,
    refetch,
  } = useGetReportsQuery({
    variables: {
      type: AdminReportType.TeacherGroupsClassroomsPupils,
      limit,
      skip,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const { total, reports } = data.getReports;
      if (total) {
        setTotal(total);
        setReports(reports);
      }
    },
  });

  const [
    createAdminReport,
    { loading: adminReportCreationLoading, error: adminReportCreationError },
  ] = useCreateTeacherGroupsClassroomsPupilsReportMutation({
    onCompleted: async () => {
      await refetch();
      if (endDate || startDate) setDateRange(initialDateRange);
    },
  });

  const [
    deleteAdminReport,
    { loading: adminReportDeletionLoading, error: adminReportDeletionError },
  ] = useDeleteTeacherGroupsClassroomsPupilsReportMutation({
    onCompleted: async () => {
      await refetch();
    },
  });

  const showAdminReports =
    !adminReportsLoading &&
    !adminReportCreationLoading &&
    !adminReportDeletionLoading;

  const errors =
    adminReportsError || adminReportCreationError || adminReportDeletionError;
  const errorMessage =
    (adminReportsError && 'Ошибка при загрузке отчета по учителям и урокам') ||
    (adminReportCreationError && 'Ошибка при создании отчета') ||
    (adminReportDeletionError && 'Ошибка при удалении отчета');

  const { requestError, setRequestError } = useRequestError(errors);

  const handleAdminReportsDeletion = async (id: string) => {
    try {
      await deleteAdminReport({ variables: { id } });
    } catch (e) {
      console.error(e);
    }
  };

  const handleAdminReportsCreation = async () => {
    try {
      await createAdminReport({ variables: { startDate, endDate } });
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setSkip(newPage * limit);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(+event.target.value);
    setSkip(0);
    setPage(0);
  };

  const loading = <LoadingProgress />;

  return (
    <ReportsContainer>
      <Grid container justifyContent="center" spacing={2}>
        <Grid xs={3} item>
          <h2>По учителям и урокам</h2>
        </Grid>
        <Grid xs={9} item>
          <Box display="flex" alignItems="center" height="100%">
            <DatePickerButton
              onClick={() => setDateRangePickerOpen(true)}
              isActive={isDatePickerActive}
              style={{ marginRight: 8 }}
            >
              <DateRange />
              Период
            </DatePickerButton>
            {showAdminReports ? (
              <ButtonCustom width="220" onClick={handleAdminReportsCreation}>
                Создать новый отчет <br/>
                {startDate && endDate ? 'за период': null}
              </ButtonCustom>
            ) : (
              loading
            )}
          </Box>
        </Grid>
      </Grid>

      <Paper>
        <TableContainer className="paymentsHistory">
          <Table stickyHeader>
            <HeadColumns />
            <TableBody>
              <ReportsList
                reports={reports}
                handleDeleteReport={handleAdminReportsDeletion}
              />
            </TableBody>
          </Table>
        </TableContainer>

        {total > rowsPerPageOptions[0] ? (
          <CustomTablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={total}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Paper>

      <DateRangePicker
        setDateRange={setDateRange}
        open={isDateRangePickerOpen}
        onClose={() => setDateRangePickerOpen(false)}
      />

      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => setRequestError(false)}
      />
    </ReportsContainer>
  );
};
