import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { primary } from "../shared/Style/Style";
import MuiPhoneNumber from "material-ui-phone-number";

export const Subtitle = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 42px;
`

export const VerticalLine = styled.div`
  width: 2px;
  min-height: 80%;
  border: 1px solid #ccc;
`

export const UserField = styled.div`
  width: 100%;
  padding: 10px 14px;
  background: #f5f4f4;
  border-bottom: 1px solid #e3e3e3;
  border-radius: 4px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`
export const UserLabelBlock= styled.div`
  width: 100%;
  font-weight: 500;
`
export const UserFields = styled(TextField)`
  margin-bottom: 20px;
  margin-left: 10px;
  & .MuiInput-underline:before {
    border-bottom-color: #eee;
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${primary};
  }

  & .MuiInputBase-multiline {
    padding: 13px 14px 13px 20px;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${primary};
  }
`;

export const UserFieldsPhone = styled(MuiPhoneNumber)`
  margin-bottom: 20px;
  margin-left: 10px;
  & .MuiInput-underline:before {
    border-bottom-color: #eee;
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${primary};
  }

  & .MuiInputBase-multiline {
    padding: 13px 14px 13px 20px;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${primary};
  }
`;

export const SchoolList = styled.ul`
  padding: 0;
  list-style-type: none;
`;

export const SchoolListItem = styled.li`
  margin-top: 8px;
`;

export const SchoolLink = styled(Link)`
  color: ${primary};
`;
