import React, { useEffect, useState } from "react";
import {
  CreateProjectTaskStatusForProjectDocument,
  CreateProjectTaskStatusForProjectMutation,
  GetProjectTaskStatusesByProjectDocument,
  ProjectTaskStatus,
} from "../../../generated/graphql";
import { SectionTitle } from "../SectionTitle/SectionTitle";
import { useMutation } from "@apollo/client";
import { NewItem } from "../NewItem/NewItem";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { ItemsContainer, SectionContainer } from "../KanbanSettings.style";
import { ProjectTaskStatusItem } from "./ProjectTaskStatusItem";

interface StatusesSectionProps {
  projectTaskStatuses: ProjectTaskStatus[];
  projectId: string;
}

export const StatusesSection = ({
  projectTaskStatuses,
  projectId,
}: StatusesSectionProps) => {
  const [requestError, setRequestError] = useState(false);

  const [isCreating, setIsCreating] = useState(false);
  const [newProjectTaskStatusName, setNewProjectTaskStatusName] = useState("");

  const [createProjectTaskStatus, { data, loading, error }] =
    useMutation<CreateProjectTaskStatusForProjectMutation>(
      CreateProjectTaskStatusForProjectDocument,
      {
        refetchQueries: [
          {
            query: GetProjectTaskStatusesByProjectDocument,
            variables: {
              projectId,
            },
          },
        ],
      }
    );

  useEffect(() => {
    if (data) {
      setIsCreating(false);
      setNewProjectTaskStatusName("");
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setRequestError(true);
    }
  }, [error]);

  const onSave = (): void => {
    createProjectTaskStatus({
      variables: {
        projectId,
        name: newProjectTaskStatusName,
      },
    });
  };

  return (
    <SectionContainer>
      <SectionTitle
        title="Статусы задач"
        onClickBtn={() => {
          setIsCreating(true);
        }}
        withoutButton
      />
      {isCreating && (
        <NewItem
          value={newProjectTaskStatusName}
          setValue={setNewProjectTaskStatusName}
          onSave={onSave}
          label="статус задачи"
          disabledSaveButton={!newProjectTaskStatusName || loading}
        />
      )}
      <ItemsContainer>
        {projectTaskStatuses.map((status) => (
          <ProjectTaskStatusItem
            key={status.id}
            status={status}
            label="статус"
            projectId={projectId}
          />
        ))}
      </ItemsContainer>

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={"Ошибка создания статуса для задачи"}
      />
    </SectionContainer>
  );
};
