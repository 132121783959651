import React from "react";
import { DropdownIconWrapper } from "./DropdownArrowButtonBold.style";
import { ArrowDropDown } from "@material-ui/icons";

export const DropdownArrowButtonBold = ({
  expanded,
}: {
  expanded: boolean;
}) => {
  return (
    <DropdownIconWrapper expanded={expanded}>
      <ArrowDropDown color="inherit" />
    </DropdownIconWrapper>
  );
};
