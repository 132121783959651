import {
  containerGap,
  containerItemHeight,
  containerMaxHeight,
  maxInputValue,
} from "./constants";

export const verifyInput = (value: string) => {
  const valueAsNumber = Number(value);
  console.log(value, valueAsNumber)
  return (
    !value.length ||
    value !== String(valueAsNumber) ||
    isNaN(valueAsNumber) ||
    !Number.isInteger(valueAsNumber) ||
    valueAsNumber < 0 ||
    valueAsNumber > maxInputValue
  );
};

export const calculateIsScrollable = (itemsLength: number) => {
  return (
    itemsLength &&
    itemsLength * containerItemHeight + (itemsLength - 1) * containerGap >=
      containerMaxHeight
  );
};
