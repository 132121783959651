import styled from "styled-components";
import { ListItem } from "@material-ui/core";
import { ButtonCustom, primary } from "../../shared/Style/Style";
import { Link } from "react-router-dom";

export const ListCardClubsGroups = styled.div`
  .MuiGrid-item {
    width: 100%;
    max-width: 950px;
    background-color: #faf9f9;
  }
`;
export const GroupEditButtonContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  .MuiButton-root {
    width: 100%;
  }
`;
export const PersonalData = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: black;
`;
export const ClubsAndGroupsData = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: black;
`;
export const FlexBetween = styled.div`
  .MuiGrid-container {
    justify-content: space-between;
  }
`;
export const ListItemStyle = styled(ListItem)<{ active: boolean }>`
  &.MuiListItem-button {
    background-color: ${(props) => (props.active ? `${primary}` : "#fff")};
  }
`;

export const ProjectAndGroupStyle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: black;
`;

export const UserSalaryButton = styled(ButtonCustom)`
  display: block;
  margin: 40px 0 35px;
  &:not(:last-of-type) {
    margin-right: 20px;
  }
`;

export const UserSalaryLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;
