import { Table } from '@material-ui/core'
import { ParticipantsTableHeadCell, ParticipantsTableRow } from "../style";

const TABLE_HEADER = [
  'ID',
  'ФИО',
  'Электронный адрес',
  'Роль',
  'Номер телефона',
  'Страна',
]

export const ParticipantsTableHeader = () => {
    return (
        <Table>
            <thead>
                <ParticipantsTableRow>
                    {TABLE_HEADER.map((name, i) => (
                        <th key={i}>
                            <ParticipantsTableHeadCell>
                                {name}
                            </ParticipantsTableHeadCell>
                        </th>
                    ))}
                </ParticipantsTableRow>
            </thead>
        </Table>
    );
};
