import { useReactiveVar } from "@apollo/client";
import { ApplicationMain } from "../../../../../generated/graphql";
import { mobileAppModel } from "../../state";
import { ModalInput } from "../../../../shared/Style/Style";
import { Chip, IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { Autocomplete } from "@material-ui/lab";


export const SelectSubjectCategory = () => { 
    const model = useReactiveVar(mobileAppModel.model);
    const subjectCategoriesList = useReactiveVar(mobileAppModel.subjectCategoriesList);
    const isLoading = useReactiveVar(mobileAppModel.updateLoading);
    const inputErrors = useReactiveVar(mobileAppModel.inputErrors);

    if (!model) return null;
    const { mains } = model;

    const getSubjectToRender = (id: string) => {
        return findSubjectById(subjectCategoriesList, id)?.name || "";
    };

    const findSubjectById = (subjects: ApplicationMain[], id: string) => {
        return subjects.find((subject) => subject.id === id);
    };

    const handleRemoveSubjectIds = (subjectId: string) => {
        const subjectsToSet = mains.filter(({ id }) => id !== subjectId).map(({ id }) => id) || [];
        mobileAppModel.setSubjectCategories(subjectsToSet);
    };

    const deleteIcon = (<IconButton><CancelIcon/></IconButton>);
    
    return (
        <>
            <Autocomplete
                multiple
                disabled={isLoading}
                filterSelectedOptions
                style={{ maxWidth: "520px"}}
                options={subjectCategoriesList?.map(({ id }) => id)}
                onChange={(_, value) => {
                    mobileAppModel.setSubjectCategories(value);
                }}
                onFocus={() =>
                    mobileAppModel.setInputErrors("mains", false)
                }
                getOptionLabel={(option) => {
                    return getSubjectToRender(option);
                }}
                value={mains.map(({ id }) => id)}
                    renderTags={(value: string[], getTagProps) => {
                    return value.map((tagId: string, index: number) => (
                    <Chip
                        variant="default"
                        label={findSubjectById(mains, tagId)?.name}
                        deleteIcon={deleteIcon}
                        {...getTagProps({ index })}
                        onDelete={() => handleRemoveSubjectIds(tagId)}
                    />
                ));
            }}
            renderInput={(params) => (
                <ModalInput
                    required
                    error={inputErrors.mains.error}  
                    label="Предметы"
                    margin="none"
                    {...params}
                />
            )}
            />
        </>
    );
};
