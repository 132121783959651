import styled from "styled-components";
import { Button } from '@material-ui/core';
import { ImageWrapper } from "../Style/Style";

export const AvatarImage = styled(ImageWrapper)<{ variant?: string, disabled?: boolean}>`
  max-width: ${({variant}) => variant === 'pic' ? 'fit-content' : 'unset'};
  margin: ${({variant}) => variant === 'pic' ? '0 auto' : 'unset'};
  border-radius: ${({variant}) => variant === 'pic' ? '0%' : '100%'};
  margin-top: 16px;
  img {
    width: ${({variant}) => variant === 'pic' ? 'fit-content' : '174px'};
    height: ${({variant}) => variant === 'pic' ? 'fit-content' : '174px'};
  }
  &:hover {
    cursor: ${({disabled}) => disabled ? 'auto' : 'pointer'};
    .AvatarImage__edit {
      opacity: 1;
    }
  }
  .AvatarImage__edit {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    opacity: 0;
    transition: all 200ms linear;
    display: ${({variant, disabled}) => variant === 'pic' && disabled ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

export const UploadButton = styled(Button)`
  &.MuiButton-root {
    color: white;
    border-color: white;
    &:hover {
      color: white;
      border-color: white;
    }
  }
`;