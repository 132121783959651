enum Participants {
  teachers = "Учителя",
  pupils = "Ученики",
  parents = "Родители",
  guest = "Гость",
};

export const participantsMap = new Map<Participants, boolean>([
  [Participants.teachers, true],
  [Participants.pupils, true],
  [Participants.parents, true],
  [Participants.guest, true],
]);

export const PARTICIPANTS = Array.from(new Map([
  ["isForTEACHER", Participants.teachers],
  ["isForPUPIL", Participants.pupils],
  ["isForPARENT", Participants.parents],
  ["isForGUEST", Participants.guest],
]));

export const tableHeaderMap = new Map([
  ["Фото", false],
  ["Название", false],
  ["Участники", false],
  ["Состояние", true],
  ["Статус", false],
  ["", false],
]);

export const TOOLTIP_MSG = "Состояние отображает доступность награды для пользователя в приложении. Открытая - пользователь может получать по ней прогресс, закрытая - доступна только для просмотра, не для прохождения.";
