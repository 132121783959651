import { useState, useEffect } from "react";
import { Field, Form } from "react-final-form";
import {
  Achieve,
  AchieveTypeEnum,
  CreateAchieveMutationVariables,
  UpdateAchieveMutationVariables,
} from "../../../generated/graphql";
import { Box, Grid, Container, Paper, MenuItem } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import { AvatarPicker } from "../../shared/AvatarPicker";
import { InfoTooltip } from "../../shared/InfoTooltip";
import { LoadingProgress } from "../../shared/LoadingProgress";
import { CustomRadioControl } from "../../shared/CustomRadioGroup/CustomRadioGroup.style";
import {
  ButtonCustom,
  Header,
  ModalInput,
  CheckboxStyled,
} from "../../shared/Style/Style";
import { validateForm } from "../utils";
import { PARTICIPANTS, TOOLTIP_MSG } from "../constants";

interface AchievementProps {
  title: string;
  achievementData?: Achieve;
  dataMutation?: boolean;
  loading?: boolean;
  createAchievement?(variables: CreateAchieveMutationVariables): Promise<void>;
  updateAchievement?(variables: UpdateAchieveMutationVariables): Promise<void>;
}

const formInitialValues = {
  name: undefined,
  descriptionDone: undefined,
  descriptionProgress: undefined,
  type: undefined,
};

const achieveType = Object.values(AchieveTypeEnum);

export const Achievement = ({
  title,
  achievementData,
  loading,
  createAchievement,
  updateAchievement,
}: AchievementProps) => {
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const [locked, setLocked] = useState("false");
  const [participants, setParticipants] = useState({
    isForTEACHER: false,
    isForPUPIL: false,
    isForPARENT: false,
    isForGUEST: false,
  });

  const handleLockedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocked((event.target as HTMLInputElement).value);
  };

  const handleParticipantsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParticipants({ ...participants, [event.target.name]: event.target.checked });
  };

  const initialValues = achievementData ?? formInitialValues;

  const handleAddFile = (file: File) => {
    setUploadedPhoto(file);
  };

  const onFormValidation = (values) => validateForm(values);

  const onFormSubmit = (values) => {
    const { name, descriptionProgress, descriptionDone, type } = values;
    const { isForTEACHER, isForPUPIL, isForPARENT, isForGUEST } = participants;

    const variables = {
      achieveData: {
        name: name ?? "",
        descriptionProgress: descriptionProgress ?? "",
        descriptionDone: descriptionDone ?? "",
        type: type ?? "",
        isLocked: JSON.parse(locked),
        isForTEACHER,
        isForPUPIL,
        isForPARENT,
        isForGUEST,
      },
      ...(uploadedPhoto ? { photo: uploadedPhoto } : {}),
    };

    if (createAchievement) {
      return createAchievement(variables);
    }

    updateAchievement({
      achieveData : {
        ...variables.achieveData,
        id: achievementData.id
      },
      ...(variables.photo ? { photo: variables.photo } : {}),
    });
  };

  useEffect(() => {
    setLocked(achievementData?.isLocked?.toString() ?? "false")
  }, [achievementData?.isLocked]);

  useEffect(() => {
    const { isForTEACHER, isForPUPIL, isForPARENT, isForGUEST } = achievementData ?? {};

    if (achievementData) {
      setParticipants({
        ...participants,
        isForTEACHER,
        isForPUPIL,
        isForPARENT,
        isForGUEST,
      });
    }
  }, [achievementData]);

  if (loading) {
    return <LoadingProgress />;
  }

  return (
    <Container>
      <Header>{title}</Header>

      <Paper elevation={3}>
        <Box p="20px 30px">
          <Form
            initialValues={initialValues}
            validate={onFormValidation}
            onSubmit={onFormSubmit}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid xs={2} item>
                    <AvatarPicker
                      photo={achievementData?.photo}
                      uploadHandler={handleAddFile}
                    />
                  </Grid>

                  <Grid xs={5} item>
                    <Box m="20px 0">
                    <Field name="name">
                      {({ input, meta }) => (
                        <ModalInput
                          label="Название награды"
                          error={meta.error && meta.touched}
                          helperText={meta.touched && meta.error}
                          variant="filled"
                          type="text"
                          {...input}
                        />
                      )}
                    </Field>
                    </Box>

                    <Box m="20px 0">
                    <Field name="descriptionProgress">
                      {({ input, meta }) => (
                        <ModalInput
                          label="Описание условий для получения награды"
                          error={meta.error && meta.touched}
                          helperText={meta.touched && meta.error}
                          variant="filled"
                          type="text"
                          multiline
                          rows={6}
                          {...input}
                        />
                      )}
                    </Field>
                    </Box>

                    <Box m="20px 0">
                    <Field name="descriptionDone">
                      {({ input, meta }) => (
                        <ModalInput
                          label="Описание полученной награды"
                          error={meta.error && meta.touched}
                          helperText={meta.touched && meta.error}
                          variant="filled"
                          type="text"
                          multiline
                          rows={6}
                          {...input}
                        />
                      )}
                    </Field>
                    </Box>

                    <Box display="flex">
                      <h3>Состояние</h3>
                      <InfoTooltip title={TOOLTIP_MSG} />
                    </Box>
                        <RadioGroup value={locked} onChange={handleLockedChange}>
                          <Box display="flex">
                            <FormControlLabel value="false" control={<CustomRadioControl />} label="Открытая" />
                            <FormControlLabel value="true" control={<CustomRadioControl />} label="Закрытая" />
                          </Box>
                        </RadioGroup>

                    <h3>Участники (множественный выбор)</h3>
                    {PARTICIPANTS.map(([key, value]) => (
                      <Box key={key}>
                        <FormControlLabel
                          control={
                            <CheckboxStyled
                              checked={participants[key]}
                              onChange={handleParticipantsChange}
                              name={key}
                            />
                          }
                          label={value}
                        />
                      </Box>
                    ))}
                  </Grid>

                  <Grid xs={4} item>
                    <Box m="20px 0">
                    <Field name="type">
                      {({ input, meta }) => (
                        <>
                          <InputLabel>Тип награды</InputLabel>
                          <Select
                            error={meta.error && meta.touched}
                            fullWidth
                            {...input}
                          >
                            {achieveType.map(t => <MenuItem key={t} button value={t}>{t}</MenuItem>)}
                          </Select>
                        </>
                      )}
                    </Field>
                    </Box>
                  </Grid>

                  <Grid xs={12} item>
                  <Box display="flex" justifyContent="center">
                    <ButtonCustom
                      type="submit"
                      disabled={false}
                    >
                      Сохранить
                    </ButtonCustom>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
        </Box>
      </Paper>
    </Container>
  );
};
